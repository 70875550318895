define("builder/pods/components/tab-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage: see "tabs-manager" component
   */
  var _default = Ember.Component.extend({
    classNames: 'tabs__title',
    classNameBindings: ['isActive:tabs__title_active'],

    /**
     * Property contain name of tab which will be open when user click
     * on that button
     *
     * @property {String|null} name
     */
    name: null,

    /**
     * Property contain ref on "tabs-manager" component
     *
     * @property {Component|null} manager
     */
    manager: null,

    /**
     * @inheritDoc
     */
    willDestroyElement() {
      this.manager.removeTab(this.name);
    },

    /**
     * Property contain button state(active/inactive)
     *
     * @property {Boolean} isActive - indicated what button is active
     * @readonly
     */
    isActive: Ember.computed('manager.active', function () {
      return this.name === this.get('manager.active');
    }).readOnly(),

    /**
     * Observer for watch isActive property
     *
     * @property {Function} _activeObserver
     * @private
     */
    _activeObserver: Ember.observer('isActive', function () {
      if (this.isActive) {
        this.manager.send('moveIndicator', this.$());
      }
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.manager.addTab(this.name);
    },

    click() {
      this.manager.send('select', this.name);
    }

  });

  _exports.default = _default;
});