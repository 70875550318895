define("builder/pods/components/base-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ShqyE44",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"class\",[29,[\"btn __df-icon-lg \",[22,\"styleClass\"],\" \",[22,\"sizeClass\"]]]],[11,\"style\",[28,\"html-format\",[[28,\"concat\",[[24,[\"style\"]],\";\",[24,[\"width\"]]],null]],null]],[11,\"data-aos\",[24,[\"animationData\",\"name\"]]],[11,\"data-aos-duration\",[24,[\"animationData\",\"duration\"]]],[11,\"data-aos-mirror\",[24,[\"animationData\",\"mirror\"]]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"base-button__content base-button__content__\",[23,0,[\"contentLayout\"]]]]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[11,\"class\",[23,0,[\"data\",\"icon\"]]],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[1,[23,0,[\"data\",\"title\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/base-button/template.hbs"
    }
  });

  _exports.default = _default;
});