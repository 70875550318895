define("builder/pods/components/input-search/component", ["exports", "jquery", "builder/core/enumerators/keyboard"], function (_exports, _jquery, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    tagName: 'form',

    /**
     * @inheritDoc
     */
    classNames: ['input-search'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['expanded::__compact'],

    /**
     * Placeholder for search input
     * @type {string}
     */
    placeholder: 'Search...',

    /**
     * Key word to search
     * @type {string}
     */
    searchString: '',

    /**
     * Is input should be focused or not
     * @type {boolean}
     */
    focused: false,

    /**
     * Class name of search input
     * @type {string}
     */
    inputClass: 'input-search__input-field',

    /**
     * Determine whether input search is expanded
     * @type {boolean}
     */
    expanded: true,

    /**
     * Stay expanded input if search query is empty
     * @type {boolean}
     */
    expandedOnEmptySearchQuery: false,

    /**
     * If searchString has some symbols, it's state is dirty
     * @type {boolean}
     * @readonly
     */
    isDirty: Ember.computed('searchString', function () {
      return !!this.searchString.length;
    }).readOnly(),

    /**
     * @inheritDoc
     */
    didRender(...args) {
      this._super(...args);

      (0, _jquery.default)(document).on('keydown.gallery', event => {
        if (event.keyCode === _keyboard.default.KEY.ESCAPE) {
          this.send('clear');
          this.send('blur');
        }
      });
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      if (this.focused) {
        this._setFocus();
      }
    },

    /**
     * @inheritDoc
     */
    willDestroyElement() {
      (0, _jquery.default)(document).off('keydown.gallery');
      this.set('searchString', '');
    },

    /**
     * Callback that will be invoked when user presses a key search form
     * @type {Function}
     */
    onKeyPress() {},

    /**
     * Callback to be invoked when user clears search input field
     * @type {Function}
     */
    onClear() {},

    /**
     * Callback to be invoked when input focus out
     * @type {Function}
     */
    focusOut() {},

    /**
    	 * @inheritdoc
    	 */
    didUpdateAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      if (this.searchString === '') {
        // Used scheduleOnce run loop because observer in controller re rendering multiple time and causing some issues
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.submit();
        });
      }
    },

    /**
     * Handle form submit
     * @returns {boolean}
     */
    submit() {
      this.onKeyPress(this.searchString);
      return false;
    },

    /**
     * Set focus to search input
     * @private
     * @returns {void}
     */
    _setFocus() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        this.$(`.${this.inputClass}`).focus();
      });
    },

    actions: {
      /**
       * KeyPress Actions
       */
      onKeyPress() {
        // TODO Do not submit changes if key is not character (e.g. Enter, arrows, etc.)
        this.submit();
      },

      /**
       * Action on search focus out
       * @param {string} searchQuery - search query
       */
      blur(searchQuery) {
        if (this.expanded) {
          if (!searchQuery && !this.expandedOnEmptySearchQuery) {
            this.set('expanded', false);
          }

          this.focusOut(searchQuery);
        }
      },

      /**
       * Action to expand search
       */
      expand() {
        if (!this.expanded) {
          this.set('expanded', true);

          this._setFocus();
        }
      },

      /**
       * Action to clear search
       */
      clear() {
        this.onClear();
        this.set('searchString', '');

        this._setFocus();
      }

    }
  });

  _exports.default = _default;
});