define("builder/pods/components/color-picker/component", ["exports", "jquery", "ember-spectrum-color-picker/components/spectrum-color-picker"], function (_exports, _jquery, _spectrumColorPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * NOTICE: Don't use "onChange" event in "flat" mode because it's buggy and it can not be canceled with "clickoutFiresChange"
   * 				 because it's buggy too.
   * 				 This event bind on "document" and doesn't clear.
   * 				 Use "onMove" to capture changes.
   */
  var _default = _spectrumColorPicker.default.extend({
    /**
     * Property contain title which showed inside color-picker
     *
     * @property {String|null} title
     */
    title: null,

    /**
     * Spectrum config option
     *
     * @property {Boolean} showInput
     */
    showInput: true,

    /**
     * Spectrum config option
     *
     * @property {Boolean} showAlpha
     */
    showAlpha: true,

    /**
     * Spectrum config option
     *
     * @property {String} preferredFormat
     */
    preferredFormat: 'hex',

    /**
     * Spectrum config option
     *
     * @property {Boolean} showButtons
     */
    showButtons: false,

    /**
     * Spectrum config option
     *
     * @property {String} theme
     */
    theme: 'sp-builder',

    /**
     * Method for Spectrum "show" callback
     *
     * @param {String} color
     * @param {Element} element
     * @param {ColorPicker} context
     * @method onShow
     */
    onShow(color, element, context) {
      let container = (0, _jquery.default)(element).spectrum('container');

      if (!context.get('flatMode')) {
        container = container.find('.sp-picker-container');
      } else {
        container = container.next().find(' > .sp-picker-container');
      }

      if (context.get('title') && !container.find('.sp-title').length) {
        (0, _jquery.default)(`<div class="sp-title">${context.get('title')}</div>`).insertBefore(container.find('.sp-top'));
      }
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this._startPalette();
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      this._destroyPalette(); // Fix for this issue https://github.com/bgrins/spectrum/issues/387


      this._removeHandler();
    },

    /**
     * Method initialize palette
     *
     * @method onShow
     * @private
     */
    _startPalette() {
      const {
        palette
      } = this;
      const opts = {
        theme: this.theme,
        color: this.color,
        flat: this.flatMode,
        allowEmpty: this.allowEmpty,
        disabled: this.disabled,
        showInput: this.showInput,
        showAlpha: this.showAlpha,
        showInitial: this.showInitial,
        showButtons: this.showButtons,
        showPalette: this.showPalette,
        showPaletteOnly: this.showPaletteOnly,
        palette: typeof palette === 'string' ? JSON.parse(palette) : palette,
        togglePaletteOnly: this.togglePaletteOnly,
        showSelectionPalette: this.showSelectionPalette,
        maxSelectionSize: this.maxSelectionSize,
        hideAfterPaletteSelect: this.hideAfterPaletteSelect,
        preferredFormat: this.preferredFormat,
        clickoutFiresChange: this.clickoutFiresChange,
        chooseText: this.chooseText,
        cancelText: this.cancelText,
        togglePaletteMoreText: this.togglePaletteMoreText,
        togglePaletteLessText: this.togglePaletteLessText,
        appendTo: this.appendTo,
        localStorageKey: this.localStorageKey,
        replacerClassName: this.replacerClassName,
        containerClassName: this.containerClassName
      };
      const self = this;

      const updateFunction = function (newColor) {
        const color = newColor ? newColor.toString() : null;
        const onChange = self.get('onChange');

        if (!self.isDestroyed) {
          self.set('color', color);
        }

        if (onChange) {
          onChange(color);
        }
      };

      opts.change = updateFunction;

      if (this.moveFiresChange) {
        opts.move = updateFunction;
      } // Move Event


      const {
        onMove
      } = self;

      if (onMove) {
        opts.move = function (newColor) {
          onMove(newColor ? newColor.toString() : null, this, self);
        };
      } // Hide Event


      const {
        onHide
      } = self;

      if (onHide) {
        opts.hide = function (newColor) {
          onHide(newColor ? newColor.toString() : null, this, self);
        };
      } // Show Event


      const {
        onShow
      } = self;

      if (onShow) {
        opts.show = function (newColor) {
          onShow(newColor ? newColor.toString() : null, this, self);
        };
      } // Initialize spectrum


      (0, _jquery.default)(this.element).spectrum(opts);

      if (!opts.clickoutFiresChange) {
        this._removeHandler();
      } // Dragstop event should be bind after plugin was initialized


      const onDragStop = self.get('onDragStop');

      if (onDragStop) {
        (0, _jquery.default)(this.element).on('dragstop.spectrum', (e, newColor) => {
          onDragStop(newColor ? newColor.toString() : null, this, self);
        });
      }
    },

    /**
     * Method destroy palette
     *
     * @method onShow
     * @private
     */
    _destroyPalette() {
      (0, _jquery.default)(this.element).spectrum('destroy');
    },

    /**
     * Method remove handler
     *
     * @method onShow
     * @private
     */
    _removeHandler() {
      (0, _jquery.default)(document).unbind('click.spectrum');
    }

  });

  _exports.default = _default;
});