define("builder/pods/components/content-editable/component", ["exports", "builder/core/enumerators/keyboard", "jquery"], function (_exports, _keyboard, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Content editable component
   *
   * TODO: handle paste, pasted text should be validated against maxlength
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: 'textarea',

    /**
     * @inheritdoc
     */
    attributeBindings: ['maxlength'],

    /**
     * @property {null|Number} maxlength - max allowed chars
     */
    maxlength: null,

    /**
     * @property {Boolean} blurOnEsc - blur component when esc key pressed
     */
    blurOnEsc: true,

    /**
     * @property {String} content - property passed in to component
     */
    content: '',

    /**
     * @property {String} value - actual div text
     */
    value: '',

    /**
     * @property {Function} onRender - callback to be invoked on didInsertElement
     */
    onRender() {},

    /**
     * @property {Function} onChange - callback to be invoked when component loses focus, emulation of input change event
     */
    onFocus() {},

    /**
     * @property {Function} onBlur - callback to be invoked on focusOut/blur
     */
    onBlur() {},

    /**
     * @property {Function} onKeyUp - callback to be invoked on keyup
     */
    onKeyUp() {},

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('value', this.content);
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.element.value = this.value;
      this.onRender(this.$());
    },

    /**
     * Input event listener
     */
    keyUp() {
      this.onKeyUp(this.$());
    },

    /**
     * Input focus/focusIn listener
     */
    focusIn() {
      this.onFocus(this.$());
    },

    /**
     * Focus out/blur event listener
     */
    focusOut() {
      const {
        value
      } = this.element;
      const lastValue = this.value;
      this.set('value', value); // Only on real content change trigger change

      if (lastValue !== value) {
        this.onChange(value);
      }

      this.onBlur(this.$());
    },

    /**
     * Key down event listener
     *
     * @param {Event} event
     */
    keyDown(event) {
      if (this.blurOnEsc && event.which === _keyboard.default.KEY.ESCAPE) {
        event.stopPropagation();
        (0, _jquery.default)(this.element).blur();
      }
    }

  });

  _exports.default = _default;
});