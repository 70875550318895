define("builder/core/froala/metatags/metatags-popup", ["exports", "froala-editor", "builder/core/froala/metatags/dropdown-vehicles", "builder/core/froala/metatags/dropdown-variables", "builder/core/froala/metatags/dropdown-website", "builder/core/froala/metatags/dropdown-dealership"], function (_exports, _froalaEditor, _dropdownVehicles, _dropdownVariables, _dropdownWebsite, _dropdownDealership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metaPluginName = _exports.metaPopupCommand = void 0;

  /* eslint-disable new-cap */
  const metaPopupCommand = 'metaPopupClick';
  _exports.metaPopupCommand = metaPopupCommand;
  const metaPluginName = 'metaPlugin';
  _exports.metaPluginName = metaPluginName;
  const metaPopupButtonsName = 'metaPopupButtons';
  const metaPopupName = `${metaPluginName}.popup`;
  const metaPopupOpenIconName = 'meta-popup-open-icon';

  _froalaEditor.default.DefineIconTemplate(metaPopupOpenIconName, '<i class="fa fa-code"></i>');

  _froalaEditor.default.DefineIcon(metaPopupCommand, {
    NAME: 'code',
    SVG_KEY: 'codeView'
  });

  _froalaEditor.default.RegisterCommand(metaPopupCommand, {
    title: 'Metatags',
    undo: false,
    focus: false,
    plugin: metaPluginName,

    callback() {
      this[metaPluginName].showPopup();
    }

  }); // Define custom popup close button icon and command.


  const metaPopupCloseIconName = 'meta-popup-close-icon';
  const metaPopupCloseCommand = 'meta-popup-close';

  _froalaEditor.default.DefineIconTemplate(metaPopupCloseIconName, '<i class="fa fa-times"></i>');

  _froalaEditor.default.DefineIcon(metaPopupCloseCommand, {
    NAME: metaPopupCloseIconName,
    template: metaPopupCloseIconName
  });

  _froalaEditor.default.RegisterCommand(metaPopupCloseCommand, {
    title: 'Close',
    undo: false,
    focus: false,

    callback() {
      this[metaPluginName].hidePopup();
    }

  });

  _froalaEditor.default.POPUP_TEMPLATES[metaPopupName] = '[_BUTTONS_]';
  _froalaEditor.default.DEFAULTS = { ..._froalaEditor.default.DEFAULTS,
    [metaPopupButtonsName]: [_dropdownVehicles.vehiclesDropdownCommand, _dropdownVariables.variablesDropdownCommand, _dropdownWebsite.websitesDropdownCommand, _dropdownDealership.dealershipDropdownCommand, metaPopupCloseCommand]
  };

  _froalaEditor.default.PLUGINS[metaPluginName] = function metatagsPopup(editor) {
    function initPopup() {
      var _editor$opts$metaPopu;

      let popupButtons;

      if ((((_editor$opts$metaPopu = editor.opts[metaPopupButtonsName]) === null || _editor$opts$metaPopu === void 0 ? void 0 : _editor$opts$metaPopu.length) || 0) > 1) {
        popupButtons = `
				<div class="fr-buttons">
					${editor.button.buildList(editor.opts[metaPopupButtonsName])}
				</div>
			`;
      } // Load popup template.


      const template = {
        buttons: popupButtons
      }; // Create popup.

      const popup = editor.popups.create(metaPopupName, template);
      return popup;
    }

    function showPopup() {
      // Get the popup object defined above.
      let metaPopup = editor.popups.get(metaPopupName); // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.

      if (!metaPopup) {
        metaPopup = initPopup();
      } // Set the editor toolbar as the popup's container.


      editor.popups.setContainer(metaPopupName, editor.$tb); // This will trigger the refresh event assigned to the popup.
      // editor.popups.refresh('customPlugin.popup');
      // This custom popup is opened by pressing a button from the editor's toolbar.
      // Get the button's object in order to place the popup relative to it.

      const metaButton = editor.$tb.find(`.fr-command[data-cmd="${metaPopupCommand}"]`);

      if (!metaButton) {
        return;
      } // Set the popup's position.


      const left = metaButton.offset().left + metaButton.outerWidth() / 2;
      const top = metaButton.offset().top + (editor.opts.toolbarBottom ? 10 : metaButton.outerHeight() - 10); // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.

      editor.popups.show(metaPopupName, left, top, metaButton.outerHeight());
    }

    function hidePopup() {
      editor.popups.hide(metaPopupName);
    }

    return {
      showPopup,
      hidePopup
    };
  };
});