define("builder/pods/components/undo-redo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/5gGEyMa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"tooltip-external\",null,[[\"text\",\"closingDelayTime\",\"verticalPosition\",\"horizontalPosition\",\"autoWidth\",\"useCorner\"],[\"Undo\",0,\"top\",\"center\",true,true]],{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"undo-redo__undo-button \",[28,\"if\",[[24,[\"undoIsDisabled\"]],\"undo-redo--disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"undo\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"tooltip-external\",null,[[\"text\",\"closingDelayTime\",\"verticalPosition\",\"horizontalPosition\",\"autoWidth\",\"useCorner\"],[\"Redo\",0,\"top\",\"center\",true,true]],{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"undo-redo__redo-button \",[28,\"if\",[[24,[\"redoIsDisabled\"]],\"undo-redo--disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"redo\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/undo-redo/template.hbs"
    }
  });

  _exports.default = _default;
});