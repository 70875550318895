define("builder/pods/components/widget-switcher/component", ["exports", "jquery", "builder/core/enumerators/keyboard", "builder/core/enumerators/engine"], function (_exports, _jquery, _keyboard, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Builder Switcher component. Renders switcher.
   * @class
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['pg-header__m'],

    /**
     * @property {Object[]} allCompanies - array of companies with added object for switcher prompt.
     */
    allCompanies: [],

    /**
     * @property {Object[]} filteredWebsites - filtered for current company websites array.
     */
    filteredWebsites: [],

    /**
     * @property {Object|null} selectedCompany - selected company.
     */
    selectedCompany: null,

    /**
     * @property {Object|null} selectedWebsite - selected website.
     */
    selectedWebsite: null,

    /**
     * @property {String} searchValue - search value for filter companies/websites
     */
    searchValue: '',

    /**
     * @property {Boolean} isCompaniesDropdownOpen - open dropdown with company list or not
     */
    isCompaniesDropdownOpen: false,

    /**
     * @property {Boolean} isWebsitesDropdownOpen - open dropdown with website list or not
     */
    isWebsitesDropdownOpen: false,

    /**
     * @property {String} engine5 - engine5 name
     */
    engine5: _engine.E5,

    /**
     * @property {boolean} noAnyCompanies - determines that no any companies.
     */
    noAnyCompanies: Ember.computed.not('allCompanies.length'),

    /**
     * @property {boolean} noAnyWebsites - determines that no any websites.
     */
    noAnyWebsites: Ember.computed.not('filteredWebsites.length'),

    /**
     * @property {boolean} hasManyCompanies - determines that companies have more than one.
     */
    hasManyCompanies: Ember.computed.gt('allCompanies.length', 1),

    /**
     * @property {boolean} hasManyWebsites - determines that websites have more than one.
     */
    hasManyWebsites: Ember.computed.gt('filteredWebsites.length', 1),

    /**
     * @property {Object[]} companiesList - list with companies after add all category, and filter by search
     */
    companiesList: Ember.computed('isCompaniesDropdownOpen', 'allCompanies', 'searchValue', function () {
      const companies = this.allCompanies;

      if (this.isCompaniesDropdownOpen) {
        const value = this.searchValue.toLowerCase();

        this._resetActiveOptions(companies);

        if (value.length > 1) {
          return companies.filter(item => ~item.name.toLowerCase().indexOf(value));
        }
      }

      return companies;
    }),

    /**
     * @property {Object[]} websitesList - list with websites after filter by category, and filter by search
     */
    websitesList: Ember.computed('isWebsitesDropdownOpen', 'filteredWebsites', 'searchValue', function () {
      const websites = this.filteredWebsites;

      if (this.isWebsitesDropdownOpen) {
        const value = this.searchValue.toLowerCase();

        this._resetActiveOptions(websites);

        if (value.length > 1) {
          return websites.filter(item => ~item.name.toLowerCase().indexOf(value) || ~item.url.toLowerCase().indexOf(value));
        }
      }

      return websites;
    }),

    /**
     * @property {Object|null} _timer - timer for close switcher list when mouse out from him
     * @private
     */
    _timer: null,

    /**
     * It is a dummy method to setCompany action
     *
     * @method setCompany
     */
    setCompany() {
      throw new Ember.Error('setCompany must be assigned');
    },

    /**
     * It is a dummy method to setWebsite action
     *
     * @method setWebsite
     */
    setWebsite() {
      throw new Ember.Error('setWebsite must be assigned');
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this._addEventsHandlers();
    },

    /**
     * @inheritdoc
     */
    willDestroyElement(...args) {
      this._super(...args);

      this._removeEventsHandlers();
    },

    /**
     * Method add events handlers
     *
     * @method _addEventsHandlers
     * @private
     */
    _addEventsHandlers() {
      // Close switchers with leave mouse
      this.$().on('mouseenter', '.switcher__wrapper', () => {
        this._clearTimer();
      }).on('mouseleave', '.switcher__wrapper', () => {
        this.set('_timer', Ember.run.later(this, this._closeDropdowns, 3000));
      }) // Select item in dropdown
      .on('keyup', '.switcher__search', event => {
        this._keyHandler(event);
      }); // Close switchers with click on document

      (0, _jquery.default)(document).on('click.closeSwitcher', event => {
        if ((this.isCompaniesDropdownOpen || this.isWebsitesDropdownOpen) && !event.target.closest('.switcher__wrapper')) {
          this._closeDropdowns();
        }
      });
    },

    /**
     * Method remove events handlers
     *
     * @method _removeEventsHandlers
     * @private
     */
    _removeEventsHandlers() {
      // Close switchers with leave mouse
      this.$().off('mouseenter').off('mouseleave').off('keyup');
      (0, _jquery.default)(document).off('click.closeSwitcher');
    },

    /**
     * Method reset timer for close dropdown
     *
     * @method _clearTimer
     * @private
     */
    _clearTimer() {
      Ember.run.cancel(this._timer);
    },

    /**
     * Method for close dropdowns and reset properties
     *
     * @method _closeDropdowns
     * @private
     */
    _closeDropdowns() {
      this._clearTimer();

      this.set('searchValue', '');
      this.set('isCompaniesDropdownOpen', false);
      this.set('isWebsitesDropdownOpen', false);
    },

    /**
     * Method set focus on search input
     *
     * @method _focusOnSearch
     * @private
     */
    _focusOnSearch() {
      Ember.run.next(this, function () {
        this.$().find('.switcher__search').focus();
      });
    },

    /**
     * Method move select on option in dropdown
     *
     * @method _moveSelect
     * @param {String} direction - direction for move select
     * @private
     */
    _moveSelect(direction) {
      const options = this.isCompaniesDropdownOpen ? this.companiesList : this.websitesList;

      let index = this._findActive(options);

      const {
        length
      } = options;

      if (~index) {
        Ember.set(options[index], 'isActive', false);

        switch (direction) {
          case 'up':
            index--;
            break;

          case 'down':
            index++;
            break;
        }

        if (index >= length) {
          index = 0;
        } else if (index < 0) {
          index = length - 1;
        }
      } else {
        index = 0;
      }

      Ember.set(options[index], 'isActive', true);
    },

    /**
     * Method reset isActive item in list
     *
     * @method _resetActiveOptions
     * @param {Object[]} list - Array with company or website for search
     * @private
     */
    _resetActiveOptions(list) {
      const index = this._findActive(list);

      if (~index) {
        Ember.set(list[index], 'isActive', false);
      }
    },

    /**
     * Method finds active item index
     *
     * @method _findActive
     * @param {Object[]} list - Array with company or website for search
     * @returns {Number} index active item or -1
     * @private
     */
    _findActive(list) {
      return list.indexOf(list.findBy('isActive', true));
    },

    /**
     * Handler for key event
     *
     * @method _keyHandler
     * @param {Event} event - key event
     * @private
     */
    _keyHandler(event) {
      switch (event.keyCode) {
        case _keyboard.default.KEY.DOWN:
          this._moveSelect('down');

          break;

        case _keyboard.default.KEY.UP:
          this._moveSelect('up');

          break;

        case _keyboard.default.KEY.ENTER:
          this._selectActive();

          break;
      }
    },

    /**
     * Method call set website/company method for current active item
     *
     * @method _selectActive
     * @private
     */
    _selectActive() {
      const options = this.isCompaniesDropdownOpen ? this.companiesList : this.websitesList;

      const index = this._findActive(options);

      if (~index) {
        const {
          id
        } = options[index];

        if (this.isCompaniesDropdownOpen) {
          this.setCompany(id);
        } else {
          this.setWebsite(id);
        }

        this._closeDropdowns();
      }
    },

    actions: {
      /**
       * Method open dropdown with companies
       *
       * @method openCompaniesDropdown
       * @param {Event} event - click event
       */
      openCompaniesDropdown(event) {
        if (this.hasManyCompanies) {
          event.stopPropagation();

          this._closeDropdowns();

          this.set('isCompaniesDropdownOpen', true);

          this._focusOnSearch();
        }
      },

      /**
       * Method open dropdown with websites
       *
       * @method openWebsitesDropdown
       * @param {Event} event - click event
       */
      openWebsitesDropdown(event) {
        if (this.hasManyWebsites) {
          event.stopPropagation();

          this._closeDropdowns();

          this.set('isWebsitesDropdownOpen', true);

          this._focusOnSearch();
        }
      },

      /**
       * Method provided set company
       *
       * @method selectCompany
       * @param {String} companyId - company id
       */
      selectCompany(companyId) {
        this._closeDropdowns();

        this.setCompany(companyId);
      },

      /**
       * Method provided set website
       *
       * @method selectWebsite
       * @param {String} websiteId - website id
       */
      selectWebsite(websiteId) {
        this._closeDropdowns();

        this.setWebsite(websiteId);
      }

    }
  });

  _exports.default = _default;
});