define("builder/instance-initializers/misc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    const handler = applicationInstance.lookup('module-configuration-handler:handler');
    handler.addHandler('configuration', applicationInstance.lookup('module-configuration-handler:configuration'));
    handler.addHandler('form-configuration', applicationInstance.lookup('module-configuration-handler:form-configuration'));
  }

  var _default = {
    name: 'misc',
    initialize
  };
  _exports.default = _default;
});