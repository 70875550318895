define("builder/models/menu-root", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "builder/models/menu-child", "builder/models/menu-child-link", "ember-data-model-fragments/attributes"], function (_exports, _model, _fragment, _menuChild, _menuChildLink, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class MenuRoot
   */
  const MenuRoot = _fragment.default.extend({
    /**
     * @property {String} Title - Menu title
     */
    Title: (0, _model.attr)('string'),

    /**
     * @property {String} Type - Menu type
     */
    Type: (0, _model.attr)('string'),

    /**
     * @property {fragmentArray} Structure - menu structure
     */
    Structure: (0, _attributes.fragmentArray)('menu-child'),

    /**
     * @property {fragmentArray} children - Alias to Structure
     */
    children: Ember.computed.alias('Structure', 'Structure.[]'),

    /**
     * Create new menu child item
     * @param {Object} item - New menu child data
     */
    createMenuChild(item) {
      this.Structure.createFragment(item);
    },

    /**
     * Real remove removed items from menu
     */
    removeDeletedItems() {
      const toBeRemovedFragments = this._getRemovedItems();

      for (let i = 0, len = toBeRemovedFragments.length; i < len; i++) {
        toBeRemovedFragments[i].parent.removeFragment(toBeRemovedFragments[i].child);
      }
    },

    /**
     * Clears structure
     */
    clearStructure() {
      this.Structure.clear();
    },

    /**
     * Mark menu item as deleted
     *
     * @method markItemAsDelete
     * @param {Number|String} id - id of menu fragment to need remove
     * @returns {null|MenuChildModel} - null if item is not found otherwise menu item
     */
    markItemAsDelete(id) {
      const result = this._filterByFn(item => item.get('itemId') === id);

      if (result) {
        result.set('isDelete', true);
      }

      return result;
    },

    /**
     * Check and mark menu item as missed if page was removed but menu item is not
     * @param {Object} pagesAliases - Page alias cache object
     * @param {MenuChildModel[]} [menuStructure] - Menu structure
     */
    markMissingPages(pagesAliases, menu) {
      const menuStructure = menu || this.Structure;
      menuStructure.forEach(menuChild => {
        if (menuChild.get('type') === _menuChild.default.PAGE) {
          const linkAlias = menuChild.get('link.value');
          menuChild.set('isMissingPage', !pagesAliases.hasOwnProperty(linkAlias));
        }

        if (menuChild.get('children')) {
          this.markMissingPages(pagesAliases, menuChild.get('children'));
        }
      });
    },

    /**
     * Returns first page in structure
     * @returns {Object}
     */
    getFirstPage() {
      return this._filterByFn(item => item.get('type') === _menuChild.default.PAGE);
    },

    /**
     * Update alias of all items that have reference to old alias
     * @param {String} oldAlias - old alias value to change(used to find items which alias to update)
     * @param {String} newAlias - new alias value to set
     * @returns {MenuChildModel[]}
     */
    updateItemsAlias(oldAlias, newAlias) {
      const items = this._filterByFn(menuItem => menuItem.get('link.value') === oldAlias, false);

      for (let i = 0, len = items.length; i < len; i++) {
        items[i].child.set('link.value', newAlias);
      }

      return items;
    },

    /**
     * Update page title
     * @param {String} pageAlias - page alias which title to update
     * @param {String} title - new title to set
     * @returns {null|MenuChildModel}
     */
    updatePageTitle(pageAlias, title) {
      const page = this.findPageByAlias(pageAlias);

      if (page) {
        page.set('title', title);
      }

      return page;
    },

    /**
     * Add new menu item into structure as first item
     * @param {Fragment} item - Fragment to add
     * @param {Number} [index] - Index to insert. Default is 0
     */
    insertMenuItemAt(item, index = 0) {
      this.Structure.insertAt(index, item);
    },

    /**
     * Add new menu item as last element to structure
     * @param {Fragment} item - Fragment to add
     */
    pushMenuItem(item) {
      this.Structure.pushObject(item);
    },

    /**
     * Find menu item of type page by it's alias
     *
     * @method findPageByAlias
     * @param {String} pageAlias - search page alias
     * @returns {null|MenuChildModel}
     */
    findPageByAlias(pageAlias) {
      return this._filterByFn(menuItem => menuItem.get('type') === _menuChild.default.PAGE && menuItem.get('link.value') === pageAlias);
    },

    /**
     * Finds menu item by alias
     * @param {String} searchAlias - Alias to look for
     * @returns {Object}
     */
    findItemByAlias(searchAlias) {
      return this._filterByFn(item => item.get('link.value') === searchAlias);
    },

    /**
     * Find menu item by it's id (itemId)
     * @param {Number} itemId - itemId of menu item to find
     * @returns {null|MenuChildModel}
     */
    findItemById(itemId) {
      return this._filterByFn(menuItem => menuItem.get('itemId') === itemId);
    },

    /**
     * Finds internal link or folder by alias
     * @param {String} searchAlias - Alias of item to find
     * @returns {MenuChildModel[]}
     */
    findInternalLinksByAlias(searchAlias) {
      const searchFn = menuItem => !menuItem.isDelete && (menuItem.type === _menuChild.default.FOLDER || menuItem.type === _menuChild.default.LINK) && menuItem.link && menuItem.link.type === _menuChildLink.default.INTERNAL && menuItem.link.value === searchAlias;

      return this._filterByFn(searchFn, false).map(x => x.child);
    },

    /**
     * Filter menu items by filter function
     *
     * @method _filterByFn
     * @param {Function} filterFn - function to be used to filter menu items
     * @param {Boolean} singleResult - if set to true, function will be stopped after 1-st found result
     * @returns {null|MenuChildModel|Object[]} - return format is {parent: <parent>, child: <child that fits filter>} or if
     * singleResult set to true, single Fragment will be returned
     * @private
     */
    _filterByFn(filterFn, singleResult = true) {
      const result = this._recursiveFilter(this.Structure || [], filterFn, [], singleResult);

      const childResult = result.length ? result[0].child : null;
      return singleResult ? childResult : result;
    },

    /**
     * Get all menu items stored in structure
     *
     * @method getRemovedItems
     * @returns {MenuChildModel[]}
     * @private
     */
    _getRemovedItems() {
      return this._filterByFn(item => item.get('isDelete'), false);
    },

    /**
     * Filter children by child property and value
     *
     * @method _recursiveFilter
     * @param {fragmentArray} children - array of children
     * @param {Function} filterFn - filter function that will be used to find search items,
     * menu-item will be passed as an argument
     * @param {mixed[]} store - initial state of result
     * @param {Boolean} singleResult - single result search
     * @returns {Object[]} - return format is {parent: <parent>, child: <child that fits filter>}
     * @private
     */
    _recursiveFilter(children, filterFn, store, singleResult) {
      for (let i = 0, length = children.get('length'); i < length; i++) {
        if (singleResult && store.length) {
          break;
        }

        const child = children.objectAt(i);

        if (filterFn(child)) {
          store.push({
            parent: children,
            child
          });
        }

        this._recursiveFilter(child.get('children') || [], filterFn, store, singleResult);
      }

      return store;
    }

  });

  MenuRoot.reopenClass({
    /**
     * @property {String} MAIN - menu type main identifier
     */
    MAIN: 'main',

    /**
     * @property {String} UNLINKED - menu type unlinked menu identifier
     */
    UNLINKED: 'unlinked'
  });
  var _default = MenuRoot;
  _exports.default = _default;
});