define("builder/models/meta-variables", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class MetaVariablesModel
   */
  var _default = _model2.default.extend({
    /**
     * @property {Object[]} Structure - meta variables structure
     */
    Structure: (0, _model.attr)('raw')
  });

  _exports.default = _default;
});