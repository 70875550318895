define("builder/pods/components/fonts-source-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['b2-fonts-source-picker'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['isDragOver:b2-fonts-source-picker--drag-over', 'isInvalidFontUrl:b2-fonts-source-picker--invalid-font-link'],

    /**
     * @property {Boolean} isInvalidFontUrl - is entered url valid for upload font
     */
    isInvalidFontUrl: false,

    /**
     * @property {Function|Null} onSourcesSelected - handler for select sources
     */
    onSourcesSelected: null,

    /**
     * @property {String} acceptType - allow file types to upload
     */
    acceptType: 'application/zip',

    /**
     * @property {Boolean} isDragOver - files is over drop area
     */
    isDragOver: false,

    /**
     * @property {Boolean} isUploadViaFile - font uploader switched layout for upload font via file
     */
    isUploadViaFile: true,

    /**
     * @property {Object} fieldUrl - structure that described UI for enter url
     */
    fieldUrl: Ember.computed(function () {
      return {
        type: 'field-text',
        name: 'fontUrl',
        placeholder: 'Enter exact URL (Don\'t include #...)',
        label: 'Import available only from Google Fonts',
        data: '',
        enableValidationImmediately: false,
        help: 'Enter in a valid Google Font URL here. You may have to get the URL from embed code on the Google Font website.<br>Example: https://fonts.google.com/specimen/Roboto',
        validation: {
          required: true,
          pattern: 'https:\\/\\/fonts.google.com\\/specimen\\/\\w(?!.*#).*',
          message: 'Please add a valid url'
        }
      };
    }).readOnly(),

    /**
     * @property {Boolean} isDisabledButtonImport - button import font via url is disabled or not
     * @readonly
     */
    isDisabledButtonImport: Ember.computed('isInvalidFontUrl', 'fieldUrl.data', function () {
      return this.isInvalidFontUrl || Ember.isEmpty(this.get('fieldUrl.data'));
    }).readOnly(),

    /**
     * @inheritDoc
     */
    dragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      this.set('isDragOver', true);
    },

    /**
     * @inheritDoc
     */
    dragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.set('isDragOver', false);
    },

    /**
     * @inheritDoc
     */
    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.set('isDragOver', false);

      this._selectFiles(event.dataTransfer.files);
    },

    /**
     * Prepare files for call handler "onSourcesSelected"
     * @param {window.File|window.FileList} files - selected files
     * @private
     */
    _selectFiles(files) {
      const filesToUpload = [...files];

      if (typeof this.onSourcesSelected === 'function') {
        this.onSourcesSelected(filesToUpload);
      }
    },

    /**
     * Prepare link for call handler "onSourcesSelected"
     * @param {String} url - url to font
     * @private
     */
    _selectUrl(url) {
      if (typeof this.onSourcesSelected === 'function') {
        this.onSourcesSelected([url]);
      }
    },

    actions: {
      /**
       * Files selected in input component
       * @param {Event} event
       */
      filesSelected(event) {
        this._selectFiles(event.target.files);
      },

      /**
       * Transition view to upload font via link
       */
      uploadViaLink() {
        this.set('isUploadViaFile', false);
      },

      /**
       * Transition view to upload font via file(s)
       */
      uploadViaFile() {
        this.set('fieldUrl.data', '');
        this.set('isUploadViaFile', true);
      },

      /**
       * Import font via link
       */
      importFontViaUrl() {
        this._selectUrl(this.get('fieldUrl.data'));

        this.send('uploadViaFile');
      },

      /**
       * Handle validation result for font url
       * @param {Ember.Component} item - target component
       * @param {Boolean} result - validation result
       */
      fontUrlValidationHandler(item, result) {
        this.set('isInvalidFontUrl', !result);
      }

    }
  });

  _exports.default = _default;
});