define("builder/pods/components/font-loader/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component help to load css with included font.
   * Before try to load font, component detect that font is not exists in OS.
   *
   *
   * Required options:
   *  	font
   *
   * @usage
   *    1) Basic usage
   * 			{{font-loader font=fontModel}}
   *
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: '',

    /**
     * @property {WebsiteFontModel} font - model described font
     */
    font: null,

    /**
     * @property {Boolean} isFontNotExist - Has user this font in system or we should load it from CDN
     * @private
     */
    _isFontNotExist: null,

    /**
     * @inheritdoc
     */
    willRender() {
      this.set('isFontNotExist', !this.isFontExistInSystem());
    },

    /**
     * Method detect that font exist in system.
     *
     * @method isFontExistInSystem
     * @return {Boolean}
     */
    isFontExistInSystem() {
      const monospaceWidth = this.getFontWidth('monospace');
      const serifWidth = this.getFontWidth('serif');
      const sansSerif = this.getFontWidth('sans-serif');
      const font = this.get('font.Name');
      return monospaceWidth !== this.getFontWidth(`${font},monospace`) || serifWidth !== this.getFontWidth(`${font},serif`) || sansSerif !== this.getFontWidth(`${font},sans-serif`);
    },

    /**
     * Method return width of container with passed font family name
     *
     * @param {String} font - font family name
     * @return {Number}
     */
    getFontWidth(font) {
      const div = document.createElement('span');
      const {
        body
      } = document;
      div.innerHTML = new Array(100).join('wim');
      div.style.cssText = ['position:absolute', 'width:auto', 'font-size:128px', 'left:-99999px', `font-family:${font}`, 'font-display: swap'].join(' !important;');
      body.appendChild(div);
      const width = div.clientWidth;
      body.removeChild(div);
      return width;
    }

  });

  _exports.default = _default;
});