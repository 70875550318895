define("builder/pods/components/field-search-text/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "ember-copy", "builder/core/enumerators/keyboard"], function (_exports, _fieldValidation, _fieldVisibility, _emberCopy, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['bld-form_search-text'],

    /**
     * Parent action handler
     * @type {Function}
     */
    actionHandler: null,

    /**
     * Flat go expand/close dropdown
     * @type {boolean}
     */
    isExpanded: false,

    /**
     * Field text settings
     * @type {Object}
     * @readonly
     */
    fieldText: Ember.computed('field.data', 'field.placeholder', function () {
      return {
        name: `search-text-${this.elementId}`,
        data: this.field.data || this.field.placeholder
      };
    }).readOnly(),

    /**
     * Choices is provided or not
     * @type {boolean}
     * @readonly
     */
    isChoicesProvided: Ember.computed('field.choices', function () {
      return !!(this.field.choices && this.field.choices.length);
    }).readOnly(),

    /**
     * List of options
     * @type {Object[]}
     * @readonly
     */
    choices: Ember.computed('field.choices', function () {
      return this.field.choices.map(option => {
        const newOption = (0, _emberCopy.copy)(option, true);
        newOption.visible = true;
        return newOption;
      });
    }).readOnly(),

    /**
     * Available choices to select
     * @type {string[]}
     * @private
     * @readonly
     */
    _availableChoices: Ember.computed('choices.@each.visible', function () {
      return this.choices.filterBy('visible', true);
    }).readOnly(),

    /**
     * Max available choice index
     * @type {number}
     * @private
     * @readonly
     */
    _maxAvailableChoiceIndex: Ember.computed('_availableChoices.[]', function () {
      return Math.max(0, this._availableChoices.length - 1);
    }).readOnly(),

    /**
     * Search query
     * @type {string}
     * @private
     */
    _searchQuery: '',

    /**
     * Closes dropdown
     */
    closeDropdown() {
      this.set('isExpanded', false);
    },

    /**
     * Passes selected value and name to parent action
     * @param {string} value - text field value
     */
    sendValueToParent(value) {
      this.set('fieldText.data', value);

      if (typeof this.actionHandler === 'function') {
        this.actionHandler(value, this.field.name);
      }
    },

    /**
     * @inheritDoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.element.addEventListener('keyup', Ember.run.bind(this, this._onKeyUpHandler));
    },

    /**
     * Element key up handler
     * @param {KeyboardEvent} event - event
     * @private
     */
    _onKeyUpHandler(event) {
      const {
        UP,
        DOWN,
        ENTER
      } = _keyboard.default.KEY;

      if (!this.isChoicesProvided || !this.isExpanded) {
        return;
      }

      switch (event.keyCode) {
        case UP:
          {
            this._selectAboveChoice();

            break;
          }

        case DOWN:
          {
            this._selectBelowChoice();

            break;
          }

        case ENTER:
          {
            this._selectActiveChoice();

            break;
          }
      }
    },

    /**
     * Select active choice
     * @private
     * @returns {void}
     */
    _selectActiveChoice() {
      const activeChoice = this.choices.findBy('active', true);

      if (activeChoice) {
        this.send('selectOption', activeChoice);
      }
    },

    /**
     * Select choice above
     * @private
     * @returns {void}
     */
    _selectAboveChoice() {
      const {
        choices,
        _availableChoices
      } = this;

      const activeChoice = _availableChoices.findBy('active', true);

      const activeChoiceIndex = _availableChoices.indexOf(activeChoice);

      let prevIndex = 0;

      if (activeChoiceIndex !== -1) {
        prevIndex = activeChoiceIndex - 1;
      }

      if (prevIndex < 0) {
        prevIndex = this._maxAvailableChoiceIndex;
      }

      choices.forEach(choice => Ember.set(choice, 'active', false));
      Ember.set(choices[prevIndex], 'active', true);
    },

    /**
     * Select choice below
     * @private
     * @returns {void}
     */
    _selectBelowChoice() {
      const {
        choices,
        _availableChoices
      } = this;

      const activeChoice = _availableChoices.findBy('active', true);

      const activeChoiceIndex = _availableChoices.indexOf(activeChoice);

      let nextIndex = 0;

      if (activeChoiceIndex !== -1) {
        nextIndex = activeChoiceIndex + 1;
      }

      if (nextIndex > this._maxAvailableChoiceIndex) {
        nextIndex = 0;
      }

      choices.forEach(choice => Ember.set(choice, 'active', false));
      Ember.set(choices[nextIndex], 'active', true);
    },

    actions: {
      selectOption(option) {
        this.sendValueToParent(option.value);
        this.closeDropdown();
      },

      search(query) {
        const normalizedQuery = query.toLowerCase();

        if (this._searchQuery === normalizedQuery) {
          return;
        }

        this.set('_searchQuery', normalizedQuery);
        this.choices.forEach(choice => {
          const isMatched = choice.label.toLowerCase().indexOf(normalizedQuery) !== -1;
          Ember.set(choice, 'active', false);
          Ember.set(choice, 'visible', isMatched);
        });
        const firstMatchedChoice = this.choices.findBy('visible', true);

        if (firstMatchedChoice) {
          Ember.set(firstMatchedChoice, 'active', true);
        }
      },

      showFullList() {
        this.choices.forEach(choice => {
          Ember.set(choice, 'active', false);
          Ember.set(choice, 'visible', true);
        });
      },

      onChangeDropdownState(expanded) {
        if (expanded) {
          this.send('showFullList');
        }
      },

      onTextChangeHandler(value) {
        this.sendValueToParent(value);
        this.closeDropdown();
      }

    }
  });

  _exports.default = _default;
});