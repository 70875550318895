define("builder/pods/components/core-module/component", ["exports", "jquery", "builder/pods/components/grid-base-component/component", "builder/config/environment", "builder/core/enumerators/module-action", "builder/core/enumerators/system-aliases"], function (_exports, _jquery, _component, _environment, _moduleAction, _systemAliases) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember module component.
   */
  var _default = _component.default.extend({
    /**
     * @inheritDoc
     */
    classNames: ['js-module', 'page_module'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['hideModuleOnPage:__hidden'],

    /**
     * Data of current module
     * @type {object}
     */
    data: null,

    /**
     * Environment config
     * @type {object}
     */
    environment: _environment.default,

    /**
     * Flag to indicate whether error is occurred
     * @type {boolean}
     */
    error: false,

    /**
     * Track auto open config
     * @type {boolean}
     */
    autoOpen: false,

    /**
     * Iframe url to core-module preview
     * @type {string}
     */
    serviceUrl: '',

    /**
     * Compiled css for iframe. Passed from controller.
     * @type {null|string}
     */
    iframeCSS: null,

    /**
     * Full css width property for iframe
     * @type {string}
     * @readonly
     */
    iframeWidthCSS: Ember.computed('_iframeWidth', function () {
      return this._iframeWidth ? Ember.String.htmlSafe(`width: ${this._iframeWidth}px;`) : Ember.String.htmlSafe('');
    }).readOnly(),

    /**
     * Proxy used for dependencyModules.@each.moduleStyleModel.{global,lg,md,sm,xs}
     * @type {ModuleStyleModel[]}
     */
    dependencyModulesCSSProxy: Ember.computed('dependencyModules', 'dependencyModules.[]', 'dependencyModules.@each.moduleStyleModel', function () {
      const nodes = this.dependencyModules;
      const modules = [];

      for (let i = 0, len = nodes.length; i < len; i++) {
        // Since module styles provided later
        if (nodes[i].moduleStyleModel) {
          modules.pushObject(nodes[i].moduleStyleModel);
        }
      }

      return modules;
    }),

    /**
     * Dependency modules styles
     * @type {string}
     */
    dependencyModuleCSS: Ember.computed('dependencyModulesCSSProxy', 'dependencyModulesCSSProxy.[]', 'dependencyModulesCSSProxy.@each.localStyleCSS', function () {
      const styles = this.dependencyModulesCSSProxy;
      return styles.reduce((aggregator, style) => aggregator + style.localStyleCSS, '');
    }),

    /**
     * Module style
     * @type {ModuleStyleModel}
     */
    moduleStyleModel: Ember.computed.alias('data.moduleStyleModel'),

    /**
     * Base grid models, current module depends on
     * @type {BaseGridModel[]}
     */
    dependencyModules: Ember.computed.alias('data.dependencyModules'),

    /**
     * Current module css
     * @type {string}
     */
    moduleCSS: Ember.computed('moduleStyleModel.localStyleCSS', function () {
      var _this$moduleStyleMode;

      return ((_this$moduleStyleMode = this.moduleStyleModel) === null || _this$moduleStyleMode === void 0 ? void 0 : _this$moduleStyleMode.localStyleCSS) || '';
    }),

    /**
     * @type {FontIconModel | null}
     */
    moduleIconLibrary: Ember.computed('fontIconService.currentIconLibrary', function () {
      return this.fontIconService.currentIconLibrary;
    }),

    /**
     * Provide module CSS
     */
    moduleCSSProvider: Ember.observer('moduleStyleModel', 'moduleStyleModel.localStyleCSS', 'configurationService.currentDevice.breakpoint', 'dependencyModuleCSS', function () {
      Ember.run.once(this, () => {
        if (this._moduleStyle) {
          this._provideModuleCSS();

          this.set('data.refresh', Date.now());
        }
      });
    }),

    /**
     * Preview service
     * @type {PreviewService}
     */
    previewService: Ember.inject.service('preview'),

    /**
     * Animation Service
     * @type {AnimationService}
     */
    animationService: Ember.inject.service('animation'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Configuration Styles service
     * @type {CssCustomPropertiesService}
     */
    cssCustomPropertiesService: Ember.inject.service('css-custom-properties'),

    /**
     * Store service
     * @type {StoreService}
     */
    storeService: Ember.inject.service('store'),

    /**
     * Font icon service
     * @type {FontIconService}
     */
    fontIconService: Ember.inject.service('font-icon'),

    /**
     * Hide module on page
     * @type {boolean}
     * @readonly
     */
    hideModuleOnPage: Ember.computed('configurationService.page.Alias', 'data.module.Name', function () {
      var _this$data, _this$data$module, _this$configurationSe, _this$configurationSe2;

      const hideModulesOnIndexPage = this.environment.APP.HIDE_MODULES_ON_INDEX_PAGE || [];
      const moduleName = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$module = _this$data.module) === null || _this$data$module === void 0 ? void 0 : _this$data$module.Name;
      const pageAlias = (_this$configurationSe = this.configurationService) === null || _this$configurationSe === void 0 ? void 0 : (_this$configurationSe2 = _this$configurationSe.page) === null || _this$configurationSe2 === void 0 ? void 0 : _this$configurationSe2.Alias;
      return pageAlias === _systemAliases.HOME_PAGE_ALIAS && hideModulesOnIndexPage.includes(moduleName);
    }).readOnly(),

    /**
     * Module container wrapper width
     * @type {number}
     */
    containerWidth: 0,

    /**
     * Contain ref on styles tag which added to iframe
     * @type {null|HTMLStyleElement}
     * @private
     */
    _style: null,

    /**
     * Contain ref on module styles tag which added to iframe
     * @type {null|HTMLStyleElement}
     * @private
     */
    _moduleStyle: null,

    /**
     * Contain refs on link tags which added to iframe
     * @type {null|HTMLLinkElement[]}
     * @private
     */
    _fonts: null,

    /**
     * Window ref to iframe
     * @type {null|Window}
     * @private
     */
    _iframeWindow: null,

    /**
     * Wrapper of iframe
     * @type {null|HTMLElement}
     * @private
     */
    _iframeWrapper: null,

    /**
     * Contain ref to iframe content
     * @type {null|Document}
     * @private
     */
    _iframeContent: null,

    /**
     * This property show load iframe or not. Because if iframe load< event 'load' not fire.
     * @type {boolean}
     * @private
     */
    _isLoadIframe: false,

    /**
     * Contain width value for iframe
     * @type {null|string}
     * @private
     */
    _iframeWidth: null,

    /**
     * Timer for adjust module width/height
     * @type {object}
     * @private
     */
    _refreshTimer: null,

    /**
     * Timer for resize module width/height
     * @type {object}
     * @private
     */
    _resizeTimer: null,

    /**
     * Observer for refresh module
     * @type {Function}
     * @private
     */
    _refreshObserver: Ember.observer('data.refresh', function () {
      if (this._refreshTimer) {
        Ember.run.cancel(this._refreshTimer);
      }

      if (this._iframeContent) {
        this.set('_refreshTimer', Ember.run.later(this, () => {
          // TODO: remove this side effect. We should not set anything on data.
          this.data.set('isLoaded', false);

          if (this._iframeContent && !this.isDestroyed) {
            this._adjustModuleWidth();

            this._adjustModuleHeight();
          }
        }, 500));
      }
    }),

    /**
     * Observer for reinit iframe url
     * @type {Function}
     * @private
     */
    _iframeUrlInitializerObserver: Ember.observer('data.originObject.id', 'data.reload', function () {
      this._iframeUrlInitializer();
    }),

    /**
     * Define url for iframe
     * @private
     */
    _iframeUrlInitializer() {
      // We should remove links to DOM elements which are not exists any more after iframe reload
      // because when IE and MsEdge trying to get or set these properties they throw error "Permission denied"
      // And reset iframeWidth and module wrapper width, for correct calculate e6 module width inside iframe
      this.set('_iframeWindow', null);
      this.set('_iframeWidth', null);
      this.set('_style', null);
      this.set('_fonts', null);
      this.set('error', false);

      if (this._iframeContent) {
        this._iframeContent.querySelector('.module-main-wrapper').style.width = '';
        this.set('_iframeContent', null);
      }

      Ember.run.once(this, () => {
        var _this$data2, _this$data2$originObj, _this$data3;

        if (((_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : _this$data2$originObj.id) && ((_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : _this$data3.isEnabled)) {
          var _this$data4, _this$data4$originObj;

          const previewUrlParams = {
            moduleId: (_this$data4 = this.data) === null || _this$data4 === void 0 ? void 0 : (_this$data4$originObj = _this$data4.originObject) === null || _this$data4$originObj === void 0 ? void 0 : _this$data4$originObj.id
          };
          let newSrc = '';

          if (this.data.reload) {
            var _this$data5;

            previewUrlParams.reload = (_this$data5 = this.data) === null || _this$data5 === void 0 ? void 0 : _this$data5.reload;
          }

          newSrc = this.previewService.previewUrl(previewUrlParams);

          if (this.serviceUrl !== newSrc) {
            this.set('serviceUrl', newSrc);
          }
        }
      });
    },

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args);

      this._iframeUrlInitializer();

      this.configurationService.device.on('onChange', this, this.provideIframeDims);
    },

    /**
     * @inheritDoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      if (this._iframeContent) {
        this._provideFonts();

        this._provideIcons();

        if (this._style) {
          this._provideCSS(); // After we update css in iframe we should update it's width and height


          this.set('data.refresh', Date.now());
        }
      }
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      if (this.serviceUrl) {
        this.handleIframe();
      }

      this._addResizeHandler();

      this.initializeResizeObserver();
    },

    /**
     * @inheritDoc
     */
    didRender(...args) {
      this._super(...args);

      if (!this.data.isEnabled) {
        this.moduleAction(_moduleAction.default.LOADED, this.data);
      } else if (this.serviceUrl && !this._isLoadIframe) {
        this.handleIframe();
      }
    },

    /**
     * @inheritDoc
     */
    willDestroyElement(...args) {
      this._super(...args);

      this.configurationService.device.off('onChange', this, this.provideIframeDims);

      this._removeResizeHandler();

      Ember.run.cancel(this._refreshTimer);
      Ember.run.cancel(this._resizeTimer);
    },

    /**
     * Double click handler
     * @returns {object}
     */
    doubleClick(...args) {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return this._super(...args);
    },

    /**
     * Activate module
     * @returns {object}
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    },

    /**
     * Method which provide width depend on simulated device 'viewport' for iframe and reload it
     * to get correct dimensions, and add or remove handler for adjust core-module width
     * @returns {void}
     */
    provideIframeDims() {
      this.set('data.refresh', Date.now());

      if (this.configurationService.device.isDesktop()) {
        this._addResizeHandler();
      } else {
        this._removeResizeHandler();
      }
    },

    /**
     *	Adds global styles and module styles to iframe document
     * @param {Document} iframeDocument
     * @returns {void}
     */
    _attachStyleBlocks(iframeDocument) {
      var _iframeDocument$query, _linkElements$item;

      // Create styles containers
      this.set('_style', this._makeStyleContainer());
      this.set('_moduleStyle', this._makeStyleContainer(`module${this.data.originObject.id}`)); // Add styles container to iframe
      // const lastLinkElement = 'link:last-child';

      const shadowHtmlSelector = '.shadow-html';
      const styleParent = ((_iframeDocument$query = iframeDocument.querySelector(shadowHtmlSelector)) === null || _iframeDocument$query === void 0 ? void 0 : _iframeDocument$query.shadowRoot) || iframeDocument.body;

      if (!styleParent) {
        return;
      }

      const linkElements = styleParent.querySelectorAll('link');
      const stylesAnchor = ((_linkElements$item = linkElements.item(linkElements.length - 1)) === null || _linkElements$item === void 0 ? void 0 : _linkElements$item.nextSibling) || styleParent.firstChild;
      styleParent.insertBefore(this._style, stylesAnchor);
      styleParent.insertBefore(this._moduleStyle, this._style.nextSibling); // Provide font

      this._provideFonts(true);

      this._provideIcons(); // Pass styles to iframe


      this._provideCSS(); // Pass module css to iframe


      this._provideModuleCSS();
    },

    /**
     *
     * @param {HTMLIFrameElement} $iframe - loaded iframe
     */
    _onIframeLoad($iframe) {
      /*
       Try to detect that we don't receive fatal error when
       try to get iframe content
       */
      try {
        const iframeWindow = $iframe.contentWindow;
        const iframeDocument = $iframe.contentDocument || iframeWindow.document;
        this.set('_iframeContent', iframeDocument);
        this.set('_iframeWindow', iframeWindow); // Fix for Safari, cuz it does not throw error when we try to get empty document in iframe

        if (!iframeDocument) {
          throw new Ember.Error('iframe is empty');
        }

        this.$().find('.page_module_control_item.__disabled').removeClass('__disabled');

        this._attachStyleBlocks(iframeDocument);

        this.set('data.refresh', Date.now());
        this.set('error', false);

        if (this.data.autoOpen) {
          this.moduleAction(_moduleAction.default.EDIT, this.data);
        }
      } catch (error) {
        this.set('error', true);
        this.moduleAction(_moduleAction.default.LOADED, this.data); // Unlock delete button

        this.$().find('.js-module-remove').removeClass('__disabled');
      }

      this.updateAllDimensions();

      if (this.data.autoOpen) {
        this.set('data.autoOpen', false);
      }

      this.set('_isLoadIframe', true);
      this.updateAnimation();
    },

    /**
     * Refresh AOS on load of iframes in a page to update the scroll positions
     */
    updateAnimation() {
      Ember.run.later(this, () => {
        this.animationService.refreshAOS();
      }, 1000);
    },

    /**
     * Handles module iframe
     * @returns {void}
     */
    handleIframe() {
      const $view = this.$();
      const $iframe = $view.find('.js-module-frame');
      this.data.set('isLoaded', false);
      this.set('_iframeWrapper', $iframe.closest('.iframe-wrapper'));

      if (!$iframe.length) {
        this.moduleAction('loaded', this.data);
      }

      $iframe.off('load').on('load', () => {
        this._onIframeLoad($iframe[0]);
      });
    },

    /**
     * Since module content has iframe, this method will adjust core-module height
     * @returns {void}
     * @private
     */
    _adjustModuleHeight() {
      const iframeContent = this._iframeContent;
      const moduleWrapper = iframeContent.querySelector('.module-main-wrapper');

      if (moduleWrapper) {
        const $wrapper = this._iframeWrapper;
        const moduleContainer = moduleWrapper.querySelector('.module-wrapper');

        if (moduleContainer) {
          moduleContainer.style.marginBottom = 0;
        }

        const moduleHtml = iframeContent.querySelector('html'); // It's very important fix for height to get correct height.
        // Overflow hidden needs for ie11 fix for hiding horizontal scrollbar

        moduleHtml.style.height = 'auto';
        moduleHtml.style.overflow = 'hidden';
        iframeContent.body.style.height = 'auto'; // Important thing to help determine correct iframe content height

        $wrapper.css({
          height: 'auto'
        }); // We have to wait until some jQuery plugins
        // will change the width of module and
        // container height will be changed too.

        Ember.run.later(this, () => {
          $wrapper.css({
            height: `${moduleHtml.scrollHeight}px`,
            visibility: 'visible'
          });
          this.moduleAction(_moduleAction.default.LOADED, this.data);
        }, 500);
      }
    },

    /**
     * Since module content has iframe, this method will adjust core-module width
     * @returns {void}
     * @private
     */
    _adjustModuleWidth() {
      const moduleWrapper = this._iframeContent.querySelector('.module-main-wrapper');

      if (moduleWrapper) {
        const wrapperWidth = Math.floor(this._iframeWrapper.width());
        const iframeWindow = this._iframeWindow;
        let originalDeviceWidth = this.configurationService.device.getDevice().width;
        moduleWrapper.style.width = `${wrapperWidth}px`; // Generate resize for e6, increment need for change iframe width on devices

        this.set('_iframeWidth', ++originalDeviceWidth);
        iframeWindow.$(iframeWindow).trigger('resize');
      }
    },

    /**
     * Add Resize handler for adjust core-module width
     * @returns {void}
     * @private
     */
    _addResizeHandler() {
      this._removeResizeHandler();

      (0, _jquery.default)(window).on(`resize.coreModule${this.data.originObject.id}`, Ember.run.bind(this, function () {
        if (!this.isDestroyed) {
          this.set('_resizeTimer', Ember.run.debounce(this, this._coreModuleResize, 300));
        }
      }));
    },

    /**
     * Handler for adjust core-module width
     * @returns {void}
     * @private
     */
    _coreModuleResize() {
      if (!this.isDestroyed) {
        this.set('data.refresh', Date.now());
      }
    },

    /**
     * Remove Resize handler for adjust core-module width
     * @returns {void}
     * @private
     */
    _removeResizeHandler() {
      (0, _jquery.default)(window).off(`resize.coreModule${this.data.originObject.id}`);
    },

    /**
     * Method create styles tag
     * @param {string} [id=#styles-management] - string for tag id attribute
     * @returns {Element}
     * @private
     */
    _makeStyleContainer(id = '#styles-management') {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = id;
      return style;
    },

    /**
     * Method create <link> tag with defined "id" attribute
     * @param {string} href - url where load font
     * @param {string | null} customId - custom tag element id
     * @returns {HTMLLinkElement}
     * @private
     */
    _makeLinkDOMNode(href, customId = null) {
      const linkElement = document.createElement('link');
      linkElement.id = customId !== null && customId !== void 0 ? customId : 'builder2-font';
      linkElement.type = 'text/css';
      linkElement.rel = 'stylesheet';
      linkElement.href = href + "?&display=swap";
      return linkElement;
    },

    /**
     * Add builder module css string to iframe
     * @returns {void}
     * @private
     */
    _provideModuleCSS() {
      this._moduleStyle.innerHTML = `${this.dependencyModuleCSS} ${this.moduleCSS}`;
    },

    /**
     * Method update styles in iframe
     * @returns {void}
     * @private
     */
    _provideCSS() {
      var _this$data$theme;

      let globalStyles = '';

      if ((_this$data$theme = this.data.theme) === null || _this$data$theme === void 0 ? void 0 : _this$data$theme.hasCSSCustomProperties) {
        var _this$globalStylesMod;

        globalStyles = ((_this$globalStylesMod = this.globalStylesModel) === null || _this$globalStylesMod === void 0 ? void 0 : _this$globalStylesMod.localStyleCustomCSS) || '';
      } else {
        var _this$globalStylesMod2;

        globalStyles = ((_this$globalStylesMod2 = this.globalStylesModel) === null || _this$globalStylesMod2 === void 0 ? void 0 : _this$globalStylesMod2.localStyleCSS) || '';
      }

      this._style.innerHTML = globalStyles;
    },

    /**
     * Method provide <link> tag into iframe for loading font.
     * This method only once create tag and on subsequence re-renders change only "href" attribute.
     * @param {boolean} isReload - force recreate "link" node. Helpful when iframe was reload.
     * @returns {void}
     * @private
     */
    _provideFonts(isReload = false) {
      const fontsRecords = this.storeService.peekAll('website-font');
      const variablesRecords = this.storeService.peekRecord('website-style', this.configurationService.website.id);

      if (fontsRecords && variablesRecords) {
        const fonts = _environment.default.APP.GLOBAL_FONT_VARIABLES.map(variableName => {
          const fontVariable = variablesRecords.global.findBy('name', variableName);
          const fontRecord = fontVariable ? fontsRecords.findBy('Name', fontVariable.value) : null;
          let font = null;

          if (fontRecord) {
            font = {
              variable: variableName,
              url: fontRecord.Url
            };
          }

          return font;
        }).filter(font => !!font);

        fonts.forEach(font => {
          const fontNodes = this.get_fonts || {};
          let fontNode = fontNodes[font.variable];

          if (!fontNode || isReload) {
            const iframeContent = this._iframeContent;
            fontNode = this._makeLinkDOMNode(font.url);
            fontNodes[font.variable] = fontNode;
            iframeContent.querySelector('body').insertBefore(fontNode, iframeContent.querySelector('body > *:first-child'));
          } else if (fontNode) {
            fontNode.href = font.url;
          }

          this.set('_fonts', fontNodes);
        });
      }
    },

    /**
     * Method provides <link> tag into iframe for loading icon library.
     * @returns {void}
     * @private
     */
    _provideIcons() {
      const iconFont = this.moduleIconLibrary;

      if (!iconFont) {
        return;
      }

      const iconlibraryUrl = `${_environment.default.APP.BASE_ICONS_URI}${iconFont.CSSFile}`;
      const iframeContent = this._iframeContent;

      const node = this._makeLinkDOMNode(iconlibraryUrl, 'builder2-icons');

      iframeContent.querySelector('body').append(node);
    }

  });

  _exports.default = _default;
});