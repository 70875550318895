define("builder/pods/components/widget-field-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const minTopOffset = 40;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_info'],

    /**
     * @property {Boolean} topPosition - determine that label show in top
     */
    topPosition: Ember.computed(function () {
      const {
        element
      } = this;
      return element && element.offsetTop < minTopOffset;
    }),

    /**
     * @property {Boolean} isShowLabel - determine show label or not
     */
    isShowLabel: Ember.computed.or('field.{label,help}')
  });

  _exports.default = _default;
});