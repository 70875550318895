define("builder/services/structure", ["exports", "builder/core/enumerators/container", "builder/core/cleaners/row", "builder/core/cleaners/column", "builder/core/cleaners/empty", "builder/core/enumerators/module", "builder/core/enumerators/widget", "ember-copy", "builder/core/enumerators/breakpoint-modes", "builder/core/page-structure-copier", "uid"], function (_exports, _container, _row, _column, _empty, _module2, _widget, _emberCopy, _breakpointModes, _pageStructureCopier, _uid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CONTAINERS = [_container.default.TOP_CONTAINER, _container.default.WIDGET_HEADER, _container.default.WIDGET_MAIN, _container.default.WIDGET_FOOTER, _container.default.WIDGET_CONTAINER, _widget.WidgetType.WIDGET_SECTION, _widget.WidgetType.WIDGET_TAB_PANE, _container.default.WIDGET_ROW, _container.default.WIDGET_COLUMN, _container.default.WIDGET_TABS];
  /**
   * Class used for moving children around, from 1 parent to another and removing children from it's parent
   * TODO: bootstrap service should be moved from here, this is the unit service
   */

  let StructureService = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('bootstrap'), _dec3 = Ember.inject.service('grid'), _dec4 = Ember.inject.service('grid-builder'), _dec5 = Ember.inject.service('drag-and-drop'), _dec6 = Ember.inject.service('configuration'), _dec7 = Ember.inject.service('grid-order'), _dec8 = Ember.computed.readOnly('_pageViewModel'), (_class = (_temp = class StructureService extends Ember.Service {
    /**
     * Models Store
     * @type {import('ember-data').DS.Store}
     */

    /**
     * bootstrap service
     * @type {Service}
     */

    /**
     * grid service
     * @type {GridService}
     * @private
     */

    /**
     * grid-builder instance
     * @type {Service}
     * @type {GridBuilderService}
     */

    /**
     * drag-and-drop instance
     * @type {Service}
     */

    /**
     * Configuration service
     * @type {ConfigurationService}
     */

    /**
     * grid-order service
     * @type {GridOrderService}
     */

    /**
     * Registered cleaners
     * @type {object[]}
     * @private
     */

    /**
     * List of parents to clean up
     * @type {object[]}
     * @private
     */

    /**
     * Is transaction active or not
     * @type {boolean}
     * @private
     */

    /**
     * Current page view model
     */

    /**
     * Structure of current page
     * @type {object | null} _pageStructure -
     */

    /**
     * @inheritdoc
     */
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "bootstrap", _descriptor2, this);

      _initializerDefineProperty(this, "_gridService", _descriptor3, this);

      _initializerDefineProperty(this, "_gridBuilder", _descriptor4, this);

      _initializerDefineProperty(this, "dragAndDropService", _descriptor5, this);

      _initializerDefineProperty(this, "configuration", _descriptor6, this);

      _initializerDefineProperty(this, "order", _descriptor7, this);

      this._cleaners = null;
      this._parents = null;
      this._transactionActive = false;
      this._pageViewModel = null;

      _initializerDefineProperty(this, "pageViewModel", _descriptor8, this);

      this.set('_cleaners', []);
      this.set('_parents', []);

      this._setupCleaners();
    }
    /**
     * Initializes page view model
     * @param {PageModel} page - page model
     */


    initializeViewModel(page) {
      const layouts = this.store.peekAll('website-layout');
      const layout = layouts.find(record => record.Name === page.WebsiteLayoutName);

      if (!layout) {
        throw new Ember.Error('Layout not found');
      }

      const structure = (0, _emberCopy.copy)(layout.Structure, true);
      const widgetMain = structure.findBy('type', _container.default.WIDGET_MAIN);
      widgetMain.children = (0, _emberCopy.copy)(page.Structure, true);
      const serializer = this.store.serializerFor('page');
      const normalizedStructure = serializer.normalizeStructure(structure);

      const pageViewModel = _pageStructureCopier.default.configure(this._gridBuilder, normalizedStructure);

      this._gridService.initStructure(pageViewModel);

      this.set('_pageViewModel', pageViewModel);

      this._lockLayout(layout);
    }
    /**
     * Lock header and footer
     * @param {Object} structure
     * @param {Object} model
     * @private
     */


    _lockLayout(layout) {
      if (!layout.IsLayoutLocked) {
        return;
      }

      const header = this.pageViewModel.originObject.children.find(item => item.originObject.type === _container.default.WIDGET_HEADER);
      const footer = this.pageViewModel.originObject.children.find(item => item.originObject.type === _container.default.WIDGET_FOOTER);
      header.set('droppable', false);
      footer.set('droppable', false);
    }
    /**
     * Build breadth first array
     *
     * @param {EmberObject} container - container as root
     * @returns {EmberObject}
     */


    buildBFArray(container) {
      const stack = [];
      let children = [container];

      while (children.length) {
        const grandChildren = children.map(child => child.get('originObject.children') || []);
        children = [];
        grandChildren.forEach(grandChild => {
          if (grandChild.length) {
            stack.push(grandChild);
          }

          children = children.concat(grandChild);
        });
      }

      return stack;
    }
    /**
     * Register cleaners
     * @private
     * @returns {void}
     */


    _setupCleaners() {
      const cleaners = this._cleaners;
      const cleanerInstances = [_empty.default.create(), _column.default.create(), _row.default.create()];

      for (let i = 0, len = cleanerInstances.length; i < len; i++) {
        cleanerInstances[i].set('gridBuilder', this._gridBuilder);
        cleaners.push(cleanerInstances[i]);
      }
    }
    /**
     * Start transaction, poor functionality for now, but in future,
     * could be used to rollback changes
     * @returns {void}
     */


    startTransaction() {
      if (this._transactionActive || this._parents.length) {
        throw new Ember.Error('Previous transaction is not completed properly');
      }

      this.set('_transactionActive', true);
      this.set('_parents', []);
    }
    /**
     * End transaction, see startTransaction method description
     * @returns {void}
     */


    commitTransaction() {
      for (let i = 0; i < this._parents.length; i++) {
        this._doCleanup(this._parents[i]);
      }

      this.set('_parents', []);
      this.set('_transactionActive', false);
    }
    /**
     * Finds closest parent by type
     * @param {object} node - Node object to find parent
     * @param {string} type - Type of node to find
     * @returns {null | object}
     */


    findClosest(node, type) {
      if (!node) {
        return null;
      }

      let {
        parent
      } = node;
      let result = null;

      while (parent) {
        if (parent.originObject.type === type) {
          result = parent;
          break;
        }

        const currentParent = parent.parent;
        parent = currentParent;
      }

      return result;
    }
    /**
     * Returns children of element
     * @param {object} element - Structure element object
     * @returns {object[]}
     */


    getChildren(element) {
      return element.originObject.children || [];
    }
    /**
     * Sets children for element
     * @param {object} element - Structure element object
     * @param {object[]} children - Array of children
     * @returns {void}
     */


    setChildren(element, children) {
      element.set('originObject.children', children);
    }
    /**
     * Sets parent for child
     * @param {object} child - Child object
     * @param {object} parent - Parent object
     * @returns {void}
     */


    setParent(child, parent) {
      child.set('parent', parent);
    }
    /**
     * Returns element parent
     * @param {object} element - element to get parent
     * @returns {object}
     */


    getParent(element) {
      return element.parent;
    }
    /**
     * Returns element type
     * @param {object} element - Element to get type
     * @returns {string | null}
     */


    getType(element) {
      if (element) {
        return element.originObject && element.originObject.type || element.type;
      }

      return null;
    }
    /**
     * Checks element type to passed type
     * @param {object} element - Structure element object
     * @param {string} type - Type to compare
     * @returns {boolean}
     */


    isType(element, type) {
      return this.getType(element) === type;
    }
    /**
     * Determines whether passed type is "container" type
     * @param {EmberObject} element - Element to check type
     * @returns {boolean}
     */


    isContainerType(element) {
      return CONTAINERS.indexOf(this.getType(element)) !== -1;
    }
    /**
     * Determines whether element has children
     * @param {object} element - Structure element
     * @returns {boolean}
     */


    isContainerWidget(element) {
      const {
        originObject
      } = element;
      return Ember.isArray(originObject.children) && Object.values(_container.default).includes(originObject.type);
    }
    /**
     * Checks whether structure item is fully created, has origin object and component.
     * E.g. new modules added from gallery are not resolved right away
     * @param {object} item - Page structure item
     * @returns {boolean}
     */


    isResolved(item) {
      return Boolean(item.originObject && item.component);
    }
    /**
     * Finds module by id
     * @param {object} structure - Copy of page structure
     * @param {string | number} id - Module id
     * @returns {object | null} Module object
     *
     * TODO: divide this method into 2 methods.
     * First - find in provided structure.
     * Second - find by id globally
     */


    findModuleById(structure, id) {
      let structureTree = structure;
      let moduleId = id; // if only module id is provided we need to search inside whole page structure

      if (typeof structure === 'number' || typeof structure === 'string') {
        moduleId = structure;
        structureTree = this.pageViewModel;
      }

      const children = structureTree.originObject.children || [];
      const originObjectType = structureTree.originObject.type;
      const originObjectId = structureTree.originObject.id;

      if ((originObjectType === _module2.default.MODULE_CORE || originObjectType === _module2.default.MODULE_FORM || originObjectType === _module2.default.MODULE_SYSTEM) && originObjectId === parseInt(moduleId, 10)) {
        return structureTree;
      }

      for (let i = 0; i < children.length; i++) {
        const module = this.findModuleById(children[i], moduleId);

        if (module) {
          return module;
        }
      }

      return null;
    }
    /**
     * Find drag and drop node, by original structure node
     * TODO: find a better way of finding node in drag and drop and remove this method
     *
     * @param {object} node - drag and drop node reference to search in
     * @param {object} originalNode - original structure node reference
     * @returns {object | null} - drag and drop node reference or null if not found
     */


    findNode(node, originalNode) {
      const {
        children
      } = node.originObject;

      if (originalNode === node) {
        return node;
      }

      if (!children || !children.length) {
        return null;
      }

      for (let i = 0; i < children.length; i++) {
        const foundNode = this.findNode(children[i], originalNode);

        if (foundNode) {
          return foundNode;
        }
      }

      return null;
    }
    /**
     *
     * @param {object} structureNode
     * @param {object} node
     * @returns {object}
     */


    findByNode(structureNode, node) {
      const {
        children
      } = structureNode.originObject; // Since $($0) !== $($0) => we should compare nodes directly

      if (structureNode.component.$()[0] === node[0]) {
        return structureNode;
      }

      if (!children || !children.length) {
        return null;
      }

      for (let i = 0; i < children.length; i++) {
        const foundNode = this.findByNode(children[i], node);

        if (foundNode) {
          return foundNode;
        }
      }

      return null;
    }
    /**
     * Find all children by type
     * @param {object} structure - source structure
     * @param {string} type - type of child
     * @param {object[]} [collection] - accumulator
     * @returns {object[]} - found children
     */


    findAllByType(structure, type, collection = []) {
      const children = structure.get('originObject.children') || [];

      if (structure.get('originObject.type') === type) {
        collection.push(structure);
      }

      for (let i = 0; i < children.length; i++) {
        this.findAllByType(children[i], type, collection);
      }

      return collection;
    }
    /**
     * Find by type
     * @param {object} structure - source structure
     * @param {string} type - type of child
     * @returns {object | null} - found child
     */


    findByType(structure, type) {
      const {
        children
      } = structure.originObject;

      if (structure.originObject.type === type) {
        return structure;
      }

      if (!children || !children.length) {
        return null;
      }

      for (let i = 0; i < children.length; i++) {
        const child = this.findByType(children[i], type);

        if (child) {
          return child;
        }
      }

      return null;
    }
    /**
     * Finds first child by type
     * @param {object} structure - source structure
     * @param {string} type - type of child
     * @returns {object | null} - found child
     */


    findFirstChildByType(structure, type) {
      const {
        children
      } = structure.originObject;

      if (!children || !children.length) {
        return null;
      }

      for (let i = 0; i < children.length; i++) {
        const child = this.findByType(children[i], type);

        if (child) {
          return child;
        }
      }

      return null;
    }
    /**
     * Get deepest pointed child in passed in item
     *
     * @param {object} node - structure object where search will be performed
     * @param {number} x - event pageX position
     * @param {number} y - event pageY position
     * @returns {object | null} - if pageX and pageY coordinates lies within structure node,
     * will return it's deepest child, otherwise null
     */


    getPointedNode(node, x, y) {
      const children = node.get('originObject').get('children') || [];
      let pointedChild = null;
      let pointedItem = null;

      if (this.isPointed(node, x, y) && node.get('droppable')) {
        pointedItem = node;

        for (let i = 0, len = children.length; i < len; i++) {
          pointedChild = this.getPointedNode(children[i], x, y);

          if (pointedChild) {
            pointedItem = pointedChild;
            break;
          }
        }
      }

      return pointedItem;
    }
    /**
     * Get pointed child in container
     *
     * @param {object} node - node
     * @param {number} x - event.pageX
     * @param {number} y - event.pageY
     * @returns {object | null} - structure if child found otherwise null
     */


    getPointedChild(node, x, y) {
      const {
        children
      } = node.originObject;

      if (!children || !children.length) {
        return null;
      }

      for (let i = 0; i < children.length; i++) {
        if (this.isPointed(children[i], x, y)) {
          return children[i];
        }
      }

      return null;
    }
    /**
     * Detect if item has been pointed,
     * current pageX and pageY coordinates lies inside given item
     *
     * @param {object} node - structure item
     * @param {number} x - event pageX
     * @param {number} y - event pageY
     * @returns {boolean} - true if pageX and pageY lies within item, otherwise false
     */


    isPointed(node, x, y) {
      return node.x <= x && node.x + node.width >= x && node.y <= y && node.y + node.height >= y;
    }
    /**
     * Add child to a parent
     *
     * @param {object} parent - parent node where child will be added
     * @param {object} child - child to add to the parent
     * @param {number} position - position to add child
     *
     * @returns {void}
     */


    addChild(parent, child, position = null) {
      const oldParent = child.parent;
      let index = position === null ? parent.originObject.children.length : position; // When moving inside same parent
      // TODO: probably this should be removed, since, drop rule should handle such operations

      if (parent.originObject.children.includes(child)) {
        if (parent.originObject.children.indexOf(child) < position) {
          index--;
        }
      } // It important to remove object before adding to a parent


      if (oldParent) {
        this.removeChild(child, oldParent !== parent);
      } // Well yep, since insertAt is not the same as splice, we must push object, if it's future index is out of
      // array boundaries


      if (index > parent.originObject.children.length) {
        parent.originObject.children.pushObject(child);
      } else {
        parent.originObject.children.insertAt(index, child);
      }

      if (oldParent) {
        this._parents.push(oldParent);
      }

      child.set('parent', parent);

      if (this.dragAndDropService.isGridEditMode()) {
        return;
      } // No need to recalculate column's width when moved inside same parent


      if (parent.originObject.type === _container.default.WIDGET_ROW && parent !== oldParent) {
        this.bootstrap.adjustColumnsWidthOnAdd(parent, child);
      } else if (parent === oldParent) {
        this.bootstrap.adjustSmWidthOnAdd(parent, child);
        this.bootstrap.adjustXsWidthOnAdd(parent, child);
      }
    }
    /**
     * Remove child from it's parent
     *
     * @param {object} child - child to remove
     * @param {boolean} adjustWidth - adjust width or not
     */


    removeChild(child, adjustWidth = true) {
      const {
        parent
      } = child;

      if (parent) {
        const childIndex = parent.originObject.children.indexOf(child);
        parent.originObject.children.removeObject(child);

        if (adjustWidth && parent.originObject.type === _container.default.WIDGET_ROW && child.originObject.type === _container.default.WIDGET_COLUMN) {
          this._adjustColumnWidthOnRemove(parent, child, childIndex);
        }

        child.set('parent', null);

        this._parents.push(parent);
      }
    }
    /**
     * Returns Bootstrap column width of current screen mode
     *
     * @param {object} column - Column object
     * @param {string} [mode] - mode
     * @returns {number} Column width
     */


    getColumnWidth(column, mode) {
      const sizeBreakpoint = mode || this.configuration.device.getDevice().breakpoint;
      return column.originObject.size[sizeBreakpoint];
    }
    /**
     * Sets column width
     *
     * @param {object} column - Column object
     * @param {number} width - Column width
     * @param {string} mode
     *
     * @returns {void}
     */


    setColumnWidth(column, width, mode) {
      const sizeBreakpoint = mode || this.configuration.device.getDevice().breakpoint;
      column.originObject.set(`size.${sizeBreakpoint}`, width);
    }
    /**
     * Create copy of passed in node of type widget or module
     *
     * @param {object} node - copy source
     * @returns {object}
     */


    createCopy(node) {
      const dataCopy = (0, _emberCopy.copy)(node, true);
      const {
        originObject
      } = dataCopy;
      delete dataCopy.originObject;

      const copyObject = this._gridBuilder.createInstance(originObject.type, originObject);

      Object.keys(dataCopy).forEach(prop => copyObject.set(prop, dataCopy[prop]));

      if (copyObject.originObject.hasOwnProperty('id')) {
        if (copyObject.originObject.type === _widget.WidgetType.WIDGET_TAB_PANE) {
          copyObject.originObject.set('id', (0, _uid.default)());
        } else {
          copyObject.originObject.set('id', null);
        }

        copyObject.set('moduleStyleModel', null);
      } // Remove anchor because it is unique on page scope


      if (this.isType(copyObject, _container.default.WIDGET_CONTAINER) && copyObject.originObject.anchor) {
        delete copyObject.originObject.anchor;
      }

      return copyObject;
    }
    /**
     * Find all modules into structure
     *
     * @param {object} structure - structure to search in
     * @param {string} type - type of module
     * @param {object[]} collection
     * @returns {object[]} array of found modules
     */


    findModules(structure, type = 'core', collection = []) {
      let moduleType;

      if (type === 'system') {
        moduleType = _module2.default.MODULE_SYSTEM;
      } else if (type === _module2.default.MODULE_FORM) {
        moduleType = type;
      } else {
        moduleType = _module2.default.MODULE_CORE;
      }

      return this.findAllByType(structure, moduleType, collection);
    }
    /**
     * Adjust column width on remove
     *
     * @param {EmberObject} parent - direct parent of child that has been removed
     * @param {EmberObject} child - child that has been removed
     * @param {number} childIndex - index of child in parent list of children
     * @private
     * @returns {void}
     */


    _adjustColumnWidthOnRemove(parent, child, childIndex) {
      const actualChildToRemove = child.originObject.children.objectAt(0);
      const row = child.parent;
      const rowChildren = row.originObject.children;
      const column = rowChildren.objectAt(childIndex - 1) || rowChildren.objectAt(childIndex);
      const {
        maxColumns
      } = this.bootstrap; // Special removal rules for WidgetType.WIDGET_SPACER

      if (column && actualChildToRemove.originObject.type === _widget.WidgetType.WIDGET_SPACER) {
        this.setColumnWidth(column, this.getColumnWidth(column, _breakpointModes.LARGE) + this.getColumnWidth(child, _breakpointModes.LARGE), _breakpointModes.LARGE);
        this.setColumnWidth(column, this.getColumnWidth(column, _breakpointModes.MEDIUM) + this.getColumnWidth(child, _breakpointModes.MEDIUM), _breakpointModes.MEDIUM);

        if (this.getColumnWidth(child, _breakpointModes.SMALL) < maxColumns) {
          const width = this.getColumnWidth(column, _breakpointModes.SMALL) + this.getColumnWidth(child, _breakpointModes.SMALL);
          this.setColumnWidth(column, Math.min(width, maxColumns), _breakpointModes.SMALL);
        }

        if (this.getColumnWidth(child, _breakpointModes.EXTRA_SMALL) < maxColumns) {
          const width = this.getColumnWidth(column, _breakpointModes.EXTRA_SMALL) + this.getColumnWidth(child, _breakpointModes.EXTRA_SMALL);
          this.setColumnWidth(column, Math.min(width, maxColumns), _breakpointModes.EXTRA_SMALL);
        }
      } else {
        this.bootstrap.adjustColumnWidthOnRemove(parent, child, childIndex);
      }
    }
    /**
     * Clean up node, remove excessive nodes
     * @param {object} node - node to cleanup
     * @private
     * @returns {void}
     */


    _doCleanup(node) {
      let subject = node;

      while (subject) {
        const oldParent = subject.parent;

        for (let i = 0; i < this._cleaners.length; i++) {
          // Each cleaner should perform cleanup if it can, order doesn't matter for now
          if (this._cleaners[i].canCleanup(subject)) {
            this._cleaners[i].doCleanup(this, subject);
          }
        }

        subject = oldParent;
      }
    }
    /**
     * returns the array with indices of parents bubbled till widget-main
     * @param {Object} module - module data
     * @returns {Array} - array of indices
     */


    getModuleIndexArray(module) {
      const flatIndexArray = [];
      let element = module;

      while (((_element = element) === null || _element === void 0 ? void 0 : (_element$originObject = _element.originObject) === null || _element$originObject === void 0 ? void 0 : _element$originObject.type) !== _container.default.WIDGET_MAIN && ((_element2 = element) === null || _element2 === void 0 ? void 0 : (_element2$parent = _element2.parent) === null || _element2$parent === void 0 ? void 0 : (_element2$parent$orig = _element2$parent.originObject) === null || _element2$parent$orig === void 0 ? void 0 : (_element2$parent$orig2 = _element2$parent$orig.children) === null || _element2$parent$orig2 === void 0 ? void 0 : _element2$parent$orig2.length)) {
        var _element, _element$originObject, _element2, _element2$parent, _element2$parent$orig, _element2$parent$orig2;

        const siblings = element.parent.originObject.children;
        const index = siblings.indexOf(element);
        flatIndexArray.unshift(index);
        element = element.parent;
      }

      return flatIndexArray;
    }
    /**
     * returns a module after traversing the main page structure using input indices array
     * @param {Array} arrayOfIndices - Array of indices used to reach the module
     * @returns {Object} - module data
     */


    getModuleByIndexFromStructure(arrayOfIndices) {
      var _this$_pageViewModel, _this$_pageViewModel$;

      const pageChildren = (_this$_pageViewModel = this._pageViewModel) === null || _this$_pageViewModel === void 0 ? void 0 : (_this$_pageViewModel$ = _this$_pageViewModel.originObject) === null || _this$_pageViewModel$ === void 0 ? void 0 : _this$_pageViewModel$.children;
      const widgetMain = pageChildren && pageChildren.find(child => child.originObject.type === _container.default.WIDGET_MAIN);

      if (!widgetMain) {
        return null;
      }

      let module = widgetMain;
      arrayOfIndices.forEach(index => {
        var _module, _module$originObject, _module$originObject$;

        if (!((_module = module) === null || _module === void 0 ? void 0 : (_module$originObject = _module.originObject) === null || _module$originObject === void 0 ? void 0 : (_module$originObject$ = _module$originObject.children) === null || _module$originObject$ === void 0 ? void 0 : _module$originObject$.length) || !module.originObject.children[index]) {
          module = null;
          return;
        }

        module = module.originObject.children[index];
      });
      return module;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_gridService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_gridBuilder", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dragAndDropService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "configuration", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "order", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pageViewModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StructureService;
});