define("builder/pods/components/image-marker/component", ["exports", "builder/utils/math-extended"], function (_exports, _mathExtended) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FRACTION = 0.2;
  /**
   * @class ImageMarkerComponent
   */

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['image-marker'],

    /**
     * @property {String} imageSelector - image element selector
     */
    imageSelector: 'img',

    /**
     * @property {String} position - marker position
     */
    position: Ember.computed('_elementShiftX', '_elementShiftY', 'x', 'y', function () {
      const shiftX = this._elementShiftX;
      const shiftY = this._elementShiftY;

      const x = _mathExtended.default.toNearestFraction(this.x, FRACTION);

      const y = _mathExtended.default.toNearestFraction(this.y, FRACTION);

      return Ember.String.htmlSafe([`left: calc(${x}% - ${shiftX}px)`, `top: calc(${y}% - ${shiftY}px)`].join('; '));
    }),

    /**
     * @property {Number} x - x position of marker, that comes from outer context
     */
    x: 0,

    /**
     * @property {Number} y - y position of marker, that comes from outer context
     */
    y: 0,

    /**
     * @property {String|null} title - marker title(actual value to be set to "title" attribute)
     */
    title: null,

    /**
     * Callback to be invoked when user changes marker position
     *
     * @param {Object} point - point will be passed to that callback
     */
    updatePosition() {},

    /**
     * @property {String} _markerSelector - marker element css selector
     * @private
     */
    _markerSelector: '.image-marker__marker',

    /**
     * @property {JQuery} _image - jquery wrapped image element
     * @private
     */
    _image: null,

    /**
     * @property {Object} _imageAttributes - image original attribute values, stored to restore values later
     * @private
     */
    _imageAttributes: null,

    /**
     * @property {JQuery} _element - jquery wrapped marker element
     * @private
     */
    _element: null,

    /**
     * @property {Number} _elementShiftX - marker element shift x to be applied to center marker relative to cursor
     * @private
     */
    _elementShiftX: 0,

    /**
     * @property {Number} _elementShiftY - marker element shift y to be applied to center marker relative to cursor
     * @private
     */
    _elementShiftY: 0,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_imageAttributes', {});
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const image = this.$(this.imageSelector);
      const marker = this.$(this._markerSelector);

      if (!image || !image.length) {
        throw new Ember.Error('Image is required');
      }

      if (!marker || !marker.length) {
        throw new Ember.Error('Marker element is required');
      }

      this.setImage(image);
      this.setElement(marker);
    },

    /**
     * Remove image marker
     */
    willDestroyElement() {
      const image = this._image;
      const attrs = this._imageAttributes;

      if (attrs) {
        Object.keys(attrs).forEach(key => {
          if (attrs[key] === null) {
            image.removeAttr(key);
          } else {
            image.attr(key, attrs[key]);
          }
        });
      }

      image.off('click');
    },

    /**
     * Set image element on which marker will be placed
     *
     * @param {JQuery} $image - JQuery wrapped image element
     */
    setImage($image) {
      if (this._image) {
        this._image.off('click');
      }

      this.set('_image', $image); // Save original draggable state

      this.set('_imageAttributes.draggable', $image.attr('draggable') === undefined ? null : $image.attr('draggable'));
      $image.attr('draggable', false);
      $image.on('click', event => {
        this.updatePosition({
          x: _mathExtended.default.toNearestFraction(event.offsetX / $image.outerWidth() * 100, FRACTION),
          y: _mathExtended.default.toNearestFraction(event.offsetY / $image.outerHeight() * 100, FRACTION)
        });
      });
    },

    /**
     * Set element that will be used as marker element
     *
     * @param {JQuery} $element - JQuery wrapper element
     */
    setElement($element) {
      this.set('_element', $element);
      this.set('_elementShiftX', $element.outerWidth() / 2);
      this.set('_elementShiftY', $element.outerHeight() / 2);
    }

  });

  _exports.default = _default;
});