define("builder/controllers/application", ["exports", "jquery", "builder/controllers/base", "builder/config/environment", "ember-copy", "builder/core/enumerators/system-aliases", "builder/services/company-and-website", "builder/core/enumerators/notification"], function (_exports, _jquery, _base, _environment, _emberCopy, _systemAliases, _companyAndWebsite, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Cookies
  } = window;

  var _default = _base.default.extend({
    /**
     * Module query parameters
     * @type {string[]}
     */
    queryParams: ['company'],

    /**
     * Current company ID
     * @type {string|null}
     */
    company: null,

    /**
     * User menu timer to be opened
     * @type {Object|null}
     */
    timer: null,

    /**
     * Notification timer to be show
     * @type {Object|null}
     */
    notificationTimer: null,

    /**
     * Flag to determine user menu status
     * @type {boolean}
     */
    menuIsVisible: false,

    /**
     * Flag to determine sidebar status
     * @type {boolean}
     */
    sidebarIsClosed: true,

    /**
     * Notification object
     * @type {Object|null}
     */
    notification: null,

    /**
     * Selected company
     * @type {Object|null}
     */
    selectedCompany: null,

    /**
     * Selected website
     * @type {Object|null}
     */
    selectedWebsite: null,

    /**
     * Ignite2 base url
     * @type {string}
     */
    ignite2BaseUrl: _environment.default.ignite2BaseUrl,

    /**
     * Array of companies with added object for switcher prompt
     * @type {Object[]}
     * @readonly
     */
    allCompanies: Ember.computed('app.companies', function () {
      let companies = [];

      if (this.get('app.companies.length')) {
        companies = (0, _emberCopy.copy)(this.get('app.companies'), true);

        if (companies.length > 1) {
          companies.unshift({
            id: 'all',
            name: 'All companies'
          });
        }
      }

      return companies;
    }).readOnly(),

    /**
     * Logout url
     * @type {string}
     * @readonly
     */
    logoutUrl: Ember.computed.readOnly('authManager.logoutUrl'),

    /**
     * Reset impersonation url
     * @type {string}
     * @readonly
     */
    resetImpersonationUrl: Ember.computed.readOnly('authManager.resetImpersonationUrl'),

    /**
     * Filtered for current company websites array.
     * @type {Object[]}
     * @readonly
     */
    filteredWebsites: Ember.computed('selectedCompany', 'app.websites', function () {
      let websites = [];

      if (this.get('app.websites.length')) {
        const company = this.selectedCompany;
        websites = (0, _emberCopy.copy)(this.get('app.websites'), true);

        if (company && company.id !== 'all') {
          return websites.filter(item => item.companyId === company.id);
        }
      }

      return websites;
    }).readOnly(),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configuration: Ember.inject.service(),

    /**
     * Company and website service
     * @type {CompanyAndWebsiteService}
     */
    companyAndWebsite: Ember.inject.service(),

    /**
     * SSO auth manager service
     * @type {AuthManagerService}
     */
    authManager: Ember.inject.service(),

    /**
     * Sentry reporting service
     * @type {SentryService}
     */
    sentry: Ember.inject.service(),

    /**
     * Pendo analytics service
     * @type {PendoService}
     */
    pendo: Ember.inject.service(),

    /**
     * Router service
     * @type {Ember.Service}
     */
    router: Ember.inject.service('router'),

    /**
     * Cookies domain
     * @type {string}
     * @private
     */
    _cookiesDomain: Ember.computed(function () {
      return _environment.default.cookiesDomain;
    }),

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args);

      Ember.run.schedule('afterRender', this, () => {
        // Handle menu after render
        this.handleUserMenu();
        const cookieCompanyId = Cookies.get(_companyAndWebsite.COMPANY_PARAM_NAME);
        const cookieWebsiteId = Cookies.get(_companyAndWebsite.WEBSITE_PARAM_NAME);

        this._setSwitcherToCompany(cookieCompanyId);

        this._setSwitcherToWebsite(cookieWebsiteId); // Transition to "website.edit" route if current route is "index".


        if (this.router.currentRouteName === _systemAliases.HOME_PAGE_ALIAS) {
          this._transitionToWebsite(cookieWebsiteId);
        }
      });
    },

    /**
     * Handle use menu.
     * @returns {void}
     */
    handleUserMenu() {
      // @todo move this part to some component
      (0, _jquery.default)('.header_user_dropdown').on('mouseleave', Ember.run.bind(this, this.runClearMenuTimer)).on('mouseenter', Ember.run.bind(this, function () {
        Ember.run.cancel(this.timer);
        this.set('menuIsVisible', true);
      }));
    },

    /**
     * Clear menu timer in a three seconds.
     * @returns {void}
     */
    runClearMenuTimer() {
      Ember.run.cancel(this.timer);
      this.timer = Ember.run.later(this, () => {
        this.set('menuIsVisible', false);
      }, 3000);
    },

    /**
     * Shows popup
     * @param {string} url - Popup url
     * @param {string} modifier - Popup content size modifier
     * @returns {void}
     */
    showPopup(url, modifier) {
      if (typeof _jquery.default.fn.magnificPopup !== 'undefined') {
        _jquery.default.magnificPopup.open({
          items: {
            src: url
          },
          type: 'iframe',
          preloader: true,
          callbacks: {
            beforeOpen() {
              this.contentContainer.addClass(modifier);
            },

            open() {
              const mp = this;
              mp.content.find('iframe').on('load', () => {
                mp.content.closest('.mfp-content').css('opacity', 1).siblings('.mfp-preloader').hide();
              });
            }

          }
        });
      }
    },

    /**
     * @inheritdoc
     */
    destroy() {
      Ember.run.cancel(this.timer);
      Ember.run.cancel(this.notificationTimer);
    },

    /**
     * Updates website id
     * @param {string} websiteId - Website id
     * @returns {void}
     */
    updateWebsite(websiteId) {
      if (this.hasNoChangesOrDoNotNeedToBeSaved()) {
        const {
          id,
          companyId
        } = this._getWebsite(websiteId);

        this._setWebsite(id);

        this._checkAndSetCompany(companyId);
      }
    },

    /**
     * Set website by id for website switcher
     * @param {string} websiteId - Website id
     * @returns {void}
     * @private
     */
    _setSwitcherToWebsite(websiteId) {
      const website = this._findWebsiteById(websiteId);

      if (website) {
        this.set('selectedWebsite', website);
      }
    },

    /**
     * Set company by id for company switcher
     * @param {string} companyId - company id
     * @returns {void}
     * @private
     */
    _setSwitcherToCompany(companyId) {
      const company = this._findCompanyById(companyId);

      if (company) {
        this.set('selectedCompany', company);
      }
    },

    /**
     * Set cookie for website
     * @param {string} websiteId - Website id
     * @returns {void}
     * @private
     */
    _setCookieToWebsite(websiteId) {
      Cookies.set(_companyAndWebsite.WEBSITE_PARAM_NAME, websiteId, {
        path: '/',
        expires: 365,
        domain: this._cookiesDomain
      });
    },

    /**
     * Set cookie for company
     * @param {string} companyId - Company id
     * @returns {void}
     * @private
     */
    _setCookieToCompany(companyId) {
      Cookies.set(_companyAndWebsite.COMPANY_PARAM_NAME, companyId, {
        path: '/',
        expires: 365,
        domain: this._cookiesDomain
      });
    },

    /**
     * Transitions to selected website.
     * @param {string} websiteId - Website id
     * @returns {void}
     * @private
     */
    _transitionToWebsite(websiteId) {
      this.transitionToRoute('website.edit', websiteId);
    },

    /**
     * Method find company by id
     * @param {string} companyId - company id
     * @return {Object|undefined} if company found return company
     * @private
     */
    _findCompanyById(companyId) {
      return this.allCompanies && this.allCompanies.findBy('id', companyId);
    },

    /**
     * Method find website by id
     * @param {string} websiteId - website id
     * @return {Object|undefined} if website found return website
     * @private
     */
    _findWebsiteById(websiteId) {
      return this.get('app.websites') && this.get('app.websites').findBy('id', websiteId);
    },

    /**
     * Gets company object
     * @param {string} companyId - Company id
     * @returns {Object}
     * @private
     */
    _getCompany(companyId) {
      let company = this._findCompanyById(companyId);

      if (!company) {
        company = this.get('app.companies.0');
      }

      return company;
    },

    /**
     * Checks company id and set it
     * @param {string} companyId - Company Id
     * @returns {void}
     * @private
     */
    _checkAndSetCompany(companyId) {
      if (this.get('selectedCompany.id') !== 'all' && this.get('selectedCompany.id') !== companyId) {
        const {
          id
        } = this._getCompany(companyId);

        this._setCompany(id);
      }
    },

    /**
     * Set company by company id, and check first website in this company
     * @param {string} companyId - company id
     * @returns {void}
     * @private
     */
    _setCompany(companyId) {
      this._setSwitcherToCompany(companyId);

      this._setCookieToCompany(companyId);

      this.companyAndWebsite.setCompanyId(companyId);
      this.set('company', companyId);
    },

    /**
     * Set website by website id
     * @param {string} websiteId - Website id
     * @returns {void}
     * @private
     */
    _setWebsite(websiteId) {
      this._setSwitcherToWebsite(websiteId);

      this._setCookieToWebsite(websiteId);

      this.companyAndWebsite.setWebsiteId(websiteId);
    },

    /**
     * Gets website by id
     * @param {string} [websiteId] - Website id
     * @returns {Object}
     * @private
     */
    _getWebsite(websiteId) {
      let website = this._findWebsiteById(websiteId);

      if (!website) {
        website = this.get('filteredWebsites.0') || this.get('app.websites.0');
      }

      return website;
    },

    /**
     * Controller actions.
     * @namespace
     */
    actions: {
      /**
       * Toggle use menu.
       * @returns {void}
       */
      showUserMenu() {
        this.toggleProperty('menuIsVisible'); // Clear timer when menu is closed
        // Because no need to close it in a 3 seconds

        if (!this.menuIsVisible) {
          clearTimeout(this.timer);
        } else {
          this.runClearMenuTimer();
        }
      },

      /**
       * Show popup with Feedback form
       * @returns {void}
       */
      gotFeedback() {
        this.showPopup(`${this.ignite2BaseUrl}site/feedback`, '__feedback');
      },

      /**
       * Show notification
       *
       * @method notification
       * @param {Object} data - Notification parameters
       * @param {'success' | 'error' | 'info' | 'warning'} data.type - notification type (available types: )
       * @param {string[]} data.message - messages to display
       * @param {number} [data.timeToDisplay=7000] - time to display message, msec.
       * @param {boolean} [data.alwaysShow] - is page reload required
       * @param {Object} [data.features] - notification features
       * @param {boolean} [data.features.needToReloadPage] - is page reload required
       */
      notification(data) {
        Ember.run.cancel(this.notificationTimer);
        this.set('notification', data);

        if (!data.alwaysShow) {
          this.notificationTimer = Ember.run.later(this, () => {
            this.send('closeNotification');
          }, Number(data.timeToDisplay) || 7000);
        }

        if (data.type === _notification.default.TYPE.ERROR) {
          this.sentry.report(data.message);
        }
      },

      closeNotification() {
        this.set('notification', null);
      },

      /**
       * After check has noting changes or don't need to be saved, set company by company id
       *
       * @method setCompany
       * @param {String} compId - company id
       */
      setCompany(compId) {
        if (this.hasNoChangesOrDoNotNeedToBeSaved()) {
          const company = this._getCompany(compId);

          this._setCompany(company.id);

          const {
            id,
            companyId
          } = this._getWebsite();

          this._setWebsite(id);

          this._checkAndSetCompany(companyId);

          this._transitionToWebsite(id);
        }
      },

      /**
       * After check has noting changes or don't need to be saved, set website by website id
       *
       * @method setWebsite
       * @param {String} websiteId - website id
       */
      setWebsite(websiteId) {
        if (this.hasNoChangesOrDoNotNeedToBeSaved()) {
          const {
            id
          } = this._getWebsite(websiteId);

          this._setWebsite(id);

          this._transitionToWebsite(id);
        }
      },

      /**
       * Toggle side bar
       *
       * @method toggleSidebar
       */
      toggleSidebar() {
        (0, _jquery.default)(document.body).toggleClass('page__body_aside-min');
        this.toggleProperty('sidebarIsClosed');
      },

      /**
       * Exit impersonation mode
       */
      switchBack() {
        window.location.href = this.resetImpersonationUrl;
      },

      logoutUser() {
        this.authManager.signOutUser();
      }

    }
  });

  _exports.default = _default;
});