define("builder/pods/components/widget-tab-pane/component", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WidgetTabPane extends _component.default {
    constructor(...args) {
      super(...args);
      this.classNames = ['js-tab-pane-wrapper'];
    }

  }

  _exports.default = WidgetTabPane;
});