define("builder/pods/website/edit/page/create/route", ["exports", "builder/routes/application", "jquery"], function (_exports, _application, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @inheritdoc
     */
    model() {
      // Since we have some "virtual" categories and we cannot say where template was added, we must request templates
      // again
      return Ember.RSVP.hash({
        categories: this.store.findAll('page-category'),
        templates: this.store.findAll('page-template'),
        visibilities: this.store.getVisibilities()
      });
    },

    /**
     * @inheritdoc
     */
    resetController(controller, isExiting) {
      this._super(controller, isExiting);

      if (isExiting) {
        // Since we want to save backend sorting, we have to hard reload records
        this.store.unloadAll('page-category');
      }

      _jquery.default.each(['activeCategory', 'selectedTemplate'], (index, prop) => {
        controller.set(prop, null);
      });

      controller.set('step', 'step1');
      controller.set('searchQuery.search', '');
      controller.set('searchQuery.input', ''); // Reset fields data

      controller.set('fieldAlias.data', '');
      controller.set('fieldTitle.data', '');
      controller.set('fieldTitle.isValid', false);
      controller.set('fieldAlias.isValid', false);
      controller.set('fieldCloneFromUrl.data', '');
      controller.set('isDisableCreateButton', true);
    },

    /**
     * @inheritdoc
     */
    setupController(controller, model) {
      this._super(controller, model);

      const editModel = this.modelFor('website.edit');
      controller.setCategory(model.categories.get('length') ? model.categories.objectAt(0) : null);
      controller.set('menu', editModel.menu);
      controller.set('unlinkedMenu', editModel.unlinkedMenu);
      controller.set('isShowPageTypeControls', false);
      controller.set('fieldPageTypeModel', Ember.Object.create());
    },

    actions: {
      /**
       * Action mark website as unpublished
       */
      markWebsiteAsUnPublished() {
        this.modelFor('website.edit').website.set('IsPublished', '0');
      }

    }
  });

  _exports.default = _default;
});