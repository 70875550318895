define("builder/abilities/tier/page", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierPageAbility
   */
  var _default = _ability.default.extend({
    /**
     * Page model
     * @type {PageModel}
     */
    model: null,

    /**
     * @inheritdoc
     */
    map: {
      canCreate: {
        entity: 'page',
        property: 'create'
      },
      canUpdate: {
        entity: 'page',
        property: 'update'
      },
      canUpdateGlobal: {
        entity: 'page',
        property: 'manage-global'
      },
      canUpdateChild: {
        entity: 'page',
        property: 'delete'
      },
      canDelete: {
        entity: 'page',
        property: 'delete'
      },
      canDeleteGlobal: {
        entity: 'page',
        property: 'manage-global'
      }
    },

    /**
     * @inheritdoc
     */
    handle(actionName) {
      const result = this._super(actionName);

      switch (actionName) {
        case 'canDelete':
          {
            var _this$model;

            const canDeleteGlobal = ((_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.isGlobal) ? this.handle('canDeleteGlobal') : true;
            const canDelete = result && canDeleteGlobal;
            return canDelete;
          }

        case 'canDeleteGlobal':
          {
            return result && !this.model.isGlobal;
          }

        case 'canUpdate':
          {
            const canUpdateGlobal = this.handle('canUpdateGlobal');
            const canUpdateChild = this.handle('canUpdateChild');
            return canUpdateGlobal && canUpdateChild;
          }

        case 'canUpdateGlobal':
          {
            return this.model.isGlobal ? result : true;
          }

        case 'canUpdateChild':
          {
            return !this.model.isChild;
          }

        default:
          return result;
      }
    }

  });

  _exports.default = _default;
});