define("builder/models/page-meta", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { fragmentArray } from 'ember-data-model-fragments/attributes';
  var _default = _model2.default.extend({
    /**
     * page id
     * @type {string}
     */
    PageId: (0, _model.attr)('String'),

    /**
     * @type {fragmentArray} Structure - page meta structure
     */
    Structure: (0, _model.attr)('raw')
  });

  _exports.default = _default;
});