define("builder/core/factories/module-gallery/category-decorator", ["exports", "builder/core/factories/module-gallery/gallery-module", "builder/core/literals"], function (_exports, _galleryModule, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CategoryGalleryModuleDecorator
   */
  var _default = _galleryModule.default.extend({
    /**
     * @inheritDoc
     */
    componentName: 'gallery-category',

    /**
     * @inheritDoc
     */
    type: 'category',

    /**
     * @inheritDoc
     * @readonly
     */
    title: Ember.computed.readOnly('data.Name'),

    /**
     * Category icon class
     * @type {string}
     */
    iconClass: null,

    /**
     * @inheritDoc
     */
    image: '',

    /**
     * @inheritDoc
     */
    enabled: Ember.computed('_childrenEnabled', {
      // eslint-disable-next-line ember/require-return-from-computed
      set(key, value) {
        this.set('_enabled', value);
      },

      get() {
        return this._childrenEnabled;
      }

    }),

    /**
     * Error message that indicates the reason is not enabled
     * @type {string}
     * @readonly
     */
    errorMessage: Ember.computed('_childrenEnabled', function () {
      const children = this.children || [];
      const disabledChildren = children.filterBy('enabled', false);
      const errorMessages = disabledChildren.map(child => child.errorMessage);
      const errorMessagesSet = new Set(errorMessages);

      if (errorMessagesSet.has(_literals.default.FEATURE_TIER_RESTRICTION)) {
        return _literals.default.FEATURE_TIER_RESTRICTION;
      }

      if (errorMessagesSet.has(_literals.default.GLOBAL_PAGE_USAGE_RESTRICTION)) {
        return _literals.default.GLOBAL_PAGE_USAGE_RESTRICTION;
      }

      return errorMessages.firstObject;
    }).readOnly(),

    /**
     * Has visible children
     * @type {boolean}
     * @readonly
     */
    hasChildrenToDisplay: Ember.computed('children.@each.visible', function () {
      const children = this.children || [];
      return children.length && children.some(child => child.visible);
    }).readOnly(),

    /**
     * Current module enabled state
     * @type {boolean}
     * @private
     */
    _enabled: true,

    /**
     * Is module has at least 1 enabled child
     * @type {boolean}
     * @private
     */
    _childrenEnabled: Ember.computed('children.@each.enabled', function () {
      const children = this.children || [];
      let enabled = false;

      if (!children.length || children.some(child => child.enabled)) {
        enabled = true;
      }

      return enabled;
    }),

    /**
     * @inheritDoc
     */
    getInsertModel() {
      throw new Ember.Error('Categories cannot be put on the page');
    }

  });

  _exports.default = _default;
});