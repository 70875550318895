define("builder/pods/components/field-media-active-area-video-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0+9lbBK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"select\",\"delete\"],[[28,\"action\",[[23,0,[]],\"open\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null]]]]]],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"field\",\"data\",\"type\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",false],[12,\"class\",\"add-new\"],[3,\"action\",[[23,0,[]],\"open\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus-circle\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"add-new_title\"],[8],[0,\"Add New\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-media-active-area-video-document/template.hbs"
    }
  });

  _exports.default = _default;
});