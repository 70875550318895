define("builder/pods/components/widget-section/component", ["exports", "builder/pods/components/grid-base-component/component", "builder/core/enumerators/module-action", "builder/core/styles-management/widget-section/map"], function (_exports, _component, _moduleAction, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember component component.
   */
  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['js-section-wrapper'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isHovered:js-section-wrapper_hovered', 'visibleOnLg::hidden-lg', 'visibleOnMd::hidden-md', 'visibleOnSm::hidden-sm', 'visibleOnXs::hidden-xs', 'isOverlayVisible:overlay-visible', 'data.originObject.resetColumnsPadding:reset-paddings-in-columns'],

    /**
     * @type {ConfigurationService} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @type {CssCustomPropertiesService} CssCustomPropertiesService - configuration styles service
     */
    cssCustomPropertiesService: Ember.inject.service('css-custom-properties'),

    /**
     * @type {import('ember-data').DS.Store} configurationService - configuration service
     */
    storeService: Ember.inject.service('store'),

    /**
     * @type {StructureService} structureService - Structure service
     */
    structureService: Ember.inject.service('structure'),

    /**
     * @type {CSSGenerator} cssGenerator - css generator to be used for css generation
     */
    cssGenerator: null,

    /**
     * @type {boolean} isHovered - is mouse over container or not
     */
    isHovered: false,

    /**
     * @type {string} backgroundVideoId - youtube video id for container background
     * @readonly
     */
    backgroundVideoId: Ember.computed.alias('data.originObject.videoId').readOnly(),

    /**
     * @type {boolean} showVideoBackground - show or hide video background in container
     * @readonly
     */
    showVideoBackground: Ember.computed('configurationService.currentDevice.type', 'backgroundVideoId', function () {
      const deviceService = this.configurationService.device;
      return !!((deviceService.isDesktop() || deviceService.isSmallMonitor()) && this.backgroundVideoId);
    }).readOnly(),

    /**
     * Is content vertically centered
     * @type {boolean}
     */
    isContentVerticallyCentered: Ember.computed('data.originObject.styles.global.@each.value', function () {
      var _this$data, _this$data$originObje, _this$data$originObje2;

      const globalStyles = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : (_this$data$originObje2 = _this$data$originObje.styles) === null || _this$data$originObje2 === void 0 ? void 0 : _this$data$originObje2.global;

      if (!globalStyles) {
        return false;
      }

      const contentPosition = globalStyles.findBy('name', 'contentPosition');
      const contentPositionValue = contentPosition && contentPosition.value;
      return contentPositionValue === 'vertically-centered';
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('cssGenerator', Ember.getOwner(this).lookup('css-generator:main'));
    },

    /**
     * @type {number} displayHeight - height value of current widget
     * @readonly
     */
    displayHeight: Ember.computed('data.originObject.styles.{lg,md,sm,xs}.@each.value', 'mode', function () {
      var _this$data$originObje3, _this$data2, _this$data2$originObj, _styles$this$mode;

      const styles = (_this$data$originObje3 = this === null || this === void 0 ? void 0 : (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : _this$data2$originObj.styles) !== null && _this$data$originObje3 !== void 0 ? _this$data$originObje3 : null;

      if (!styles) {
        return 0;
      }

      const breakpointStyles = (_styles$this$mode = styles[this.mode]) !== null && _styles$this$mode !== void 0 ? _styles$this$mode : null;

      if (!breakpointStyles) {
        return 0;
      }

      const height = breakpointStyles.findBy('name', 'height');
      return height ? parseInt(height.value, 10) : 0;
    }).readOnly(),

    /**
     * @type {number} displayWidth - width value of parent column element(1-12)
     * @readonly
     */
    displayWidth: Ember.computed('data.parent.originObject.size.{lg,md,sm,xs}', 'mode', function () {
      var _this$data$parent$ori, _this$data3, _this$data3$parent, _parentColumnWidth$th;

      const defaultWidth = 12;
      const parentGridObject = (_this$data$parent$ori = this === null || this === void 0 ? void 0 : (_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : (_this$data3$parent = _this$data3.parent) === null || _this$data3$parent === void 0 ? void 0 : _this$data3$parent.originObject) !== null && _this$data$parent$ori !== void 0 ? _this$data$parent$ori : null;

      if (!parentGridObject) {
        return defaultWidth;
      }

      const parentColumnWidth = parentGridObject === null || parentGridObject === void 0 ? void 0 : parentGridObject.size;

      if (!parentColumnWidth) {
        return defaultWidth;
      }

      return (_parentColumnWidth$th = parentColumnWidth[this.mode]) !== null && _parentColumnWidth$th !== void 0 ? _parentColumnWidth$th : defaultWidth;
    }).readOnly(),

    /**
     * @type {string} styles - contain inline css styles
     */
    styles: Ember.computed('mode', 'data.originObject.styles.{lg,md,sm,xs,global}.@each.value', 'globalStylesModel.global.@each.value', function () {
      var _this$element;

      const variablesCopy = this.data.originObject.styles.toJSON();
      const styleRecord = this.storeService.createRecord('style', variablesCopy);
      const moduleId = `#${(_this$element = this.element) === null || _this$element === void 0 ? void 0 : _this$element.id}`;
      const cssCustomProperties = this.cssCustomPropertiesService.generateStylesFromVariables(styleRecord, null, moduleId);
      const styles = this.cssCustomPropertiesService.generateStylesFromStyleMap(moduleId, _map.default, ''); // Clear not needed record

      this.storeService.deleteRecord(styleRecord);
      return `${cssCustomProperties} ${styles}`;
    }),

    /**
     * @inheritdoc
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    doubleClick(...args) {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    },

    /**
     * @inheritdoc
     */
    mouseOver(...args) {
      this.set('isHovered', true);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    mouseOut() {
      this.set('isHovered', false);
    },

    actions: {
      onMouseEnter() {
        this.mouseOver();
      },

      onMouseLeave() {
        this.mouseOut();
      }

    }
  });

  _exports.default = _default;
});