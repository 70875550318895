define("builder/initializers/factories", ["exports", "builder/core/factories/module-gallery/factory"], function (_exports, _factory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('factory:module-gallery', _factory.default, {
      singleton: true,
      instantiate: true
    });
  }

  var _default = {
    name: 'factories',
    initialize
  };
  _exports.default = _default;
});