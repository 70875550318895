define("builder/serializers/menu-summary", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Special serializer for menu-summary model, for more info @see models/menu-summary model
   */
  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * Normalize root key before processing
     *
     * @inheritdoc
     */
    normalizeResponse(store, modelClass, payload, id, requestType) {
      return this._super(store, modelClass, {
        'menu-summary': payload.menus
      }, id, requestType);
    }

  });

  _exports.default = _default;
});