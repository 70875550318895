define("builder/pods/components/form-prompt/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form_prompt'],

    /**
     * @property {Ember.Service} pageModuleService - page-module service
     */
    pageModuleService: Ember.inject.service('page-module'),

    /**
     * @property {Boolean} applyFormDisabled - flag to activate/deactivate prompt apply button
     */
    applyButtonDisabled: true,

    /**
     * @property {Number|Null} formConfigId - id of active form
     */
    formConfigId: null,

    /**
     * @property {String|Null} formName - title of new form
     */
    formName: null,

    /**
     * @property {Boolean} fieldIsReady - flag to detect whether form title field is ready
     */
    fieldIsReady: false,

    /**
     * @property {String|Null} userChoice - user choice action
     */
    userChoice: null,

    /**
     * Apply form API
     *
     * @method applyForm
     */
    applyForm() {
      throw new Ember.Error('Provide applyForm method for form-prompt');
    },

    /**
     * Reset form API
     *
     * @method resetPrompt
     */
    resetPrompt() {
      throw new Ember.Error('Provide resetPrompt method for form-prompt');
    },

    /**
     * Set text field for new form title
     *
     * @method _setFormTitleField
     * @private
     */
    _setFormTitleField() {
      this.pageModuleService.getConfig(this.formConfigId).then(config => {
        this.set('formTitleField', {
          label: 'Form Title',
          name: 'formTitle',
          data: config.get('Structure').title,
          enableValidationImmediately: true,
          validation: {
            required: {
              presence: true,
              ignoreBlank: true
            },
            formTitle: {
              debounce: 400,
              pageId: 0
            },
            length: {
              min: 1,
              max: 255
            }
          }
        });
        this.set('fieldIsReady', true);
      });
    },

    actions: {
      /**
       * Cancels form prompt
       *
       * @method cancel
       */
      cancel() {
        this.set('userChoice', null);
        this.set('applyButtonDisabled', true);
        this.set('fieldIsReady', false);
        this.set('formName', null);
        this.resetPrompt();
      },

      /**
       * Action for action handler save as new field
       *
       * @method actionHandlerSaveAsNewFiled
       * @param {String} title - form new title
       */
      actionHandlerSaveAsNewFiled(title) {
        this.set('applyButtonDisabled', false);
        this.set('formName', title);
      },

      /**
       * Action for error handler save as new field
       *
       * @method errorHandlerSaveAsNewFiled
       */
      errorHandlerSaveAsNewFiled() {
        this.set('applyButtonDisabled', true);
      },

      /**
       * Applies user action
       *
       * @param {String} action - user action
       */
      chooseOption(action) {
        this.set('userChoice', action);

        switch (action) {
          case 'update':
            this.set('applyButtonDisabled', false);
            this.set('fieldIsReady', false);
            break;

          case 'save':
            this._setFormTitleField();

            break;
        }
      },

      /**
       * Action to apply form changes from prompt window
       *
       * @method apply
       */
      apply() {
        this.applyForm(this.userChoice, this.formName);
        this.send('cancel');
      }

    }
  });

  _exports.default = _default;
});