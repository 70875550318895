define("builder/core/froala/index", ["exports", "froala-editor/js/plugins.pkgd.min", "builder/core/froala/constants", "builder/core/froala/metatags/metatags-popup", "builder/core/froala/icons/popup-df-icons", "builder/core/froala/links/link-add-button", "builder/core/froala/links/link-update-button", "builder/core/froala/links/link-remove-button", "builder/core/froala/links/link-color-plugin", "builder/core/froala/editor-factory"], function (_exports, _pluginsPkgd, _constants, _metatagsPopup, _popupDfIcons, _linkAddButton, _linkUpdateButton, _linkRemoveButton, _linkColorPlugin, _editorFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_constants).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _constants[key];
      }
    });
  });
  Object.keys(_metatagsPopup).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _metatagsPopup[key];
      }
    });
  });
  Object.keys(_popupDfIcons).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _popupDfIcons[key];
      }
    });
  });
  Object.keys(_linkAddButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkAddButton[key];
      }
    });
  });
  Object.keys(_linkUpdateButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkUpdateButton[key];
      }
    });
  });
  Object.keys(_linkRemoveButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkRemoveButton[key];
      }
    });
  });
  Object.keys(_linkColorPlugin).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkColorPlugin[key];
      }
    });
  });
  Object.keys(_editorFactory).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _editorFactory[key];
      }
    });
  });
});