define("builder/pods/components/style-import/component", ["exports", "js-yaml"], function (_exports, _jsYaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let StyleImportComponent = (_dec = Ember._action, (_class = (_temp = class StyleImportComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['style-import'];

      this.updateStyleField = (value, name) => {
        throw new Ember.Error('method should be provided');
      };

      this.onImportError = msg => {
        throw new Ember.Error('method should be provided');
      };
    }

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const [inputElement] = this.element.getElementsByTagName('input');

      if (!inputElement) {
        return;
      }

      inputElement.addEventListener('change', e => this.readYamlFile(e));
    }
    /**
     * Reads file content
     * @param {Event} event
     * @private
     */


    readYamlFile(event) {
      const {
        files
      } = event.target;
      const [file] = files;

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = evt => {
        this.loadFileContent(evt.target.result); // We need to clear input content so we could upload same file again

        event.target.value = '';
      };

      reader.readAsText(file);
    }
    /**
     * Parses file content and updates seo fields
     * @param {string} content - content to parse
     * @returns {void}
     */


    loadFileContent(content) {
      const styleData = _jsYaml.default.safeLoad(content);
      /**
       * We always serialize page styles as array. So if no array received, invalid
       * input was provided
       */


      if (!Array.isArray(styleData)) {
        const errorMessage = 'Invalid file structure. Array of items should be provided';
        this.onImportError(errorMessage);
        return;
      }

      this.updateStyleField(styleData);
    }
    /**
     * Opens file upload window
     * @returns {void}
     */


    openFileUpload() {
      if (!window.confirm('Warning: this will overwrite all the existing style properties on this page. If you want to add to the existing style properties please export the file and then edit before uploading.')) {
        return;
      }

      const inputElement = this.element.getElementsByTagName('input')[0];
      inputElement.click();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "openFileUpload", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "openFileUpload"), _class.prototype)), _class));
  _exports.default = StyleImportComponent;
});