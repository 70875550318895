define("builder/widgets/configs/widget-buttonsgroup", ["exports", "builder/pods/components/widget-buttonsgroup/component", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "ember-copy", "builder/config/environment", "builder/core/styles-management/widget-buttonsgroup/structure"], function (_exports, _component, _fields, _breakpointModes, _emberCopy, _environment, _structure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const buttonTextLayout = {
    type: _fields.default.DROPDOWN,
    label: 'Button Text Layout',
    name: 'buttonTextLayout',
    choices: [{
      value: 'horizontal',
      label: 'Horizontal'
    }, {
      value: 'vertical',
      label: 'Vertical Stack'
    }]
  };
  const buttons = {
    type: _fields.default.FORM_BUTTONS,
    label: 'Buttons',
    description: 'Add multiple buttons',
    name: 'data',
    children: [{
      type: 'widget-button',
      children: [{
        name: 'title',
        type: _fields.default.TEXT,
        label: 'Title',
        data: 'Button',
        validation: {
          required: true,
          pattern: '\\S',
          length: {
            max: 40
          }
        }
      }, {
        name: 'icon',
        type: _fields.default.ICON,
        label: 'Icon'
      }, {
        name: 'url',
        type: _fields.default.LINK,
        label: 'URL',
        data: {
          menuItemId: null,
          value: '',
          type: 'internal',
          target: 'self'
        },
        availableType: 'both',
        validation: {
          required: true
        }
      }, {
        name: 'width',
        type: _fields.default.NUMBER_WITH_UNITS,
        label: 'Minimum width',
        description: 'Define the precise minimum width of the button in pixels or percents',
        data: '60px',
        units: [{
          symbol: 'px',
          label: 'px'
        }, {
          symbol: '%',
          label: '%'
        }],
        validation: {
          pattern: '^(?:0|\\d+)[.,]?\\d*\\D*$'
        }
      }, {
        name: 'style',
        type: _fields.default.DROPDOWN,
        label: 'Style',
        data: 'primary',
        choices: [{
          value: 'primary',
          label: 'Primary'
        }, {
          value: 'default',
          label: 'Secondary'
        }, {
          value: 'tertiary',
          label: 'Tertiary'
        }, {
          value: 'quaternary',
          label: 'Quaternary'
        }, {
          value: 'quinary',
          label: 'Quinary'
        }]
      }],
      isSelected: true
    }],
    validation: {
      elements: {
        min: 2,
        max: 5
      }
    }
  };
  const buttonsSize = {
    type: _fields.default.DROPDOWN,
    label: 'Size',
    name: 'size',
    choices: [{
      value: 'default',
      label: 'Default'
    }, {
      value: 'xl',
      label: 'Extra Large'
    }, {
      value: 'lg',
      label: 'Large'
    }, {
      value: 'sm',
      label: 'Small'
    }]
  };
  const buttonsLayout = {
    type: _fields.default.DROPDOWN,
    label: 'Layout',
    name: 'layout',
    description: 'Please choose how you want your buttons to display.',
    help: 'Horizontal will stack the buttons left to right. Vertical will stack the buttons top to bottom.',
    choices: [{
      value: _component.default.LAYOUT_HORIZONTAL,
      label: 'Horizontal'
    }, {
      value: _component.default.LAYOUT_FULL_WIDTH,
      label: 'Full-Width Horizontal'
    }, {
      value: _component.default.LAYOUT_VERTICAL,
      label: 'Vertical'
    }]
  };
  const buttonsAlignment = {
    type: _fields.default.DROPDOWN,
    label: 'Align',
    name: 'aligned',
    choices: [{
      value: 'left',
      label: 'Left'
    }, {
      value: 'center',
      label: 'Center'
    }, {
      value: 'right',
      label: 'Right'
    }],
    showOnlyIf: [{
      field: 'layout',
      notEquals: _component.default.LAYOUT_FULL_WIDTH
    }]
  };
  const buttonsVisibility = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Show buttons group on which devices / breakpoints?',
    description: 'Please select at least 1 device for the module to show on',
    help: 'When Un-checking the box means the widget will be hidden on that device / breakpoint.',
    name: 'visibleOnDevices',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this module to display on.'
    }
  };
  /**
   * Widget buttonsgroup config
   */

  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      if (_environment.default === null || _environment.default === void 0 ? void 0 : _environment.default.featureFlags['release-widget-button-styles']) {
        return {
          styles: (0, _emberCopy.copy)(_structure.default, true),
          config: [buttons, buttonsSize, buttonTextLayout, buttonsLayout, buttonsAlignment, buttonsVisibility]
        };
      }

      return {
        config: [buttons, buttonsSize, buttonsLayout, buttonsAlignment, buttonsVisibility]
      };
    }

  });

  _exports.default = _default;
});