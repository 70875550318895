define("builder/services/grid-builder", ["exports", "@ember-data/model", "builder/core/enumerators/container", "builder/core/enumerators/module", "builder/core/enumerators/widget", "builder/core/factories/grid-factory", "builder/mixins/styles-variables", "ember-copy", "builder/core/structure-copy"], function (_exports, _model, _container, _module, _widget, _gridFactory, _stylesVariables, _emberCopy, _structureCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.moduleThemeSeparator = void 0;
  const copyObject = {
    copy() {
      return (0, _structureCopy._copy)(this);
    }

  };
  const moduleThemeSeparator = '—';
  /**
   * Grid containers, creator for grid objects
   * @class GridBuilderService
   */

  _exports.moduleThemeSeparator = moduleThemeSeparator;

  var _default = Ember.Service.extend(_stylesVariables.default, {
    /**
     * @property {Ember.Service} storeService - store service
     */
    storeService: Ember.inject.service('store'),

    /**
     * Create instance of grid object
     *
     * @param {String} type - type of object to create
     * @param {Object} [config] - additional config of grid structure item
     * @returns {Object}
     */
    createInstance(type, config = {}) {
      const instance = _gridFactory.default.createInstance(type, config);

      const store = this.storeService;
      instance.set('isEnabled', type === _module.default.MODULE_CORE ? this._isEnabled(type, instance) : true);
      this.setupACLs(instance);

      this._provideDefaultStyles(instance.get('originObject'));

      instance.set('mainTitle', this.getTitle(type, config));

      if (type === _module.default.MODULE_CORE || type === _module.default.MODULE_SYSTEM) {
        const module = store.peekAll('module').findBy('Name', instance.get('originObject.name')) || null;
        const theme = module ? store.peekAll('module-theme').filterBy('Name', instance.get('originObject.theme')).findBy('ModuleId', module.get('id')) : null;
        instance.set('module', module);
        instance.set('theme', theme);

        if (type === _module.default.MODULE_CORE) {
          instance.set('isLoaded', false);
          instance.set('refresh', Date.now());
        }
      }

      return instance;
    },

    /**
     * Setup grid component instance acls if needed
     * ACLs are affecting allowed buttons at module panel
     *
     * TODO: move allowed actions configuration to somewhere near corresponding widget
     * so we could easily find which actions are configured for widget
     *
     * @param {Ember.Object} instance - instance to set acls to
     * @returns {Ember.Object} - same instance
     */
    setupACLs(instance) {
      let acl = this._getACL();

      if (!this.hasACLs(instance.get('originObject.type'))) {
        return instance;
      }

      switch (instance.get('originObject.type')) {
        case _module.default.MODULE_CORE:
          {
            acl = this._getModuleAcls(instance);
            break;
          }

        case _module.default.MODULE_FORM:
          {
            acl.set('configure', true);
            acl.set('copyable', false);
            acl.set('routing', true);
            break;
          }

        case _widget.WidgetType.WIDGET_PARAGRAPH:
          {
            acl.set('edit', true);
            break;
          }

        case _widget.WidgetType.WIDGET_LINE_DIVIDER:
          {
            /** TODO:
             * acl of type 'edit' generates cog icon which is highly used as configuration button
             * acl of type 'configure' generates pencil icon which is highly used as edit button
             * make names for action more suitable to icons they correspond
             */
            acl.set('edit', true);
            break;
          }

        case _widget.WidgetType.WIDGET_SECTION:
          {
            acl.set('copyable', true);
            acl.set('remove', true);
            acl.set('configure', false);
            break;
          }

        case _container.default.WIDGET_TABS:
          {
            acl.set('edit', true);
            acl.set('copyable', true);
            acl.set('remove', true);
            break;
          }

        case _container.default.WIDGET_CONTAINER:
          {
            acl.set('edit', true);
            acl.set('copyable', true);
            acl.set('remove', true);
            acl.set('configure', false);
            acl.set('moveUp', false);
            acl.set('moveDown', false);
          }
      }

      instance.set('acl', acl);
      return instance;
    },

    /**
     * Define is current structure item can have acls or not
     *
     * @param {Ember.Object} type - structure item to determine if it can have acls
     * @returns {Boolean} - true if item can have acls
     */
    hasACLs(type) {
      if (type === _module.default.MODULE_CORE || type === _module.default.MODULE_SYSTEM || type === _module.default.MODULE_FORM) {
        return true;
      }

      if (type === _widget.WidgetType.WIDGET_PARAGRAPH || type === _widget.WidgetType.WIDGET_IMAGE || type === _widget.WidgetType.WIDGET_BUTTON || type === _widget.WidgetType.WIDGET_BUTTONSGROUP || type === _widget.WidgetType.WIDGET_SPACER || type === _widget.WidgetType.WIDGET_LINE_DIVIDER) {
        return true;
      }

      if (type === _container.default.WIDGET_CONTAINER || type === _widget.WidgetType.WIDGET_SECTION) {
        return true;
      }

      if (type === _container.default.WIDGET_TABS) {
        return true;
      }

      return false;
    },

    /**
     * Method return options for component styles.
     *
     * @method getStyleOptions
     * @return {Object[]}
     */
    getStyleOptions() {
      return [];
    },

    /**
     * Method provide styles variables for with default values.
     *
     * @param {Ember.Object} element - widget-container element
     * @method _provideDefaultStyles
     * @private
     */
    _provideDefaultStyles(element) {
      const store = this.storeService;
      let variables = element.styles || this.getVariablesConfig(element.type);
      const styleOptions = element.styleOptions || this.getStyleOptions(element.type);

      if (variables) {
        if (variables instanceof _model.default) {
          variables = variables.toJSON();
        }

        element.set('styles', store.createRecord('style', (0, _emberCopy.copy)(Ember.assign({}, variables, {
          StyleOptions: styleOptions
        }), true)));
      }
    },

    /**
     * Get default acls
     *
     * @param {Object} defaults - values to override default acl values
     * @returns {{edit: boolean, copy: boolean, remove: boolean, configure: boolean}}
     * @private
     */
    _getACL(defaults = {}) {
      return Ember.Object.extend(_emberCopy.Copyable, copyObject).create({
        edit: defaults.hasOwnProperty('edit') ? defaults.edit : true,
        copyable: defaults.hasOwnProperty('copyable') ? defaults.copyable : true,
        remove: defaults.hasOwnProperty('remove') ? defaults.remove : true,
        configure: defaults.hasOwnProperty('configure') ? defaults.configure : false,
        routing: defaults.hasOwnProperty('routing') ? defaults.routing : false
      });
    },

    /**
     * Set module acls, basically all that is done to set edit acl for modules which theme has no structure
     * Note: here we assume that all modules and themes where loaded before invoking this method
     *
     * @param {Ember.Object} instance - grid component structure item
     * @returns {Object} - new core-module acls
     * @private
     */
    _getModuleAcls(instance) {
      const store = this.storeService;
      const originObject = instance.get('originObject');
      const module = store.peekAll('module').findBy('Name', originObject.get('name'));
      const theme = module ? store.peekAll('module-theme').find(moduleTheme => moduleTheme.get('Name') === originObject.get('theme') && moduleTheme.get('ModuleId') === module.get('id')) : null;
      const isEnabled = instance.get('isEnabled');
      return this._getACL({
        edit: isEnabled && this._canEdit(theme),
        copyable: isEnabled
      });
    },

    /**
     * If module can be edited or not
     *
     * @param {DS.Model} theme - module theme
     * @returns {Boolean} - true if module can be edited
     * @private
     */
    _canEdit(theme) {
      const strings = theme.get('Strings');
      return !!(theme.get('Structure.length') || strings && Object.keys(strings).length || theme.get('StyleInterface.length'));
    },

    /**
     * Get default component title
     * @param {string} type - type for which find title
     * @param {Object} data - data of the object
     * @returns {string}
     */
    getTitle(type, data) {
      var _title;

      let title = '';
      const messages = this.literals || {};

      switch (type) {
        case _container.default.WIDGET_CONTAINER:
          {
            title = messages.WIDGET_CONTAINER_TITLE || '';
            break;
          }

        case _widget.WidgetType.WIDGET_SECTION:
          {
            title = messages.WIDGET_SECTION_TITLE || '';
            break;
          }

        case _container.default.WIDGET_TABS:
          {
            title = messages.WIDGET_TABS_TITLE || '';
            break;
          }

        case _module.default.MODULE_FORM:
          {
            title = messages.FORM_MODULE_TITLE || '';
            break;
          }

        case _module.default.MODULE_CORE:
          {
            title = `${this.storeService.peekAll('module').findBy('Name', data.name).Label} ${moduleThemeSeparator} ${data.theme}`;
            break;
          }

        case _module.default.MODULE_SYSTEM:
          {
            var _this$storeService$pe;

            title = (_this$storeService$pe = this.storeService.peekAll('module').findBy('Name', data.name)) === null || _this$storeService$pe === void 0 ? void 0 : _this$storeService$pe.Label;
            break;
          }

        default:
          {
            const widget = this.storeService.peekAll('widget').findBy('Name', type);

            if (widget) {
              title = widget.Label;
            }
          }
      }

      return (_title = title) === null || _title === void 0 ? void 0 : _title.trim();
    },

    /**
     * Is page component active or not, generally used for modules
     *
     * @param {String} type - type of component to check
     * @param {Ember.Object} instance - instance to check
     * @returns {Boolean} - true if component is active otherwise false
     * @private
     */
    _isEnabled(type, instance) {
      const store = this.storeService;
      const originObject = instance.get('originObject');
      const module = store.peekAll('module').findBy('Name', originObject.get('name'));
      const theme = module ? store.peekAll('module-theme').find(moduleTheme => moduleTheme.get('Name') === originObject.get('theme') && moduleTheme.get('ModuleId') === module.get('id')) : null;
      return !!(module && theme);
    }

  });

  _exports.default = _default;
});