define("builder/pods/components/language-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Language selector component for changing global site settings
   * @class
   * @example
   * <LanguageSelector
   * 				@languages={{someLanguagesArray}}
   * 				@selectedLanguage={{currentWebsiteLanguage}}
   * 				@placeholder={{some placeholder}}
   * 				@label={{some label}}
   * 				@help={{some help}}
   * 				@onLanguageSelected={{action setterForLanguageSelector}}
   * />
   */
  var _default = Ember.Component.extend({
    /**
     * @property {{Code:String, Label:String}[]} languages - array of languages for dropdown
     * should be provided as component property
     */
    languages: null,

    /**
     * @property {String} selectedLanguage - current selected language code
     * should be provided as component property
     */
    selectedLanguage: '',

    /**
     * @property {String} placeholder - default message to show if no label found
     * should be provided as component property
     */
    placeholder: '',

    /**
     * @property {String} label - label to show for select
     */
    label: '',

    /**
     * @property {String} help - help message for setting
     */
    help: '',

    init(...args) {
      this._super(...args);

      this.languages = this.languages || [];
    },

    /**
     * @property {{value:String, label: String}[]} choices - choices list for language dropdown
     */
    choices: Ember.computed('languages.[]', function () {
      return this.languages.map(x => ({
        value: x.Code,
        label: x.Label
      }));
    }),

    /**
     * @property {Object} dropdownOptions - options for language select
     */
    dropdownOptions: Ember.computed('languages.[]', 'label', 'selectedLanguage', 'help', 'placeholder', function () {
      return {
        label: this.label,
        data: this.selectedLanguage,
        help: this.help,
        placeholder: this.placeholder,
        choices: this.choices
      };
    }),

    /**
     * Passes selected language to parent component
     * @param {String} languageCode - current selected language code
     * should be provided through component property
     */
    onLanguageSelected(languageCode) {
      throw new Ember.Error('Method implementation should be provided through component property');
    },

    actions: {
      /**
       * Language change handler
       * @param {String} languageCode - selected language code
       */
      onLanguageSelect(languageCode) {
        this.onLanguageSelected(languageCode);
      }

    }
  });

  _exports.default = _default;
});