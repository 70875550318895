define("builder/errors/form-validation-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormValidationError
   */
  class FormValidationError extends Error {
    constructor(errors, warnings) {
      super(errors);
      this.errors = errors;
      this.warnings = warnings;
    }

  }

  _exports.default = FormValidationError;
});