define("builder/pods/components/page-structure/component", ["exports", "jquery", "builder/core/enumerators/module-action"], function (_exports, _jquery, _moduleAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['container-global'],

    /**
     * @type {AnimationService}
     */
    animationService: Ember.inject.service('animation'),

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const onClick = event => {
        this.moduleAction(_moduleAction.default.DEACTIVATE, null, null, event);
      };

      this.$().on('click.pst', onClick);
      (0, _jquery.default)(document).on('click.pst', onClick);
      this.animationService.initAOS();
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      (0, _jquery.default)(document).off('click.pst');
      this.$().off('click.pst');
    }

  });

  _exports.default = _default;
});