define("builder/models/website-config", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WebsiteConfigModel
   */
  var _default = _model2.default.extend({
    /**
     * @type {string}
     */
    CarouselBulletStyle: (0, _model.attr)('string')
  });

  _exports.default = _default;
});