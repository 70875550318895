define("builder/pods/website/edit/page/edit/market-segments/route", ["exports", "builder/routes/application", "builder/core/enumerators/controller-action"], function (_exports, _application, _controllerAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @inheritdoc
     */
    model() {
      return {
        marketSegments: this.store.findAll('market-segment')
      };
    },

    /**
     * @inheritdoc
     */
    activate(...args) {
      this._super(...args);

      this.send('handleBubbleAction', _controllerAction.default.OPEN_MARKET_SEGMENTS_SETTINGS);
    },

    /**
     * @inheritdoc
     */
    deactivate() {
      this.controller.set('activeTab', 'get-started');
      this.send('handleBubbleAction', _controllerAction.default.CLOSE_MARKET_SEGMENTS_SETTINGS);
    }

  });

  _exports.default = _default;
});