define("builder/pods/components/field-icon/component", ["exports", "jquery", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _jquery, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_icon'],

    /**
     * @type {IFontIconService}
     */
    iconsService: Ember.inject.service('font-icon'),

    /**
     * Icon classes
     * @type {string[]} source -
     * @readonly
     */
    source: Ember.computed(function () {
      const library = this.iconsService.currentIconLibrary;

      if (!library) {
        return [];
      }

      const mainClass = library.CSSSelectorMain.replace('.', '');
      const glyphClassPlaceholder = library.CSSSelector.replace('.', '');
      return library.Glyphs.map(glyph => `${mainClass} ${glyphClassPlaceholder.replace('{{glyph}}', glyph)}`);
    }).readOnly(),

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      var _iconField$field;

      const iconField = this;
      const data = (_iconField$field = iconField.field) === null || _iconField$field === void 0 ? void 0 : _iconField$field.data;
      const action = iconField.actionHandler;
      const placeholder = 'Please select icon';
      const inputEl = this.$('input');

      if (data) {
        inputEl.val(data);
      }

      inputEl.fontIconPicker({
        source: this.source,
        theme: 'fip-light',
        iconsPerPage: 12
      }).on('change', function () {
        const icon = (0, _jquery.default)(this).val();
        const isChanged = data !== icon;

        if (isChanged) {
          iconField.set('field.data', icon);
        }

        iconField.setIconText(icon || placeholder);

        if (action && !iconField.notValid) {
          var _iconField$field2;

          action(icon, (_iconField$field2 = iconField.field) === null || _iconField$field2 === void 0 ? void 0 : _iconField$field2.name);
        }
      });

      if (data) {
        this.setIconText(data);
      }
    },

    /**
     * Set icon name
     *
     * @param {string} icon - icon class
     */
    setIconText(icon) {
      this.$('.selected-icon-text').text(icon.replace(/df-icon\s?-?/g, ''));
    }

  });

  _exports.default = _default;
});