define("builder/pods/components/form-module/form-control", ["exports", "builder/mixins/event-base", "jquery"], function (_exports, _eventBase, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base class for form module control elements, like title, edit button, etc.
   */
  var _default = Ember.Component.extend(_eventBase.default, {
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @property {jQuery} wrapper - wrapper element
     */
    wrapper: null,

    /**
     * @property {jQuery} template - control element template
     */
    _wrapperTemplate: '<div class="page_module_form__control"></div>',

    /**
     * @inheritdoc
     */
    init() {
      this._super(); // Since we dont want property element to be shared, we should protect ourself


      if (this.wrapper !== null) {
        throw new Ember.Error('Property "wrapper" must be null prior to "init" hook');
      }

      this._initWrapper();

      this._initElement();

      this._positionElements();
    },

    /**
     * Initialize control element wrapper
     */
    _initWrapper() {
      this.set('wrapper', (0, _jquery.default)(this._wrapperTemplate));
    },

    /**
     * Initialize actual element
     */
    _initElement() {
      throw new Ember.Error('Method "_initElement" must be overrided');
    },

    /**
     * Move element into wrapper or another element
     */
    _positionElements() {
      throw new Ember.Error('Method "_positionElements" must be overrided');
    }

  });

  _exports.default = _default;
});