define("builder/models/bullet", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class BulletModel
   */
  var _default = _model.default.extend({
    Code: (0, _model.attr)('string'),
    Label: (0, _model.attr)('string')
  });

  _exports.default = _default;
});