define("builder/pods/components/field-daterange/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y0W4X6rx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-form_date-range_container\"],[8],[0,\"\\n\\t\"],[1,[28,\"input-date\",null,[[\"value\",\"maxDate\",\"datepickerVisible\",\"onClose\"],[[24,[\"dateStart\"]],[24,[\"maxDate\"]],[24,[\"datepickerVisible\"]],[28,\"action\",[[23,0,[]],\"updateDateStart\"],null]]]],false],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"bld-form_date-range_separator\"],[8],[0,\"to\"],[9],[0,\"\\n\\t\"],[1,[28,\"input-date\",null,[[\"value\",\"minDate\",\"onClose\"],[[24,[\"dateEnd\"]],[24,[\"minDate\"]],[28,\"action\",[[23,0,[]],\"updateDateEnd\"],null]]]],false],[0,\"\\n\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-calendar bld-form_date-range_calendar\"],[12,\"style\",\"z-index: 7\"],[3,\"action\",[[23,0,[]],\"openDatepicker\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-daterange/template.hbs"
    }
  });

  _exports.default = _default;
});