define("builder/pods/components/custom-breakpoint/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Cookies
  } = window;
  const FIELD_DEVICE_WIDTH = 'deviceWidth';
  const FIELD_DEVICE_HEIGHT = 'deviceHeight';
  const COOKIE_CUSTOM_BREAKPOINTS = 'customBreakpoints';
  let CustomBreakpoint = (_dec = Ember.inject.service('configuration'), _dec2 = Ember.computed('formInputs.@each.isValid'), _dec3 = Ember.computed('_optionsList.[]', '_optionsList.@each.width'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class CustomBreakpoint extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "configurationService", _descriptor, this);

      this.formInputs = [{
        data: null,
        name: FIELD_DEVICE_WIDTH,
        isNumberOnly: true,
        validation: {
          required: true,
          range: {
            min: 200,
            max: 5000
          }
        },
        isValid: false
      }, {
        data: null,
        name: FIELD_DEVICE_HEIGHT,
        isNumberOnly: true,
        validation: {
          required: true,
          range: {
            min: 200,
            max: 5000
          }
        },
        isValid: false
      }];
      this.width = this.formInputs.find(input => input.name === FIELD_DEVICE_WIDTH);
      this.height = this.formInputs.find(input => input.name === FIELD_DEVICE_HEIGHT);
      this._optionsList = [];
    }

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      this._optionsList = this.getOptionsFromCookies();
    }
    /**
     * determine if form is valid
     * @type {Boolean}
     */


    get isFormInvalid() {
      return !this.formInputs.every(field => field.isValid);
    }
    /**
     * Get saved custom breakpoints in cookies
     * @returns {Array}
     */


    getOptionsFromCookies() {
      const breakpoints = Cookies.get(COOKIE_CUSTOM_BREAKPOINTS);

      if (!breakpoints) {
        return [];
      }

      return JSON.parse(breakpoints);
    }
    /**
     * Add new custom breakpoint into the list
     * @param {Object} option - new custom breakpoint
     */


    setOptionInCookie(option) {
      this._optionsList.pushObject(option);

      Cookies.set(COOKIE_CUSTOM_BREAKPOINTS, JSON.stringify(this._optionsList));
    }

    /**
     * return the list of saved options for display
     * @type {Array}
     */
    get options() {
      return this._optionsList;
    }
    /**
     * Apply the breakpoint chosen
     * @param {Object} device - breakpoint to be applied
     */


    applyCustomBreakpoint(device) {
      if (this.callbackHandler) {
        this.callbackHandler(device);
      }
    }
    /**
     * Handle validation info in the current component
     * @param {Object} component - field component
     * @param {Boolean} isValid - is the field valid
     */


    validationHandler(component, isValid) {
      const input = this.formInputs.find(field => field.name === component.field.name);
      Ember.set(input, 'isValid', isValid);
    }
    /**
     * @returns {void}
     */


    clickBtnApply() {
      const width = this.width.data;
      const height = this.height.data;

      if (!width || !height || this.isFormInvalid) {
        return;
      }

      const deviceService = this.configurationService.device;
      const breakpoint = deviceService.getBreakpointByDimension(width);
      const deviceName = deviceService.setDeviceTypeBasedOnBreakpoint(breakpoint);
      const device = {
        name: deviceName,
        icon: deviceName,
        width,
        height
      };
      this.setOptionInCookie(device);
      this.applyCustomBreakpoint(device);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "configurationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFormInvalid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isFormInvalid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyCustomBreakpoint", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "applyCustomBreakpoint"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validationHandler", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "validationHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickBtnApply", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "clickBtnApply"), _class.prototype)), _class));
  _exports.default = CustomBreakpoint;
});