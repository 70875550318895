define("builder/core/module-config-handlers/handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Entry point for module config processing. Focused on module data setting
   *
   * @class ModuleDataHandler
   */
  var _default = Ember.Object.extend({
    /**
     * @property {Object|null} _handlers - list of available handlers
     */
    _handlers: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_handlers', {});
    },

    /**
     * Add new handler to the list of available handlers
     *
     * @param {String} name - handler unique name
     * @param {ModuleConfigHandler} handler - instance of handler to register
     * @returns {*}
     */
    addHandler(name, handler) {
      const handlers = this._handlers;
      handlers[name] = handler;
      return this;
    },

    /**
     * Entry point of for handling module config
     *
     * @param {String} type - type of handler to be used to process module config value
     * @param {PageModuleModel} config - module config model
     * @param {Ember.Object} field - field which value has been changed
     * @param {String} key - config field which value needs to be changed
     * @param {*} value - new value to set to config
     * @param {String} mode - breakpoint mode
     * @returns {Boolean} - true if handle has been successful otherwise false
     */
    handle(type, config, field, key, value, mode) {
      const handlers = this._handlers;
      const handler = handlers.hasOwnProperty(type) ? handlers[type] : null;

      if (!handler) {
        throw new Ember.Error(`Handler for type "${type}" is not found`);
      }

      return handler.handle(config, field, key, value, mode);
    }

  });

  _exports.default = _default;
});