define("builder/pods/components/sm-image/component", ["exports", "builder/core/abstract-classes/variable-component"], function (_exports, _variableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to build file uploader for styles-management
   * Used dropdown component instead
   */
  var _default = _variableComponent.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['line-box__line', 'line-box__inner'],

    /**
     * @property {null|Object} field - contain config for field-media.
     * This property is mutable in field-media
     */
    field: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('field', {
        name: 'sm-image',
        data: {},
        validation: {
          image: {
            extension: ['jpg', 'jpeg', 'png']
          }
        }
      });
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      const value = this.get('variable.value');

      if (value && value !== 'false' && value !== 'none') {
        // Setup data for field-media component
        this.set('field.data', {
          type: 'image',
          url: value
        });
      }
    },

    actions: {
      /**
       * Action to handle file upload "change" and "delete" event from "field-media" component
       *
       * @param {null|Object} data
       */
      change(data) {
        this.send('update', data ? data.url : 'none');
      }

    }
  });

  _exports.default = _default;
});