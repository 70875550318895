define("builder/models/module-style", ["exports", "@ember-data/model", "builder/models/style", "builder/core/enumerators/breakpoint-modes"], function (_exports, _model, _style, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preparedRecord = preparedRecord;
  _exports.default = void 0;
  const WIDGETS_PREFIX = '.page_edit_canvas';

  function preparedRecord(id, moduleTheme, globalVariablesModel) {
    const json = moduleTheme.toJSON().StyleVariables || {};
    json.id = id;
    json.ThemeId = moduleTheme.get('id');
    json.StyleCSS = '';
    json.styleMap = moduleTheme.get('StyleMap');
    json.globalVariablesModel = globalVariablesModel;
    return json;
  }
  /**
   * @class ModuleStyleModel
   */


  var _default = _style.default.extend({
    /**
     * @property {String} ThemeId - theme id
     */
    ThemeId: (0, _model.attr)('string'),

    /**
     * @property {CssCustomPropertiesService} cssCustomPropertiesService - configuration styles service
     */
    cssCustomPropertiesService: Ember.inject.service('css-custom-properties'),

    /**
     * @property {Boolean} isCanBeUnload - help to determine that model created via "push" method and don't exist on backend
     */
    isCanBeUnload: false,

    /**
     * @property {DS.Model<ModuleThemeModel>} moduleTheme - module theme
     * @readonly
     */
    moduleTheme: Ember.computed('ThemeId', function () {
      return this.store.peekRecord('module-theme', this.ThemeId);
    }).readOnly(),

    /**
     * @property {String} localStyleCSS - generated css specific for current device breakpoint
     */
    localStyleCSS: Ember.computed('configurationService.currentDevice.breakpoint', `{${_breakpointModes.GLOBAL},${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}.@each.value`, 'globalVariablesModel.localStyleCSS', function () {
      var _this$moduleTheme;

      const {
        generateStylesFromVariables
      } = this.cssCustomPropertiesService;
      const cssVariables = ((_this$moduleTheme = this.moduleTheme) === null || _this$moduleTheme === void 0 ? void 0 : _this$moduleTheme.hasCSSCustomProperties) ? generateStylesFromVariables(this, null, '.module-container') : '';

      const styleString = this._generateCSS(this.get('configurationService.currentDevice.breakpoint'));

      return `${cssVariables} ${styleString}`;
    }),

    /**
     * @property {String} scopedStyleCSS - generated css specific for current device breakpoint, scoped
     */
    scopedStyleCSS: Ember.computed('configurationService.currentDevice.breakpoint', `{${_breakpointModes.GLOBAL},${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}.@each.value`, 'globalVariablesModel.localStyleCSS', function () {
      var _this$moduleTheme2;

      const {
        generateStylesFromVariables
      } = this.cssCustomPropertiesService;
      const cssVariables = ((_this$moduleTheme2 = this.moduleTheme) === null || _this$moduleTheme2 === void 0 ? void 0 : _this$moduleTheme2.hasCSSCustomProperties) ? generateStylesFromVariables(this, null, '.page_module_content') : '';

      const styleString = this._generateCSS(this.get('configurationService.currentDevice.breakpoint'), WIDGETS_PREFIX);

      return `${cssVariables} ${styleString}`;
    }),

    /**
     * @property {Function} _globalStylesUpdate - observer for change any global style variables
     * @private
     */
    _globalStylesUpdate: Ember.observer(`globalVariablesModel.{${_breakpointModes.GLOBAL},${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}.@each.value`, function () {
      const {
        globalVariablesModel
      } = this;
      const changedGlobalVariables = globalVariablesModel.getChangedVariables();
      const hasReferenceToStyleVariable = Object.keys(changedGlobalVariables).some(variableName => this.hasReferenceToStyleVariable(variableName));

      if (hasReferenceToStyleVariable) {
        this.set('StyleCSS', this._generateCSS());
      }
    }),

    /**
     * @inheritDoc
     */
    hasReferenceToStyleVariable(variableName) {
      return this._super(variableName) || this.moduleTheme.hasGlobalStyleReference(variableName);
    },

    /**
     * @inheritdoc
     */
    didUpdate() {
      this.set('isCanBeUnload', false);
    },

    /**
     * @inheritdoc
     */
    _getModuleId() {
      const {
        id
      } = this;
      const theme = this.moduleTheme;
      const isSystem = theme && theme.get('IsSystem');
      return id && !isSystem ? id : null;
    }

  });

  _exports.default = _default;
});