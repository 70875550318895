define("builder/services/page-anchors", ["exports", "builder/core/enumerators/container"], function (_exports, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageAnchorsService
   */
  var _default = Ember.Service.extend({
    /**
     * Store service
     * @type {StoreService}
     */
    store: Ember.inject.service(),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configuration: Ember.inject.service(),

    /**
     * Structure service
     * @type {StructureService}
     */
    structure: Ember.inject.service(),

    /**
     * Collect and return anchors from page by alias
     * @param {string} alias - page alias
     * @returns {string[]}
     */
    async getAnchorsListByPageAlias(alias) {
      const page = this.store.peekAll('page').findBy('Alias', alias);

      if (page.BaseStructure == null) {
        return this.store.getPageStructure(page.id).then(updatedPage => {
          page.BaseStructure = updatedPage.BaseStructure;
          page.Structure = updatedPage.Structure;
          return this._getAnchorsfromLayout(page);
        });
      } else {
        return this._getAnchorsfromLayout(page);
      }
    },

    _getAnchorsfromLayout(page) {
      // Collect anchors from current and unsaved page layout structure
      // Because in layout model potentially not present latest user changes
      const layoutAnchors = this._getAnchorsFromCurrentPageLayout();

      let anchors = [];

      if (!page || page.isDeleted) {
        return layoutAnchors;
      }

      if (page === this.configuration.page) {
        // Collect anchors from current and unsaved page structure
        // Because in page model potentially not present latest user changes
        anchors = this._getAnchorsFromCurrentPageBody();
      } else {
        anchors = page.Structure.reduce((list, child) => {
          if (child.type === _container.default.WIDGET_CONTAINER && child.anchor) {
            list.push(child.anchor);
          }

          return list;
        }, []);
      }

      return [...layoutAnchors, ...anchors];
    },

    /**
     * Collect and return anchors from current page body structure
     * @returns {string[]}
     * @private
     */
    _getAnchorsFromCurrentPageBody() {
      const currentPageStructure = this.structure.pageViewModel;

      if (!currentPageStructure) {
        return [];
      }

      const pageBody = this.structure.findByType(currentPageStructure, _container.default.WIDGET_MAIN);
      const containers = this.structure.findAllByType(pageBody, _container.default.WIDGET_CONTAINER);
      const anchors = containers.reduce((list, container) => {
        if (container.originObject.anchor) {
          list.push(container.originObject.anchor);
        }

        return list;
      }, []);
      return anchors;
    },

    /**
     * Collect and return anchors from current page layout
     * @returns {string[]}
     * @private
     */
    _getAnchorsFromCurrentPageLayout() {
      const currentPageStructure = this.structure.pageViewModel;

      if (!currentPageStructure) {
        return [];
      }

      const header = this.structure.findByType(currentPageStructure, _container.default.WIDGET_HEADER);
      const footer = this.structure.findByType(currentPageStructure, _container.default.WIDGET_FOOTER);
      const containers = [...this.structure.findAllByType(header, _container.default.WIDGET_CONTAINER), ...this.structure.findAllByType(footer, _container.default.WIDGET_CONTAINER)];
      const anchors = containers.reduce((list, container) => {
        if (container.originObject.anchor) {
          list.push(container.originObject.anchor);
        }

        return list;
      }, []);
      return anchors;
    }

  });

  _exports.default = _default;
});