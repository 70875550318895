define("builder/abilities/oem-control", ["exports", "builder/abilities/control", "builder/core/literals"], function (_exports, _control, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlOEMControl
   */
  var _default = _control.default.extend({
    /**
     * @inheritdoc
     */
    namespace: 'oem',

    /**
     * @inheritdoc
     */
    message: _literals.default.FEATURE_OEM_RESTRICTION
  });

  _exports.default = _default;
});