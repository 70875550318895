define("builder/pods/components/gallery-category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ajvjP+VM",
    "block": "{\"symbols\":[],\"statements\":[[5,\"tooltip-external\",[[12,\"class\",\"tooltip-external_display_inline-block\"]],[[\"@text\",\"@show\",\"@autoWidth\"],[\"This category is empty\",[28,\"not\",[[24,[\"hasChildrenToDisplay\"]]],null],true]],{\"statements\":[[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"page_modules_list_item-icon\"],[11,\"style\",[28,\"html-format\",[[24,[\"extraIconStyles\"]]],null]],[8],[0,\"\\n\\t\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"use\",true],[11,\"xlink:href\",[29,[\"#\",[22,\"iconClass\"]]],\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[8],[1,[22,\"name\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/gallery-category/template.hbs"
    }
  });

  _exports.default = _default;
});