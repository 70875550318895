define("builder/core/meta-tags/meta-variables-configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultMetaVariables = _exports.website = _exports.vehicle = _exports.variables = _exports.dealership = void 0;

  /**
   * Dealership meta variables
   * @type {MetaVariablesColumn}
   */
  const dealership = {
    name: 'Dealership',
    variables: [{
      name: 'Id',
      path: 'meta.dealer.id'
    }, {
      name: 'Name',
      path: 'meta.dealer.name'
    }, {
      name: 'Lat Lng',
      path: 'meta.dealer.lat_lng'
    }, {
      name: 'Phone',
      path: 'meta.dealer.phone'
    }, {
      name: 'Email',
      path: 'meta.dealer.email'
    }, {
      name: 'City',
      path: 'meta.dealer.city'
    }, {
      name: 'Code',
      path: 'meta.dealer.code'
    }, {
      name: 'Logo',
      path: 'meta.dealer.logo'
    }, {
      name: 'State',
      path: 'meta.dealer.state'
    }, {
      name: 'Zip',
      path: 'meta.dealer.zip'
    }, {
      name: 'Country',
      path: 'meta.dealer.country'
    }, {
      name: 'Dept Name',
      path: 'meta.dealer.dept_name'
    }]
  };
  /**
   * Variable meta variables
   * @type {MetaVariablesColumn}
   */

  _exports.dealership = dealership;
  const variables = {
    name: 'Variables',
    variables: [{
      name: 'Main Phone',
      path: 'meta.variable.main_phone'
    }, {
      name: 'Toll Phone',
      path: 'meta.variable.toll_phone'
    }, {
      name: 'Surrounding City 1',
      path: 'meta.variable.surrounding_city_1'
    }, {
      name: 'Surrounding City 2',
      path: 'meta.variable.surrounding_city_2'
    }, {
      name: 'Surrounding City 3',
      path: 'meta.variable.surrounding_city_3'
    }, {
      name: 'Dealership Home City',
      path: 'meta.variable.dealership_home_city'
    }]
  };
  /**
   * Vehicle meta variables
   * @type {MetaVariablesColumn}
   */

  _exports.variables = variables;
  const vehicle = {
    name: 'Vehicle',
    variables: [{
      name: 'Blue Book',
      path: 'meta.vehicle.blue_book'
    }, {
      name: 'Body',
      path: 'meta.vehicle.body'
    }, {
      name: 'Body Style',
      path: 'meta.vehicle.body_style'
    }, {
      name: 'Cab',
      path: 'meta.vehicle.cab'
    }, {
      name: 'Comment1',
      path: 'meta.vehicle.comment1'
    }, {
      name: 'Comment2',
      path: 'meta.vehicle.comment2'
    }, {
      name: 'Comment3',
      path: 'meta.vehicle.comment3'
    }, {
      name: 'Comment4',
      path: 'meta.vehicle.comment4'
    }, {
      name: 'Comment5',
      path: 'meta.vehicle.comment5'
    }, {
      name: 'Date In Stock',
      path: 'meta.vehicle.date_in_stock'
    }, {
      name: 'Days In Stock',
      path: 'meta.vehicle.days_in_stock'
    }, {
      name: 'Dealer City',
      path: 'meta.vehicle.dealer_city'
    }, {
      name: 'Dealer Id',
      path: 'meta.vehicle.dealer_id'
    }, {
      name: 'Dealer Name',
      path: 'meta.vehicle.dealer_name'
    }, {
      name: 'Dealer State',
      path: 'meta.vehicle.dealer_state'
    }, {
      name: 'Description',
      path: 'meta.vehicle.description'
    }, {
      name: 'Doors Number',
      path: 'meta.vehicle.doors_number'
    }, {
      name: 'Drivetrain',
      path: 'meta.vehicle.drivetrain'
    }, {
      name: 'Engine Aspiration Type',
      path: 'meta.vehicle.engine_aspiration_type'
    }, {
      name: 'Engine Block Type',
      path: 'meta.vehicle.engine_block_type'
    }, {
      name: 'Engine Cylinders Number',
      path: 'meta.vehicle.engine_cylinders_number'
    }, {
      name: 'Engine Description',
      path: 'meta.vehicle.engine_description'
    }, {
      name: 'Engine Displacement',
      path: 'meta.vehicle.engine_displacement'
    }, {
      name: 'EPA City',
      path: 'meta.vehicle.epa_city'
    }, {
      name: 'EPA Highway',
      path: 'meta.vehicle.epa_highway'
    }, {
      name: 'Exterior Color',
      path: 'meta.vehicle.exterior_color'
    }, {
      name: 'Final Price',
      path: 'meta.vehicle.final_price'
    }, {
      name: 'Fuel Type',
      path: 'meta.vehicle.fuel_type'
    }, {
      name: 'Has Warranty',
      path: 'meta.vehicle.has_warranty'
    }, {
      name: 'Interior Color',
      path: 'meta.vehicle.interior_color'
    }, {
      name: 'Internet Price',
      path: 'meta.vehicle.internet_price'
    }, {
      name: 'Invoice Amount',
      path: 'meta.vehicle.invoice_amount'
    }, {
      name: 'Is Dealer Certified',
      path: 'meta.vehicle.is_dealer_certified'
    }, {
      name: 'Is New',
      path: 'meta.vehicle.is_new'
    }, {
      name: 'Is OEM Certified',
      path: 'meta.vehicle.is_o_e_m_certified'
    }, {
      name: 'Is Sold',
      path: 'meta.vehicle.is_sold'
    }, {
      name: 'Is Special',
      path: 'meta.vehicle.is_special'
    }, {
      name: 'Main Image Url',
      path: 'meta.vehicle.main_image_url'
    }, {
      name: 'Make',
      path: 'meta.vehicle.make'
    }, {
      name: 'Mileage',
      path: 'meta.vehicle.mileage'
    }, {
      name: 'Misc Price1',
      path: 'meta.vehicle.misc_price1'
    }, {
      name: 'Misc Price2',
      path: 'meta.vehicle.misc_price2'
    }, {
      name: 'Misc Price3',
      path: 'meta.vehicle.misc_price3'
    }, {
      name: 'Model',
      path: 'meta.vehicle.model'
    }, {
      name: 'Model Number',
      path: 'meta.vehicle.model_number'
    }, {
      name: 'MSRP',
      path: 'meta.vehicle.m_s_r_p'
    }, {
      name: 'NADA Retail Price',
      path: 'meta.vehicle.n_a_d_a_retail_price'
    }, {
      name: 'Photo Count',
      path: 'meta.vehicle.photo_count'
    }, {
      name: 'Selling Price',
      path: 'meta.vehicle.selling_price'
    }, {
      name: 'Series',
      path: 'meta.vehicle.series'
    }, {
      name: 'Stock Number',
      path: 'meta.vehicle.stock_number'
    }, {
      name: 'Style Description',
      path: 'meta.vehicle.style_description'
    }, {
      name: 'Style Id',
      path: 'meta.vehicle.style_id'
    }, {
      name: 'Transmission',
      path: 'meta.vehicle.transmission'
    }, {
      name: 'Transmission Description',
      path: 'meta.vehicle.transmission_description'
    }, {
      name: 'Transmission Speed Number',
      path: 'meta.vehicle.transmission_speed_number'
    }, {
      name: 'Trim',
      path: 'meta.vehicle.trim'
    }, {
      name: 'Upholstery',
      path: 'meta.vehicle.upholstery'
    }, {
      name: 'Id',
      path: 'meta.vehicle.id'
    }, {
      name: 'Video Count',
      path: 'meta.vehicle.video_count'
    }, {
      name: 'VIN',
      path: 'meta.vehicle.vin'
    }, {
      name: 'VUID',
      path: 'meta.vehicle.vuid'
    }, {
      name: 'Wheelbase Code',
      path: 'meta.vehicle.wheelbase_code'
    }, {
      name: 'Year',
      path: 'meta.vehicle.year'
    }, {
      name: 'Extended type',
      path: 'meta.vehicle.extended_type'
    }]
  };
  /**
   * Website meta variables
   * @type {MetaVariablesColumn}
   */

  _exports.vehicle = vehicle;
  const website = {
    name: 'Website',
    variables: [{
      name: 'Dealer Id',
      path: 'meta.website.dealer_id'
    }, {
      name: 'Dealer Code',
      path: 'meta.website.dealer_code'
    }, {
      name: 'Name',
      path: 'meta.website.name'
    }, {
      name: 'Address',
      path: 'meta.website.address'
    }, {
      name: 'Address 2',
      path: 'meta.website.address_2'
    }, {
      name: 'Main Phone',
      path: 'meta.website.main_phone'
    }, {
      name: 'Sales Phone',
      path: 'meta.website.sales_phone'
    }, {
      name: 'Service Phone',
      path: 'meta.website.service_phone'
    }, {
      name: 'Parts Phone',
      path: 'meta.website.parts_phone'
    }, {
      name: 'Main Fax',
      path: 'meta.website.main_fax'
    }, {
      name: 'City',
      path: 'meta.website.city'
    }, {
      name: 'Country',
      path: 'meta.website.country'
    }, {
      name: 'State',
      path: 'meta.website.state'
    }, {
      name: 'State Full',
      path: 'meta.website.state_full'
    }, {
      name: 'Zip',
      path: 'meta.website.zip'
    }, {
      name: 'General Email',
      path: 'meta.website.general_email'
    }]
  };
  /**
   * All default meta variables
   * @type {MetaVariablesColumn[]}
   */

  _exports.website = website;
  const defaultMetaVariables = [dealership, variables, vehicle, website];
  _exports.defaultMetaVariables = defaultMetaVariables;
});