define("builder/pods/components/fonts-uploaded-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['b2-fonts-uploaded-list'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['isCollapsed:b2-fonts-uploaded-list--collapsed'],

    /**
     * @property {String} title - title
     */
    title: '',

    /**
     * @property {Object[]|null} list - list of fonts
     */
    fonts: null,

    /**
     * @property {Boolean} isCollapsed - collapsed or expanded fonts list
     */
    isCollapsed: false,

    /**
     * @property {Function|null} onChangeFontVisibility - on change handler for font visibility
     */
    onChangeFontVisibility: null,

    /**
     * @property {Function|null} onChangeFontTitle - on change handler for font title
     */
    onChangeFontTitle: null,

    /**
     * @property {Function|null} onRemoveFont - on change handler for remove font
     */
    onRemoveFont: null,

    /**
     * @property {Function|null} onReloadFont - on reload font
     */
    onReloadFont: null,
    actions: {
      collapseExpandList() {
        this.toggleProperty('isCollapsed');
      }

    }
  });

  _exports.default = _default;
});