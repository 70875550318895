define("builder/pods/website/edit/page/edit/create/controller", ["exports", "builder/pods/website/edit/page/create/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    /**
     * Rollback page template attributes except for attributes listed in blacklist string array
     *
     * Approach below could be used in Ember v2.13 to rollback specific attributes
     * for (let key in attributes) {
     *		if (attributes.hasOwnProperty(key) && !blacklist.includes(key)) {
     *			template.rollbackAttribute(key);
     *		}
     *	}
     *
     * @param {PageTemplateModel} template - page template model
     * @param {Array} blackList
     * @returns {Object}
     */
    rollbackTemplateAttributes(template, blackList = []) {
      const oldValues = [];
      blackList.forEach(attribute => {
        oldValues.push({
          attribute,
          value: template.get(attribute)
        });
      });
      template.rollbackAttributes();
      oldValues.forEach(attribute => {
        template.set(attribute.attribute, attribute.value);
      });
      return template;
    },

    actions: {
      closePopup() {
        if (this.step === 'templateEditor') {
          const template = this.activeTemplate;

          if (template) {
            this.rollbackTemplateAttributes(template, ['Image', 'Preview']); // Preview must be saved
            // TODO: some response that thumbnail is saving?

            if (template.get('hasDirtyAttributes')) {
              template.save();
            }
          }

          this.send('setStep', 'step1');
          return;
        }

        this.transitionToRoute('website.edit.page.edit');
      }

    }
  });

  _exports.default = _default;
});