define("builder/pods/components/field-range/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_range'],

    /**
     * @property {object} sliderOptions - options for range-slider
     * @readonly
     * @private
     */
    sliderOptions: Ember.computed(function () {
      const min = this.get('field.min');
      const max = this.get('field.max');
      let data = [].concat(this.get('field.data'));

      if (data.length === 1) {
        data = [data[0], data[0]];
      }

      return {
        start: data || [min, max],
        tooltips: [true, true],
        connect: true,
        min,
        max,
        format: {
          to(value) {
            return Math.round(value);
          },

          from(value) {
            return Math.round(value);
          }

        }
      };
    }).readOnly(),

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * Method for call action handler
     *
     * @method _callActionHandler
     * @private
     */
    _callActionHandler() {
      const {
        actionHandler
      } = this;

      if (actionHandler) {
        actionHandler(this.get('field.data'), this.get('field.name'));
      }
    },

    actions: {
      /**
       * Action change values in dat and call actionHandler
       * @param {number[]} values - values from slider
       */
      change(values) {
        if (values && !this.notValid) {
          this.set('field.data', [values[0], values[1]]);

          this._callActionHandler();
        }
      }

    }
  });

  _exports.default = _default;
});