define("builder/core/expression/expression", ["exports", "builder/core/expression/operand", "builder/core/expression/operators"], function (_exports, _operand, _operators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isExpression = isExpression;
  _exports.getDataObjects = getDataObjects;
  _exports.default = _exports.STRING_EXPRESSION = _exports.LOGICAL_EXPRESSION = _exports.BINARY_EXPRESSION = _exports.ARRAY_EXPRESSION = void 0;

  /**
   * @const {String} ARRAY_EXPRESSION - Type of array expression
   */
  const ARRAY_EXPRESSION = 'array-expression';
  /**
   * @const {String} BINARY_EXPRESSION - Type of binary expression
   */

  _exports.ARRAY_EXPRESSION = ARRAY_EXPRESSION;
  const BINARY_EXPRESSION = 'binary-expression';
  /**
   * @const {String} LOGICAL_EXPRESSION - Type of logical expression
   */

  _exports.BINARY_EXPRESSION = BINARY_EXPRESSION;
  const LOGICAL_EXPRESSION = 'logical-expression';
  /**
   * @const {String} STRING_EXPRESSION - Type of string expression
   */

  _exports.LOGICAL_EXPRESSION = LOGICAL_EXPRESSION;
  const STRING_EXPRESSION = 'logical-expression';
  /**
   * @const {Array} STRING_OPERATORS - Array of string operators
   */

  _exports.STRING_EXPRESSION = STRING_EXPRESSION;
  const STRING_OPERATORS = [_operators.OPERATOR_CONTAINS];
  /**
   * @const {Array} LOGICAL_OPERATORS - Array of logical operators
   */

  const LOGICAL_OPERATORS = [_operators.OPERATOR_OR, _operators.OPERATOR_AND];
  /**
   * @const {Array} ARRAY_OPERATORS - Array of array operators
   */

  const ARRAY_OPERATORS = [_operators.OPERATOR_IN_LIST, _operators.OPERATOR_NOT_IN_LIST];
  /**
   * @const {Array} BINARY_OPERATORS - Array of binary operators
   */

  const BINARY_OPERATORS = [_operators.OPERATOR_EQUAL, _operators.OPERATOR_NOT_EQUAL, _operators.OPERATOR_LESS, _operators.OPERATOR_GREATER, _operators.OPERATOR_BETWEEN];
  /**
   * @const {Array} SUPPORTED_OPERATORS - Array of supported operators
   */

  const SUPPORTED_OPERATORS = [...LOGICAL_OPERATORS, ...ARRAY_OPERATORS, ...BINARY_OPERATORS, ...STRING_OPERATORS];

  var _default = Ember.Object.extend({
    /**
     * @property {Null|String} _operator - Expression operator
     * @private
     */
    _operator: null,

    /**
     * @property {Null|Operand} _leftOperand - Expression left operand object
     * @private
     */
    _leftOperand: null,

    /**
     * @property {Null|Operand} _rightOperand - Expression right operand object
     * @private
     */
    _rightOperand: null,

    /**
     * Sets expression operator
     * @param {String} type - Operator type
     */
    setOperator(type) {
      if (!SUPPORTED_OPERATORS.includes(type)) {
        throw new Ember.Error(`${type} operator is not supported`);
      }

      this.set('_operator', type);
    },

    /**
     * Returns expression operator
     * @returns {Null|String}
     */
    getOperator() {
      return this._operator;
    },

    /**
     * Removes right operand and set only if valid value is passed
     * @param {*} value - Operand value
     */
    updateRightOperand(value) {
      this.removeRightOperand();

      if (Ember.isPresent(value)) {
        this.setRightOperand(value);
      }
    },

    /**
     * Removes left operand and set only if valid value is passed
     * @param {*} value - Operand value
     */
    updateLeftOperand(value) {
      this.removeLeftOperand();

      if (Ember.isPresent(value)) {
        this.setLeftOperand(value);
      }
    },

    /**
     * Sets expression left operand
     * @param {*} value - Operand value
     */
    setLeftOperand(value) {
      const operand = _operand.default.create();

      operand.setValue(value);
      this.set('_leftOperand', operand);
    },

    /**
     * Returns left operand
     * @returns {Null|Object}
     */
    getLeftOperand() {
      return this._leftOperand;
    },

    /**
     * Returns right operand
     * @returns {Null|Object}
     */
    getRightOperand() {
      return this._rightOperand;
    },

    /**
     * Sets expression right operand
     * @param {*} value - Operand value
     */
    setRightOperand(value) {
      const operand = _operand.default.create();

      operand.setValue(value);
      this.set('_rightOperand', operand);
    },

    /**
     * Remove right operand
     */
    removeRightOperand() {
      this.set('_rightOperand', null);
    },

    /**
     * Remove left operand
     */
    removeLeftOperand() {
      this.set('_leftOperand', null);
    },

    /**
     * Checks whether all expression properties filled
     * @returns {Boolean}
     */
    isValid() {
      return !!(this._operator && this._leftOperand && this._rightOperand);
    },

    /**
     * Returns data object of the expression
     * @returns {Object}
     */
    toObject() {
      if (!this.isValid()) {
        throw new Ember.Error('Expression is not valid. Check all operands and operator.');
      }

      const type = this._getExpressionType();

      const operator = this._operator;
      const expression = {
        type,
        operator
      };

      this._applyOperands(expression, type);

      return expression;
    },

    /**
     * Returns type of the expression
     * @returns {String} Type of expression
     * @private
     */
    _getExpressionType() {
      const operator = this._operator;
      let type;

      if (BINARY_OPERATORS.includes(operator)) {
        type = BINARY_EXPRESSION;
      } else if (LOGICAL_OPERATORS.includes(operator)) {
        type = LOGICAL_EXPRESSION;
      } else if (ARRAY_OPERATORS.includes(operator)) {
        type = ARRAY_EXPRESSION;
      } else if (STRING_OPERATORS.includes(operator)) {
        type = STRING_EXPRESSION;
      }

      return type;
    },

    /**
     * Adds operands to expression object
     * @param {Object} expression - Expression plain object
     * @param {String} type - Expression type
     * @private
     */
    _applyOperands(expression, type) {
      const leftOperand = this._leftOperand.toObject();

      const rightOperand = this._rightOperand.toObject();

      switch (type) {
        case STRING_EXPRESSION:
        case BINARY_EXPRESSION:
        case LOGICAL_EXPRESSION:
          expression.left = leftOperand;
          expression.right = rightOperand;
          break;

        case ARRAY_EXPRESSION:
          if (this._leftOperand.isArray()) {
            expression.elements = leftOperand;
            expression.argument = rightOperand;
          } else if (this._rightOperand.isArray()) {
            expression.elements = rightOperand;
            expression.argument = leftOperand;
          }

          break;
      }
    }

  });
  /**
   * This part of Expression module is supposed to work with expression data object
   */

  /**
   * Determines passed object data is expression
   * @param {Object} expression - Expression data object
   * @returns {Boolean}
   */


  _exports.default = _default;

  function isExpression(expression) {
    return [ARRAY_EXPRESSION, BINARY_EXPRESSION, LOGICAL_EXPRESSION, STRING_EXPRESSION].includes(expression.type);
  }
  /**
   * Gets expression data object (@see toObject method) then picks all objects and groups all properties.
   * Returned array of objects will be used to render saved expressions
   * @param {Object} data - Expression data object
   * @param {Array} objects - Array to store objects
   * @returns {{name: String, properties: {name: String, value: String, operator: String}[]}[]} Array of objects with properties
   */


  function getDataObjects(data, objects = []) {
    switch (data.type) {
      case ARRAY_EXPRESSION:
        {
          const {
            elements,
            argument
          } = data;
          const objName = argument.value.name;
          let object = objects.findBy('name', objName);

          if (!object) {
            object = getObject(objName);
            addObject(objects, object);
          }

          addObjectProperty(object, argument.value.property, elements.value, data.operator);
          break;
        }

      case BINARY_EXPRESSION:
      case STRING_EXPRESSION:
      case LOGICAL_EXPRESSION:
        {
          const {
            left,
            right
          } = data;

          if (isExpression(left) && isExpression(right)) {
            getDataObjects(left, objects);
            getDataObjects(right, objects);
          } else if ((0, _operand.isOperand)(left) && (0, _operand.isOperand)(right)) {
            const objName = left.value.name;
            let object = objects.findBy('name', objName);

            if (!object) {
              object = getObject(objName);
              addObject(objects, object);
            }

            addObjectProperty(object, left.value.property, right.value, data.operator);
          }

          break;
        }
    }

    return objects;
  }
  /**
   * Returns object
   * @param {String} name - Object name
   * @returns {{name: String, properties: Array}}
   */


  function getObject(name) {
    return {
      name,
      properties: []
    };
  }
  /**
   * Adds objects to objects array
   * @param {Array} objects - Array to store all objects
   * @param {Object} object - Object
   */


  function addObject(objects, object) {
    objects.unshift(object);
  }
  /**
   * Adds property to object
   * @param {Object} object - Object
   * @param {String} name - Property name
   * @param {String} value - Property value
   * @param {String} operator - Property operator
   */


  function addObjectProperty(object, name, value, operator) {
    object.properties.push({
      name,
      value,
      operator
    });
  }
});