define("builder/services/can", ["exports", "ember-can/services/can"], function (_exports, _can) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlService
   */
  var _default = _can.default.extend({
    /**
     * @property {AccessControlControl|null} control - access control control to be used for access check
     */
    control: null,

    /**
     * @inheritdoc
     */
    can(abilityString, model, properties) {
      const {
        control
      } = this;
      const {
        abilityName,
        propertyName
      } = this.parse(abilityString);
      return control ? control.checkAccess(abilityName, propertyName, model, properties) : {
        isGranted: true,
        message: 'Control is not set, access granted'
      };
    }

  });

  _exports.default = _default;
});