define("builder/pods/components/field-checkboxlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ZK2o+X7",
    "block": "{\"symbols\":[\"element\"],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"choicesArray\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_checkboxlist_checkbox\"],[8],[0,\"\\n\\t\\t\"],[7,\"input\",true],[11,\"id\",[23,1,[\"id\"]]],[11,\"checked\",[23,1,[\"checked\"]]],[11,\"value\",[23,1,[\"value\"]]],[11,\"disabled\",[23,1,[\"disabled\"]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"label\",true],[11,\"for\",[23,1,[\"id\"]]],[11,\"title\",[23,1,[\"label\"]]],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-checkboxlist/template.hbs"
    }
  });

  _exports.default = _default;
});