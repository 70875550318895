define("builder/services/page-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service for getting module configurations
   *
   * @class PageModuleService
   * TODO: Remove this sh*t, right after this dependency in form module will be resolved
   */
  var _default = Ember.Service.extend({
    /**
     * @property {Ember.Service} _store - store itself
     */
    _store: Ember.inject.service('store'),

    /**
     * Get module config by id
     *
     * @param {String|Number} id - id of module to find
     * @returns {Promise}
     */
    getConfig(id) {
      let record = this._store.peekRecord('page-module', id);

      if (record) {
        record = new Ember.RSVP.Promise(resolve => {
          resolve(record);
        });
      }

      if (!record) {
        record = this._store.findRecord('page-module', id);
      }

      return record;
    },

    /**
     * Save module to page-module store
     *
     * @param {String|Number} id - id of module to find
     * @param {Boolean} isFakeSave - Property that determines whether this should be a fake save or not
     * @returns {Promise}
     */
    saveModule(id, isFakeSave = false) {
      const record = this.getConfig(id);

      if (isFakeSave) {
        record.then(pageModule => pageModule.save({
          adapterOptions: {
            fakeRequest: true
          }
        }));
      } else {
        record.then(pageModule => pageModule.save());
      }

      return record;
    },

    /**
     * Add new module to page-module store
     *
     * @param {Object} module
     */
    addModule(module) {
      this._store.pushPayload('page-module', module);
    },

    removeModule(id) {
      const record = this._store.peekRecord('page-module', id);

      if (record) {
        record.deleteRecord();
      }
    }

  });

  _exports.default = _default;
});