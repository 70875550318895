define("builder/pods/components/widget-paragraph/component", ["exports", "builder/core/widget", "builder/core/enumerators/module-action", "builder/core/enumerators/container", "jquery", "ember-copy", "sanitize-html", "builder/core/enumerators/fields", "builder/core/meta-tags/meta-variables-configuration", "builder/core/meta-tags/serializer", "builder/core/meta-tags/deserializer", "builder/core/meta-tags/dom-manipulation", "builder/core/froala", "builder/core/froala/links/link-color-plugin", "builder/core/froala/links/link-hover-color-plugin", "builder/core/froala/links/link-hover-decoration-dropdown", "builder/core/froala/toolbar-style-dropdown", "builder/core/froala/settings/widget-paragraph-settings", "builder/core/enumerators/widget", "builder/core/enumerators/keyboard"], function (_exports, _widget, _moduleAction, _container, _jquery, _emberCopy, _sanitizeHtml, _fields, _metaVariablesConfiguration, _serializer, _deserializer, _domManipulation, _froala, _linkColorPlugin, _linkHoverColorPlugin, _linkHoverDecorationDropdown, _toolbarStyleDropdown, _widgetParagraphSettings, _widget2, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO Uncomment after metatags plugin ready
  // import {
  // 	getMetapickerNavigationCommand
  // } from 'builder/core/meta-tags/commands';
  const {
    Cookies
  } = window;
  const LAYOUT_CHECKPOINT = 'layout';
  const PAGE_CHECKPOINT = 'page';

  var _default = _widget.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['page_module'],

    /**
     * Widget paragraph service
     * @type {WidgetParagraphService}
     */
    widgetParagraphService: Ember.inject.service('widget-paragraph'),

    /**
     * Service to manage icon fonts
     * @type {IFontIconService}
     */
    iconFontsService: Ember.inject.service('font-icon'),

    /**
     * WYSIWYG editor instance
     * @type {object|null}
     */
    editor: null,

    /**
     * Editor mode status
     * @type {boolean}
     */
    isEditorMode: false,

    /**
     * Structure service
     * @type {StructureService}
     */
    structureService: Ember.inject.service('structure'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Content of paragraph
     * @type {string}
     * @readonly
     */
    content: Ember.computed('data.originObject.content', function () {
      const html = (0, _deserializer.deserialize)(this.data.originObject.content);
      return Ember.String.htmlSafe(html);
    }).readOnly(),

    /**
     * Metapicker wrapper element id
     * @type {string}
     */
    metapickerWrapperId: Ember.computed('elementId', function () {
      return `metapicker-popup-${this.elementId}`;
    }),

    /**
     * Content editable element
     * @type {HTMLElement}
     * @readonly
     */
    contentEditable: Ember.computed('element', function () {
      const selector = '.js-editor-wrapper';
      return this.element.querySelector(selector);
    }).readOnly(),

    /**
     * string to filter metapicker content
     * @type {string}
     */
    searchQuery: '',

    /**
     * Contains removed field links data
     * @type {object}
     * @private
     */
    _removedFieldLinks: {},

    /**
     * Contains scope name to notify after change
     * @type {string}
     */
    widgetCheckpointScope: LAYOUT_CHECKPOINT,

    /**
     * Display ot not meta picker
     * @type {boolean}
     */
    isMetaPickerOpen: false,

    /**
     * Available meta variable configurations
     * @type {MetaVariablesColumn[]}
     */
    metaVariables: null,

    /**
     * Metapicker navigation command to perform
     * @type {MetapickerNavigationCommand | null}
     */
    metapickerNavigationCommand: null,
    showOverlay: false,

    /**
     * @inheritDoc
     */
    init(...args) {
      var _this$data$editOnStar, _this$data;

      this._super(...args);

      this.isEditorMode = (_this$data$editOnStar = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.editOnStartup) !== null && _this$data$editOnStar !== void 0 ? _this$data$editOnStar : false;
      this.metaVariables = _metaVariablesConfiguration.defaultMetaVariables;
    },

    /**
     * Get CSS Styles string for the links style data
     * @type {string}
     * @readonly
     */
    localCssStylesString: Ember.computed('data.originObject.links', 'data.originObject.links.[]', function () {
      var _this$data2, _this$data2$originObj;

      if (!((_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : _this$data2$originObj.links)) {
        return '';
      }

      const {
        links
      } = this.data.originObject; // Updating the links data on froala instance each time styles are updated

      if (this.editor) {
        this.editor.paragraphLinks = links;
      }

      const attrKey = 'data-field-link-id';
      return Object.keys(links).map(linkID => {
        var _link$styles, _link$hoverStyles;

        const link = links[linkID].data;
        let elementStyle = ''; // Marking link styles with !important as they need to be prioritized incase links are wrapped inside a header(h1,h2, etc.) element

        const linkStyles = link === null || link === void 0 ? void 0 : (_link$styles = link.styles) === null || _link$styles === void 0 ? void 0 : _link$styles.global;

        if (linkStyles && linkStyles.color) {
          elementStyle += `a[${attrKey}="${linkID}"] {
					color: ${linkStyles.color} !important;
				}`;
        }

        const linkHoverStyles = link === null || link === void 0 ? void 0 : (_link$hoverStyles = link.hoverStyles) === null || _link$hoverStyles === void 0 ? void 0 : _link$hoverStyles.global;

        if (linkHoverStyles && linkHoverStyles.color) {
          elementStyle += `a[${attrKey}="${linkID}"]:hover {
					color: ${linkHoverStyles.color} !important;
				}`;
        }

        if (linkHoverStyles && linkHoverStyles['text-decoration']) {
          elementStyle += `a[${attrKey}="${linkID}"]:hover {
					text-decoration: ${linkHoverStyles['text-decoration']} !important;
				}`;
        }

        return elementStyle;
      }).join('');
    }),

    /**
     * @returns {Object} - Animation Data Object
     */
    animationObject: Ember.computed('animationData.{name,mirror,duration}', 'data.originObject.animationName', 'data.originObject.animationDuration', 'data.originObject.animationMirroring', 'data.originObject.enableAnimation', 'data.originObject.animationOnDeviceType.[]', 'configurationService.currentDevice.breakpoint', function () {
      var _this$data$originObje;

      const animationEnabledDevices = ((_this$data$originObje = this.data.originObject) === null || _this$data$originObje === void 0 ? void 0 : _this$data$originObje.animationOnDeviceType) || [];
      const currentDevice = this.configurationService.currentDevice.breakpoint;
      const enabled = this.data.originObject.enableAnimation && animationEnabledDevices.includes(currentDevice);
      const {
        animationName: name,
        animationDuration: duration,
        animationMirroring: mirror
      } = this.data.originObject;

      if (!this.data.originObject.enableAnimation) {
        return this.animationData;
      }

      return {
        duration: duration.replace('ms', ''),
        name: enabled ? name : '',
        mirror
      };
    }),

    /**
     * Updates the link data with the style properties applied
     * @param {'styles'|'hoverStyles'} styleType - style type
     * * @param {'color'|'text-decoration'} styleProp - style feature
     * @param {string} linkId - ID of the link updated
     * @param {string} value - css value for the corresponding style feature
     * @returns {void}
     */
    updateLinkStyle(linkId, styleType, styleProp, value) {
      var _this$data$originObje2;

      if (!((_this$data$originObje2 = this.data.originObject) === null || _this$data$originObje2 === void 0 ? void 0 : _this$data$originObje2.links)) {
        return;
      } // Deep copying to trigger the computed property on links object 'localCssStylesString'


      const links = JSON.parse(JSON.stringify(this.data.originObject.links));
      const linksData = links[linkId].data;

      if (linksData[styleType] && linksData[styleType].global) {
        linksData[styleType].global[styleProp] = value;
      } else {
        linksData[styleType] = {
          global: {
            [styleProp]: value
          }
        };
      }

      this.set('data.originObject.links', links);
      this.updateWidgetState();
    },

    /**
     * Represents current text node previous sibling
     * Will be used during hacking ckeditor bug
     * @type {Node}
     */
    _previousBackspaceStepSibling: null,

    /**
     * Sets current text node previous sibling
     * This method should be called on keydown event,
     * so we'll have access to sibling of current node
     * @param {number} keyCode - current keyboard key code
     * @returns {void}
     */
    _setPreviousBackspaceStepSibling(keyCode) {
      const {
        BACKSPACE
      } = _keyboard.default.KEY;

      if (keyCode !== BACKSPACE) {
        return;
      }

      const caretNode = window.getSelection().getRangeAt(0).commonAncestorContainer;

      if (!caretNode) {
        return;
      }

      this._previousBackspaceStepSibling = caretNode.previousSibling;
    },

    /**
     * @inheritdoc
     */
    async didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
      this.element.addEventListener('mouseenter', () => {
        this.set('showOverlay', true);
      });
      this.element.addEventListener('mouseleave', () => {
        this.set('showOverlay', false);
      }); // TODO Activate on next story for metapicker plugin
      // const isFF = !!window.navigator.userAgent.match(/firefox/i);
      // if (isFF) {
      // 	addSpacesForMeta(this.contentEditable);
      // }
      //
      //
      // // contenteditable reads default behavior for control buttons on keyup events.
      // // so if we move all this stuff to keydown event, the default behavior for this buttons still remains.
      // // with this event handler we preventing default behavior for up/down/enter/escape if they were pressed
      // // within our search element
      // this.contentEditable.addEventListener('keydown', event => {
      // 	const { keyCode } = event;
      //
      // 	this._setPreviousBackspaceStepSibling(keyCode);
      //
      // 	if (!isKeyPressedOnSearchElement()) {
      // 		return;
      // 	}
      //
      // 	const { UP, DOWN, ENTER, ESCAPE } = Keyboard.KEY;
      //
      // 	// Prevent default behavior for these keys
      // 	if ([ENTER, UP, DOWN].includes(keyCode)) {
      // 		event.preventDefault();
      // 		event.stopPropagation();
      // 		const command = getMetapickerNavigationCommand(keyCode);
      //
      // 		this.set('metapickerNavigationCommand', command);
      //
      // 		return;
      // 	}
      //
      // 	if (keyCode === ESCAPE) {
      // 		event.preventDefault();
      // 		event.stopPropagation();
      // 		replaceSearchWithCurrentInput(this.contentEditable);
      // 		this.closeMetaTagsPopup();
      // 	}
      // });
      //
      // this.contentEditable.addEventListener('keyup', event => {
      // 	const { keyCode } = event;
      // 	const { SHIFT, UP, DOWN, ESCAPE } = Keyboard.KEY;
      //
      //
      // 	// we still need to prevent default behavior for this keys
      // 	// in other way escape will close popup window and up/down will move caret within contenteditable
      // 	if ([SHIFT, UP, DOWN, ESCAPE].includes(keyCode)) {
      // 		event.preventDefault();
      // 		event.stopPropagation();
      // 		return;
      // 	}
      //
      // 	if (isFF) {
      // 		addSpacesForMeta(this.contentEditable);
      // 	}
      //
      // 	detectOutOfSearchBoundaries(this.handleOutOfCurlyWrapperBoundaries.bind(this));
      //
      // 	if (!this.isMetaPickerOpen) {
      // 		detectDoubleCurlyPress(event, this.handleDoubleCurlyInput.bind(this));
      // 	}
      // 	if (this.isMetaPickerOpen) {
      // 		detectSearchContentChange(event, this.handleSearchContentChange.bind(this));
      // 	}
      // });
    },

    /**
     * Handles case when user put cursor out of curly braces search
     * @returns {void}
     */
    handleOutOfCurlyWrapperBoundaries() {
      this.closeMetaTagsPopup();
    },

    /**
     * Handles metatag search change
     * @param {string} searchString - string to search
     * @returns {void}
     */
    handleSearchContentChange(searchString) {
      this.set('searchQuery', searchString);
      this.setMetapopupPosition();
    },

    /**
     * Handles case when user input two curly braces in a row
     * @returns {void}
     */
    handleDoubleCurlyInput() {
      (0, _domManipulation.replaceCurlyBracesWithSearch)(true);
      this.openMetaTagsPopup();
      this.setMetapopupPosition();
    },

    didRender(...args) {
      this._super(...args);

      if (this.isEditorMode && !this.editor) {
        this.initializeFroalaEditor();
      }
    },

    /**
     * Default component lifecycle hook.
     * Extending default behavior with setting widget checkpoint scope.
     * This scope is essential for undo/redo actions
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      const hasHeaderParent = this.structureService.findClosest(this.data, _container.default.WIDGET_HEADER);
      const hasFooterParent = this.structureService.findClosest(this.data, _container.default.WIDGET_FOOTER);
      const hasMainParent = this.structureService.findClosest(this.data, _container.default.WIDGET_MAIN);

      if (hasHeaderParent || hasFooterParent) {
        this.widgetCheckpointScope = LAYOUT_CHECKPOINT;
      } else if (hasMainParent) {
        this.widgetCheckpointScope = PAGE_CHECKPOINT;
      }
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      if (this.editor) {
        this.editor.destroy();
      }
    },

    /**
     * @method
     * @returns {boolean} - return if the toolbar is set to be in inline style or scroll style
     */
    isToolbarInline() {
      const toolbarStyle = Cookies.get(_toolbarStyleDropdown.FROALA_TOOLBAR_STYLE_PREFERENCE);

      if (!toolbarStyle) {
        return true; // default to inline
      }

      return toolbarStyle === 'inline';
    },

    /**
     * Update the toolbar position when the viewport is scrolled in 'scroll' mode
     * Update the toolbar display when the viewport is scrolled in 'inline' mode
     */
    updateToolbarStylesOnScroll() {
      var _this$element, _this$element$querySe, _this$editor, _this$editor$$tb;

      const viewport = document.querySelector('.viewport');
      const containerElement = (_this$element = this.element) === null || _this$element === void 0 ? void 0 : (_this$element$querySe = _this$element.querySelector('.fr-wrapper')) === null || _this$element$querySe === void 0 ? void 0 : _this$element$querySe.getBoundingClientRect();
      const toolbarElement = (_this$editor = this.editor) === null || _this$editor === void 0 ? void 0 : (_this$editor$$tb = _this$editor.$tb) === null || _this$editor$$tb === void 0 ? void 0 : _this$editor$$tb[0];

      if (!toolbarElement || !containerElement) {
        return;
      }

      if (!this.isToolbarInline()) {
        toolbarElement.style.width = `${this.element.clientWidth}px`;
      }

      this.updateToolbarCss(viewport, containerElement, toolbarElement);
    },

    /**
     * Update styles on toolbar element with scroll on smaller devices
     */
    updateToolbarCss(viewport, containerElement, toolbarElement) {
      const viewportTop = viewport.getBoundingClientRect().top > 0 ? viewport.getBoundingClientRect().top : 0;
      const offset = 100;
      const isContainerTopReached = containerElement.top < offset + viewportTop;
      const isContainerBottomNotReached = containerElement.top > offset + viewportTop + toolbarElement.clientHeight - containerElement.height;

      if (isContainerTopReached && isContainerBottomNotReached) {
        if (this.isToolbarInline()) {
          toolbarElement.style.display = 'block';
          return;
        }

        toolbarElement.classList.remove('fr-sticky-off');
        toolbarElement.classList.add('fr-sticky-on');
        toolbarElement.style.top = `${offset + viewportTop}px`;
      } else {
        if (this.isToolbarInline()) {
          toolbarElement.style.display = 'none';
          return;
        }

        toolbarElement.classList.remove('fr-sticky-on');
        toolbarElement.classList.add('fr-sticky-off');
        toolbarElement.style.top = '';
      }
    },

    /**
     * Double click handler
     *
     * @method
     * @returns {*}
     */
    doubleClick(...args) {
      if (!this.isEditorMode) {
        this.moduleAction(_moduleAction.default.EDIT, this.data);
      }

      return this._super(...args);
    },

    /**
     * to toggle paragraph draggability
     * @param {Boolean} status - restrict paragraph drag if false and vice versa
     */
    toggleParagraphDraggability(status) {
      const parentSectionWidget = this.structureService.findClosest(this.data, _widget2.WidgetType.WIDGET_SECTION);

      if (parentSectionWidget) {
        this.toggleDraggable({
          data: parentSectionWidget
        }, status);
      }

      const parentTabsWidget = this.structureService.findClosest(this.data, _container.default.WIDGET_TABS);

      if (parentTabsWidget) {
        this.toggleDraggable({
          data: parentTabsWidget
        }, status);
      }

      this.toggleDraggable(this, status);
    },

    /**
     * Activate module
     *
     * @method
     * @returns {*}
     */
    click(...args) {
      var _this$data3;

      if (!((_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : _this$data3.isActive)) {
        this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      }

      return this._super(...args);
    },

    /**
     * Triggers blur handler on deactivating
     */
    activeStatus: Ember.observer('data.isActive', function () {
      if (!this.data.isActive) {
        this.blurHandler();
      }
    }),

    /**
     * Set the active state of froala editor
     * @param {Boolean} isActive - true if in edit mode
     */
    setEditorMode(isActive) {
      this.set('isEditorMode', isActive);
      this.toggleParagraphDraggability(!isActive);
      setTimeout(() => {
        this.handleViewportScroll(isActive);
      }, 0);
    },

    /**
     * Handle the toolbar position when in 'scroll' mode on smaller devices with scroll of viewport container
     * @param {Boolean} isEditMode - Is Froala in active mode
     */
    handleViewportScroll(isEditMode) {
      const viewport = document.querySelector('.viewport');

      if (!viewport) {
        return;
      }

      if (isEditMode) {
        const isViewportScrollable = viewport.style.overflowY === 'auto';

        if (!isViewportScrollable) {
          return;
        }

        this.updateToolbarStylesOnScroll = this.updateToolbarStylesOnScroll.bind(this);
        viewport.addEventListener('scroll', this.updateToolbarStylesOnScroll);
      } else {
        viewport.removeEventListener('scroll', this.updateToolbarStylesOnScroll);
      }
    },

    /**
     * Blur event handler when editor mode true
     *
     * @method
     */
    blurHandler() {
      // Make sure this is real blur after content edit and content was changed
      if (this.isContentChanged()) {
        this.updateWidgetState();
      }

      this.closeMetaTagsPopup();
      this.resetComponentEditorState();
      this.notifyContainerAboutChanges();
    },

    /**
     * Updates current page widget state and notifies all subscribers
     * about changes
     * TODO: widget should NOT change data directly. Move data change responsibility to separate subsystem
     */
    updateWidgetState() {
      const rawContent = (0, _sanitizeHtml.default)(this.editor.html.get(), {
        allowedAttributes: false
      });
      const newContent = (0, _serializer.serialize)(rawContent).trim();
      this.removeOrRestoreFieldLinks(newContent);
      this.set('data.originObject.content', newContent); // This method is provided by parent through components properties

      this.createStructureCheckpoint(this.widgetCheckpointScope);
      this.setChange('page');
    },

    /**
     * Resets editor state instance
     * @returns {void}
     */
    resetComponentEditorState() {
      this.setEditorMode(false);

      if (this.editor) {
        this.set('editor', null);
      }
    },

    /**
     * Notifies parent container about changes
     * @returns {void}
     */
    notifyContainerAboutChanges() {
      // Trigger event for recalculate widget-container height
      (0, _jquery.default)(document).trigger('changeStructure');
    },

    /**
     * Determines whether content is changed
     * @returns {boolean}
     */
    isContentChanged() {
      if (!this.editor) {
        return false;
      }

      const rawContent = this.editor.html.get();
      const oldContent = this.data.originObject.content;
      const newContent = (0, _serializer.serialize)(rawContent).trim();
      return oldContent !== newContent;
    },

    /**
     * Reset font size
     *
     * @param {Object} plugin - plugin responsible for font size reset
     */
    resetFontSize(plugin) {
      const fontSize = plugin.getValue(); // Only when font explicitly selected

      if (fontSize) {
        plugin.onClickOriginal(fontSize, true);
      }
    },

    /**
     * Delete field link data from data.originObject.links
     *
     * @method deleteFieldLinkData
     * @param {String} id - internal field link id
     */
    deleteFieldLinkData(id) {
      var _this$data$originObje3;

      const links = (_this$data$originObje3 = this.data.originObject) === null || _this$data$originObje3 === void 0 ? void 0 : _this$data$originObje3.links;
      const removedLinks = this._removedFieldLinks;

      if (links && links.hasOwnProperty(id)) {
        removedLinks[id] = links[id];
        delete links[id];
      }
    },

    /**
     * Restore field link data to data.originObject.links if before delete it and appear again
     * E.g. cases "cut-paste", "undo-redo".
     * @param {string} id - internal field link id
     * @returns {void}
     */
    restoreFieldLinkData(id) {
      var _this$data$originObje4;

      const removedLinks = this._removedFieldLinks;
      let links = (_this$data$originObje4 = this.data.originObject) === null || _this$data$originObje4 === void 0 ? void 0 : _this$data$originObje4.links;

      if (removedLinks && removedLinks.hasOwnProperty(id)) {
        if (!links) {
          links = {};
          this.set('data.originObject.links', links);
        }

        links[id] = removedLinks[id];
        delete removedLinks[id];
      }
    },

    /**
     * Make field link structure for save
     * @param {object} fieldLinkData - pure field link data
     * @param {string} fieldLinkData.type="external","internal" - field link type
     * @param {string} fieldLinkData.value - field link content (e.g. "index" or "https://site.com")
     * @param {string} [fieldLinkData.target="self","popup","tab"] - field link target to open
     * @param {number|null} [fieldLinkData.menuItemId] - linked menu item id
     * @param {object} [fieldLinkData.params] - get params (e.g. { filter: "all", limit: 50 })
     * @returns {object}
     */
    makeFieldLinkStructure(fieldLinkData) {
      return {
        type: _fields.default.LINK,
        name: 'noname',
        label: 'nolabel',
        data: (0, _emberCopy.copy)(fieldLinkData, true)
      };
    },

    /**
     * Get saved link
     * @param {number|string} id - internal field link id
     * @returns {object|null}
     */
    getFieldLink(id) {
      var _this$data$originObje5;

      const links = (_this$data$originObje5 = this.data.originObject) === null || _this$data$originObje5 === void 0 ? void 0 : _this$data$originObje5.links;
      let link = null;

      if (links && links.hasOwnProperty(id)) {
        link = links[id];
      }

      return link;
    },

    /**
     * Remove or restore field links based on paragraph content
     * @param {string} paragraphContent - text content of paragraph
     */
    removeOrRestoreFieldLinks(paragraphContent) {
      var _this$data$originObje6;

      let availableLinks = (_this$data$originObje6 = this.data.originObject) === null || _this$data$originObje6 === void 0 ? void 0 : _this$data$originObje6.links;
      const removedLinks = this._removedFieldLinks;
      const grabber = /data-field-link-id="(\d+)"/gi;
      let fieldLinkFragment = grabber.exec(paragraphContent);
      const contentFieldLinkIds = {};
      let ids = [];

      if (!availableLinks) {
        availableLinks = {};
        this.set('data.originObject.links', availableLinks);
      } // Collect field links from raw paragraph content


      while (fieldLinkFragment) {
        contentFieldLinkIds[fieldLinkFragment.pop()] = 1;
        fieldLinkFragment = grabber.exec(paragraphContent);
      } // Remove unused field links


      ids = Object.keys(availableLinks);

      for (let i = 0, len = ids.length; i < len; i++) {
        const id = ids[i]; // If content not contains saved field link then move link to removed links

        if (!contentFieldLinkIds.hasOwnProperty(id)) {
          this.deleteFieldLinkData(id);
        }
      } // Restore field links if its again appear in content


      ids = Object.keys(contentFieldLinkIds);

      for (let i = 0, len = ids.length; i < len; i++) {
        const id = ids[i];

        if (removedLinks.hasOwnProperty(id)) {
          this.restoreFieldLinkData(id);
        }
      }

      if (!availableLinks || !Object.keys(availableLinks).length) {
        delete this.data.originObject.links;
      } else {
        this.set('data.originObject.links', availableLinks);
      }
    },

    /**
     * Inserts link into paragraph content.
     * Use this method with Froala editor only
     * @param {string} linkText - new link text
     * @param {LinkOptions} linkData - new link options
     * @returns {void}
     */
    insertLink(linkText, linkData) {
      var _this$data$originObje7;

      const linkId = Date.now();
      const linkElement = `<a href="#" ${_froala.LINK_DATA_ATTRIBUTE}=${linkId}>${linkText}</a>`;
      const links = ((_this$data$originObje7 = this.data.originObject) === null || _this$data$originObje7 === void 0 ? void 0 : _this$data$originObje7.links) || {};
      links[linkId] = this.makeFieldLinkStructure(linkData);
      this.set('data.originObject.links', links);
      this.editor.html.insert(linkElement);
    },

    /**
     * Removes link from paragraph originObject
     * Use this method with Froala editor only
     * @param {number} linkId - link id to remove
     * @returns {void}
     */
    removeLink(linkId) {
      const {
        links
      } = this.data.originObject;

      if (!links) {
        return;
      }

      delete links[linkId];
      this.set('data.originObject.links', links);
    },

    /**
     * Updates link according provided data
     * Use this method with Froala editor only
     * @param {number} linkId - link id to update
     * @param {HTMLElement} linkElement - link element reference
     * @param {string} linkText - new link text
     * @param {LinkOptions} linkData - new link options
     * @returns {void}
     */
    updateLink(linkId, linkElement, linkText, linkData) {
      var _this$data$originObje8;

      const links = ((_this$data$originObje8 = this.data.originObject) === null || _this$data$originObje8 === void 0 ? void 0 : _this$data$originObje8.links) || {};

      if (!links[linkId]) {
        return;
      }

      links[linkId] = this.makeFieldLinkStructure(linkData);
      this.set('data.originObject.links', links);
      linkElement.innerText = linkText;
      this.updateWidgetState();
    },

    /**
     * Attaches events to froal editor
     * @param {any} editor - froala editor
     */
    attachFroalaEvents(editor) {
      editor.events.on('contentChanged', () => {
        if (this.isContentChanged()) {
          this.updateWidgetState();
        }

        this.notifyContainerAboutChanges();
      });
      editor.events.on(_toolbarStyleDropdown.toolbarStyleEvent, style => {
        const isInline = this.isToolbarInline();

        if (style === 'inline' && isInline || style === 'scroll' && !isInline) {
          return; // no change in option value
        }

        this.resetComponentEditorState();
        this.setEditorMode(true);
      });
      editor.events.on('blur', () => {
        const pService = this.widgetParagraphService;

        if (pService.addLinkPopupVisible || pService.updateLinkPopupVisible) {
          return;
        }

        this.resetComponentEditorState();
      });
      editor.events.on(_froala.linkAddEvent, selectedText => {
        const currentLinkText = {
          data: selectedText,
          label: 'Display Text'
        };
        this.editor.selection.save();
        this.widgetParagraphService.openAddLinkPopup(currentLinkText, (linkText, linkData) => {
          this.editor.selection.restore();
          this.insertLink(linkText, linkData);
        });
      });
      editor.events.on(_froala.linkUpdateEvent, (linkId, linkElement) => {
        const {
          links
        } = this.data.originObject;

        if (!links || !links[linkId]) {
          return;
        }

        const currentLinkData = links[linkId];
        const currentLinkText = {
          data: linkElement.innerText,
          label: 'Display Text'
        };
        this.widgetParagraphService.openUpdateLinkPopup(currentLinkText, currentLinkData, (linkText, linkData) => {
          this.updateLink(linkId, linkElement, linkText, linkData);
        });
      });
      editor.events.on(_froala.linkRemoveEvent, linkId => {
        this.removeLink(linkId);
      });
      editor.events.on(_linkColorPlugin.linkColorChangeEvent, (linkId, stylesType, color) => {
        this.updateLinkStyle(linkId, stylesType, 'color', color);
      });
      editor.events.on(_linkHoverColorPlugin.linkHoverColorChangeEvent, (linkId, stylesType, color) => {
        this.updateLinkStyle(linkId, stylesType, 'color', color);
      });
      editor.events.on(_linkHoverDecorationDropdown.linkHoverDecorationEvent, (linkId, stylesType, hoverDecoration) => {
        this.updateLinkStyle(linkId, stylesType, 'text-decoration', hoverDecoration);
      }); // Adding inline styles to table elements as froala classes are not shared with E6.
      // Also in builder, they work only in active froala editor element

      editor.events.on('commands.after', (cmd, option) => {
        if (cmd === 'tableHeader') {
          this.addTableHeaderStyles();
        }

        if (cmd === 'tableStyle' && option === 'fr-alternate-rows' || cmd === 'tableRows') {
          this.updateAlternateRowsStyle();
        }

        if (cmd === 'tableStyle' && option === 'fr-dashed-borders' || cmd === 'tableInsert') {
          this.updateTableBordersStyle();
        }

        if (cmd === 'tableCellStyle' && (option === 'fr-highlighted' || option === 'fr-thick')) {
          this.updateTableCellStyle();
        }
      });
      /**
       * We need to provide current icons to froala instance.
       * If someone find good way to share data between froala and ember, then DO IT
       */

      editor.DF_ICONS_LIBRARY = this.iconFontsService.currentIconLibrary;
    },

    initializeFroalaEditor() {
      const editorElementSelector = '.js-editor-wrapper';
      const editorElement = this.element.querySelector(editorElementSelector);
      const updatedParagraphSettings = { ..._widgetParagraphSettings.froalaParagraphSettings,
        toolbarInline: this.isToolbarInline(),
        toolbarSticky: !this.isToolbarInline()
      };
      const me = this;
      (0, _froala.createFroalaEditor)(editorElement, function () {
        var _me$data, _me$data$originObject;

        me.set('editor', this); // Passing the links data to froala editor instance to be used in style initialization

        if ((_me$data = me.data) === null || _me$data === void 0 ? void 0 : (_me$data$originObject = _me$data.originObject) === null || _me$data$originObject === void 0 ? void 0 : _me$data$originObject.links) {
          me.editor.paragraphLinks = me.data.originObject.links;
        }

        me.attachFroalaEvents(me.editor);
        me.element.querySelector('.js-editor-wrapper').click();
        me.editor.events.trigger('focus', [], true);
        me.editor.html.set(me.content.toString());
        me.editor.html.wrap();
        me.removeOrRestoreFieldLinks(me.content.toString());

        if (me.data.editOnStartup) {
          me.set('data.editOnStartup', false);
        }

        editorElement.addEventListener('click', event => {
          const {
            target: {
              classList
            }
          } = event;

          if (!classList.contains(_domManipulation.MetaCloseButtonTag.className)) {
            return;
          }

          const {
            parentNode
          } = event.target;
          me.editor.selection.setAfter(parentNode);
          me.editor.selection.restore();
          me.editor.cursor.backspace();
          me.editor.toolbar.enable();
          me.updateWidgetState();
          me.notifyContainerAboutChanges();
        }); //	keydown event to remove double curley bracket witch metatags popup

        let bracketLeftCount = 0;
        editorElement.addEventListener('keydown', event => {
          if (bracketLeftCount === 1 && event.code === 'Space') {
            bracketLeftCount = 0;
          } else {
            if (event.code === 'BracketLeft') {
              bracketLeftCount += 1;
            }

            if (bracketLeftCount > 1) {
              setTimeout(() => {
                me.editor.cursor.backspace();
                me.editor.cursor.backspace();
                me.editor.toolbar.show();
                me.editor.metaPlugin.showPopup();
                bracketLeftCount = 0;
              }, 0);
            }
          }
        });
      }, updatedParagraphSettings);
    },

    /**
     * @returns {void} - add default inline styles for table header
     */
    addTableHeaderStyles() {
      var _this$editor2;

      const froalaElement = (_this$editor2 = this.editor) === null || _this$editor2 === void 0 ? void 0 : _this$editor2.$el;
      const header = froalaElement === null || froalaElement === void 0 ? void 0 : froalaElement.find('th');

      if (!header) {
        return;
      }

      header.css('background-color', '#ececec');
    },

    /**
     * @returns {void} - add/remove inline bg styles to alternate rows
     */
    updateAlternateRowsStyle() {
      var _this$editor3, _froalaElement$;

      const froalaElement = (_this$editor3 = this.editor) === null || _this$editor3 === void 0 ? void 0 : _this$editor3.$el;
      const tablesArray = ((_froalaElement$ = froalaElement[0]) === null || _froalaElement$ === void 0 ? void 0 : _froalaElement$.querySelectorAll('table')) || [];
      tablesArray.forEach(table => {
        var _table$querySelector, _table$classList;

        const tableRows = ((_table$querySelector = table.querySelector('tbody')) === null || _table$querySelector === void 0 ? void 0 : _table$querySelector.querySelectorAll('tr')) || [];
        const hasAlternateBgRows = (_table$classList = table.classList) === null || _table$classList === void 0 ? void 0 : _table$classList.contains('fr-alternate-rows');
        tableRows.forEach((tr, index) => {
          const {
            style
          } = tr;
          style.backgroundColor = hasAlternateBgRows && index % 2 !== 0 ? 'whitesmoke' : 'transparent';
        });
      });
    },

    /**
     * @returns {void } - add/remove inline border styles to table cells
     */
    updateTableBordersStyle() {
      var _this$editor4, _froalaElement$2;

      const froalaElement = (_this$editor4 = this.editor) === null || _this$editor4 === void 0 ? void 0 : _this$editor4.$el;
      const tablesArray = ((_froalaElement$2 = froalaElement[0]) === null || _froalaElement$2 === void 0 ? void 0 : _froalaElement$2.querySelectorAll('table')) || [];
      tablesArray.forEach(table => {
        var _table$classList2;

        const hasDashedBorders = (_table$classList2 = table.classList) === null || _table$classList2 === void 0 ? void 0 : _table$classList2.contains('fr-dashed-borders');
        table.querySelectorAll('td').forEach(td => {
          var _td$classList, _td$classList2;

          const hasCustomCellStyle = ((_td$classList = td.classList) === null || _td$classList === void 0 ? void 0 : _td$classList.contains('fr-thick')) || ((_td$classList2 = td.classList) === null || _td$classList2 === void 0 ? void 0 : _td$classList2.contains('fr-highlighted'));

          if (hasCustomCellStyle) {
            return;
          }

          td.style.border = hasDashedBorders ? '1px dashed #DDD' : '1px solid #DDD';
        });
      });
    },

    /**
     * @returns {void} - add/remove custom inline border styles to selected table cell
     */
    updateTableCellStyle() {
      var _this$editor5, _this$editor5$$el$;

      const selectedCell = (_this$editor5 = this.editor) === null || _this$editor5 === void 0 ? void 0 : (_this$editor5$$el$ = _this$editor5.$el[0]) === null || _this$editor5$$el$ === void 0 ? void 0 : _this$editor5$$el$.querySelector('.fr-selected-cell');

      if (!selectedCell) {
        return;
      }

      const {
        classList,
        style
      } = selectedCell;

      if (classList.contains('fr-thick')) {
        style.border = classList.contains('fr-highlighted') ? '2px double red' : '2px solid #DDD';
        return;
      }

      if (classList.contains('fr-highlighted')) {
        style.border = '1px double red';
        return;
      }

      style.border = '1px solid #DDD';
    },

    /**
     * Opens meta tags picker popup.
     * @returns {void}
     */
    openMetaTagsPopup() {
      this.set('isMetaPickerOpen', true);
    },

    /**
     * Closes meta tags picker popup
     * @returns {void}
     */
    closeMetaTagsPopup() {
      this.set('isMetaPickerOpen', false); // TODO Activate on story metapicker popup
      // replaceSearchWithCurrentInput(this.contentEditable);
    },

    /**
     * Sets meta tags picker popup position
     * @returns {void}
     */
    setMetapopupPosition() {
      const metapicker = this.element.querySelector(`#${this.metapickerWrapperId}`);

      if (!metapicker) {
        return;
      }

      const searchOffset = (0, _domManipulation.getSearchElementOffset)(this.contentEditable);
      metapicker.style.top = `${searchOffset.top}px`;
      metapicker.style.left = `${searchOffset.left}px`;
    },

    /**
     * show overlay on template when widget is in focus or hovered
     * @type {Boolean}
     */
    showEditOverlayTemplate: Ember.computed.or('showOverlay', 'data.isActive'),
    actions: {
      /**
       * Handle click on edit text button
       */
      overlayButtonClick() {
        this.setEditorMode(true);
      },

      onMetaVariableSelected(variable) {
        const metaTag = (0, _domManipulation.createMetaTagElement)(variable.path);
        (0, _domManipulation.replaceSearchWithMetaElement)(this.contentEditable, metaTag);
        this.closeMetaTagsPopup();
      },

      onCloseMetaPicker() {
        this.closeMetaTagsPopup();
      }

    }
  });

  _exports.default = _default;
});