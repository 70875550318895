define("builder/pods/components/field-year-range/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @property {String}
     */
    labelMin: 'Min.',

    /**
     * @property {String}
     */
    labelMax: 'Max.',

    /**
     * @property {String}
     */
    min: '',

    /**
     * @property {String}
     */
    max: '',

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      const {
        data
      } = this.field;
      const years = data && data.length ? data : [null, null];
      this.set('min', years[0]);
      this.set('max', years[1]);
    },

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * Method for call action handler
     *
     * @method _callActionHandler
     * @private
     */
    _callActionHandler() {
      const {
        actionHandler
      } = this;

      if (actionHandler) {
        var _this$field, _this$field2;

        actionHandler((_this$field = this.field) === null || _this$field === void 0 ? void 0 : _this$field.data, (_this$field2 = this.field) === null || _this$field2 === void 0 ? void 0 : _this$field2.name);
      }
    },

    actions: {
      /**
       * Action update min year and call actionHandler
       * @param {number} minYear - value from year picker
       */
      updateMinYear(minYear) {
        let _minYear = minYear || null;

        const maxYear = this.max || null;

        if (maxYear && minYear > maxYear) {
          _minYear = null;
        }

        this.set('min', _minYear);
        this.set('field.data', [_minYear, maxYear]);

        this._callActionHandler();
      },

      /**
       * Action update max year and call actionHandler
       * @param {number} maxYear - value from year picker
       */
      updateMaxYear(maxYear) {
        let _maxYear = maxYear || null;

        const minYear = this.min || null;

        if (minYear && minYear > maxYear) {
          _maxYear = null;
        }

        this.set('max', _maxYear);
        this.set('field.data', [minYear, _maxYear]);

        this._callActionHandler();
      }

    }
  });

  _exports.default = _default;
});