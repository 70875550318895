define("builder/pods/components/field-textarea-content/component", ["exports", "jquery", "builder/mixins/field-visibility"], function (_exports, _jquery, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form field, used for configuring modules text
   */
  var _default = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['field-textarea-content'],

    /**
     * @property {Number} textareaMaxlength - max characters length allowed for textarea
     */
    textareaMaxlength: 2000,

    /**
     * @property {null|Object} field - passed in data
     */
    field: null,

    /**
     * @property {String} title - field title
     */
    title: Ember.computed.alias('field.name'),

    /**
     * @property {String} content - field text
     */
    content: Ember.computed.alias('field.data'),

    /**
     * @property {Number} height - height of cover
     */
    height: Ember.String.htmlSafe('height: 0px'),

    /**
     * @property {Number} _contentHeight - height of textarea
     */
    _contentHeight: 55,

    /**
     * @property {Number} _coverHeight - height of cover
     */
    _coverHeight: 25,

    /**
     * @property {String} _textareaClass - textarea class name(basic)
     */
    _textareaClass: 'field-textarea-content__textarea',

    /**
     * @property {String} _textareaNotEmptyClass - textarea class to be added when text is not empty
     */
    _textareaNotEmptyClass: 'field-textarea-content__textarea_is-not-empty',

    /**
     * @property {String} _phantomTemplate - phantom element template/blueprint
     */
    _phantomTemplate: '<div class="field-textarea-content__textarea-phantom"></div>',

    /**
     * @property {jQuery} _phantom - reference to phantom element
     */
    _phantom: null,

    /**
     * @property {Function} onChange - callback to be invoked when content changes
     */
    onChange() {},

    /**
     * @property {Ember.Computed} textareaClass -
     */
    textareaClass: Ember.computed('content', function () {
      const classes = [this._textareaClass];
      const content = this.content || '';

      if (content.length) {
        classes.push(this._textareaNotEmptyClass);
      }

      return classes.join(' ');
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_phantom', this._createPhantom());
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      this._phantom.remove();

      this.set('_phantom', null);
    },

    /**
     * Prepare textarea content to be inserted into div
     *
     * @param {String} text - textarea content
     * @returns {String} - prepared html to be inserted into
     * @private
     */
    _prepareHTML(text) {
      return text.replace(/\n/g, '<br/>').replace('&nbsp;', ' ');
    },

    /**
     * Create phantom element to contain textarea content(used to measure it's height and set the same height to
     * textarea, to make textarea content fit it's text)
     *
     * @returns {jQuery} - jQuery phantom element, already appended into body
     * @private
     */
    _createPhantom() {
      const phantom = (0, _jquery.default)(this._phantomTemplate); // Should be always visible

      (0, _jquery.default)(document.body).append(phantom);
      return phantom;
    },

    /**
     * Update textarea height
     *
     * @param {jQuery} textarea - jQuery textarea element which height to update
     * @private
     */
    _updateHeight(textarea) {
      const phantom = this._phantom;

      this._setPhantomContent(this._prepareHTML(textarea.val()), '<br/>');

      const height = phantom.outerHeight();
      textarea.css('height', `${height}px`);
      this.set('height', Ember.String.htmlSafe(`height: ${Math.min(height - this._contentHeight, this._coverHeight)}px`));
    },

    /**
     * Update phantom element content
     *
     * @param {String} content - string to append to phantom element content
     * @param {String} delimiter - linebreak character
     * @returns {jQuery} - phantom element itself
     * @private
     */
    _setPhantomContent(content, delimiter) {
      const phantom = this._phantom;
      const chunks = content.split(delimiter);
      const pTags = [];

      this._phantom.empty();

      for (let i = 0, len = chunks.length; i < len; i++) {
        pTags.push((0, _jquery.default)('<p></p>').text(chunks[i]));
      }

      phantom.append(...pTags);
      return phantom;
    },

    actions: {
      onRender(textarea) {
        this._updateHeight(textarea);
      },

      onFocus(textarea) {
        textarea.css('max-height', 'none');
      },

      onBlur(textarea) {
        textarea.css('max-height', '');
      },

      onKeyUp(textarea) {
        this._updateHeight(textarea);
      },

      onChange(text) {
        this.set('content', text);
        this.onChange(text);
      }

    }
  });

  _exports.default = _default;
});