define("builder/models/menu-child-link", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Model = _fragment.default.extend({
    /**
     * @property {String} target - Type of target of the link
     */
    target: (0, _model.attr)('string'),

    /**
     * @property {String} type - Type of link (internal or external)
     */
    type: (0, _model.attr)('string'),

    /**
     * @property {String} value - Address of the link
     */
    value: (0, _model.attr)('string'),

    /**
     * @property {Object} params - link get params
     */
    params: (0, _model.attr)('raw')
  });

  Model.reopenClass({
    /**
     * @property {String} INTERNAL - internal link
     */
    INTERNAL: 'internal',

    /**
     * @property {String} EXTERNAL - external link
     */
    EXTERNAL: 'external'
  });
  var _default = Model;
  _exports.default = _default;
});