define("builder/errors/merge-error", ["exports", "@ember-data/adapter/error", "builder/core/literals"], function (_exports, _error, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _error.default.extend({
    message: _literals.default.SERVER_MERGE_ERROR
  });

  _exports.default = _default;
});