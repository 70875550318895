define("builder/pods/components/page-meta-import/component", ["exports", "js-yaml", "builder/models/meta-item"], function (_exports, _jsYaml, _metaItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PageMetaImportComponent = (_dec = Ember._action, (_class = (_temp = class PageMetaImportComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['page-meta-button'];

      this.updateMetaField = (value, name) => {
        throw new Ember.Error('method should be provided');
      };

      this.onImportError = msg => {
        throw new Ember.Error('method should be provided');
      };
    }

    /**
     * @inheritdoc
     */
    didRender() {
      const [inputElement] = this.element.getElementsByTagName('input');

      if (!inputElement) {
        return;
      }

      inputElement.addEventListener('change', e => this.readYamlFile(e));
    }
    /**
     * Reads file content
     * @param {Event} event
     * @private
     */


    readYamlFile(event) {
      const {
        files
      } = event.target;
      const [file] = files;

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = evt => {
        this.loadFileContent(evt.target.result); // We need to clear input content so we could upload same file again

        event.target.value = '';
      };

      reader.readAsText(file);
    }
    /**
     * Parses file content and updates meta fields
     * @param {string} content - content to parse
     * @returns {void}
     */


    loadFileContent(content) {
      const meta = _jsYaml.default.safeLoad(content);
      /**
       * We always serialize page meta as array. So if no array received, invalid
       * input was provided
       */


      if (!Array.isArray(meta)) {
        const errorMessage = 'Invalid file structure. Array of items should be provided';
        this.onImportError(errorMessage);
        return;
      }

      const allowedKeys = Object.keys(_metaItem.MetaItemObject);
      const isMetaValid = meta.every(item => this.isPageMetaItemValid(item, allowedKeys));

      if (!isMetaValid) {
        const errorMessage = 'Invalid import. Provided items does not contain meta fields';
        this.onImportError(errorMessage);
      }

      meta.forEach(item => this.removeRedundantFields(item, allowedKeys));
      this.updateMetaField(meta);
    }
    /**
     * Validates meta item according current meta-item model interface
     * @param {object} metaItem - item to validate
     * @param {string[]} allowedKeys - keys that belongs to meta-item
     */


    isPageMetaItemValid(metaItem, allowedKeys) {
      return Object.keys(metaItem).some(key => allowedKeys.includes(key));
    }
    /**
     * Removes fields which are not belong to meta item model
     * @param {object} metaItem - item to change
     * @param {string[]} allowedKeys - keys that belongs to meta-item
     */


    removeRedundantFields(metaItem, allowedKeys) {
      Object.keys(metaItem).forEach(key => {
        if (allowedKeys.includes(key)) {
          return;
        }

        delete metaItem[key];
      });
    }
    /**
     * Opens file upload window
     * @returns {void}
     */


    openFileUpload() {
      if (!window.confirm('Warning: this will overwrite all the existing meta on this page. If you want to add to the existing meta please export the file and then edit before uploading.')) {
        return;
      }

      const inputElement = this.element.getElementsByTagName('input')[0];
      inputElement.click();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "openFileUpload", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "openFileUpload"), _class.prototype)), _class));
  _exports.default = PageMetaImportComponent;
});