define("builder/services/configuration-module", ["exports", "@ember-data/adapter/error", "builder/core/enumerators/module"], function (_exports, _error, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for operations with modules within configuration context
   */
  let ConfigurationModuleService = (_dec = Ember.inject.service('ajax'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('structure'), _dec4 = Ember.inject.service('modules-store'), (_class = (_temp = class ConfigurationModuleService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_ajaxService", _descriptor, this);

      _initializerDefineProperty(this, "_store", _descriptor2, this);

      _initializerDefineProperty(this, "_structureService", _descriptor3, this);

      _initializerDefineProperty(this, "_modulesService", _descriptor4, this);
    }

    /**
     * Saves all modules
     *
     * TODO: this method should be refactored to get rid of controllers direct
     * links.
     * @param {ModuleSaveAllCommand} command - modules save parameters
     * @returns {Promise<void>}
     */
    async saveAll({
      page,
      isPageStructureChanged,
      isFormModuleChanged,
      isModulesConfigChanged
    }) {
      this._clearModuleStyles({
        page
      }); // For save module configs use request to save page without body
      // Purpose for backend: move changed module configs from temp table to persistent table


      if (!page.isDeleted && !isPageStructureChanged && (isFormModuleChanged || isModulesConfigChanged)) {
        const adapter = this._store.adapterFor('page');

        const url = adapter.buildURL('page', page.id);
        await this._ajaxService.request(url, {
          method: 'PUT'
        }); // Modules saved, now we can change pageModules state to saved, to make it kasherno

        this._store.peekAll('page-module').forEach(module => module.save({
          adapterOptions: {
            fakeRequest: true
          }
        }));
      }
    }
    /**
     * Handles MODULE_SAVE_ALL_COMPLETED domain event
     * @returns {Promise<void>}
     */


    async onSaveAllCompleted() {
      /**
       * reload all forms and category items
       */
      await Promise.all([this._store.findAll('form', {
        reload: true
      }), this._store.findAll('module-category-item', {
        reload: true
      })]); // We need to update form module configs to get most up to date form title and subject
      // This happens when you already have several loaded form configs and you updated one of them
      // Backend updates all related this form configs after save

      const pages = this._store.peekAll('page');

      const promises = this._store.peekAll('page-module').map(async config => {
        // We found form module config and make sure page is not deleted
        if (config.Structure.formId && !config.isDeleted && pages.findBy('id', config.PageId)) {
          try {
            await config.reload();
          } catch (error) {
            if (error instanceof _error.NotFoundError) {
              config.unloadRecord();
            }
          }
        }
      });

      await Promise.all(promises);
    }
    /**
     * Clear unused module styles
     *
     * @returns {void}
     * @private
     */


    _clearModuleStyles({
      page
    }) {
      const rawPageStructure = this._structureService.pageViewModel;
      const pageModules = [];

      this._structureService.findModules(rawPageStructure, _module.default.MODULE_CORE, pageModules);

      this._structureService.findModules(rawPageStructure, _module.default.MODULE_FORM, pageModules);

      const pageModuleIds = pageModules.reduce((hash, module) => {
        hash[module.originObject.id] = true;
        return hash;
      }, {}); // TODO: refactor to map() function

      const systemModuleIds = this._modulesService.getList().filter(module => module.originObject.type === _module.default.MODULE_SYSTEM).reduce((hash, module) => {
        hash[module.originObject.id] = true;
        return hash;
      }, {});

      this._store.peekAll('module-style').forEach(model => {
        // Clear only not saved on backend models and skip turned on system modules
        if (!model.isCanBeUnload || systemModuleIds.hasOwnProperty(model.id)) {
          return;
        }

        if (page.get('isDeleted') && pageModuleIds.hasOwnProperty(model.id) || !pageModuleIds.hasOwnProperty(model.id)) {
          model.unloadRecord();
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_structureService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_modulesService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ConfigurationModuleService;
});