define("builder/core/page-type-provider", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    /**
     * List of available types
     * @type {object[]}
     */
    availableTypes: [],

    /**
     * Default type
     * @type {object}
     */
    defaultType: {},

    /**
     * Build config for page type UI interface
     * @param {object} data - source data for initialize config
     * @param {string[]} existsTypes - exist page types for exclude its from list
     * @returns {object}
     */
    buildConfig(data, existsTypes) {
      const config = this._getFieldsConfig(this.availableTypes);

      if (existsTypes && existsTypes.length) {
        const pageTypeField = config.findBy('name', 'page-type');
        pageTypeField.choices = pageTypeField.choices.reject(choice => choice.isUniq && existsTypes.includes(choice.value));
      }

      if (data && Object.keys(data).length) {
        config.forEach(field => {
          if (data && data.hasOwnProperty(field.name)) {
            field.data = data[field.name];
          }
        });
      }

      return config;
    },

    /**
     * Find page type definition by value
     * @param {string} value - page type value
     * @returns {object}
     */
    findByValue(value) {
      return this.availableTypes.findBy('value', value);
    },

    /**
     * Get field configurations to display in UI
     * @param {object[]} availableTypes - available page types
     * @return {object[]}
     * @private
     */
    _getFieldsConfig(availableTypes) {
      return [{
        type: 'field-dropdown',
        label: 'Page Type',
        name: 'page-type',
        data: this.get('defaultType.value'),
        help: 'Page type is used by analytics system to identify pages on your website.',
        choices: (0, _emberCopy.copy)(availableTypes, true)
      }, {
        type: 'field-dropdown',
        label: 'Type',
        name: 'Type',
        choices: [{
          label: 'Not Set',
          value: 'Not Set'
        }, {
          label: 'New',
          value: 'New'
        }, {
          label: 'Used',
          value: 'Used'
        }],
        data: 'Not Set',
        showOnlyIf: [{
          field: 'page-type',
          equals: 'model-clp'
        }]
      }, {
        type: 'field-text',
        label: 'Year',
        name: 'Year',
        showOnlyIf: [{
          field: 'page-type',
          equals: 'model-clp'
        }]
      }, {
        type: 'field-text',
        label: 'Make',
        name: 'Make',
        showOnlyIf: [{
          field: 'page-type',
          equals: 'model-clp'
        }]
      }, {
        type: 'field-text',
        label: 'Model',
        name: 'Model',
        showOnlyIf: [{
          field: 'page-type',
          equals: 'model-clp'
        }]
      }, {
        type: 'field-text',
        label: 'Trim',
        name: 'Trim',
        showOnlyIf: [{
          field: 'page-type',
          equals: 'model-clp'
        }]
      }, {
        type: 'field-textarea',
        label: 'HTML Code',
        name: 'Html',
        showOnlyIf: [{
          field: 'page-type',
          equals: 'static-html'
        }]
      }];
    }

  });

  _exports.default = _default;
});