define("builder/pods/website/edit/page/edit/settings/setting/route", ["exports", "builder/pods/website/edit/page/edit/settings/index/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    /**
     * @inheritdoc
     */
    renderTemplate(controller, model) {
      this.render('website.edit.page.edit.settings.index', {
        model,
        controller
      });
    }

  });

  _exports.default = _default;
});