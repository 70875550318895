define("builder/widgets/configs/widget-container", ["exports", "ember-copy", "builder/core/styles-management/widget-container/structure", "builder/widgets/configs/widget-container.options", "builder/widgets/configs/animation.options"], function (_exports, _emberCopy, _structure, _widgetContainer, _animation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Widget container config
   */
  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      const configuration = {
        styles: (0, _emberCopy.copy)(_structure.default, true),
        config: [_widgetContainer.anchor, _widgetContainer.videoBackground, _widgetContainer.fullWidthColumns, _widgetContainer.deviceVisibility, _widgetContainer.vehicleTargeting, (0, _animation.animationEnabled)('Container'), _animation.animationName, _animation.animationDuration, _animation.animationMirroring, _animation.enableAnimationOnDevice]
      };
      return configuration;
    }

  });

  _exports.default = _default;
});