define("builder/core/data-setters/fields/field-media", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldMediaDataSetter
   */
  var _default = Ember.Object.extend({
    /**
     * Entry point to set data to field-media field
     *
     * @param {Object} holder - data holder, like {source: <here data will be set>}
     * @param {String} fieldName - key under which url is stored, probably should be unified and removed from here
     * @param {Object} data - data to be converted from field-media consumable to storage format
     * @param {String} mode - breakpoint mode
     */
    setData(holder, fieldName, data, mode) {
      // Default media type is image
      Ember.set(holder, 'type', data && data.type || 'image');
      Ember.set(holder, fieldName, data && data.url || data);
      Ember.set(holder, 'naturalWidth', data && data.hasOwnProperty('naturalWidth') ? data.naturalWidth : 0);
      Ember.set(holder, 'naturalHeight', data && data.hasOwnProperty('naturalHeight') ? data.naturalHeight : 0);

      if (!holder.hasOwnProperty('focalPoints')) {
        Ember.set(holder, 'focalPoints', {
          [_breakpointModes.LARGE]: null,
          [_breakpointModes.MEDIUM]: null,
          [_breakpointModes.SMALL]: null,
          [_breakpointModes.EXTRA_SMALL]: null
        });
      }

      Ember.set(holder, `focalPoints.${mode}`, data && data.hasOwnProperty('min') && data.hasOwnProperty('zoomLevel') ? {
        scale: Math.round(data.min / data.zoomLevel * 100)
      } : null);

      if (Ember.get(holder, `focalPoints.${mode}`) !== null && Ember.get(holder, `focalPoints.${mode}`).hasOwnProperty('scale')) {
        Ember.set(holder, `focalPoints.${mode}.left`, Math.round(Ember.get(holder, 'naturalWidth') * data.left / 100) || 0);
        Ember.set(holder, `focalPoints.${mode}.top`, Math.round(Ember.get(holder, 'naturalHeight') * data.top / 100) || 0);
      }
    }

  });

  _exports.default = _default;
});