define("builder/core/enumerators/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Notification = void 0;
  const Notification = {
    TYPE: {
      SUCCESS: 'success',
      ERROR: 'error',
      INFO: 'info',
      WARNING: 'warning'
    }
  };
  _exports.Notification = Notification;
  var _default = Notification;
  _exports.default = _default;
});