define("builder/core/util/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Check value for object type
   * @param {*} value - checked value
   * @return {boolean}
   */
  function isObject(value) {
    return value && typeof value === 'object' && !Ember.isArray(value);
  }

  var _default = isObject;
  _exports.default = _default;
});