define("builder/pods/components/field-number-with-units/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * Element class name.
     * @property
     */
    classNames: ['bld-form_number_with_units'],

    /**
     * @property {String} unit - unit of value
     * @readonly
     */
    unit: Ember.computed('field.{data,units}', function () {
      const data = (this.get('field.data') || '').toString().replace(/\s/g, '');

      let unit = this._unitExtractRegex.exec(data);

      const units = this.get('field.units');

      if (unit && unit.length) {
        unit = unit.pop();
      } else {
        unit = units[0].symbol;
      }

      return unit;
    }).readOnly(),

    /**
     * @property {Number} - numeric value
     * @readonly
     */
    value: Ember.computed('field.data', function () {
      return parseFloat(this.get('field.data')) || '';
    }).readOnly(),

    /**
     * @property {Object[]} units - available units
     * @readonly
     */
    units: Ember.computed('field.units', function () {
      const units = this.get('field.units') || {};
      const data = (this.get('field.data') || '').toString().replace(/\s/g, '');

      let unit = this._unitExtractRegex.exec(data);

      const options = [];

      if (unit && unit.length) {
        unit = unit.pop();
      } else {
        unit = units[0].symbol;
      }

      for (let i = 0; i < units.length; i++) {
        options.push({
          value: units[i].symbol,
          label: units[i].label,
          checked: units[i].symbol === unit
        });
      }

      return options;
    }).readOnly(),
    max: Ember.computed('field.max', function () {
      return this.get('field.max');
    }).readOnly(),
    min: Ember.computed('field.min', function () {
      return this.get('field.min') || 1;
    }).readOnly(),

    /**
     * @property {RegExp} _unitExtractRegex - regexp for extract unit from raw value
     * @private
     */
    _unitExtractRegex: /\d(\D+)$/,

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * Updates units after value change
     * @param {String} value - New value
     */
    updateUnits(value) {
      this.units.forEach(unit => {
        unit.checked = unit.value === value;
      });
    },

    actions: {
      onValueChange(e) {
        let newValue = parseFloat(e.target.value);
        const oldValue = parseFloat(this.get('field.data'));
        let action = null;

        if (newValue === oldValue) {
          return;
        }

        if (this.min && newValue < this.min) {
          newValue = this.min;
        }

        if (this.max && newValue > this.max) {
          newValue = this.max;
        }

        newValue = isNaN(newValue) ? null : `${newValue}${this.unit}`;
        this.set('field.data', newValue);
        action = this.actionHandler;

        if (action && !this.notValid) {
          action(newValue, this.get('field.name'));
        }
      },

      onUnitChange(value) {
        let newValue = parseFloat(this.get('field.data'));
        let action = null;
        newValue = isNaN(newValue) ? null : `${newValue}${value}`;
        this.set('field.data', newValue);
        this.updateUnits(value);
        action = this.actionHandler;

        if (action && !this.notValid) {
          action(newValue, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});