define("builder/models/website-css", ["exports", "ember-data-model-fragments/attributes", "builder/models/model"], function (_exports, _attributes, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WebsiteCSSModel
   */
  var _default = _model.default.extend({
    /**
     * @property {ModuleCSSFragment} CSS - global css by breakpoints
     */
    CSS: (0, _attributes.fragment)('style-css-fragment')
  });

  _exports.default = _default;
});