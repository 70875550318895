define("builder/core/enumerators/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FORM_PREV = _exports.FORM_NEXT = _exports.FORM_SUBMIT = void 0;
  // Form button submit type
  const FORM_SUBMIT = 'button-submit'; // Form button next type

  _exports.FORM_SUBMIT = FORM_SUBMIT;
  const FORM_NEXT = 'button-next'; // Form button prev type

  _exports.FORM_NEXT = FORM_NEXT;
  const FORM_PREV = 'button-prev';
  _exports.FORM_PREV = FORM_PREV;
});