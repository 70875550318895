define("builder/services/batch-operations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for handling batch operations
   */
  let BatchOperationsService = (_dec = Ember.inject.service('configuration-website'), _dec2 = Ember.inject.service('configuration-form'), _dec3 = Ember.inject.service('configuration-system-module'), _dec4 = Ember.inject.service('configuration-module'), _dec5 = Ember.inject.service('configuration-page-seo'), _dec6 = Ember.inject.service('configuration-module-style'), _dec7 = Ember.inject.service('configuration-page-meta'), _dec8 = Ember.inject.service('configuration-market-segment'), _dec9 = Ember.inject.service('configuration-website-setting'), _dec10 = Ember.inject.service('configuration-website-style'), _dec11 = Ember.inject.service('configuration-meta-variables'), _dec12 = Ember.inject.service('rendering-structure'), _dec13 = Ember.inject.service('website-management-menu'), _dec14 = Ember.inject.service('history'), _dec15 = Ember.inject.service('configuration-website-config'), (_class = (_temp = class BatchOperationsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteConfigurationService", _descriptor, this);

      _initializerDefineProperty(this, "configurationFormService", _descriptor2, this);

      _initializerDefineProperty(this, "configurationSystemModuleService", _descriptor3, this);

      _initializerDefineProperty(this, "configurationModuleService", _descriptor4, this);

      _initializerDefineProperty(this, "configurationPageSeoService", _descriptor5, this);

      _initializerDefineProperty(this, "configurationModuleStyleService", _descriptor6, this);

      _initializerDefineProperty(this, "configurationPageMetaService", _descriptor7, this);

      _initializerDefineProperty(this, "configurationMarketSegmentService", _descriptor8, this);

      _initializerDefineProperty(this, "configurationWebsiteSettingService", _descriptor9, this);

      _initializerDefineProperty(this, "configurationWebsiteStyleService", _descriptor10, this);

      _initializerDefineProperty(this, "configurationMetaVariablesService", _descriptor11, this);

      _initializerDefineProperty(this, "renderingStructureService", _descriptor12, this);

      _initializerDefineProperty(this, "websiteManagementMenuService", _descriptor13, this);

      _initializerDefineProperty(this, "history", _descriptor14, this);

      _initializerDefineProperty(this, "ConfigurationWebsiteConfigService", _descriptor15, this);
    }

    /**
     * Changes website status to published
     * @param {BatchPublishCommand} message - parameters for publish operation
     * @returns {Promise<void>}
     */
    async publish({
      websiteId
    }) {
      this.websiteConfigurationService.update(websiteId, {
        IsPublished: '1'
      });
      await this.websiteConfigurationService.save(websiteId);
    }
    /**
     * Saves all connected entities
     * @param {BatchSaveCommand} message - parameters for save operation
     * @returns {Promise<void>}
     */


    async save({
      websiteId,
      isFormModuleChanged,
      isPageStructureChanged,
      isModulesConfigChanged,
      website,
      page,
      menu,
      layout,
      _publishLayouts,
      setPublishLayout,
      modifiedSystemModules,
      setModifiedSystemModules,
      supportContactsMessage,
      showNotification,
      notifyAboutGlobalPageMenuInsertionError
    }) {
      // Save form
      await this.configurationFormService.saveAll({
        isFormModuleChanged,
        page,
        website,
        supportContactsMessage,
        showNotification
      }); // // Save system modules

      await this.configurationSystemModuleService.saveAll({
        setPublishLayout,
        modifiedSystemModules,
        setModifiedSystemModules
      }); // // Save structure

      await this.renderingStructureService.save({
        page,
        isPageStructureChanged,
        layout,
        _publishLayouts,
        setPublishLayout,
        notifyAboutGlobalPageMenuInsertionError
      }); // // Save page and menu structure

      await Promise.all([this.renderingStructureService.onSaveCompleted({
        page
      }), this.websiteManagementMenuService.save({
        menu
      })]); // // Save modules

      await this.configurationModuleService.saveAll({
        page,
        isPageStructureChanged,
        isFormModuleChanged,
        isModulesConfigChanged
      });
      await this.configurationModuleService.onSaveAllCompleted(); // /**
      //  * Parallel save
      //  * - page seo urls
      //  * - all module styles
      //  *
      //  * - page meta tags
      //  * - market segments
      //  *
      //  * - style variables
      //  * - meta variables
      //  * - website settings
      //  */

      await Promise.all([this.configurationPageSeoService.saveAll(), this.configurationModuleStyleService.saveAll(), this.configurationPageMetaService.saveAll(), this.configurationMarketSegmentService.saveAll(), this.configurationWebsiteSettingService.save({
        websiteId
      }), this.configurationWebsiteStyleService.save({
        websiteId
      }), this.configurationMetaVariablesService.save({
        websiteId
      }), this.ConfigurationWebsiteConfigService.save({
        websiteId
      })]); // // Clear all history checkpoints

      await this.history.clear();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteConfigurationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "configurationFormService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "configurationSystemModuleService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "configurationModuleService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "configurationPageSeoService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "configurationModuleStyleService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "configurationPageMetaService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "configurationMarketSegmentService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "configurationWebsiteSettingService", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "configurationWebsiteStyleService", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "configurationMetaVariablesService", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "renderingStructureService", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "websiteManagementMenuService", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "history", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "ConfigurationWebsiteConfigService", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BatchOperationsService;
});