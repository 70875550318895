define("builder/models/widget", ["exports", "@ember-data/model", "builder/models/model", "builder/core/enumerators/widget"], function (_exports, _model, _model2, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const imageMap = {
    [_widget.WidgetType.WIDGET_PARAGRAPH]: '/images/widgets/widget-paragraph.png',
    [_widget.WidgetType.WIDGET_IMAGE]: '/images/widgets/widget-image.png',
    [_widget.WidgetType.WIDGET_BUTTON]: '/images/widgets/widget-button.png',
    [_widget.WidgetType.WIDGET_BUTTONSGROUP]: '/images/widgets/widget-buttonsgroup.png',
    [_widget.WidgetType.WIDGET_SPACER]: '/images/widgets/widget-spacer.svg',
    [_widget.WidgetType.WIDGET_LINE_DIVIDER]: '/images/widgets/widget-line-divider.svg'
  };
  const defaultImage = imageMap[_widget.WidgetType.WIDGET_PARAGRAPH];
  /**
   * @class WidgetModel
   */

  var _default = _model2.default.extend({
    /**
     * Widget system name
     * @type {string}
     */
    Name: (0, _model.attr)('string'),

    /**
     * Widget title/label
     * @type {string}
     */
    Label: (0, _model.attr)('string'),

    /**
     * Global page capability ('1' - compatible, '0' - incompatible)
     * @type {'1' | '0'}
     */
    IsGlobalPageCompatible: (0, _model.attr)('string', {
      defaultValue: '1'
    }),

    /**
     * Global page capability
     * @type {boolean}
     * @readonly
     */
    isGlobalPageCompatible: Ember.computed.equal('IsGlobalPageCompatible', '1').readOnly(),

    /**
     * Widget image to be displayed in module gallery
     * @type {string}
     * @readonly
     */
    Image: Ember.computed('Name', function () {
      return imageMap[this.Name] || defaultImage;
    }).readOnly()
  });

  _exports.default = _default;
});