define("builder/services/module-gallery-item-builder", ["exports", "builder/core/enumerators/container", "builder/core/enumerators/widget"], function (_exports, _container, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModuleGalleryItemBuilder = (_dec = Ember.inject.service('grid-builder'), _dec2 = Ember.inject.service('tab'), (_class = (_temp = class ModuleGalleryItemBuilder extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "gridBuilder", _descriptor, this);

      _initializerDefineProperty(this, "tabService", _descriptor2, this);
    }

    /**
     * Recursively creates instances for the structure
     * @param {string} type - Object type to create instance for
     * @param {Object} [data] - Data for new instance
     *
     * @returns {Ember.Object}
     */
    createInstance(type, data = {}) {
      switch (type) {
        case _container.default.WIDGET_CONTAINER:
        case _container.default.WIDGET_COLUMN:
        case _widget.WidgetType.WIDGET_SECTION:
        case _widget.WidgetType.WIDGET_TAB_PANE:
        case _container.default.WIDGET_ROW:
          {
            return this._createContainerInstance(type, data);
          }

        case _container.default.WIDGET_TABS:
          {
            return this._createTabsWidgetInstance(data);
          }

        default:
          return this.gridBuilder.createInstance(type, data);
      }
    }
    /**
     * Creates page structure instance for container and its children
     * @param {string} type - Object type to create instance for
     * @param {Object} [data] - Data for new instance
     *
     * @private
     * @returns {Ember.Object}
     */


    _createContainerInstance(type, data) {
      var _instance$originObjec;

      const instance = this.gridBuilder.createInstance(type, data);
      const children = (instance === null || instance === void 0 ? void 0 : (_instance$originObjec = instance.originObject) === null || _instance$originObjec === void 0 ? void 0 : _instance$originObjec.children) || [];
      const instancedChildren = [];
      children.forEach(child => {
        const childInstance = this.createInstance(child.type, child); // Restore parent because "gridBuilder.createInstance" sets to null

        childInstance.parent = instance;
        instancedChildren.push(childInstance);
      });
      instance.originObject.set('children', instancedChildren);
      return instance;
    }
    /**
     * Creates tabs widget with the default tab pane
     * @param {Object} [data] - Data for new instance
     *
     * @private
     * @returns {Ember.Object}
     */


    _createTabsWidgetInstance(data) {
      const instance = this._createContainerInstance(_container.default.WIDGET_TABS, data);

      if (!data || data && Object.keys(data).length === 0) {
        this.tabService.addTabPane(instance);
      }

      return instance;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gridBuilder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tabService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ModuleGalleryItemBuilder;
});