define("builder/pods/components/widget-image/component", ["exports", "builder/core/widget", "builder/core/enumerators/module-action", "jquery", "builder/utils/geometry", "builder/core/enumerators/breakpoint-modes", "builder/core/froala", "builder/core/froala/settings/widget-image-settings", "builder/config/environment", "builder/helpers/helpers"], function (_exports, _widget, _moduleAction, _jquery, _geometry, _breakpointModes, _froala, _widgetImageSettings, _environment, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const IMAGE_WRAP_SELECTOR = '.b2-widget-image';

  /**
   * @class WidgetImageComponent
   */
  var _default = _widget.default.extend({
    /**
     * @property {String[]} classNames - Component class names
     */
    classNames: ['page_module'],

    /**
     * @property {Number} width - current widget width
     */
    width: 0,

    /**
     * @property {Number} height - image natural height
     */
    height: 0,

    /**
     * @type {boolean} isEditorMode - determine if active in image resize mode
     */
    isEditorMode: false,

    /**
     * @type {boolean} showOverlay - show overlay with edit button
     */
    showOverlay: false,

    /**
     * @type {String} editButtonIcon - edit button icon
     */
    editButtonIcon: `${_environment.default.rootURL}images/widgets/edit/resize-image.svg`,

    /**
     * @property {Number} resizeImageWidth - width of the image being resized
     */
    resizeImageWidth: null,

    /**
     * @property {Number} resizeImageHeight - height of the image being resized
     */
    resizeImageHeight: null,

    /**
     * @property {Boolean} determines if we can show resize tooltip
     */
    showWidthAndHeightTooltip: false,

    /**
     * @property {Number} aspectRation - image aspect ratio
     */
    aspectRatio: Ember.computed('data.originObject', 'data.originObject.naturalWidth', 'data.originObject.naturalHeight', 'height', function () {
      const height = this.get('data.originObject.naturalHeight') || this.height;
      const width = this.get('data.originObject.naturalWidth') || 1;
      return width / height;
    }),

    /**
     * @property {Number} focalX - x coordinate of focal point
     */
    focalX: Ember.computed('configurationService.currentDevice.breakpoint', `data.originObject.focalPoints.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}.left`, function () {
      const mode = this.get('configurationService.currentDevice.breakpoint');
      return this.get(`data.originObject.focalPoints.${mode}.left`);
    }),

    /**
     * @property {Number} focalY - y coordinate of focal point
     */
    focalY: Ember.computed('configurationService.currentDevice.breakpoint', `data.originObject.focalPoints.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}.top`, function () {
      const mode = this.get('configurationService.currentDevice.breakpoint');
      return this.get(`data.originObject.focalPoints.${mode}.top`);
    }),

    /**
     * @property {ConfigurationService} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Double click handler
     *
     * @method
     * @returns {*}
     */
    doubleClick(...args) {
      if (!this.isEditorMode) {
        this.moduleAction(_moduleAction.default.EDIT, this.data);
      }

      return this._super(...args);
    },

    /**
     * Activate module
     *
     * @method
     * @returns {*}
     */
    click(...args) {
      this._super(...args);

      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
    },

    /**
     * @property {String} alignedClass - Get class based on aligned property
     */
    alignedClass: Ember.computed('data.originObject.aligned', function () {
      const aligned = this.data.get('originObject').get('aligned');

      if (Ember.isEmpty(aligned) || aligned === 'left') {
        return '';
      }

      return `__align-${aligned}`;
    }),

    /**
     * @property {String} imageWidthStyle - Get breakpoint based width of image
     */
    imageWidthStyle: Ember.computed('data.originObject.styles.{lg,md,sm,xs}.@each.value', 'mode', function () {
      var _this$data, _this$data$originObje, _this$data$originObje2;

      const imageWidthStyle = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : (_this$data$originObje2 = _this$data$originObje.styles[this.mode]) === null || _this$data$originObje2 === void 0 ? void 0 : _this$data$originObje2.find(style => style.name === 'width');

      if (!(imageWidthStyle === null || imageWidthStyle === void 0 ? void 0 : imageWidthStyle.name) || !(imageWidthStyle === null || imageWidthStyle === void 0 ? void 0 : imageWidthStyle.value)) {
        return '';
      }

      if (imageWidthStyle.value === '100%') {
        return 'max-width: 100%';
      }

      let width = imageWidthStyle.value;
      width = parseInt(width.toString().replace('px', ''), 10);
      this.set('data.clientWidth', width);
      this.set('data.clientHeight', (0, _helpers.getHeight)(width));
      return `width: ${imageWidthStyle.value}`;
    }).readOnly(),

    /**
     * @property {String} marginBottomStyle - Get breakpoint based margin-bottom of image
     */
    marginBottomStyle: Ember.computed('data.originObject.styles.{lg,md,sm,xs}.@each.value', 'mode', function () {
      var _this$data2, _this$data2$originObj, _this$data2$originObj2;

      const marginBottomStyle = (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : (_this$data2$originObj2 = _this$data2$originObj.styles[this.mode]) === null || _this$data2$originObj2 === void 0 ? void 0 : _this$data2$originObj2.find(style => style.name === 'margin-bottom');

      if (!(marginBottomStyle === null || marginBottomStyle === void 0 ? void 0 : marginBottomStyle.name) || !(marginBottomStyle === null || marginBottomStyle === void 0 ? void 0 : marginBottomStyle.value)) {
        return '';
      }

      return `${marginBottomStyle.name}: ${marginBottomStyle.value}`;
    }).readOnly(),

    /**
     * @property {String} margin - image margin to use for setting focal point
     */
    margin: Ember.computed(`data.originObject.focalPoints.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}.scale`, 'configurationService.currentDevice.breakpoint', // TODO: Well, this isn't proper way of detecting, we need notification system, top to bottom
    'data.parent.width', 'data.originObject.src', 'aspectRatio', 'focalX', 'focalY', 'width', 'height', function () {
      const currentWidth = this.width; // We don't take actual element height, since we cannot rely on it(height is stretchable), but width is always the same,
      // hence the safest way is to calculate height based on width
      // TODO: class for image where it's actual and natural dimension will be stored, plus zooming support

      const currentHeight = currentWidth / this.aspectRatio;
      const naturalWidth = this.get('data.originObject.naturalWidth') || this.width;
      const naturalHeight = this.get('data.originObject.naturalHeight') || this.height; // This is shitty, but what I do bout this (shrug), here we rely on column width, but it's not good,
      // but we cannot calculate visible area, without knowing column width

      const min = this.$().width() / naturalWidth * 100;
      const zoom = min / this.get(`data.originObject.focalPoints.${this.get('configurationService.currentDevice.breakpoint')}.scale`);
      const zoomedWidth = naturalWidth * zoom;
      const zoomedHeight = naturalHeight * zoom;

      let horizontalBoundaries = _geometry.default.translateSegmentOnAxis(zoomedWidth, currentWidth, this.focalX * zoom);

      let verticalBoundaries = _geometry.default.translateSegmentOnAxis(zoomedHeight, currentHeight, this.focalY * zoom);

      if (!Number.isNaN(zoom)) {
        // Current width greater than scaled natural width or difference is within approximation range (1% up or down)
        if (!horizontalBoundaries || naturalWidth * zoom - currentWidth <= naturalWidth * zoom / 100 * 2) {
          horizontalBoundaries = {
            left: 0,
            right: 0
          };
        } else {
          // Since we need distance from right border, not left
          horizontalBoundaries.right = zoomedWidth - horizontalBoundaries.right;
        } // Current height greater than scaled natural height or difference is within approximation range (1% up or down)


        if (!verticalBoundaries || naturalHeight * zoom - currentHeight <= naturalHeight * zoom / 100 * 2) {
          verticalBoundaries = {
            left: 0,
            right: 0
          };
        } else {
          // Since we need distance from right border, not left
          verticalBoundaries.right = zoomedHeight - verticalBoundaries.right;
        }
      }

      return Ember.String.htmlSafe([`margin-top: -${Math.round(verticalBoundaries.left)}px`, `margin-right: -${Math.round(horizontalBoundaries.right)}px`, `margin-bottom: -${Math.round(verticalBoundaries.right)}px`, `margin-left: -${Math.round(horizontalBoundaries.left)}px`].join('; '));
    }),

    /**
     * @property {Object|Null} froalaEditor - froala editor instance object
     */
    froalaEditor: null,

    /**
     * show overlay on template when widget is in focus or hovered
     * @type {Boolean}
     */
    showEditOverlayTemplate: Ember.computed('showOverlay', 'data.isActive', 'data.originObject.src', function () {
      var _this$data3, _this$data3$originObj;

      if (!((_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : (_this$data3$originObj = _this$data3.originObject) === null || _this$data3$originObj === void 0 ? void 0 : _this$data3$originObj.src)) {
        return false;
      }

      return this.showOverlay || this.data.isActive;
    }),

    /**
     * @inheritdoc
     */
    async didRender(...args) {
      this._super(...args); // Initialize froala editor once the element .js-image-editor-active is active in DOM


      if (this.isEditorMode && !this.froalaEditor) {
        this.initializeFroalaEditor();
      } // Since immediate calculation will cause incorrect dimensions calculation(immediate invocation on didRender)
      // will cause infinite rerender


      Ember.run.debounce(this, () => {
        var _this$$;

        const width = (_this$$ = this.$()) === null || _this$$ === void 0 ? void 0 : _this$$.width();

        if (width && this.width !== width) {
          this.set('width', width);
        }
      }, 100);
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      const $img = this.$('img').first();
      const img = $img.get(0);

      this._super(...args);

      this.initializeResizeObserver();
      this.element.addEventListener('mouseenter', () => {
        this.set('showOverlay', true);
      });
      this.element.addEventListener('mouseleave', () => {
        this.set('showOverlay', false);
      });

      if (img.complete) {
        // Trigger event for recalculate widget-container height
        (0, _jquery.default)(document).trigger('changeStructure');
      }

      $img.on('load', () => {
        Ember.run(() => {
          const height = this.$('img').length ? this.$('img').get(0).naturalHeight : 0;

          if (this.height !== height) {
            this.set('height', height);
          }

          if (!this.get('data.maxWidth')) {
            this.set('data.maxWidth', $img[0].naturalWidth);
          }

          if (!this.get('data.previousWidth')) {
            this.set('data.previousWidth', $img[0].clientWidth);
          }

          if (!this.get('data.clientWidth')) {
            this.set('data.clientWidth', $img[0].clientWidth);
          }

          if (!this.get('data.clientHeight')) {
            this.set('data.clientHeight', $img[0].clientHeight);
          }
        }); // Trigger event for recalculate widget-container height

        (0, _jquery.default)(document).trigger('changeStructure');
      });
    },

    /**
     * @inheritdoc
     */
    willDestroyElement(...args) {
      this._super(...args);

      this.$('img').off('load');
    },

    /**
     * @method - Initialize Froala editor on the image wrapper div
     */
    initializeFroalaEditor() {
      var _this$element;

      const editorElement = (_this$element = this.element) === null || _this$element === void 0 ? void 0 : _this$element.querySelector('.js-image-editor-active');

      if (!editorElement) {
        return;
      }

      const me = this;
      (0, _froala.createFroalaEditor)(editorElement, function () {
        me.froalaEditor = this;
        me.handleFroalaEvents();
      }, _widgetImageSettings.froalaImageSettings);
    },

    /**
     * @method - Handle the froala events associated with image resize feature
     */
    handleFroalaEvents() {
      var _this$froalaEditor$$e;

      this.froalaEditor.events.on('image.resize', this.onImageResize.bind(this));
      this.froalaEditor.events.on('image.resizeEnd', this.onImageResizeEnd.bind(this));
      this.froalaEditor.events.on('blur', this.destroyFroalaInstance.bind(this));
      this.froalaEditor.events.on('click', e => {
        // avoid typing beside the image. frola editor allows paragraphs beside the image
        if (e.target.tagName !== 'IMG') {
          this.destroyFroalaInstance();
        }
      });
      const imageElement = (_this$froalaEditor$$e = this.froalaEditor.$el[0]) === null || _this$froalaEditor$$e === void 0 ? void 0 : _this$froalaEditor$$e.querySelector('.b2-widget-image__img');

      if (imageElement) {
        imageElement.click();
      }

      this.froalaEditor.events.trigger('focus', [], true);
    },

    /**
     * @method - Handle image resize end event - update the local width and height values
     * @param {object} event - resize event object
     */
    onImageResize(event) {
      this.set('showWidthAndHeightTooltip', true);
      this.set('resizeImageWidth', event[0].width);
      this.set('resizeImageHeight', event[0].height);
    },

    /**
     * Widget service
     * @type {WidgetService}
     */
    widgetService: Ember.inject.service('widget'),

    /**
     * @method - Handle image resize end event - update the model with latest width value
     * @param {object} event - resize event object
     */
    onImageResizeEnd(event) {
      var _this$data4, _this$data4$originObj, _this$data4$originObj2;

      this.set('showWidthAndHeightTooltip', false);
      const width = event.css('width');
      const height = event.css('height');
      this.set('data.clientWidth', parseInt(width.toString().replace('px', ''), 10));
      this.set('data.clientHeight', parseInt(height.toString().replace('px', ''), 10));
      const styles = (_this$data4 = this.data) === null || _this$data4 === void 0 ? void 0 : (_this$data4$originObj = _this$data4.originObject) === null || _this$data4$originObj === void 0 ? void 0 : (_this$data4$originObj2 = _this$data4$originObj.styles) === null || _this$data4$originObj2 === void 0 ? void 0 : _this$data4$originObj2.toJSON();
      const objIndex = styles[this.mode].findIndex(item => item.name === 'width');

      if (objIndex > -1) {
        styles[this.mode][objIndex].value = width;
      }

      this.data.originObject.styles.set(this.mode, styles[this.mode]);
      this.setChange('page');
    },

    /**
     * @method - Destroy Froala instance when not in image resize mode
     */
    destroyFroalaInstance() {
      this.set('isEditorMode', false);
      this.set('data.vResizable', true);

      if (this.froalaEditor) {
        this.froalaEditor.destroy();
        this.froalaEditor = null;
      }
    },

    actions: {
      /**
       * Handle click on resize image button
       */
      overlayButtonClick() {
        this.set('isEditorMode', true); // Enable editor mode for image resize

        this.set('data.vResizable', false);
      }

    }
  });

  _exports.default = _default;
});