define("builder/pods/components/widget-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Element tag name.
     * @property
     */
    tagName: 'label'
  });

  _exports.default = _default;
});