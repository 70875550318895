define("builder/services/media-manager", ["exports", "builder/config/environment", "builder/services/company-and-website"], function (_exports, _environment, _companyAndWebsite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALLOW_SELECT_FROM_LIBRARY = _exports.DO_NOT_ALLOW_SELECT_FROM_LIBRARY = void 0;
  const DO_NOT_ALLOW_SELECT_FROM_LIBRARY = 0;
  _exports.DO_NOT_ALLOW_SELECT_FROM_LIBRARY = DO_NOT_ALLOW_SELECT_FROM_LIBRARY;
  const ALLOW_SELECT_FROM_LIBRARY = 1;
  /**
   * @class MediaManagerService
   *
   * TODO: refactoring, url builder make as builder like
   */

  _exports.ALLOW_SELECT_FROM_LIBRARY = ALLOW_SELECT_FROM_LIBRARY;

  var _default = Ember.Service.extend({
    /**
     * @property {Object} companyAndWebsite - company-and-website service
     */
    companyAndWebsite: Ember.inject.service(),

    /**
     * Show media manager
     *
     * @param {String} validation - list of allowed extensions
     * @param {Object} callbacks - list of callbacks to be invoked on media manager actions(select/delete)
     */
    open(validation, callbacks) {
      const url = this._buildUrl(validation);

      if (this._popupExists()) {
        this._showPopup(url, callbacks);
      }
    },

    /**
     * Is media manager popup exists
     * @returns {Boolean}
     * @private
     */
    _popupExists() {
      return typeof $.fn.magnificPopup !== 'undefined';
    },

    /**
     * Show media manager popup
     *
     * @param {String} url - url to media manager
     * @param {Object} callbacks - media manager callbacks
     * @private
     */
    _showPopup(url, callbacks = {}) {
      $.magnificPopup.open({
        items: {
          src: url
        },
        type: 'iframe',
        preloader: true,
        callbacks: {
          beforeOpen() {
            this.contentContainer.addClass('__media-manager');
          },

          open() {
            const mp = this;
            mp.content.find('iframe').on('load', event => {
              mp.content.closest('.mfp-content').css('opacity', 1).siblings('.mfp-preloader').hide();
              const manager = event.target.contentWindow.ICore.pmediamanager;
              manager.on('select', function (file) {
                if (typeof callbacks.select === 'function') {
                  callbacks.select(file);
                }

                mp.close();
              });
              manager.on('delete', function (file) {
                if (typeof callbacks.delete === 'function') {
                  callbacks.delete(file);
                }
              });
            });
          }

        }
      });
    },

    /**
     * Build url to media manager
     *
     * @param {String} validation - validation string, list of allowed extensions
     * @returns {String}
     * @private
     */
    _buildUrl(validation = '') {
      const domain = `${window.location.protocol}//${_environment.default.mediaManagerDomain || window.location.host}`;
      const pathname = '/moduletheme/p/mediamanager/default/index';
      const parameters = [`builder2Domain=${_environment.default.domain}`, `${_companyAndWebsite.COMPANY_PARAM_NAME}=${this.companyAndWebsite.getCompanyId()}`, `library=${DO_NOT_ALLOW_SELECT_FROM_LIBRARY}`];

      if (validation) {
        parameters.push(validation);
      }

      return [domain, pathname, '?', parameters.join('&')].join('');
    }

  });

  _exports.default = _default;
});