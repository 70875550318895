define("builder/core/page-structure-copier", ["exports", "builder/core/page-structure-model", "ember-copy"], function (_exports, _pageStructureModel, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Original structure config creator
   */
  var _default = {
    /**
     * Copies original structure and pass to handle it
     *
     * @method configure
     * @param {Ember.Service} gridBuilder - grid builder service
     * @param {Ember.Object} structure - page structure
     * @returns {PageStructureModel} - structure model
     */
    configure(gridBuilder, structure) {
      const copyObject = (0, _emberCopy.copy)(structure, true);
      const configuredCopy = this.handlerObjects(gridBuilder, copyObject);
      return _pageStructureModel.default.create(configuredCopy);
    },

    /**
     * Create config based on page structure
     *
     * @param {Ember.Service} gridBuilder - grid factory service
     * @param {Object} structure - Copy original page structure
     * @param {Object} parent - Current structure item parent
     *
     * @return {Object}
     */
    handlerObjects(gridBuilder, structure, parent = null) {
      const config = gridBuilder.createInstance(structure.type, structure);
      const children = config.get('originObject').get('children') || [];
      const newChildren = [];
      config.set('parent', parent);

      for (let i = 0, len = children.length; i < len; i++) {
        newChildren.push(this.handlerObjects(gridBuilder, children[i], config));
      }

      if (children.length) {
        config.get('originObject').set('children', newChildren);
      }

      return config;
    }

  };
  _exports.default = _default;
});