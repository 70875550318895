define("builder/helpers/form-field-icon", ["exports", "builder/core/form-field-icons"], function (_exports, _formFieldIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Helper for form field icons, will add appropriate icons by field type
   */
  var _default = Ember.Helper.extend({
    compute(params, hash) {
      const {
        type
      } = hash;

      if (typeof type === 'undefined' || !_formFieldIcons.default.hasOwnProperty(type)) {
        return '';
      }

      return _formFieldIcons.default[type];
    }

  });

  _exports.default = _default;
});