define("builder/services/ajax", ["exports", "@ember-data/adapter/error", "ember-ajax/services/ajax", "builder/core/enumerators/server-error-code", "builder/errors/merge-error", "builder/errors/token-invalid-error", "builder/errors/token-expired-error", "builder/errors/global-page-validation-error", "builder/errors/form-validation-error", "builder/errors/global-page-menu-insertion-error", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _error, _ajax, _serverErrorCode, _mergeError, _tokenInvalidError, _tokenExpiredError, _globalPageValidationError, _formValidationError, _globalPageMenuInsertionError, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AjaxService
   */
  var _default = _ajax.default.extend({
    /**
     * List ot trusted hosts
     * @type {string[]}
     */
    trustedHosts: [_environment.default.apiURL.replace(/http(s)?:\/\//, '')],

    /**
     * Auth service
     * @type {AuthManagerService}
     */
    authManager: Ember.inject.service(),

    /**
     * Modules store
     * @type {Ember.Service}
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {Oidc.UserManager|null} ssoClient - sso client
     */
    ssoClient: null,

    /**
     * @inheritDoc
     */
    headers: Ember.computed('authManager.accessToken', function () {
      const env = Cookies.get('env');

      if (this.authManager.isAuthTokenExpired()) {
        return this.authManager.refreshToken();
      }

      const headers = {
        Authorization: `Bearer ${this.get('authManager.accessToken')}`
      };

      if (env) {
        headers['X-ENV'] = env;
      }

      return headers;
    }).readOnly(),

    /**
     * @inheritDoc
     */
    request(...args) {
      if (this.authManager.isAuthTokenExpired()) {
        return this.authManager.refreshToken();
      }

      return this._super(...args).catch(error => {
        if (error instanceof _tokenInvalidError.default || error instanceof _tokenExpiredError.default) {
          return this.authManager.refreshToken();
        }

        return Ember.RSVP.reject(error);
      });
    },

    /**
    * @inheritdoc
    */
    init(...args) {
      this._super(...args);

      this.addObserver('ssoClient', this, '_onSsoClientProvided');
    },

    _onSsoClientProvided() {
      const {
        ssoClient
      } = this;

      if (!ssoClient) {
        return;
      }

      this.removeObserver('ssoClient', this, 'onSsoClientProvided');
    },

    /**
     * @inheritDoc
     */
    handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      }

      if (this.isNotFoundError(status)) {
        return new _error.NotFoundError();
      }

      if (this.isGlobalPageValidationError(status, payload)) {
        return new _globalPageValidationError.default([].concat(payload.errorDetails), [].concat(payload.moduleIds));
      }

      if (this.isGlobalPageMenuInsertionError(status, payload)) {
        return new _globalPageMenuInsertionError.default(['There was a problem adding this global page on the following website(s):'], payload.errorDetails);
      }

      if (this.isFormValidatorError(status, payload)) {
        return new _formValidationError.default([].concat(payload.errorDetails), [].concat(payload.warningDetails));
      }

      if (this.isInvalid(status)) {
        return new _error.InvalidError([].concat(payload.errorDetails));
      }

      if (this.isMergeConflictError(status, payload)) {
        return new _mergeError.default();
      }

      if (this.isTokenInvalidError(status, payload)) {
        let oidc = this;
        return this.ssoClient.removeUser().then(function () {
          oidc.ssoClient.signoutRedirect();
        });
      }

      if (this.isTokenExpiredError(status, payload)) {
        let oidc = this;
        return this.ssoClient.removeUser().then(function () {
          oidc.ssoClient.signoutRedirect();
        });
      }

      if (this.isServerError(status)) {
        return new _error.InvalidError([].concat(payload.errorDetails));
      }

      return new _error.default(this.normalizeErrorResponse(status, headers, payload), payload.errorDetails || 'Unknown error. Please try again in a moment.');
    },

    /**
     * Checks if the given status code represents a not found error response
     * @param {string} status  - status code
     * @returns {boolean}
     */
    isNotFoundError(status) {
      return status === 404;
    },

    /**
     * Checks if the given status code represents a merge conflict error response
     * @param {string} status  - status code
     * @param {Object} payload  - response payload
     * @returns {boolean}
     */
    isMergeConflictError(status, payload) {
      return this.isServerError(status) && Ember.get(payload, 'error') === _serverErrorCode.MERGE_CONFLICT;
    },

    /**
     * Checks if the given status code represents a sso token invalid error response
     * @param {string} status  - status code
     * @param {Object} payload  - response payload
     * @returns {boolean}
     */
    isTokenInvalidError(status, payload) {
      return this.isServerError(status) && Ember.get(payload, 'error') === _serverErrorCode.TOKEN_INVALID;
    },

    /**
     * Checks if the given status code represents a sso token expired error response
     * @param {string} status  - status code
     * @param {Object} payload  - response payload
     * @returns {boolean}
     */
    isTokenExpiredError(status, payload) {
      return this.isServerError(status) && Ember.get(payload, 'error') === _serverErrorCode.TOKEN_EXPIRED;
    },

    /**
     * Checks if the given status code represents and is global page validation error
     * @param {string} status  - status code
     * @param {Object} payload  - response payload
     * @returns {boolean}
     */
    isGlobalPageValidationError(status, payload) {
      return this.isInvalid(status) && Ember.get(payload, 'error') === _serverErrorCode.VALIDATION_ERROR && !!Ember.get(payload, 'moduleIds');
    },

    /**
     * Checks if the given status code represents and is form validator error
     * @param {number} status  - status code
     * @param {Object} payload  - response payload
     * @returns {boolean}
     */
    isFormValidatorError(status, payload) {
      return this.isInvalid(status) && Ember.get(payload, 'error') === _serverErrorCode.FORM_VALIDATION_ERROR;
    },

    /**
     * Checks if the given status code represents and is global page validation error
     * @param {string} status  - status code
     * @param {Object} payload  - response payload
     * @returns {boolean}
     */
    isGlobalPageMenuInsertionError(status, payload) {
      const [errorDetail] = payload.errorDetails || [];
      return this.isServerError(status) && payload.error === _serverErrorCode.GLOBAL_PAGE_MENU_INSERTION_ERROR && errorDetail && errorDetail.hasOwnProperty('websiteId');
    },

    /**
     * Checks if the given status code represents a server error response
     * @param {string} status - status code
     * @returns {boolean}
     */
    isServerError(status) {
      return status >= 500 && status < 600;
    },

    /**
     * Checks if the given status code represents a invalid error response
     * @param {string} status - status code
     * @returns {boolean}
     */
    isInvalid(status) {
      return status === 422 || status === 400;
    },

    /**
     * Return common formatted request for builder2 restapi
     * @param {string} path - API name
     * @param {string} queryParamString - more query params in the request as string
     * @returns {Promise<object>}
     */
    requestBuilderAPI(path, queryParamString) {
      return this.request(`${_environment.default.apiURL}/${path}?${this.companyAndWebsite.getCompanyWebsiteParams()}
			&${_cookie.default.SESSION_ID}=${this.configurationService.sessionId}${queryParamString}`);
    }

  });

  _exports.default = _default;
});