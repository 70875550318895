define("builder/adapters/page-module", ["exports", "builder/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageModuleAdapter
   */
  var _default = _application.default.extend({
    /**
     * Url to create and update operations
     * @type {string}
     * @private
     * @readonly
     */
    _createUpdateUrl: Ember.computed(function () {
      return `${this.host}/page_modulepreview`;
    }).readOnly(),

    /**
     * Url to find
     * @type {string}
     * @private
     * @readonly
     */
    _findUrl: Ember.computed(function () {
      return `${this.host}/${this.pathForType()}`;
    }).readOnly(),

    /**
     * @inheritdoc
     */
    pathForType() {
      return 'page_module';
    },

    /**
     * @inheritdoc
     */
    urlForUpdateRecord(id) {
      return `${this._createUpdateUrl}/${id}`;
    },

    /**
     * @inheritdoc
     */
    urlForCreateRecord() {
      return `${this._createUpdateUrl}`;
    },

    /**
     * @inheritdoc
     */
    deleteRecord() {
      // Fix for unnecessary delete page-module request
      // because now 2.03.18 we use api page-module point only for GET
      // for create or update record, we use PUT and POST request on page_modulepreview
      // DELETE request not use, but we need delete record from ember-data when we delete module from page
      return Ember.RSVP.resolve({
        _label: `DS: RESTAdapter#ajax DELETE to ${this.host}/page-module/id`,
        _onError: null,
        _result: undefined,
        _state: 1
      });
    }

  });

  _exports.default = _default;
});