define("builder/pods/components/field-html/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JRRafzOX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"widget-field-info\",[],[[\"@field\",\"@validation\"],[[22,\"field\"],[22,\"validationsMessage\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"bttn bttn-default bttn-default__full-width\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"showCodeEditorPopup\"]]]],[8],[0,\"\\n\\tEdit HTML\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-html/template.hbs"
    }
  });

  _exports.default = _default;
});