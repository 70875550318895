define("builder/pods/components/edit-widget-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pKdIHFKm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"overlay_edit_widget\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"bttn bttn-primary btn-overlay\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"onButtonClick\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"editIconSrc\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[22,\"editIconSrc\"]],[10,\"alt\",\"Resize Image\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"faIconName\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[7,\"span\",true],[8],[0,\"  \"],[1,[22,\"buttonText\"],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/edit-widget-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});