define("builder/adapters/font-icon", ["exports", "builder/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @ts-check
  class FontIconAdapter extends _application.default {
    pathForType() {
      return 'fonticon';
    }

  }

  _exports.default = FontIconAdapter;
});