define("builder/services/menu-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Menu Manager service, used to store and access searchString horizontally through components,
   * used to store currentPageId which helps to compare while page transitions.
   */
  class MenuManagerService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.searchString = '';
      this.currentPageId = '';
    }

  }

  _exports.default = MenuManagerService;
});