define("builder/initializers/set-cookies", ["exports", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // If not exists any website we don't change cookie
    if (!window.appBootstrap.initData.companies.length || !window.appBootstrap.initData.websites.length) {
      return;
    } // First need to set company then to filter websites.


    this.setCompany();
    this.setWebsite(); // Need to check whether websites belongs to selected company.
    // If not re-set company from the selected website.

    this.checkWebsiteCompany(); // Setup sessionId

    this.setupSessionId();
  }

  var _default = {
    /**
     * @property {string} name
     */
    name: 'set-cookies',

    /**
     * @property {string} before - Determine initializer order
     */
    after: 'init-data',

    /**
     * @method initialize
     */
    initialize,

    /**
     * @property {string} _cookiesDomain - cookies domain
     * @private
     */
    _cookiesDomain: _environment.default.cookiesDomain,

    /**
     * Set company
     * @method setCompany
     * @param {String} id - company id
     */
    setCompany(id) {
      const getParam = this.getCompanyGetParam();
      let companyId;

      if (id) {
        companyId = id; // Update get param if exists

        if (getParam) {
          const url = window.location.href.replace(/company=(\d+|all)/, `company=${companyId}`);
          window.history.replaceState(null, null, url);
        }
      } else if (getParam && !isNaN(getParam)) {
        companyId = getParam;
      } else {
        companyId = this.getCompany();
      }

      Cookies.set(_cookie.default.COMPANY_ID, companyId, {
        path: '/',
        expires: 365,
        domain: this._cookiesDomain
      });
    },

    /**
     * Get company get parameter
     * @method getCompanyGetParam
     * @return {String} company get param
     */
    getCompanyGetParam() {
      let getParam = window.location.search;

      if (getParam) {
        getParam = getParam.match(/company=(\d+|all)/);

        if (getParam !== null && Array.isArray(getParam) && getParam.length) {
          return getParam[1];
        }
      }

      return getParam;
    },

    /**
     * Get company
     * @method getCompany
     * @return {String} company id
     */
    getCompany() {
      const cookie = Cookies.get(_cookie.default.COMPANY_ID);
      const getParam = this.getCompanyGetParam();
      let companyId;

      if (getParam) {
        // Make sure get param is numeric. If not set "all" value.
        // And make it text to compare with init data.
        companyId = parseInt(getParam, 10) || 'all';
      } else if (cookie) {
        companyId = cookie;
      } else {
        // First company id
        companyId = window.appBootstrap.initData.companies[0].id;
      }

      return companyId;
    },

    /**
     * Set website
     * @method setWebsite
     */
    setWebsite() {
      let websiteId = this.getWebsiteId();

      if (!this.checkWebsite(websiteId)) {
        websiteId = window.appBootstrap.initData.websites[0].id;
      }

      Cookies.set(_cookie.default.WEBSITE_ID, websiteId, {
        path: '/',
        expires: 365,
        domain: this._cookiesDomain
      });
    },

    /**
     * Get website id
     * @method getWebsiteId
     * @return {String} Website id
     */
    getWebsiteId() {
      const cookie = Cookies.get(_cookie.default.WEBSITE_ID);
      const firstWebsiteId = window.appBootstrap.initData.websites[0].id;
      let modelId = window.location.pathname.match(/website\/(\d+)\/?/);
      let websiteId;

      if (Array.isArray(modelId) && modelId.length) {
        [, modelId] = modelId;
      }

      if (modelId) {
        websiteId = modelId;
      } else if (cookie) {
        websiteId = cookie;
      } else {
        websiteId = firstWebsiteId;
      }

      return websiteId;
    },

    /**
     * Check that website exists
     * @method checkWebsite
     * @param {String} websiteId - website id
     * @return {Boolean} exists site or not
     */
    checkWebsite(websiteId) {
      const {
        websites
      } = window.appBootstrap.initData;
      let isExists = false;

      for (let i = 0; i < websites.length; i++) {
        if (websites[i].id === websiteId) {
          isExists = true;
          break;
        }
      }

      return isExists;
    },

    /**
     * Checks that the current website belongs to the selected company, if not - changes it
     * @method checkWebsiteCompany
     */
    checkWebsiteCompany() {
      const websiteId = Cookies.get(_cookie.default.WEBSITE_ID);

      if (!this.existInCompany(websiteId)) {
        const websiteCompanyId = this.getWebsiteCompany(websiteId);
        this.setCompany(websiteCompanyId);
      }
    },

    /**
     * Checks whether website belongs selected company
     * @method existInCompany
     * @param {string} websiteId - Website id
     * @returns {boolean} exist in company or not
     */
    existInCompany(websiteId) {
      const selectedCompany = Cookies.get(_cookie.default.COMPANY_ID);
      return this.getWebsiteCompany(websiteId) === selectedCompany || selectedCompany === 'all';
    },

    /**
     * Gets website company id.
     * @method getWebsiteCompany
     * @param {string} websiteId
     * @returns {string|undefined} company id
     */
    getWebsiteCompany(websiteId) {
      const website = window.appBootstrap.initData.websites.filterBy('id', websiteId);

      if (website.length) {
        return website[0].companyId;
      }

      return null;
    },

    /**
     * Setup session id
     */
    setupSessionId() {
      const userId = Ember.get(window, 'appBootstrap.initData.user.id');
      const sessionId = Cookies.get(_cookie.default.SESSION_ID);
      const cookieCheck = new RegExp(`^${userId}$d+$`);

      if (!cookieCheck.test(sessionId)) {
        Cookies.set(_cookie.default.SESSION_ID, `${userId}$${Date.now()}`);
      }
    }

  };
  _exports.default = _default;
});