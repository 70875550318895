define("builder/core/froala/links/link-update-button", ["exports", "froala-editor", "builder/core/froala/constants"], function (_exports, _froalaEditor, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkUpdateEvent = _exports.linkUpdateCommand = void 0;

  /* eslint-disable new-cap */
  const linkUpdateCommand = 'link-button';
  _exports.linkUpdateCommand = linkUpdateCommand;
  const linkUpdateEvent = 'link-button-click';
  _exports.linkUpdateEvent = linkUpdateEvent;

  _froalaEditor.default.DefineIcon(linkUpdateCommand, {
    NAME: 'edit',
    SVG_KEY: 'edit'
  });

  _froalaEditor.default.RegisterCommand(linkUpdateCommand, {
    title: 'Update Link',
    focus: false,
    undo: false,
    refreshAfterCallback: false,

    callback() {
      const link = this.link.get();
      const linkId = link.getAttribute(_constants.LINK_DATA_ATTRIBUTE);
      this.events.trigger(linkUpdateEvent, [linkId, link]);
    }

  });
});