define("builder/pods/components/seo-url-export-button/component", ["exports", "js-yaml", "file-saver"], function (_exports, _jsYaml, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SeoUrlExportButtonComponent = (_dec = Ember.inject.service('router'), _dec2 = Ember.computed('pageSeo.@each'), _dec3 = Ember._action, (_class = (_temp = class SeoUrlExportButtonComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['seo-export-button'];

      _initializerDefineProperty(this, "router", _descriptor, this);

      this.pageSeo = void 0;
    }

    /**
     * File name to generate
     * @type {string}
     */
    get fileName() {
      var _this$router, _this$router$currentR, _this$router$currentR2, _this$router$currentR3;

      const pageId = (_this$router = this.router) === null || _this$router === void 0 ? void 0 : (_this$router$currentR = _this$router.currentRoute) === null || _this$router$currentR === void 0 ? void 0 : (_this$router$currentR2 = _this$router$currentR.attributes) === null || _this$router$currentR2 === void 0 ? void 0 : (_this$router$currentR3 = _this$router$currentR2.page) === null || _this$router$currentR3 === void 0 ? void 0 : _this$router$currentR3.id;
      return `page-seo-${pageId || 'unknown'}.yml`;
    }
    /**
     * Serializes current page variant to yml file
     * @returns {void}
     */


    serializePageSeoUrls() {
      var _this$pageSeo, _this$pageSeo$data;

      let pageSeoCollection = null;

      if (((_this$pageSeo = this.pageSeo) === null || _this$pageSeo === void 0 ? void 0 : (_this$pageSeo$data = _this$pageSeo.data) === null || _this$pageSeo$data === void 0 ? void 0 : _this$pageSeo$data.length) > 0) {
        var _this$pageSeo2;

        pageSeoCollection = JSON.parse(JSON.stringify((_this$pageSeo2 = this.pageSeo) === null || _this$pageSeo2 === void 0 ? void 0 : _this$pageSeo2.data));
      }

      const seoContent = _jsYaml.default.safeDump(pageSeoCollection, {
        sortKeys: true
      });

      const blob = new Blob([seoContent], {
        type: 'text/plain;charset=utf-8'
      });

      _fileSaver.default.saveAs(blob, this.fileName);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fileName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "serializePageSeoUrls", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "serializePageSeoUrls"), _class.prototype)), _class));
  _exports.default = SeoUrlExportButtonComponent;
});