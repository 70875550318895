define("builder/serializers/form", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/form"], function (_exports, _rest, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args); // Remove readonly recipients from PUT/POST requests. Ask about it Shukhrat Mirsaidov (@Shukhrat).


      if (json.Leadroutes && json.Leadroutes.length) {
        const editableLeadRoutes = json.Leadroutes.filter(x => x.Editable);
        Ember.set(json, 'Leadroutes', editableLeadRoutes);
      }

      if (json.Structure) {
        const serializedForm = this._convertToNonStepForm(json.Structure.form);
        /**
         * NOTE:
         * runtime ember assertion are failing if we are not using ember set function.
         * official documentation tells us that we could assign value directly, but reality is different.
         * The error is not so informative, but it looks like assertion is failing when we changing original json object reference
         */


        Ember.set(json.Structure, 'form', serializedForm);
      }

      return json;
    },

    /**
     * @inheritdoc
     */
    normalize(model, hash, ...args) {
      if (hash.Structure) {
        hash.Structure.form = this._convertToStepForm(hash.Structure.form);
      }

      return this._super(model, hash, ...args);
    },

    /**
     * Convert simple form structure to one step structure form
     *
     * @method _convertToStepForm
     * @param {Array} formData - form data
     * @return {Object} converted form data
     * @private
     */
    _convertToStepForm(formData) {
      if (formData.length === 1 && formData[0].type === _form.MULTISTEP) {
        return formData[0];
      }

      return {
        type: _form.MULTISTEP,
        children: [{
          type: _form.STEP,
          label: 'Contact info',
          children: formData
        }]
      };
    },

    /**
     * Convert one step structure form to simple form structure
     *
     * @method _convertToNonStepForm
     * @param {Array} formData - form data
     * @return {Object} converted form data
     * @private
     */
    _convertToNonStepForm(formData) {
      if (formData && formData.children && formData.children.length === 1) {
        return formData.children[0].children;
      }

      return [formData];
    }

  });

  _exports.default = _default;
});