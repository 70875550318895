define("builder/services/configuration", ["exports", "builder/core/enumerators/cookie", "builder/core/enumerators/fields"], function (_exports, _cookie, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHOW_MODULES_OUTLINE_LOCALSTORAGE_KEY = 'show_modules_outline_user';
  /**
   * Application configuration service, used to store all "global" application stuff, like language, user info, etc.
   * NOTE: right now this service is used for storing anything but global settings.
   * TODO: refactor this service to make it more cohesive
   *
   * @class ConfigurationService
   */

  var _default = Ember.Service.extend({
    /**
     * Flag to determine changes on page
     * @type {boolean}
     */
    hasUnsavedChanges: false,

    /**
     * Current website
     * @type {WebsiteModel | null}
     */
    website: null,

    /**
     * Current opened page
     * @type {PageModel | null}
     */
    page: null,

    /**
     * Main menu of current website
     * @type {MenuModel | null}
     */
    menu: null,

    /**
     * Unlinked menu of current website
     * @type {MenuModel | null}
     */
    unlinkedMenu: null,

    /**
     * Device service
     * @type {Ember.Service}
     */
    device: Ember.inject.service(),

    /**
     * Current selected device, for more details see device service _device property
     * @type {Object | null}
     */
    currentDevice: null,

    /**
     * Local storage service
     * @type {Ember.Service}
     */
    localStorage: Ember.inject.service(),

    /**
     * Show/hide outlines for modules
     * @type {boolean}
     */
    isModulesOutlineVisible: false,

    /**
     * sessionId - session id
     * @type {string}
     * @readonly
     */
    sessionId: Ember.computed(function () {
      return window.Cookies.get(_cookie.default.SESSION_ID) || '';
    }).readOnly(),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args); // Update current device when device changes


      this.device.on('onChange', this, this.onDeviceChange);
      this.updateDevice();
      this.set('isModulesOutlineVisible', this.getModulesOutlineMode());
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      this.device.off('onChange', this, this.onDeviceChange);
    },

    /**
     * On device change handler
     */
    onDeviceChange() {
      this.updateDevice();
    },

    /**
     * Synchronize selected device with device selected in device service
     */
    updateDevice() {
      this.set('currentDevice', this.device.getDevice());
    },

    /**
     * Get mode for outline modules
     * @return {Boolean}
     */
    getModulesOutlineMode() {
      return !!this.localStorage.getItem(SHOW_MODULES_OUTLINE_LOCALSTORAGE_KEY + this.get('app.user.id')) || this.isModulesOutlineVisible;
    },

    /**
     * Set mode for outline modules
     * @param {Boolean} value - value for save
     */
    setModulesOutlineMode(value) {
      this.localStorage.setItem(SHOW_MODULES_OUTLINE_LOCALSTORAGE_KEY + this.get('app.user.id'), !!value);
      this.set('isModulesOutlineVisible', !!value);
    },

    /**
     * Unpacks treelike fields configuration structure to flat array.
     * We need flat array to watch on particular field configuration for field
     * visibility and choices filtering.
     * There is no way for ember to watch on treelike structures, so this map is essential
     * @param {object[]} fieldConfigurations - array of module configuration fields
     * @param {string} [basePath=''] - root path for current configuration subtree
     * @returns {FieldConfigurationNode[]}
     */
    getFieldsConfigFlatMap(fieldConfigurations, basePath = '') {
      if (!fieldConfigurations || !fieldConfigurations.length) {
        return [];
      }

      return fieldConfigurations.reduce((previous, current) => {
        if (!current.name) {
          return previous;
        }
        /**
         * Each variant has sandboxed configuration, so we need to skip this type
         */


        if (current.type === _fields.default.FORM_VARIANTS || current.type === _fields.default.FORM_VARIANT) {
          return previous;
        }

        const path = `${basePath}/${current.name}`;

        if (current.children && current.children.length) {
          const children = this.getFieldsConfigFlatMap(current.children, path);
          return [...previous, ...children];
        }

        const configurationNode = {
          path,
          name: current.name,
          value: current.data
        };
        return [...previous, configurationNode];
      }, []);
    }

  });

  _exports.default = _default;
});