define("builder/core/module-dependency-manager", ["exports", "builder/core/graph"], function (_exports, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ModuleDependencyManager = void 0;
  const ModuleDependencyManager = Ember.Object.extend({
    /**
     * Graph that holds theme to module dependencies
     * @type {Graph}
     */
    dependencyGraph: null,

    /**
     * Graph that holds module theme dependencies,
     * in other words which themes belongs to which module
     * @type {Graph}
     */
    moduleThemeGraph: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('dependencyGraph', _graph.Graph.create());
      this.set('moduleThemeGraph', _graph.Graph.create());
    },

    /**
     * Add/Register theme to module dependency
     *
     * @param {ModuleThemeModel} theme - theme which depends on module
     * @param {ModuleModel} module - module on which theme depends
     * @returns {ModuleDependencyManager}
     */
    addThemeToModuleDependency(theme, module) {
      const graph = this.dependencyGraph;
      graph.makeEdge(graph.getVertex(theme) || graph.makeVertex(theme), graph.getVertex(module) || graph.makeVertex(module));
      return this;
    },

    /**
     * Add/Register module to theme fk dependency, theme belongs to module
     *
     * @param {ModuleModel} module - module, owner of theme
     * @param {ModuleThemeModel} theme - theme that belongs to module
     * @returns {ModuleDependencyManager}
     */
    addModuleThemeFKDependency(module, theme) {
      const graph = this.moduleThemeGraph;
      graph.makeEdge(graph.getVertex(module) || graph.makeVertex(module), graph.getVertex(theme) || graph.makeVertex(theme));
      return this;
    },

    /**
     * Get themes that depends on passed in theme(theme depends on module, but in fact, module has themes, hence, theme
     * in reality depends on module theme, not module itself) theme -> module -> themes
     * @param {ModuleThemeModel} theme - module theme, which dependent themes to get
     * @returns {ModuleThemeModel[]} - themes that depends on passed in theme
     */
    getDependentThemes(theme) {
      const module = this.getThemeModule(theme);
      return this.getDependentModuleThemes(module).map(themeVertex => themeVertex.value);
    },

    /**
     * Get themes which belongs to module, passed in theme has dependency on
     *
     * @param {ModuleThemeModel} theme - theme which dependencies to get
     * @returns {ModuleThemeModel[]} - list of themes on which passed in theme depends
     */
    getDependencyThemes(theme) {
      const modules = this.getDependencyModules(theme);
      return this.getModulesThemes(modules).map(themeVertex => themeVertex.value);
    },

    /**
     * Get theme module
     *
     * @param {ModuleThemeModel} theme - theme which module to find
     * @returns {ModuleModel|null} - module model or null if not found
     */
    getThemeModule(theme) {
      const graph = this.moduleThemeGraph;
      const vertex = graph.getVertex(theme);
      let modules = [];

      if (vertex) {
        modules = graph.getBroadcasters(vertex);
      }

      return modules.length ? modules[0].value : null;
    },

    /**
     * Get themes that depends on module (theme -> module)
     *
     * @param {ModuleModel} module - module which dependencies to get
     * @returns {Vertex[]} - list of theme vertices that depends on module
     */
    getDependentModuleThemes(module) {
      const graph = this.dependencyGraph;
      const vertex = graph.getVertex(module);
      let themes = [];

      if (vertex) {
        themes = graph.getBroadcasters(vertex);
      }

      return themes;
    },

    /**
     * Get modules passed in theme depends on
     *
     * @param {ModuleThemeModel} theme - theme that depends on modules to find
     * @returns {Vertex[]} - list of module vertices current theme depends on
     */
    getDependencyModules(theme) {
      const graph = this.dependencyGraph;
      const vertex = graph.getVertex(theme);
      let modules = [];

      if (vertex) {
        modules = graph.getReceivers(vertex);
      }

      return modules;
    },

    /**
     * Get themes of all passed in modules
     *
     * @param {Vertex[]} modules - module vertices which themes to get
     * @returns {Vertex[]} - list of all modules theme vertices
     */
    getModulesThemes(modules) {
      let themes = [];
      let moduleThemes;

      for (let i = 0, len = modules.length; i < len; i++) {
        moduleThemes = this.getModuleThemes(modules[i].value);
        themes = themes.concat(moduleThemes);
      }

      return themes;
    },

    /**
     * Get single module themes(by fk)
     *
     * @param {ModuleModel} module - module which theme to get
     * @returns {Vertex[]} list of all module theme vertices
     */
    getModuleThemes(module) {
      const graph = this.moduleThemeGraph;
      const vertex = graph.getVertex(module);
      let themes = [];

      if (vertex) {
        themes = graph.getReceivers(vertex);
      }

      return themes;
    }

  });
  _exports.ModuleDependencyManager = ModuleDependencyManager;
});