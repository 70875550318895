define("builder/pods/components/field-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['ember-select'],

    /**
     * @property {Boolean} disabled - is select disabled or not
     */
    disabled: false,

    /**
     * @property {jQuery|null} _$select - jquery select object
     * @private
     */
    _$select: null,

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      const attrs = this.attr;
      const select = this.$('select');
      this.set('_$select', select);

      if (typeof attrs === 'object') {
        Object.keys(attrs).forEach(item => {
          select.attr(item, attrs[item]);
        });
      }

      this._super(...args);
    },

    /**
     * Since FF has some strange behaviour, probably related to autocomplete, we must explicitly select option
     * after render, otherwise when "content" changes => select rerenders and firefox selects 1-st option,
     * another theory is that, FF tries to find selected element, but can't find it, since it's not present yet
     * (sounds crazy but who knows)
     *
     * @inheritdoc
     */
    didRender(...args) {
      const checkedOption = this.findSelectedOption(this.content);

      if (checkedOption) {
        this._$select.get(0).value = checkedOption.value;
      }

      this._super(...args);
    },

    /**
     * Finds selected option
     * @param {Array} options - Options of the select
     * @returns {Null|Object}
     */
    findSelectedOption(options) {
      let foundOption = null;

      for (let i = 0, len = options.length; i < len; i++) {
        const option = options.objectAt(i);

        if (option.checked) {
          foundOption = option;
        } else if (Array.isArray(option.value)) {
          foundOption = this.findSelectedOption(option.value);
        }

        if (foundOption) {
          break;
        }
      }

      return foundOption;
    },

    actions: {
      /**
       * Action for change
       */
      change() {
        const value = this._$select.val();

        const {
          action
        } = this;

        if (action) {
          action(value);
        } // Use changeAction from parent component


        if (typeof this.changeAction === 'function') {
          this.changeAction(value);
        }
      }

    }
  });

  _exports.default = _default;
});