define("builder/core/abstract-classes/composite-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Abstract class describes operations with composite properties like "background" or "border" and so on.
   *
   * @class AbstractCompositeProperty
   * @abstract
   */
  var _default = Ember.Object.extend({
    /**
     * Should describe each part of composite property
     * @type {object}
     * @abstract
     */
    compositeProperties: Ember.computed(function () {
      throw new Ember.Error('"compositeProperties" property should be overridden.');
    }),

    /**
     * Method should return requested part of composite property
     *
     * @param {string} propName - name of composite property part
     * @returns {string}
     */
    getValue(propName) {
      return this.get(`compositeProperties.${propName}`);
    },

    /**
     * Method should set value on part of composite property
     *
     * @param {string} propName - name of composite property part
     * @param {string|number} value - new value
     */
    setValue(propName, value) {
      if (this.compositeProperties.hasOwnProperty(propName)) {
        this.set(`compositeProperties.${propName}`, value);
      }
    },

    /**
     * Method should return composite property builds from it's parts
     * @abstract
     */
    build() {
      throw new Ember.Error('"build" method should be overridden');
    },

    /**
     * Method should return specific hash which need to view part of "sm-composite" component.
     *
     * @TODO Probably it's not a good place for this functionality but now it's makes component part more predictable. Need to think.
     * @abstract
     */
    buildForCompositeView() {
      throw new Ember.Error('"buildForCompositeView" method should be overridden');
    }

  });

  _exports.default = _default;
});