define("builder/pods/components/form-module/drop-container", ["exports", "builder/core/drop-coordinator", "builder/pods/components/form-module/form-module-factory"], function (_exports, _dropCoordinator, _formModuleFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component used for specifying drag and drop logic for each type of drag and drop actors
   * TODO: Refactor "playScenarioSelfParent", "playScenarioVerticalDrop", "playScenario",
   * must be protected/private methods
   */
  var _default = Ember.Component.extend({
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @property {String[]} allowed - list of allowed types to be dropped inside
     */
    allowed: null,

    /**
     * @property {Object} _coordinator - DropCoordinator instance
     * @private
     */
    _coordinator: null,

    /**
     * @property {Object} tolerance - drag and drop tolerance for x and y axis
     */
    tolerance: null,

    /**
     * @property {Object} dragAndDropModule - drag-and-drop-module instance
     */
    dragAndDropModule: null,

    /**
     * Component hook
     */
    init(...args) {
      this._super(...args);

      this.set('factory', _formModuleFactory.default);
      this.set('_coordinator', _dropCoordinator.default.create());
      this.set('allowed', []);
      this.set('tolerance', {
        x: 50,
        y: 30
      });
    },

    /**
     * Is drop into "container" allowed or not
     *
     * @param {Object} container - form-item instance where drag item wants to be dropped
     * @param {Object} draggable - form-item dragging instance
     * @param {Object} anchor - form-item instance next to which dragging form-item wants to be dropped
     * @param {String} position - drop position top/right/bottom/left
     * @param {Number} distance - distance to drop position(in percents, between 0-50)
     */
    dropAllowed() {
      throw new Ember.Error('dropAllowed must be overridden');
    },

    /**
     * Get drop position
     *
     * @param {Object} container - container where dragging form-item will be dropped
     * @param {Object} draggable - dragging form-item instance
     * @param {Object} anchor - form-item instance next to which dragging form-item wants to be dropped
     * @param {HTMLElement} target - immediate drop target (event.target)
     * @param {Number} x - drag position (event.offsetX)
     * @param {Number} y - drag position (event.offsetY)
     */
    getDropPosition() {
      throw new Ember.Error('getDropPosition must be overridden');
    },

    /**
     * Handle drop into form-item
     *
     * @param {Object} container - container where form-item is dropped
     * @param {Object} draggable - dropped form-item
     * @param {Object} anchor - form-item next to which form-item has been dropped
     * @param {String} position - drop position top/right/bottom/left
     */
    drop(container, draggable, anchor, position) {
      switch (position) {
        case 'top':
          {
            const parent = draggable.get('parent');
            let row;

            if (parent && parent.get('type') === 'column') {
              row = parent.get('parent');
            }

            if (anchor === row && anchor.get('type') === 'row') {
              this.playScenarioSelfParent(container, draggable, anchor, 0);
            } else {
              this.playScenarioVerticalDrop(container, draggable, anchor, 0);
            }

            break;
          }

        case 'right':
          {
            this.playScenario(container, anchor, draggable, anchor);
            break;
          }

        case 'bottom':
          {
            const parent = draggable.get('parent');
            let row;

            if (parent && parent.get('type') === 'column') {
              row = parent.get('parent');
            }

            if (anchor === row && anchor.get('type') === 'row') {
              this.playScenarioSelfParent(container, draggable, anchor, 1);
            } else {
              this.playScenarioVerticalDrop(container, draggable, anchor, 1);
            }

            break;
          }

        case 'left':
          {
            this.playScenario(container, draggable, anchor, anchor);
            break;
          }

        default:
          {
            this.removeChild(container, draggable);
            container.addChildAt(draggable, container.get('children').length);
          }
      }
    },

    /**
     * When field/fieldset is dropped on the left/right side of another field/field (types must match),
     * this scenario should be executed.
     * In general, following steps are executed:
     * 1) wrap both fields/fieldsets into column
     * 2) put before anchor element row
     * 3) put inside row wrapped fields/fieldsets
     *
     * @param {Object} fieldset - form-item of type "fieldset", that houses anchor component
     * @param {Object} draggableLeft - form-item to be placed on the left side of row
     * @param {Object} draggableRight - form-item to be placed on the right side of row
     * @param {Object} anchor - form-item before which row form-item will be placed
     */
    playScenario(fieldset, draggableLeft, draggableRight, anchor) {
      const {
        factory
      } = this;
      const {
        dragAndDropModule
      } = this;
      const row = factory.createRow();
      const leftColumn = factory.createColumn();
      const rightColumn = factory.createColumn(); // Is that ok to share drag and drop module?
      // Attach event listeners

      dragAndDropModule.setupItem(row);
      dragAndDropModule.setupItem(leftColumn);
      dragAndDropModule.setupItem(rightColumn);
      this.drop(fieldset, row, anchor, 'top');
      this.removeChild(fieldset, draggableLeft);
      this.removeChild(fieldset, draggableRight);
      this.rowContainer.drop(row, leftColumn, null, 'left');
      this.rowContainer.drop(row, rightColumn, leftColumn, 'right');
      this.columnContainer.drop(leftColumn, draggableLeft, null, 'bottom');
      this.columnContainer.drop(rightColumn, draggableRight, null, 'bottom');
    },

    /**
     * When draggable inside row dropped below same row it lies
     *
     * @param {Object} container - container where draggable dropped
     * @param {Object} draggable - drag object
     * @param {Object} anchor - anchor element next to which element will be placed
     * @param {Number} positionShift - to drop below anchor, pass 1, otherwise 0
     */
    playScenarioSelfParent(container, draggable, anchor, positionShift = 0) {
      const row = draggable.get('parent').get('parent');
      const children = container.get('children');
      const index = children.indexOf(row);
      this.removeChild(container, draggable);
      container.addChildAt(draggable, index === -1 ? 0 : index + positionShift);
    },

    /**
     * Vertical drop handler
     *
     * @param {Object} container - container where draggable dropped
     * @param {Object} draggable - drag object
     * @param {Object} anchor - anchor element next to which element will be placed
     * @param {Number} positionShift - to drop below anchor, pass 1, otherwise 0
     */
    playScenarioVerticalDrop(container, draggable, anchor, positionShift = 0) {
      const children = container.get('children');
      this.removeChild(container, draggable);
      const index = children.indexOf(anchor);
      container.addChildAt(draggable, index === -1 ? 0 : index + positionShift);
    },

    /**
     * @inheritdoc
     */
    removeChild(container, child) {
      const parent = child.get('parent');

      if (!parent) {
        return;
      }

      parent.removeChild(child); // Specific logic, since empty columns should be removed
      // And row with the only column should be removed, we should check for these conditions on child remove

      if (parent.get('type') === 'column') {
        const row = parent.get('parent');
        const rowParent = row.get('parent'); // Remove empty column

        this.removeChild(row, parent);
        const column = row.get('children')[0];

        if (column) {
          const draggable = column.get('children')[0]; // If inside column we have a child, move it before row

          if (draggable) {
            rowParent.addChildAt(draggable, rowParent.get('children').indexOf(row));
          }

          this.removeChild(row, column);
        }

        this.removeChild(rowParent, row);
      }
    }

  });

  _exports.default = _default;
});