define("builder/serializers/meta-variables", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/variant-names"], function (_exports, _rest, _variantNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Page seo serializer
   */
  var _default = _rest.default.extend({
    /**
     * @property {String} primaryKey - primary key index
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    modelNameFromPayloadKey() {
      return 'metaVariables';
    },

    /**
     * @inheritdoc
     */
    payloadKeyFromModelName() {
      return 'websiteMetavariable';
    },

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args);

      const newStructure = {}; // Change field name and
      // replace to object for correct backend work

      json.Structure.forEach((item, index) => {
        newStructure[`surrounding_city_${index + 1}`] = item.city;
      });
      json.Structure = newStructure;
      return json;
    },

    /**
     * @inheritdoc
     */
    normalize(model, hash, ...args) {
      const structure = hash.Structure;
      const newStructure = []; // Add variant-name, change field name and
      // replace to array for correct form-variants work

      for (let i = 1;; i++) {
        const item = structure[`surrounding_city_${i}`];

        if (typeof item !== 'undefined') {
          newStructure.push({
            city: item,
            'variant-name': _variantNames.META_VARIABLES_NAME
          });
        } else {
          break;
        }
      }

      hash.Structure = newStructure;
      return this._super(model, hash, ...args);
    }

  });

  _exports.default = _default;
});