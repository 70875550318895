define("builder/core/factories/module-gallery/module-decorator", ["exports", "builder/core/factories/module-gallery/gallery-module", "builder/core/enumerators/module"], function (_exports, _galleryModule, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    oneWay,
    readOnly,
    bool
  } = Ember.computed;
  /**
   * @class ModuleGalleryModuleDecorator
   */

  var _default = _galleryModule.default.extend({
    /**
     * @inheritdoc
     */
    componentName: 'gallery-module',

    /**
     * @inheritdoc
     */
    type: _module.default.MODULE_CORE,

    /**
     * @property {AccessControlService} accessControl - access control service
     */
    accessControl: null,

    /**
     * @inheritdoc
     */
    title: readOnly('data.Label'),

    /**
     * @inheritdoc
     */
    image: readOnly('data.Image'),

    /**
     * @inheritdoc
     */
    visible: bool('children.length'),

    /**
     * @property {Object} moduleAbility - result of ACL validation, for module availability
     */
    moduleAbility: Ember.computed('accessControl', function () {
      return this.accessControl.can('create module-gallery', null, {
        name: this.get('data.Name').toLowerCase()
      });
    }),

    /**
     * @property {Boolean} enabled - is module enabled
     */
    enabled: oneWay('moduleAbility.isGranted'),

    /**
     * @property {String} errorMessage - error message that indicates the reason module is not enabled
     */
    errorMessage: Ember.computed('moduleAbility.isGranted', function () {
      return this.enabled ? '' : this.get('moduleAbility.message');
    }),

    /**
     * @inheritdoc
     */
    satisfiesSearch(searchRegex) {
      return searchRegex.test(this.title) || this.children.some(child => child.satisfiesSearch(searchRegex));
    },

    /**
     * filter by title
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByTitle(searchRegex) {
      return searchRegex.test(this.title) || this.children.some(child => child.satisfiesSearchByTitle(searchRegex));
    },

    /**
     * filter by tag
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByTag(searchRegex) {
      return searchRegex.test(this.data.Tags) || this.children.some(child => child.satisfiesSearchByTag(searchRegex));
    },

    /**
     * filter by description
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByDescription(searchRegex) {
      return searchRegex.test(this.data.Description) || this.children.some(child => child.satisfiesSearchByDescription(searchRegex));
    },

    /*
     * @inheritdoc
     */
    getInsertModel() {
      const children = this.children || [];
      const lastChild = children.sortBy('data.DateCreated').get('lastObject');
      return lastChild || null;
    },

    /**
     * @inheritdoc
     */
    getInsertData() {
      throw new Ember.Error('Modules cannot be inserted into page, only module themes');
    }

  });

  _exports.default = _default;
});