define("builder/core/enumerators/variant-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_SEO_NAME = _exports.PAGE_META_NAME = _exports.META_VARIABLES_NAME = void 0;

  /**
   * Enum for variant names
   */
  // Meta Variables form-variant name
  const META_VARIABLES_NAME = 'meta-variables'; //  Page Meta form-variant name

  _exports.META_VARIABLES_NAME = META_VARIABLES_NAME;
  const PAGE_META_NAME = 'meta-rule'; //  Page Seo form-variant name

  _exports.PAGE_META_NAME = PAGE_META_NAME;
  const PAGE_SEO_NAME = 'seo-rule';
  _exports.PAGE_SEO_NAME = PAGE_SEO_NAME;
});