define("builder/core/froala/metatags/metatags-insert", ["exports", "builder/core/meta-tags/dom-manipulation"], function (_exports, _domManipulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insertMetatag = insertMetatag;

  function insertMetatag(froalaEditor, metatag) {
    const metaTag = (0, _domManipulation.createMetaTagElement)(metatag);
    const htmlToInsert = `${metaTag.outerHTML}`;
    froalaEditor.html.insert(htmlToInsert);
    /**
     * Froala sets cursor position inside inline element after inserting it.
     * We need to move cursor after inserted metatag so next metatag will be inserted
     * correctly
     */

    const selectionEnd = froalaEditor.selection.endElement();
    const metaElement = selectionEnd.parentNode;
    froalaEditor.selection.setAfter(metaElement);
    froalaEditor.selection.restore();
  }
});