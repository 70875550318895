define("builder/core/drop-rules/widget-tab-pane", ["exports", "builder/core/drop-rules/widget-container", "builder/core/enumerators/container"], function (_exports, _widgetContainer, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Drag and drop rules for widget-section
   */
  var _default = _widgetContainer.default.extend({
    canDrop(droppable, draggable, direction, anchor, ...args) {
      if (!droppable || !draggable) {
        return false;
      } // Do not drop next to self


      if (anchor === draggable) {
        return false;
      }

      if (draggable.originObject.type === _container.default.WIDGET_CONTAINER) {
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});