define("builder/initializers/web-socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    const socket = Ember.Object.extend();
    application.register('socket:main', socket);
    application.inject('route', 'socket', 'socket:main');
    application.inject('controller', 'socket', 'socket:main');
  }

  var _default = {
    name: 'web-socket',
    after: 'set-cookies',
    initialize
  };
  _exports.default = _default;
});