define("builder/pods/components/form-module/children-module", ["exports", "builder/pods/components/form-module/module-base", "builder/pods/components/form-module/form-item", "builder/mixins/event-base", "builder/pods/components/form-module/fields-handler", "builder/pods/components/form-module/component", "jquery", "builder/core/enumerators/buttons", "builder/core/enumerators/form-fields", "builder/core/util/define-fields-visibility"], function (_exports, _moduleBase, _formItem, _eventBase, _fieldsHandler, _component, _jquery, _buttons, _formFields, _defineFieldsVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _moduleBase.default.extend(_eventBase.default, {
    /**
     * Service for feature toggling
     * @type {Ember.Service}
     */
    features: null,

    /**
     * Monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     * @type {boolean}
     */
    renderer: true,

    /**
     * Form component itself
     * @type {Ember.Component}
     */
    form: null,

    /**
     * Field form types
     * @type {FormFieldTypeModel}
     */
    formFieldTypes: null,

    /**
     * Form field add service
     * @type {FormAddFieldService}
     */
    formFieldAddService: null,

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: null,

    /**
     * Selected item on the form
     * @type {Object}
     * @private
     */
    _activeItem: null,

    /**
     * The cache for the current update form item
     * @type {Object}
     * @private
     */
    _formFieldUpdateInProgress: Ember.Object.create(),

    /**
     * The cache for the same as the current update form item, contains (if it has) the following query for one config
     * @type {Object}
     * @private
     */
    _formFieldUpdateNextPromise: Ember.Object.create(),

    /**
     * @inheritDoc
     */
    init() {
      this._super();

      this.set('events', {
        click: null,
        dblclick: null,
        mouseenter: null,
        mouseleave: null,
        removeButtonClick: null,
        itemRemoved: null
      });

      this._attachEvents();
    },

    /**
     * Recursive children creation
     * @param {Object[]} [children] - children object
     * @param {Object|null} [parent] - parent object
     * @returns {Object[]}
     */
    createChildren(children = [], parent = null) {
      const items = [];

      for (let i = 0, len = children.length; i < len; i++) {
        const item = this.createItem(children[i], parent);
        item.set('children', this.createChildren(children[i].children, item));
        items.push(item);
      }

      return items;
    },

    /**
     * Create form item by its config
     * @param {Object} config - form item config
     * @param {Object} parent - parent component
     * @returns {Object}
     */
    createItem(config, parent = null) {
      const canEditField = this.accessControl.can('edit fields form').isGranted;

      const item = _formItem.default.create({
        wrapperClass: config.wrapperClass,
        type: config.type,
        wrapperTagName: config.wrapperTagName,
        hiddenFields: config.hiddenFields,
        editable: canEditField && config.controls.edit,
        removable: canEditField && config.controls.remove,
        draggable: canEditField && config.draggable,
        droppable: canEditField && config.droppable,
        useMask: true,
        wrap: config.wrap,
        data: config.data,
        parent
      });

      this._attachChildEvents(item);

      return item;
    },

    /**
     * Enables encryption field when SSN field was removed
     * @returns {void}
     */
    enableEncryptionField() {
      this.pageModuleService.getConfig(this.form.data.originObject.id).then(record => {
        const theme = this.store.peekRecord('module-theme', record.ThemeId);
        const isEncryptedField = theme.Structure.findBy('name', 'isEncrypted');

        if (isEncryptedField) {
          isEncryptedField.disabled = false;
        }
      });
    },

    /**
     * Set field value
     * @param {Object} field - target field
     * @param {Object} data - field data
     * @returns {void}
     */
    setFieldValue(field, data) {
      switch (field.name) {
        case 'isPrivate':
        case 'validation.required':
        case 'servicesAdditionalFields':
        case 'verify':
          Ember.set(field, 'data', data === true ? 'yes' : 'no');
          break;

        case 'validation.range':
        case 'validation.length':
        case 'validation.elements':
          Ember.set(field, 'data', data ? [data.min, data.max] : undefined);
          break;

        case 'type':
          Ember.set(field, 'data', this._getFieldTypeText(data));
          break;

        default:
          Ember.set(field, 'data', data);
      }
    },

    /**
     * Attach events on module init
     * @returns {void}
     * @private
     */
    _attachEvents() {
      const me = this;
      (0, _jquery.default)(document).on('keydown', function (e) {
        const activeItem = me.get('_activeItem');

        if (e.which === 46 && activeItem) {
          me._remove(activeItem);
        }
      });
      this.form.attachListener('modeChanged', function (mode) {
        if (mode === _component.default.MODE.DISPLAY) {
          me._unsetActive();
        }
      });
    },

    /**
     * Attach form item supported events
     * @param {Object} formItem - form-item instance
     * @returns {void}
     * @private
     */
    _attachChildEvents(formItem) {
      const canEditField = this.accessControl.can('edit fields form').isGranted;

      if (!canEditField) {
        return;
      }

      formItem.attachListener('click', item => this._activate(item));
      formItem.attachListener('editButtonClick', item => this._showFieldConfigurationPopup(item));
      formItem.attachListener('mouseenter', item => this._hover(item));
      formItem.attachListener('mouseleave', item => this._unhover(item));
      formItem.attachListener('removeButtonClick', item => {
        this._remove(item);

        if (item.data.type === _formFields.default.SOCIAL_SECURITY) {
          this.enableEncryptionField();
        }
      });
    },

    /**
     * Activate item
     * @param {Object} item - item to activate
     * @param {boolean} [force] - item will be activate only if config is not opened, this option will activate item
     * despite config status
     * @returns {void}
     * @private
     */
    _activate(item, force = false) {
      if (force || !this.fieldConfigVisible) {
        this._unsetActive();

        item.set('active', true);
        this.set('_activeItem', item);
      }
    },

    /**
     * Make last active item inactive
     * @returns {void}
     * @private
     */
    _unsetActive() {
      const activeItem = this._activeItem;

      if (activeItem) {
        activeItem.set('active', false);
      }
    },

    /**
     * Edit item
     * @param {Object} item - item to edit
     * @returns {void}
     * @private
     */
    _showFieldConfigurationPopup(item) {
      this._activate(item, true);

      const {
        data
      } = item;
      const fieldType = data.type;
      const settingFields = this.formFieldAddService.getFieldStructureByType(fieldType);
      let configType = 'field';

      switch (fieldType) {
        case _buttons.FORM_SUBMIT:
        case _buttons.FORM_NEXT:
        case _buttons.FORM_PREV:
          {
            configType = 'button';
            break;
          }
      }

      settingFields.forEach(field => {
        const fieldData = Ember.get(data, field.name) || field.data;
        this.setFieldValue(field, fieldData);
      });
      /**
       * Update configuration fields visibility according showOnlyIf property
       */

      (0, _defineFieldsVisibility.defineFieldsVisibility)(settingFields);
      this.parentActions({
        configType,
        action: 'openConfig',
        form: this.form,
        config: settingFields,
        change: (value, name) => {
          (0, _fieldsHandler.processField)(name, value, data);

          this._updateFormItem(this, item);
          /**
          	* Update configuration fields visibility according showOnlyIf property
          	*/


          (0, _defineFieldsVisibility.defineFieldsVisibility)(settingFields);
        }
      });
    },

    /**
     * Get field title by type
     * @param {string} fieldType - field type
     * @returns {string}
     * @private
     */
    _getFieldTypeText(fieldType) {
      switch (fieldType) {
        case _formFields.default.VEHICLE_TYPE:
          {
            return 'Dropdown';
          }

        default:
          {
            const name = fieldType.replace('field-', '');
            return name.charAt(0).toUpperCase() + name.slice(1);
          }
      }
    },

    /**
     * Update the form element using the cache so that you do not send many identical queries on the same configuration
     * @param {Object} module - module to update
     * @param {Object} item - item to update
     * @returns {void}
     * @private
     */
    _updateFormItem(module, item) {
      const updateInProgress = this._formFieldUpdateInProgress;
      const nextPromise = this._formFieldUpdateNextPromise;

      if (!updateInProgress.get(item.elementId)) {
        updateInProgress.set(item.elementId, true);

        this._runUpdateFormItem(module, item);
      } else {
        nextPromise.set(item.elementId, true);
      }
    },

    /**
     * Run update form item
     * @param {Object} module - module to update
     * @param {Object} item - item to update
     * @returns {void}
     * @private
     */
    _runUpdateFormItem(module, item) {
      const updateInProgress = this._formFieldUpdateInProgress;
      const nextPromise = this._formFieldUpdateNextPromise;
      module.updateItem(item).finally(() => {
        if (nextPromise.get(item.elementId)) {
          nextPromise.set(item.elementId, false);

          this._runUpdateFormItem(module, item);
        } else {
          updateInProgress.set(item.elementId, false);
        }
      });
    },

    /**
     * Remove item handler
     * @param {Object} item - item to remove
     * @returns {void}
     * @private
     */
    _remove(item) {
      const children = item.get('parent.children');
      const {
        parentActions
      } = this;

      if (children && item.get('removable')) {
        const index = children.indexOf(item);

        if (index !== -1) {
          children.splice(index, 1);
        }

        parentActions({
          action: 'closeFieldConfig'
        });
        item.get('wrapper').remove();
        item.dispatchEvent('remove', item);
        this.dispatchEvent('itemRemoved', item);
      }
    },

    /**
     * Item hover handler
     * @param {Object} item - hovered item
     * @returns {void}
     * @private
     */
    _hover(item) {
      item.set('hover', true);
    },

    /**
     * Item unhover handler
     * @param {Object} item - unhovered item
     * @returns {void}
     * @private
     */
    _unhover(item) {
      item.set('hover', false);
    }

  });

  _exports.default = _default;
});