define("builder/core/abstract-classes/index", ["exports", "builder/core/abstract-classes/field-base-component"], function (_exports, _fieldBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_fieldBaseComponent).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _fieldBaseComponent[key];
      }
    });
  });
});