define("builder/core/enumerators/marginable", ["exports", "builder/core/enumerators/widget", "builder/core/enumerators/module"], function (_exports, _widget, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Array of element to set default margin-bottom
   */
  var _default = [_widget.WidgetType.WIDGET_PARAGRAPH, _widget.WidgetType.WIDGET_IMAGE, _widget.WidgetType.WIDGET_BUTTON, _widget.WidgetType.WIDGET_BUTTONSGROUP, _widget.WidgetType.WIDGET_SPACER, _widget.WidgetType.WIDGET_LINE_DIVIDER, _module.default.MODULE_CORE, _module.default.MODULE_FORM];
  _exports.default = _default;
});