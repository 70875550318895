define("builder/abilities/tier/page-template-categories", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierPageTemplateCategoriesAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      canUseGlobal: {
        entity: 'page-template-categories',
        property: 'globaltemplates'
      },
      canUseMy: {
        entity: 'page-template-categories',
        property: 'mytemplates'
      },
      canUseGlobalPages: {
        entity: 'page',
        property: 'manage-global'
      }
    }
  });

  _exports.default = _default;
});