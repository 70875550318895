define("builder/pods/components/field-number/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_number'],

    /**
     * @property {Object|null} field - component data
     */
    field: null,

    /**
     * @property {Function|null} actionHandler - action handler function
     */
    actionHandler: null,

    /**
     * @inheritdoc
     */
    willInsertElement(...args) {
      this._super(...args);

      this.applyValidations();
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      if (this.triggerOnRender && this.get('field.data')) {
        this.sendParentAction(this.get('field.data'));
      }
    },

    sendParentAction(value) {
      const action = this.actionHandler;

      if (action && !this.notValid) {
        action(value, this.get('field.name'));
      }
    },

    actions: {
      /**
       * On change action
       *
       * @param {Event} e - event object
       */
      onChange(e) {
        const newValue = parseFloat(e.target.value);
        const oldValue = parseFloat(this.get('field.data'));

        if (newValue === oldValue) {
          return;
        }

        this.set('field.data', isNaN(newValue) ? null : newValue);
        this.sendParentAction(newValue);
      }

    }
  });

  _exports.default = _default;
});