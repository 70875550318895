define("builder/pods/components/fieldset-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KuXZhUtI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"legend\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"legend\",true],[10,\"class\",\"b2-fieldset-wrapper__legend\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"legend\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"help\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"widget-help\",null,[[\"text\"],[[24,[\"help\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isSwitchable\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"switcher-button\",null,[[\"change\",\"isActive\"],[[28,\"action\",[[23,0,[]],\"change\"],null],[24,[\"isActive\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"b2-fieldset-wrapper__content-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"isActive\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"b2-fieldset-wrapper__content-shadow\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/fieldset-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});