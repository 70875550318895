define("builder/core/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Graph = _exports.Vertex = void 0;

  /**
   * @class Vertex
   */
  const Vertex = Ember.Object.extend({
    /**
     * Value to be stored in vertex
     * @type {any}
     */
    value: null,

    /**
     * List of vertices that directed into current vertex
     * @type {Vertex[]}
     */
    ins: null,

    /**
     * List of vertices that are coming from current vertex
     * @type {Vertex[]}
     */
    outs: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('ins', []);
      this.set('outs', []);
    }

  });
  /**
   * DAG
   *
   * @class Graph
   */

  _exports.Vertex = Vertex;
  const Graph = Ember.Object.extend({
    /**
     * List of all created vertices
     * @type {Vertex[]}
     */
    vertices: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('vertices', []);
    },

    /**
     * Get/Find vertex by it's value
     *
     * @param {any} value - value of vertex to find
     * @returns {Vertex|null}
     */
    getVertex(value) {
      return this.vertices.findBy('value', value) || null;
    },

    /**
     * Make/Create new vertex
     *
     * @param {any} value - value to be stored in vertex
     * @returns {Vertex} - new created vertex
     */
    makeVertex(value) {
      const vertex = Vertex.create({
        value
      });
      this.vertices.push(vertex);
      return vertex;
    },

    /**
     * Make edge between 2 vertices
     *
     * @param {Vertex} fromVertex - edge from this vertex(directed from)
     * @param {Vertex} toVertex - edge to this vertex(directed to)
     */
    makeEdge(fromVertex, toVertex) {
      fromVertex.outs.push(toVertex);
      toVertex.ins.push(fromVertex);
    },

    /**
     * Get list of deepest vertices to which other vertices directed to
     * vertex(initial) -> vertex#1 -> vertex#2, vertex#2 is our target
     *
     * @param {Vertex} vertex - root vertex
     * @returns {Vertex[]}
     */
    getReceivers(vertex) {
      let outs = vertex.get('outs');
      let lastOuts = [];

      while (outs.length) {
        lastOuts = outs;
        outs = lastOuts.reduce((accumulator, v) => accumulator.concat(v.get('outs')), []);
      }

      return lastOuts;
    },

    /**
     * Get vertices from which direction comes
     * vertex -> vertex#1 -> vertex#2, here if we pass vertex#2, then vertex is our target
     *
     * @param {Vertex} vertex - root vertex
     * @returns {Vertex[]} - list of vertices directed to passed in vertex(directly or indirectly)
     */
    getBroadcasters(vertex) {
      let ins = vertex.get('ins');
      let lastIns = [];

      while (ins.length) {
        lastIns = ins;
        ins = lastIns.reduce((accumulator, v) => accumulator.concat(v.get('ins')), []);
        break;
      }

      return lastIns;
    }

  });
  _exports.Graph = Graph;
});