define("builder/abilities/authorization/page-template", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlAuthorizationPageTemplateAbility
   */
  var _default = _ability.default.extend({
    /**
     * Id of current user
     * @type {string}
     */
    userId: Ember.computed.readOnly('app.user.id'),

    /**
     * @inheritdoc
     */
    map: {
      canUpdate: {
        entity: 'page-template',
        property: 'templateadmin'
      },
      canDelete: {
        entity: 'page-template',
        property: 'templateadmin'
      }
    },

    /**
     * @inheritdoc
     */
    handle(...args) {
      const template = this.model;

      if (template) {
        if (this.isSystem(template)) {
          return false;
        }

        if (this.isAuthor(template)) {
          return true;
        }
      }

      return this._super(...args);
    },

    /**
     * Is template is system template
     *
     * @param {PageTemplateModel} template - page template model
     * @returns {boolean}
     */
    isSystem(template) {
      return template.get('IsSystem');
    },

    /**
     * Is template was created by current user
     *
     * @param {PageTemplateModel} template - page template model
     * @returns {boolean}
     */
    isAuthor(template) {
      return template.get('Author') === this.userId;
    }

  });

  _exports.default = _default;
});