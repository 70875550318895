define("builder/core/enumerators/template-visibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Visibility constants for page and container templates
   */
  var _default = {
    /**
     * Visible only for me visibility type
     * @type {string}
     */
    VISIBILITY_PRIVATE: '1',

    /**
     * Visible for everyone in my company visibility type
     * @type {string}
     */
    VISIBILITY_COMPANY: '2',

    /**
     * Visible for dealerfire employees visibility type
     * @type {string}
     */
    VISIBILITY_DEALERFIRE_EMPLOYEES: '3',

    /**
     * Visible globally for all users visibility type
     * @type {string}
     */
    VISIBILITY_GLOBAL: '4'
  };
  _exports.default = _default;
});