define("builder/helpers/background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Template helper, to get background image
   */
  var _default = Ember.Helper.extend({
    /**
     * @inheritdoc
     */
    compute(params) {
      return Ember.String.htmlSafe(`background-image: url(${params[0]})`);
    }

  });

  _exports.default = _default;
});