define("builder/errors/global-page-validation-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class GlobalPageValidationError
   */
  class GlobalPageValidationError extends Ember.Error {
    constructor(message, pageModuleIds) {
      super(message);
      this.pageModuleIds = pageModuleIds;
    }

  }

  _exports.default = GlobalPageValidationError;
});