define("builder/pods/components/page-meta-export/component", ["exports", "js-yaml", "file-saver"], function (_exports, _jsYaml, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PageMetaExportComponent = (_dec = Ember.computed('pageMeta.Structure.@each'), _dec2 = Ember.computed('pageId'), _dec3 = Ember._action, (_class = (_temp = class PageMetaExportComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['page-meta-button'];
      this.pageId = void 0;
      this.pageMeta = void 0;
    }

    /**
     * Data for serialization to yaml
     * @type {MetaItem | null}
     */
    get pageMetaCollection() {
      if (!this.pageMeta) {
        return null;
      }

      const meta = this.pageMeta.toJSON();

      if (!meta.Structure) {
        return null;
      }

      return meta.Structure;
    }
    /**
     * File name to generate
     * @type {string}
     */


    get fileName() {
      return `page-meta-${this.pageId || 'unknown'}.yml`;
    }
    /**
     * Serializes current page meta to yml file
     * @returns {void}
     */


    serializePageMeta() {
      const metaContent = _jsYaml.default.safeDump(this.pageMetaCollection);

      const blob = new Blob([metaContent], {
        type: 'text/plain;charset=utf-8'
      });

      _fileSaver.default.saveAs(blob, this.fileName);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "pageMetaCollection", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pageMetaCollection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fileName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "serializePageMeta", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "serializePageMeta"), _class.prototype)), _class));
  _exports.default = PageMetaExportComponent;
});