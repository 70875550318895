define("builder/core/enumerators/template-category-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /**
     * Category is static, not a virtual
     * @type {number}
     */
    STATIC: 1,

    /**
     * Category is not static, not virtual
     * @type {number}
     */
    NON_STATIC: 0
  };
  _exports.default = _default;
});