define("builder/widgets/configs/widget-section", ["exports", "builder/core/enumerators/widget-container-video-background-fields", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "builder/core/styles-management/widget-section/structure", "ember-copy"], function (_exports, _widgetContainerVideoBackgroundFields, _fields, _breakpointModes, _structure, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The configuration itself is really close to the container's one, though it may change dramatically in the future.
   */
  const infoField = {
    type: 'field-info-block',
    label: 'Information',
    theme: 'info',
    content: 'The Section Module allows for other modules to be placed within it. This allows you to create a new grouping of modules within a Container.'
  };
  const videoBackground = {
    type: _fields.default.TEXT,
    label: 'Video Background',
    name: _widgetContainerVideoBackgroundFields.default.VIDEO_URL,
    help: `Video will play and loop automatically, without sound. When on mobile, the video will not be shown,
						and instead the container will display whatever else is set in Section Styles for the background
						(image, color, or gradient). The video will also revert to other container background settings on
						larger devices if the video file is too large to load quickly (< 6 sec) for the end-user.`,
    placeholder: 'Paste YouTube URL...',
    validation: {
      pattern: '^(https|http):\\/\\/(youtu\\.be|www\\.youtube\\.com\\/watch).+|^$',
      message: 'Please paste a valid YouTube URL.'
    }
  };
  const fullWidthColumns = {
    type: _fields.default.RADIOLIST,
    label: 'Full-Width Columns',
    name: 'resetColumnsPadding',
    choices: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    data: false,
    description: 'Enable full-width for all columns in the section?',
    help: 'When box is checked, this will disable padding between modules inside the section. ' + '(e.g. If you put two images side by side, there will be no space between them.)'
  };
  const deviceVisibility = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Show container on which devices / breakpoints?',
    description: 'Please select at least 1 device for the module to show on',
    help: 'Un-checking the box means all the modules within the container will be hidden on that device / breakpoint.',
    name: 'visibleOnDevices',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this module to display on.'
    }
  };

  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      return {
        styles: (0, _emberCopy.copy)(_structure.default, true),
        config: [infoField, videoBackground, fullWidthColumns, deviceVisibility]
      };
    }

  });

  _exports.default = _default;
});