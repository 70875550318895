define("builder/pods/components/field-media-active-area-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uItZ123S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"select\",\"delete\"],[[28,\"action\",[[23,0,[]],\"open\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null]]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"field-media-image-preview__wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"field\",\"data\",\"url\"]]],null,{\"statements\":[[4,\"image-marker\",null,[[\"x\",\"y\",\"title\",\"updatePosition\"],[[24,[\"focalX\"]],[24,[\"focalY\"]],[24,[\"literals\",\"FOCAL_POINT_HELP\"]],[28,\"action\",[[23,0,[]],\"updateFocalPointPosition\"],null]]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[28,\"get-absolute-image-url\",[[24,[\"field\",\"data\",\"url\"]]],null]],[10,\"class\",\"field-media__image\"],[10,\"alt\",\"Media image\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",false],[12,\"class\",\"field-media-image-preview__content\"],[3,\"action\",[[23,0,[]],\"open\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field-media-image-preview__icon-wrapper\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-camera\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field-media-image-preview__content-text\"],[8],[0,\"Click here to upload image\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"field\",\"data\",\"url\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"slider-picker\",null,[[\"min\",\"max\",\"start\",\"hover\",\"showInput\",\"onChange\"],[[24,[\"min\"]],[24,[\"max\"]],[24,[\"level\"]],false,false,[28,\"action\",[[23,0,[]],\"updateZoomLevel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-media-active-area-image/template.hbs"
    }
  });

  _exports.default = _default;
});