define("builder/pods/components/field-media-active-area/component", ["exports", "builder/utils/location"], function (_exports, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldMediaActiveArea
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: null,

    /**
     * @property {String} iconClass - icon to be used for "select different media file" button
     */
    iconClass: '',

    /**
     * @property {Object} field - origin field data
     */
    field: null,

    /**
     * @property {MediaManagerService} mediaManager - media manager
     */
    mediaManager: Ember.inject.service('media-manager'),

    /**
     * @property {Function} update - function to be executed whenever selected file changes
     */
    update() {},

    /**
     * @inheritdoc
     */
    didInsertElement() {
      if (this.get('field.autoOpen')) {
        this.showPopup();
      }
    },

    /**
     * Show media manager popup
     */
    showPopup() {
      const me = this;
      const validation = this.get('field.validation');
      const validationStr = validation ? `&${$.param(validation)}` : '';
      this.mediaManager.open(validationStr, {
        select(file) {
          me.select(file);
        },

        delete(file) {
          me.delete(file);
        }

      });
    },

    /**
     * Invoke data update callback
     *
     * @param {Object} data - data to be sent for current field data update
     */
    sendData(data) {
      this.update(data);
    },

    /**
     * Method to be invoked when file is selected in media manager
     *
     * @param {Object} file - file object received from media manager
     */
    select(file) {
      // Remove previously selected file data
      this.sendData({
        type: file.type.split(',').shift(),
        url: _location.default.pathname(file.url),
        naturalWidth: parseInt(file.width, 10) || 0,
        naturalHeight: parseInt(file.height, 10) || 0
      });
    },

    /**
     * Method to be invoked when file is deleted from media manager
     *
     * @param {Object} file - file deleted from media manager
     */
    delete(file) {
      if (file && _location.default.pathname(file.url) === this.get('field.data.url')) {
        this.sendData(null);
      }
    },

    actions: {
      /**
       *
       * @param {Object|null} file - image file meta or null if file was removed/deselected
       */
      update(file) {
        this.update(file);
      },

      /**
       * Open file select action
       */
      open() {
        this.showPopup();
      },

      /**
       * Delete file from media manager action
       */
      delete() {
        this.sendData(null);
      }

    }
  });

  _exports.default = _default;
});