define("builder/core/enumerators/module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Enum of available modules
   */
  var _default = Ember.Object.create({
    MODULE_CORE: 'core-module',
    MODULE_FORM: 'form-module',
    MODULE_SYSTEM: 'core-systemmodule'
  });

  _exports.default = _default;
});