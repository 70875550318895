define("builder/validators/seo-urls", ["exports", "builder/core/abstract-classes/base-ajax-validator", "builder/core/literals", "builder/config/environment"], function (_exports, _baseAjaxValidator, _literals, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SeoUrls = _baseAjaxValidator.default.extend({
    /**
     * @inheritdoc
     */
    ajaxOptions(value, options, model) {
      const template = model.get('variant.children').get(0).data;
      const filter = model.get('variant.children').get(1).data;
      return {
        method: 'PUT',
        url: `${_environment.default.apiURL}/page_seourlvalidator/${options.pageId}`,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          pageSeourlValidator: {
            template,
            filter
          }
        })
      };
    },

    /**
     * @inheritdoc
     */
    validate(value, options, model) {
      const template = model.get('variant.children').get(0).data;
      const filter = model.get('variant.children').get(1).data;
      const original = model.get('variant.original');
      const variants = model.get('variants'); // If template is empty we return true, because required validation return error

      if (!template) {
        return true;
      }

      if (variants.some(item => item !== original && item.template === template && item.filter === filter)) {
        return this.createErrorMessage('customMessage', value, {
          description: _literals.default.IS_NOT_UNIQUE_SEO_RULE
        });
      } // Property isFilter need for fixed bugs with remove incorrect value from filter,
      // and when filter will be empty after that.
      // TODO After validation refactor, need change it


      if (!filter && options.isFilter) {
        return true;
      }

      return this._super(value, options, model);
    }

  });

  var _default = SeoUrls;
  _exports.default = _default;
});