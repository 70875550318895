define("builder/pods/components/sm-color/component", ["exports", "builder/core/abstract-classes/variable-component", "builder/core/styles-management/utils/get-variables-structure", "builder/models/module-style", "builder/models/website-style"], function (_exports, _variableComponent, _getVariablesStructure, _moduleStyle, _websiteStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *	Component helps build color picker in styles management interface.
   *
   * @class SMColorComponent
   *
   * Basic usage:
   * 		Direct passing attributes:
   * 		{{sm-color
   * 				variablesModel=model
   * 				label="label"
   * 				updateVariable=(action "updateVariable")
   * 				mode="lg"
   * 		  	variableName="name"
   * 		  	cssPropertyName="color"
   * 		}}
   *		Implicit attribute passing(helpful on dynamic render):
   *		{{sm-color baseAttrs=justLikeAllUpper}}
   */
  var _default = _variableComponent.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['line-box__line', 'line-box__inner'],

    /**
     * @property {Handlebars.SafeString} backgroundStyle - contain value for style attribute
     */
    backgroundStyle: Ember.computed('previewColor', function () {
      return Ember.String.htmlSafe(`background: ${this.previewColor}`);
    }),

    /**
     * @property {String} previewColor - contain color for fast preview response
     */
    previewColor: Ember.computed('variable', 'variable.value', {
      get() {
        return this.get('variable.value');
      },

      set(key, value) {
        return value;
      }

    }),

    /**
     * @property {String[]} dependentVariableColors - list variables names to make current variable dependent from listed
     * @readonly
     */
    dependentVariableColors: Ember.computed(function () {
      return ['primary-color', 'primary-color-hover', 'secondary-color', 'secondary-color-hover', 'line-color', 'solid-box-color', 'structure-color'];
    }).readOnly(),

    /**
     * @property {[Object[]]} palette
     * @property {String} Object[].value - color
     * @property {String} Object[].label - title
     * @readonly
     */
    palette: Ember.computed(function () {
      let palette = [[{
        value: '#f00',
        label: '#f00'
      }, {
        value: '#f90',
        label: '#f90'
      }, {
        value: '#ff0',
        label: '#ff0'
      }, {
        value: '#0f0',
        label: '#0f0'
      }, {
        value: '#00f',
        label: '#00f'
      }, {
        value: '#90f',
        label: '#90f'
      }, {
        value: '#f0f',
        label: '#f0f'
      }], [{
        value: '#f4cccc',
        label: '#f4cccc'
      }, {
        value: '#fce5cd',
        label: '#fce5cd'
      }, {
        value: '#fff2cc',
        label: '#fff2cc'
      }, {
        value: '#d9ead3',
        label: '#d9ead3'
      }, {
        value: '#cfe2f3',
        label: '#cfe2f3'
      }, {
        value: '#d9d2e9',
        label: '#d9d2e9'
      }, {
        value: '#ead1dc',
        label: '#ead1dc'
      }], [{
        value: '#ea9999',
        label: '#ea9999'
      }, {
        value: '#f9cb9c',
        label: '#f9cb9c'
      }, {
        value: '#ffe599',
        label: '#ffe599'
      }, {
        value: '#b6d7a8',
        label: '#b6d7a8'
      }, {
        value: '#9fc5e8',
        label: '#9fc5e8'
      }, {
        value: '#b4a7d6',
        label: '#b4a7d6'
      }, {
        value: '#d5a6bd',
        label: '#d5a6bd'
      }], [{
        value: '#e06666',
        label: '#e06666'
      }, {
        value: '#f6b26b',
        label: '#f6b26b'
      }, {
        value: '#ffd966',
        label: '#ffd966'
      }, {
        value: '#93c47d',
        label: '#93c47d'
      }, {
        value: '#6fa8dc',
        label: '#6fa8dc'
      }, {
        value: '#8e7cc3',
        label: '#8e7cc3'
      }, {
        value: '#c27ba0',
        label: '#c27ba0'
      }], [{
        value: '#c00',
        label: '#c00'
      }, {
        value: '#e69138',
        label: '#e69138'
      }, {
        value: '#f1c232',
        label: '#f1c232'
      }, {
        value: '#6aa84f',
        label: '#6aa84f'
      }, {
        value: '#3d85c6',
        label: '#3d85c6'
      }, {
        value: '#674ea7',
        label: '#674ea7'
      }, {
        value: '#a64d79',
        label: '#a64d79'
      }], [{
        value: '#900',
        label: '#900'
      }, {
        value: '#b45f06',
        label: '#b45f06'
      }, {
        value: '#bf9000',
        label: '#bf9000'
      }, {
        value: '#38761d',
        label: '#38761d'
      }, {
        value: '#0b5394',
        label: '#0b5394'
      }, {
        value: '#351c75',
        label: '#351c75'
      }, {
        value: '#741b47',
        label: '#741b47'
      }]];
      const variablesPaletteData = this.variablesPalette;

      if (variablesPaletteData && variablesPaletteData.palette) {
        palette = [variablesPaletteData.palette].concat(palette);
      }

      return palette;
    }).readOnly(),

    /**
     * @property {null|Object} - defined palette for dependency colors
     * @property {[Object[]]} Object.palette - data for palette
     * @property {Map} Object.map - contains variables and palette mapping
     * @readonly
     */
    variablesPalette: Ember.computed(function () {
      const data = [];
      const map = new Map();

      if (this.variablesModel instanceof _moduleStyle.default || this.variablesModel instanceof _websiteStyle.default) {
        const {
          dependentVariableColors
        } = this;

        if (!dependentVariableColors.includes(this.variableName)) {
          const {
            variableResolver
          } = this;
          const {
            mode
          } = this;
          const structParts = (0, _getVariablesStructure.default)(dependentVariableColors, []);
          structParts.forEach(part => {
            const resolvedVar = variableResolver.resolve(part.variableName, mode);
            const variable = resolvedVar.dependedVar || resolvedVar.variable;

            if (variable) {
              const palette = {
                label: part.label,
                value: variable.get('value')
              };
              map.set(palette, variable);
              data.push(palette);
            }
          });
        }
      }

      return data && data.length ? {
        palette: data,
        map
      } : null;
    }).readOnly(),
    actions: {
      /**
       * @param {String} color - hex,rgba or name(red, blue and etc)
       */
      updatePreviewColor(color) {
        this.set('previewColor', color);
      },

      /**
       * Action which update variable value
       *
       * @param {String|Object} color - color which will be set as new
       * @param {String} color.value - color hex, string or rgba value
       */
      updateVariable(color) {
        if (Ember.typeOf(color) !== 'object') {
          this.send('update', color);
          return;
        }

        const varPalette = this.variablesPalette;
        let variable;

        if (varPalette) {
          variable = varPalette.map.get(color);
        }

        if (variable) {
          var _this$themeConfig;

          const {
            name
          } = variable;
          let varName = `$${name}`; // Use css custom properties format for global styles OR modules with hasCSSCustomProperties flag true

          if (!this.themeConfig || ((_this$themeConfig = this.themeConfig) === null || _this$themeConfig === void 0 ? void 0 : _this$themeConfig.hasCSSCustomProperties)) {
            varName = `var(--${name})`;
          }

          this.send('update', varName);
        } else {
          this.send('update', color.value);
        }
      }

    }
  });

  _exports.default = _default;
});