define("builder/models/menu", ["exports", "@ember-data/model", "builder/models/model", "builder/models/menu-root", "ember-data-model-fragments/attributes", "builder/core/enumerators/system-aliases"], function (_exports, _model, _model2, _menuRoot, _attributes, _systemAliases) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class MenuModel
   */
  const ModelExport = _model2.default.extend({
    /**
     * @property {fragmentArray} Structure - menu structure
     */
    Structure: (0, _attributes.fragmentArray)('menu-root'),

    /**
     * @property {String} BaseStructure - original serialized structure
     */
    BaseStructure: (0, _model.attr)('string'),

    /**
     * @property {DS.attr} WebsiteId - website id
     */
    WebsiteId: (0, _model.attr)('string'),

    /**
     * @property {MenuRoot} mainMenu - Main menu object
     */
    mainMenu: Ember.computed('Structure.@each.Type', function () {
      return this.Structure.findBy('Type', _menuRoot.default.MAIN);
    }),

    /**
     * @property {MenuRoot} unlinkedMenu - Unlinked menu object
     */
    unlinkedMenu: Ember.computed('Structure.@each.Type', function () {
      return this.Structure.findBy('Type', _menuRoot.default.UNLINKED);
    }),

    /**
     * Returns first page in the whole menu
     * @returns {MenuChildModel}
     */
    getFirstPage() {
      let page = this.mainMenu.getFirstPage();

      if (!page) {
        page = this.unlinkedMenu.getFirstPage();
      }

      return page;
    },

    /**
     * Returns home page in the whole menu
     * @returns {MenuChildModel}
     */
    getHomePage() {
      return this.findPageByAlias(_systemAliases.HOME_PAGE_ALIAS);
    },

    /**
     * Finds page by alias
     * @param {String} alias - Alias to search
     * @returns {MenuChildModel}
     */
    findPageByAlias(alias) {
      let menuItem = this.mainMenu.findPageByAlias(alias);

      if (!menuItem) {
        menuItem = this.unlinkedMenu.findPageByAlias(alias);
      }

      return menuItem;
    },

    /**
     * Calls MenuChild method to mark pages as missed
     * @param {Object} aliases - Object of aliases
     */
    markMissingPages(aliases) {
      this.Structure.forEach(rootItem => rootItem.markMissingPages(aliases));
    },

    /**
     * Calls MenuChild method to clear structure
     */
    clearStructure() {
      this.Structure.forEach(rootItem => rootItem.clearStructure());
    },

    /**
     * Update page title found by alias
     * @param {String} alias - Page alias
     * @param {String} title - New page title
     */
    updatePageTitle(alias, title) {
      this.mainMenu.updatePageTitle(alias, title);
      this.unlinkedMenu.updatePageTitle(alias, title);
    },

    /**
     * @inheritDoc
     */
    save(...args) {
      this.Structure.forEach(rootObject => rootObject.removeDeletedItems());
      return this._super(...args);
    }

  });

  var _default = ModelExport;
  _exports.default = _default;
});