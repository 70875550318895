define("builder/pods/components/link-add-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MpQLNvTb",
    "block": "{\"symbols\":[],\"statements\":[[5,\"widget-popup\",[[12,\"class\",\"widget-popup_theme_field-link-paragraph js-field-link-paragraph-popup\"]],[[\"@closeHandler\",\"@isFixedCentered\"],[[23,0,[\"onCancelClick\"]],true]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page_module-config page_module-config_theme-light form_field-config\"],[8],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"page_module-config_title\"],[8],[0,\"Create New Link\"],[9],[0,\"\\n\\t\\t\"],[7,\"form\",true],[10,\"class\",\"page_module-config_form\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_field bld-form_field--width-available\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"field-text\",[],[[\"@field\",\"@autofocus\"],[[23,0,[\"linkText\"]],true]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_field bld-form_field--width-available\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"field-link\",[],[[\"@field\"],[[23,0,[\"linkData\"]]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_module-config__buttons\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"default-button\",[],[[\"@label\",\"@handler\",\"@isDisabled\"],[\"Create\",[23,0,[\"onCreateClick\"]],[28,\"unless\",[[24,[\"linkData\",\"data\",\"value\"]],true],null]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/link-add-popup/template.hbs"
    }
  });

  _exports.default = _default;
});