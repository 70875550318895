define("builder/pods/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pSyZq0cx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"accept\",[22,\"accept\"]],[11,\"class\",[22,\"inputClass\"]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[14,1,[[28,\"action\",[[23,0,[]],\"select\"],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});