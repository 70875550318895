define("builder/pods/components/widget-tab-pane/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IHbV9n6Q",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[4,\"each\",[[24,[\"data\",\"originObject\",\"children\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"component\",[[23,1,[\"originObject\",\"type\"]]],[[\"data\",\"setChange\",\"createStructureCheckpoint\",\"iframeCSS\",\"parentActions\",\"onFirstRender\",\"toggleDraggable\",\"moduleAction\",\"updateAllDimensions\",\"toggleOverlay\",\"isLocked\",\"isBootstrapLoad\",\"openFieldLinkPopup\",\"animationData\"],[[23,1,[]],[24,[\"setChange\"]],[24,[\"createStructureCheckpoint\"]],[24,[\"iframeCSS\"]],[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"parentActions\"]]],null]],null],[24,[\"onFirstRender\"]],[24,[\"toggleDraggable\"]],[24,[\"moduleAction\"]],[24,[\"updateAllDimensions\"]],[24,[\"toggleOverlay\"]],[24,[\"isLocked\"]],[24,[\"isBootstrapLoad\"]],[24,[\"openFieldLinkPopup\"]],[24,[\"animationData\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-tab-pane/template.hbs"
    }
  });

  _exports.default = _default;
});