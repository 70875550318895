define("builder/models/style-options", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  @class StyleOptionsFragment
   */
  var _default = _fragment.default.extend({
    /**
     * @property {String} variableName - variable name
     */
    variableName: (0, _model.attr)('string'),

    /**
     * @property {Boolean} locked - variable lock state
     */
    locked: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});