define("builder/core/factories/module-gallery/gallery-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class GalleryModule
   */
  var _default = Ember.Object.extend({
    /**
     * name of component to display module
     * @type {string}
     */
    componentName: 'gallery-category',

    /**
     * is module enabled
     * @type {boolean}
     */
    enabled: true,

    /**
     * is module could be deleted
     * @type {boolean}
     */
    canDelete: false,

    /**
     * nested children of current module
     * @type {null|GalleryModule[]}
     */
    children: null,

    /**
     * parent of current module
     * @type {null|GalleryModule}
     */
    parent: null,

    /**
     * is module visible or not
     * @type {boolean}
     */
    visible: true,

    /**
     * any additional data of module
     * @type {null|any}
     */
    data: null,

    /**
     * display title, to be used as module title
     * @type {null|string}
     */
    title: null,

    /**
     * path to image, to be used as background image of module
     * @type {null|string}
     */
    image: null,

    /**
     * icon class
     * @type {null|string}
     */
    iconClass: null,

    /**
     * module page type, I think this shouldn't be here, but leave it for now
     * @type {null|string}
     */
    type: null,

    /**
     * id of current module
     * @type {string}
     */
    id: Ember.computed.readOnly('data.id'),

    /**
     * label of module, to be used for created page module
     * @todo This property must be removed, since label of page item should not be here
     * @type {string}
     */
    label: '',

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('children', this.children === null ? [] : this.children);
    },

    /**
     * If current module satisfies search regex conditions and should be included into found modules list
     *
     * @param {RegExp} searchRegex - regexp against which to test current module
     * @returns {boolean}
     */
    satisfiesSearch(searchRegex) {
      return searchRegex.test(this.title);
    },

    /**
     * Get module to be used for page module
     * @returns {GalleryModule}
     */
    getInsertModel() {
      return this;
    },

    /**
     * Get data to be used as constructor parameters for page module creation
     * @returns {Object}
     */
    getInsertData() {
      return this.data;
    },

    /**
     * filter by title
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByTitle(searchRegex) {
      return searchRegex.test(this.title);
    },

    /**
     * filter by tag
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByTag(searchRegex) {
      return searchRegex.test(this.data.Tags);
    },

    /**
     * filter by description
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByDescription(searchRegex) {
      return searchRegex.test(this.data.Description);
    }

  });

  _exports.default = _default;
});