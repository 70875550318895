define("builder/models/style", ["exports", "@ember-data/model", "ember-copy", "ember-data-model-fragments/attributes", "builder/core/util/normalize-style-variable-name", "builder/core/enumerators/breakpoint-modes"], function (_exports, _model, _emberCopy, _attributes, _normalizeStyleVariableName, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base class for all styles management storage
   * TODO: need more flexibility
   *
   * @class StyleModel
   */
  var _default = _model.default.extend(_emberCopy.Copyable, {
    /**
     * @property {VariableFragment[]} global - array of global variables
     */
    global: (0, _attributes.fragmentArray)('variable'),

    /**
     * @property {VariableFragment[]} lg - list of lg variables
     */
    lg: (0, _attributes.fragmentArray)('variable'),

    /**
     * @property {VariableFragment[]} md - list of md variables
     */
    md: (0, _attributes.fragmentArray)('variable'),

    /**
     * @property {VariableFragment[]} sm - list of sm variables
     */
    sm: (0, _attributes.fragmentArray)('variable'),

    /**
     * @property {VariableFragment[]} xs - list of xs variables
     */
    xs: (0, _attributes.fragmentArray)('variable'),

    /**
     * @property {StyleOptionFragment[]} StyleOptions - style options
     */
    StyleOptions: (0, _attributes.fragmentArray)('style-options'),

    /**
     * @property {String} StyleCSS
     */
    StyleCSS: (0, _model.attr)('string'),

    /**
     * @property {String} localStyleCSS - Compiled CSS for modules
     */
    localStyleCSS: Ember.computed('styleMap', 'configurationService.currentDevice.breakpoint', '{global,lg,md,sm,xs}.@each.value', function () {
      const breakpoint = this.get('configurationService.currentDevice.breakpoint');
      return this._generateCSS(breakpoint);
    }),

    /**
     * @property {String} localStyleCustomCSS - Compiled Global CSS for modules using css custom properties
     */
    localStyleCustomCSS: Ember.computed('styleMap', 'configurationService.currentDevice.breakpoint', '{global,lg,md,sm,xs}.@each.value', function () {
      const breakpoint = this.get('configurationService.currentDevice.breakpoint');
      const {
        generateStylesFromVariables
      } = this.cssCustomPropertiesService;
      const strCSSVariables = generateStylesFromVariables(this, null);

      const styleString = this._generateCSS(breakpoint);

      return `${strCSSVariables} ${styleString}`;
    }),

    /**
     * @property {Object|Null} styleMap - styles map to generate CSS
     */
    styleMap: null,

    /**
     * @property {WebsiteStyleModel} [globalVariablesModel] - model of global variables
     */
    globalVariablesModel: null,

    /**
     * @property {CSSGenerator} cssGenerator - css generator instance used for css generation
     */
    cssGenerator: null,

    /**
     * @property {ConfigurationService} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {CssCustomPropertiesService} cssCustomPropertiesService - configuration styles service
     */
    cssCustomPropertiesService: Ember.inject.service('css-custom-properties'),

    /**
     * Method clone current record and return new
     * @param {Number} newId - id for new model
     * @returns {ModuleStyleModel}
     */
    cloneRecord(newId) {
      const json = this.toJSON();
      json.styleMap = (0, _emberCopy.copy)(this.styleMap, true);
      json.globalVariablesModel = this.globalVariablesModel;
      json.id = newId;
      return this.store.createRecord('module-style', json);
    },

    /**
     * JSON representation of model
     * @returns {Object}
     */
    copy() {
      return this.toJSON();
    },

    /**
     * @inheritdoc
     */
    save(...args) {
      // Generate css for E6 for modules
      if (this._internalModel.modelName !== 'website-style') {
        this.set('StyleCSS', this._generateCSS());
      }

      return this._super(...args);
    },

    /**
     * Has current model reference to passed variableName or not
     * @param {String} variableName - style variable name
     * @returns {Boolean}
     */
    hasReferenceToStyleVariable(variableName) {
      const normalizedVariableName = (0, _normalizeStyleVariableName.default)(variableName);
      return [_breakpointModes.GLOBAL, _breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].any(mode => {
        const variables = this.get(mode);
        const relatedVariable = variables.find(variable => (0, _normalizeStyleVariableName.default)(variable.value) === normalizedVariableName);
        return !!relatedVariable;
      });
    },

    /**
     * Return hash of changed variable names
     * @returns {Object}
     */
    getChangedVariables() {
      const result = {};
      [_breakpointModes.GLOBAL, _breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
        const variables = this.get(mode);
        variables.forEach(variable => {
          const variableName = variable.get('name');

          if (variable.get('hasDirtyAttributes') && !result.hasOwnProperty(variableName)) {
            result[variableName] = true;
          }
        });
      });
      return result;
    },

    /**
     * Returns prefix for css selector
     * @returns {String|Null}
     * @private
     */
    _getModuleId() {
      return null;
    },

    /**
     * return css custom property string for internal styles
     * @param {String} breakpoint - current device type
     * @returns {String}
     */
    getGlobalCssCustomProperties(breakpoint) {
      return this.cssCustomPropertiesService.generateStylesFromVariables(this, this.styleMap);
    },

    /**
     * Generates CSS
     * @param {String|Null} [mode] - breakpoint mode
     * @param {String|Null} [prefixedClass] - prefix class for css
     * @returns {*|String}
     * @private
     */
    _generateCSS(mode, prefixedClass = '') {
      var _this$moduleTheme;

      const hasCSSCustomProperties = (_this$moduleTheme = this.moduleTheme) === null || _this$moduleTheme === void 0 ? void 0 : _this$moduleTheme.hasCSSCustomProperties;

      if (hasCSSCustomProperties) {
        return this._getStylesUsingCSSCustomProps(mode, prefixedClass);
      }

      return this._getStylesUsingCSSGenerator(mode, prefixedClass);
    },

    _getStylesUsingCSSGenerator(mode, prefixedClass = '') {
      const map = this.styleMap;
      return map ? this.cssGenerator.generateCSS({
        mode,
        map,
        variables: this,
        dependencyVariables: this.globalVariablesModel,
        prefixedClass,
        moduleId: this._getModuleId()
      }) : '';
    },

    _getStylesUsingCSSCustomProps(mode, prefixedClass = '') {
      const map = this.styleMap;
      return this.cssCustomPropertiesService.generateStylesFromStyleMap(this.get('id'), map, prefixedClass);
    }

  });

  _exports.default = _default;
});