define("builder/pods/website/edit/page/create/controller", ["exports", "builder/controllers/base", "builder/config/environment", "builder/core/enumerators/notification", "builder/models/menu-root", "builder/core/enumerators/template-category-type", "ember-copy", "builder/core/util/define-fields-visibility"], function (_exports, _base, _environment, _notification, _menuRoot, _templateCategoryType, _emberCopy, _defineFieldsVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TEMPLATE_MAX_PAGE_SIZE = 50;

  var _default = _base.default.extend({
    /**
     * Property contain data for "field-text" component.
     * Its for title	 *
     * @type {Object}
     */
    fieldTitle: null,

    /**
     * @inheritdoc
     */
    queryParams: ['addToMenu'],

    /**
     * Indicates whether created page should be added to menu
     * @type {boolean}
     */
    addToMenu: false,

    /**
     * Property contain data for "field-text" component
     * Its for alias
     *
     * @type {Object|null}
     */
    fieldAlias: null,

    /**
     * Property for display UI controls for define PageModel.CloneFromUrl
     *
     * @type {Object|null}
     */
    fieldCloneFromUrl: null,

    /**
     * @inheritdoc
     */
    entity: 'page',

    /**
     * Current active/selected category
     *
     * @type {DS.Model|string|null}
     */
    activeCategory: null,

    /**
     * Current active/selected template
     * @type {DS.Model|null}
     */
    activeTemplate: null,

    /**
     * Is button create disable or not
     * @type {boolean}
     */
    isDisableCreateButton: true,

    /**
     * Current active step
     * @type {string}
     */
    step: 'step1',

    /**
     * preview image
     * @type {string}
     */
    previewImage: `${_environment.default.rootURL}images/page/template.png`,

    /**
     * Big preview image
     * @type {string}
     */
    previewImageBig: `${_environment.default.rootURL}images/page/template_big.png`,

    /**
     * Show/hide page type controls
     * @type {boolean}
     */
    isShowPageTypeControls: false,

    /**
     * Show/hide page search
     * @type {boolean}
     */
    showSearchInput: Ember.computed('activeCategory', function () {
      return this.activeCategory && this.activeCategory.Name !== 'Blank Page';
    }),

    /**
     * Page template categories
     * @type {PageCategoryModel[]}
     */
    templateCategories: Ember.computed('model.categories', function () {
      const {
        categories
      } = this.model;
      return categories.filter(category => {
        if (!category.isGlobalPagesCategory) {
          return true;
        }

        return this.accessControl.can('useGlobalPages page-template-categories').isGranted;
      });
    }),

    /**
     * is page template valid
     * @type {boolean}
     */
    isSaveAllowed: Ember.computed('activeTemplate', 'activeTemplate.{hasDirtyAttributes,isSaving}', function () {
      const model = this.activeTemplate;
      return model && model.get('hasDirtyAttributes') && !model.get('isSaving');
    }),

    /**
     * Title to tmp page preview
     * @type {string}
     */
    previewTitle: Ember.computed('fieldTitle.data', function () {
      return this.get('fieldTitle.data') ? this.get('fieldTitle.data') : 'Your page name';
    }),

    /**
     * Current website protocol
     * @type {string | null}
     */
    protocol: Ember.computed('configurationService.website.Protocol', function () {
      return this.configurationService.get('website').get('Protocol');
    }),

    /**
     * current website domain
     * @type {string | null}
     */
    domain: Ember.computed('configurationService.website.Domain', function () {
      return this.configurationService.get('website').get('Domain');
    }),

    /**
     * Templates that belongs to "activeCategory"
     * @type {DS.Model[]}
     */
    filteredTemplates: Ember.computed('activeCategory', 'activeCategory.Templates.[]', 'model.templates.@each.isDeleted', 'searchQuery.search', function () {
      const category = this.activeCategory;
      const globalPageCategory = this.model.categories.findBy('isGlobalPagesCategory', true);
      const canManageGlobalPage = this.accessControl.can('manage-global page').isGranted;
      let templates = [];
      let searchQuery = this.get('searchQuery.search');
      let searchQueryRegex = /.*/;

      if (searchQuery) {
        // Escape search string
        searchQuery = searchQuery.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
        searchQueryRegex = new RegExp(searchQuery, 'i');
      }

      if (category === 'all') {
        templates = this.get('model.templates');

        if (searchQuery) {
          templates = templates.filter(template => searchQueryRegex.test(template.get('Title')));
        }
      } else if (category) {
        templates = this.get('model.templates').filter(template => category.get('Templates').includes(template.get('id')) && searchQueryRegex.test(template.get('Title')));
      } // Exclude global pages access denied to global page management


      if (!canManageGlobalPage && globalPageCategory) {
        templates = templates.filter(template => !globalPageCategory.Templates.includes(template.id));
      }

      return templates;
    }),

    /**
     * @type {Number} - property to track page number in pagination
     */
    pageNumber: 1,

    /**
     * @type {Object}
     * @readonly
     */
    pageTypeWarning: Ember.computed('pageType', function () {
      const template = this.activeTemplate;
      const {
        pageType
      } = this;
      const pageTemplateType = this.pageTypesProvider.findByValue(template.get('PageType'));
      let error = null;

      if (pageType.value !== pageTemplateType.value) {
        error = {
          changedTo: pageType,
          changedFrom: pageTemplateType
        };
      }

      return error;
    }).readOnly(),

    /**
     * Page type for new page
     * @type {Object}
     * @readonly
     */
    pageType: Ember.computed('activeTemplate', function () {
      return this.getPageType(this.activeTemplate);
    }).readOnly(),

    /**
     * Field config for define data dependency
     * @type {Object}
     * @readonly
     */
    fieldPageType: Ember.computed('model.page', function () {
      const template = this.activeTemplate;
      const {
        pageType
      } = this;
      const pageTypeStructure = template.get('PageTypeStructure');
      const existPageTypes = this.store.peekAll('page').map(model => model.get('Type'));
      const data = {
        'page-type': pageType.value
      };

      if (pageTypeStructure) {
        Object.keys(pageTypeStructure).forEach(key => {
          data[key] = pageTypeStructure[key];
        });
      }

      const pageConfig = this.pageTypesProvider.buildConfig(data, existPageTypes);
      return (0, _defineFieldsVisibility.defineFieldsVisibility)(pageConfig);
    }).readOnly(),

    /**
     * Fields config for setup page conditional type
     * @type {Object}
     * @readonly
     */
    fieldPageConditionalType: Ember.computed('model.page', function () {
      const {
        pageConditionalTypesProvider
      } = this;
      const conditionalType = this.get('model.page.ConditionalType');
      return pageConditionalTypesProvider.buildConfig(conditionalType);
    }).readOnly(),

    /**
     * Instance of PageTypesProvider
     * @type {Object}
     * @readonly
     */
    pageTypesProvider: Ember.computed(function () {
      return Ember.getOwner(this).lookup('provider:page-types');
    }).readOnly(),

    /**
     * Instance of PageConditionalTypesProvider
     * @type {Object}
     * @readonly
     */
    pageConditionalTypesProvider: Ember.computed(function () {
      return Ember.getOwner(this).lookup('provider:page-conditional-types');
    }).readOnly(),

    /**
     * Data model for store page type settings
     * @type {Ember.Object|null}
     */
    fieldPageTypeModel: null,

    /**
     * Values for template search
     * @type {search: string, input: string}
     */
    searchQuery: {
      search: '',
      input: ''
    },

    /**
     * Transliteration service
     * @type {TransliterationService}
     */
    transliteration: Ember.inject.service(),

    /**
     * Service to show popups
     * @type {PopupsService}
     */
    popupsService: Ember.inject.service('popups'),

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('fieldTitle', {
        label: 'Name your new page',
        name: 'Title',
        data: '',
        isValid: false,
        validation: {
          required: {
            presence: true,
            ignoreBlank: true
          },
          length: {
            min: 1,
            max: 255
          }
        }
      });
      this.set('fieldAlias', {
        label: 'Page URL',
        name: 'Alias',
        data: '',
        isValid: false,
        textTransform: 'lowerCase',
        validation: {
          required: {
            presence: true,
            ignoreBlank: true
          },
          pageAlias: {
            debounce: 400,
            pageId: 0
          },
          length: {
            min: 1,
            max: 255
          }
        }
      });
      this.set('fieldCloneFromUrl', {
        label: 'Clone From',
        name: 'CloneFromUrl',
        data: '',
        help: 'This is the URL of the page that you want to create a clone from.',
        placeholder: 'http://...',
        textTransform: 'lowerCase',
        validation: {
          pattern: '/^(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,63}\\.[a-z]{2,63}\\b([-a-zA-Z0-9@:%_\\+.,~#?&\\/=]*))?$/',
          message: 'URL is invalid'
        }
      });
    },

    /**
     * @type {Array} - Paginated Templates
     */
    paginatedTemplatesList: Ember.computed('filteredTemplates', 'pageNumber', function () {
      return this.filteredTemplates.slice(0, TEMPLATE_MAX_PAGE_SIZE * this.pageNumber);
    }),

    /**
     * Get effective page type
     *
     * @param {DS.Model} template - template model
     * @returns {Object}
     */
    getPageType(template) {
      const {
        pageTypesProvider
      } = this;
      const pageTemplateType = pageTypesProvider.findByValue(template.get('PageType'));
      let pageType = pageTemplateType || pageTypesProvider.get('defaultType');
      const existPageTypes = this.store.peekAll('page').map(model => model.get('Type'));

      if (pageType.isUniq && existPageTypes.includes(pageType.value)) {
        pageType = pageTypesProvider.get('defaultType');
      }

      return pageType;
    },

    /**
     * Set active category
     *
     * @param {DS.Model|String} category - category to set as active
     * @returns {void}
     */
    setCategory(category) {
      this.set('activeCategory', category);
      this.set('pageNumber', 1);
      this.setTemplate(null);
    },

    /**
     * Set active template
     *
     * @param {DS.Model} template - template to set as active
     * @returns {void}
     */
    setTemplate(template) {
      if (template) {
        const pageType = this.getPageType(template);
        this.set('fieldPageTypeModel.Type', pageType.value);

        if (pageType.value === template.get('PageType') && template.get('PageTypeStructure')) {
          this.set('fieldPageTypeModel.TypeStructure', (0, _emberCopy.copy)(template.get('PageTypeStructure'), true));
        } else {
          this.set('fieldPageTypeModel.TypeStructure', null);
        }
      }

      this.set('activeTemplate', template);
    },

    /**
     * Delete template
     *
     * @param {DS.Model} template - template to remove
     * @returns {Object}
     */
    deleteTemplate(template) {
      return template.destroyRecord();
    },

    /**
     * Method send action to current route(website.edit.page.create) to mark current website as unpublished
     *
     * @returns {void}
     */
    markWebsiteAsUnpublished() {
      this.send('markWebsiteAsUnPublished');
    },

    /**
     * Method set valid or not valid status to the field
     *
     * @param {string} name - input name
     * @param {boolean} status
     * @returns {void}
     */
    changeFieldValidStatus(name, status) {
      if (name === 'Alias') {
        this.set('fieldAlias.isValid', status);
      }

      if (name === 'Title') {
        this.set('fieldTitle.isValid', status);
      }
    },

    /**
     * Method check that all fields is valid
     *
     * @returns {boolean}
     */
    isAllFieldsValid() {
      return this.get('fieldTitle.isValid') && this.get('fieldAlias.isValid');
    },

    actions: {
      /**
       * Action which passed to "field-text" component
       * Its for title
       *
       * @param {string} title
       * @returns {void}
       */
      inputTitleHandler(title) {
        const alias = this.transliteration.transliterate(title.trim()).replace(/[^0-9a-zA-Z_]+/g, '-').toLowerCase();
        this.set('isDisableCreateButton', true);
        this.set('fieldTitle.data', title);
        this.set('fieldAlias.data', alias);
      },

      /**
       * Action which passed to "field-text" component
       * Its for alias
       *
       * @returns {void}
       */
      inputAliasHandler() {
        this.set('isDisableCreateButton', true);
      },

      /**
       * Action for change CloneFromUrl field
       * @param {string} pageUrl - page url which page cloned from
       * @returns {void}
       */
      inputCloneFromUrlHandler(pageUrl) {
        this.set('fieldCloneFromUrl.data', pageUrl.trim());
      },

      /**
       * Action which passed to "field-text" component.
       * This action called when "Alias" or "Title" is valid
       *
       * @param {string} value - input value
       * @param {string} name - input name
       * @returns {void}
       */
      validate(value, name) {
        this.changeFieldValidStatus(name, true);

        if (name === 'Title') {
          this.changeFieldValidStatus('Alias', false);
        } else if (this.isAllFieldsValid()) {
          this.set('isDisableCreateButton', false);
        }
      },

      /**
       * Actions which passed to "field-text" and calling when
       * error occurred
       *
       * @param {string} value - input value
       * @param {string} name - input name
       * @returns {void}
       */
      errorHandler(value, name) {
        this.changeFieldValidStatus(name, false);
        this.set('isDisableCreateButton', true);
      },

      /**
       * Action to close popup
       *
       * @returns {void}
       */
      closePopup() {
        this.transitionToRoute('website.edit');
      },

      selectCategory(category) {
        this.setCategory(category);
      },

      selectTemplate(template) {
        this.setTemplate(template);
      },

      /**
       * Remove template
       *
       * @param {DS.Model} template - template to remove
       * @returns {void}
       */
      removeTemplate(template) {
        if (!template.get('isDeleted') && window.confirm(this.get('literals.PAGE_TEMPLATE_REMOVE_CONFIRMATION'))) {
          this.deleteTemplate(template).then(() => {
            // After removing, template, if it was active template, set active template to null, since it
            // was removed
            if (this.activeTemplate === template) {
              this.setTemplate(null);
            }
          }).then(() => this.showNotification(this.get('literals.PAGE_TEMPLATE_REMOVE_SUCCESS'))).catch(() => {
            template.rollbackAttributes();
            this.showNotification(this.get('literals.PAGE_TEMPLATE_REMOVE_ERROR'), _notification.default.TYPE.ERROR);
          });
        }
      },

      create() {
        const {
          store
        } = this;
        return store.createPage({
          Title: this.get('fieldTitle.data'),
          Alias: this.get('fieldAlias.data'),
          CloneFromUrl: this.get('fieldCloneFromUrl.data'),
          Type: this.get('fieldPageTypeModel.Type'),
          TypeStructure: this.get('fieldPageTypeModel.TypeStructure') || {},
          ConditionalType: this.get('fieldPageConditionalType.data'),
          ClonedFrom: this.get('activeTemplate.id'),
          MenuType: this.addToMenu ? _menuRoot.default.MAIN : _menuRoot.default.UNLINKED
        }).then(pageModel => {
          // Clean structure of menus for correct data merge after fetch data from server
          const promises = store.peekAll('menu').map(model => {
            model.clearStructure();
            return model.save({
              adapterOptions: {
                fakeRequest: true
              }
            });
          });
          return Ember.RSVP.all(promises).then(() => pageModel);
        }).then(pageModel => {
          const allPromises = Ember.RSVP.all([// Reload module-style because backend implicitly create module-style models for cloned modules
          store.findAll('module-style', {
            reload: true
          }), store.findAll('menu', {
            reload: true
          }), store.findAll('page-seo', {
            reload: true
          }), store.findAll('page-meta', {
            reload: true
          })]);
          return allPromises.then(() => pageModel);
        }).then(pageModel => {
          this.markWebsiteAsUnpublished();
          this.transitionToRoute('website.edit.page.edit', pageModel.get('id'));
        }).catch(error => {
          if (error.errors[0] === 'Convertor service request received error code 500') {
            this.showNotification('This page can\'t be cloned. Please make sure that the page you are trying to clone from is live and accessible on the Internet and the page was built using the Ignite Pages feature.', _notification.default.TYPE.ERROR, true);
          }
        });
      },

      fetchCategories() {
        return Ember.RSVP.resolve(this.store.peekAll('page-category').filterBy('IsStatic', _templateCategoryType.default.STATIC));
      },

      updateVisibility(visibility) {
        const template = this.activeTemplate;

        if (template) {
          template.updateVisibility(visibility);
        }
      },

      updateThumbnail(imageId, thumbnailUrl) {
        this.activeTemplate.updateThumbnail(imageId, thumbnailUrl);
      },

      removeThumbnail() {
        this.activeTemplate.removeThumbnail();
      },

      setStep(step) {
        if (step === 'step1') {
          this.store.findAll('page-category', {
            reload: true
          }).then(() => {
            // If more complex selection logic will be required, move to separate method, yep I am lazy to do it now
            const {
              activeTemplate
            } = this;

            if (!this.filteredTemplates.includes(activeTemplate)) {
              this.setTemplate(null);
            }
          });
        }

        this.set('step', step);
      },

      saveTemplate() {
        const template = this.activeTemplate;
        return template.save().then(() => {
          this.showNotification('Page template was successfully saved.', _notification.default.TYPE.SUCCESS);
          this.send('setStep', 'step1');
        }).catch(error => {
          let errors = error.errors || [];
          const isUnknownServerError = !!Ember.get(error, 'errors.0.status');

          if (isUnknownServerError) {
            errors = [this.get('literals.PAGE_TEMPLATE_SAVE_ERROR')];
          }

          this.showNotification(errors, _notification.default.TYPE.ERROR);
        });
      },

      searchTemplate() {
        const input = this.get('searchQuery.input').trim();

        if (input.length > 2) {
          this.set('searchQuery.search', input);
        } else {
          this.set('searchQuery.search', '');
        }
      },

      clearSearchQuery() {
        this.set('searchQuery.input', '');
        this.set('searchQuery.search', '');
      },

      /**
       * Action for show/hide page type settings UI
       *
       * @returns {void}
       */
      togglePageTypeControls() {
        this.toggleProperty('isShowPageTypeControls');
      },

      /**
       * Action for change page type settings
       *
       * @param {any} value - value of field
       * @param {string} name - field name
       * @returns {void}
       */
      changeFieldType(value, name) {
        const model = this.fieldPageTypeModel;
        let pageType = null;

        if (name === 'page-type') {
          model.set('Type', value);
        } else {
          const typeStructure = model.get('TypeStructure') || {};
          Ember.set(typeStructure, name, value);
          model.set('TypeStructure', typeStructure);
        }

        pageType = this.pageTypesProvider.findByValue(model.get('Type'));

        if (pageType && !pageType.hasExtraData) {
          delete model.TypeStructure;
        }

        (0, _defineFieldsVisibility.defineFieldsVisibility)(this.fieldPageType);
      },

      /**
       * Shows popup with template image
       *
       * @param {Object} template - template object with essential data
       * @param {MouseEvent} event - click event
       */
      showImagePopup(template) {
        const imageUrl = template.OriginalImage || this.previewImage;
        const contentElement = new Image();
        contentElement.src = imageUrl;
        this.popupsService.showPopup(contentElement);
      },

      /**
       * Handle the onscroll event of the templates list
       * @param {Event} event - DOM event
       */
      onScroll(event) {
        const element = event.target;

        if (element.scrollHeight - element.scrollTop === element.clientHeight && this.filteredTemplates.length > this.paginatedTemplatesList.length) {
          this.set('pageNumber', this.pageNumber + 1);
        }
      }

    }
  });

  _exports.default = _default;
});