define("builder/pods/components/fieldset-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component wrapps content into field-set tag and add couple features.
   * isSwitchable - switch on ability to disable/enable whole section
   *
   * Usage:
   * 		{{fieldset-wrapper label="label" help="help text" isActive=false isSwitchable=true}}
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: 'fieldset',

    /**
     * @inheritdoc
     */
    classNames: ['b2-fieldset-wrapper'],

    /**
     * @property {String} legend - text for legend
     */
    legend: '',

    /**
     * @property {String} help - text for help
     */
    help: '',

    /**
     * @property {Boolean} isSwitchable - state for switch ability
     */
    isSwitchable: false,

    /**
     * @property {Boolean} isActive - state for switch ability
     */
    isActive: false,
    actions: {
      /**
       * Action for reaction to on change switcher status
       * @param {Boolean} value - switcher status
       */
      change(value) {
        const {
          actionHandler
        } = this;
        const {
          validationHandler
        } = this;

        if (typeof actionHandler === 'function') {
          actionHandler(value);
        }

        if (typeof validationHandler === 'function') {
          validationHandler(this, true);
        }
      }

    }
  });

  _exports.default = _default;
});