define("builder/pods/components/sm-group/component", ["exports", "builder/core/abstract-classes/attributes-provider-component"], function (_exports, _attributesProviderComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Component helps build nested sections in styles management.
   * You can build any level of nesting, but all section after second level will be
   * look like second level, because component design is limited to only for two levels of the section
   *
   * @class SMGroupComponent
   *
   * Basic usage:
   *     You can use property direct initialization
   * 		{{sm-component label="label" children=sectionChildren}}
   *
   * 		Or using implicit attributes passing which useful on dynamic render:
   * 		{{component baseAttrs=dataForComponent}}
   *
   */
  let _class = (_dec = Ember.computed('name'), (_class2 = (_temp = class _class2 extends _attributesProviderComponent.default {
    constructor(...args) {
      super(...args);
      this.classNameBindings = ['isNested:line-box__box:line-box'];
      this.name = null;
      this.label = null;
      this.isNested = false;
      this.children = null;
      this.disabled = false;
      this.excludeAttrs = ['name'];
    }

    /**
     * Name of permission that controls access to the component
     * @type {string}
     */
    get permissionName() {
      return `use${this.name.toUpperCase()}`;
    }
    /**
     * @inheritdoc
     */


  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "permissionName", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "permissionName"), _class2.prototype)), _class2));

  _exports.default = _class;
});