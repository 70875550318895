define("builder/pods/components/gallery-theme/component", ["exports", "builder/pods/components/gallery-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * classes to be added to ember wrapper
     * @type {string[]}
     */
    classNames: ['__theme'],

    /**
     * image src
     * @type {string}
     * @readonly
     */
    image: Ember.computed.readOnly('data.image'),

    /**
     * style css for display thumbnail of item
     * @type {string}
     * @readonly
     */
    thumbnailCss: Ember.computed('image', function () {
      return Ember.String.htmlSafe(`background-image: url(${this.image})`);
    }).readOnly(),

    /**
     * Theme original image for zoom popup
     * @type {string}
     * @readonly
     */
    originalImage: Ember.computed.readOnly('data.data.OriginalImage'),

    /**
     * name(title) of theme
     * @type {string}
     * @readonly
     */
    name: Ember.computed.readOnly('data.title'),

    /**
     * description of theme
     * @type {string}
     * @readonly
     */
    description: Ember.computed.readOnly('data.description'),

    /**
     * about theme text
     * @type {string}
     * @readonly
     */
    aboutThemeText: Ember.computed('description', 'name', function () {
      if (!this.description) {
        return '';
      }

      const aboutThemeText = [`<strong>${this.name}</strong>`];

      if (this.description) {
        aboutThemeText.push('<br>', `<span>${this.get('data.description')}</span>`);
      }

      return aboutThemeText.join('');
    }).readOnly(),

    /**
     * @inheritdoc
     */
    init(...agrs) {
      this._super(...agrs);

      this.set('draggable', true);
    }

  });

  _exports.default = _default;
});