define("builder/pods/components/gallery-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Yjx6bDd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page_module\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"page-module__widget page-module__widget_type-\",[24,[\"data\",\"data\",\"Name\"]]]]],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page_modules_list_item-controls\"],[8],[0,\"\\n\\t\\t\"],[5,\"tooltip-external\",[],[[\"@text\",\"@closingDelayTime\"],[[24,[\"literals\",\"GALLERY_ZOOM\"]],0]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"page_modules_list_item-control\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showImagePopup\",[24,[\"image\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-search-plus\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"page_modules_list_item-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/gallery-widget/template.hbs"
    }
  });

  _exports.default = _default;
});