define("builder/core/enumerators/dependency-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEPENDENCY_TYPES = void 0;

  /**
   *
   * Type of variable dependencies for SM
   */
  const DEPENDENCY_TYPES = {
    VIRTUAL: 'virtual',
    VALUE: 'value'
  };
  _exports.DEPENDENCY_TYPES = DEPENDENCY_TYPES;
});