define("builder/pods/components/menumanager-link/component", ["exports", "builder/pods/components/menumanager-item/component", "builder/models/menu-child"], function (_exports, _component, _menuChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @property {String} settingsTitle - settings popup title
     */
    settingsTitle: 'Edit Link Settings',

    /**
     * @property {Boolean} addToMenu - flag to indicate need add folder to menu list or unlinked list
     */
    addToMenu: false,

    /**
     * @inheritdoc
     */
    _builderIconClass: 'icon-link',

    /**
     * @inheritdoc
     */
    _builderIconHiddenClass: 'icon-hidden-link',

    /**
     * Method return data to configure form elements in config popup
     *
     * @method getConfig
     * @return {Object}
     */
    getConfig() {
      const link = this.get('data.link');
      return {
        type: _menuChild.default.LINK,
        storeSegmentRef: this.data,
        settingsTitle: this.settingsTitle,
        addToMenu: this.addToMenu,
        title: {
          label: 'Title',
          data: this.get('data.title'),
          validation: {
            required: {
              presence: true,
              ignoreBlank: true
            },
            length: {
              min: 1,
              max: 255
            }
          }
        },
        link: {
          label: 'Link To',
          data: {
            target: link.get('target'),
            type: link.get('type'),
            value: link.get('value'),
            params: link.get('params')
          },
          availableType: 'both',
          validation: {
            required: {
              presence: true,
              ignoreBlank: true
            }
          }
        }
      };
    }

  });

  _exports.default = _default;
});