define("builder/pods/website/create-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KaZStsBh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"widget-popup\",null,[[\"class\"],[\"widget-popup_theme_website-create\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"website-create website-create_loading\"],[8],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"website-create__title\"],[8],[0,\"\\n\\t\\t\\tSelect an Option to Begin Building your Site\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"website-create__text\"],[8],[0,\"\\n\\t\\t\\tStart a new website by cloning from an existing theme. Or, clone any existing website.\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"website-create__preloader\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"module-preloader\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"module-preloader__item\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"website-create__footer\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"bttn bttn-default __disabled\"],[8],[0,\"\\n\\t\\t\\t\\tGet Started\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/create-loading/template.hbs"
    }
  });

  _exports.default = _default;
});