define("builder/pods/website/edit/page/edit/site-settings/controller", ["exports", "builder/controllers/base", "builder/core/enumerators/controller-action", "builder/core/enumerators/fields", "ember-copy", "builder/core/enumerators/variant-names", "builder/pods/website/edit/page/edit/site-settings/font-item-model", "codemirror", "codemirror/mode/css/css", "js-beautify"], function (_exports, _base, _controllerAction, _fields, _emberCopy, _variantNames, _fontItemModel, _codemirror, _css, _jsBeautify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * @property {Ember.computed} fieldsWebsiteSetting - fields for basic tab
     */
    fieldsWebsiteSetting: Ember.computed('model.websiteSetting', function () {
      var _this$model, _websiteSetting$PageM;

      const websiteSetting = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.websiteSetting;
      return {
        name: {
          name: 'dealerName',
          label: 'Name',
          help: 'This is the name of your business. It will appear on some of your website pages and emails sent from your website',
          data: websiteSetting.DealerName,
          validation: {
            required: true,
            length: {
              min: 0,
              max: 255
            }
          }
        },
        mainPhone: {
          name: 'dealerMainPhone',
          label: 'Main Phone',
          help: 'Your primary contact phone number. This number will be made public and is displayed within some modules on your site',
          data: websiteSetting.DealerMainPhone,
          maskType: 'USPhone',
          validation: {
            pattern: '^(\\(\\d{3}\\) \\d{3}-\\d{4})?$'
          }
        },
        salesPhone: {
          name: 'dealerSalesPhone',
          label: 'Sales Phone',
          help: 'The phone number you want customers to dial to contact your Sales department. This number will be made public and is displayed within some modules on your site',
          data: websiteSetting.DealerSalesPhone,
          maskType: 'USPhone',
          validation: {
            pattern: '^(\\(\\d{3}\\) \\d{3}-\\d{4})?$'
          }
        },
        servicePhone: {
          name: 'dealerServicePhone',
          label: 'Service Phone',
          help: 'The phone number you want customers to dial to contact your Service department. This number will be made public and is displayed within some modules on your site',
          data: websiteSetting.DealerServicePhone,
          maskType: 'USPhone',
          validation: {
            pattern: '^(\\(\\d{3}\\) \\d{3}-\\d{4})?$'
          }
        },
        partsPhone: {
          name: 'dealerPartsPhone',
          label: 'Parts Phone',
          help: 'The phone number you want customers to dial to contact your Parts department. This number will be made public and is displayed within some modules on your site',
          data: websiteSetting.DealerPartsPhone,
          maskType: 'USPhone',
          validation: {
            pattern: '^(\\(\\d{3}\\) \\d{3}-\\d{4})?$'
          }
        },
        favicon: {
          name: 'favicon',
          label: 'Favicon',
          help: 'This icon will appear in the browser tab at the top of all pages',
          data: websiteSetting.Favicon ? {
            type: 'document',
            url: websiteSetting.Favicon
          } : null,
          validation: {
            document: {
              size: '500K',
              extension: ['ico', 'png']
            }
          }
        },
        canonical: {
          label: 'Canonical URL',
          help: 'This may have an impact on your website SEO. Please only use this if you understand how it works. <strong>On</strong> - this will add a canonical tag to the website to tell search engines where the page content is. <strong>No</strong> - the canonical tag will be removed from the website. By default search engines will use the page url to determine where the page content is.'
        },
        modalType: {
          name: 'PageModalType',
          label: 'Modal Appearance',
          help: 'This controls the behavior of modals across the entire website. <strong>Full page</strong> will render a configurable background that will fill the full screen. <strong>Visible Header</strong> will display the popup on the page and allows the header to remain interactable.',
          data: (_websiteSetting$PageM = websiteSetting === null || websiteSetting === void 0 ? void 0 : websiteSetting.PageModalType) !== null && _websiteSetting$PageM !== void 0 ? _websiteSetting$PageM : 'full-page',
          choices: [{
            value: 'full-page',
            label: 'Full Page'
          }, {
            value: 'visible-header',
            label: 'Visible Header'
          }]
        }
      };
    }),

    /**
     * @property {Ember.computed} metaVariablesVariants - meta variables variants
     */
    metaVariablesVariants: Ember.computed('model.metaVariables', function () {
      var _this$model2, _this$model2$metaVari, _this$model2$metaVari2;

      const data = (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$metaVari = _this$model2.metaVariables) === null || _this$model2$metaVari === void 0 ? void 0 : (_this$model2$metaVari2 = _this$model2$metaVari.Structure) === null || _this$model2$metaVari2 === void 0 ? void 0 : _this$model2$metaVari2.map(item => (0, _emberCopy.copy)(item));
      return {
        children: [{
          isUnique: false,
          name: _variantNames.META_VARIABLES_NAME,
          label: 'Surrounding City',
          labelFrom: 'city',
          children: [{
            name: 'city',
            type: _fields.default.TEXT,
            label: 'Surrounding City',
            help: 'This will update all pages on your website that use this meta tag',
            data: '',
            validation: {
              length: {
                min: 0,
                max: 255
              }
            }
          }]
        }],
        sortable: false,
        data,
        validation: {
          elements: {
            min: 0,
            max: 10
          }
        }
      };
    }),
    formFieldErrorMessagePosition: Ember.computed('model.websiteSettings.FormFieldErrorMessagePosition', function () {
      var _this$model3, _this$model3$websiteS;

      return {
        label: 'Form Field Error Message Position',
        name: 'FormFieldErrorMessagePosition',
        data: ((_this$model3 = this.model) === null || _this$model3 === void 0 ? void 0 : (_this$model3$websiteS = _this$model3.websiteSetting) === null || _this$model3$websiteS === void 0 ? void 0 : _this$model3$websiteS.FormFieldErrorMessagePosition) || 'below',
        description: 'Change where the field error message is placed',
        choices: [{
          value: 'below',
          label: 'Below the label'
        }, {
          value: 'tooltip',
          label: 'Tooltip within the Field'
        }]
      };
    }),
    formFieldHelpMessagePosition: Ember.computed('model.websiteSettings.FormFieldHelpMessagePosition', function () {
      var _this$model4, _this$model4$websiteS;

      return {
        label: 'Form Field Help Message Position',
        name: 'FormFieldHelpMessagePosition',
        data: ((_this$model4 = this.model) === null || _this$model4 === void 0 ? void 0 : (_this$model4$websiteS = _this$model4.websiteSetting) === null || _this$model4$websiteS === void 0 ? void 0 : _this$model4$websiteS.FormFieldHelpMessagePosition) || 'below',
        description: 'Change where the field help text is placed',
        choices: [{
          value: 'below',
          label: 'Below the label'
        }, {
          value: 'tooltip',
          label: 'Tooltip within the Field '
        }]
      };
    }),
    formFieldLabelPosition: Ember.computed('model.websiteSettings.FormFieldLabelPosition', function () {
      var _this$model5, _this$model5$websiteS;

      return {
        label: 'Form Field Label Position',
        name: 'FormFieldLabelPosition',
        data: ((_this$model5 = this.model) === null || _this$model5 === void 0 ? void 0 : (_this$model5$websiteS = _this$model5.websiteSetting) === null || _this$model5$websiteS === void 0 ? void 0 : _this$model5$websiteS.FormFieldLabelPosition) || 'above',
        description: 'Change how the field labels are positioned',
        help: '',
        choices: [{
          value: 'above',
          label: 'Above the field'
        }, {
          value: 'inside',
          label: 'Inside field'
        }]
      };
    }),
    formFieldShowLabelAsPlaceholder: Ember.computed('model.websiteSettings.FormFieldShowLabelAsPlaceholder', function () {
      var _this$model6, _this$model6$websiteS;

      return {
        label: 'Show Label as Placeholder',
        name: 'FormFieldShowLabelAsPlaceholder',
        data: ((_this$model6 = this.model) === null || _this$model6 === void 0 ? void 0 : (_this$model6$websiteS = _this$model6.websiteSetting) === null || _this$model6$websiteS === void 0 ? void 0 : _this$model6$websiteS.FormFieldShowLabelAsPlaceholder) || 'yes',
        description: 'If no help message is specified do you want to show the label as the placeholder',
        help: 'This label will only appear when the field label position is selected to show above the field.',
        choices: [{
          value: 'yes',
          label: 'Yes'
        }, {
          value: 'no',
          label: 'No'
        }]
      };
    }),

    /**
     * @property {FontItemModel[]} uploadingFonts - uploading font items
     * @readonly
     */
    uploadingFonts: Ember.computed('_uploadingFonts.[]', function () {
      return this._uploadingFonts;
    }).readOnly(),

    /**
     * @property {FontItemModel[]} uploadedFonts - already uploaded font items
     * @readonly
     */
    uploadedFonts: Ember.computed('_uploadedFonts.[]', function () {
      return this._uploadedFonts;
    }).readOnly(),

    /**
     * @property {DS.Model[]} availableFonts - fonts list to display
     * @readonly
     */
    availableFonts: Ember.computed('model.websiteFonts.[]', 'model.websiteFonts.@each.VisibilityType', 'uploadedFonts.[]', function () {
      var _this$model7, _this$model7$websiteF;

      const {
        uploadedFonts
      } = this;
      return (_this$model7 = this.model) === null || _this$model7 === void 0 ? void 0 : (_this$model7$websiteF = _this$model7.websiteFonts) === null || _this$model7$websiteF === void 0 ? void 0 : _this$model7$websiteF.toArray().filter(font => font.id).sort((a, b) => {
        let orderA = 0;
        let orderB = 0;
        let result = 0;
        let aParam = a.Title || '';
        let bParam = b.Title || '';
        uploadedFonts.forEach(fontItemModel => {
          var _fontItemModel$model, _fontItemModel$model2;

          if (((_fontItemModel$model = fontItemModel.model) === null || _fontItemModel$model === void 0 ? void 0 : _fontItemModel$model.Name) === a.Name) {
            orderA = fontItemModel.order;
          } else if (((_fontItemModel$model2 = fontItemModel.model) === null || _fontItemModel$model2 === void 0 ? void 0 : _fontItemModel$model2.Name) === b.Name) {
            orderB = fontItemModel.order;
          }
        });
        aParam = aParam.toLowerCase();
        bParam = bParam.toLowerCase();

        if (orderA || orderB) {
          if (orderA < orderB) {
            result = 1;
          } else if (orderA > orderB) {
            result = -1;
          }
        } else if (aParam < bParam) {
          result = -1;
        } else if (aParam > bParam) {
          result = 1;
        }

        return result;
      }).map(model => this.createFontItem(null, model));
    }).readOnly(),

    /**
     * @property {FontItemModel[]} paidAndNewFonts - already uploaded fonts with not global visibility
     * @readonly
     */
    paidAndNewFonts: Ember.computed('availableFonts', 'uploadingFonts', function () {
      const availableFonts = this.availableFonts.filter(fontItemModel => {
        var _fontItemModel$model3;

        return ((_fontItemModel$model3 = fontItemModel.model) === null || _fontItemModel$model3 === void 0 ? void 0 : _fontItemModel$model3.VisibilityType) !== '0';
      });
      const {
        uploadingFonts
      } = this;
      const paidAndNewFonts = [...uploadingFonts, ...availableFonts];
      return paidAndNewFonts;
    }).readOnly(),

    /**
     * @property {FontItemModel[]} globalFontList - already uploaded fonts with global visibility
     * @readonly
     */
    globalFontList: Ember.computed('availableFonts', function () {
      const {
        availableFonts
      } = this;
      return availableFonts.filter(fontItemModel => {
        var _fontItemModel$model4;

        return ((_fontItemModel$model4 = fontItemModel.model) === null || _fontItemModel$model4 === void 0 ? void 0 : _fontItemModel$model4.VisibilityType) === '0';
      });
    }).readOnly(),

    /**
     * ACL Can Manage Custom Css String
     * @type {boolean}
     */
    canEditCustomCssString: Ember.computed('model', function () {
      var _this$app, _this$app$user, _this$app$user$acl, _this$app$user$acl$we;

      return this === null || this === void 0 ? void 0 : (_this$app = this.app) === null || _this$app === void 0 ? void 0 : (_this$app$user = _this$app.user) === null || _this$app$user === void 0 ? void 0 : (_this$app$user$acl = _this$app$user.acl) === null || _this$app$user$acl === void 0 ? void 0 : (_this$app$user$acl$we = _this$app$user$acl.website) === null || _this$app$user$acl$we === void 0 ? void 0 : _this$app$user$acl$we.customcssstring;
    }).readOnly(),

    /**
     * @property {FontItemModel[]} _uploadingFonts - fonts to upload list
     * @private
     */
    _uploadingFonts: [],

    /**
     * @property {FontItemModel[]} _uploadedFonts - uploaded fonts at current session
     * @private
     */
    _uploadedFonts: [],

    /**
     * Code mirror instance
     * @type {CodeMirror}
     */
    codeEditor: null,

    /**
     * Reset controller state
     * @method resetProps
     */
    resetProps() {
      this.set('_uploadingFonts.length', 0);
      this.set('_uploadedFonts.length', 0);
    },

    /**
     * Create font item model
     * @param {File|String} source - source of font
     * @param {DS.Model|Null} model - font model
     * @param {Number} [order=0] - item order
     * @returns {FontItemModel}
     */
    createFontItem(source, model, order = 0) {
      return _fontItemModel.default.create({
        source,
        model,
        container: Ember.getOwner(this),
        order
      });
    },

    /**
     * Upload font
     * @param {FontItemModel} fontItemModel - font item model
     * @returns {Promise}
     */
    uploadFont(fontItemModel) {
      const {
        uploadingFonts
      } = this;
      const {
        uploadedFonts
      } = this;
      return fontItemModel.upload().then(() => {
        uploadingFonts.removeObject(fontItemModel);
        uploadedFonts.unshiftObject(fontItemModel);
      }).catch(() => {// Do nothing
      });
    },

    // Instantiate code mirror
    initCodeMirror() {
      var _websiteSetting$Custo;

      const editorElement = document.querySelector('#code-css-injection');

      if (!editorElement) {
        return;
      }

      const {
        websiteSetting
      } = this.model; // eslint-disable-next-line new-cap

      this.codeEditor = (0, _codemirror.default)(editorElement, {
        value: (_websiteSetting$Custo = websiteSetting === null || websiteSetting === void 0 ? void 0 : websiteSetting.CustomCSSString) !== null && _websiteSetting$Custo !== void 0 ? _websiteSetting$Custo : '',
        mode: 'css',
        autocorrect: true,
        lineNumbers: true,
        theme: 'base16-dark'
      });
      this.codeEditor.on('blur', () => {
        const cssString = this.codeEditor.getValue();
        const beautifiedString = (0, _jsBeautify.css_beautify)(cssString, {
          indent_size: 2
        });
        this.codeEditor.setOption('value', beautifiedString);
        this.send('updateWebsiteSetting', 'CustomCSSString', beautifiedString);
      });
    },

    actions: {
      handleTabSelection(activeTab) {
        if (activeTab !== 'css-injection') {
          return;
        }

        setTimeout(() => {
          this.initCodeMirror();
        }, 0);
      },

      /**
       * Action close popup
       */
      closePopup() {
        this.transitionToRoute('website.edit.page.edit');
      },

      /**
       * Action handle user interaction with Meta Variables
       *
       * @param {object[]} fields - updated fields from meta variables variant
       */
      updateMetaVariables(fields) {
        if (fields) {
          this.model.metaVariables.set('Structure', fields);
          this.send('handleBubbleAction', _controllerAction.default.SET_CHANGES);
        }
      },

      /**
       * Action handle user interaction withWebsite Config
       *
       * @param {object[]} fields - updated fields from websiteConfig
       */
      updateWebsiteConfig(fieldName, fieldData) {
        if (fieldName) {
          const modelPropName = fieldName[0].toUpperCase() + fieldName.slice(1);
          const {
            websiteConfig
          } = this.model;

          if (websiteConfig.modelPropName !== fieldData) {
            websiteConfig.set(modelPropName, fieldData);
            this.send('handleBubbleAction', _controllerAction.default.SET_CHANGES);
          }
        }
      },

      /**
       * Action handle user interaction with Meta Variables
       * @param {String} fieldName - field name from website setting model update
       * @param {object|String} fieldData - updated field from website setting model update
       */
      updateWebsiteSetting(fieldName, fieldData) {
        var _this$model8;

        const modelPropName = fieldName[0].toUpperCase() + fieldName.slice(1);
        const websiteSetting = (_this$model8 = this.model) === null || _this$model8 === void 0 ? void 0 : _this$model8.websiteSetting;
        let dataToSet = fieldData;

        if (modelPropName === 'Favicon') {
          dataToSet = fieldData ? fieldData.url : '';
        } else if (modelPropName === 'CanonicalUrlEnabled') {
          dataToSet = fieldData ? 1 : 0;
        }

        if (fieldName === 'formFieldLabelPosition' && fieldData === 'inside') {
          websiteSetting.set('FormFieldShowLabelAsPlaceholder', 'no');
        }

        if (websiteSetting[modelPropName] !== dataToSet || modelPropName === 'IsIESupported') {
          websiteSetting.set(modelPropName, dataToSet);
          this.send('handleBubbleAction', _controllerAction.default.SET_CHANGES);
        }
      },

      /**
       * Upload selected files
       * @param {File[]|String[]} sources - sources to upload files
       */
      onFontSourcesSelected(sources) {
        var _this$model9, _this$model9$websiteF;

        const {
          uploadingFonts
        } = this;
        const lastItemIndex = (_this$model9 = this.model) === null || _this$model9 === void 0 ? void 0 : (_this$model9$websiteF = _this$model9.websiteFonts) === null || _this$model9$websiteF === void 0 ? void 0 : _this$model9$websiteF.length;
        const sourcesToUpload = [...sources].map((source, index) => this.createFontItem(source, null, lastItemIndex + index));
        uploadingFonts.unshiftObjects(sourcesToUpload);
        sourcesToUpload.forEach(font => this.uploadFont(font));
      },

      /**
       * Change font title
       * @param {FontItemModel} fontItemModel - font item model
       * @param {String} title - font title
       */
      onChangeFontTitle(fontItemModel, title) {
        fontItemModel.changeTitle(title);
      },

      /**
       * Change font visibility
       * @param {FontItemModel} fontItemModel - instance of FontsUploadedListItem
       * @param {String} visibility - font visibility
       */
      onChangeFontVisibility(fontItemModel, visibility) {
        fontItemModel.changeVisibility(visibility);
      },

      /**
       * Remove font
       * @param {FontItemModel} fontItemModel - font item model
       */
      onRemoveFont(fontItemModel) {
        if (window.confirm(`Are you sure to delete font "${fontItemModel.label}"?`)) {
          if (fontItemModel.isPresentModel) {
            fontItemModel.remove();
          } else {
            this._uploadingFonts.removeObject(fontItemModel);
          }
        }
      },

      /**
       * Reload font
       * @param {FontItemModel} fontItemModel - font item model
       */
      onReloadFont(fontItemModel) {
        this.uploadFont(fontItemModel);
      }

    }
  });

  _exports.default = _default;
});