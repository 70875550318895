define("builder/pods/components/core-module/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d/Zls8lO",
    "block": "{\"symbols\":[],\"statements\":[[5,\"module-buttons\",[],[[\"@acl\",\"@title\",\"@moduleAction\",\"@module\",\"@parentWidth\"],[[24,[\"data\",\"acl\"]],[24,[\"data\",\"mainTitle\"]],[22,\"moduleAction\"],[22,\"data\"],[22,\"containerWidth\"]]]],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"page_module_error \",[28,\"if\",[[24,[\"error\"]],\"__visible\"],null]]]],[11,\"style\",[22,\"styles\"]],[11,\"title\",[24,[\"literals\",\"MODULE_LOADING_ERROR\"]]],[8],[0,\"\\n\\t\"],[1,[24,[\"literals\",\"MODULE_LOADING_ERROR\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"page_module_content \",[28,\"if\",[[24,[\"error\"]],\"__hidden\"],null]]]],[11,\"data-aos\",[24,[\"animationData\",\"name\"]]],[11,\"data-aos-duration\",[24,[\"animationData\",\"duration\"]]],[11,\"data-aos-mirror\",[24,[\"animationData\",\"mirror\"]]],[11,\"style\",[22,\"styles\"]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"iframe-wrapper\"],[8],[0,\"\\n\\t\\t\"],[7,\"iframe\",true],[10,\"class\",\"js-module-frame\"],[11,\"src\",[22,\"serviceUrl\"]],[11,\"style\",[22,\"iframeWidthCSS\"]],[8],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"enableVResize\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"resize-handler\",[],[[\"@horizontal\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"page_module_cover\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"data\",\"isEnabled\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\",true],[10,\"class\",\"page_module_error __visible\"],[11,\"title\",[24,[\"literals\",\"MODULE_STATE_INACTIVE_MESSAGE\"]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[24,[\"literals\",\"MODULE_STATE_INACTIVE_MESSAGE\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/core-module/template.hbs"
    }
  });

  _exports.default = _default;
});