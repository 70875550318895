define("builder/models/page", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageModel
   */
  var _default = _model2.default.extend({
    /**
     * Page  title
     * @type {string}
     */
    Title: (0, _model.attr)('string'),

    /**
     * Id of page current page cloned from
     * @type {string}
     */
    ClonedFrom: (0, _model.attr)('string'),

    /**
     * Source page url (use for only E5 page cloning)
     * @type {string}
     */
    CloneFromUrl: (0, _model.attr)('string'),

    /**
     * Page structure
     * @type {Object}
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * Original serialized structure
     * @type {string}
     */
    BaseStructure: (0, _model.attr)('string'),

    /**
     * Current page alias
     * @type {string}
     */
    Alias: (0, _model.attr)('string'),

    /**
     * Is page hidden or not, 0 - not hidden, 1 - hidden
     * @type {number}
     */
    IsHidden: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Is page a global page
     * 0 - not global, 1 - global
     * @type {string}
     */
    IsGlobal: (0, _model.attr)('string', {
      defaultValue: '0'
    }),

    /**
     * Name of layout current page uses
     * @type {string}
     */
    WebsiteLayoutName: (0, _model.attr)('string'),

    /**
     * Required param, required for modules and forms
     * @type {string}
     */
    RequiredParam: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * Page type
     * @type {string}
     */
    Type: (0, _model.attr)('string'),

    /**
     * Additional data related with page type
     * @type {Object}
     */
    TypeStructure: (0, _model.attr)('raw'),

    /**
     * Conditional page type
     * @type {string}
     */
    ConditionalType: (0, _model.attr)('string'),

    /**
     * Page OEM restricted
     * @type {boolean}
     */
    IsOemRestricted: (0, _model.attr)('boolean'),

    /**
     * Type of menu where to add new page
     * @type {string}
     */
    MenuType: (0, _model.attr)('string'),

    /**
     * Hide or show page in sitemap
     * @type {string}
     */
    IsDisplayedOnSitemap: (0, _model.attr)('string', {
      defaultValue: '1'
    }),

    /**
     * If page a child page this field connects it with parent page
     * @type {string}
     */
    GlobalParentId: (0, _model.attr)('string', {
      defaultValue: '0'
    }),

    /**
     * Website ids where global page is present
     * @type {string[]}
     */
    GlobalPageLinkWebsites: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),

    /**
     * Is popup
     * @type {boolean}
     * @readonly
     */
    isPopup: Ember.computed.equal('WebsiteLayoutName', 'popup').readOnly(),

    /**
     * Is global
     * @type {boolean}
     * @readonly
     */
    isGlobal: Ember.computed.equal('IsGlobal', '1').readOnly(),

    /**
     * Is child
     * @type {boolean}
     * @readonly
     */
    isChild: Ember.computed('GlobalParentId', function () {
      return this.GlobalParentId !== '0';
    }).readOnly(),

    /**
     * Page have linked pages
     * @type {boolean}
     * @readonly
     */
    hasLinkedPages: Ember.computed.bool('GlobalPageLinkWebsites.length').readOnly(),

    /**
     * Page thumbnail for search engines, social media sites, and page embeds
     * @type {string}
     */
    Thumbnail: (0, _model.attr)('string')
  });

  _exports.default = _default;
});