define("builder/core/drop-rules/widget-footer", ["exports", "builder/core/drop-rules/meta-container"], function (_exports, _metaContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Rules for widget-footer, for actual rules see MetaContainer
   */
  var _default = _metaContainer.default.extend({});

  _exports.default = _default;
});