define("builder/router", ["exports", "builder/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      this.location = _environment.default.locationType;
      this.rootURL = _environment.default.rootURL;
    }

  } // eslint-disable-next-line array-callback-return


  _exports.default = Router;
  Router.map(function () {
    this.route('website', function () {
      this.route('create');
      this.route('e6-info');
      this.route('edit', {
        path: '/:website_id'
      }, function () {
        this.route('page', function () {
          this.route('create', function () {});
          this.route('edit', {
            path: '/:page_id'
          }, function () {
            this.route('create', function () {});
            this.route('settings', function () {
              this.route('setting', {
                path: ':id'
              });
            });
            this.route('styles', {
              path: '/styles-management'
            });
            this.route('module-gallery', {
              path: '/:module_type'
            }, function () {
              this.route('settings', {
                path: '/:module_id/settings'
              });
            });
            this.route('page-template', function () {});
            this.route('container-template', function () {});
            this.route('container-template', {
              path: '/container-template/:slug'
            }, function () {});
            this.route('site-settings', function () {});
            this.route('market-segments', function () {});
            this.route('module', {
              path: 'module/:module_id'
            }, function () {
              this.route('settings', function () {});
            });
          });
        });
      });
    });
    this.route('access-denied');
    this.route('application-error');
    this.route('not-found', {
      path: '/*wildcard'
    });
  });
});