define("builder/core/enumerators/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SnapshotCategories = _exports.SNAPSHOT_STUDIO = _exports.default = void 0;

  /**
   * Enumerators of gallery types
   */
  var _default = Ember.Object.create({
    // Types from backend
    MODULE: 0,
    WIDGET: 1,
    FORM_TEMPLATE: 2,
    // Exact component types
    Component: {
      CATEGORY: 'gallery-category',
      MODULE: 'gallery-module',
      WIDGET: 'gallery-widget',
      FORM_TEMPLATE: 'gallery-form-template',
      THEME: 'gallery-theme'
    }
  });

  _exports.default = _default;
  const SNAPSHOT_STUDIO = 'Snapshot Studio';
  _exports.SNAPSHOT_STUDIO = SNAPSHOT_STUDIO;
  const SnapshotCategories = {
    ALL_SNAPSHOTS: 'All Snapshots',
    SHARED_WITH_ME: 'Shared With Me',
    ABOUT_US: 'About Us',
    CONTACT_US: 'Contact Us',
    CTA_BLOCK: 'CTA Block',
    CUSTOMER_REVIEWS: 'Customer Reviews',
    LANDING_PAGE: 'Landing Page',
    MY_SNAPSHOTS: 'My Snapshots',
    SOCIAL_BOX: 'Social Box'
  };
  _exports.SnapshotCategories = SnapshotCategories;
});