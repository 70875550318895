define("builder/pods/website/edit/page/edit/page-template/controller", ["exports", "builder/controllers/base", "builder/core/enumerators/notification"], function (_exports, _base, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * @property {Boolean} isValid - is template model valid
     */
    isSaveAllowed: Ember.computed('model.template.{hasDirtyAttributes,isSaving}', function () {
      const model = this.get('model.template');
      return model && model.get('hasDirtyAttributes') && !model.get('isSaving');
    }),
    actions: {
      fetchCategories() {
        return this.store.findAll('page-category', {
          adapterOptions: {
            includeStatic: true
          },
          backgroundReload: false
        });
      },

      save() {
        const model = this.get('model.template');
        return model.save().then(() => {
          this.showNotification('Page template was successfully created.', _notification.default.TYPE.SUCCESS);
          this.transitionToRoute('website.edit.page.edit');
        }).catch(error => {
          let errors = error.errors || [];
          const isUnknownServerError = !!Ember.get(error, 'errors.0.status');

          if (isUnknownServerError) {
            errors = [this.get('literals.PAGE_TEMPLATE_SAVE_ERROR')];
          }

          this.showNotification(errors, _notification.default.TYPE.ERROR);
        });
      },

      /**
       * Action to close popup
       */
      closePopup() {
        this.transitionToRoute('website.edit.page.edit');
      },

      updateVisibility(visibility) {
        this.get('model.template').updateVisibility(visibility);
      },

      updateThumbnail(imageId, thumbnailUrl) {
        this.get('model.template').updateThumbnail(imageId, thumbnailUrl);
      },

      removeThumbnail() {
        this.get('model.template').removeThumbnail();
      }

    }
  });

  _exports.default = _default;
});