define("builder/models/page-template", ["exports", "@ember-data/model", "builder/models/model", "builder/core/enumerators/template-visibility"], function (_exports, _model, _model2, _templateVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PageTemplateModel = _model2.default.extend({
    /**
     * Id of page template was created from
     * @type {string}
     */
    ClonedFrom: (0, _model.attr)('string'),

    /**
     * Template visibility
     * @type {string}
     */
    Visibility: (0, _model.attr)('string', {
      defaultValue: _templateVisibility.default.VISIBILITY_PRIVATE
    }),

    /**
     * Category template belongs to
     * @type {string}
     */
    Category: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * Template title
     * @type {string}
     */
    Title: (0, _model.attr)('string'),

    /**
     * Id of user who created the template
     * @type {string}
     */
    Author: (0, _model.attr)('string'),

    /**
     * Url to template thumbnail
     * @type {string}
     */
    Preview: (0, _model.attr)('string'),

    /**
     * system field, used for switching tmp thumbnail to permanent,
     * or delete key word if thumbnail was deleted
     * @type {string}
     */
    Image: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * Image in original width and height
     * @type {string}
     */
    OriginalImage: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * Page type for page created from template
     * @type {string}
     */
    PageType: (0, _model.attr)('string'),

    /**
     * Additional data related with page type for page created from template
     * @type {object}
     */
    PageTypeStructure: (0, _model.attr)('raw'),

    /**
     * Is template system (not created by clients), but provided by the platform
     * @type {boolean}
     */
    IsSystem: (0, _model.attr)('boolean'),

    /**
     * Update page template visibility, it could affect category
     *
     * @param {string} visibility - visibility
     */
    updateVisibility(visibility) {
      this.set('Visibility', visibility);

      if (visibility !== _templateVisibility.default.VISIBILITY_GLOBAL && this.Category) {
        this.set('Category', null);
      }
    },

    /**
     * Is global page template
     * @type (boolean}
     * @readonly
     */
    isGlobalPageTemplate: Ember.computed('id', function () {
      const globalCategory = this.store.peekAll('page-category').findBy('isGlobalPagesCategory', true);

      if (!globalCategory) {
        return true;
      }

      return globalCategory.Templates.includes(this.id);
    }).readOnly(),

    /**
     * Method update thumbnail, since thumbnail update requires property "Image" to be set
     *
     * @param {string|null} imageId - id of uploaded image
     * @param {string|null} thumbnailUrl - path to thumbnail preview
     */
    updateThumbnail(imageId = null, thumbnailUrl = null) {
      this.set('Image', imageId);
      this.set('Preview', thumbnailUrl);
    },

    /**
     * Method to clear thumbnail, since thumbnail removal has specific logic, property "Image" must be set to "delete"
     */
    removeThumbnail() {
      this.set('Preview', '');
      this.set('Image', 'delete');
    }

  });

  var _default = PageTemplateModel;
  _exports.default = _default;
});