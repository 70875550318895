define("builder/core/froala/clear-formatting-button", ["exports", "froala-editor", "builder/core/froala/icon-templates", "builder/core/froala/constants", "builder/core/froala/links/link-color-plugin", "builder/core/froala/links/link-hover-color-plugin", "builder/core/froala/links/link-hover-decoration-dropdown"], function (_exports, _froalaEditor, _iconTemplates, _constants, _linkColorPlugin, _linkHoverColorPlugin, _linkHoverDecorationDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buttonClearFormattingCmd = void 0;

  /* eslint-disable new-cap */
  const buttonClearFormattingCmd = 'clear-formatting-button';
  _exports.buttonClearFormattingCmd = buttonClearFormattingCmd;
  const iconTemplateName = 'clear-format';
  const unWrapNodeTypes = ['S', 'SUP', 'SUB', 'U', 'EM', 'STRONG'];

  _froalaEditor.default.DefineIconTemplate(iconTemplateName, _iconTemplates.svgClearFormatting);

  _froalaEditor.default.DefineIcon(buttonClearFormattingCmd, {
    NAME: 'clear',
    template: iconTemplateName
  });

  _froalaEditor.default.RegisterCommand(buttonClearFormattingCmd, {
    title: 'Clear Formatting',
    focus: false,
    undo: true,
    refreshAfterCallback: true,

    callback() {
      const selectedElements = this.selection.blocks();
      selectedElements.forEach(block => removeCustomStyles(this, block));
      this.selection.clear();
    }

  });
  /**
   * Removes styles recursively on the element and its children in the DOM tree
   * @param {Object} editor - froala editor instance
   * @param {HTMLElement} element - DOM element
   */


  function removeCustomStyles(editor, element) {
    var _element$children;

    if (element.nodeName !== 'TABLE' && element.nodeName !== 'TD') {
      element.removeAttribute('style');
    }

    if (element.hasAttribute(_constants.LINK_DATA_ATTRIBUTE)) {
      resetLinkStyles(editor, element);
    }

    if (((_element$children = element.children) === null || _element$children === void 0 ? void 0 : _element$children.length) > 0) {
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i];
        unWrapNode(element, child, i);
        removeCustomStyles(editor, child);
      }
    }
  }
  /**
   * Remove style related wrapper elements on text/links
   * @param {HTMLElement} parent - selected element in the editor
   * @param {HTMLElement} child - a child element of the parent
   * @param {Object} childIndex - index of the child
   */


  function unWrapNode(parent, child, childIndex) {
    if (!child || !unWrapNodeOfType(child.nodeName) || !child.firstChild) {
      return;
    }

    parent.replaceChild(child.firstChild, child);
    unWrapNode(parent, parent.children[childIndex], childIndex);
  }
  /**
   * Returns if the element type is needed to be unwrapped
   * @param {String} nodeName - type of the element
   * @returns {Boolean}
   */


  function unWrapNodeOfType(nodeName) {
    return unWrapNodeTypes.indexOf(nodeName) > -1;
  }
  /**
   * Resets custom link styles on an element
   * @param {Object} editor - froala editor instance
   * @param {HTMLElement} element - an element in the selection
   */


  function resetLinkStyles(editor, element) {
    editor.events.trigger(_linkColorPlugin.linkColorChangeEvent, [element.getAttribute(_constants.LINK_DATA_ATTRIBUTE), 'styles', '']);
    editor.events.trigger(_linkHoverColorPlugin.linkHoverColorChangeEvent, [element.getAttribute(_constants.LINK_DATA_ATTRIBUTE), 'hoverStyles', '']);
    editor.events.trigger(_linkHoverDecorationDropdown.linkHoverDecorationEvent, [element.getAttribute(_constants.LINK_DATA_ATTRIBUTE), 'hoverStyles', 'inherit']);
  }
});