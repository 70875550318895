define("builder/core/drop-coordinator", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Helper responsible for detecting drop position and distance of drag element relative to drop target
   */
  var _default = Ember.Object.extend({
    /**
     * Get drop coordinates of drop target relative to drop zone
     *
     * @param {Object} dropZone - component that received drop
     * @param {Object} dropTarget - component that has been dropped
     * @param {Number} x - x coordinate of immediate drop position
     * @param {Number} y - y coordinate of immediate drop position
     * @returns {Object} - {x: Number, y: Number} - drop coordinates relative to drop zone
     */
    getDropCoordinates(dropZone, dropTarget, x, y) {
      const dropZonePosition = dropZone.getBoundingClientRect();
      const targetPosition = dropTarget.getBoundingClientRect();
      return {
        x: targetPosition.left - dropZonePosition.left + x,
        y: targetPosition.top - dropZonePosition.top + y
      };
    },

    /**
     * Get vertical drop position irrelevant to x
     *
     * @param {Element} dropZone - drop zone
     * @param {Element} dropTarget - drop target
     * @param {Number} x - x position inside direct drop target
     * @param {Number} y - y position inside direct drop target
     * @returns {Object} - {position: String, distance: Number} - position: top|bottom, distance to position in percents
     */
    getVerticalPosition(dropZone, dropTarget, x, y) {
      const coordinates = this.getDropCoordinates(dropZone, dropTarget, x, y);
      const elementHeight = (0, _jquery.default)(dropZone).outerHeight();
      return this.getPositionY(elementHeight, coordinates.y);
    },

    /**
     * Get horizontal drop position irrelevant to y
     *
     * @param {Element} dropZone - drop zone
     * @param {Element} dropTarget - drop target
     * @param {Number} x - x position inside direct drop target
     * @param {Number} y - y position inside direct drop target
     * @returns {Object} - {position: String, distance: Number} - position: right|left, distance to position in percents
     */
    getHorizontalPosition(dropZone, dropTarget, x, y) {
      const coordinates = this.getDropCoordinates(dropZone, dropTarget, x, y);
      const elementWidth = (0, _jquery.default)(dropZone).outerWidth();
      return this.getPositionX(elementWidth, coordinates.x);
    },

    /**
     * Convert horizontal position of mouse relative to element, if mouse x < <element width> / 2 - left
     * otherwise right and convert distance in pixels into percents (0 - 50)
     *
     * @param {Number} width - element width
     * @param {Number} x - x position on the element
     * @returns {Object} - {position: <String>, distance: <Number>} - horizontal position and distance
     */
    getPositionX(width, x) {
      const distance = this.toPercent(width, x);
      return {
        position: distance > 50 ? 'right' : 'left',
        distance: Math.min(distance, 100 - distance)
      };
    },

    /**
     * Convert vertical position of mouse relative to element, if mouse y < <element height> / 2 - top
     * otherwise bottom and convert distance in pixels into percents (0 - 50)
     *
     * @param {Number} height - element height
     * @param {Number} y - y position on the element
     * @returns {Object} - {position: <String>, distance: <Number>} - vertical position and distance
     */
    getPositionY(height, y) {
      const distance = this.toPercent(height, y);
      return {
        position: distance > 50 ? 'bottom' : 'top',
        distance: Math.min(distance, 100 - distance)
      };
    },

    /**
     * Convert value into percents
     *
     * @param {Number} absoluteValue - 100% representation in number
     * @param {Number} currentValue - value to convert into percents
     * @returns {Number} value in percents
     */
    toPercent(absoluteValue, currentValue) {
      return currentValue * 100 / absoluteValue;
    }

  });

  _exports.default = _default;
});