define("builder/services/auth-manager", ["exports", "url", "querystring", "builder/config/environment"], function (_exports, _url, _querystring, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AuthManagerService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {Oidc.UserManager|null} ssoClient - sso client
     */
    ssoClient: null,

    /**
     * @property {Object|null} ssoResponse - response structer received from Oidc.UserManager
     */
    ssoResponse: null,

    /**
     * @property {AjaxService} ajaxService - ajax service.
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * @property {String} accessToken - SSO access token
     * @readonly
     */
    accessToken: Ember.computed.readOnly('ssoResponse.access_token'),

    /**
     * @property {String} logoutUrl - Logout url
     */
    logoutUrl: '',

    /**
     * @property {String} resetImpersonationUrl - Reset impersonation url
     */
    resetImpersonationUrl: '',

    /**
     * @property {Function} _ssoResponseObserver - observer for sso access token
     */
    _ssoResponseObserver: Ember.observer('ssoResponse.access_token', function () {
      const {
        ssoClient
      } = this;
      const {
        ssoResponse
      } = this;
      ssoClient.createSignoutRequest({
        /* eslint-disable */
        id_token_hint: ssoResponse && ssoResponse.id_token
        /* eslint-enable */

      }).then(req => {
        this.set('logoutUrl', req.url);
      });
      ssoClient.createSigninRequest().then(req => {
        const urlObject = _url.default.parse(req.url);

        const queryParams = _querystring.default.parse(urlObject.query);
        /* eslint-disable */


        queryParams.acr_values = 'revert_imp';
        /* eslint-enable */

        queryParams.prompt = 'login';
        queryParams.client_id = 'web';
        queryParams.response_type = 'code';
        queryParams.redirect_uri = `${window.location.protocol}//${_environment.default.domain}/site/sso`;
        this.set('resetImpersonationUrl', [urlObject.protocol, '//', urlObject.host, urlObject.pathname, '?', _querystring.default.stringify(queryParams)].join(''));
      });
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.addObserver('ssoClient', this, '_onSsoClientProvided');
    },

    /**
     * Handle update sso user data
     *
     * @method
     * @private
     */
    _onSsoClientProvided() {
      const {
        ssoClient
      } = this;

      if (!ssoClient) {
        return;
      }

      this.removeObserver('ssoClient', this, 'onSsoClientProvided');
      ssoClient.events.addUserLoaded(user => {
        const updatedSsoResponse = Ember.assign({}, this.ssoResponse, user);
        this.set('ssoResponse', updatedSsoResponse);
      });
      ssoClient.getUser().then(user => {
        const updatedSsoResponse = Ember.assign({}, this.ssoResponse, user);
        this.set('ssoResponse', updatedSsoResponse);
      });
    },

    /**
     * Refresh expired token
     *
     * @method refreshToken
     * @returns {Promise}
     */
    refreshToken() {
      return this.ssoClient.signinSilent();
    },

    /**
     *@returns {boolean}
     */
    isAuthTokenExpired() {
      var _this$ssoResponse;

      return !!((_this$ssoResponse = this.ssoResponse) === null || _this$ssoResponse === void 0 ? void 0 : _this$ssoResponse.expired);
    },

    signOutUser() {
      this.ssoClient.signoutRedirect();
    }

  });

  _exports.default = _default;
});