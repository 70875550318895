define("builder/services/rendering-layout", ["exports", "builder/core/enumerators/container"], function (_exports, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for operations with layout entities within rendering context
   */
  let RenderingLayoutService = (_dec = Ember.inject.service('store'), (_class = (_temp = class RenderingLayoutService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    /**
     * Handles LAYOUT_SAVE_ALL command
     * @param {LayoutSaveAllCommand} command
     * @returns {Promise<void>}
     */
    async saveAll({
      structure,
      layout,
      _publishLayouts,
      setPublishLayout
    }) {
      // _publishLayouts is temp code. Should be removed in Q4 2019 when Website6
      // team separate system modules and layouts deploy
      if (!layout.IsLayoutLocked || _publishLayouts) {
        const pageStructure = structure.findBy('type', _container.default.WIDGET_MAIN); // Remove layout page structure

        if (pageStructure) {
          pageStructure.children = [];
        }

        layout.set('Structure', structure); // Backend need save first current layout and then others

        await layout.save();
        const layouts = this.store.peekAll('website-layout'); // If system module was modified then force save all layouts.
        // This is temp code should be removed in Q4 2019

        if (_publishLayouts) {
          const promises = layouts.map(async layoutModel => {
            // Don't save upper layout twice
            if (layoutModel !== layout) {
              await layoutModel.save();
            }
          });
          setPublishLayout(false);
          await Promise.all(promises);
          return;
        }

        const layoutsSavePromises = layouts.map(model => {
          if (model.isDeleted || model.hasDirtyAttributes || model.isCanBeUnload) {
            return model.save();
          }

          return Promise.resolve();
        });
        await Promise.all(layoutsSavePromises);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RenderingLayoutService;
});