define("builder/pods/components/style-export/component", ["exports", "js-yaml", "file-saver", "builder/core/enumerators/breakpoint-modes"], function (_exports, _jsYaml, _fileSaver, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StyleExportComponent = (_dec = Ember.inject.service('router'), _dec2 = Ember._action, (_class = (_temp = class StyleExportComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['style-export'];

      _initializerDefineProperty(this, "router", _descriptor, this);

      this.styleData = void 0;
    }

    /**
     * Filter out array of styleObj
     * @type {array}
     */
    filterStyleList(data) {
      const deviceSpecificStyles = [];

      if (!data) {
        return {};
      }

      [_breakpointModes.GLOBAL, _breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(breakpoint => {
        const stylesObj = {};
        const breakpointStyles = {};
        data[breakpoint].content.forEach(element => {
          stylesObj[element.name] = element.value;
        });
        breakpointStyles[breakpoint] = stylesObj;
        deviceSpecificStyles.push(breakpointStyles);
      });
      return deviceSpecificStyles;
    }
    /**
     * Serializes current page variant to yml file
     * @returns {void}
     */


    serializeGlobalStyle() {
      let styleCollection = null;

      if (this.styleData) {
        styleCollection = JSON.parse(JSON.stringify(this.filterStyleList(this.styleData)));
      }

      const styleContent = _jsYaml.default.safeDump(styleCollection, {
        sortKeys: true
      });

      const blob = new Blob([styleContent], {
        type: 'text/plain;charset=utf-8'
      });
      const fileName = 'global-style.yml';

      _fileSaver.default.saveAs(blob, fileName);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "serializeGlobalStyle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "serializeGlobalStyle"), _class.prototype)), _class));
  _exports.default = StyleExportComponent;
});