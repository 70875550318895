define("builder/core/factories/module-gallery/container-template-decorator", ["exports", "builder/core/factories/module-gallery/gallery-module", "builder/core/enumerators/container", "builder/core/literals"], function (_exports, _galleryModule, _container, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ContainerTemplateGalleryDecorator
   */
  var _default = _galleryModule.default.extend({
    /**
     * @inheritdoc
     */
    componentName: 'gallery-container-template',

    /**
     * @inheritdoc
     */
    type: _container.default.WIDGET_CONTAINER,

    /**
     * @inheritdoc
     */
    visible: Ember.computed.not('data.isDeleted'),

    /**
     * @property {Ember.Service<StoreService>} store - store service
     */
    store: null,

    /**
     * @property {AccessControlService} accessControl - access control service
     */
    accessControl: null,

    /**
     * @property {DS.Model<ContainerTemplate>|null} - model of container template
     */
    data: null,

    /**
     * @inheritdoc
     */
    label: _literals.default.WIDGET_CONTAINER_TITLE,

    /**
     * Current page model
     * @type {PageModel|null}
     */
    currentPageModel: null,

    /**
     * @inheritdoc
     */
    title: Ember.computed.readOnly('data.Title'),

    /**
     * @inheritdoc
     */
    image: Ember.computed.readOnly('data.Preview'),

    /**
     * Result of check usage ability
     * @type {{isGranted:boolean, message:string}}
     * @readonly
     */
    usageAbility: Ember.computed('accessControl', 'currentPageModel.isGlobal', function () {
      const aclAbility = this.accessControl.can('create module-gallery', null, {
        name: this.data.Title.toLowerCase()
      });
      const globalPageUsageAbility = this.currentPageModel.isGlobal ? {
        isGranted: this.data.isGlobalPageCompatible,
        message: _literals.default.GLOBAL_PAGE_USAGE_RESTRICTION
      } : {
        isGranted: true
      };

      if (!aclAbility.isGranted) {
        return aclAbility;
      }

      return globalPageUsageAbility;
    }),

    /**
     * Is enabled
     * @type {boolean}
     * @readonly
     */
    enabled: Ember.computed.readOnly('usageAbility.isGranted'),

    /**
     * Error message that indicates the reason is not enabled
     * @type {string}
     * @readonly
     */
    errorMessage: Ember.computed('usageAbility.isGranted', function () {
      return this.usageAbility.isGranted ? '' : this.usageAbility.message;
    }).readOnly(),

    /**
     * @property {Boolean} canDelete - define delete template ability
     * @readonly
     */
    canDelete: Ember.computed('accessControl', function () {
      return this.data.canDeleteModel();
    }).readOnly(),

    /**
     * @property {Boolean} canEdit - define update template ability
     * @readonly
     */
    canEdit: Ember.computed('accessControl', function () {
      return this.data.canUpdateProperties();
    }).readOnly(),

    /**
     * @inheritdoc
     */
    satisfiesSearch(searchRegex) {
      return searchRegex.test(this.title);
    },

    /**
     * Returns empty object because we don't get its structure in GET listing request
     * and event when we clone (drop to the page) we clone by id
     * @returns {{}}
     */
    getInsertData() {
      return {};
    }

  });

  _exports.default = _default;
});