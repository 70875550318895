define("builder/pods/website/edit/page/edit/create-loading/controller", ["exports", "builder/pods/website/edit/page/create-loading/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    actions: {
      closePopup() {
        this.transitionToRoute('website.edit.page.edit');
      }

    }
  });

  _exports.default = _default;
});