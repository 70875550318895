define("builder/models/form-field-type-category", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormFieldTypeCategoryModel
   */
  var _default = _model2.default.extend({
    /**
     * Category title
     * @type {string}
     */
    Title: (0, _model.attr)('string'),

    /**
     * Category position in "Add fields" popup
     * @type {number}
     */
    Position: (0, _model.attr)('number'),

    /**
     * List of fields that belongs to that category
     * @type {FormFieldTypeModel[]}
     */
    fields: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('fields', []);
    }

  });

  _exports.default = _default;
});