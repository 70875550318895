define("builder/core/froala/links/link-hover-decoration-dropdown", ["exports", "froala-editor", "builder/core/froala/icon-templates"], function (_exports, _froalaEditor, _iconTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkHoverDecorationEvent = _exports.linkHoverDecorationCommand = _exports.LINK_HOVER_DECORATION_CLASS_PREFIX = void 0;

  /* eslint-disable new-cap */
  const LINK_HOVER_DECORATION_CLASS_PREFIX = 'fr-link--';
  _exports.LINK_HOVER_DECORATION_CLASS_PREFIX = LINK_HOVER_DECORATION_CLASS_PREFIX;
  const linkHoverDecorationCommand = 'link-hover-decoration';
  _exports.linkHoverDecorationCommand = linkHoverDecorationCommand;
  const linkHoverDecorationEvent = 'link-hover-decoration-change-event';
  _exports.linkHoverDecorationEvent = linkHoverDecorationEvent;
  const iconTemplateName = 'link_decoration';

  _froalaEditor.default.DefineIconTemplate(iconTemplateName, _iconTemplates.svgInlineStyles);

  _froalaEditor.default.DefineIcon(linkHoverDecorationCommand, {
    NAME: 'linkHoverDecorationIcon',
    template: iconTemplateName
  });

  _froalaEditor.default.RegisterCommand(linkHoverDecorationCommand, getDecorationConfig());

  function getDecorationConfig() {
    return {
      title: 'Hover Decoration',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: getOptions(),

      callback(cmd, val) {
        var _link$attributes$data;

        const link = this.link.get();
        const linkId = link === null || link === void 0 ? void 0 : (_link$attributes$data = link.attributes['data-field-link-id']) === null || _link$attributes$data === void 0 ? void 0 : _link$attributes$data.value;

        if (linkId) {
          this.events.trigger(linkHoverDecorationEvent, [linkId, 'hoverStyles', val]);
        }
      }

    };
  }

  function getOptions() {
    const options = {
      'line-through': 'line-through',
      overline: 'overline',
      underline: 'underline',
      none: 'none',
      inherit: 'inherit'
    };
    return options;
  }
});