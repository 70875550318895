define("builder/pods/components/field-dropdown/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "builder/core/visibility-filters"], function (_exports, _fieldValidation, _fieldVisibility, _visibilityFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * Full array of module fields with their paths
     * @type {FieldConfigurationNode[]}
     */
    configurationFlatMap: [],

    /**
     * Field configuration.
     * Should be provided by parent component
     * @type {FieldConfiguration}
     */
    field: null,

    /**
     * @inheritdoc
     */
    classNames: ['bld-form_dropdown'],

    /**
     * @inheritdoc
     */
    attributeBindings: ['field.name:name'],

    /**
     * @property {AjaxService} ajaxService - ember ajax service
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Is select element disabled
     * @type {boolean}
     */
    disabled: false,

    /**
     * Handler called after a change in the field
     * @type {Function|null}
     */
    actionHandler: null,

    /**
     * Choices filtered by items visibility
     * @type {DataSourceItem}
     */
    filteredChoices: Ember.computed('configurationFlatMap.@each.value', 'field.choices', function () {
      return (0, _visibilityFilters.filterVisibleChoices)(this.configurationFlatMap, this.field);
    }),

    /**
     * Array with choices
     * @type {Object[]}
     */
    choicesArray: Ember.computed('filteredChoices', 'field.data', function () {
      return this.createOptions(this.filteredChoices, this.field.data);
    }),

    /**
     * Creates options for dropdown
     * @param {Object[]} choices - Dropdown choices
     * @param {string} selection - Selected option value
     * @returns {Object[]}
     */
    createOptions(choices, selection) {
      let options = [];

      if (typeof choices === 'string') {
        this.ajaxService.requestBuilderAPI('datasource', `&name=${choices}`).then(res => {
          this.set('field', { ...this.field,
            choices: (res === null || res === void 0 ? void 0 : res.dataSources) || []
          });
        });
      } else {
        options = choices.map(item => this.handleOption(item, selection));
      }

      if (!this.field.ignoreRemovedOption && !Ember.isEmpty(selection) && !this.findOptionByValue(this.filteredChoices, selection)) {
        options.push(this.handleOption({
          value: selection,
          label: 'Please select option'
        }, selection));
      }

      return options;
    },

    /**
     * Handle creation of option
     * @param {Object} data - Option data
     * @param {string} selection - Selected option value
     * @returns {*|{checked: (*|Boolean), disabled: Boolean, label: *, value: *}}
     */
    handleOption(data, selection) {
      const option = this.createOption(data, selection);
      const {
        value
      } = option;

      if (Ember.isArray(value) && !Ember.isEmpty(value)) {
        option.value = this.createOptions(value, selection);
      }

      return option;
    },

    /**
     * Creates option object
     * @param {Object} option - Option object
     * @param {string} data - Selected option value
     * @returns {{checked: (*|Boolean|*), disabled: Boolean, _disabled: Boolean, label: *, value: *}}
     */
    createOption(option, data) {
      const {
        label,
        value,
        disabled,
        _disabled
      } = option;
      return {
        label,
        value,
        checked: this._isChecked(value, data),
        disabled: !!disabled || !!_disabled // Property _disabled may be set in data-filter "enabled-third-party-scripts"

      };
    },

    /**
     * Finds option
     * @param {Object[]} options - Options of the select
     * @param {string} value - Option value
     * @returns {Object|null}
     */
    findOptionByValue(options, value) {
      let foundOption = null;

      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          foundOption = options[i];
          break;
        }

        if (Ember.isArray(options[i].value)) {
          foundOption = this.findOptionByValue(options[i].value, value);

          if (foundOption) {
            break;
          }
        }
      }

      return foundOption;
    },

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      const data = this.get('field.data');

      if (this.triggerOnRender && data) {
        this._callActionHandler(data);
      }
    },

    /**
     * Determines whether options is checked or not
     * @param {string} value - Option value
     * @param {string|string[]} data - Option data
     * @returns {boolean}
     * @private
     */
    _isChecked(value, data) {
      if (Ember.isArray(data)) {
        return data.indexOf(value) > -1;
      }

      return data === value;
    },

    /**
     * Call actionHandler if exists
     * @param {string} value - value from field
     * @private
     * @returns {void}
     */
    _callActionHandler(value) {
      const {
        actionHandler
      } = this;

      if (typeof actionHandler === 'function') {
        actionHandler(value, this.get('field.name'));
      }
    },

    actions: {
      /**
       * Action for change
       * @param {String} value - value of item
       * @returns {void}
       */
      change(value) {
        this.set('field.data', value);

        if (!this.notValid) {
          this._callActionHandler(value);
        }
      }

    }
  });

  _exports.default = _default;
});