define("builder/core/styles-management/variable-dependency-resolver", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEPENDENCY_TYPE_VIRTUAL = 'virtual';
  const DEPENDENCY_TYPE_VALUE = 'value';
  const MODE_REGEXP = new RegExp(`-(${_breakpointModes.LARGE}|${_breakpointModes.MEDIUM}|${_breakpointModes.SMALL}|${_breakpointModes.EXTRA_SMALL})[)]?$`, 'i');

  function isCustomCssVariable(variable) {
    return /^var\(/.test(variable.value);
  }

  function isDependentVariable(variable) {
    return isCustomCssVariable(variable) || /^\$/.test(variable.value);
  }

  function getDependentVarMod(variable) {
    return variable.value.match(MODE_REGEXP);
  }

  function beautifyDependantVar(variable) {
    if (isCustomCssVariable(variable)) {
      return variable.value.match(/var\(--(.*?)\)/)[1].replace(MODE_REGEXP, '');
    }

    return variable.value.replace(/^\$/, '').replace(MODE_REGEXP, '');
  }

  function variableToString(variable) {
    return `{name: '${variable.name}', value: '${variable.value}'}`;
  }

  function findBy(array, key, value) {
    const result = array.find(item => item[key] === value);
    return result || null;
  }
  /**
   * @class VariableDependencyResolver
   */


  class VariableDependencyResolver {
    /**
     * Constructor
     */
    constructor() {
      this._searchStack = new Set();
      this.mainModel = null;
      this.dependentModel = null;
    }
    /**
     * Method help resolve variable dependency or return "real" variable without resolve dependency
     *
     * @param {String} varName - name of variable
     * @param {String} mode - active bootstrap mode(lg,md,sm,xs) or 'global'
     * @returns {
     * 	null
     * |
     * {
     * 		variable: VariableFragment,
     * 		dependedVar: (VariableFragment|null),
     * 		isDeviceDependant: boolean,
     * 		dependencyType: string,
     * 		isResolved: boolean}
     * }
     */


    resolve(varName, mode) {
      let varMode = mode;
      let model = null; // TODO: this method to complex. Refactor it to make more reasonable.
      // eslint-disable-next-line prefer-const

      let {
        variable,
        isDeviceDependent
      } = this._find(this.mainModel, varName, mode);

      let dependencyType = null;
      let dependedVar = null;
      let isResolved = false;

      if (variable && isDependentVariable(variable)) {
        dependencyType = DEPENDENCY_TYPE_VALUE;

        try {
          ({
            variable: dependedVar,
            mode: varMode,
            isDeviceDependent,
            model
          } = this._resolveDependency(variable, this.mainModel, this.dependentModel));
        } finally {
          if (!dependedVar && this.dependentModel) {
            ({
              variable: dependedVar,
              mode: varMode,
              isDeviceDependent,
              model
            } = this._resolveDependency(variable, this.dependentModel));
          }

          isResolved = true;
        }
      } else if (!variable && this.dependentModel) {
        ({
          variable: dependedVar,
          mode: varMode,
          isDeviceDependent
        } = this._find(this.dependentModel, varName, mode));
        model = this.dependentModel;

        if (!dependedVar) {
          throw new Error(`virtual dependency found! Variable "${varName}" is not defined in "dependentModel"`);
        }

        if (isDependentVariable(dependedVar)) {
          ({
            variable: dependedVar,
            mode: varMode,
            isDeviceDependent,
            model
          } = this._resolveDependency(dependedVar, this.dependentModel));
        }

        dependencyType = DEPENDENCY_TYPE_VIRTUAL;
        isResolved = true;
      }

      return variable || dependedVar ? {
        variable,
        dependedVar,
        mode: varMode,
        model,
        isDeviceDependent,
        dependencyType,
        isResolved
      } : null;
    }
    /**
     * Find variable by passed type
     *
     * @param {StyleModel} model
     * @param {String} variableName
     * @param {String} type - bootstrap mode(lg,sm,md,xs) or 'global'
     * @method _findByType
     * @return {StyleModel}
     * @private
     */


    _findByType(model, variableName, type) {
      return findBy(model[type], 'name', variableName);
    }
    /**
     * Method helps resolve variable dependency by "value" like -> {name: 'some-var', value: '$depend-var-md'}
     *
     * @param {VariableFragment} variable - variable model
     * @param {StyleModel} mainModel - style model
     * @param {StyleModel} [dependentModel] - dependent style model
     * @return {{variable: VariableFragment, isDeviceDependent: boolean}}
     * @method _resolveDependency
     * @private
     */


    _resolveDependency(variable, mainModel, dependentModel = null) {
      const searchStack = this._searchStack;
      let isDeviceDependent = false;
      const varMode = getDependentVarMod(variable);
      const varName = beautifyDependantVar(variable);
      let foundedVar;
      let model = mainModel;

      if (searchStack.has(variable)) {
        searchStack.clear();
        throw new Error(`Styles management variable cycle dependency detected -> ${variableToString(variable)}`);
      }

      searchStack.add(variable);

      if (varMode && varMode[1]) {
        foundedVar = this._findByType(mainModel, varName, varMode[1]);
        isDeviceDependent = true;
      } else {
        foundedVar = this._findByType(mainModel, varName, 'global');
      }

      if (!foundedVar && dependentModel) {
        if (varMode && varMode[1]) {
          foundedVar = this._findByType(dependentModel, varName, varMode[1]);
          isDeviceDependent = true;
        } else {
          foundedVar = this._findByType(dependentModel, varName, 'global');
        }

        if (foundedVar) {
          model = dependentModel;
        }
      }

      if (foundedVar === variable) {
        foundedVar = null;
      }

      return foundedVar && isDependentVariable(foundedVar) ? this._resolveDependency(foundedVar, mainModel, dependentModel) : searchStack.clear() || {
        variable: foundedVar,
        mode: varMode ? varMode[1] : 'global',
        isDeviceDependent,
        model
      };
    }
    /**
     * Realise basic algorithm of variable search
     *
     * @param {StyleModel} model
     * @param {String} variableName
     * @param {String} mode
     * @returns {{variable: (undefined|VariableFragment), isDeviceDependent: boolean}}
     * @method _find
     * @private
     */


    _find(model, variableName, mode) {
      const hash = {
        variable: null,
        isDeviceDependent: false,
        mode: 'global'
      };
      hash.variable = this._findByType(model, variableName, 'global');

      if (!hash.variable) {
        // If variable not find in global, we find it in mode
        hash.variable = this._findByType(model, variableName, mode);
        hash.mode = mode;

        if (hash.variable) {
          hash.isDeviceDependent = true;
        }
      }

      return hash;
    }

  }
  /**
   * @class VariableDependencyResolver
   */


  var _default = Ember.Object.extend({
    /**
     * @property {VariableDependencyResolver} resolver - "original" variable dependency resolver
     */
    resolver: null,

    /**
     * @property {StyleModel} mainModel - main model used for dependency resolution
     */
    mainModel: null,

    /**
     * @property {StyleModel} dependentModel - dependent model used for dependency resolution
     */
    dependentModel: null,

    /**
     * Constructor
     */
    init(...args) {
      this._super(...args);

      this.set('resolver', new VariableDependencyResolver());
    },

    /**
     * Decorator
     *
     * @param {String} variableName - name of variable which value needs to be resolved
     * @param {String} mode - name of mode, where variable should be resolved if not found in global scope
     * @returns {Object} - resolved variables with additional data
     */
    resolve(variableName, mode) {
      const {
        resolver
      } = this;
      const {
        mainModel
      } = this;
      const {
        dependentModel
      } = this;
      resolver.mainModel = mainModel ? mainModel.toJSON() : null;
      resolver.dependentModel = dependentModel ? dependentModel.toJSON() : null;
      const result = resolver.resolve(variableName, mode);

      if (result) {
        if (result.variable) {
          let variable = mainModel.get('global').findBy('name', variableName);

          if (!variable) {
            variable = mainModel.get(mode).findBy('name', variableName);
          }

          result.variable = variable;
        }

        if (result.dependedVar) {
          const model = result.model === resolver.mainModel ? mainModel : dependentModel;
          result.dependedVar = model.get(result.mode).findBy('name', result.dependedVar.name);
        }
      }

      return result;
    }

  });

  _exports.default = _default;
});