define("builder/pods/components/widget-help/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPE_ICON_MAP = {
    help: 'fa-question-circle',
    info: 'fa-info-circle'
  };

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    tagName: 'span',

    /**
     * @inheritDoc
     */
    classNames: ['widget-help'],

    /**
     * Help text
     * @type {string|null}
     */
    text: null,

    /**
     * Align help text
     * @type {string}
     */
    textAlign: 'center',

    /**
     * Icon type
     * @type {string}
     */
    iconType: 'help',

    /**
     * Icon class
     * @type {string}
     * @readonly
     */
    iconClass: Ember.computed('iconType', function () {
      return TYPE_ICON_MAP[this.iconType] || TYPE_ICON_MAP.help;
    }).readOnly(),

    /**
     * External handler for click on tooltip content
     * @type {Function|null}
     */
    contentClickHandler: null,

    /**
     * open tooltip on click event
     * @type {boolean}
     */
    openTooltipOnClick: true
  });

  _exports.default = _default;
});