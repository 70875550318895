define("builder/core/froala/links/custom-color-plugin-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initPopup = initPopup;
  _exports.initializeTextBox = initializeTextBox;
  _exports.showSelectedColorIndicator = showSelectedColorIndicator;
  _exports.removeSelectedColorIndicator = removeSelectedColorIndicator;
  _exports.handleSelectionChange = handleSelectionChange;
  _exports.selectedColorHighlighter = _exports.popupButtons = _exports.deleteButtonSVG = _exports.setColorButtonId = _exports.deleteButtonId = _exports.textBoxId = _exports.colorSetId = _exports.backButtonId = _exports.idPrefix = void 0;
  // Popup Element IDs
  const idPrefix = 'popup__';
  _exports.idPrefix = idPrefix;
  const backButtonId = `${idPrefix}back-btn-id`;
  _exports.backButtonId = backButtonId;
  const colorSetId = `${idPrefix}color-set-id`;
  _exports.colorSetId = colorSetId;
  const textBoxId = `${idPrefix}textbox-id`;
  _exports.textBoxId = textBoxId;
  const deleteButtonId = `${idPrefix}delete-color-btn-id`;
  _exports.deleteButtonId = deleteButtonId;
  const setColorButtonId = `${idPrefix}set-color-btn-id`;
  _exports.setColorButtonId = setColorButtonId;
  const backButtonSVG = `<svg class="fr-svg" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M20 11L7.83 11 11.425 7.405 10.01 5.991 5.416 10.586 5.414 10.584 4 11.998 4.002 12 4 12.002 5.414 13.416 5.416 13.414 10.01 18.009 11.425 16.595 7.83 13 20 13 20 13 20 11 20 11Z"></path>
	</svg>`;
  const deleteButtonSVG = `<svg class="fr-svg" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M15,10v8H9v-8H15 M14,4H9.9l-1,1H6v2h12V5h-3L14,4z M17,8H7v10c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V8z"></path>
    </svg> `;
  _exports.deleteButtonSVG = deleteButtonSVG;
  const popupButtons = `
    <div class="fr-buttons fr-colors-buttons fr-tabs">
            <button id="${backButtonId}" type="button" tabindex="-1" role="button" class="fr-command fr-btn fr-back" data-cmd="colorsBack" data-title="Back">
            ${backButtonSVG}
            <span class="fr-sr-only">Back</span></button>
    </div>
`;
  _exports.popupButtons = popupButtons;

  function getTemplate(editor) {
    return `
<div id=${colorSetId} class="fr-color-set fr-text-color fr-selected-set">
			${editor.opts.colorsText.map((color, index) => index === editor.opts.colorsText.length - 1 ? `<span class="fr-command fr-select-color" tabindex="-1" id="${deleteButtonId}" role="button" data-param1="REMOVE" title="Clear Formatting">
			${deleteButtonSVG}
			<span class="fr-sr-only"> Clear Formatting </span></span>` : `<span class="fr-command fr-select-color color-pallet__box" style="background:${color};" tabindex="-1" aria-selected="false" role="button" data-param1="${color}">
				<span class="fr-sr-only">${color}</span>
			</span>${(index + 1) % 7 === 0 ? '<br>' : ''}`).join('')}
		</div>
		<div class="fr-color-hex-layer fr-active fr-layer" id="fr-color-hex-layer-1" style="width: 264px;" data-mouseenter-event-set="true">
		<div class="fr-input-line">
			<input maxlength="7" id="${textBoxId}" type="text" placeholder="" tabindex="1" aria-required="true" dir="auto" class="fr-not-empty">
			<label for="${textBoxId}">HEX Color</label>
		</div>
		<div class="fr-action-buttons"><button type="button" id="${setColorButtonId}" class="fr-command fr-submit" data-cmd="customTextColor" tabindex="2" role="button">OK</button></div>
	</div>`;
  }

  const selectedColorHighlighter = document.createElement('span'); // Selected Indicator(tick) element to append to the selected color block

  _exports.selectedColorHighlighter = selectedColorHighlighter;
  selectedColorHighlighter.className = 'fr-selected-color';
  selectedColorHighlighter.innerText = '';

  function initPopup(editor, popupName) {
    const layerTemplate = getTemplate(editor); // Load popup template.

    const template = {
      buttons: popupButtons,
      custom_layer: layerTemplate
    }; // Create popup.

    const popup = editor.popups.create(popupName, template);
    return popup;
  } // Initialize textbox with the value passed


  function initializeTextBox(popupElement, value) {
    popupElement.querySelector(`#${textBoxId}`).value = value;
  } // Update the tick indicator on the color block last selected


  function showSelectedColorIndicator(popupElement, colorHEX) {
    removeSelectedColorIndicator(popupElement);
    const selectedColorBlock = popupElement.querySelector(`[data-param1="${colorHEX}"]`); // Selected color block in the pallet

    if (colorHEX) {
      if (selectedColorBlock) {
        selectedColorBlock.append(selectedColorHighlighter);
      }
    }
  } // Clear the selected color indicator


  function removeSelectedColorIndicator(popupElement) {
    const colorBlocks = popupElement.querySelectorAll('.color-pallet__box');
    colorBlocks.forEach(block => {
      const hasClass = block === null || block === void 0 ? void 0 : block.querySelector('.fr-selected-color');

      if (hasClass) {
        block.removeChild(selectedColorHighlighter);
      }
    });
  } // handle changes in the selected color in popup


  function handleSelectionChange(popupElement, colorUpdateHandler) {
    const colorBoxes = popupElement.querySelectorAll('.color-pallet__box');
    colorBoxes.forEach(box => {
      box.onclick = colorUpdateHandler;
    });
  }
});