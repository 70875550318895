define("builder/core/factories/composite-property", ["exports", "builder/core/styles-management/composite-properties/rectangle", "builder/core/styles-management/composite-properties/border", "builder/core/styles-management/composite-properties/background"], function (_exports, _rectangle, _border, _background) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Factory for AbstractCompositeProperty instances
   *
   * @class CompositePropertyFactory
   */
  const CompositePropertyFactory = Ember.Object.extend({});
  CompositePropertyFactory.reopenClass({
    /**
     * Method return instance by passed type
     *
     * @param {String} type - factory type
     * @return {AbstractCompositeProperty} - instance of this abstract factory
     * @static
     * @method createInstance
     */
    createInstance(type) {
      switch (type) {
        case 'rectangle':
          return _rectangle.default.create();

        case 'border':
          return _border.default.create();

        case 'background':
          return _background.default.create();

        default:
          return null;
      }
    }

  });
  var _default = CompositePropertyFactory;
  _exports.default = _default;
});