define("builder/services/animation", ["exports", "aos"], function (_exports, _aos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const animationClassName = 'aos-animate';
  /**
   * Used to handle/manage animations on builder2 components
   *
   * @class AnimationService
   */

  var _default = Ember.Service.extend({
    /**
     * @type {Object}
     */
    _aosInstance: null,

    /**
     * Initialize AOS if it not already has an instance
     * @property {Object} config - options for configuring AOS
     */
    initAOS(reset = false) {
      if (!this._aosInstance || reset) {
        this._aosInstance = _aos.default.init();
      }
    },

    /**
     * Refresh AOS elements list if there are any new elements added with attributes
     */
    refreshElementsList() {
      const elementsWithAOSAttrs = document.querySelectorAll('*[data-aos]');

      if (elementsWithAOSAttrs.length !== this._aosInstance.length) {
        this.initAOS(true);
      }
    },

    /**
     * Add animation class to the element manually to enable animation
     * @param {HTMLElement} element - Html Element Object
     * @param {String} className - Class name to add
     */
    addAnimationClass(element, className) {
      if (!element.classList.contains(className)) {
        element.classList.add(className);
      }
    },

    /**
     * Remove animation class to the element manually to disable animation
     * @param {HTMLElement} element - Html Element Object
     * @param {String} className - Class name to remove
     */
    removeAnimationClass(element, className) {
      if (element.classList.contains(className)) {
        element.classList.remove(className);
      }
    },

    /**
     * Refresh AOS on DOM update
     */
    refreshAOS(hard = false) {
      if (hard) {
        _aos.default.refreshHard();

        return;
      }

      _aos.default.refresh();
    },

    /**
     * Handle container animation separately for smaller devices as there are two scrollbars in non fullscreen modes
     * @param {Event} scrollEvent - Viewport scroll event
     */
    handleViewportScroll(scrollEvent) {
      const body = document.getElementsByTagName('body')[0];
      const {
        bottom: bodyBottom
      } = body === null || body === void 0 ? void 0 : body.getBoundingClientRect();
      const {
        top: viewportTop
      } = scrollEvent.target.getBoundingClientRect();
      const animateElements = document.querySelectorAll('.aos-init');
      animateElements.forEach(element => {
        if (!(element === null || element === void 0 ? void 0 : element.getAttribute('data-aos'))) {
          return;
        }

        const mirror = element.getAttribute('data-aos-mirror') === 'true';
        const {
          bottom: elementBottom,
          top: elementTop
        } = element.getBoundingClientRect(); // Toggle Animations

        if (elementBottom < bodyBottom || mirror && elementTop > viewportTop) {
          this.addAnimationClass(element, animationClassName);
        }

        if (elementBottom > bodyBottom || mirror && elementTop < viewportTop) {
          this.removeAnimationClass(element, animationClassName);
        }
      });
    }

  });

  _exports.default = _default;
});