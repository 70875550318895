define("builder/core/froala/metatags/dropdown-website", ["exports", "froala-editor", "builder/core/meta-tags/meta-variables-configuration", "builder/core/froala/metatags/meta-config-mapper", "builder/core/froala/metatags/metatags-insert"], function (_exports, _froalaEditor, _metaVariablesConfiguration, _metaConfigMapper, _metatagsInsert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.websitesDropdownCommand = void 0;

  /* eslint-disable new-cap */
  const websitesDropdownCommand = 'metatags-website-dropdown';
  _exports.websitesDropdownCommand = websitesDropdownCommand;
  const websiteIconName = 'website-icon';

  _froalaEditor.default.DefineIconTemplate(websiteIconName, '<i class="fa fa-window-maximize"></i>');

  _froalaEditor.default.DefineIcon(websitesDropdownCommand, {
    NAME: websiteIconName,
    template: websiteIconName
  });

  _froalaEditor.default.RegisterCommand(websitesDropdownCommand, {
    title: 'Website meta',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: (0, _metaConfigMapper.mapMetatagsConfigToFroalaOptions)(_metaVariablesConfiguration.website),

    callback(cmd, value) {
      (0, _metatagsInsert.insertMetatag)(this, value);
    },

    // Callback on refresh.
    refresh($btn) {},

    // Callback on dropdown show.
    refreshOnShow($btn, $dropdown) {}

  });
});