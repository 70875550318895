define("builder/serializers/page-template", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageTemplateSerializer
   */
  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const serialized = this._super(...args);

      this.serializeIsSystemAttribute(serialized);
      return serialized;
    },

    /**
     * @inheritdoc
     */
    normalize(model, hash, ...args) {
      this.normalizeIsSystemAttribute(hash);
      return this._super(model, hash, ...args);
    },

    /**
     * Serialize page-template IsSystem attribute
     *
     * @param {Object} hash - hash containing IsSystem property
     * @returns {Object}
     */
    serializeIsSystemAttribute(hash) {
      if (!hash.hasOwnProperty('IsSystem')) {
        return hash;
      }

      hash.IsSystem = hash.IsSystem ? '1' : '0';
      return hash;
    },

    /**
     * Normalize page-template IsSystem attribute
     *
     * @param {Object} hash - hash containing IsSystem property
     * @returns {Object}
     */
    normalizeIsSystemAttribute(hash) {
      hash.IsSystem = hash.hasOwnProperty('IsSystem') ? !!parseInt(hash.IsSystem, 10) : false;
      return hash;
    },

    /**
     * NOTE: We need to send only changed values to backend to avoid empty fields validation errors
     * @inheritdoc
     */
    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    }

  });

  _exports.default = _default;
});