define("builder/initializers/widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.registerOptionsForType('widget', {
      singleton: true,
      instantiate: false
    });
  }

  var _default = {
    name: 'widget',
    after: 'misc',
    initialize
  };
  _exports.default = _default;
});