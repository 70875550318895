define("builder/pods/components/form-module/drag-and-drop-module", ["exports", "builder/pods/components/form-module/module-base", "builder/pods/components/form-module/rule-set", "builder/core/drop-highlighter", "builder/mixins/event-base"], function (_exports, _moduleBase, _ruleSet, _dropHighlighter, _eventBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Module responsible for form items D&D, will modify original model structure
   *
   * TODO: Is it ok for module to modify original model?
   */
  var _default = _moduleBase.default.extend(_eventBase.default, {
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @property {Object} dragItem - item that is being dragged
     */
    dragItem: null,

    /**
     * @property {Object} immediateItem - immediate drop item, anchor item
     */
    immediateItem: null,

    /**
     * @property {Object} immediateDropItem - immediate drop item, even if it's parent doesn't allows drop
     */
    immediateDropItem: null,

    /**
     * @property {Object} ruleSet - rule set that will handle all drag and drop events
     */
    ruleSet: null,

    /**
     * @property {Object} highlighter - helper used to highlight anchor element drop position(border)
     */
    highlighter: null,

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args);

      const ruleSet = _ruleSet.default.create({
        module: this
      });

      this.set('ruleSet', ruleSet);
      this.set('highlighter', _dropHighlighter.default.create());
      this.set('removeHandler', function (item) {
        ruleSet.onRemove(item);
      });
      this.set('events', {
        update: null
      });
    },

    /**
     * Attach drag and drop events for form
     *
     * @param {Object} form - form, a root element
     */
    setup(form) {
      this.setupItem(form);
    },

    /**
     * Attach event for single form-item
     *
     * @param {Object} item - form-item instance
     */
    setupItem(item) {
      const children = item.get('children') || [];

      if (item.get('draggable')) {
        this._makeDraggable(item);
      }

      for (let i = 0, len = children.length; i < len; i++) {
        this.setupItem(children[i]);
      }

      this._attachEvents(item);

      item.attachListener('remove', this.removeHandler);
    },

    /**
     * Make form-item node draggable, set attribute "draggable" to true
     *
     * @param {Object} item - form-item instance
     * @private
     */
    _makeDraggable(item) {
      item.get('wrapper').attr('draggable', true);
    },

    /**
     * Attach drag and drop event listeners
     *
     * @param {Object} item - form-item instance
     * @private
     */
    _attachEvents(item) {
      const wrapper = item.get('wrapper');
      const {
        highlighter
      } = this;
      const {
        ruleSet
      } = this;

      if (item.get('draggable')) {
        wrapper.off('dragstart').on('dragstart', $event => {
          const event = $event.originalEvent;
          event.stopPropagation();
          this.set('dragItem', item); // FF and IE needs this

          event.dataTransfer.setData('Text', 'Some text');
        });
      }

      wrapper.off('dragenter').on('dragenter', $event => {
        $event.stopPropagation();
        this.set('immediateDropItem', item);
      });
      wrapper.off('dragleave').on('dragleave', $event => {
        $event.stopPropagation();
        this.set('immediateDropItem', null);
        this.set('immediateTarget', null);
      });
      wrapper.off('dragover').on('dragover', $event => {
        const parent = item.get('parent');
        const event = $event.originalEvent;
        const {
          dragItem
        } = this;

        if (item.get('droppable') && ruleSet.dropAllowed(item, dragItem, null, event.target, event.offsetX, event.offsetY)) {
          event.preventDefault();
          event.stopPropagation();

          this._highlight(item, 'all');
        } else if (parent && ruleSet.dropAllowed(parent, dragItem, item, event.target, event.offsetX, event.offsetY)) {
          event.preventDefault();
          event.stopPropagation();
          const {
            position
          } = ruleSet.getDropPosition(parent, dragItem, item, event.target, event.offsetX, event.offsetY);

          this._highlight(item, position);
        }
      });
      wrapper.off('drop').on('drop', $event => {
        const parent = item.get('parent');
        const event = $event.originalEvent;
        const {
          dragItem
        } = this;

        if (item.get('droppable') && ruleSet.dropAllowed(item, dragItem, null, event.target, event.offsetX, event.offsetY)) {
          event.preventDefault();
          event.stopPropagation();
          ruleSet.onDrop(item, dragItem, null, event.target, event.offsetX, event.offsetY);
          this.dispatchEvent('update', dragItem);
        } else if (parent && ruleSet.dropAllowed(parent, dragItem, item, event.target, event.offsetX, event.offsetY)) {
          event.preventDefault();
          event.stopPropagation();
          ruleSet.onDrop(parent, dragItem, item, event.target, event.offsetX, event.offsetY);
          this.dispatchEvent('update', dragItem);
        }
      });
      wrapper.off('dragend').on('dragend', $event => {
        $event.stopPropagation();
        highlighter.dimElement();
        this.set('immediateTarget', null);
        this.set('dragItem', null);
      });
    },

    /**
     * Highlight potential drop position,
     * TODO: should be moved to ruleSet, since highlight logic is not a part of drag and drop
     *
     * @param {Object} item - form-item instance to highlight
     * @param {String} position - highlight position top/right/bottom/left
     * @returns {Boolean} - form-item has been highlighted or not
     * @private
     */
    _highlight(item, position) {
      let anchor = item;

      if (!item) {
        return false;
      }

      if (item.get('type') === 'column') {
        [anchor] = item.get('children');
      }

      if (anchor) {
        this.highlighter.highlight(anchor.get('wrapper')[0], position);
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});