define("builder/serializers/menu-root", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    serialize(...args) {
      const hash = this._super(...args);

      this._cleanUp(hash.Structure);

      return hash;
    },

    /**
     * Method remove some properties which not need backend
     *
     * @method _cleanUp
     * @param {Object[]} hash
     * @private
     */
    _cleanUp(hash) {
      for (let len = hash.length - 1; len >= 0; --len) {
        if (Array.isArray(hash[len].children)) {
          this._cleanUp(hash[len].children);
        }

        if (hash[len].isDelete) {
          hash.removeAt(len);
        } else {
          delete hash[len].config;
          delete hash[len].isDelete;
        }
      }
    }

  });

  _exports.default = _default;
});