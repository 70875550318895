define("builder/pods/components/sm-tabs/component", ["exports", "builder/core/abstract-classes/attributes-provider-component"], function (_exports, _attributesProviderComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for render tabs.
   * Useful when you render dynamic structure with {{component}} helper
   *
   * Basic usage:
   * 	{{sm-tabs children=structureHere}}
   *
   * Usage with implicit attribute passing:
   * 	{{component baseAttrs=structureHere}}
   *
   * 	^ with this "feature" you don't need to pass not needed properties through several components,
   * 	and components looks more clean :)
   *
   *
   * @class SMTabsComponent
   */
  var _default = _attributesProviderComponent.default.extend({
    /**
     * @inheritdoc
     * @readonly
     */
    excludeAttrs: Ember.computed(function () {
      return ['name'];
    }).readOnly(),

    /**
     * @property {Object[]} children - contains objects which present each tabs with it's children(not normalized structure)
     * @property {String} children[].label - contain tab label
     * @property {Object[]} children[].children - tab content
     * @API
     */
    children: null,

    /**
     * @property {String} activeTab - contain tab id(it's first tab always)
     * @readonly
     */
    activeTab: Ember.computed.oneWay('tabs.firstObject.id'),

    /**
     * @property {Object[]} tabs - contain normalized structure for render each tab
     * @property {String} tabs[].label - tab label
     * @property {String} tabs[].id - tab id(for switching between them)
     * @property {Object[]} tabs[].items - items which depend tab
     * @readonly
    	 */
    tabs: Ember.computed(function () {
      return this.children.map(item => ({
        label: item.label,
        id: item.label.dasherize(),
        items: item.children
      }));
    }).readOnly()
  });

  _exports.default = _default;
});