define("builder/pods/components/dropdown-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ernSj4A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dropdown-help\"],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"drop-button fa fa-question-circle\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"dropdown-content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"arrow-up\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://cdn.dealersocket.com/help.websites.ignite/Home.htm\"],[10,\"rel\",\"noopener\"],[10,\"target\",\"_blank\"],[8],[0,\"\\n\\t\\t\\tHelp\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://www.youtube.com/playlist?list=PLXljO9sk4DGA4t9Hf3o4oNQwf6pVRGGT3\"],[10,\"rel\",\"noopener\"],[10,\"target\",\"_blank\"],[8],[0,\"\\n\\t\\t\\tTraining\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/dropdown-help/template.hbs"
    }
  });

  _exports.default = _default;
});