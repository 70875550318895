define("builder/pods/website/edit/page/create-loading/controller", ["exports", "builder/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    actions: {
      /**
       * Action to close popup
       */
      closePopup() {
        this.transitionToRoute('website.edit');
      }

    }
  });

  _exports.default = _default;
});