define("builder/initializers/css-generator", ["exports", "builder/core/styles-management/css-generator"], function (_exports, _cssGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @param {Ember.Application} application
   */
  function initialize(application) {
    application.register('css-generator:main', _cssGenerator.default.create(), {
      singleton: true,
      instantiate: false
    });
    application.inject('model', 'cssGenerator', 'css-generator:main');
  }

  var _default = {
    name: 'core',
    initialize,
    after: 'init-data'
  };
  _exports.default = _default;
});