define("builder/models/form", ["exports", "@ember-data/model", "builder/models/model", "builder/core/enumerators/form"], function (_exports, _model, _model2, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.usedPagesProp = void 0;

  /**
   * @const {String} usedPagesProp - model property
   */
  const usedPagesProp = 'UsedInPages';
  _exports.usedPagesProp = usedPagesProp;
  const AVAILABLE_CONTAINER_TYPES = {
    page: true,
    layout: true
  };
  /**
   * @class FormModel
   */

  var _default = _model2.default.extend({
    /**
     * Form title
     * @type {string}
     */
    Title: (0, _model.attr)('string'),

    /**
     * Form subject
     * @type {string}
     */
    Subject: (0, _model.attr)('string'),

    /**
     * Form type
     * @type {string}
     */
    FormType: (0, _model.attr)('string'),

    /**
     * Form structure
     * @type {object}
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * Form theme
     * @type {string}
     */
    Theme: (0, _model.attr)('string'),

    /**
     * Form gallery image
     * @type {string}
     */
    Image: (0, _model.attr)('string'),

    /**
     * Form encryption
     * @type {string}
     */
    IsEncrypted: (0, _model.attr)('string', {
      defaultValue: '0'
    }),

    /**
     * Form template what used in pages
     * @example
     * [{PageId: <String>, ModuleConfigIds: [<String>, ...], Type: <String>='page','layout'}]
     * 		PageId - page id
     * 		ModuleConfigIds - config ids
     *		Type - the place where form inserted
     * @type {UsedInPagesData[]}
     */
    [usedPagesProp]: (0, _model.attr)('raw', {
      defaultValue() {
        return [];
      }

    }),

    /**
     * @type {LeadrouteData[]} Leadroutes - lead routes for form
     * @example
     * [{Id: <String|null>, ToEmail: <String>, EmailFormat: <String>, Editable: <Boolean>}, ...]
     * formatId: 1 - Plain/Html, 2 - Adf/Xml
     */
    Leadroutes: (0, _model.attr)('raw', {
      defaultValue() {
        return [];
      }

    }),

    /**
     * @type {string[]}
     */
    Webservices: (0, _model.attr)('raw', {
      defaultValue() {
        return [];
      }

    }),

    /**
     * Form step labels
     * @type {string[]}
     * @readonly
     */
    formSteps: Ember.computed('Structure.form', function () {
      const structure = this.get('Structure.form');
      const formSteps = [];

      if (structure && Ember.get(structure, 'type') === _form.MULTISTEP && Ember.get(structure, 'children.length') > 0) {
        Ember.get(structure, 'children').forEach(formStep => {
          formSteps.push(Ember.get(formStep, 'label'));
        });
      }

      return formSteps;
    }).readOnly(),

    /**
     * Add form template usage on page
     * @param {string} formContainerType="page","layout" - form container type
     * @param {string | number} pageId - page id
     * @param {string | number} moduleConfigId - module config id
     * @returns {void}
     */
    addUsageOnPage(formContainerType, pageId, moduleConfigId) {
      if (Ember.isEmpty(formContainerType) || Ember.isEmpty(pageId) || Ember.isEmpty(moduleConfigId)) {
        throw new Ember.Error('Parameters "formContainerType", "pageId" and "moduleConfigId" is required.');
      }

      if (!this.isValidFormContainerType(formContainerType)) {
        throw new Ember.Error('Incorrect "formContainerType" value.');
      }

      const usedInPages = this.get(usedPagesProp).slice();
      let page = usedInPages.find(item => item.Type === formContainerType && item.PageId.toString() === pageId.toString());

      if (!page) {
        page = {
          Type: formContainerType,
          PageId: pageId.toString(),
          ModuleConfigIds: []
        };
        usedInPages.pushObject(page);
      }

      if (page.ModuleConfigIds.indexOf(moduleConfigId.toString()) === -1) {
        page.ModuleConfigIds.pushObject(moduleConfigId.toString());
        this.set(usedPagesProp, usedInPages);
      }
    },

    /**
     * Remove form template usage on page
     * @param {string} formContainerType="page","layout" - form container type
     * @param {string | number} pageId - page id
     * @param {string | number} configId - module config id
     * @returns {void}
     */
    removeUsageOnPage(formContainerType, pageId, configId) {
      const usedInPages = this.get(usedPagesProp).slice();
      let isChanged = false;

      if (Ember.isEmpty(formContainerType) || Ember.isEmpty(pageId)) {
        throw new Ember.Error('Parameters "formContainerType" and "pageId" is required.');
      }

      const moduleConfigId = configId && configId.toString();

      for (let i = usedInPages.length - 1; i >= 0; i--) {
        const usedInPage = usedInPages[i];
        const moduleConfigIds = usedInPage.ModuleConfigIds;
        const usedPageId = usedInPage.PageId.toString();

        if (usedInPage.Type === formContainerType && usedPageId === pageId.toString()) {
          if (!Ember.isEmpty(moduleConfigId)) {
            const formIndex = moduleConfigIds.indexOf(moduleConfigId);

            if (formIndex !== -1) {
              moduleConfigIds.removeAt(formIndex, 1);
              isChanged = true;
            }
          } else {
            moduleConfigIds.length = 0;
            isChanged = true;
          }
        }

        if (!moduleConfigIds.length) {
          usedInPages.removeAt(i, 1);
          isChanged = true;
        }
      }

      if (isChanged) {
        this.set(usedPagesProp, usedInPages);
      }
    },

    /**
     * Check available form container type
     *
     * @param {string} type - checked form container type
     * @returns {boolean}
     */
    isValidFormContainerType(type) {
      return !!AVAILABLE_CONTAINER_TYPES[type];
    }

  });

  _exports.default = _default;
});