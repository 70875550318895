define("builder/services/form-add-field", ["exports", "builder/core/form/normalizer-structure", "builder/pods/components/form-module/fields-handler", "builder/core/enumerators/form-fields", "ember-copy"], function (_exports, _normalizerStructure, _fieldsHandler, _formFields, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FORM_FIELD_PREFIX_PATTERN = /^field-/;
  const VEHICLE_SELECTOR_FIELD_TYPES = new Set([_formFields.default.VEHICLE_MAKE, _formFields.default.VEHICLE_MAKE.replace(FORM_FIELD_PREFIX_PATTERN, ''), _formFields.default.VEHICLE_TYPE, _formFields.default.VEHICLE_TYPE.replace(FORM_FIELD_PREFIX_PATTERN, ''), _formFields.default.VEHICLE_MODEL, _formFields.default.VEHICLE_MODEL.replace(FORM_FIELD_PREFIX_PATTERN, ''), _formFields.default.VEHICLE_TRIM, _formFields.default.VEHICLE_TRIM.replace(FORM_FIELD_PREFIX_PATTERN, ''), _formFields.default.VEHICLE_YEAR, _formFields.default.VEHICLE_YEAR.replace(FORM_FIELD_PREFIX_PATTERN, ''), _formFields.default.VEHICLE_SELECTOR, _formFields.default.VEHICLE_SELECTOR.replace(FORM_FIELD_PREFIX_PATTERN, '')]);
  /**
   * Service to store logic related to add field into form from add fields config window
   * @class FormAddFieldService
   */

  var _default = Ember.Service.extend({
    /**
     * @property {Object} formModule - form module instance to handle
     */
    formModule: null,

    /**
     * @property {Object} fields - list of all fields available to be dropped into form
     */
    fields: null,

    /**
     * @property {Object} dragItem - drag item instance
     */
    dragItem: null,

    /**
     * @property {Function} updateHandler - handler for form, when it triggers "update" event
     */
    updateHandler: null,

    /**
     * @property {Function} dragAndDropUpdateHandler - handler for drag and drop when it triggers "update" event,
     * for now update event triggered when drop happens into valid drop zone
     */
    dragAndDropUpdateHandler: null,

    /**
     * @property {Ember.Service} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {String[]} _blackList - tmp blocked fields
     * @readonly
     */
    _blackList: Ember.computed('configurationService.website.id', function () {
      const fieldNames = ['field-website', 'field-file'];
      const website = this.get('app.websites').filterBy('id', this.get('configurationService.website.id')).pop();

      if (website && website.dealerIds && website.dealerIds.length < 2) {
        fieldNames.push('field-location');
      }

      return fieldNames;
    }).readOnly(),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('fields', []);
      this.set('updateHandler', () => {
        const types = this.collectFieldTypes(this.formModule.get('form').get('children'));
        const hiddenFields = this.get('formModule.hiddenFields') || [];
        hiddenFields.forEach(hiddenField => {
          if (hiddenField.name !== undefined) {
            types.push({
              hidden: 1,
              name: hiddenField.name
            });
          }
        });
        this.updateFields(types);
      });
      this.set('dragAndDropUpdateHandler', item => {
        if (this.dragItem === item) {
          item.set('loaded', false);
          this.formModule.updateItem(item).then(() => item.set('loaded', true));
        }
      });
    },

    /**
     * Start tracking form fields
     *
     * @param {Object} formModule - form module instance to watch over
     */
    run(formModule) {
      if (!formModule) {
        throw new Ember.Error('Reference Error: formModule is required');
      } // Remove handlers from old module


      this._removeModuleHandlers(this.formModule); // Attach handlers to new module


      this._attachModuleHandlers(formModule);

      this.set('formModule', formModule); // On start up we must update fields state

      this.updateHandler();
    },

    /**
     * Here we must create form-item and notify form-module drag-and-drop module
     *
     * @param {Object} field - form-field-item model being dragged
     */
    notifyDragStart(field) {
      const formItem = this.createFormItem(field);
      const module = this.formModule.get('_modules.dragAndDrop');

      if (module && this._isEnabled(field.get('type'))) {
        module.set('dragItem', formItem);
      }

      this.set('dragItem', formItem);
    },

    /**
     * Attach module handlers for form-module and attach handlers for dragAndDrop module
     *
     * @param {Object} module - form-module instance
     * @private
     */
    _attachModuleHandlers(module) {
      const dragAndDropModule = module.get('_modules.dragAndDrop');
      module.attachListener('fieldsUpdate', this.updateHandler);

      if (dragAndDropModule) {
        dragAndDropModule.attachListener('update', this.dragAndDropUpdateHandler);
      }
    },

    /**
     * Remove module handlers from form-module and from dragAndDrop module
     *
     * @param {Object} module - form-module instance
     * @private
     */
    _removeModuleHandlers(module) {
      if (module) {
        const dragAndDropModule = module.get('_modules.dragAndDrop');
        module.removeListener('update', this.updateHandler);

        if (dragAndDropModule) {
          dragAndDropModule.removeListener('update', this.dragAndDropUpdateHandler);
        }
      }
    },

    /**
     * On drag end we should dim element
     */
    notifyDragEnd() {
      const highlighter = this.formModule.get('_modules.dragAndDrop').get('highlighter');
      highlighter.dimElement();
      this.set('dragItem', null);
    },

    /**
     * Create form-item instance based on form-field-type instance
     *
     * @param {Object} field - form-field-type instance
     * @returns {Object}
     */
    createFormItem(field) {
      const structure = this._createFieldStructure(field);

      const {
        WebsiteGroup
      } = this.configurationService.website;
      const {
        FormType
      } = this.formModule.formModel;
      const formFieldViewModel = (0, _normalizerStructure.default)(structure, WebsiteGroup, FormType);
      return this.formModule.createItem(formFieldViewModel);
    },

    /**
     * Get all types of passed in children(instances of form-item)
     * TODO: Probably concat not the best way to do such an operation
     *
     * @param {Object[]} children - list of form-items
     * @returns {String[]} - all form item types
     */
    collectFieldTypes(children = []) {
      let types = [];

      for (let i = 0, len = children.length; i < len; i++) {
        const subChildren = children[i].get('children') || [];
        types.push({
          hidden: 0,
          name: children[i].get('data.type')
        });
        types = types.concat(this.collectFieldTypes(subChildren));
      }

      return types;
    },

    /**
     * Update fields state, since unique fields must be unique
     *
     * @param {String[]} reservedTypes - fields that are already on the form
     */
    updateFields(reservedTypes) {
      const fields = this.fields || [];
      const blackList = this._blackList;
      fields.forEach(field => {
        const type = field.get('Type');
        let enabled = true;
        const reserved = reservedTypes.find(reservedType => this.isEqualFieldTypes(reservedType.name, type));
        let reservedMessage = null;

        if (reserved && reserved.hidden) {
          reservedMessage = this.get('literals.FORM_ERROR_FIELD_ALREADY_EXISTS_IN_FORM_HIDDEN_FIELDS');
        }

        if (blackList.includes(type) || field.get('IsUnique') && reserved) {
          enabled = false;
        } // This code for disable vehicle selector and vehicle selector related fields


        if (enabled && VEHICLE_SELECTOR_FIELD_TYPES.has(type)) {
          reservedTypes.forEach(reservedType => {
            if (this.isEqualFieldTypes(reservedType.name, _formFields.default.VEHICLE_SELECTOR)) {
              enabled = !VEHICLE_SELECTOR_FIELD_TYPES.has(type);
              reservedMessage = this.get('literals.FORM_ERROR_FIELDS_RELATED_WITH_VEHICLE_SELECTOR_ALREADY_EXISTS_IN_FORM');
            } else if (type === _formFields.default.VEHICLE_SELECTOR && VEHICLE_SELECTOR_FIELD_TYPES.has(reservedType.name)) {
              enabled = false;
              reservedMessage = this.get('literals.FORM_ERROR_FIELD_VEHICLE_SELECTOR_ALREADY_EXISTS_IN_FORM');
            }
          });
        }

        field.set('enabled', enabled);
        field.set('disabledReason', reservedMessage);
      });
    },

    /**
     * Return structure of field for render UI config
     * @param {string} fieldType - field type
     * @returns {Object[]|null}
     */
    getFieldStructureByType(fieldType) {
      const field = this.fields.findBy('Type', fieldType);
      let structure = null;

      if (field && field.Structure) {
        structure = (0, _emberCopy.copy)(field.Structure, true);
      }

      return structure;
    },

    /**
     * Compare form field types
     *
     * @param {String} type1 - first type
     * @param {String} type2 - second type
     * @returns {Boolean}
     */
    isEqualFieldTypes(type1, type2) {
      return type1.replace(FORM_FIELD_PREFIX_PATTERN, '') === type2.replace(FORM_FIELD_PREFIX_PATTERN, '');
    },

    /**
     * Is given field type is enabled or not
     *
     * @param {String} type - type of field to check if it's enabled or not
     * @returns {Boolean}
     * @private
     */
    _isEnabled(type) {
      const fields = this.fields || [];
      let enabled = true;

      for (let i = 0, len = fields.length; i < len; i++) {
        const field = fields.objectAt(i);

        if (field.get('Type') === type) {
          enabled = field.get('enabled');
          break;
        }
      }

      return enabled;
    },

    /**
     * Set predefined data to field
     *
     * @param {Object} field - field config
     * @returns {Object}
     * @private
     */
    _createFieldStructure(field) {
      const structure = {
        type: field.type,
        children: []
      };
      field.model.Structure.forEach(item => {
        if (item.data) {
          (0, _fieldsHandler.processField)(item.name, item.data, structure);
        }
      });

      if (structure.type !== _formFields.default.FIELDSET) {
        const {
          IsUnique
        } = field.model;
        const uid = Date.now();
        structure.name = IsUnique ? field.type : `fieldname${uid}`;
      }

      return structure;
    }

  });

  _exports.default = _default;
});