define("builder/core/enumerators/controller-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    OPEN_PAGE_SETTINGS: 'open-page-settings',
    CLOSE_PAGE_SETTINGS: 'close-page-settings',
    OPEN_SITE_SETTINGS: 'open-site-settings',
    CLOSE_SITE_SETTINGS: 'close-site-settings',
    OPEN_MARKET_SEGMENTS_SETTINGS: 'open-market-segments-settings',
    CLOSE_MARKET_SEGMENTS_SETTINGS: 'close-market-segments-settings',
    SET_CHANGES: 'set-changes',
    SHOW_OVERLAY: 'show-overlay',
    HIDE_OVERLAY: 'hide-overlay',
    REVERT_FORM_MODULE: 'revert-form-module',
    DEACTIVATE_FORM_MODULE: 'deactivate-form-module',
    SHOW_PAGE_TOOLS_PANEL: 'show-page-tools-panel',
    SETUP_PAGE_MODEL: 'setup-page-model',
    CLOSE_MENU_MANAGEMENT: 'close-menu-management',
    DESTROY_MENU_MANAGEMENT_CONFIG: 'destroy-menu-management-config',
    SAVE_COMPLETE: 'save-complete',
    CLEAR_UNDO_REDO: 'clear-undo-redo-history',
    OPEN_MODULE_GALLERY: 'open-module-gallery',
    OPEN_GLOBAL_STYLES: 'open-global-styles',
    CLOSE_GLOBAL_STYLES: 'close-global-styles'
  };
  _exports.default = _default;
});