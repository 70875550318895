define("builder/core/froala/icons/popup-df-icons", ["exports", "froala-editor"], function (_exports, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dfIconsPluginName = _exports.dfIconsPopupCommand = void 0;

  /* eslint-disable new-cap */
  const dfIconsPopupCommand = 'dfIconsPopupClick';
  _exports.dfIconsPopupCommand = dfIconsPopupCommand;
  const dfIconsPluginName = 'dfIconsPlugin';
  _exports.dfIconsPluginName = dfIconsPluginName;
  const dfIconsPopupButtonsName = 'dfIconsPopupButtons';
  const dfIconsPopupName = `${dfIconsPluginName}.popup`;
  /**
   * Main icon definition.
   * This icon will be visible at Froala toolbar
   */

  _froalaEditor.default.DefineIcon(dfIconsPopupCommand, {
    SVG_KEY: 'add',
    template: 'svg'
  });

  _froalaEditor.default.RegisterCommand(dfIconsPopupCommand, {
    title: 'Engine Icons',
    undo: false,
    focus: false,
    plugin: dfIconsPluginName,

    callback() {
      this[dfIconsPluginName].showPopup();
    }

  });
  /**
   * Close icon definition.
   * This icon will be visible inside icons popup.
   */


  const dfIconsPopupCloseCommand = 'df-icons-popup-close';

  _froalaEditor.default.DefineIcon(dfIconsPopupCloseCommand, {
    NAME: 'arrow-left',
    SVG_KEY: 'back'
  });

  _froalaEditor.default.RegisterCommand(dfIconsPopupCloseCommand, {
    title: 'Close',
    undo: false,
    focus: false,

    callback() {
      this[dfIconsPluginName].hidePopup();
    }

  });

  _froalaEditor.default.POPUP_TEMPLATES[dfIconsPopupName] = '[_BUTTONS_][_CUSTOM_LAYER_]';
  _froalaEditor.default.DEFAULTS = { ..._froalaEditor.default.DEFAULTS,
    [dfIconsPopupButtonsName]: [dfIconsPopupCloseCommand]
  };

  _froalaEditor.default.PLUGINS[dfIconsPluginName] = function dfIconsPopup(editor) {
    function initPopup() {
      var _editor$opts$dfIconsP, _iconsLibrary$CSSSele, _iconsLibrary$CSSSele2;

      let popupButtons;

      if ((((_editor$opts$dfIconsP = editor.opts[dfIconsPopupButtonsName]) === null || _editor$opts$dfIconsP === void 0 ? void 0 : _editor$opts$dfIconsP.length) || 0) >= 1) {
        popupButtons = `
				<div class="fr-buttons">
					${editor.button.buildList(editor.opts[dfIconsPopupButtonsName])}
				</div>
			`;
      }
      /**
       * DF_ICONS_LIBRARY is provided via monkey patch in widget-paragraph component
       * @type {FontIconModel}
       */


      const iconsLibrary = editor.DF_ICONS_LIBRARY;
      const iconClass = (iconsLibrary === null || iconsLibrary === void 0 ? void 0 : (_iconsLibrary$CSSSele = iconsLibrary.CSSSelectorMain) === null || _iconsLibrary$CSSSele === void 0 ? void 0 : _iconsLibrary$CSSSele.replace('.', '')) || '';
      const iconPrefix = (iconsLibrary === null || iconsLibrary === void 0 ? void 0 : (_iconsLibrary$CSSSele2 = iconsLibrary.CSSSelector) === null || _iconsLibrary$CSSSele2 === void 0 ? void 0 : _iconsLibrary$CSSSele2.replace('.', '')) || '';
      const glyphs = (iconsLibrary === null || iconsLibrary === void 0 ? void 0 : iconsLibrary.Glyphs) || '';
      const iconsList = glyphs.map(glyph => {
        const glyphClass = iconPrefix.replace('{{glyph}}', glyph);
        return `<i class="${iconClass} ${glyphClass}"></i>`;
      }).join('');
      const layerTemplate = `
		<div class="icons-popup__container">
			${iconsList}
		</div>
		`; // Load popup template.

      const template = {
        buttons: popupButtons,
        custom_layer: layerTemplate
      }; // Create popup.

      const popup = editor.popups.create(dfIconsPopupName, template);
      return popup;
    }

    function iconClickHandler(event) {
      const {
        target: {
          tagName,
          outerHTML
        }
      } = event;

      if (tagName.toLowerCase() !== 'i') {
        return;
      }
      /**
       * Space after icon html is essential.
       * Froala sets focus inside inserted HTML. Without space each added icon
       * will be placed inside previously added icon
       */


      const iconHTML = `${outerHTML}&nbsp;`;
      editor.html.insert(iconHTML, true);
      event.stopPropagation();
    }

    function showPopup() {
      // Get the popup object defined above.
      let popup = editor.popups.get(dfIconsPopupName); // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.

      if (!popup) {
        popup = initPopup();
      } // Set the editor toolbar as the popup's container.


      editor.popups.setContainer(dfIconsPopupName, editor.$tb); // This will trigger the refresh event assigned to the popup.
      // editor.popups.refresh('customPlugin.popup');
      // This custom popup is opened by pressing a button from the editor's toolbar.
      // Get the button's object in order to place the popup relative to it.

      const dfIconsButton = editor.$tb.find(`.fr-command[data-cmd="${dfIconsPopupCommand}"]`);

      if (!dfIconsButton) {
        return;
      } // Set the popup's position.


      const left = dfIconsButton.offset().left + dfIconsButton.outerWidth() / 2;
      const top = dfIconsButton.offset().top + (editor.opts.toolbarBottom ? 10 : dfIconsButton.outerHeight() - 10); // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.

      editor.popups.show(dfIconsPopupName, left, top, dfIconsButton.outerHeight());
      const iconsContainer = popup[0].querySelector('.icons-popup__container');
      iconsContainer.addEventListener('click', iconClickHandler);
    }

    function hidePopup() {
      const popup = editor.popups.get(dfIconsPopupName);

      if (popup) {
        const iconsContainer = popup[0].querySelector('.icons-popup__container');
        iconsContainer.removeEventListener('click', iconClickHandler);
      }

      editor.popups.hide(dfIconsPopupName);
    }

    return {
      showPopup,
      hidePopup
    };
  };
});