define("builder/abilities/oem/route", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlOEMRouteAbility
   */
  var _default = _ability.default.extend({
    map: {
      'website.edit.page.edit.settings.index': {
        entity: 'page',
        property: 'oempageenabled'
      },
      'website.edit.page.edit.settings.setting': {
        entity: 'page',
        property: 'oempageenabled'
      },
      'website.edit.page.edit.page-template': {
        entity: 'page',
        property: 'oempageenabled'
      }
    },

    canHandle(actionName) {
      const {
        page
      } = this;
      return actionName === 'canVisit' && page && page.get('IsOemRestricted') && this.isSecuredRoute(this.get('model.routeName'));
    },

    /**
     * Is route secured and access check should be performed before visiting route
     *
     * @param {String} routeName - route name
     * @returns {Boolean}
     */
    isSecuredRoute(routeName) {
      const {
        map
      } = this;
      return map && map.hasOwnProperty(routeName);
    },

    /**
     * Get route permission name
     *
     * @param {String} routeName - name of route for which permission will be retrieved
     * @returns {String}
     */
    getPermission() {
      const {
        map
      } = this;
      return map[this.get('model.routeName')];
    }

  });

  _exports.default = _default;
});