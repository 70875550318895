define("builder/serializers/module-theme", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Module theme serializer
   */
  var _default = _rest.default.extend({
    /**
     * @property {String} primaryKey - primary key index
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    normalize(model, hash) {
      if (Ember.isEmpty(hash.Label)) {
        hash.Label = hash.Name;
      }

      return this._super(model, hash);
    }

  });

  _exports.default = _default;
});