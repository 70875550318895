define("builder/pods/website/edit/page/edit/site-settings/route", ["exports", "builder/routes/application", "builder/core/enumerators/controller-action"], function (_exports, _application, _controllerAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @inheritdoc
     */
    model() {
      const websiteModel = this.modelFor('website.edit');
      return Ember.RSVP.hash({
        websiteSetting: websiteModel.websiteSetting,
        website: websiteModel.website,
        metaVariables: websiteModel.metaVariables,
        websiteFonts: websiteModel.websiteFonts,
        languages: websiteModel.languages,
        bullets: websiteModel.bullets,
        websiteConfig: websiteModel.websiteConfig
      });
    },

    /**
     * @inheritdoc
     */
    resetController(controller) {
      controller.resetProps();
    },

    /**
     * @inheritdoc
     */
    activate(...args) {
      this._super(...args); // Highlight page settings


      this.send('handleBubbleAction', _controllerAction.default.OPEN_SITE_SETTINGS);
    },

    /**
     * @inheritdoc
     */
    deactivate() {
      // Deactivate page settings
      this.send('handleBubbleAction', _controllerAction.default.CLOSE_SITE_SETTINGS);
    }

  });

  _exports.default = _default;
});