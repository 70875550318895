define("builder/pods/components/resize-handler/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['resizable-handler'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['vertical:resizable-handler_vertical', 'horizontal:resizable-handler_horizontal'],

    /**
     * @property {Number|null} timer - Timer to update element height
     */
    timer: null,

    /**
     * @property {Boolean} vertical - type of resize line
     */
    vertical: false,

    /**
     * @property {Device} device - Device service
     */
    device: Ember.inject.service('device'),

    /**
     * @type {ResizeGridModuleService} - Resize Grid Module service
     */
    resizeGridModuleService: Ember.inject.service('resize-grid-module'),

    /**
     * @property {Boolean} horizontal - type of resize line
     */
    horizontal: false,

    /**
     * @property {Object} schedule - Object of schedule queue
     */
    schedule: null,

    /**
     * @type {String|null} - returns resize data
     */
    helpText: Ember.computed('resizeGridModuleService._marginStyleVariable.value', function () {
      var _this$resizeGridModul;

      return ((_this$resizeGridModul = this.resizeGridModuleService._marginStyleVariable) === null || _this$resizeGridModul === void 0 ? void 0 : _this$resizeGridModul.value) || null;
    }),

    /**
     * Remove height of handler in mobile and tablet devices
     * and run again in desktop or small monitor
     *
     * @method adjustHandlerHeight
     */
    adjustHandlerHeight() {
      if (this.isDestroyed) {
        return;
      }

      this.set('schedule', Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.device.isTablet() || this.device.isMobile()) {
          window.clearInterval(this.timer);
          this.element.removeAttribute('style');
        } else if (this.vertical) {
          this.runInterval();
        }
      }));
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      if (this.vertical && this.device.isDesktop() && this.device.isSmallMonitor()) {
        this.runInterval();
        this.device.on('onChange', this, this.adjustHandlerHeight);
      }
    },

    /**
     * Runs interval to update element height
     *
     * @method runInterval
     */
    runInterval() {
      window.clearInterval(this.timer); // Sorry guys for this hack

      this.set('timer', window.setInterval(() => {
        this.element.style.height = this.element.closest('.row').outerHeight();
      }, 500));
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      if (this.vertical && this.timer) {
        window.clearInterval(this.timer);
        this.device.off('onChange', this, this.adjustHandlerHeight);
        Ember.run.cancel(this.schedule);
      }
    }

  });

  _exports.default = _default;
});