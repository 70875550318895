define("builder/pods/components/field-reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ZrwZvCx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bld-form_number_with_units_container\"],[8],[0,\"\\n\\t\"],[7,\"a\",true],[10,\"href\",\"#\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onValueChange\"],null]],[10,\"class\",\"b2-builder-error-message__messag\"],[8],[0,\"\\n\\t\\t\"],[1,[24,[\"field\",\"label\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-reset/template.hbs"
    }
  });

  _exports.default = _default;
});