define("builder/pods/website/edit/page/edit/styles/controller", ["exports", "builder/controllers/base", "builder/core/enumerators/breakpoint-modes"], function (_exports, _base, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-restricted-syntax */

  /* eslint-disable guard-for-in */

  /**
   * @class WebsiteEditPageEditStylesController
   */
  var _default = _base.default.extend({
    /**
     * fetch the allowed properties in styleObj
     */
    getStyleProperties(breakpoint) {
      var _data$breakpoint, _data$breakpoint2;

      // eslint-disable-next-line prefer-rest-params
      const allowedStylePropertyList = [];
      const data = this.model.smVariables;

      if (!((_data$breakpoint = data[breakpoint]) === null || _data$breakpoint === void 0 ? void 0 : _data$breakpoint.content)) {
        return [];
      }

      const deviceSpecificStyles = (_data$breakpoint2 = data[breakpoint]) === null || _data$breakpoint2 === void 0 ? void 0 : _data$breakpoint2.content;
      deviceSpecificStyles.forEach(element => allowedStylePropertyList.push(element.name));
      return allowedStylePropertyList;
    },

    findStyleVariable(arr, target) {
      return arr.find(element => element.name === target) || {};
    },

    /**
     * method update variable value for module and container styles and generate new CSS only for module styles
     * and also update styles based on incoming import data
     * @param {VariableFragment} variable
     * @param {String} variableName - name of variable that was originally referenced to store value
     * @param {String} newValue - new value of style variable
     * @param {Boolean} isNew - help to determine that we should create new variable fragment
     * @param {Boolean} isDeviceDependent - determine that variable defined in each mode
    */
    updateStyleVariableValue(variable, variableName, newValue, isDeviceDependent, variablesModel, breakpoint) {
      let variableObj = variable || {};
      const styleOption = variablesModel.StyleOptions.findBy('variableName', variableName);

      if (Object.keys(variableObj).length === 0 && breakpoint) {
        var _variablesModel$break;

        variableObj = this.findStyleVariable((_variablesModel$break = variablesModel[breakpoint]) === null || _variablesModel$break === void 0 ? void 0 : _variablesModel$break.content, variableName);
      }

      let changedVariables = [variableObj];
      variableObj.set('value', newValue); // Populate variable value to all breakpoints

      if (isDeviceDependent && styleOption && (styleOption === null || styleOption === void 0 ? void 0 : styleOption.locked)) {
        changedVariables = this._populateVariableToBreakpoints(variableObj, variablesModel);
      }

      changedVariables = changedVariables.map(obj => ({
        model: obj
      }));

      if (changedVariables.length) {
        this.send('addHistoryCheckpoint', changedVariables);
        this.pageController.send('setChanges');
      }
    },

    /**
     * Setup variable to all breakpoints at once
     *
     * @method _populateVariableToBreakpoints
     * @param {VariableFragment} variable - variable model
     * @param {StyleModel} variablesModel - style model
     * @param {String[]} [breakpoints=[LARGE, MEDIUM, SMALL, EXTRA_SMALL]] - list of breakpoints
     * @returns {VariableFragment[]} - changed variables
     * @private
     */
    _populateVariableToBreakpoints(variable, variablesModel, breakpoints = [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL]) {
      const changedVariables = [];
      breakpoints.forEach(mode => {
        const variables = variablesModel.get(mode);
        const variableToChange = variables.findBy('name', variable.get('name'));

        if (variableToChange) {
          variableToChange.set('value', variable.get('value'));
          changedVariables.push(variableToChange);
        }
      });
      return changedVariables;
    },

    actions: {
      /**
       * Action update variable value for module and container styles and generate new CSS only for module styles
       *
       * @param {VariableFragment} variable
       * @param {String} variableName - name of variable that was originally referenced to store value
       * @param {String} newValue - new value of style variable
       * @param {Boolean} isNew - help to determine that we should create new variable fragment
       * @param {Boolean} isDeviceDependent - determine that variable defined in each mode
       */
      updateVariable({
        variable,
        variableName,
        newValue,
        isDeviceDependent,
        variablesModel
      }) {
        this.updateStyleVariableValue(variable, variableName, newValue, isDeviceDependent, variablesModel);
      },

      /**
       * Action to import global style items from file
       * @param {object[]} styles - array of items to import
       */
      importGlobalStyles(styles) {
        let styleKeys = []; // eslint-disable-next-line no-restricted-syntax

        styles.forEach(obj => {
          const breakpoint = Object.keys(obj).join();
          styleKeys = this.getStyleProperties(breakpoint);
          const styleObj = obj[breakpoint];

          for (const key in styleObj) {
            var _styleKeys;

            if (!((_styleKeys = styleKeys) === null || _styleKeys === void 0 ? void 0 : _styleKeys.includes(key))) {
              return;
            }

            const elementValue = styleObj[key];
            let isDeviceDependent = false;

            if (key !== _breakpointModes.GLOBAL) {
              isDeviceDependent = true;
            }

            this.updateStyleVariableValue({}, key, elementValue, isDeviceDependent, this.model.smVariables, breakpoint);
          }
        });
        this.send('setChanges');
      },

      /**
       * Action update lock state for style variables
       *
       * @param {String} mode - current breakpoint
       * @param {String[]} variableNames - variable names whose lock state will be changed
       * @param {StyleModel} variablesModel - style model
       * @param {Boolean} locked - lock state
       */
      updateLockState({
        mode,
        variableNames,
        variablesModel,
        locked
      }) {
        const styleOptions = variablesModel.get('StyleOptions');
        let changedVariables = [];
        variableNames.forEach(variableName => {
          let option = styleOptions.findBy('variableName', variableName);
          const variable = variablesModel.get(mode).findBy('name', variableName);
          option = option || styleOptions.createFragment({
            variableName,
            locked
          });
          option.set('locked', locked);

          if (locked && variable) {
            changedVariables.push(...this._populateVariableToBreakpoints(variable, variablesModel));
          }
        });
        changedVariables = changedVariables.map(variableObject => ({
          model: variableObject
        }));

        if (changedVariables.length) {
          this.send('addHistoryCheckpoint', changedVariables);
          this.pageController.send('setChanges');
        }
      },

      /**
       * Action to display import error
       */
      onImportError(msg) {
        this.showNotification(msg, 'error');
      },

      /**
       * Action to close SM popup
       */
      closeSMPopup() {
        this.transitionToRoute('website.edit.page.edit');
      }

    }
  });

  _exports.default = _default;
});