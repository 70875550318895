define("builder/core/factories/grid-factory", ["exports", "builder/core/enumerators/container", "builder/core/enumerators/module", "builder/core/enumerators/widget", "builder/core/copyable", "builder/core/grid-models/base", "builder/core/grid-models/container", "builder/core/grid-models/widget-container", "builder/core/enumerators/breakpoint-modes", "builder/core/grid-models/module-device-visibility-state"], function (_exports, _container, _module, _widget, _copyable, _base, _container2, _widgetContainer, _breakpointModes, _moduleDeviceVisibilityState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const classMap = {
    [_container.default.TOP_CONTAINER]: _base.default,
    [_container.default.WIDGET_HEADER]: _base.default,
    [_container.default.WIDGET_MAIN]: _base.default,
    [_container.default.WIDGET_FOOTER]: _base.default,
    [_container.default.WIDGET_CONTAINER]: _widgetContainer.default,
    [_container.default.WIDGET_TABS]: _widgetContainer.default,
    [_widget.WidgetType.WIDGET_TAB_PANE]: _container2.default,
    [_container.default.WIDGET_ROW]: _container2.default,
    [_container.default.WIDGET_COLUMN]: _container2.default,
    [_module.default.MODULE_CORE]: _base.default,
    [_module.default.MODULE_FORM]: _base.default,
    [_module.default.MODULE_SYSTEM]: _base.default,
    [_widget.WidgetType.WIDGET_SECTION]: _widgetContainer.default,
    [_widget.WidgetType.WIDGET_PARAGRAPH]: _base.default,
    [_widget.WidgetType.WIDGET_IMAGE]: _base.default,
    [_widget.WidgetType.WIDGET_BUTTON]: _base.default,
    [_widget.WidgetType.WIDGET_BUTTONSGROUP]: _base.default,
    [_widget.WidgetType.WIDGET_SPACER]: _base.default,
    [_widget.WidgetType.WIDGET_LINE_DIVIDER]: _base.default,
    [_widget.WidgetType.WIDGET_IMAGE]: _base.default
  };
  /**
   * Grid containers, creator for grid objects
   */

  var _default = {
    /**
     * Create instance of grid object
     *
     * @param {String} type - type of object to create
     * @param {Object} config - additional config of grid structure item
     * @returns {Object}
     */
    createInstance(type, config = {}) {
      const instance = this._getInstance(type);

      this._configure(instance, config);

      return instance;
    },

    /**
     * Create pure grid structure item instance
     *
     * @param {String} type - type of grid structure item to create
     * @returns {Object} - pure grid structure item
     * @private
     */
    _getInstance(type) {
      const componentClass = classMap[type];

      const originObject = _copyable.default.create({
        type
      });

      if (!componentClass) {
        throw new Ember.Error(`Class for component of type ${type} is not found`);
      }

      const instance = componentClass.create({
        component: null,
        parent: null,
        isEnabled: true
      });

      switch (type) {
        case _container.default.TOP_CONTAINER:
        case _container.default.WIDGET_HEADER:
        case _container.default.WIDGET_MAIN:
        case _container.default.WIDGET_FOOTER:
          {
            instance.set('droppable', true);
            originObject.set('children', []);
            break;
          }

        case _widget.WidgetType.WIDGET_TAB_PANE:
          {
            instance.set('droppable', true);
            instance.set('isActiveTab', false);
            originObject.set('children', []);
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity());
            break;
          }

        case _container.default.WIDGET_CONTAINER:
          {
            instance.set('droppable', true);
            originObject.set('children', []);
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity()); // Animation defaults

            originObject.set('enableAnimation', false);
            originObject.set('animationName', 'fade-down');
            originObject.set('animationDuration', '250ms');
            originObject.set('animationMirroring', 'true');
            originObject.set('animationOnDeviceType', [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL]);
            break;
          }

        case _container.default.WIDGET_TABS:
        case _widget.WidgetType.WIDGET_SECTION:
        case _container.default.WIDGET_ROW:
          {
            instance.set('droppable', true);
            originObject.set('children', []);
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity());
            break;
          }

        case _container.default.WIDGET_COLUMN:
          {
            instance.set('droppable', true);
            originObject.set('children', []);
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity());
            originObject.set('size', _copyable.default.create({
              [_breakpointModes.LARGE]: 12,
              [_breakpointModes.MEDIUM]: 12,
              [_breakpointModes.SMALL]: 12,
              [_breakpointModes.EXTRA_SMALL]: 12
            }));
            break;
          }

        case _module.default.MODULE_FORM:
          {
            instance.set('formIsConfirmed', false);
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity());
            break;
          }

        case _module.default.MODULE_CORE:
        case _module.default.MODULE_SYSTEM:
        case _widget.WidgetType.WIDGET_BUTTON:
        case _widget.WidgetType.WIDGET_BUTTONSGROUP:
        case _widget.WidgetType.WIDGET_LINE_DIVIDER:
        case _widget.WidgetType.WIDGET_IMAGE:
        case _widget.WidgetType.WIDGET_SPACER:
          {
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity());
            break;
          }

        case _widget.WidgetType.WIDGET_PARAGRAPH:
          {
            originObject.set('visibleOnDevices', this._getDeviceVisibilityEntity()); // Animation defaults

            originObject.set('enableAnimation', false);
            originObject.set('animationName', 'fade-down');
            originObject.set('animationDuration', '250ms');
            originObject.set('animationMirroring', 'true');
            originObject.set('animationOnDeviceType', [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL]);
            break;
          }

        default:
          {
            throw new Ember.Error(`Unknown type "${type}"`);
          }
      }

      instance.set('originObject', originObject);
      return instance;
    },

    /**
     * @returns {EmberObject} & {Copyable}
     *
     * @private
     */
    _getDeviceVisibilityEntity() {
      return _moduleDeviceVisibilityState.default.create();
    },

    /**
     * Configure grid structure item
     *
     * @param {Object} item - grid structure item to configure
     * @param {Object} config - grid structure item config
     * @returns {Object} - configured grid structure item
     * @private
     */
    _configure(item, config) {
      if (config) {
        Object.keys(config).forEach(key => {
          let value = config[key];

          if ($.isPlainObject(value)) {
            value = _copyable.default.create(value);
          }

          item.get('originObject').set(key, value);
        });
      }

      return item;
    }

  };
  _exports.default = _default;
});