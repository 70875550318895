define("builder/pods/components/menumanager-item/component", ["exports", "builder/core/which-transition-event", "builder/models/menu-child", "jquery"], function (_exports, _whichTransitionEvent2, _menuChild, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ok not a good approach used here, I think better to use drag service
   * like so: register all draggable droppable component into drag service(by creating base draggable component),
   * and provide hooks for custom logic:
   * onDragStart() {} - when drag just started what to do,
   * onDragOver() {} - if component is droppable, this hook will trigger when mouse moves over component,
   * onDrop() {} - on drop hook,
   * onDragEnd() {} - when user releases mouse button,
   * getDraggableElement() {} - element to which events should be attached to,
   * getDragElement() {} - actual element to show as dragging
   */
  var _default = Ember.Component.extend({
    /**
     * @property {Object|null} data - component base data
     */
    data: null,

    /**
     * @property {String[]} classNameBindings - class name bindings
     */
    classNameBindings: ['isEmptyClass::menu-manager__line'],

    /**
     * @property {Boolean} isEmptyClass - property to set class 'menu-manager__line'
     */
    isEmptyClass: false,

    /**
     * @property {String} classNames - component tag name
     */
    tagName: 'li',

    /**
     * @property {String} deleteClassName - delete class name
     */
    deleteClassName: 'menu-manager__item_delete',

    /**
     * @property {Boolean} addToMenu - property to pass openPagePopup action
     */
    addToMenu: false,

    /**
     * @property {String} _hiddenIconClass - hidden icon class name
     * @private
     */
    _hiddenIconClass: 'menu-manager__icon_hidden',

    /**
     * @property {String} _builderIconClass - class to be used for builder1 menu item
     * @private
     */
    _builderIconClass: 'icon-page',

    /**
     * @property {String} _builderIconHiddenClass - class to be used for builder1 hidden menu item
     */
    _builderIconHiddenClass: 'icon-hidden-page',

    /**
     * @property {String} icon - icon class to be used for current menu item
     */
    icon: Ember.computed('data.isHidden', function () {
      return this.data.get('isHidden') ? `${this._builderIconHiddenClass} ${this._hiddenIconClass}` : this._builderIconClass;
    }),

    /**
     * It is a dummy method to show error when you try to invoke
     * not implemented method in sub class component.
     *
     * @method getConfig
     */
    getConfig() {
      throw new Ember.Error(`${this.toString()}: please declare "getConfig" method`);
    },

    /**
     * It is a dummy method to show error when you try to invoke
     * not passed action from parent component or controller.
     *
     * @method openMMConfig
     */
    openMMConfig() {
      throw new Ember.Error(`${this.toString()}: does not passed "openMMConfig" handler`);
    },

    /**
     * @property {Ember.Service} dragService - drag service
     */
    dragService: Ember.inject.service('drag'),

    /**
     * @property {Function} onRender - callback to be invoked on didRender
     */
    onRender() {},

    /**
     * @property {Function} onDragStart - callback to be invoked on drag start
     */
    onDragStart: null,

    /**
     * @property {Function} onMouseMove - callback to be invoked on mouse move
     */
    onMouseMove: null,

    /**
     * @property {Function} onMouseUp - callback to be invoked on mouse up
     */
    onMouseUp: null,

    /**
     * @property {Function} onDestroy - callback to be invoked before component destroy
     */
    onDestroy() {},

    /**
     * @property {Boolean} editMode - indicates whether component in edit mode
     */
    editMode: false,

    /**
     * @property {Boolean} error - Input error indicator
     */
    error: false,

    /**
     * @property {String} editTitle - title for edit mode
     */
    editTitle: Ember.computed(function () {
      return this.data.get('title');
    }),

    /**
     * @property {Ember.Observer} onEditModeChange - observer to watch editMode property
     */
    onEditModeChange: Ember.observer('editMode', function () {
      if (this.editMode) {
        this._draggableOff();
      } else {
        this._draggableOn();
      }
    }),

    /**
     * @property {String} _hiddenClass - class to add when on drag start
     */
    _hiddenClass: 'menu-manager__line_hidden',

    /**
     * @property {String} _floatingClass - class to add for floating item
     */
    _floatingClass: 'menu-manager__line_floating',

    /**
     * @property {Object|null} _eventIO - capture event for singleClick only execute if there is no doubleClick
     */
    _eventIO: null,

    /**
     * @inheritdoc
     */
    didRender(...args) {
      this._super(...args);

      this._draggableOn();

      this.onRender(this);
    },

    /**
     * Handle click outside of menu-manager
     */
    handleDocumentClick() {
      const item = this.$();
      (0, _jquery.default)(document).on('click.mm', event => {
        if (this.editMode) {
          if (!(0, _jquery.default)(event.target).closest(item.find(' > .menu-manager__item')).length) {
            this.send('applyTitle', true);
          }
        }
      });
    },

    /**
     * Remove document click of menu-manager click listener
     */
    removeDocumentHandler() {
      (0, _jquery.default)(document).off('click.mm');
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      if (this.editMode) {
        this.removeDocumentHandler();
      }

      this._draggableOff();

      this.onDestroy(this);
    },

    /**
     * Get element to register as draggable node
     *
     * @returns {jQuery}
     */
    getDraggableElement() {
      return this.$();
    },

    /**
     * Make current node draggable
     *
     * @returns {Boolean}
     * @private
     */
    _draggableOn() {
      const self = this;
      const draggable = self.getDraggableElement();

      if (!this.get('data.config.draggable') || this.editMode || this.get('data.config.isMissingPage')) {
        return false;
      }

      self.get('dragService').applyDraggable(draggable, {
        scrollObject: document.querySelector('.menu-manager__dropdown'),

        onScroll() {
          self.get('onScroll')();
        },

        onScrollStop() {
          self.get('onScrollStop')();
        },

        onDragStart() {
          draggable.addClass(self.get('_hiddenClass'));
          self.get('onDragStart')();
        },

        onMouseMove(x, y, viewport) {
          self.get('onMouseMove')(self, x, y, viewport);
        },

        onMouseUp(_, __, x, y) {
          self.get('onMouseUp')(self, x, y);
        },

        getDragElement(node) {
          return self._getDragElement(node);
        },

        onDragEnd() {
          draggable.removeClass(self.get('_hiddenClass'));
        }

      });
      return true;
    },

    /**
     * Make current node undraggable
     *
     * @private
     */
    _draggableOff() {
      const el = this.$();

      if (el) {
        this.dragService.removeDraggable(this.getDraggableElement());
      }
    },

    /**
     * Draggable element thumbnail
     *
     * @param {jQuery} node - jQuery element
     * @returns {jQuery}
     * @private
     */
    _getDragElement(node) {
      const element = node.clone();
      const offset = node.offset();
      element.addClass(this._floatingClass); // Position right at current component's position

      element.css({
        position: 'absolute',
        top: offset.top,
        left: offset.left,
        'z-index': 1000,
        opacity: 0.5
      });
      (0, _jquery.default)('body').append(element);
      element.on((0, _whichTransitionEvent2._whichTransitionEvent)(), function () {
        this.remove();
      });
      return element;
    },

    /**
     * It is a dummy method to show error when you try to invoke
     * not passed action from parent component or controller.
     *
     * @method openPageWizard
     */
    openPageWizard() {
      throw new Ember.Error(`${this.toString()}: does not passed "openPageWizard" handler`);
    },

    actions: {
      /**
       * Changes state of component
       *
       * @param {String} action
       */
      changeState(action) {
        switch (action) {
          case 'delete':
            this.$(' > .menu-manager__item').addClass(this.deleteClassName);
            break;

          default:
            this.$(' > .menu-manager__item').removeClass(this.deleteClassName);
            break;
        }
      },

      toggleHide() {
        this.toggleIsHidden(this.data);
      },

      remove() {
        this.removeMenuItem(this.data);
      },

      /**
       * Action to send config data.
       */
      openConfig() {
        this.openMMConfig(this.getConfig());
      },

      /**
       * Action to edit item title
       */
      editTitle() {
        const eventIO = this._eventIO;

        if (eventIO !== null) {
          Ember.run.cancel(eventIO);
          this.set('_eventIO', null);
        } // Home page should not be editable


        if (this.get('data.type') === _menuChild.default.PAGE && this.get('data.config.isHomepage')) {
          return;
        }

        this.set('error', false);
        this.set('editMode', true);
        this.set('editTitle', this.get('data.title'));
        Ember.run.schedule('afterRender', this, () => {
          this.handleDocumentClick();
          this.$('input').trigger('focus').on('keydown', event => {
            if (event.keyCode === 13) {
              this.send('applyTitle');
            }
          });
        });
      },

      /**
       * Action to apply new title
       *
       * @param {Boolean} applyPrevious - flag to restore title
       */
      applyTitle(applyPrevious = false) {
        const prevValue = this.get('data.title').trim();
        const newValue = this.editTitle.trim();

        if (newValue && newValue.length <= 255) {
          this.changeMMTitle(newValue, this.data);
          this.set('editMode', false);
          this.removeDocumentHandler();

          if (this.data.get('type') === _menuChild.default.PAGE) {
            this.changePageTitle(newValue, this.data.get('config').get('pageId'));
          } else {
            this.changeMMSettingsTitle(newValue, this.data.get('itemId'));
          }

          this.set('editTitle', newValue);
        } else if (applyPrevious) {
          this.set('editMode', false);
          this.set('editTitle', prevValue);
          this.removeDocumentHandler();
        } else {
          this.set('error', true);
        }
      },

      /**
       * Action to transition to page
       */
      titleClick() {
        const eventIO = this._eventIO;

        if (eventIO === null) {
          const laterEvent = Ember.run.later(this, function () {
            const laterEventIO = this._eventIO;
            this.onTitleClick(this.data);

            if (laterEventIO !== null) {
              Ember.run.cancel(laterEventIO);
              this.set('_eventIO', null);
            }
          }, 200);
          this.set('_eventIO', laterEvent);
        }
      },

      /**
       * Action opens page wizard
       *
       * @param {Boolean} addToMenu - set page.create addToMenu query parameter
       */
      openPagePopup(addToMenu) {
        this.openPageWizard(addToMenu);
      }

    }
  });

  _exports.default = _default;
});