define("builder/pods/components/field-radiolist/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['bld-form_radiolist'],

    /**
     * Column class name modifiers
     * @type {Object}
     */
    modifiers: {
      1: '__one',
      2: '__two',
      3: '__three',
      4: '__four'
    },

    /**
     * Fields state disabled/enabled
     * @type {boolean}
     * @readonly
     */
    disabled: Ember.computed.bool('field.disabled').readOnly(),

    /**
     * Array of checkboxes objects
     * @type {Object[]}
     * @readonly
     */
    choicesArray: Ember.computed('field.choices', 'field.data', function () {
      const fieldName = this.get('field.name');
      const fieldData = this.get('field.data');
      const choices = this.get('field.choices') || [];
      return choices.map(choice => ({
        id: fieldName + choice.value + Date.now(),
        value: choice.value,
        label: choice.label,
        help: choice.help,
        checked: fieldData === choice.value,
        disabled: !!choice._disabled
      }));
    }).readOnly(),

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args);

      if (!this.get('field.columns')) {
        this.set('field.columns', 1);
      }
    },

    /**
     * @inheritDoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * @inheritDoc
     */
    didRender() {
      const radios = this.$('.bld-form_radiolist_radio');
      const columns = this.getColumns();

      if (!this.$('.bld-form_radiolist_column').length) {
        columns.forEach(range => this._wrapElements(radios.slice(...range)));
      }
    },

    /**
     * Returns columns range
     * @returns {Array[]} - Array of columns range
     */
    getColumns() {
      const columns = this.get('field.columns');
      let end = 0;
      const results = [];
      let radioCount = this.choicesArray.length;
      let beginning;

      if (radioCount < columns) {
        radioCount = columns;
      }

      const inColumn = radioCount / columns;
      const max = Math.ceil(inColumn);
      const min = Math.floor(inColumn);

      for (let i = 0; i < columns; i++) {
        beginning = end;
        end += (radioCount - max) / (columns - (i + 1)) >= 1 ? max : min;
        radioCount -= end;
        results.push([beginning, end]);
      }

      return results;
    },

    /**
     * Wraps passed jquery elements
     * @param {jQuery} elements - Elements need to be wrapped with column element
     * @private
     */
    _wrapElements(elements) {
      elements.wrapAll(`<div class="bld-form_radiolist_column ${this.modifiers[this.get('field.columns')]}"></div>`);
    },

    actions: {
      change(value) {
        const action = this.actionHandler;
        this.set('field.data', value);

        if (action && !this.notValid) {
          action(value, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});