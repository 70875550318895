define("builder/services/font-icon", ["exports", "builder/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ICONS_PROPERTY_NAME = 'icons-font';
  /**
   * @implements {IFontIconService}
   */

  let FontIconService = (_dec = Ember.inject.service('store'), _dec2 = Ember.computed('_currentIconFontName'), (_class = (_temp = class FontIconService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_store", _descriptor, this);

      this._currentIconFontName = null;
    }

    /**
     * Icons array reference.
     * @type {FontIconModel[]}
     */
    get icons() {
      return this._store.peekAll('font-icon').toArray();
    }
    /**
     * Selected icon name
     * @type {string}
     */


    /**
     * Selected icon library
     * @type {FontIconModel | null}
     */
    get currentIconLibrary() {
      if (!this._currentIconFontName) {
        return null;
      }

      return this._store.peekAll('font-icon').find(icon => icon.Name === this._currentIconFontName);
    }
    /**
     * Reloads icons data if needed.
     * @returns {Promise<void>}
     */


    async reloadIcons() {
      const data = this._store.peekAll('font-icon');

      if (!data || !data.length) {
        await this._store.findAll('font-icon');
      }
    }
    /**
     * Applies selected icon font for current website
     * @param {string} iconFontName - icon font name to appy
     * @returns
     */


    applyIconFont(iconFontName) {
      this.set('_currentIconFontName', iconFontName);

      const iconFont = this._store.peekAll('font-icon').find(icon => icon.Name === iconFontName);

      if (!iconFont) {
        // If no icon font provided, than no icon with provided name configured
        return;
      }

      const cssFileUrl = `${_environment.default.APP.BASE_ICONS_URI}${iconFont.CSSFile}`;
      const iconsPlaceholder = document.head.querySelector('link[data-icons]');
      iconsPlaceholder.setAttribute('href', cssFileUrl);
    }
    /**
     * Applies initial icon font for website
     * @returns {void}
     */


    applyInitialIconFont() {
      var _this$_store$peekAll, _this$_store$peekAll$, _this$_store$peekAll$2;

      const iconFontProperty = (_this$_store$peekAll = this._store.peekAll('website-style')) === null || _this$_store$peekAll === void 0 ? void 0 : (_this$_store$peekAll$ = _this$_store$peekAll.firstObject) === null || _this$_store$peekAll$ === void 0 ? void 0 : (_this$_store$peekAll$2 = _this$_store$peekAll$.global) === null || _this$_store$peekAll$2 === void 0 ? void 0 : _this$_store$peekAll$2.find(prop => prop.name === ICONS_PROPERTY_NAME);

      if (!iconFontProperty || !iconFontProperty.value) {
        // If no icon font name provided, than no icons configured for this website
        return;
      }

      this.applyIconFont(iconFontProperty.value);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentIconLibrary", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "currentIconLibrary"), _class.prototype)), _class));
  _exports.default = FontIconService;
});