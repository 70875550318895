define("builder/pods/components/slider-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tilFg+Fh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"range-picker__slider \",[28,\"unless\",[[24,[\"showInput\"]],\"range-picker__slider_margin-0\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"range-picker__label\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[1,[28,\"range-slider\",null,[[\"start\",\"min\",\"max\",\"on-change\",\"on-start\",\"on-end\",\"on-slide\"],[[24,[\"start\"]],[24,[\"min\"]],[24,[\"max\"]],[28,\"action\",[[23,0,[]],\"sliderChanged\"],null],[28,\"action\",[[23,0,[]],\"activate\"],null],[28,\"action\",[[23,0,[]],\"deactivate\"],null],[28,\"action\",[[23,0,[]],\"updateValue\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showInput\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"range-picker__value \",[28,\"if\",[[24,[\"isInputInFocus\"]],\"range-picker__focused_input\",\"\"],null]]]],[8],[0,\"\\n\\t\\t\"],[7,\"input\",false],[12,\"class\",\"range-picker__input\"],[12,\"maxlength\",[22,\"maxLength\"]],[12,\"placeholder\",[22,\"inputValue\"]],[12,\"value\",[22,\"inputValue\"]],[12,\"type\",\"text\"],[3,\"action\",[[23,0,[]],\"updateInputValue\"],[[\"on\"],[\"change\"]]],[3,\"action\",[[23,0,[]],\"onInputFocusIn\"],[[\"on\"],[\"focusIn\"]]],[3,\"action\",[[23,0,[]],\"onInputFocusOut\"],[[\"on\"],[\"focusOut\"]]],[8],[9],[0,\"\\n\\t\\t\"],[1,[22,\"measurementPoint\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/slider-picker/template.hbs"
    }
  });

  _exports.default = _default;
});