define("builder/widgets/configs/widget-spacer", ["exports", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes"], function (_exports, _fields, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Configuration for widget-spacer
   * This fields will appear at module configuration popup
   */
  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      return {
        config: [{
          type: _fields.default.CHECKBOX_LIST,
          label: 'Show spacer on which devices / breakpoints?',
          description: 'Please select at least 1 device for the module to show on',
          help: 'When Un-checking the box means the widget will be hidden on that device / breakpoint.',
          name: 'visibleOnDevices',
          data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
          choices: [{
            value: _breakpointModes.LARGE,
            label: 'Desktop'
          }, {
            value: _breakpointModes.MEDIUM,
            label: 'Small Monitor'
          }, {
            value: _breakpointModes.SMALL,
            label: 'Tablet'
          }, {
            value: _breakpointModes.EXTRA_SMALL,
            label: 'Mobile'
          }],
          validation: {
            elements: {
              min: 1,
              max: 4
            },
            message: 'You must select at least one device for this module to display on.'
          }
        }]
      };
    }

  });

  _exports.default = _default;
});