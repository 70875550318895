define("builder/pods/components/sm-info-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "544c3PAs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"field-info-block \",[22,\"themeClass\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"label\",true],[10,\"class\",\"field-info-block__label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"field-info-block__content\"],[8],[1,[28,\"html-format\",[[24,[\"content\"]]],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/sm-info-block/template.hbs"
    }
  });

  _exports.default = _default;
});