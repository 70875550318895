define("builder/models/form-field", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    Type: (0, _model.attr)('string'),
    Content: (0, _model.attr)('string')
  });

  _exports.default = _default;
});