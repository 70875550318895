define("builder/pods/components/widget-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4R3p9MZt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"module-buttons\",[],[[\"@acl\",\"@title\",\"@moduleAction\",\"@module\",\"@parentWidth\"],[[24,[\"data\",\"acl\"]],[24,[\"data\",\"mainTitle\"]],[22,\"moduleAction\"],[22,\"data\"],[22,\"containerWidth\"]]]],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"page_module_content \",[22,\"alignClass\"]]]],[8],[0,\"\\n\\t\"],[5,\"base-button\",[],[[\"@animationData\",\"@data\",\"@contentLayout\",\"@style\",\"@sizeClass\"],[[22,\"animationData\"],[24,[\"data\",\"originObject\"]],[23,0,[\"contentLayout\"]],[22,\"styles\"],[22,\"sizeClass\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"enableVResize\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"resize-handler\",[],[[\"@horizontal\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-button/template.hbs"
    }
  });

  _exports.default = _default;
});