define("builder/services/widget-paragraph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WidgetParagraphService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this._addLinkCallback = null;
      this._updateLinkCallback = null;
      this.addLinkPopupVisible = false;
      this.updateLinkPopupVisible = false;
      this.linkData = null;
      this.linkText = null;
    }

    /**
     * Shows add popup
     * @param {LinkText} linkText - object for link text
     * @param {(linkText:string, linkData: LinkOptions) => void} callback - callback for link add
     * @returns {void}
     */
    openAddLinkPopup(linkText, callback) {
      this.set('_addLinkCallback', callback);
      this.set('linkText', linkText);
      this.set('addLinkPopupVisible', true);
    }
    /**
     * Cancel link add
     * @returns {void}
     */


    linkAddCancel() {
      this.set('_addLinkCallback', null);
      this.set('addLinkPopupVisible', false);
    }
    /**
     * Link add successful
     * @param {string} linkText - link visible text
     * @param {LinkOptions} linkData - link attributes
     * @returns {void}
     */


    linkAddSuccess(linkText, linkData) {
      if (typeof this._addLinkCallback === 'function') {
        this._addLinkCallback(linkText, linkData);
      }

      this.set('_addLinkCallback', null);
      this.set('addLinkPopupVisible', false);
    }
    /**
     * Shows update popup
     * @param {string} linkText - new link text
     * @param {LinkOptions} linkData - new link data
     * @param {(linkText:string, linkData: LinkOptions) => void} callback - callback for link update
     * @returns {void}
     */


    openUpdateLinkPopup(linkText, linkData, callback) {
      this.set('_updateLinkCallback', callback);
      this.set('linkData', linkData);
      this.set('linkText', linkText);
      this.set('updateLinkPopupVisible', true);
    }
    /**
     * Cancel link update
     * @returns {void}
     */


    linkUpdateCancel() {
      this.set('_updateLinkCallback', null);
      this.set('linkData', null);
      this.set('linkText', null);
      this.set('updateLinkPopupVisible', false);
    }
    /**
     * Link update successful
     * @param {string} linkText - link visible text
     * @param {LinkOptions} linkData - link attributes
     * @returns {void}
     */


    linkUpdateSuccess(linkText, linkData) {
      if (typeof this._updateLinkCallback === 'function') {
        this._updateLinkCallback(linkText, linkData);
      }

      this.set('_updateLinkCallback', null);
      this.set('updateLinkPopupVisible', false);
    }

  }

  _exports.default = WidgetParagraphService;
});