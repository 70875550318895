define("builder/pods/components/field-contenteditable/component", ["exports", "builder/core/meta-tags/serializer", "builder/core/meta-tags/deserializer", "builder/core/meta-tags/commands", "builder/core/meta-tags/meta-variables-configuration", "builder/core/enumerators/keyboard", "builder/mixins/field-validation", "builder/mixins/field-visibility", "builder/core/meta-tags/dom-manipulation", "builder/core/meta-tags/dom-manipulation/tag-constants"], function (_exports, _serializer, _deserializer, _commands, _metaVariablesConfiguration, _keyboard, _fieldValidation, _fieldVisibility, _domManipulation, _tagConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isFirefox = !!window.navigator.userAgent.match(/firefox/i);

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['bld-field_content-editable'],

    /**
     * API property to set auto focus on render
     * @type {boolean}
     */
    autofocus: false,

    /**
     * Remove new lines from returned value
     * @type {boolean}
     */
    singleLine: Ember.computed.bool('field.singleLine'),

    /**
     * Remove trailing spaces from head and tail returned value
     * @type {boolean}
     */
    trimSpaces: Ember.computed.bool('field.trimSpaces'),

    /**
     * Remove double spaces from returned value
     * @type {boolean}
     */
    clearDoubleSpaces: Ember.computed.bool('field.clearDoubleSpaces'),

    /**
     * Metapicker popup is shown or not
     * @type {boolean}
     */
    isMetaPickerOpen: false,

    /**
     * Metapicker wrapper element id
     * @type {string}
     */
    metapickerWrapperId: Ember.computed('elementId', function () {
      return `metapicker-popup-${this.elementId}`;
    }),

    /**
     * Content holder
     * @type {HTMLElement}
     */
    contentEditable: Ember.computed('element', function () {
      return this.element.querySelector('.bld-field_content-editable_data');
    }),

    /**
     * Check if the field belong to Seo Url
     * @type {boolean}
     */
    isFieldDataAnArray: Ember.computed.bool('field.isFieldDataAnArray'),

    /**
     * Check if the field belong to Seo Url
     * @type {boolean}
     */
    isSEORule: Ember.computed.bool('field.isSEORule'),

    /**
     * string to filter metapicker content
     * @type {string}
     */
    searchQuery: '',

    /**
     * Metapicker navigation command to perform
     * @type {MetapickerNavigationCommand | null}
     */
    metapickerNavigationCommand: null,

    /**
     * Current raw text content
     * @type {string|null}
     * @private
     */
    _currentContent: null,

    /**
     * @inheritDoc
     */
    init() {
      this._super();

      this.metaVariables = _metaVariablesConfiguration.defaultMetaVariables;
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      if (isFirefox) {
        this.contentEditable.addEventListener('keydown', function (event) {
          const selection = document.getSelection();

          if (!selection) {
            return;
          }

          const {
            anchorNode
          } = selection;
          const {
            previousSibling
          } = anchorNode;

          if (event.keyCode === _keyboard.default.KEY.BACKSPACE) {
            var _previousSibling$clas;

            if (selection.anchorOffset === 0 && previousSibling && (previousSibling === null || previousSibling === void 0 ? void 0 : (_previousSibling$clas = previousSibling.classList) === null || _previousSibling$clas === void 0 ? void 0 : _previousSibling$clas.contains(_tagConstants.MetaTag.className))) {
              // Remove meta tag
              previousSibling.remove();
            }
          }
        });
      }

      if (this.triggerOnRender && this.field.data) {
        this._callActionHandler();
      }

      if (this.autofocus) {
        this.element.focus();
      }

      this.contentEditable.addEventListener('paste', function (event) {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
      });
      this.contentEditable.addEventListener('focus', event => {
        this.preventTextFormatting(); // Save content on focus and use it to compare on blur to emulate change event

        this.saveCurrentContent();
      });
      this.contentEditable.addEventListener('blur', event => {
        if (this.isMetaPickerOpen && this._findMetapickerWrapper(event.relatedTarget)) {
          return;
        } // Remove text formatting handler on component focus out to restore document normal behaviour


        this.removeTextFormattingPrevent();
        this.closeMetaTagsPopup();

        if (isFirefox && this.isFieldDataAnArray) {
          (0, _domManipulation.addSpacesForMeta)(this.contentEditable, true);
        }

        if (this.isContentChanged()) {
          this.triggerChangeEvent();
          this.insertContent();
        }
      });
      this.setRows();
      this.setPlaceholder();
      this.insertContent();

      if (isFirefox) {
        (0, _domManipulation.addSpacesForMeta)(this.contentEditable, true);
      } // contenteditable reads default behavior for control buttons on keyup events.
      // so if we move all this stuff to keydown event, the default behavior for this buttons still remains.
      // with this event handler we preventing default behavior for up/down/enter/escape if they were pressed
      // within our search element


      this.contentEditable.addEventListener('keydown', event => {
        if (!(0, _domManipulation.isKeyPressedOnSearchElement)()) {
          return;
        }

        const {
          keyCode
        } = event;
        const {
          UP,
          DOWN,
          ENTER,
          ESCAPE
        } = _keyboard.default.KEY; // // Prevent default behavior for these keys

        if ([ENTER, UP, DOWN].includes(keyCode)) {
          event.preventDefault();
          event.stopPropagation();
          const command = (0, _commands.getMetapickerNavigationCommand)(keyCode);
          this.set('metapickerNavigationCommand', command);
          return;
        }

        if (keyCode === ESCAPE) {
          event.preventDefault();
          event.stopPropagation();
          (0, _domManipulation.replaceSearchWithCurrentInput)(this.contentEditable);
          this.closeMetaTagsPopup();
        }
      }); //

      this.contentEditable.addEventListener('keyup', event => {
        const {
          keyCode
        } = event;
        const {
          SHIFT,
          UP,
          DOWN,
          ESCAPE
        } = _keyboard.default.KEY; // we still need to prevent default behavior for this keys
        // in other way escape will close popup window and up/down will move caret within contenteditable

        if ([SHIFT, UP, DOWN, ESCAPE].includes(keyCode)) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        if (isFirefox) {
          if (this.isSEORule) {
            this._updateFieldData();

            return;
          }

          (0, _domManipulation.addSpacesForMeta)(this.contentEditable, true);
        }

        this._updateFieldData();

        (0, _domManipulation.detectOutOfSearchBoundaries)(this.handleOutOfCurlyWrapperBoundaries.bind(this));

        if (!this.isMetaPickerOpen) {
          (0, _domManipulation.detectDoubleCurlyPress)(event, this.handleDoubleCurlyInput.bind(this));
        }

        if (this.isMetaPickerOpen) {
          (0, _domManipulation.detectSearchContentChange)(event, this.handleSearchContentChange.bind(this));
        }

        if (!this.isFieldDataAnArray && !isFirefox) {
          var _this$contentEditable;

          const lastChildElement = (_this$contentEditable = this.contentEditable) === null || _this$contentEditable === void 0 ? void 0 : _this$contentEditable.lastChild;

          if ((lastChildElement === null || lastChildElement === void 0 ? void 0 : lastChildElement.className) === _tagConstants.MetaTag.className) {
            this.setCaretAfterMetaTag(lastChildElement);
          }
        }
      });
      this.contentEditable.addEventListener('click', event => {
        (0, _domManipulation.clearKeyHistory)(event);
        (0, _domManipulation.handleMetaTagRemoveButtonClick)(event, () => this._handleMetaTagRemoveButtonClick());
        (0, _domManipulation.detectOutOfSearchBoundaries)(this.handleOutOfCurlyWrapperBoundaries.bind(this));
      });
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.set('disableValidation', !this.field.enableValidationImmediately); // We should always re-init validation

      this.applyValidations();

      if (this.field.enableValidationImmediately) {
        this._firstValidation();
      }
    },

    /**
     * @inheritdoc
     */
    didUpdateAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.insertContent();
    },

    handleOutOfCurlyWrapperBoundaries() {
      (0, _domManipulation.replaceSearchWithCurrentInput)(this.contentEditable);
      this.closeMetaTagsPopup();
    },

    /**
     * Sets field data on content editable change
     * @returns {void}
     */
    _updateFieldData() {
      if (!this.isFieldDataAnArray) {
        return;
      }

      const serializedHtmlContent = (0, _serializer.serialize)(this.contentEditable.innerHTML);
      const textContent = this.htmlToText(serializedHtmlContent);
      this.set('field.data', textContent);
    },

    /**
     * handle click event for removing metatag
     * @returns {void}
     */
    _handleMetaTagRemoveButtonClick() {
      this.triggerChangeEvent();

      this._updateFieldData();
    },

    /**
     * Method for call error handler
     *
     * @method _callErrorHandler
     * @private
     */
    _callErrorHandler() {
      const {
        errorHandler
      } = this;

      if (errorHandler) {
        errorHandler(this.field.data, this.field.name, this.validations.message);
      }
    },

    /**
     * Method for call action handler
     *
     * @method _callActionHandler
     * @param {string} [type] - type event called it method
     * @private
     */
    _callActionHandler(type) {
      const {
        actionHandler
      } = this;

      if (actionHandler) {
        actionHandler(this.field.data, this.field.name, type);
      }
    },

    /**
     * Method for the first field check
     *
     * @method _firstValidation
     * @private
     */
    _firstValidation() {
      if (!this.validations.isTruelyValid) {
        this._callErrorHandler();
      }
    },

    handleSearchContentChange(searchString) {
      this.set('searchQuery', searchString);
    },

    handleDoubleCurlyInput() {
      let addSpace = true;

      if (!isFirefox && this.isFieldDataAnArray) {
        addSpace = false;
      }

      (0, _domManipulation.replaceCurlyBracesWithSearch)(addSpace);
      this.openMetaTagsPopup();
      this.positionMetapickerElement();
    },

    /**
     * Sets placeholder for content editable
     * @returns {void}
     */
    setPlaceholder() {
      if (this.field.placeholder) {
        this.contentEditable.setAttribute('placeholder', this.field.placeholder);
      }
    },

    /**
     * Emulates rows of textarea
     * @returns {void}
     */
    setRows() {
      const {
        rows
      } = this.field;

      if (rows && rows > 1) {
        const style = window.getComputedStyle(this.contentEditable);
        const height = parseInt(style.getPropertyValue('line-height'), 10);
        const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10);
        const paddingBottom = parseInt(style.getPropertyValue('padding-bottom'), 10);
        const borderTop = parseInt(style.getPropertyValue('border-top-width'), 10);
        const borderBottom = parseInt(style.getPropertyValue('border-bottom-width'), 10);
        this.contentEditable.style.minHeight = `${height * rows + paddingTop + paddingBottom + borderBottom + borderTop}px`;
      }
    },

    /**
     * Insert default data into content editable
     * @returns {void}
     */
    insertContent() {
      var _this$contentEditable2;

      const {
        data
      } = this.field;
      const html = isFirefox && this.isSEORule ? data : (0, _deserializer.deserialize)(data);
      this.contentEditable.innerHTML = Ember.String.htmlSafe(html);
      const lastChildElement = (_this$contentEditable2 = this.contentEditable) === null || _this$contentEditable2 === void 0 ? void 0 : _this$contentEditable2.lastChild;

      if (isFirefox && (lastChildElement === null || lastChildElement === void 0 ? void 0 : lastChildElement.className) === _tagConstants.MetaTag.className) {
        (0, _domManipulation.addSpacesForMeta)(this.contentEditable, true);

        this._setCaretPositon(lastChildElement);
      }
    },

    /**
     * Prevents text formatting to emulate input text and textarea
     * @returns {void}
     */
    preventTextFormatting() {
      document.addEventListener('keydown', this.textFormatHandler);
    },

    /**
     * Removes text formatting handlers
     * @returns {void}
     */
    removeTextFormattingPrevent() {
      document.removeEventListener('keydown', this.textFormatHandler);
    },

    /**
     * Prevents text formatting shortcuts (Ctrl+B, Ctrl+I, Ctrl+U)
     * @param {KeyboardEvent} event
     * @returns {void}
     */
    textFormatHandler(event) {
      if ((event.ctrlKey || event.metaKey) && /^[biu]$/.test(event.key)) {
        event.preventDefault();
      }
    },

    /**
     * Saves current content
     * @returns {void}
     */
    saveCurrentContent() {
      var _this$element;

      this._currentContent = (_this$element = this.element) === null || _this$element === void 0 ? void 0 : _this$element.innerHTML;
    },

    /**
     * Checks whether content was changed
     * @returns {boolean}
     */
    isContentChanged() {
      var _this$element2;

      return this._currentContent !== ((_this$element2 = this.element) === null || _this$element2 === void 0 ? void 0 : _this$element2.innerHTML);
    },

    /**
     * Trigger external action handler and pass field content and name
     * @returns {void}
     */
    triggerChangeEvent() {
      const action = this.actionHandler;

      if (typeof action === 'function') {
        const serializedHtmlContent = (0, _serializer.serialize)(this.contentEditable.innerHTML);
        const textContent = this.htmlToText(serializedHtmlContent);
        action(textContent, this.field.name);
      }
    },

    /**
     * Transform html text to plain text
     * @param {string} html - string potentially contains html
     * @return {string}
     */
    htmlToText(html) {
      const div = document.createElement('div');
      let text = '';
      div.innerHTML = html;
      text = div === null || div === void 0 ? void 0 : div.innerText;

      if (this.singleLine) {
        text = text.replace(/\n+/g, ' ');
      }

      if (this.trimSpaces) {
        text = text.trim();
      }

      if (this.clearDoubleSpaces) {
        text = text.replace(/\s+/g, ' ');
      }

      return text;
    },

    /**
     * Opens meta tags picker popup
     * @returns {void}
     */
    openMetaTagsPopup() {
      this.set('isMetaPickerOpen', true);
    },

    positionMetapickerElement() {
      const metapicker = this.element.querySelector(`#${this.metapickerWrapperId}`);

      if (!metapicker) {
        return;
      }

      const searchOffset = (0, _domManipulation.getSearchElementOffset)(this.contentEditable);
      metapicker.style.top = `${searchOffset.top}px`;
      metapicker.style.left = `${searchOffset.left}px`;
    },

    /**
     * Closes meta tags picker popup
     * @returns {void}
     */
    closeMetaTagsPopup() {
      if (!this.isDestroyed) {
        this.set('isMetaPickerOpen', false);
      }

      (0, _domManipulation.replaceSearchWithCurrentInput)(this.contentEditable);
    },

    /**
     * Sets caret after meta tag
     * @param {HTMLElement} metaTag
     * @returns {void}
     */
    setCaretAfterMetaTag(metaTag) {
      metaTag.after('\u00A0');
      const spaceAfterMetaTag = metaTag.nextSibling;

      this._setCaretPositon(spaceAfterMetaTag);
    },

    /**
     * Sets caret after element
     * @param {HTMLElement} element
     * @returns {void}
     */
    _setCaretPositon(element) {
      if (!element) {
        return;
      }

      const range = document.createRange();
      const selection = window.getSelection();
      range.setStart(element, 0);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      this.contentEditable.focus();
    },

    /**
     * Find metapicker wrapper
     * @param {HTMLElement} element - element to search
     * @returns {HTMLElement | null}
     * @private
     */
    _findMetapickerWrapper(element) {
      if (!element || element === this.element) {
        return null;
      }

      if (element && element.id === this.metapickerWrapperId) {
        return element;
      }

      return this._findMetapickerWrapper(element.parentElement);
    },

    actions: {
      onMetaVariableSelected(variable) {
        const metaTag = (0, _domManipulation.createMetaTagElement)(variable.path);
        (0, _domManipulation.replaceSearchWithMetaElement)(this.contentEditable, metaTag);
        this.setCaretAfterMetaTag(metaTag);
        this.closeMetaTagsPopup();

        if (!this.isFieldDataAnArray && !isFirefox) {
          // Format the content editable data and set caret correctly after the new Metatag
          const serializedHtmlContent = (0, _serializer.serialize)(this.contentEditable.innerHTML);
          const textContent = this.htmlToText(serializedHtmlContent);
          const html = (0, _deserializer.deserialize)(textContent);
          this.contentEditable.innerHTML = Ember.String.htmlSafe(html);

          this._setCaretPositon(metaTag);
        }

        this._updateFieldData();
      },

      onCloseMetaPicker() {
        this.closeMetaTagsPopup();
      }

    }
  });

  _exports.default = _default;
});