define("builder/core/styles-management/composite-properties/rectangle", ["exports", "builder/core/abstract-classes/composite-property"], function (_exports, _compositeProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Class helps build css properties "margin" and "padding" according to css rules
   *
   * @class RectangleCompositeProperty
   */
  var _default = _compositeProperty.default.extend({
    /**
     * @inheritdoc
     */
    compositeProperties: Ember.computed(function () {
      return {
        top: null,
        right: null,
        bottom: null,
        left: null
      };
    }),

    /**
     * @property {String} unit
     */
    unit: 'px',

    /**
     * @inheritdoc
     */
    getValue(propName) {
      return this._super(this.transformPropertyName(propName));
    },

    /**
     * @inheritdoc
     */
    setValue(propName, value) {
      const transformedPropName = this.transformPropertyName(propName);
      const valueWithUnits = `${parseFloat(value)}${this.unit}`;

      this._super(transformedPropName, valueWithUnits);
    },

    /**
     * Method transform props "margin-{side}" or "padding-{side}" to inner format
     *
     * @param {String} propName - property name in format "text-anotherText";
     * @method transformPropertyName
     * @returns {*}
     */
    transformPropertyName(propName) {
      return propName.split('-')[1];
    },

    /**
     * @inheritdoc
     */
    build() {
      const {
        top,
        right,
        bottom,
        left
      } = this.compositeProperties;
      let result;

      if (top === right && right === bottom && bottom === left) {
        result = top;
      } else if (top === bottom && right === left) {
        result = [top, right].join(' ');
      } else if (right === left) {
        result = [top, right, bottom].join(' ');
      } else {
        result = [top, right, bottom, left].join(' ');
      }

      return result;
    },

    /**
     * @inheritdoc
     */
    buildForCompositeView() {
      return {
        value: this.build(),
        background: null
      };
    }

  });

  _exports.default = _default;
});