define("builder/models/style-css-fragment", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Contain builded CSS by breakpoints
   *
   * @class StyleCSSFragment
   */
  var _default = _fragment.default.extend({
    /**
     * @property {String} global
     */
    global: (0, _model.attr)('string'),

    /**
     * @property {String} lg
     */
    lg: (0, _model.attr)('string'),

    /**
     * @property {String} md
     */
    md: (0, _model.attr)('string'),

    /**
     * @property {String} sm
     */
    sm: (0, _model.attr)('string'),

    /**
     * @property {String} xs
     */
    xs: (0, _model.attr)('string')
  });

  _exports.default = _default;
});