define("builder/pods/components/field-info/component", ["exports", "builder/mixins/field-visibility"], function (_exports, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldInfoComponent
   */
  var _default = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['field-info'],

    /**
     * @property {Object} field - original passed in data
     */
    field: null,

    /**
     * @property {Ember.computed} value - value from data source, not editable value, from backend
     */
    value: Ember.computed('field', 'field.choices', 'field.choices.[]', function () {
      const choices = this.get('field.choices') || [];
      const firstChoice = choices.pop();
      return firstChoice ? firstChoice.label : null;
    })
  });

  _exports.default = _default;
});