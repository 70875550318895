define("builder/services/drag-and-drop", ["exports", "builder/core/drop-rules/rule-resolver", "builder/core/drop-coordinator", "builder/core/enumerators/direction", "builder/core/enumerators/widget", "builder/core/enumerators/module", "builder/core/enumerators/container", "builder/core/enumerators/breakpoint-modes"], function (_exports, _ruleResolver, _dropCoordinator, _direction, _widget, _module, _container, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class DragAndDropService
   */
  var _default = Ember.Service.extend({
    /**
     * Service for feature toggling
     * @type {Ember.Service}
     */
    features: Ember.inject.service('features'),

    /**
     * structure to watch over
     * @type {object}
     */
    structure: Ember.computed.readOnly('_structureService.pageViewModel'),

    /**
     * @property {Ember.Service} dropHighlight - Drop highlight service
     */
    dropHighlight: Ember.inject.service('drop-highlight'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {Object} resizableService - Resizable service
     */
    resizableService: Ember.inject.service('resizable'),

    /**
     * @property {Object} draggableComponent - structure item, that is dragged
     */
    draggableComponent: null,

    /**
     * @property {Object} droppableComponent - structure item to drop in
     */
    droppableComponent: null,

    /**
     * @property {Function[]} - collection beforeDrop callbacks
     */
    beforeDrop: [],

    /**
     * @property {Function[]} - collection afterDrop callbacks
     */
    afterDrop: [],

    /**
     * @property {Boolean} canDragComponents - Can drag components in page or not
     * @default true
     */
    canDragComponents: true,

    /**
     * @type {'widget-row' | 'widget-column'} - Mode of Grid editor
     */
    _gridEditMode: null,

    /**
     *
     * @type {object} - Container widget data
     */
    _gridEditContainerNode: null,

    /**
     * @property {Ember.Service} _dragService - drag service, responsible for drag and drop operations
     * @private
     */
    _dragService: Ember.inject.service('drag'),

    /**
     * @type {StructureService} _structureService - structure service instance
     * @private
     */
    _structureService: Ember.inject.service('structure'),

    /**
     * @property {Ember.Service} _gridBuilderService - grid builder service
     * @private
     */
    _gridBuilderService: Ember.inject.service('grid-builder'),

    /**
     * @property {Object} _ruleResolver - builder/core/drop-rules/rule-resolver instance
     * @private
     */
    _ruleResolver: null,

    /**
     * @property {Object} _coordinator - builder/core/drop-coordinator instance
     * @private
     */
    _coordinator: null,

    /**
     * @property {Number} _minTouchDistance - if distance is less that this value, means drop should be performed
     * into parent higher in tree hierarchy
     * @private
     */
    _minTouchDistance: 15,

    /**
     * @property {Number} - _boostrapContainerPadding - padding of container + 1px of border
     * @private
     */
    _boostrapContainerPadding: 16,

    /**
     * @property {Boolean} _isDropToLayout - Flag to indicate whether drop happened to layout
     * @private
     */
    _isDropToLayout: false,

    /**
     * @property {Boolean} _isDragFromLayout - Flag to indicate whether drag happened from layout
     * @private
     */
    _isDragFromLayout: false,

    /**
     * @property {Boolean} _isDropToPage - Flag to indicate whether drop happened to page
     * @private
     */
    _isDropToPage: false,

    /**
     * @property {Boolean} _isDragFromPage - Flag to indicate whether drag happened from page
     * @private
     */
    _isDragFromPage: false,

    /**
     * @property {String[]} _draggables - list of draggable types
     * @private
     */
    _draggables: [_module.default.MODULE_CORE, _module.default.MODULE_FORM, _widget.WidgetType.WIDGET_PARAGRAPH, _widget.WidgetType.WIDGET_IMAGE, _widget.WidgetType.WIDGET_BUTTON, _widget.WidgetType.WIDGET_BUTTONSGROUP, _widget.WidgetType.WIDGET_SPACER, _widget.WidgetType.WIDGET_LINE_DIVIDER, _widget.WidgetType.WIDGET_SECTION, _container.default.WIDGET_TABS],

    /**
     * @property {Object} _dragServiceObject - drag service object, used for drag service
     * @private
     */
    _dragServiceObject: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      const me = this;

      this._super(...args);

      this.set('_ruleResolver', _ruleResolver.default.create({
        gridBuilder: this._gridBuilderService
      }));
      this.set('_coordinator', _dropCoordinator.default.create());
      this.set('_dragServiceObject', Ember.Object.create({
        getDragElement(node) {
          return node;
        },

        /**
         * Determine ability to drag current node.
         * Method called from 'drag' service.
         *
         * @param {jQuery} node - jQuery element
         * @returns {Boolean} - can drag element or not
        	 */
        canDrag(node) {
          var _component$component, _component$component$, _component$component$2;

          // Find component in page structure associated with node
          let {
            structure
          } = me;

          if (me.isGridEditMode()) {
            structure = me._gridEditContainerNode;
          }

          const component = me._structureService.findByNode(structure, node);

          if (!component) {
            return false;
          }

          const currentDeviceType = component === null || component === void 0 ? void 0 : (_component$component = component.component) === null || _component$component === void 0 ? void 0 : (_component$component$ = _component$component.device) === null || _component$component$ === void 0 ? void 0 : (_component$component$2 = _component$component$._device) === null || _component$component$2 === void 0 ? void 0 : _component$component$2.breakpoint;

          if (!me.isGridEditMode() && currentDeviceType !== _breakpointModes.LARGE) {
            const hasOrderValue = me.hasOrderedChildrenInContainer(component, currentDeviceType);

            if (hasOrderValue) {
              return false;
            }
          }

          return !!(component.draggable && (me.canDragComponents || me.isGridEditMode()));
        },

        onDragStart(node) {
          me.mouseDown(me.get('_structureService').findByNode(me.get('structure'), node));
        },

        onMouseMove(x, y) {
          me.mouseMove(x, y);
        },

        onMouseUp(dragServiceObject, node, x, y) {
          me.mouseUp(x, y);
        },

        onDragEnd() {
          me.set('draggableComponent', null);
        }

      }));
    },

    /**
     * Mousedown event handler. It also registers mousemove and mouseleave event handlers
     *
     * @method mouseDown
     * @param {Object} component - Core widget component instance
     */
    mouseDown(component) {
      this.updateAllDimensions();
      this.set('draggableComponent', component);

      if (!this.isGridEditMode()) {
        return;
      }

      this.dropHighlight._handleHighlightParentElement(component, true);
    },

    /**
     * Mouse move event handler
     *
     * @method mouseMove
     * @param {Number} x - event.pageX
     * @param {Number} y - event.pageY
     */
    mouseMove(x, y) {
      this._highlighDropPosition(x, y);
    },

    /**
     * Mouse up event handler
     *
     * @method mouseUp
     * @param {Number} x - event.pageX
     * @param {Number} y - event.pageY
     * @returns {*}
     */
    mouseUp(x, y) {
      var _this$draggableCompon;

      let draggable = ((_this$draggableCompon = this.draggableComponent) === null || _this$draggableCompon === void 0 ? void 0 : _this$draggableCompon.parent) || this.draggableComponent; // Move column, since most time columns will be moved with module, widget inside

      if (this.isGridEditMode()) {
        var _this$draggableCompon2;

        draggable = this.draggableComponent || ((_this$draggableCompon2 = this.draggableComponent) === null || _this$draggableCompon2 === void 0 ? void 0 : _this$draggableCompon2.parent);
      }

      let result = false;

      if (!draggable) {
        return result;
      }

      const {
        droppable,
        position,
        anchor
      } = this.getDropConfig(draggable, x, y);

      if (droppable && position && (!droppable.get('originObject.children').length || anchor)) {
        this.set('droppableComponent', droppable);
        this.setIsDropToLayout();
        this.setIsDragFromLayout();
        this.setIsDropToPage();
        this.setIsDragFromPage();
        this.beforeDrop.forEach(callback => callback());
        result = this.drop(droppable, draggable, position.position, anchor);

        if (result) {
          this.afterDrop.forEach(callback => callback());
        }
      } // Remove highlight


      this.dropHighlight.removeHighlight();

      this.dropHighlight._handleHighlightParentElement(draggable, false);

      return result;
    },

    /**
     * Check drop component ability
     * Like as method mouseUp without real insertion to structure
     * @param {number} x - x coordinate of drop
     * @param {number} y - y coordinate of drop
     * @returns {boolean}
     */
    canDrop(x, y) {
      var _this$draggableCompon3;

      let draggable = ((_this$draggableCompon3 = this.draggableComponent) === null || _this$draggableCompon3 === void 0 ? void 0 : _this$draggableCompon3.parent) || this.draggableComponent;

      if (this.isGridEditMode()) {
        var _this$draggableCompon4;

        draggable = this.draggableComponent || ((_this$draggableCompon4 = this.draggableComponent) === null || _this$draggableCompon4 === void 0 ? void 0 : _this$draggableCompon4.parent);
      }

      const {
        droppable,
        position,
        anchor
      } = this.getDropConfig(draggable, x, y);
      let result = false;

      if (droppable && position && (!droppable.get('originObject.children').length || anchor)) {
        const rule = this._ruleResolver.getRule(droppable.get('originObject').get('type'));

        if (draggable !== droppable && draggable !== anchor && rule) {
          result = rule.canDrop(droppable, draggable, position.position, anchor);
        }
      }

      return result;
    },

    /**
     * Drops draggable element inside the copy of the structure
     *
     * @method drop
     * @param {Object} droppable - Droppable object
     * @param {Object} draggable - draggable object
     * @param {String} direction - drop direction
     * @param {Object} anchor - next to which drop performed
     * @returns {Boolean} - if drop performed returns true, otherwise false
     */
    drop(droppable, draggable, direction, anchor) {
      const rule = this._ruleResolver.getRule(droppable.originObject.type); // Do not drop into self


      if (draggable !== droppable && draggable !== anchor && rule) {
        const sourceParent = draggable.parent;
        rule.drop(this._structureService, droppable, draggable, direction, anchor, this.isGridEditMode());

        if (!this.isGridEditMode()) {
          this._updateDraggableRecursively(this.structure);
        }

        this.refreshModules(sourceParent, droppable, [draggable]);
        return true;
      }

      return false;
    },

    /**
     * Updates index depending of direction.
     * If direction right or bottom need to increment index to insert after.
     * If direction left or top there is no need to change index.
     * TODO: redesign this function to stateless
     *
     * @method updateIndex
     * @param {Number} index - Current index
     * @param {String} direction - Insert direction
     * @returns {Number} Updated index
     */
    updateIndex(index, direction) {
      if (/right|bottom/.test(direction)) {
        // eslint-disable-next-line no-param-reassign
        return index += 1;
      }

      return index;
    },

    /**
     * Method applies mousedown event handler to draggable elements
     *
     * @method applyDraggable
     * @param {Object} object - Page structure element
     */
    applyDraggable(object) {
      const node = object.get('component').$();

      if (object.get('draggable')) {
        this._dragService.applyDraggable(node, this._dragServiceObject);
      } else {
        this._dragService.removeDraggable(node);
      }
    },

    /**
     * Update "component" property of drag and drop structure
     * Also applies mousedown for draggable components
     *
     * @param {Object} node - Reference to structure node
     * @param {Object} component - GridBaseComponent instance that represents passed in node
     */
    updateComponentReference(node, component) {
      const nodeReference = this._structureService.findNode(this.structure, node);

      if (nodeReference) {
        nodeReference.set('component', component);
        this.updateDimensions(nodeReference);
        this.applyDraggable(nodeReference);
      }
    },

    /**
     * Disables and enables draggable for components
     *
     * @method toggleDraggable
     * @param {Object} component - Component object instance
     * @param {Boolean} status - Draggable status
     */
    toggleDraggable(component, status) {
      const nodeReference = this._structureService.findNode(this.structure, component.data);

      if (nodeReference) {
        nodeReference.set('draggable', status);
        this.applyDraggable(nodeReference);
      }
    },

    /**
     * Update component x,y position and component width and height
     *
     * @method updateDimensions
     * @param {Object} item - structure item
     */
    updateDimensions(item) {
      const component = item.get('component');
      const element = component ? component.$() : null;

      if (element) {
        const offset = element.offset();
        item.setProperties({
          x: offset.left,
          y: offset.top,
          width: element.outerWidth(),
          height: element.outerHeight()
        });
      }
    },

    /**
     * Update component x,y position and component width and height
     */
    updateAllDimensions() {
      const {
        structure
      } = this;

      if (structure) {
        this._updateDimensionsRecursively(structure);
      }
    },

    /**
     * Update structure items property "draggable", set to true or false
     */
    setDraggableElements() {
      let node = this.structure;

      if (this.isGridEditMode()) {
        node = this._gridEditContainerNode;
      }

      this._updateDraggableRecursively(node);
    },

    /**
     * Get real drop target, target is droppable and drop allowed,
     * used to determine droppable by drop direction, same as _getRealDroppable
     *
     * @param {Object} droppable - immediate drop target
     * @param {Object} draggable - draggable component
     * @param {String} direction - drop direction
     * @returns {*}
     * @private
     */
    getDroppable(droppable, draggable, direction) {
      let subject = droppable === null || droppable === void 0 ? void 0 : droppable.parent;
      let dropTarget = null;
      let anchor = droppable;
      let rule; // Move up until real drop target is found or there is a container to drop in

      while (subject) {
        var _subject$originObject, _subject, _subject$originObject2, _subject2;

        const subjectType = (_subject$originObject = (_subject = subject) === null || _subject === void 0 ? void 0 : (_subject$originObject2 = _subject.originObject) === null || _subject$originObject2 === void 0 ? void 0 : _subject$originObject2.type) !== null && _subject$originObject !== void 0 ? _subject$originObject : null;

        if (!subjectType) {
          break;
        }

        rule = this._ruleResolver.getRule(subjectType);

        if (rule && rule.canDrop(subject, draggable, direction, anchor)) {
          dropTarget = subject;
          break;
        } // Go up


        anchor = subject;
        subject = (_subject2 = subject) === null || _subject2 === void 0 ? void 0 : _subject2.parent;
      }

      return {
        dropTarget,
        anchor
      };
    },

    /**
     * Get all modules from container, where draggable was taken and all modules where draggable was dropped,
     * then reload all found modules
     *
     * @param {Ember.Object} draggable - draggable component, all modules into this parent (of type widget-container)
     * will be refreshed
     * @param {Ember.Object} [droppable] - droppable component, all modules into this parent (of type widget-container)
     * will be refreshed
     * @param {Ember.Object[]} [notUpdatableModules=[]] - modules which not need to update
     * @returns {*}
     */
    refreshModules(draggable, droppable, notUpdatableModules = []) {
      const service = this._structureService;

      const sourceContainer = this._findParentWidgetContainer(draggable);

      const destinationContainer = this._findParentWidgetContainer(droppable);

      const currentDate = Date.now();
      let modules = [];

      if (sourceContainer) {
        modules = modules.concat(service.findModules(sourceContainer));
      } // When moved inside same parent, do not take into account destination


      if (destinationContainer && destinationContainer !== sourceContainer) {
        modules = modules.concat(service.findModules(destinationContainer));
      }

      modules.forEach(module => {
        if (notUpdatableModules.indexOf(module) === -1) {
          module.set('refresh', currentDate);
        }
      });
      return modules;
    },

    /**
     * Returns status of drop to layout
     * @returns {Boolean}
     */
    isDropToLayout() {
      return this._isDropToLayout;
    },

    /**
     * Returns status of drag from layout
     * @returns {Boolean}
     */
    isDragFromLayout() {
      return this._isDragFromLayout;
    },

    /**
     * Returns status of drop to page
     * @returns {Boolean}
     */
    isDropToPage() {
      return this._isDropToPage;
    },

    /**
     * Returns status of drag from page
     * @returns {Boolean}
     */
    isDragFromPage() {
      return this._isDragFromPage;
    },

    /**
     * Indicates whether drop happened to layout
     */
    setIsDropToLayout() {
      const droppable = this.droppableComponent;
      const structureService = this._structureService;
      this.set('_isDropToLayout', structureService.isType(droppable, _container.default.WIDGET_HEADER) || structureService.isType(droppable, _container.default.WIDGET_FOOTER) || !!structureService.findClosest(droppable, _container.default.WIDGET_HEADER) || !!structureService.findClosest(droppable, _container.default.WIDGET_FOOTER));
    },

    /**
     * Indicates whether drag happened from layout
     */
    setIsDragFromLayout() {
      const draggable = this.draggableComponent;
      const structureService = this._structureService;
      this.set('_isDragFromLayout', !!(structureService.findClosest(draggable, _container.default.WIDGET_HEADER) || structureService.findClosest(draggable, _container.default.WIDGET_FOOTER)));
    },

    /**
     * Indicates whether drop happened to page
     */
    setIsDropToPage() {
      const droppable = this.droppableComponent;
      const structureService = this._structureService;
      this.set('_isDropToPage', structureService.isType(droppable, _container.default.WIDGET_MAIN) || !!structureService.findClosest(droppable, _container.default.WIDGET_MAIN));
    },

    /**
     * Indicates whether drag happened from page
     */
    setIsDragFromPage() {
      const draggable = this.draggableComponent;
      const structureService = this._structureService;
      this.set('_isDragFromPage', !!structureService.findClosest(draggable, _container.default.WIDGET_MAIN));
    },

    /**
     * Actual "draggable" property update
     *
     * @param {Object} node - current structure item, which property should be updated
     * @private
     */
    _updateDraggableRecursively(node) {
      var _node$originObject;

      if (!node) {
        return;
      }

      const children = (node === null || node === void 0 ? void 0 : (_node$originObject = node.originObject) === null || _node$originObject === void 0 ? void 0 : _node$originObject.children) || [];
      node.set('draggable', this._isDraggable(node));

      for (let i = 0, len = children.length; i < len; i++) {
        this._updateDraggableRecursively(children[i]);
      }
    },

    /**
     * Recursive "updateDimensions" call
     *
     * @param {Object} node - parent node to update dimension
     * @private
     */
    _updateDimensionsRecursively(node) {
      const children = node.originObject.children || [];
      this.updateDimensions(node);

      for (let i = 0, len = children.length; i < len; i++) {
        this._updateDimensionsRecursively(children[i]);
      }
    },

    /**
     * Detect is passed in type is draggable or not
     *
     * @param {String} type - component type (core-module, widget-*)
     * @returns {Boolean} - true if component draggable, otherwise false
     * @private
     */
    _isDraggable(node) {
      if (!(node === null || node === void 0 ? void 0 : node.originObject)) {
        return false;
      }

      const {
        type
      } = node.originObject;
      const {
        parent
      } = node;

      if (this.isGridEditMode()) {
        // Draggable only if the column/row has sibilings to swap with
        return type === this._gridEditMode && parent.originObject.children.length > 1;
      }

      return this._draggables.indexOf(type) !== -1;
    },

    /**
     * @returns {Boolean} - true if the container is in grid edit mode
     */
    isGridEditMode() {
      return !!this._gridEditMode;
    },

    /**
     * @param {'widget-row'|'widget-column'} mode - Column edit or Row edit
     * @param {Object} node - Node data of the container
     */
    setActiveGridContainer(mode, node) {
      this.set('_gridEditMode', mode);
      this.set('_gridEditContainerNode', node);
    },

    hasOrderedChildrenInContainer(component, currentDeviceType) {
      var _component$parent;

      let subject = (_component$parent = component.parent) !== null && _component$parent !== void 0 ? _component$parent : null;
      let hasOrderValue = false;

      while (subject && !hasOrderValue) {
        var _subject3, _subject3$originObjec;

        const type = (_subject3 = subject) === null || _subject3 === void 0 ? void 0 : (_subject3$originObjec = _subject3.originObject) === null || _subject3$originObjec === void 0 ? void 0 : _subject3$originObjec.type;

        if (type && _container.default.WIDGET_CONTAINER === type) {
          subject = subject.parent;
          continue;
        }

        hasOrderValue = this._structureService.order.hasOrderedChildren(subject, currentDeviceType);
        subject = subject.parent;
      }

      return hasOrderValue;
    },

    /**
     * Get real drop target, target is droppable and drop allowed
     * TODO: Rename, should be get drop options
     *
     * @param {Object} droppable - immediate drop target
     * @param {Object} draggable - draggable component
     * @param {Number} x - event.pageX
     * @param {Number} y - event.pageY
     * @returns {Object}
     * @private
     */
    _getRealDroppable(droppable, draggable, x, y) {
      const structureService = this._structureService;
      const ruleResolver = this._ruleResolver;
      let subject = droppable;
      let dropTarget = null;
      let anchor, position, rule; // Move up until real drop target is found or there is a container to drop in

      while (subject) {
        rule = ruleResolver.getRule(subject.originObject.type);
        anchor = structureService.getPointedChild(subject, x, y);
        position = this._getDropPosition(anchor || droppable, x, y);
        let isDropAllowed = false;

        if (this.isGridEditMode()) {
          var _subject4, _subject4$component, _structureService$get, _structureService$get2;

          // limit the droppable range to current parent in Grid edit mode
          isDropAllowed = ((_subject4 = subject) === null || _subject4 === void 0 ? void 0 : (_subject4$component = _subject4.component) === null || _subject4$component === void 0 ? void 0 : _subject4$component.elementId) === ((_structureService$get = structureService.getParent(draggable)) === null || _structureService$get === void 0 ? void 0 : (_structureService$get2 = _structureService$get.component) === null || _structureService$get2 === void 0 ? void 0 : _structureService$get2.elementId);
        } else {
          isDropAllowed = true;
        }

        if (rule && isDropAllowed && rule.canDrop(subject, draggable, position.position, anchor)) {
          dropTarget = subject;
          break;
        } // Go up


        subject = subject.parent;
      }

      return {
        droppable: dropTarget,
        position
      };
    },

    /**
     * Highlight predicted drop position, if drop element found, otherwise dim
     *
     * @param {Number} x - event.pageX
     * @param {Number} y - event.pageY
     * @private
     */
    _highlighDropPosition(x, y) {
      var _this$draggableCompon5;

      const highlightService = this.dropHighlight;
      let draggable = ((_this$draggableCompon5 = this.draggableComponent) === null || _this$draggableCompon5 === void 0 ? void 0 : _this$draggableCompon5.parent) || this.draggableComponent;

      if (this.isGridEditMode()) {
        var _this$draggableCompon6;

        draggable = this.draggableComponent || ((_this$draggableCompon6 = this.draggableComponent) === null || _this$draggableCompon6 === void 0 ? void 0 : _this$draggableCompon6.parent);
      }

      const {
        droppable,
        anchor,
        position,
        ruleApplied
      } = this.getDropConfig(draggable, x, y);

      if (droppable && (!droppable.originObject.children.length || anchor)) {
        let styles = this._getHighlightStyles(anchor || droppable, anchor ? position.position : _direction.default.TOP);

        styles = this._adjustStylesToBootstrapStyles(styles, anchor || droppable, anchor ? position.position : _direction.default.TOP);
        highlightService.highlight(styles, ruleApplied);
      } else {
        highlightService.removeHighlight();
      }
    },

    /**
     * Generate highlight style for highlight service
     *
     * @param {Object} anchor - anchor structure
     * @param {String} side - highlight side
     * @returns {{left: Number, top: Number, display: String}}
     * @private
     */
    _getHighlightStyles(anchor, side) {
      var _this$_dragServiceObj, _this$_dragServiceObj2;

      const scrollShift = (_this$_dragServiceObj = (_this$_dragServiceObj2 = this._dragServiceObject) === null || _this$_dragServiceObj2 === void 0 ? void 0 : _this$_dragServiceObj2.scrollShift) !== null && _this$_dragServiceObj !== void 0 ? _this$_dragServiceObj : 0;
      const styles = {
        left: anchor.x,
        top: anchor.y - scrollShift,
        display: 'block'
      }; // To highlight bottom and right side we must move initial draw point by height or width

      if (_direction.default.BOTTOM === side) {
        styles.top += anchor.height;
      } else if (_direction.default.RIGHT === side) {
        styles.left += anchor.width;
      }

      if (_direction.default.BOTTOM === side || _direction.default.TOP === side) {
        styles.width = anchor.width;
      } else if (_direction.default.LEFT === side || _direction.default.RIGHT === side) {
        styles.height = anchor.height;
      }

      return styles;
    },

    /**
     * Adjust highlight position by taking into account bootstrap features
     *
     * @param {Object} styles - styles to adjust
     * @param {Ember.Object} anchor - anchor object, next to which component will be potentially dropped
     * @param {String} direction - direction of potential drop
     * @returns {*}
     * @private
     */
    _adjustStylesToBootstrapStyles(styles, anchor, direction) {
      const padding = this._boostrapContainerPadding;
      const {
        type
      } = anchor.originObject;

      if (type === _container.default.WIDGET_CONTAINER || type === _widget.WidgetType.WIDGET_SECTION || type === _widget.WidgetType.WIDGET_TAB_PANE || type === _container.default.WIDGET_COLUMN && this._isTheOnlyChildInContainer(anchor, _container.default.WIDGET_CONTAINER)) {
        styles.left += padding;

        if (_direction.default.RIGHT === direction) {
          styles.left -= padding * 2;
        }

        if (_direction.default.BOTTOM === direction || _direction.default.TOP === direction) {
          styles.width -= padding * 2;
        }
      }

      return styles;
    },

    /**
     * Test if child is the only child in ancestor chain, until it reaches specific type of ancestor
     *
     * @param {Ember.Object} child - child to test
     * @param {String} type - type of ancestor unit which to test
     * @returns {Boolean} - if child is the only child in ancestor chain, return true
     * @private
     */
    _isTheOnlyChildInContainer(child, type) {
      let {
        parent
      } = child;
      let onlyChild = true;

      while (parent && parent.get('originObject.type') !== type) {
        if (parent.get('originObject.children.length') > 1) {
          onlyChild = false;
          break;
        }

        parent = parent.get('parent');
      }

      return onlyChild;
    },

    /**
     * Get closest pointed position
     *
     * @param {Object} droppable - drop container structure
     * @param {Number} x - event.pageX
     * @param {Number} y - event.pageY
     * @returns {Object|null} - object with {distance, position} keys or null if not found
     * @private
     */
    _getDropPosition(droppable, x, y) {
      var _this$_dragServiceObj3, _this$_dragServiceObj4;

      const scrollShift = (_this$_dragServiceObj3 = (_this$_dragServiceObj4 = this._dragServiceObject) === null || _this$_dragServiceObj4 === void 0 ? void 0 : _this$_dragServiceObj4.scrollShift) !== null && _this$_dragServiceObj3 !== void 0 ? _this$_dragServiceObj3 : 0;
      const coordinator = this._coordinator;

      const sortedAxes = this._sortAxes(coordinator.getPositionX(droppable.get('width'), x - droppable.get('x')), coordinator.getPositionY(droppable.get('height'), y - droppable.get('y') + scrollShift)); // Closest axis


      return sortedAxes[0];
    },

    /**
     * Get droppable, anchor, position
     *
     * @param {Object} draggable - draggable structure
     * @param {Number} x - event.pageX
     * @param {Number} y - event.pageY
     * @returns {{droppable: *, position: *, anchor: *, ruleApplied: {Boolean}}} - droppable (page element to drop in),
     * position, anchor (page element to drop next)
     */
    getDropConfig(draggable, x, y) {
      const immediateDropTarget = this._structureService.getPointedNode(this.structure, x, y);

      let {
        droppable,
        position
      } = this._getRealDroppable(immediateDropTarget, draggable, x, y);

      let anchor = null;
      let ruleApplied = false;

      if (droppable) {
        anchor = this._structureService.getPointedChild(droppable, x, y);
      }

      const isDraggableContainer = this._structureService.isType(draggable, _container.default.WIDGET_CONTAINER); // When mouse moves closer than 15% of the width or height to the border
      // then drop should happened to upper container


      if (droppable && droppable.get('parent') && position && position.distance <= this._minTouchDistance) {
        // Just in case 15% applied but no droppables upper found
        const {
          droppable: newDroppable,
          position: newPosition
        } = this._getRealDroppable(droppable.get('parent'), draggable, x, y);

        if (newDroppable) {
          droppable = newDroppable;
          position = newPosition;
          anchor = this._structureService.getPointedChild(droppable, x, y);
          ruleApplied = true;
        } // If draggable is container then drop highlight always should be like 15% rule


        if (isDraggableContainer) {
          ruleApplied = true;
        } // If draggable is widget container then drop should happen only by 15% rule

      } else if (isDraggableContainer) {
        droppable = null;
      }

      return {
        droppable,
        position,
        anchor,
        ruleApplied
      };
    },

    /**
     * Sort axes by distance, closest comes 1-st
     * TODO: This function just compares distances, but uses it with sorting an array.
     * TODO: Move this comparison to function it calls
     *
     * @param {Object} axisX - x axis
     * @param {Object} axisY - y axis
     * @returns {Object[]} - sorted axes
     * @private
     */
    _sortAxes(axisX, axisY) {
      return [axisX, axisY].sort((x, y) => x.distance < y.distance ? -1 : 1);
    },

    /**
     * Find closes widget-container parent of passed in child
     *
     * @param {Ember.Object} child - structure item which parent to find
     * @returns {Ember.Object|null}
     * @private
     */
    _findParentWidgetContainer(child) {
      let subject = child ? child.get('parent') : null;
      let parent = null;

      while (subject && !parent) {
        var _subject5, _subject5$originObjec;

        const type = (_subject5 = subject) === null || _subject5 === void 0 ? void 0 : (_subject5$originObjec = _subject5.originObject) === null || _subject5$originObjec === void 0 ? void 0 : _subject5$originObjec.type;

        if (type && _container.default.WIDGET_CONTAINER === type) {
          parent = subject;
        }

        subject = subject.parent;
      }

      return parent;
    }

  });

  _exports.default = _default;
});