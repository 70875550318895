define("builder/pods/components/input-date/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const InputDateZIndex = 800;
  /**
   * TODO: datepicker show hide, should yield action up and allow upper entities to invoked those actions, but for now I don't
   * know how to do it, since to use yielded action, place where action used, must be inside of input-date component
   * scope
   *
   * @class InputDateComponent
   */

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: 'input',

    /**
     * @inheritdoc
     */
    attributeBindings: ['type', 'style', 'value'],

    /**
     * @inheritdoc
     */
    type: 'text',

    /**
     * @property {String|null} value - value to display in input field
     */
    value: null,

    /**
     * @property {String|Handlebars.SafeString} - this will fix datepicker zindex issue, since it takes zindex of input
     * datepicker binded to(onlt if it's position is relative) and in our popup we have z-index eq 700
     */
    style: Ember.String.htmlSafe(`z-index: ${InputDateZIndex}; position: relative`),

    /**
     * @property {String|null} minDate - minimal available date to select
     */
    minDate: null,

    /**
     * @property {String|null} maxDate - maximal available date to select
     */
    maxDate: null,

    /**
     * @property {Boolean} datepickerVisible - is datepicker visible, this property added exclusively since, for now
     * I don't know how to yield action outside of component scope
     */
    datepickerVisible: false,

    /**
     * @property {String} format - date format for datepicker
     */
    format: 'mm/dd/yy',

    /**
     * @property {String} mask - input mask to mask user input
     */
    mask: '00/00/0000',

    /**
     * Callback to be invoked when datepicker closes
     */
    onClose() {},

    /**
     * @inehritdoc
     */
    didReceiveAttrs() {
      this._toggleDatepicker();
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this._addDatepicker();
    },

    /**
     * Add datapicker
     *
     * @method _addDatepicker
     * @private
     */
    _addDatepicker() {
      const component = this;
      const input = component.$();
      input.datepicker({
        dateFormat: component.get('format'),

        onClose() {
          const date = input.datepicker('getDate');
          component.onClose(date); // This is bad, but how to avoid ember run?

          Ember.run(() => {
            component.set('datepickerVisible', false);
          });
        }

      }); // Mask for datepicker

      input.mask(this.mask);
    },

    /**
     * Toggle datepickers
     *
     * @method _toggleDatepicker
     * @private
     */
    _toggleDatepicker() {
      const input = this.$();
      const {
        minDate
      } = this;
      const {
        maxDate
      } = this;

      if (input && input.datepicker) {
        input.datepicker('option', 'minDate', minDate || null);
        input.datepicker('option', 'maxDate', maxDate || null);
        input.datepicker(this.datepickerVisible ? 'show' : 'hide');
      }
    },

    actions: {
      showDatepicker() {
        this.$().datepicker('show');
      }

    }
  });

  _exports.default = _default;
});