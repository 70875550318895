define("builder/pods/components/field-media/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_COMPONENT = _exports.IMAGE_COMPONENT = _exports.MEDIA_TYPE_DOCUMENT = _exports.MEDIA_TYPE_VIDEO = _exports.MEDIA_TYPE_IMAGE = void 0;
  const MEDIA_TYPE_IMAGE = 'image';
  _exports.MEDIA_TYPE_IMAGE = MEDIA_TYPE_IMAGE;
  const MEDIA_TYPE_VIDEO = 'video';
  _exports.MEDIA_TYPE_VIDEO = MEDIA_TYPE_VIDEO;
  const MEDIA_TYPE_DOCUMENT = 'document';
  _exports.MEDIA_TYPE_DOCUMENT = MEDIA_TYPE_DOCUMENT;
  const IMAGE_COMPONENT = 'field-media-active-area-image';
  _exports.IMAGE_COMPONENT = IMAGE_COMPONENT;
  const MEDIA_COMPONENT = 'field-media-active-area-video-document';
  _exports.MEDIA_COMPONENT = MEDIA_COMPONENT;
  const FIELD_IMAGE_ICON = 'fa fa-file-image-o';
  const FIELD_VIDEO_ICON = 'fa fa-file-video-o';
  const FIELD_DOCUMENT_ICON = 'fa fa-file-text-o';
  /**
   * @class FieldMedia
   */

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * Element class name.
     * @property
     */
    classNames: ['bld-form_media'],

    /**
     * @property {Boolean} useStylesManagementView - trigger to change view presentation
     */
    useStylesManagementView: false,

    /**
     * @property {Ember.Object} widget - widget currently editing
     */
    widget: null,

    /**
     * @property {String} mediaType - expected media type
     */
    mediaType: Ember.computed('field', 'field.validation.@each', function () {
      const validation = this.get('field.validation');
      const hasVideoResult = validation.hasOwnProperty('video') ? MEDIA_TYPE_VIDEO : MEDIA_TYPE_DOCUMENT;
      return validation.hasOwnProperty('image') ? MEDIA_TYPE_IMAGE : hasVideoResult;
    }),

    /**
     * @property {String} componentName - which component ui should be used
     */
    componentName: Ember.computed('mediaType', function () {
      return this.mediaType === MEDIA_TYPE_IMAGE ? IMAGE_COMPONENT : MEDIA_COMPONENT;
    }),

    /**
     * @property {String} iconClass - icon what presented media type
     */
    iconClass: Ember.computed('mediaType', function () {
      const type = this.mediaType;
      const isVideoResult = type === MEDIA_TYPE_VIDEO ? FIELD_VIDEO_ICON : FIELD_DOCUMENT_ICON;
      return type === MEDIA_TYPE_IMAGE ? FIELD_IMAGE_ICON : isVideoResult;
    }),

    /**
     * @property {String} refreshIconClass - icon what presented refresh icon
     */
    refreshIconClass: Ember.computed('mediaType', function () {
      const type = this.mediaType;
      return type === MEDIA_TYPE_IMAGE || type === MEDIA_TYPE_VIDEO ? 'fa-camera' : 'fa-refresh';
    }),

    /**
     * @property {String} fileName - url to file
     */
    fileName: Ember.computed('field.data.url', function () {
      const url = this.get('field.data.url');

      if (url) {
        return url.split('/').pop();
      }

      return '';
    }),

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    deleteExists(file) {
      if (file && file.url === this.get('field.data.url')) {
        this.send('delete');
      }
    },

    actions: {
      /**
       *
       * @param {Object|null} data - media properties to update
       */
      update(data) {
        const action = this.actionHandler;
        this.set('field.data', data);

        if (action && !this.notValid) {
          action(data, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});