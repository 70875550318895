define("builder/pods/website/edit/page/edit/module-gallery/route", ["exports", "builder/routes/application", "builder/core/enumerators/controller-action", "builder/config/environment"], function (_exports, _application, _controllerAction, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Service for feature toggling
     * @type {import('ember').Ember.Service}
     */
    features: Ember.inject.service('features'),

    /**
     * @type {import('ember').Ember.Service} modules - module store service
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * Property for save dynamic segment and then set it to controller
     * @type {null | string} dynamicRouteSegment - Property for save dynamic segment
     */
    dynamicRouteSegment: null,

    /**
     * @property {Ember.Service} configuration - configuration
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Storage for system modules
     * @type {object}
     */
    systemModules: [],

    /**
     * @inheritdoc
     */
    model(params) {
      const {
        website
      } = this.modelFor('website.edit');
      const {
        page,
        layout
      } = this.modelFor('website.edit.page.edit');
      this.dynamicRouteSegment = params.module_type || null;

      if (params.module_type !== 'system-modules' && !this.canAccessToModuleGallery(page)) {
        return this.transitionTo('website.edit.page.edit');
      }

      return Ember.RSVP.hash({
        website,
        page,
        layout,
        modules: this._getEnabledModules(),
        widgets: this.store.peekAll('widget'),
        themes: this.store.peekAll('module-theme'),
        categories: this.store.peekAll('module-category'),
        categoryItems: this.store.peekAll('module-category-item'),
        formTemplates: this.store.peekAll('form'),
        websiteSettings: this._getWebsiteSettings(),
        containerTemplateCategories: this.store.peekAll('container-template-category'),
        containerTemplates: this.store.peekAll('container-template')
      });
    },

    /**
     * @inheritdoc
     */
    afterModel(model) {
      const oemModulesAndThemes = this.filterOEMModules(model.website, model.modules, model.themes, model.systemModules);
      model.modules = oemModulesAndThemes.modules;
      model.themes = oemModulesAndThemes.themes;

      if (this.dynamicRouteSegment === 'system-modules') {
        const systemModules = this.store.peekAll('system-module');
        const systemThemes = this.getSystemThemes(model.themes);
        const systemModulesIds = this.getSystemModulesIds(systemThemes);
        const modules = this.getSystemModules(model.modules, systemModulesIds);
        this.createOrSetSystemModule(modules, systemModules, systemThemes);
        model.systemModules = this.getFilteredSystemModules(model.modules);
      }

      return model;
    },

    /**
     * Returns filtered system modules
     * @param {object[]} modules - List of modules
     * @returns {object[]}
     */
    getFilteredSystemModules(modules) {
      const modulesMap = {};
      modules.forEach(module => modulesMap[module.Name] = module);
      return this.store.peekAll('system-module').filter(module => modulesMap.hasOwnProperty(module.Module)).sortBy('isEnabled');
    },

    /**
     * Creates or sets system module for the further render
     * @param {ModuleModel[]} modules - List of modules
     * @param {ModuleModel[]} systemModules - List of system-module records
     * @param {ModuleThemeModel[]} systemThemes - List of system themes
     * @returns {void}
     */
    createOrSetSystemModule(modules, systemModules, systemThemes) {
      const language = this._getWebsiteSettings().Language || _environment.default.APP.DEFAULT_LOCALE;

      const systemModulesMap = {};
      systemModules.forEach(module => systemModulesMap[module.Module] = module);
      modules.forEach(module => {
        const systemModule = systemModulesMap[module.Name]; // If we don't find module in system-module records then create new system-module record for that module

        if (!systemModule) {
          this.createSystemModule(module, systemThemes, language);
        } else {
          systemModule.setProperties({
            module,
            language
          });
        }
      });
    },

    /**
     * Create new system-module record
     * @param {object} module - Module record
     * @param {ModuleThemeModel[]} systemThemes - List of system themes
     * @param {string} language - Website language
     */
    createSystemModule(module, systemThemes, language) {
      this.store.createRecord('system-module', {
        Module: module.Name,
        IsActive: 0,
        Theme: systemThemes.findBy('ModuleId', module.id).Name,
        module,
        language
      });
    },

    /**
     * Returns list of system modules
     * @param {ModuleModel[]} modules - List of modules
     * @param {string[]} systemModulesIds - List of system modules ids
     * @returns {ModuleModel[]}
     */
    getSystemModules(modules, systemModulesIds) {
      return modules.filter(module => systemModulesIds.includes(module.id));
    },

    /**
     * Create unique list of system module ids according to system themes
     * @param {ModuleThemeModel[]} systemThemes - List of system themes
     * @returns {string[]} List of system module ids
     */
    getSystemModulesIds(systemThemes) {
      const systemModulesIds = [];
      systemThemes.forEach(theme => {
        const moduleId = theme.get('ModuleId');

        if (!systemModulesIds.includes(moduleId)) {
          systemModulesIds.push(moduleId);
        }
      });
      return systemModulesIds;
    },

    /**
     * Filters all system themes
     * @param {ModuleThemeModel[]} themes - List of module themes
     * @returns {ModuleThemeModel[]}
     */
    getSystemThemes(themes) {
      return themes.filter(theme => theme.get('IsSystem') && theme.get('Layout') && theme.get('Layout').length);
    },

    /**
     * Checks theme visibility according to OEM/WebsiteGroup rules
     * @param {string[]} themeGroups - theme website groups
     * @param {string[]} themeOEMs - theme OEMs
     * @param {string[]} websiteOEMs - website OEMs
     * @param {string[]} websiteGroups - website groups
     * @returns {boolean}
     */
    isThemeVisible(themeGroups, themeOEMs, websiteOEMs, websiteGroups) {
      // Theme Website Group is not defined
      // and Theme OEMs is not defined
      if ((!themeGroups || !themeGroups.length) && (!themeOEMs || !themeOEMs.length)) {
        return true;
      } // Theme Website Group is not defined
      // and Theme OEMs intersect with Website OEMs


      if ((!themeGroups || !themeGroups.length) && !!themeOEMs && !!themeOEMs.length && themeOEMs.some(oem => websiteOEMs && websiteOEMs.includes(oem))) {
        return true;
      } // Website Group is equals Theme Website Group
      // and Theme OEMs intersect with Website OEMs


      if (websiteGroups && websiteGroups.length && websiteGroups.some(group => themeGroups && themeGroups.includes(group)) && themeOEMs && themeOEMs.length && themeOEMs.some(oem => websiteOEMs && websiteOEMs.includes(oem))) {
        return true;
      }

      if (websiteGroups && websiteGroups.some(group => themeGroups && themeGroups.includes(group)) && (!themeOEMs || !themeOEMs.length)) {
        return true;
      }

      return false;
    },

    /**
     * Filter OEM modules for display in gallery
     *
     * @param {WebsiteModel} website - website model
     * @param {ModuleModel[]} modules - module models
     * @param {ModuleThemeModel[]} themes - theme models
     * @returns {{
     * 	modules: ModuleModel[],
     * 	themes: ModuleThemeModel[]
     * }} - Object with filtered modules and themes stored in keys "modules" and "themes"
     */
    filterOEMModules(website, modules, themes) {
      const websiteGroups = website.WebsiteGroup;
      const websiteOEMs = website.OEM;
      const result = {
        modules: [],
        themes: []
      };
      themes.forEach(theme => {
        const themeGroups = theme.WebsiteGroups;
        const moduleId = theme.ModuleId;
        const themeOEMs = theme.OEM;
        const visibleInGallery = theme.VisibleInGallery === '1';
        const isVisible = this.isThemeVisible(themeGroups, themeOEMs, websiteOEMs, websiteGroups);

        if (!visibleInGallery || !isVisible) {
          return;
        }

        result.themes.push(theme);
        const themeModule = modules.findBy('id', moduleId);
        const resultModule = result.modules.find(module => module.id === moduleId);

        if (themeModule && !resultModule) {
          result.modules.push(themeModule);
        }
      });
      return result;
    },

    /**
     * @inheritdoc
     */
    resetController(controller, isExiting) {
      this._super();

      if (isExiting) {
        $.each(['categoriesLevel', 'modulesLevel', 'themesLevel', 'noResults'], (index, prop) => {
          controller.set(prop, prop === 'categoriesLevel' ? true : null);
        });
      }
    },

    /**
     * @inheritdoc
     */
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('routeDynamicSegment', this.dynamicRouteSegment);
      controller.set('systemModules', this.systemModules);
      controller.set('openedConfigId', this.controllerFor('website.edit.page.edit').get('moduleConfig'));
    },

    /**
     * @inheritdoc
     */
    renderTemplate(controller, model) {
      this.render({
        outlet: 'modules-gallery',
        into: 'website.edit.page',
        controller,
        model
      });
    },

    /**
     * Check access to route
     * @param {PageModel} page - page model
     * @returns {boolean}
     */
    canAccessToModuleGallery(page) {
      if (page.isChild) {
        return false;
      }

      if (page.isGlobal && !this.accessControl.can('manage-global page', page).isGranted) {
        return false;
      }

      return true;
    },

    /**
     * @inheritDoc
     */
    activate() {
      this.send('handleBubbleAction', _controllerAction.default.OPEN_MODULE_GALLERY);
    },

    /**
     * @inheritdoc
     */
    deactivate() {
      this.set('dynamicRouteSegment', null);
      this.set('systemModules', []);
      this.controller.set('routeDynamicSegment', null);
      this.controller.set('systemModules', []);
    },

    /**
     * Method wraps website config fetch workflow
     *
     * @returns {object} - cached module config
     * @private
     */
    _getWebsiteSettings() {
      const websiteEditParams = this.paramsFor('website.edit') || {};
      return this.store.peekRecord('website-setting', websiteEditParams.website_id);
    },

    /**
     * Filters modules according feature toggles
     * @returns {ModuleModel[]}
     */
    _getEnabledModules() {
      const modules = this.store.peekAll('module');

      if (this.features.isEnabled('release-code-fields')) {
        return modules;
      }

      const htmlEditModuleName = 'HTMLEditor';
      return modules.filter(module => module.Name !== htmlEditModuleName);
    },

    /**
     * @inheritdoc
     */
    actions: {
      setModuleConfigId(id) {
        this.controller.set('currentConfigId', id);
      },

      handleBubbleAction() {
        // Bubble action up to parent route
        return true;
      }

    }
  });

  _exports.default = _default;
});