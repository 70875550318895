define("builder/pods/components/resize-handler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "waKmc640",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"resizable-handler__highlighter\"],[8],[0,\" \"],[4,\"if\",[[24,[\"helpText\"]]],null,{\"statements\":[[0,\" \"],[7,\"div\",true],[10,\"class\",\"resizable-handler__resize_pixels\"],[8],[0,\" \"],[1,[22,\"helpText\"],false],[0,\" \"],[9],[0,\" \"]],\"parameters\":[]},null],[0,\" \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/resize-handler/template.hbs"
    }
  });

  _exports.default = _default;
});