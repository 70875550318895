define("builder/services/bootstrap", ["exports", "builder/core/enumerators/container", "builder/core/enumerators/breakpoint-modes"], function (_exports, _container, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class BootstrapService
   */
  var _default = Ember.Service.extend({
    /**
     * Max number of columns into row
     * @type {number}
     * @readonly
     */
    maxColumns: Ember.computed(function () {
      return 12;
    }).readOnly(),

    /**
     * Structure service
     * @type {StructureService}
     * @private
     */
    _structure: Ember.inject.service('structure'),

    /**
     * Adjusts columns width inside row on column add into row
     * @param {Object} row - row node which columns width should be fixed
     * @param {Object} column - added column into row
     * @returns {void}
     */
    adjustColumnsWidthOnAdd(row, column) {
      this.adjustWidthOnAdd(row, column, _breakpointModes.LARGE);
      this.adjustWidthOnAdd(row, column, _breakpointModes.MEDIUM);
      this.adjustSmWidthOnAdd(row, column);
      this.adjustXsWidthOnAdd(row, column);
    },

    adjustWidthOnAdd(row, column, mode) {
      var _row$originObject;

      const {
        _structure
      } = this;
      const columns = (((_row$originObject = row.originObject) === null || _row$originObject === void 0 ? void 0 : _row$originObject.children) || []).filter(col => this.isColumnVisible(col, mode));
      let addedColumnWidth = columns.length - 1 ? 0 : this.maxColumns;
      let width;

      for (let i = 0, len = columns.length; i < len; i++) {
        // Skip sizing of added column
        if (columns[i] === column) {
          continue;
        }

        width = _structure.getColumnWidth(columns[i], mode);

        if (width > 1) {
          const rest = Math.max(1, Math.floor(width / len));

          _structure.setColumnWidth(columns[i], width - rest, mode);

          addedColumnWidth += rest;
        }
      }

      if (addedColumnWidth) {
        _structure.setColumnWidth(column, addedColumnWidth, mode);
      }
    },

    /**
     * Shrinks width of sibling columns to add width for added one in SM mode
     * @param {Object} row - row node which columns width should be fixed
     * @param {Object} column - added column into row
     * @returns {void}
     */
    adjustSmWidthOnAdd(row, column) {
      var _row$originObject2, _row$component, _row$component$device, _row$component$device2;

      const {
        _structure
      } = this;
      const columns = (((_row$originObject2 = row.originObject) === null || _row$originObject2 === void 0 ? void 0 : _row$originObject2.children) || []).filter(col => this.isColumnVisible(col, _breakpointModes.SMALL)); // Number of iterations to adjust columns two of two

      const length = parseInt(columns.length / 2, 10); // Counter for columns index for each iteration

      let counter = 0; // Whether columns are nested

      const rowParent = row.parent;
      const addedColumnIndex = columns.indexOf(column);
      const isNested = rowParent && rowParent.originObject.type === _container.default.WIDGET_COLUMN;
      let firstColWidth, secondColWidth; // All nested columns should be 100% width

      if (isNested) {
        for (let c = 0, len = columns.length; c < len; c++) {
          _structure.setColumnWidth(columns[c], this.maxColumns, _breakpointModes.SMALL);
        }

        return;
      }

      const currentBreakpoint = row === null || row === void 0 ? void 0 : (_row$component = row.component) === null || _row$component === void 0 ? void 0 : (_row$component$device = _row$component.device) === null || _row$component$device === void 0 ? void 0 : (_row$component$device2 = _row$component$device._device) === null || _row$component$device2 === void 0 ? void 0 : _row$component$device2.breakpoint;

      if (currentBreakpoint === _breakpointModes.SMALL && columns.length <= 3) {
        columns.forEach(col => {
          _structure.setColumnWidth(col, this.maxColumns / columns.length, _breakpointModes.SMALL);
        });
        return;
      }

      for (let i = 0; i < length; i++) {
        // Looking for added column in current iteration
        if (addedColumnIndex === counter || addedColumnIndex === counter + 1) {
          // Determine whether added column even or odd
          const evenOdd = addedColumnIndex % 2;
          const siblingIndex = evenOdd ? counter : counter + 1;

          const siblingWidth = _structure.getColumnWidth(columns[siblingIndex], _breakpointModes.SMALL);

          if (siblingWidth === this.maxColumns) {
            _structure.setColumnWidth(column, this.maxColumns / 2, _breakpointModes.SMALL);

            _structure.setColumnWidth(columns[siblingIndex], this.maxColumns / 2, _breakpointModes.SMALL);
          } else {
            _structure.setColumnWidth(column, this.maxColumns - siblingWidth, _breakpointModes.SMALL);
          }

          counter += 2; // If added column is in current iteration it means we have adjusted it and its sibling and can go on

          continue;
        }

        firstColWidth = _structure.getColumnWidth(columns[counter], _breakpointModes.SMALL);
        secondColWidth = _structure.getColumnWidth(columns[counter + 1], _breakpointModes.SMALL); // When user adds new column he can break columns adjusted two of two
        // Need to fix it by setting max. 12 columns width in each two of two

        if (firstColWidth + secondColWidth > this.maxColumns && (firstColWidth !== this.maxColumns || secondColWidth !== this.maxColumns)) {
          if (firstColWidth > secondColWidth) {
            _structure.setColumnWidth(columns[counter], this.maxColumns - secondColWidth, _breakpointModes.SMALL);
          } else {
            _structure.setColumnWidth(columns[counter + 1], this.maxColumns - firstColWidth, _breakpointModes.SMALL);
          }
        } else if (firstColWidth + secondColWidth < this.maxColumns) {
          if (firstColWidth < secondColWidth) {
            _structure.setColumnWidth(columns[counter], this.maxColumns - secondColWidth, _breakpointModes.SMALL);
          } else {
            _structure.setColumnWidth(columns[counter + 1], this.maxColumns - firstColWidth, _breakpointModes.SMALL);
          }
        }

        counter += 2;
      } // Adjust last column which is not in set two of two


      if (columns.length && columns.length % 2) {
        _structure.setColumnWidth(columns[counter], this.maxColumns, _breakpointModes.SMALL);
      }
    },

    /**
     * Shrinks width of sibling columns to add width for added one in XS mode
     * @param {Object} row - row node which columns width should be fixed
     * @param {Object} column - added column into row
     * @returns {void}
     */
    adjustXsWidthOnAdd(row, column) {
      var _row$originObject3, _row$component2, _row$component2$devic, _row$component2$devic2;

      const {
        _structure
      } = this;
      const columns = (((_row$originObject3 = row.originObject) === null || _row$originObject3 === void 0 ? void 0 : _row$originObject3.children) || []).filter(col => this.isColumnVisible(col, _breakpointModes.EXTRA_SMALL));
      const addedColumnIndex = columns.indexOf(column);
      const prevColumn = columns[addedColumnIndex - 1];
      const nextColumn = columns[addedColumnIndex + 1];

      const prevColumnWidth = prevColumn && _structure.getColumnWidth(prevColumn, _breakpointModes.EXTRA_SMALL);

      const nextColumnWidth = nextColumn && _structure.getColumnWidth(nextColumn, _breakpointModes.EXTRA_SMALL);

      const currentBreakpoint = row === null || row === void 0 ? void 0 : (_row$component2 = row.component) === null || _row$component2 === void 0 ? void 0 : (_row$component2$devic = _row$component2.device) === null || _row$component2$devic === void 0 ? void 0 : (_row$component2$devic2 = _row$component2$devic._device) === null || _row$component2$devic2 === void 0 ? void 0 : _row$component2$devic2.breakpoint; // Allow to have two xs-6 cols in a row in mobile view

      if (columns.length === 2 && currentBreakpoint === _breakpointModes.EXTRA_SMALL) {
        columns.forEach(col => {
          _structure.setColumnWidth(col, this.maxColumns / columns.length, _breakpointModes.EXTRA_SMALL);
        });
        return;
      }

      if (!nextColumn || !prevColumn || nextColumnWidth === this.maxColumns || prevColumnWidth === this.maxColumns) {
        _structure.setColumnWidth(column, this.maxColumns, _breakpointModes.EXTRA_SMALL);

        return;
      }

      _structure.setColumnWidth(column, this.maxColumns - prevColumnWidth, _breakpointModes.EXTRA_SMALL);

      _structure.setColumnWidth(nextColumn, this.maxColumns, _breakpointModes.EXTRA_SMALL);
    },

    /**
     * Extends width of sibling columns according removed column width
     * @param {Object} row - row node which columns width should be fixed
     * @param {Object} column - removed column
     * @param {number} index - index of removed column
     * @returns {void}
     */
    adjustColumnWidthOnRemove(row, column, index) {
      this.adjustLgMdWidthOnRemove(row, column, _breakpointModes.LARGE, index);
      this.adjustLgMdWidthOnRemove(row, column, _breakpointModes.MEDIUM, index);
      this.adjustSmXsWidthOnRemove(row, column, _breakpointModes.SMALL, index);
      this.adjustSmXsWidthOnRemove(row, column, _breakpointModes.EXTRA_SMALL, index);
    },

    /**
     * Adjusts small and extra small columns widths on remove
     * @param {Object} row - row node which columns width should be fixed
     * @param {Object} column - removed column
     * @param {string} mode - passed screen mode
     * @param {number} index - removed column index
     * @returns {void}
     */
    adjustSmXsWidthOnRemove(row, column, mode, index) {
      var _row$originObject4;

      const {
        _structure
      } = this;
      const columns = (((_row$originObject4 = row.originObject) === null || _row$originObject4 === void 0 ? void 0 : _row$originObject4.children) || []).filter(col => this.isColumnVisible(col, mode));
      const {
        length
      } = columns;

      if (_structure.getColumnWidth(column, mode) === this.maxColumns) {
        return;
      }

      for (let i = 0; i < length; i++) {
        if (_structure.getColumnWidth(columns[i], mode) === this.maxColumns) {
          continue;
        } else {
          if (i === index - 1 || i === index) {
            _structure.setColumnWidth(columns[i], this.maxColumns, mode);
          }

          i += 1;
        }
      }
    },

    /**
     * Adjusts large and medium columns widths on remove
     * @param {Object} row - row node which columns width should be fixed
     * @param {Object} column - removed column
     * @param {string} mode - passed screen mode
     * @private
     */
    adjustLgMdWidthOnRemove(row, column, mode) {
      const columns = row.originObject.children || []; // If row have no a columns then do nothing

      if (!columns.length) {
        return;
      }

      const visibleColumns = columns.filter(col => this.isColumnVisible(col, mode)); // If row have no a visible columns then do nothing

      if (visibleColumns.length === 0) {
        return;
      }

      const removedColumnWidth = this._structure.getColumnWidth(column, mode);

      const add = Math.floor(removedColumnWidth / visibleColumns.length);
      const rest = removedColumnWidth % visibleColumns.length;
      const visibleColumnModels = visibleColumns.map(col => ({
        ref: col,
        width: this._structure.getColumnWidth(col, mode)
      }));
      visibleColumnModels.forEach(columnModel => {
        columnModel.width += add;
      });

      if (rest) {
        visibleColumnModels[visibleColumnModels.length - 1].width += rest;
      } // Check total width


      const totalVisibleColumnsWidthAfter = visibleColumnModels.reduce((columnModel, sum) => sum + columnModel.width, 0);

      if (totalVisibleColumnsWidthAfter > this.maxColumns || totalVisibleColumnsWidthAfter < this.maxColumns) {
        throw new Error(`Total columns width is ${totalVisibleColumnsWidthAfter} more then ${this.maxColumns}`);
      }

      if (totalVisibleColumnsWidthAfter < this.maxColumns) {
        throw new Error(`Total columns width is ${totalVisibleColumnsWidthAfter} less then ${this.maxColumns}`);
      } // Apply new widths to visible columns


      visibleColumnModels.forEach(columnModel => this._structure.setColumnWidth(columnModel.ref, columnModel.width, mode));
    },

    /**
     * Check column is visible
     * @param {Object} column - column
     * @param {string} mode - passed screen mode
     * @return {boolean}
     */
    isColumnVisible(column, mode) {
      var _column$originObject;

      return !!(column === null || column === void 0 ? void 0 : (_column$originObject = column.originObject) === null || _column$originObject === void 0 ? void 0 : _column$originObject.visibleOnDevices[mode]);
    },

    /**
     * Get columns sum of sizes
     * @param {Object[]} columns - columns
     * @param {Object} mode
     * @param {boolean} [onlyVisible=false] - calculate only visible columns
     * @returns {number} - sum of column sizes
     */
    sumSizes(columns, mode, onlyVisible = false) {
      const {
        _structure
      } = this;
      return columns.reduce((sum, column) => {
        if (onlyVisible && this.isColumnVisible(column, mode)) {
          return sum;
        }

        return sum + _structure.getColumnWidth(column, mode);
      }, 0);
    }

  });

  _exports.default = _default;
});