define("builder/core/visibility-filters/choices-filter", ["exports", "builder/core/visibility-filters/statement-check"], function (_exports, _statementCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterVisibleChoices = filterVisibleChoices;
  _exports.filterVisibleVariants = filterVisibleVariants;

  /**
   * Filters field choices array according provided filters
   * @param {FieldConfigurationNode[]} configurationMap
   * @param {FieldConfiguration} fieldConfiguration
   * @returns {DataSourceItem[]}
   */
  function filterVisibleChoices(configurationMap, fieldConfiguration) {
    const {
      choices,
      choiceFilters,
      path
    } = fieldConfiguration;

    if (!choices || !choices.length) {
      return [];
    }

    if (!choiceFilters || !choiceFilters.length) {
      // no choice filters provided, all choices should be visible
      return choices;
    }

    return choices.filter(choice => {
      if (!choice.tag || choice.tag === '') {
        // choices without tag should always be visible
        return true;
      }

      const applicableFilters = choiceFilters.filter(choiceFilter => choiceFilter.tags.includes(choice.tag));
      const result = applicableFilters.some(statement => (0, _statementCheck.isConfigurationStatementTrue)(configurationMap, statement, path));
      return result;
    });
  }
  /**
   * Filters form variants array according provided filters
   * @param {FieldConfigurationNode[]} configurationMap
   * @param {FieldConfiguration} fieldConfiguration
   * @returns {DataSourceItem[]}
   */


  function filterVisibleVariants(configurationMap, fieldConfiguration) {
    const {
      children,
      choiceFilters,
      path
    } = fieldConfiguration;

    if (!children || !children.length) {
      return [];
    }

    if (!choiceFilters || !choiceFilters.length) {
      // no choice filters provided, all choices should be visible
      return children;
    }

    return children.filter(choice => {
      if (!choice.tag || choice.tag === '') {
        // choices without tag should always be visible
        return true;
      }

      const applicableFilters = choiceFilters.filter(choiceFilter => choiceFilter.tags.includes(choice.tag));
      return applicableFilters.some(statement => (0, _statementCheck.isConfigurationStatementTrue)(configurationMap, statement, path));
    });
  }
});