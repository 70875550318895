define("builder/pods/components/filter-property/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e9SRlU9m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bld-filter-property__operator\"],[8],[0,\"\\n\\t\"],[1,[28,\"filter-operator\",null,[[\"label\",\"expression\",\"operators\",\"setOperator\",\"updateLeftOperand\",\"updateRightOperand\",\"updateOperator\",\"triggerOnRender\"],[[24,[\"label\"]],[24,[\"property\",\"expression\"]],[24,[\"operators\"]],[28,\"action\",[[23,0,[]],\"handleSetOperatorAction\"],null],[24,[\"updateLeftOperand\"]],[28,\"action\",[[23,0,[]],\"handleRightOperandAction\"],null],[28,\"action\",[[23,0,[]],\"handleOperatorAction\"],null],[24,[\"property\",\"triggerOnRender\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-filter-property__remove-button\"],[8],[0,\"\\n\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-times-circle bld-filter-property__remove-icon\"],[3,\"action\",[[23,0,[]],\"removeProperty\"]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/filter-property/template.hbs"
    }
  });

  _exports.default = _default;
});