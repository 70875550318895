define("builder/services/form-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormPreviewService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {Ember.Service} previewService - preview service, that provides urls for everything
     */
    previewService: Ember.inject.service('preview'),

    /**
     * @property {AjaxService} ajaxService - ajax service.
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Get form template(raw html representation of passed in structure)
     *
     * @param {String|Number} websiteId - id of website
     * @param {String|Number} theme - form theme
     * @param {Number} moduleId - module id for which template will be requested
     * @param {Object[]} structure - form structure to generate html from
     * @returns {Promise}
     */
    getTemplate(websiteId, theme, moduleId, structure = []) {
      const url = this.previewService.get('url'); // Use http://preview.e6.dealerfire.com/form when Lambda team will done their part of work
      // @todo move this url to core/preview-service.js

      return this.ajaxService.request(`${url}/form`, {
        method: 'POST',
        data: {
          websiteId,
          theme,
          structure: JSON.stringify(structure),
          moduleId
        },
        dataType: 'json'
      });
    }

  });

  _exports.default = _default;
});