define("builder/pods/website/index-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tpNCISne",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"background-color: transparent; height: 600px;\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"style\",[28,\"html-format\",[\"margin-top: 300px; text-align: center\"],null]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-refresh fa-spin\"],[10,\"style\",\"color: #478a91; font-size: 40px; margin: 50px auto;\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/index-loading/template.hbs"
    }
  });

  _exports.default = _default;
});