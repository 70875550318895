define("builder/pods/components/field-rangeinput/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @property {String[]}
     */
    classNames: ['bld-form_rangeinput'],

    /**
     * @property {String}
     */
    labelMin: 'Min.',

    /**
     * @property {String}
     */
    labelMax: 'Max.',

    /**
     * @property {Function}
     */
    actionHandler: null,

    /**
     * @property {Object}
     */
    field: {},

    /**
     * @property {String}
     */
    min: '',

    /**
     * @property {String}
     */
    max: '',

    /**
     * Component hook
     */
    init(...args) {
      this._super(...args);

      this.initialize();
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      if (this.triggerOnRender && this.get('field.data')) {
        this.onChange();
      }
    },

    /**
     * @inheritDoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * Since data property is array, it's zero element is min and 1-st element is max
     */
    initialize() {
      const data = this.get('field.data');

      if (!Ember.isEmpty(data)) {
        this.set('min', this.formatValue(data[0]));
        this.set('max', this.formatValue(data[1]));
      }
    },

    /**
     * Get component data, integer validation is not applied
     * cause we assume that this method will be invoked after
     * formatValues method call
     *
     * @returns {Object} - {min: Number, max: Number}
     */
    getData() {
      let {
        min
      } = this;
      let {
        max
      } = this;

      if (!this.isNumericValue(min)) {
        min = null;
      }

      if (!this.isNumericValue(max)) {
        max = null;
      }

      if (min === null && max === null) {
        return null;
      }

      return [min, max];
    },

    /**
     * Check is key pressed by user allowed or not
     *
     * @param {Element} input - input element
     * @param {Number} key - key code
     * @returns {Boolean}
     */
    isAllowedKey(input, key) {
      const keyValue = String.fromCharCode(key); // 0 key code for firefox - system key pressed, other browsers doesn't send system key press event

      if (key === 0 || key === 8) {
        return true;
      } // Minus sign allowed only at the beginning


      if (keyValue === '-' && input && input.selectionStart === 0) {
        return true;
      }

      if (!isFinite(parseInt(keyValue, 10))) {
        return false;
      }

      return true;
    },

    /**
     * Check is value numeric
     *
     * @param {mixed} value - value to check
     * @returns {Boolean} - numeric string or not
     */
    isNumericValue(value) {
      if (typeof value === 'number' || isFinite(parseInt(value, 10))) {
        return true;
      }

      return false;
    },

    /**
     * Format value from string to integer or
     * if value is not numeric - should return empty string
     *
     * @param {mixed} value - value to format
     * @returns {String|Number} - string if not numeric string passed and integer value if numeric string passed
     */
    formatValue(value) {
      if (!this.isNumericValue(value)) {
        return '';
      }

      return parseInt(value, 10);
    },

    /**
     * Run validations for specified fields
     */
    formatValues() {
      this.set('min', this.formatValue(this.min));
      this.set('max', this.formatValue(this.max));
    },

    /**
     * Min must not be greater than max
     * probably will be removed to let validation do it's job
     *
     * Observers not used to prevent property sync spamming
     * @returns {*}
     */
    applyConstraints() {
      const min = parseInt(this.min, 10);
      const max = parseInt(this.max, 10);

      if (!isFinite(min) || !isFinite(max)) {
        return;
      }

      if (min > max) {
        this.set('min', max);
      }
    },

    /**
     * Change event handler
     */
    onChange() {
      const action = this.actionHandler;
      this.formatValues();
      this.applyConstraints();
      const data = this.getData();

      if (action && !this.notValid) {
        action(data, this.get('field.name'));
      }
    },

    /**
     * List of actions available in template
     */
    actions: {
      /**
       * Change
       */
      change() {
        this.onChange();
      },

      /**
       * Prevent typing non integer values
       *
       * @param {Event} event - jQuery event
       */
      filter(event) {
        // FF event.which is 0 in case of special character press
        const key = isFinite(event.which) ? event.which : event.which || event.keyCode;

        if (!this.isAllowedKey(event.target, key)) {
          event.preventDefault();
          event.stopPropagation();
        }
      }

    }
  });

  _exports.default = _default;
});