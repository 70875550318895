define("builder/pods/components/widget-youtube-video-background/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N24JgBGe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"id\",[22,\"videoPlayerId\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-youtube-video-background/template.hbs"
    }
  });

  _exports.default = _default;
});