define("builder/pods/components/widget-popup/component", ["exports", "jquery", "builder/core/enumerators/keyboard"], function (_exports, _jquery, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['widget-popup'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isFixed:widget-popup_fixed', 'isDraggable:widget-popup_draggable', 'isFixedCentered:widget-popup_centered'],

    /**
     * @inheritdoc
     */
    attributeBindings: ['popupStylesCSS:style'],

    /**
     * @property {boolean} isDraggable - can we drag popup or not
     */
    isDraggable: false,

    /**
     * @property {boolean} isFixed - applied class with fixed position
     */
    isFixed: false,

    /**
     * @property {boolean} isCenteredFixed- applied class with fixed position and centered it vertically and horizontally
     */
    isFixedCentered: false,

    /**
     * @property {String|null} customTop - custom top position for popup
     */
    customTop: null,

    /**
     * @property {String|null} customHeight - custom height for popup
     */
    customHeight: null,

    /**
     * @property {String|null} customLeft - custom left position for popup
     */
    customLeft: null,

    /**
     * @property {Function|null} closeHandler - initialize closeHandler method
     */
    closeHandler: null,

    /**
     * @property {String} popupStylesCSS - style css for popup
     */
    popupStylesCSS: Ember.computed('_popupStyles.{top,right,left,height}', function () {
      let result = '';
      const styles = this._popupStyles;

      if (styles) {
        Object.keys(styles).forEach(item => {
          const value = styles[item];

          if (value) {
            result += `${item}:${value};`;
          }
        });
      }

      return Ember.String.htmlSafe(result);
    }),

    /**
     * @property {boolean} hasCloseHandler - has close handler or not
     */
    hasCloseHandler: Ember.computed('closeHandler', function () {
      return typeof this.closeHandler === 'function';
    }),

    /**
     * @property {Object|null} _popupStyles - styles for popup
     * @private
     */
    _popupStyles: null,

    /**
     * @property {Object|null} _isHeightToBottomOfWindow - need set height to bottom of window or not
     * @private
     */
    _isHeightToBottomOfWindow: false,

    /**
     * @property {Object|null} _resizeTimer - timer for debounce call resize
     * @private
     */
    _resizeTimer: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args); // Reset popup styles


      this.set('_popupStyles', Ember.Object.create());
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const top = this.customTop;
      const left = this.customLeft;
      const height = this.customHeight;
      const styles = this._popupStyles;

      if (top) {
        styles.set('top', top);
      }

      if (left) {
        styles.set('left', left);
      }

      if (height) {
        styles.set('height', height);
      } else if (this.isDraggable) {
        const popupStyles = this._popupStyles;
        const customTop = parseInt(this.customTop || this.$().css('top'), 10) || 0;
        this.set('_isHeightToBottomOfWindow', true);
        popupStyles.set('height', `${document.documentElement.clientHeight - customTop}px`);
      }

      this._bindEvents();
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      this._unbindEvents();
    },

    /**
     * Calculate and set popup position after resize
     * @method _resizePopupPosition
     * @private
     */
    _resizePopupPosition() {
      if (this._isHeightToBottomOfWindow) {
        const styles = this._popupStyles;
        const left = this.customLeft;
        const top = parseInt(this.customTop || this.$().css('top'), 10) || 0;
        styles.set('height', `${document.documentElement.clientHeight - top}px`);
        styles.set('top', top ? `${top}px` : '');
        styles.set('left', left || '');
        styles.set('right', '');
      }
    },

    /**
     * Add handlers for close, drag and resize.
     * @method _bindEvents
     * @private
     */
    _bindEvents() {
      const {
        elementId
      } = this;

      if (this.hasCloseHandler) {
        (0, _jquery.default)(document).on(`keyup.${elementId}`, event => {
          if (event.keyCode === _keyboard.default.KEY.ESCAPE) {
            this._callCloseHandler();
          }
        });
      }

      if (this.isDraggable) {
        this.$().draggable({
          handle: '.widget-popup__handle',
          axis: 'xy',
          containment: 'parent',

          // drag event will change Top postion in time of drag a config popup
          drag(event, ui) {
            let newTop = ui.position.top;
            const minTopPosition = (0, _jquery.default)('.header').outerHeight() + (0, _jquery.default)('.page_edit_tools').outerHeight();
            const maxTopPosition = (0, _jquery.default)(window).height() - minTopPosition;

            if (ui.position.top > maxTopPosition) {
              newTop = this.customTop;
            } else if (ui.position.top < minTopPosition) {
              newTop = minTopPosition;
            }

            ui.position.top = newTop;
          }

        });
        (0, _jquery.default)(window).on(`resize.${elementId}`, Ember.run.bind(this, function () {
          this.set('_resizeTimer', Ember.run.debounce(this, this._resizePopupPosition, 200));
        }));
      }
    },

    /**
     * Remove handlers and reset timer for resize.
     * @method _unbindEvents
     * @private
     */
    _unbindEvents() {
      const {
        elementId
      } = this;

      if (this.hasCloseHandler) {
        (0, _jquery.default)(document).off(`keyup.${elementId}`);
      }

      if (this.isDraggable) {
        (0, _jquery.default)(window).off(`resize.${elementId}`);
        Ember.run.cancel(this._resizeTimer);
      }
    },

    /**
     * Method call closeHandler if it exist
     * @method _callCloseHandler
     * @private
     */
    _callCloseHandler() {
      if (this.hasCloseHandler) {
        this.closeHandler();
      }
    },

    actions: {
      /**
       * Action call closeHandler after click in close button
       * @method close
       */
      closePopup() {
        this._callCloseHandler();
      }

    }
  });

  _exports.default = _default;
});