define("builder/core/meta-tags/dom-manipulation/element-meta", ["exports", "builder/core/meta-tags/dom-manipulation/tag-constants"], function (_exports, _tagConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createMetaTagElement = createMetaTagElement;
  _exports.handleMetaTagRemoveButtonClick = handleMetaTagRemoveButtonClick;

  /**
   * Wraps meta tag with span
   * @param {string} metaTag - Meta tag with {{}}
   * @returns {HTMLElement}
   */
  function createMetaTagElement(metaTag) {
    const element = createMetaDOMElement(metaTag);
    const closeButton = createCloseButton();
    const metaName = metaTag.match(/{{(.+?)}}/)[1];
    element.textContent = beautifyMeta(metaName);
    element.appendChild(closeButton);
    return element;
  }
  /**
   * Detects that click appeared on meta close button element
   * @param {MouseEvent} event - click event
   * @param {()=>void} callback - function to call on click
   */


  function handleMetaTagRemoveButtonClick(event, callback) {
    const {
      target: {
        classList
      }
    } = event;

    if (classList.contains(_tagConstants.MetaCloseButtonTag.className)) {
      const {
        parentNode
      } = event.target;
      parentNode.remove();
      callback();
    }
  }
  /**
   * Creates meta HTML tag
   * @param {string} metaTag - Meta tag name with {{}}
   * @returns {HTMLElement}
   */


  function createMetaDOMElement(metaTag) {
    const tag = document.createElement(_tagConstants.MetaTag.tagName);
    tag.setAttribute(_tagConstants.MetaTag.dataAttribute, metaTag);
    tag.setAttribute('class', _tagConstants.MetaTag.className);
    tag.setAttribute('contenteditable', 'false');
    return tag;
  }
  /**
   * Create meta tag close button
   * @returns {HTMLSpanElement}
   */


  function createCloseButton() {
    const button = document.createElement(_tagConstants.MetaCloseButtonTag.tagName);
    button.setAttribute('class', _tagConstants.MetaCloseButtonTag.className);
    button.setAttribute('contenteditable', false);
    button.appendChild(document.createTextNode('\u2716'));
    return button;
  }
  /**
   * Makes meta tag human readable
   * @param {string} metaName - Meta tag name w/o {{}}
   * @returns {string}
   */


  function beautifyMeta(metaName) {
    const beautyName = metaName.replace(/meta\./g, '').replace(/[_.]/g, ' ');
    return beautyName.replace(/(?:^|\s)\S/g, match => match.toUpperCase());
  }
});