define("builder/pods/components/page-structure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xjU1BkIN",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"structure\",\"originObject\",\"type\"]]],[[\"data\",\"isPopup\",\"setChange\",\"createStructureCheckpoint\",\"iframeCSS\",\"isAllModulesLoaded\",\"parentActions\",\"unlockLayout\",\"onFirstRender\",\"toggleDraggable\",\"moduleAction\",\"updateAllDimensions\",\"toggleOverlay\",\"isBootstrapLoad\",\"openFieldLinkPopup\",\"globalStylesModel\"],[[24,[\"structure\"]],[24,[\"isPopup\"]],[24,[\"setChange\"]],[24,[\"createStructureCheckpoint\"]],[24,[\"iframeCSS\"]],[24,[\"isAllModulesLoaded\"]],[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"parentActions\"]]],null]],null],[28,\"action\",[[23,0,[]],[24,[\"unlockLayout\"]]],null],[24,[\"onFirstRender\"]],[24,[\"toggleDraggable\"]],[24,[\"moduleAction\"]],[24,[\"updateAllDimensions\"]],[24,[\"toggleOverlay\"]],[24,[\"isBootstrapLoad\"]],[24,[\"openFieldLinkPopup\"]],[24,[\"globalStylesModel\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/page-structure/template.hbs"
    }
  });

  _exports.default = _default;
});