define("builder/core/froala/settings/widget-paragraph-settings", ["exports", "builder/config/environment", "froala-editor", "builder/core/froala/metatags", "builder/core/froala/icons/popup-df-icons", "builder/core/froala/links", "builder/core/froala/links/link-color-plugin", "builder/core/froala/links/link-hover-color-plugin", "builder/core/froala/line-spacing/dropdown-line-spacing", "builder/core/styles-management/structure", "builder/core/froala/toolbar-style-dropdown", "builder/core/froala/clear-formatting-button"], function (_exports, _environment, _froalaEditor, _metatags, _popupDfIcons, _links, _linkColorPlugin, _linkHoverColorPlugin, _dropdownLineSpacing, _structure, _toolbarStyleDropdown, _clearFormattingButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHeadingOptions = getHeadingOptions;
  _exports.getFontSizeOptions = getFontSizeOptions;
  _exports.froalaParagraphSettings = void 0;
  const froalaParagraphSettings = {
    key: _environment.default.FroalaKey,
    pluginsEnabled: ['link', 'align', 'table', 'colors', 'quote', 'lineHeight', 'paragraphFormat', 'lists', 'fontSize', _metatags.metaPluginName, _popupDfIcons.dfIconsPluginName, _linkColorPlugin.frCustomColorPlugin, _linkHoverColorPlugin.frCustomHoverColorPlugin],

    /**
     * Number 10 is bigger than ignite panel z-index.
     * We need to show all popups on top of other elements.
     */
    zIndex: 10,
    theme: 'royal',
    toolbarInline: true,
    toolbarSticky: false,
    toolbarStickyOffset: 100,
    attribution: false,
    toolbarVisibleWithoutSelection: true,
    autoStart: false,
    autofocus: false,
    quickInsertEnabled: true,
    placeholderText: '',
    pastePlain: true,
    charCounterCount: false,
    htmlUntouched: true,
    htmlAllowedEmptyTags: ['i'],
    fontFamilySelection: true,
    fontSizeSelection: true,
    fontSize: getFontSizeOptions(),
    paragraphFormatSelection: true,
    paragraphFormat: {
      N: 'Normal',
      PRE: 'Code',
      ...getHeadingOptions()
    },
    imagePaste: false,
    ...(!(_environment.default === null || _environment.default === void 0 ? void 0 : _environment.default.featureFlags['release-paragraph-format']) && {
      enter: _froalaEditor.default.ENTER_BR
    }),
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'textColor', 'backgroundColor', 'fontSize', _dropdownLineSpacing.dropdownLetterSpaceCommand, _clearFormattingButton.buttonClearFormattingCmd],
        align: 'left',
        buttonsVisible: 3
      },
      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'outdent', 'indent', 'paragraphFormat', 'quote', 'lineHeight'],
        align: 'left',
        buttonsVisible: 3
      },
      moreRich: {
        buttons: [_links.linkAddCommand, 'insertTable', _metatags.metaPopupCommand, _popupDfIcons.dfIconsPopupCommand],
        align: 'left',
        buttonsVisible: 4
      },
      moreMisc: {
        buttons: [_toolbarStyleDropdown.dropdownToolbarStyleCommand]
      }
    },
    linkEditButtons: [_links.linkUpdateCommand, _links.linkRemoveCommand, _links.linkColorCommand, _links.linkHoverColorCommand, _links.linkHoverDecorationCommand]
  }; // Fetching the heading options to be used from global styles structure

  _exports.froalaParagraphSettings = froalaParagraphSettings;

  function getHeadingOptions() {
    var _stylesStructure$inde, _children$indexHeadin, _children$indexHeadin2;

    // Extracting Heading styles used in structure.js
    const indexHeading = _structure.default === null || _structure.default === void 0 ? void 0 : _structure.default.findIndex(item => item.label === 'Heading');
    const children = indexHeading && ((_stylesStructure$inde = _structure.default[indexHeading]) === null || _stylesStructure$inde === void 0 ? void 0 : _stylesStructure$inde.children);
    const indexHeadingStyles = children && children.findIndex(item => item.label === 'Heading styles');
    const headingObjects = indexHeadingStyles && ((_children$indexHeadin = children[indexHeadingStyles]) === null || _children$indexHeadin === void 0 ? void 0 : (_children$indexHeadin2 = _children$indexHeadin.children[0]) === null || _children$indexHeadin2 === void 0 ? void 0 : _children$indexHeadin2.children) || [];
    const options = {};
    headingObjects.forEach(({
      label
    }) => {
      if (label.match(/H[1-6]/)) {
        options[label] = `Heading ${label[1]}`;
      }
    });
    return options;
  }

  function getFontSizeOptions() {
    const fontSizes = Array.from(Array(100).keys());
    fontSizes.shift();
    return fontSizes;
  }
});