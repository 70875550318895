define("builder/services/menu-management-drag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * @property {Ember.Object[]} - Structures to watch over
     */
    _structures: [],

    /**
     * Add new structure to observe
     *
     * @param {Ember.Object} structure - structure to add
     */
    addStructure(structure) {
      this._structures.addObject(structure);
    },

    /**
     * Remove all registered structures
     */
    clearStructures() {
      this.set('_structures', []);
    },

    /**
     * Register component of data node
     *
     * @param {Ember.Object|Fragment} reference - data node linked to component
     * @param {Ember.Component} component - component itself
     */
    register(reference, component) {
      reference.get('config').set('component', component);
    },

    /**
     * Unregister component
     *
     * @param {Ember.Component|Fragment} reference - data node linked to component
     */
    unregister(reference) {
      const config = reference.get('config');
      config.set('component', null);
      config.set('x', 0);
      config.set('y', 0);
      config.set('width', 0);
      config.set('height', 0);
    },

    /**
     * Get pointed item by x and y coordinate
     *
     * @param {Number} x - x coordinate
     * @param {Number} y - y coordinate
     * @returns {*}
     */
    getPointedItem(x, y) {
      let node = null;
      const structures = this._structures;

      for (let i = 0, len = structures.length; i < len; i++) {
        node = this._findPointedNode(structures[i], x, y);

        if (node) {
          break;
        }
      }

      return node;
    },

    /**
     * Update structures positions (x, y, width, height)
     */
    updateDimensions() {
      const structures = this._structures;

      for (let i = 0, len = structures.length; i < len; i++) {
        this._updateNodesDimensions(structures[i]);
      }
    },

    /**
     * Find pointed node by x and y coordinate, the deepest node will be found
     *
     * @param {Ember.Object|Fragment} structure - search node
     * @param {Number} x - x coordinate
     * @param {Number} y - y coordinate
     * @returns {Ember.Object|Fragment|null} - found node
     * @private
     */
    _findPointedNode(structure, x, y) {
      const children = structure.get('children') || [];
      let node = null;
      let subNode = null;

      if (this._isPointed(structure, x, y)) {
        node = structure;
      }

      for (let i = 0, len = children.get('length'); i < len; i++) {
        subNode = this._findPointedNode(children.objectAt(i), x, y);

        if (subNode) {
          node = subNode;
          break;
        }
      }

      return node;
    },

    /**
     * Update node coordinates (x, y, width, height) and it's children nodes
     *
     * @param {Ember.Object|Fragment} node - node to update
     * @private
     */
    _updateNodesDimensions(node) {
      const children = node.get('children') || [];

      if (!node.get('config.hidden')) {
        this._updateNodeDimensions(node);

        for (let i = 0, len = children.get('length'); i < len; i++) {
          this._updateNodesDimensions(children.objectAt(i));
        }
      }
    },

    /**
     * Update single node coordinates (x, y, width, height)
     *
     * @param {Ember.Object|Fragment} node - node to update
     * @returns {Ember.Object|Fragmentr} - updated node
     * @private
     */
    _updateNodeDimensions(node) {
      const component = node.get('config.component');
      const params = {
        left: 0,
        top: 0,
        width: 0,
        height: 0
      };

      if (component) {
        const element = component.getDraggableElement();
        const offset = element.offset();
        params.left = offset.left;
        params.top = offset.top;
        params.width = element.outerWidth();
        params.height = element.outerHeight();
      }

      node.set('config.x', params.left);
      node.set('config.y', params.top);
      node.set('config.width', params.width);
      node.set('config.height', params.height);
      return node;
    },

    /**
     * Detect if item has been pointed, current pageX and pageY coordinates lies inside given item
     *
     * @param {Object} item - structure item
     * @param {Number} x - event pageX
     * @param {Number} y - event pageY
     * @returns {Boolean} - true if pageX and pageY lies within item, otherwise false
     */
    _isPointed(item, x, y) {
      // Item without width or height cannot be pointed
      if (item.get('config.width') === 0 || item.get('config.height') === 0) {
        return false;
      }

      return item.get('config.x') <= x && item.get('config.x') + item.get('config.width') >= x && item.get('config.y') <= y && item.get('config.y') + item.get('config.height') >= y;
    }

  });

  _exports.default = _default;
});