define("builder/pods/components/content-blocker/component", ["exports", "builder/core/which-transition-event"], function (_exports, _whichTransitionEvent2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['content-blocker', 'flex', 'flex_center'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isShow::content-blocker_hidden'],

    /**
     * Property contain component state.
     *
     * @property
     * @type {null|boolean}
     */
    isShow: null,

    /**
     * It is a dummy method to show error when you try to invoke
     * not passed action from parent component or controller.
     * See template component.
     *
     * @method unlockLayout
     */
    unlockLayout() {
      throw new Ember.Error('"unlockLayout" method must be passed');
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this._addHandlers();
    },

    /**
     * @inheritdoc
     */
    click() {
      this.unlockLayout();
    },

    /**
     * Add handlers for hide element when animation end
     * @method _addHandlers
     * @private
     */
    _addHandlers() {
      this.element.addEventListener((0, _whichTransitionEvent2._whichTransitionEvent)(), () => {
        this.style.display = 'none';
      });
    }

  });

  _exports.default = _default;
});