define("builder/services/module-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * @property {DS.Store} _store - ember store, just store
     */
    _store: Ember.inject.service('store'),

    /**
     * Load theme by id
     *
     * @param {String|Number} id - id of theme to load
     * @returns {Promise} - request promise
     */
    getTheme(id) {
      const store = this._store;
      const record = store.peekRecord('module-theme', id);

      if (record) {
        return Ember.RSVP.resolve(record);
      }

      return store.findRecord('module-theme', id);
    }

  });

  _exports.default = _default;
});