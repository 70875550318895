define("builder/pods/website/edit/page/edit/field-hidden-normalizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.variantName = void 0;

  /**
   * @const {String} variantName - form variants name
   */
  const variantName = 'hiddenField';
  _exports.variantName = variantName;
  var _default = {
    /**
     * Normalizer form variants data for hidden fields
     *
     * @method normalize
     * @param {Object[]} fields - form variants data
     * @returns {Array} normalized data
     */
    normalize(fields) {
      const data = [];

      if (!Array.isArray(fields)) {
        return data;
      }

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        data.push({
          type: 'field-hidden',
          data: field.value,
          name: field.name
        });
      }

      return data;
    },

    /**
     * Denormalize data for from variants
     *
     * @method denormalize
     * @param {Object[]} fields - hidden fields
     * @returns {Array} denormalized data
     */
    denormalize(fields) {
      const data = [];

      if (Array.isArray(fields)) {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          data.push({
            value: field.data,
            'variant-name': variantName,
            name: field.name
          });
        }
      }

      return data;
    }

  };
  _exports.default = _default;
});