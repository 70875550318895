define("builder/core/meta-tags/deserializer", ["exports", "builder/core/meta-tags/dom-manipulation"], function (_exports, _domManipulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserialize = deserialize;

  /**
   * Deserialize text with meta tags
   * @param {string} text
   * @returns {string} HTML code string
   */
  function deserialize(text) {
    if (typeof text !== 'string') {
      return '';
    }

    const metaRegExp = /{{.+?}}/gm;
    return text.replace(metaRegExp, metaTag => (0, _domManipulation.createMetaTagElement)(metaTag).outerHTML);
  }
});