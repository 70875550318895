define("builder/services/modules-store", ["exports", "builder/core/enumerators/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModulesStoreService
   *
   * TODO: decouple dependency handling => module store -> (storage, dependencyManager)
   * Module store as proxy, that will add/remove modules into store and perform modules update through dependency manager
   */
  var _default = Ember.Service.extend({
    /**
     * @property {ModuleDependencyManager} dependencyManager - module dependency manager.
     * Injected via initializer misc.js
     */
    dependencyManager: null,

    /**
     * @property {Array} _list - array of module objects
     * @private
     */
    _list: null,

    /**
     * @property {StoreService} _store - store service
     */
    _store: Ember.inject.service('store'),

    /**
     * @property {Number} length - length of the list
     */
    length: Ember.computed('_list.[]', function () {
      return this._list.length;
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_list', []);
    },

    /**
     * Method return list of modules
     * Be very careful, because this method return a reference
     *
     * @method getList
     * @returns {Array}
     */
    getList() {
      return this._list;
    },

    /**
     * Method find in structure 'core-modules' or 'form-module' and then push to list property.
     *
     * @method fill
     * @param {Object} structure
     */
    fill(structure) {
      for (let i = structure.length; i--;) {
        const item = structure[i];
        const type = item.get('originObject').get('type');
        const children = item.get('originObject').get('children');

        if (type === _module.default.MODULE_CORE || type === _module.default.MODULE_FORM) {
          this.add(item);
        } else if (children) {
          this.fill(children);
        }
      }
    },

    /**
     * Method find an object in collection by id.
     * Method return reference
     *
     * @param {number|string} id
     * @returns {undefined|Object}
     */
    findById(id) {
      return this._list.find(item => item.get('originObject').get('id') === parseInt(id, 10));
    },

    /**
     * Method find an object in collection by name.
     * Method return reference
     *
     * @param {String} name - module name
     * @returns {Object[]}
     */
    findByName(name) {
      return this._list.filterBy('originObject.name', name);
    },

    /**
     * Method add object to collection and set status if it not a system module
     *
     * @method add
     * @param {Object} object
     */
    add(object) {
      object.get('originObject').set('id', parseInt(object.get('originObject').get('id'), 10));

      if (!this._list.includes(object)) {
        this._list.pushObject(object);
      }

      this.updateModulesDependencies(object);
    },

    /**
     * Method removes item from list by id
     *
     * @param {String|Number} id
     */
    remove(id) {
      this.removeBy({
        id: parseInt(id, 10)
      });
    },

    /**
     * Method removes item from list by passed pairs key-value
     *
     * @param {Object} conditions - remove condition
     * @param {Boolean} onlyFirstMatch - remove only 1-st matched module
     * @param {Boolean} reload - should dependant modules be reloaded
     */
    removeBy(conditions, onlyFirstMatch = true, reload = true) {
      const removedModules = this._removeModulesBy(conditions, onlyFirstMatch);

      if (reload) {
        this.updateModulesDependencies(removedModules, true);
      }
    },

    /**
     * Remove modules that satisfy conditions
     *
     * @param {Object} conditions - remove condition
     * @param {Boolean} onlyFirstMatch - remove only 1-st matched module
     * @returns {BaseGridModel[]} - list of removed modules
     * @private
     */
    _removeModulesBy(conditions, onlyFirstMatch) {
      if (!conditions) {
        return [];
      }

      const list = this._list;
      const keys = Object.keys(conditions);
      const keysLength = keys.length;
      const removedItems = [];

      for (let i = list.get('length') - 1; i >= 0; i -= 1) {
        let successConditions = 0;

        for (let j = 0; j < keysLength; j++) {
          if (list[i].get(`originObject.${keys[j]}`) === conditions[keys[j]]) {
            successConditions++;
          }
        }

        if (successConditions === keysLength) {
          removedItems.push(list[i]);
          list.removeAt(i, 1);

          if (onlyFirstMatch) {
            break;
          }
        }
      }

      return removedItems;
    },

    /**
     * Removes all modules from the list
     */
    clear() {
      this._list.clear();
    },

    /**
     * Removes all core modules from the list
     */
    clearCoreModules() {
      this.removeBy({
        type: _module.default.MODULE_CORE
      });
    },

    /**
     * Update modules dependencies
     *
     * @param {BaseGridModel|BaseGridModel[]} moduleOrModules - list of modules that was added/removed
     * @param {Boolean} remove - is remove operation was executed to passed in modules
     */
    updateModulesDependencies(moduleOrModules, remove = false) {
      const {
        dependencyManager
      } = this;
      const modules = Ember.isArray(moduleOrModules) ? moduleOrModules : [moduleOrModules];
      const updatedModules = modules.slice(); // The incoming modules marked as already updated/reloaded

      modules.forEach(module => {
        const theme = module.get('theme');

        if (theme) {
          this._updateDependentThemes(module, dependencyManager.getDependentThemes(theme), remove, updatedModules);

          this._updateDependencyThemes(module, dependencyManager.getDependencyThemes(theme), remove);
        }
      });
    },

    /**
     * Update page modules which depends on passed in module
     * When module on which other modules depends on added on page, this module must be added as dependency to
     * dependent modules
     *
     * @param {BaseGridModel} pageModule - page module which dependent module themes to update
     * @param {ModuleThemeModel} themes - module themes which has dependency on pageModule
     * @param {Boolean} remove - operation executed on pageModule
     * @param {Ember.Object[]} [updatedModules=[]] - already updated modules
     * @private
     */
    _updateDependentThemes(pageModule, themes, remove, updatedModules = []) {
      const {
        dependencyManager
      } = this;

      const modules = this._list.filter(module => themes.includes(module.get('theme')));

      modules.forEach(module => {
        if (remove) {
          module.get('dependencyModules').removeObject(pageModule);
        } else if (!module.get('dependencyModules').includes(pageModule)) {
          module.get('dependencyModules').pushObject(pageModule);
        }

        if (!updatedModules.includes(module)) {
          module.set('reload', window.performance.now());
          updatedModules.push(module);

          this._updateDependentThemes(module, dependencyManager.getDependentThemes(module.get('theme')), remove, updatedModules);
        }
      });
    },

    /**
     * Update modules passed in pageModule depends on
     * When module which has dependencies added on page, all modules current module depends on must be added as
     * dependencies to current module
     *
     * @param {BaseGridModel} moduleNode - operated module
     * @param {ModuleThemeModel[]} themes - list of all themes passed in module depends on
     * @param {Boolean} remove - operation performed on current module
     * @private
     */
    _updateDependencyThemes(moduleNode, themes, remove) {
      const modules = this._list.filter(module => themes.includes(module.get('theme')));

      modules.forEach(module => {
        if (remove) {
          moduleNode.get('dependencyModules').removeObject(module);
        } else if (!moduleNode.get('dependencyModules').includes(module)) {
          moduleNode.get('dependencyModules').pushObject(module);
        }
      });
    }

  });

  _exports.default = _default;
});