define("builder/core/drop-rules/meta-container", ["exports", "builder/core/drop-rules/base-rule", "builder/core/enumerators/direction", "builder/core/enumerators/container"], function (_exports, _baseRule, _direction, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Since widget-header, widget-main and widget-footer are the same(almost), these rules are common for these widgets
   */
  var _default = _baseRule.default.extend({
    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_allowedTypes', ['widget-container']);
    },

    /**
     * @inheritdoc
     */
    canDrop(container, draggable, direction, anchor, ...args) {
      // This must be validated first
      if (!this._super(container, draggable, direction, anchor, ...args) || !direction) {
        return false;
      }

      const children = container.get('originObject.children');
      const draggableParent = draggable.get('parent.parent'); // When single item exists in container

      if (draggableParent && anchor && draggable.get('parent.originObject.children.length') === 1) {
        // Do not drop into self, applicable only if draggable is the only child in container
        if (anchor === draggableParent && draggableParent.get('originObject.children.length') === 1) {
          return false;
        }
      } // When no children inside container, drop allowed at any direction


      return children.get('length') ? _direction.default.TOP === direction || _direction.default.BOTTOM === direction : true;
    },

    /**
     * @inheritdoc
     */
    prepare(structure, container, draggable) {
      const {
        gridBuilder
      } = this;
      const containerWrapper = gridBuilder.createInstance(_container.default.WIDGET_CONTAINER);
      const row = gridBuilder.createInstance(_container.default.WIDGET_ROW);
      structure.addChild(row, draggable);
      structure.addChild(containerWrapper, row);
      return containerWrapper;
    },

    /**
     * @inheritdoc
     */
    drop(structure, container, draggable, direction, anchor = null) {
      const draggableContainer = this._getDraggableMetaContainer(draggable);

      let preparedDraggable = draggable;
      const originalParent = draggable.get('parent');
      let index = 0;
      structure.startTransaction();

      if (anchor) {
        index = container.get('originObject').get('children').indexOf(anchor);

        if (direction === _direction.default.BOTTOM) {
          index++;
        }
      } // When moved inside same parent, since it has only 1 child, this child will be removed,
      // it must be taken into account


      const childIndex = container.get('originObject').get('children').indexOf(draggableContainer);

      if (childIndex !== -1 && container.get('originObject').get('children').length === 1) {
        // Since draggable container wont be recycled if it has other children inside, we must check for that
        if (childIndex < index && draggableContainer.get('originObject').get('children') < 2) {
          index--;
        }
      } // No need to prepare draggable


      if (draggable.get('originObject.type') !== _container.default.WIDGET_CONTAINER) {
        preparedDraggable = this.prepare(structure, container, draggable);
      } // Emulate draggable, hasn't changed, so cleanup will be performed on it's original parent


      preparedDraggable.set('parent', originalParent);
      structure.addChild(container, preparedDraggable, index);
      structure.commitTransaction();
    },

    /**
     * Get draggable meta container
     *
     * @param {Object} draggable - draggable component
     * @returns {Object|null} - draggables meta container || null
     * @private
     */
    _getDraggableMetaContainer(draggable) {
      const parent = draggable.get('parent');
      return parent ? parent.get('parent') : null;
    }

  });

  _exports.default = _default;
});