define("builder/models/website-style", ["exports", "builder/models/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WIDGETS_PREFIX = '.page_edit_canvas';
  /**
   * @class WebsiteStyleModel
   */

  var _default = _style.default.extend({
    /**
     * TODO: think about, maybe we should share not generated css, but stylesheet, so that way we can get rid of this
     * scoped css and let consumer decide, scoped or not css he/she wants.
     *
     * @property {String} scopedStyleCSS - css scoped to builder2 space(all selectors are prefixed with WIDGETS_PREFIX
     * selector)
     */
    scopedStyleCSS: Ember.computed('styleMap', 'configurationService.currentDevice.breakpoint', '{global,lg,md,sm,xs}.@each.value', function () {
      const breakpoint = this.get('configurationService.currentDevice.breakpoint');
      return this._generateCSS(breakpoint, WIDGETS_PREFIX);
    }),
    globalStyleVariables: Ember.computed('styleMap', 'configurationService.currentDevice.breakpoint', '{global,lg,md,sm,xs}.@each.value', function () {
      const breakpoint = this.get('configurationService.currentDevice.breakpoint');
      return this.getGlobalCssCustomProperties(breakpoint);
    })
  });

  _exports.default = _default;
});