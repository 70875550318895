define("builder/validators/uniq-container-anchor", ["exports", "ember-cp-validations/validators/base", "builder/core/enumerators/container"], function (_exports, _base, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UniqContainerAnchor = _base.default.extend({
    /**
     * @property {StructureService} structure - structure service
     */
    structure: Ember.inject.service('structure'),

    /**
     * @inheritdoc
     */
    validate(value, ...args) {
      const containers = this.structure.findAllByType(this.structure.pageViewModel, _container.default.WIDGET_CONTAINER);
      const anchorIsUsed = !!containers.findBy('originObject.anchor', value);

      if (anchorIsUsed) {
        return 'This value is a duplicate that already exists on the page. Please use a unique value in this field.';
      }

      return this._super(value, ...args);
    }

  });

  var _default = UniqContainerAnchor;
  _exports.default = _default;
});