define("builder/pods/components/sm-info-block/component", ["exports", "builder/core/abstract-classes/attributes-provider-component"], function (_exports, _attributesProviderComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Component to build info block for styles-management
   */
  let _class = (_dec = Ember.computed('theme'), (_class2 = (_temp = class _class2 extends _attributesProviderComponent.default {
    constructor(...args) {
      super(...args);
      this.classNames = ['line-box__inner'];
      this.label = null;
      this.content = null;
      this.theme = null;
    }

    /**
     * Class for theme
     * @type {string}
     * @readonly
     */
    get themeClass() {
      const theme = this.theme || 'info';
      return `field-info-block--${theme}`;
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "themeClass", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "themeClass"), _class2.prototype)), _class2));

  _exports.default = _class;
});