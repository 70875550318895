define("builder/pods/components/menumanager-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property {String[]} classNames - component class names
     */
    classNames: ['menu-manager__actions'],

    /**
     * @property {Boolean} removable - button remove is active
     */
    removable: true,

    /**
     * @property {Boolean} configurable - button configure is active
     */
    configurable: true,

    /**
     * @property {Boolean} hiddable - button hide is active
     */
    hiddable: true,

    /**
     * @property {Boolean} canRemove - can remove associated item
     */
    canRemove: true,

    /**
     * @property {String} removeDisabledMessage - message to be shown when remove is disabled
     */
    removeDisabledMessage: '',

    /**
     * @property {Boolean} canUpdate - can update associated item
     */
    canUpdate: true,

    /**
     * @property {String} updateDisabledMessage - message to be shown when update is disabled
     */
    updateDisabledMessage: '',
    actions: {
      /**
       * Triggers state action
       *
       * @method
       * @param {String} action - action name
       */
      changeState(action) {
        this.stateAction(action);
      },

      remove() {
        this.removeItem();
      },

      /**
       * Action for user interaction with item which should open config
       */
      openConfig() {
        this.openConfig();
      },

      toggleHide() {
        this.toggleHide();
      }

    }
  });

  _exports.default = _default;
});