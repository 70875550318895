define("builder/pods/components/field-website-selector/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['field-website-selector'],

    /**
     * Max selected website part displayed without expand all selected list
     * @type {number}
     * @default [false]
     */
    maxDisplayedWebsites: 5,

    /**
     * Field described searchable selector for choose websites
     * @type {object}
     * @readonly
     */
    fieldSelector: Ember.computed('field.data.[]', 'field.choices', function () {
      return {
        choices: this.field.choices,
        data: this.field.data
      };
    }).readOnly(),

    /**
     * Selected websites
     * @type {{label: string, value: string}[]}
     * @readonly
     */
    selectedWebsitesList: Ember.computed('field.data.[]', 'field.choices', function () {
      const selectedWebsiteIds = new Set(this.field.data);
      return this.field.choices.filter(website => selectedWebsiteIds.has(website.value)).sortBy('label');
    }).readOnly(),

    /**
     * Selected websites to display
     * @type {{label: string, value: string}[]}
     * @readonly
     */
    displayedWebsitesList: Ember.computed('selectedWebsitesList', 'canShowAll', 'isShowAll', function () {
      if (this.canShowAll && !this.isShowAll) {
        return this.selectedWebsitesList.slice(0, this.maxDisplayedWebsites);
      }

      return this.selectedWebsitesList;
    }).readOnly(),

    /**
     * Action handler
     * @type {Function|null}
     */
    actionHandler: null,

    /**
     * Expand state for searchable selector
     * @type {boolean}
     * @default [false]
     */
    isWebsiteSelectorExpanded: false,

    /**
     * State for display all selected websites
     * @type {boolean}
     * @default [false]
     */
    isShowAll: false,

    /**
     * Is any site selected
     * @type {boolean}
     * @readonly
     */
    isAnyWebsiteSelected: Ember.computed.bool('field.data.length').readOnly(),

    /**
     * Can select new website
     * @type {boolean}
     * @readonly
     */
    сanSelectNewWebsite: Ember.computed('field.data.[]', 'field.choices', function () {
      return this.field.data.length < this.field.choices.length;
    }).readOnly(),

    /**
     * Can show all selected websites
     * @type {boolean}
     * @readonly
     */
    canShowAll: Ember.computed('field.data.[]', function () {
      return this.field.data.length > this.maxDisplayedWebsites;
    }).readOnly(),

    /**
     * Additional styles for selected website list
     * @type {string}
     * @readonly
     */
    additionalListStyles: Ember.computed('isShowAll', function () {
      if (!this.isShowAll) {
        return '';
      }

      const listHeight = this.element.querySelector('.selected-list-wrapper').offsetHeight;
      return `max-height: ${listHeight}px`;
    }).readOnly(),

    /**
     * @inheritDoc
     */
    willInsertElement(...args) {
      this._super(...args);

      this.applyValidations();
    },

    /**
     * Send value to upstream
     * @param {string[]} value - field value
     */
    sendValueToParent(value) {
      if (typeof this.actionHandler === 'function') {
        this.actionHandler(value, this.field.name);
      }
    },

    actions: {
      selectWebsites(websiteIds) {
        const selectedWebsites = [...this.field.data, ...websiteIds];
        this.sendValueToParent(selectedWebsites);

        if (selectedWebsites.length === this.field.choices.length) {
          this.set('isWebsiteSelectorExpanded', false);
        }
      },

      removeOneWebsite(website) {
        if (!window.confirm('Delete the page from the website?')) {
          return;
        }

        const removedWebsiteId = website.value;
        const selectedWebsites = this.field.data.reject(id => id === removedWebsiteId);
        this.sendValueToParent(selectedWebsites);
      },

      removeAllWebsites() {
        if (window.confirm('Do you want to delete this page from all the websites?')) {
          this.sendValueToParent([]);
        }
      },

      onChangeWebsiteSelectorState(expanded) {
        // Prevent any actions during destroying DropdownContainer
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        if (!this.сanSelectNewWebsite && expanded) {
          this.set('isisWebsiteSelectorExpanded', false);
        }

        Ember.run.scheduleOnce('afterRender', this, () => this.element.scrollIntoView());
      },

      showAll() {
        this.set('isShowAll', true);
      }

    }
  });

  _exports.default = _default;
});