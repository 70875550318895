define("builder/adapters/page-category", ["exports", "builder/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @inheritdoc
     */
    urlForFindAll(type, snapshot) {
      let url = `${this.host}/page_template?Category=1`;
      const options = snapshot.adapterOptions;

      if (options && options.includeStatic) {
        url += '&Static=1';
      }

      return url;
    }

  });

  _exports.default = _default;
});