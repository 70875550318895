define("builder/pods/components/sm-composite/component", ["exports", "builder/core/literals", "builder/core/abstract-classes/attributes-provider-component", "builder/core/factories/composite-property", "builder/core/styles-management/variable-dependency-resolver"], function (_exports, _literals, _attributesProviderComponent, _compositeProperty, _variableDependencyResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    DEVICE_DEPENDENCE_VARIABLE
  } = _literals.default;
  /**
   * @class SMCompositeComponent
   */

  var _default = _attributesProviderComponent.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['line-box__line', 'line-box__inner'],

    /**
     * @property {String} type - composite property name registered in our system
     * @API
     * @see core/styles-management/composite-properties
     */
    type: null,

    /**
     * @property {String} label - label of rendered part
     * @API
     */
    label: null,

    /**
     * @property {String} tooltipText - text for the tooltip when this variable changes depending on the device
     */
    tooltipText: DEVICE_DEPENDENCE_VARIABLE,

    /**
     * @property {Boolean} allInnerPropertyDeviceDependent - show that all inner property device dependent
     */
    allInnerPropertyDeviceDependent: Ember.computed('dependantVariables', function () {
      const {
        dependantVariables
      } = this;
      return dependantVariables.filter(item => item.isDeviceDependent).length === dependantVariables.length;
    }),

    /**
     * @property {Boolean} isAllVariablesLocked - indicate what all children variables is locked
     * @readonly
     */
    isAllVariablesLocked: Ember.computed('variablesModel.StyleOptions.@each.locked', 'childrenVariableNames', function () {
      const {
        childrenVariableNames
      } = this;
      const styleOptions = this.get('variablesModel.StyleOptions');

      if (!Ember.get(childrenVariableNames, 'length') || !Ember.get(styleOptions, 'length')) {
        return false;
      }

      return childrenVariableNames.every(variableName => {
        const option = styleOptions.findBy('variableName', variableName);
        return option && option.get('locked');
      });
    }).readOnly(),

    /**
     * @property {Object[]} children
     * @API
     */
    children: null,

    /**
     * @property {StyleModel} variablesModel
     */
    variablesModel: null,

    /**
     * @property {StyleModel} [dependentModel] -  for dependency one model from another
     */
    dependentModel: null,

    /**
     * @property {String[]} resolve - resolver for equal css properties names which value should be showed as result
     * @API
     */
    resolve: null,

    /**
     * @inheritdoc
     */
    excludeAttrs: Ember.computed(function () {
      return ['name'];
    }).readOnly(),

    /**
     * @property {String} mode - bootstrap mode(lg,md,sm,xs)
     */
    mode: null,

    /**
     * @property {String} result - value which see user
     * @readonly
     */
    result: Ember.computed('dependantVariables', 'dependantVariables.@each.value', function () {
      this._provideVariablesValues();

      return this.propertyBuilder.buildForCompositeView();
    }).readOnly(),

    /**
     * @property {VariableFragment[]} dependantVariables - contain variables which this component should observe
     */
    dependantVariables: Ember.computed('variablesModel', 'mode', 'variablesModel.{global,lg,md,xs,sm}.[]', 'variablesModel.{global,lg,md,xs,sm}.@each.value', function () {
      const variables = [];
      const {
        propertyBuilder
      } = this;
      const {
        resolve
      } = this;
      const {
        children
      } = this;
      const skipCSSProperties = [];
      const {
        mode
      } = this;

      if (resolve && resolve.length) {
        resolve.forEach(name => {
          const dependantChild = children.findBy('variableName', name);
          const cssProperty = Ember.get(dependantChild, 'cssPropertyName');
          const value = propertyBuilder.getValue(cssProperty);

          if (typeof value === 'undefined') {
            throw new Ember.Error(`type: "${this.type}" -> does not supported for this css property "${cssProperty}" bounded on "${name}" variable`);
          }

          skipCSSProperties.pushObject(cssProperty);
          const {
            variable,
            isDeviceDependent
          } = this.resolveDependency(name, mode);
          variables.pushObject({
            cssProperty,
            variable,
            isDeviceDependent
          });
        });
      }

      this.children.forEach(child => {
        const cssProperty = Ember.get(child, 'cssPropertyName');
        const value = propertyBuilder.getValue(cssProperty);
        let variable, isDeviceDependent;

        if ((value || value === null) && (!skipCSSProperties.length || !skipCSSProperties.includes(cssProperty))) {
          ({
            variable,
            isDeviceDependent
          } = this.resolveDependency(Ember.get(child, 'variableName'), mode));
          variables.pushObject({
            cssProperty,
            variable,
            isDeviceDependent
          });
        }
      });
      return variables;
    }).readOnly(),

    /**
     * @property {String[]} childrenVariableNames - children variable names
     * @readonly
     */
    childrenVariableNames: Ember.computed('children', function () {
      return collectVariableNames(this.children);

      function collectVariableNames(children, names = []) {
        children.forEach(child => {
          const childChildren = Ember.get(child, 'children');

          if (Ember.get(child, 'variableName')) {
            names.push(Ember.get(child, 'variableName'));
          }

          if (childChildren && childChildren.length) {
            collectVariableNames(childChildren, names);
          }
        });
        return names;
      }
    }).readOnly(),

    /**
     *
     * @param {String} name - variable name
     * @param {String} mode - lg,md,sm,xs
     * @return {VariableFragment}
     * @method resolveDependency
     */
    resolveDependency(name, mode) {
      const resolvedDependency = this.variableResolver.resolve(name, mode);
      return resolvedDependency && resolvedDependency.isResolved ? {
        variable: resolvedDependency.dependedVar,
        isDeviceDependent: resolvedDependency.isDeviceDependent
      } : {
        variable: resolvedDependency.variable,
        isDeviceDependent: resolvedDependency.isDeviceDependent
      };
    },

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('variableResolver', _variableDependencyResolver.default.create({
        mainModel: this.variablesModel,
        dependentModel: this.dependentModel
      }));
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.set('propertyBuilder', _compositeProperty.default.createInstance(this.type));
    },

    /**
     * Method setup composite property
     *
     * @method _provideVariablesValues
     * @private
     */
    _provideVariablesValues() {
      const {
        propertyBuilder
      } = this;
      this.dependantVariables.forEach(variable => {
        propertyBuilder.setValue(Ember.get(variable, 'cssProperty'), Ember.get(variable, 'variable.value'));
      });
    },

    actions: {
      toggleLock() {
        if (typeof this.updateLockState === 'function') {
          this.updateLockState({
            mode: this.mode,
            variableNames: this.childrenVariableNames,
            variablesModel: this.variablesModel,
            locked: !this.isAllVariablesLocked
          });
        }
      }

    }
  });

  _exports.default = _default;
});