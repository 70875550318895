define("builder/core/froala/line-spacing/dropdown-line-spacing", ["exports", "froala-editor"], function (_exports, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dropdownLetterSpaceCommand = void 0;

  /* eslint-disable new-cap */
  const dropdownLetterSpaceCommand = 'letter-spacing';
  _exports.dropdownLetterSpaceCommand = dropdownLetterSpaceCommand;
  const LETTER_SPACE_ARRAY_LENGTH = 11;

  _froalaEditor.default.RegisterCommand(dropdownLetterSpaceCommand, getLetterSpacingConfig()); // Get Custom Dropdown config for Letter spacing option


  function getLetterSpacingConfig() {
    // Custom dropdown button element
    const element = document.createElement('span');
    element.style.width = '30px';
    element.style.fontWeight = 'normal';
    return {
      title: 'Letter Spacing',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: getSpacesOptions(),

      callback(cmd, val) {
        this.format.applyStyle('letter-spacing', `${val}em`);
      },

      // Callback on refresh.
      refresh(btn) {
        // Update the selected value in the dropdown button
        const parentNode = btn && btn[0];

        if (parentNode) {
          parentNode.innerText = '';
          const {
            style
          } = this.selection.element();
          const em = (style === null || style === void 0 ? void 0 : style.letterSpacing) || '0em';
          const spaceValue = em.split('em')[0]; // Update the button text

          element.innerText = spaceValue;
          parentNode.append(element);
        }
      }

    };
  } // Returns a object with key:value pairs from 0.0 to 1.0


  function getSpacesOptions() {
    const options = {};
    Array.from({
      length: LETTER_SPACE_ARRAY_LENGTH
    }, (x, i) => (i / 10).toFixed(1)).reduce((prev, current) => options[current] = current, {});
    return options;
  }
});