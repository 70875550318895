define("builder/pods/components/drop-highlight/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['page-edit__highlight'],

    /**
     * @property {Ember.Service} dropHighlightService - Drop highlight service
     */
    dropHighlightService: Ember.inject.service('drop-highlight'),

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.dropHighlightService.setHighlightElement(this.$());
    }

  });

  _exports.default = _default;
});