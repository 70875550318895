define("builder/core/grid-models/container", ["exports", "builder/core/grid-models/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ContainerGridModel
   */
  var _default = _base.default.extend({
    /**
     * @property {Boolean} isActive - indicates if current container element has active child inside of it
     */
    isActive: Ember.computed('originObject.children.[]', 'originObject.children.@each.isActive', function () {
      const children = this.get('originObject.children') || [];
      let active = false;

      for (let i = 0, len = children.length; i < len; i++) {
        if (children[i].get('isActive')) {
          active = true;
          break;
        }
      }

      return active;
    })
  });

  _exports.default = _default;
});