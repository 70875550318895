define("builder/pods/components/slider-picker/component", ["exports", "builder/utils/math-extended"], function (_exports, _mathExtended) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['range-picker'],
    classNameBindings: ['hover::__visible', 'measurementPoint::range-picker_wo-unit'],

    /**
     * @inheritdoc
     */
    attributeBindings: ['tooltip:title'],

    /**
     * @property {String|null} [label]
     */
    label: null,

    /**
     * @property {Number|null} start - input value
     */
    start: null,

    /**
     * @property {Number|null} min - Slider min value
     */
    min: null,

    /**
     * @property {Number|null} max - Slider max value
     */
    max: null,

    /**
     * @property {Number|null} input max value
     */
    inputMax: null,

    /**
     * @property {Number} digits - count numbers after dot in value
     */
    digits: 0,

    /**
     * @property {Number|null} inputValue - input value
     */
    inputValue: null,

    /**
     * @property {Boolean} isActive - Indicates active state of the slider
     */
    isActive: false,

    /**
     * @property {Boolean} mouseleave - Determine whether mouse left component or not
     */
    mouseleave: true,

    /**
     * @property {Boolean} hover - Switches on/off hover behaviour
     */
    hover: true,

    /**
     * @property {Boolean} showInput - should be value input visible or not
     */
    showInput: true,

    /**
     * @type {Number|null} - holds the old value of the input field
     */
    previousInput: null,

    /**
     * @type {Boolean} - whether if the input field is in focus
     */
    isInputInFocus: false,

    /**
     * @property {Boolean} disabled - "disabled" attribute of the input
     */
    disabled: Ember.computed('mouseleave', 'isActive', function () {
      return this.hover && this.mouseleave && !this.isActive;
    }),

    /**
     * @property {Number} maxLength - max length for input field
     * @readonly
     */
    maxLength: Ember.computed('max', function () {
      let maxLength = Number.MAX_SAFE_INTEGER;

      if (typeof this.max === 'number') {
        maxLength = this.max.toFixed(this.digits).length;
      }

      return maxLength;
    }).readOnly(),

    /**
     * Makes component visible on hover and hidden on leave
     *
     * @method hoverable
     */
    hoverable() {
      let timer;
      this.$().on('mouseenter', () => {
        this.set('mouseleave', false);
        timer = setTimeout(() => {
          this.set('hover', false);
        }, 100);
      }).on('mouseleave', () => {
        this.set('mouseleave', true);

        if (!this.isActive) {
          clearTimeout(timer);
          this.set('hover', true);
        }
      });
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      if (this.hover) {
        this.hoverable();
      }

      this._super(...args);
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.set('inputValue', this.start);
    },

    /**
     * Triggers passed action
     *
     * @method passValueUp
     * @param {Number} value
     */
    passValueUp(value) {
      const action = this.onChange;

      if (!Ember.isEmpty(value) && !isNaN(value)) {
        if (action) {
          action(value);
        }
      }
    },

    /**
     * Parse slider value
     *
     * @method _parseValue
     * @param {String} value - slider value
     * @return {Number}
     * @private
     */
    _parseValue(value) {
      const {
        min
      } = this;
      const num = parseFloat(value === '' && min === 'number' ? min : value);
      return _mathExtended.default.cropToDigits(num, this.digits);
    },

    actions: {
      /**
       * Pass it up value when event slidechange fire
       *
       * @method sliderChanged
       * @param {Number} value - Slider value
       */
      sliderChanged(value) {
        const parsedValue = this._parseValue(value);

        this.set('inputValue', parsedValue);
        this.passValueUp(parsedValue);
      },

      /**
       * Updates value in input on event slide fire
       *
       * @method
       * @param {Number} value - slider value
       */
      updateValue(value) {
        this.set('inputValue', this._parseValue(value));
      },

      /**
       * Update value in slider when keyup event fire
       *
       * @method updateInputValue
       */
      updateInputValue() {
        const $input = this.$('.range-picker__input');
        const value = $input.val();

        let numValue = this._parseValue(value);

        const max = this.inputMax || this.max;
        numValue = isNaN(numValue) ? value : numValue;

        if (typeof numValue === 'number') {
          if (typeof this.min === 'number') {
            numValue = Math.max(numValue, this.min);
          }

          if (typeof max === 'number') {
            numValue = Math.min(numValue, max);
          }
        } else if (numValue === '' && typeof this.min === 'number') {
          numValue = this.min;
        }

        $input.val(numValue);

        if (this.inputValue !== numValue) {
          this.set('inputValue', numValue);
          this.passValueUp(numValue);
        }
      },

      /**
       * Set slider status as "active"
       *
       * @method activate
       */
      activate() {
        if (this.hover) {
          this.set('isActive', true);
        }
      },

      /**
       * Set slider status as "inactive"
       *
       * @method deactivate
       */
      deactivate() {
        if (this.hover) {
          this.set('isActive', false);

          if (this.mouseleave) {
            this.set('hover', true);
          }
        }
      },

      /**
       * handle focus on the input element
       * @method onInputFocusIn
       */
      onInputFocusIn() {
        this.set('isInputInFocus', true);
        this.previousInput = this.inputValue;
        this.set('inputValue', null);
      },

      /**
       * handle blur on the input element
       * @method onInputFocusOut
       */
      onInputFocusOut() {
        this.set('isInputInFocus', false);

        if (this.inputValue === null) {
          this.set('inputValue', this.previousInput);
        }
      }

    }
  });

  _exports.default = _default;
});