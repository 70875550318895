define("builder/models/module-theme", ["exports", "@ember-data/model", "builder/models/model", "ember-copy", "builder/core/enumerators/breakpoint-modes"], function (_exports, _model, _model2, _emberCopy, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend(_emberCopy.Copyable, {
    /**
     * theme label
     * @type {string}
     */
    Label: (0, _model.attr)('string'),

    /**
     * theme display name
     * @type {string}
     */
    Name: (0, _model.attr)('string'),

    /**
     * description
     * @type {string}
     */
    Description: (0, _model.attr)('string'),

    /**
     * module id related with theme
     * @type {string}
     */
    ModuleId: (0, _model.attr)('string'),

    /**
     * associated tags with theme separated by comma
     * @type {string}
     */
    Tags: (0, _model.attr)('string'),

    /**
     * module UI definition
     * @type {FieldConfiguration[]}
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * @type {number} - module is system or not
     */
    IsSystem: (0, _model.attr)('number'),

    /**
     * specific layout ids related with theme
     * @type {string[]}
     */
    Layout: (0, _model.attr)('raw'),

    /**
     * date of theme creation
     * @type {string}
     */
    DateCreated: (0, _model.attr)('string'),

    /**
     * image thumbnail of theme
     * @type {string}
     */
    Image: (0, _model.attr)('string'),

    /**
     * image of theme
     * @type {string}
     */
    OriginalImage: (0, _model.attr)('string'),

    /**
     * object of translation strings {en: {hello: 'hello'}, ru: {hello: 'privet'}}
     * @type {Object}
     */
    Strings: (0, _model.attr)('raw'),

    /**
     * default defined variables
     * @type {Object}
     * @see models/style.js
     */
    StyleVariables: (0, _model.attr)('raw'),

    /**
     * declarative defined UI interface for module style
     * @type {Object}
     */
    StyleInterface: (0, _model.attr)('raw'),

    /**
     * contain css selector for module style
     * @type {Object}
     */
    StyleMap: (0, _model.attr)('raw'),

    /**
     * contain names of global variables that related with theme style
     * @type {StyleGlobalReferences}
     */
    StyleGlobalReferences: (0, _model.attr)('raw'),

    /**
     * list of module names, on which current theme depends on
     * @type {string[]}
     */
    DependsOn: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),

    /**
     * list of OEM names
     * @type {string[]}
     */
    OEM: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),

    /**
     * website group
     * @type {string[]}
     */
    WebsiteGroups: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),

    /**
     * visibility in module gallery ('1' - visible by default, '0' - invisible)
     * @type {'1' | '0'}
     */
    VisibleInGallery: (0, _model.attr)('string'),

    /**
     * Global page capability ('1' - compatible, '0' - incompatible)
     * @type {'1' | '0'}
     */
    IsGlobalPageCompatible: (0, _model.attr)('string', {
      defaultValue: '1'
    }),

    /**
     * detecting if theme has structure or not
     * @type {boolean}
     * @readonly
     */
    hasStructure: Ember.computed.bool('Structure.length').readOnly(),

    /**
     * detecting if module supports custom css properties
     * @type {string}
     * @readonly
     */
    HasCSSCustomProperty: (0, _model.attr)('string'),

    /**
     * flag specifying whether to use custom css properties for style generation
     * @type {boolean}
     * @readonly
     */
    hasCSSCustomProperties: Ember.computed('HasCSSCustomProperty', function () {
      return !!Number(this.HasCSSCustomProperty);
    }).readOnly(),

    /**
     * Global page capability
     * @type {boolean}
     * @readonly
     */
    isGlobalPageCompatible: Ember.computed.equal('IsGlobalPageCompatible', '1').readOnly(),

    /**
     * list of style variable names
     * @type {Set}
     * @readonly
     */
    styleGlobalReferences: Ember.computed('StyleGlobalReferences', function () {
      const styleGlobalReferences = this.StyleGlobalReferences || {};
      const variableNames = [_breakpointModes.GLOBAL, _breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].reduce((names, mode) => {
        const variables = styleGlobalReferences[mode] || [];
        return [...names, ...variables];
      }, []);
      return new Set(variableNames);
    }).readOnly(),

    /**
     * Check that variableName
     * @param {String} variableName - style variable name
     * @returns {Boolean}
     */
    hasGlobalStyleReference(variableName) {
      return this.styleGlobalReferences.has(variableName);
    },

    /**
     * For now @date 21/11/2017 we don't use copy
     *
     * @returns {null}
     */
    copy() {
      return null;
    }

  });

  _exports.default = _default;
});