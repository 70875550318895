define("builder/core/enumerators/engine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.E6 = _exports.TT = _exports.E5 = void 0;

  /**
   * Enum for engine.
   */
  // Engine 5
  const E5 = '0'; // Twin-Turbo

  _exports.E5 = E5;
  const TT = '1'; // Engine 6

  _exports.TT = TT;
  const E6 = '2';
  _exports.E6 = E6;
});