define("builder/models/website-layout", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const WebsiteLayoutModel = _model2.default.extend({});
  /**
   * @class WebsiteLayoutModel
   */


  WebsiteLayoutModel.reopenClass({
    /**
     * @property {Number} LAYOUT_IS_LOCKED - layout locked value
     */
    LAYOUT_IS_LOCKED: 1,

    /**
     * @property {Number} LAYOUT_IS_UNLOCKED - layout unlocked value
     */
    LAYOUT_IS_UNLOCKED: 0
  });
  WebsiteLayoutModel.reopen({
    /**
     * @property {String} Name - layout unique name
     */
    Name: (0, _model.attr)('string'),

    /**
     * @property {Number} WebsiteId - id of website that own layout
     */
    WebsiteId: (0, _model.attr)('number'),

    /**
     * @property {Object} JsonStructure - JSON of layout structure
     *
     * ex.
     *
     * Structure: [
     *     {type: 'widget-header', children: [...]},
     *     {type: 'widget-main', children: []},
     *     {type: 'widget-footer', children: [...]},
     * ]
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * @property {String} BaseStructure - original serialized structure
     */
    BaseStructure: (0, _model.attr)('string'),

    /**
     * @property {String} IsPublished - is layout published or nor, not used on frontend
     */
    IsPublished: (0, _model.attr)('string'),

    /**
     * TODO: must be date, too lazy to make date transformer, especially when date isn't used anywhere
     * @property {String} DateCreated - layout creation date
     */
    DateCreated: (0, _model.attr)('string'),

    /**
     * @property {Number} ClonedFrom - id of page, that was used as original
     */
    ClonedFrom: (0, _model.attr)('number'),

    /**
     * @property {Number} IsLayoutLocked - defines if layout(header and footer) are locked from editing(initially)
     * could be unlocked.
     */
    IsLayoutLocked: WebsiteLayoutModel.LAYOUT_IS_LOCKED,

    /**
     * @inheritdoc
     */
    rollbackAttributes(...args) {
      this._super(...args);

      this.lockLayout();
    },

    /**
     * Lock layout
     */
    lockLayout() {
      this.set('IsLayoutLocked', WebsiteLayoutModel.LAYOUT_IS_LOCKED);
    }

  });
  var _default = WebsiteLayoutModel;
  _exports.default = _default;
});