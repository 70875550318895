define("builder/pods/components/widget-youtube-video-background/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *	Component show youtube video as background
   *
   * 	Usage:
   * 		{{widget-youtube-video-background videoId="<youtube video id>"}}
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['widget-youtube-video-background'],

    /**
     * @property {Ember.observer} videoIdObserver - detect videoId change
     */
    videoIdObserver: Ember.observer('videoId', function () {
      if (this.player) {
        this.player.loadVideoById(this.videoId);
      }
    }),

    /**
     * @property {String} videoPlayerId - unique DOM node id for embed youtube player
     * @readonly
     */
    videoPlayerId: Ember.computed('elementId', function () {
      return `youtube-video-${this.elementId}`;
    }).readOnly(),

    /**
     * @property {Object} playerVars - parameters for embedding youtube player
     * @readonly
     */
    playerVars: Ember.computed(function () {
      return {
        autoplay: 1,
        autohide: 1,
        loop: 1,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        controls: 0,
        disablekb: 1,
        enablejsapi: 0,
        origin: document.location.origin,

        /* eslint-disable */
        iv_load_policy: 3
        /* eslint-enable */

      };
    }).readOnly(),

    /**
     * @property {YT.Player|null} player - youtube player instance
     */
    player: null,

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      if (this.player && typeof this.player.destroy === 'function') {
        this.player.destroy();
      }

      this._super();
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this._loadYoutubePlayerScript();
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      if (this.videoId && !this.player) {
        this.createPlayer(this.videoPlayerId, this.playerVars).then(player => {
          player.addEventListener('onReady', this._onPlayerReady.bind(this));
          player.addEventListener('onStateChange', this._onPlayerStateChange.bind(this));

          this._onContainerResize(player, this.parentWidth, this.parentHeight);
        });
      }
    },

    /**
     * @inheritdoc
     */
    didUpdateAttrs() {
      this._onContainerResize(this.player, this.parentWidth, this.parentHeight);
    },

    /**
     * Embed youtube player in page and return promise with youtube player instance
     *
     * @method createPlayer
     * @param {String} domElementId - unique dom element id
     * @param {Object} playerVars - player options
     * @returns {Ember.RSVP.Promise}
     */
    createPlayer(domElementId, playerVars) {
      return this.waitYoutubePlayerAPI().then(() => {
        const player = new window.YT.Player(domElementId, {
          playerVars
        });
        return Ember.RSVP.resolve(player);
      });
    },

    /**
     * _loadYoutubePlayerScript - append youtube player script if one not been append before
     * @private
     */
    _loadYoutubePlayerScript() {
      if (window.YT && window.YT.Player) {
        return;
      }

      const playerScript = document.createElement('script');
      playerScript.setAttribute('src', '//www.youtube.com/player_api');
      playerScript.setAttribute('defer', true);
      this.element.appendChild(playerScript);
    },

    /**
     * Wait youtube player api
     *
     * @method waitYoutubePlayerAPI
     * @returns {Ember.RSVP.Promise}
     */
    waitYoutubePlayerAPI() {
      return new Ember.RSVP.Promise(res => {
        function checkPlayer() {
          if (window.YT && window.YT.Player) {
            res();
          } else {
            window.setTimeout(checkPlayer, 1000);
          }
        }

        checkPlayer();
      });
    },

    /**
     * Handler for youtube player container
     *
     * @method onContainerResize
     * @param {Number} width - container width
     * @param {Number} height - container height
     * @private
     */
    _onContainerResize(player, width, height) {
      const playerIframe = player ? player.getIframe() : null;
      const ratio16x9 = 16 / 9;
      let videoWidth, videoHeight;

      if (!player || !playerIframe || !(width && height)) {
        return;
      }

      if (width / height < ratio16x9) {
        videoHeight = height;
        videoWidth = videoHeight * ratio16x9;
      } else {
        videoWidth = width;
        videoHeight = videoWidth / ratio16x9;
      }

      playerIframe.style.height = `${videoHeight}px`;
      playerIframe.style.width = `${videoWidth}px`;
      playerIframe.style.marginLeft = `${(width - videoWidth) / 2}px`;
      playerIframe.style.marginTop = `${(height - videoHeight) / 2}px`;
    },

    /**
     * Handler for youtube player ready
     *
     * @method onPlayerReady
     * @param {Object} event
     * @param {YT.Player} event.target
     * @private
     */
    _onPlayerReady(event) {
      this.set('player', event.target);
      this.player.mute();

      if (!this.videoId) {
        return;
      }

      this.player.loadVideoById(this.videoId);
    },

    /**
     * Handler for youtube player change state
     *
     * @method onPlayerStateChange
     * @param {Object} event
     * @param {YT.Player} event.target
     * @private
     */
    _onPlayerStateChange(event) {
      var _window$YT, _window$YT$PlayerStat, _this$player;

      if (event.data !== ((_window$YT = window.YT) === null || _window$YT === void 0 ? void 0 : (_window$YT$PlayerStat = _window$YT.PlayerState) === null || _window$YT$PlayerStat === void 0 ? void 0 : _window$YT$PlayerStat.ENDED)) {
        return;
      }

      if (!((_this$player = this.player) === null || _this$player === void 0 ? void 0 : _this$player.playVideo) || typeof this.player.playVideo !== 'function') {
        return;
      }

      this.player.playVideo();
    }

  });

  _exports.default = _default;
});