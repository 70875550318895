define("builder/abilities/control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    /**
     * Next control that will be used for access check
     * @type {AccessControlControl|null}
     */
    successor: null,

    /**
     * Namespace of abilities, related to current control
     * @type {string}
     */
    namespace: '',

    /**
     * Message used as access denied description
     * @type {string}
     */
    message: '',

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      if (this.namespace === '') {
        throw new Ember.Error('Property "namespace" is required');
      }
    },

    /**
     * Check access
     *
     * @param {string} abilityName - name of ability to check
     * @param {string} propertyName - name of property to check
     * @param {*} model - model to be used to validated against
     * @param {object} properties - other optional properties
     * @returns {AccessControlResult}
     */
    checkAccess(abilityName, propertyName, model, properties) {
      const ability = this.abilityFor(abilityName, model, properties);
      const {
        successor
      } = this;
      const isGranted = ability ? ability.isGranted(propertyName) : true;

      if (isGranted && successor) {
        return successor.checkAccess(abilityName, propertyName, model, properties);
      }

      return {
        isGranted,
        message: isGranted ? 'success' : this.message
      };
    },

    /**
     * Get ability be name
     *
     * @param {string} abilityName - ability name
     * @param {any} model - model to set as ability model property
     * @param {object} properties - other ability properties to set
     * @returns {AccessControlAbility}
     */
    abilityFor(abilityName, model, properties) {
      const ability = this.lookup(this.getPathToAbility(abilityName));

      if (ability) {
        ability.setProperties(Ember.assign({}, {
          model
        }, properties));
      }

      return ability;
    },

    /**
     * Get lookup container
     *
     * @returns {Container} - ember container
     */
    getContainer() {
      return Ember.getOwner(this);
    },

    /**
     * Lookup component
     *
     * @param {string} what - string representation of what to lookup <namespace>:<component>
     * @returns {*}
     */
    lookup(what) {
      return this.getContainer().lookup(what);
    },

    /**
     * Get path to ability
     *
     * @param {string} abilityName - name of ability to which path should be constructed
     * @returns {string} - path to ability lookup
     */
    getPathToAbility(abilityName = '') {
      return `ability:${this.namespace}/${abilityName}`;
    }

  });

  _exports.default = _default;
});