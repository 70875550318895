define("builder/services/field-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FieldCodeService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.saveSnippetCallback = null;
      this.codePopupVisible = false;
      this.snippetText = '';
      this.snippetMode = 'htmlmixed';
    }

    _cleanInputs() {
      this.setProperties({
        saveSnippetCallback: null,
        snippetMode: 'htmlmixed',
        snippetText: '',
        codePopupVisible: false
      });
    }
    /**
     * Opens code snippet edit popup
     * @param {string} snippetText - initial snippet text
     * @param {'htmlmixed' | 'css'} mode - highlight mode
     * @param {(value:string)=>void} callback - callback to call on snippet save
     * @returns {void}
     */


    openCodePopup(snippetText, mode, callback) {
      this.setProperties({
        saveSnippetCallback: callback,
        snippetMode: mode,
        snippetText,
        codePopupVisible: true
      });
    }
    /**
     * Closes popup without saving
     * @returns {void}
     */


    codeEditCancel() {
      this._cleanInputs();
    }
    /**
     * Closes popup with current snippet state
     * @param {string} snippetText - updated snippet text
     * @returns {void}
     */


    codeSaveSuccess(snippetText) {
      if (typeof this.saveSnippetCallback === 'function') {
        this.saveSnippetCallback(snippetText);
      }

      this._cleanInputs();
    }

  }

  _exports.default = FieldCodeService;
});