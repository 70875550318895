define("builder/core/form-leadrouting-tab-config", ["exports", "builder/core/enumerators/email-format", "builder/core/enumerators/fields", "builder/config/environment"], function (_exports, _emailFormat, _fields, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    type: _fields.default.FORM_VARIANTS,
    name: 'recipients',
    label: 'Add Recipients',
    description: 'Who should receive leads from this form?',
    sortable: false,
    data: null,
    children: [{
      type: _fields.default.FORM_VARIANT,
      label: 'Enter Recipient Email',
      labelFrom: 'ToEmail',
      name: 'recipient',
      isUnique: false,
      footer: `To view, add, or edit complex lead routing details, you may do so from the <a href="${_environment.default.ignite2BaseUrl}leadrouting" target="_blank">Leads&nbsp;Routing&nbsp;page</a>.`,
      children: [{
        type: 'field-hidden',
        name: 'Id',
        data: null
      }, {
        type: _fields.default.TEXT,
        label: 'Email',
        name: 'ToEmail',
        enableValidationImmediately: true,
        data: null,
        validation: {
          required: true,
          pattern: '/^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/',
          length: {
            min: 6
          }
        }
      }, {
        type: _fields.default.DROPDOWN,
        label: 'Letter format',
        name: 'EmailFormat',
        data: _emailFormat.default.PLAIN_HTML,
        choices: [{
          value: _emailFormat.default.PLAIN_HTML,
          label: 'Plain/Html'
        }, {
          value: _emailFormat.default.ADF_XML,
          label: 'Adf/Xml'
        }],
        validation: {
          required: true
        }
      }]
    }],
    validation: {// TODO enable after refactor whole validation and concrete for form-variants
      // uniqueVariant: {
      // 	byFieldNames: [
      // 		'ToEmail',
      // 		'EmailFormat'
      // 	],
      // 	errorMessage: 'Email and letter format required and should be unique'
      // }
    }
  };
  _exports.default = _default;
});