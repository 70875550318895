define("builder/pods/components/button-toolbar-right/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Action button for right section of toolbar
   *
   * API properties:
   * 	label
   * 	isDisabled
   *  handler
   *  hasError
   *
   *
   * Example:
   *
   * {{button-toolbar-right label="Save" isDisabled=true handler=(action "doSomething")}}
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['bttn'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isDisabled:__disabled', 'hasError:__error', 'isSuccess:__success', 'isLoading:__in-progress'],

    /**
     * @inheritdoc
     */
    attributeBindings: ['type'],

    /**
     * Property contain button state(enable/disable)
     * @type {boolean}
     */
    isDisabled: false,

    /**
     * Property contain type of button.
     * It can be "button", "submit" and etc.
     * @type {string}
     */
    type: 'button',

    /**
     * @inheritdoc
     */
    tagName: 'button',

    /**
     * Property contain button label
     * @type {string|null}
     */
    label: null,

    /**
     * Property protect from multiple click on button
     * if it button handle async request or disabled
     * @type {boolean}
     * @readOnly
     */
    canWeClick: Ember.computed('isLoading', 'isDisabled', function () {
      return !this.isLoading && !this.isDisabled;
    }).readOnly(),

    /**
     * State of loading status
     * @type {boolean}
     */
    isLoading: false,

    /**
     * State of success status
     * @type {boolean}
     */
    isSuccess: false,

    /**
     * Indicate what error occurred
     * @type {boolean}
     */
    hasError: false,

    /**
     * Sentry reporting service
     * @type {SentryService}
     */
    sentry: Ember.inject.service(),

    /**
     * Method detect is Promise based on duke typing
     *
     * @param {*} object - checked object
     * @returns {boolean}
     * @private
     */
    _isPromise(object) {
      return object && typeof object.then === 'function' && typeof object.catch === 'function';
    },

    /**
     * Method which you should pass to component
     * @returns {void}
     */
    handler() {
      throw new Ember.Error('default-button error: not passed handler in "handler" property');
    },

    /**
     * If we can click, we call handler. If handler func return promise, we change
     * label on actionLabel and wait promise resolve for replace label to default
     * @returns {void}
     */
    click() {
      if (this.canWeClick) {
        const fn = this.handler(); // Probably it's a promise

        if (this._isPromise(fn)) {
          this.set('isLoading', true);
          this.set('hasError', false);
          this.set('isSuccess', false);
          fn.then(() => {
            this.set('isSuccess', true);
            Ember.run.later(this, () => {
              if (!this.isDestroyed) {
                this.set('isSuccess', false);
              }
            }, 2000);
          }).catch(error => {
            this.set('hasError', true);
            this.sentry.report(error);
          }).finally(() => {
            this.set('isLoading', false);
          });
        }
      }
    }

  });

  _exports.default = _default;
});