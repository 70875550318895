define("builder/core/cleaners/base-cleaner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    /**
     * Test if current cleaner can clean up a node
     *
     * @param {Object} node - node to test
     * @returns {Boolean}
     */
    canCleanup() {
      return false;
    },

    /**
     * Perform node clean up, remove excessive nodes
     *
     * @param {Ember.Service} structure - structure service
     * @param {Object} node - node to clean up
     * @returns {Object}
     */
    doCleanup(structure, node) {
      return node;
    }

  });

  _exports.default = _default;
});