define("builder/pods/components/menumanager-root/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "57f6DxVM",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[4,\"each\",[[24,[\"data\",\"children\"]]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"or\",[[23,1,[\"config\",\"hidden\"]],[23,1,[\"isDelete\"]]],null]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"component\",[[28,\"concat\",[\"menumanager-\",[23,1,[\"type\"]]],null]],[[\"data\",\"isOpened\",\"firstLevel\",\"changeMMSettingsTitle\",\"changePageTitle\",\"removeMenuItem\",\"changeMMTitle\",\"addToMenu\",\"openMMConfig\",\"onRender\",\"onDragStart\",\"onMouseMove\",\"onMouseUp\",\"onDestroy\",\"onScroll\",\"openPageSettingsConfigById\",\"toggleIsHidden\",\"onTitleClick\",\"onCarretClick\"],[[23,1,[]],[23,1,[\"config\",\"expanded\"]],true,[24,[\"changeMMSettingsTitle\"]],[24,[\"changePageTitle\"]],[24,[\"removeMenuItem\"]],[24,[\"changeMMTitle\"]],[24,[\"addToMenu\"]],[24,[\"openMMConfig\"]],[24,[\"onRender\"]],[24,[\"onDragStart\"]],[24,[\"onMouseMove\"]],[24,[\"onMouseUp\"]],[24,[\"onDestroy\"]],[24,[\"onScroll\"]],[24,[\"openPageSettingsConfigById\"]],[24,[\"toggleIsHidden\"]],[24,[\"onTitleClick\"]],[24,[\"onCarretClick\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/menumanager-root/template.hbs"
    }
  });

  _exports.default = _default;
});