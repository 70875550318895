define("builder/pods/components/form-popups/component", ["exports", "builder/pods/website/edit/page/edit/field-hidden-normalizer"], function (_exports, _fieldHiddenNormalizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sortByPosition(f1, f2) {
    return f1.Position - f2.Position;
  }

  var _default = Ember.Component.extend({
    /**
     * @property {Ember.Service} formFieldsService - handle drag from form add fields window into form
     */
    formFieldsService: Ember.inject.service('form-add-field'),

    /**
     * @property {StoreService} store - store service
     */
    storeService: Ember.inject.service('store'),

    /**
     * @property {Boolean} showAddFieldsPopup - Flag to show and hide add form fields popup
     */
    showAddFieldsPopup: false,

    /**
     * @property {String} popupMarginTop - margin top for popup
     */
    popupMarginTop: '140px',

    /**
     * @property {Object|Null} formObject - Component instance of current active form module
     */
    formObject: null,

    /**
     * @property {Object|Null} formFieldParams - form field params
     */
    formFieldParams: null,

    /**
     * @property {Object|Null} activeModule - Active module object
     */
    activeModule: null,

    /**
     * @property {Function|Null} changeFormConfig - Callback on form change
     */
    changeFormConfig: null,

    /**
     * @property {Boolean} showFormPrompt - flag to determine to show/hide form prompt
     */
    showFormPrompt: false,

    /**
     * @property {Array|Null} formCategories - field categories
     */
    formCategories: null,

    /**
     * @property {String|null} fieldsConfigTitle - Title for form fields config
     */
    fieldsConfigTitle: Ember.computed('formFieldParams', function () {
      return this._getFieldsConfigName(this.formFieldParams.configType);
    }),

    /**
     * @property {String} formPromptTopStyle - top position for form prompt popup
     */
    formPromptTopStyle: Ember.computed(function () {
      return Math.max(0, (document.documentElement.clientHeight - 600) / 2);
    }),

    /**
     * @property {String} fieldConfigLeftPosition - left position for field config popup
     */
    fieldConfigLeftPosition: Ember.computed('formObject', function () {
      return this._getFieldConfigLeftPosition(this.formObject.$()[0]);
    }),
    sortedCategories: Ember.computed.sort('formCategories', sortByPosition),

    /**
     * @property {Array} formFieldCategories - Array of field categories
     */
    formFieldCategories: Ember.computed('formObject', 'sortedCategories', function () {
      const categories = this.sortedCategories;
      const fields = this.formFields; // Since we cannot query categories with fields by fk, be do that by hand

      categories.forEach(category => {
        const children = fields.filterBy('CategoryId', category.get('id'));
        const existingChildren = category.get('fields');
        children.forEach(child => {
          if (!existingChildren.includes(child)) {
            existingChildren.pushObject(child);
          }
        });
        existingChildren.sort(sortByPosition);
      });
      return categories;
    }),

    /**
     * @inheritDoc
     */
    didReceiveAttrs() {
      if (this.formObject) {
        this.formFieldsService.set('formModule', this.formObject);
        this.formFieldsService.run(this.formObject);
      }
    },

    /**
     * Toggle form fields popup visibility
     * @param {Boolean} state - state of popup visibility
     */
    toggleFormFields(state) {
      this.set('showAddFieldsPopup', state);
    },

    /**
     * Return title for fields config popup
     * @param {String} configType - field type
     * @returns {String} title for fields config popup
     * @private
     */
    _getFieldsConfigName(configType) {
      switch (configType) {
        case 'field':
          {
            return 'Field Settings';
          }

        case 'button':
          {
            return 'Button Settings';
          }

        default:
          return '';
      }
    },

    /**
     * Get form fields config window left position
     * @param {Element} formElement - module for which config position will be calculated
     * @returns {String}
     * @private
     */
    _getFieldConfigLeftPosition(formElement) {
      const elementPosition = formElement.getBoundingClientRect();
      const documentPosition = document.body.getBoundingClientRect();
      const popupWidth = 355;
      let left = 0;

      if (elementPosition.left < documentPosition.width - elementPosition.right) {
        left = elementPosition.right; // Not enough space on the right to fit in form config window

        if (documentPosition.width - left < popupWidth) {
          left = documentPosition.width - popupWidth;
        }
      } else {
        left = Math.max(0, elementPosition.left - popupWidth);
      }

      return Ember.String.htmlSafe(`${left}px`);
    },

    actions: {
      closeFormFields() {
        this.set('showAddFieldsPopup', false);
      },

      closeFieldConfig() {
        this.set('formFieldParams', null);
      },

      /**
       * Action to be triggered when drag starts in add fields window
       * @param {Object} field - drag field
       */
      onFieldDragStart(field) {
        this.formFieldsService.notifyDragStart(field);
      },

      /**
       * Action to be triggered when drag ends in add fields window
       * @param {Object} field - dragged field
       */
      onFieldDragEnd(field) {
        this.formFieldsService.notifyDragEnd(field);
      },

      inputHiddenFields() {// NOP
      },

      updateHiddenFields(data, valid) {
        const formModule = this.get('formFieldsService.formModule');

        if (valid) {
          formModule.set('hiddenFields', _fieldHiddenNormalizer.default.normalize(data));
          this.formFieldsService.updateHandler(); // Notify changes

          formModule.dispatchEvent('update');
        }
      },

      /**
       * Action to close form prompt and reset it
       *
       * @method resetPrompt
       */
      resetPrompt() {
        this.set('showFormPrompt', false);
      },

      changeFormConfig(data, name, type) {
        // This is need for field-text for call only on change type event
        if (!type || type !== 'input') {
          this.changeFormConfig(data, name);
        }
      },

      /**
       * Action to apply form changes from prompt window
       * @param {String} action - user action
       * @param {String|Null} title - title of new form
       */
      applyForm(action, title) {
        this.applyForm(action, title);
      }

    }
  });

  _exports.default = _default;
});