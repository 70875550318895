define("builder/pods/components/system-module/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Router service
     * @type {Ember.Service}
     */
    router: Ember.inject.service('router'),

    /**
     * @inheritdoc
     */
    classNames: ['system-modules__item', 'b2-random-group'],

    /**
     * @property {SystemModuleModel} module - system module record, responsible for this system module
     */
    module: null,

    /**
     * @property {Function|null} actionHandler - handler to be invoked when modules event fire
     */
    actionHandler: null,

    /**
     * @property {ModuleThemeModel} selectedTheme - module selected theme
     */
    selectedTheme: Ember.computed.alias('module.selectedTheme'),

    /**
     * @property {Boolean} isLoading - is module config loading
     */
    isLoading: Ember.computed.readOnly('module.isLoadingNow'),

    /**
     * @property {ModuleModel} moduleModel - system module model record
     */
    moduleModel: Ember.computed.alias('module.module'),

    /**
     * @property {ModuleThemeModel[]} themes - list of themes that belong to current page layout
     */
    themes: Ember.computed.alias('module.themes'),

    /**
     * @property {String} thumbnailUrl - url to system module thumbnail
     */
    thumbnailUrl: Ember.computed('selectedTheme', function () {
      return this.get('selectedTheme.Image');
    }),

    /**
     * @property {Boolean} showConfigIcon - is config icon should be shown or not
     */
    showConfigIcon: Ember.computed('module.{isLoadingNow,structure,hasConfig}', function () {
      const hasConfig = !!(!this.get('module.isLoadingNow') && this.get('module.hasConfig'));

      if (hasConfig) {
        this.router.transitionTo('website.edit.page.edit.module-gallery.settings', this.get('module').ModuleConfigurationId);
      } else {
        this.router.transitionTo('website.edit.page.edit.module-gallery');
      }

      return hasConfig;
    }),

    /**
     * @property {Object[]} choices - themes data structure, prepared for field-select
     */
    choices: Ember.computed('themes', function () {
      const choices = [];
      const {
        themes
      } = this;
      const {
        selectedTheme
      } = this;

      for (let i = 0, len = themes.length; i < len; i++) {
        choices.push({
          value: themes[i].get('Name'),
          label: themes[i].get('Name'),
          checked: selectedTheme && themes[i] === selectedTheme
        });
      }

      return choices;
    }),

    /**
     * @property {Boolean} showThemesDropdown - if themes dropdown should be visible or not
     */
    showThemesDropdown: Ember.computed('themes', function () {
      // When single theme exists do not show themes dropdown
      return this.themes.length > 1;
    }),

    /**
     * Property store class name which is used when user
     * interact with "show more" and "show less" button
     * @type {string}
     */
    descriptionOpenedStateClass: 'js-opened-text',

    /**
     * @inheritdoc
     */
    didRender(...args) {
      this._super(...args);

      const container = this.element.querySelector('.system-modules__text');
      const text = container.querySelector('[data-expanded-text]');
      const button = this.element.querySelector('.system-modules__more'); // Detect to show 'show less/show more' button

      if (text.outerHeight > container.outerHeight || container.classList.contains(this.descriptionOpenedStateClass)) {
        button.classList.remove('system-modules__more_hidden');
      } else {
        button.classList.add('system-modules__more_hidden');
      }
    },

    actions: {
      /**
       * Method call when user react with checkbox(disable/enable system module)
       * and then send action to parent component
       *
       * @param {Boolean} isChecked - this option give manually set that module is checked or not
       */
      change(isChecked) {
        this.toggleModule(this.module, isChecked);
      },

      /**
       * Action handler event received from 'select' button,
       * set selected theme and hide 'show less/show more' button
       *
       * @param {String} value - name of selected theme
       */
      changeAction(value) {
        const theme = this.themes.findBy('Name', value) || null;
        const description = this.element.querySelector(`.${this.get('descriptionOpenedStateClass')}`);

        if (description) {
          description.classList.remove(this.get('descriptionOpenedStateClass'));
          description.style.height = '';
          const moreElement = this.element.querySelector('.system-modules__more');
          moreElement.classList.add('system-modules__more_hidden');
          moreElement.textContent = 'Show more';
        }

        this.changeTheme(this.module, theme);
      },

      /**
       * Action handle click event from 'show less/show more' button
       * and then expand or hide theme description
       *
       * @param {Event} event
       */
      toggleText(event) {
        const button = (0, _jquery.default)(event.currentTarget);
        const text = button.prev();

        if (text.hasClass(this.descriptionOpenedStateClass)) {
          button.text('Show more');
          text.css('height', '');
          text.removeClass(this.descriptionOpenedStateClass);
        } else {
          button.text('Show less');
          text.css('height', text.find('[data-expanded-text]').outerHeight());
          text.addClass(this.descriptionOpenedStateClass);
        }
      },

      /**
       * Invoke cog click handler
       */
      cogClick() {
        this.cogClick(this.module.ModuleConfigurationId);
      }

    }
  });

  _exports.default = _default;
});