define("builder/core/form/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeTemporaryFormId = makeTemporaryFormId;
  _exports.isTemporaryFormId = isTemporaryFormId;

  /**
   * Make temporary id for form models created from template
   *
   * @method makeTemporaryFormId
   * @param {String} moduleConfigId - module config id
   * @returns {String}
   */
  function makeTemporaryFormId(moduleConfigId) {
    return `form-from-template_${moduleConfigId}`;
  }
  /**
   * Check that id is temporary
   *
   * @method isTemporaryFormId
   * @param {String} id - form model id
   * @returns {Boolean}
   */


  function isTemporaryFormId(id) {
    return /^form-from-template_\d+$/.test(id);
  }
});