define("builder/core/abstract-classes/base-ajax-validator", ["exports", "ember-cp-validations/validators/base", "builder/core/literals", "builder/core/enumerators/cookie", "sweetalert2"], function (_exports, _base, _literals, _cookie, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * Ajax service.
     * @type {import('ember').Ember.Service}
     * @link https://github.com/ember-cli/ember-ajax
     */
    ajax: Ember.inject.service(),

    /**
     * Page service
     * @type {ConfigurationService}
     */
    configuration: Ember.inject.service(),

    /**
     * Company and website service
     * @type {import('ember').Ember.Service}
     */
    companyAndWebsite: Ember.inject.service(),

    /**
     * Element for parse and build absolute url
     * @type {HTMLAnchorElement}
     */
    locationElement: null,

    /**
     * User session id
     * @type {string}
     */
    sessionId: Ember.computed.readOnly('configuration.sessionId'),

    init(...args) {
      this._super(...args);

      this.locationElement = document.createElement('a');
    },

    /**
     * Returns Ember-ajax options
     * @param {string} value - input value
     * @param {object} options - input options
     * @param {object} model - input model
     */
    ajaxOptions() {
      throw new Ember.Error('ajaxOptions method must be overwritten');
    },

    validate(value, options, model) {
      if (options.disabled) {
        return undefined;
      }

      const ajaxOptions = this.ajaxOptions(value, options, model);
      const url = this.prepareUrl(ajaxOptions.url);
      Ember.set(model, 'isShowPreloader', true);
      return this.ajax.request(url, ajaxOptions).then(() => {
        Ember.run.next(this, () => {
          Ember.set(model, 'isShowPreloader', false);
        });
        return Ember.RSVP.resolve(true);
      }).catch(error => {
        Ember.run.next(() => {
          Ember.set(model, 'isShowPreloader', false);
        });
        let description = _literals.default.ERROR_VALIDATION_REQUEST;

        if (error && Ember.isArray(error.errors)) {
          description = error.errors.join(' ');

          _sweetalert.default.fire('Error', description, 'error');
        }

        return Ember.RSVP.resolve(this.createErrorMessage('customMessage', value, {
          description
        }));
      });
    },

    prepareUrl(url) {
      const requiredParams = [this.companyAndWebsite.getCompanyWebsiteParams(), `${_cookie.default.SESSION_ID}=${this.sessionId}`].join('&');
      this.locationElement.href = url;

      if (/^\?\w+/.test(this.locationElement.search)) {
        this.locationElement.search += `&${requiredParams}`;
      } else {
        this.locationElement.search = `?${requiredParams}`;
      }

      return this.locationElement.href;
    }

  });

  _exports.default = _default;
});