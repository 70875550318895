define("builder/helpers/get-absolute-image-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Template helper, used to make absolute url to images
   */
  var _default = Ember.Helper.extend({
    /**
     * @inheritdoc
     */
    compute(url) {
      const isAbsoluteUrl = /^\w{3,}:\/\//i.exec(url);
      return isAbsoluteUrl ? url : `${this.app.imageCdnBaseUrl}${url}`;
    }

  });

  _exports.default = _default;
});