define("builder/core/data-filters/market-segments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Prepare field config with data filter setting
   *
   * @param {Ember.Application} application - application instance
   * @param {Object} destModuleFieldConfig - field config with data filter
   * @returns {Promise}
   */
  function filter(application, destModuleFieldConfig) {
    return _getModels(application).then(models => {
      if (destModuleFieldConfig.choices && destModuleFieldConfig.choices.length) {
        // Convention with backend: first choice is default
        const defaultChoice = destModuleFieldConfig.choices.objectAt(0);
        destModuleFieldConfig.choices = models.map(model => ({
          label: model.get('Name'),
          value: model.get('id')
        }));
        destModuleFieldConfig.choices.unshiftObject(defaultChoice);
      } else {
        throw new Ember.Error('Choices must be present in choices.');
      }

      return Ember.RSVP.resolve(destModuleFieldConfig);
    });
  }
  /**
   * Get market segment models
   *
   * @param {Ember.Application} application - application instance
   * @returns {Promise.<DS.Models[]>}
   */


  function _getModels(application) {
    return application.lookup('service:store').findAll('market-segment').then(models => models.filter(model => !model.get('isDeleted')));
  }

  var _default = filter;
  _exports.default = _default;
});