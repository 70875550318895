define("builder/pods/components/top-container/component", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['top-container'],
    // If is popup we added e6 "popup" class
    classNameBindings: ['isPopup:popup']
  });

  _exports.default = _default;
});