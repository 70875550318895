define("builder/models/form-field-type", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormFieldTypeModel
   */
  var _default = _model2.default.extend({
    /**
     * Field type
     * @type {string}
     */
    Type: (0, _model.attr)('string'),

    /**
     * Field name
     * @type {string}
     */
    Name: (0, _model.attr)('string'),

    /**
     * Field title
     * @type {string}
     */
    Title: (0, _model.attr)('string'),

    /**
     * Flag indicates the uniqueness of the field
     * @type {string}
     */
    IsUnique: (0, _model.attr)('string'),

    /**
     * Id of category the field belongs to
     * @property {string}
     */
    CategoryId: (0, _model.attr)('string'),

    /**
     * Field settings form structure
     * @type {Object[]}
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * Field position if its category
     * @type {number}
     */
    Position: (0, _model.attr)('number'),

    /**
     * Flag to indicates whether field is enabled
     * @type {boolean}
     */
    enabled: true,

    /**
     * Reason why field disabled
     * @type {string}
     */
    disabledReason: null
  });

  _exports.default = _default;
});