define("builder/adapters/form-field", ["exports", "builder/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: 'http://preview.b2-b-27796-redis.ignite2.dealerfire.com',

    pathForType() {
      return 'form_fields';
    }

  });

  _exports.default = _default;
});