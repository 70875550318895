define("builder/serializers/module-style", ["exports", "builder/serializers/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleStyleSerializer
   */
  var _default = _style.default.extend({});

  _exports.default = _default;
});