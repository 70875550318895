define("builder/core/module-config-handlers/base", ["exports", "ember-copy", "builder/core/enumerators/breakpoint-modes"], function (_exports, _emberCopy, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Module config change handlers base class
   *
   * @class ModuleConfigHandler
   */
  var _default = Ember.Object.extend({
    /**
     * @property {String} _dataKey - key under which data to process by handler lies(for modules it's Structure & Strings)
     * @protected
     */
    _dataKey: '',

    /**
     * Handle module config change, for single field
     *
     * @param {DS.Model} config - module config model
     * @param {Ember.Object|Object} field - reference to field which data has been changed
     * @param {String} name - field name
     * @param {*} value - new value to set
     * @param {String} mode - breakpoint mode for which data to be set
     * @returns {Boolean} - returns true if change handled successfully otherwise false
     */
    handle(config, field, name, value, mode = _breakpointModes.LARGE) {
      const dataKey = this._dataKey;
      let data = (0, _emberCopy.copy)(config.get(dataKey), true); // Since for some reason when no data provided, structure is empty array, we should check for that

      if (!this._isNormalized(data)) {
        data = this._getNormalizedData();
      } // Here we copy new value to prevent error related to passing value by reference
      // ex. form-buttons sets value field.data that can affect config value inside of the component
      // ex2. we change smth on clean model, then we make model dirty, after that we save model,
      // after saving we make dirty again, but because Ember inside moves _attributes to _data by reference
      // and data passed to components is not re references, any set inside component affects model _data directly


      data = this._handle(data, field, name, (0, _emberCopy.copy)(value, true), mode); // Make config model dirty, in order to do that we must set new object into key "Structure", since modification
      // of existing structure will not mark model as dirty

      config.set(dataKey, data);
      return config;
    },

    /**
     * Handle method to be overridden by descendant handlers
     *
     * @param {PageModuleModel} config - config model
     * @param {Ember.Object} field - content of our originObject
     * @param {String} name - name of field to set value
     * @param {*} value - new value to set to field by name
     * @returns {PageModuleModel} - config model
     * @protected
     */
    _handle(config) {
      return config;
    },

    /**
     * Check is data under specified key(_dataKey) is normalized
     *
     * @param {Object} data - data to verify
     * @returns {Boolean} - true if data normalized other wise false
     * @protected
     */
    _isNormalized(data) {
      return !Ember.isArray(data);
    },

    /**
     * If data is not normalized this method will be invoked to get normalized data
     * @returns {*} - normalized data
     * @protected
     */
    _getNormalizedData() {
      return {};
    }

  });

  _exports.default = _default;
});