define("builder/pods/components/system-modules/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * NOTICE: systemModules property in component contains references to objects in controller same property
   * so be very careful in changes
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['system-modules'],

    /**
     * @property {Boolean} preload - Property need only if you need to show preload state for this component
     */
    preload: false,

    /**
     * @property {Object|null} systemModules - Property contains main data from controller
     */
    systemModules: null,

    /**
     * Method set max-height to system modules container
     *
     * @method _setContainerMaxHeight
     * @private
     */
    _setContainerMaxHeight() {
      const windowHeight = document.documentElement.clientHeight;
      const footerHeight = document.querySelector('.footer').offsetHeight;
      const headerHeight = document.querySelector('.header').offsetHeight;
      this.element.style['max-height'] = windowHeight - this.element.offsetTop - footerHeight - headerHeight;
    },

    /**
     * Method set the position of system modules container
     *
     * @method _position
     * @private
     */
    _position() {
      const btnSystem = (0, _jquery.default)('.js-system-nav');
      const offset = btnSystem.position();
      const mockupLeftOffset = 48;
      this.element.style.left = Math.floor(offset.left - mockupLeftOffset);
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this._position(); // Work only for <website.edit.page.edit.module-loading> route


      if (this.preload) {
        this.element.classList.add('system-modules_preload flex flex_center');
      } else {
        this._setContainerMaxHeight();

        (0, _jquery.default)(window).on('resize.system-modules', Ember.run.bind(this, this._setContainerMaxHeight));
      }
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      (0, _jquery.default)(window).off('resize.system-modules');
    },

    actions: {
      /**
       * Action to set module in active/inactive state
       *
       * @param {SystemModuleModel} module - module that has been toggled
       * @param {Boolean} isChecked - toggle state, module checked or unchecked
       */
      toggleModule(module, isChecked) {
        this.toggleModule(module, isChecked);
      },

      /**
       * Action toggle module theme
       *
       * @param {SystemModuleModel} module - system module which theme has been changed
       * @param {ModuleThemeModel} theme - theme selected
       */
      changeTheme(module, theme) {
        this.changeTheme(module, theme);
      },

      /**
       * Action redirect to config page
       * @param {Number} configId - System module config id
       */
      cogClick(configId) {
        if (typeof this.cogClick === 'function') {
          this.cogClick(configId);
        }
      }

    }
  });

  _exports.default = _default;
});