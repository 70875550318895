define("builder/pods/components/field-multiselect/component", ["exports", "jquery", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _jquery, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['bld-form_multiselect'],

    /**
     * Show can we add new or not
     * @type {boolean}
     */
    canAddNew: false,

    /**
     * Show open choices dropdown or not
     * @type {boolean}
     */
    isOpenChoices: false,

    /**
     * Show expandable list or not
     * @type {boolean}
     */
    isExpanded: false,

    /**
     * Disable add from choices or not
     * @type {boolean}
     */
    disableAddFromChoices: false,

    /**
     * Maximum not expandable items count
     * @type {number}
     */
    maxNotExpandableItemsCount: 5,

    /**
     * Handler called after change item sort
     * @type {({oldIndex:number, newIndex:number})=>void}
     */
    sortingHandler: () => {},

    /**
     * Handler called after remove item
     * @type {({index:number})=>void}
     */
    removeHandler: () => {},

    /**
     * Handler called after rename item label
     * @type {({index:number, label:string})=>void}
     */
    renameLabelHandler: () => {},

    /**
     * Handler called after add new item
     * @type {({label:string})=>void}
     */
    addNewHandler: () => {},

    /**
     * Handler called after select item
     * @type {({index:number})=>void}
     */
    selectHandler: () => {},

    /**
     * Handler called on change data
     * @type {(data:string[], fieldName:string)=>void}
     */
    actionHandler: () => {},

    /**
     * Expandable list
     * @type {Object}
     */
    expandableList: Ember.computed('list.[]', 'isExpanded', function () {
      const {
        list
      } = this;

      if (!this.isExpanded && list.length > this.maxNotExpandableItemsCount) {
        return list.slice(0, this.maxNotExpandableItemsCount);
      }

      return list;
    }),

    /**
     * Show list or not
     * @type {boolean}
     */
    showList: Ember.computed('expandableList.[]', 'canAddNew', function () {
      return this.expandableList.length > 0 || this.canAddNew;
    }),

    /**
     * Rest list count
     * @type {number}
     */
    restListCount: Ember.computed('list.[]', function () {
      const {
        length
      } = this.list;
      return length > this.maxNotExpandableItemsCount ? length - this.maxNotExpandableItemsCount : 0;
    }),

    /**
     * Placeholder for input
     * @type {string}
     */
    placeholder: Ember.computed('field.placeholder', function () {
      return this.field.placeholder || 'Type...';
    }),

    /**
     * Choices list
     * @type {Object[]}
     */
    choices: Ember.computed('field.choices', function () {
      const objChoices = this.field.choices;

      if (objChoices) {
        const {
          list
        } = this;
        const choices = [];
        objChoices.forEach(choice => {
          if (!list || !list.filterBy('key', choice.value).length) {
            choices.pushObject({
              key: choice.value,
              value: choice.label
            });
          }
        });
        return choices;
      }

      return null;
    }),

    /**
     * Show Add new choices
     * @type {boolean}
     */
    showAddNewChoices: Ember.computed('choices.[]', 'disableAddFromChoices', function () {
      return this.choices !== null || this.disableAddFromChoices;
    }),

    /**
     * Show Add new form
     * @type {boolean}
     */
    showAddNewForm: Ember.computed('choices.[]', function () {
      return this.choices === null;
    }),

    /**
     * Show is sortable or not
     * @type {boolean}
     */
    isSortable: Ember.computed.oneWay('field.sortable'),

    /**
     * Show is editable or not
     * @type {boolean}
     */
    isEditable: Ember.computed.oneWay('field.editable'),

    /**
     * Min allowed number of items
     * @type {number}
     */
    minItems: Ember.computed('field.validation.elements.min', function () {
      const minItems = parseInt(this.get('field.validation.elements.min'), 10);
      return isFinite(minItems) ? Math.abs(minItems) : 0;
    }),

    /**
     * Number of max allowed items
     * @type {number}
     */
    maxItems: Ember.computed('field.validation.elements.max', function () {
      const maxItems = parseInt(this.get('field.validation.elements.max'), 10);
      return isFinite(maxItems) ? Math.abs(maxItems) : 0;
    }),

    /**
     * Is it allowed to add items
     * @type {boolean}
     */
    canAdd: Ember.computed('field.data.[]', 'maxItems', function () {
      const {
        maxItems
      } = this;
      const length = this.get('field.data.length') || 0; // If maxItems = 0 - means no validation applied, any number of items allowed

      return maxItems === 0 || length < maxItems;
    }),

    /**
     * Can remove items or not
     * @type {boolean}
     */
    canRemove: Ember.computed('field.data.[]', 'minItems', function () {
      const {
        minItems
      } = this;
      const length = this.get('field.data.length') || 0; // If minItems = 0 - no min limit is set

      return minItems === 0 || length > minItems;
    }),

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args);

      const list = this._createList();

      this.set('list', list); // Select first item

      if (list && list.length) {
        this._callSelectHandler(list[0]);
      }
    },

    /**
     * @inheritDoc
     */
    willInsertElement(...args) {
      this._super(...args);

      this.applyValidations();
    },

    /**
     * @inheritDoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this._attachSortable();

      this._attachClickHandler();
    },

    /**
     * @inheritDoc
     */
    willDestroyElement(...args) {
      this._super(...args);

      this._detachSortable();

      this._detachClickHandler();
    },

    /**
     * @inheritDoc
     */
    didUpdate(...args) {
      this._super(...args);

      if (this.isSortable) {
        this.$('.multiselect-items').sortable('refresh');
      }
    },

    /**
     * Create items list
     * @returns {Object[]}
     * @private
     */
    _createList() {
      const data = this.get('field.data') || [];
      const choices = this.get('field.choices') || [];
      return data.map(item => {
        let choice;

        if (typeof item === 'string') {
          choice = choices.findBy('value', item);
        } else if (typeof item === 'object' && item.hasOwnProperty('label') && item.hasOwnProperty('value')) {
          choice = choices.findBy('value', item.value) || item;
        }

        return {
          value: choice ? choice.label : item,
          key: choice ? choice.value : item,
          isSelect: false,
          isNew: false,
          isEdit: false
        };
      });
    },

    /**
     * Outside component click handler
     * @param {Event} event - event
     * @returns {boolean}
     * @private
     */
    _outsideClickHandler(event) {
      const target = (0, _jquery.default)(event.target);

      if (target.closest('.add-new').length) {
        return true;
      }

      if (!target.hasClass('multiselect-choice')) {
        this._hideDropdown();
      }

      return true;
    },

    /**
     * Attach click handler
     * @returns {void}
     * @private
     */
    _attachClickHandler() {
      // NOTE: why do we need this? This event listener does nothing, but adds listeners to whole page
      // TODO: kill it with fire. Investigate and remove this jquery legacy
      (0, _jquery.default)(document).on(`click.multiselect-${this.elementId}`, this._outsideClickHandler.bind(this));
    },

    /**
     * Detach click handler
     * @returns {void}
     * @private
     */
    _detachClickHandler() {
      (0, _jquery.default)(document).off(`click.multiselect-${this.elementId}`);
    },

    /**
     * Attach sortable handler
     * @returns {void}
     * @private
     */
    _attachSortable() {
      if (this.isSortable) {
        const self = this;
        this.$('.multiselect-items').sortable({
          items: '.multiselect-item.__draggable',
          distance: 5,
          axis: 'y',
          cursor: 'move',

          start(event, ui) {
            let items = (0, _jquery.default)(this).sortable('option', 'items');
            items = self.$(items);
            ui.item.data('oldIndex', items.index(ui.item));

            self._highlightIsNewOff();
          },

          stop(event, ui) {
            const $this = (0, _jquery.default)(this);
            const items = self.$($this.sortable('option', 'items'));
            const oldIndex = ui.item.data('oldIndex');
            const newIndex = items.index(ui.item);

            if (oldIndex !== newIndex) {
              $this.sortable('cancel');

              self._sort(oldIndex, newIndex);

              self._callSortingHandler(oldIndex, newIndex);
            }
          }

        });
      }
    },

    /**
     * Detach sortable handler
     * @returns {void}
     * @private
     */
    _detachSortable() {
      if (this.isSortable) {
        this.$('.multiselect-items').sortable('destroy');
      }
    },

    /**
     * Apply sort
     * @param {number} oldIndex - old index
     * @param {number} newIndex - new index
     * @returns {void}
     * @private
     */
    _sort(oldIndex, newIndex) {
      const {
        list
      } = this;
      const item = list.objectAt(oldIndex);
      list.removeAt(oldIndex);
      list.insertAt(newIndex, item);

      this._highlightIsNewOff();

      this._onChange();
    },

    /**
     * Perform action after add event
     * @returns {void}
     * @private
     */
    _afterAddEvent() {
      this.set('isExpanded', true);
    },

    /**
     * Add isSelect highlight for item
     * @param {Object} item - item for select
     * @returns {void}
     * @private
     */
    _highlightOn(item) {
      this._highlightOff();

      Ember.set(item, 'isSelect', true);
    },

    /**
     * Remove isSelect highlight for all items
     * @returns {void}
     * @private
     */
    _highlightOff() {
      const {
        list
      } = this;
      list.forEach(item => {
        Ember.set(item, 'isSelect', false);
      });
    },

    /**
     * Remove isNew highlight
     * @returns {void}
     * @private
     */
    _highlightIsNewOff() {
      const {
        list
      } = this;

      if (list.length) {
        const last = list.get('lastObject');
        Ember.set(last, 'isNew', false);
      }
    },

    /**
     * Hide dropdown
     * @returns {void}
     * @private
     */
    _hideDropdown() {
      this.set('canAddChoice', false);
      const list = this.$('.multiselect-choices');

      if (list && list.length) {
        list.get(0).scrollTop = 0;
      }
    },

    /**
     * Push new item
     * @param {string} value - value
     * @param {string} key - key
     * @returns {void}
     * @private
     */
    _pushNewItem(value, inputKey) {
      const {
        list
      } = this;
      const key = inputKey || value;

      this._highlightOff();

      list.pushObject({
        value,
        key,
        isSelect: true,
        isNew: true,
        isEdit: false
      });
      this.set('canAddNew', false);

      this._callAddNewHandler(value);

      this._onChange();
    },

    /**
     * Show dropdown
     * @returns {boolean}
     * @private
     */
    _showDropdown() {
      if (this.disableAddFromChoices && this.choices) {
        return false;
      }

      this._highlightIsNewOff();

      if (this.canAddChoice) {
        this._hideDropdown();

        return false;
      }

      this.set('canAddChoice', true);
      return true;
    },

    /**
     * Select choice
     * @param {string} choice
     * @private
     */
    _selectChoice(choice) {
      if (this.canAdd) {
        const {
          choices
        } = this;

        this._pushNewItem(choice.value, choice.key);

        choices.removeObject(choice);

        if (choices.length === 0) {
          this.set('disableAddFromChoices', true);
        }

        this._hideDropdown();
      }
    },

    /**
     * Handler onChange
     * @returns {void}
     * @private
     */
    _onChange() {
      const {
        list
      } = this;
      const data = list.map(item => {
        if (!this._isChoicesKey(item.key)) {
          return item.value;
        }

        return item.key;
      });
      this.set('field.data', data);

      if (!this.notValid) {
        this.actionHandler(data, this.field.name);
      }
    },

    /**
     * Check key for exists in choices
     * @param {string} key - key of choice
     * @returns {boolean}
     * @private
     */
    _isChoicesKey(key) {
      const choices = this.get('field.choices') || [];
      return !!choices.findBy('value', key);
    },

    /**
     * Remove item
     * @param {Object} item - removed item
     * @returns {void}
     * @private
     */
    _removeItem(item) {
      if (this.canRemove) {
        const {
          list
        } = this;
        const {
          choices
        } = this;
        const index = list.indexOf(item); // If has selectHandler and it was selected item, we select first item

        if (item.isSelect) {
          this._callSelectHandler(list[0]);
        }

        if (choices) {
          choices.pushObject({
            key: item.key,
            value: item.value
          });
          this.set('disableAddFromChoices', false);
        }

        list.removeAt(index);

        this._callRemoveHandler(index);

        this._highlightIsNewOff();

        this._onChange();
      }
    },

    /**
     * Change item label
     * @param {Object} item - removed item
     * @param {string} label - new item label
     * @returns {void}
     * @private
     */
    _changeLabel(item, label) {
      const index = this.list.indexOf(item);

      this._onChange();

      this._callRenameLabelHandler(index, label);
    },

    /**
     * Call sorting handler if exists
     * @param {number} oldIndex - old item index
     * @param {number} newIndex - new item index
     * @returns {void}
     * @private
     */
    _callSortingHandler(oldIndex, newIndex) {
      this.sortingHandler({
        oldIndex,
        newIndex
      });
    },

    /**
     * Call remove handler if exists
     * @param {number} index - index of the deleted item
     * @returns {void}
     * @private
     */
    _callRemoveHandler(index) {
      this.removeHandler({
        index
      });
    },

    /**
     * Call remove handler if exists
     * @param {number} index - index of the deleted item
     * @param {string} label - new item label
     * @returns {void}
     * @private
     */
    _callRenameLabelHandler(index, label) {
      this.renameLabelHandler({
        index,
        label
      });
    },

    /**
     * Call add new handler if exists
     * @param {string} label - label of new item
     * @returns {void}
     * @private
     */
    _callAddNewHandler(label) {
      this.addNewHandler({
        label
      });
    },

    /**
     * Select item
     * @param {Object} item - removed item
     * @returns {void}
     * @private
     */
    _callSelectHandler(item) {
      const index = this.list.indexOf(item);

      this._highlightOn(item);

      this._highlightIsNewOff();

      this.selectHandler({
        index
      });
    },

    actions: {
      /**
       * Show dropdown action
       * @returns {void}
       */
      showDropdownAction() {
        if (this.canAdd) {
          this._showDropdown();
        }
      },

      /**
       * Show form
       * @param {boolean} [clear=false] - need clear or not
       * @returns {void}
       */
      showForm(clear = false) {
        if (this.canAdd && !this.canAddNew) {
          this.set('canAddNew', true);

          this._highlightIsNewOff();

          this._afterAddEvent();

          Ember.run.scheduleOnce('afterRender', this, () => {
            const input = this.$().find('input');
            input.focus();

            if (clear) {
              input.val('');
            }
          });
        }
      },

      /**
       * Select choice and scroll
       * @param {Object} choice - selected choice
       * @returns {void}
       */
      selectChoiceAndScroll(choice) {
        if (this.canAdd) {
          this._selectChoice(choice);

          this._afterAddEvent();
        }
      },

      /**
       * Expand list
       * @returns {void}
       */
      expandList() {
        this.set('isExpanded', true);
      },

      /**
       * Collapse list
       * @returns {void}
       */
      collapseList() {
        this.set('isExpanded', false);

        this._highlightIsNewOff();
      },

      /**
       * Edit item
       * @returns {void}
       */
      editItem() {
        this._highlightOff();

        this._highlightIsNewOff();
      },

      /**
       * Delete item
       * @param {Object} item - item
       * @returns {void}
       */
      deleteItem(item) {
        this._removeItem(item);
      },

      /**
       * Handle new item by key
       * @param {string} value - item value
       * @param {Event} event - event
       * @returns {void}
       */
      handleNewItemByKey(value, event) {
        if (event.keyCode === 13) {
          event.preventDefault();

          if (value.trim()) {
            this._pushNewItem(value);

            this.send('showForm', true);
          }
        }

        if (event.keyCode === 27) {
          event.stopPropagation();
          this.set('canAddNew', false);
        }
      },

      /**
       * Save filled new item
       * @param {string} value - item value
       * @returns {void}
       */
      saveFilledNewItem(value) {
        if (value.trim()) {
          this._pushNewItem(value);
        } else {
          this.set('canAddNew', false);
        }
      },

      /**
       * Call change item label
       * @param {Object} item - item
       * @param {string} label - new label
       * @returns {void}
       */
      onChangeLabel(item, label) {
        this._highlightOn(item);

        this._changeLabel(item, label);
      },

      /**
       * Call select item handler
       * @param {Object} item - item
       * @returns {void}
       */
      selectHandler(item) {
        this._callSelectHandler(item);
      }

    }
  });

  _exports.default = _default;
});