define("builder/instance-initializers/access-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    const tierControl = applicationInstance.lookup('access-control:tier-control');
    const oemControl = applicationInstance.lookup('access-control:oem-control');
    const authControl = applicationInstance.lookup('access-control:authorization-control');
    tierControl.set('successor', oemControl);
    oemControl.set('successor', authControl);
    applicationInstance.inject('service:can', 'control', 'access-control:tier-control');
  }

  var _default = {
    name: 'access-control',
    initialize
  };
  _exports.default = _default;
});