define("builder/models/module-category-item", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleCategoryItemModel
   */
  var _default = _model2.default.extend({
    CategoryId: (0, _model.attr)('string'),
    ItemId: (0, _model.attr)('string'),
    Type: (0, _model.attr)('number'),
    Order: (0, _model.attr)('number')
  });

  _exports.default = _default;
});