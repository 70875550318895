define("builder/widgets/data-setters/widget-buttonsgroup", ["exports", "ember-copy", "builder/widgets/data-setters/widget-button", "builder/core/enumerators/breakpoint-modes"], function (_exports, _emberCopy, _widgetButton, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: move this to initializer, maybe

  /**
   * @class WidgetButtonsGroupDataSetter
   */
  var _default = Ember.Object.create({
    /**
     * @property {String[]} _generalProperties - list of properties that directly belongs to buttonsgroup
     * @private
     */
    _generalProperties: ['aligned', 'layout', 'size', 'buttonTextLayout'],

    /**
     * Set data to widget(originObject)
     *
     * @param {EmberObject} component - originObject where value should be set
     * @param {String} name - name of property to set
     * @param {*} value - value to set
     * @returns {EmberObject} - component back
     */
    setData(component, name, value) {
      if (name === 'visibleOnDevices') {
        [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
          Ember.set(component, `visibleOnDevices.${mode}`, value.includes(mode));
        });
        return component;
      }

      if (this._generalProperties.includes(name)) {
        // Need to set value, to trigger computed properties/observers
        Ember.set(component, name, value); // Default size not needed on website
        // TODO: should be exclusive property handlers, when new property will be added, make handlers for each property

        if (name === 'size' && value === 'default') {
          delete component.size;
        }
      } else {
        const setter = _widgetButton.default;
        const {
          buttons
        } = value;
        const cButtons = Ember.get(component, 'buttons');
        let button;

        for (let i = 0, len = buttons.length; i < len; i++) {
          button = cButtons[i];

          if (!button) {
            button = (0, _emberCopy.copy)(buttons[i], true);
            cButtons.pushObject(button);
          } // TODO: validate exact button and exact field to prevent excessive runs


          setter.setData(button, 'title', Ember.get(buttons[i], 'title'));
          setter.setData(button, 'icon', Ember.get(buttons[i], 'icon'));
          setter.setData(button, 'url', Ember.get(buttons[i], 'url'));
          setter.setData(button, 'width', Ember.get(buttons[i], 'width'));
          setter.setData(button, 'style', Ember.get(buttons[i], 'style'));
        }

        Ember.set(component, 'group', Ember.get(value, 'group'));
      }

      return component;
    }

  });

  _exports.default = _default;
});