define("builder/pods/components/field-media-active-area-image-compact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uOjPIqI5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"flex__item flex__item_field-media \",[28,\"unless\",[[24,[\"field\",\"data\",\"type\"]],\"flex__item_hidden\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"a\",true],[10,\"class\",\"media-button\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[11,\"href\",[28,\"get-absolute-image-url\",[[24,[\"field\",\"data\",\"url\"]]],null]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-link\"],[11,\"title\",[22,\"fileName\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"media-button\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-refresh\"],[3,\"action\",[[23,0,[]],\"open\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"media-button\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-close\"],[3,\"action\",[[23,0,[]],\"delete\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"flex__item flex__item_field-media \",[28,\"if\",[[24,[\"field\",\"data\",\"type\"]],\"flex__item_hidden\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"span\",false],[12,\"class\",\"media-button\"],[3,\"action\",[[23,0,[]],\"open\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-media-active-area-image-compact/template.hbs"
    }
  });

  _exports.default = _default;
});