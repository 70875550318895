define("builder/core/module-config-handlers/configuration", ["exports", "builder/core/module-config-handlers/base", "builder/core/data-setters/fields/field-media", "builder/core/enumerators/fields", "builder/utils/fields", "builder/core/util/extend", "builder/core/util/is-object", "ember-copy"], function (_exports, _base, _fieldMedia, _fields, _fields2, _extend, _isObject, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO: Here we go again, now it's obvious that config handler became kinda complicated, similar to configuration-builder
   * we can apply the same flow here, to update configs of widgets and modules, get data, serialize it, update config and
   * that's it, but layer like field-specific layer wil persist,
   *
   * <ConfigurationHander> => <Component Specific Handler> => <Field Specific Serializer>,
   * Why we need Component Specific Handler? Cause in some cases we should remove keys with null or undefined values,
   * or change some keys names, etc.
   *
   * @class ModuleConfigConfigurationHandler
   */
  var _default = _base.default.extend({
    /**
     * @inheritdoc
     */
    _dataKey: 'Structure',

    /**
     * @inheritdoc
     */
    _handle(structure, field, name, value, mode) {
      // Null values must not be sent to server, by convention, if field has no value, it's key must be removed
      if (value === null || field && field.hasOwnProperty('type') && field.type === _fields.default.LINK && field.data.value === null) {
        delete structure[name];
      } else if (field.type === _fields.default.CONTAINER) {
        Ember.set(structure, name, (0, _extend.default)(true, structure[name], value));
      } else if (field.type === _fields.default.MEDIA && field.validation.hasOwnProperty('image')) {
        const setter = _fieldMedia.default.create();

        let data = (0, _emberCopy.copy)(structure[name], true);

        if (!data || !(0, _isObject.default)(data)) {
          data = {};
        }

        setter.setData(data, 'url', value, mode);
        Ember.set(structure, name, data);
      } else if (field.type === _fields.default.FORM_VARIANTS) {
        const setter = _fieldMedia.default.create();

        const data = (0, _emberCopy.copy)(structure[name], true) || []; // Collect all fields from all variants that contains field-media of image type

        const imageVariants = _fields2.default.findFieldsInFormVariants(field, variantField => variantField.type === _fields.default.MEDIA && variantField.validation.hasOwnProperty('image')); // Remove removed data from data list


        data.length = value.length; // Now magic begins

        value.forEach((variantData, index) => {
          // Get all field variants for current variant, variant current field belongs to
          const imageVariantNames = imageVariants.filter(imageVariant => imageVariant.variant.name === variantData['variant-name']).map(filteredImageVariant => filteredImageVariant.field.name); // Add missing data template

          if (!data[index]) {
            data[index] = {};
          } // The strategy is, for each field we have separate setter, and each setter should be applied to appropriate
          // field, for now only field-media of image type is supported, extend field-media to support other types.


          if (variantData) {
            Object.keys(variantData).forEach(key => {
              if (imageVariantNames.includes(key) && variantData[key] !== null) {
                // By default if field-media has no data, it's value is null
                if (!data[index].hasOwnProperty(key) || data[index][key] === null) {
                  Ember.set(data[index], key, {});
                }

                setter.setData(data[index][key], 'url', variantData[key], mode);
              } else {
                data[index][key] = variantData[key];
              }
            });
          }
        });
        Ember.set(structure, name, data);
      } else {
        Ember.set(structure, name, value);
      }

      return structure;
    }

  });

  _exports.default = _default;
});