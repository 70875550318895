define("builder/pods/website/edit/page/edit/styles/route", ["exports", "builder/routes/application", "builder/core/styles-management/structure", "builder/core/enumerators/controller-action"], function (_exports, _application, _structure, _controllerAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WebsiteEditPageEditStylesRoute
   */
  var _default = _application.default.extend({
    /**
     * @inheritdoc
     */
    model() {
      const websiteEditModel = this.modelFor('website.edit');
      return Ember.RSVP.hash({
        smVariables: websiteEditModel.styles,
        websiteFonts: websiteEditModel.websiteFonts
      });
    },

    /**
     * @inheritdoc
     */
    setupController(controller, ...args) {
      this._super(controller, ...args);

      controller.set('pageController', this.controllerFor('website.edit.page'));
      controller.set('stylesManagementStructure', _structure.default);
    },

    /**
     * @inheritDoc
     */
    activate() {
      this.send('handleBubbleAction', _controllerAction.default.OPEN_GLOBAL_STYLES);
    },

    /**
     * @inheritDoc
     */
    deactivate() {
      this.send('handleBubbleAction', _controllerAction.default.CLOSE_GLOBAL_STYLES);
    },

    actions: {
      /**
       * @inheritdoc
       */
      willTransition(transition) {
        const pageEditController = this.controllerFor('website.edit.page.edit');

        if (pageEditController.get('lockStylesRoute')) {
          pageEditController.set('lockStylesRoute', false);
          transition.abort();
        }
      },

      handleBubbleAction() {
        // Bubble action up to parent route
        return true;
      }

    }
  });

  _exports.default = _default;
});