define("builder/pods/components/field-number-with-units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qdv7U6as",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"widget-field-info\",[],[[\"@field\",\"@validation\"],[[22,\"field\"],[22,\"validationsMessage\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-form_number_with_units_container\"],[8],[0,\"\\n\\t\"],[7,\"input\",true],[11,\"min\",[22,\"min\"]],[11,\"max\",[22,\"max\"]],[11,\"value\",[22,\"value\"]],[11,\"name\",[24,[\"field\",\"name\"]]],[11,\"class\",[22,\"size\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onValueChange\"],null]],[10,\"type\",\"number\"],[8],[9],[0,\"\\n\\t\"],[5,\"field-select\",[],[[\"@content\",\"@data\",\"@attr\",\"@changeAction\",\"@disabled\"],[[22,\"units\"],[22,\"unit\"],[24,[\"field\",\"attr\"]],[28,\"action\",[[23,0,[]],\"onUnitChange\"],null],false]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-number-with-units/template.hbs"
    }
  });

  _exports.default = _default;
});