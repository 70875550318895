define("builder/services/sentry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Send error reports to Sentry
   * @class SentryService
   */
  var _default = Ember.Service.extend({
    /**
     * Service for feature toggling
     */
    features: Ember.inject.service(),

    /**
     * Enabled or disabled Sentry reporting
     * @type {boolean}
     * @readonly
     */
    enabled: Ember.computed(function () {
      const sdk = this._getSDK();

      return !!(this.features.isEnabled('experiment-sentry') && sdk && typeof sdk.captureException === 'function' && typeof sdk.captureMessage === 'function');
    }).readOnly(),

    /**
     * Report exception to Sentry
     * @param {Error|string} exception - exception
     * @returns {void}
     */
    report(exception) {
      if (!this.enabled || !exception) {
        return;
      }

      const sdk = this._getSDK();

      try {
        if (exception instanceof Error) {
          sdk.captureException(exception);
        } else {
          sdk.captureMessage(Object.prototype.toString.call(exception));
        }
      } catch (e) {
        window.console.warn('Error report was failed. Sentry SDK is not provided.', e);
      }
    },

    /**
     * Return Sentry SDK instance
     * @returns {Object}
     * @private
     */
    _getSDK() {
      return window.Sentry;
    }

  });

  _exports.default = _default;
});