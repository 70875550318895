define("builder/adapters/page", ["exports", "builder/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @inheritdoc
   */
  var _default = _application.default.extend({
    pathForType() {
      return 'page';
    }

  });

  _exports.default = _default;
});