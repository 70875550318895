define("builder/pods/components/color-picker-palette/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U32yNny2",
    "block": "{\"symbols\":[\"colorLine\",\"color\"],\"statements\":[[4,\"each\",[[24,[\"palette\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"b2-color-picker-palette__line b2-random-group b2-random-group_palette\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"b2-color-picker-palette__color s-dropdown__color-block s-dropdown__color-block_single\"],[11,\"title\",[23,2,[\"label\"]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"b2-color-picker-palette__background s-dropdown__color\"],[12,\"style\",[28,\"html-format\",[[28,\"concat\",[\"background-color: \",[23,2,[\"value\"]],\";\"],null]],null]],[3,\"action\",[[23,0,[]],\"chooseColor\",[23,2,[]]],[[\"bubbles\"],[false]]],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/color-picker-palette/template.hbs"
    }
  });

  _exports.default = _default;
});