define("builder/core/styles-management/widget-section/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This file contains WidgetSection stylesmap that is being used by ccs-generator
  function buildXsSectionStylesMap() {
    return {
      type: 'media',
      conditions: [{
        type: 'expression',
        leftOperand: {
          type: 'operand',
          name: 'min-width',
          value: '0px'
        },
        rightOperand: {
          type: 'operand',
          name: 'max-width',
          value: '767px'
        },
        operation: 'and'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-section-content',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-xs', '$margin-right-xs', '$margin-bottom-xs', '$margin-left-xs']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-xs', '$padding-right-xs', '$padding-bottom-xs', '$padding-left-xs']
          }
        }, {
          name: 'background-size',
          value: '$background-size-xs'
        }, {
          name: 'background-position',
          value: '$background-position-xs'
        }, {
          name: 'border-color',
          value: '$border-color-xs'
        }, {
          name: 'border-width',
          value: '$border-width-xs'
        }, {
          name: 'border-style',
          value: '$border-style-xs'
        }, {
          name: 'border-radius',
          value: '$border-radius-xs'
        }]
      }]
    };
  }

  function buildSmSectionStylesMap() {
    return {
      type: 'media',
      conditions: [{
        type: 'expression',
        leftOperand: {
          type: 'operand',
          name: 'min-width',
          value: '768px'
        },
        rightOperand: {
          type: 'operand',
          name: 'max-width',
          value: '991px'
        },
        operation: 'and'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-section-content',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-sm', '$margin-right-sm', '$margin-bottom-sm', '$margin-left-sm']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-sm', '$padding-right-sm', '$padding-bottom-sm', '$padding-left-sm']
          }
        }, {
          name: 'background-size',
          value: '$background-size-sm'
        }, {
          name: 'background-position',
          value: '$background-position-sm'
        }, {
          name: 'border-color',
          value: '$border-color-sm'
        }, {
          name: 'border-width',
          value: '$border-width-sm'
        }, {
          name: 'border-style',
          value: '$border-style-sm'
        }, {
          name: 'border-radius',
          value: '$border-radius-sm'
        }]
      }]
    };
  }

  function buildMdSectionStylesMap() {
    return {
      type: 'media',
      conditions: [{
        type: 'expression',
        leftOperand: {
          type: 'operand',
          name: 'min-width',
          value: '992px'
        },
        rightOperand: {
          type: 'operand',
          name: 'max-width',
          value: '1199px'
        },
        operation: 'and'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-section-content',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-md', '$margin-right-md', '$margin-bottom-md', '$margin-left-md']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-md', '$padding-right-md', '$padding-bottom-md', '$padding-left-md']
          }
        }, {
          name: 'background-size',
          value: '$background-size-md'
        }, {
          name: 'background-position',
          value: '$background-position-md'
        }, {
          name: 'border-color',
          value: '$border-color-md'
        }, {
          name: 'border-width',
          value: '$border-width-md'
        }, {
          name: 'border-style',
          value: '$border-style-md'
        }, {
          name: 'border-radius',
          value: '$border-radius-md'
        }]
      }]
    };
  }

  function buildLgSectionStylesMap() {
    return {
      type: 'media',
      conditions: [{
        type: 'operand',
        name: 'min-width',
        value: '1200px'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-section-content',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-lg', '$margin-right-lg', '$margin-bottom-lg', '$margin-left-lg']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-lg', '$padding-right-lg', '$padding-bottom-lg', '$padding-left-lg']
          }
        }, {
          name: 'background-size',
          value: '$background-size-lg'
        }, {
          name: 'background-position',
          value: '$background-position-lg'
        }, {
          name: 'border-color',
          value: '$border-color-lg'
        }, {
          name: 'border-width',
          value: '$border-width-lg'
        }, {
          name: 'border-style',
          value: '$border-style-lg'
        }, {
          name: 'border-radius',
          value: '$border-radius-lg'
        }]
      }]
    };
  }

  function buildDeviceSpecificSectionStylesMap(filename) {
    return {
      filename,
      stylesmap: [{
        type: 'style',
        selector: '{moduleId}.js-section-wrapper',
        properties: [{
          name: 'max-width',
          value: '100%'
        }, {
          name: 'padding',
          value: '0'
        }]
      }, buildXsSectionStylesMap(), buildSmSectionStylesMap(), buildMdSectionStylesMap(), buildLgSectionStylesMap()]
    };
  }

  function buildGlobalSectionStylesMap(filename) {
    return {
      filename,
      stylesmap: [{
        type: 'style',
        selector: '{moduleId}.js-section-wrapper',
        properties: [{
          name: 'max-width',
          value: '100%'
        }]
      }, {
        type: 'style',
        selector: '{moduleId} .js-section-content',
        properties: [{
          name: 'background-color',
          value: '$background-color'
        }, {
          name: 'background-image',
          value: [{
            type: 'function',
            name: 'url',
            arguments: ['$background-image']
          }, {
            type: 'function',
            name: 'linear-gradient',
            arguments: ['$background-gradient-direction', '$background-gradient-color1', '$background-gradient-color2']
          }]
        }, {
          name: 'background-repeat',
          value: '$background-repeat'
        }, {
          name: 'background-attachment',
          value: '$background-attachment'
        }]
      }]
    };
  }

  var _default = [buildDeviceSpecificSectionStylesMap('widget-section-device-specific.css'), buildGlobalSectionStylesMap('widget-section-global.css')];
  _exports.default = _default;
});