define("builder/pods/components/widget-line-divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oFS/5Z9/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"module-buttons\",[],[[\"@acl\",\"@title\",\"@moduleAction\",\"@module\",\"@parentWidth\"],[[24,[\"data\",\"acl\"]],[24,[\"data\",\"mainTitle\"]],[22,\"moduleAction\"],[22,\"data\"],[22,\"containerWidth\"]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"page_module_content widget-line-divider\"],[11,\"data-aos\",[24,[\"animationData\",\"name\"]]],[11,\"data-aos-duration\",[24,[\"animationData\",\"duration\"]]],[11,\"data-aos-mirror\",[24,[\"animationData\",\"mirror\"]]],[8],[0,\"\\n\\t\"],[7,\"hr\",true],[11,\"style\",[22,\"styles\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"enableVResize\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"resize-handler\",[],[[\"@horizontal\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-line-divider/template.hbs"
    }
  });

  _exports.default = _default;
});