define("builder/pods/components/form-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['buttons-list_item'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isExpanded:__expanded'],

    /**
     * @property {Number} index - index of button in buttons list array
     */
    index: 0,

    /**
     * @property {Object|null} button - button meta data
     * @property {String} button.type - button type
     * @property {Object} button.data - button's data
     * @property {Object[]} button.fields - ui-controll configurations
     */
    button: null,

    /**
     * @property {Function|null} onRemove - remove handler
     */
    onRemove: null,

    /**
     * @property {Function|null} onClick - click handler
     */
    onClick: null,

    /**
     * @property {Boolean} isExpanded - is button content expanded
     */
    isExpanded: false,

    /**
     * @property {Boolean} isRemoveEnabled - enable or disable remove icon
     */
    isRemoveEnabled: true,

    /**
     * @property {String} label - button label
     * @readonly
     */
    label: Ember.computed.readOnly('button.data.title'),

    /**
     * @property {Object[]} fields - field configurations
     * @readonly
     */
    fields: Ember.computed.readOnly('button.fields'),
    actions: {
      /**
       * Action on change some button setting
       *
       * @param {*} value - value
       * @param {String} property - property name
       */
      onChange(value, property) {
        this.changeButton(this.index, property, value);
      },

      /**
       * Action on click button header
       */
      onClick() {
        this.onClick();
      },

      /**
       * Action on delete button
       */
      remove() {
        this.onRemove();
      }

    }
  });

  _exports.default = _default;
});