define("builder/pods/components/widget-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4KJVj8fm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"tooltip-external\",[],[[\"@text\",\"@horizontalPosition\",\"@contentClickHandler\",\"@openTooltipOnClick\"],[[22,\"text\"],[22,\"textAlign\"],[22,\"contentClickHandler\"],[22,\"openTooltipOnClick\"]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"bld-form_help fa \",[22,\"iconClass\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-help/template.hbs"
    }
  });

  _exports.default = _default;
});