define("builder/pods/components/sm-select/sm-select-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CSS_PROPERTY_OPTIONS_MAP = void 0;

  /**
   * Css property to option map
   * @type {SMOptionsMap}
   */
  const CSS_PROPERTY_OPTIONS_MAP = {
    'font-style': [{
      name: 'normal',
      value: 'normal'
    }, {
      name: 'italic',
      value: 'italic'
    }, {
      name: 'oblique',
      value: 'oblique'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'font-weight': [{
      name: 'bold',
      value: 'bold'
    }, {
      name: 'bolder',
      value: 'bolder'
    }, {
      name: 'lighter',
      value: 'lighter'
    }, {
      name: 'normal',
      value: 'normal'
    }],
    'text-decoration': [{
      name: 'line-through',
      value: 'line-through'
    }, {
      name: 'overline',
      value: 'overline'
    }, {
      name: 'underline',
      value: 'underline'
    }, {
      name: 'none',
      value: 'none'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'text-transform': [{
      name: 'capitalize',
      value: 'capitalize'
    }, {
      name: 'lowercase',
      value: 'lowercase'
    }, {
      name: 'uppercase',
      value: 'uppercase'
    }, {
      name: 'none',
      value: 'none'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'border-style': [{
      name: 'dotted',
      value: 'dotted'
    }, {
      name: 'dashed',
      value: 'dashed'
    }, {
      name: 'solid',
      value: 'solid'
    }, {
      name: 'double',
      value: 'double'
    }, {
      name: 'groove',
      value: 'groove'
    }, {
      name: 'ridge',
      value: 'ridge'
    }, {
      name: 'inset',
      value: 'inset'
    }, {
      name: 'outset',
      value: 'outset'
    }],
    'gradient-direction': [{
      name: 'to top',
      value: 'to top'
    }, {
      name: 'to left	',
      value: 'to left'
    }, {
      name: 'to bottom',
      value: 'to bottom',
      default: true
    }, {
      name: 'to right',
      value: 'to right'
    }, {
      name: 'to top left',
      value: 'to top left'
    }, {
      name: 'to top right',
      value: 'to top right'
    }, {
      name: 'to bottom left',
      value: 'to bottom left'
    }, {
      name: 'to bottom right',
      value: 'to bottom right'
    }],
    'background-repeat': [{
      name: 'repeat',
      value: 'repeat'
    }, {
      name: 'repeat-x',
      value: 'repeat-x'
    }, {
      name: 'repeat-y',
      value: 'repeat-y'
    }, {
      name: 'no-repeat',
      value: 'no-repeat'
    }, {
      name: 'initial',
      value: 'initial'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'background-position': [{
      name: 'left top',
      value: 'left top'
    }, {
      name: 'left center',
      value: 'left center'
    }, {
      name: 'left bottom',
      value: 'left bottom'
    }, {
      name: 'right top',
      value: 'right top'
    }, {
      name: 'right center',
      value: 'right center'
    }, {
      name: 'right bottom',
      value: 'right bottom'
    }, {
      name: 'center top',
      value: 'center top'
    }, {
      name: 'center center',
      value: 'center center'
    }, {
      name: 'center bottom',
      value: 'center bottom'
    }, {
      name: 'initial',
      value: 'initial'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'background-attachment': [{
      name: 'scroll',
      value: 'scroll'
    }, {
      name: 'fixed',
      value: 'fixed'
    }, {
      name: 'local',
      value: 'local'
    }, {
      name: 'initial',
      value: 'initial'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'background-size': [{
      name: 'auto',
      value: 'auto'
    }, {
      name: 'cover',
      value: 'cover'
    }, {
      name: 'contain',
      value: 'contain'
    }, {
      name: 'initial',
      value: 'initial'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'parallax-scrolling': [{
      name: 'off',
      value: 'off'
    }, {
      name: 'fixed',
      value: 'fixed'
    }, {
      name: 'slow',
      value: 'slow'
    }, {
      name: 'normal',
      value: 'normal'
    }, {
      name: 'fast',
      value: 'fast'
    }],
    'container-width': [{
      name: 'fit to content',
      value: 'fit-to-content'
    }, {
      name: 'fit to browser',
      value: 'fit-to-browser'
    }],
    'container-content-width': [{
      name: 'fit to content',
      value: 'fit-to-content'
    }, {
      name: 'fit to browser',
      value: 'fit-to-browser'
    }],
    fullHeight: [{
      name: 'fit to content',
      value: 'fit-to-content'
    }, {
      name: 'fit to browser',
      value: 'fit-to-browser'
    }],
    'content-position': [{
      name: 'vertically centered',
      value: 'vertically-centered'
    }, {
      name: 'fixed position',
      value: 'fixed-position'
    }],
    'text-align': [{
      name: 'left',
      value: 'left'
    }, {
      name: 'right',
      value: 'right'
    }, {
      name: 'center',
      value: 'center'
    }, {
      name: 'justify',
      value: 'justify'
    }, {
      name: 'initial',
      value: 'initial'
    }, {
      name: 'inherit',
      value: 'inherit'
    }],
    'justify-content': [{
      name: 'center',
      value: 'center'
    }, {
      name: 'start',
      value: 'flex-start'
    }, {
      name: 'end',
      value: 'flex-end'
    }]
  };
  _exports.CSS_PROPERTY_OPTIONS_MAP = CSS_PROPERTY_OPTIONS_MAP;
});