define("builder/pods/website/edit/page/edit/settings/index/route", ["exports", "builder/routes/application", "builder/core/enumerators/controller-action"], function (_exports, _application, _controllerAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageSettingsRoute extends _application.default {
    /**
     * @inheritdoc
     */
    model(params) {
      const id = params && params.id ? params.id : this.configurationService.page.id;
      const model = this.modelFor('website.edit');
      const page = this.store.peekRecord('page', id);
      const availableWebsiteModelsForCreateGlobalPages = this.store.peekAll('website').filter(website => website.id !== this.configurationService.website.id);

      if (!this.canAccessToPageSettings(page)) {
        return this.transitionTo('website.edit.page.edit');
      } // Store page to access it in activate hook


      this.set('page', page);
      return Ember.RSVP.hash({
        page,
        menu: model.menu,
        pageMetaTags: this.store.peekAll('page-meta').findBy('PageId', id),
        pageSeo: this.store.peekAll('page-seo').findBy('PageId', id),
        availableWebsiteModelsForCreateGlobalPages
      });
    }
    /**
     * @inheritdoc
     */


    activate() {
      const {
        page
      } = this;

      if (!this.accessControl.can('visit route', this, {
        page
      }).isGranted) {
        this.handleAccessDenied();
        return;
      } // Highlight page settings


      this.send('handleBubbleAction', _controllerAction.default.OPEN_PAGE_SETTINGS);
    }
    /**
     * @inheritdoc
     */


    deactivate() {
      // Deactivate page settings
      this.send('handleBubbleAction', _controllerAction.default.CLOSE_PAGE_SETTINGS);
    }
    /**
     * Check access to route
     * @param {PageModel} page - page model
     * @returns {boolean}
     */


    canAccessToPageSettings(page) {
      if (page.isChild) {
        return false;
      }

      if (page.isGlobal && !this.accessControl.can('manage-global page', page).isGranted) {
        return false;
      }

      return true;
    }

  }

  _exports.default = PageSettingsRoute;
});