define("builder/core/drop-component-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Helper to define drag and drop component type
   */
  var _default = {
    /**
     * Define draggable type, based on field type passed
     *
     * @param {String} type - field type
     * @returns {Boolean}
     */
    getType(type) {
      return type === 'form-fieldset' ? 'fieldset' : 'component';
    }

  };
  _exports.default = _default;
});