define("builder/abilities/tier/global-style", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierGlobalStyleAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      canUseColors: {
        entity: 'global-style',
        property: 'colors'
      },
      canUseText: {
        entity: 'global-style',
        property: 'text'
      },
      canUseHeadings: {
        entity: 'global-style',
        property: 'headings'
      },
      canUseButtons: {
        entity: 'global-style',
        property: 'buttons'
      },
      canUseForms: {
        entity: 'global-style',
        property: 'forms'
      },
      canUsePanels: {
        entity: 'global-style',
        property: 'panels'
      },
      canUseOverlays: {
        entity: 'global-style',
        property: 'overlays'
      }
    }
  });

  _exports.default = _default;
});