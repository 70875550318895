define("builder/utils/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A set of usefull functions to be used when working with field configs
   *
   * @class FieldsUtils
   */
  var _default = Ember.Object.create({
    /**
     * Find fields in form-variants in each variant by criteria
     *
     * @param {Object} formVariants - form variants
     * @param {Function} criteria - function to be used to determine if fields should be incliuded to result list
     * @returns {Object[]}
     */
    findFieldsInFormVariants(formVariants, criteria) {
      const foundFields = [];
      formVariants.children.forEach(variant => {
        const children = variant.children || [];
        children.forEach(field => {
          if (criteria(field)) {
            foundFields.push({
              variant,
              field
            });
          }
        });
      });
      return foundFields;
    }

  });

  _exports.default = _default;
});