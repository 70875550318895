define("builder/pods/components/field-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2B+4kBal",
    "block": "{\"symbols\":[\"manager\",\"child\"],\"statements\":[[4,\"dropdown-container\",null,[[\"activeClass\",\"class\",\"isExpanded\",\"closeSelector\",\"autoPosition\"],[\"s-dropdown_sm-opened\",\"s-dropdown\",false,[24,[\"closeSelector\"]],false]],{\"statements\":[[4,\"if\",[[24,[\"field\",\"isSimple\"]]],null,{\"statements\":[[4,\"dropdown-button\",null,[[\"manager\",\"class\"],[[23,1,[]],\"s-dropdown__button s-dropdown__button_sm-small-section line-box__header\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[1,[24,[\"field\",\"label\"]],false],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"s-dropdown__icon s-dropdown__icon_gray-arrow icon-expanded-arrow\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"dropdown-button\",null,[[\"manager\",\"class\"],[[23,1,[]],\"s-dropdown__button s-dropdown__button_config-section\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[1,[24,[\"field\",\"label\"]],false],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"s-dropdown__icon s-dropdown__icon_arrow-styles icon-expanded-arrow\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"dropdown-dropable\",null,[[\"class\",\"manager\"],[\"s-dropdown__dropable-config\",[23,1,[]]]],{\"statements\":[[4,\"each\",[[24,[\"field\",\"children\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_field bld-form_field--width-available\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,2,[\"type\"]]],[[\"field\",\"parent\",\"configurationFlatMap\",\"actionHandler\"],[[23,2,[]],[24,[\"field\"]],[24,[\"configurationFlatMap\"]],[28,\"action\",[[23,0,[]],\"actionHandler\"],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-container/template.hbs"
    }
  });

  _exports.default = _default;
});