define("builder/pods/components/form-module/fields-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processField = processField;

  function processField(name, value, data) {
    if (!data.validation && name.indexOf('validation.') !== -1) {
      Ember.set(data, 'validation', {});
    }

    switch (name) {
      // Is reserved name and must be skipped from process.
      // Field type is readonly in UI (field add popup) and need just for display label of type.
      case 'type':
        {
          break;
        }

      case 'choices':
        {
          _handleChoices(name, value, data);

          break;
        }

      case 'validation.required':
      case 'servicesAdditionalFields':
      case 'isPrivate':
      case 'verify':
        {
          _handleValidationRequired(name, value, data);

          break;
        }

      case 'validation.range':
      case 'validation.length':
      case 'validation.elements':
        {
          _handleValidationLength(name, value, data);

          break;
        }

      case 'fieldNameMapping':
        {
          _handleDefault('name', value || data.type, data);

          break;
        }

      case 'help':
        {
          _handleHelp(name, value, data);

          break;
        }

      default:
        {
          _handleDefault(name, value, data);

          break;
        }
    }

    if (typeof data.validation === 'object' && !Object.keys(data.validation).length) {
      delete data.validation;
    }
  }

  function _handleChoices(name, value, data) {
    let choices = value || [];
    choices = choices.map(choice => ({
      value: choice,
      label: choice
    }));
    Ember.set(data, name, choices);
  }

  function _handleValidationRequired(name, value, data) {
    Ember.set(data, name, value === 'yes');
  }

  function _handleValidationLength(name, value, data) {
    const rule = {};

    if (value !== null) {
      if (value[0] !== null) {
        [rule.min] = value;
      }

      if (value[1] !== null) {
        [, rule.max] = value;
      }

      Ember.set(data, name, rule);
    } else {
      data.validation = [];
    }
  }

  function _handleHelp(name, value, data) {
    if (typeof value === 'string') {
      Ember.set(data, name, value);
    } else {
      throw new Ember.Error('Help value has not string type.');
    }
  }

  function _handleDefault(name, value, data) {
    let number;

    if (!Ember.isEmpty(value) && !Ember.isEmpty(value.toString().trim())) {
      number = Number(value);
    }

    if (typeof number === 'number' && !isNaN(number) && !Ember.isArray(value)) {
      Ember.set(data, name, number);
    } else {
      Ember.set(data, name, value);
    }
  }
});