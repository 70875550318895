define("builder/pods/components/form-module/component", ["exports", "builder/pods/components/grid-base-component/component", "builder/pods/components/form-module/renderer-module", "builder/pods/components/form-module/drag-and-drop-module", "builder/pods/components/form-module/layout-updater-module", "builder/core/form/parser", "builder/pods/components/form-module/children-module", "builder/mixins/event-base", "builder/core/form/normalizer", "builder/core/enumerators/module-action", "builder/pods/components/form-module/form-module-factory", "builder/core/form-services-proxy", "builder/core/enumerators/form-fields", "ember-copy", "jquery", "builder/core/form/utils", "builder/core/enumerators/buttons"], function (_exports, _component, _rendererModule, _dragAndDropModule, _layoutUpdaterModule, _parser, _childrenModule, _eventBase, _normalizer, _moduleAction, _formModuleFactory, _formServicesProxy, _formFields, _emberCopy, _jquery, _utils, _buttons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const FormComponent = _component.default.extend(_eventBase.default);

  FormComponent.reopenClass({
    MODE: {
      EDIT: 'edit',
      DISPLAY: 'display'
    }
  });
  /**
   * Get form item structure required for form-builder
   * @param {Object} formItem - form item to render
   * @returns {Object} - form item structure
   */

  function getStructure(formItem) {
    let structure = (0, _emberCopy.copy)(formItem.data, true);
    const children = formItem.children || []; // Here we filter out form, since it doesn't have wrapper, not needed when steps will come?
    // Form is kinda phantom element with no layout

    if (structure.type === 'form') {
      structure = getChildrenStructure(children);
      return structure;
    } // Button submit must not have children key


    if (![_buttons.FORM_SUBMIT, _buttons.FORM_NEXT, _buttons.FORM_PREV].includes(structure.type)) {
      structure.children = getChildrenStructure(children);
    }

    return structure;
  }
  /**
   * Get children structure, recursively
   * @param {Object[]} children - array of form item children
   * @returns {Object[]} - children structure
   */


  function getChildrenStructure(children) {
    const childrenStructure = [];
    let child, innerChildren;

    for (let i = 0, len = children.length; i < len; i++) {
      child = (0, _emberCopy.copy)(children[i].data, true);
      innerChildren = children[i].children;

      if (innerChildren && innerChildren.length) {
        child.children = getChildrenStructure(innerChildren);
      }

      childrenStructure.push(child);
    }

    return childrenStructure;
  }
  /**
   * Form module, form-item is atomic unit, so every module will work with that object,
   * In this module we operate with form items only, other modules will know what to retrieve from module
   *
   * TODO: top level must be form and .page_module_content should be form layout(think about it)
   * TODO: Don't forget to disable sortable in D&D story
   * TODO: Component creation could be moved into factory
   * TODO: IMPORTANT, before adding new features, refactor form-item creation and fix all todos
   */


  var _default = FormComponent.reopen({
    /**
     * Service for feature toggling
     * @type {Ember.Service}
     */
    features: Ember.inject.service(),

    /**
     * Router service
     * @type {Ember.Service}
     */
    router: Ember.inject.service('router'),

    /**
     * @inheritDoc
     */
    classNames: ['js-module', 'page_module'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['isEditMode:__edit-form', 'identityClassName', 'classes'],

    /**
     * Additional classes to be added to current node
     * @type {string}
     */
    classes: '',

    /**
     * Id of current rendered/active form, module config id
     * @type {string|number|null}
     */
    formInsertId: null,

    /**
     * Form module data
     * @type {Object|null}
     */
    data: null,

    /**
     * Form builder component
     * @type {Object|null}
     */
    builder: null,

    /**
     * Normalizer object
     * @type {Normalizer}
     */
    normalizer: _normalizer.default.create(),

    /**
     * Form hidden fields
     * @type {Object|null}
     */
    hiddenFields: null,

    /**
     * Reference to node elements, that normally would be handled in template.hbs
     * @type {Object|null}
     * @private
     */
    _elements: null,

    /**
     * Root node that houses module
     * @type {jQuery|null}
     */
    wrapper: null,

    /**
     * Modules hash table
     * @type {Object|null}
     */
    modules: null,

    /**
     * Link with outer world, invoking this we can actually invoking edit/controller's parentActions action
     * @type {Function|null}
     */
    parentActions: null,

    /**
     * Index current step
     * @type {number}
     */
    currentStepIndex: 0,

    /**
     * Structure extracted from FormModel without normalization
     * @type {Object|null}
     */
    sourceStructure: null,

    /**
     * Form structure normalized
     * @type {Object|null}
     */
    structure: null,

    /**
     * Current active mode for form
     * @type {string}
     */
    formMode: FormComponent.MODE.DISPLAY,

    /**
     * Indicate is module cover should be displayed or not
     * @type {boolean}
     */
    hasMask: true,

    /**
     * Is form steps drop down shown or not
     * @type {boolean}
     */
    isFormStepsShown: false,

    /**
     * Selector of module content wrapper div
     * @type {string}
     */
    contentDivSelector: '.page_module_content',

    /**
     * Flag to determine whether form is changed
     * @type {boolean}
     */
    formIsChanged: false,

    /**
     * Form model reference
     * @type {FormModel|Null}
     */
    formModel: null,

    /**
     * Is current formMode is display
     * @type {boolean}
     */
    isDisplayMode: Ember.computed.equal('formMode', FormComponent.MODE.DISPLAY),

    /**
     * Is current formMode is edit
     * @type {boolean}
     */
    isEditMode: Ember.computed.equal('formMode', FormComponent.MODE.EDIT),

    /**
     * Show multi step it's form or not
     * @type {boolean}
     */
    isMultiStepForm: Ember.computed.gt('normalizeStructure.children.length', 1),

    /**
     * Observer to reload form
     * @type {Function}
     */
    onFormReload: Ember.observer('data.reload', function () {
      this._loadForm();
    }),

    /**
     * Property from page edit controller, that indicates, open config module or not
     * @type {Function}
     */
    showConfigModuleObserver: Ember.observer('data.isActive', function () {
      const isEditMode = this.data.isActive && this.router.currentRouteName === 'website.edit.page.edit.module.settings_loading'; // After exit from edit mode we switch form to first step

      if (this.formMode === FormComponent.MODE.EDIT) {
        this._runSwitchToStep(0);
      }

      this.set('formMode', FormComponent.MODE[isEditMode ? 'EDIT' : 'DISPLAY']);
    }),

    /**
     * Detect formMode change, to hide/show mask, close config windows
     * @type {Function}
     */
    onModeChange: Ember.observer('formMode', function () {
      this.toggleOverlay(this.isEditMode);
      this.toggleDraggable(this, this.isDisplayMode);
      this.set('hasMask', this.isDisplayMode);

      if (this.isDisplayMode) {
        this.parentActions({
          action: 'closeFieldConfig'
        });
        this.parentActions('closeFormFields');
        this.parentActions('closeFormConfig');
      } // Dispatch event to notify modules, that formMode has changed


      this.dispatchEvent('modeChanged', this.formMode);
    }),

    /**
     * returns animation data for the template to be used in non edit mode
     */
    animationObject: Ember.computed('isDisplayMode', 'animationData.{name,duration,mirror}', function () {
      return this.isDisplayMode ? this.animationData : {};
    }),

    /**
     * Proxy to children module property observer
     * @type {Function}
     */
    onFieldConfigVisibleChange: Ember.observer('data.fieldConfigVisible', function () {
      this._modules.children.set('fieldConfigVisible', this.data.fieldConfigVisible);
    }),

    /**
     * Since attribute related hooks wont fire, but id of form config will be provided later, this observer used
     * @type {Function}
     */
    onIdChange: Ember.observer('data.originObject.id', function () {
      this._start();
    }),

    /**
     * Form structure observer, to update self, when another
     * instance of form changes layout
     * TODO: Should be clone structure, work with it, whenever original model structure changes, update local copy
     * why clone? since we must update form template only after user modified template and then decides to "apply changes"
     * @type {Function}
     */
    formModelStructureObserver: Ember.observer('formModel.Structure.form', function () {
      const form = this.formModel;
      const attributes = form.changedAttributes() || {}; // Awful way to update form, but see no other way to do it, since after saving object of structure changes
      // and structures are not longer equal(make deep equal validation? Is it worth it, since could time consuming)
      // plus we make some weird shit during form saving, which affects it's dirty state

      if (form && form.hasDirtyAttributes && attributes.Structure && this.sourceStructure !== form.Structure) {
        this._initializeAndRenderForm(form);
      }
    }),

    /**
     * Form field add service
     * @type {FormAddFieldService}
     */
    formFieldAddService: Ember.inject.service('form-add-field'),

    /**
     * page-module service
     * @type {PageModuleService}
     */
    pageModuleService: Ember.inject.service('page-module'),

    /**
     * Special modules service
     * @type {ModulesStoreService}
     */
    modulesStoreService: Ember.inject.service('modules-store'),

    /**
     * Form service
     * @type {FormService}
     */
    formService: Ember.inject.service('form'),

    /**
     * Store service
     * @type {StoreService}
     */
    storeService: Ember.inject.service('store'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Form preview service
     * @type {FormPreviewService}
     */
    formPreviewService: Ember.inject.service('form-preview'),

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Style model
     * @type {StyleModel}
     */
    style: Ember.computed.alias('data.moduleStyleModel'),

    /**
     * Generated css, based on StyleModel
     * @type {string}
     */
    css: Ember.computed('style.scopedStyleCSS', function () {
      const {
        style
      } = this;
      return style ? style.scopedStyleCSS : '';
    }),

    /**
     * Step render in progress or not
     * @type {boolean}
     * @private
     */
    _renderStepInProgress: false,

    /**
     * Method for render next step after load current
     * @type {Function|null}
     * @private
     */
    _renderStepWait: null,

    /**
     * Manager responsible for loading resources, css
     * @type {ResourceManager}
     * @private
     */
    _resourceManager: Ember.inject.service('resource-manager'),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_modules', {});
      this.set('events', {
        update: null,
        create: null,
        fieldsUpdate: null,
        modeChanged: null
      });
      this.router.one('routeDidChange', this, this.setEditModeOnRouteChange);
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this._initModules();

      this._attachEvents();

      this.initializeResizeObserver();
    },

    /**
     * @inheritDoc
     */
    willDestroyElement(...args) {
      var _this$formModel;

      this._super(...args);

      if (!((_this$formModel = this.formModel) === null || _this$formModel === void 0 ? void 0 : _this$formModel.id)) {
        return;
      }

      this.formService.removeTempStructure(this.formModel.id);
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments); // This hook used to invoked form job, when page is rendered


      this._start();
    },

    /**
     * Enable edit mode on route changes
     */
    setEditModeOnRouteChange(transition) {
      var _transition$to, _transition$to$parent, _transition$to$parent2, _transition$to$parent3, _transition$to2, _transition$to2$paren;

      const currentRouteModuleId = transition === null || transition === void 0 ? void 0 : (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : (_transition$to$parent = _transition$to.parent) === null || _transition$to$parent === void 0 ? void 0 : (_transition$to$parent2 = _transition$to$parent.parent) === null || _transition$to$parent2 === void 0 ? void 0 : (_transition$to$parent3 = _transition$to$parent2.params) === null || _transition$to$parent3 === void 0 ? void 0 : _transition$to$parent3['module_id'];

      if ((transition === null || transition === void 0 ? void 0 : (_transition$to2 = transition.to) === null || _transition$to2 === void 0 ? void 0 : (_transition$to2$paren = _transition$to2.parent) === null || _transition$to2$paren === void 0 ? void 0 : _transition$to2$paren.name) === 'website.edit.page.edit.module.settings') {
        var _this$data, _this$data$originObje, _this$data$originObje2;

        if (currentRouteModuleId === ((_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : (_this$data$originObje2 = _this$data$originObje.id) === null || _this$data$originObje2 === void 0 ? void 0 : _this$data$originObje2.toString())) {
          this.set('formMode', FormComponent.MODE.EDIT);
        }
      }
    },

    /**
     * Select step and switch to it
     * @param {number} index - index form step
     * @returns {void}
     */
    selectStep(index) {
      if (index !== this.currentStepIndex) {
        this._runSwitchToStep(index);
      }
    },

    /**
     * Add new step to form
     * @param {string} label - label added step
     * @returns {void}
     */
    addStep(label) {
      const structure = this.normalizeStructure;
      const {
        WebsiteGroup
      } = this.configurationService.website;
      const {
        FormType
      } = this.formModel;

      const newStep = _formModuleFactory.default.createNewStep();

      const normalizedStep = this.normalizer.normalize(newStep, WebsiteGroup, FormType);
      const firstStepData = structure.children.objectAt(0).data;
      normalizedStep.data.label = label; //	TODO add logic for rename first step

      if (!this.isMultiStepForm && !firstStepData.label) {
        firstStepData.label = 'Contact info';
      }

      const step = this._createFormStructure(normalizedStep);

      step.set('parent', structure);
      structure.children.pushObject(step);
      const stepsCount = structure.children.length - 1;

      this._updateStepButtons(stepsCount - 1);

      this._runSwitchToStep(stepsCount, true);
    },

    /**
     * Remove step to form
     * @param {number} index - index removed step
     * @returns {void}
     */
    removeStep(index) {
      const structure = this.normalizeStructure;
      const currentIndex = this.currentStepIndex;
      const lastStepIndex = structure.children.length - 1;
      structure.children.removeAt(index, 1); // We need update button only if we removed first or last step

      switch (index) {
        case 0:
          this._updateStepButtons(0);

          break;

        case lastStepIndex:
          this._updateStepButtons(lastStepIndex - 1);

          break;
      } // We need refresh form, if we removed current step - switch to first item,
      // if removed step has index less that current, switch to item - 1, because index <<


      const lowerIndexResult = index < currentIndex ? currentIndex - 1 : currentIndex;
      const indexEqualityResult = index === currentIndex ? 0 : lowerIndexResult;

      this._runSwitchToStep(indexEqualityResult, true);
    },

    /**
     * Rename step in form
     * @param {number} index - index rename step
     * @param {string} label - new label for step
     * @returns {void}
     */
    renameStep(index, label) {
      const step = this.normalizeStructure.children.objectAt(index);

      if (step) {
        step.set('data.label', label); // We need refresh preview

        this._runSwitchToStep(this.currentStepIndex, true);
      }
    },

    /**
     * Add new step to form
     * @param {number} oldIndex - old index moved step
     * @param {number} newIndex - new index moved step
     * @returns {void}
     */
    moveStep(oldIndex, newIndex) {
      const {
        children
      } = this.normalizeStructure;
      const currentIndex = this.currentStepIndex;
      const currentStep = children.objectAt(currentIndex);
      const moveStep = children.objectAt(oldIndex);
      const lastStepIndex = children.length - 1;
      children.removeAt(oldIndex);
      children.insertAt(newIndex, moveStep);
      const newIndexCurrentStep = children.indexOf(currentStep); // Update buttons in modified steps only if our changes affect the last or first step

      if (oldIndex === 0 || newIndex === 0 || oldIndex === lastStepIndex || newIndex === lastStepIndex) {
        this._updateStepButtons(oldIndex, newIndex);
      } // If index current step changed we need switch to its new index


      this._runSwitchToStep(currentIndex !== newIndexCurrentStep ? newIndexCurrentStep : currentIndex, true);
    },

    /**
     * Initialize form
     * @returns {void}
     * @private
     */
    _start() {
      const formInsertId = this.data.originObject.id;

      if (formInsertId && this.formInsertId !== formInsertId) {
        this.pageModuleService // When we add a form, we need to make a model save fake to roll back the changes
        .saveModule(this.data.originObject.id, true).then(() => {
          this._loadForm();

          if (this.isDestroyed) {
            return;
          }

          this.set('formInsertId', formInsertId);
        }, error => {
          throw new Ember.Error(`Fake form save was not executed, because: "${error}"`);
        });
      }
    },

    /**
     * Load form
     * @returns {void}
     * @private
     */
    _loadForm() {
      this.formService.getFormByConfigId(this.data.originObject.id, true).then(formModel => {
        this._initializeAndRenderForm(formModel);

        if (this.isDestroyed) {
          return;
        }

        this.set('formModel', formModel);
      }).finally(() => {
        // Notify controller, that form module has been loaded and ready to operate
        this.moduleAction(_moduleAction.default.LOADED, this.data);
      });
    },

    /**
     * Initialize form structure and render form
     * @param {FormModel} form - form model attached to component to be rendered
     * @returns {void}
     * @private
     */
    _initializeAndRenderForm(form) {
      const {
        FormType,
        Structure
      } = form;
      const formStructure = {
        type: Structure.form.type,
        children: Structure.form.children
      };
      const {
        WebsiteGroup
      } = this.configurationService.website;
      const formViewModel = this.normalizer.normalize(formStructure, WebsiteGroup, FormType);

      const normalizedStructure = this._createFormStructure(formViewModel);

      if (this.isDestroyed) {
        return;
      } // Save original state of structure before normalization


      this.set('sourceStructure', form.Structure);
      this.set('hiddenFields', form.Structure.hiddenFields || []);
      this.set('normalizeStructure', normalizedStructure);
      this.formService.saveTempStructure(form.id, this.normalizeStructure);

      this._runSwitchToStep(this.currentStepIndex);
    },

    /**
     * Create form structure fields data
     * @param {Object} structure - normalized form structure
     * @return {Object} form structure with added fields data
     * @private
     */
    _createFormStructure(structure) {
      const form = this._modules.children.createItem(structure);

      const children = this._modules.children.createChildren(structure.children, form);

      form.set('children', children);
      return form;
    },

    /**
     * Run switch to step or set switch method in property for call it after render current step
     * @param {number} stepIndex - index form step
     * @param {boolean} dispatch - need update form or not
     * @returns {void}
     * @private
     */
    _runSwitchToStep(stepIndex, dispatch = false) {
      if (this._renderStepInProgress) {
        this.set('renderStepWait', this._switchToStep.bind(this, stepIndex, dispatch));
      } else {
        this._switchToStep(stepIndex, dispatch);
      }
    },

    /**
     * Switch to step
     * @param {number} indexStep - index form step
     * @param {boolean} dispatch - need update form or not
     * @returns {void}
     * @private
     */
    _switchToStep(indexStep, dispatch) {
      let step;
      let stepIndex = indexStep;

      if (this.isMultiStepForm) {
        this.normalizeStructure.children.forEach((item, index) => {
          if (!item.data) {
            item.data = {};
          }

          item.data.isActive = stepIndex === index;
        });
        step = this.normalizeStructure;
      } else {
        step = this.normalizeStructure.children.get(0);
        stepIndex = 0;
      }

      this.set('currentStepIndex', stepIndex);
      this.set('form', step);
      this.set('form.loaded', false);

      this._renderForm(dispatch);
    },

    /**
     * Render form
     * @param {boolean} dispatch - need update form or not
     * @returns {void}
     * @private
     */
    _renderForm(dispatch) {
      this.set('_renderStepInProgress', true);
      const {
        form
      } = this;

      this._modules.renderer.renderItem(form); // Initial render shouldn't invoke change, since all forms are template form - template form are already exists
      // in DB


      this.updateItem(form, dispatch).then(() => form.set('loaded', true)).then(() => this._modules.dragAndDrop.setup(form)).then(() => {
        if (!this.isDestroyed) {
          this.set('_renderStepInProgress', false);
        }

        if (this.renderStepWait) {
          const switchStep = this.renderStepWait;
          this.set('renderStepWait', null);
          switchStep();
        }
      });
    },

    /**
     * Call update buttons in steps
     * @param {number} indexes - indexes step how need update
     * @returns {void}
     * @private
     */
    _updateStepButtons(...indexes) {
      const {
        children
      } = this.normalizeStructure; // If form not multistep we check and add only submit button

      if (!this.isMultiStepForm) {
        this._checkAndUpdateFormButton(children.get(0), [_buttons.FORM_SUBMIT]);
      } else {
        indexes.forEach(index => {
          const step = children.get(index);

          if (step) {
            switch (index) {
              case 0:
                // If it is first step we check and add only next button
                this._checkAndUpdateFormButton(step, [null, _buttons.FORM_NEXT]);

                break;

              case children.length - 1:
                // If it is first step we check and add prev and submit button
                this._checkAndUpdateFormButton(step, [_buttons.FORM_PREV, _buttons.FORM_SUBMIT]);

                break;

              default:
                // If it is first step we check and add prev and next button
                this._checkAndUpdateFormButton(step, [_buttons.FORM_PREV, _buttons.FORM_NEXT]);

            }
          }
        });
      }
    },

    /**
     * Check button in step and update if need
     * @param {Object} step - step for check button
     * @param {string[]} buttons - buttons type list in step
     * @returns {void}
     * @private
     */
    _checkAndUpdateFormButton(step, buttons) {
      const {
        children
      } = step;
      const childrenCount = children.length - 1;

      for (let i = buttons.length, j = 0; i--; j++) {
        const type = buttons[i];
        const elementIndex = childrenCount - j;
        const element = children.get(elementIndex);
        const elementType = element.data.type;

        if (type) {
          if (elementType !== type) {
            const {
              parent
            } = children.get(elementIndex);

            const buttonStructure = _formModuleFactory.default.createFormButton(type);

            const {
              WebsiteGroup
            } = this.configurationService.website;
            const buttonViewModel = this.normalizer.normalize(buttonStructure, WebsiteGroup);
            const button = this.createItem(buttonViewModel);
            button.set('parent', parent); // If received button type not equal structure button type - we need replace button

            if (this._checkThatIsButtonType(elementType)) {
              children.replace(elementIndex, 1, [button]); // If received button type not exist in structure - we need add button
            } else {
              children.insertAt(elementIndex + 1, button);
            }
          } // If received button type null - we need remove button

        } else if (this._checkThatIsButtonType(elementType)) {
          children.removeAt(elementIndex);
        }
      }
    },

    /**
     * Check that type is one of is button
     * @param {string} type - type for check
     * @return {boolean}
     * @private
     */
    _checkThatIsButtonType(type) {
      return type === _buttons.FORM_SUBMIT || type === _buttons.FORM_PREV || type === _buttons.FORM_NEXT;
    },

    /**
     * Attach events
     * @returns {void}
     * @private
     */
    _attachEvents() {
      this._modules.dragAndDrop.attachListener('update', dragItem => {
        this.updateItem();
        this.dispatchEvent('fieldDropped'); // If user dropped SSN field into form we have to enable form encryption

        if (dragItem.data.type === _formFields.default.SOCIAL_SECURITY) {
          this.disableFormConfigEncryption();
        }
      });

      this._modules.children.attachListener('itemRemoved', () => {
        this.set('formIsChanged', true);
        this.dispatchEvent('fieldsUpdate', this.form);
      });

      this.attachListener('update', () => {
        this._updateOrCreateForm('update'); // Notify that changes has been made


        this.setChange('module');
      });
      this.attachListener('create', () => {
        this._updateOrCreateForm('create');
      }); // Whenever form body is clicked, form steps should be closes, we must detect form body clicks specifically
      // We need capture phase, since bubbling phase will be canceled on child click

      this.$(this.contentDivSelector).get(0).addEventListener('click', () => {
        this.set('isFormStepsShown', false);
      }, true);
    },

    /**
     * Creates or updates form
     * @param {string} action - action name
     * @returns {void}
     * @private
     */
    _updateOrCreateForm(action) {
      const configId = this.data.originObject.id;
      const form = this.formService.getFormByConfigId(configId);
      const config = this.pageModuleService.getConfig(configId);
      const {
        hiddenFields
      } = this;
      form.then(formModel => {
        config.then(formConfig => {
          const structure = {
            success: formModel.Structure.success,
            form: this.normalizer.denormalize(this.normalizeStructure, hiddenFields),
            hiddenFields
          };

          if (action === 'create') {
            const parentFormModelJSON = this.formModel.toJSON();
            const newFormModelJSON = Ember.assign(parentFormModelJSON, {
              Id: (0, _utils.makeTemporaryFormId)(configId),
              Title: formConfig.Structure.title,
              IsEncrypted: formConfig.Structure.isEncrypted,
              FormType: formConfig.Structure.formType || formModel.FormType,
              Structure: structure,
              UsedInPages: []
            });
            const newFormRecord = this.storeService.push({
              data: {
                id: newFormModelJSON.Id,
                type: 'form',
                attributes: newFormModelJSON
              }
            }); // Reset flag for confirmation popup

            this.set('data.formIsConfirmed', false);
            Ember.set(formConfig.Structure, 'formId', newFormRecord.id);
            this.formService.createStructure(configId, {
              title: newFormRecord.Title,
              formType: newFormRecord.FormType,
              formImage: newFormRecord.Image,
              newStructure: newFormRecord.Structure,
              leadroutes: newFormRecord.Leadroutes,
              isEncrypted: newFormRecord.IsEncrypted
            });
            this.set('formModel', newFormRecord);
          } else if (action === 'update') {
            this.formService.updateStructure(formConfig.Structure.formId, {
              title: formConfig.Structure.title,
              formType: formConfig.Structure.formType || formModel.FormType,
              newStructure: structure,
              isEncrypted: formConfig.Structure.isEncrypted
            });
          } // Update source structure


          this.set('sourceStructure', structure);
        });
      });
    },

    /**
     * Initialize all used modules here
     * @returns {void}
     * @private
     */
    _initModules() {
      this.set('_modules.renderer', _rendererModule.default.create({
        viewport: this.$('.page_module_content')
      })); // Inject services by hand, since not all made in "Ember way"

      this.set('_modules.updater', _layoutUpdaterModule.default.create({
        websiteId: this.configurationService.website.id,
        formPreviewService: (0, _formServicesProxy._makeProxy)(this.formPreviewService, ['getTemplate']),
        theme: this.data.originObject.theme,
        parser: _parser.default.create()
      }));
      this.set('_modules.children', _childrenModule.default.create({
        formFieldAddService: this.formFieldAddService,
        features: this.features,
        pageModuleService: this.pageModuleService,
        store: this.storeService,
        form: this,
        parentActions: this.parentActions,
        updateItem: this.updateItem.bind(this),
        fieldConfigVisible: this.data.fieldConfigVisible,
        accessControl: this.accessControl
      }));
      this.set('_modules.dragAndDrop', _dragAndDropModule.default.create());
    },

    /**
     * Disables encryption field in form config
     * @returns {void}
     */
    disableFormConfigEncryption() {
      this.pageModuleService.getConfig(this.data.originObject.id).then(record => {
        const theme = this.storeService.peekRecord('module-theme', record.ThemeId);
        const isEncryptedField = theme.Structure.findBy('name', 'isEncrypted');
        const data = (0, _emberCopy.copy)(record.Structure, true);

        if (isEncryptedField) {
          isEncryptedField.disabled = true;
        }

        data.isEncrypted = '1';
        record.set('Structure', data);
        this.setChange('form-module');
      });
    },

    /**
     * Update item layout
     * @param {Object} component - item to update
     * @param {boolean} dispatch - dispatch update event or not
     * @returns {Promise}
     */
    updateItem(component = null, dispatch = true) {
      let promise = Ember.RSVP.resolve();

      if (component) {
        component.set('loaded', false);
        const websiteId = this.configurationService.website.id;
        promise = this.formPreviewService.getTemplate(websiteId, this.data.originObject.theme, this.data.originObject.id, getStructure(component)).then(response => {
          const html = (0, _jquery.default)(response.html)[0].childNodes;
          const classes = Object.values(response.cssClasses).join(' ');
          const css = response.styles;

          this._modules.updater.updateLayout(component, (0, _jquery.default)(html));

          component.set('loaded', true); // Update drag and drop event after replace node

          this._modules.dragAndDrop.setup(component);

          this._loadCSSAndApplyWrapperStyles(css, classes);
        }, () => component.set('loaded', true));
      } // Guarantee promise


      if (!promise) {
        promise = Ember.RSVP.resolve(null);
      }

      if (dispatch) {
        this.set('formIsChanged', true);
        this.dispatchEvent('fieldsUpdate', this.form);
      }

      return promise;
    },

    /**
     * Updates current form model. API
     * @returns {void}
     */
    updateForm() {
      // Update form model if form was changed except of title and subject
      if (this.formIsChanged) {
        this.dispatchEvent('update', this.form);
        this.set('formIsChanged', false);
      }
    },

    /**
     * Creates new form in "form" service. API
     * @returns {void}
     */
    createForm() {
      this.dispatchEvent('create', this.form);
    },

    /**
     * Create form item, taking into account registered modules
     * Should be entry point for any form item creation
     * TODO: run method above must use this one
     * @param {Object} config - config of item to create
     * @returns {Object} - form-item instance
     */
    createItem(config) {
      const item = this._modules.children.createItem(config);

      this._modules.dragAndDrop.setupItem(item);

      return item;
    },

    /**
     * @inheritdoc
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    doubleClick(...args) {
      // TODO: form mode change should be done somewhere else, when form mode change will be moved, acl validation
      // must be moved too
      if (this.editModeAllowed()) {
        this.set('formMode', FormComponent.MODE.EDIT);
        this.get('moduleAction')(_moduleAction.default.EDIT, this.get('data'));
      }

      return this._super(...args);
    },

    /**
     * Load form specific css and add classes to wrapper
     * @param {string[]} css - list of css to be loaded for current module theme
     * @param {string} classes - classes string to be added to current node
     * @returns {void}
     * @private
     */
    _loadCSSAndApplyWrapperStyles(css, classes) {
      const resourceManager = this._resourceManager;
      css.forEach(stylesheetUrl => {
        resourceManager.loadCSS(stylesheetUrl);
      });

      if (this.isDestroyed) {
        return;
      }

      this.set('classes', classes);
    },

    /**
     * Is switch to form edit mode allowed
     * @returns {boolean}
     */
    editModeAllowed() {
      return this.formMode === FormComponent.MODE.DISPLAY && this.accessControl.can('configure form').isGranted;
    },

    actions: {
      jumpToContainer() {
        this.moduleAction(_moduleAction.default.JUMP_TO_CONTAINER, this.data);
      },

      configure() {
        this.set('formMode', FormComponent.MODE.EDIT);
      },

      delete(data = this.data) {
        this.formService.removeForm(data.originObject.id);
        this.moduleAction('delete', data);
      },

      buttonAction(type, data = this.data) {
        switch (type) {
          case _moduleAction.default.CONFIGURE:
            this.send('configure');
            break;

          case 'revertForm':
            this.set('hiddenFields', []);
            this.parentActions({
              action: 'revertForm',
              formInsertId: this.formInsertId
            });
            break;

          case _moduleAction.default.DEACTIVATE:
            this.set('formMode', FormComponent.MODE.EDIT);
            this.moduleAction(_moduleAction.default.DEACTIVATE, null, null, data);
            break;

          case _moduleAction.default.DELETE:
            this.send('delete', data);
            break;

          default:
            this.moduleAction(type, data);
        }
      },

      showFieldsPopup() {
        this.parentActions({
          action: 'showFormFieldsPopup',
          form: this
        });
      }

    }
  });

  _exports.default = _default;
});