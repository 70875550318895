define("builder/pods/components/menu-manager/component", ["exports", "jquery", "builder/models/menu-child", "builder/core/enumerators/direction"], function (_exports, _jquery, _menuChild, _direction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Node: all logic related methods should be passed from outer source
   */
  var _default = Ember.Component.extend({
    /**
     * @property {Object|null} tree - Menu model
     */
    tree: null,

    /**
     * @property {DS.Model} page - page model itself
     */
    page: null,

    /**
     * @property {String} treeTitle - title of linked pages
     */
    treeTitle: '',

    /**
     * @property {Array} pages - list of website pages
     */
    pages: [],

    /**
     * @property {String|null} menuTitle - Menu name under selected page
     */
    menuTitle: null,

    /**
     * @property {Object[]|null} unlinkedPages - list of unlinked pages
     */
    unlinkedPages: null,

    /**
     * @property {Boolean} dropDownIsOpened - Indicates whether dropdown is opened
     */
    dropDownIsOpened: false,

    /**
     * @property {Ember.Service} mmService - menu-management-drag service
     */
    mmService: Ember.inject.service('menu-management-drag'),

    /**
     * @property {Ember.Service} menuManagerService - menu-manager service
     */
    menuManagerService: Ember.inject.service('menu-manager'),

    /**
     * @property {Ember.Service} hlService - highlight service
     */
    hlService: Ember.inject.service('drop-highlight'),

    /**
     * @property {ConfigurationService} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {AccessControlService} accessControl - access control service
     */
    accessControl: Ember.inject.service('can'),

    /**
     * @property {Function} onItemDrop - callback to be executed when item dropped
     */
    onItemDrop() {},

    /**
     * @property {Function} onSearchSubmit - callback that will be passed to input-search
     */
    onSearchSubmit() {},

    /**
     * @property {Function} onSearchClear - callback that will be invoked to input-search
     */
    onSearchClear() {},

    /**
     * @property {Function} onItemTitleClick - function to be invoked on menu item title or icon click
     */
    onItemTitleClick() {},

    /**
     * @property {Function} onItemCarretClick - function to be invoked on menu item carret click (for folders)
     */
    onItemCarretClick() {},

    /**
     * @property {String} linkedListWatermark - linked list watermark, accepts html tags
     */
    linkedListWatermark: '',

    /**
     * @property {String} unlinkedListWatermark - unlinked list watermark, accepts html tags
     */
    unlinkedListWatermark: '',

    /**
     * @property {String} escapedUnlinkedListWatermark - escaped unlinkedListWatermark
     */
    escapedUnlinkedListWatermark: Ember.computed('unlinkedListWatermark', function () {
      return Ember.String.htmlSafe(this.unlinkedListWatermark);
    }),

    /**
     * @property {String} escapedLinkedListWatermark - escaped linkedListWatermark
     */
    escapedLinkedListWatermark: Ember.computed('linkedListWatermark', function () {
      return Ember.String.htmlSafe(this.linkedListWatermark);
    }),

    /**
     * @property {String} escapedLinkedListWatermark - escaped linkedListWatermark
     */
    isTwinTurbo: Ember.computed('configurationService.website', function () {
      return this.get('configurationService.website').isTwinTurbo();
    }),

    /**
     * @property {Ember.Observer} treeChange - whenever tree changes we must update structure, since new structure passed
     * in and old structure isn't valid anymore
     */
    treeChange: Ember.observer('tree', 'unlinkedPages', function () {
      this.mmService.clearStructures();
      this.mmService.addStructure(this.tree);
      this.mmService.addStructure(this.unlinkedPages);
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args); // Since observer wont detect initial data passing


      this.mmService.clearStructures();
      this.mmService.addStructure(this.tree);
      this.mmService.addStructure(this.unlinkedPages);
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      (0, _jquery.default)(window).off('resize.mm');
    },

    /**
     * Adds window resize handler
     *
     * @method
     */
    addResizeHandler() {
      (0, _jquery.default)(window).on('resize.mm', Ember.run.bind(this, this._setMenuMaxHeight));
    },

    /**
     * Sets maximal height for menu
     *
     * @method _setMenuMaxHeight
     * @private
     */
    _setMenuMaxHeight() {
      const dropdown = this.element.querySelector('.menu-manager__dropdown');
      const maxHeight = document.documentElement.clientHeight - dropdown.offsetTop - document.querySelector('.footer').offsetHeight;

      if (maxHeight) {
        dropdown.style.maxHeight = `${maxHeight}px`;
      }
    },

    /**
     * Detect if child is descendant of parent at any level, in other words, if child contains parent at any level down
     * like so: child -> element -> parent -> element -> <passed in parent> - this will return true;
     * if passed in parent is a child of passed in child
     *
     * @param {Object} parent - possible ancestor object
     * @param {Object} child - child of possible ancestor
     * @returns {Boolean} - true if child is descendant of ancestor(parent)
     * @private
     */
    _isDescendant(parent, child) {
      let levelParent = parent;
      let isDescendant = false;
      const children = child.get('children');

      if (!children) {
        return false;
      }

      if (parent === child) {
        return true;
      }

      while (levelParent) {
        if (children.indexOf(levelParent) !== -1) {
          isDescendant = true;
          break;
        }

        levelParent = levelParent.get('config.parent');
      }

      return isDescendant;
    },

    /**
     * Generate styles(position) for drop highlight service
     *
     * @param {Ember.Object|Fragment} item - hovered anchor item
     * @param {String} position - drop position
     * @returns {{left: number, top: number}}
     * @private
     */
    _generateHighlightStyles(item, position) {
      const styles = {
        // Deal with li item padding
        left: item.get('config.x') + 20,
        top: item.get('config.y')
      };

      if (position === _direction.default.BOTTOM) {
        styles.top += item.get('config.height');
      }

      return styles;
    },

    /**
     * Determine if drop allowed, basically drop is not allowed for folder inside it's children at any level
     *
     * @param {Object} container - receiver
     * @param {Object} draggable - draggable object
     * @param {Object} anchor - anchor
     * @returns {Boolean}
     * @private
     */
    _canDrop(container, draggable, anchor) {
      if (draggable === anchor) {
        return false;
      } // We not allowed d&d missing page


      if (draggable.get('type') !== _menuChild.default.PAGE && draggable.get('isMissingPage')) {
        return false;
      } // We not allowed d&d link into unlinked page
      // We not allowed d&d folder to other area (menu -> unlinked, unlinked -> menu)


      if (draggable.get('type') !== _menuChild.default.PAGE && !this._checkDraggableDropAndSameParent(container, draggable)) {
        return false;
      } // Drop is not allowed if drop target container is child of draggable, draggable is drop target or no anchor provided


      return !this._isDescendant(container, draggable);
    },

    /**
     * Determines whether it is item dropped into the same container
     *
     * @method _checkDraggableDropAndSameParent
     * @param {Object} container - receiver
     * @param {Object} draggable - draggable object
     * @returns {Boolean}
     * @private
     */
    _checkDraggableDropAndSameParent(container, draggable) {
      let draggableParent = draggable.get('config.parent');
      let containerParent = container;

      while (draggableParent.get('config.parent')) {
        draggableParent = draggableParent.get('config.parent');
      }

      while (containerParent.get('config.parent')) {
        containerParent = containerParent.get('config.parent');
      }

      return draggableParent === containerParent;
    },

    /**
     * Is drop allowed into container next to anchor
     *
     * @param {Ember.Object|Fragment} container - future parent of draggable
     * @param {Fragment} draggable - draggable fragment
     * @param {Ember.Object|Fragment} anchor - anchor node
     * @returns {Boolean}
     * @private
     */
    _isDropAllowed(container, draggable, anchor) {
      return container && this._canDrop(container, draggable, anchor);
    },

    /**
     * Get drop position, valid values are top, inside, bottom
     *
     * @param {Ember.Object|Fragment} anchor - anchor element next to which element is dragged
     * @param {Number} y - y coordinate
     * @returns {String} - position
     * @private
     */
    _getDropPosition(anchor, y) {
      const config = anchor.get('config');
      let position = _direction.default.TOP;
      const anchorY = config.get('y');
      const anchorHeight = config.get('height');

      if (anchor.get('type') === _menuChild.default.FOLDER && y >= anchorY + anchorHeight / 3 && y <= anchorY + anchorHeight / 1.5) {
        position = _direction.default.INSIDE;
      } else if (anchor.get('type') === null) {
        position = _direction.default.INSIDE;
      } else if (y > anchorY + anchorHeight / 2) {
        position = _direction.default.BOTTOM;
      }

      return position;
    },

    /**
     * Highlight position of drop
     *
     * @param {Ember.Object|Fragment} item - anchor item
     * @param {String} position - highlight position (top, inside, bottom)
     * @private
     */
    _showDropPosition(item, position) {
      const {
        hlService
      } = this;

      if (position === _direction.default.INSIDE) {
        hlService.highlightElement(item.get('config.component').$(), 'menu-manager__drop-hint_inside');
      } else {
        hlService.highlight(this._generateHighlightStyles(item, position), false, 'page-edit__highlight_theme-gradient-line');
      }
    },

    /**
     * Determine parent, since we don't have parent for menu and unlinked pages
     *
     * @param {Object} item - item which parent to find
     * @param {String} position - drop position
     * @returns {Object}
     * @private
     */
    _getDropContainer(item, position) {
      // TODO: should be made in more "clear" way
      if (item.get('type') === _menuChild.default.FOLDER && item.get('config.component.isOpened') && position === _direction.default.BOTTOM && item.get('children.length')) {
        return item;
      }

      return position === _direction.default.INSIDE ? item : item.get('config.parent');
    },

    /**
     * Get drop index
     *
     * @param {Ember.Object|Fragment} parent - drop parent
     * @param {Fragment} child - anchor position
     * @param {Object} draggable
     * @param {String} position - drop position
     * @returns {*}
     * @private
     */
    _getDropIndex(parent, child, draggable, position) {
      let index = parent.get('children').indexOf(child);

      if (position === _direction.default.INSIDE) {
        return parent.get('children.length');
      }

      if (position === _direction.default.BOTTOM) {
        index++;
      }

      if (draggable.get('config.parent') === parent && index > parent.get('children').indexOf(draggable)) {
        index--;
      }

      return index;
    },

    /**
     * Determines whether element in view port
     *
     * @param {Number} x - mouse x point
     * @param {Number} y - mouse y point
     * @param {Object} viewport - view port
     * @returns {Boolean}
     * @private
     */
    _inViewport(x, y, viewport) {
      return x >= viewport.left && x <= viewport.left + viewport.width && y >= viewport.top && y <= viewport.top + viewport.height;
    },

    actions: {
      toggleDropdown() {
        this.toggleProperty('dropDownIsOpened');
        const isOpened = this.dropDownIsOpened;
        this.onClick();

        if (isOpened) {
          this.addResizeHandler();
          Ember.run.schedule('afterRender', this, this._setMenuMaxHeight);
        } else {
          (0, _jquery.default)(window).off('resize.mm');
        }
      },

      onRender(component) {
        // Register all draggable/droppable components
        this.mmService.register(component.get('data'), component);
      },

      onDragStart() {
        this.mmService.updateDimensions();
      },

      onMouseMove(component, x, y, viewport) {
        const pointedItem = this.mmService.getPointedItem(x, y);
        let position = null;
        let dropAllowed = false;

        if (pointedItem && viewport && this._inViewport(x, y, viewport)) {
          position = this._getDropPosition(pointedItem, y);

          const container = this._getDropContainer(pointedItem, position);

          dropAllowed = this._isDropAllowed(container, component.get('data'), pointedItem);
        }

        if (dropAllowed) {
          this._showDropPosition(pointedItem, position);
        } else {
          this.hlService.removeHighlight();
        }
      },

      onMouseUp(component, x, y) {
        const pointedItem = this.mmService.getPointedItem(x, y);

        if (pointedItem) {
          const position = this._getDropPosition(pointedItem, y);

          const container = this._getDropContainer(pointedItem, position);

          if (this._isDropAllowed(container, component.get('data'), pointedItem)) {
            const index = this._getDropIndex(container, pointedItem, component.get('data'), position);

            this.onItemDrop(container, component.get('data'), index);
          }
        }

        this.hlService.removeHighlight();
      },

      onDestroy(component) {
        if (Ember.typeOf(component.get('data.config')) === 'instance') {
          this.mmService.unregister(component.get('data'));
        }
      },

      onScroll() {
        this.mmService.updateDimensions();
      },

      /**
       * Action opens page wizard
       */
      addPage() {
        this.openPageWizard();
      }

    }
  });

  _exports.default = _default;
});