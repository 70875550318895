define("builder/pods/components/widget-main/component", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'main',
    // If is popup we added e6 "popup_content" class
    classNameBindings: ['isPopup:popup_content', 'data.isEnabled::widget-main__no-mouse-events'],
    attributeBindings: ['data.role:role'],
    gridMainRowClass: 'js-row'
  });

  _exports.default = _default;
});