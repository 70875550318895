define("builder/pods/components/field-inputmask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZZgGw0w9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"labelClass\",\"field\",\"validation\"],[[24,[\"labelClass\"]],[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-form_text_container\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"field\",\"maskType\"]],\"USPhone\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"one-way-phone-mask\",null,[[\"placeholder\",\"value\",\"name\",\"update\",\"class\",\"readonly\"],[[24,[\"placeholder\"]],[24,[\"field\",\"data\"]],[24,[\"field\",\"name\"]],[28,\"action\",[[23,0,[]],\"update\"],null],[24,[\"size\"]],[24,[\"field\",\"readOnly\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-inputmask/template.hbs"
    }
  });

  _exports.default = _default;
});