define("builder/services/resize-grid-columns", ["exports", "builder/core/enumerators/container", "jquery"], function (_exports, _container, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ResizeGridColumnsService
   */
  var _default = Ember.Service.extend({
    /**
     * @type {string} _postfix - Postfix for event name
     * @private
     */
    _postfix: '.resize',

    /**
     * @type {Object|null} resizableColumn - Column is being resized now
     */
    resizableColumn: null,

    /**
     * @type {Object|null} nextColumn - Column next to resizable one
     */
    nextColumn: null,

    /**
     * @type {number|null} initialWidth - Initial width of resized column
     */
    initialWidth: null,

    /**
     * @type {null|number} initialPosition - initial position
     */
    initialPosition: null,

    /**
     * @type {Service} dragAndDropService - Drag and drop service
     */
    dragAndDropService: Ember.inject.service('drag-and-drop'),

    /**
     * @type {Service} dragService - Drag service
     */
    dragService: Ember.inject.service('drag'),

    /**
     * @type {DeviceService} device - device service
     */
    device: Ember.inject.service('device'),

    /**
     * @type {Service} _structureService - structure service
     */
    _structureService: Ember.inject.service('structure'),

    /**
     * @type {Service} bootstrap - Bootstrap service
     */
    bootstrap: Ember.inject.service('bootstrap'),

    /**
     * @type {Object|null} _pageStructure - page structure
     * @private
     */
    _pageStructure: Ember.computed.readOnly('_structureService.pageViewModel'),

    /**
     * @type {Function[]} afterResize - collection afterResize callbacks
     */
    afterResize: [],

    /**
     * @type {boolean} resizeStarted - is resize started or not
     */
    resizeStarted: false,

    /**
     * @type {string} _cursorSelector - resize cursor selector
     * @private
     */
    _cursorSelector: '__col-resize',

    /**
     * Hook that will be invoked by resize service, when resize just started
     *
     * @param {Ember.Object} component - component data representation of component to resize
     * @returns {void}
     */
    onResizeStart(component) {
      const structureService = this._structureService;
      this.set('nextColumn', this.getNextColumn(component));
      this.set('resizableColumn', component); // Don't use mouse pageX as initial position because resize handler element width is 16px

      this.set('initialPosition', component.get('x') + component.get('width'));
      this.set('initialWidth', structureService.getColumnWidth(component));
      this.set('nextInitialWidth', structureService.getColumnWidth(this.nextColumn));
      this.set('step', this.calculateStep(component));
      this.dragService.disableSelection();
      this.set('resizeStarted', true);
      (0, _jquery.default)('body').addClass(this._cursorSelector);
    },

    /**
     * Returns next column
     *
     * @param {Object} column - Column object
     * @returns {Object | undefined} Next column object
     */
    getNextColumn(column) {
      const children = column.get('parent').get('originObject').get('children');
      const index = children.indexOf(column);
      return children[index + 1];
    },

    /**
     * Hook to be invoked by resize service when resize ends
     *
     * @param {Ember.Object} component - component data representation
     */
    onResizeEnd(component) {
      if (this.isDimWasChanged()) {
        this._doWorkAround(component);
      }

      this._clearResizable();

      this.dragService.enableSelection();
      this.set('resizeStarted', false);

      if (this._isTabletOrMobile()) {
        this.setResizableElements();
      }
    },

    /**
     * Method detect dimension changes with column
     *
     * @returns {boolean} - true when dimensions are changed
     */
    isDimWasChanged() {
      return this.resizeStarted && this.initialWidth !== this._structureService.getColumnWidth(this.resizableColumn);
    },

    /**
     * Calculates and returns resize step for column
     *
     * @param {Object} column - Column object
     * @returns {number} Resize step
     */
    calculateStep(column) {
      // TODO: what is going on here? Do we actually need parseInt?
      return parseInt(column.get('width') / this._structureService.getColumnWidth(column), 10);
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      this._clearResizable();
    },

    /**
     * Sets resizable through all components in structure
     *
     * @param {Object} [structure] - page structure
     */
    setResizableElements(structure) {
      const structureToResize = structure || this._pageStructure;
      this.setResizableRecursively(structureToResize);
    },

    /**
     * Invoked all workarounds here, for now it's structure coordinates update and afterResize callbacks(arguable)
     * probably it's core, no need to use it here
     *
     * @param {Ember.Object} component - structure object resized
     * @private
     * @returns {void}
     */
    _doWorkAround(component) {
      const {
        nextColumn
      } = this;

      if (component && nextColumn) {
        this.dragAndDropService.updateAllDimensions();
        this.afterResize.forEach(callback => callback(component, nextColumn));
      }
    },

    /**
     * Sets elements as resizable
     *
     * @param {Object} structure - Page structure
     * @returns {void}
     */
    setResizableRecursively(structure) {
      const children = structure.get('originObject').get('children') || [];
      structure.set('resizable', this.isResizable(structure));

      for (let i = 0, len = children.length; i < len; i++) {
        this.setResizableRecursively(children[i]);
      }
    },

    /**
     * Clears resizable properties
     *
     * @returns {void}
     */
    _clearResizable() {
      this.set('nextColumn', null);
      this.set('initialPosition', null);
      this.set('initialWidth', null);
      this.set('nextInitialWidth', null);
      this.set('step', null);
      (0, _jquery.default)('body').removeClass(this._cursorSelector);
    },

    /**
     * Detects whether current mode is desktop or small monitor
     *
     * @private
     * @returns {boolean}
     */
    _isDesktopOrSmMonitor() {
      return this.device.isDesktop() || this.device.isSmallMonitor();
    },

    /**
     * Detects whether current mode is desktop or small monitor
     *
     * @private
     * @returns {boolean}
     */
    _isTabletOrMobile() {
      return this.device.isTablet() || this.device.isMobile();
    },

    /**
     * Hook to be invoked by resize service whenever resize happens(mouse move)
     *
     * @param {Object} component - component data representation
     * @param {number} x - current mouse x position
     * @returns {void}
     */
    onResize(component, x) {
      if (this._isDesktopOrSmMonitor()) {
        this._onResizeLgMd(component, x, 1);
      } else if (this.device.isTablet()) {
        this._onResizeSmXs(component, x, 1);
      } else {
        this._onResizeSmXs(component, x, 2);
      }
    },

    /**
     * Set resized column size
     *
     * @param {number} x - Mouse x coordinate
     * @private
     * @returns {void}
     */
    _setSize(x) {
      const distance = this.initialPosition - x; // TODO: what is going on here? Do we actually need parseInt?

      this.set('size', parseInt(distance / this.step, 10));
    },

    /**
     * Resizes column on Desktop and Small Monitor modes
     *
     * @param {Object} component - component data representation
     * @param {number} x - current mouse x position
     * @param {number} minColumnWidth - minimum allowed column width
     * @private
     * @returns {void}
     */
    _onResizeLgMd(component, x, minColumnWidth) {
      const structureService = this._structureService;
      const {
        initialWidth
      } = this;
      const {
        nextInitialWidth
      } = this;
      const {
        nextColumn
      } = this;

      this._setSize(x);

      if (this.size === 0 && initialWidth === structureService.getColumnWidth(component)) {
        return;
      }

      if (this.size > 0) {
        if (initialWidth - Math.abs(this.size) >= minColumnWidth) {
          structureService.setColumnWidth(component, initialWidth - Math.abs(this.size));
          structureService.setColumnWidth(nextColumn, nextInitialWidth + Math.abs(this.size));
        }
      } else if (nextInitialWidth - Math.abs(this.size) >= minColumnWidth) {
        structureService.setColumnWidth(component, initialWidth + Math.abs(this.size));
        structureService.setColumnWidth(nextColumn, nextInitialWidth - Math.abs(this.size));
      }
    },

    /**
     * Resizes column on Tablet and Mobile modes
     *
     * @param {Object} component - component data representation
     * @param {number} x - current mouse x position
     * @param {number} minColumnWidth - minimum allowed column width
     * @private
     * @returns {void}
     */
    _onResizeSmXs(component, x, minColumnWidth) {
      const structureService = this._structureService;
      const {
        initialWidth
      } = this;
      const {
        nextInitialWidth
      } = this;
      const {
        nextColumn
      } = this;
      const maxColumnsNumber = this.bootstrap.get('maxColumns');

      this._setSize(x);

      if (this.size === 0 && initialWidth === structureService.getColumnWidth(component)) {
        return;
      }

      if (this.size > 0) {
        if (initialWidth - Math.abs(this.size) >= minColumnWidth) {
          structureService.setColumnWidth(component, initialWidth - Math.abs(this.size));

          if (nextInitialWidth === maxColumnsNumber) {
            structureService.setColumnWidth(nextColumn, Math.abs(this.size));
          } else {
            structureService.setColumnWidth(nextColumn, nextInitialWidth + Math.abs(this.size));
          }
        }
      } else if (initialWidth + Math.abs(this.size) <= maxColumnsNumber || structureService.getColumnWidth(nextColumn) < maxColumnsNumber) {
        if (initialWidth + Math.abs(this.size) <= maxColumnsNumber) {
          structureService.setColumnWidth(component, initialWidth + Math.abs(this.size));
        }

        if (nextInitialWidth - Math.abs(this.size) === 0) {
          structureService.setColumnWidth(nextColumn, maxColumnsNumber);
        } else {
          structureService.setColumnWidth(nextColumn, nextInitialWidth - Math.abs(this.size));
        }
      }
    },

    /**
     * Determines whether object is resizable
     *
     * @private
     * @param {Object} column - Handled object
     * @returns {boolean} Whether object is resizable
     */
    isResizable(column) {
      const isColumn = column.originObject.type === _container.default.WIDGET_COLUMN; // We can not resize anything but columns

      if (!isColumn) {
        return false;
      }

      const nextColumn = this.getNextColumn(column); // for desktops and small monitors
      // all columns are resizable except last

      if (this._isDesktopOrSmMonitor()) {
        return !!nextColumn;
      } // for any monitor size
      // last column should not be resizable


      if (!nextColumn) {
        return false;
      }

      const maxColumnsNumber = this.bootstrap.maxColumns;

      const columnWidth = this._structureService.getColumnWidth(column);

      const nextColumnWidth = this._structureService.getColumnWidth(nextColumn); // In tablet only full width columns are resizable
      // because it allows to create two columns
      // and only first column of two of two columns
      // because there are only two columns allowed in a "row"


      return columnWidth + nextColumnWidth === maxColumnsNumber || columnWidth === maxColumnsNumber && nextColumnWidth === maxColumnsNumber;
    },

    /**
     * Sets page structure
     *
     * @param {Object} structure - page structure
     * @returns {void}
     */
    setPageStructure(structure) {
      this.set('_pageStructure', structure);
    }

  });

  _exports.default = _default;
});