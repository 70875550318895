define("builder/helpers/html-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlFormat = htmlFormat;
  _exports.default = void 0;

  function htmlFormat(value) {
    return new Ember.String.htmlSafe(value);
  }

  var _default = Ember.Helper.helper(htmlFormat);

  _exports.default = _default;
});