define("builder/pods/components/module-buttons/component", ["exports", "builder/core/enumerators/module", "builder/core/enumerators/container", "builder/core/enumerators/widget", "builder/core/enumerators/module-action", "builder/services/grid-builder"], function (_exports, _module, _container, _widget, _moduleAction, _gridBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const containerCollapsedStateButtons = {
    show: [_moduleAction.default.RESET_ORDER, _moduleAction.default.RESET_ALL_DEVICE, _moduleAction.default.ROW_EDITOR, _moduleAction.default.COLUMN_EDITOR, _moduleAction.default.JUMP_TO_MODULE, _moduleAction.default.MOVE_UP, _moduleAction.default.MOVE_DOWN],
    hide: [_moduleAction.default.CREATE_CONTAINER_TEMPLATE, _moduleAction.default.EDIT, _moduleAction.default.CONFIGURE, _moduleAction.default.COPY, _moduleAction.default.DELETE]
  };
  const otherCollapsedStateButtons = {
    show: [_moduleAction.default.JUMP_TO_CONTAINER],
    hide: [_moduleAction.default.EDIT, _moduleAction.default.CONFIGURE, _moduleAction.default.COPY, _moduleAction.default.DELETE]
  };
  const gridAction = [_moduleAction.default.RESET_ORDER, _moduleAction.default.RESET_ALL_DEVICE, _moduleAction.default.ROW_EDITOR, _moduleAction.default.COLUMN_EDITOR];

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['page_module_header'],

    /**
     * Widget title
     * @type {string|null}
     */
    title: null,

    /**
     * Flat go expand/close grid editor dropdown
     * @type {boolean}
     */
    isGridEditButtonExpanded: false,

    /**
     * Configuration
     *  @type {ConfigurationService}
     */
    configuration: Ember.inject.service('configuration'),

    /**
     * Device type
     * @type {string|null}
     */
    deviceType: null,

    /**
     * Object with allowed actions
     * @type {Object|null}
     */
    acl: null,

    /**
     * Widget object
     * @type {Object|null}
     */
    module: null,

    /**
     * Parent width
     * @type {number}
     */
    parentWidth: 0,

    /**
     * Structure service
     * @type {StructureService}
     */
    structureService: Ember.inject.service('structure'),
    hasSection: Ember.computed(function () {
      var _this$module, _this$module$originOb;

      const type = (_this$module = this.module) === null || _this$module === void 0 ? void 0 : (_this$module$originOb = _this$module.originObject) === null || _this$module$originOb === void 0 ? void 0 : _this$module$originOb.type;

      if (!type || type === _container.default.WIDGET_CONTAINER) {
        return false;
      }

      const closestSection = this.structureService.findClosest(this.module, _widget.WidgetType.WIDGET_SECTION);
      return closestSection !== null;
    }),

    /**
     * Title for configuration button
     * @type {string}
     * @readonly
     */
    configurationTitle: Ember.computed('module', 'module.originObject.type', function () {
      return this.module.originObject.type === _module.default.MODULE_FORM ? 'Edit Form' : '';
    }).readOnly(),

    /**
     * Actions displayed in panel
     * @type {string[]}
     * @readonly
     */
    showActions: Ember.computed('availableActions', 'isCollapsed', function () {
      if (this.isContainer && this.isCollapsed) {
        return this.availableActions.filter(action => containerCollapsedStateButtons.show.includes(action));
      }

      if (this.isCollapsed) {
        return this.availableActions.filter(action => otherCollapsedStateButtons.show.includes(action));
      }

      return this.availableActions;
    }).readOnly(),

    /**
     * Actions hided on 3-dots menu
     * @type {string[]}
     * @readonly
     */
    hideActions: Ember.computed('availableActions', 'isCollapsed', function () {
      if (this.isContainer && this.isCollapsed) {
        return this.availableActions.filter(action => containerCollapsedStateButtons.hide.includes(action));
      }

      if (this.isCollapsed) {
        return this.availableActions.filter(action => otherCollapsedStateButtons.hide.includes(action));
      }

      return this.availableActions;
    }).readOnly(),

    /**
     * Generates action buttons for module
     * Allowed actions for widgets are applying at grid-builder service
     * see setupACLs method at builder/app/services/grid-builder.js
     * @type {string[]}
     * @readonly
     */
    availableActions: Ember.computed('acl', 'acl.{configure,copyable,moveUp,moveDown,edit,remove}', 'deviceType', 'module.originObject.type', function () {
      var _this$module2, _this$module2$originO;

      const acl = this.acl || {};
      const type = ((_this$module2 = this.module) === null || _this$module2 === void 0 ? void 0 : (_this$module2$originO = _this$module2.originObject) === null || _this$module2$originO === void 0 ? void 0 : _this$module2$originO.type) || '';
      const actions = [];

      if (!this.configuration.device.isDesktop() && type === _container.default.WIDGET_CONTAINER) {
        actions.push(...gridAction);
      }

      switch (type) {
        case _container.default.WIDGET_CONTAINER:
          actions.push(_moduleAction.default.JUMP_TO_MODULE);
          actions.push(_moduleAction.default.CREATE_CONTAINER_TEMPLATE);
          break;

        case _widget.WidgetType.WIDGET_SECTION:
          actions.push(_moduleAction.default.JUMP_TO_CONTAINER);
          actions.push(_moduleAction.default.JUMP_TO_SECTION_MODULE);
          break;

        default:
          actions.push(_moduleAction.default.JUMP_TO_CONTAINER);
          break;
      }

      if (this.hasSection) {
        actions.push(_moduleAction.default.JUMP_TO_SECTION);
      }

      if (acl.configure) {
        actions.push(_moduleAction.default.CONFIGURE);
      }

      if (acl.copyable) {
        actions.push(_moduleAction.default.COPY);
      }

      if (acl.moveUp && type === _container.default.WIDGET_CONTAINER) {
        actions.push(_moduleAction.default.MOVE_UP);
      }

      if (acl.edit && type !== _module.default.MODULE_FORM) {
        actions.push(_moduleAction.default.EDIT);
      }

      if (acl.moveDown && type === _container.default.WIDGET_CONTAINER) {
        actions.push(_moduleAction.default.MOVE_DOWN);
      }

      if (acl.remove) {
        actions.push(_moduleAction.default.DELETE);
      }

      return actions;
    }).readOnly(),

    /**
     * @property {Boolean} isContainer - is module container
     * @readonly
     */
    isContainer: Ember.computed('module.originObject.type', function () {
      var _this$module3, _this$module3$originO;

      return ((_this$module3 = this.module) === null || _this$module3 === void 0 ? void 0 : (_this$module3$originO = _this$module3.originObject) === null || _this$module3$originO === void 0 ? void 0 : _this$module3$originO.type) === _container.default.WIDGET_CONTAINER;
    }).readOnly(),

    /**
     * Determine collapse buttons state
     * @type {boolean}
     * @readonly
     */
    isCollapsed: Ember.computed('parentWidth', function () {
      return this.isContainer ? this.parentWidth < 360 : this.parentWidth < 300;
    }).readOnly(),

    /**
     * Effective title after processing
     * @type {string}
     * @readonly
     */
    effectiveTitle: Ember.computed('title', 'isCollapsed', function () {
      const title = this.title || '';

      if (!this.isCollapsed) {
        return title;
      }

      const moduleTitle = title.split(_gridBuilder.moduleThemeSeparator)[0].trim();
      return moduleTitle;
    }).readOnly(),

    /**
    	 * @inheritdoc
    	 */
    didUpdateAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.set('deviceType', this.configuration.device.getDevice().type);
    },

    /**
     * @inheritDoc
     */
    doubleClick() {
      // Prevent redundant double click which bubbled up
      return false;
    },

    actions: {
      buttonAction(type) {
        if (gridAction.indexOf(type) > -1) {
          this.set('isGridEditButtonExpanded', !this.isGridEditButtonExpanded);
        } else {
          this.set('isGridEditButtonExpanded', false);
        }

        this.moduleAction(type, this.module);
      }

    }
  });

  _exports.default = _default;
});