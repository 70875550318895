define("builder/services/transliteration", ["exports", "transliteration"], function (_exports, _transliteration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class TransliterationService
   */
  var _default = Ember.Service.extend({
    /**
     * Transliterate the string str and return the result
     * @param {string} str - source string
     * @returns {string}
     */
    transliterate(str) {
      return (0, _transliteration.transliterate)(str);
    }

  });

  _exports.default = _default;
});