define("builder/pods/website/edit/page/edit/container-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Z3QnSLB",
    "block": "{\"symbols\":[],\"statements\":[[5,\"widget-popup\",[[12,\"class\",\"widget-popup_theme_page-template\"]],[[\"@closeHandler\",\"@isFixed\"],[[28,\"action\",[[23,0,[]],\"closePopup\"],null],true]],{\"statements\":[[0,\"\\n\\t\"],[5,\"container-template-editor\",[],[[\"@title\",\"@description\",\"@templateTitle\",\"@templateVisibility\",\"@templateCategory\",\"@templateThumbnail\",\"@updateVisibility\",\"@updateThumbnail\",\"@removeThumbnail\",\"@isSaveAllowed\",\"@saveButtonText\",\"@visibilities\",\"@fetchCategories\",\"@save\"],[[28,\"if\",[[24,[\"isNewTemplate\"]],[24,[\"literals\",\"CONTAINER_TEMPLATE_CREATE_TITLE\"]],[24,[\"literals\",\"CONTAINER_TEMPLATE_EDIT_TITLE\"]]],null],[28,\"if\",[[24,[\"isNewTemplate\"]],[24,[\"literals\",\"CONTAINER_TEMPLATE_CREATE_DESCRIPTION\"]]],null],[24,[\"template\",\"Title\"]],[24,[\"template\",\"Visibility\"]],[24,[\"template\",\"Category\"]],[24,[\"template\",\"Preview\"]],[28,\"action\",[[23,0,[]],\"updateVisibility\"],null],[28,\"action\",[[23,0,[]],\"updateThumbnail\"],null],[28,\"action\",[[23,0,[]],\"removeThumbnail\"],null],[22,\"isSaveAllowed\"],[28,\"if\",[[24,[\"isNewTemplate\"]],[24,[\"literals\",\"CONTAINER_TEMPLATE_CREATE_BUTTON_TITLE\"]],[24,[\"literals\",\"CONTAINER_TEMPLATE_UPDATE_BUTTON_TITLE\"]]],null],[24,[\"model\",\"visibilities\"]],[28,\"action\",[[23,0,[]],\"fetchCategories\"],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/edit/page/edit/container-template/template.hbs"
    }
  });

  _exports.default = _default;
});