define("builder/core/enumerators/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    KEY: {
      // Primary
      NONE: 0,
      ESCAPE: 27,
      ENTER: 13,
      BACKSPACE: 8,
      WIN: 91,
      DOWN: 40,
      UP: 38,
      LEFT: 37,
      RIGHT: 39,
      SHIFT: 16,
      Y: 89,
      Z: 90
    }
  };
  _exports.default = _default;
});