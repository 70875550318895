define("builder/pods/components/form-module/renderer-module", ["exports", "builder/pods/components/form-module/module-base"], function (_exports, _moduleBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Module responsible for rendering item into viewport
   */
  var _default = _moduleBase.default.extend({
    /**
     * @property {jQuery} viewport - form viewport element
     */
    viewport: null,

    /**
     * @property {Object} component - currently active component
     */
    formItem: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      if (!this.viewport) {
        throw new Ember.Error('Viewport required, I do not know where to render');
      }
    },

    /**
     * Here we set which item we want to display
     *
     * @param {Object} formItem - item to be rendered into viewport
     */
    renderItem(formItem) {
      if (!formItem) {
        throw new Ember.Error('FormItem is required in order to render template');
      }

      this.viewport.empty().append(formItem.get('wrapper'));
    }

  });

  _exports.default = _default;
});