define("builder/pods/components/form-field-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FZeFort+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[22,\"fieldClass\"],\"\\n\\t\\t\\t\",[28,\"unless\",[[24,[\"enabled\"]],\"_disabled\"],null],\"\\n\\t\\t\\t\",[28,\"if\",[[24,[\"tooltip\"]],\"form-field-item_red\"],null]]]],[11,\"draggable\",[22,\"enabled\"]],[11,\"title\",[22,\"tooltip\"]],[8],[0,\"\\n\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"form-field-item__icon\\n\\t\\t\\t\",[28,\"form-field-icon\",null,[[\"type\"],[[24,[\"type\"]]]]]]]],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"title\",[22,\"title\"]],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-field-item__icon\\n\\t\\t\\t\\tform-field-item__icon_hidden\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-ellipsis-v\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-ellipsis-v\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/form-field-type/template.hbs"
    }
  });

  _exports.default = _default;
});