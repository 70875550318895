define("builder/pods/components/seo-url-import-button/component", ["exports", "js-yaml", "builder/core/enumerators/variant-names"], function (_exports, _jsYaml, _variantNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SeoUrlImportButtonComponent = (_dec = Ember._action, (_class = (_temp = class SeoUrlImportButtonComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['seo-import-button'];

      this.updateSeoField = (value, name) => {
        throw new Ember.Error('method should be provided');
      };

      this.onImportError = msg => {
        throw new Ember.Error('method should be provided');
      };
    }

    /**
     * @inheritdoc
     */
    didRender() {
      const [inputElement] = this.element.getElementsByTagName('input');

      if (!inputElement) {
        return;
      }

      inputElement.addEventListener('change', e => this.readYamlFile(e));
    }
    /**
     * Reads file content
     * @param {Event} event
     * @private
     */


    readYamlFile(event) {
      const {
        files
      } = event.target;
      const [file] = files;

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = evt => {
        this.loadFileContent(evt.target.result); // We need to clear input content so we could upload same file again

        event.target.value = '';
      };

      reader.readAsText(file);
    }
    /**
     * Parses file content and updates seo fields
     * @param {string} content - content to parse
     * @returns {void}
     */


    loadFileContent(content) {
      let seo = _jsYaml.default.safeLoad(content);
      /**
       * We always serialize page seo as array. So if no array received, invalid
       * input was provided
       */


      if (!Array.isArray(seo)) {
        const errorMessage = 'Invalid file structure. Array of items should be provided';
        this.onImportError(errorMessage);
        return;
      }

      const allowedKeys = ['filter', 'template', 'variant-name'];
      const isSeoValid = seo.every(item => this.isPageSeoItemValid(item, allowedKeys));

      if (!isSeoValid) {
        const errorMessage = 'Invalid import. Provided items does not contain Seo urls fields';
        this.onImportError(errorMessage);
      }

      seo.forEach((item, index) => {
        if (!seo[index]['variant-name'] || seo[index]['variant-name'] !== _variantNames.PAGE_SEO_NAME) {
          seo[index]['variant-name'] = _variantNames.PAGE_SEO_NAME;
        }

        this.removeRedundantFields(item, allowedKeys);
      });
      seo = this._getUniqueSeoItemsArray(seo);
      this.updateSeoField(seo);
    }
    /**
     * Validates seo item according current seo-item model interface
     * @param {object} seoItem - item to validate
     * @param {string[]} allowedKeys - keys that belongs to seo-item
     */


    isPageSeoItemValid(seoItem, allowedKeys) {
      return Object.keys(seoItem).every(key => allowedKeys.includes(key));
    }
    /**
     * Remove duplicate seo entries from array
     * @param {string[]} seo - keys that belongs to seo-item
     */


    _getUniqueSeoItemsArray(seo) {
      return seo.filter((v, i, a) => a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i);
    }
    /**
     * Removes fields which are not belong to seo item model
     * @param {object} seoItem - item to change
     * @param {string[]} allowedKeys - keys that belongs to seo-item
     */


    removeRedundantFields(seoItem, allowedKeys) {
      Object.keys(seoItem).forEach(key => {
        if (allowedKeys.includes(key)) {
          return;
        }

        delete seoItem[key];
      });
    }
    /**
     * Opens file upload window
     * @returns {void}
     */


    openFileUpload() {
      if (!window.confirm('Warning: this will overwrite all the existing seo urls on this page. If you want to add to the existing seo urls please export the file and then edit before uploading.')) {
        return;
      }

      const inputElement = this.element.getElementsByTagName('input')[0];
      inputElement.click();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "openFileUpload", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "openFileUpload"), _class.prototype)), _class));
  _exports.default = SeoUrlImportButtonComponent;
});