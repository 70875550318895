define("builder/pods/components/form-tree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEw782pp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"field-select\",null,[[\"content\",\"prompt\",\"action\"],[[24,[\"tree\"]],[24,[\"field\",\"placeholder\"]],[24,[\"actionHandler\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/form-tree/template.hbs"
    }
  });

  _exports.default = _default;
});