define("builder/pods/components/gallery-container-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eNOjTByw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page_module\"],[11,\"style\",[22,\"thumbnailCss\"]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"page_modules_list_item-title\"],[8],[1,[22,\"name\"],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page_modules_list_item-controls\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"page_modules_list_item-control edit-container-template \",[28,\"unless\",[[24,[\"canEdit\"]],\"page_modules_list_item-control_disable\"],null]]]],[12,\"title\",\"Edit Template\"],[3,\"action\",[[23,0,[]],\"editTemplate\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-pencil-square-o\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"page_modules_list_item-control delete-container-template \",[28,\"unless\",[[24,[\"canDelete\"]],\"page_modules_list_item-control_disable\"],null]]]],[12,\"title\",\"Delete Template\"],[3,\"action\",[[23,0,[]],\"deleteTemplate\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-trash-o page_module_control_item\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",true],[10,\"class\",\"page_modules_list_item-control\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showImagePopup\",[24,[\"originalImage\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-search-plus\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/gallery-container-template/template.hbs"
    }
  });

  _exports.default = _default;
});