define("builder/core/meta-tags/dom-manipulation/tag-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MetaSearchTag = _exports.MetaCloseButtonTag = _exports.MetaTag = void 0;

  /**
   * HTML meta tag attributes
   * @type {MetaHTMLTagDescription}
   */
  const MetaTag = {
    tagName: 'span',
    dataAttribute: 'data-meta-tag',

    /**
     * fr-deletable class is needed for froala to make meta deletable
     */
    className: 'b2-meta-tag fr-deletable',
    offsetClassName: 'b2-meta-tag__left-offset'
  };
  /**
   * @type {MetaHTMLTagDescription}
   */

  _exports.MetaTag = MetaTag;
  const MetaCloseButtonTag = {
    tagName: 'span',
    className: 'b2-meta-tag__close-button'
  };
  /**
   * @type {MetaHTMLTagDescription}
   */

  _exports.MetaCloseButtonTag = MetaCloseButtonTag;
  const MetaSearchTag = {
    tagName: 'span',
    dataAttribute: 'data-search-wrapper'
  };
  _exports.MetaSearchTag = MetaSearchTag;
});