define("builder/services/pendo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PendoService extends Ember.Service {
    /**
     * Track a Pendo event
     * @param {string} eventType to track
     * @param {Object} options that contain event data
     * @returns {void}
     */
    trackEvent(eventType, options = {}) {
      if (this._isSdkInjected()) {
        window.pendo.track(event, options);
      }
    }
    /**
     * Checks if pendo init script is injected
     * @returns boolean
     * @private
     */


    _isSdkInjected() {
      return window.pendo !== undefined;
    }

  }

  _exports.default = PendoService;
});