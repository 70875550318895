define("builder/pods/components/input-datetime/component", ["exports", "builder/pods/components/input-zebradatepicker/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class InputDateTimeComponent
   */
  class InputDateTimeComponent extends _component.default {
    constructor(...args) {
      super(...args);
      this.format = 'm/d/Y h:i a';
    }

  }

  _exports.default = InputDateTimeComponent;
});