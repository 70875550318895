define("builder/pods/components/widget-column/component", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * Set component class attribute.
     * @property
     */
    classNameBindings: ['className', 'visibleOnLg::hidden-lg', 'visibleOnMd::hidden-md', 'visibleOnSm::hidden-sm', 'visibleOnXs::hidden-xs', 'isOverlayVisible:overlay-visible'],

    /**
     * @property {String[]} classNames - classes to be added on component render
     */
    classNames: ['js-col', 'grid-column', 'flexbox-column-grid'],
    attributeBindings: ['styles:style'],
    styles: Ember.computed('data.originObject.styles.{lg,md,sm,xs}.@each.value', 'mode', function () {
      var _styles$this$mode;

      const {
        styles
      } = this.data.originObject;
      const orderStyle = (_styles$this$mode = styles[this.mode]) === null || _styles$this$mode === void 0 ? void 0 : _styles$this$mode.find(style => style.name === 'order');

      if (!(orderStyle === null || orderStyle === void 0 ? void 0 : orderStyle.value)) {
        return '';
      }

      return `order: ${orderStyle.value}`;
    }),

    /**
     * Set Twitter Bootstrap column size.
     * Depends on data size
     *
     * @property
     * @returns {String}
     */
    className: Ember.computed('data.originObject.size.{xs,sm,md,lg}', function () {
      const className = [];
      const originObject = this.get('data.originObject');
      const size = originObject.get('size');
      originObject.set('size', size);

      if (size) {
        Object.keys(size).forEach(key => {
          const val = size[key];
          className.push(`col-${key}-${val}`);
        });
      }

      return className.join(' ');
    })
  });

  _exports.default = _default;
});