define("builder/pods/components/shell-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FzCR1j6g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"page_edit_tools_button-icon\"],[8],[0,\"\\n\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\\t\\t\"],[7,\"use\",true],[11,\"xlink:href\",[29,[\"#\",[22,\"icon\"]]],\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isTitleVisible\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[10,\"class\",\"page_edit_tools_button-label\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/shell-button/template.hbs"
    }
  });

  _exports.default = _default;
});