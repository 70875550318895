define("builder/pods/components/field-text/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO: validation will not trigger change if field will be removed before debounce time expires
   * ex. change value in field, immediately go to another tab
   */
  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_text'],

    /**
     * Used in /validations/page-alias.js as dependency property to control preloader state
     * NOTICE: don't change it manually or in component logic
     *
     * @property {Boolean} isShowPreloader - Property for async validation to show/hide preloader
     * @type boolean
     */
    isShowPreloader: false,

    /**
     * @property {Boolean} autofocus - show need set focus to input or not
     */
    autofocus: false,

    /**
     * @property {String} size - element class name.
     * @readonly
     */
    size: Ember.computed(function () {
      const {
        field
      } = this;

      if (field.size) {
        return `input-${field.size}`;
      }

      return '';
    }).readOnly(),

    /**
     * @property {Boolean} _transformToLowerCase - show need that need transform input value to lower case format
     * @readonly
     * @private
     */
    _transformToLowerCase: Ember.computed.equal('field.textTransform', 'lowerCase').readOnly(),

    /**
     * @property {Ember.observer} enableValidation - observer which just enable validation for field if it value changed
     * @private
     */
    _enableValidation: Ember.observer('field.data', function () {
      this.set('disableValidation', false);
    }),

    /**
     * "isShowPreloader" fix some bug with "isValidating" computed property. This property does not change while
     * we not remove focus from this field and go back
     * This observer calling "errorHandler" callback if error is registered and callback is passed
     * TODO: this will not trigger after field removed, hence causes "bug" if field removed before validation complete
     *
     * @property {Ember.observer} asyncValidation - Observer for async validation, when promise resolved and
     * validation is pass send data to save it in store
     * @private
     */
    _asyncValidation: Ember.observer('validations.isValidating', 'isShowPreloader', function () {
      if (this.validations.isAsync) {
        const {
          isTruelyValid,
          message,
          isValidating
        } = this.validations;

        if (isTruelyValid) {
          this._callActionHandler();
        } else if (message && !isValidating) {
          this._callErrorHandler();
        }
      }
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      if (!this.placeholder && this.get('field.placeholder')) {
        this.set('placeholder', this.get('field.placeholder'));
      }
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      if (this.triggerOnRender && this.get('field.data')) {
        this._callActionHandler();
      }
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.set('disableValidation', !this.get('field.enableValidationImmediately')); // We should always re-init validation

      this.applyValidations();

      if (this.get('field.enableValidationImmediately')) {
        this._firstValidation();
      }
    },

    /**
     * @inheritdoc
     */
    didRender(...args) {
      this._super(...args);

      if (this.autofocus) {
        this.$().find('input').focus();
      }
    },

    /**
     * Method for the first field check
     *
     * @method _firstValidation
     * @private
     */
    _firstValidation() {
      if (!this.get('validations.isTruelyValid')) {
        this._callErrorHandler();
      }
    },

    /**
     * Method for call action handler
     *
     * @method _callActionHandler
     * @param {string} [type] - type event called it method
     * @private
     */
    _callActionHandler(type) {
      const {
        actionHandler
      } = this;

      if (actionHandler) {
        actionHandler(this.get('field.data'), this.get('field.name'), type);
      }
    },

    /**
     * Method for call input handler
     *
     * @method _callInputHandler
     * @private
     */
    _callInputHandler() {
      const {
        inputHandler
      } = this;

      if (inputHandler) {
        inputHandler(this.get('field.data'), this.get('validations.isTruelyValid'));
      }
    },

    /**
     * Method for call error handler
     *
     * @method _callErrorHandler
     * @private
     */
    _callErrorHandler() {
      const {
        errorHandler
      } = this;

      if (errorHandler) {
        errorHandler(this.get('field.data'), this.get('field.name'), this.get('validations.message'));
      }
    },

    actions: {
      /**
       * Action called when someone input in field.
       */
      input() {
        const isValid = this.get('validations.isTruelyValid');

        if (this._transformToLowerCase) {
          this.set('field.data', this.get('field.data').toLowerCase());
        }

        this._callInputHandler();

        if (isValid) {
          this._callActionHandler('input');
        } else {
          this._callErrorHandler();
        }
      },

      /**
       * Action called when someone change in field.
       */
      change() {
        if (!this.notValid) {
          this._callActionHandler('change');
        }
      }

    }
  });

  _exports.default = _default;
});