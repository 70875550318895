define("builder/core/form/parser", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Will search for nodes
   */
  var _default = Ember.Object.extend({
    /**
     * @property {Object} _counter - will track number of nodes to omit
     * @private
     */
    _counter: {},

    /**
     * @property {Object} node - current node, where search will be applied
     */
    node: null,

    /**
     * Set searchable node
     *
     * @param {HTMLElement} node - root node, starting from which other nodes will be searched
     */
    setNode(node) {
      this.set('node', node);

      this._resetCounter();
    },

    /**
     * Reset node tracker
     *
     * @private
     */
    _resetCounter() {
      this.set('_counter', {});
    },

    /**
     * Get next node by type
     *
     * @param {String} type - find node with given data attribute (data-type="<passed in type>")
     * @returns {HTMLElement|null}
     */
    getNextNode(type) {
      const counter = this._counter;

      const nodes = this._getNodes(type);

      if (!counter.hasOwnProperty(type)) {
        counter[type] = -1;
      }

      counter[type]++;
      return nodes[counter[type]] ? (0, _jquery.default)(nodes[counter[type]]) : null;
    },

    /**
     * Get all nodes of given type
     *
     * @param {String} type - type of nodes to collect
     * @returns {jQuery[]} - found nodes
     * @private
     */
    _getNodes(type) {
      const {
        node
      } = this;
      let nodes = (0, _jquery.default)();
      let el;

      if (!node) {
        throw new Ember.Error('Node is not set, set node first');
      }

      for (let i = 0, len = node.length; i < len; i++) {
        el = null;

        if (node[i].nodeType === Node.ELEMENT_NODE && type === node[i].getAttribute('data-type')) {
          el = (0, _jquery.default)(node[i]);
        }

        if (!el) {
          el = (0, _jquery.default)(node[i]).find(`[data-type="${type}"]`);
        }

        nodes = nodes.add(el);
      }

      return nodes;
    }

  });

  _exports.default = _default;
});