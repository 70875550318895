define("builder/core/drop-rules/widget-row", ["exports", "builder/core/drop-rules/base-rule", "builder/core/enumerators/direction", "builder/core/enumerators/container", "builder/core/enumerators/breakpoint-modes"], function (_exports, _baseRule, _direction, _container, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Drag and drop rules for widget-row
   */
  var _default = _baseRule.default.extend({
    /**
     * Max number of children
     * @type {number}
     */
    _maxChildren: 12,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_allowedTypes', ['widget-column']);
    },

    /**
     * @inheritdoc
     */
    canDrop(container, draggable, direction, anchor, ...args) {
      var _container$component, _container$component$, _container$component$2;

      if (!this._super(container, draggable, direction, anchor, ...args)) {
        return false;
      }

      const currentDeviceType = container === null || container === void 0 ? void 0 : (_container$component = container.component) === null || _container$component === void 0 ? void 0 : (_container$component$ = _container$component.device) === null || _container$component$ === void 0 ? void 0 : (_container$component$2 = _container$component$._device) === null || _container$component$2 === void 0 ? void 0 : _container$component$2.breakpoint;
      const isTabletOrMobileDevice = currentDeviceType === _breakpointModes.EXTRA_SMALL || currentDeviceType === _breakpointModes.SMALL; // show drop highlight on top and botton if the current device other than desktop  and dragglable column width is 12

      if (isTabletOrMobileDevice && this.hasMaxColumnWidth(draggable, currentDeviceType)) {
        return _direction.default.TOP === direction || _direction.default.BOTTOM === direction;
      }

      if (!isTabletOrMobileDevice && (_direction.default.TOP === direction || _direction.default.BOTTOM === direction)) {
        return false;
      } // To restrict drop around columns in mobile view when there are more than 2 columns in a row


      if (currentDeviceType === _breakpointModes.LARGE && (_direction.default.TOP === direction || _direction.default.BOTTOM === direction)) {
        return false;
      }

      if (currentDeviceType === _breakpointModes.EXTRA_SMALL && (_direction.default.LEFT === direction || _direction.default.RIGHT === direction) && container.originObject.children.length > 1) {
        return false;
      } // To restrict drop around columns in tablet view when there are more than 3 columns in a row


      if (currentDeviceType === _breakpointModes.SMALL && (_direction.default.LEFT === direction || _direction.default.RIGHT === direction) && container.originObject.children.length > 2) {
        return false;
      } // Do not drop next to self


      if (anchor === draggable) {
        return false;
      }

      if (draggable.originObject.type === _container.default.WIDGET_CONTAINER) {
        return false;
      } // Since we drag column, we should test against draggable parent


      if (container.originObject.children.length >= this._maxChildren && !container.originObject.children.includes(draggable)) {
        return false;
      }

      return true;
    },

    /**
     * @inheritdoc
     */
    drop(structure, container, draggable, direction, anchor) {
      var _this$getRootContaine;

      const currentDeviceType = this.getCurrentBreakpoint(draggable);
      const targetRootContainer = (_this$getRootContaine = this.getRootContainer(container)) === null || _this$getRootContaine === void 0 ? void 0 : _this$getRootContaine.component; // Check if the grid editor is enabled for the root container

      const isGridEditorEnabledForContainer = (targetRootContainer === null || targetRootContainer === void 0 ? void 0 : targetRootContainer.isColumnEditorModeOn) || (targetRootContainer === null || targetRootContainer === void 0 ? void 0 : targetRootContainer.isRowEditorModeOn);

      if (currentDeviceType !== _breakpointModes.LARGE && isGridEditorEnabledForContainer) {
        this.modifyOrder(structure, container, draggable, direction, anchor);
      } else {
        this.addDraggableToStructure(structure, container, draggable, direction, anchor);
        this.resetOrderOfSourceContainer(draggable, targetRootContainer, structure);
      }
    },

    /**
     * modify the order of container children when Grid editor is enabled
     */
    modifyOrder(structure, container, draggable, direction, anchor) {
      const currentDeviceType = this.getCurrentBreakpoint(draggable);

      if (!structure.order.hasOrderedChildren(container, currentDeviceType)) {
        var _container$originObje;

        const containerChildren = (container === null || container === void 0 ? void 0 : (_container$originObje = container.originObject) === null || _container$originObje === void 0 ? void 0 : _container$originObje.children) || [];
        structure.order.initChildrenOrder(containerChildren, currentDeviceType);
      }

      structure.order.calculateOrderValue(container, draggable, direction, anchor);
      structure.order.reassignOrderConsecutively(container, null);
    },

    /**
     * add the draggable item to structure
     */
    addDraggableToStructure(structure, container, draggable, direction, anchor) {
      structure.startTransaction();

      const index = this._getDropIndex(container, anchor, direction, structure);

      const wrappedDraggable = this.prepare(structure, container, draggable);
      structure.addChild(container, wrappedDraggable, index);
      structure.commitTransaction();
      structure.order.updateChildrenOrderOnAdd(container, draggable, index);
    },

    /**
     * check if the column takes 12
     * column width
     */
    hasMaxColumnWidth(draggable, breakpoint) {
      var _draggable$originObje, _draggable$originObje2;

      const draggableSize = (_draggable$originObje = draggable === null || draggable === void 0 ? void 0 : (_draggable$originObje2 = draggable.originObject) === null || _draggable$originObje2 === void 0 ? void 0 : _draggable$originObje2.size) !== null && _draggable$originObje !== void 0 ? _draggable$originObje : null;

      if (!draggableSize) {
        return false;
      }

      return draggableSize[breakpoint] === 12;
    },

    /**
     * Get drop index of draggable into container
     *
     * @param {Ember.Object} container - container where draggable will be dropped
     * @param {Ember.Object} anchor - anchor child, next to which draggable will be dropped
     * @param {String} direction - direction of draggable drop {@link builder/app/core/enumerators/direction.js}
     * @param {Object} structure - structure
     * @returns {Number} - index of draggable drop
     * @private
     */
    _getDropIndex(container, anchor, direction, structure) {
      let index = 0;
      const currentDeviceType = this.getCurrentBreakpoint(anchor);
      const hasOrderedChildren = structure.order.hasOrderedChildren(container, currentDeviceType);

      if (anchor) {
        if (!hasOrderedChildren) {
          var _container$originObje2, _container$originObje3;

          index = container === null || container === void 0 ? void 0 : (_container$originObje2 = container.originObject) === null || _container$originObje2 === void 0 ? void 0 : (_container$originObje3 = _container$originObje2.children) === null || _container$originObje3 === void 0 ? void 0 : _container$originObje3.indexOf(anchor);
        } else {
          index = structure.order._getOrderOfObject(anchor, currentDeviceType);
        }

        if (direction === _direction.default.RIGHT || direction === _direction.default.BOTTOM) {
          index++;
        }
      }

      return index;
    }

  });

  _exports.default = _default;
});