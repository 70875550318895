define("builder/pods/website/edit/page/edit/module-gallery/settings/route", ["exports", "builder/pods/website/edit/page/edit/module/settings/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    renderTemplate(controller, model) {
      const systemModuleController = this.controllerFor('website.edit.page.edit.module-gallery');
      const systemModuleModel = this.modelFor('website.edit.page.edit.module-gallery');
      systemModuleController.set('routeDynamicSegment', 'system-modules');
      this.render('website.edit.page.edit.module-gallery', {
        into: 'website.edit.page',
        outlet: 'modules-gallery',
        controller: systemModuleController,
        model: systemModuleModel
      });
      this.render('website.edit.page.edit.module.settings', {
        into: 'website.edit.page.edit',
        controller,
        model
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.send('setModuleConfigId', model.moduleConfig.id);
    }

  });

  _exports.default = _default;
});