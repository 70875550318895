define("builder/models/website", ["exports", "@ember-data/model", "builder/models/model", "builder/core/enumerators/engine"], function (_exports, _model, _model2, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WebsiteModel
   */
  var _default = _model2.default.extend({
    /**
     * Website id from cloned this site
     * @type {string}
     */
    ClonedFrom: (0, _model.attr)('string'),

    /**
     * Website domain name
     * @type {string}
     */
    Domain: (0, _model.attr)('string'),

    /**
     * Flag indicate publish website or not
     * @type {string}
     */
    IsPublished: (0, _model.attr)('string', {
      defaultValue: '0'
    }),

    /**
     * Flag indicate website engine
     * @type {string}
     */
    IsTT: (0, _model.attr)('string'),

    /**
     * Indicate is template website or not
     * @type {string}
     */
    IsTemplate: (0, _model.attr)('string'),

    /**
     * Id of website menu
     * @type {string}
    	 */
    MenuId: (0, _model.attr)('string'),

    /**
     * Website protocol
     * @type {string}
     */
    Protocol: (0, _model.attr)('string'),

    /**
     * Ids of website dealers
     * @type {number[]}
     */
    DealerIds: (0, _model.attr)('raw'),

    /**
     * List of OEM names
     * @type {string[]}
     */
    OEM: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),

    /**
     * Website group
     * @type {string[]}
     */
    WebsiteGroup: (0, _model.attr)('raw'),

    /**
     * Check whether website is Twin Turbo
     * @return {boolean}
     */
    isTwinTurbo() {
      return this.IsTT === _engine.TT;
    }

  });

  _exports.default = _default;
});