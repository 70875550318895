define("builder/core/enumerators/widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WidgetType = void 0;

  /**
   * Enum for available widgets
   */
  const WidgetType = Object.freeze({
    WIDGET_PARAGRAPH: 'widget-paragraph',
    WIDGET_IMAGE: 'widget-image',
    WIDGET_BUTTON: 'widget-button',
    WIDGET_BUTTONSGROUP: 'widget-buttonsgroup',
    WIDGET_SPACER: 'widget-spacer',
    WIDGET_LINE_DIVIDER: 'widget-line-divider',
    WIDGET_SECTION: 'widget-section',
    WIDGET_TAB_PANE: 'widget-tab-pane'
  });
  _exports.WidgetType = WidgetType;
});