define("builder/widgets/configs/widget-line-divider", ["exports", "builder/core/styles-management/widget-line-divider/structure", "ember-copy", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes"], function (_exports, _structure, _emberCopy, _fields, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Configuration for widget-line-divider
   * This fields will appear on module configuration popup
   */
  var _default = {
    /**
     * Essential method for widget configuration
     * Will be called during widget instantiation
     * see builder/app/services/widget.js file for more info
     *
     * @returns {Object} plain object with fields which will appear on module config popup
     */
    getConfig() {
      return {
        styles: (0, _emberCopy.copy)(_structure.default, true),
        config: [{
          type: _fields.default.CHECKBOX_LIST,
          label: 'Show line divider on which devices / breakpoints?',
          description: 'Please select at least 1 device for the module to show on',
          help: 'When Un-checking the box means the widget will be hidden on that device / breakpoint.',
          name: 'visibleOnDevices',
          data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
          choices: [{
            value: _breakpointModes.LARGE,
            label: 'Desktop'
          }, {
            value: _breakpointModes.MEDIUM,
            label: 'Small Monitor'
          }, {
            value: _breakpointModes.SMALL,
            label: 'Tablet'
          }, {
            value: _breakpointModes.EXTRA_SMALL,
            label: 'Mobile'
          }],
          validation: {
            elements: {
              min: 1,
              max: 4
            },
            message: 'You must select at least one device for this module to display on.'
          }
        }]
      };
    }

  };
  _exports.default = _default;
});