define("builder/pods/components/filter-property/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPropertyObject = getPropertyObject;
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['bld-filter-property'],

    /**
     * @property {String} label - Property label
     */
    label: Ember.computed('property.parent.label', 'property.label', function () {
      return `${this.get('property.parent.label')} ${this.get('property.label')}`;
    }),

    /**
     * @property {Array} operators - Array of operators object
     */
    operators: Ember.computed('property.children', function () {
      return this.get('property.children').map(operator => _jquery.default.extend(true, {}, operator));
    }),

    /**
     * @inhericDoc
     */
    didInsertElement() {
      this.sendUpdateLeftOperand();
    },

    /**
     * Sends action to update left operand
     */
    sendUpdateLeftOperand() {
      const {
        property
      } = this; // If left operand is NOT defined then user adds new property, else saved "data" is rendering

      if (!property.expression.getLeftOperand()) {
        const propertyObject = getPropertyObject(property);
        this.updateLeftOperand(property.expression, propertyObject);
      }
    },

    actions: {
      /**
       * Remove property from filters
       */
      removeProperty() {
        this.removeHandler();
      },

      /**
       * Handles right operand update
       * @param {Object} expression - Expression class
       * @param {*} operand - Operand
       */
      handleRightOperandAction(expression, operand) {
        this.sendUpdateLeftOperand();
        this.updateRightOperand(expression, operand);
      },

      /**
       * Handles set operator
       * @param {Object} expression - Expression class
       * @param {String} operator - Operator
       */
      handleSetOperatorAction(expression, operator) {
        this.sendUpdateLeftOperand();
        this.setOperator(expression, operator);
      },

      /**
       * Handles operator
       * @param {Object} expression - Expression class
       * @param {String} operator - Operator
       */
      handleOperatorAction(expression, operator) {
        this.updateOperator(expression, operator);
      }

    }
  });
  /**
   * Returns object type operand value
   * @param {Object} property - Filter-property object
   * @returns {{name: String, property: String}}
   */


  _exports.default = _default;

  function getPropertyObject(property) {
    return {
      name: property.parent.name,
      property: property.name
    };
  }
});