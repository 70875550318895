define("builder/pods/components/field-search-dropdown/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "ember-copy"], function (_exports, _fieldValidation, _fieldVisibility, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['bld-form_search-dropdown'],

    /**
     * Selected option
     * @type {null|Object}
     */
    selectedOption: null,

    /**
     * Parent action handler
     * @type {Function}
     */
    actionHandler: null,

    /**
     * Flat go expand/close dropdown
     * @type {boolean}
     */
    isExpanded: false,

    /**
     * Label of selected option
     * @type {string}
     */
    selectedOptionLabel: Ember.computed('field.data', 'selectedOption', 'field.placeholder', function () {
      const currentOption = this.choices.findBy('value', this.field.data);
      let optionValue = '';

      if (this.selectedOption) {
        optionValue = this.selectedOption.label;
      } else if (currentOption) {
        optionValue = currentOption.label;
      } else {
        optionValue = this.field.placeholder;
      }

      return optionValue;
    }),

    /**
     * List of options
     * @type {Object[]}
     */
    choices: Ember.computed('field.choices', function () {
      return this.field.choices.map(option => {
        const newOption = (0, _emberCopy.copy)(option, true);
        newOption.visible = true;
        return newOption;
      });
    }),

    /**
     * Closes dropdown
     */
    closeDropdown() {
      this.set('isExpanded', false);
    },

    /**
     * Passes selected value and name to parent action
     */
    sendValueToParent() {
      if (typeof this.actionHandler === 'function') {
        this.actionHandler(this.selectedOption.value, this.field.name);
      }
    },

    actions: {
      selectOption(option) {
        this.set('selectedOption', option);
        this.closeDropdown();
        this.sendValueToParent();
      },

      search(query) {
        const normalizedQuery = query.toLowerCase();
        this.choices.forEach(option => {
          Ember.set(option, 'visible', option.label.toLowerCase().indexOf(normalizedQuery) !== -1);
        });
      },

      showFullList() {
        this.choices.forEach(option => {
          Ember.set(option, 'visible', true);
        });
      }

    }
  });

  _exports.default = _default;
});