define("builder/services/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * This service provide undo/redo functionality for Ember.Models or ember-fragments
   *
   * Usage:
   *
   * 	// First need to create a checkpoint.
   * 	history.checkpoint(model);
   *
   * 	// After we create checkpoint we can navigate through changes with undo/redo methods
   * 	history.undo(); // go back
   * 	history.redo(); // go forward
   *
   *  // We can change added before model and make a checkpoint again
   *  model.set('Title', 'new title');
   *  history.checkpoint(model);
   *
   *  // Or add several models and bound it to one checkpoint. Each created model snapshot will be bound to one checkpoint.
   *  // That means that method undo/redo will apply all changes for that models at same time.
   *  history.checkpoint([model, model2,...]);
   *
   *  // If you want to clear all checkpoint just call
   *  history.clear();
   *
   */
  var _default = Ember.Service.extend({
    /**
     * contain checkpoints
     * @type {Ember.NativeArray}
     * @private
     */
    _stack: null,

    /**
     * pointer position
     * @type {number}
     * @private
     */
    _index: -1,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_stack', Ember.A([]));
    },

    /**
     * Main method create checkpoint.
     * This method can create checkpoint for several models.
     *
     * @param {object|object[]} data - object with models to create snapshot and callbacks.
     * If passed array of models, for each will be created snapshot which will be bound to one checkpoint.
     * @returns {number} - stack length
     */
    checkpoint(data) {
      const changedModels = Ember.A([]);
      const stack = this._stack;
      let snapshotData = data;

      if (Ember.isEmpty(Ember.get(snapshotData, 'length'))) {
        snapshotData = Ember.A([snapshotData]);
      }

      snapshotData.forEach(obj => {
        const {
          model,
          callback
        } = obj;
        const changedAttrs = model.changedAttributes();

        if (Object.keys(changedAttrs).length) {
          const snapshot = model.get('_internalModel').createSnapshot();
          const changes = {
            type: Ember.get(snapshot, 'modelName'),
            attributes: null,
            action: 'update',
            snapshot,
            callback
          };

          if (!Ember.isEmpty(stack)) {
            const length = Ember.get(stack, 'length');
            const index = this._index; // Delete all checkpoint if user makes several calls undo and then makes new checkpoint.

            if (length - 1 !== index) {
              const from = index + 1;
              stack.removeAt(from, length - from);
            }

            const prevModel = this._getLastSnapshotByType(changes.type, model);

            if (prevModel) {
              Object.keys(changedAttrs).forEach(key => {
                if (prevModel.attributes[key]) {
                  changedAttrs[key][0] = Ember.get(prevModel, 'snapshot').attributes()[key];

                  if (changedAttrs[key][0] === changedAttrs[key][1]) {
                    delete changedAttrs[key];
                  }
                }
              });
            }
          }

          if (!Ember.isEmpty(changedAttrs)) {
            changes.attributes = changedAttrs;
            changedModels.pushObject(changes);
          }
        }
      });

      if (!Ember.isEmpty(changedModels)) {
        stack.pushObject(changedModels);
        this.set('_index', Ember.get(stack, 'length') - 1);
      }

      return this.get('_stack.length');
    },

    /**
     * Method go back by history
     * @returns {void}
     */
    undo() {
      if (this.isCanGoBack) {
        const items = this._stack.objectAt(this._index);

        this._applyCheckpoint(items, 0);

        this.set('_index', this._index - 1);
      }
    },

    /**
     * Method go forward by history
     * @returns {void}
     */
    redo() {
      if (this.isCanGoForward) {
        const index = this._index + 1;

        const items = this._stack.objectAt(index);

        this._applyCheckpoint(items, 1);

        this.set('_index', index);
      }
    },

    /**
     * Method remove all saved snapshots
     *
     * @returns {number} - stack length
     */
    clear() {
      const stack = this._stack;
      stack.clear();
      this.set('_index', -1);
      return stack.get('length');
    },

    /**
     * Detect that we can go to previous snapshot
     *
     * @type {boolean}
     */
    isCanGoBack: Ember.computed('_index', function () {
      return this._index >= 0;
    }),

    /**
     * Detect that we can go to the next snapshot
     *
     * @type {boolean}
     */
    isCanGoForward: Ember.computed('_index', '_stack.[]', function () {
      if (!this._stack || !this._stack.length) {
        return false;
      }

      return this._index < this._stack.length - 1;
    }),

    /**
     * Apples data of checkpoint
     * @param {object[]} data - Data to apply
     * @param {number} index - Index of changed attributes
     * @private
     * @returns {void}
     */
    _applyCheckpoint(data, index) {
      data.forEach(changes => {
        const record = Ember.get(changes, 'snapshot.record');
        const attributes = Ember.get(changes, 'attributes');
        Object.keys(attributes).forEach(key => {
          record.set(key, attributes[key][index]);
        });

        if (typeof changes.callback === 'function') {
          changes.callback();
        }
      });
    },

    /**
     * Method find record in stored before checkpoints using model type and ref on instance
     *
     * @param {string} type - name of model
     * @param {Ember.Model} record - record which will be used to find stored before
     * @returns {object}
     * @private
     */
    _getLastSnapshotByType(type, record) {
      const stack = this._stack;

      for (let i = stack.length - 1; i >= 0; i -= 1) {
        for (let j = stack[i].length - 1; j >= 0; j -= 1) {
          if (Ember.get(stack[i][j], 'type') === type && record === Ember.get(stack[i][j], 'snapshot.record')) {
            return stack[i][j];
          }
        }
      }

      return null;
    }

  });

  _exports.default = _default;
});