define("builder/validators/unique-variant", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UniqueVariant = _base.default.extend({
    /**
     * @inheritdoc
     */
    validate(_, options, model) {
      const variants = model.get('field.data.[]');

      if (!Ember.isArray(variants) || variants.length < 2) {
        return true;
      }

      const {
        byFieldNames,
        errorMessage
      } = options;
      let hashes = [];

      for (let i = 0; i < variants.length; i++) {
        hashes.push(this._makeHash(variants[i], byFieldNames));
      }

      hashes = hashes.uniq();
      return hashes.length === variants.length || errorMessage;
    },

    /**
     * Make hash from values based on passed fields names
     * @param {Object} object - source object
     * @param {String[]} byFieldNames - field names for hash creation
     * @return {String}
     * @private
     */
    _makeHash(object, byFieldNames) {
      const hash = [];

      for (let i = 0; i < byFieldNames.length; i++) {
        const name = byFieldNames[i];

        if (object.hasOwnProperty(name)) {
          hash.push(JSON.stringify(object[name]));
        }
      }

      return hash.join();
    }

  });

  UniqueVariant.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor() {
      return [];
    }

  });
  var _default = UniqueVariant;
  _exports.default = _default;
});