define("builder/pods/components/link-update-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let LinkUpdatePopup = (_dec = Ember._action, _dec2 = Ember._action, (_class = (_temp = class LinkUpdatePopup extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.linkText = null;
      this.linkData = null;

      this.onPopupClose = () => {
        throw new Ember.Error('onPopupClose handler should be provided');
      };

      this.onLinkUpdate = () => {
        throw new Ember.Error('onLinkUpdate handler should be provided');
      };
    }

    /**
     * Action for popup cancel event
     * @returns {void}
     */
    onCancelClick() {
      this.onPopupClose();
    }

    onUpdateClick() {
      this.onLinkUpdate(this.linkText.data, this.linkData.data);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "onCancelClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateClick"), _class.prototype)), _class));
  _exports.default = LinkUpdatePopup;
});