define("builder/pods/components/field-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iZxfIWcr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"field-select\",null,[[\"name\",\"content\",\"prompt\",\"data\",\"attr\",\"changeAction\",\"disabled\"],[[24,[\"field\",\"name\"]],[24,[\"choicesArray\"]],[24,[\"field\",\"placeholder\"]],[24,[\"field\",\"data\"]],[24,[\"field\",\"attr\"]],[28,\"action\",[[23,0,[]],\"change\"],null],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});