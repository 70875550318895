define("builder/core/validation", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Validation {
    constructor(field, object) {
      this.rules = [];
      return this.create(field, object);
    }

    addRule(name, options) {
      this.rules.push((0, _emberCpValidations.validator)(name, options));
    }

    addRequiredRule(validation) {
      if (!validation.required) {
        return;
      }

      this.addRule('presence', validation.required);
    }

    addLengthRule(validation) {
      if (!validation.length) {
        return;
      }

      this.addRule('length', validation.length);
    }

    addPatternRule(validation) {
      if (!validation.pattern) {
        return;
      } // Convert pattern string from validation object to correct pattern string in js


      const flagsPattern = /([gimuy]+)$/;
      const flagsMatch = validation.pattern.match(flagsPattern);
      let flags = '';
      let patternString = validation.pattern;
      const newRules = validation.message ? {
        message: validation.message
      } : {};

      if (flagsMatch && flagsMatch[1]) {
        [, flags] = flagsMatch;
        patternString = patternString.replace(flagsPattern, '');
      }

      newRules.regex = new RegExp(patternString.replace(/\/(.*)?\/$/, '$1'), flags);
      this.addRule('format', newRules);
    }

    addRangeRule(validation) {
      if (!validation.range) {
        return;
      }

      this.addRule('number', {
        allowString: true,
        gte: validation.range.min,
        lte: validation.range.max
      });
    }

    addPageAlias(validation) {
      if (!validation.pageAlias) {
        return;
      }

      this.addRule('page-alias', validation.pageAlias);
    }

    addSeoUrls(validation) {
      if (!validation.seoUrls) {
        return;
      }

      this.addRule('seo-urls', validation.seoUrls);
    }

    addFormTitle(validation) {
      if (!validation.formTitle) {
        return;
      }

      this.addRule('form-title', validation.formTitle);
    }

    addCollectionLengthRule(validation) {
      const params = validation.choice || validation.elements;

      if (!params) {
        return;
      }

      const {
        message
      } = validation;
      this.addRule('length', {
        min: params.min,
        max: params.max,
        message: message || '{description} length is invalid. It should be between {min} and {max} items long.'
      });
    }

    addUniqueVariant(validation) {
      if (!validation.uniqueVariant) {
        return;
      }

      this.addRule('unique-variant', {
        byFieldNames: validation.uniqueVariant.byFieldNames,
        errorMessage: validation.uniqueVariant.errorMessage
      });
    }

    addDelegate(validation) {
      if (!validation.delegate) {
        return;
      } // Since function as option cannot be passed as 1-st level option, find out why


      this.addRule('delegate', {
        validate: {
          fn: validation.delegate.validate
        }
      });
    }

    addContainerTemplateTitle(validation) {
      if (!validation.containerTemplateTitle) {
        return;
      }

      this.addRule('container-template-title', validation.containerTemplateTitle);
    }

    addUniqContainerAnchor(validation) {
      if (!validation.uniqContainerAnchor) {
        return;
      }

      this.addRule('uniq-container-anchor', validation.uniqContainerAnchor);
    }

    getRules() {
      return this.rules;
    }

    create(field, validation) {
      if (!validation) {
        return false;
      }

      const buildObject = {};
      this.addRequiredRule(validation);
      this.addLengthRule(validation);
      this.addPatternRule(validation);
      this.addRangeRule(validation);
      this.addCollectionLengthRule(validation);
      this.addPageAlias(validation);
      this.addSeoUrls(validation);
      this.addFormTitle(validation);
      this.addUniqueVariant(validation);
      this.addDelegate(validation);
      this.addContainerTemplateTitle(validation);
      this.addUniqContainerAnchor(validation);
      buildObject[field] = this.getRules(); // TODO Remove after refactor validation system
      // This code for immediately run validation.
      // Used in for form-variants at context form lead routing tab

      if (validation.hasOwnProperty('uniqueVariant')) {
        buildObject['field.immediateUniqueValidationHash'] = [(0, _emberCpValidations.validator)('unique-variant', {
          byFieldNames: validation.uniqueVariant.byFieldNames,
          errorMessage: validation.uniqueVariant.errorMessage
        })];
      }

      return (0, _emberCpValidations.buildValidations)(buildObject, {
        disabled: Ember.computed.readOnly('model.disableValidation')
      });
    }

  }

  _exports.default = Validation;
});