define("builder/core/widget", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['js-widget']
  });

  _exports.default = _default;
});