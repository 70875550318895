define("builder/core/meta-tags/dom-manipulation/dom-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createCurlyBracesRange = createCurlyBracesRange;
  _exports.setCaretToNodeEnd = setCaretToNodeEnd;
  _exports.setCaretToNodeStart = setCaretToNodeStart;

  /**
   * Extract curly braces as selection to manipulate
   * @returns {Range}
   */
  function createCurlyBracesRange() {
    const sourceSelection = document.getSelection();
    const range = document.createRange();
    const element = sourceSelection.anchorNode;
    const endOffset = sourceSelection.anchorOffset; // assuming that anchor node is text node and last 2 chars are {{

    const startOffset = endOffset - 2;
    range.setStart(element, startOffset);
    range.setEnd(element, endOffset);
    return range;
  }
  /**
   * Sets caret to the end of node content
   * @param {Node} node
   */


  function setCaretToNodeEnd(node) {
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }
  /**
   * Sets carret to the end of node content
   * @param {Node} node
   */


  function setCaretToNodeStart(node) {
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
});