define("builder/adapters/module-theme", ["exports", "builder/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @inheritDoc
     */
    pathForType() {
      return 'module_theme';
    },

    /**
     * Modules themes containing data which related to organization.
     * Every time findAll called, data should be refetched.
     * This method is a build in hook for ember-data adapter.
     * If it returns true data will be refetched after findAll method call.
     * See official documentation:
     * https://api.emberjs.com/ember-data/3.8/classes/DS.Adapter/methods?anchor=shouldBackgroundReloadAll
     * @inheritDoc
     */
    shouldBackgroundReloadAll() {
      return true;
    }

  });

  _exports.default = _default;
});