define("builder/pods/components/system-modules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rb2+J4xS",
    "block": "{\"symbols\":[\"module\",\"manager\"],\"statements\":[[4,\"each\",[[24,[\"systemModules\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"dropdown-container\",[[12,\"class\",[28,\"if\",[[23,1,[\"isEnabled\"]],\"s-dropdown s-dropdown_theme_system-modules\",\"s-dropdown s-dropdown_theme_system-modules s-dropdown_theme_system-modules_inactive\"],null]]],[[\"@activeClass\",\"@isExpanded\",\"@closeSelector\",\"@autoPosition\"],[\"s-dropdown_opened\",false,\".system-modules\",false]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"dropdown-button\",[[12,\"class\",\"s-dropdown__button\"]],[[\"@manager\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"system-modules__indicator\",[28,\"if\",[[23,1,[\"IsActive\"]],\" system-modules__indicator_checked\"],null]]]],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"system-modules__label\"],[8],[1,[23,1,[\"module\",\"Label\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"s-dropdown__icon s-dropdown__icon_arrow-styles icon-expanded-arrow\"],[8],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[5,\"dropdown-dropable\",[[12,\"class\",\"s-dropdown__dropable\"]],[[\"@manager\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"system-module\",[],[[\"@module\",\"@toggleModule\",\"@changeTheme\",\"@cogClick\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleModule\"],null],[28,\"action\",[[23,0,[]],\"changeTheme\"],null],[28,\"action\",[[23,0,[]],\"cogClick\"],null]]]],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/system-modules/template.hbs"
    }
  });

  _exports.default = _default;
});