define("builder/pods/components/website-menu/component", ["exports", "builder/models/menu-child", "jquery"], function (_exports, _menuChild, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO: This component must be remade, since we use new menu
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['website-menu'],

    /**
     * @property {Boolean} noShowLink - property specifies whether to show links
     */
    noShowLinks: false,

    /**
     * @property {ConfigurationService} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {MenuChildModel[]} pageListSort - sorted array of all item menu
     */
    pageListSort: Ember.computed('configurationService.mainMenu.Structure.[]', 'configurationService.unlinkedMenu.Structure.[]', function () {
      const links = [];

      this._recursiveMenuItem(links, this.get('configurationService.mainMenu.Structure'));

      this._recursiveMenuItem(links, this.get('configurationService.unlinkedMenu.Structure'));

      return links.filter(item => !item.get('isMissingPage')).sort((a, b) => {
        const aTitle = a.get('title').toLowerCase();
        const bTitle = b.get('title').toLowerCase();
        const greaterReturn = aTitle > bTitle ? 1 : 0;
        return aTitle < bTitle ? -1 : greaterReturn;
      });
    }),

    /**
     * @property {String} internalSearch - internal search value
     */
    internalSearch: '',

    /**
     * @property {null|Number} menuItemId - id of selected menu item(when internal link selected)
     */
    menuItemId: null,

    /**
     * @property {null|Number} focusTimeout - focus timeout id
     */
    focusTimeout: null,

    /**
     * @property {Ember.observer} onMenuOpen - menu open observer
     */
    onMenuOpen: Ember.observer('condition', function () {
      this.send('clearSearch');

      if (this.condition) {
        // Need to be sure input is visible.
        this.set('focusTimeout', Ember.run.later(() => {
          const input = this.$('.website-menu_search input');

          if (input && input.length) {
            input.trigger('focus');
          }

          this.set('focusTimeout', null);
        }, 300));
      }
    }),

    /**
     * @property {null|Function} onSelectMenuItem - callback to be invoked when menu item selected
     */
    onSelectMenuItem: null,

    /**
     * @inheritdoc
     */
    didUpdate() {
      if (this.internalSearch) {
        const selector = '.website-menu_list .website-menu_list_sub-items.__opened:last .website-menu_list_item:visible:last';
        (0, _jquery.default)(selector).addClass('__no-border-bottom');
      } else {
        (0, _jquery.default)('.__no-border-bottom').removeClass('__no-border-bottom');
      }
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.send('clearSearch');
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      const timeout = this.focusTimeout;

      if (timeout) {
        Ember.run.cancel(timeout);
      }
    },

    /**
     * Filter linked list by passed in regexp
     *
     * TODO: split filter and show parent into 2 separate method
     *
     * @param {Array} array - array for pushing result
     * @param {MenuChildModel[]} menu - menu child model
     * @private
     */
    _recursiveMenuItem(array, menu) {
      menu.forEach(item => {
        if (item.get('type') === _menuChild.default.FOLDER) {
          this._recursiveMenuItem(array, item.get('children'));
        } else if (!this.noShowLinks || item.get('type') !== _menuChild.default.LINK) {
          array.push(item);
        }
      });
    },

    actions: {
      clearSearch() {
        this.set('internalSearch', '');
      },

      transition(alias) {
        if (typeof this.action === 'function') {
          this.action(alias);
        }
      }

    }
  });

  _exports.default = _default;
});