define("builder/core/drop-rules/rule-resolver", ["exports", "builder/core/drop-rules/top-container", "builder/core/drop-rules/widget-column", "builder/core/drop-rules/widget-container", "builder/core/drop-rules/widget-footer", "builder/core/drop-rules/widget-header", "builder/core/drop-rules/widget-main", "builder/core/drop-rules/widget-row", "builder/core/drop-rules/widget-section", "builder/core/drop-rules/widget-tab-pane"], function (_exports, _topContainer, _widgetColumn, _widgetContainer, _widgetFooter, _widgetHeader, _widgetMain, _widgetRow, _widgetSection, _widgetTabPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Should return rule by it's name
   * basically, if you need rules for widget-container, you should call rule-resolver.getRule('widget-container');
   */
  var _default = Ember.Object.extend({
    /**
     * Grid builder service
     * @type {import('ember').Ember.Service}
     */
    gridBuilder: null,

    /**
     * Hash table of registered rules
     * @type {object}
     * @private
     */
    _rules: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_rules', {});

      this._prepare(this.gridBuilder);
    },

    /**
     * Register all predefined rules
     *
     * @param {object} gridBuilder - grid builder service
     * @private
     */
    _prepare(gridBuilder) {
      this.registerRule('top-container', _topContainer.default.create({
        gridBuilder
      }));
      this.registerRule('widget-column', _widgetColumn.default.create({
        gridBuilder
      }));
      this.registerRule('widget-container', _widgetContainer.default.create({
        gridBuilder
      }));
      this.registerRule('widget-footer', _widgetFooter.default.create({
        gridBuilder
      }));
      this.registerRule('widget-header', _widgetHeader.default.create({
        gridBuilder
      }));
      this.registerRule('widget-main', _widgetMain.default.create({
        gridBuilder
      }));
      this.registerRule('widget-row', _widgetRow.default.create({
        gridBuilder
      }));
      this.registerRule('widget-section', _widgetSection.default.create({
        gridBuilder
      }));
      this.registerRule('widget-tab-pane', _widgetTabPane.default.create({
        gridBuilder
      }));
    },

    /**
     * Register new rule to the set of already registered rules, to make it available through "getRule" method
     *
     * @param {string} name - name/identificator of rule to add
     * @param {object} rule - rule itself, instance of base-rule
     * @returns {object}
     */
    registerRule(name, rule) {
      const rules = this._rules;

      if (rules.hasOwnProperty(name)) {
        throw new Ember.Error(`Rule "${name}" already registered`);
      }

      rules[name] = rule;
      return this;
    },

    /**
     * Get rule by name/identifier
     *
     * @param {string} name - name of rule to get
     * @returns {object} - rule
     */
    getRule(name) {
      const rules = this._rules;
      let rule = null;

      if (rules.hasOwnProperty(name)) {
        rule = rules[name];
      }

      return rule;
    }

  });

  _exports.default = _default;
});