define("builder/core/data-filters/index", ["exports", "builder/core/data-filters/enabled-third-party-scripts", "builder/core/data-filters/market-segments"], function (_exports, _enabledThirdPartyScripts, _marketSegments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    enabledThirdPartyScripts: _enabledThirdPartyScripts.default,
    marketSegments: _marketSegments.default
  };
  _exports.default = _default;
});