define("builder/widgets/configs/widget-button", ["exports", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "builder/core/styles-management/widget-button/structure", "ember-copy", "builder/config/environment"], function (_exports, _fields, _breakpointModes, _structure, _emberCopy, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const buttonTextLayout = {
    type: _fields.default.DROPDOWN,
    label: 'Button Text Layout',
    name: 'buttonTextLayout',
    choices: [{
      value: 'horizontal',
      label: 'Horizontal'
    }, {
      value: 'vertical',
      label: 'Vertical Stack'
    }]
  };
  const buttonTitle = {
    type: _fields.default.TEXT,
    label: 'Title',
    name: 'title',
    validation: {
      required: true,
      pattern: '\\S',
      length: {
        max: 40
      }
    }
  };
  const buttonIcon = {
    type: _fields.default.ICON,
    label: 'Icon',
    name: 'icon'
  };
  const buttonLink = {
    type: _fields.default.LINK,
    label: 'URL',
    name: 'url',
    availableType: 'both',
    validation: {
      required: true
    }
  };
  const buttonMinimumWidth = {
    type: _fields.default.NUMBER_WITH_UNITS,
    label: 'Minimum width',
    name: 'width',
    description: 'Define the precise minimum width of the button in pixels or percents',
    data: '60px',
    units: [{
      symbol: 'px',
      label: 'px'
    }, {
      symbol: '%',
      label: '%'
    }],
    validation: {
      pattern: '^(?:0|\\d+)[.,]?\\d*\\D*$'
    }
  };
  const buttonSize = {
    type: _fields.default.DROPDOWN,
    label: 'Size',
    name: 'size',
    choices: [{
      value: 'default',
      label: 'Default'
    }, {
      value: 'xl',
      label: 'Extra Large'
    }, {
      value: 'lg',
      label: 'Large'
    }, {
      value: 'sm',
      label: 'Small'
    }]
  };
  const buttonStyle = {
    type: _fields.default.DROPDOWN,
    label: 'Style',
    name: 'style',
    choices: [{
      value: 'primary',
      label: 'Primary'
    }, {
      value: 'default',
      label: 'Secondary'
    }, {
      value: 'tertiary',
      label: 'Tertiary'
    }, {
      value: 'quaternary',
      label: 'Quaternary'
    }, {
      value: 'quinary',
      label: 'Quinary'
    }]
  };
  const buttonAlign = {
    type: _fields.default.DROPDOWN,
    label: 'Align',
    name: 'aligned',
    choices: [{
      value: 'left',
      label: 'Left'
    }, {
      value: 'center',
      label: 'Center'
    }, {
      value: 'right',
      label: 'Right'
    }]
  };
  const buttonVisibility = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Show button on which devices / breakpoints?',
    description: 'Please select at least 1 device for the module to show on',
    help: 'When Un-checking the box means the widget will be hidden on that device / breakpoint.',
    name: 'visibleOnDevices',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this module to display on.'
    }
  };
  /**
   * Widget button configuration
   */

  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      if (_environment.default === null || _environment.default === void 0 ? void 0 : _environment.default.featureFlags['release-widget-button-styles']) {
        return {
          styles: (0, _emberCopy.copy)(_structure.default, true),
          config: [buttonTitle, buttonIcon, buttonLink, buttonMinimumWidth, buttonSize, buttonTextLayout, buttonStyle, buttonAlign, buttonVisibility]
        };
      }

      return {
        config: [buttonTitle, buttonIcon, buttonLink, buttonMinimumWidth, buttonSize, buttonStyle, buttonAlign, buttonVisibility]
      };
    }

  });

  _exports.default = _default;
});