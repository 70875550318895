define("builder/services/popups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PopupsService extends Ember.Service {
    /**
     * Shows popup on top of all elements with some content
     * @param {HTMLElement} contentElement - content that will be shown inside popup
     * @returns {void}
     */
    showPopup(contentElement) {
      const popup = this._getPopupBlock(contentElement);

      document.body.classList.add('bld-no-scroll');
      document.body.appendChild(popup);
    }
    /**
     * Creates popup block with all event handlers
     * @param {HTMLElement} contentElement - content that will be placed inside popup
     * @returns {HTMLElement}
     */


    _getPopupBlock(contentElement) {
      const wrapperElement = document.createElement('div');
      const containerElement = document.createElement('div');

      const closeIconElement = this._getIconElement(wrapperElement);

      const loaderElement = this._getLoaderElement();

      wrapperElement.classList.add('bld-popup__wrapper');
      containerElement.classList.add('bld-popup__content');
      wrapperElement.addEventListener('click', event => this._closeHandler(wrapperElement));
      containerElement.addEventListener('click', event => event.stopPropagation());
      contentElement.addEventListener('load', event => {
        wrapperElement.removeChild(loaderElement);
        containerElement.appendChild(closeIconElement);
        containerElement.appendChild(contentElement);
        wrapperElement.appendChild(containerElement);

        this._adjustContentDimensions(event.target);
      });
      wrapperElement.appendChild(loaderElement);
      return wrapperElement;
    }
    /**
     * 	Popup close event handler
     * @param {HTMLElement} wrapperElement - popup background element
     * @returns {void}
     */


    _closeHandler(wrapperElement) {
      document.body.classList.remove('bld-no-scroll');
      document.body.removeChild(wrapperElement);
    }
    /**
     * Adjusts width and height of element according screen size
     * @param {HTMLElement} contentElement - element to adjust
     * @returns {void}
     */


    _adjustContentDimensions(contentElement) {
      if (contentElement.offsetWidth > window.innerWidth) {
        contentElement.style.width = '90vw';
        contentElement.style.height = 'auto';
      }

      if (contentElement.offsetHeight > window.innerHeight) {
        contentElement.style.width = 'auto';
        contentElement.style.height = '90vh';
      }
    }
    /**
     * Creates close icon element
     * @param {HTMLElement} wrapperElement - popup background element
     * @returns {HTMLElement}
     */


    _getIconElement(wrapperElement) {
      const iconElement = document.createElement('i');
      iconElement.classList.add('fa', 'fa-times', 'bld-popup__close');
      iconElement.addEventListener('click', event => this._closeHandler(wrapperElement));
      return iconElement;
    }
    /**
     * Creates loader icon element
     * @returns {HTMLElement}
     */


    _getLoaderElement() {
      const loaderElement = document.createElement('i');
      loaderElement.classList.add('fa', 'fa-refresh', 'fa-spin');
      loaderElement.style.color = '#ffffff';
      loaderElement.style.fontSize = '25px';
      return loaderElement;
    }

  }

  _exports.default = PopupsService;
});