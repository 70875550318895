define("builder/pods/components/gallery-container-template/component", ["exports", "builder/pods/components/gallery-item/component", "builder/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @property {String[]} classNames - classes to be added to ember wrapper
     */
    classNames: ['__theme'],

    /**
     * @property {String} image - image src
     * @readonly
     */
    image: Ember.computed('data.image', function () {
      return this.get('data.image') || `${_environment.default.rootURL}images/default-image.png`;
    }).readOnly(),

    /**
     * Theme original image for zoom popup
     * @type {string}
     * @readonly
     */
    originalImage: Ember.computed.readOnly('data.data.OriginalImage'),

    /**
     * @property {String} thumbnailCss - style css for display thumbnail of item
     * @readonly
     */
    thumbnailCss: Ember.computed('image', function () {
      return Ember.String.htmlSafe(`background-image: url(${this.image})`);
    }).readOnly(),

    /**
     * @property {String} name - name(title) of template
     * @readonly
     */
    name: Ember.computed.readOnly('data.title'),

    /**
     * @property {Boolean} canDelete - indicates the ability of delete template from the gallery
     * @readonly
     */
    canDelete: Ember.computed.readOnly('data.canDelete'),

    /**
     * @property {Boolean} canEdit - indicates the ability of edit template from the gallery
     * @readonly
     */
    canEdit: Ember.computed.readOnly('data.canEdit'),

    /**
     * @inheritDoc
     */
    click(event) {
      event.preventDefault();
    },

    actions: {
      deleteTemplate() {
        if (typeof this.onDelete === 'function') {
          this.onDelete(this.ref);
        }
      },

      editTemplate() {
        if (typeof this.onEdit === 'function') {
          this.onEdit(this.ref);
        }
      }

    }
  });

  _exports.default = _default;
});