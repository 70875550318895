define("builder/pods/components/sm-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YD1ypohC",
    "block": "{\"symbols\":[\"manager\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"line-box__line-cont\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showDeviceDependent\"]]],null,{\"statements\":[[4,\"dropdown-container\",null,[[\"autoPosition\",\"delay\",\"method\",\"class\"],[false,1000,\"hover\",\"s-dropdown s-dropdown_theme_tooltip\"]],{\"statements\":[[4,\"dropdown-button\",null,[[\"manager\"],[[23,1,[]]]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"s-dropdown__button s-dropdown__button-icon s-dropdown__button--clickable line-box__ellipsis-right\"],[3,\"action\",[[23,0,[]],\"toggleLock\"]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLocked\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[7,\"use\",true],[10,\"xlink:href\",\"#icon-devices-style-locked\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[7,\"use\",true],[10,\"xlink:href\",\"#icon-devices-style-unlocked\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"dropdown-dropable\",null,[[\"manager\",\"class\"],[[23,1,[]],\"s-dropdown__tooltip\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"html-format\",[[24,[\"tooltipText\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\tImage\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"line-box__line-cont\"],[8],[0,\"\\n\\t\"],[1,[28,\"field-media\",null,[[\"useStylesManagementView\",\"field\",\"actionHandler\"],[true,[24,[\"field\"]],[28,\"action\",[[23,0,[]],\"change\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/sm-image/template.hbs"
    }
  });

  _exports.default = _default;
});