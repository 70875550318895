define("builder/core/styles-management/widget-button/structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Described components for container styles.
   * Values for each component passed before render.
   */
  var _default = [{
    label: 'Button padding',
    name: 'composite',
    type: 'rectangle',
    children: [{
      label: 'Right',
      variableName: 'padding-right',
      cssPropertyName: 'padding-right',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Left',
      variableName: 'padding-left',
      cssPropertyName: 'padding-left',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 150
            }
          }
        }
      }
    }, {
      label: 'Top',
      variableName: 'padding-top',
      cssPropertyName: 'padding-top',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 150
            }
          }
        }
      }
    }, {
      label: 'Bottom',
      variableName: 'padding-bottom',
      cssPropertyName: 'padding-bottom',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 150
            }
          }
        }
      }
    }]
  }, {
    variableName: 'font-size',
    cssPropertyName: 'font-size',
    label: 'Font Size'
  }];
  _exports.default = _default;
});