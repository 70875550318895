define("builder/services/device", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @class Device
   *
   * It's service contain information about current simulated device viewport for application and
   * some helpful method to manipulate it.
   *
   * By default it's service configured application 'viewport' dimensions as desktop screen. Because our application opened by default in desktop mode.
   * If need to simulate another device viewport you should reconfigured service.
   *
   * Examples:
   *  First what need to do is configure which device viewport should to be simulate.
   *
   *	service.configure('mobile', {
   *		name: 'Iphone 6',
   *		width: 300,
   *	  height: 400
   *	});
   *
   *  After you configure service you can call rotate method to change simulated 'viewport' orientation
   *
   *  service.rotate()
   *
   *  To get all information about simulated device(it's dimensions, orientation, breakpoints and etc) use getDevice method
   *
   *  let device = service.getDevice();
   *
   *  If you need to handle when device was reconfigured you may subscribe "onChange" event
   *
   *  service.on('onChange', this, function() { //some logic here  })
   *
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    /**
     * @property {null|Object} _device - contain information about current simulated 'viewport'
     * @property {String} _device.type - type of device('desktop', 'small-monitor', 'tablet' or 'mobile')
     * @property {String} _device.name - name of device(desktop has this property as null)
     * @property {Number} _device.width - device physical screen width
     * @property {Number} _device.height -  device physical screen height
     * @property {String} _device.breakpoint - bootstrap breakpoint('lg', 'sm' and etc) which depends on device screen
     * @private
     */
    _device: null,

    /**
     * Determines whether current mode is Desktop
     *
     * @method isDesktop
     * @returns {Boolean}
     */
    isDesktop() {
      return this._device.type === 'desktop' || this._device.type === 'fullscreen';
    },

    /**
     * Determines whether current mode is Small Monitor
     *
     * @method isSmallMonitor
     * @returns {Boolean}
     */
    isSmallMonitor() {
      return this._device.type === 'small-monitor';
    },

    /**
     * Determines whether current mode is Tablet
     *
     * @method isTablet
     * @returns {Boolean}
     */
    isTablet() {
      return this._device.type === 'tablet';
    },

    /**
     * Determines whether current mode is Mobile
     *
     * @method isMobile
     * @returns {Boolean}
     */
    isMobile() {
      return this._device.type === 'mobile';
    },

    /**
     * Determines fullscreen width
     *
     * @method getFullscreenWidth
     * @returns {number}
     */
    getFullscreenWidth() {
      var _document$querySelect;

      const pageWidth = (_document$querySelect = document.querySelector('.pg-main')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.clientWidth;
      const sidebarWidth = 62;
      const fullScreenWidth = pageWidth !== null && pageWidth !== void 0 ? pageWidth : window.innerWidth - sidebarWidth;
      return +fullScreenWidth;
    },

    /**
     * @property {Object} breakpoints - contain mapped physical screens sizes on bootstrap breakpoints.
     * @property {Array} breakpoints.xs - very small devices.
     * @property {Array} breakpoints.sm - small devices.
     * @property {Array} breakpoints.md - probably tablets or some small desktop screens
     * @property {Array} breakpoints.lg - desktop and bigger
     * @readonly
     */
    breakpoints: Ember.computed(function () {
      return {
        [_breakpointModes.EXTRA_SMALL]: [0, 767],
        [_breakpointModes.SMALL]: [768, 991],
        [_breakpointModes.MEDIUM]: [992, 1199],
        [_breakpointModes.LARGE]: [1200, 5000]
      };
    }).readOnly(),

    /**
     * It's init method to setup any device.
     *
     * @method configure
     * @param {String} deviceType - device type('desktop', 'small-monitor', 'mobile' or 'tablet')
     * @param {Object} data - hash which contain some information about device
     * @param {String} data.name - device name(it should be null if deviceType == 'desktop')
     * @param {Number} data.width - device physical screen width
     * @param {Number} data.height - device physical screen height
    	 */
    configure(data) {
      const device = { ...data
      };

      if (!device.width) {
        device.width = this.getFullscreenWidth();
      }

      device.breakpoint = this.getBreakpointByDimension(device.width);
      device.type = this.setDeviceTypeBasedOnBreakpoint(device.breakpoint);

      if (device.type !== 'desktop') {
        const range = this.breakpoints[device.breakpoint];
        device.range = device.type === 'mobile' ? [range[1]] : range;
      }

      this.set('_device', device);
      this.trigger('onChange');
    },

    /**
     * method to generate device based on width
     *
     * @method setDeviceTypeBasedOnBreakpoint
     * @param {Object} width - current width of device
    	 */
    generateDeviceDataBasedOnCustomWidth(width) {
      const device = {
        selected: true,
        width
      };
      device.breakpoint = this.getBreakpointByDimension(width);
      device.type = this.setDeviceTypeBasedOnBreakpoint(device.breakpoint);

      if (!(device === null || device === void 0 ? void 0 : device.name)) {
        device.name = device.type;
      }

      device.height = this.setDeviceHeightBasedOnName(device.name);
      return device;
    },

    /**
     * method to set device type based on width.
     *
     * @method setDeviceTypeBasedOnBreakpoint
     * @param {Object} breakpoint - hash which contain some information about device breakpoint
    	 */
    setDeviceTypeBasedOnBreakpoint(breakpoint) {
      let type = '';

      if (breakpoint === _breakpointModes.LARGE) {
        type = 'desktop';
      } else if (breakpoint === _breakpointModes.MEDIUM) {
        type = 'small-monitor';
      } else if (breakpoint === _breakpointModes.SMALL) {
        type = 'tablet';
      } else if (breakpoint === _breakpointModes.EXTRA_SMALL) {
        type = 'mobile';
      }

      return type;
    },

    /**
     * method to set device type based on width.
     *
     * @method setDeviceTypeBasedOnWidth
     * @param {Object} data - hash which contain some information about device
    	 */
    setDeviceHeightBasedOnName(name) {
      let height = 0;

      if (name === 'desktop') {
        height = 860;
      } else if (name === 'small-monitor') {
        height = 672;
      } else if (name === 'tablet') {
        height = 1024;
      } else if (name === 'mobile') {
        height = 812;
      } else if (name === 'fullscreen') {
        height = 1160;
      }

      return height;
    },

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.configure({
        name: 'fullscreen',
        width: this.getFullscreenWidth(),
        height: 1160
      });
    },

    /**
     * Return hash which describe current simulated device viewport.
     *
     * @returns {Object}
     */
    getDevice() {
      const device = this._device;

      if (!device) {
        throw new Ember.Error(`${this.toString()}: service is not configured. Call 'configure' method.`);
      }

      return device;
    },

    /**
     * Method return bootstrap breakpoint which related to passed size.
     * @param {Number} size - any screen physical size
     * @returns {String|undefined}
     */
    getBreakpointByDimension(size) {
      return Object.keys(this.breakpoints).find(breakpoint => size >= this.breakpoints[breakpoint][0] && size <= this.breakpoints[breakpoint][1]);
    },

    /**
     * Get the breakpoint based on css min or max width
     * @param {'min-width'|'max-width'} minOrMaxWidth - css property min-width or max-width
     * @param {String} value - value in pixels
     * @returns {String} - breakpoint
     */
    getDeviceTypeFromWidth(minOrMaxWidth, value) {
      const index = minOrMaxWidth === 'min-width' ? 0 : 1;
      return Object.keys(this.breakpoints).find(breakpoint => `${this.breakpoints[breakpoint][index]}px` === value);
    }

  });

  _exports.default = _default;
});