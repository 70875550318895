define("builder/widgets/configs/filter/page-filter-object", ["exports", "builder/core/enumerators/fields"], function (_exports, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_FILTER_OBJECT = void 0;
  // This file is generated of PageFilterObject.yml from Engine6
  const PAGE_TYPE_PROPERTIES = [{
    label: 'Other',
    value: 'other'
  }, {
    label: 'Contact Us',
    value: 'contact-us'
  }, {
    label: 'Coupons',
    value: 'coupons'
  }, {
    label: 'Credit Approval',
    value: 'credit-approval'
  }, {
    label: 'E-price',
    value: 'e-price'
  }, {
    label: 'Get A Quote',
    value: 'get-a-quote'
  }, {
    label: 'Incentives',
    value: 'incentives'
  }, {
    label: 'Inventory Listing',
    value: 'inventory-listing'
  }, {
    label: 'Manager Specials',
    value: 'manager-specials'
  }, {
    label: 'MLP',
    value: 'mlp'
  }, {
    label: 'Model CLP',
    value: 'model-clp'
  }, {
    label: 'Model Comparison',
    value: 'model-comparison'
  }, {
    label: 'Model Detail Page',
    value: 'model-detail-page'
  }, {
    label: 'Offer Details',
    value: 'offer-details'
  }, {
    label: 'Order Parts',
    value: 'order-parts'
  }, {
    label: 'Our Dealership',
    value: 'our-dealership'
  }, {
    label: 'Our Services',
    value: 'our-services'
  }, {
    label: 'Our Team',
    value: 'our-team'
  }, {
    label: 'Service Appointment',
    value: 'service-appointment'
  }, {
    label: 'Showroom',
    value: 'showroom'
  }, {
    label: 'Test Drive',
    value: 'test-drive'
  }, {
    label: 'Testimonials',
    value: 'testimonials'
  }, {
    label: 'Trade In',
    value: 'trade-in'
  }, {
    label: 'Vehicle Details',
    value: 'vehicle-details-page'
  }, {
    label: 'Weekly Ads',
    value: 'weekly-ads'
  }];
  const PAGE_FILTER_OBJECT = {
    type: 'filter-object',
    label: 'Page',
    name: 'page',
    children: [{
      type: 'filter-property',
      label: 'Type',
      name: 'type',
      children: [{
        type: 'filter-operator',
        label: 'Equal to',
        logic: 'equal',
        children: [{
          type: _fields.default.DROPDOWN,
          name: 'any',
          label: 'hidden',
          data: 'other',
          choices: PAGE_TYPE_PROPERTIES
        }]
      }, {
        type: 'filter-operator',
        label: 'Not equal to',
        logic: 'notEqual',
        children: [{
          type: _fields.default.DROPDOWN,
          name: 'any',
          label: 'hidden',
          data: 'other',
          choices: PAGE_TYPE_PROPERTIES
        }]
      }]
    }, {
      type: 'filter-property',
      label: 'Title',
      name: 'name',
      children: [{
        type: 'filter-operator',
        label: 'Equal to',
        logic: 'equal',
        children: [{
          type: _fields.default.TEXT,
          name: 'any',
          label: 'hidden',
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'Not equal to',
        logic: 'notEqual',
        children: [{
          type: _fields.default.TEXT,
          name: 'any',
          label: 'hidden',
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'In list',
        logic: 'inList',
        children: [{
          type: _fields.default.TAGS,
          name: 'any',
          label: 'hidden',
          choices: [],
          allowOther: true,
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'Not in list',
        logic: 'notInList',
        children: [{
          type: _fields.default.TAGS,
          name: 'any',
          label: 'hidden',
          choices: [],
          allowOther: true,
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'Contains',
        logic: 'contains',
        children: [{
          type: _fields.default.TEXT,
          name: 'any',
          label: 'hidden',
          validation: {
            required: true
          }
        }]
      }]
    }, {
      type: 'filter-property',
      label: 'URL',
      name: 'alias',
      children: [{
        type: 'filter-operator',
        label: 'Equal to',
        logic: 'equal',
        children: [{
          type: _fields.default.TEXT,
          name: 'any',
          label: 'hidden',
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'Not equal to',
        logic: 'notEqual',
        children: [{
          type: _fields.default.TEXT,
          name: 'any',
          label: 'hidden',
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'In list',
        logic: 'inList',
        children: [{
          type: _fields.default.TAGS,
          name: 'any',
          label: 'hidden',
          choices: [],
          allowOther: true,
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'Not in list',
        logic: 'notInList',
        children: [{
          type: _fields.default.TAGS,
          name: 'any',
          label: 'hidden',
          choices: [],
          allowOther: true,
          validation: {
            required: true
          }
        }]
      }, {
        type: 'filter-operator',
        label: 'Contains',
        logic: 'contains',
        children: [{
          type: _fields.default.TEXT,
          name: 'any',
          label: 'hidden',
          validation: {
            required: true
          }
        }]
      }]
    }]
  };
  _exports.PAGE_FILTER_OBJECT = PAGE_FILTER_OBJECT;
});