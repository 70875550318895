define("builder/pods/components/field-inputmask/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_text'],

    /**
     * @property {Boolean} autofocus - show need set focus to input or not
     */
    autofocus: false,

    /**
     * @property {String} size - element class name.
     * @readonly
     */
    size: Ember.computed(function () {
      const size = this.get('field.size');
      return size ? `input-${size}` : '';
    }).readOnly(),

    /**
     * @property {Ember.observer} enableValidation - observer which just enable validation for field if it value changed
     * @private
     */
    _enableValidation: Ember.observer('field.data', function () {
      this.set('disableValidation', false);
    }),

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      this.set('disableValidation', true); // We should always re-init validation

      this.applyValidations();
    },

    /**
     * @inheritdoc
     */
    didRender(...args) {
      this._super(...args);

      this._setAutoFocus();
    },

    /**
     * Method for set auto focus on input
     *
     * @method _setAutoFocus
     * @private
     */
    _setAutoFocus() {
      if (this.autofocus) {
        this.$('input').focus();
      }
    },

    /**
     * Method for call action handler
     *
     * @method _callActionHandler
     * @private
     */
    _callActionHandler() {
      const {
        actionHandler
      } = this;

      if (actionHandler) {
        actionHandler(this.get('field.data'), this.get('field.name'));
      }
    },

    actions: {
      /**
       * Action called when someone update field data.
       * @param {String} unmaskedData - field data unmasked
       * @param {String} maskedData - field data masked
       */
      update(unmaskedData, maskedData) {
        this.set('field.data', maskedData);

        if (!this.notValid) {
          this._callActionHandler();
        }
      }

    }
  });

  _exports.default = _default;
});