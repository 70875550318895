define("builder/pods/components/menumanager-createfolder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXWkGae6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",false],[12,\"class\",\"menu-manager__add-list-item\"],[3,\"action\",[[23,0,[]],\"openConfig\"]],[8],[7,\"i\",true],[10,\"class\",\"menu-manager__add-list-icon icon-collapsed-folder\"],[8],[9],[0,\"Folder\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/menumanager-createfolder/template.hbs"
    }
  });

  _exports.default = _default;
});