define("builder/services/drag", ["exports", "builder/core/enumerators/mouse", "jquery"], function (_exports, _mouse, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Available callbacks are dragStart, mouseMove, mouseUp, dragEnd
   */
  var _default = Ember.Service.extend({
    /**
     * @property {String} noSelection - class name to disable selection during drag
     */
    noSelection: 'bld-no-selection',

    /**
     * @property {Number} distance - The number of pixels mouse should be moved to start drag.
     * This approach fixes {@link https://bugs.chromium.org/p/chromium/issues/detail?id=327114|Chrome bug}
     */
    distance: 15,

    /**
     * @property {Object|null} initialPosition - Initial position of draggable element
     */
    initialPosition: null,

    /**
     * @property {Boolean} dragStart - Flag to determine whether drag has been started
     */
    dragStart: false,

    /**
     * @property {Object|null} draggableObject - Draggable component
     */
    draggableObject: null,

    /**
     * @property {String} _postfix - Postfix for event name
     * @private
     */
    _postfix: '.grid',

    /**
     * @property {Ember.Service} dropHighlight - Drop highlight service
     */
    dropHighlight: Ember.inject.service('drop-highlight'),

    /**
     * @property {Ember.Service} scrollerService - scroller service instance
     */
    scrollerService: Ember.inject.service('scroller'),

    /**
     * @property {DeviceService} - Device Service
     */
    deviceService: Ember.inject.service('device'),

    /**
     * @property {Number} draggableZIndex - z index to set on drag start to draggable node
     */
    draggableZIndex: 9,

    /**
     * @property {Number} _touchDistance - how close mouse should be to the bottom/top border of viewport to trigger
     * scroll
     */
    _touchDistance: 60,

    /**
     * @property {Null|Object} _viewport - scroll object view port
     * @private
     */
    _viewport: null,

    /**
     * @property {Number} _initialViewportScrollTop - viewport scrollTop before mouse move
     * @private
     */
    _initialViewportScrollTop: 0,

    /**
     * @property {Null|Object} _viewportElement - scrollable viewport element
     * @private
     */
    _viewportElement: null,

    /**
     * Make object draggable
     *
     * @param {jQuery} node - jQuery dom element trigger
     * @param {Object} object - object that represents model of draggable node, object must provide the following
     * hooks: onDragStart, onMouseMove, onMouseUp, getDragElement, canDrag
     */
    applyDraggable(node, object) {
      node.off(`mousedown${this._postfix}`).on(`mousedown${this._postfix}`, event => {
        if (event.button === _mouse.default.BUTTON_MAIN) {
          if (event.target.tagName === 'IMG') {
            // Prevent HTML5 drag
            event.preventDefault();
          }

          if (object && typeof object.canDrag === 'function' && !object.canDrag(node)) {
            return;
          }

          this.mouseDown(event, node, object);
        }
      });
    },

    /**
     * Remove draggable event listeners/handlers
     *
     * @param {jQuery} node - jQuery node object
     */
    removeDraggable(node) {
      node.off(`mousedown${this._postfix}`);
    },

    /**
     * Handle mousedown event
     *
     * @param {Event} event - mousedown event
     * @param {jQuery} node - jQuery node object
     * @param {Object} object - draggable object with callbacks to be invoked during drag
     */
    mouseDown(event, node, object) {
      const {
        scrollerService
      } = this;
      this.set('_viewport', null);
      this.set('draggableObject', object);
      this.initScrollObject();
      this.set('initialPosition', {
        x: event.pageX,
        y: event.pageY
      });
      (0, _jquery.default)('body').on(`mousemove${this._postfix}`, Ember.run.bind(this, function (evt) {
        this.mouseMove(node, evt);

        this._scroll(evt);
      }));
      (0, _jquery.default)(window).on(`mouseup${this._postfix}`, Ember.run.bind(this, function (evt) {
        this.mouseUp(evt);
        scrollerService.stop();
      }));
    },

    /**
     * Mouse move event handler
     *
     * @method mouseMove
     * @param {Element} node
     * @param {jQuery.Event} event - jQuery event object
     * @returns {*}
     */
    mouseMove(node, event) {
      const object = this.draggableObject;
      const {
        x: initialX,
        y: initialY
      } = this.initialPosition;
      const {
        pageX: x,
        pageY: y
      } = event; // Is drag started or not, we need to move a couple of pixels away from original mouse down

      if (this.checkDistance(x, y)) {
        // Drag start event is important
        if (!this.dragStart) {
          if (object.scrollObject) {
            this.calculateViewport();
          }

          this._onDragStart(node, event);

          this.set('dragStart', true);
        } // Move element


        this.updateCSSPosition(this.draggableNode, this.getPositionShift(initialX, initialY, x, y));
        const scrollShift = this.getViewportScrollShift();
        this.draggableObject.scrollShift = scrollShift;
        object.onMouseMove(x, y + scrollShift, this._viewport);
      }
    },

    /**
     * Initialize scrollable object to viewport
     */
    initScrollObject() {
      if (this.draggableObject.scrollObject || this.deviceService.isDesktop()) {
        // scroll object already assigned
        return;
      }

      this.set('_viewportElement', document.querySelector('.viewport'));
      this.draggableObject.scrollObject = this._viewportElement;
      this.set('_initialViewportScrollTop', this._viewportElement.scrollTop);
    },

    /**
     * Returns the value of scroll shifted since mousedown
     * @returns {Number}
     */
    getViewportScrollShift() {
      if (!this._viewportElement) {
        return 0;
      }

      const isScrolled = this._viewportElement.scrollTop !== this._initialViewportScrollTop;
      return isScrolled ? this._viewportElement.scrollTop - this._initialViewportScrollTop : 0;
    },

    /**
     * reset scroll references related to viewport
     */
    resetViewportScrollInfo() {
      this._viewportElement = null;
      this.draggableObject.scrollObject = null;
      this.draggableObject.scrollShift = null;
    },

    /**
     * On drag start handler
     *
     * @param {Element} node
     * @param {jQuery.Event} event
     * @private
     */
    _onDragStart(node, event) {
      const object = this.draggableObject;
      const draggableNode = object.getDragElement(node);
      object.onDragStart(node, event.pageX, event.pageY);
      this.set('draggableNode', draggableNode);
      draggableNode.css({
        'z-index': this.draggableZIndex,
        opacity: '0.5'
      });
      this.disableSelection();
    },

    /**
     * Calculates view port of scroll object
     *
     * @method
     */
    calculateViewport() {
      const {
        scrollObject
      } = this.draggableObject;
      const height = scrollObject.offsetHeight;
      const width = scrollObject.offsetWidth;
      const {
        top,
        left
      } = (0, _jquery.default)(scrollObject).offset();
      this.set('_viewport', {
        left,
        top,
        width,
        height
      });
    },

    /**
     * Mouse up event handler
     *
     * @method mouseUp
     * @param {jQuery.Event} event - Event object
     */
    mouseUp(event) {
      const node = this.draggableNode;

      if (this.dragStart) {
        const draggable = this.draggableObject;
        const scrollShift = this.getViewportScrollShift();
        draggable.onMouseUp(draggable, node, event.pageX, event.pageY + scrollShift);
        this.clearCSSPosition(node);
        this.resetViewportScrollInfo();
        draggable.onDragEnd();
      }

      this.removeListeners();
      this.clearDraggable();
    },

    clearDraggable() {
      this.set('draggableNode', null);
      this.set('draggableObject', null);
      this.set('initialPosition', null);
      this.set('dragStart', false);
    },

    /**
     * Removes all registered listeners from body element
     *
     * @method removeListeners
     */
    removeListeners() {
      const postfix = this._postfix;
      this.enableSelection();
      (0, _jquery.default)('body').off(`mousemove${postfix}`);
      (0, _jquery.default)(window).off(`mouseup${postfix}`);
    },

    /**
     * Sets x and y coordinates to 0
     *
     * @method clearCSSPosition
     * @param {jQuery} element - jQuery object need to be reset
     */
    clearCSSPosition(element) {
      element.css({
        'z-index': '',
        opacity: 1,
        transform: 'translate(0px, 0px)',
        transition: 'transform 0.2s ease 0s'
      });
    },

    /**
     * Sets new x and y coordinates for element
     *
     * @method updateCSSPosition
     * @param {jQuery} element - jQuery object need to be updated
     * @param {Object} coordinates - Element coordinates
     */
    updateCSSPosition(element, coordinates) {
      element.css({
        transform: `translate(${coordinates.shiftX}px, ${coordinates.shiftY}px)`,
        transition: 'transform 0s ease 0s'
      });
    },

    /**
     * Get shift by x and y, relative to initial position and current mouse position
     *
     * @param {Number} initialX - initial pageX coordinate
     * @param {Number} initialY - initial pageY coordinate
     * @param {Number} currentX - current pageX coordinate
     * @param {Number} currentY - current pageY coordinate
     * @returns {{shiftX: Number, shiftY: Number}} - position shift required to position
     * component relative to current mouse position
     */
    getPositionShift(initialX, initialY, currentX, currentY) {
      const scrollShift = this.getViewportScrollShift();
      return {
        shiftX: currentX - initialX,
        shiftY: currentY - initialY + scrollShift
      };
    },

    /**
     * Checks distance between mouse init position and current one
     *
     * @method checkDistance
     * @param {Number} x
     * @param {Number} y
     * @returns {boolean}
     */
    checkDistance(x, y) {
      const {
        x: initialX,
        y: initialY
      } = this.initialPosition;
      const {
        distance
      } = this;
      return Math.abs(initialX - x) >= distance || Math.abs(initialY - y) >= distance;
    },

    /**
     * Disables selection on body
     *
     * @method
     */
    disableSelection() {
      (0, _jquery.default)('body').addClass(this.noSelection);
    },

    /**
     * Enables selection on body
     *
     * @method
     */
    enableSelection() {
      (0, _jquery.default)('body').removeClass(this.noSelection);
    },

    /**
     * Scroll or not scroll
     *
     * @param {Event} event - jQuery mousemove event
     * @private
     */
    _scroll(event) {
      const object = this.draggableObject;
      const scrollObject = object.scrollObject || window;
      const touchDistance = this._touchDistance;
      let direction = null;
      let bottom, top;

      if (scrollObject === window) {
        bottom = scrollObject.innerHeight;
        top = 0;
      } else {
        const offsetTop = (0, _jquery.default)(scrollObject).offset().top;
        top = offsetTop;
        bottom = scrollObject.offsetHeight + top;
      }

      if (event.clientY + touchDistance > bottom) {
        let totalHeight = scrollObject.scrollHeight;
        let {
          scrollTop,
          offsetHeight
        } = scrollObject;

        if (scrollObject === window) {
          totalHeight = scrollObject.document.documentElement.scrollHeight;
          scrollTop = scrollObject.scrollY;
          offsetHeight = scrollObject.innerHeight;
        } // Since mac can scroll even deeper into document, we must prevent this kind of a bug
        // $(document).height() - returns actual(increased) height of document


        if (scrollTop + offsetHeight < totalHeight) {
          direction = 'bottom';
        }
      }

      if (event.clientY - touchDistance < top) {
        direction = 'top';
      }

      if (direction) {
        this.scrollerService.scroll(scrollObject, direction, object.onScroll);
      } else {
        this.scrollerService.stop();
      }
    }

  });

  _exports.default = _default;
});