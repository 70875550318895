define("builder/pods/components/widget-notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAP_TYPE_TO_CLASS_NAME = {
    success: 'fa-check',
    error: 'fa-exclamation-triangle',
    info: 'fa-info-circle',
    warning: 'fa-exclamation-triangle'
  };

  var _default = Ember.Component.extend({
    /**
     * @property {String} iconClassName - icon class name
     * @readonly
     */
    iconClassName: Ember.computed('type', function () {
      return MAP_TYPE_TO_CLASS_NAME[this.type] || MAP_TYPE_TO_CLASS_NAME[this._defaultType];
    }).readOnly(),

    /**
     * @property {String} type - notification type
     * @readonly
     */
    type: Ember.computed.readOnly('data.type'),

    /**
     * Handler for close notification
     * @type {()=>void}
     */
    action: () => {},

    /**
     * @property {Object} features - features list
     * @readonly
     */
    features: Ember.computed('data.features', function () {
      return this.get('data.features') || {};
    }).readOnly(),

    /**
     * @property {String} _defaultType - default notification type
     * @private
     */
    _defaultType: 'info',

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      const windowWidth = window.innerWidth;
      const left = windowWidth - 850 >> 1;
      const notificationElement = this.element.querySelector('.builder_notification');
      notificationElement.style.left = left;
    },

    actions: {
      close() {
        this.action();
      },

      reloadPage() {
        window.location.reload();
      }

    }
  });

  _exports.default = _default;
});