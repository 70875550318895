define("builder/helpers/date-parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseDate = parseDate;
  _exports.default = void 0;

  /**
   * Parse string date into actual date object
   *
   * @param {Date[]|null[]} params - 1-st argument - date to parse
   * @returns {*}
   */
  function parseDate(params) {
    const date = params[0];
    const dateString = date || '';

    if (!date) {
      return date;
    }

    const dateParts = dateString.split('/'); // Js month zero based

    return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
  }
  /**
   * @class DateParseHelper
   */


  var _default = Ember.Helper.extend({
    compute(params) {
      return parseDate(params);
    }

  });

  _exports.default = _default;
});