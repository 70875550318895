define("builder/core/abstract-classes/variable-component", ["exports", "builder/core/literals", "builder/core/abstract-classes/attributes-provider-component", "builder/core/styles-management/variable-dependency-resolver", "builder/core/enumerators/dependency-types"], function (_exports, _literals, _attributesProviderComponent, _variableDependencyResolver, _dependencyTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    DEVICE_DEPENDENCE_VARIABLE
  } = _literals.default;
  const {
    VIRTUAL
  } = _dependencyTypes.DEPENDENCY_TYPES;
  /**
   * Abstract component for styles management UI which should extend every component
   * that changes the value of the variable
   *
   * @class AbstractVariableComponent
   * @abstract
   */

  var _default = _attributesProviderComponent.default.extend({
    /**
     * Name of scss variable
     * @type {string|null}
     */
    variableName: null,

    /**
     * Css property like 'background-color' and etc
     * @type {string|null}
     */
    cssPropertyName: null,

    /**
     * Header of component
     * @type {string|null}
     * @API
     */
    label: null,

    /**
     * Model which contains values by any variable
     * @type {StyleModel|null}
     */
    variablesModel: null,

    /**
     * Model if some variables from default passed model depends from this
     * This property is optional and needed if
     * need to create variable dependency from default passed model from another
     * @type {StyleModel|null}
     */
    dependentModel: null,

    /**
     * @inheritdoc
     * @readonly
     */
    excludeAttrs: Ember.computed(function () {
      return ['name'];
    }).readOnly(),

    /**
     * Bootstrap breakpoint mode(sm, xs, lg, md)
     * @type {string|null}
     */
    mode: null,

    /**
     * Text for the tooltip when this variable changes depending on the device
     * @type {string}
     */
    tooltipText: DEVICE_DEPENDENCE_VARIABLE,

    /**
     * This property shows whether to show a device dependent icon and tooltip inside a composite property
     * @type {boolean}
     */
    showDependencyWithinCompositeProperties: true,

    /**
     * Is a dependent device
     * @type {boolean}
     */
    isDeviceDependent: false,

    /**
     * Original property for customizing a component of a variable
     * @type {CssVariableOptions | null}
     */
    options: null,

    /**
     * Should show device dependent icon and tooltip
     * @type {boolean}
     * @readonly
     */
    showDeviceDependent: Ember.computed.and('isDeviceDependent', 'showDependencyWithinCompositeProperties').readOnly(),

    /**
     * Contain resolved dependency
     * NOTICE: Don't use this property in UI
     * @type {
     * null
     * |
     * 	{
     * 		variable: VariableFragment,
     * 		dependedVar:VariableFragment|null,
     * 		isDeviceDependent: boolean,
     * 		isResolved: boolean,
     * 		dependencyType: string|null
     * 	}
     * }
     * @readonly
     */
    resolvedDependency: Ember.computed('mode', 'variablesModel', 'variablesModel.{global,lg,md,sm,xs}.[]', 'variablesModel.{global,lg,md,sm,xs}.@each.value', function () {
      var _this$variableResolve;

      return (_this$variableResolve = this.variableResolver) === null || _this$variableResolve === void 0 ? void 0 : _this$variableResolve.resolve(this.variableName, this.mode);
    }).readOnly(),

    /**
     * Contain variable which bond to this component by "variableName"
     * @type {VariableFragment}
     * @readonly
     */
    variable: Ember.computed('resolvedDependency', function () {
      let variable, isDeviceDependent;

      if (this.resolvedDependency.dependedVar) {
        ({
          dependedVar: variable,
          isDeviceDependent
        } = this.resolvedDependency);
      } else {
        ({
          variable,
          isDeviceDependent
        } = this.resolvedDependency);
      }

      if (!variable) {
        throw new Ember.Error(`variable ${this.variableName} is not defined.`);
      }

      this.set('isDeviceDependent', isDeviceDependent);
      return variable;
    }).readOnly(),

    /**
     * Style options for variable
     * @type {StyleOptionsFragment|undefined}
     * @readonly
     */
    variableStyleOptions: Ember.computed('variablesModel.StyleOptions', 'variablesModel.StyleOptions.[]', function () {
      var _this$variablesModel;

      const options = ((_this$variablesModel = this.variablesModel) === null || _this$variablesModel === void 0 ? void 0 : _this$variablesModel.StyleOptions) || [];
      return options.findBy('variableName', this.variableName);
    }).readOnly(),

    /**
     * Locked variables across all breakpoints
     * @type {boolean}
     * @readonly
     */
    isLocked: Ember.computed.bool('variableStyleOptions.locked').readOnly(),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('variableResolver', _variableDependencyResolver.default.create({
        mainModel: this.variablesModel,
        dependentModel: this.dependentModel
      }));
    },

    /**
     * Method which update variable value, and create new if not exist in passed model "variablesModel"
     * It can be a local defined method or passed from parent callback
     *
     * @param {Object} varData
     * @param {VariableFragment} varData.variable - variable fragment
     * @param {string} varData.newValue - value which will be set
     * @param {boolean} varData.isNew - detect that variable is not exist in current passed model, and we should create it with new value
     * @param {boolean} varData.isDeviceDependent - mean that var defined in each mode exclude "global"
     */
    updateVariable() {
      throw new Ember.Error(`"updateVariable" method must be overridden or passed as callback. ${this.toString()}`);
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments); // We need this get to cache computed property value.
      // eslint-disable-next-line no-unused-expressions


      this.variable;
    },

    actions: {
      /**
       * Action provide data up to update passed variable
       *
       * @param {string} newValue - value which will be set to variable
       */
      update(newValue) {
        const {
          dependencyType,
          isDeviceDependent,
          variable,
          dependedVar
        } = this.resolvedDependency;
        const isVirtual = dependencyType === VIRTUAL;
        this.updateVariable({
          variable: dependedVar && isVirtual ? dependedVar : variable,
          variableName: this.variableName,
          newValue,
          isNew: isVirtual,
          isDeviceDependent,
          variablesModel: this.variablesModel
        });
      },

      toggleLock() {
        if (typeof this.updateLockState === 'function') {
          this.updateLockState({
            mode: this.mode,
            variableNames: [this.variableName],
            variablesModel: this.variablesModel,
            locked: !this.isLocked
          });
        }
      }

    }
  });

  _exports.default = _default;
});