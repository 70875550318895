define("builder/pods/website/edit/page/edit/page-template/route", ["exports", "builder/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @property {Ember.Service<ConfigurationService>} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @inheritdoc
     */
    model() {
      return Ember.RSVP.hash({
        template: this.store.createRecord('page-template', {
          ClonedFrom: this.get('configurationService.page.id'),
          Title: this.get('configurationService.page.Title')
        }),
        visibilities: this.store.getVisibilities()
      });
    },

    /**
     * @inheritdoc
     */
    activate() {
      const page = this.get('configurationService.page');

      if (!this.accessControl.can('visit route', this, {
        page
      }).isGranted) {
        this.handleAccessDenied();
      }
    },

    /**
     * @inheritdoc
     */
    deactivate() {
      const model = this.get('controller.model.template'); // To preserve backend sorting we must unload all categories, since during template creation, static categories
      // shouldn't be shown

      this.store.unloadAll('page-category');

      if (model && model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
    }

  });

  _exports.default = _default;
});