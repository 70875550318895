define("builder/pods/components/field-year-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0cp/6YY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-form_yearpicker_container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_yearpicker_container_left\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[10,\"class\",\"bld-form_yearpicker_container_label\"],[8],[1,[22,\"labelMin\"],false],[9],[0,\"\\n\\t\\t\"],[5,\"input-year\",[],[[\"@value\",\"@onUpdate\"],[[22,\"min\"],[28,\"action\",[[23,0,[]],\"updateMinYear\"],null]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_yearpicker_container_right\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[10,\"class\",\"bld-form_yearpicker_container_label\"],[8],[1,[22,\"labelMax\"],false],[9],[0,\"\\n\\t\\t\"],[5,\"input-year\",[],[[\"@value\",\"@onUpdate\"],[[22,\"max\"],[28,\"action\",[[23,0,[]],\"updateMaxYear\"],null]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-year-range/template.hbs"
    }
  });

  _exports.default = _default;
});