define("builder/abilities/tier/module-gallery", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierModuleGalleryAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      canCreate: {
        entity: 'module-gallery',
        property: 'create'
      }
    },

    /**
     * @inheritdoc
     */
    canHandle(actionName) {
      const {
        name
      } = this;
      const modules = this.get('checkpoint.permissions.module-gallery') || {};
      return name && modules.hasOwnProperty(name) && this._super(actionName);
    },

    /**
     * @inheritdoc
     */
    handle() {
      const {
        name
      } = this;
      const modules = this.get('checkpoint.permissions.module-gallery');
      return modules[name];
    }

  });

  _exports.default = _default;
});