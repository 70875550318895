define("builder/models/page-global", ["exports", "@ember-data/model", "builder/models/page"], function (_exports, _model, _page) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _page.default.extend({
    /**
     * Website id
     * @type {string}
     */
    WebsiteId: (0, _model.attr)('string')
  });

  _exports.default = _default;
});