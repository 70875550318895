define("builder/pods/components/form-module/row-drop-container", ["exports", "builder/pods/components/form-module/drop-container"], function (_exports, _dropContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Handle drop inside row
   * Logic behind is - we assume that fields or fieldsets will be dropped(swapped) into row, since only 2 items max
   * allowed to lie inside row
   */
  var _default = _dropContainer.default.extend({
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('allowed', ['field', 'column', 'fieldset']);
    },

    /**
     * @inheritdoc
     */
    dropAllowed(row, draggable, anchor, position) {
      if (this.allowed.indexOf(draggable.get('type')) === -1) {
        return false;
      }

      if (draggable === anchor) {
        return false;
      }

      const children = row.get('children'); // Move children inside row is allowed

      if (children.length > 1) {
        if (children.indexOf(draggable.get('parent')) === -1 || position === 'top' || position === 'bottom') {
          return false;
        }
      }

      switch (position) {
        case 'top':
          {
            return false;
          }

        case 'right':
          {
            return true;
          }

        case 'bottom':
          {
            return false;
          }

        case 'left':
          {
            return true;
          }

        default:
          return false;
      }
    },

    /**
     * @inheritdoc
     */
    getDropPosition(row, draggable, anchor, target, x, y) {
      let position = {
        position: 'bottom',
        distance: 0
      };
      const coordinator = this._coordinator;

      if (anchor) {
        const wrapper = anchor.get('wrapper')[0];
        const horizontal = coordinator.getHorizontalPosition(wrapper, target, x, y);
        const vertical = coordinator.getVerticalPosition(wrapper, target, x, y);
        position = horizontal;

        if (vertical.distance <= this.get('tolerance.y')) {
          position = vertical;
        }
      }

      return position;
    },

    /**
     * @inheritdoc
     */
    drop(row, draggable, anchor, position) {
      let column = draggable;

      if (draggable.get('type') === 'field' || draggable.get('type') === 'fieldset') {
        column = draggable.get('parent');
      }

      if (column.get('type') !== 'column') {
        throw new Ember.Error(`Not a column detected ${column.get('type')}`);
      }

      switch (position) {
        case 'right':
          {
            const parent = column.get('parent');

            if (parent) {
              parent.removeChild(column);
            }

            const index = row.get('children').indexOf(anchor) + 1;
            row.addChildAt(column, index === 0 ? row.get('children').length : index);
            break;
          }

        case 'left':
          {
            const parent = column.get('parent');

            if (parent) {
              parent.removeChild(column);
            }

            const index = row.get('children').indexOf(anchor);
            row.addChildAt(column, index === -1 ? 0 : index);
            break;
          }
      }
    }

  });

  _exports.default = _default;
});