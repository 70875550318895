define("builder/pods/components/field-checkbox/component", ["exports", "builder/mixins/field-visibility"], function (_exports, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['checkbox'],

    /**
     * @property {*} value - field value to set as default value
     */
    value: null,
    actions: {
      change(event) {
        let value = '';
        const {
          actionHandler
        } = this;

        if (event.target.checked) {
          value = this.get('field.value') || event.target.value;
          this.set('field.data', value);
        } else {
          this.set('field.data', undefined);
        }

        if (actionHandler) {
          actionHandler(value, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});