define("builder/core/froala/links/link-color-button", ["exports", "froala-editor", "builder/core/froala/links/link-color-plugin", "builder/core/froala/icon-templates"], function (_exports, _froalaEditor, _linkColorPlugin, _iconTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkColorCommand = void 0;

  /* eslint-disable new-cap */
  const linkColorCommand = 'link-color';
  _exports.linkColorCommand = linkColorCommand;
  const iconTemplateName = 'font_color';

  _froalaEditor.default.DefineIconTemplate(iconTemplateName, _iconTemplates.svgFontColor);

  _froalaEditor.default.DefineIcon(linkColorCommand, {
    NAME: 'linkColorIcon',
    template: iconTemplateName
  });

  _froalaEditor.default.RegisterCommand(linkColorCommand, {
    title: 'Link Color',
    undo: false,
    focus: false,
    refreshAfterCallback: false,
    plugin: _linkColorPlugin.frCustomColorPlugin,

    callback() {
      this[_linkColorPlugin.frCustomColorPlugin].showPopup(this.selection.element());
    }

  });
});