define("builder/pods/components/form-buttons/component", ["exports", "jquery", "builder/mixins/field-validation", "builder/mixins/field-visibility", "ember-copy", "builder/core/enumerators/fields"], function (_exports, _jquery, _fieldValidation, _fieldVisibility, _emberCopy, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Need to refactor (like as form-variants component) render of buttons list on add/remove/reorder operations
  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_buttons'],

    /**
     * @property {Boolean} isShowDropdown - show/hide dropdown for button selection
     */
    isShowDropdown: false,

    /**
     * @property {Boolean} hasOneChild - indicates that the user can add only one button
     */
    hasOneChild: Ember.computed.equal('field.children.length', 1),

    /**
     * @property {Object|null} expandedButton - currently expanded button (element from field.data.buttons)
     */
    expandedButton: null,

    /**
     * @property {Object[]} availableButtons - available buttons to select
     * @readonly
     */
    availableButtons: Ember.computed('field.children', function () {
      const children = this.get('field.children');
      let availableButtons = [];

      if (children) {
        const selectedButtons = this.get('field.data.buttons') || [];
        availableButtons = children.map(button => {
          const buttonType = button.type;
          const buttonTitle = button.children.findBy('name', 'title');
          const isSelected = this._isUniqButtonType(buttonType) && !!selectedButtons.findBy('type', buttonType);
          return {
            type: buttonType,
            title: buttonTitle && buttonTitle.data ? buttonTitle.data : buttonType,
            fields: button.children,
            isSelected
          };
        });
      }

      return availableButtons;
    }).readOnly(),

    /**
     * @property {Object[]} selectedButtons - selected buttons to display
     * @readonly
     */
    selectedButtons: Ember.computed('availableButtons', 'field.data.buttons.[]', function () {
      const {
        availableButtons
      } = this;
      const buttons = this.get('field.data.buttons') || [];
      return buttons.map(button => {
        const availableButton = availableButtons.findBy('type', button.type);
        const availableButtonFields = (0, _emberCopy.copy)(availableButton.fields || [], true);

        if (!availableButton) {
          return null;
        }

        this.provideDataToButtonFields(availableButtonFields, button);
        return {
          type: availableButton.type,
          fields: availableButtonFields,
          data: button
        };
      }).filter(selectedButton => !!selectedButton);
    }).readOnly(),

    /**
     * @property {Boolean} isShowButtons - show/hide selected button container
     */
    isShowButtons: Ember.computed.gt('field.data.buttons.length', 0),

    /**
     * @property {Boolean} isShowGroup - show/hide ui-control for group buttons
     */
    isShowGroup: Ember.computed.gt('field.data.buttons.length', 1),

    /**
     * @property {Boolean} isCheckedGroup - group or ungroup buttons
     */
    isCheckedGroup: Ember.computed.equal('field.data.group', true),

    /**
     * @property {Boolean} isShowAddNew - disable/enable "add button" control
     * @property
     */
    isShowAddNew: Ember.computed('availableButtons.@each.isSelected', 'field.data.buttons.[]', function () {
      const buttons = this.availableButtons; // Buttons count validation should be applied 1-st

      if (!this.canAdd) {
        return false;
      } // Workaround to allow "Add button" for widget-buttonsgroup
      // Since it has only 1 type of button to add


      if (buttons.length === 1 && buttons[0].type === 'widget-button') {
        return true;
      }

      return buttons.isAny('isSelected', false);
    }).readOnly(),

    /**
     * @property {Boolean} canAdd - is it allowed to add buttons
     * @readonly
     */
    canAdd: Ember.computed('field.data.buttons.[]', 'field.validation.elements.max', function () {
      const {
        maxButtons
      } = this; // If maxButtons = 0 - means no validation applied, any number of buttons allowed

      if (maxButtons === 0 || this.get('field.data.buttons').length < maxButtons) {
        return true;
      }

      return false;
    }).readOnly(),

    /**
     * @property {Number} maxButtons - number of max allowed buttons
     * @readonly
     */
    maxButtons: Ember.computed('field.validation.elements.max', function () {
      const maxButtons = parseInt(this.get('field.validation.elements.max'), 10);
      return isFinite(maxButtons) ? Math.abs(maxButtons) : 0;
    }).readOnly(),

    /**
     * @property {Boolean} canRemove - can remove buttons from form or not
     * @readonly
     */
    canRemove: Ember.computed('field.data.buttons.[]', 'field.validation.elements.min', function () {
      const {
        minButtons
      } = this; // If minButtons = 0 - no min limit is set

      if (minButtons === 0 || this.get('field.data.buttons').length > minButtons) {
        return true;
      }

      return false;
    }).readOnly(),

    /**
     * @property {Number} minButtons - min allowed number of buttons
     * @readonly
     */
    minButtons: Ember.computed('field.validation.elements.min', function () {
      const minButtons = parseInt(this.get('field.validation.elements.min'), 10);
      return isFinite(minButtons) ? Math.abs(minButtons) : 0;
    }).readOnly(),

    /**
     * @property {Function} _changeGroup - group option observer
     * @private
     */
    _changeGroup: Ember.observer('isCheckedGroup', function () {
      this.set('field.data.group', !!this.isCheckedGroup);
    }),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.applyValidations('field.data.buttons.[]', 'field.validation', false);
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      Ember.run.scheduleOnce('afterRender', this, this._attachClickHandler);
      Ember.run.scheduleOnce('afterRender', this, this._attachSortable);
    },

    /**
     * @inheritdoc
     */
    willDestroyElement(...args) {
      this._detachClickHandler();

      this._detachSortable();

      this._super(...args);
    },

    /**
     * Provide data from data to data UI components
     * @param {Object[]} fieldsDefinition - definitions of UI components
     * @param {Object} fieldsData - fields data hash
     */
    provideDataToButtonFields(fieldsDefinition, fieldsData) {
      fieldsDefinition.forEach(field => {
        if (!fieldsData.hasOwnProperty(field.name)) {
          return;
        }

        if (field.type === _fields.default.CONTAINER) {
          this.provideDataToButtonFields(field.children, fieldsData[field.name]);
        } else {
          field.data = fieldsData[field.name];
        }
      });
    },

    /**
     * Click event handler
     *
     * @param {Event} event - event
     * @return {Boolean}
     * @private
     */
    _outsideClickHandler(event) {
      const target = (0, _jquery.default)(event.target);
      const addNewEl = target.closest('.add-new');

      if (addNewEl.length && addNewEl.data('id') === this.elementId) {
        return true;
      }

      if (!target.hasClass('buttons-list_item')) {
        this._hideDropdown();
      }

      return true;
    },

    /**
     * Attach click handler
     *
     * @private
     */
    _attachClickHandler() {
      (0, _jquery.default)(document).on(`click.${this.elementId}`, this._outsideClickHandler.bind(this));
    },

    /**
     * Detach click handler
     *
     * @private
     */
    _detachClickHandler() {
      (0, _jquery.default)(document).off(`click.${this.elementId}`);
    },

    /**
     * Attach sort feature
     *
     * @private
     */
    _attachSortable() {
      const buttons = this;
      this.$('.buttons-list').sortable({
        items: '.buttons-list_item-inner',
        cancel: '.buttons-list_item-content:not(.__hidden)',
        distance: 5,

        start(event, ui) {
          let items = (0, _jquery.default)(this).sortable('option', 'items');
          items = buttons.$(items);
          ui.item.data('oldIndex', items.index(ui.item));
        },

        stop(event, ui) {
          const sortable = (0, _jquery.default)(this);
          const items = buttons.$(sortable.sortable('option', 'items'));
          const oldIndex = ui.item.data('oldIndex');
          const newIndex = items.index(ui.item);
          sortable.sortable('cancel');

          buttons._sort(oldIndex, newIndex);
        }

      });
    },

    /**
     * Detach sort feature
     *
     * @private
     */
    _detachSortable() {
      this.$('.multiselect-items').sortable('destroy');
    },

    /**
     * Hide dropdown with available buttons to selection
     *
     * @private
     */
    _hideDropdown() {
      this.set('isShowDropdown', false);
    },

    /**
     * Create and push new button
     *
     * @param {Object} sourceButtonConfig - button config
     * @param {String} sourceButtonConfig.type - type
     * @param {String} sourceButtonConfig.title - title
     * @param {Object[]} sourceButtonConfig.fields - field descriptions for settings ui
     * @private
     */
    _pushButton(sourceButtonConfig) {
      const button = {
        type: sourceButtonConfig.type
      };
      sourceButtonConfig.fields.forEach(field => {
        if (field.hasOwnProperty('data')) {
          button[field.name] = (0, _emberCopy.copy)(field.data, true);
        }
      });

      if (!this.get('field.data')) {
        // Create default structure for field data
        this._addEmptyData();
      }

      this.get('field.data.buttons').pushObject(button);
    },

    /**
     * Sort buttons
     *
     * @param {Number} oldIndex - old button index
     * @param {Number} newIndex - new button index
     * @private
     */
    _sort(oldIndex, newIndex) {
      const buttons = this.get('field.data.buttons');

      if (buttons) {
        const button = buttons.objectAt(oldIndex);
        buttons.removeAt(oldIndex);
        buttons.insertAt(newIndex, button);
      }

      this.set('field.data.buttons', buttons);

      this._triggerChange();
    },

    /**
     * Notify change event
     *
     * @private
     */
    _triggerChange() {
      const action = this.actionHandler;

      if (action && !this.notValid) {
        action(this.get('field.data'), this.get('field.name'));
      }
    },

    /**
     * Return unique or not passed button type
     *
     * @param {String} type - button type
     * @return {Boolean}
     * @private
     */
    _isUniqButtonType(type) {
      return type !== 'custom';
    },

    /**
     * Add data stub
     *
     * @private
     */
    _addEmptyData() {
      this.set('field.data', {
        buttons: [],
        group: false
      });
    },

    actions: {
      /**
       * Show dropdown with available buttons to selection
       */
      showDropdown() {
        if (this.isShowAddNew) {
          this.toggleProperty('isShowDropdown');
        }
      },

      /**
       * Add one button
       */
      addOne() {
        if (this.isShowAddNew) {
          this._pushButton(this.get('availableButtons.firstObject'));

          this._triggerChange();
        }
      },

      /**
       * Add button
       *
       * @param {Object} button - button config
       * @param {String} button.type - type
       * @param {String} button.title - title
       * @param {Object[]} button.fields - field descriptions for settings ui
       */
      add(button) {
        if (this._isUniqButtonType(button.type)) {
          Ember.set(button, 'isSelected', true);
        } // Max validation applied, only if add button allowed, we push button into list of buttons


        if (this.canAdd) {
          this._pushButton(button);

          this._triggerChange();
        }

        this._hideDropdown();
      },

      /**
       * Remove button
       *
       * @param {Object} button - member from field.data.buttons
       */
      remove(button) {
        const {
          type
        } = button; // If min validation applied, we should validate elements.min

        if (!this.canRemove) {
          return;
        }

        if (this._isUniqButtonType(type)) {
          const listButtons = this.availableButtons;
          const listButton = listButtons.findBy('type', type);
          Ember.set(listButton, 'isSelected', false);
        }

        this.get('field.data.buttons').removeObject(button);

        this._triggerChange();
      },

      /**
       * Show/hide ui controls to display button settings
       *
       * @param {Object} button - member from field.data.buttons
       */
      toggleExpand(button) {
        // Click on the same button will collapse it, if it was expanded
        this.set('expandedButton', button === this.expandedButton ? null : button);
      },

      /**
       * Toggle button group option
       */
      toggleGroup() {
        this.toggleProperty('isCheckedGroup');

        this._triggerChange();
      },

      /**
       * Change button setting
       *
       * @param {Number} index - button order in field.data.buttons
       * @param {String} property - property name
       * @param {*} value - property value
       */
      changeButton(index, property, value) {
        const button = this.get(`field.data.buttons.${index}`);
        Ember.set(button, property, value);

        this._triggerChange();
      }

    }
  });

  _exports.default = _default;
});