define("builder/controllers/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BaseController = (_dec = Ember.inject.controller('application'), _dec2 = Ember.inject.service('configuration'), (_class = (_temp = class BaseController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "applicationController", _descriptor, this);

      this.entity = null;

      _initializerDefineProperty(this, "configurationService", _descriptor2, this);
    }

    /**
     * Callback for module activation
     * @returns {void}
     */
    activate() {
      this._super(); // Scroll window to top on each transition


      window.scrollTo(0, 0);
    }
    /**
     * This method is used mostly to show validation errors
     * @param {Object} error error to show
     * @deprecated use showNotification instead
     */


    errorHandler(error) {
      if (Array.isArray(error.errors)) {
        const message = this.generateError(error.errors[0]);
        this.applicationController.send('notification', {
          type: 'error',
          message
        });
      }
    }

    getFieldLabel(name, form) {
      const formFields = form || this.formFields;
      let field;

      for (let i = 0; i < formFields.length; i++) {
        field = formFields[i];

        if (field.name === name) {
          return field.label;
        }

        if (field.children) {
          const label = this.getFieldLabel(name, field.children);

          if (label) {
            return label;
          }
        }
      }

      return null;
    }

    generateError(fields, msg = ['Error occurred!']) {
      const parentMsg = fields['variant-name'] ? `${this.getFieldLabel(fields['variant-name'])} - ` : '';

      if (fields) {
        Object.keys(fields).forEach(field => {
          if (fields[field] === false) {
            msg.push(`${parentMsg}${this.getFieldLabel(field)} field value is not valid`);
          } else if (Array.isArray(fields[field])) {
            this.generateError(fields[field][0], msg);
          }
        });
      }

      return msg;
    }

    serializeForm(form, data) {
      const dict = data || {};
      let element;

      if (!Array.isArray(form)) {
        return dict;
      }

      for (let i = 0; i < form.length; i++) {
        element = form[i];

        if (element.name && typeof element.data !== 'undefined') {
          dict[element.name] = element.data;
        }
      }

      return dict;
    }
    /**
     * Callback for "beforeunloadevent" to show browser notification
     * when user leave or reload page.
     *
     * @private
     * @param {Event} e
     * @returns {string}
     */


    _onBeforeUnloadCallback(e) {
      const message = 'You have unsaved changes';

      if (e) {
        e.returnValue = message;
      }

      return message;
    }
    /**
     * Method register "beforeunload" event
     * @returns {void}
     */


    tearDown() {
      window.onbeforeunload = this._onBeforeUnloadCallback;
    }
    /**
     * Method disable "beforeunload" event
     * @returns {void}
     */


    cancelTearDown() {
      window.onbeforeunload = null;
    }
    /**
     * Show notification popup
     *
     * @param {string | string[]} msg - message to display, or array of messages to display
     * @param {'success', 'error', 'info'} [type] - message type, supported types are: []
     * @param {boolean} alwaysShow - Always show notification
     * @returns {void}
     */


    showNotification(msg, type = 'success', alwaysShow = false) {
      this.applicationController.send('notification', {
        type,
        message: Ember.isArray(msg) ? msg : [msg],
        alwaysShow
      });
    }

    closeNotification() {
      this.applicationController.send('closeNotification');
    }
    /**
     * Check unsaved changes and, if they are, request confirmation for leave without saving
     *
     * @returns {boolean} - if haven't changes or user confirm leave without saving return true, otherwise return false
     */


    hasNoChangesOrDoNotNeedToBeSaved() {
      return !this.get('configurationService.hasUnsavedChanges') || window.confirm('You have unsaved changes! Are you sure to leave without save it?');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "configurationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BaseController;
});