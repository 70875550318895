define("builder/core/enumerators/breakpoint-modes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EXTRA_SMALL = _exports.SMALL = _exports.MEDIUM = _exports.LARGE = _exports.GLOBAL = void 0;
  // @ts-check

  /**
   * Common scope
   */
  const GLOBAL = 'global';
  /**
   * Large mode. Breakpoints >= 1200px
   */

  _exports.GLOBAL = GLOBAL;
  const LARGE = 'lg';
  /**
   * Medium mode. Breakpoints >= 992px and <= 1199px
   */

  _exports.LARGE = LARGE;
  const MEDIUM = 'md';
  /**
   * Small mode. Breakpoints >= 768px and <= 991px
   */

  _exports.MEDIUM = MEDIUM;
  const SMALL = 'sm';
  /**
   * Extra small. Breakpoints <= 767px
   */

  _exports.SMALL = SMALL;
  const EXTRA_SMALL = 'xs';
  _exports.EXTRA_SMALL = EXTRA_SMALL;
});