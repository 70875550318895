define("builder/pods/website/edit/page/edit/module-gallery/settings/controller", ["exports", "builder/pods/website/edit/page/edit/module/settings/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    /**
     * @inheritDoc
     */
    transitionToParent() {
      this.transitionToRoute('website.edit.page.edit.module-gallery');
    }

  });

  _exports.default = _default;
});