define("builder/pods/components/metapicker-popup/component", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VARIABLE_SELECTOR = 'dd';
  const HIDDEN_VARIABLE_CLASS = 'metapicker-popup--hidden-variable';

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['metapicker-popup'],

    /**
     * Variable columns to render
     * @type {MetaVariablesColumn[]}
     */
    columns: null,

    /**
     * Current focused variable index
     * @type {number}
     */
    focusedVariableIndex: 0,

    /**
     * All available variables
     * @type {MetaVariable[]}
     * @readonly
     */
    variables: Ember.computed('categories', function () {
      return this.categories.reduce((acc, column) => acc.concat(column.variables), []).filter(variable => !variable.hidden);
    }).readOnly(),

    /**
     * Is any variables present
     * @type {boolean}
     * @readonly
     */
    isVariablesPresent: Ember.computed('categories', function () {
      return this.categories.some(category => !category.hidden);
    }).readOnly(),

    /**
     * List of variables categories to render
     * @type {MetaVariable[]}
     * @readonly
     */
    categories: Ember.computed('_columns', 'searchQuery', function () {
      const escapedSearchQuery = this.searchQuery.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&').trim();
      const variableNameMatcher = new RegExp(escapedSearchQuery || '.*', 'i');

      this._columns.forEach(category => {
        let visibleVariables = 0;
        category.variables.forEach(variable => {
          const isVisible = variableNameMatcher.test(variable.name);
          Ember.set(variable, 'hidden', !isVisible);

          if (isVisible) {
            visibleVariables += 1;
          }
        });
        Ember.set(category, 'hidden', visibleVariables === 0);
      });

      return this._columns;
    }).readOnly(),

    /**
     * Min variable index
     * @type {number}
     */
    minVariableIndex: 0,

    /**
     * Max variable index
     * @type {number}
     * @readonly
     */
    maxVariableIndex: Ember.computed('variables', function () {
      return Math.max(this.variables.length - 1, 0);
    }).readOnly(),

    /**
     * Query string for search variables by name
     * @type {string}
     */
    searchQuery: '',

    /**
     * Navigation command event passed from parent component
     * @type {MetapickerNavigationCommand | null}
     */
    navigationCommand: null,

    /**
     * Deep copy of columns
     * @type {MetaVariablesColumn[]}
     * @readonly
     * @private
     */
    _columns: Ember.computed('columns', function () {
      return (0, _emberCopy.copy)(this.columns, true);
    }).readOnly(),

    /**
     * Watch changes of search query and reset focus
     * @type {Function}
     */
    _resetNavigation: Ember.observer('searchQuery', function () {
      this.set('focusedVariableIndex', this.minVariableIndex);
      Ember.run.scheduleOnce('afterRender', this, () => {
        this._setFocus(this.minVariableIndex);
      });
    }),

    /**
     * Watch changes of passed navigation command
     * @type {Function}
     */
    _navigationCommand: Ember.observer('navigationCommand', function () {
      if (this.navigationCommand) {
        Ember.run.next(this, () => this._performCommand(this.navigationCommand));
      }
    }),

    /**
     * Handler should be provided by outer scope
     * @param {MetaVariable} [variable] - variable path. will be passed to outer scope
     * @abstract
     * @returns {void}
     */
    onVariableSelected(variable) {
      throw new Ember.Error('Method implementation should be provided through component property');
    },

    /**
     * Handler called after popup close
     * @abstract
     * @returns {void}
     */
    onClose() {
      throw new Ember.Error('Method implementation should be provided through component property');
    },

    /**
     * @inheritDoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this._setFocus(this.focusedVariableIndex);
    },

    /**
     * @inheritdoc
     */
    actions: {
      /**
       * Variable click handler
       * @param {MetaVariable} variable - variable that was clicked
       */
      onVariableClick(variable) {
        this.onVariableSelected(this._prepareVariable(variable));
      }

    },

    /**
     *
     * @param {MetaVariable} variable
     * @param {string} label
     * @return {boolean}
     */
    matchVariableTitle(variable, label) {
      return variable.name.toLowerCase() === label.toLowerCase();
    },

    /**
     * Sets document focus according provided coordinates
     * @param {number} columnIndex - index of focused column
     * @param {number} variableIndex - index of focused variable in column
     * @private
     * @returns {void}
     */
    _setFocus(variableIndex) {
      const allVariableElements = this.element.querySelectorAll(VARIABLE_SELECTOR);
      const availableVariableElements = Array.prototype.filter.call(allVariableElements, element => !element.classList.contains(HIDDEN_VARIABLE_CLASS));
      const variableElement = availableVariableElements[variableIndex];
      allVariableElements.forEach(element => element.classList.remove('active'));

      if (variableElement) {
        variableElement.classList.toggle('active');

        if (variableIndex === 0) {
          this.element.scrollTop = 0;
        } else {
          this.element.scrollTop = variableElement.offsetTop - this.element.offsetTop;
        }
      }
    },

    /**
     * Chooses and executes appropriate method for provided keycode
     * @param {MetapickerNavigationCommand} command - command to perform
     * @private
     * @returns {void}
     */
    _performCommand(command) {
      switch (command.code) {
        case 'down':
          this._moveFocusDown();

          break;

        case 'up':
          this._moveFocusUp();

          break;

        case 'select':
          this._selectFocusedVariable();

          break;

        case 'cancel':
          this._close();

          break;
      }
    },

    /**
     * Returns variable with handled path
     * @param {MetaVariable} variable
     * @returns {MetaVariable}
     * @private
     */
    _prepareVariable(variable) {
      return { ...variable,
        path: `{{${variable.path}}}`
      };
    },

    /**
     * Method dispatching path to upper scope according focused variable coordinates
     * @private
     * @returns {void}
     */
    _selectFocusedVariable() {
      const variable = this.variables[this.focusedVariableIndex];
      this.onVariableSelected(this._prepareVariable(variable));
    },

    /**
     * Method dispatching when popup closing without choose
     * @private
     * @returns {void}
     */
    _close() {
      this.onClose();
    },

    /**
     * Moves document focus down according current focus coordinates
     * @private
     * @returns {void}
     */
    _moveFocusDown() {
      let nextVariableIndex = this.focusedVariableIndex + 1;

      if (nextVariableIndex > this.maxVariableIndex) {
        nextVariableIndex = this.minVariableIndex;
      }

      this.set('focusedVariableIndex', nextVariableIndex);

      this._setFocus(nextVariableIndex);
    },

    /**
     * Moves document focus up according current focus coordinates
     * @private
     * @returns {void}
     */
    _moveFocusUp() {
      let prevVariableIndex = this.focusedVariableIndex - 1;

      if (prevVariableIndex < this.minVariableIndex) {
        prevVariableIndex = this.maxVariableIndex;
      }

      this.set('focusedVariableIndex', prevVariableIndex);

      this._setFocus(prevVariableIndex);
    }

  });

  _exports.default = _default;
});