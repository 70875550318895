define("builder/services/rendering-structure", ["exports", "ember-copy", "builder/core/enumerators/container"], function (_exports, _emberCopy, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for operations with page structure within rendering context
   */
  let RenderingStructureService = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('structure'), _dec3 = Ember.inject.service('rendering-layout'), _dec4 = Ember.inject.service('configuration-page'), (_class = (_temp = class RenderingStructureService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_store", _descriptor, this);

      _initializerDefineProperty(this, "_structureService", _descriptor2, this);

      _initializerDefineProperty(this, "_renderingLayoutService", _descriptor3, this);

      _initializerDefineProperty(this, "_configurationPageService", _descriptor4, this);
    }

    /**
     * Saves current page structure
     * @param {StructureSaveCommand} command
     * @returns {Promise<void>}
     */
    async save({
      page,
      isPageStructureChanged,
      layout,
      _publishLayouts,
      setPublishLayout,
      notifyAboutGlobalPageMenuInsertionError
    }) {
      const structure = this._store.serializerFor('page').serializeStructure((0, _emberCopy.copy)(this._structureService.pageViewModel, true)); // We need copy page structure, because backend needs first save layout
      // and we need modify structure for layout


      const pageStructure = (0, _emberCopy.copy)(structure.findBy('type', _container.default.WIDGET_MAIN));
      await this._renderingLayoutService.saveAll({
        structure,
        layout,
        _publishLayouts,
        setPublishLayout
      });
      await this._configurationPageService.saveAll({
        page,
        structure: pageStructure,
        isPageStructureChanged,
        notifyAboutGlobalPageMenuInsertionError
      });
    }
    /**
     * Structure save complete event handler
     *
     * TODO: this handler is not required when we'll have push events from server
     * @param {StructureSaveCompletedEvent} domainEvent
     * @returns {Promise<void>}
     */


    async onSaveCompleted({
      page
    }) {
      const currentIds = this._store.peekAll('page').getEach('id');

      const currentPageId = page.id; // It is important to use query method otherwise findAll returns records
      // including ones from local store

      const pages = await this._store.query('page', {});
      const newIds = pages.getEach('id');
      currentIds.forEach(id => {
        if (id && !newIds.includes(id) && id !== currentPageId) {
          const pageToUnload = this._store.peekRecord('page', id);

          if (pageToUnload) {
            pageToUnload.unloadRecord();
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_structureService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_renderingLayoutService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_configurationPageService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RenderingStructureService;
});