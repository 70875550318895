define("builder/pods/components/form-module/form-item", ["exports", "builder/mixins/event-base", "jquery", "builder/core/enumerators/form-fields"], function (_exports, _eventBase, _jquery, _formFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base component of form-module
   */
  var _default = Ember.Component.extend(_eventBase.default, {
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @property {mixed} data - form item data, like size, label, validations, etc.
     */
    data: null,

    /**
     * @property {Boolean} active - is current form item is active or not
     */
    active: false,

    /**
     * @property {Boolean} active - is current form item is hovered or not
     */
    hover: false,

    /**
     * @property {String} activeClass - class to be added when item becomes active
     */
    _activeClass: '__active',

    /**
     * @property {String} hoverClass - class to be added when item becomes hover
     */
    _hoverClass: '__hover',

    /**
     * @property {String} loaderClass - class to be added when item is loading
     */
    _loaderClass: '__hidden',

    /**
     * Watch over "active" property
     */
    activeStateChanged: Ember.observer('active', function () {
      this.wrapper.toggleClass(this._activeClass, this.active);
    }),

    /**
     * Watch over "hover" property
     */
    hoverStateChanged: Ember.observer('hover', function () {
      this.wrapper.toggleClass(this._hoverClass, this.hover);
    }),

    /**
     * @property {String} loaded - check whether component layout is loaded or not
     */
    loaded: true,

    /**
     * Watch over "loaded" property
     */
    loadedStateChanged: Ember.observer('loaded', function () {
      this.get('_elements.loader').toggleClass(this._loaderClass, this.loaded);
    }),

    /**
     * @property {Boolean} useMask - should we mask element or not
     */
    useMask: false,

    /**
     * If useMask set to true, display mask, otherwise hide
     */
    useMaskStateChanged: Ember.observer('useMask', function () {
      this.get('_elements.mask').toggle(!this.useMask);
    }),

    /**
     * @property {jQuery} wrapper - public form item layout
     */
    wrapper: null,

    /**
     * @property {jQuery} anchor - for droppables we must know where to drop children, this node is a beacon(anchor)
     * node for children nodes
     */
    anchor: null,

    /**
     * @property {Object} parent - parent component
     */
    parent: null,

    /**
     * @property {Boolean} editable - is item editable or not
     */
    editable: true,

    /**
     * @property {Boolean} removable - is item removable or not
     */
    removable: true,

    /**
     * @property {Object} _templates - form item templates
     */
    _templates: {
      // Root element template
      wrapper: '<div class="form_item_wrapper"></div>',
      controlsWrapper: '<div class="form_field_header"><div class="form_field_header-inner"></div></div>',
      controls: '<div class="form_field_control __two-items __two-items-inner-top"></div>',
      editControl: '<i class="fa form_field_control_item fa-pencil js-form-field-edit"></i>',
      removeControl: '<i class="fa form_field_control_item fa-trash-o js-form-field-remove"></i>',
      // Custom inner content will be removed
      loader: '<div class="form_item_wrapper_loader flex flex_center flex_full-size __hidden"><div class="module-preloader"><div class="module-preloader__item"></div></div></div>',
      mask: '<div class="form_item_wrapper_mask"></div>'
    },

    /**
     * @property {Object} _elements - list of form item element nodes
     */
    _elements: null,

    /**
     * TODO: make some kind of init mechanism? or not needed, since we expect for "Ember way" to come?
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_elements', {
        element: null,
        loader: null,
        mask: null,
        controlsWrapper: null,
        controls: null,
        editControl: null,
        removeControl: null
      });
      this.set('events', {
        editButtonClick: null,
        removeButtonClick: null,
        click: null,
        mouseenter: null,
        mouseleave: null,
        remove: null
      });

      this._initElement();

      this._positionElements();

      this._attachEvents(); // Since observer is not triggered


      if (this.useMask) {
        this.get('_elements.mask').css('display', 'block');
      }

      if (!this.children) {
        this.set('children', []);
      }
    },

    /**
     * Do ember job, create node elements
     *
     * @private
     */
    _initElement() {
      const {
        wrapperClass
      } = this;
      const {
        wrapperTagName
      } = this;
      const templateString = this.get('_templates.wrapper');
      const template = (0, _jquery.default)(wrapperTagName ? templateString.replace(/div/g, wrapperTagName) : templateString);
      const controlsWrapperTemplate = (0, _jquery.default)(this.get('_templates.controlsWrapper'));
      const controlsTemplate = (0, _jquery.default)(this.get('_templates.controls'));

      if (wrapperClass) {
        template.addClass(wrapperClass);
      }

      if (this.get('data.type') === 'form-fieldset') {
        controlsWrapperTemplate.addClass('form_field_header__position-right');
        controlsTemplate.removeClass('__two-items-inner-top');
        controlsTemplate.addClass('__two-items-inner-right');
      }

      this.set('wrapper', template);
      this.set('_elements.controlsWrapper', controlsWrapperTemplate);
      this.set('_elements.controls', controlsTemplate);
      this.set('_elements.editControl', (0, _jquery.default)(this.get('_templates.editControl')));
      this.set('_elements.removeControl', (0, _jquery.default)(this.get('_templates.removeControl')));
      this.set('_elements.loader', (0, _jquery.default)(this.get('_templates.loader')));
      this.set('_elements.mask', (0, _jquery.default)(this.get('_templates.mask')));
    },

    /**
     * Put elements to their appropriate positions
     *
     * @private
     */
    _positionElements() {
      const {
        wrapper
      } = this;
      const controlsWrapper = this.get('_elements.controlsWrapper');
      const controls = this.get('_elements.controls'); // Move to post init ?

      if (this.editable) {
        controls.append(this.get('_elements.editControl'));
      }

      if (this.removable) {
        controls.append(this.get('_elements.removeControl'));
      } // Not the best way to insert controls, better find another way


      if (this.editable || this.removeable) {
        controlsWrapper.find('.form_field_header-inner').append(this.get('_elements.controls'));
      }

      wrapper.append(controlsWrapper);
      wrapper.append(controlsWrapper);
      wrapper.append(this.get('_elements.loader'));
      wrapper.append(this.get('_elements.mask'));
    },

    /**
     * Attach form item supported events
     * @private
     */
    _attachEvents() {
      const {
        wrapper
      } = this;
      this.get('_elements.editControl').off('click').on('click', () => {
        this.dispatchEvent('editButtonClick', this);
      });
      this.get('_elements.removeControl').off('click').on('click', () => {
        this.dispatchEvent('removeButtonClick', this);
      });
      wrapper.off('click').on('click', event => {
        // Temp workaround
        event.stopPropagation();
        this.dispatchEvent('click', this);
      });
      wrapper.off('dblclick').on('dblclick', event => {
        event.stopPropagation();
        this.dispatchEvent('editButtonClick', this);
      });
      wrapper.off('mouseenter').on('mouseenter', () => {
        this.dispatchEvent('mouseenter', this);
      });
      wrapper.off('mouseleave').on('mouseleave', () => {
        this.dispatchEvent('mouseleave', this);
      });
    },

    /**
     * Get anchor element before which new element will be placed as children of current form-item
     *
     * @param {HTMLElement} element - element where to search
     * @returns {HTMLElement|null} - found comment node or null if comment node wasn't found
     * @private
     */
    _getAnchor(element) {
      const children = element.childNodes || [];
      let commentNode = null;

      for (let i = 0, len = children.length; i < len; i++) {
        if (children[i].nodeType === Node.COMMENT_NODE) {
          commentNode = children[i];
          break;
        }

        commentNode = this._getAnchor(children[i]);

        if (commentNode) {
          break;
        }
      }

      return commentNode;
    },

    /**
     * Update form item element layout
     *
     * @param {jQuery} newElement - new layout to set as element layout
     * @private
     */
    _updateElement(newElement) {
      const {
        wrapper
      } = this;
      const element = this.get('_elements.element');

      if (element) {
        element.remove();
      }

      if (this.wrap) {
        // To convert new element and save it's position in DOM tree
        wrapper.insertBefore(newElement);
        wrapper.append(newElement);
      } else {
        this.set('wrapper', newElement);
      }

      this._setElement(newElement);

      this._attachEvents();
    },

    /**
     * Set/Update element layout
     *
     * @param {jQuery} newElement - new form item layout element
     */
    setElement(newElement) {
      const element = this.get('_elements.element');
      const type = this.get('data.type');

      if (element) {
        element.remove();
      } // Since "hidden" input has z-index = 1 and mask has the same z-index, mask must be placed below "hidden" in DOM tree
      // since mask must receive events like dragstart, etc.


      if (type === _formFields.default.DATE || type === _formFields.default.DATE_OF_BIRTH) {
        newElement.insertBefore(this.get('_elements.mask'));
      } else {
        this.wrapper.append(newElement);
      }

      this._setElement(newElement);
    },

    /**
     * Set reference to a new element
     *
     * @param {jQuery} element - jQuery element
     * @private
     */
    _setElement(element) {
      const {
        wrapper
      } = this;

      if (this.type === 'fieldset') {
        this.set('anchor', (0, _jquery.default)(this._getAnchor(element[0])));
      }

      if (wrapper) {
        // Prevent focus element on tab key press
        wrapper.find('input,textarea,button').attr('tabindex', '-1');
      }

      this.set('_elements.element', element);
    },

    /**
     * Remove child from current component
     *
     * @param {Object} child - child to remove
     */
    removeChild(child) {
      const {
        children
      } = this;
      const index = children.indexOf(child);

      if (index !== -1) {
        children.removeAt(index);
        child.set('parent', null); // Node removing

        child.get('wrapper').detach();
      }
    },

    /**
     * Add new child to current component
     *
     * @param {Object} child - form-item to add as child
     * @param {Number} position - index where to place new child
     */
    addChildAt(child, position = 0) {
      const {
        children
      } = this;
      const anchor = children[position];
      children.insertAt(position, child);
      child.set('parent', this); // Node swapping

      if (anchor) {
        child.get('wrapper').insertBefore(anchor.get('wrapper'));
      } else if (this.type === 'fieldset') {
        // Hello workaround, since only fieldset has special comment node
        child.get('wrapper').insertBefore(this.anchor);
      } else {
        this.wrapper.append(child.get('wrapper'));
      }
    }

  });

  _exports.default = _default;
});