define("builder/pods/components/widget-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmCqEZod",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"builder_notification_wrapper\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"builder_notification __\",[22,\"type\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-times builder_notification_close\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"builder_notification_type __\",[22,\"type\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"iconClassName\"]]]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"builder_notification_message\"],[8],[0,\"\\n\\t\\t\\t\"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"features\",\"needToReloadPage\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"reloadPage\"]],[8],[0,\"Reload page\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-notification/template.hbs"
    }
  });

  _exports.default = _default;
});