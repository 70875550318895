define("builder/core/enumerators/mouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // Mouse main action button, normally it's left button, but left and right buttons could be swapped,
    // since we cannot rely on left or right names, BUTTON_MAIN name used here, 0 means main action button was used,
    // 1 for middle and 2 for secondary mouse action button
    BUTTON_MAIN: 0
  };
  _exports.default = _default;
});