define("builder/pods/components/file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FileUpload
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: 'form',

    /**
     * @property {String} uploadUrl - url to upload endpoint
     */
    uploadUrl: '/',

    /**
     * @property {String} uploadName - upload file parameter name
     */
    uploadName: 'file',

    /**
     * @property {jQuery} input - jquery wrapped dom input file element
     */
    input: null,

    /**
     * @property {String} accept - list of acceptable for upload files (ex. image/jpeg, image/*)
     */
    accept: null,

    /**
     * @property {String} inputClass - class to be added to input file element
     */
    inputClass: '',

    /**
     * @property {AjaxService} ajaxService - ember ajax service
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Hook to be invoked before file upload starts
     */
    startUpload() {},

    /**
     * Hook to be invoked after file upload ends
     */
    endUpload() {},

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const input = this.$().find('input');
      this.set('input', input);
    },

    /**
     * Handle hook invocation during file upload
     *
     * @param {File} file - file to upload
     * @returns {Promise}
     */
    upload(file) {
      this.startUpload();
      return this._upload(file).then(response => ({
        success: true,
        response
      })).catch(error => ({
        success: false,
        response: error
      })).then(result => {
        this._resetFileSelection();

        this.endUpload(result.success, result.response);
      });
    },

    /**
     * Change event handler
     *
     * @param {Event} event - change event
     */
    change(event) {
      const file = this.input[0].files[0];

      if (this._isOwnInput(event.target) && file) {
        this.upload(file);
      } // Since no one stopped it, that's our boy


      event.stopPropagation();
    },

    /**
     * Reset selected file of input file
     *
     * @private
     */
    _resetFileSelection() {
      this.input.val('');
    },

    /**
     * Check if input is current component input
     *
     * @param {HTMLElement} input - input to check
     * @returns {Boolean}
     * @private
     */
    _isOwnInput(input) {
      return input === this.input[0];
    },

    /**
     * File upload
     *
     * @param {File} file - file to be uploaded
     * @returns {Promise}
     * @private
     */
    _upload(file) {
      const formData = new FormData();
      formData.append(this.uploadName, file);
      return this.ajaxService.request(this.uploadUrl, {
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
      });
    },

    actions: {
      select() {
        this.input.trigger('click');
      }

    }
  });

  _exports.default = _default;
});