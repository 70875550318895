define("builder/pods/components/form-module/form-module-factory", ["exports", "builder/pods/components/form-module/form-item", "builder/core/enumerators/form", "builder/core/enumerators/buttons"], function (_exports, _formItem, _form, _buttons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Kinda factory of form item
   */
  var _default = {
    create(config, parent = null) {
      return _formItem.default.create({
        type: config.type,
        editable: config.controls.edit,
        removable: config.controls.remove,
        draggable: config.draggable,
        droppable: config.droppable,
        useMask: config.useMask,
        wrap: config.wrap,
        data: config.data,
        parent
      });
    },

    /**
     * Create form-item of row type, specific use - drag-and-drop module
     *
     * @returns {Object} - form-item instance
     */
    createRow() {
      const rowItem = _formItem.default.create({
        type: 'row',
        editable: false,
        removable: false,
        useMask: false,
        draggable: false,
        droppable: true,
        data: {
          type: 'widget-row'
        },
        wrap: false,
        children: []
      });

      rowItem.get('wrapper').addClass('row');
      return rowItem;
    },

    /**
     * Create form-item of column type, specific use - drag-and-drop module
     *
     * @returns {Object} - form-item instance
     */
    createColumn() {
      const columnItem = _formItem.default.create({
        type: 'column',
        editable: false,
        removable: false,
        useMask: false,
        draggable: false,
        droppable: true,
        data: {
          type: 'widget-column',
          size: {
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6
          }
        },
        wrap: false,
        children: []
      });

      columnItem.get('wrapper').addClass('col-xs-6');
      return columnItem;
    },

    /**
     * Create form-step with prev and submit button
     *
     * @returns {Object} - form-step instance
     */
    createNewStep() {
      return {
        label: 'New step',
        type: _form.STEP,
        children: [{
          label: 'New STEP',
          type: 'form-fieldset',
          children: []
        }, {
          label: 'Prev',
          type: _buttons.FORM_PREV
        }, {
          label: 'Submit',
          type: _buttons.FORM_SUBMIT
        }]
      };
    },

    /**
     * Create form-step button
     *
     * @param {String} type - type new button
     * @returns {Object} - form-step instance
     */
    createFormButton(type) {
      switch (type) {
        case _buttons.FORM_SUBMIT:
          return {
            label: 'Submit',
            type: _buttons.FORM_SUBMIT
          };

        case _buttons.FORM_PREV:
          return {
            label: 'Prev',
            type: _buttons.FORM_PREV
          };

        case _buttons.FORM_NEXT:
          return {
            label: 'Next',
            type: _buttons.FORM_NEXT
          };

        default:
          return null;
      }
    }

  };
  _exports.default = _default;
});