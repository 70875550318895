define("builder/models/module-category", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleCategoryModel
   */
  var _default = _model2.default.extend({
    Name: (0, _model.attr)('string')
  });

  _exports.default = _default;
});