define("builder/serializers/page", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/widget", "builder/core/enumerators/module", "builder/core/enumerators/container", "builder/mixins/styles-variables", "ember-copy", "builder/core/enumerators/breakpoint-modes"], function (_exports, _rest, _widget, _module, _container, _stylesVariables, _emberCopy, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Page serializer
   */
  var _default = _rest.default.extend(_stylesVariables.default, {
    /**
     * @property {String} primaryKey - primary key index
     */
    primaryKey: 'Id',

    /**
     * @property {String[]} _containers - list of container types
     * @private
     */
    _containers: [_container.default.WIDGET_HEADER, _container.default.WIDGET_MAIN, _container.default.WIDGET_FOOTER, _container.default.WIDGET_CONTAINER, _widget.WidgetType.WIDGET_SECTION, _container.default.WIDGET_ROW, _container.default.WIDGET_COLUMN, _container.default.WIDGET_TABS, _container.default.WIDGET_TAB_PANE],

    /**
     * @property {Array} _nestedStylesProperties - list of properties which should be converted to styles variables
     * @private
     */
    _nestedStylesProperties: ['fullWidth', 'fullWidthContent', 'parallax', 'fullHeight', 'contentPosition'],

    /**
     * Convert page structure needed for backend
     *
     * @method serializeStructure
     * @param {Object} structure - page structure
     * @return {Object} structure
     */
    serializeStructure(structure) {
      if (!structure) {
        return null;
      } // Hot fix for pages which does not have "originObject" and initialized grid.
      // We should not save that structure cuz user does not change it.


      if (structure && Ember.typeOf(structure) !== 'instance') {
        return null;
      }

      this.restoreParents(structure);

      this._provideDefaultContentIfEmptyParagraph(structure); // Remove empty fields in all widget-containers


      this._removeEmptyFields(structure, _container.default.WIDGET_CONTAINER);

      const structureWithoutTop = this.removeTopContainer(structure);
      this.prepareStyles(structureWithoutTop);
      this.rollbackFormModule(structureWithoutTop);
      this.unwrapModules(structureWithoutTop);
      return this.makeJSON(structureWithoutTop);
    },

    /**
     * @inheritdoc
     */
    serialize(snapshot, ...args) {
      const shouldRemoveOemAttribute = snapshot.attr('IsOemRestricted') === undefined;

      const json = this._super(snapshot, ...args); // Convert to string for backend


      json.IsHidden += ''; // Since IsOemRestricted is boolean value and if it's not boolean value, means we are creating page, but
      // we don't know if page from which template was created is oem restricted or not, hence, we don't send the value
      // and rely on backend to return us valid restriction value

      if (shouldRemoveOemAttribute) {
        delete json.IsOemRestricted;
      } else {
        json.IsOemRestricted = json.IsOemRestricted === true ? '1' : '0';
      }

      return json;
    },

    /**
     * Sets parent to objects
     *
     * @param {Object} structure - object to process
     * @param {Object|null} parent - parent
     */
    restoreParents(structure, parent = null) {
      const children = structure.get('originObject.children');
      structure.set('parent', parent);

      if (children) {
        for (let i = 0, len = children.length; i < len; i++) {
          this.restoreParents(children[i], structure);
        }
      }
    },

    /**
     * @inheritdoc
     */
    normalize(modelClass, hash, ...args) {
      if (hash.hasOwnProperty('IsOemRestricted')) {
        hash.IsOemRestricted = !!parseInt(hash.IsOemRestricted, 10);
      }

      return this._super(modelClass, hash, ...args);
    },

    /**
     * Method normalizes page structure for render
     *
     * @method normalizeStructure
     * @param {Array} structure
     * @returns {*}
     */
    normalizeStructure(structure) {
      const children = structure || [];

      for (let i = 0, len = children.length; i < len; i++) {
        this._normalize(children[i]);
      }

      return {
        type: _container.default.TOP_CONTAINER,
        children
      };
    },

    /**
     * Removes top container
     *
     * @method
     * @param {Object} structure - page structure object
     * @returns {Array} top container children
     */
    removeTopContainer(structure) {
      const originObject = structure.get('originObject');
      const children = originObject.get('children');

      if (originObject.get('type') === _container.default.TOP_CONTAINER) {
        for (let i = 0; i < children.length; i++) {
          children[i].set('parent', null);
        }
      }

      return children;
    },

    /**
     * Method prepare styles data before send request.
     * It's remove not changed values and convert data to expected format for server
     *
     * @param {Object[]} structure - page structure
     * @returns {void}
     */
    prepareStyles(structure) {
      structure.forEach(item => {
        const origin = item.originObject;

        if (origin.styles) {
          const styles = this._serializeStyles(origin);

          const styleOptions = this._serializeStyleOptions(origin);

          origin.set('styles', styles);
          origin.set('styleOptions', styleOptions); // Remove 'styleOptions' attribute if styleOptions is empty

          if (!styleOptions.length) {
            delete origin.styleOptions;
          } // Remove 'styles' attribute if all styles equal to default


          if (!Object.keys(origin.styles).length) {
            delete origin.styles;
          }
        }

        if (origin.children) {
          this.prepareStyles(origin.children);
        }
      });
    },

    /**
     * Rollback form module to core module
     *
     * @method
     * @param {Array} structure - page structure
     */
    rollbackFormModule(structure) {
      let item, children;

      for (let i = 0; i < structure.length; i++) {
        item = structure[i];
        children = item.get('originObject').get('children');

        if (item.get('originObject').get('type') === _module.default.MODULE_FORM) {
          item.get('originObject').set('type', _module.default.MODULE_CORE);
        }

        if (children) {
          this.rollbackFormModule(children);
        }
      }
    },

    /**
     * Convert page structure from Ember.Object to plain objects
     *
     * @method
     * @param {Array} structure - page structure
     * @returns {Array|Object} new structure
     */
    makeJSON(structure) {
      if (Array.isArray(structure)) {
        const newStructure = [];

        for (let i = 0; i < structure.length; i++) {
          let object = structure[i];
          object = object.get && object.get('originObject') || object;

          const json = this._copyObject(object);

          newStructure.push(json);
        }

        return newStructure;
      }

      const object = structure.get && structure.get('originObject') || structure;
      return this._copyObject(object);
    },

    /**
     * Normalize page structure for best render on website
     *
     * @method
     * @param {Ember.Object} structure
     */
    unwrapModules(structure) {
      for (let i = 0; i < structure.length; i++) {
        if (Ember.typeOf(structure[i]) !== 'instance') {
          continue;
        }

        const item = structure[i];
        const children = item.get('originObject').get('children');

        if (this._needUnwrap(item)) {
          this._unwrap(item);
        }

        this._serialiseTabData(item);

        if (children) {
          this.unwrapModules(children);
        }
      }
    },

    /**
     * Sets default content for empty paragraph
     *
     * @method _provideDefaultContentIfEmptyParagraph
     * @param {Ember.Object} structure - object to process
     * @private
     */
    _provideDefaultContentIfEmptyParagraph(structure) {
      const originObject = structure.get('originObject');
      const children = originObject.get('children');

      if (children) {
        for (let i = 0; i < children.length; i++) {
          this._provideDefaultContentIfEmptyParagraph(children[i]);
        }
      } else if (originObject.get('type') === _widget.WidgetType.WIDGET_PARAGRAPH && !originObject.get('content')) {
        originObject.set('content', '<p><br></p>');
      }
    },

    /**
     * Method convert data to backend format for styles attribute
     * and add some styles variables ('fullWidth', 'fillWidthContent', 'parallax')
     * as own properties passed object.
     *
     * NOTICE:
     * 		Styles attribute does not modified directly. Method always return new object.
     * 		Styles variables listed in "_nestedStylesProperties"	will be converted to properties and added directly into passed object.
     *
     * @see _nestedStylesProperties
     *
     * Example:
     * 	Before:
     * 	{
     * 		styles: {
     * 			global: [
     * 				{name: 'variable1', value: '20px'},
     * 				{name: 'variable2', value: '10px'},
     * 				...
     * 			],
     *		 	lg: [...],
     *			md: [...],
     *			sm: [...],
     *			xs: [...]
     * 	  }
     * 	}
     *
     *	After:
     *		{
     *			styles: {
     *				global: {
     *					'variable1': '20px',
     *					'variable2': '10px',
     *					...
     *				},
     *			  lg: {...},
     *				md: {...},
     *				sm: {...},
     *				xs: {...}
     *			}
     *		}
     *
     *
     * @param {Object} origin - main item structure(originObject property)
     * @return {Object} - serialized data
     * @method _serializeStyles
     * @throws Ember.Error
     * @private
     */
    _serializeStyles(origin) {
      const variables = this.getVariablesConfig(origin.get('type'));
      const styles = origin.get('styles');
      const excludedVariables = this._nestedStylesProperties;
      const result = {}; // Going each all bootstrap modes(lg, md and etc)

      [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL, 'global'].forEach(mode => {
        if (styles.hasOwnProperty(mode)) {
          let defaultVariable, currentVariable; // Each all variables by mode

          for (let i = styles[mode].length - 1; i >= 0; i -= 1) {
            // Get variable which stored in component hash
            currentVariable = styles[mode][i]; // Get variable from variables hardcoded storage

            defaultVariable = variables[mode].findBy('name', currentVariable.name); // Throw error if we can't find it in variables hardcoded storage

            if (!defaultVariable) {
              throw new Ember.Error(`This variable "${currentVariable.name}" is not declared for ${origin.get('type')}`);
            }

            if (excludedVariables.includes(currentVariable.name)) {
              origin[currentVariable.name] = currentVariable.value;
              styles[mode].removeAt(i, 1); // Gradient variables should be added always
            } else if (currentVariable.value && (currentVariable.value !== defaultVariable.value || /gradient/gi.test(defaultVariable.name))) {
              if (!result[mode]) {
                result[mode] = {};
              }

              result[mode][currentVariable.name] = currentVariable.value;
            }
          } // We should remove gradient variables if direction not selected(colors we have always by default now)


          if (result[mode]) {
            const colorTransparentChecker = /rgba\(\d{1,3},\s?\d{1,3},\s?\d{1,3},\s?0\)/;
            const bootstrapMode = result[mode];

            if (colorTransparentChecker.test(bootstrapMode['background-gradient-color1']) && colorTransparentChecker.test(bootstrapMode['background-gradient-color2'])) {
              delete bootstrapMode['background-gradient-direction'];
              delete bootstrapMode['background-gradient-color1'];
              delete bootstrapMode['background-gradient-color2'];
            } // Convert background for E6


            if (bootstrapMode['background-image']) {
              bootstrapMode['background-image'] = `url(${bootstrapMode['background-image']})`;
            } // Remove empty hash


            if (!Object.keys(bootstrapMode).length) {
              delete result[mode];
            }
          }
        }
      });
      return result;
    },

    /**
     * Serialize style options for backend
     *
     * @method _serializeStyleOptions
     * @param {Object} origin - main item structure(originObject property)
     * @return {Object[]} - serialized data
     * @private
     */
    _serializeStyleOptions(origin) {
      const options = origin.get('styles.StyleOptions');

      if (!options || !options.length) {
        return [];
      }

      return options.filter(option => Ember.get(option, 'locked'));
    },

    /**
     * Copies object
     *
     * @param {Object} object - object to be copied
     * @returns {Object}
     * @private
     */
    _copyObject(object) {
      const json = {};
      Object.keys(object).forEach(key => {
        if (typeof object[key] === 'function') {
          return;
        }

        if (Array.isArray(object[key])) {
          json[key] = [];
          const {
            length
          } = object[key];

          for (let c = 0; c < length; c++) {
            if (object[key][c] !== null && typeof object[key][c] === 'object') {
              json[key].push(this.makeJSON(object[key][c]));
            } else {
              json[key].push(object[key][c]);
            }
          }
        } else if (object[key] !== null && typeof object[key] === 'object') {
          json[key] = this.makeJSON(object[key]);
        } else {
          json[key] = object[key];
        }
      });
      return json;
    },

    /**
     * Determines whether object need to be unwrapped
     *
     * @method
     * @param {Object} node - object to check for unwrap
     * @returns {boolean}
     */
    _needUnwrap(node) {
      var _node$originObject$ty, _node$originObject, _node$parent, _node$parent$originOb, _node$originObject2, _nodeChildren$, _firstChild$originObj;

      const nodeType = (_node$originObject$ty = node === null || node === void 0 ? void 0 : (_node$originObject = node.originObject) === null || _node$originObject === void 0 ? void 0 : _node$originObject.type) !== null && _node$originObject$ty !== void 0 ? _node$originObject$ty : null;

      if (nodeType !== _container.default.WIDGET_COLUMN) {
        return false;
      }

      const nodeSiblings = (node === null || node === void 0 ? void 0 : (_node$parent = node.parent) === null || _node$parent === void 0 ? void 0 : (_node$parent$originOb = _node$parent.originObject) === null || _node$parent$originOb === void 0 ? void 0 : _node$parent$originOb.children) || [];

      if (nodeSiblings.length !== 1) {
        return false;
      }

      const nodeChildren = (node === null || node === void 0 ? void 0 : (_node$originObject2 = node.originObject) === null || _node$originObject2 === void 0 ? void 0 : _node$originObject2.children) || [];

      if (nodeChildren.length > 1) {
        return false;
      }

      const firstChild = (_nodeChildren$ = nodeChildren[0]) !== null && _nodeChildren$ !== void 0 ? _nodeChildren$ : null;

      if (firstChild && (firstChild === null || firstChild === void 0 ? void 0 : (_firstChild$originObj = firstChild.originObject) === null || _firstChild$originObj === void 0 ? void 0 : _firstChild$originObj.type) === _widget.WidgetType.WIDGET_SECTION) {
        return false;
      }

      const parentObject = node.parent.originObject;

      if (parentObject.type === _container.default.WIDGET_ROW && parentObject.styles) {
        return false;
      }

      return true;
    },

    /**
     * Unwraps object
     *
     * @method
     * @param {Object} structure - object to unwrap
     */
    _unwrap(structure) {
      const module = structure.get('originObject').get('children')[0];
      const row = structure.get('parent');
      const container = row.get('parent');

      if (container) {
        const index = container.get('originObject').get('children').indexOf(row);
        container.get('originObject').get('children').removeAt(index);
        container.get('originObject').get('children').insertAt(index, module);
        module.set('parent', container);
      }
    },

    /**
     * Recursive element structure normalizer
     *
     * @param {Object} element - element which structure to normalize
     * @param {Object} parent - parent element of element to normalize
     * @private
     */
    _normalize(element, parent = null) {
      const index = parent && parent.children ? parent.children.indexOf(element) : null;
      const children = element.children || [];
      element.type = this._getType(element);

      if (this._isNeedStylesNormalization(element)) {
        this._normalizeStyles(element);
      }

      if (parent && this._needsWrap(element, parent)) {
        const wrapper = this._getWrapper(element);

        wrapper.children.push(element);
        parent.children[index] = wrapper;

        this._normalize(wrapper, parent);
      }

      for (let i = 0, len = children.length; i < len; i++) {
        this._normalize(children[i], element);
      }
    },

    _serialiseTabData(element) {
      var _element$originObject;

      if ((element === null || element === void 0 ? void 0 : (_element$originObject = element.originObject) === null || _element$originObject === void 0 ? void 0 : _element$originObject.type) !== _container.default.WIDGET_TABS) {
        return;
      }

      element.originObject.tabs = undefined;
    },

    /**
     * Method detect that need to normalize styles or convert some
     * nested properties to styles variables
     *
     * @see _nestedStylesProperties to see all nested properties
     *
     * @param {Object} element - hash described component
     * @param {String} element.type - name of component
     * @param {Object} [element.styles] - styles for component
     * @return {Boolean}
     * @method _isNeedStylesNormalization
     * @private
     */
    _isNeedStylesNormalization(element) {
      const nestedVariables = this._nestedStylesProperties;
      const {
        styles
      } = element;
      let isNeed = false; // Check that container have nested variables which should be moved to styles property as variables.

      if (element.type === _container.default.WIDGET_CONTAINER) {
        for (let i = nestedVariables.length - 1; i >= 0; i -= 1) {
          if (element[nestedVariables[i]]) {
            isNeed = true;
            break;
          }
        }
      }

      if (!isNeed && styles) {
        isNeed = Object.keys(styles).some(breakpoint => !Array.isArray(styles[breakpoint]));
      }

      return isNeed;
    },

    /**
     * Method normalize styles hash to application format
     * and merge received values with it's default.
     *
     *
     * NOTICE:
     * 		Some properties described in "_nestedStylesProperties" property
     * 		will be converted in styles variables.
     *
     * @see _nestedStylesProperties to know about nested variables.
     *
     * Example:
     *   Data received from backend:
     *   	{
     *   		fullWidth: 'fit-to-content',
     *   	  	fullWidthContent: 'fit-to-content',
     *   		parallax: 'slow',
     *   		styles: {
     *   			global: {'variable1': '25px'},
     *   			lg: {'padding-top': '10px', ...},
     *   			...
     *   		}
     *   	}
     *
     *   After normalization:
     *		{
     *			styles: {
     *				global: [
     *					{name: 'variable1', value: '25px'},
     *					{name: 'fullWidth', value: 'fit-to-content'},
     *					{name: 'fullWidthContent', value: 'fit-to-content'},
     *					{name: 'parallax', value: 'slow'}
     *				],
     *				lg: [
     *					{name: 'padding-top', value: '10px'},
     *					...
     *				],
     *				...
     *		}
     *
     * @param {Object} element - hash described component('widget-container', 'widget-paragraph' and etc)
     * @method _normalizeStyles
     * @private
     */
    _normalizeStyles(element) {
      const copyVariables = (0, _emberCopy.copy)(this.getVariablesConfig(element.type), true);
      const nestedVariables = this._nestedStylesProperties;
      const regCssImageValue = /url\(['"]?(.*)['"]?\)/i;
      const {
        styles
      } = element;

      if (copyVariables) {
        if (styles) {
          Object.keys(styles).forEach(breakpoint => {
            const breakpointStyles = styles[breakpoint];

            if (breakpointStyles) {
              Object.keys(breakpointStyles).forEach(styleName => {
                var _copyVariables$breakp;

                const variable = (_copyVariables$breakp = copyVariables[breakpoint]) === null || _copyVariables$breakp === void 0 ? void 0 : _copyVariables$breakp.findBy('name', styleName);

                if (variable) {
                  // Replace all 'url(path/to/image.jpg) -> path/to/image.jpg' it's need for application
                  variable.value = regCssImageValue.test(breakpointStyles[styleName]) ? breakpointStyles[styleName].replace(regCssImageValue, '$1') : breakpointStyles[styleName];
                }
              });
            }
          });
        } // Convert some properties in styles variables


        for (let i = nestedVariables.length - 1; i >= 0; i -= 1) {
          if (element[nestedVariables[i]]) {
            this._provideNestedStylesVariable(element, nestedVariables[i], copyVariables);
          }
        }

        Ember.set(element, 'styles', copyVariables);
      } else {
        delete element.styles;
      }
    },

    /**
     * Method provide value from received nested styles variable
     * to it's analogue defined in variables hash which contains default values
     * and after that remove passed nested variable.
     *
     * @param {Object} element - has described component('widget-container', 'widget-paragraph' and etc)
     * @param {String} nestedVariable - name of variable
     * @param {Object} variables - variables hash which contains default values
     * @method _provideNestedStylesVariable
     * @throw {Ember.Error}
     * @private
     */
    _provideNestedStylesVariable(element, nestedVariable, variables) {
      const variable = variables.global.findBy('name', nestedVariable);

      if (!variable) {
        throw new Ember.Error(`Variable "${nestedVariable}" is not defined for "${element.type}"`);
      }

      variable.value = element[nestedVariable];
      delete element[nestedVariable];
    },

    /**
     * Get wrapper widget-column/widget-row
     *
     * @param {Object} element - element that needs wrap
     * @returns {Object} - wrapper
     * @private
     */
    _getWrapper(element) {
      let wrapper = null;

      if (_container.default.WIDGET_COLUMN === element.type) {
        wrapper = this._createRow();
      } else {
        wrapper = this._createColumn();
      }

      return wrapper;
    },

    /**
     * Check if wrap need
     *
     * @param {Object} child - element to test if it needs wrapper or not
     * @param {Object} parent - child element direct parent
     * @returns {Boolean} - true if wrap needed otherwise false
     * @private
     */
    _needsWrap(child, parent) {
      if (_container.default.WIDGET_ROW !== parent.type && _container.default.WIDGET_COLUMN === child.type) {
        return true;
      } // If column has more than 1 child and each child must be wrapped into row -> column -> item


      if (_container.default.WIDGET_COLUMN === parent.type && _container.default.WIDGET_ROW !== child.type && _module.default.MODULE_SYSTEM !== child.type && parent.children.length !== 1) {
        return true;
      }

      if (child.type === _widget.WidgetType.WIDGET_SECTION && parent.type === _container.default.WIDGET_CONTAINER) {
        return true;
      }

      if (child.type === _widget.WidgetType.WIDGET_TAB_PANE && parent.type === _container.default.WIDGET_TABS) {
        return false;
      } // Any widget or module must be wrapped into column


      return !this._containers.includes(child.type) && child.type !== _module.default.MODULE_SYSTEM && _container.default.WIDGET_COLUMN !== parent.type;
    },

    /**
     * Create new widget-column element
     *
     * @returns {Object} - widget-column element
     * @private
     */
    _createColumn() {
      return {
        type: 'widget-column',
        size: {
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        },
        children: []
      };
    },

    /**
     * Create new widget-row element
     *
     * @returns {Object} - widget-row element
     * @private
     */
    _createRow() {
      return {
        type: 'widget-row',
        children: []
      };
    },

    /**
     * Get correct type of component(config)
     *
     * @param {Object} element - element which type to detect
     * @returns {String} - "real" type of component
     * @private
     */
    _getType(element) {
      if (_module.default.MODULE_CORE === element.type && element.name === 'Form') {
        return _module.default.MODULE_FORM;
      }

      return element.type;
    },

    /**
     * Remove empty fields
     *
     * @method _removeEmptyFields
     * @param {Object} structure - object to process
     * @param {String} [type] - type of element (e.g. 'widget-container', 'widget-paragraph')
     * @private
     */
    _removeEmptyFields(structure, type) {
      const originObject = structure.get('originObject');
      const children = originObject.get('children');

      if (type && originObject.get('type') === type) {
        Object.keys(originObject).forEach(key => {
          if (Ember.isEmpty(originObject[key])) {
            delete originObject[key];
          }
        });
      } else if (children) {
        for (let i = 0; i < children.length; i++) {
          this._removeEmptyFields(children[i], type);
        }
      }
    }

  });

  _exports.default = _default;
});