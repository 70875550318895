define("builder/helpers/gte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class GTEHelper
   */
  var _default = Ember.Helper.extend({
    /**
     * @inheritdoc
     */
    compute(params) {
      return params[0] >= params[1];
    }

  });

  _exports.default = _default;
});