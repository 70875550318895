define("builder/pods/website/edit/page/controller", ["exports", "jquery", "builder/controllers/base", "builder/models/menu-child", "builder/models/menu-child-link", "builder/core/copy-menu-management-config", "builder/core/enumerators/notification", "builder/core/enumerators/module", "ember-copy", "builder/core/enumerators/controller-action", "builder/models/menu-root", "builder/errors/form-validation-error", "builder/errors/global-page-menu-insertion-error", "builder/core/enumerators/system-aliases", "sweetalert2"], function (_exports, _jquery, _base, _menuChild, _menuChildLink, _copyMenuManagementConfig, _notification, _module, _emberCopy, _controllerAction, _menuRoot, _formValidationError, _globalPageMenuInsertionError, _systemAliases, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * Service for menu manager
     * @type {Ember.Service}
     */
    menuManagerService: Ember.inject.service('menu-manager'),

    /**
     * Field Code service
     * @type {FieldCodeService}
     */
    fieldCodeService: Ember.inject.service('field-code'),

    /**
     * flag to show/hide copy action
     * @type {boolean}
     */
    isCopied: null,

    /**
     * state of overlay
     * @type {boolean}
     */
    hasOverlay: false,

    /**
     * is menu management opened or not
     * @type {boolean}
     */
    menuManagementDropDownIsOpened: false,

    /**
     * is module gallery button disabled or not
     * @type {boolean}
     */
    buttonModuleGalleryDisabled: Ember.computed('pageModel.page.isDeleted', 'updatePageAbility.isGranted', function () {
      return !!this.get('pageModel.page.isDeleted') || !this.updatePageAbility.isGranted;
    }),

    /**
     * State for page structure changes
     * @type {boolean}
     */
    isPageStructureChanged: false,

    /**
     * State for any module config changes
     * @type {boolean}
     */
    isModulesConfigChanged: false,

    /**
     * State for any form-module changes
     * @type {boolean}
     */
    isFormModuleChanged: false,

    /**
     * Flag to indicate whether save button has error
     * @type {boolean}
     */
    hasSaveButtonError: false,

    /**
     * Show page tools panel (create page, styles management and etc.) or not
     * @type {boolean}
     */
    isShowPageTools: true,

    /**
     * min string length to apply filter
     * @type {number}
     */
    _menuManagementFilterMinLength: 2,

    /**
     * Flag to publish layouts if any system module was modified
     * @type {boolean}
     * @deprecated This is temp code. Will be removed in Q4 2019
     */
    _publishLayouts: false,

    /**
     * watermark to be displayed in linked list of menu-manager
     * @type {string} linkedListWatermark -
     */
    linkedListWatermark: '',

    /**
     * watermark to be displayed in unlinked list of menu-manager
     * @type {string}
     */
    unlinkedListWatermark: '',

    /**
     * Shows/hides menu management config
     * @type {boolean}
     */
    isDisplayMMConfig: false,

    /**
     * contain data for MM config components
     * @type {null | Object}
     */
    mmConfigData: null,

    /**
     * Last search string
     * @type {string}
     */
    lastSearchString: '',

    /**
     * top position for popup MM
     * @type {string}
     */
    popupMMTop: '0',

    /**
     * model of the current page
     * @type {null|Ember.Model}
     */
    pageModel: Ember.computed('pageEditController.model.page', function () {
      return this.get('pageEditController.model') || null;
    }),

    /**
     * flag to determine whether the current page is home
     * @type {boolean}
     */
    homePage: Ember.computed('pageModel.page.Alias', function () {
      return this.get('pageModel.page.Alias') === _systemAliases.HOME_PAGE_ALIAS;
    }),

    /**
     * Observer add/remove listener("beforeunload" event) when user leave page without saving any changes
     * @type {Function}
     */
    tearDownObserver: Ember.observer('configurationService.hasUnsavedChanges', function () {
      if (this.get('configurationService.hasUnsavedChanges')) {
        this.tearDown();
      } else {
        this.cancelTearDown();
      }
    }),

    /**
     * Property contains status for menu item styles management.
     * @type {boolean}
     */
    stylesManagementIsOpened: false,

    /**
     * Property contains status for page settings.
     * @type {boolean}
     */
    pageSettingsIsOpened: false,

    /**
     * Property contains status for site settings.
     * @type {boolean}
     */
    siteSettingsIsOpened: false,

    /**
     * Property contains status for market segments settings.
     * @type {boolean}
     */
    marketSegmentsIsOpened: false,

    /**
     * is page config button disabled or not
     * @type {boolean}
     */
    buttonPageConfigDisabled: Ember.computed.bool('pageModel.page.isDeleted'),

    /**
     * page edit controller
     * @type {Ember.Controller}
     */
    pageEditController: Ember.inject.controller('website/edit/page/edit'),

    /**
     * module route controller
     * @type {Ember.Controller}
     */
    moduleController: Ember.inject.controller('website/edit/page/edit/module-gallery'),

    /**
     * form service
     * @type {Ember.Service}
     */
    formService: Ember.inject.service('form'),

    /**
     * Form validator service
     * @type {FormValidatorService}
     */
    formValidatorService: Ember.inject.service('form-validator'),

    /**
     * history service
     * @type {HistoryService}
     */
    history: Ember.inject.service('history'),

    /**
     * modules store service
     * @type {Ember.Service}
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * ajax service.
     * @type {AjaxService}
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * preview service
     * @type {Ember.Service}
     */
    previewService: Ember.inject.service('preview'),

    /**
     * structure service
     * @type {StructureService}
     */
    structureService: Ember.inject.service('structure'),

    /**
     * reference to access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Service for batch operations
     * @type {BatchOperationsService}
     * @private
     */
    batchOperationsService: Ember.inject.service('batch-operations'),

    /**
     * is page button previous disabled or not
     * @type {boolean}
     */
    buttonPreviousDisabled: Ember.computed.bool('pageModel.page.isDeleted'),

    /**
     * is page button next disabled or not
     * @type {boolean}
     */
    buttonNextDisabled: Ember.computed.bool('pageModel.page.isDeleted'),

    /**
     * is system modules button disabled or not
     * @type {boolean}
     */
    buttonSystemModulesDisabled: Ember.computed.bool('pageModel.page.isDeleted'),

    /**
     * is styles management button disabled or not
     * @type {boolean}
     */
    buttonStylesManagementDisabled: Ember.computed.bool('pageModel.page.isDeleted'),

    /**
     * is more actions button disabled or not
     * @type {boolean}
     */
    buttonMoreActionsDisabled: Ember.computed.bool('pageModel.page.isDeleted'),

    /**
     * Property contain state for "Publish" button
     * NOTE: when we changing site settings without changing anything within page,
     * backend will reset publish state and IsPublish property will be set to '1'.
     * @type {boolean}
     * @readonly
     */
    disablePublishButton: Ember.computed('model.website.IsPublished', 'configurationService.hasUnsavedChanges', 'isLoading', function () {
      const {
        hasUnsavedChanges
      } = this.configurationService;
      const isPublished = this.model.website.IsPublished === '1';
      return this.isLoading || !hasUnsavedChanges && isPublished;
    }).readOnly(),

    /**
     * Property contain state for "Save" button
     * @type {boolean}
     * @readonly
     */
    disableSaveButton: Ember.computed('configurationService.hasUnsavedChanges', 'isLoading', function () {
      const {
        hasUnsavedChanges
      } = this.configurationService;
      return this.isLoading || !hasUnsavedChanges;
    }).readOnly(),

    /**
     * Property contain state for MM "Create" button
     * @type {boolean}
     * @readonly
     */
    disableCreateButton: Ember.computed('mmConfigData.title.data', 'mmConfigData.link.data.value', function () {
      const config = this.mmConfigData;
      let disabled = true;

      if (config && config.title.data.trim() && (config.type === _menuChild.default.FOLDER || typeof config.link.validation === 'object' && !config.link.validation.required || config.link.data.value)) {
        disabled = false;
      }

      return disabled;
    }).readOnly(),

    /**
     * flag to determine whether module gallery is opened
     * @type {boolean}
     */
    modulesIsOpened: Ember.computed.equal('moduleController.routeDynamicSegment', 'module-gallery'),

    /**
     * flag to determine whether system modules is opened
     * @type {boolean}
     */
    systemModulesIsOpened: Ember.computed.equal('moduleController.routeDynamicSegment', 'system-modules'),

    /**
     * is at least 1 module(core-module) has it's property isLoaded set to false
     * return true, otherwise false, used to toggle "loader" visibility
     * @type {boolean}
     * @readonly
     */
    isPreloaderShow: Ember.computed('modules._list.@each.isLoaded', function () {
      const notSystemModules = this.modules.getList().filter(function (item) {
        return item.get('originObject.type') !== _module.default.MODULE_SYSTEM && !item.get('hideModuleOnPage');
      });
      return notSystemModules.filterBy('isLoaded', true).get('length') !== notSystemModules.get('length');
    }).readOnly(),

    /**
     * visibility status of the page
     * @type {string}
     * @readOnly
     */
    pageVisibilityStatus: Ember.computed('pageModel.page.IsHidden', function () {
      return this.get('pageModel.page.IsHidden') ? 'Show Page' : 'Hide Page';
    }).readOnly(),

    /**
     * visibility status for modules outlines
     * @type {string}
     * @readOnly
     */
    modulesOutlineVisibilityStatus: Ember.computed('configurationService.isModulesOutlineVisible', function () {
      return this.get('configurationService.isModulesOutlineVisible') ? this.get('literals.HIDE_MODULE_OUTLINES') : this.get('literals.SHOW_MODULE_OUTLINES');
    }).readOnly(),

    /**
     * flag to show/hide more actions
     * @type {boolean}
     */
    moreActions: false,

    /**
     * Main menu object
     * @type {MenuRoot}
     */
    mainMenu: Ember.computed('model.menu', 'model.menu.mainMenu', function () {
      return this.get('model.menu.mainMenu');
    }),

    /**
     * Unlinked menu object
     * @type {MenuRoot}
     */
    unlinkedMenu: Ember.computed('model.menu', 'model.menu.unlinkedMenu', function () {
      return this.get('model.menu.unlinkedMenu');
    }),

    /**
     * linked list of menu-manager
     * @type {Ember.Object[]}
     * @readonly
     */
    linkedList: Ember.computed('mainMenu.Structure.[]', function () {
      const pages = this.get('mainMenu.Structure') || [];
      const root = Ember.Object.create({
        config: Ember.Object.create({
          parent: null
        }),
        itemId: 0,
        parentId: 0,
        title: this.get('mainMenu.Title'),
        type: null,
        link: null,
        children: pages
      });

      this._setItemHelpers(pages, root);

      root.children = this.getSeoData(pages);
      return root;
    }).readOnly(),

    /**
     * unlinked list of menu-manager
     * @type {Ember.Object}
     * @readonly
     */
    unlinkedList: Ember.computed('unlinkedMenu.Structure.[]', function () {
      const pages = this.get('unlinkedMenu.Structure') || [];
      const root = Ember.Object.create({
        config: Ember.Object.create({
          parent: null
        }),
        itemId: null,
        parentId: null,
        title: this.get('unlinkedMenu.Title'),
        type: null,
        link: null,
        children: pages
      });

      this._setItemHelpers(pages, root);

      root.children = this.getSeoData(pages);
      return root;
    }).readOnly(),

    /**
     * seo data of menu-manager
     * @type {Ember.Object}
     */
    getSeoData(pages) {
      const data = (pages === null || pages === void 0 ? void 0 : pages.arrangedContent) || [];

      if (data.length === 0) {
        return pages;
      }

      for (let i = 0; i < data.length; i++) {
        var _data$i$children, _seoData$;

        if (((_data$i$children = data[i].children) === null || _data$i$children === void 0 ? void 0 : _data$i$children.arrangedContent) && data[i].children.arrangedContent.length > 0) {
          this.getSeoData(data[i].children);
        }

        const seoData = this.store.peekAll('page-seo').filterBy('PageId', data[i].config.pageId);
        data[i].seo = (_seoData$ = seoData[0]) === null || _seoData$ === void 0 ? void 0 : _seoData$.Structure;
      }

      return pages;
    },

    /**
     * Menu name under selected page
     * @type {string|null}
     * @readonly
     */
    menuTitle: Ember.computed('pageModel.page.Alias', 'linkedList.[]', 'unlinkedList.[]', function () {
      const alias = this.get('pageModel.page.Alias');
      const isMatchInLinkedMenu = this.mainMenu.findPageByAlias(alias);
      return isMatchInLinkedMenu ? this.get('mainMenu.Title') : this.get('unlinkedMenu.Title');
    }).readOnly(),

    /**
     * undo button is disabled
     * @type {boolean}
     */
    undoIsDisabled: Ember.computed('history.isCanGoBack', function () {
      return !this.history.isCanGoBack;
    }),

    /**
     * redo button is disabled
     * @type {boolean}
     */
    redoIsDisabled: Ember.computed('history.isCanGoForward', function () {
      return !this.history.isCanGoForward;
    }),

    /**
     * State for page
     * @type {boolean}
     */
    isPageScrolledToSticky: false,

    /**
     * width of collapsed side bar
     * @type {number}
     * @private
     */
    _collapsedSideBarWidth: 62,

    /**
     * width of expanded side bar
     * @type {number}
     * @private
     */
    _expandedSideBarWidth: 220,

    /**
     * class to be added to shell to make it sticky
     * @type {string}
     * @private
     */
    _fixedShellClass: 'page_edit_tools_fixed',

    /**
     * Property indicates the current page is 404
     * @type {boolean}
     * @private
     */
    _currentPageIs404: false,

    /**
     * Additional class names for tools panel
     * @type {string}
     * @readonly
     */
    toolsPanelAdditionalClasses: Ember.computed('isPageScrolledToSticky', function () {
      return this.isPageScrolledToSticky ? this._fixedShellClass : '';
    }).readOnly(),

    /**
     * Style for tools panel dummy
     * @type {string}
     * @readonly
     */
    toolsPanelDummyCss: Ember.computed('isPageScrolledToSticky', function () {
      return Ember.String.htmlSafe(this.isPageScrolledToSticky ? 'display: block;' : 'display: none;');
    }).readOnly(),

    /**
     * tools panel (shell) additional styles, used for sticky
     * @type {string|Handlebars.SafeString}
     */
    toolsPanelCss: '',

    /**
     * Property contains status for some actions in progress
     * @type {boolean}
     * @private
     */
    isLoading: false,

    /**
     * indicate that Ignite sidebar closed or not
     * @type {boolean}
     * @readonly
     */
    sidebarIsClosed: Ember.computed.readOnly('applicationController.sidebarIsClosed'),

    /**
     * Support contact message used for error notificatons
     * @type {string}
     * @readonly
     */
    supportContactsMessage: Ember.computed('app.support.url', 'app.support.phone', function () {
      return `
					<br/>
					Please contact <a href=${this.app.support.url} target="_blank" rel="noopener">Website Support</a> if you are unaware how to fix this issue
					<br/>
					Email: <a href="mailto:websitesupport@dealersocket.com">websitesupport@dealersocket.com</a>
					<br/>
					or Call ${this.app.support.phone}
				`;
    }).readOnly(),

    /**
     * Delete page ability
     * @returns {AccessControlResult}
     * @readonly
     */
    deletePageAbility: Ember.computed('accessControl', 'pageModel.page.isGlobal', function () {
      const {
        page
      } = this.pageModel;
      const deleteAbility = this.accessControl.can('delete page', page);
      const manageGlobalPage = this.accessControl.can('manage-global page', page);
      const deleteGlobalPageAbility = this.accessControl.can('delete-global page', page);
      let {
        message
      } = deleteAbility;

      if (!manageGlobalPage.isGranted) {
        message = this.literals.GLOBAL_PAGE_UPDATE_RESTRICTION;
      } else if (!deleteGlobalPageAbility.isGranted) {
        message = this.literals.GLOBAL_PAGE_DELETE_RESTRICTION;
      }

      return { ...deleteAbility,
        message
      };
    }).readOnly(),

    /**
     * Update page ability
     * @returns {AccessControlResult}
     * @readonly
     */
    updatePageAbility: Ember.computed('accessControl', 'pageModel.page.isChild', 'pageModel.page.isGlobal', function () {
      const {
        page,
        masterPage
      } = this.pageModel;
      const updateAbility = this.accessControl.can('update page', page);
      const updateGlobalAbility = this.accessControl.can('update-global page', page);
      const updateChildAbility = this.accessControl.can('update-child page', page);
      let {
        message
      } = updateAbility;

      if (!updateGlobalAbility.isGranted) {
        message = this.literals.GLOBAL_PAGE_UPDATE_RESTRICTION;
      } else if (!updateChildAbility.isGranted && masterPage) {
        message = this.literals.CHILD_PAGE_UPDATE_RESTRICTION.replace(':website', masterPage.WebsiteId).replace(':page', masterPage.id);
      }

      return { ...updateAbility,
        message
      };
    }).readOnly(),

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args); // Store to keep system modules which configs has been modified


      this.modifiedSystemModules = [];
      Ember.run.next(this, () => {
        (0, _jquery.default)(window).on('scroll.page', Ember.run.bind(this, () => {
          this.set('isPageScrolledToSticky', this._isShellSticky());

          this._adjustStickyShellAppearence();
        })).on('resize.page', Ember.run.bind(this, () => this._adjustStickyShellAppearence()));
      });

      this._adjustStickyShellAppearence();
    },

    /**
     * Styles to be added to sticky shell to correct it's appearance
     *
     * @returns {string}
     * @private
     */
    _getStickyStyles() {
      // When shell is sticky it has fixed position, hence width must be set explicitly
      // Also, when page scrolled by X axis, shell must be moved by X axis too
      const sideBarWidth = this.sidebarIsClosed ? this._collapsedSideBarWidth : this._expandedSideBarWidth;
      const width = (0, _jquery.default)(document).outerWidth() - sideBarWidth;
      return `width: ${width}px; transform: translateX(${sideBarWidth - window.pageXOffset}px)`;
    },

    /**
     * Adjust sticky shell appearance, set correct left, width, etc.
     *
     * @returns {void}
     * @private
     */
    _adjustStickyShellAppearence() {
      let styles = '';

      if (this.isPageScrolledToSticky) {
        styles = this._getStickyStyles();
      }

      this.set('toolsPanelCss', Ember.String.htmlSafe(styles));
    },

    /**
     * Define is shell sticky or not
     *
     * @returns {boolean} - true if shell is sticky and false otherwise
     * @private
     */
    _isShellSticky() {
      return window.pageYOffset > (0, _jquery.default)('header').outerHeight();
    },

    /**
     * Find page menu item by page id, in linkedList and unlinked
     *
     * @param {number|string} pageId - id of page to search
     * @returns {null|Ember.Object} - found page menu item or null if page menu item is not found
     * @private
     */
    _findPageItemInMenu(pageId) {
      const params = {
        pageId,
        menuItem: null
      };

      this._walkTree(this.unlinkedList, this._searchPageInMenuFn, params);

      if (!params.menuItem) {
        this._walkTree(this.linkedList, this._searchPageInMenuFn, params);
      }

      return params.menuItem;
    },

    /**
     * detect linked list change and perform actions
     * @type {Function}
     */
    onLinkedListChange: Ember.observer('linkedList', function () {
      if (this.lastSearchString) {
        this.send('onSearchSubmit', this.lastSearchString);
      }
    }),

    /**
     * detect unlinked list change and perform actions
     * @type {Function}
     */
    onUnlinkedListChange: Ember.observer('unlinkedList', function () {
      if (this.lastSearchString) {
        this.send('onSearchSubmit', this.lastSearchString);
      }
    }),

    /**
     * Item used to find page menu item by id in linkedList
     *
     * @param {Ember.Object} item - menu item
     * @param {any} params - params to be passed in filter function
     * @returns {boolean} - true if item is search menu item, false otherwise
     * @private
     */
    _searchPageInMenuFn(item, params) {
      if (item.get('type') === _menuChild.default.PAGE && item.get('config.pageId') === params.pageId) {
        params.menuItem = item;
        return true;
      }

      return false;
    },

    /**
     * Sets link to parent object for all menu items
     *
     * @param {Object[]} tree - array of menu items
     * @param {Object} parent - menu item
     * @returns {void}
     * @private
     */
    _setItemHelpers(tree, parent) {
      tree.forEach(item => {
        var _item$link;

        if (!item.config) {
          item.set('config', Ember.Object.extend(_emberCopy.Copyable, {
            copy() {
              return (0, _copyMenuManagementConfig._copy)(this);
            }

          }).create());
        }

        const isHomePage = item.type === _menuChild.default.PAGE && (item === null || item === void 0 ? void 0 : (_item$link = item.link) === null || _item$link === void 0 ? void 0 : _item$link.value) === _systemAliases.HOME_PAGE_ALIAS;
        const {
          config
        } = item;
        config.set('isMissingPage', item.type === _menuChild.default.PAGE && (config.isMissingPage || item.isMissingPage));
        config.set('parent', parent);
        config.set('draggable', true);
        config.set('removable', !isHomePage);
        config.set('hidden', false);
        config.set('hiddable', !isHomePage);
        config.set('forceHidden', false);
        config.set('isHomepage', isHomePage); // Store folder state

        if (item.type === _menuChild.default.FOLDER) {
          const menu = this.mainMenu;
          const {
            unlinkedMenu
          } = this;
          config.set('expanded', config.expanded || false);
          Ember.defineProperty(config, 'hasBrokenLink', Ember.computed('component.data.link.value', function () {
            var _this$component, _this$component$data, _this$component2, _this$component2$data, _this$component2$data2;

            const link = (_this$component = this.component) === null || _this$component === void 0 ? void 0 : (_this$component$data = _this$component.data) === null || _this$component$data === void 0 ? void 0 : _this$component$data.link;
            const linkValue = (_this$component2 = this.component) === null || _this$component2 === void 0 ? void 0 : (_this$component2$data = _this$component2.data) === null || _this$component2$data === void 0 ? void 0 : (_this$component2$data2 = _this$component2$data.link) === null || _this$component2$data2 === void 0 ? void 0 : _this$component2$data2.value;
            let hasBrokenLink = false;

            if (!link || link.type !== _menuChildLink.default.INTERNAL || !linkValue) {
              hasBrokenLink = false;
            } else {
              // Find page in primary menu
              let menuItem = menu.findPageByAlias(linkValue);

              if (menuItem && menuItem.isMissingPage) {
                hasBrokenLink = true;
              } else {
                // Find page in unlinked menu
                menuItem = unlinkedMenu.findPageByAlias(linkValue);

                if (menuItem && menuItem.isMissingPage) {
                  hasBrokenLink = true;
                }
              }
            }

            return hasBrokenLink;
          }));
        }

        if (item.type === _menuChild.default.PAGE) {
          var _item$link2;

          config.set('isMissingPage', config.isMissingPage || item.isMissingPage);
          const page = this.pageModel.all.findBy('Alias', item === null || item === void 0 ? void 0 : (_item$link2 = item.link) === null || _item$link2 === void 0 ? void 0 : _item$link2.value);
          let isPopupPage = false;

          if (page) {
            config.set('pageId', page.id);

            const masterPage = this._getMasterPage(page, this.pageModel.all); // Too lazy to refactor, just inject another property


            config.set('page', page);
            config.set('masterPage', masterPage);
            isPopupPage = page.isPopup;
          }

          config.set('isPopupPage', isPopupPage);
        }

        if (item.children) {
          this._setItemHelpers(item.children, item);
        }
      });
    },

    _getMasterPage(page, allPages) {
      if (page.GlobalParentId === '0') {
        return null;
      }

      return allPages.findBy('id', page.GlobalParentId);
    },

    /**
     * Makes all passed items hidden
     *
     * @param {Object[]} items - set of items
     * @param {number} hidden - item hidden status
     * @param {string} [type] - type of item
     * @param {string} [alias] - alias of item
     * @returns {void}
     */
    toggleMenuItems(items, hidden, type, alias) {
      for (let i = 0, length = items.get('length'); i < length; i++) {
        const item = items.objectAt(i);

        if (type === item.get('type') && item.get('link') && item.get('link').get('value') === alias) {
          if (!(hidden === 0 && item.get('config').get('forceHidden'))) {
            item.set('isHidden', hidden);
          }
        } else if (!type && !alias) {
          if (!(hidden === 0 && item.get('config').get('forceHidden'))) {
            item.set('isHidden', hidden);
          }
        }

        if (item.get('children')) {
          this.toggleMenuItems(item.get('children'), hidden, type, alias);
        }
      }
    },

    /**
     * Compiled CSS for widgets
     * @type {string}
     * @readonly
     */
    widgetsCSS: Ember.computed.readOnly('model.websiteStyle.scopedStyleCSS'),
    globalStyleVariables: Ember.computed.readOnly('model.websiteStyle.globalStyleVariables'),

    /**
     * Toggle menu item isHidden property
     *
     * @param {Object} data - menu item object
     * @returns {void}
     * @private
     */
    _toggleMenuItemHidden(data) {
      const isHidden = data.get('isHidden') === 1 ? 0 : 1;
      data.set('isHidden', isHidden);
      data.get('config').set('forceHidden', !!isHidden);
    },

    /**
     * Method that sets whether to show or hide a page
     *
     * @param {DS.Model} model - page model
     * @param {0 | 1} isHidden - menu item status, hide or unhide, must be 0 or 1
     * @returns {void}
     * @private
     */
    _setPageVisibility(model, isHidden) {
      model.set('IsHidden', isHidden);
      this.send('setChanges');
    },

    /**
     * Toggle linked menu items isHidden property
     *
     * @param {Object} data - menu item object
     * @returns {void}
     * @private
     */
    _toggleLinkedItems(data) {
      const isHidden = data.get('isHidden');
      const type = data.get('type');

      if (type === _menuChild.default.PAGE) {
        this._setPageVisibility(this.pageModel.all.findBy('id', data.get('config.pageId')), isHidden);

        this.toggleMenuItems(this.linkedList.get('children'), isHidden, _menuChild.default.LINK, data.get('link.value'));
      } else if (type === _menuChild.default.FOLDER) {
        this.toggleMenuItems(data.get('children'), isHidden);
      }
    },

    /**
     * Reset controller state
     *
     * @returns {void}
     */
    resetProps() {
      this.configurationService.set('hasUnsavedChanges', false);
      this.set('isPageStructureChanged', false);
      this.set('isModulesConfigChanged', false);
      this.set('isFormModuleChanged', false);
      this.set('isShowPageTools', true); // Need to send rebuild because on save API removes module configs

      this.previewService.set('rebuild', true);
    },

    showErrorMessage(error) {
      if (error && error.hasOwnProperty('errors')) {
        const errorMessages = error.errors.join(', ');

        _sweetalert.default.fire('Error', errorMessages, 'error');
      }

      if (error instanceof _formValidationError.default) {
        this.showNotification([error.errors.join('<br>'), this.supportContactsMessage].join('</br>'), _notification.default.TYPE.ERROR, Number.MAX_SAFE_INTEGER);
      } else if (error instanceof _globalPageMenuInsertionError.default) {
        this.notifyAboutGlobalPageMenuInsertionError(error);
      } else {
        this.showNotification([error.message || error.errors.join('<br>'), this.supportContactsMessage].join('</br>'), _notification.default.TYPE.ERROR, 7000);
      }
    },

    /**
     * Delete menu item
     *
     * @param {Ember.Object} item - menu item to delete
     * @returns {boolean} - if menu item is removed returns true, otherwise false
     */
    deleteMenuItem(item) {
      const id = item.get('itemId');
      let menuItem = this.mainMenu.markItemAsDelete(id);

      if (!menuItem) {
        menuItem = this.unlinkedMenu.markItemAsDelete(id);
      }

      return !!menuItem;
    },

    /**
     * Update parent node of a tree
     *
     * @param {Element} node
     * @param {Element} parent
     * @returns {void}
     * @private
     */
    _updateParent(node, parent) {
      const children = node.get('children');
      node.set('config.parent', parent);
      node.set('parentId', parent.get('itemId'));

      if (children) {
        for (let i = 0, len = children.get('length'); i < len; i++) {
          this._updateParent(children.objectAt(i), node);
        }
      }
    },

    /**
     * Delete page by id
     *
     * @param {string|number} id - id of page to delete
     * @returns {void}
     */
    deletePage(id) {
      const page = this.store.peekRecord('page', id);

      if (page) {
        page.deleteRecord();
        this.updateAllPagesList();

        this._deleteFormTemplateUsageOnPage(id);
      }
    },

    /**
     * Delete form usage on page
     *
     * @param {string|number} pageId - id of page to delete
     * @returns {void}
     * @private
     */
    _deleteFormTemplateUsageOnPage(pageId) {
      const formTemplates = this.store.peekAll('form');
      formTemplates.forEach(model => {
        model.removeUsageOnPage('page', pageId);
      });
    },

    /**
     * Updated model "all" property
     *
     * @returns {void}
     */
    updateAllPagesList() {
      Ember.set(this.pageModel, 'all', this.store.peekAll('page').filterBy('isDeleted', false));
    },

    /**
     * Show notification popup
     *
     * @param {string|string[]} message - message to display
     * @param {'success' | 'error' | 'info'} [type=success] - message type
     * @param {number} [timeToDisplay=7000] - popup appearance delay in ms
     * @returns {void}
     */
    showNotification(message, type = _notification.default.TYPE.SUCCESS, timeToDisplay = 7000) {
      this.applicationController.send('notification', {
        type,
        timeToDisplay,
        message: [].concat(message)
      });
    },

    /**
     * Generate water mark for menu
     *
     * @method _generateWaterMark
     * @param {string} searchString - string to be placed in bold tag
     * @returns {string}
     * @private
     */
    _generateWaterMark(searchString) {
      return `<b>${searchString}</b><span> - no pages found</span>`;
    },

    /**
     * Generate menu-manager search regexp
     *
     * @param {string} searchString - search string
     * @returns {RegExp}
     * @private
     */
    _generateSearchRegExp(searchString) {
      const escaped = searchString.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      return new RegExp(`${escaped.split(' ').join('|')}`, 'i');
    },

    /**
     * Walk tree and apply callback to each tree leaf and node
     *
     * @param {Ember.Object} tree - tree object or tree node
     * @param {Function} callback - callback to be invoked on each tree leaf
     * @param {any} params - additional params to be passed to callback
     * @returns {void}
     * @private
     */
    _walkTree(tree, callback, params) {
      const children = tree.get('children') || null;

      if (callback(tree, params) === true) {
        return;
      }

      for (let i = 0, len = children ? children.get('length') : 0; i < len; i++) {
        this._walkTree(children.objectAt(i), callback, params);
      }
    },

    /**
     * Walk up the tree, from bottom to top
     *
     * @param {Ember.Object} tree - child node
     * @param {string} parentKey - object property key, link to parent
     * @param {Function} callback - callback to be applied on each parent
     * @returns {void}
     * @private
     */
    _walkTreeUp(tree, parentKey, callback) {
      const parent = tree.get(parentKey);
      callback(tree);

      if (parent) {
        this._walkTreeUp(parent, parentKey, callback);
      }
    },

    /**
     * Filter linked list by passed in regexp
     *
     * TODO: split filter and show parent into 2 separate method
     *
     * @param {Model.FragmentArray} linkedList - mode array with nested fragments
     * @param {RegExp} filterRegExp - regexp to match against
     * @param {boolean} walkUp - apply to visible items method this._processMenuManagementVisibleItem or not
     * @returns {{regexp: RegExp, visible: Object[], hidden: Object[]}}
     * @private
     */
    _filterMenuList(linkedList, filterRegExp, walkUp = false) {
      const filterFn = this._filterMenuManagementItem;
      const processFn = this._processMenuManagementVisibleItem;
      const params = {
        regexp: filterRegExp,
        visible: [],
        hidden: []
      };

      for (let i = 0, len = linkedList.get('length'); i < len; i++) {
        this._walkTree(linkedList.objectAt(i), filterFn, params);

        if ((params === null || params === void 0 ? void 0 : params.hidden) && params.hidden.length > 0 && this._filterMenuManagementByAlias) {
          this._walkTree(linkedList.objectAt(i), this._filterMenuManagementByAlias, params);
        }

        if ((params === null || params === void 0 ? void 0 : params.hidden) && params.hidden.length > 0 && this._filterMenuManagementBySeo) {
          this._walkTree(linkedList.objectAt(i), this._filterMenuManagementBySeo, params);
        }
      }

      if (walkUp) {
        for (let i = 0, len = params.visible.length; i < len; i++) {
          this._walkTreeUp(params.visible[i], 'config.parent', processFn);
        }
      }

      return params;
    },

    /**
     * Function to be invoked on each visible menu item
     *
     * @param {Ember.Object|Fragment} item - menu item
     * @returns {void}
     * @private
     */
    _processMenuManagementVisibleItem(item) {
      item.get('config').set('hidden', false);

      if (item.get('type') === _menuChild.default.FOLDER) {
        item.get('config').set('expanded', true);
      }
    },

    /**
     * Function to be invoked to filter menu items
     *
     * @param {Ember.Object|Fragment} item - menu item
     * @param {Object} params - additional parameters with regexp to filter, hiddenCount and visibleCount, just counters
     * @returns {void}
     * @private
     */
    _filterMenuManagementItem(item, params) {
      const hidden = !params.regexp.test(item.get('title'));
      item.get('config').set('hidden', hidden);

      if (hidden) {
        params.hidden.push(item);
      } else {
        params.visible.push(item);
      }
    },

    /**
     * Function to be invoked to filter menu items by alias
     *
     * @param {Ember.Object|Fragment} item - menu item
     * @param {Object} params - additional parameters with regexp to filter, hiddenCount and visibleCount, just counters
     * @returns {void}
     * @private
     */
    _filterMenuManagementByAlias(item, params) {
      const hidden = !params.regexp.test(item.get('link.value'));
      item.get('config').set('hidden', hidden);

      if (hidden) {
        if (!params.hidden.some(elem => elem === item) && !params.visible.some(elem => elem === item)) {
          params.hidden.push(item);
        }
      } else if (!params.visible.some(elem => elem === item)) {
        params.visible.push(item);
        params.hidden.pop(item);
      }
    },

    /**
     * Function to be invoked to filter menu items by seo
     *
     * @param {Ember.Object|Fragment} item - menu item
     * @param {Object} params - additional parameters with regexp to filter, hiddenCount and visibleCount, just counters
     * @returns {void}
     * @private
     */
    _filterMenuManagementBySeo(item, params) {
      // visible to check item is visible or not
      let visible = false;
      const structure = (item === null || item === void 0 ? void 0 : item.seo) || [];

      if (structure.length === 0) {
        return params;
      }

      for (let i = 0; i < structure.length; i++) {
        if (params.regexp.test(structure[i].template)) {
          visible = params.regexp.test(structure[i].template);
        }

        if (params.regexp.test(structure[i].filter)) {
          visible = params.regexp.test(structure[i].filter);
        }

        const isVisibleItemsInParams = !params.visible.some(elem => elem === item);

        if (visible && isVisibleItemsInParams) {
          params.hidden.pop(item);
          return params.visible.push(item);
        }
      }

      const itemAlreadyExsitInParams = !params.hidden.some(elem => elem === item) && !params.visible.some(elem => elem === item);

      if (itemAlreadyExsitInParams) {
        item.config.set('hidden', true);
        params.hidden.push(item);
      }

      return params;
    },

    /**
     * Adds system module to storage in order to be saved later
     *
     * @param {Object} module - System module model
     * @returns {void}
     */
    addModifiedSystemModule(module) {
      if (!this.modifiedSystemModules.includes(module)) {
        this.modifiedSystemModules.push(module);
      }
    },

    /**
     * Find menu item in linked and unlinked pages and delete it
     *
     * @param {string|number} pageId - page id of menu item page
     * @returns {boolean} - if item is actually removed returns true, otherwise false
     */
    findAndDeleteMenuItemByPageId(pageId) {
      const menuItem = this._findPageItemInMenu(pageId);

      let removed = false;

      if (menuItem) {
        removed = this.deleteMenuItem(menuItem);
      }

      return removed;
    },

    /**
     * Invalidates modified system modules storage
     *
     * @returns {void}
     */
    invalidatedModifiedSystemModules() {
      this.set('modifiedSystemModules', []);
    },

    /**
     * Notify backend to clean all unsaved data
     *
     * @returns {void}
     */
    sendBackendClearUnsavedData() {
      this.store.clearModules(this.pageModel.page.get('id'));
    },

    /**
     * @inheritdoc
     */
    destroy(...args) {
      (0, _jquery.default)(window).off('scroll.page');
      (0, _jquery.default)(window).off('resize.page');

      this._super(...args);
    },

    /**
     * Method notify about changes, activate save button and mark page as unpublished
     *
     * @returns {void}
     */
    notifyAboutChanges() {
      this.set('configurationService.hasUnsavedChanges', true);
      this.set('model.website.IsPublished', '0');
    },

    /**
     * Method return url for preview
     *
     * @returns {string}
     * @private
     */
    _getPreviewUrl() {
      const alias = this.homePage ? '' : this.pageModel.page.get('Alias');
      const domain = this.get('model.website.Domain');
      const protocol = this.get('model.website.Protocol');
      return `${protocol}://${domain}/${alias}?_preview`;
    },

    /**
     * Adds new check point for undo/redo history
     *
     * @param {Array} checkpoints - Models to be added to check point
     * @returns {void}
     */
    addHistoryCheckpoint(checkpoints) {
      this.history.checkpoint(checkpoints);
    },

    /**
     * Clears undo/redo history
     *
     * @returns {void}
     */
    clearUndoRedoHistory() {
      this.history.clear();
    },

    /**
     * Notify user about global page insertion into menus
     * @param {GlobalPageMenuInsertionError} error - error instance
     * @returns {void}
     */
    notifyAboutGlobalPageMenuInsertionError(error) {
      if (!error || !error.details) {
        return;
      }

      const detailMessages = error.details.map(detail => {
        const {
          message,
          websiteId
        } = detail;
        const website = this.store.peekRecord('website', websiteId);
        const websiteLabel = (website === null || website === void 0 ? void 0 : website.Domain) || `Website #${websiteId}`;
        return `<b>${websiteLabel}</b> - ${message}`;
      });
      const message = [error.message, detailMessages.join('<br>')].join('<br>');
      this.showNotification([message, this.supportContactsMessage].join('<br>'), _notification.default.TYPE.WARNING, Number.MAX_SAFE_INTEGER);
    },

    actions: {
      /**
       * Action to handle user interaction with title "field-link" in config.
       * This action update menu model directly.
       *
       * @param {Object} value - values which will be set in model
       * @returns {void}
       */
      changeMMLink(value) {
        let link = this.get('mmConfigData.storeSegmentRef.link');

        if (!link) {
          this.set('mmConfigData.storeSegmentRef.link', {
            target: null,
            value: null,
            type: null
          });
          link = this.get('mmConfigData.storeSegmentRef.link');
        }

        Ember.set(link, 'type', value.type);
        Ember.set(link, 'value', value.value);
        Ember.set(link, 'target', value.target);
        Ember.set(link, 'params', value.params); // Prevent activate "Save" button if menu management config opened not in edit mode.
        // In create mode have button to activate "Save".

        if (this.get('mmConfigData.isEdit')) {
          this.send('setChanges');
        }
      },

      /**
       * Action adds new menu item into menu structure
       *
       * @param {Object} data - menu item created based on this object
       * @returns {Object}
       */
      addMenuItem(data) {
        const page = this.pageModel.all.findBy('Alias', data.link.data.value);
        return this.store.createMenuItem({
          title: data.title.data,
          type: data.type,
          link: data.link.data,
          pageId: page && page.id || null
        }).then(item => {
          const menu = data.addToMenu ? this.mainMenu : this.unlinkedMenu;
          menu.createMenuChild(item);
          this.send('setChanges');
          this.send('destroyMMConfig');
        }, error => {
          this.showNotification(error.responseJSON.message, _notification.default.TYPE.ERROR);
        });
      },

      /**
       * Action to handle menu-manager search
       *
       * TODO: probably walkup tree should be invoked here
       * @param {string} searchString - search string
       * @returns {void}
       */
      onSearchSubmit(searchString) {
        let trimmedSearchString = searchString.trim();

        let searchRegExp = this._generateSearchRegExp(trimmedSearchString);

        const watermark = this._generateWaterMark(searchString);

        let showVisible = true;
        let useWatermark = true;

        if (trimmedSearchString.length < this._menuManagementFilterMinLength) {
          searchRegExp = new RegExp('.*'); // Reset search string

          trimmedSearchString = '';
          useWatermark = false;
          showVisible = false;
        }

        const resultUnlinked = this._filterMenuList(this.get('unlinkedList.children'), searchRegExp, showVisible);

        const resultLinked = this._filterMenuList(this.get('linkedList.children'), searchRegExp, showVisible);

        this.set('unlinkedListWatermark', useWatermark && resultUnlinked.visible.length === 0 ? watermark : '');
        this.set('linkedListWatermark', useWatermark && resultLinked.visible.length === 0 ? watermark : '');
        this.set('lastSearchString', trimmedSearchString);
      },

      /**
       * Handle menu management item title or icon click
       *
       * @param {MenuChildModel} model - component that has been clicked
       * @returns {void}
       */
      onMenuManagementItemTitleClick(model) {
        const currentPage = this.get('pageModel.page'); // When clicked on the same page, do nothing

        if (!model || !currentPage || model.get('config.pageId') === currentPage.get('id')) {
          return;
        }

        switch (model.get('type')) {
          case _menuChild.default.PAGE:
            {
              this.send('onMenuManagementPageItemClick', model);
              break;
            }

          case _menuChild.default.LINK:
            {
              this.send('onMenuManagementLinkItemClick', model);
              break;
            }

          case _menuChild.default.FOLDER:
            {
              this.send('onMenuManagementFolderItemClick', model);
              break;
            }
        }
      },

      /**
       * Page menu item click handler
       *
       * @param {MenuChildModel} pageItem - menu child model of page type
       * @returns {void}
       */
      onMenuManagementPageItemClick(pageItem) {
        if (this.hasNoChangesOrDoNotNeedToBeSaved()) {
          this.transitionToRoute('website.edit.page.edit', pageItem.get('config.pageId'));
        }
      },

      /**
       * Link menu item click handler
       *
       * @param {MenuChildModel} linkItem - menu child model of link type
       * @returns {void}
       */
      onMenuManagementLinkItemClick(linkItem) {
        // When internal link clicked, we search for page menu item it references
        if (linkItem.get('link.type') === _menuChildLink.default.INTERNAL) {
          const pageItem = this.model.menu.findPageByAlias(linkItem.get('link.value'));

          if (pageItem) {
            this.send('onMenuManagementPageItemClick', pageItem);
          }
        } else {
          const link = linkItem.get('link.value');

          if (link) {
            window.open(link);
          }
        }
      },

      /**
       * Folder menu item click handler
       *
       * @param {MenuChildModel} folderItem - menu child model of folder type
       * @returns {void}
       */
      onMenuManagementFolderItemClick(folderItem) {
        const link = folderItem.get('link.value');

        if (link) {
          this.send('onMenuManagementLinkItemClick', folderItem);
        } else {
          this.send('onMenuManagementItemCarretClick', folderItem);
        }
      },

      /**
       * Menu item remove confirmation action
       *
       * @param {MenuChildModel} item - menu item to check for confirmation
       * @returns {void}
       */
      confirmRemoveMenuItem(item) {
        if (!item.config.removable) {
          return;
        }

        switch (item.type) {
          case _menuChild.default.PAGE:
            {
              const alias = item.link.value;
              const internalLinks = this.mainMenu.findInternalLinksByAlias(alias);

              if (internalLinks.length === 0) {
                break;
              }

              const messages = [this.literals.MENU_MANAGEMENT_REMOVE_REFERED_PAGE, '<br/>', 'link references: ', ...internalLinks.map(x => Ember.String.htmlSafe(`- <b>${x.title}</b>`))];
              this.showNotification(messages, _notification.default.TYPE.ERROR, Number.MAX_SAFE_INTEGER);
              return;
            }

          case _menuChild.default.FOLDER:
            {
              if (!item.children || item.children.length === 0) {
                break;
              }

              this.showNotification(this.literals.MENU_MANAGEMENT_REMOVE_NOT_EMPTY_FOLDER, _notification.default.TYPE.ERROR, Number.MAX_SAFE_INTEGER);
              return;
            }

          default:
            break;
        }

        if (window.confirm(this.literals.MENU_MANAGEMENT_REMOVE_ITEM_CONFIRMATION)) {
          this.send('removeMenuItem', item);
        }
      },

      /**
      * Used internally only
      *
      * @param {Ember.Object} item - menu item
      * @returns {boolean|undefined} - returns false if item is not removable
      */
      removeMenuItem(item) {
        if (!item.get('config.removable')) {
          return false;
        }

        this.deleteMenuItem(item); // Close config of removed page

        if (this.isDisplayMMConfig) {
          const config = this.mmConfigData;

          if (config && config.storeSegmentRef.get('itemId') === item.get('itemId')) {
            this.send('destroyMMConfig');
          }
        }

        if (item.get('type') === _menuChild.default.PAGE) {
          const pageId = item.get('config.pageId');
          let message = this.literals.PAGE_REMOVED_SUCCESS;

          if (pageId) {
            this.deletePage(pageId);

            if (pageId === this.get('pageModel.page.id')) {
              message = this.literals.CURRENT_PAGE_REMOVED_SUCCESS; // When current page removed

              this.pageEditController.closePageFeaturesPopups();
            }
          }

          this.showNotification(message, _notification.default.TYPE.SUCCESS);
        }

        this.send('setChanges');
        return undefined;
      },

      onMenuManagementClick() {
        this.pageEditController.send('deactivateModule');
        this.pageEditController.send('closeWidgetPopup');
        this.fieldCodeService.codeEditCancel();
        this.transitionToRoute('website.edit.page.edit');
      },

      /**
      * Sets new item title in MM settings
      *
      * @param {string} title - item title
      * @param {number} itemId
      * @returns {void}
      */
      changeMMSettingsTitle(title, itemId) {
        if (this.isDisplayMMConfig && this.mmConfigData && this.mmConfigData.storeSegmentRef.get('itemId') === itemId) {
          this.set('mmConfigData.title.data', title);
          this.send('setChanges');
        }
      },

      /**
      * Action to update page title and it's dependencies Meta title if
      * pageTitle === metaTitle
      *
      * @param {string} title - new page title
      * @param {number} id - page id
      * @returns {void}
      */
      changePageTitle(title, id) {
        const page = this.get('pageModel.all').findBy('id', id);

        if (page && page.get('Title') !== title) {
          const pageMetaTagsRecord = this.store.peekAll('page-meta').findBy('PageId', page.get('id')); // We should update meta title at same time with page title if they are equal

          if (pageMetaTagsRecord && pageMetaTagsRecord.get('Title') === page.get('Title')) {
            pageMetaTagsRecord.set('Title', title);
          }

          page.set('Title', title);
          this.send('setChanges');
        }
      },

      /**
       * Action to handle user interaction with title "field-text" in config.
       * This action update menu model directly.
       *
       * @param {string} value - value which will be set in model
       * @param {Object} item - menu item
       * @returns {void}
       */
      changeMMTitle(value, item) {
        if (item && item.get('title') !== value) {
          item.set('title', value);
          this.send('setChanges');
        } else if (this.get('mmConfigData.storeSegmentRef.title') && this.get('mmConfigData.storeSegmentRef.title') !== value) {
          this.set('mmConfigData.storeSegmentRef.title', value);
          this.send('setChanges');
        } // Config was opened in edit mode(without "Create" button)


        if (this.get('mmConfigData.isEdit')) {
          this.send('setChanges');
        }
      },

      /**
      * Action to handle menu-manager search clear
      *
      * @returns {void}
      */
      onSearchClear() {
        this.send('onSearchSubmit', '');
        this.set('lastSearchString', '');
        this.menuManagerService.set('searchString', '');
      },

      /**
      * Handle menu management folder expand/collapse icon click
      *
      * @param {Ember.Object|Fragment} component - component that has been clicked
      * @returns {void}
      */
      onMenuManagementItemCarretClick(component) {
        component.get('config').set('expanded', !component.get('config.expanded'));
      },

      /**
      * Action show popup config
      *
      * @param {Object} data - contains elements default state
      * @param {boolean} isEdit - mode for config(Edit or Create)
      * @returns {void}
      */
      openMMConfig(data, isEdit = true) {
        const config = this.mmConfigData; // Prevent multiple rerender when user clicked on opened config item

        if (!this.isDisplayMMConfig || config && config.storeSegmentRef.get('itemId') !== data.storeSegmentRef.get('itemId')) {
          // This property setup in which mode MM config open to show/hide create button
          data.isEdit = isEdit; // Since menuItemId required by field-link, we should find page that is referenced by current data item

          if (data.link.data.type === 'internal') {
            const menuItem = this.model.menu.findPageByAlias(data.link.data.value);
            data.link.data.menuItemId = menuItem ? menuItem.get('itemId') : null;
          }

          const $pageToolsPanel = (0, _jquery.default)('.js-page-edit-tools'); // Calc top offset for popup
          // 13 it's extra space

          this.set('popupMMTop', `${13 + $pageToolsPanel.outerHeight() + ($pageToolsPanel.hasClass('page_edit_tools_fixed') ? 0 : (0, _jquery.default)('.header').outerHeight())}px`);
          this.set('isDisplayMMConfig', true);
          this.set('mmConfigData', data);
        }
      },

      /**
      * Action to open page create wizard
      *
      * @param {boolean} [addToMenu] - set addToMenu query param
      * @returns {void}
      */
      openPageWizard(addToMenu = false) {
        if (this.hasNoChangesOrDoNotNeedToBeSaved()) {
          // Close page edit popup
          this.pageEditController.closePageFeaturesPopups();
          this.transitionToRoute('website.edit.page.edit.create', this.pageModel, {
            queryParams: {
              addToMenu
            }
          });
        }
      },

      /**
       * Action redirect user to page settings page.
       *
       * @param {number|string} id - page settings id
       * @returns {void}
       */
      async openPageSettingsConfigById(id) {
        this.send('destroyMMConfig');
        let page = this.store.peekRecord('page', id);

        if (page.BaseStructure == null) {
          await this.store.getPageStructure(id).then(updatedPage => {
            page.BaseStructure = updatedPage.BaseStructure;
            page.Structure = updatedPage.Structure;
          });
        }

        this.transitionToRoute('website.edit.page.edit.settings.setting', id);
      },

      /**
       * Action for user interaction to lose menu management config
       * @returns {void}
       */
      destroyMMConfig() {
        this.set('isDisplayMMConfig', false);
        this.set('mmConfigData', null);
      },

      /**
       * Note: here not deep copy used, cause fragments cannot be moved anywhere, since they are tightly coupled with fragment
       * rollback feature
       *
       * @param {Element} receiver
       * @param {Element} item
       * @param {number} index
       * @returns {void}
       */
      menuManagerMoveItem(receiver, item, index) {
        const parent = item.get('config.parent');
        const itemCopy = item.copy();

        if (parent) {
          if (parent.get('type') === null && parent.get('itemId') === null) {
            parent.get('children').removeObject(item);
          } else {
            parent.get('children').removeFragment(item);
          } // Update ref on object which should be edit after drop


          if (this.isDisplayMMConfig && this.mmConfigData.storeSegmentRef.get('itemId') === item.get('itemId')) {
            this.mmConfigData.storeSegmentRef = itemCopy;
          }
        }

        receiver.get('children').insertAt(Math.min(index, receiver.get('children.length')), itemCopy);

        this._updateParent(itemCopy, receiver);

        this.send('setChanges');
      },

      /**
       * Action makes menu item hidden
       *
       * @param {Object} data - menu item data
       * @returns {void}
       */
      toggleIsHidden(data) {
        this._toggleMenuItemHidden(data);

        this._toggleLinkedItems(data);

        this.send('setChanges');
      },

      /**
       * Action mark what any changes is occurred
       *
       * @param {string} [scope] - scope where change is occurred
       * @returns {void}
       */
      setChanges(scope) {
        switch (scope) {
          case 'page':
            {
              this.set('isPageStructureChanged', true);
              this.notifyAboutChanges();
              break;
            }

          case 'module':
            {
              this.set('isModulesConfigChanged', true);
              this.notifyAboutChanges();
              break;
            }

          case 'form-module':
            {
              this.set('isFormModuleChanged', true);
              break;
            }

          case 'modules-gallery':
            {
              this.notifyAboutChanges();
              break;
            }

          default:
            {
              this.notifyAboutChanges();
            }
        }
      },

      /**
       * Check form module changes and if it has notify about changes
       *
       * @returns {void}
       */
      deactivateFormModule() {
        if (this.isFormModuleChanged) {
          this.notifyAboutChanges();
        }
      },

      /**
       * Action toggle some menu items
       *
       * @param {string} type
       * @returns {void}
       */
      toggleMenu(type) {
        switch (type) {
          case 'system-modules':
          case 'module-gallery':
            {
              if (type === this.get('moduleController.routeDynamicSegment')) {
                this.transitionToRoute('website.edit.page.edit');
              } else {
                this.transitionToRoute('website.edit.page.edit.module-gallery', type);
              }

              break;
            }

          case 'styles':
            {
              this.transitionToRoute(this.stylesManagementIsOpened ? 'website.edit.page.edit' : 'website.edit.page.edit.styles');
              break;
            }

          case 'page-settings':
            {
              this.transitionToRoute(this.pageSettingsIsOpened ? 'website.edit.page.edit' : 'website.edit.page.edit.settings', this.pageModel);
              break;
            }

          case 'site-settings':
            {
              this.transitionToRoute(this.siteSettingsIsOpened ? 'website.edit.page.edit' : 'website.edit.page.edit.site-settings');
              break;
            }

          case 'market-segments':
            {
              this.transitionToRoute(this.marketSegmentsIsOpened ? 'website.edit.page.edit' : 'website.edit.page.edit.market-segments');
              break;
            }

          default:
            {
              this.toggleProperty(type);
            }
        }
      },

      /**
       * Updated publish deploy status
       * @param {string} status - Publish deploy status
       */
      updateDeploy(status) {
        if (status === 'failed') {
          this.set('model.website.IsPublished', '0');
        }
      },

      /**
       * Action for "Publish" button
       * Returned promise need for <default-button>
       *
       * @returns {Promise}
       */
      async publish() {
        try {
          this.set('isLoading', true);
          const publishCommand = {
            websiteId: this.model.website.id,
            website: this.model.website,
            page: this.pageModel.page,
            menu: this.model.menu,
            isPageStructureChanged: this.isPageStructureChanged,
            isFormModuleChanged: this.isFormModuleChanged,
            isModulesConfigChanged: this.isModulesConfigChanged,
            layout: this.pageEditController.model.layout,
            _publishLayouts: this._publishLayouts,
            setPublishLayout: publishLayouts => {
              this.set('_publishLayouts', publishLayouts);
            },
            modifiedSystemModules: this.modifiedSystemModules,
            setModifiedSystemModules: newModules => {
              this.set('modifiedSystemModules', newModules);
            },
            supportContactsMessage: this.supportContactsMessage,
            showNotification: (message, type, timeToShow) => {
              this.showNotification(message, type, timeToShow);
            },
            notifyAboutGlobalPageMenuInsertionError: this.notifyAboutGlobalPageMenuInsertionError.bind(this)
          };
          await this.batchOperationsService.save(publishCommand);
          await this.batchOperationsService.publish({
            websiteId: this.model.website.id
          });
          this.send('handleBubbleAction', _controllerAction.default.SAVE_COMPLETE);

          if (this.pageEditController) {
            this.pageEditController.send('closeWidgetPopup');
          }

          this.resetProps(); // Invalidate save button error because publish saves everything before publish

          this.set('hasSaveButtonError', false);
          const {
            page
          } = this.pageModel; // Since current page has been removed redirect user to another page

          if (page.get('isDeleted') || this._currentPageIs404) {
            page.unloadRecord();
            this.transitionToRoute('website.edit', this.get('configurationService.website.id'));
          }
        } catch (error) {
          // Set IsPublish to 0 because publish wasn't success and to unlock Publish button
          this.set('model.website.IsPublished', '0');
          this.showErrorMessage(error);
          throw error;
        } finally {
          this.set('isLoading', false);
        }
      },

      /**
       * Action for "Preview" button
       * Open new tab with current page
       *
       * @returns {void}
       */
      preview() {
        window.open(this._getPreviewUrl());
      },

      /**
       * Action for "Save" button
       * Returned promise need for <default-button>
       *
       * @returns {Promise}
       */
      async save() {
        try {
          this.set('isLoading', true);
          this.set('hasSaveButtonError', false);
          const saveCommand = {
            websiteId: this.model.website.id,
            website: this.model.website,
            page: this.pageModel.page,
            menu: this.model.menu,
            isPageStructureChanged: this.isPageStructureChanged,
            isFormModuleChanged: this.isFormModuleChanged,
            isModulesConfigChanged: this.isModulesConfigChanged,
            layout: this.pageEditController.model.layout,
            _publishLayouts: this._publishLayouts,
            setPublishLayout: publishLayouts => {
              this.set('_publishLayouts', publishLayouts);
            },
            modifiedSystemModules: this.modifiedSystemModules,
            setModifiedSystemModules: newModules => {
              this.set('modifiedSystemModules', newModules);
            },
            supportContactsMessage: this.supportContactsMessage,
            showNotification: (message, type, timeToShow) => {
              this.showNotification(message, type, timeToShow);
            },
            notifyAboutGlobalPageMenuInsertionError: this.notifyAboutGlobalPageMenuInsertionError.bind(this)
          };
          await this.batchOperationsService.save(saveCommand);

          if (this.pageEditController) {
            this.pageEditController.send('closeWidgetPopup');
          }

          this.resetProps();
          await this.model.website.reload();
          const {
            page
          } = this.pageModel; // Since current page has been removed redirect user to another page

          if (page.get('isDeleted') || this._currentPageIs404) {
            page.unloadRecord();
            this.transitionToRoute('website.edit', this.get('configurationService.website.id'));
          } else {
            this.send('handleBubbleAction', _controllerAction.default.SAVE_COMPLETE);
          }
        } catch (error) {
          this.set('configurationService.hasUnsavedChanges', true);
          this.set('hasSaveButtonError', true);
          this.showErrorMessage(error);
          throw error;
        } finally {
          this.set('isLoading', false);
        }
      },

      /**
       * Action for change page visibility status
       *
       * @param {Object} model
       * @returns {void}
       */
      togglePageVisibilityStatus(pageModel) {
        let menuItem;
        const model = pageModel || this.pageModel.page;
        menuItem = this.mainMenu.findItemByAlias(model.get('Alias'));

        if (!menuItem) {
          this._walkTree(this.unlinkedList, item => {
            const isFound = item.get('type') === _menuChild.default.PAGE && item.get('config').get('pageId') === model.get('id');

            if (isFound) {
              menuItem = item;
            }

            return isFound;
          });
        }

        if (menuItem) {
          this._toggleMenuItemHidden(menuItem);

          this._setPageVisibility(model, menuItem.get('isHidden'));
        }
      },

      /**
       * delete page action
       *
       * @returns {void}
       */
      delete() {
        const page = this.get('pageModel.page'); // Close more action dropdown

        this.set('moreActions', false); // Temp solution for rule "Home page cannot be removed"

        if (page.get('Alias') === _systemAliases.HOME_PAGE_ALIAS) {
          return;
        }

        if (window.confirm(this.literals.CURRENT_PAGE_REMOVE_CONFIRMATION)) {
          const pageId = page.get('id');
          this.findAndDeleteMenuItemByPageId(pageId);
          this.deletePage(pageId);
          this.pageEditController.closePageFeaturesPopups();
          this.showNotification(this.literals.CURRENT_PAGE_REMOVED_SUCCESS, _notification.default.TYPE.SUCCESS);
          this.send('setChanges');
        }
      },

      /**
       * copy page action
       *
       * @returns {void}
       */
      copy() {
        const inMainMenu = this.mainMenu.findPageByAlias(this.get('pageModel.page.Alias'));
        const {
          store
        } = this;

        if (this.isCopied || !this.hasNoChangesOrDoNotNeedToBeSaved()) {
          return;
        }

        this.set('isCopied', true);
        const page = store.createPage({
          ClonedFrom: this.get('pageModel.page.id'),
          Type: this.get('pageModel.page.Type'),
          MenuType: inMainMenu ? _menuRoot.default.MAIN : _menuRoot.default.UNLINKED
        });
        page.then(pageModel => {
          // Clean structure of menus for correct data merge after fetch data from server
          const promises = store.peekAll('menu').map(model => {
            model.clearStructure();
            return model.save({
              adapterOptions: {
                fakeRequest: true
              }
            });
          });
          return Ember.RSVP.all(promises).then(() => pageModel);
        }).then(pageModel => Ember.RSVP.all([// Reload module-style because backend implicitly create module-style models for cloned modules
        store.findAll('module-style', {
          reload: true
        }), store.findAll('menu', {
          reload: true
        }), store.findAll('page-seo', {
          reload: true
        }), store.findAll('page-meta', {
          reload: true
        }), // Reload forms, to update "UsedInPages" property
        // TODO: some thoughts, we can parse structure after page creation and update manually "UsedInPages"
        // but it's kinda not good?
        store.findAll('form', {
          reload: true
        })]).then(() => pageModel)).then(pageModel => {
          // Close more action dropdown
          this.set('moreActions', false);
          this.set('model.website.IsPublished', '0');
          this.showNotification(this.literals.CURRENT_PAGE_COPIED_SUCCESS, _notification.default.TYPE.SUCCESS); // Call route action for invalidate unlinked menu after transition to copied page route

          this.send('updateMenu');
          this.transitionToRoute('website.edit.page.edit', pageModel.get('id'));
        }, () => {
          page.then(pageModel => pageModel.unloadRecord());
          this.showNotification(this.literals.ALIAS_ALREADY_EXISTS, _notification.default.TYPE.ERROR);
        }).then(() => {
          this.set('isCopied', false);
        });
      },

      /**
       * create template action
       *
       * @returns {void}
       */
      createTemplate() {
        // Close more action dropdown
        this.set('moreActions', false);
        this.pageEditController.closePageFeaturesPopups();
        this.transitionToRoute('website.edit.page.edit.page-template');
      },

      /**
       * next menu change handler
       *
       * @returns {void}
       */
      onMenuMoreChange() {
        if (this.buttonMoreActionsDisabled) {
          this.set('moreActions', false);
        }
      },

      /**
       * modules outline toggle handler
       *
       * @returns {void}
       */
      toggleModulesOutline() {
        const {
          configurationService
        } = this;
        configurationService.setModulesOutlineMode(!configurationService.getModulesOutlineMode());
        this.set('moreActions', false);
      },

      /**
       * Action to call on 'more actions' button clicks
       * @returns {void}
       */
      onMoreActionsComplete() {
        this.set('moreActions', false);
      },

      /**
       * undo action
       *
       * @returns {void}
       */
      undo() {
        this.history.undo();
      },

      /**
       * redo action
       *
       * @returns {void}
       */
      redo() {
        this.history.redo();
      },

      /**
       * React on failed deploy
       * @param {Error} error - instance of error
       * @returns {void}
       */
      onPublishStatusFail(error) {
        let errorMessage = 'Please try to Publish again in a moment.';
        let timeToShow = 7000;

        if (error instanceof _formValidationError.default) {
          errorMessage = error.errors.join('<br>');
          timeToShow = Number.MAX_SAFE_INTEGER;
        }

        this.showNotification([errorMessage, this.supportContactsMessage].join('</br>'), _notification.default.TYPE.ERROR, timeToShow);
      }

    }
  });

  _exports.default = _default;
});