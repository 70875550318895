define("builder/core/styles-management/widget-tabs/variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WidgetTabsStyleVariables = void 0;
  // @ts-check

  /**
   * Default variable values for section styles functionality.
   * This variables values can be changed in UI.
   * @type {StyleVariables}
   */
  const WidgetTabsStyleVariables = {
    lg: [{
      name: 'font-size',
      value: '15px'
    }, {
      name: 'font-weight',
      value: 'normal'
    }, {
      name: 'letter-spacing',
      value: '0em'
    }, {
      name: 'text-decoration',
      value: 'none'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }, {
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'justify-content',
      value: 'center'
    }, {
      name: 'text-transform',
      value: 'none'
    }],
    md: [{
      name: 'font-size',
      value: '15px'
    }, {
      name: 'font-weight',
      value: 'normal'
    }, {
      name: 'letter-spacing',
      value: '0em'
    }, {
      name: 'text-decoration',
      value: 'none'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }, {
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'justify-content',
      value: 'center'
    }, {
      name: 'text-transform',
      value: 'none'
    }],
    sm: [{
      name: 'font-size',
      value: '15px'
    }, {
      name: 'font-weight',
      value: 'normal'
    }, {
      name: 'letter-spacing',
      value: '0em'
    }, {
      name: 'text-decoration',
      value: 'none'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }, {
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'justify-content',
      value: 'center'
    }, {
      name: 'text-transform',
      value: 'none'
    }],
    xs: [{
      name: 'font-size',
      value: '15px'
    }, {
      name: 'font-weight',
      value: 'normal'
    }, {
      name: 'letter-spacing',
      value: '0em'
    }, {
      name: 'text-decoration',
      value: 'none'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }, {
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'justify-content',
      value: 'center'
    }, {
      name: 'text-transform',
      value: 'none'
    }],
    global: [{
      name: 'color',
      value: ''
    }, {
      name: 'background-color',
      value: 'inherit'
    }]
  };
  _exports.WidgetTabsStyleVariables = WidgetTabsStyleVariables;
});