define("builder/pods/components/image-marker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wt7Q1+qY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"image-marker__marker\"],[11,\"title\",[22,\"title\"]],[11,\"style\",[22,\"position\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/image-marker/template.hbs"
    }
  });

  _exports.default = _default;
});