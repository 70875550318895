define("builder/helpers/can", ["exports", "ember-can/helpers/can"], function (_exports, _can) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlCanHelper
   */
  var _default = _can.default.extend({
    /**
     * Execute helper
     *
     * @param {string} abilityString - ability string that container, entity name + entity property
     * @param {Object} properties - other ability properties
     * @returns {AccessControlResult}
     */
    compute([abilityString, model], properties) {
      return this.can.can(abilityString, model, properties);
    }

  });

  _exports.default = _default;
});