define("builder/core/froala/links/link-hover-color-button", ["exports", "froala-editor", "builder/core/froala/links/link-hover-color-plugin", "builder/core/froala/icon-templates"], function (_exports, _froalaEditor, _linkHoverColorPlugin, _iconTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkHoverColorCommand = void 0;

  /* eslint-disable new-cap */
  const linkHoverColorCommand = 'link-hover-color';
  _exports.linkHoverColorCommand = linkHoverColorCommand;
  const iconTemplateName = 'hover_color';

  _froalaEditor.default.DefineIconTemplate(iconTemplateName, _iconTemplates.svgBgColor);

  _froalaEditor.default.DefineIcon(linkHoverColorCommand, {
    NAME: 'linkHoverColorIcon',
    template: iconTemplateName
  });

  _froalaEditor.default.RegisterCommand(linkHoverColorCommand, {
    title: 'Hover Color',
    undo: false,
    focus: false,
    refreshAfterCallback: false,
    plugin: _linkHoverColorPlugin.frCustomHoverColorPlugin,

    callback() {
      this[_linkHoverColorPlugin.frCustomHoverColorPlugin].showPopup(this.selection.element());
    }

  });
});