define("builder/pods/components/gallery-module/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VNQRsW8g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page_module\"],[11,\"style\",[22,\"thumbnailCss\"]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"page_modules_list_item-count \",[28,\"unless\",[[24,[\"hasThemes\"]],\"__hidden\"],null]]]],[8],[1,[22,\"themesCount\"],false],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"page_modules_list_item-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/gallery-module/template.hbs"
    }
  });

  _exports.default = _default;
});