define("builder/pods/components/menumanager-root/component", ["exports", "builder/pods/components/menumanager-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    tagName: 'ul',

    /**
     * @inheritdoc
     */
    classNames: ['menu-manager'],

    /**
     * @inheritdoc
     */
    data: null,

    /**
     * @inheritdoc
     */
    isEmptyClass: true,

    /**
     * @property {Function} removeMenuItem - remove menu item handler
     */
    removeMenuItem: null,

    /**
     * @property {Boolean} addToMenu - flag to indicate need add folder to menu list or unlinked list
     */
    addToMenu: false,

    /**
     * @property {Function} openMMConfig - open menu manager config handler
     */
    openMMConfig: null,

    /**
     * @inheritdoc
     */
    getDraggableElement() {
      return this.$();
    }

  });

  _exports.default = _default;
});