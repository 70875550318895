define("builder/core/styles-management/css-generator", ["exports", "dealerfire-builder2-cssgenerator", "builder/core/enumerators/breakpoint-modes"], function (_exports, _dealerfireBuilder2Cssgenerator, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getVariable = getVariable;
  _exports.getValue = getValue;
  _exports.default = void 0;
  const {
    CSSStyleSheet,
    Builder2Context,
    Context,
    CSSVariable,
    isReferenceValue,
    getMeta,
    valueToVariableName
  } = _dealerfireBuilder2Cssgenerator.default;
  const CONTEXTS = {
    GLOBAL: _breakpointModes.GLOBAL,
    LARGE: _breakpointModes.LARGE,
    MEDIUM: _breakpointModes.MEDIUM,
    SMALL: _breakpointModes.SMALL,
    EXTRA_SMALL: _breakpointModes.EXTRA_SMALL
  };
  /**
   * Get CSSVariable from passed in cache or create new one
   *
   * @param {String} name - variable name
   * @param {Object} cache - cache that possibly contains variable
   * @returns {CSSVariable}
   */

  function getVariable(name, cache) {
    let variable;

    if (cache.hasOwnProperty(name)) {
      variable = cache[name]; // Yep, it's lil bit slow. But not too much, ~4% for V8 vs setting value to null

      delete cache[name];
    } else {
      variable = new CSSVariable(name);
    }

    return variable;
  }
  /**
   * Get resolved value
   *
   * @param {String} value - original value
   * @param {Builder2Context} context - context to search for variable is needed
   * @param {Object} cache - cache where variable will be added if it's value is reference to variable
   * that is not initialized yet
   * @returns {String|CSSVariable}
   */


  function getValue(value, context, cache) {
    let resolvedValue = value;

    if (value && isReferenceValue(value)) {
      const {
        name,
        context: variableContext
      } = getMeta(valueToVariableName(value));
      resolvedValue = context.getVariable(name, variableContext);

      if (!resolvedValue) {
        resolvedValue = new CSSVariable(name);
        cache[variableContext][name] = resolvedValue;
      }
    }

    return resolvedValue;
  }
  /**
   * @class CSSGenerator
   */


  var _default = Ember.Object.extend({
    /**
     * @property {CSSGenerator} cssGenerator - "original" css generator
     */
    cssGenerator: null,

    /**
     * @property {String} mode - mode for which css should be generated
     */
    mode: null,

    /**
     * @property {DeviceService} device - device service
     */
    device: null,

    /**
     * @property {String} imageCdnBaseUrl - base image cdn url
     */
    imageCdnBaseUrl: null,

    /**
     * Generate css, based on config
     *
     * TODO: Each device must implement user agent and passed in here
     *
     * @param {Object} config - config used for css generation, for more info look "original" css generator
     * @returns {String} - generated css
     */
    generateCSS(config) {
      let {
        userAgent
      } = this;
      const {
        stylesheet
      } = this.generateStyles(config.map, config.dependencyVariables, config.variables);
      const breakpoints = this.get('device.breakpoints');
      let plain = true; // While stylesheet generated every time css generation executed, it's ok to not reset previous values

      if (config.prefixedClass) {
        stylesheet.scoped = true;
        stylesheet.parentClass = config.prefixedClass;
      }

      stylesheet.domain = this.imageCdnBaseUrl;
      stylesheet.ownerId = config.moduleId ? config.moduleId : '';

      if (config.mode) {
        [userAgent.width] = breakpoints[config.mode];
      } else {
        // If mode not passed - css needs to be built for E6, hence all media rules must not be unfolded
        plain = false;
        userAgent = null;
      }

      return stylesheet.getCSSText(userAgent, plain);
    },

    /**
     * Generate global styles map
     *
     * @param {Object[]} map - styles map
     * @param {StyleMap} parentVariables - StyleMap that contains top level variables(global/website wide)
     * @param {StyleMap} variables - StyleMap that contains bottom level variables(local/module)
     * @returns {{stylesheet: CSSStyleSheet, context: Builder2Context}}
     */
    generateStyles(map, parentVariables, variables) {
      const executor = this.cssGenerator;
      const stylesheet = new CSSStyleSheet();

      const parentB2Context = this._buildContext();

      const b2Context = this._buildContext(parentB2Context);

      if (parentVariables) {
        this._stuff(parentB2Context, parentVariables);
      }

      if (variables) {
        this._stuff(b2Context, variables);
      }

      executor.execute(map, b2Context, stylesheet);
      return {
        stylesheet,
        context: b2Context
      };
    },

    /**
     * Build builder2 context
     *
     * @param {Builder2Context|null} parentB2Context - parent builder2 context
     * @returns {Builder2Context} - built Builder2Context
     * @private
     */
    _buildContext(parentB2Context = null) {
      const b2Context = new Builder2Context(parentB2Context);
      const globalContext = new Context(null, CONTEXTS.GLOBAL);
      const contexts = {
        global: globalContext,
        lg: new Context(globalContext, CONTEXTS.LARGE),
        md: new Context(globalContext, CONTEXTS.MEDIUM),
        sm: new Context(globalContext, CONTEXTS.SMALL),
        xs: new Context(globalContext, CONTEXTS.EXTRA_SMALL)
      };
      b2Context.addContexts([globalContext, contexts.lg, contexts.md, contexts.sm, contexts.xs]);
      return b2Context;
    },

    /**
     * Fill in builder2 context with variables from StyleModel
     *
     * @param {Builder2Context} context - context to fill in
     * @param {StyleModel} styleModel - style model that contains variables
     * @private
     */
    _stuff(context, styleModel) {
      const cache = {};
      Object.keys(CONTEXTS).forEach(key => {
        cache[CONTEXTS[key]] = {};
      });
      Object.keys(CONTEXTS).forEach(key => {
        const breakpoint = CONTEXTS[key];
        styleModel.get(breakpoint).forEach(definition => {
          const name = definition.get('name');
          const value = definition.get('value');

          if (typeof name !== 'undefined' && typeof value !== 'undefined') {
            const variable = getVariable(name, cache[breakpoint]);
            variable.value = getValue(value, context, cache);
            context.addVariable(variable, breakpoint);
          }
        });
      });
      Object.keys(cache).forEach(contextName => {
        if (Object.keys(cache[contextName]).length) {
          throw new Error(`[Context] Some variables are not resolved "${Object.keys(cache[contextName]).join()}"`);
        }
      });
    }

  });

  _exports.default = _default;
});