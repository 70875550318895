define("builder/pods/components/field-info-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRLJgOKK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"label\",true],[10,\"class\",\"field-info-block__label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"field-info-block__content\"],[8],[1,[28,\"html-format\",[[24,[\"field\",\"content\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-info-block/template.hbs"
    }
  });

  _exports.default = _default;
});