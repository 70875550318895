define("builder/pods/components/form-module/layout-updater-module", ["exports", "builder/pods/components/form-module/module-base", "ember-copy", "builder/core/enumerators/form", "builder/core/enumerators/buttons"], function (_exports, _moduleBase, _emberCopy, _form, _buttons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Module responsible for form item nodes update
   */
  var _default = _moduleBase.default.extend({
    /**
     * @property {String} theme - current form theme
     */
    theme: null,

    /**
     * @property {Object} parser - form parser, used to find form item nodes
     */
    parser: null,

    /**
     * @property {String} websiteId - contain current website id
     */
    websiteId: null,

    /**
     * @property {Ember.Service} previewService - form preview service
     */
    formPreviewService: null,

    /**
     * @property {String} errorMessageTemplate - error message node to be used to display an error
     */
    errorMessageTemplate: '<div class="page_module_error __visible">Sorry, error occurred</div>',

    /**
     * Update form item layout
     *
     * @param {Object} item - item to update layout
     * @param {jQuery} element - jQuery element
     */
    updateLayout(item, element) {
      const {
        parser
      } = this;
      parser.setNode(element); // Since every field/fields wrapped into "form" element, we must extract required field/fields

      item.setElement(parser.getNextNode(item.get('data.type')) || element);

      if (item.get('data.type') === _form.MULTISTEP) {
        const steps = item.get('children');
        const activeStep = steps.findBy('data.isActive');
        const activeStepIndex = activeStep ? steps.indexOf(activeStep) : 0;
        const parserStep = element[1].children[1].children[activeStepIndex];

        if (parserStep) {
          parser.setNode(parserStep.childNodes);

          this._updateChildrenNodeReferences(steps.get(activeStepIndex).get('children'), parser);
        }
      } else {
        // Update children references
        this._updateChildrenNodeReferences(item.get('children'), parser);
      }
    },

    /**
     * Update children references to node
     *
     * @param {Object[]} children - form-item instances
     * @param {Object} parser - parser, that will help find respective nodes
     * @private
     */
    _updateChildrenNodeReferences(children = [], parser = null) {
      for (let i = 0, len = children.length; i < len; i++) {
        const subChildren = children[i].get('children');

        children[i]._updateElement(parser.getNextNode(children[i].get('data.type')));

        children[i].set('loaded', true);

        if (subChildren && subChildren.length) {
          this._updateChildrenNodeReferences(subChildren, parser);
        }
      }
    },

    /**
     * Get form item structure required for form-builder
     *
     * @param {Object} formItem - form item to render
     * @returns {Object} - form item structure
     */
    getStructure(formItem) {
      let structure = (0, _emberCopy.copy)(formItem.get('data'), true);
      const children = formItem.get('children') || [];
      const {
        type
      } = structure; // Here we filter out form, since it doesn't have wrapper, not needed when steps will come?
      // Form is kinda phantom element with no layout

      if (_form.STEP === type || type === 'form') {
        structure = this.getChildrenStructure(children); // Button submit must not have children key
      } else if (type !== _buttons.FORM_SUBMIT && type !== _buttons.FORM_NEXT && type !== _buttons.FORM_PREV) {
        structure.children = this.getChildrenStructure(children);
      }

      return structure;
    },

    /**
     * Get children structure, recursively
     *
     * @param {Object[]} children - array of form item children
     * @returns {Object[]} - children structure
     */
    getChildrenStructure(children) {
      const childrenStructure = [];

      for (let i = 0, len = children.length; i < len; i++) {
        const child = (0, _emberCopy.copy)(children[i].get('data'), true);
        const innerChildren = children[i].get('children');

        if (innerChildren && innerChildren.length) {
          child.children = this.getChildrenStructure(innerChildren);
        }

        childrenStructure.push(child);
      }

      return childrenStructure;
    }

  });

  _exports.default = _default;
});