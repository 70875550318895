define("builder/pods/components/field-textarea-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6QW7muHH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-editable\",null,[[\"onRender\",\"onFocus\",\"onBlur\",\"onKeyUp\",\"onChange\",\"class\",\"content\",\"maxlength\"],[[28,\"action\",[[23,0,[]],\"onRender\"],null],[28,\"action\",[[23,0,[]],\"onFocus\"],null],[28,\"action\",[[23,0,[]],\"onBlur\"],null],[28,\"action\",[[23,0,[]],\"onKeyUp\"],null],[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"textareaClass\"]],[24,[\"content\"]],[24,[\"textareaMaxlength\"]]]]],false],[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"field-textarea-content__title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"field-textarea-content__line\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"field-textarea-content__cover\"],[11,\"style\",[22,\"height\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-textarea-content/template.hbs"
    }
  });

  _exports.default = _default;
});