define("builder/pods/components/field-datetime/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "builder/helpers/datetime-format"], function (_exports, _fieldValidation, _fieldVisibility, _datetimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldDateTimeComponent
   */
  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_date'],

    /**
     * @property {Function|null} actionHandler - handler to be invoked when date value changes(datepicker closes date picker)
     */
    actionHandler: null,

    /**
     * @property {Boolean} datepickerVisible - control date picker show/hide state, actually will affect only show state,
     * since datepicker will reset this property to false internally
     */
    datePickerVisible: false,

    /**
     * @property {String} value - date string value
     */
    value: Ember.computed('dateTime', function () {
      return (0, _datetimeFormat.formatDatetime)([this.dateTime]);
    }),

    /**
     * @property {Date|null} dateTime - actual date time value
     * @private
     */
    dateTime: null,

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      this.set('dateTime', this.parseUTCDate([this.field.data]));
    },

    /**
     * @inheritdoc
     */
    willInsertElement(...args) {
      this._super(...args);

      this.applyValidations();
    },

    /**
     * @param value
     * @returns {null|Date}
     */
    parseUTCDate(value) {
      const date = value[0];

      if (!date || isNaN(date) && typeof date !== 'object') {
        return null;
      }

      if (typeof date !== 'object') {
        return new Date(Number(date));
      }

      const parseData = new Date(Number(date.value));
      return new Date(Date.parse(parseData.toLocaleString('en-US', {
        timeZone: date.zone
      })));
    },

    actions: {
      /**
       * @param {string} formattedDate
       * @param {string}  dateString in YYYY-MM-DD format
       * @param {Date| null} dateObject
       */
      onUpdate(formattedDate, dateString, dateObject) {
        let utcValue = '';

        if (formattedDate) {
          if (this.field.saveTimeZone) {
            utcValue = {
              value: dateObject.getTime(),
              zone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };
          } else {
            utcValue = dateObject.getTime();
          }

          this.set('dateTime', dateObject);
          this.set('field.data', utcValue);
          this.actionHandler(utcValue, this.field.name);
        }

        this.set('datePickerVisible', false);
      },

      onClear() {
        this.set('dateTime', null);
        this.set('field.data', null);
        this.actionHandler(null, this.field.name);
        this.set('datePickerVisible', false);
      }

    }
  });

  _exports.default = _default;
});