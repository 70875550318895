define("builder/pods/components/grid-base-component/component", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base component for all grid component
   */
  var _default = Ember.Component.extend({
    /**
     * Observer which will handles all element dimensions change.
     * Observer is required to handling device change
     * @type {ResizeObserver}
     */
    resizeObserver: null,

    /**
     * Container width.
     * This property is required for changing buttons appearance
     * @type {number}
     */
    containerWidth: 0,

    /**
     * Container height.
     * This property is required for changing youtube-background appearance
     * @type {number}
     */
    containerHeight: 0,

    /**
     * @inheritDoc
     */
    classNameBindings: ['data.isActive:__active', 'resizeHandlerIsVisible:__show-handler'],

    /**
     * Selector on node that represents resizable line
     * @type {string}
     */
    resizeNodeSelector: '.resizable-handler',

    /**
     * Flag to show and hide resize handler
     * @type {boolean}
     */
    resizeHandlerIsVisible: false,

    /**
     * Device service
     * @type {DeviceService}
     */
    device: Ember.inject.service('device'),

    /**
     * @type {CssCustomPropertiesService}
     */
    configurationStyles: Ember.inject.service('css-custom-properties'),

    /**
     * Service for global visibility state modification
     * @type {RenderingGlobalVisibilityService}
     */
    globalVisibilityService: Ember.inject.service('rendering-global-visibility'),

    /**
     * Bootstrap mode
     * @type {string}
     */
    mode: null,

    /**
     * Inline component styles(generate only "margin-bottom")
     * @type {string|null}
     * @readonly
     * @TODO Probably css value should be received through "CssGenerator" class, but now it's impossible.
     */
    styles: Ember.computed('data.originObject.styles.{lg,md,sm,xs,global}.@each.value', 'mode', function () {
      var _this$data, _this$data$originObje;

      const originStyles = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : _this$data$originObje.styles;
      return this.configurationStyles.mapInlineStyles(originStyles);
    }).readOnly(),

    /**
     * Current component data
     * @type {import('ember').Ember.Object}
     */
    data: null,

    /**
     * Function to invoked to resize column(horizontal resize)
     * @type {()=>void}
     */
    resizeColumn() {},

    /**
     * Determines whether module is locked by header or footer
     * @type {boolean}
     */
    isLocked: false,

    /**
     * Flag to determine whether device listener is added
     * @property {boolean}
     * @private
     */
    _isListenerAdded: false,

    /**
     * Defines visibility for lg breakpoint
     * @type {boolean}
     */
    visibleOnLg: Ember.computed(`data.originObject.visibleOnDevices.${_breakpointModes.LARGE}`, `globalVisibilityService.globalVisibility.${_breakpointModes.LARGE}`, function () {
      var _this$data2, _this$data2$originObj;

      const visibleOnDevices = (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : _this$data2$originObj.visibleOnDevices; // If data does not contain this object, no need to hide it

      if (!visibleOnDevices) {
        return true;
      }

      return visibleOnDevices[_breakpointModes.LARGE] || this.globalVisibilityService.globalVisibility[_breakpointModes.LARGE];
    }).readOnly(),

    /**
     * Defines visibility for md breakpoint
     * @type {boolean}
     */
    visibleOnMd: Ember.computed(`data.originObject.visibleOnDevices.${_breakpointModes.MEDIUM}`, `globalVisibilityService.globalVisibility.${_breakpointModes.MEDIUM}`, function () {
      var _this$data3, _this$data3$originObj;

      const visibleOnDevices = (_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : (_this$data3$originObj = _this$data3.originObject) === null || _this$data3$originObj === void 0 ? void 0 : _this$data3$originObj.visibleOnDevices; // If data does not contain visibleOnDevices, no need to hide it

      if (!visibleOnDevices) {
        return true;
      }

      return visibleOnDevices[_breakpointModes.MEDIUM] || this.globalVisibilityService.globalVisibility[_breakpointModes.MEDIUM];
    }).readOnly(),

    /**
     * Defines visibility for sm breakpoint
     * @type {boolean}
     */
    visibleOnSm: Ember.computed(`data.originObject.visibleOnDevices.${_breakpointModes.SMALL}`, `globalVisibilityService.globalVisibility.${_breakpointModes.SMALL}`, function () {
      var _this$data4, _this$data4$originObj;

      const visibleOnDevices = (_this$data4 = this.data) === null || _this$data4 === void 0 ? void 0 : (_this$data4$originObj = _this$data4.originObject) === null || _this$data4$originObj === void 0 ? void 0 : _this$data4$originObj.visibleOnDevices; // If data does not contain visibleOnDevices, no need to hide it

      if (!visibleOnDevices) {
        return true;
      }

      return visibleOnDevices[_breakpointModes.SMALL] || this.globalVisibilityService.globalVisibility[_breakpointModes.SMALL];
    }).readOnly(),

    /**
     * Defines visibility for sm breakpoint
     * @type {boolean}
     */
    visibleOnXs: Ember.computed(`data.originObject.visibleOnDevices.${_breakpointModes.EXTRA_SMALL}`, `globalVisibilityService.globalVisibility.${_breakpointModes.EXTRA_SMALL}`, function () {
      var _this$data5, _this$data5$originObj;

      const visibleOnDevices = (_this$data5 = this.data) === null || _this$data5 === void 0 ? void 0 : (_this$data5$originObj = _this$data5.originObject) === null || _this$data5$originObj === void 0 ? void 0 : _this$data5$originObj.visibleOnDevices; // If data does not contain visibleOnDevices, no need to hide it

      if (!visibleOnDevices) {
        return true;
      }

      return visibleOnDevices[_breakpointModes.EXTRA_SMALL] || this.globalVisibilityService.globalVisibility[_breakpointModes.EXTRA_SMALL];
    }).readOnly(),

    /**
     * Determines should overlay be visible or not
     * @type {boolean}
     */
    isOverlayVisible: Ember.computed('device._device', `data.originObject.visibleOnDevices.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}`, `globalVisibilityService.globalVisibility.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}`, function () {
      var _this$data6, _this$data6$originObj;

      const device = this.device.getDevice();

      if (!device) {
        return false;
      }

      const {
        breakpoint
      } = device;
      const visibleOnDevices = (_this$data6 = this.data) === null || _this$data6 === void 0 ? void 0 : (_this$data6$originObj = _this$data6.originObject) === null || _this$data6$originObj === void 0 ? void 0 : _this$data6$originObj.visibleOnDevices;

      if (!visibleOnDevices) {
        return false;
      }

      return !visibleOnDevices[breakpoint] && this.globalVisibilityService.globalVisibility[breakpoint];
    }).readOnly(),

    /**
     * Disable/enable vertical resize
     * @type {boolean}
     */
    enableVResize: Ember.computed('isLocked', 'data.vResizable', function () {
      var _this$data7;

      return !this.isLocked && ((_this$data7 = this.data) === null || _this$data7 === void 0 ? void 0 : _this$data7.vResizable);
    }),

    /**
     * Disable/enable horizontal resize
     * @type {boolean}
     */
    enableHResize: Ember.computed('isLocked', 'data.resizable', function () {
      var _this$data8;

      return !this.isLocked && ((_this$data8 = this.data) === null || _this$data8 === void 0 ? void 0 : _this$data8.resizable);
    }),

    /**
     * @inheritDoc
     */
    init(...args) {
      var _this$data9, _this$data9$originObj;

      this._super(...args);

      if (!this.mode && ((_this$data9 = this.data) === null || _this$data9 === void 0 ? void 0 : (_this$data9$originObj = _this$data9.originObject) === null || _this$data9$originObj === void 0 ? void 0 : _this$data9$originObj.styles)) {
        this.set('mode', this.device.getDevice().breakpoint);
        this.device.on('onChange', this, this.onDeviceChange);
        this.set('_isListenerAdded', true);
      }
    },

    /**
     * @inheritDoc
     */
    didRender(...args) {
      this._super(...args);

      this.onFirstRender(this);
    },

    /**
     * Initializes resize observer for buttons change on element width change
     * @returns {void}
     */
    initializeResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        // using requestAnimationFrame method to avoid the error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          const [currentElement] = entries;
          const {
            contentRect
          } = currentElement;

          if (this.isDestroyed) {
            return;
          }

          this.set('containerWidth', contentRect.width);
          this.set('containerHeight', contentRect.height);
        });
      });
      this.resizeObserver.observe(this.element);
    },

    /**
     * @inheritDoc
     */
    willDestroy() {
      if (this._isListenerAdded) {
        this.device.off('onChange', this, this.onDeviceChange);
        this.set('_isListenerAdded', false);
      }

      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },

    /**
     * Callback for device service "onChange" event which provide
     * mode(lg, md and etc) for component
     * @returns {void}
     */
    onDeviceChange() {
      this.set('mode', this.device.getDevice().breakpoint);
    },

    /**
     * Action to be invoked when rendered 1-st time
     * @TODO Haha, seriously first time!? It's a lie ^
     * @returns {void}
     * @abstract
     */
    onFirstRender() {
      throw new Ember.Error('onFirstRender method must be overridden');
    },

    /**
     * Action to be invoked when draggable toggled
     * @returns {void}
     * @abstract
     */
    toggleDraggable() {
      throw new Ember.Error('toggleDraggable method must be overridden');
    },

    /**
     * Action to be invoked to force dimensions update
     * @returns {void}
     * @abstract
     */
    updateAllDimensions() {
      throw new Ember.Error('toggleDraggable method must be overridden');
    },

    /**
     * @inheritDoc
     */
    mouseOver() {
      return false;
    },

    /**
     * @inheritDoc
     */
    click() {
      return false;
    },

    /**
     * @inheritDoc
     */
    doubleClick() {
      return false;
    },

    /**
     * Shows resize handler
     * @returns {void}
     */
    showResizeHandler() {
      this.set('resizeHandlerIsVisible', true);
    },

    /**
     * Hides resize handler
     * @returns {void}
     */
    hideResizeHandler() {
      this.set('resizeHandlerIsVisible', false);
    },

    /**
     * Get resizable line node
     * @returns {jQuery}
     */
    getResizableNode() {
      var _this$$;

      return (_this$$ = this.$()) === null || _this$$ === void 0 ? void 0 : _this$$.find(` > ${this.resizeNodeSelector}`);
    }

  });

  _exports.default = _default;
});