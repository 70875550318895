define("builder/pods/components/field-search-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jlw5VOH1",
    "block": "{\"symbols\":[\"manager\",\"option\"],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"widget-field-info\",[],[[\"@field\",\"@validation\"],[[22,\"field\"],[22,\"validationsMessage\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"dropdown-container\",[[12,\"class\",\"s-dropdown s-dropdown_theme_default\"]],[[\"@isExpanded\"],[[22,\"isExpanded\"]]],{\"statements\":[[0,\"\\n\\t\"],[5,\"dropdown-button\",[],[[\"@manager\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_search-dropdown_selected-value\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"selectedOptionLabel\"],false],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"fa fa-caret-down\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[5,\"dropdown-dropable\",[],[[\"@manager\",\"@class\"],[[23,1,[]],\"s-dropdown__dropable\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"input-search\",[[12,\"class\",\"default-theme\"]],[[\"@focused\",\"@onKeyPress\",\"@onClear\"],[[22,\"isExpanded\"],[28,\"action\",[[23,0,[]],\"search\"],null],[28,\"action\",[[23,0,[]],\"showFullList\"],null]]]],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_search-dropdown_options-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"choices\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"bld-form_search-dropdown_option \",[28,\"unless\",[[23,2,[\"visible\"]],\"bld-form_search-dropdown_option-hidden\"],null]]]],[12,\"title\",[23,2,[\"label\"]]],[3,\"action\",[[23,0,[]],\"selectOption\",[23,2,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,2,[\"label\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-search-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});