define("builder/pods/components/sm-select-icon/component", ["exports", "builder/pods/components/sm-select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Component help build select for icons management UI.
   */
  let SMSelectFontsComponent = (_dec = Ember.inject.service('font-icon'), _dec2 = Ember.computed('variable.value'), _dec3 = Ember._action, (_class = (_temp = class SMSelectFontsComponent extends _component.SMSelectComponent {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "iconsService", _descriptor, this);
    }

    /**
     * @inheritdoc
     */
    get defaultOptions() {
      return this.iconsService.icons.map(icon => ({
        name: icon.Title,
        value: icon.Name
      }));
    }
    /**
     * Action select new option.
     *
     * @param {SMSelectOption} option - option which select user
     * @returns {void}
     */


    select(option) {
      if (this.selectedOption.value !== option.value) {
        this.send('update', option.value);
      } // Force close


      this.set('isOpened', false);
      this.iconsService.applyIconFont(option.value.toString());
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "iconsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "defaultOptions", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "defaultOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = SMSelectFontsComponent;
});