define("builder/core/drop-highlighter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Helper responsible for highlighting element's sides and dimming
   */
  var _default = Ember.Helper.extend({
    /**
     * List of available classes
     * @type {
     * {
     * 	all: string[];
     * 	top: string[];
     * 	right: string[];
     * 	bottom: string[];
     * 	left: string[]
     * }
     * }
     */
    classes: {
      all: ['border-top', 'border-right', 'border-bottom', 'border-left'],
      top: ['border-top'],
      right: ['border-right'],
      bottom: ['border-bottom'],
      left: ['border-left']
    },

    /**
     * Last highlighted element
     * @type {Element}
     */
    lastElement: null,

    /**
     * Last highligh class group used
     * @type {string}
     */
    lastClass: null,

    /**
     * Highlight element
     *
     * @param {Element} element - element to highlight
     * @param {string} side - element side to highlight
     */
    highlight(element, side) {
      const classes = this.classes[side];
      const {
        lastElement
      } = this;

      if (!element) {
        throw new Ember.Error('Element required');
      }

      if (!classes) {
        throw new Ember.Error(`Side "${side}" is not defined, should be "top", "right", "bottom", "left" or "all"`);
      }

      if (lastElement) {
        // Remove border classes from last highlighted element
        // If element to highlight is the same element as current element to highlight
        // Do not remove border classes to be added
        this.dim(lastElement, this.lastClass, lastElement === element ? side : null);
      } // Keep reference to last highlighted element, to dim it before highlighting next


      if (element !== lastElement) {
        this.set('lastElement', element);
      }

      this.addClasses(element, classes);
      this.set('lastClass', side);
    },

    /**
     * Public dim method, dim last highlighted element
     */
    dimElement() {
      const element = this.lastElement;

      if (element) {
        this.dim(element, this.lastClass);
      }
    },

    /**
     * Remove highlight classes from element, classes passed as "except" will not be removed
     *
     * @param {Element} element - element from which classes will be removed
     * @param {string} clazz - border class group to be removed
     * @param {string} except - border class group, that will not be removed
     */
    dim(element, clazz, except = null) {
      let classes = this.classes[clazz]; // Filter out classes that shouldn't be removed

      if (except) {
        const exceptClasses = this.classes[except] || [];
        classes = classes.filter(className => {
          if (exceptClasses.indexOf(className) === -1) {
            return true;
          }

          return false;
        });
      }

      this.removeClasses(element, classes);
    },

    /**
     * Add classes to an element
     *
     * @param {Element} element - element to which classes will be added
     * @param {string[]} classes - list of classes to add
     */
    addClasses(element, classes) {
      // Following syntax - element.classList.add(...classes);
      // Cannot be used, not works in IE
      for (let i = 0; i < classes.length; i++) {
        element.classList.add(classes[i]);
      }
    },

    /**
     * Remove classes from an element
     *
     * @param {Element} element - element which classes will be removed
     * @param {string[]} classes - list of classes to remove
     */
    removeClasses(element, classes) {
      // Following syntax - element.classList.remove(...classes);
      // Cannot be used, not works in IE
      for (let i = 0; i < classes.length; i++) {
        element.classList.remove(classes[i]);
      }
    }

  });

  _exports.default = _default;
});