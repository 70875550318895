define("builder/pods/components/field-textarea/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * default rows number used for initial row sizing
     * @property
     * @private
     */
    _DEFAULT_ROWS_NUMBER: 6,

    /**
     * Element class name.
     * @property
     */
    classNames: ['bld-form_textarea'],

    /**
     * Before insert callback
     * @method willInsertElement
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * @property {String} rows - Property rows
     * @readonly
     */
    rows: Ember.computed('field.rows', function () {
      return this.field.rows || this._DEFAULT_ROWS_NUMBER;
    }).readOnly(),
    actions: {
      change(event) {
        const action = this.actionHandler;

        if (action && !this.notValid) {
          action(event.target.value, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});