define("builder/core/factories/module-gallery/widget-decorator", ["exports", "builder/core/factories/module-gallery/gallery-module", "builder/core/enumerators/widget", "builder/core/enumerators/breakpoint-modes", "builder/core/literals"], function (_exports, _galleryModule, _widget, _breakpointModes, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const insertData = {
    [_widget.WidgetType.WIDGET_BUTTON]() {
      return {
        title: 'Button',
        url: {
          menuItemId: null,
          value: '',
          type: 'internal',
          target: 'self'
        },
        style: 'primary'
      };
    },

    [_widget.WidgetType.WIDGET_BUTTONSGROUP]() {
      return {
        group: false,
        buttons: [{
          type: 'widget-button',
          title: 'Button',
          style: 'primary',
          url: {
            menuItemId: null,
            type: 'internal',
            target: 'self',
            value: ''
          }
        }, {
          type: 'widget-button',
          title: 'Button',
          style: 'primary',
          url: {
            menuItemId: null,
            type: 'internal',
            target: 'self',
            value: ''
          }
        }]
      };
    },

    [_widget.WidgetType.WIDGET_SPACER]() {
      return {
        visibleOnDevices: {
          [_breakpointModes.LARGE]: true,
          [_breakpointModes.MEDIUM]: true,
          [_breakpointModes.SMALL]: true,
          [_breakpointModes.EXTRA_SMALL]: false
        }
      };
    }

  };
  /**
   * @class WidgetGalleryModuleDecorator
   */

  var _default = _galleryModule.default.extend({
    /**
     * @inheritdoc
     */
    componentName: 'gallery-widget',

    /**
     * @inheritdoc
     */
    type: Ember.computed.readOnly('data.Name'),

    /**
     * @inheritdoc
     */
    title: Ember.computed.readOnly('data.Label'),

    /**
     * @inheritdoc
     */
    image: Ember.computed.readOnly('data.Image'),

    /**
     * @inheritdoc
     */
    label: Ember.computed.readOnly('data.Label'),

    /**
     * Current page model
     * @type {PageModel|null}
     */
    currentPageModel: null,

    /**
     * @property {AccessControlService} accessControl - access control service
     */
    accessControl: null,

    /**
     * Result of check usage ability
     * @type {{isGranted:boolean, message:string}}
     * @readonly
     */
    usageAbility: Ember.computed('currentPageModel.isGlobal', function () {
      const aclAbility = this.accessControl.can('create module-gallery', null, {
        name: this.title.toLowerCase()
      });
      const globalPageUsageAbility = this.currentPageModel.isGlobal ? {
        isGranted: this.data.isGlobalPageCompatible,
        message: _literals.default.GLOBAL_PAGE_USAGE_RESTRICTION
      } : {
        isGranted: true
      };

      if (!aclAbility.isGranted) {
        return aclAbility;
      }

      return globalPageUsageAbility;
    }).readOnly(),

    /**
     * Is enabled
     * @type {boolean}
     * @readonly
     */
    enabled: Ember.computed.readOnly('usageAbility.isGranted'),

    /**
     * Error message that indicates the reason is not enabled
     * @type {string}
     * @readonly
     */
    errorMessage: Ember.computed('usageAbility.isGranted', function () {
      return this.usageAbility.isGranted ? '' : this.usageAbility.message;
    }).readOnly(),

    /**
     * @inheritdoc
     */
    getInsertData() {
      const name = this.data.Name;
      let data = {};

      if (insertData.hasOwnProperty(name)) {
        data = insertData[name]();
      }

      return data;
    }

  });

  _exports.default = _default;
});