define("builder/services/resize-grid-module", ["exports", "builder/core/enumerators/marginable", "jquery"], function (_exports, _marginable, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ResizeGridModuleService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {String} _postfix - Postfix for event name
     * @private
     */
    _postfix: '.vResize',

    /**
     * @property {Object|null} resizableModule - Module is being resized now
     */
    resizableModule: null,

    /**
     * @property {Number|null} initialMargin - Initial margin of resized module
     */
    initialMargin: null,

    /**
     * @property {null|Number} initialPosition - initial position
     */
    initialPosition: null,

    /**
     * @property {Ember.Service} dragAndDropService - Drag and drop service
     */
    dragAndDropService: Ember.inject.service('drag-and-drop'),

    /**
     * @property {Ember.Service} dragService - Drag service
     */
    dragService: Ember.inject.service('drag'),

    /**
     * @property {Device} device
     */
    device: Ember.inject.service('device'),

    /**
     * @property {Function[]} afterResize - collection afterResize callbacks
     */
    afterResize: [],

    /**
     * @property {Number} step - resize step
     */
    step: 5,

    /**
     * @property {Number} minMargin - minimum available margin
     */
    minMargin: 0,

    /**
     * @property {Number} max - maximum available margin
     */
    max: 600,

    /**
     * @property {Boolean} resizeStarted - detect that mouse move event started
     */
    resizeStarted: false,

    /**
     * @property {String} _cursorSelector - resize cursor selector
     * @private
     */
    _cursorSelector: '__row-resize',

    /**
     * @property {Object} _marginStyleVariable - contain variable for 'margin-bottom'
     * @property {String} _marginStyleVariable.name - variable name
     * @property {String} _marginStyleVariable.value - variable value
     */
    _marginStyleVariable: null,

    /**
     * Hook that will be invoked by resize service, when resize just started
     *
     * @param {Ember.Object} component - component data representation of component to resize
     * @param {Number} pageX - event.pageX
     * @param {Number} pageY - event.pageY
     * @param {String} resizeProperty - property to set value to during resize(like margin or height)
     * @param {Number} resizeStep - step of resize value (like 1, 2, 3)
     * @param {Number} resizeMax - max allowed value to be set to resizeProperty(if 0 passed, no max will be applied)
     * @throws Ember.Error
     */
    onResizeStart(component, pageX, pageY, resizeProperty, resizeStep, resizeMax) {
      const mode = this.device.getDevice().breakpoint;
      this.dragAndDropService.updateAllDimensions();
      this.set('resizableModule', component);
      this.set('_marginStyleVariable', this.get(`resizableModule.originObject.styles.${mode}`).findBy('name', resizeProperty));

      if (!this._marginStyleVariable) {
        throw new Ember.Error(`${this.get('resizableModule.type')}: not defined styles variable "${resizeProperty}"`);
      }

      this.set('step', resizeStep);
      this.set('max', resizeMax);
      this.set('resizeStarted', true);
      this.set('initialPosition', component.get('y') + component.get('height'));
      this.set('initialMargin', parseInt(this.get('_marginStyleVariable.value'), 10));
      this.dragService.disableSelection();
      (0, _jquery.default)('body').addClass(this._cursorSelector);
    },

    /**
     * Hook to be invoked by resize service whenever resize happens(mouse move)
     *
     * @param {Ember.Object} component - component data representation
     * @param {Number} [x] - current mouse x position
     * @param {Number} y - current mouse y position
     * @param {Number|null} min - min value allowed for module to be resized to
     */
    onResize(component, x, y, min = null) {
      const distance = this.initialPosition - y;
      const {
        initialMargin
      } = this;
      const {
        step
      } = this;
      const {
        minMargin
      } = this;
      const {
        max
      } = this;
      let newValue = initialMargin; // TODO: what is going on here? Do we actually need parseInt?

      const steps = parseInt(distance / step, 10);

      if (steps > 0) {
        newValue = initialMargin - Math.abs(steps * step);
        newValue = Math.max(minMargin, newValue);
      } else if (steps < 0) {
        newValue = initialMargin + Math.abs(steps * step);
        newValue = max ? Math.min(max, newValue) : newValue;
      }

      if (min !== null && newValue < min) {
        newValue = min;
      }

      Ember.set(this._marginStyleVariable, 'value', `${newValue}px`);
    },

    /**
     * Hook to be invoked by resize service when resize ends
     *
     * @param {Ember.Object} component - component data representation
     */
    onResizeEnd(component) {
      if (this.isDimWasChanged()) {
        this._doWorkAround(component);
      }

      this._clearResizable();

      this.dragService.enableSelection();
      this.set('resizeStarted', false);
    },

    /**
     * Method detect dimension changes with module
     *
     * @return {boolean}
     */
    isDimWasChanged() {
      const initMargin = this.initialMargin;
      const updatedMargin = parseInt(this.get('_marginStyleVariable.value'), 10);
      return this.resizeStarted && initMargin !== updatedMargin;
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      this._clearResizable();
    },

    /**
     * Sets resizable through all components in structure
     *
     * @method setResizableElements
     * @param {Object} structure
     */
    setResizableElements(structure) {
      this._setResizableRecursively(structure);
    },

    /**
     * Invoked all workarounds here, for now it's structure coordinates update and afterResize callbacks(arguable)
     * probably it's core, no need to use it here
     *
     * @param {Ember.Object} component - structure object resized
     * @private
     */
    _doWorkAround(component) {
      if (component) {
        this.dragAndDropService.updateAllDimensions();
        this.afterResize.forEach(callback => callback(component));
      }
    },

    /**
     * Sets elements as resizable
     *
     * @method _setResizableRecursively
     * @private
     * @param {Object} structure - Page structure
     */
    _setResizableRecursively(structure) {
      const children = structure.get('originObject').get('children') || [];
      structure.set('vResizable', this._isResizable(structure));

      for (let i = 0, len = children.length; i < len; i++) {
        this._setResizableRecursively(children[i]);
      }
    },

    /**
     * Clears resizable properties
     *
     * @method _clearResizable
     * @private
     */
    _clearResizable() {
      this.set('initialPosition', null);
      this.set('initialMargin', null);
      this.set('resizableModule', null);
      this.set('_marginStyleVariable', null);
      (0, _jquery.default)('body').removeClass(this._cursorSelector);
    },

    /**
     * Determines whether object is resizable
     *
     * @method _isResizable
     * @private
     * @param {Object} module - Handled object
     * @returns {Boolean} Whether object is resizable
     */
    _isResizable(module) {
      return _marginable.default.includes(module.get('originObject').get('type'));
    }

  });

  _exports.default = _default;
});