define("builder/core/get-params-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.exceptions = _exports.words = void 0;
  const words = {
    default: 'All Inventory',
    t: 'Inventory',
    u: 'Used',
    n: 'New',
    priceto: 'Price To',
    pricefrom: 'Price From',
    minmpg: 'Min MPG',
    maxmpg: 'Max MPG',
    itemsperpage: 'Items Per Page',
    dateinstock: 'Date In Stock',
    make: 'Make',
    model: 'Model'
  };
  _exports.words = words;
  const exceptions = {
    specials: 1
  };
  _exports.exceptions = exceptions;

  var _default = Ember.Object.create({
    /**
     * Generates wordings
     *
     * @param {String} params - params should be replaced
     * @returns {String}
     */
    generate(params) {
      const paramsArray = params.split('&');

      for (let i = 0; i < paramsArray.length; i++) {
        const data = paramsArray[i].split('=');

        if (data.length > 1) {
          data[1] = data[1].toLowerCase();
          const val = data[1];
          data[0] = data[0].toLowerCase();
          const key = data[0];
          const invKey = key.replace(/-|_/g, '');

          if (key in words) {
            data[0] = words[key];
          }

          if (invKey in words) {
            data[0] = words[invKey];
          }

          if (key in exceptions) {
            data[1] = '';
          }

          if (val === 'all') {
            if (!(key in words)) {
              data[0] = `${key}s`;
            }
          }

          if (val in words) {
            data[1] = words[val];
          }

          data[0] = data[0].replace(/_/g, ' ');

          if (!/\d/.test(val)) {
            data.reverse();
          }

          paramsArray[i] = data.join(' ');
        } else {
          paramsArray[i] = words[paramsArray[i]] || params.replace(/-|_/g, ' ');
        }
      }

      return paramsArray.join(' & ') || 'Meta rule';
    }

  });

  _exports.default = _default;
});