define("builder/core/enumerators/widget-container-video-background-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    VIDEO_URL: 'videoUrl',
    VIDEO_ID: 'videoId'
  };
  _exports.default = _default;
});