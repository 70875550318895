define("builder/pods/components/switcher-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "npwZe+PV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"b2-switcher-button__status\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"b2-switcher-button__circle\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/switcher-button/template.hbs"
    }
  });

  _exports.default = _default;
});