define("builder/core/enumerators/font-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GOOGLE = _exports.DEALERSOCKET = void 0;
  const DEALERSOCKET = 'dealersocket';
  _exports.DEALERSOCKET = DEALERSOCKET;
  const GOOGLE = 'google';
  _exports.GOOGLE = GOOGLE;
});