define("builder/services/grid-order", ["exports", "builder/core/enumerators/breakpoint-modes", "builder/core/enumerators/container"], function (_exports, _breakpointModes, _container5) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Class used for moving children around, from 1 parent to another and removing children from it's parent
   * TODO: bootstrap service should be moved from here, this is the unit service
   */
  class GridOrderService extends Ember.Service {
    /**
     * Calculate order of the container
     * @param {object} container - parent of the draggable
     * @param {object} draggable - child to add to the parent
     * @param {number} direction - direction of the draggable
     * @param {object} anchor - target where draggable is being dropped
     * @returns {void}
     */
    calculateOrderValue(container, draggable, direction, anchor) {
      var _container$originObje, _draggable$component, _draggable$component$, _draggable$component$2;

      let refrenceChildren = container === null || container === void 0 ? void 0 : (_container$originObje = container.originObject) === null || _container$originObje === void 0 ? void 0 : _container$originObje.children;
      const breakpoint = draggable === null || draggable === void 0 ? void 0 : (_draggable$component = draggable.component) === null || _draggable$component === void 0 ? void 0 : (_draggable$component$ = _draggable$component.device) === null || _draggable$component$ === void 0 ? void 0 : (_draggable$component$2 = _draggable$component$._device) === null || _draggable$component$2 === void 0 ? void 0 : _draggable$component$2.breakpoint;
      refrenceChildren = this.sortRefrenceChildrenByOrder(refrenceChildren, breakpoint);

      const draggableOrder = this._getOrderOfObject(draggable, breakpoint);

      const anchorOrder = this._getOrderOfObject(anchor, breakpoint);

      const isDraggedIndexAscending = direction === 'left' || direction === 'top';

      for (let i = 0; i < ((_refrenceChildren = refrenceChildren) === null || _refrenceChildren === void 0 ? void 0 : _refrenceChildren.length); i++) {
        var _refrenceChildren;

        const child = refrenceChildren[i];
        const draggableItem = child.originObject.styles;

        const childOrderValue = this._getOrderOfObject(child, breakpoint);

        const value = this.getCalculatedOrderForChild(childOrderValue, isDraggedIndexAscending, draggableOrder, anchorOrder);
        draggableItem.set(breakpoint, [{
          name: 'order',
          value: value.toString()
        }]);
      }
    }
    /**
     * calculate the order value of the child
     * @param {object} child - child of the target Container
     * @param {string} breakpoint - device type
     * @param {string} isDraggedIndexAscending - should be true if direction of the drag item is left/top
     * @param {string} draggableOrder - order value of the drag item
     * @param {object} anchorOrder - order value of target where draggable is being dropped
     * @returns {string}
     */


    getCalculatedOrderForChild(itemOrder, isDraggedIndexAscending, draggableOrder, anchorOrder) {
      let value = 0;

      if (itemOrder === draggableOrder) {
        value = anchorOrder;
      } else if (isDraggedIndexAscending && itemOrder < draggableOrder && itemOrder >= anchorOrder) {
        value = +itemOrder + 1;
      } else if (!isDraggedIndexAscending && itemOrder > draggableOrder && itemOrder <= anchorOrder) {
        value = itemOrder - 1;
      } else {
        value = itemOrder;
      }

      return value;
    }
    /**
     * Return the order of the object in the array
     * @param {object} arr - Array from where index of the object needs to be found
     * @param {object} breakpoint - device type {lg, md, s, xs}
     * @returns {string}
     */


    _getOrderOfObject(obj, breakpoint) {
      var _obj$originObject, _obj$originObject$sty, _deviceSpecificStyle$, _deviceSpecificStyle$2;

      const deviceSpecificStyle = obj === null || obj === void 0 ? void 0 : (_obj$originObject = obj.originObject) === null || _obj$originObject === void 0 ? void 0 : (_obj$originObject$sty = _obj$originObject.styles) === null || _obj$originObject$sty === void 0 ? void 0 : _obj$originObject$sty.toJSON()[breakpoint];
      const orderValue = (_deviceSpecificStyle$ = deviceSpecificStyle === null || deviceSpecificStyle === void 0 ? void 0 : (_deviceSpecificStyle$2 = deviceSpecificStyle.find(style => style.name === 'order')) === null || _deviceSpecificStyle$2 === void 0 ? void 0 : _deviceSpecificStyle$2.value) !== null && _deviceSpecificStyle$ !== void 0 ? _deviceSpecificStyle$ : null;
      return +orderValue;
    }
    /**
     * sort array in ascending
     * @param {object} orderChildren - Array of the ordered children
     * @param {object} breakpoint - device type {lg, md, s, xs}
     * @returns {array}
     */


    sortRefrenceChildrenByOrder(orderedChildren, breakpoint) {
      if (!orderedChildren) {
        return null;
      }

      return orderedChildren.slice().sort((child1, child2) => {
        var _child1$originObject, _child1$originObject$, _child2$originObject, _child2$originObject$, _styleObj1$find, _styleObj2$find;

        // the style obj based on breakpoint
        const styleObj1 = child1 === null || child1 === void 0 ? void 0 : (_child1$originObject = child1.originObject) === null || _child1$originObject === void 0 ? void 0 : (_child1$originObject$ = _child1$originObject.styles) === null || _child1$originObject$ === void 0 ? void 0 : _child1$originObject$.toJSON()[breakpoint];
        const styleObj2 = child2 === null || child2 === void 0 ? void 0 : (_child2$originObject = child2.originObject) === null || _child2$originObject === void 0 ? void 0 : (_child2$originObject$ = _child2$originObject.styles) === null || _child2$originObject$ === void 0 ? void 0 : _child2$originObject$.toJSON()[breakpoint];
        const order1 = styleObj1 === null || styleObj1 === void 0 ? void 0 : (_styleObj1$find = styleObj1.find(style => style.name === 'order')) === null || _styleObj1$find === void 0 ? void 0 : _styleObj1$find.value;
        const order2 = styleObj2 === null || styleObj2 === void 0 ? void 0 : (_styleObj2$find = styleObj2.find(style => style.name === 'order')) === null || _styleObj2$find === void 0 ? void 0 : _styleObj2$find.value;
        if (+order1 > +order2) return 1;
        if (+order1 < +order2) return -1;
        return 0;
      });
    }
    /**
    * Init order values on the children elements
    * @param {Object} container - container of the children elements
    * @param {String} breakpoint - current breakpoint mode
    */


    initChildrenOrder(children, breakpoint) {
      if (!children) {
        return;
      }

      children.forEach((child, index) => {
        var _child$originObject$s, _child$originObject;

        const childStyle = (_child$originObject$s = child === null || child === void 0 ? void 0 : (_child$originObject = child.originObject) === null || _child$originObject === void 0 ? void 0 : _child$originObject.styles) !== null && _child$originObject$s !== void 0 ? _child$originObject$s : null;

        if (childStyle) {
          childStyle.set(breakpoint, [{
            name: 'order',
            value: index.toString()
          }]);
        }
      });
    }
    /**
     * reassign the orderValue in consecutive way starting from 0
     * incase any duplicate/nonsequential value has been set
     * @param {object} container - Array of the ordered element
     * @param {object} excludeChild - List of the child which should be excluded from been recalculated
     * @returns {void}
     */


    reassignOrderConsecutively(container, excludeChild = null) {
      [_breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(breakpoint => {
        if (this.hasOrderedChildren(container, breakpoint)) {
          var _container$originObje2;

          const containerChildren = container === null || container === void 0 ? void 0 : (_container$originObje2 = container.originObject) === null || _container$originObje2 === void 0 ? void 0 : _container$originObje2.children;
          const filteredChildren = excludeChild ? containerChildren.filter(item => item !== excludeChild) : containerChildren;

          if (!filteredChildren) {
            return;
          }

          const sortedChildren = this.sortRefrenceChildrenByOrder(filteredChildren, breakpoint);
          this.initChildrenOrder(sortedChildren, breakpoint);
        }
      });
    }
    /**
     * Update order on existing rows/columns after a new row or column is added
     * @param {object} container - container object
     * @param {object} draggable - newly added element
     * @param {object} index - insert index in the container
     * @returns {void}
     */


    updateChildrenOrderOnAdd(container, draggable, index) {
      [_breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(breakpoint => {
        if (this.hasOrderedChildren(container, breakpoint)) {
          const siblings = container.originObject.children.filter(item => item !== draggable);
          const sortedChildren = this.sortRefrenceChildrenByOrder(siblings, breakpoint);
          this.updateOrderIndicesAfterInsert(sortedChildren, index, breakpoint);
          draggable.originObject.styles.set(breakpoint, [{
            name: 'order',
            value: index
          }]);
        }
      });
    }
    /**
     * Update the order Indices of neighbour elements on inserting new child
     * @param {Array} children - children elements array
     * @param {Number} insertedAtIndex - newly added child index
     * @param {String} breakpoint - current breakpoint
     */


    updateOrderIndicesAfterInsert(children, insertedAtIndex, breakpoint) {
      children.forEach(child => {
        var _child$originObject2, _child$originObject2$, _stylesJSON$breakpoin;

        const stylesJSON = child === null || child === void 0 ? void 0 : (_child$originObject2 = child.originObject) === null || _child$originObject2 === void 0 ? void 0 : (_child$originObject2$ = _child$originObject2.styles) === null || _child$originObject2$ === void 0 ? void 0 : _child$originObject2$.toJSON();
        const order = (_stylesJSON$breakpoin = stylesJSON[breakpoint]) === null || _stylesJSON$breakpoin === void 0 ? void 0 : _stylesJSON$breakpoin.find(style => style.name === 'order');
        const orderVal = Number(order === null || order === void 0 ? void 0 : order.value);

        if (order && orderVal >= insertedAtIndex) {
          const newOrder = orderVal + 1;
          child.originObject.styles.set(breakpoint, [{
            name: 'order',
            value: newOrder.toString()
          }]);
        }
      });
    }
    /**
     * Check if the container has ordered children on given breakpoint
     * @param {object} container - container object
     * @param {String} breakpoint - current breakpoint mode
     */


    hasOrderedChildren(container, breakpoint) {
      var _container$originObje3;

      const children = container === null || container === void 0 ? void 0 : (_container$originObje3 = container.originObject) === null || _container$originObje3 === void 0 ? void 0 : _container$originObje3.children;

      if (!children) {
        return false;
      }

      const idx = children.findIndex(item => {
        var _item$originObject, _item$originObject$st, _stylesJSON$breakpoin2;

        const stylesJSON = (_item$originObject = item.originObject) === null || _item$originObject === void 0 ? void 0 : (_item$originObject$st = _item$originObject.styles) === null || _item$originObject$st === void 0 ? void 0 : _item$originObject$st.toJSON();

        if (!stylesJSON) {
          return false;
        }

        const order = (_stylesJSON$breakpoin2 = stylesJSON[breakpoint]) === null || _stylesJSON$breakpoin2 === void 0 ? void 0 : _stylesJSON$breakpoin2.find(style => style.name === 'order');
        return order && +order.value > -1;
      });
      return idx > -1;
    }
    /**
     * Update order of children after delete
     * @param {object} module - module to be deleted
     * @returns {void}
     */


    updateOrderOnDelete(module) {
      var _module$parent, _container, _container2, _container2$parent, _container3, _container3$parent, _container3$parent$or, _container4, _container4$originObj;

      if (module && module.originObject.type === _container5.default.WIDGET_CONTAINER) {
        return;
      }

      let container = module === null || module === void 0 ? void 0 : (_module$parent = module.parent) === null || _module$parent === void 0 ? void 0 : _module$parent.parent;

      if (!((_container = container) === null || _container === void 0 ? void 0 : _container.originObject) || !((_container2 = container) === null || _container2 === void 0 ? void 0 : (_container2$parent = _container2.parent) === null || _container2$parent === void 0 ? void 0 : _container2$parent.originObject)) {
        return;
      }

      let immediateWrapper = module.parent;
      const containerParentType = (_container3 = container) === null || _container3 === void 0 ? void 0 : (_container3$parent = _container3.parent) === null || _container3$parent === void 0 ? void 0 : (_container3$parent$or = _container3$parent.originObject) === null || _container3$parent$or === void 0 ? void 0 : _container3$parent$or.type;
      const containerType = (_container4 = container) === null || _container4 === void 0 ? void 0 : (_container4$originObj = _container4.originObject) === null || _container4$originObj === void 0 ? void 0 : _container4$originObj.type;
      const {
        children
      } = container.originObject;

      if (containerType === _container5.default.WIDGET_ROW && (containerParentType === _container5.default.WIDGET_COLUMN || containerParentType === _container5.default.WIDGET_CONTAINER && (children === null || children === void 0 ? void 0 : children.length) === 1)) {
        container = container.parent;
        immediateWrapper = immediateWrapper.parent;
      }

      this.reassignOrderConsecutively(container, immediateWrapper);
    }
    /**
     * update the column/row order value incase of copy operation of modules
     * @param {object} module - module to be copied
     * @returns {void}
     */


    updateOrderOnCopyModules(module) {
      var _module$originObject, _module$parent2, _container$component, _container$component$, _container$component$2;

      if (!module || (module === null || module === void 0 ? void 0 : (_module$originObject = module.originObject) === null || _module$originObject === void 0 ? void 0 : _module$originObject.type) === _container5.default.WIDGET_CONTAINER) {
        return;
      }

      const container = module === null || module === void 0 ? void 0 : (_module$parent2 = module.parent) === null || _module$parent2 === void 0 ? void 0 : _module$parent2.parent;
      const currentDeviceMode = container === null || container === void 0 ? void 0 : (_container$component = container.component) === null || _container$component === void 0 ? void 0 : (_container$component$ = _container$component.device) === null || _container$component$ === void 0 ? void 0 : (_container$component$2 = _container$component$._device) === null || _container$component$2 === void 0 ? void 0 : _container$component$2.breakpoint;
      const hasOrderedChildren = this.hasOrderedChildren(container === null || container === void 0 ? void 0 : container.parent, currentDeviceMode);
      let index = 0;

      if (hasOrderedChildren) {
        index = this._getOrderOfObject(container, currentDeviceMode);
      } else {
        var _container$originObje4, _container$originObje5;

        index = container === null || container === void 0 ? void 0 : (_container$originObje4 = container.originObject) === null || _container$originObje4 === void 0 ? void 0 : (_container$originObje5 = _container$originObje4.children) === null || _container$originObje5 === void 0 ? void 0 : _container$originObje5.findIndex(i => {
          var _i$component, _module$component;

          return (i === null || i === void 0 ? void 0 : (_i$component = i.component) === null || _i$component === void 0 ? void 0 : _i$component.elementId) === (module === null || module === void 0 ? void 0 : (_module$component = module.component) === null || _module$component === void 0 ? void 0 : _module$component.elementId);
        });
      }

      if (index === undefined) {
        return;
      }

      this.updateChildrenOrderOnAdd(container === null || container === void 0 ? void 0 : container.parent, container, index);
    }
    /**
    * reset order values on the elements to -1
    * @param {Object} children - list of the children elements
    * @param {String} breakpoint - current breakpoint mode
    */


    initializeOrderToStartValue(child, breakpoint) {
      var _child$originObject$s2, _child$originObject3;

      if (!child) {
        return;
      }

      const childStyle = (_child$originObject$s2 = child === null || child === void 0 ? void 0 : (_child$originObject3 = child.originObject) === null || _child$originObject3 === void 0 ? void 0 : _child$originObject3.styles) !== null && _child$originObject$s2 !== void 0 ? _child$originObject$s2 : null;

      if (childStyle) {
        childStyle.set(breakpoint, [{
          name: 'order',
          value: '-1'
        }]);
      }
    }
    /**
     * reset the order of the nested children of specific breakpoint
     * @param {object} parent - list of the children elements
     * @param {String} breakpoint - breakpoint mode of the device
     * @returns {void}
     */


    resetOrderOfChildren(parent, breakpoint) {
      var _parent$originObject$, _parent$originObject;

      const children = (_parent$originObject$ = parent.originObject.children) !== null && _parent$originObject$ !== void 0 ? _parent$originObject$ : [];
      const validWidgetType = [_container5.default.WIDGET_COLUMN, _container5.default.WIDGET_ROW, _container5.default.WIDGET_CONTAINER];
      const validTypeContainer = !validWidgetType.includes(parent === null || parent === void 0 ? void 0 : (_parent$originObject = parent.originObject) === null || _parent$originObject === void 0 ? void 0 : _parent$originObject.type) || !this.hasOrderedChildren(parent, breakpoint);

      if (!children || validTypeContainer) {
        return;
      }

      children.forEach(child => {
        var _child$originObject4, _child$originObject5, _child$originObject5$;

        if (!validWidgetType.includes(child === null || child === void 0 ? void 0 : (_child$originObject4 = child.originObject) === null || _child$originObject4 === void 0 ? void 0 : _child$originObject4.type)) {
          return;
        }

        this.initializeOrderToStartValue(child, breakpoint);

        if ((child === null || child === void 0 ? void 0 : (_child$originObject5 = child.originObject) === null || _child$originObject5 === void 0 ? void 0 : (_child$originObject5$ = _child$originObject5.children) === null || _child$originObject5$ === void 0 ? void 0 : _child$originObject5$.length) > 0) {
          this.resetOrderOfChildren(child, breakpoint);
        }
      });
    }
    /**
     * reset the order to all or specific device
     * @param {boolean} resetToAll - should reset for all breakpoints
     * @param {object} container - conatiner data for which reset has been requested
     * @returns {void}
     */


    resetOrder(resetToAll, container, device) {
      if (resetToAll) {
        [_breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(breakpoint => {
          this.resetOrderOfChildren(container, breakpoint);
        });
      } else {
        const currentBreakpoint = device._device.breakpoint;
        this.resetOrderOfChildren(container, currentBreakpoint);
      }
    }

  }

  _exports.default = GridOrderService;
});