define("builder/pods/components/field-info-block/component", ["exports", "builder/mixins/field-visibility"], function (_exports, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPONENT_CLASS = 'field-info-block';
  /**
   * @class FieldInfoBlockComponent
   */

  var _default = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: [COMPONENT_CLASS],

    /**
     * @inheritdoc
     */
    classNameBindings: ['_themeClass'],

    /**
     * @property {Object} field - original passed in data
     */
    field: null,

    /**
     * @property {String} label - info block label
     * @readonly
     */
    label: Ember.computed.readOnly('field.label'),

    /**
     * @property {String} _themeClass - class for theme
     * @readonly
     * @private
     */
    _themeClass: Ember.computed('field.theme', function () {
      const theme = this.get('field.theme') || 'info';
      return `${COMPONENT_CLASS}--${theme}`;
    }).readOnly()
  });

  _exports.default = _default;
});