define("builder/pods/components/builder-error-message/component", ["exports", "builder/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for render top level builder errors.
   *
   * Usage:
   * 	{{builder-error-message message="Not Found"}}
   *
   * @class BuilderErrorMessage
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['b2-builder-error-message'],

    /**
     * @property {String} message - error message
     */
    message: '',

    /**
     * @property {String} description - error description
     */
    description: 'Sorry, something went wrong. Please reload.',

    /**
     * @property {String} linkTo - link to success page
     * @readonly
     */
    linkTo: Ember.computed(function () {
      return _environment.default.rootURL;
    }).readOnly()
  });

  _exports.default = _default;
});