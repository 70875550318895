define("builder/mixins/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Set component style attribute bindings.
     * @type {string[]}
     */
    attributeBindings: ['style'],

    /**
     * Get joined styles.
     * @returns {string|null}
     */
    style: Ember.computed('data.styles', function () {
      const styles = this.get('data.styles');
      return styles ? this.objectToString(styles) : null;
    }),

    /**
     * Initialization method.
     */
    init(...args) {
      this._super(...args);

      this.attachAction();
      this.setValidation();
    },

    /**
     * Join object to styles string.
     * @param {Object} object - Object with styles
     * @returns {string}
     */
    objectToString(object) {
      return Object.keys(object).map(key => {
        const value = object[key];
        return `${key}:${value};`;
      }).join('');
    },

    /**
     * Extends attributeBindings property with validation attributes.
     * @returns {void}
     */
    setValidation() {
      const extended = this.attributeBindings.concat(this.getValidation());
      this.set('attributeBindings', extended);
    },

    /**
     * Extends attributes array with validation.
     * @returns {string[]}
     */
    getValidation() {
      const attributes = [];
      const validation = this.get('data.validation');
      const view = this;

      if (validation) {
        Object.keys(validation).forEach(key => {
          attributes.push(`data-parsley-${key}`);
          view.set(`data-parsley-${key}`, `${validation[key]}`);
        });
      }

      return attributes;
    },

    /**
     * Attach registered action.
     * @returns {void}
     */
    attachAction() {
      const actions = this.get('data.actions');
      const view = this;

      if (actions) {
        Object.keys(actions).forEach(action => {
          const normalizedActionName = action.charAt(0).toUpperCase() + action.slice(1);
          view[`attach${normalizedActionName}`](actions[action]);
        });
      }
    },

    /**
     * Attach populate action.
     * @returns {void}
     */
    attachPopulate() {},

    /**
     * Attach toggle effect to element.
     * @method
     * @param {Object[]} data - Array of toggle objects
     * @returns {void}
     */
    attachToggle(data) {
      const attributes = this.attributeBindings;
      const view = this;
      const type = this.get('data.type');

      if (/checkbox|radio/.test(type)) {
        data.forEach(action => {
          const {
            target
          } = action;
          attributes.push('data-target', 'data-toggle');
          view.set('data-target', target);
          view.set('data-toggle', 'collapse');
        });
        view.set('attributeBindings', attributes);
      } else if (/dropdown/.test(type)) {
        view.on('change', event => {
          view.send('toggle', event, data);
        });
      }

      view.prepareToggleTargets(data);
    },

    /**
     * Prepare toggle target after view render.
     * @method
     * @param {array} data - Array of toggle objects
     */
    prepareToggleTargets(data) {
      const view = this;
      const type = this.get('data.type');
      Ember.run.schedule('afterRender', view, () => {
        data.forEach(action => {
          const element = (0, _jquery.default)(action.target).addClass('collapse').css('height', 0);

          if (/checkbox|radio/.test(type) && view.get('checked')) {
            element.addClass('in').removeAttr('style');
          } else if (/dropdown|checkboxlist|radiolist/.test(type) && view.$()) {
            const selected = view.$().find(':selected').val();

            if (selected === action.value) {
              element.addClass('in').removeAttr('style');
            }
          }
        });
      });
    },

    actions: {
      /**
       * Toggle element.
       * @method
       * @param {object} event - Handler event object
       * @param {array} data - Toggle data objects
       */
      toggle(event, data) {
        const selection = (0, _jquery.default)(event.target).find(':selected').val();
        data.forEach(action => {
          const effect = action.value === selection ? 'show' : 'hide';
          const target = (0, _jquery.default)(action.target);

          if (effect === 'show' && !target.hasClass('in')) {
            target.collapse(effect);
          } else if (effect === 'hide' && target.hasClass('in')) {
            target.collapse(effect);
          }
        });
      },

      populate() {}

    }
  });

  _exports.default = _default;
});