define("builder/core/froala/settings/widget-image-settings", ["exports", "builder/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.froalaImageSettings = void 0;
  const froalaImageSettings = {
    key: _environment.default.FroalaKey,
    attribution: false,
    toolbarInline: true,
    toolbarVisibleWithoutSelection: false,
    autoStart: false,
    autofocus: false,
    imageResize: true,
    imageEditButtons: [],
    lineBreakerTags: ['table']
  };
  _exports.froalaImageSettings = froalaImageSettings;
});