define("builder/serializers/container-template", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/widget", "builder/core/enumerators/module", "builder/core/enumerators/container", "builder/mixins/styles-variables", "ember-copy", "builder/core/enumerators/breakpoint-modes"], function (_exports, _rest, _widget, _module, _container, _stylesVariables, _emberCopy, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Container template serializer
   *
   * @todo I am not covering this file with unit tests because it should be refactored.
   * Refactor added to technical debt
   */
  var _default = _rest.default.extend(_stylesVariables.default, {
    /**
     * @property {String} primaryKey - primary key index
     */
    primaryKey: 'Id',

    /**
     * @property {Array} _nestedStylesProperties - list of properties which should be converted to styles variables
     * @private
     */
    _nestedStylesProperties: ['fullWidth', 'fullWidthContent', 'parallax', 'fullHeight', 'contentPosition'],

    /**
     * @property {Ember.Service} structure - Structure service
     */
    structure: Ember.inject.service('structure'),

    /**
     * Convert page structure needed for backend
     *
     * @method serializeStructure
     * @param {Ember.Object} structure - page structure
     * @return {Object} structure
     */
    serializeStructure(structure) {
      this.restoreParents(structure);
      this.provideDefaultContentIfEmptyParagraph(structure); // Remove empty fields in all widget-containers

      this.removeEmptyFields(structure, _container.default.WIDGET_CONTAINER);
      this.prepareStyles(structure);
      this.rollbackFormModule(structure);
      this.unwrapModules(structure);
      return this.makeJSON(structure);
    },

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const model = this._super(...args);

      if (model.Structure && Ember.typeOf(model.Structure) === 'instance') {
        model.Structure = [this.serializeStructure(model.Structure)];
      }

      return model;
    },

    /**
     * Sets parent to objects
     *
     * @param {Ember.Object} structure - object to process
     * @param {Object|null} parent - parent
     */
    restoreParents(structure, parent = null) {
      const {
        children
      } = structure.originObject;
      structure.set('parent', parent);

      if (children) {
        children.forEach(child => this.restoreParents(child, structure));
      }
    },

    /**
     * Method prepare styles data before send request.
     * It's remove not changed values and convert data to expected format for server
     *
     * @method prepareStyles
     * @param {Object} structure - page structure
     */
    prepareStyles(structure) {
      const origin = structure.originObject;
      const {
        children
      } = origin;

      if (origin.styles) {
        const styles = this._serializeStyles(origin);

        const styleOptions = this._serializeStyleOptions(origin);

        origin.set('styles', styles);

        if (styleOptions && styleOptions.length) {
          origin.set('styleOptions', styleOptions);
        } // Remove attribute if all styles equal to default


        if (!Object.keys(origin.styles).length) {
          delete origin.styles;
        }
      }

      if (children) {
        children.forEach(child => this.prepareStyles(child));
      }
    },

    /**
     * Rollback form module to core module
     * @param {Ember.Object} structure - page structure
     */
    rollbackFormModule(structure) {
      const {
        children
      } = structure.originObject;

      if (structure.originObject.type === _module.default.MODULE_FORM) {
        structure.originObject.set('type', _module.default.MODULE_CORE);
      }

      if (children) {
        children.forEach(child => this.rollbackFormModule(child));
      }
    },

    /**
     * Convert page structure from Ember.Object to plain objects
     *
     * @method
     * @param {Ember.Object|Array} structure - page structure
     * @returns {Array|Object} new structure
     */
    makeJSON(structure) {
      if (Array.isArray(structure)) {
        const newStructure = [];
        structure.forEach(item => {
          const obj = item.originObject || item;
          newStructure.push(this._copyObject(obj));
        });
        return newStructure;
      }

      const object = structure.originObject || structure;
      return this._copyObject(object);
    },

    /**
     * Normalize page structure for best render on website
     *
     * @method
     * @param {Ember.Object} structure
     */
    unwrapModules(structure) {
      const {
        children
      } = structure.originObject;

      if (Ember.typeOf(structure) === 'instance') {
        if (this._needUnwrap(structure)) {
          this._unwrap(structure);
        }
      }

      if (children) {
        children.forEach(child => this.unwrapModules(child));
      }
    },

    /**
     * Sets default content for empty paragraph
     *
     * @method provideDefaultContentIfEmptyParagraph
     * @param {Ember.Object} structure - object to process
     */
    provideDefaultContentIfEmptyParagraph(structure) {
      const {
        originObject
      } = structure;
      const {
        children
      } = originObject;

      if (originObject.type === _widget.WidgetType.WIDGET_PARAGRAPH && !originObject.content) {
        originObject.set('content', '<p><br></p>');
      }

      if (children) {
        children.forEach(child => this.provideDefaultContentIfEmptyParagraph(child));
      }
    },

    /**
     * Method convert data to backend format for styles attribute
     * and add some styles variables ('fullWidth', 'fillWidthContent', 'parallax')
     * as own properties passed object.
     *
     * NOTICE:
     * 		Styles attribute does not modified directly. Method always return new object.
     * 		Styles variables listed in "_nestedStylesProperties"	will be converted to properties and added directly into passed object.
     *
     * @see _nestedStylesProperties
     *
     * Example:
     * 	Before:
     * 	{
     * 		styles: {
     * 			global: [
     * 				{name: 'variable1', value: '20px'},
     * 				{name: 'variable2', value: '10px'},
     * 				...
     * 			],
     *		 	lg: [...],
     *			md: [...],
     *			sm: [...],
     *			xs: [...]
     * 	  }
     * 	}
     *
     *	After:
     *		{
     *			styles: {
     *				global: {
     *					'variable1': '20px',
     *					'variable2': '10px',
     *					...
     *				},
     *			  lg: {...},
     *				md: {...},
     *				sm: {...},
     *				xs: {...}
     *			}
     *		}
     *
     *
     * @param {Object} origin - main item structure(originObject property)
     * @return {Object} - serialized data
     * @method _serializeStyles
     * @throws Ember.Error
     * @private
     */
    _serializeStyles(origin) {
      const variables = this.getVariablesConfig(origin.get('type'));
      const styles = origin.get('styles');
      const excludedVariables = this._nestedStylesProperties;
      const result = {}; // Going each all bootstrap modes(lg, md and etc)

      [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL, 'global'].forEach(mode => {
        if (styles.hasOwnProperty(mode)) {
          let defaultVariable, currentVariable; // Each all variables by mode

          for (let i = styles[mode].length - 1; i >= 0; i -= 1) {
            // Get variable which stored in component hash
            currentVariable = styles[mode][i]; // Get variable from variables hardcoded storage

            defaultVariable = variables[mode].findBy('name', currentVariable.name); // Throw error if we can't find it in variables hardcoded storage

            if (!defaultVariable) {
              throw new Ember.Error(`This variable "${currentVariable.name}" is not declared for ${origin.get('type')}`);
            }

            if (excludedVariables.includes(currentVariable.name)) {
              origin[currentVariable.name] = currentVariable.value;
              styles[mode].removeAt(i, 1); // Gradient variables should be added always
            } else if (currentVariable.value && (currentVariable.value !== defaultVariable.value || /gradient/gi.test(defaultVariable.name))) {
              if (!result[mode]) {
                result[mode] = {};
              }

              result[mode][currentVariable.name] = currentVariable.value;
            }
          } // We should remove gradient variables if direction not selected(colors we have always by default now)


          if (result[mode]) {
            const bootstrapMode = result[mode];

            if (bootstrapMode['background-gradient-direction'] && (bootstrapMode['background-gradient-color1'] === 'inherit' || bootstrapMode['background-gradient-color2'] === 'inherit')) {
              delete bootstrapMode['background-gradient-direction'];
              delete bootstrapMode['background-gradient-color1'];
              delete bootstrapMode['background-gradient-color2'];
            } // Convert background for E6


            if (bootstrapMode['background-image']) {
              bootstrapMode['background-image'] = `url(${bootstrapMode['background-image']})`;
            } // Remove empty hash


            if (!Object.keys(bootstrapMode).length) {
              delete result[mode];
            }
          }
        }
      });
      return result;
    },

    /**
     * Serialize style options for backend
     *
     * @method _serializeStyleOptions
     * @param {Object} origin - main item structure(originObject property)
     * @return {Object[]} - serialized data
     * @private
     */
    _serializeStyleOptions(origin) {
      const options = origin.get('styles.StyleOptions');

      if (!options || !options.get('length')) {
        return [];
      }

      return options.filter(option => Ember.get(option, 'locked'));
    },

    /**
     * Copies object
     *
     * @param {Object} object - object to be copied
     * @returns {Object}
     * @private
     */
    _copyObject(object) {
      const json = {};
      Object.keys(object).forEach(key => {
        if (typeof object[key] === 'function') {
          return;
        }

        if (Array.isArray(object[key])) {
          json[key] = [];
          const {
            length
          } = object[key];

          for (let c = 0; c < length; c++) {
            if (object[key][c] !== null && typeof object[key][c] === 'object') {
              json[key].push(this.makeJSON(object[key][c]));
            } else {
              json[key].push(object[key][c]);
            }
          }
        } else if (object[key] !== null && typeof object[key] === 'object') {
          json[key] = this.makeJSON(object[key]);
        } else {
          json[key] = object[key];
        }
      });
      return json;
    },

    /**
     * Determines whether object need to be unwrapped
     *
     * @method
     * @param {Object} structure - object to check for unwrap
     * @returns {boolean}
     */
    _needUnwrap(structure) {
      return structure.get('originObject').get('type') === _container.default.WIDGET_COLUMN && structure.get('parent').get('originObject').get('children').length === 1;
    },

    /**
     * Unwraps object
     *
     * @method
     * @param {Object} structure - object to unwrap
     */
    _unwrap(structure) {
      const module = structure.get('originObject').get('children')[0];
      const row = structure.get('parent');
      const container = row.get('parent');

      if (container) {
        const index = container.get('originObject').get('children').indexOf(row);
        container.get('originObject').get('children').removeAt(index);
        container.get('originObject').get('children').insertAt(index, module);
        module.set('parent', container);
      }
    },

    /**
     * Remove empty fields
     * @param {Ember.Object} structure - object to process
     * @param {String} [type] - type of element (e.g. 'widget-container', 'widget-paragraph')
     */
    removeEmptyFields(structure, type) {
      const {
        originObject
      } = structure;
      const {
        children
      } = originObject;

      if (type && originObject.type === type) {
        Object.keys(originObject).forEach(key => {
          if (Ember.isEmpty(originObject[key])) {
            delete originObject[key];
          }
        });
      }

      if (children) {
        children.forEach(child => this.removeEmptyFields(child, type));
      }
    },

    /**
     * Method normalizes page structure for render
     *
     * @method normalizeStructure
     * @param {Object} structure
     * @returns {Array}
     */
    normalizeStructure(structure) {
      const cloneStructure = (0, _emberCopy.copy)(structure);
      const {
        children
      } = cloneStructure;
      this.normalizeStructureItem(cloneStructure);

      if (children) {
        children.forEach(child => this.normalizeStructureItem(child, cloneStructure));
      }

      return cloneStructure;
    },

    /**
     * Recursive element structure normalizer
     *
     * @param {Object} element - element which structure to normalize
     * @param {Object} parent - parent element of element to normalize
     */
    normalizeStructureItem(element, parent = null) {
      const index = parent && parent.children ? parent.children.indexOf(element) : null;
      const {
        children
      } = element;
      element.type = this.getType(element);

      if (this.isNeedStylesNormalization(element)) {
        this.normalizeStyles(element);
      }

      if (parent && this.needsWrap(element, parent)) {
        const wrapper = this.getWrapper(element);
        wrapper.children.push(element);
        parent.children[index] = wrapper;
        this.normalizeStructureItem(wrapper, parent);
      }

      if (children) {
        children.forEach(child => this.normalizeStructureItem(child, element));
      }
    },

    /**
     * Get correct type of component(config)
     * @param {Object} element - element which type to detect
     * @returns {String} - "real" type of component
     */
    getType(element) {
      let {
        type
      } = element;

      if (_module.default.MODULE_CORE === type && element.name === 'Form') {
        type = _module.default.MODULE_FORM;
      }

      return type;
    },

    /**
     * Method detect that need to normalize styles or convert some
     * nested properties to styles variables
     *
     * @see _nestedStylesProperties to see all nested properties
     *
     * @param {Object} element - hash described component
     * @param {String} element.type - name of component
     * @param {Object} [element.styles] - styles for component
     * @return {Boolean}
     */
    isNeedStylesNormalization(element) {
      const nestedVariables = this._nestedStylesProperties;
      const {
        styles
      } = element;
      let isNeed = false; // Check that container have nested variables which should be moved to styles property as variables.

      if (element.type === _container.default.WIDGET_CONTAINER) {
        for (let i = nestedVariables.length - 1; i >= 0; i -= 1) {
          if (element[nestedVariables[i]]) {
            isNeed = true;
            break;
          }
        }
      }

      if (!isNeed && styles) {
        isNeed = Object.keys(styles).some(breakpoint => !Array.isArray(styles[breakpoint]));
      }

      return isNeed;
    },

    /**
     * Method normalize styles hash to application format
     * and merge received values with it's default.
     *
     *
     * NOTICE:
     * 		Some properties described in "_nestedStylesProperties" property
     * 		will be converted in styles variables.
     *
     * @see _nestedStylesProperties to know about nested variables.
     *
     * Example:
     *   Data received from backend:
     *   	{
     *   		fullWidth: 'fit-to-content',
     *   	  	fullWidthContent: 'fit-to-content',
     *   		parallax: 'slow',
     *   		styles: {
     *   			global: {'variable1': '25px'},
     *   			lg: {'padding-top': '10px', ...},
     *   			...
     *   		}
     *   	}
     *
     *   After normalization:
     *		{
     *			styles: {
     *				global: [
     *					{name: 'variable1', value: '25px'},
     *					{name: 'fullWidth', value: 'fit-to-content'},
     *					{name: 'fullWidthContent', value: 'fit-to-content'},
     *					{name: 'parallax', value: 'slow'}
     *				],
     *				lg: [
     *					{name: 'padding-top', value: '10px'},
     *					...
     *				],
     *				...
     *		}
     *
     * @param {Object} element - hash described component('widget-container', 'widget-paragraph' and etc)
     */
    normalizeStyles(element) {
      const copyVariables = (0, _emberCopy.copy)(this.getVariablesConfig(element.type), true);
      const nestedVariables = this._nestedStylesProperties;
      const regCssImageValue = /url\(['"]?(.*)['"]?\)/i;
      const {
        styles
      } = element;

      if (copyVariables) {
        if (styles) {
          Object.keys(styles).forEach(breakpoint => {
            const breakpointStyles = styles[breakpoint];

            if (breakpointStyles) {
              Object.keys(breakpointStyles).forEach(styleName => {
                const variable = copyVariables[breakpoint].findBy('name', styleName);

                if (variable) {
                  // Replace all 'url(path/to/image.jpg) -> path/to/image.jpg' it's need for application
                  variable.value = regCssImageValue.test(breakpointStyles[styleName]) ? breakpointStyles[styleName].replace(regCssImageValue, '$1') : breakpointStyles[styleName];
                }
              });
            }
          });
        } // Convert some properties in styles variables


        for (let i = nestedVariables.length - 1; i >= 0; i -= 1) {
          if (element[nestedVariables[i]]) {
            this.provideNestedStylesVariable(element, nestedVariables[i], copyVariables);
          }
        }

        Ember.set(element, 'styles', copyVariables);
      } else {
        delete element.styles;
      }
    },

    /**
     * Method provide value from received nested styles variable
     * to it's analogue defined in variables hash which contains default values
     * and after that remove passed nested variable.
     *
     * @param {Object} element - has described component('widget-container', 'widget-paragraph' and etc)
     * @param {String} nestedVariable - name of variable
     * @param {Object} variables - variables hash which contains default values
     * @throw {Ember.Error}
     */
    provideNestedStylesVariable(element, nestedVariable, variables) {
      const variable = variables.global.findBy('name', nestedVariable);

      if (!variable) {
        throw new Ember.Error(`Variable "${nestedVariable}" is not defined for "${element.type}"`);
      }

      variable.value = element[nestedVariable];
      delete element[nestedVariable];
    },

    /**
     * Get wrapper widget-column/widget-row
     * @param {Object} element - element that needs wrap
     * @returns {Object} - wrapper
     */
    getWrapper(element) {
      let wrapper = null;

      if (_container.default.WIDGET_COLUMN === element.type) {
        wrapper = this.createRow();
      } else {
        wrapper = this.createColumn();
      }

      return wrapper;
    },

    /**
     * Check if wrap need
     * @param {Object} child - element to test if it needs wrapper or not
     * @param {Object} parent - child element direct parent
     * @returns {Boolean} - true if wrap needed otherwise false
     */
    needsWrap(child, parent) {
      const {
        structure
      } = this;

      if (!structure.isType(parent, _container.default.WIDGET_ROW) && structure.isType(child, _container.default.WIDGET_COLUMN)) {
        return true;
      } // If column has more than 1 child and each child must be wrapped into row -> column -> item


      if (structure.isType(parent, _container.default.WIDGET_COLUMN) && !structure.isType(child, _container.default.WIDGET_ROW) && !structure.isType(child, _module.default.MODULE_SYSTEM) && parent.children.length !== 1) {
        return true;
      } // Any widget or module must be wrapped into column


      return !structure.isContainerType(child) && !structure.isType(child, _module.default.MODULE_SYSTEM) && !structure.isType(parent, _container.default.WIDGET_COLUMN);
    },

    /**
     * Create new widget-column element
     * @returns {Object} - widget-column element
     */
    createColumn() {
      return {
        type: 'widget-column',
        size: {
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12
        },
        children: []
      };
    },

    /**
     * Create new widget-row element
     * @returns {Object} - widget-row element
     */
    createRow() {
      return {
        type: 'widget-row',
        children: []
      };
    }

  });

  _exports.default = _default;
});