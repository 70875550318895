define("builder/core/form/hyundai-essential-fields.config", ["exports", "builder/core/enumerators/form-fields"], function (_exports, _formFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hyundaiEssentialFieldsMap = _exports.HYUNDAI_GROUP_KEY = void 0;
  const HYUNDAI_GROUP_KEY = '8';
  _exports.HYUNDAI_GROUP_KEY = HYUNDAI_GROUP_KEY;
  const genericEssentialFields = {
    types: [_formFields.default.EMAIL, _formFields.default.ZIP_CODE, _formFields.default.FIRST_NAME, _formFields.default.LAST_NAME],
    names: ['tcpa-consent', 'preferred-contact']
  };
  const contactUsEssentialFields = {
    types: [...genericEssentialFields.types, _formFields.default.DEPARTMENT],
    names: [...genericEssentialFields.names]
  };
  const hyundaiEssentialFieldsMap = {
    genericEssentialFields,
    contactUsEssentialFields
  };
  _exports.hyundaiEssentialFieldsMap = hyundaiEssentialFieldsMap;
});