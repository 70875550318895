define("builder/pods/components/sm-range-slider/component", ["exports", "builder/core/abstract-classes/variable-component", "builder/core/styles-management/constants"], function (_exports, _variableComponent, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const UNITS_REGEXP = /[a-zA-Z]+/;
  /**
   * Component helps to change css property NUMBER values in styles management UI
   *
   * Basic usage:
   * 		{{sm-range-slider
   * 			variableName: 'var',
   * 			cssPropertyName: 'margin-top',
   * 			variablesModel: model,
   * 			label="label"
   * 			updateVariable=(action "updateVariable")
   * 			mode="lg"
   * 		}}
   */

  let _class = (_dec = Ember.computed('unit'), _dec2 = Ember.computed('unit'), _dec3 = Ember.computed('unit'), _dec4 = Ember.computed('variable', 'variable.value'), _dec5 = Ember.computed('unit'), _dec6 = Ember.computed('variable', 'variable.value'), _dec7 = Ember._action, (_class2 = (_temp = class _class2 extends _variableComponent.default {
    constructor(...args) {
      super(...args);
      this.classNames = ['line-box__line', 'line-box__inner'];
    }

    /**
     * Min range value
     * @type {number}
     * @readonly
     */
    get min() {
      var _this$options, _this$options$this$mo, _this$options$this$mo2, _this$options$this$mo3;

      const unit = this.unit || 'multiplier';
      let value = (_this$options = this.options) === null || _this$options === void 0 ? void 0 : (_this$options$this$mo = _this$options[this.mode]) === null || _this$options$this$mo === void 0 ? void 0 : (_this$options$this$mo2 = _this$options$this$mo.range) === null || _this$options$this$mo2 === void 0 ? void 0 : (_this$options$this$mo3 = _this$options$this$mo2[unit]) === null || _this$options$this$mo3 === void 0 ? void 0 : _this$options$this$mo3.min;

      if (!value) {
        var _this$options2, _this$options2$global, _this$options2$global2, _this$options2$global3;

        value = (_this$options2 = this.options) === null || _this$options2 === void 0 ? void 0 : (_this$options2$global = _this$options2.global) === null || _this$options2$global === void 0 ? void 0 : (_this$options2$global2 = _this$options2$global.range) === null || _this$options2$global2 === void 0 ? void 0 : (_this$options2$global3 = _this$options2$global2[unit]) === null || _this$options2$global3 === void 0 ? void 0 : _this$options2$global3.min;
      }

      return isNaN(value) || value === null ? 0 : value;
    }
    /**
     * Max range value
     * @type {number}
     * @readonly
     */


    get max() {
      var _this$options3, _this$options3$this$m, _this$options3$this$m2, _this$options3$this$m3;

      const unit = this.unit || 'multiplier';
      let value = (_this$options3 = this.options) === null || _this$options3 === void 0 ? void 0 : (_this$options3$this$m = _this$options3[this.mode]) === null || _this$options3$this$m === void 0 ? void 0 : (_this$options3$this$m2 = _this$options3$this$m.range) === null || _this$options3$this$m2 === void 0 ? void 0 : (_this$options3$this$m3 = _this$options3$this$m2[unit]) === null || _this$options3$this$m3 === void 0 ? void 0 : _this$options3$this$m3.max;

      if (!value) {
        var _this$options4, _this$options4$global, _this$options4$global2, _this$options4$global3;

        value = (_this$options4 = this.options) === null || _this$options4 === void 0 ? void 0 : (_this$options4$global = _this$options4.global) === null || _this$options4$global === void 0 ? void 0 : (_this$options4$global2 = _this$options4$global.range) === null || _this$options4$global2 === void 0 ? void 0 : (_this$options4$global3 = _this$options4$global2[unit]) === null || _this$options4$global3 === void 0 ? void 0 : _this$options4$global3.max;
      }

      return isNaN(value) || value === null ? this._getDefaultMaxValue(this.unit) : value;
    }
    /**
     * Max input range value
     * @type {number}
     * @readonly
     */


    get inputMax() {
      var _this$options5, _this$options5$this$m, _this$options5$this$m2, _this$options5$this$m3;

      const unit = this.unit || 'multiplier';
      let value = (_this$options5 = this.options) === null || _this$options5 === void 0 ? void 0 : (_this$options5$this$m = _this$options5[this.mode]) === null || _this$options5$this$m === void 0 ? void 0 : (_this$options5$this$m2 = _this$options5$this$m.rangeInput) === null || _this$options5$this$m2 === void 0 ? void 0 : (_this$options5$this$m3 = _this$options5$this$m2[unit]) === null || _this$options5$this$m3 === void 0 ? void 0 : _this$options5$this$m3.max;

      if (!value) {
        var _this$options6, _this$options6$global, _this$options6$global2, _this$options6$global3;

        value = (_this$options6 = this.options) === null || _this$options6 === void 0 ? void 0 : (_this$options6$global = _this$options6.global) === null || _this$options6$global === void 0 ? void 0 : (_this$options6$global2 = _this$options6$global.rangeInput) === null || _this$options6$global2 === void 0 ? void 0 : (_this$options6$global3 = _this$options6$global2[unit]) === null || _this$options6$global3 === void 0 ? void 0 : _this$options6$global3.max;
      }

      return isNaN(value) || value === null ? this._getDefaultMaxValue(this.unit) : value;
    }
    /**
     * Measurement unit
     * @type {string}
     */


    get unit() {
      var _this$variable, _UNITS_REGEXP$exec;

      if (((_this$variable = this.variable) === null || _this$variable === void 0 ? void 0 : _this$variable.value) === '') {
        return 'px';
      }

      const unit = (_UNITS_REGEXP$exec = UNITS_REGEXP.exec(this.variable.value)) === null || _UNITS_REGEXP$exec === void 0 ? void 0 : _UNITS_REGEXP$exec[0];

      if (!unit) {
        return '';
      }

      return unit;
    }
    /**
     * Count numbers after dot
     * @type {number}
     */


    get digits() {
      switch (this.unit) {
        case 'px':
          return _constants.PX_DIGITS;

        case 'em':
          return _constants.EM_DIGITS;

        case '':
          return _constants.MULTIPLIER_DIGITS;

        default:
          return _constants.DEFAULT_DIGITS;
      }
    }
    /**
     * Variable value
     * @type {number}
     * @readonly
     */


    get value() {
      var _this$variable2, _this$variable3;

      if (((_this$variable2 = this.variable) === null || _this$variable2 === void 0 ? void 0 : _this$variable2.value) === '') {
        return 0;
      }

      return parseFloat((_this$variable3 = this.variable) === null || _this$variable3 === void 0 ? void 0 : _this$variable3.value);
    }

    _getDefaultMaxValue(units) {
      switch (units) {
        case 'px':
          return 50;

        case 'em':
          return 2;

        default:
          return 2;
      }
    }
    /**
     * Action pass data up to store changes
     *
     * @param {number} value - new value
    */


    onChange(value) {
      this.send('update', `${value}${this.unit}`);
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "min", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "min"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "max", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "max"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputMax", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputMax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unit", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "unit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "digits", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "digits"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "value", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "value"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onChange", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "onChange"), _class2.prototype)), _class2));

  _exports.default = _class;
});