define("builder/services/company-and-website", ["exports", "builder/core/enumerators/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WEBSITE_PARAM_NAME = _exports.COMPANY_PARAM_NAME = void 0;
  const COMPANY_PARAM_NAME = _cookie.default.COMPANY_ID;
  _exports.COMPANY_PARAM_NAME = COMPANY_PARAM_NAME;
  const WEBSITE_PARAM_NAME = _cookie.default.WEBSITE_ID;
  /**
   * @class CompanyAndWebsiteService
   */

  _exports.WEBSITE_PARAM_NAME = WEBSITE_PARAM_NAME;

  var _default = Ember.Service.extend({
    /**
     * @property {String|Null} _websiteId - website id
     * @private
     */
    _websiteId: null,

    /**
     * @property {String|Null} _companyId - company id
     * @private
     */
    _companyId: null,

    /**
     * @inheritDoc
     */
    init(...args) {
      this.set('_websiteId', Cookies.get(WEBSITE_PARAM_NAME));
      this.set('_companyId', Cookies.get(COMPANY_PARAM_NAME));

      this._super(...args);
    },

    /**
     * Returns website id
     * @returns {String|Null}
     */
    getWebsiteId() {
      return this._websiteId;
    },

    /**
     * Sets website id
     * @param {String} id - Website id
     */
    setWebsiteId(id) {
      this.set('_websiteId', id);
    },

    /**
     * Returns company id
     * @returns {String|Null}
     */
    getCompanyId() {
      return this._companyId;
    },

    /**
     * Sets company id
     * @param {String} id - Company id
     */
    setCompanyId(id) {
      this.set('_companyId', id);
    },

    /**
     * Returns company and website GET params
     * @returns {String}
     */
    getCompanyWebsiteParams() {
      const websiteId = this.getWebsiteId();
      const companyId = this.getCompanyId();
      return `${COMPANY_PARAM_NAME}=${companyId}&${WEBSITE_PARAM_NAME}=${websiteId}`;
    }

  });

  _exports.default = _default;
});