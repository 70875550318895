define("builder/pods/components/page-meta-export/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v7W6FiOt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",false],[3,\"on\",[\"click\",[23,0,[\"serializePageMeta\"]]]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus-circle\"],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Export File\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/page-meta-export/template.hbs"
    }
  });

  _exports.default = _default;
});