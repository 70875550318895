define("builder/core/froala/links/link-remove-button", ["exports", "froala-editor", "builder/core/froala/constants"], function (_exports, _froalaEditor, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkRemoveEvent = _exports.linkRemoveCommand = void 0;

  /* eslint-disable new-cap */
  const linkRemoveCommand = 'link-remove';
  _exports.linkRemoveCommand = linkRemoveCommand;
  const linkRemoveEvent = 'link-remove-event';
  _exports.linkRemoveEvent = linkRemoveEvent;

  _froalaEditor.default.DefineIcon(linkRemoveCommand, {
    NAME: 'unlink',
    SVG_KEY: 'unlink'
  });

  _froalaEditor.default.RegisterCommand(linkRemoveCommand, {
    title: 'Remove Link',
    focus: false,
    undo: false,
    refreshAfterCallback: false,

    callback() {
      const link = this.link.get();

      if (!link) {
        return;
      }

      const linkId = link.getAttribute(_constants.LINK_DATA_ATTRIBUTE);
      this.link.remove();
      this.events.trigger(linkRemoveEvent, [linkId]);
    }

  });
});