define("builder/pods/website/edit/index/route", ["exports", "builder/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Redirect from dashboard page always if 'index' page exists or on first page in list.
     *
     * @inheritdoc
     */
    redirect(model) {
      const websiteModel = this.modelFor('website.edit');
      const itemToRedirect = websiteModel.menu.getHomePage() || websiteModel.menu.getFirstPage();

      if (itemToRedirect) {
        const pageWhereRedirect = model.pages.find(function (page) {
          return page.get('Alias') === itemToRedirect.get('link.value') && !page.get('isDeleted');
        });

        if (pageWhereRedirect) {
          this.transitionTo('website.edit.page.edit', pageWhereRedirect.get('id'));
        } else {
          // If page not found in page list we try redirect to first page in list, if not - throw error and user can see application error
          const firstPage = model.pages.find(page => !page.get('isDeleted'));

          if (firstPage) {
            window.console.warn(`Page with alias "${itemToRedirect.get('link.value')}" is exists in menu, but not exists in pages.`);
            this.transitionTo('website.edit.page.edit', firstPage.get('id'));
          } else {
            throw new Ember.Error(`Page with alias "${itemToRedirect.get('link.value')}" is exists in menu, but not exists in pages.`);
          }
        }
      } else {
        // Hide page tools panel
        this.controllerFor('website.edit.page').set('isShowPageTools', false);
        this.transitionTo('website.edit.page.create');
      }
    }

  });

  _exports.default = _default;
});