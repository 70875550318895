define("builder/services/rendering-global-visibility", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class
   * @implements {RenderingGlobalVisibilityService}
   */
  let _class = (_dec = Ember.inject.service('device'), (_class2 = (_temp = class _class2 extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "deviceService", _descriptor, this);

      this.globalVisibility = {
        [_breakpointModes.LARGE]: false,
        [_breakpointModes.SMALL]: false,
        [_breakpointModes.MEDIUM]: false,
        [_breakpointModes.EXTRA_SMALL]: false
      };
    }

    /**
     * toggles global visibility for current device breakpoint
     * @returns {void}
     */
    toggleGlobalVisibility() {
      const device = this.deviceService.getDevice();

      if (!device || !device.breakpoint) {
        return;
      }

      const {
        breakpoint
      } = device;
      const globalVisibility = { ...this.globalVisibility,
        [breakpoint]: !this.globalVisibility[breakpoint]
      };
      this.setProperties({
        globalVisibility
      });
    }
    /**
     * Gets global visibility for current device breakpoint
     * @returns {boolean}
     */


    getCurrentDeviceVisibility() {
      const device = this.deviceService.getDevice();

      if (!device || !device.breakpoint) {
        return false;
      }

      const {
        breakpoint
      } = device;
      return this.globalVisibility[breakpoint];
    }
    /**
     * Resets global visibility to default values
     * @returns {void}
     */


    clearVisibility() {
      this.set('globalVisibility', {
        [_breakpointModes.LARGE]: false,
        [_breakpointModes.SMALL]: false,
        [_breakpointModes.MEDIUM]: false,
        [_breakpointModes.EXTRA_SMALL]: false
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "deviceService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});