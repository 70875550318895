define("builder/serializers/style", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/breakpoint-modes"], function (_exports, _rest, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleStyleSerializer
   */
  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    normalize(model, props, ...args) {
      const variables = props.StyleVariables;

      if (variables) {
        Object.keys(variables).forEach(variableName => {
          props[variableName] = variables[variableName];
        });
      }

      if (!props.StyleCSS) {
        props.StyleCSS = '';
      }

      delete props.StyleVariables;
      return this._super(model, props, ...args);
    },

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args);

      json.StyleVariables = {};
      Object.keys(json).forEach(prop => {
        switch (prop) {
          case _breakpointModes.GLOBAL:
          case _breakpointModes.LARGE:
          case _breakpointModes.MEDIUM:
          case _breakpointModes.SMALL:
          case _breakpointModes.EXTRA_SMALL:
            json.StyleVariables[prop] = json[prop];
            delete json[prop];
            break;
        }
      });
      return json;
    }

  });

  _exports.default = _default;
});