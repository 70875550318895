define("builder/core/form/is-field-removable", ["exports", "builder/core/form/default-essential-fields.config", "builder/core/form/hyundai-essential-fields.config"], function (_exports, _defaultEssentialFields, _hyundaiEssentialFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFieldRemovable = isFieldRemovable;

  /**
   * Some OEMs need to configure required fields with restriction
   * to remove them from form.
   * Module contains such definitions for essential form fields.
   * Configuration tree look like:
   * -FormTypeMap
   * -----WebsiteGroupMap
   * ---------field names that should not be removed
   * ---------field types that should not be removed
   * @module
   */

  /**
   * Configuration map for all form types
   * Key - website group id
   * Value - configuration object
   * @type {Map<string, EssentialFieldsConfig>}
   */
  const genericFormMap = new Map().set(_defaultEssentialFields.DEFAULT_GROUP_KEY, _defaultEssentialFields.defaultEssentialFieldsMap).set(_hyundaiEssentialFields.HYUNDAI_GROUP_KEY, _hyundaiEssentialFields.hyundaiEssentialFieldsMap.genericEssentialFields);
  /**
   * Configuration map for Contact Us form type
   * Key - website group id
   * Value - configuration object
   * @type {Map<string, EssentialFieldsConfig>}
   */

  const contactUsFormMap = new Map().set(_defaultEssentialFields.DEFAULT_GROUP_KEY, _defaultEssentialFields.defaultEssentialFieldsMap).set(_hyundaiEssentialFields.HYUNDAI_GROUP_KEY, _hyundaiEssentialFields.hyundaiEssentialFieldsMap.contactUsEssentialFields);
  const GENERIC_FORM_KEY = 'generic';
  const CONTACT_US_FORM_KEY = 'contact-us';
  /**
   * Configuration map for essential fields
   * Key - form type name
   * Value - configuration map for form type
   * @type {Map<string, Map<string, EssentialFieldsConfig>>}
   */

  const essentialFields = new Map().set(GENERIC_FORM_KEY, genericFormMap).set(CONTACT_US_FORM_KEY, contactUsFormMap);
  /**
   * Function checks wether field could be removed from form or not
   * @param {string} fieldType - type of field to check
   * @param {string} fieldName - name of field to check
   * @param {string[]} websiteGroups - current website groups ids
   * @param {string} formType - form type to check
   * @returns {boolean} - check result
   * @function
   */

  function isFieldRemovable(fieldType, fieldName, websiteGroups, formType) {
    const providedGroups = websiteGroups || [];
    const formRules = essentialFields.get(formType) || essentialFields.get(GENERIC_FORM_KEY);
    const groups = ['default', ...providedGroups];
    const rules = groups.map(group => formRules.get(group)).filter(rule => rule);
    const typeRules = rules.flatMap(rule => rule.types);
    const nameRules = rules.flatMap(rule => rule.names);
    const isTypeEssential = typeRules.some(type => type === fieldType);
    const isNameEssential = nameRules.some(name => name === fieldName);
    return !isTypeEssential && !isNameEssential;
  }
});