define("builder/pods/components/field-media-active-area-image-compact/component", ["exports", "builder/pods/components/field-media-active-area/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldMediaActiveAreaImageCompact
   */
  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    tagName: 'div',

    /**
     * @inheritdoc
     */
    classNames: ['flex']
  });

  _exports.default = _default;
});