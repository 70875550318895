define("builder/pods/components/field-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EyHpkUrw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"widget-field-info\",[],[[\"@field\"],[[22,\"field\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"switcher-button\",[],[[\"@change\",\"@isActive\"],[[28,\"action\",[[23,0,[]],\"switch\"],null],[22,\"isActive\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-switcher/template.hbs"
    }
  });

  _exports.default = _default;
});