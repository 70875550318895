define("builder/serializers/website-style", ["exports", "builder/serializers/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _style.default.extend({
    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args);

      return json;
    }

  });

  _exports.default = _default;
});