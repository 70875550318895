define("builder/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class LocalStorageService
   */
  var _default = Ember.Service.extend({
    /**
     * Get value from localStorage
     * @method getItem
     * @param {string} key - key
     * @param {*} value - value
     * @returns {*}
     */
    getItem(key) {
      return this.localStorageIsAvailable() && !Ember.isEmpty(key) ? JSON.parse(window.localStorage.getItem(key)) : null;
    },

    /**
     * Set value into localStorage
     * @method setItem
     * @param {string} key - key
     * @param {*} value - value
     */
    setItem(key, value) {
      if (this.localStorageIsAvailable() && !Ember.isEmpty(key) && !Ember.isEmpty(value)) {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    },

    /**
     * Remove key from localStorage
     * @method removeItem
     * @param {string} key - key
     */
    removeItem(key) {
      if (this.localStorageIsAvailable()) {
        window.localStorage.removeItem(key);
      }
    },

    /**
     * Check localStorage availability
     * @method localStorageIsAvailable
     * @return {boolean}
     */
    localStorageIsAvailable() {
      return window.localStorage && typeof window.localStorage.getItem === 'function' && typeof window.localStorage.setItem === 'function' && typeof window.localStorage.removeItem === 'function';
    }

  });

  _exports.default = _default;
});