define("builder/pods/website/edit/page/edit/module-gallery/controller", ["exports", "builder/controllers/base", "builder/core/enumerators/module", "builder/core/enumerators/gallery"], function (_exports, _base, _module, _gallery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPE_MODULE = 0;
  const TYPE_WIDGET = 1;
  const TYPE_FORM = 2; // Convert data to be passed to module-gallery component via computed property

  var _default = _base.default.extend({
    /**
     * @property {Ember.Controller} pageController - page controller
     */
    pageController: Ember.inject.controller('website/edit/page'),

    /**
     * @property {ModulesStoreService} modules - modules store service
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * @property {Ember.Service} gridBuilder - grid builder service
     */
    gridBuilder: Ember.inject.service('grid-builder'),

    /**
     * @property {Ember.Service} previewService - preview service
     */
    previewService: Ember.inject.service('preview'),

    /**
     * @property {ModuleConfigurationService} moduleConfigurationService - module configuration service
     */
    moduleConfigurationService: Ember.inject.service('module-configuration'),

    /**
     * @property {Ember.Service} features - feature toggling service
     */
    features: Ember.inject.service(),

    /**
     * Dynamic part of route. @TODO separate to two separated routes
     */
    routeDynamicSegment: null,

    /**
     * @property {Number} currentConfigId - current config id (comes from route)
     */
    currentConfigId: null,
    moduleMenuActive: Ember.computed('routeDynamicSegment', function () {
      const types = {
        system: false,
        base: false
      };

      if (this.routeDynamicSegment === 'module-gallery') {
        types.base = true;
      } else if (this.routeDynamicSegment === 'system-modules') {
        types.system = true;
      }

      return types;
    }),
    css: {
      top: 0,
      maxWidth: '100%',
      left: 0,
      right: 0,
      padding: 0,
      'z-index': 99,
      '-webkit-box-shadow': '1px 8px 6px rgba(0, 0, 0, 0.1)',
      'box-shadow': '1px 8px 6px rgba(0, 0, 0, 0.1)'
    },

    /**
     * @property {GalleryModule[]} categories - gallery modules to be passed to module gallery
     */
    categories: Ember.computed('model.page', 'model.{categories,modules,formTemplates,widgets,themes,categoryItems,containerTemplates,containerTemplateCategories}.length', function () {
      const {
        model
      } = this;
      const categories = this.prepareCategories(model.categories, // Skip form module, since it will be placed directly into form category
      model.modules.filter(module => module.get('Name') !== 'Form'), model.formTemplates, model.widgets, model.themes.filterBy('IsSystem', 0), model.categoryItems, model.containerTemplateCategories, model.containerTemplates, this.model.page);
      return categories;
    }),

    /**
     * Create gallery modules
     * @param {DS.Model<ModuleCategoryModel>[]} categories - categories models
     * @param {DS.Model<ModuleModel>[]} modules - modules models
     * @param {DS.Model<FormModel>[]} forms - forms models
     * @param {DS.Model<WidgetModel>[]} widgets - widgets models
     * @param {DS.Model<ModuleThemeModel>[]} moduleThemes - themes models
     * @param {DS.Model<ModuleCategoryItemModel>[]} map - module category items models
     * @param {DS.Model<ContainerTemplateCategoryModel>[]} containerTemplateCategories - container template categories models
     * @param {DS.Model<ContainerTemplateModel>[]} containerTemplates - container templates models
     * @param {PageModel} currentPage - current page model
     * @returns {CategoryGalleryModuleDecorator[]}
     */
    prepareCategories(categories, modules, forms, widgets, moduleThemes, map, containerTemplateCategories, containerTemplates, currentPage) {
      const factory = Ember.getOwner(this).lookup('factory:module-gallery');
      const galleryCategories = categories.map(category => factory.createCategory(category));
      const galleryModules = modules.map(module => factory.createModule(module));
      const galleryThemes = moduleThemes.map(theme => factory.createModuleTheme(theme, currentPage));
      const galleryForms = forms.map(form => factory.createForm(form, currentPage));
      const galleryWidgets = widgets.map(widget => factory.createWidget(widget, currentPage));
      const galleryContainerTemplates = containerTemplates.map(template => factory.createContainerTemplate(template, currentPage));
      this.linkModules(galleryModules, galleryThemes);
      this.linkCategories(galleryCategories, galleryModules, galleryForms, galleryWidgets, map);
      const categoryAll = this.buildAllCategory(galleryModules, galleryForms, galleryWidgets, galleryContainerTemplates);
      const categoryModuleGroups = this.buildModuleGroupsCategory(containerTemplateCategories, containerTemplates, currentPage);
      galleryCategories.insertAt(0, categoryModuleGroups);
      galleryCategories.insertAt(1, categoryAll);
      return galleryCategories;
    },

    /**
     * Create category "All"
     * @param {ModuleGalleryModuleDecorator[]} modules - decorated modules
     * @param {FormGalleryModuleDecorator[]} forms - decorated forms
     * @param {WidgetGalleryModuleDecorator[]} widgets - decorated widgets
     * @param {ContainerTemplates[]} containerTemplates - decorated container templates
     * @returns {CategoryGalleryModuleDecorator}
     */
    buildAllCategory(modules, forms, widgets, containerTemplates) {
      const factory = Ember.getOwner(this).lookup('factory:module-gallery');
      const category = factory.createCategory({
        Name: 'All Modules'
      });
      const allModules = [...modules, ...widgets];
      category.set('children', allModules);
      return category;
    },

    /**
     * Create category "Snapshot Studio" contains container templates
     * @param {DS.Model<ContainerTemplateCategory>[]} containerTemplateCategories - container template categories
     * @param {DS.Model<ContainerTemplate>[]} containerTemplates - container templates
     * @param {PageModel} currentPageModel - current page model
     * @returns {CategoryGalleryModuleDecorator}
     */
    buildModuleGroupsCategory(containerTemplateCategories, containerTemplates, currentPageModel) {
      const factory = Ember.getOwner(this).lookup('factory:module-gallery');
      const category = factory.createCategory({
        Name: _gallery.SNAPSHOT_STUDIO
      });
      const templatesMap = containerTemplates.reduce((hash, model) => {
        hash[model.get('id')] = model;
        return hash;
      }, {});
      const subcategories = containerTemplateCategories.map(categoryModel => {
        const subcategory = factory.createCategory(categoryModel, 'icon-gallery-all');
        const subcategoryChildren = subcategory.get('children');
        const templatesIds = categoryModel.get('Templates');
        templatesIds.forEach(templateId => {
          const templateModel = templatesMap[templateId];

          if (templateModel) {
            const templateItem = factory.createContainerTemplate(templateModel, currentPageModel);
            templateItem.set('parent', subcategory);
            subcategoryChildren.pushObject(templateItem);
          }
        });
        return subcategory;
      });
      const subcategoryAll = factory.createCategory({
        Name: _gallery.SnapshotCategories.ALL_SNAPSHOTS
      });
      subcategoryAll.set('children', containerTemplates.map(templateModel => {
        const child = factory.createContainerTemplate(templateModel, currentPageModel);
        child.set('parent', subcategoryAll);
        return child;
      }));
      category.set('children', [subcategoryAll, ...subcategories]);
      return category;
    },

    /**
     * TODO: Move somewhere else
     *
     * @param {ModuleGalleryModuleDecorator[]} modules - list of gallery modules to be linked with themes
     * @param {WidgetModuleGalleryDecorator[]} themes - list of themes to be pushed to modules
     * @returns {ModuleGalleryModuleDecorator[]}
     */
    linkModules(modules, themes) {
      modules.forEach(module => {
        const moduleThemes = themes.filterBy('data.ModuleId', module.get('data.id'));
        moduleThemes.forEach(theme => {
          theme.set('parent', module);
        });
        module.set('children', moduleThemes);
      });
      return modules;
    },

    /**
     * TODO: move somewhere else
     *
     * @param {CategoryGalleryModuleDecorator[]} categories
     * @param {ModuleGalleryModuleDecorator[]} modules
     * @param {FormGalleryModuleDecorator[]} forms
     * @param {WidgetGalleryModuleDecorator[]} widgets
     * @param {ModuleCategoryItemModel[]} map
     * @returns {CategoryGalleryModuleDecorator[]}
     */
    linkCategories(categories, modules, forms, widgets, map) {
      const sets = {
        [TYPE_MODULE]: modules,
        [TYPE_FORM]: forms,
        [TYPE_WIDGET]: widgets
      };
      map.forEach(mapPiece => {
        const category = categories.findBy('data.id', mapPiece.get('CategoryId'));
        const searchSet = sets[mapPiece.get('Type')];

        if (!searchSet) {
          throw new Ember.Error(`Unknown type, cant find appropriate set for "${mapPiece.get('Type')}" type`);
        }

        const item = searchSet.findBy('data.id', mapPiece.get('ItemId'));

        if (item) {
          item.set('parent', category);
          category.get('children').pushObject(item);
        }
      });
      return categories;
    },

    /**
     * Creates page structure object for system module
     * @param {DS.Model<SystemModuleModel>} module - System module model object
     * @returns {Object}
     */
    createSystemModuleStructure(module) {
      const theme = module.selectedTheme;
      const {
        gridBuilder
      } = this;
      const id = module.ModuleConfigurationId || null;
      return gridBuilder.createInstance(_module.default.MODULE_SYSTEM, {
        id,
        theme: theme.Name,
        name: module.Module
      });
    },

    /**
     * Create page-module model record
     * @param {Object} module - System module model object
     * @returns {Object}
     */
    createModuleConfig(module) {
      const theme = module.selectedTheme;
      const config = this.moduleConfigurationService.createModuleConfiguration(theme);
      return this.store.createRecord('page-module', config);
    },

    /**
     * Creates module style record
     * @param {DS.Model<ModuleStyle>} themeModel - Module theme model object
     * @param {Number} configId - Id of the page-module model
     * @returns {DS.Model<ModuleStyleModel>}
     */
    createModuleStyle(themeModel, configId) {
      const themeId = themeModel.id;
      const json = themeModel.toJSON().StyleVariables;
      json.id = configId;
      json.ThemeId = themeId;
      json.styleMap = themeModel.StyleMap;
      json.StyleCSS = '';
      json.moduleThemeModel = themeModel;
      json.globalVariablesModel = this.store.peekRecord('website-style', this.get('configurationService.website.id'));
      return this.store.createRecord('module-style', json);
    },

    /**
     * Update system module page structure
     * @param {Object} structure - System module page structure
     * @param {Object} config - Page module model
     * @param {Object} [theme] - Module theme model
     */
    updateStructure(structure, config, theme) {
      structure.set('config', config);
      structure.get('originObject').set('id', parseInt(config.id, 10));

      if (theme) {
        const themeModel = this.store.peekRecord('module-theme', theme.id);

        if (themeModel && themeModel.StyleVariables) {
          structure.set('moduleStyleModel', this.createModuleStyle(themeModel, config.id));
        }
      }
    },

    /**
     * Remove all module structures (from modules store service) in order to exclude it from saving
     * @param {Object} module - system module to remove from modules-store
     * @param {Boolean} [reload] - reload modules after remove
     */
    removeModule(module, reload = true) {
      this.modules.removeBy({
        type: _module.default.MODULE_SYSTEM,
        name: module.Module
      }, false, reload);
    },

    /**
     * Create page module model and page structure for system module
     * @param {Object} module - System module model
     * @returns {Promise}
     */
    createSysModuleConfigAndStructure(module) {
      return this.createModuleConfig(module).save().then(config => {
        const theme = module.selectedTheme;
        const structure = this.createSystemModuleStructure(module);
        module.set('ModuleConfigurationId', parseInt(config.id, 10));
        this.updateStructure(structure, config, theme);
        this.addSysModuleStructure(structure);
      });
    },

    /**
     * Returns module config record
     * @param {Number} id - Page module id
     * @returns {Promise}
     */
    getModuleConfig(id) {
      let config = this.store.peekRecord('page-module', id);

      if (!config) {
        config = this.store.findRecord('page-module', id);
      } else {
        config = Ember.RSVP.resolve(config);
      }

      return config;
    },

    /**
     * Create system module structure
     * @param {Object} module - System module model
     * @returns {Promise}
     */
    createAndAddSysModuleStructure(module) {
      return this.getModuleConfig(module.ModuleConfigurationId).then(config => {
        const structure = this.createSystemModuleStructure(module);
        this.updateStructure(structure, config);
        this.addSysModuleStructure(structure);
      });
    },

    /**
     * Adds system module structure to modules store
     * @param {Object} structure - System module page structure
     */
    addSysModuleStructure(structure) {
      this.modules.add(structure);
    },

    /**
     * Starts system module loading
     * @param {Object} module - System module model
     */
    startLoading(module) {
      module.set('isLoadingNow', true);
    },

    /**
     * Stops system module loading
     * @param {Object} module - System module model
     */
    stopModuleLoading(module) {
      module.set('isLoadingNow', false);
    },

    /**
     * Enables system module
     * @param {Object} module - System module model
     */
    enableModule(module) {
      this.startLoading(module); // When system module record is new but didn't modified

      if (!module.ModuleConfigurationId && module.isNew) {
        this.createSysModuleConfigAndStructure(module).then(() => this.stopModuleLoading(module)); // When system module already has config id. It could be new system module
        // which was enabled->disabled->enabled or system module came from backend
      } else if (module.ModuleConfigurationId) {
        this.createAndAddSysModuleStructure(module).then(() => this.stopModuleLoading(module));
      }
    },

    /**
     * Opens new module config if current is opened now or closes
     * @param {SystemModuleModel} currentSystemModule - Current opened system module
     * @param {SystemModuleModel} module - New system module
     */
    openOrCloseModuleConfig(currentSystemModule, module) {
      if (module.get('hasConfig')) {
        // If config still opened and opened module config theme has changed
        if (this.currentConfigId && currentSystemModule.Module === module.Module) {
          this.send('goToConfig', module.ModuleConfigurationId);
        }
      } else {
        // When theme has no config available, close config popup
        this.send('closeConfig');
      }
    },

    /**
     * Returns current opened system module
     * @returns {undefined|SystemModuleModel}
     */
    getCurrentSystemModule() {
      return this.model.systemModules.findBy('ModuleConfigurationId', parseInt(this.currentConfigId, 10));
    },

    actions: {
      /**
       * Toggle module check/uncheck handler
       *
       * @param {Object} module - checked/unchecked system module
       * @param {Boolean} isChecked - is module checked or unchecked
       */
      toggleModule(module, isChecked) {
        module.set('IsActive', isChecked ? 1 : 0);

        if (!isChecked) {
          const moduleConfig = this.getCurrentSystemModule();
          this.removeModule(module); // If current module config opened, close it after uncheck

          if (moduleConfig === module) {
            this.send('closeConfig');
          }
        } else if (module.get('IsActive') && !module.get('isLoadingNow')) {
          this.enableModule(module);
        } // When module added/removed, modules preview should be updated


        this.previewService.set('rebuild', true);
        this.send('setChange');
      },

      /**
       * Handle module theme change
       *
       * @param {SystemModuleModel} module - system module which theme was changed
       * @param {ModuleThemeModel} theme - new selected theme
       */
      changeTheme(module, theme) {
        // Search for current module before createSysModuleConfigAndStructure because it changes ModuleConfigurationId
        const currentSystemModule = this.getCurrentSystemModule();
        module.set('Theme', theme.Name); // Only if checked module changes its theme, we should create new module config

        if (!module.get('IsActive')) {
          return;
        }

        this.startLoading(module); // Since module will be added, no need to update dependencies on module remove, dependencies will be updated
        // on module add

        this.removeModule(module, false);
        this.createSysModuleConfigAndStructure(module).then(() => {
          this.stopModuleLoading(module);
          this.openOrCloseModuleConfig(currentSystemModule, module);
          this.previewService.set('rebuild', true);
          this.send('setChange');
        });
      },

      /**
       * Handle system module cog click
       * @param {Number} configId - system module config id
       */
      cogClick(configId) {
        this.send('goToConfig', configId);
      },

      goToConfig(configId) {
        this.transitionToRoute('website.edit.page.edit.module-gallery.settings', configId);
      },

      closeConfig() {
        this.transitionToRoute('website.edit.page.edit.module-gallery');
      },

      /**
       * Action mark what any changes is occurred
       *
       * @param {String} [scope] - scope where change is occurred
       */
      setChange(scope) {
        this.pageController.send('setChanges', scope);
      },

      parentModuleAction(type, component, config) {
        this.send('moduleAction', type, component, config);
      },

      addHistoryCheckpoint(structure, type, data) {
        // Action in page/edit/route
        this.send('addModuleHistoryCheckpoint', structure, type, data);
      }

    }
  });

  _exports.default = _default;
});