define("builder/pods/components/field-contenteditable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B3BNbFjQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"widget-field-info\",[],[[\"@field\",\"@validation\"],[[22,\"field\"],[22,\"validationsMessage\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-field_content-editable_data\"],[10,\"contenteditable\",\"true\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"id\",[22,\"metapickerWrapperId\"]],[11,\"class\",[29,[\"metapicker-popup-wrapper \",[28,\"unless\",[[24,[\"isMetaPickerOpen\"]],\"metapicker-popup-wrapper__hidden\"],null]]]],[8],[0,\"\\n\\t\"],[5,\"metapicker-popup\",[],[[\"@searchQuery\",\"@columns\",\"@onVariableSelected\",\"@onClose\",\"@navigationCommand\"],[[22,\"searchQuery\"],[22,\"metaVariables\"],[28,\"action\",[[23,0,[]],\"onMetaVariableSelected\"],null],[28,\"action\",[[23,0,[]],\"onCloseMetaPicker\"],null],[22,\"metapickerNavigationCommand\"]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-contenteditable/template.hbs"
    }
  });

  _exports.default = _default;
});