define("builder/core/styles-management/utils/get-variables-structure", ["exports", "builder/core/styles-management/structure", "ember-copy"], function (_exports, _structure, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Function return parts of interface which variables bounds
   *
   * @param {String[]} variables - list variable names which parts should be found
   * @param {Array} result - storage where founded parts will be placed
   * @return {Array} - part of interface which variable bound
   * @function getVariablesStructure
   */
  function getVariablesStructure(variables = [], result = [], initialStructure) {
    const struct = initialStructure || _structure.default;

    for (let i = 0; i < struct.length; i += 1) {
      const part = struct[i];

      if (part.children && Array.isArray(part.children)) {
        getVariablesStructure(variables, result, part.children);
      }

      if (part.variableName && variables.includes(part.variableName)) {
        result.push((0, _emberCopy.copy)(part, false));
      }
    }

    return result;
  }

  var _default = getVariablesStructure;
  _exports.default = _default;
});