define("builder/services/configuration-page", ["exports", "builder/errors/global-page-menu-insertion-error"], function (_exports, _globalPageMenuInsertionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for operations with pages within configuration context
   */
  let ConfigurationPageService = (_dec = Ember.inject.service('store'), (_class = (_temp = class ConfigurationPageService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_store", _descriptor, this);
    }

    /**
     * Saves all pages
     * @param {PageSaveAllCommand} command
     * @returns {Promise<void>}
     */
    async saveAll({
      page,
      structure,
      isPageStructureChanged,
      notifyAboutGlobalPageMenuInsertionError
    }) {
      if (!page.isDeleted && isPageStructureChanged) {
        // Set serialized structure to "Structure" property
        // because it will be normalized in page/edit/route.js
        if (structure) {
          page.set('Structure', structure.children);
        }
      }

      const savePromises = this._store.peekAll('page').map(async model => {
        if (model.isDeleted || model.hasDirtyAttributes) {
          try {
            await model.save();
          } catch (error) {
            if (error instanceof _globalPageMenuInsertionError.default) {
              notifyAboutGlobalPageMenuInsertionError(error);
            } else {
              throw error;
            }
          }
        }
      });

      await Promise.all(savePromises);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ConfigurationPageService;
});