define("builder/core/util/define-fields-visibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defineFieldsVisibility = defineFieldsVisibility;

  /**
   * Assert functions map
   * @type {{[id:string]: (fieldValue:any, conditionValue:any) => boolean}}
   */
  const ASSERTS_MAP = {
    equals: (fieldValue, conditionValue) => Ember.isEqual(fieldValue, conditionValue),
    notEquals: (fieldValue, conditionValue) => !Ember.isEqual(fieldValue, conditionValue),
    isEmpty: (fieldValue, conditionValue) => conditionValue ? Ember.isEmpty(fieldValue) : !Ember.isEmpty(fieldValue)
  };
  /**
   * Applies visibility logic to all fields in module configuration according
   * showOnlyIf schema and current fields values recursively.
   * @param {object[]} moduleConfiguration - config for display config in popup
   * @return {object[]} - modified field/module config
   */

  function defineFieldsVisibility(moduleConfiguration, fieldConfiguration = null) {
    if (!moduleConfiguration) {
      return moduleConfiguration;
    }

    const configuration = fieldConfiguration || moduleConfiguration;
    configuration.forEach(field => {
      if (field.children && field.children.length) {
        defineFieldsVisibility(moduleConfiguration, field.children);
      }

      if (!field.showOnlyIf || !field.showOnlyIf.length) {
        return;
      }

      const isVisible = field.showOnlyIf.every(condition => {
        const assertName = Object.keys(condition).filter(prop => prop !== 'field').pop();
        const assert = ASSERTS_MAP[assertName];

        if (typeof assert !== 'function') {
          return false;
        }
        /**
         * We have different schemas for defining visibility rules.
         * One with absolute names path and another with strict field name.
         * For absolute path we need to walk through whole module configuration
         * tree, for relative path we just need to check current subtree
         * without children
         */


        const fieldValue = condition.field.startsWith('/') ? _getAbsoluteFieldValue(moduleConfiguration, condition.field) : _getRelativeFieldValue(configuration, condition.field);
        const conditionValue = condition[assertName];
        return assert(fieldValue, conditionValue);
      });
      Ember.set(field, '_visible', isVisible);
    });
    return moduleConfiguration;
  }
  /**
   * Gets value of field within field configuration by field name
   * @param {object[]} fieldConfiguration - field configuration
   * @param {string} fieldName - field name to search
   * @returns {any}
   */


  function _getRelativeFieldValue(fieldConfiguration, fieldName) {
    const field = fieldConfiguration.find(f => f.name === fieldName);

    if (field) {
      return field.data;
    }

    return null;
  }
  /**
   *	Gets value of field within hole module fields configuration
   * @param {object[]} moduleConfiguration - module configuration
   * @param {string} path - absolute field path. Each path chunk represents field name
   * @returns {any}
   */


  function _getAbsoluteFieldValue(moduleConfiguration, path) {
    const pathChunks = path.split('/').filter(x => x);
    let fields = moduleConfiguration;
    let field = null;

    for (let i = 0; i < pathChunks.length; i++) {
      field = fields.find(f => f.name === pathChunks[i]);

      if (!field) {
        // path is incorrect, no field found within provided path
        return null;
      }

      fields = field.children;
    }

    if (field) {
      return field.data;
    }

    return null;
  }
});