define("builder/pods/components/base-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property {String} defaultStyle - default button style
     */
    defaultStyle: 'default',
    contentLayout: 'horizontal',
    tagName: '',

    /**
     * @property {Object} styleClassNames - Button class names
     */
    styleClassNames: {
      default: 'btn-default',
      primary: 'btn-primary',
      tertiary: 'btn-tertiary',
      quaternary: 'btn-quaternary',
      quinary: 'btn-quinary'
    },

    /**
     * @property {Object|null} data - Button data
     */
    data: null,

    /**
     * @property {Boolean} isDefault - Whether button is default
     */
    styleClass: Ember.computed('data.style', function () {
      const style = this.data.style || this.defaultStyle;
      return this.styleClassNames[style];
    }),

    /**
     * @property {String} buttonSize - Whether button is large/small etc
     */
    sizeClass: '',

    /**
     * @property {String} width - Width of button
     */
    width: Ember.computed('data.width', function () {
      const buttonWidth = this.get('data.width');
      const widthValue = Math.abs(parseFloat(buttonWidth));
      const parentContainerMargin = '6px';
      let widthUnit = /\d(\D+)$/.exec(buttonWidth);
      let styleWidth = '';

      if (widthUnit && widthUnit.length) {
        widthUnit = widthUnit.pop();
      } else {
        widthUnit = 'px';
      }

      if (isFinite(widthValue)) {
        if (widthUnit === '%') {
          styleWidth = `min-width: calc(${widthValue}${widthUnit} - ${parentContainerMargin});`;
        } else {
          styleWidth = `min-width: ${widthValue}${widthUnit};`;
        }
      }

      return Ember.String.htmlSafe(styleWidth);
    })
  });

  _exports.default = _default;
});