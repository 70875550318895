define("builder/pods/website/edit/page/edit/page-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mbTuFhkc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"widget-popup\",null,[[\"class\",\"closeHandler\",\"isFixed\"],[\"widget-popup_theme_page-template\",[28,\"action\",[[23,0,[]],\"closePopup\"],null],true]],{\"statements\":[[0,\"\\t\"],[1,[28,\"page-template-editor\",null,[[\"title\",\"name\",\"visibility\",\"category\",\"thumbnail\",\"updateVisibility\",\"updateThumbnail\",\"removeThumbnail\",\"isSaveAllowed\",\"saveButtonText\",\"visibilities\",\"fetchCategories\",\"save\"],[[24,[\"literals\",\"PAGE_TEMPLATE_CREATE_TITLE\"]],[28,\"mut\",[[24,[\"model\",\"template\",\"Title\"]]],null],[28,\"mut\",[[24,[\"model\",\"template\",\"Visibility\"]]],null],[28,\"mut\",[[24,[\"model\",\"template\",\"Category\"]]],null],[24,[\"model\",\"template\",\"Preview\"]],[28,\"action\",[[23,0,[]],\"updateVisibility\"],null],[28,\"action\",[[23,0,[]],\"updateThumbnail\"],null],[28,\"action\",[[23,0,[]],\"removeThumbnail\"],null],[24,[\"isSaveAllowed\"]],[24,[\"literals\",\"PAGE_TEMPLATE_CREATE_BUTTON_TITLE\"]],[24,[\"model\",\"visibilities\"]],[28,\"action\",[[23,0,[]],\"fetchCategories\"],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/edit/page/edit/page-template/template.hbs"
    }
  });

  _exports.default = _default;
});