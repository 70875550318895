define("builder/core/meta-tags/commands", ["exports", "builder/core/enumerators/keyboard"], function (_exports, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMetapickerNavigationCommand = getMetapickerNavigationCommand;

  /**
   * Detects metapicker control keys
   * @returns {MetapickerNavigationCommand}
   */
  function getMetapickerNavigationCommand(keyCode) {
    const {
      UP,
      DOWN,
      ENTER
    } = _keyboard.default.KEY;

    switch (keyCode) {
      case UP:
        return {
          code: 'up'
        };

      case DOWN:
        return {
          code: 'down'
        };

      case ENTER:
        return {
          code: 'select'
        };
    }

    return null;
  }
});