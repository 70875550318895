define("builder/helpers/datetime-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDatetime = formatDatetime;
  _exports.default = void 0;
  const DEFAULT_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
  /**
   * Format date object into mm/dd/yyyy h:i a string format
   *
   * @param {*[]} params - 1-st argument is date to format
   * @returns {String|null}
   */

  function formatDatetime(params) {
    const date = params[0];

    if (!(date instanceof Date)) {
      return null;
    }

    const fillLeadingZero = function (value) {
      return value < 10 ? `0${value}` : value;
    };

    const replaceFunction = pattern => {
      switch (pattern) {
        case 'a':
          return date.getHours() >= 12 ? 'pm' : 'am';

        case 'dd':
          return fillLeadingZero(date.getDate());

        case 'MM':
          return fillLeadingZero(date.getMonth() + 1);

        case 'yyyy':
          return date.getFullYear();

        case 'hh':
          return fillLeadingZero(date.getHours());

        case 'mm':
          return fillLeadingZero(date.getMinutes());

        case 'ss':
          return fillLeadingZero(date.getSeconds());
      }

      return '';
    };

    const result = DEFAULT_TIME_FORMAT.replace(/dd|MM|yyyy|hh|mm|ss|a/g, replaceFunction);

    if (result.length <= 0) {
      return null;
    }

    return result;
  }
  /**
   * @class DatetimeFormatHelper
   */


  var _default = Ember.Helper.extend({
    compute(params) {
      return formatDatetime(params);
    }

  });

  _exports.default = _default;
});