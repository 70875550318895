define("builder/abilities/tier/route", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierRouteAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      'website.create': {
        entity: 'website',
        property: 'create'
      },
      'website.index': {
        entity: 'website',
        property: 'delete'
      },
      'website.edit.page.edit.market-segments': {
        entity: 'market-segments',
        property: 'personalisationwizard'
      }
    },

    /**
     * @inheritdoc
     */
    canHandle(actionName) {
      return actionName === 'canVisit' && this.isSecuredRoute(this.get('model.routeName'));
    },

    /**
     * Is route secured and access check should be performed before visiting route
     *
     * @param {String} routeName - route name
     * @returns {Boolean}
     */
    isSecuredRoute(routeName) {
      const {
        map
      } = this;
      return map && map.hasOwnProperty(routeName);
    },

    /**
     * Get route permission name
     *
     * @param {String} routeName - name of route for which permission will be retrieved
     * @returns {String}
     */
    getPermission() {
      const {
        map
      } = this;
      return map[this.get('model.routeName')];
    }

  });

  _exports.default = _default;
});