define("builder/validators/form-title", ["exports", "builder/core/abstract-classes/base-ajax-validator", "builder/core/form/utils", "builder/config/environment"], function (_exports, _baseAjaxValidator, _utils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const FormTitle = _baseAjaxValidator.default.extend({
    /**
     * @property {StoreService} store - store service
     */
    store: Ember.inject.service('store'),

    /**
     * @inheritdoc
     */
    ajaxOptions(value, options) {
      const id = options.formId;
      let url = `${_environment.default.apiURL}/form_titlevalidator/?title=${value}`;

      if (id && !(0, _utils.isTemporaryFormId)(id)) {
        url += `&id=${id}`;
      }

      return {
        url,
        method: 'GET'
      };
    },

    /**
     * @inheritdoc
     */
    validate(value, ...args) {
      const {
        store
      } = this;
      const formModule = store.peekAll('module').findBy('Name', 'Form');
      const pageModules = store.peekAll('page-module').filterBy('ModuleId', `${formModule.get('id')}`);

      if (pageModules.filterBy('Structure.title', value).length > 1) {
        return 'Form Title should be unique';
      }

      return this._super(value, ...args);
    }

  });

  var _default = FormTitle;
  _exports.default = _default;
});