define("builder/pods/website/e6-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNUbv+Gb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"widget-popup\",null,[[\"class\"],[\"widget-popup_theme_e6-info\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"website-e6-info\"],[8],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"website-e6-info__title\"],[8],[0,\"Sorry\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"website-e6-info__text\"],[8],[0,\"\\n\\t\\t\\tWebsite builder is only available for Engine6 websites. Please contact support for more details.\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/e6-info/template.hbs"
    }
  });

  _exports.default = _default;
});