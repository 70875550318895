define("builder/pods/components/field-multiselect-item/component", ["exports", "builder/core/enumerators/keyboard"], function (_exports, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Need enable remove option or not
     * @type {boolean}
     */
    isRemoveEnabled: true,

    /**
     * Show new this item or not
     * @type {boolean}
     */
    isNew: Ember.computed.bool('item.isNew'),

    /**
     * Select this item or not
     * @type {boolean}
     */
    isSelect: Ember.computed.bool('item.isSelect'),

    /**
     * Is edit mode item or not
     * @type {boolean}
     */
    isEdit: Ember.computed.bool('item.isEdit'),

    /**
     * Changes was saved
     * @type {boolean}
     */
    isChangesSaved: false,

    /**
     * Handler called after change label
     * @type {(item:object, value:string)=>void}
     */
    onSave: () => {},

    /**
     * Handler called after click to delete button
     * @type {(item:object)=>void}
     */
    onDeleteItem: () => {},

    /**
     * Handler called after transition to edit mode
     * @type {(item:object)=>void}
     */
    onEditItem: () => {},

    /**
     * Handler called after click to label
     * @type {(item:object)=>void}
     */
    selectHandler: () => {},

    /**
     * @inheritDoc
     */
    doubleClick(event) {
      if (!this.isEditable) {
        event.stopPropagation();
      }

      this.send('edit');
    },

    /**
     * @inheritDoc
     */
    mouseDown(event) {
      if (event.target.className !== 'fa fa-times-circle' && !this.isEdit) {
        document.activeElement.blur();
      }
    },

    /**
     * Rollback item value
     * @returns {void}
     */
    rollbackValue() {
      Ember.set(this.item, 'value', this.tempValue);
      this.set('tempValue', '');
    },

    /**
     * Cancel edit item
     * @returns {void}
     */
    cancelEdit() {
      Ember.set(this.item, 'isEdit', false);
    },

    /**
     * Remove item if empty
     * @returns {void}
     */
    removeIfEmpty() {
      if (!this.item.value) {
        this.send('remove');
      }
    },

    actions: {
      /**
       * Edit item
       * @returns {boolean}
       */
      edit() {
        if (!this.isEditable) {
          return false;
        }

        this.set('tempValue', this.item.value);
        Ember.set(this.item, 'isEdit', true);
        this.onEditItem(this.item);
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.$().find('input').focus();
        });
        return false;
      },

      /**
       * Remove item
       * @returns {void}
       */
      remove() {
        this.onDeleteItem(this.item);
      },

      /**
       * Save item
       * @returns {void}
       */
      save() {
        if (this.isChangesSaved) {
          return;
        }

        this.cancelEdit();
        this.removeIfEmpty();
        this.onSave(this.item, this.item.value);
        this.set('isChangesSaved', true);
      },

      /**
       * Handle by key item
       * @param {string} value - item value
       * @param {Event} event - event
       * @returns {void}
       */
      handleByKey(value, event) {
        switch (event.keyCode) {
          case _keyboard.default.KEY.ENTER:
            {
              event.preventDefault();
              event.stopPropagation();

              if (value && this.item.value !== value) {
                this.set('isChangesSaved', false);
                this.send('save', value);
                this.set('item.value', value);
              }

              break;
            }

          case _keyboard.default.KEY.ESCAPE:
            {
              event.preventDefault();
              event.stopPropagation();
              this.set('isChangesSaved', true);
              this.send('cancel');
              this.removeIfEmpty();
              break;
            }

          default:
            {
              this.set('isChangesSaved', false);
            }
        }
      },

      /**
       * Cancel edit item
       * @returns {void}
       */
      cancel() {
        this.rollbackValue();
        this.cancelEdit();
      },

      /**
       * Select item
       * @returns {void}
       */
      select() {
        this.selectHandler(this.item);
      }

    }
  });

  _exports.default = _default;
});