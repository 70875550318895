define("builder/core/enumerators/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STEP = _exports.MULTISTEP = void 0;
  // Multi step form type
  const MULTISTEP = 'form-multistep'; // Form step type

  _exports.MULTISTEP = MULTISTEP;
  const STEP = 'form-step';
  _exports.STEP = STEP;
});