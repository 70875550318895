define("builder/core/enumerators/email-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Variables represented email format for concrete recipient.
   * Used in leads routing settings.
   */
  var _default = {
    PLAIN_HTML: '1',
    ADF_XML: '2'
  };
  _exports.default = _default;
});