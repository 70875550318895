define("builder/core/enumerators/server-error-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GLOBAL_PAGE_MENU_INSERTION_ERROR = _exports.FORM_VALIDATION_ERROR = _exports.VALIDATION_ERROR = _exports.TOKEN_EXPIRED = _exports.TOKEN_INVALID = _exports.MERGE_CONFLICT = void 0;
  const MERGE_CONFLICT = 'merge-conflict';
  _exports.MERGE_CONFLICT = MERGE_CONFLICT;
  const TOKEN_INVALID = 'token-invalid';
  _exports.TOKEN_INVALID = TOKEN_INVALID;
  const TOKEN_EXPIRED = 'token-expired';
  _exports.TOKEN_EXPIRED = TOKEN_EXPIRED;
  const VALIDATION_ERROR = 'Validation error';
  _exports.VALIDATION_ERROR = VALIDATION_ERROR;
  const FORM_VALIDATION_ERROR = 'Form validation error';
  _exports.FORM_VALIDATION_ERROR = FORM_VALIDATION_ERROR;
  const GLOBAL_PAGE_MENU_INSERTION_ERROR = 'multi-operation';
  _exports.GLOBAL_PAGE_MENU_INSERTION_ERROR = GLOBAL_PAGE_MENU_INSERTION_ERROR;
});