define("builder/pods/components/widget-line-divider/component", ["exports", "builder/core/widget", "builder/core/enumerators/module-action"], function (_exports, _widget, _moduleAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetLineDivider
   */
  var _default = _widget.default.extend({
    /**
     * @inheritdoc
     */
    tagName: 'div',

    /**
     * @inheritdoc
     */
    classNames: ['page_module'],

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    },

    /**
     * @inheritdoc
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    doubleClick(...args) {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return this._super(...args);
    }

  });

  _exports.default = _default;
});