define("builder/abilities/oem/page", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlOEMPageAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      canCreate: {
        entity: 'page',
        property: 'oempageenabled'
      },
      canUpdate: {
        entity: 'page',
        property: 'oempageenabled'
      },
      canDelete: {
        entity: 'page',
        property: 'oempageenabled'
      }
    },

    /**
     * @inheritdoc
     */
    canHandle(...args) {
      const page = this.model;
      const restricted = page ? page.get('IsOemRestricted') : false;
      return this._super(...args) && restricted;
    }

  });

  _exports.default = _default;
});