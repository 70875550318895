define("builder/models/meta-item", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MetaItemObject = void 0;

  /**
   * Object that represents meta item interface
   * we need this object separated from Fragment for meta validation on import
   */
  const MetaItemObject = {
    Heading: (0, _model.attr)('string'),
    Params: (0, _model.attr)('string'),
    Title: (0, _model.attr)('string'),
    Description: (0, _model.attr)('string'),
    Keyword: (0, _model.attr)('string'),
    'variant-name': (0, _model.attr)('string')
  };
  /**
   * @class MetaItemModel
   */

  _exports.MetaItemObject = MetaItemObject;

  var _default = _fragment.default.extend(MetaItemObject);

  _exports.default = _default;
});