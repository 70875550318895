define("builder/core/form-services-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._makeProxy = _makeProxy;

  /**
   * Temp proxy service creator for form-module, since forms are made not using "Ember way", services doesn't work inside
   * not ember way components
   *
   * @param {Object} service
   * @param {Object} methods
   * @return {Object}
   */
  function _makeProxy(service, methods) {
    const proxy = {};

    for (let i = 0, len = methods.length; i < len; i++) {
      if (typeof service[methods[i]] !== 'function') {
        throw new Ember.Error(`Method "${methods[i]}" is not found in service`);
      }

      proxy[methods[i]] = function (...args) {
        return service[methods[i]](...args);
      };
    }

    return proxy;
  }
});