define("builder/models/page-seo", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageSeoModel
   */
  var _default = _model2.default.extend({
    /**
     * @property {String} PageId - page id
     */
    PageId: (0, _model.attr)('String'),

    /**
     * @property {Object[]} Structure - page seo url structure
     *
     * ex.
     *
     * Structure: [
     *     {
     *         template: '',
     *         filter: ''
     *     }
     * ]
     */
    Structure: (0, _model.attr)('raw', {
      defaultValue: () => []
    })
  });

  _exports.default = _default;
});