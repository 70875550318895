define("builder/core/configuration-builder/fields/form-variants-configuration-builder", ["exports", "ember-copy", "builder/utils/fields", "builder/core/enumerators/fields", "builder/core/configuration-builder/fields/field-media", "builder/core/util/is-object"], function (_exports, _emberCopy, _fields, _fields2, _fieldMedia, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormVariantsConfigurationBuilder {
    /**
     * Build module configuration for form variants
     *
     * @param {object[]} item - module config
     * @param {object} data - module data
     * @param {EmberObject} node - page structure node
     * @param {string} breakpoint - breakpoint mode
     * @param {string} path - field base path within configuration map
     * @private
     * @returns {void}
     */
    build(item, data, node, breakpoint, path = '') {
      const variantsData = (0, _emberCopy.copy)(data[item.name], true) || [];

      const imageVariantNames = _fields.default.findFieldsInFormVariants(item, field => field.type === _fields2.default.MEDIA && field.validation.image).map(imageVariant => imageVariant.field.name);

      if (imageVariantNames.length) {
        this._normalizeFormVariantsDataFieldImage(variantsData, imageVariantNames, node, breakpoint);
      }

      if (variantsData.length) {
        item.data = variantsData;
      } else if (!item.hasOwnProperty('data')) {
        item.data = [];
      }

      item.path = path;

      this._setFormVariantsFieldData(item.data, variantsData);
    }
    /**
     * Normalize form variant data(make data ready for consumption for field-media)
     * TODO: associate variant name with field name for more precise detection
     *
     * @param {object[]} variantsData - variants data
     * @param {string[]} fieldMediaNames - field names of field-media fields
     * @param {EmberObject} node - node item, one of these which contain originObject
     * @param {string} breakpoint - break point mode for which data will be normalized
     * @private
     * @returns {void}
     */


    _normalizeFormVariantsDataFieldImage(variantsData, fieldMediaNames, node, breakpoint) {
      const builder = _fieldMedia.default.create();

      variantsData.forEach(variantData => {
        if (!variantData) {
          return;
        }

        Object.keys(variantData).forEach(key => {
          if (fieldMediaNames.includes(key)) {
            const fieldData = variantData[key];
            Ember.set(variantData, key, fieldData ? builder.build({
              type: 'image',
              url: Ember.get(fieldData, 'url'),
              naturalWidth: Ember.get(fieldData, 'naturalWidth'),
              naturalHeight: Ember.get(fieldData, 'naturalHeight'),
              scale: Ember.get(fieldData, `focalPoints.${breakpoint}.scale`),
              left: Ember.get(fieldData, `focalPoints.${breakpoint}.left`),
              top: Ember.get(fieldData, `focalPoints.${breakpoint}.top`)
            }, node) : null);
          }
        });
      });
    }
    /**
     * Workaround to update form-variants data without re rendering, all we need to do is update values object passed
     * as new value of form-variants
     *
     * @param {object[]} fieldData - field data passed as new value for form-variants
     * @param {object[]} variantsData - normalized form-variants data
     * @private
     * @returns {void}
     */


    _setFormVariantsFieldData(fieldData, variantsData) {
      variantsData.forEach((variantData, i) => {
        const data = fieldData[i];

        if (!data) {
          fieldData.push({});
        }

        if (variantData) {
          Object.keys(variantData).forEach(key => {
            this._setFormVariantsFieldDataItem(fieldData[i], variantData, key);
          });
        }
      });
    }
    /**
     * Set form-variant field data
     *
     * @param {object} data - field data
     * @param {object} variantData - form-variant data
     * @param {string} key - data key
     * @private
     *
     * @returns {void}
     */


    _setFormVariantsFieldDataItem(data, variantData, key) {
      const variantDataKey = variantData[key];

      if ((0, _isObject.default)(variantDataKey)) {
        Object.keys(variantDataKey).forEach(subKey => {
          // If this is an object in the variant Data, it must also be an object in the data, now it may not be
          // the case in the module configuration, for example, data: null, and we need to redefine the property
          if (!data.hasOwnProperty(key) || !(0, _isObject.default)(data[key])) {
            data[key] = {};
          }

          this._setFormVariantsFieldDataItem(data[key], variantDataKey, subKey);
        });
      } else {
        Ember.set(data, key, variantDataKey);
      }
    }

  }

  _exports.default = FormVariantsConfigurationBuilder;
});