define("builder/services/module-configuration", ["exports", "builder/models/menu-child-link", "builder/core/enumerators/fields"], function (_exports, _menuChildLink, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Module config creator
   * Module used whenever it's required to create module configuration based theme
   *
   * @class ModuleConfigurationService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {ConfigurationService} _configurationService - website configuration service
     * @private
     */
    _configurationService: Ember.inject.service('configuration'),

    /**
     * Main entry point for module config creation
     *
     * @param {ModuleThemeModel} theme - theme based on which config should be created
     * @returns {Object}
     */
    createModuleConfiguration(theme) {
      const config = this._createConfiguration(theme);

      if (theme.get('hasStructure')) {
        config.Structure = this.convertStructureToData(theme.get('Structure'));
      }

      return config;
    },

    /**
     * Convert structure fields to actual data as predefined values
     *
     * @param {Object[]} structure - list of structure fields, from which data will be extracted
     * @returns {Object}
     */
    convertStructureToData(structure) {
      const data = {};
      structure.forEach(item => {
        const {
          name,
          type
        } = item;

        if (name && item.hasOwnProperty('data') || type === _fields.default.CONTAINER) {
          data[name] = this[this._getDataConvertorName(type)](item);
        }
      });
      return data;
    },

    /**
     * Create base module configuration, fill in all required fields
     *
     * @param {ModuleThemeModel} theme - module theme model
     * @returns {Object} - basically it's PageModuleModel, but in plain object form
     * @private
     */
    _createConfiguration(theme) {
      return {
        PageId: this.get('_configurationService.page.id'),
        ThemeId: theme.get('id'),
        ModuleId: theme.get('ModuleId'),
        Strings: theme.get('Strings'),
        Structure: {}
      };
    },

    /**
     * Get field to data convertor name
     *
     * @param {String} fieldType - field type for which convertor will be used
     * @returns {String}
     * @private
     */
    _getDataConvertorName(fieldType) {
      switch (fieldType) {
        case _fields.default.LINK:
          return '_getLinkData';

        case _fields.default.FORM_BUTTONS:
          return '_getFormButtonsData';

        case _fields.default.FORM_VARIANTS:
          return '_getFormVariantsData';

        case _fields.default.CONTAINER:
          return '_getFieldContainerData';

        default:
          return '_getData';
      }
    },

    /**
     * General field to data convertor
     *
     * @param {Object} field - field from which to extract data
     * @returns {*}
     * @private
     */
    _getData(field) {
      return field.hasOwnProperty('data') ? field.data : null;
    },

    /**
     * Field-container data extractor
     *
     * @param {Object} field - field from which to extract data
     * @returns {Object}
     * @private
     */
    _getFieldContainerData(field) {
      let data = {};
      const {
        children
      } = field;

      if (Array.isArray(field)) {
        field.forEach(item => {
          if (item.type !== _fields.default.FORM_VARIANTS && item.hasOwnProperty('name')) {
            data[item.name] = this._getFieldContainerData(item);
          }
        });
      } else if (field.hasOwnProperty('name') && field.hasOwnProperty('data')) {
        data = this[this._getDataConvertorName(field.type)](field);
      } else if (children) {
        data = this._getFieldContainerData(children);
      }

      return data;
    },

    /**
     * Field link data extractor
     *
     * @param {Object} field - field link field
     * @returns {{menuItemId: null, value: *, target: *, type: string}}
     * @private
     */
    _getLinkData(field) {
      const configurationService = this._configurationService;
      const fieldData = field.data || {};
      const data = {
        menuItemId: null,
        value: fieldData.value || '',
        target: fieldData.target || 'self',
        type: fieldData.type || 'internal'
      };

      if (fieldData.hasOwnProperty('params') && fieldData.params) {
        data.params = fieldData.params;
      }

      if (fieldData.type === _menuChildLink.default.INTERNAL && fieldData.value) {
        const menuItem = configurationService.get('mainMenu').findPageByAlias(fieldData.value) || configurationService.get('unlinkedMenu').findPageByAlias(fieldData.value);
        data.menuItemId = menuItem ? menuItem.get('itemId') : null;
      }

      return data;
    },

    /**
     * Form buttons field data extractor
     *
     * @param {Object} field - form buttons field
     * @returns {{buttons: *, group: boolean}}
     * @private
     */
    _getFormButtonsData(field) {
      const buttons = field.data ? field.data.buttons || [] : [];
      const group = field.data ? field.data.group : false;
      const data = {
        buttons,
        group
      };

      for (let i = 0, len = buttons.length; i < len; i++) {
        data.buttons[i] = Ember.assign({}, buttons[i]);

        if (buttons[i].type === 'custom') {
          data.buttons[i].link = this._getLinkData({
            data: buttons[i].link
          });
        }
      }

      return data;
    },

    /**
     * Field form-variants field data extractor
     *
     * @param {Object} field - form-variants field
     * @returns {Object[]}
     * @private
     */
    _getFormVariantsData(field) {
      const data = [];
      const fieldChildren = field.children;
      const fieldData = field.data || [];
      let timestamp = Date.now();
      fieldData.forEach(item => {
        const itemData = Ember.assign({
          createdAt: timestamp++
        }, item);
        const variant = fieldChildren.findBy('name', itemData['variant-name']);

        if (variant && variant.children) {
          variant.children.forEach(child => {
            const itemDataChild = itemData[child.name];

            if (itemDataChild) {
              let param = {
                data: itemDataChild
              }; // Workaround for f*king field-container in f*king form-variants, I know it's a bad way (, but we f*king agile

              if (child.type === _fields.default.CONTAINER) {
                const containerData = itemDataChild;
                const children = [];
                child.children.forEach(c => {
                  if (containerData[c.name]) {
                    children.push({
                      data: containerData[c.name],
                      name: c.name,
                      type: c.type
                    });
                  }
                });
                param = {
                  children
                };
              }

              itemData[child.name] = this[this._getDataConvertorName(child.type)](param);
            }
          });
        }

        data.push(itemData);
      });
      return data;
    }

  });

  _exports.default = _default;
});