define("builder/validators/container-template-title", ["exports", "builder/core/abstract-classes/base-ajax-validator", "builder/config/environment"], function (_exports, _baseAjaxValidator, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAjaxValidator.default.extend({
    /**
     * @inheritdoc
     */
    ajaxOptions(value) {
      return {
        method: 'POST',
        url: `${_environment.default.apiURL}/containertemplate_validator`,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          containerTemplateValidator: {
            Title: value
          }
        })
      };
    }

  });

  _exports.default = _default;
});