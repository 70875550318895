define("builder/pods/components/sm-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmFGc275",
    "block": "{\"symbols\":[\"manager\",\"tab\",\"comp\",\"tab\"],\"statements\":[[5,\"tabs-manager\",[],[[\"@active\"],[[22,\"activeTab\"]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tabs__navigation tabs__outer\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"tab-button\",[],[[\"@manager\",\"@name\"],[[23,1,[]],[23,4,[\"id\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[23,4,[\"label\"]],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"tab-content\",[[12,\"class\",\"tabs__content_styles\"]],[[\"@manager\",\"@name\"],[[23,1,[]],[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,2,[\"items\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"component\",[[28,\"sm-component-resolver\",[[23,3,[]]],null]],[[\"baseAttrs\",\"variablesModel\",\"dependentModel\",\"fontsModel\",\"isNested\",\"mode\",\"updateVariable\",\"updateLockState\"],[[23,3,[]],[24,[\"variablesModel\"]],[24,[\"dependentModel\"]],[24,[\"fontsModel\"]],true,[24,[\"mode\"]],[24,[\"updateVariable\"]],[24,[\"updateLockState\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/sm-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});