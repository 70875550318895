define("builder/core/enumerators/direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Enum for directions, common usage in drag and drop operations
   */
  var _default = Ember.Object.create({
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
    INSIDE: 'inside'
  });

  _exports.default = _default;
});