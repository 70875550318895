define("builder/core/drop-rules/widget-section", ["exports", "builder/core/drop-rules/widget-container", "builder/core/enumerators/container"], function (_exports, _widgetContainer, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Drag and drop rules for widget-row
   */
  var _default = _widgetContainer.default.extend({
    /**
     * @inheritDoc
     */
    // prepare(structure, droppable, draggable, direction) {
    // 	const wrappedDraggable = this._super(structure, droppable, draggable, direction);
    // 	if (wrappedDraggable?.originObject?.type !== Container.WIDGET_COLUMN) {
    // 		return wrappedDraggable;
    // 	}
    // 	const row = this.gridBuilder.createInstance(Container.WIDGET_ROW);
    // 	structure.addChild(row, wrappedDraggable);
    // 	return row;
    // },

    /**
     * @inheritdoc
     */
    canDrop(droppable, draggable, direction, anchor, ...args) {
      if (!droppable || !draggable) {
        return false;
      } // Do not drop next to self


      if (anchor === draggable) {
        return false;
      }

      if (draggable.originObject.type === _container.default.WIDGET_CONTAINER) {
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});