define("builder/core/util/normalize-style-variable-name", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VARIABLE_NAME_NORMALIZE_PATTERN = new RegExp(`^\\$|-${_breakpointModes.LARGE}$|-${_breakpointModes.MEDIUM}$|-${_breakpointModes.SMALL}$|-${_breakpointModes.EXTRA_SMALL}$`, 'g');
  /**
   * Normalize variable name to canonical
   * Examples:
   * 	$primary-color-lg normalized to primary-color
   * 	$primary-color normalized to primary-color
   * @param {String} variableName - variable name
   * @return {String}
   */

  function normalizeVariableName(variableName) {
    return variableName.replace(VARIABLE_NAME_NORMALIZE_PATTERN, '');
  }

  var _default = normalizeVariableName;
  _exports.default = _default;
});