define("builder/pods/components/gallery-theme/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ssv5eJ0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page_module\"],[11,\"style\",[22,\"thumbnailCss\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"aboutThemeText\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"widget-help\",[[12,\"class\",\"page_modules_list_item-help\"]],[[\"@text\",\"@textAlign\",\"@iconType\"],[[28,\"html-format\",[[24,[\"aboutThemeText\"]]],null],\"right\",\"info\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"page_modules_list_item-controls\"],[8],[0,\"\\n\\t\\t\"],[5,\"tooltip-external\",[],[[\"@text\",\"@closingDelayTime\"],[[24,[\"literals\",\"GALLERY_ZOOM\"]],0]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"page_modules_list_item-control\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showImagePopup\",[24,[\"originalImage\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-search-plus\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"page_modules_list_item-title\"],[8],[1,[22,\"name\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/gallery-theme/template.hbs"
    }
  });

  _exports.default = _default;
});