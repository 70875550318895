define("builder/pods/website/edit/page/route", ["exports", "builder/routes/application", "builder/core/enumerators/controller-action"], function (_exports, _application, _controllerAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @property {Ember.Service} form - form service
     */
    formService: Ember.inject.service('form'),

    /**
     * @property {Ember.Service} menuManager - menu manager
     */
    menuManagerService: Ember.inject.service('menu-manager'),

    /**
     * @inheritdoc
     */
    model() {
      const websiteModel = this.modelFor('website.edit');
      return Ember.RSVP.hash({
        menu: websiteModel.menu,
        website: websiteModel.website,
        websiteStyle: websiteModel.styles,
        websiteFonts: websiteModel.websiteFonts,
        websiteStylesMap: websiteModel.stylesMap
      });
    },

    /**
     * @inheritdoc
     */
    setupController(controller, ...args) {
      this._super(controller, ...args);
    },

    /**
     * Method rollbacks list of models
     *
     * @param {Array} list - list of models
     */
    undoList(list) {
      list.forEach(item => {
        this.undo(item);
      });
    },

    /**
     * Method rollback model to initial state
     *
     * @param {Ember.Model} model
     * @param {Function} model.rollbackAttributes
     * @param {Function} model.get
     * @param {Function} model.unloadRecord
     * @returns {boolean}
     */
    undo(model) {
      let isRollBack = false;

      if (Ember.typeOf(model) === 'instance') {
        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
          isRollBack = true;
        } else if (model.get('isCanBeUnload')) {
          model.unloadRecord();
          isRollBack = true;
        }
      }

      return isRollBack;
    },

    /**
     * @inheritdoc
     */
    resetController(controller) {
      controller.set('_currentPageIs404', false);
    },

    actions: {
      didTransition() {
        const configure = this.configurationService;

        if (configure.hasOwnProperty('page')) {
          const pageId = configure.page.id;

          if (!this.menuManagerService.currentPageId && pageId) {
            this.menuManagerService.set('currentPageId', pageId);
          }

          if (this.menuManagerService.currentPageId && this.menuManagerService.currentPageId !== pageId) {
            this.menuManagerService.set('currentPageId', pageId);
            this.menuManagerService.set('searchString', '');
          }
        }

        return true; // Bubble the didTransition event
      },

      addModifiedModuleToQueue(module) {
        this.controller.addModifiedSystemModule(module);
      },

      /**
       * Action to rollback all models
       *
       * @method rollbackModels
       */
      rollbackModels() {
        const websiteModel = this.modelFor('website.edit');
        this.controller.sendBackendClearUnsavedData();
        this.controller.send('onSearchClear');
        this.controller.resetProps(); // Reset css to re-render it when user go to another page

        this.controller.send('destroyMMConfig');
        this.controller.set('menuManagementDropDownIsOpened', false); // Rollback all layout properties including IsLayoutLocked

        this.controller.get('pageModel.layout').rollbackAttributes(); // Roll back all changes with page, page-settings, website-styles, forms models if user leave this route

        this.undoList(this.store.peekAll('page'));
        this.undoList(this.store.peekAll('page-meta'));
        this.undoList(this.store.peekAll('page-module'));
        this.undoList(this.store.peekAll('page-seo'));
        this.undoList(this.store.peekAll('module-style'));
        this.undoList(this.store.peekAll('market-segment'));
        this.formService.clear();
        this.undoList(this.store.peekAll('form'));
        this.undo(this.modelFor('website.edit.page').websiteStyle);
        this.undo(websiteModel.menu.get('unlinkedMenu'));
        this.undo(websiteModel.menu.get('mainMenu'));
        this.undo(websiteModel.website);
        this.undo(websiteModel.metaVariables);
      },

      /**
       * Update unlinked menu
       */
      updateMenu() {
        const websiteModel = this.modelFor('website.edit');
        const {
          menu
        } = websiteModel;
        menu.clearStructure();
        menu.save({
          adapterOptions: {
            fakeRequest: true
          }
        }).then(savedMenu => savedMenu.reload()).then(reloadedMenu => {
          websiteModel.menu = reloadedMenu;
          this.controller.set('model.menu', reloadedMenu);
          this.configurationService.set('mainMenu', reloadedMenu.get('mainMenu'));
          this.configurationService.set('unlinkedMenu', reloadedMenu.get('unlinkedMenu'));
        });
      },

      /**
       * Adds new check point for undo/redo history
       * @param {Array} checkpoints - Checkpoints
       */
      addHistoryCheckpoint(checkpoints) {
        this.controller.addHistoryCheckpoint(checkpoints);
      },

      handleBubbleAction(type, ...rest) {
        switch (type) {
          case _controllerAction.default.SET_CHANGES:
            {
              this.controller.send('setChanges', ...rest);
              break;
            }

          case _controllerAction.default.CLOSE_SITE_SETTINGS:
            {
              this.controller.set('siteSettingsIsOpened', false);
              break;
            }

          case _controllerAction.default.OPEN_SITE_SETTINGS:
            {
              this.controller.set('siteSettingsIsOpened', true);
              break;
            }

          case _controllerAction.default.CLOSE_PAGE_SETTINGS:
            {
              this.controller.set('pageSettingsIsOpened', false);
              break;
            }

          case _controllerAction.default.OPEN_PAGE_SETTINGS:
            {
              this.controller.set('pageSettingsIsOpened', true);
              break;
            }

          case _controllerAction.default.DESTROY_MENU_MANAGEMENT_CONFIG:
            {
              this.controller.send('destroyMMConfig');
              break;
            }

          case _controllerAction.default.CLOSE_MENU_MANAGEMENT:
            {
              this.controller.set('menuManagementDropDownIsOpened', false);
              break;
            }

          case _controllerAction.default.SHOW_OVERLAY:
            {
              this.controller.set('hasOverlay', true);
              break;
            }

          case _controllerAction.default.HIDE_OVERLAY:
            {
              this.controller.set('hasOverlay', false);
              break;
            }

          case _controllerAction.default.REVERT_FORM_MODULE:
            {
              this.controller.set('isFormModuleChanged', false);
              break;
            }

          case _controllerAction.default.SHOW_PAGE_TOOLS_PANEL:
            {
              this.controller.set('isShowPageTools', true);
              break;
            }

          case _controllerAction.default.DEACTIVATE_FORM_MODULE:
            {
              this.controller.send('deactivateFormModule');
              break;
            }

          case _controllerAction.default.OPEN_MARKET_SEGMENTS_SETTINGS:
            {
              this.controller.set('marketSegmentsIsOpened', true);
              break;
            }

          case _controllerAction.default.CLOSE_MARKET_SEGMENTS_SETTINGS:
            {
              this.controller.set('marketSegmentsIsOpened', false);
              break;
            }

          case _controllerAction.default.CLEAR_UNDO_REDO:
            {
              this.controller.clearUndoRedoHistory();
              break;
            }

          case _controllerAction.default.SAVE_COMPLETE:
            {
              this.controllerFor('website.edit.page.edit').refreshPageView();
              break;
            }

          case _controllerAction.default.OPEN_GLOBAL_STYLES:
            {
              this.controller.set('stylesManagementIsOpened', true);
              break;
            }

          case _controllerAction.default.CLOSE_GLOBAL_STYLES:
            {
              this.controller.set('stylesManagementIsOpened', false);
              break;
            }
        }
      }

    }
  });

  _exports.default = _default;
});