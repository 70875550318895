define("builder/pods/components/widget-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFlDAqRw",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"a\",false],[12,\"class\",\"pg-aside__menu-link no-click\"],[12,\"href\",[24,[\"item\",\"url\"]]],[3,\"action\",[[23,0,[]],\"toggleChildMenu\"]],[8],[0,\"\\n\\t\"],[4,\"if\",[[24,[\"item\",\"icon\"]]],null,{\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"pg-aside__menu-ico fa fa-\",[24,[\"item\",\"icon\"]]]]],[8],[9]],\"parameters\":[]},null],[7,\"span\",true],[10,\"class\",\"pg-aside__nav-label\"],[8],[1,[24,[\"item\",\"title\"]],false],[9],[0,\"\\n\\t\"],[4,\"if\",[[24,[\"hasChildren\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"pg-aside__menu-arrow fa\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasChildren\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"ul\",true],[10,\"class\",\"pg-aside__menu-l2 collapse in\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"item\",\"children\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"widget-menu-item\",null,[[\"item\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});