define("builder/pods/components/container-template-editor/component", ["exports", "builder/core/enumerators/template-visibility", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _templateVisibility, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @type {Number} - thumbnail upload success status code
   */
  const UPLOAD_SUCCESS = 1;
  /**
   * @class ContainerTemplateEditor
   */

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: '',

    /**
     * @property {ConfigurationService} configuration - Configuration service
     */
    configuration: Ember.inject.service('configuration'),

    /**
     * @property {ModulesStoreService} modules - modules store
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * @property {Object[]} visibilities - list of template visibilities available
     */
    visibilities: null,

    /**
     * @property {Object[]} visibilityChoices - parsed visibility, used to detect if visibilities where passed
     */
    visibilityChoices: null,

    /**
     * @property {ContainerCategory[]} categories - list of template categories available
     */
    categories: null,

    /**
     * @property {Object[]} categoryChoices - parsed categories, used to detect if categories where loaded
     */
    categoryChoices: null,

    /**
     * @property {Boolean} isValid - is template name is valid
     */
    isValid: true,

    /**
     * @property {Boolean} isSaveAllowed - is template allowed to be saved
     */
    isSaveAllowed: true,

    /**
     * @property {String} title - template editor title
     */
    title: '',

    /**
     * @property {String} description - template editor description
     */
    description: '',

    /**
     * @property {String|null} templateTitle - template name
     */
    templateTitle: null,

    /**
     * @property {String|null} templateVisibility - template visibility
     * TODO: should be {VisibilityModel|null}, but who cares
     */
    templateVisibility: null,

    /**
     * @property {Number|null} templateCategory - template category
     */
    templateCategory: null,

    /**
     * @property {String|null} templateThumbnail - path to template thumbnail
     */
    templateThumbnail: null,

    /**
     * Url to upload thumbnail
     * @type {string}
     */
    uploadUrl: Ember.computed('configuration.sessionId', function () {
      return `${_environment.default.apiURL}/upload?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`;
    }),

    /**
     * @property {String} uploadName - name of upload file(property name, not file name)
     */
    uploadName: 'tmp_file',

    /**
     * @property {String|null} lastError - last error message to be displayed
     */
    lastError: null,

    /**
     * @property {Boolean} loading - is template uploading
     */
    loading: false,

    /**
     * @property {String} saveButtonText - save button text
     */
    saveButtonText: 'Save',

    /**
     * @property {Object} fieldTitle - field title config
     */
    fieldTitle: null,

    /**
     * @property {Object} fieldVisibility - field visibility config
     */
    fieldVisibility: null,

    /**
     * @property {Object} fieldCategory - field category config
     */
    fieldCategory: null,

    /**
     * @property {Boolean} isGlobalVisibility - is template visibility global
     */
    isGlobalVisibility: Ember.computed('fieldVisibility.data', function () {
      return this.get('fieldVisibility.data') === _templateVisibility.default.VISIBILITY_GLOBAL;
    }),

    /**
     * @property {Boolean} buttonSaveDisabled - is button save disabled
     */
    buttonSaveDisabled: Ember.computed('isSaveAllowed', 'isValid', function () {
      return !(this.isSaveAllowed && this.isValid);
    }),

    /**
     * Update template visibility
     * Needs to be overridden.
     * @param {String} visibility - visibility of template
     */
    updateVisibility() {
      throw new Ember.Error('Method "updateVisibility" must be overridden');
    },

    /**
     * Method to be invoked on thumbnail update.
     * Needs to be overridden.
     * @param {String} imageId - upload image id
     * @param {String} thumbnailUrl - url to thumbnail image
     */
    updateThumbnail() {
      throw new Ember.Error('Method "updateThumbnail" must be overridden');
    },

    /**
     * Method to be invoked on thumbnail remove, needs to be overridden
     */
    removeThumbnail() {
      throw new Ember.Error('Method "removeThumbnail" must be overridden');
    },

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.visibilities = this.visibilities || [];
      this.categories = this.categories || [];
      this.set('fieldTitle', {
        label: this.literals.CONTAINER_TEMPLATE_FIELD_NAME_LABEL,
        data: null,
        validation: {
          required: {
            presence: true,
            ignoreBlank: true
          },
          length: {
            min: 1,
            max: 255
          },
          containerTemplateTitle: {
            debounce: 400
          }
        }
      });
      this.set('fieldVisibility', {
        label: this.get('literals.CONTAINER_TEMPLATE_FIELD_VISIBILITY_LABEL'),
        choices: [],
        data: null,
        ignoreRemovedOption: true
      });
      this.set('fieldCategory', {
        label: this.get('literals.CONTAINER_TEMPLATE_FIELD_CATEGORY_LABEL'),
        choices: [],
        data: null
      });
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      let {
        visibilityChoices
      } = this; // eslint-disable-next-line prefer-rest-params

      this._super(...arguments);

      this.set('fieldTitle.data', this.templateTitle); // TODO: for now, visibility wont change, so we don't need to check if it's was changed or not

      if (!visibilityChoices) {
        visibilityChoices = (this.visibilities || []).map(item => ({
          value: item.id,
          label: item.title
        }));
        this.set('fieldVisibility.choices', visibilityChoices);
        this.set('visibilityChoices', visibilityChoices);
      }

      this.set('fieldVisibility.data', this.templateVisibility);
      /**
       * If global visibility selected, load categories and select 1-st from the list if none selected
       */

      if (this.category || this.isGlobalVisibility) {
        this.fetchCategories().then(categories => {
          let {
            categoryChoices
          } = this;
          let category = this.templateCategory;

          if (!categoryChoices) {
            categoryChoices = (categories || []).map(item => ({
              value: item.get('id'),
              label: item.get('Name')
            }));
            this.set('fieldCategory.choices', categoryChoices);
            this.set('categoryChoices', categoryChoices);
          } // When global visibility selected, select 1-st category from the list


          if (this.isGlobalVisibility && !this.templateCategory && categories.get('length')) {
            category = categories.objectAt(0).get('id');
          }

          this.send('updateCategory', category);
        });
      }
    },

    /**
     * Method to be invoked, to load categories, when global visibility selected, expects promise
     */
    fetchCategories() {
      throw new Ember.Error('Provide fetch method for the component');
    },

    /**
     * Extract upload error code from upload response
     * NOTE: this part could be migrate to specific error code extractor that will work with current upload service
     *
     * @param {Object} response - upload response
     * @returns {Number} - error code
     * @private
     */
    _getUploadErrorStatusCode(response) {
      const errorCode = response.hasOwnProperty('errors') && response.errors.length ? response.errors[0].status : 500;
      return parseInt(errorCode, 10);
    },

    /**
     * Get error message for server error, won't handle success error codes
     * NOTE: this part of code could migrate to separate handler, if dynamic uploader will be used
     *
     * @param {Number} errorCode - server error code
     * @returns {String} - error message
     * @private
     */
    _getUploadErrorMessageByCode(errorCode = 500) {
      let message;

      switch (errorCode) {
        case 413:
          {
            message = this.get('literals.CONTAINER_TEMPLATE_THUMBNAIL_UPLOAD_ERROR_FILE_TOO_BIG');
            break;
          }

        default:
          {
            message = this.get('literals.CONTAINER_TEMPLATE_THUMBNAIL_UPLOAD_ERROR');
          }
      }

      return message;
    },

    /**
     * Extract upload error message from upload response
     * NOTE: this part of code could migrate to separate handler, if dynamic uploader will be used
     *
     * @param {Object} response - upload response
     * @returns {String} - upload error message
     * @private
     */
    _getUploadErrorMessageFromResponse(response) {
      return response && response.hasOwnProperty('message') ? response.message : this.get('literals.CONTAINER_TEMPLATE_THUMBNAIL_UPLOAD_ERROR');
    },

    actions: {
      updateTitle(title) {
        this.set('fieldTitle.data', title);
        this.set('templateTitle', title);
        this.set('isValid', true);
      },

      templateTitleValidationError() {
        this.set('isValid', false);
      },

      updateVisibility(visibility) {
        this.updateVisibility(visibility);
      },

      updateCategory(category) {
        this.set('fieldCategory.data', category);
        this.set('templateCategory', category);
      },

      removeThumbnail() {
        this.removeThumbnail();
      },

      dismissErrorMessage() {
        this.set('lastError', null);
      },

      requestUpload(uploadCallback) {
        if (!this.loading) {
          uploadCallback();
        }
      },

      startUpload() {
        this.set('loading', true);
        this.set('showHint', false);
      },

      /**
       * TODO: bubble up handlers when will be needed
       * TODO: if flexibility will be needed in handling response, use proxy method to extract required data from response
       * then pass it to updateThumbnail method
       * ex. var data = this.extractData(response); this.updateThumbnail.apply(null, data);
       *
       * @param {Boolean} success - is upload was successful
       * @param {Object} response - upload response
       *
       * example of response object:
       * {
       *     "status": 1,
       *     "id": "238327f374c08e8a",
       *     "url": "/temp\/238327f374c08e8a.png",
       *     "message": ""
       * }
       */
      endUpload(success, response) {
        let errorMessage = null;
        this.set('loading', false);

        if (success && response.status === UPLOAD_SUCCESS) {
          const url = `${_environment.default.apiURL}${response.url}`;
          this.updateThumbnail(response.id, url);
        } else {
          errorMessage = success ? this._getUploadErrorMessageFromResponse(response) : this._getUploadErrorMessageByCode(this._getUploadErrorStatusCode(response));
        }

        this.set('lastError', errorMessage);
      }

    }
  });

  _exports.default = _default;
});