define("builder/core/abstract-classes/attributes-provider-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * It's an abstract class which should be extend
   * when you have logic with dynamic render using {{component}} helper,
   * and you don't want to declare useless attributes for each {{component}} which don't use it.
   *
   * ---------------------------------
   * For example we have a component
   * 	Ember.ExampleComponent({
   * 		prop1: null,
   * 		prop2: null
   * 	});
   *
   * 	Now on each dynamic render we should pass it property directly like:
   * 			{{component 'example' prop1=1 prop2=2}}
   *
   * 	If this component will be deep we should pass this props through several components, if you want to do that
   * 	and want make code more clean you can extend this interface and make it easy like:
   *
   * 		{{component 'example' baseAttrs=hereNeededAttrs}}
   *
   *
   * @class AbstractAttributesProviderComponent
   * @abstract
   */
  var _default = Ember.Component.extend({
    /**
     * Attributes which will be provided to component
     * @type {Object | import('ember').Ember.Object}
     */
    baseAttrs: null,

    /**
     * Name of attributes which will be skip
     * @type {string[]}
     */
    excludeAttrs: null,

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      const {
        baseAttrs
      } = this;
      const {
        excludeAttrs
      } = this;

      if (baseAttrs) {
        Object.keys(baseAttrs).forEach(prop => {
          if (!excludeAttrs || !excludeAttrs.includes(prop)) {
            this.set(prop, Ember.get(baseAttrs, prop));
          }
        });
      }
    }

  });

  _exports.default = _default;
});