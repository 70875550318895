define("builder/core/form/normalizer", ["exports", "builder/core/form/normalizer-structure", "ember-copy"], function (_exports, _normalizerStructure, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @typedef {import('./normalizer-structure').FormFieldStructure} FormFieldStructure
   * @typedef {import('./normalizer-structure').FormFieldViewModel} FormFieldViewModel
   */
  var _default = Ember.Object.extend({
    /**
     * @property {Object[]} _cache - utility cache
     */
    _cache: null,

    /**
     * Normalizes form field structure recursively
     * @param {FormFieldStructure} structure
     * @param  {FormFieldViewModel} parent
     * @param {String[]} websiteGroups - website groups array, param needs for field rules map
     * @param {String} formType - form specific type, param needs for field rules map
     * @returns {FormFieldViewModel} - created
     */
    _normalizeObject(structure, parent = null, websiteGroups, formType) {
      const children = structure.children || [];
      const current = (0, _normalizerStructure.default)(structure, websiteGroups, formType);

      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }

        parent.children.push(current);
      }

      children.forEach(item => {
        this._normalizeObject(item, current, websiteGroups, formType);
      });
      return current;
    },

    normalize(structure, websiteGroups = [], formType) {
      return this._normalizeObject(structure, null, websiteGroups, formType);
    },

    _denormalizeObject(structure, parent = null) {
      const children = structure.children || [];
      const current = (0, _emberCopy.copy)(structure.data, true);

      if (current.hasOwnProperty('name')) {
        current.name = this._getFieldName(current);
      }

      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }

        parent.children.push(current);
      }

      children.forEach(item => {
        this._denormalizeObject(item, current);
      });
      return current;
    },

    /**
     * Get field name, field name must be for the 1-st field: <field-type>, for next <field-type>-1 for each succeeding
     * field <field-type>-<field-type-count - 1>
     *
     * @param {Object} field - field itself
     * @returns {String} - field name
     * @private
     */
    _getFieldName(field) {
      const fields = this._cache;
      const fieldName = (field.name || field.type).replace(/^field-/, '');
      let cachedField = fields.findBy('name', fieldName);

      if (!cachedField) {
        cachedField = {
          name: fieldName,
          count: 0
        };
        fields.push(cachedField);
      }

      cachedField.count++;
      return cachedField.count === 1 ? fieldName : `${fieldName}-${cachedField.count - 1}`;
    },

    denormalize(structure, hiddenFields = []) {
      const cache = []; // Hidden field names must be saved, just like they were written

      hiddenFields.forEach(hiddenField => {
        if (hiddenField.name !== undefined) {
          cache.push({
            name: hiddenField.name,
            count: 1
          });
        }
      }); // Since "_getFieldName" uses cache and don't know when to reset it, we must reset cache manually

      this.set('_cache', cache);
      return this._denormalizeObject(structure, null);
    }

  });

  _exports.default = _default;
});