define("builder/pods/components/widget-row/component", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['row', 'js-row', 'flexbox-grid'],
    attributeBindings: ['styles:style'],
    styles: Ember.computed('data.originObject.styles.{lg,md,sm,xs}.@each.value', 'mode', function () {
      var _styles$this$mode;

      const {
        styles
      } = this.data.originObject;
      const orderStyle = (_styles$this$mode = styles[this.mode]) === null || _styles$this$mode === void 0 ? void 0 : _styles$this$mode.find(style => style.name === 'order');

      if (!(orderStyle === null || orderStyle === void 0 ? void 0 : orderStyle.value)) {
        return '';
      }

      return `order: ${orderStyle.value}`;
    }),

    /**
     * @inheritdoc
     */
    click() {
      return false;
    }

  });

  _exports.default = _default;
});