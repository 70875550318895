define("builder/pods/components/font-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ScBJgCNP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isFontNotExist\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"link\",true],[10,\"rel\",\"stylesheet\"],[11,\"href\",[29,[[24,[\"font\",\"Url\"]],\"?&display=swap\"]]],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/font-loader/template.hbs"
    }
  });

  _exports.default = _default;
});