define("builder/pods/website/edit/page/edit/module/settings_loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j8mVgPXt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"widget-popup\",[],[[\"@class\",\"@isDraggable\"],[\"widget-popup_theme_module-config\",true]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page_module-config\"],[8],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"page_module-config_title\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"page_module-config_message\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_module-config_form page_module-config_form_with-tabs js-sm-popup\"],[8],[0,\"\\n\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/edit/page/edit/module/settings_loading/template.hbs"
    }
  });

  _exports.default = _default;
});