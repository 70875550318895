define("builder/services/grid", ["exports", "builder/core/enumerators/breakpoint-modes", "builder/core/grid-models/module-device-visibility-state", "builder/core/enumerators/container"], function (_exports, _breakpointModes, _moduleDeviceVisibilityState, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GridService = (_dec = Ember.inject.service('structure'), _dec2 = Ember.inject.service('bootstrap'), (_class = (_temp = class GridService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "structureService", _descriptor, this);

      _initializerDefineProperty(this, "bootstrapService", _descriptor2, this);

      this._addAdjusters = {
        [_breakpointModes.EXTRA_SMALL]: this.bootstrapService.adjustXsWidthOnAdd,
        [_breakpointModes.SMALL]: this.bootstrapService.adjustSmWidthOnAdd,
        [_breakpointModes.MEDIUM]: this.bootstrapService.adjustWidthOnAdd,
        [_breakpointModes.LARGE]: this.bootstrapService.adjustWidthOnAdd
      };
      this._removeAdjusters = {
        [_breakpointModes.EXTRA_SMALL]: this.bootstrapService.adjustSmXsWidthOnRemove,
        [_breakpointModes.SMALL]: this.bootstrapService.adjustSmXsWidthOnRemove,
        [_breakpointModes.MEDIUM]: this.bootstrapService.adjustLgMdWidthOnRemove,
        [_breakpointModes.LARGE]: this.bootstrapService.adjustLgMdWidthOnRemove
      };
    }

    /**
     * @param {Ember.Object & Copyable} widgetObject
     * @returns void
     */
    adjustWidgetVisibility(widgetObject) {
      let column;

      if (widgetObject.type === _container.default.WIDGET_COLUMN) {
        column = widgetObject;
      } else {
        column = widgetObject.parent;
      }

      if (!column) {
        return;
      }

      this._adjustGridObjectVisibility(column);
    }
    /**
     * @param {Number | String} moduleConfigId
     * @returns void
     */


    adjustModuleVisibility(moduleConfigId) {
      const module = this.structureService.findModuleById(moduleConfigId);

      if (!module) {
        return;
      }

      const column = module.parent;

      if (!column) {
        return;
      }

      this._adjustGridObjectVisibility(column);
    }
    /**
     * Updates grid objects (widget-row & widget-column) visibility
     * @param {Ember.Object & Copyable} gridObject
     * @private
     * @returns {void}
     */


    _adjustGridObjectVisibility(gridObject) {
      var _gridObject$originObj, _gridObject$originObj2, _gridObject$originObj3;

      const gridObjectType = (_gridObject$originObj = gridObject === null || gridObject === void 0 ? void 0 : (_gridObject$originObj2 = gridObject.originObject) === null || _gridObject$originObj2 === void 0 ? void 0 : _gridObject$originObj2.type) !== null && _gridObject$originObj !== void 0 ? _gridObject$originObj : null;

      if ([_container.default.WIDGET_MAIN, _container.default.WIDGET_FOOTER, _container.default.WIDGET_HEADER].includes(gridObjectType)) {
        return;
      }

      const visibleOnDevices = _moduleDeviceVisibilityState.default.create(gridObject === null || gridObject === void 0 ? void 0 : (_gridObject$originObj3 = gridObject.originObject) === null || _gridObject$originObj3 === void 0 ? void 0 : _gridObject$originObj3.visibleOnDevices);

      const children = gridObject.get('originObject.children') || [];

      if (!children || !children.length) {
        return;
      }

      const totalChildrenVisibility = _moduleDeviceVisibilityState.default.create(gridObject.getChildrenVisibility(children));

      if (visibleOnDevices.isEqual(totalChildrenVisibility)) {
        return;
      }

      this._updateVisibility(gridObject, totalChildrenVisibility);

      if (gridObject.originObject.type === _container.default.WIDGET_COLUMN) {
        this._adjustSiblingColumnsWidth(gridObject, visibleOnDevices, totalChildrenVisibility);
      }

      const parent = gridObject === null || gridObject === void 0 ? void 0 : gridObject.parent;

      if (!parent) {
        return;
      }

      this._adjustGridObjectVisibility(parent);
    }
    /**
     * Adjusting sibling columns widths
     * @param {Ember.Object & Copyable} gridObject
     * @param {ModuleDeviceVisibilityState} oldVisibility
        * @param {ModuleDeviceVisibilityState} newVisibility, that's being counted by it's children
     * @private
     * @returns {void}
     */


    _adjustSiblingColumnsWidth(gridObject, oldVisibility, newVisibility) {
      const row = gridObject.parent;

      if (!row || row.type === _container.default.WIDGET_ROW) {
        return;
      }

      const updatedBreakpoints = oldVisibility.diff(newVisibility);
      const index = row.originObject.children.indexOf(gridObject);
      updatedBreakpoints.forEach(mode => {
        if (!oldVisibility[mode] && newVisibility[mode]) {
          this._addAdjusters[mode].call(this.bootstrapService, row, gridObject, mode, index);
        }

        if (oldVisibility[mode] && !newVisibility[mode]) {
          this._removeAdjusters[mode].call(this.bootstrapService, row, gridObject, mode, index);
        }
      });
    }
    /**
     * Manually hide visibility of parent when there are no visible children
     *
     * @param {Ember.Object & Copyable} gridObject
     * @param {ModuleDeviceVisibilityState & Copyable | Object} childrenVisibility
     * @private
     * @returns {void}
     */


    _updateVisibility(gridObject, childrenVisibility) {
      if (!childrenVisibility) {
        return;
      }

      const parentVisibility = gridObject.originObject.visibleOnDevices;
      [_breakpointModes.EXTRA_SMALL, _breakpointModes.SMALL, _breakpointModes.MEDIUM, _breakpointModes.LARGE].forEach(breakpoint => {
        if (!childrenVisibility[breakpoint] && parentVisibility[breakpoint]) {
          parentVisibility.set(`${breakpoint}`, childrenVisibility[breakpoint]);
        }
      });
    }
    /**
     * Initialise grid structure on structure copying
     * @param {Ember.Object & Copyable} pageViewModel
     * @returns void
     */


    initStructure(pageViewModel) {
      const {
        children
      } = pageViewModel.originObject;

      if (children) {
        children.forEach(child => this.initStructure(child));
        return;
      }

      const parent = pageViewModel === null || pageViewModel === void 0 ? void 0 : pageViewModel.parent;

      if (!parent) {
        return;
      }

      this._adjustGridObjectVisibility(parent);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "structureService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bootstrapService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GridService;
});