define("builder/services/widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetService
   */
  var _default = Ember.Service.extend({
    /**
     * Get configuration of widget with default values setup
     *
     * @param {String} type - name/type of widget
     * @returns {Object}
     */
    getConfig(type) {
      const configFactory = Ember.getOwner(this).lookup(`widget:configs/${type}`);
      let config = null;

      if (configFactory) {
        config = configFactory.getConfig();
      }

      return config;
    },

    /**
     * Set data to widget
     *
     * @param {EmberObject} widget - originObject itself
     * @param {String} name - name of property to set
     * @param {*} value - value to set
     * @param {String} breakpoint - breakpoint for which value should be set, if supported
     * @param type
     */
    setData(widget, name, value, breakpoint, type = null) {
      const setter = Ember.getOwner(this).lookup(`widget:data-setters/${type !== null ? type : widget.get('type')}`);

      if (!setter) {
        throw new Ember.Error(`Data setter is not found for widget ${widget.get('type')}`);
      }

      setter.setData(widget, name, value, breakpoint);
    }

  });

  _exports.default = _default;
});