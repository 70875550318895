define("builder/pods/application-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tEXhnaij",
    "block": "{\"symbols\":[],\"statements\":[[5,\"builder-error-message\",[],[[\"@message\"],[\"Application error\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/application-error/template.hbs"
    }
  });

  _exports.default = _default;
});