define("builder/services/form", ["exports", "builder/core/form/utils", "builder/core/enumerators/form-fields", "builder/core/enumerators/container"], function (_exports, _utils, _formFields, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormService
   */
  var _default = Ember.Service.extend({
    /**
     * Ember data store
     * @type {import('ember-data').DS.Store}
     */
    _store: Ember.inject.service('store'),

    /**
     * Page module service
     * @type {import('ember').Ember.Service}
     */
    pageModuleService: Ember.inject.service('page-module'),

    /**
     * Array to keep new created forms
     * @type {object[]} forms -
     */
    forms: [],

    /**
     * Storage of form current structures (before save or discard)
     * @type {object}
     * @private
     */
    _formTempStructures: {},

    /**
     * Saves form temp structure
     * @param {number} formId - Form id
     * @param {object} structure - Form structure
     * @returns {void}
     */
    saveTempStructure(formId, structure) {
      this._formTempStructures[formId] = structure;
    },

    /**
     * Gets form temporary structure
     * @param {number} formId - Form id
     * @returns {object | undefined}
     */
    getTempStructure(formId) {
      return this._formTempStructures[formId];
    },

    /**
     * Removes form temporary structure
     * @param {number} formId
     */
    removeTempStructure(formId) {
      if (this._formTempStructures[formId]) {
        delete this._formTempStructures[formId];
      }
    },

    /**
     * Load form from the store by id
     *
     * @param {number} id - id of form to load
     * @param {boolean} [reload=false] - flag to reload record or not
     * @returns {Promise<object | null>} - request promise, after resolving will be DS.Model or null
     */
    getForm(id, reload = false) {
      let record;

      if (reload && !(0, _utils.isTemporaryFormId)(id)) {
        record = this._store.findRecord('form', id, {
          reload: true
        });
      } else {
        record = this._store.peekRecord('form', id); // Finally if form isn't cached, we must make request to server to get form structure

        if (!record && !(0, _utils.isTemporaryFormId)(id)) {
          record = this._store.findRecord('form', id);
        } else {
          // Guarantee promise
          record = Ember.RSVP.resolve(record);
        }
      }

      return record;
    },

    /**
     * Returns form by config id
     * @param {number} configId - form config id
     * @param {boolean} reload - flag to reload form model or not
     * @returns {Promise<object | null>}
     */
    getFormByConfigId(configId, reload = false) {
      return this.pageModuleService.getConfig(configId).then(config => this.getForm(config.get('Structure').formId, reload));
    },

    /**
     * Finds field in form structure by type
     * @param {object} structure - Structure to search in
     * @param {string} fieldType - Field type
     * @returns {object | null}
     */
    findFieldByType(structure, fieldType) {
      if (!structure || !structure.children) {
        return null;
      }

      const {
        children
      } = structure;
      const {
        length
      } = children;
      let field = null;

      for (let i = 0; i < length; i++) {
        const {
          type
        } = children[i].data;

        if (type === fieldType) {
          field = children[i];
          break;
        } else if (type === _formFields.default.FIELDSET || type === _formFields.default.FORM_STEP || type === _container.default.WIDGET_COLUMN || type === _container.default.WIDGET_ROW) {
          field = this.findFieldByType(children[i], fieldType);

          if (field) {
            break;
          }
        }
      }

      return field;
    },

    /**
     * Update form structure
     *
     * @method updateStructure
     * @param {Number} formId - id of form to update structure
     * @param {FormParams } params - form parameters
     * @returns {Promise<object | null>}
     */
    updateStructure(formId, params) {
      return this.getForm(formId).then(form => {
        const {
          title = form.get('Title'),
          formType = form.get('FormType'),
          newStructure = form.get('Structure'),
          isEncrypted = form.get('IsEncrypted')
        } = params;
        form.set('Title', title);
        form.set('FormType', formType);
        form.set('Structure', newStructure);
        form.set('IsEncrypted', isEncrypted);
        return form;
      });
    },

    /**
     * Creates new form structure
     * @param {number} moduleConfigId - id of form config to create structure
     * @param {FormParams} params - form parameters
     * @returns {void}
     */
    createStructure(moduleConfigId, params) {
      const form = this.findForm(moduleConfigId);
      const {
        title,
        formType,
        formImage,
        newStructure,
        leadroutes,
        isEncrypted
      } = params;

      if (!form) {
        this.forms.pushObject(Ember.Object.create({
          moduleConfigId,
          Title: title,
          FormType: formType,
          Image: formImage,
          Structure: newStructure,
          Leadroutes: leadroutes,
          IsEncrypted: isEncrypted
        }));
      } else {
        form.setProperties({
          Title: title,
          FormType: formType,
          Image: formImage,
          Structure: newStructure,
          Leadroutes: leadroutes,
          IsEncrypted: isEncrypted
        });
      }
    },

    /**
     * Remove modified form
     * @param {number} moduleConfigId - id of form config, that identifies the form
     * @returns {void}
     */
    removeForm(moduleConfigId) {
      const form = this.findForm(moduleConfigId);

      const formModel = this._store.peekRecord('form', (0, _utils.makeTemporaryFormId)(moduleConfigId));

      if (formModel) {
        formModel.unloadRecord();
      }

      this.forms.removeObject(form);
    },

    /**
     * Actions clears forms storage
     *
     * @returns {void}
     */
    clear() {
      const {
        forms
      } = this;
      const store = this._store;

      if (!forms.length) {
        return;
      }

      for (let i = 0, len = forms.length; i < len; i++) {
        const formModel = store.peekRecord('form', (0, _utils.makeTemporaryFormId)(forms[0].moduleConfigId));

        if (formModel) {
          formModel.unloadRecord();
        }
      }

      this.set('forms', []);
    },

    /**
     * Returns forms from storage by config id
     * @param {number} moduleConfigId - module config id
     * @returns {object | null}
     */
    findForm(moduleConfigId) {
      return this.forms.findBy('moduleConfigId', moduleConfigId);
    }

  });

  _exports.default = _default;
});