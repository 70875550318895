define("builder/models/menu-child", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "builder/core/copy-menu-management-config", "ember-copy", "ember-data-model-fragments/attributes"], function (_exports, _model, _fragment, _copyMenuManagementConfig, _emberCopy, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO: all properties should be moved into model
   *
   * @class MenuChildModel
   */
  const Model = _fragment.default.extend({
    /**
     * @property {Number} itemId - Menu item id
     */
    itemId: (0, _model.attr)('number'),

    /**
     * @property {Number} parentId - Parent id
     */
    parentId: (0, _model.attr)('number'),

    /**
     * @property {MenuChildLink} link - Menu item link model
     */
    link: (0, _attributes.fragment)('menu-child-link'),

    /**
     * @property {String} title - Menu item title
     */
    title: (0, _model.attr)('string'),

    /**
     * @property {String} type - Menu item type
     */
    type: (0, _model.attr)('string'),

    /**
     * @property {Number} isHidden - Hidden state of menu item
     */
    isHidden: (0, _model.attr)('number'),

    /**
     * @property {Boolean} isDelete - property shows the remoteness of the menu item
     */
    isDelete: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * @property {Object} config - Menu item config
     */
    config: (0, _model.attr)('raw', {
      defaultValue: () => Ember.Object.extend(_emberCopy.Copyable, {
        copy() {
          return (0, _copyMenuManagementConfig._copy)(this);
        }

      }).create()
    }),

    /**
     * @property {MenuChildModel[]} children - Children of menu item
     */
    children: (0, _attributes.fragmentArray)('menu-child', {
      defaultValue: []
    })
  });

  Model.reopenClass({
    /**
     * @property {String} FOLDER - menu item type folder identifier
     */
    FOLDER: 'folder',

    /**
     * @property {String} LINK - menu item type link identifier
     */
    LINK: 'link',

    /**
     * @property {String} PAGE - menu item type page identifier
     */
    PAGE: 'page'
  });
  var _default = Model;
  _exports.default = _default;
});