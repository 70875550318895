define("builder/instance-initializers/css-generator", ["exports", "dealerfire-builder2-cssgenerator"], function (_exports, _dealerfireBuilder2Cssgenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    const device = applicationInstance.lookup('service:device');
    const cssGenerator = applicationInstance.lookup('css-generator:main');
    cssGenerator.set('cssGenerator', new _dealerfireBuilder2Cssgenerator.default.Builder2Executor());
    cssGenerator.set('userAgent', new _dealerfireBuilder2Cssgenerator.default.UserAgent());
    cssGenerator.set('mode', 'lg');
    cssGenerator.set('imageCdnBaseUrl', appBootstrap.initData.imageCdnBaseUrl);
    cssGenerator.set('device', device);
  }

  var _default = {
    name: 'initial',
    initialize
  };
  _exports.default = _default;
});