define("builder/pods/components/page-template-editor/component", ["exports", "builder/core/enumerators/template-visibility", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _templateVisibility, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @type {Number} - thumbnail upload success status code
   */
  const UPLOAD_SUCCESS = 1;
  /**
   * @class PageTemplateEditorComponent
   */

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: '',

    /**
     * @property {Ember.Service<ConfigurationService>} configuration - configuration service
     */
    configuration: Ember.inject.service(),

    /**
     * @type {Ember.Service}
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * @property {Object[]} visibilities - list of page template visibilities available
     * TODO: Should be {PageTemplateVisibilityModel[]}, but who cares
     */
    visibilities: null,

    /**
     * @property {Object[]} visibilityChoices - parsed visibility, used to detect if visibilities where passed
     */
    visibilityChoices: null,

    /**
     * @property {PageCategory[]} categories - list of page template categories available
     */
    categories: null,

    /**
     * @property {Object[]} categoryChoices - parsed categories, used to detect if categories where loaded
     */
    categoryChoices: null,

    /**
     * @property {Boolean} isValid - is template name is valid
     */
    isValid: true,

    /**
     * @property {Boolean} isSaveAllowed - is page template allowed to be saved, workaround, instead of model.get('isValidonFrontend')
     */
    isSaveAllowed: true,

    /**
     * @property {String} title - template editor title
     */
    title: 'Page template editor',

    /**
     * @property {String|null} name - page template name
     */
    name: null,

    /**
     * @property {String|null} visibility - page template visibility
     * TODO: should be {VisibilityModel|null}, but who cares
     */
    visibility: null,

    /**
     * @property {Number|null} category - page template category
     * TODO: Should be {CategoryModel|null}, but who cares
     */
    category: null,

    /**
     * @property {String|null} thumbnail - path to page template thumbnail
     */
    thumbnail: null,

    /**
     * Url to upload thumbnail
     * @type {string}
     */
    uploadUrl: Ember.computed('configuration.sessionId', function () {
      return `${_environment.default.apiURL}/upload?${_cookie.default.SESSION_ID}=${this.configuration.sessionId}&${this.companyAndWebsite.getCompanyWebsiteParams()}`;
    }),

    /**
     * @property {String} uploadName - name of upload file(property name, not file name)
     */
    uploadName: 'tmp_file',

    /**
     * @property {String|null} lastError - last error message to be displayed
     */
    lastError: null,

    /**
     * @property {Boolean} loading - is template uploading
     */
    loading: false,

    /**
     * @property {String} saveButtonText - save button text
     */
    saveButtonText: 'Save',

    /**
     * TODO: tmp solutions, to pass to our field component
     *
     * @property {Object} fieldName - field name config
     */
    fieldName: null,

    /**
     * TODO: tmp solution, to pass to our field component
     *
     * @property {Object} fieldVisibility - field visibility config
     */
    fieldVisibility: null,

    /**
     * TODO: tmp solution, to pass to our field component
     *
     * @property {Object} fieldCategory - field category config
     */
    fieldCategory: null,

    /**
     * Is global page template
     * @type {boolean}
     */
    isGlobalPageTemplate: false,

    /**
     * @property {Boolean} isGlobalVisibility - is template visibility global
     */
    isGlobalVisibility: Ember.computed('fieldVisibility.data', function () {
      return this.get('fieldVisibility.data') === _templateVisibility.default.VISIBILITY_GLOBAL;
    }),

    /**
     * @property {Boolean} buttonSaveDisabled - is button save disabled
     */
    buttonSaveDisabled: Ember.computed('isSaveAllowed', 'isValid', function () {
      return !(this.isSaveAllowed && this.isValid);
    }),

    /**
     * Method to be invoked to update visibility
     */
    updateVisibility() {},

    /**
     * Method to be invoked on thumbnail update, needs to be overridden
     *
     * @param {String} imageId - upload image id
     * @param {String} thumbnailUrl - url to thumbnail image
     */
    updateThumbnail() {},

    /**
     * Method to be invoked on thumbnail remove, needs to be overridden
     */
    removeThumbnail() {},

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.visibilities = this.visibilities || [];
      this.categories = this.categories || [];
      this.set('fieldName', {
        label: this.get('literals.PAGE_TEMPLATE_FIELD_NAME_LABEL'),
        data: null,
        validation: {
          required: {
            presence: true,
            ignoreBlank: true
          },
          length: {
            min: 1,
            max: 255
          }
        }
      });
      this.set('fieldVisibility', {
        label: this.get('literals.PAGE_TEMPLATE_FIELD_VISIBILITY_LABEL'),
        choices: [],
        data: null
      });
      this.set('fieldCategory', {
        label: this.get('literals.PAGE_TEMPLATE_FIELD_CATEGORY_LABEL'),
        choices: [],
        data: null
      });
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      let {
        visibilityChoices
      } = this; // eslint-disable-next-line prefer-rest-params

      this._super(...arguments);

      this.set('fieldName.data', this.name); // TODO: for now, visibility wont change, so we do not need to check if it's was changed or not

      if (!visibilityChoices) {
        visibilityChoices = (this.visibilities || []).map(item => ({
          value: item.id,
          label: item.title
        }));
        this.set('fieldVisibility.choices', visibilityChoices);
        this.set('visibilityChoices', visibilityChoices);
      }

      this.set('fieldVisibility.data', this.visibility);
      /**
       * If global visibility selected, load categories and select 1-st from the list if none selected
       */

      if (this.category || this.isGlobalVisibility) {
        this.fetchCategories().then(categories => {
          let {
            categoryChoices
          } = this;
          let {
            category
          } = this;

          if (!categoryChoices) {
            categoryChoices = (categories || []).map(item => ({
              value: item.get('id'),
              label: item.get('Name')
            }));
            this.set('fieldCategory.choices', categoryChoices);
            this.set('categoryChoices', categoryChoices);
          } // When global visibility selected, select 1-st category from the list


          if (this.isGlobalVisibility && !this.category && categories.get('length')) {
            category = categories.objectAt(0).get('id');
          }

          this.send('updateCategory', category);
        });
      }
    },

    /**
     * Method to be invoked, to load categories, when global visibility selected, expects promise
     */
    fetchCategories() {
      throw new Ember.Error('Provide fetch method for the component');
    },

    /**
     * Extract upload error code from upload response
     * NOTE: this part could be migrate to specific error code extractor that will work with current upload service
     *
     * @param {Object} response - upload response
     * @returns {Number} - error code
     * @private
     */
    _getUploadErrorStatusCode(response) {
      const errorCode = response.hasOwnProperty('errors') && response.errors.length ? response.errors[0].status : 500;
      return parseInt(errorCode, 10);
    },

    /**
     * Get error message for server error, won't handle success error codes
     * NOTE: this part of code could migrate to separate handler, if dynamic uploader will be used
     *
     * @param {Number} errorCode - server error code
     * @returns {String} - error message
     * @private
     */
    _getUploadErrorMessageByCode(errorCode = 500) {
      let message;

      switch (errorCode) {
        case 413:
          {
            message = this.get('literals.PAGE_TEMPLATE_THUMBNAIL_UPLOAD_ERROR_FILE_TOO_BIG');
            break;
          }

        default:
          {
            message = this.get('literals.PAGE_TEMPLATE_THUMBNAIL_UPLOAD_ERROR');
          }
      }

      return message;
    },

    /**
     * Extract upload error message from upload response
     * NOTE: this part of code could migrate to separate handler, if dynamic uploader will be used
     *
     * @param {Object} response - upload response
     * @returns {String} - upload error message
     * @private
     */
    _getUploadErrorMessageFromResponse(response) {
      return response && response.hasOwnProperty('message') ? response.message : this.get('literals.PAGE_TEMPLATE_THUMBNAIL_UPLOAD_ERROR');
    },

    actions: {
      /**
       * Track template name validity
       *
       * @param {String} name - new template name
       * @param {Boolean} isValid - is template name valid
       */
      updateNameValidity(name, isValid) {
        this.set('isValid', isValid);
      },

      updateName(name) {
        this.set('fieldName.data', name);
        this.set('name', name);
      },

      updateVisibility(visibility) {
        this.updateVisibility(visibility);
      },

      updateCategory(category) {
        this.set('fieldCategory.data', category);
        this.set('category', category);
      },

      removeThumbnail() {
        this.removeThumbnail();
      },

      dismissErrorMessage() {
        this.set('lastError', null);
      },

      requestUpload(uploadCallback) {
        if (!this.loading) {
          uploadCallback();
        }
      },

      startUpload() {
        this.set('loading', true);
        this.set('showHint', false);
      },

      /**
       * TODO: bubble up handlers when will be needed
       * TODO: if flexibility will be needed in handling response, use proxy method to extract required data from response
       * then pass it to updateThumbnail method
       * ex. var data = this.extractData(response); this.updateThumbnail.apply(null, data);
       *
       * @param {Boolean} success - is upload was successful
       * @param {Object} response - upload response
       *
       * example of response object:
       * {
       *     "status": 1,
       *     "id": "238327f374c08e8a",
       *     "url": "/temp\/238327f374c08e8a.png",
       *     "message": ""
       * }
       */
      endUpload(success, response) {
        let errorMessage = null;
        this.set('loading', false);

        if (success && response.status === UPLOAD_SUCCESS) {
          const url = `${_environment.default.apiURL}${response.url}`;
          this.updateThumbnail(response.id, url);
        } else {
          errorMessage = success ? this._getUploadErrorMessageFromResponse(response) : this._getUploadErrorMessageByCode(this._getUploadErrorStatusCode(response));
        }

        this.set('lastError', errorMessage);
      }

    }
  });

  _exports.default = _default;
});