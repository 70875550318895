define("builder/pods/components/builder-error-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OH/TpQHH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"b2-builder-error-message__content\"],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"b2-builder-error-message__icon\"],[8],[9],[0,\"\\n\\t\"],[7,\"h1\",true],[10,\"class\",\"b2-builder-error-message__message\"],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"b2-builder-error-message__description\"],[8],[1,[22,\"description\"],false],[9],[0,\"\\n\\t\"],[7,\"a\",true],[11,\"href\",[22,\"linkTo\"]],[10,\"class\",\"b2-builder-error-message__reload-button\"],[8],[0,\"Reload\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"b2-builder-error-message__support-contacts\"],[8],[0,\"\\n\\t\\tYou may see this message if your account does not have access to the desired content.\"],[7,\"br\",true],[8],[9],[0,\" \\n\\t\\tIf you believe you are seeing this page in error, please \"],[7,\"a\",true],[10,\"href\",\"mailto:websitesupport@dealersocket.com\"],[8],[0,\"contact Support.\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/builder-error-message/template.hbs"
    }
  });

  _exports.default = _default;
});