define("builder/core/drop-rules/top-container", ["exports", "builder/core/drop-rules/base-rule"], function (_exports, _baseRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Rules for top-container
   */
  var _default = _baseRule.default.extend({
    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_allowedTypes', ['widget-header', 'widget-main', 'widget-footer']);
    },

    /**
     * @inheritdoc
     */
    canDrop() {
      return false;
    }

  });

  _exports.default = _default;
});