define("builder/models/page-category", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // List of mapped access groups id => group name
  const accessControlGroups = {
    102: 'My',
    103: 'My',
    1: 'Global',
    2: 'Global',
    3: 'Global',
    4: 'Global',
    5: 'Global'
  };
  const GLOBAL_PAGES_CATEGORY = '104';
  /**
   * @class PageCategoryModel
   */

  const PageCategoryModel = _model2.default.extend({
    /**
     * @property {String} Name - category name(title)
     */
    Name: (0, _model.attr)('string'),

    /**
     * @property {String[]} Templates - list of template's ids that belong to this category
     */
    Templates: (0, _model.attr)('raw'),

    /**
     * @param {Number} IsStatic - is current category static(or virtual)
     */
    IsStatic: (0, _model.attr)('number'),

    /**
     * Can you smell this? This is THE smell, this shouldn't be here, think were to move it
     *
     * @property {String|null} accessControlGroup - access control group current category relates to
     * @readonly
     */
    accessControlGroup: Ember.computed('id', function () {
      const {
        id
      } = this;
      return accessControlGroups.hasOwnProperty(id) ? accessControlGroups[id] : null;
    }).readOnly(),

    /**
     * Is global pages category
     * @type {boolean}
     * @readonly
     */
    isGlobalPagesCategory: Ember.computed.equal('id', GLOBAL_PAGES_CATEGORY).readOnly()
  });

  var _default = PageCategoryModel;
  _exports.default = _default;
});