define("builder/pods/website/edit/page/create-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eiVZFxUf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"widget-popup\",null,[[\"class\",\"closeHandler\",\"isFixed\"],[\"widget-popup_theme_create-page\",[28,\"action\",[[23,0,[]],\"closePopup\"],null],true]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"page_create\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_create_aside\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"page_create_categories\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_create_templates\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"page_create_templates-title\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_create_templates-list\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex flex_center flex_full-size\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"module-preloader module-preloader_theme_menu-preload\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"module-preloader__item\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_create_footer\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"bttn bttn-default page_create_footer-button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"closePopup\"]],[8],[0,\"Cancel\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"bttn bttn-default __disabled\"],[10,\"type\",\"button\"],[8],[0,\"\\n\\t\\t\\t\\tNext\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/edit/page/create-loading/template.hbs"
    }
  });

  _exports.default = _default;
});