define("builder/services/configuration-system-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for operations with system modules within configuration context
   */
  let ConfigurationSystemModuleService = (_dec = Ember.inject.service('store'), (_class = (_temp = class ConfigurationSystemModuleService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_store", _descriptor, this);
    }

    /**
     * Saves all system modules
     * @todo
     * TODO: get rid of link to exact controller. We need to change the way we are working
     * with system modules and their update flow.
     * @param {SystemModulesSaveAllCommand} command
     * @returns {Promise<void>}
     */
    async saveAll({
      setPublishLayout,
      modifiedSystemModules,
      setModifiedSystemModules
    }) {
      // Save system module in the following cases:
      // When record is new and has config id
      // When record already exists and was modified
      // When config was changed (send PUT request to move config even system module wasn't changed)
      const promises = this._store.peekAll('system-module').map(async module => {
        if (module.isNew && module.ModuleConfigurationId || !module.isNew && module.hasDirtyAttributes || modifiedSystemModules.includes(module)) {
          setPublishLayout(true);
          await module.save();
        }
      });

      await Promise.all(promises); // TODO: we need to change system modules update process, so we does not need to
      // store list of updated system modules. The best way is to mark added system modules
      // as dirty, so save() method will send request to backend.
      // see method _addSystemModuleToUpdateQueue
      // at app/pods/website/edit/page/edit/module/settings/controller.js

      setModifiedSystemModules([]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ConfigurationSystemModuleService;
});