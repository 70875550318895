define("builder/pods/components/switcher-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component which simulate checkbox button work.
   *
   * Example:
   * 	{{switcher-button isActive=true change=yourHandler}}
   *
   *	If you pass handler you can observe component state which passed as argument.
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['b2-switcher-button'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isActive:b2-switcher-button_active'],

    /**
     * @inheritdoc
     */
    tagName: 'button',

    /**
     * @property {Boolean} isActive - on/off state
     */
    isActive: false,

    /**
     * Handler which change component state and notify subscriber if handler is register
     *
     * @inheritdoc
     */
    click() {
      this.toggleProperty('isActive');

      if (typeof this.change === 'function') {
        this.change(this.isActive);
      }
    },

    /**
     * @property {String} label - contain label which depends on state(on/off)
     * @readonly
     */
    label: Ember.computed('isActive', function () {
      return this.isActive ? 'on' : 'off';
    }).readOnly()
  });

  _exports.default = _default;
});