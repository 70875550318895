define("builder/pods/website/create/route", ["exports", "builder/routes/application", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _application, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Website create route
   * @class
   */
  var _default = _application.default.extend({
    /**
     * @property {Object} companyAndWebsite - company-and-website service
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * @inheritDoc
     */

    /**
     * @property {AjaxService} ajaxService - ajax service
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * @property {ConfigurationService} configurationService - page service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @inheritdoc
     */
    model(params, transition) {
      const websiteId = this.companyAndWebsite.getWebsiteId();
      const isExist = this.store.peekRecord('website', websiteId);

      if (isExist) {
        transition.abort();
        this.transitionTo('website.edit', websiteId);
        return {};
      }

      return this.ajaxService.request(`${_environment.default.apiURL}/website/0?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configurationService.sessionId')}`);
    },

    /**
     * @inheritdoc
     */
    handleAccessDenied() {
      return this.store.findAll('website').then(websites => {
        const websiteId = this.companyAndWebsite.getWebsiteId();
        const website = websiteId ? websites.findBy('id', websiteId) : websites.objectAt(0);
        return website || null;
      }).then(website => {
        if (website) {
          this.transitionTo('website.edit', website.get('id'));
        } else {
          this.transitionTo('access-denied');
        }
      });
    }

  });

  _exports.default = _default;
});