define("builder/pods/components/misc-bullet-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "25i2JK7y",
    "block": "{\"symbols\":[],\"statements\":[[5,\"field-dropdown\",[],[[\"@field\",\"@actionHandler\"],[[22,\"dropdownOptions\"],[28,\"action\",[[23,0,[]],\"onBulletSelect\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/misc-bullet-selector/template.hbs"
    }
  });

  _exports.default = _default;
});