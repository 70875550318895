define("builder/pods/components/field-tags/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    classNames: ['bld-form_tags'],

    willInsertElement() {
      this.applyValidations();
    },

    didInsertElement() {
      const {
        field
      } = this;
      const options = {
        placeholder: field.placeholder || 'Please select',
        tags: this.tags
      };

      if (!field.allowOther) {
        options.createSearchChoice = function () {
          return null;
        };
      }

      this.$('.bld-form_tags_input').select2(options);

      if (this.triggerOnRender && this.get('field.data')) {
        this._callActionHandler();
      }
    },

    dataString: Ember.computed('field.data', function () {
      const data = this.get('field.data');
      return data && data.join(',') || '';
    }),
    onDataChange: Ember.observer('dataString', function () {
      const {
        dataString
      } = this;
      let data = [];

      if (dataString) {
        data = dataString.split(',');
      }

      this.set('field.data', data);

      this._callActionHandler();
    }),
    tags: Ember.computed('field.choices', function () {
      const choices = this.get('field.choices') || [];
      return choices.map(choice => ({
        id: choice.value,
        text: choice.label
      }));
    }),

    /**
     * Calls parent action handler
     * @private
     */
    _callActionHandler() {
      const action = this.actionHandler;

      if (action && !this.notValid) {
        action(this.get('field.data'), this.get('field.name'));
      }
    }

  });

  _exports.default = _default;
});