define("builder/core/visibility-filters/statement-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isConfigurationStatementTrue = isConfigurationStatementTrue;
  const ASSERTS_MAP = {
    equals: (fieldValue, conditionValue) => Ember.isEqual(fieldValue, conditionValue),
    notEquals: (fieldValue, conditionValue) => !Ember.isEqual(fieldValue, conditionValue),
    isEmpty: (fieldValue, conditionValue) => conditionValue ? Ember.isEmpty(fieldValue) : !Ember.isEmpty(fieldValue)
  };
  /**
   * Evaluates statement about field value
   * @param {FieldConfigurationNode[]} configurationMap - flat map of fields and their values
   * @param {Statement} statement - statement about field value
   * @param {string} [basePath=''] - base path where to perform field search
   */

  function isConfigurationStatementTrue(configurationMap, statement, basePath = '') {
    if (!configurationMap || !configurationMap.length) {
      return false;
    }

    const assertName = Object.keys(statement).filter(prop => prop !== 'field').pop();
    const assert = ASSERTS_MAP[assertName];

    if (typeof assert !== 'function') {
      return false;
    }

    const searchPath = statement.field.startsWith('/') ? statement.field : `${basePath}/${statement.field}`;
    const configurationNode = configurationMap.find(node => node.path === searchPath);

    if (!configurationNode) {
      return false;
    }

    const fieldValue = configurationNode.value;
    const conditionValue = statement[assertName];
    return assert(fieldValue, conditionValue);
  }
});