define("builder/core/froala/metatags/dropdown-vehicles", ["exports", "froala-editor", "builder/core/meta-tags/meta-variables-configuration", "builder/core/froala/metatags/meta-config-mapper", "builder/core/froala/metatags/metatags-insert"], function (_exports, _froalaEditor, _metaVariablesConfiguration, _metaConfigMapper, _metatagsInsert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vehiclesDropdownCommand = void 0;

  /* eslint-disable new-cap */
  const vehiclesDropdownCommand = 'metatags-vehicle-dropdown';
  _exports.vehiclesDropdownCommand = vehiclesDropdownCommand;
  const vehicleIconName = 'vehicle-icon';

  _froalaEditor.default.DefineIconTemplate(vehicleIconName, '<i class="fa fa-car"></i>');

  _froalaEditor.default.DefineIcon(vehiclesDropdownCommand, {
    NAME: vehicleIconName,
    template: vehicleIconName
  });

  _froalaEditor.default.RegisterCommand(vehiclesDropdownCommand, {
    title: 'Vehicles meta',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: (0, _metaConfigMapper.mapMetatagsConfigToFroalaOptions)(_metaVariablesConfiguration.vehicle),

    callback(cmd, value) {
      (0, _metatagsInsert.insertMetatag)(this, value);
    },

    // Callback on refresh.
    refresh($btn) {},

    // Callback on dropdown show.
    refreshOnShow($btn, $dropdown) {}

  });
});