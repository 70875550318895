define("builder/pods/components/field-multiselect-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qS2ObO6a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"multiselect-item __draggable\",[28,\"if\",[[24,[\"isSelect\"]],\" __selected\"],null],[28,\"if\",[[24,[\"isNew\"]],\" __new\"],null],[28,\"if\",[[24,[\"isEdit\"]],\" __edit\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"multiselect-item_controls\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isEditable\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-pencil\"],[3,\"action\",[[23,0,[]],\"edit\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"isEdit\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-times-circle\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"fa fa-trash-o \",[28,\"unless\",[[24,[\"isRemoveEnabled\"]],\"__hidden\"],null]]]],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"item\",\"isEdit\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"input\",null,[[\"value\",\"change\",\"key-up\",\"placeholder\"],[[24,[\"item\",\"value\"]],[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"handleByKey\"],null],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",false],[12,\"class\",\"multiselect-item_text\"],[12,\"title\",[24,[\"item\",\"value\"]]],[3,\"action\",[[23,0,[]],\"select\"]],[8],[1,[24,[\"item\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-multiselect-item/template.hbs"
    }
  });

  _exports.default = _default;
});