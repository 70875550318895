define("builder/utils/math-extended", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class MathExtended
   */
  var _default = Ember.Object.create({
    /**
     * Round to nearest fraction
     *
     * @param {Number} number - number to round
     * @param {Number} fraction - rounding fraction
     * @returns {Number} - rounded value
     */
    toNearestFraction(number, fraction) {
      return Math.round(number / fraction) * fraction;
    },

    cropToDigits(number, digits = 0) {
      const multiplier = 10 ** digits;
      return Math.floor(number * multiplier) / multiplier;
    }

  });

  _exports.default = _default;
});