define("builder/pods/website/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15E2kaGq",
    "block": "{\"symbols\":[\"font\"],\"statements\":[[4,\"each\",[[24,[\"model\",\"websiteFonts\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"font-loader\",null,[[\"font\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/edit/template.hbs"
    }
  });

  _exports.default = _default;
});