define("builder/services/configuration-form", ["exports", "builder/core/form/utils", "builder/core/enumerators/module", "builder/models/form", "builder/core/enumerators/notification", "builder/core/enumerators/container"], function (_exports, _utils, _module, _form, _notification, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service for operations with forms within configuration context
   */
  let ConfigurationFormService = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('form'), _dec3 = Ember.inject.service('structure'), _dec4 = Ember.inject.service('form-validator'), (_class = (_temp = class ConfigurationFormService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "formService", _descriptor2, this);

      _initializerDefineProperty(this, "structureService", _descriptor3, this);

      _initializerDefineProperty(this, "formValidatorService", _descriptor4, this);
    }

    /**
     * Validates and saves all forms on a page
     *
     * Temporary solution.
     * Right now controller uses its local state while saving entities.
     * @todo
     * TODO: move entity state change here during controller method refactoring
     * Forms save process is a rabbit hole. It is spread among generic methods, services,
     * controllers and utility functions.
     * To refactor this mess, forms save workflow should be documented first.
     * @param {FormSaveAllCommand} command - forms save parameters
     * @returns {Promise<void>}
     */
    async saveAll({
      isFormModuleChanged,
      website,
      page,
      supportContactsMessage,
      showNotification
    }) {
      const validationResult = await this._validateForms(website, page, this.structureService.pageViewModel);

      if (validationResult.warnings.length) {
        const message = validationResult.warnings.join('<br>');
        showNotification([message, supportContactsMessage].join('<br>'), _notification.Notification.TYPE.WARNING, Number.MAX_SAFE_INTEGER);
      }

      await this._saveForms({
        isFormModuleChanged
      });
    }
    /**
     *
     * @param {FormSaveAllCommand} command
     */


    async _saveForms({
      isFormModuleChanged
    }) {
      const {
        forms
      } = this.formService;
      const formModelsToSave = this.store.peekAll('form').filter(model => !(0, _utils.isTemporaryFormId)(model.get('id')));

      const promises = this._saveModels(formModelsToSave);

      let formSaveError = false;
      let formRecord; // If we have changed inside form, form pushed in forms array in form service
      // but if we have only config parameter in form we need find all dirty form in page-module model
      // and send on preview service changes with tempUpdateModule method and "clear" model with fake save method
      // TODO better way it's replace tempUpdateModule on ember model, but need change page-module and refactoring
      // TODO logic config auto save other module not only form, I hope we will have time for do it's later )

      forms.forEach(form => {
        const config = this.store.peekRecord('page-module', form.moduleConfigId);

        if (!config) {
          return;
        }

        const temporaryFormModel = this.store.peekRecord('form', (0, _utils.makeTemporaryFormId)(form.moduleConfigId));
        formRecord = this.store.createRecord('form', {
          Title: temporaryFormModel.Title,
          FormType: temporaryFormModel.FormType,
          Image: temporaryFormModel.Image,
          Structure: temporaryFormModel.Structure,
          Leadroutes: temporaryFormModel.Leadroutes,
          IsEncrypted: temporaryFormModel.IsEncrypted,
          SuccessScreenType: temporaryFormModel.SuccessScreenType,
          SuccessHeading: temporaryFormModel.SuccessHeading,
          SuccessContent: temporaryFormModel.SuccessContent,
          SuccessPage: temporaryFormModel.SuccessPage
        });
        temporaryFormModel.unloadRecord();
        promises.push(formRecord.save().then(record => {
          Ember.set(config.Structure, 'formId', record.id);
          return config.save();
        }).catch(() => {
          formSaveError = true;
        }));
      });

      if (isFormModuleChanged) {
        this.store.peekAll('page-module').filter(item => !this.formService.findForm(parseInt(item.get('id'), 10)) && item.hasDirtyAttributes && item.Structure.formId).forEach(formModule => {
          if (formModule.isDeleted) {
            // Remove deleted records from store
            this.store.unloadRecord(formModule);
          } else {
            formModule.save({
              adapterOptions: {
                fakeRequest: true
              }
            });
            promises.push(formModule.save());
          }
        });
      }

      this._resetFormsConfirmation(this.structureService.pageViewModel);

      await Promise.all(promises);

      if (!formSaveError) {
        // After save, temp form instance became persistent -> must be removed from the list of temp forms
        // Make sure all forms created w/o error and then clean them
        this.formService.clear();
      }
    }
    /**
     * Reset form module confirmation properties to allow user show prompt after page save
     * @param {object} [structure] - page structure
     */


    _resetFormsConfirmation(structure) {
      const currentStructure = structure;

      if (this.structureService.isType(currentStructure, _module.default.MODULE_FORM)) {
        currentStructure.setProperties({
          formIsConfirmed: false,
          confirmedOption: null
        });
      } else if (currentStructure.originObject.children) {
        currentStructure.originObject.children.forEach(child => this._resetFormsConfirmation(child));
      }
    }
    /**
     * Method save any models.
     *
     * @param {import('ember-data').DS.Model[]} arModels - any Ember models
     * @return {Promise[]}
     */


    _saveModels(arModels) {
      const promises = [];
      arModels.forEach(model => {
        const changedAttributes = Object.keys(model.changedAttributes());

        if (!model.isDeleted && model.hasDirtyAttributes && model.modelName === 'form' && changedAttributes.length === 1 && changedAttributes.toString() === _form.usedPagesProp) {
          // If current model is form and only "UsedInPages" property was changed we don't need
          // to save this model because this property is dynamically calculated by backend
          // but we also use it when add or remove form from the page
          model.rollbackAttributes();
        } else if (model.isDeleted || model.hasDirtyAttributes || model.isCanBeUnload) {
          promises.push(model.save());
        }
      });
      return promises;
    }
    /**
     * Validate forms
     * @param {WebsiteModel} website - website model
     * @param {PageModel} page - page model
     * @param {Object} pageViewModel - current page prepared structure
     * @returns {Promise}
     */


    _validateForms(website, page, pageViewModel) {
      const pageHasKeyChangesForFormsValidation = this._pageHasKeyChangesForFormsValidation(page);

      const header = this.structureService.findByType(pageViewModel, _container.default.WIDGET_HEADER);
      const footer = this.structureService.findByType(pageViewModel, _container.default.WIDGET_FOOTER);
      const body = this.structureService.findByType(pageViewModel, _container.default.WIDGET_MAIN);
      const savedCoreModulesInPage = new Set(this._collectCoreModulesIds(JSON.parse(page.BaseStructure))); // Collect forms from header/footer of page

      const layoutsFormsInfo = [...this.structureService.findAllByType(header, _module.default.MODULE_FORM), ...this.structureService.findAllByType(footer, _module.default.MODULE_FORM)].map(formModule => ({
        inLayout: true,
        moduleId: formModule.originObject.id
      })); // Collect forms from page body

      const bodyFormsInfo = this.structureService.findAllByType(body, _module.default.MODULE_FORM).map(formModule => ({
        inLayout: false,
        moduleId: formModule.originObject.id
      }));
      const formsOnPage = [...layoutsFormsInfo, ...bodyFormsInfo].map(formInfo => {
        const formModuleId = formInfo.moduleId.toString();
        const module = this.store.peekRecord('page-module', formModuleId);

        if (module) {
          const form = this.store.peekRecord('form', module.Structure.formId);
          const formWasMoved = formInfo.inLayout && savedCoreModulesInPage.has(formModuleId) || !formInfo.inLayout && !savedCoreModulesInPage.has(formModuleId);
          const formHasChangesForValidation = form.hasDirtyAttributes || module.hasDirtyAttributes || formWasMoved; // Should send only changed or new forms
          // or should send all forms if page has a key changes e.g. page type

          if (!pageHasKeyChangesForFormsValidation && !formHasChangesForValidation) {
            return null;
          }

          const preparedFormModel = form.toJSON({
            includeId: true
          });
          const {
            formType,
            isEncrypted,
            title
          } = module.Structure; // Get some form fields from not saved page-module model

          preparedFormModel.Title = title;
          preparedFormModel.FormType = formType;
          preparedFormModel.IsEncrypted = isEncrypted;
          return {
            inLayout: formInfo.inLayout,
            model: preparedFormModel
          };
        }
      }).filter(formInfo => !!formInfo);
      return this.formValidatorService.validate(website, page, formsOnPage);
    }
    /**
     * Collect core module ids from serialized page structure
     * @param {Object[]} structure - page structure
     * @param {string[]} [acc=[]] - accomulaor of modules ids
     * @returns {string[]}
     */


    _collectCoreModulesIds(structure, acc = []) {
      structure.forEach(node => {
        const {
          children
        } = node;

        if (node.type === _module.default.MODULE_CORE) {
          acc.push(node.id.toString());
        }

        if (children && children.length) {
          this._collectCoreModulesIds(children, acc);
        }
      });
      return acc;
    }
    /**
     * Check need send form validation request
     * @param {PageModel} page - page model
     * @returns {boolean}
     */


    _pageHasKeyChangesForFormsValidation(page) {
      let result = false; // Check page changes will affected to validation result

      if (page.hasDirtyAttributes) {
        const changedPageAttributes = Object.keys(page.changedAttributes());
        const pageHasKeyChanges = ['Type', 'RequiredParam', 'TypeStructure'].some(fieldName => changedPageAttributes.includes(fieldName));
        result = pageHasKeyChanges;
      }

      return result;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "formService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "structureService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formValidatorService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ConfigurationFormService;
});