define("builder/pods/components/filter-operator/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.selectOperator = selectOperator;
  _exports.default = _exports.SELECTED_OPERATOR = void 0;

  /**
   * @const {String} SELECTED_OPERATOR - Selected operator property
   */
  const SELECTED_OPERATOR = 'isSelected';
  _exports.SELECTED_OPERATOR = SELECTED_OPERATOR;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['bld-filter-operator'],

    /**
     * @property {Boolean} isOpened - Flag to open/close dropdown
     */
    isOpened: false,

    /**
     * @property {Null|Expression} - expression - Expression class
     */
    expression: null,

    /**
     * @property {Object[]|null} - list of operators
     */
    operators: null,

    /**
     * @property {Object} selectedOperator - Selected operator object
     */
    selectedOperator: Ember.computed(`operators.@each.${SELECTED_OPERATOR}`, function () {
      return this.operators.findBy(SELECTED_OPERATOR, true);
    }),

    /**
     * @property {String} selectedOperatorLabel - Selected operator label
     */
    selectedOperatorLabel: Ember.computed('selectedOperator', function () {
      return this.get('selectedOperator.label');
    }),

    /**
     * @property {Array} selectedOperatorChildren - Selected operator children (fields)
     */
    selectedOperatorChildren: Ember.computed('selectedOperator', function () {
      return this.get('selectedOperator.children').map(field => _jquery.default.extend(true, {}, field));
    }),

    /**
     * @inheritDoc
     */
    didReceiveAttrs() {
      if (!this.operators.findBy(SELECTED_OPERATOR)) {
        selectOperator(this.operators.objectAt(0));
      }
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      // If operator is NOT defined then user adds new property, else saved "data" is rendering
      if (!this.expression.getOperator()) {
        this.setOperator(this.expression, this.get('selectedOperator.logic'));
      }
    },

    actions: {
      /**
       * Change event handler triggered on children's fields
       * @param {*} value - Child field value
       */
      onChange(value) {
        this.updateRightOperand(this.expression, value);
      },

      /**
       * Action to change operator
       * @param {Object} operator - Selected operator object
       */
      changeOperator(operator) {
        this.set('isOpened', false);
        Ember.set(this.selectedOperator, SELECTED_OPERATOR, false);
        selectOperator(operator);
        this.updateOperator(this.expression, this.get('selectedOperator.logic'));
      }

    }
  });
  /**
   * Selects passed operator object
   * @param {Object} operator - Filter-operator object
   */


  _exports.default = _default;

  function selectOperator(operator) {
    Ember.set(operator, SELECTED_OPERATOR, true);
  }
});