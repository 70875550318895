define("builder/pods/components/field-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Nk2Ojkq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-form_number_container\"],[8],[0,\"\\n\\t\"],[7,\"input\",true],[11,\"value\",[24,[\"field\",\"data\"]]],[11,\"name\",[24,[\"field\",\"name\"]]],[11,\"class\",[22,\"size\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onChange\"],null]],[10,\"type\",\"number\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-number/template.hbs"
    }
  });

  _exports.default = _default;
});