define("builder/initializers/init-data", ["exports", "builder/core/literals", "builder/core/page-type-provider", "builder/core/page-conditional-type-provider"], function (_exports, _literals, _pageTypeProvider, _pageConditionalTypeProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    const init = window.appBootstrap.initData;
    application.register('data:app', init, {
      instantiate: false
    });
    application.inject('route', 'app', 'data:app');
    application.inject('controller', 'app', 'data:app');
    application.inject('component', 'app', 'data:app');
    application.inject('helper', 'app', 'data:app');
    application.inject('service', 'app', 'data:app');
    application.inject('ability', 'app', 'data:app');
    application.register('literals:system', _literals.default, {
      singleton: true,
      instantiate: false
    });
    application.inject('controller', 'literals', 'literals:system');
    application.inject('route', 'literals', 'literals:system');
    application.inject('component', 'literals', 'literals:system');
    application.inject('service', 'literals', 'literals:system'); // Register page types provider

    const {
      pageTypes
    } = init;
    const defaultPageType = init.pageTypes.findBy('value', 'other');
    pageTypes.sort((a, b) => {
      const aLabel = a.label.toUpperCase();
      const bLabel = b.label.toUpperCase();
      const greaterResult = aLabel > bLabel ? 1 : 0;
      return aLabel < bLabel ? -1 : greaterResult;
    });

    if (!defaultPageType) {
      throw new Ember.Error('Default page type is not exist.');
    }

    pageTypes.splice(pageTypes.indexOf(defaultPageType), 1);
    pageTypes.unshift(defaultPageType);
    application.register('provider:page-types', _pageTypeProvider.default.create({
      availableTypes: pageTypes,
      defaultType: defaultPageType
    }), {
      instantiate: false
    }); // Register page types provider

    application.register('provider:page-conditional-types', _pageConditionalTypeProvider.default.create({
      availableTypes: init.pageConditionalTypes
    }), {
      instantiate: false
    });
  }

  var _default = {
    name: 'init-data',
    before: 'set-cookies',
    initialize
  };
  _exports.default = _default;
});