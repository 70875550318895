define("builder/app", ["exports", "builder/resolver", "ember-load-initializers", "builder/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      this.modulePrefix = _environment.default.modulePrefix;
      this.podModulePrefix = _environment.default.podModulePrefix;
      this.Resolver = _resolver.default;
      this.rootElement = '#builder-app';
      this.customEvents = {
        mouseover: 'mouseOver',
        mouseout: 'mouseOut'
      };
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix); // Uncomment next lines to remove deprecation messages from console

  Ember.Debug.registerDeprecationHandler((message, options, next) => {// next(message, options);
  });
});