define("builder/pods/components/form-field-manager/component", ["exports", "builder/mixins/event-base", "jquery"], function (_exports, _eventBase, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Since core-module kinda big and messy, form field logic moved here
   */
  var _default = Ember.Component.extend(_eventBase.default, {
    /**
     * @property {Object} formBuilder - form builder component
     */
    formBuilder: null,

    /**
     * @property {String} fieldSelector - selector of element, that should be decorated with controls
     */
    fieldSelector: '[data-field="true"], fieldset',

    /**
     * @property {Element} activeField - currently active field
     */
    activeField: null,

    /**
     * @property {String} activeFieldClass - class to add to active field
     */
    activeFieldClass: '__active',

    /**
     * @property {String} configWindowSelector - field config window selector
     */
    configWindowSelector: '.page_module-config.form_field-config',

    /**
     * @property {String} fieldActionName - name of controller action to invoke
     */
    fieldActionName: 'parentActions',

    /**
     * @property {Object} fieldActions - list of available controller actions
     * @property {String} openConfig - open config action name
     * @property {String} closeConfig - close config action name
     */
    fieldActions: {
      openConfig: 'openConfig',
      closeConfig: 'closeConfig'
    },

    /**
     * @property {Object} fieldAction - interface to send actions to page/edit controller
     */
    fieldAction: null,

    /**
     * @property {Object} events - list of supported events
     */
    events: null,

    /**
     * @property {Element[]} fields - references to fields
     */
    fields: null,

    /**
     * @property {Element[]} fieldControls - references to field controls
     */
    fieldControls: null,

    /**
     * @property {String} itemHoverClass - class to add on mouseenter and remove on mouseleave
     */
    itemHoverClass: '__hover',

    /**
     * @property {String} itemRemove - item name for form-decorator
     */
    itemRemove: 'remove',

    /**
     * @property {String} itemTypeAttribute - name of attribute where field type specified
     */
    itemTypeAttribute: 'data-type',

    /**
     * Component hook
     */
    init(...args) {
      this._super(...args);

      const me = this;
      me.set('clickHandler', function (event) {
        if (!me.isConfigVisible()) {
          me.activate(this);
        }

        switch (me.get('formBuilder').getControlType(event.target)) {
          case 'edit':
            {
              me.onEditItemClick(this, event);
              break;
            }

          case 'remove':
            {
              me.onRemoveItemClick(this, event);
              break;
            }
        }

        event.stopPropagation();
      });
      me.set('doubleClickHandler', function (event) {
        me.onEditItemClick(this, event);
        event.stopPropagation();
      });
      me.set('documentClickHandler', function () {
        const activeField = me.get('activeField');

        if (activeField) {
          me.makeInactive(activeField);
        }
      });
      me.set('mouseenterHandler', function () {
        me.hover(this);
      });
      me.set('mouseleaveHandler', function () {
        me.unhover(this);
      });
      me.set('events', {
        fieldClick: null,
        fieldDoubleClick: null,
        fieldEditClick: null,
        fieldRemoveClick: null
      });
    },

    /**
     * Initializer
     *
     * @param {Element} element - element where form fields are lie
     */
    setup(element) {
      this.attachEvents((0, _jquery.default)(element).find(this.fieldSelector));
    },

    /**
     * On edit item click this handler will be executed
     *
     * @param {Element} field - field that associated with clicked edit icon
     * @param {Event} event - click event
     */
    onEditItemClick(field) {
      this[this.fieldActionName]({
        action: this.get('fieldActions.openConfig')
      });
      this.activate(field);
    },

    /**
     * Remove icon click handler
     *
     * @param {Element} field - field parent of remove icon
     * @param {Event} event - original click event
     */
    onRemoveItemClick(field, event) {
      this.dispatchEvent('fieldRemoveClick', field, event); // Close field config

      this[this.fieldActionName]({
        action: this.get('fieldActions.closeConfig')
      });
    },

    /**
     * When user makes form inactive edit mode should be switched off
     */
    stopEdit() {
      this.makeInactive();
      this[this.fieldActionName]({
        action: this.get('fieldActions.closeConfig')
      });
    },

    /**
     * Horrible workaround, but for now it's the least painful way to detect is form field window is visible or not
     *
     * @returns {*}
     */
    isConfigVisible() {
      const configWindow = (0, _jquery.default)(document).find(this.configWindowSelector).first(); // Since we are not sure, is it will be found, double check needed

      if (!configWindow || configWindow.length === 0) {
        return false;
      }

      return configWindow.is(':visible');
    },

    /**
     * Attach required events to each field element
     *
     * @param {Element[]} fields - fields where listeners will be added
     */
    attachEvents(fields) {
      for (let i = 0; i < fields.length; i++) {
        this.attachFieldEvent(fields[i]);
      }
    },

    /**
     * Attach event listeners to a single field
     *
     * @param {Element} field
     */
    attachFieldEvent(field) {
      // We cannot rely on browser :hover, https://bugs.chromium.org/p/chromium/issues/detail?id=410328
      // That's why here, we must operate with classes
      field.addEventListener('mouseenter', this.mouseenterHandler);
      field.addEventListener('mouseleave', this.mouseleaveHandler);
      field.addEventListener('click', this.clickHandler);
      field.addEventListener('dblclick', this.doubleClickHandler);
    },

    /**
     * Make field active
     *
     * @param {Element} field - field to make active
     */
    activate(field) {
      const {
        activeField
      } = this; // Inactivate previous active field

      if (activeField && activeField !== field) {
        this.makeInactive(activeField);
      }

      this.makeActive(field);
    },

    /**
     * Make field active
     *
     * @param {Element} field - field to make active
     */
    makeActive(field) {
      field.classList.add(this.activeFieldClass);
      this.set('activeField', field);
    },

    /**
     * Make field inactive
     *
     * @param {Element} fieldToChange - field to make inactive
     */
    makeInactive(fieldToChange = null) {
      const field = fieldToChange || this.activeField;

      if (field) {
        field.classList.remove(this.activeFieldClass);
        this.set('activeField', null);
      }
    },

    /**
     * Add hover class to an element
     *
     * @param {Element} element
     */
    hover(element) {
      if (element) {
        element.classList.add(this.itemHoverClass);
      }
    },

    /**
     * Remove hover class from an element
     *
     * @param {Element} element
     */
    unhover(element) {
      if (element) {
        element.classList.remove(this.itemHoverClass);
      }
    },

    /**
     * Component hook
     */
    willDestroy() {
      this.makeInactive(this.activeField);
    }

  });

  _exports.default = _default;
});