define("builder/pods/components/widget-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZdrIz1ZT",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"widget-footer__wrapper\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\",\"originObject\",\"children\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"component\",[[23,1,[\"originObject\",\"type\"]]],[[\"data\",\"setChange\",\"createStructureCheckpoint\",\"iframeCSS\",\"isAllModulesLoaded\",\"parentActions\",\"toggleOverlay\",\"onFirstRender\",\"toggleDraggable\",\"moduleAction\",\"updateAllDimensions\",\"isLocked\",\"isBootstrapLoad\",\"openFieldLinkPopup\",\"globalStylesModel\"],[[23,1,[]],[24,[\"setChange\"]],[24,[\"createStructureCheckpoint\"]],[24,[\"iframeCSS\"]],[24,[\"isAllModulesLoaded\"]],[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"parentActions\"]]],null]],null],[24,[\"toggleOverlay\"]],[24,[\"onFirstRender\"]],[24,[\"toggleDraggable\"]],[24,[\"moduleAction\"]],[24,[\"updateAllDimensions\"]],[24,[\"isLocked\"]],[24,[\"isBootstrapLoad\"]],[24,[\"openFieldLinkPopup\"]],[24,[\"globalStylesModel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container js-empty-container\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"row \",[22,\"gridMainRowClass\"],\" js-empty-row __layout-height\"]]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page_edit_canvas_footer-line\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"page_edit_canvas_footer-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"content-blocker\",null,[[\"isShow\",\"unlockLayout\"],[[24,[\"isLocked\"]],[28,\"action\",[[23,0,[]],\"unlockLayout\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-footer/template.hbs"
    }
  });

  _exports.default = _default;
});