define("builder/core/structure-copy", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._copy = _copy;
  // TODO: ok, this is shitty way of copying, what I was thinking, need to make "normal" copy here
  // write by hand, which properties to copy and how

  /**
   * Black list of properties, that shouldn't be copied
   */
  const blackList = ['component', 'parent', 'dependencyModules'];
  /**
   * Copies passed object
   *
   * @param {*} obj - object to be copied
   * @returns {Object}
   *
   * TODO: rename this function to smth more usefull
   */

  function _copy(obj) {
    const copyObject = {};
    Object.keys(obj).forEach(key => {
      const item = obj[key];
      const isBlacklistedItem = blackList.find(prop => prop === key);

      if (isBlacklistedItem) {
        copyObject[key] = obj[key];
        return;
      }

      if (typeof item !== 'object' || item === null) {
        // Can't copy primitives
        copyObject[key] = item;
      } else if (typeof item === 'object') {
        copyObject[key] = (0, _emberCopy.copy)(item, true);
      }
    });
    return Ember.Object.extend(_emberCopy.Copyable, {
      copy() {
        return _copy(this);
      }

    }).create(copyObject);
  }
});