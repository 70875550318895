define("builder/serializers/website-setting", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    normalize(model, props, hash) {
      const normalizeProps = {};
      Object.keys(props).forEach(prop => {
        normalizeProps[this._convertDealerPropToPascalCase(prop)] = props[prop];
      });
      return this._super(model, normalizeProps, hash);
    },

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const serializePropsJson = {};

      const json = this._super(...args);

      Object.keys(json).forEach(prop => {
        serializePropsJson[this._convertDealerPropToSnakeCase(prop)] = json[prop];
      });
      return serializePropsJson;
    },

    /**
     * Convert not standard api property to correct model name format
     * @method _convertDealerPropToPascalCase
     * @param {String} item - property name for convert
     * @return {String} converted name
     * @private
     */
    _convertDealerPropToPascalCase(item) {
      if (/^dealer/.test(item)) {
        return item[0].toUpperCase() + item.slice(1).toLowerCase().replace(/(_[a-z])/g, function ($1) {
          return $1.slice(1).toUpperCase();
        });
      }

      return item;
    },

    /**
     * Convert model property to not standard api name
     * @method _convertDealerPropToSnakeCase
     * @param {String} item - property name for convert
     * @return {String} converted name
     * @private
     */
    _convertDealerPropToSnakeCase(item) {
      if (/^Dealer/.test(item)) {
        return item[0].toLowerCase() + item.slice(1).replace(/([A-Z])/g, function ($1) {
          return `_${$1.toLowerCase()}`;
        });
      }

      return item;
    }

  });

  _exports.default = _default;
});