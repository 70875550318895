define("builder/pods/components/tabs-manager/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Components which help to create perfect tabs :)
   *
   * usage:
   * {{#tabs-manager active="tab1" as |manager|}}
  			<div class="tabs__navigation tabs__inner">
  			 {{#tab-button manager=manager name="tab1"}}button1{{/tab-button}}
  			 {{#tab-button manager=manager name="tab2"}}button2{{/tab-button}
  			</div>
  
  			{{#tab-content manager=manager name="tab1"}}
  				Tab1 content
  			{{/tab-content}}
  
  			{{#tab-content manager=manager name="tab2"}}
  					Tab2 content
  			{{/tab-content}}
  	{{/tabs-manager}}
   *
   * You can register any "tab-button" and "tab-content" components.
   * And you have full control to what place in "tab-content" component
   *
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: 'tabs',

    /**
     * Property contain name of active tab
     *
     * @property {String|null} active
     */
    active: null,

    /**
     * @property {Array|null} tabs - Tabs store
     */
    tabs: null,

    /**
     * Property contain ref on DOM element
     * which show indication which tab is active
     *
     * @property {Element|null}
     * @private
     */
    _activeIndicator: null,

    /**
     * @property {Boolean} _isRendered - Flag to prevent run didRender on first component render
     * @private
     */
    _isRendered: false,

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args);

      this.tabs = [];
    },

    /**
     * @inheritDoc
     */
    willDestroyElement() {
      this.set('tabs', []);
      this.set('active', null);
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.createIndicator();
      this.set('active', this.tabs[0]);
    },

    /**
     * @inheritDoc
     */
    didRender() {
      if (this._isRendered && this.$().find('.tabs__title_active').length) {
        this._animateIndicator(this.$().find('.tabs__title_active'));
      }

      this._isRendered = true;
    },

    /**
     * Method create "line" indicator for tabs
     *
     * @method _createIndicator
     */
    createIndicator() {
      const indicator = (0, _jquery.default)('<div class="tabs__indicator"></div>');
      this.$().find('.tabs__navigation').append(indicator);
      this.set('_activeIndicator', indicator);
    },

    /**
     * Method animate "line" indicator
     *
     * @param {Object} $activeTab - jQuery DOM element representation
     * @private
     */
    _animateIndicator($activeTab) {
      this._activeIndicator.css({
        width: `${$activeTab.get(0).offsetWidth}px`,
        '-webkit-transform': `translate(${$activeTab.position().left}px, ${$activeTab.position().top}px)`,
        transform: `translate(${$activeTab.position().left}px, ${$activeTab.position().top}px)`
      });
    },

    /**
     * Adds tab to tabs store
     * @param {String} name - Tab name
     */
    addTab(name) {
      this.tabs.push(name);
    },

    /**
     * Removes tab from tabs store
     * @param {String} name - Tab name
     */
    removeTab(name) {
      const index = this.tabs.indexOf(name);
      this.tabs.slice(index, 1);
      this.checkActiveTab(name);
    },

    /**
     * Checks if removed tab was active then activate first one
     * @param {String} name - Tab name
     */
    checkActiveTab(name) {
      if (this.active === name) {
        this.set('active', this.tabs[0]);
      }
    },

    actions: {
      /**
       * Action to move indicator to active tab
       *
       * @param {Element} element - DOM element
       */
      moveIndicator(element) {
        this._animateIndicator((0, _jquery.default)(element));
      },

      /**
       * Action set passed tab as active
       *
       * @param {String} name - name of tab which will be active
       */
      select(name) {
        this.set('active', name);

        if (typeof this.actionHandler === 'function') {
          this.actionHandler(name);
        }
      }

    }
  });

  _exports.default = _default;
});