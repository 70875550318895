define("builder/pods/components/website-menu-item/component", ["exports", "builder/models/menu-child"], function (_exports, _menuChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Menu item component used in field-link to display internal pages
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['website-menu_list_item'],

    /**
     * @property {ConfigurationService} configurationService - configuration service
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @inheritdoc
     */
    classNameBindings: ['folder::__page', 'visible::__hidden', 'selected:__selected'],

    /**
     * @property {MenuChildModel} item - menu child model to display
     */
    item: null,

    /**
     * @property {null|Number} menuItemId - id of selected menu item(for internal link)
     */
    menuItemId: null,

    /**
     * @property {Boolean} condition - menuIsOpened passed from field-link
     */
    condition: false,

    /**
     * @property {String|null} searchQuery - search string
     */
    searchQuery: null,

    /**
     * @property {Function} action - function to be invoked on component click
     */
    action: null,

    /**
     * @property {Boolean} selected - is current component selected in field-link(menuItemId === this component item.itemId)
     */
    selected: Ember.computed('menuItemId', function () {
      const {
        menuItemId
      } = this;
      return menuItemId && menuItemId === this.get('item.itemId');
    }),

    /**
     * @property {Boolean} folder - is current item type is folder
     */
    folder: Ember.computed('item.type', function () {
      return this.get('item.type') === _menuChild.default.FOLDER;
    }),

    /**
     * @property {String} icon - icon for current page
     * @readonly
     */
    icon: Ember.computed('item', 'item.isHidden', function () {
      const isHidden = this.get('item.isHidden');
      const type = this.get('item.type');

      if (this.get('item.config.isHomepage')) {
        return 'icon-home';
      }

      if (type === _menuChild.default.LINK) {
        return isHidden ? 'icon-hidden-link' : 'icon-link';
      }

      if (type === _menuChild.default.PAGE) {
        if (this.get('item.config.isPopupPage')) {
          return isHidden ? 'icon-popup-page-hidden' : 'icon-popup-page';
        }

        return isHidden ? 'icon-hidden-page' : 'icon-page';
      }

      return '';
    }).readOnly(),

    /**
     * @property {Boolean} expanded - applicable for folder types, is current folder expanded
     */
    expanded: Ember.computed('menuItemId', function () {
      if (!this.folder) {
        const {
          menuItemId
        } = this;
        return menuItemId && menuItemId === this.get('item.menuItemId');
      }

      return this.searchSelected(this.get('item.children'));
    }),
    showBottomBorder: Ember.computed('expanded', function () {
      return this.folder ? this.expanded : null;
    }),
    visible: true,

    /**
     * @property {null|Function} onSelectMenuItem - callback to be invoked when menu item selected
     */
    onSelectMenuItem: null,

    /**
     * Previous search query
     * @type {string}
     */
    _oldSearchQuery: '',

    init(...args) {
      this._super(...args);

      this.set('_oldCondition', this.condition);
      this.set('_oldSearchQuery', this.searchQuery);
    },

    didUpdateAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      const {
        children,
        item
      } = this;
      const newCondition = this.condition;
      const newSearchQuery = this.searchQuery;

      if (newSearchQuery !== this._oldSearchQuery) {
        let visible;

        if (this.searchQuery) {
          const query = this.searchQuery.toLowerCase().split(/,\s?/);

          if (!this.folder) {
            for (let i = 0; i < query.length; i++) {
              if (item.title.toLowerCase().indexOf(query[i]) !== -1) {
                visible = true;
                break;
              }
            }
          } else if (children) {
            visible = this.search(children, query);
          }
        } else {
          visible = true;
        }

        this.set('visible', visible);
      }

      this.set('_oldCondition', newCondition);
      this.set('_oldSearchQuery', newSearchQuery);
    },

    /**
     * Search menu item by current item itemId
     *
     * @param {MenuChildModel[]} items - menu items where to search
     * @returns {Boolean}
     */
    searchSelected(items = []) {
      let selected = false;
      const {
        menuItemId
      } = this;
      items.forEach(itemObj => {
        if (itemObj.type !== _menuChild.default.FOLDER) {
          if (menuItemId && menuItemId === itemObj.itemId) {
            selected = true;
          }
        } else if (itemObj.children) {
          selected = this.searchSelected(itemObj.children);
        }
      });
      return selected;
    },

    search(items, query) {
      let visible = false;
      let c = 0;
      const len = items.get('length');
      let item;

      for (; c < len; c++) {
        item = items.objectAt(c);

        if (item.get('type') !== _menuChild.default.FOLDER) {
          for (let i = 0; i < query.length; i++) {
            if (item.get('title').toLowerCase().indexOf(query[i]) !== -1) {
              visible = true;
              break;
            }
          }
        } else if (item.get('children.length')) {
          visible = this.search(item.get('children'), query);
        }
      }

      return visible;
    },

    actions: {
      openOrSelect(item) {
        const {
          onSelectMenuItem
        } = this;

        if (item.get('children.length')) {
          this.toggleProperty('expanded');
        } else if (!this.folder) {
          if (onSelectMenuItem) {
            onSelectMenuItem(item);
          }
        }
      },

      transition(id) {
        if (typeof this.action === 'function') {
          this.action(id);
        }
      }

    }
  });

  _exports.default = _default;
});