define("builder/core/form/default-essential-fields.config", ["exports", "builder/core/enumerators/form", "builder/core/enumerators/buttons", "builder/core/enumerators/container", "builder/core/enumerators/form-fields"], function (_exports, _form, _buttons, _container, _formFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultEssentialFieldsMap = _exports.DEFAULT_GROUP_KEY = void 0;
  const DEFAULT_GROUP_KEY = 'default';
  _exports.DEFAULT_GROUP_KEY = DEFAULT_GROUP_KEY;
  const defaultEssentialFieldsMap = {
    types: ['form', _formFields.default.EMAIL, _form.MULTISTEP, _form.STEP, _container.default.WIDGET_ROW, _container.default.WIDGET_COLUMN, _buttons.FORM_SUBMIT, _buttons.FORM_NEXT, _buttons.FORM_PREV],
    names: []
  };
  _exports.defaultEssentialFieldsMap = defaultEssentialFieldsMap;
});