define("builder/pods/components/widget-navbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YUzGDvxG",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"nav\",true],[10,\"role\",\"navigation\"],[8],[0,\"\\n\\t\"],[7,\"ul\",true],[11,\"class\",[29,[\"pg-aside__menu main-nav \",[28,\"if\",[[24,[\"navbarStatus\"]],\"__mini\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"menu\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"widget-menu-item\",null,[[\"item\",\"sidebarIsClosed\"],[[23,1,[]],[24,[\"sidebarIsClosed\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-navbar/template.hbs"
    }
  });

  _exports.default = _default;
});