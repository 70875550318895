define("builder/pods/components/color-picker-palette/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['b2-color-picker-palette'],

    /**
     * @property {[Object[]]} -  Array of colors
     * @property {String} Object[].value - color
     * @property {String} [Object[].label] - uses to show title
     */
    palette: null,
    actions: {
      /**
       * Action to choose color
       *
       * @param {Object} color - chosen color
       */
      chooseColor(color) {
        this.onChange(color);
      }

    }
  });

  _exports.default = _default;
});