define("builder/core/page-structure-model", ["exports", "builder/core/structure-copy", "ember-copy"], function (_exports, _structureCopy, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Page structure model
   *
   * @class PageStructureModel
   */
  var _default = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Implementation of copy method(Copyable)
     *
     * @returns {Object}
     */
    copy() {
      return (0, _structureCopy._copy)(this);
    },

    /**
     * External private _each method entry point
     *
     * @param {Function} fn - to be invoked on each item
     */
    each(fn) {
      this._each(this.get('originObject.children'), fn);
    },

    /**
     * Loop through each leaf and apply passed in function on each item
     *
     * @param {Ember.Object[]} items - array of items to be processed by fn
     * @param {Function} fn - function to be invoked on each item
     */
    _each(items, fn) {
      for (let i = 0, len = items.length; i < len; i++) {
        fn(items[i]);

        if (items[i].get('originObject.children.length')) {
          this._each(items[i].get('originObject.children'), fn);
        }
      }
    }

  });

  _exports.default = _default;
});