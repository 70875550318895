define("builder/services/protected-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Any service that implements access-control should inherit from this class and use inject EmberCan service
   * for access check
   *
   * @class ProtectedService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {EmberCanService} accessControl - EmberCan service, used for access control
     */
    accessControl: Ember.inject.service('can')
  });

  _exports.default = _default;
});