define("builder/core/styles-management/constants/default-measurement-digits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_DIGITS = _exports.MULTIPLIER_DIGITS = _exports.EM_DIGITS = _exports.PX_DIGITS = void 0;

  /**
   * Fraction digits for px units
   * @type {number}
   */
  const PX_DIGITS = 0;
  /**
   * Fraction digits for em units
   * @type {number}
   */

  _exports.PX_DIGITS = PX_DIGITS;
  const EM_DIGITS = 1;
  /**
   * Fraction digits for multiplier units like line-height
   * @type {number}
   */

  _exports.EM_DIGITS = EM_DIGITS;
  const MULTIPLIER_DIGITS = 1;
  /**
   * Fraction digits by default
   * @type {number}
   */

  _exports.MULTIPLIER_DIGITS = MULTIPLIER_DIGITS;
  const DEFAULT_DIGITS = 0;
  _exports.DEFAULT_DIGITS = DEFAULT_DIGITS;
});