define("builder/services/css-custom-properties", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CssCustomPropertiesService = (_dec = Ember.inject.service('device'), (_class = (_temp = class CssCustomPropertiesService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "deviceService", _descriptor, this);
    }

    /**
     *
     * @param {StyleVariables} styleVariables - variables
     * @returns {import('ember__template/-private/handlebars').SafeString | string} - inlined styles
     */
    mapInlineStyles(styleVariables) {
      var _this$deviceService$g;

      const mode = (_this$deviceService$g = this.deviceService.getDevice()) === null || _this$deviceService$g === void 0 ? void 0 : _this$deviceService$g.breakpoint;
      const globalStyles = inlineCssProperties(styleVariables.global);

      if (!mode) {
        return globalStyles.length ? Ember.String.htmlSafe(globalStyles.join(';')) : Ember.String.htmlSafe(null);
      }

      const cssArray = [...globalStyles, ...inlineCssProperties(styleVariables[mode])];
      return cssArray.length ? Ember.String.htmlSafe(cssArray.join(';')) : Ember.String.htmlSafe(null);
    }
    /**
     * Get styles string for the website/module using Style map data
     * @param {String} moduleId  - module ID of current module if input is a module stylemap
     * @param {Object} styleMap - style map data
     * @param {String} selectorPrefix - prefix to add to the selectors in creating the style string
     * @returns {import('ember__template/-private/handlebars').SafeString | string} - css string for internal styles
     */


    generateStylesFromStyleMap(moduleId, styleMap, selectorPrefix) {
      if (!styleMap) {
        return '';
      }

      const globalStyleMap = styleMap.reduce((acc, item) => acc.concat(item.stylesmap), []);
      let stylestring = '';
      globalStyleMap.forEach(rule => {
        if (rule.type === 'style') {
          stylestring = stylestring.concat(processStyleRule(rule, selectorPrefix, moduleId));
        }

        if (rule.type === 'media') {
          var _this$deviceService$g2;

          const currentDeviceType = (_this$deviceService$g2 = this.deviceService.getDevice()) === null || _this$deviceService$g2 === void 0 ? void 0 : _this$deviceService$g2.breakpoint;

          if (currentDeviceType === getDeviceTypeFromConditions(rule.conditions, this.deviceService)) {
            stylestring = stylestring.concat(`${processMediaRule(rule, selectorPrefix, moduleId)}`);
          }
        }
      });
      return Ember.String.htmlSafe(stylestring);
    }
    /**
     * Get CSS Custom properties created from variable data from style data and style map variables
     * @param {Object} styleData - css data variables
     * @param {Array} styleMap - style map for css generation
     * @param {String} psuedoClass - wrapper class for the style
     * @returns {import('ember__template/-private/handlebars').SafeString | string} - css string for internal styles
     */


    generateStylesFromVariables(styleData, styleMap, psuedoClass = ':root, :host') {
      const nameValuePairs = [...getCustomPropertiesFromStyleData(styleData), ...getCustomPropertiesFromStyleMap(styleMap)];
      return Ember.String.htmlSafe(`${psuedoClass} { ${nameValuePairs.join('; ')} }`);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "deviceService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CssCustomPropertiesService;

  // Get CSS custom properties as array from data
  function getCustomPropertiesFromStyleData(styleData) {
    const nameValuePairs = [];

    if (!styleData || !styleData.get) {
      return nameValuePairs;
    }

    [_breakpointModes.GLOBAL, _breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
      styleData.get(mode).forEach(definition => {
        let name = definition.get('name');
        let value = definition.get('value');

        if (name === 'background-image') {
          value = getBackgroundImageCSS(styleData.get(mode));
        }

        name = mode === _breakpointModes.GLOBAL ? name : `${name}-${mode}`;
        nameValuePairs.push(getCustomProperty(name, value));
      });
    });
    return nameValuePairs;
  } // Get CSS custom properties as array from style map


  function getCustomPropertiesFromStyleMap(styleMap) {
    const nameValuePairs = [];

    if (styleMap) {
      const globalStyleMap = styleMap.reduce((acc, item) => acc.concat(item.stylesmap), []);
      globalStyleMap.forEach(rule => {
        if (rule.type === 'variable') {
          const {
            name,
            value
          } = rule;
          nameValuePairs.push(getCustomProperty(name, value));
        }
      });
    }

    return nameValuePairs;
  }
  /**
   * Maps style objects to strings
   * @param {StyleObject[]} cssProperties - css properties to map
   * @returns {string[]}
   */


  function inlineCssProperties(cssProperties) {
    return cssProperties.map(style => {
      if (!style.name || !style.value) {
        return '';
      }

      if (Array.isArray(style.value)) {
        return `${style.name}: ${resolveVariableValue(style.value[0])}`;
      }

      return `${style.name}: ${resolveVariableValue(style.value)}`;
    }).filter(x => !!x);
  } // Simplifies style rules in style map


  function processStyleRule(rule, prefixClass, moduleId) {
    const prefixedSelectors = rule.selector.split(',').map(selector => `${prefixClass} ${selector}`).join(', ');
    const moduleSelectors = prefixedSelectors.replaceAll('{moduleId}', moduleId);
    const inlineCss = inlineCssProperties(rule.properties);
    const joinedStyles = inlineCss.join('; ');
    return `${moduleSelectors} { ${joinedStyles} } `;
  } // Simplifies media rules in style map


  function processMediaRule(data, prefixedClass, moduleId) {
    let styles = '';
    data.children.forEach(element => {
      if (element.type === 'style') {
        const selector = `${prefixedClass} ${element.selector.replaceAll('{moduleId}', moduleId)}`;
        styles = styles.concat(`${selector} { ${inlineCssProperties(element.properties).join('; ')} }`);
      }
    });
    return `${styles}`;
  } // Get device type based on the max and min width conditions in media query


  function getDeviceTypeFromConditions(conditions, deviceService) {
    const condition = conditions && conditions[0];

    if (!condition) {
      return null;
    }

    const {
      type,
      rightOperand,
      leftOperand,
      name,
      value
    } = condition;

    if (type === 'operand') {
      return deviceService.getDeviceTypeFromWidth(name, value);
    }

    if (type === 'expression') {
      return deviceService.getDeviceTypeFromWidth(rightOperand.name, rightOperand.value) || deviceService.getDeviceTypeFromWidth(leftOperand.name, rightOperand.value);
    }

    return null;
  } // Returns combined style value parts as a single value string


  function getCombinedStyleValue(parts) {
    return parts.reduce((acc, part) => acc.concat(`${resolveVariableValue(part)} `), '');
  } // resolves style value to make it string


  function resolveVariableValue(styleValue) {
    if (typeof styleValue === 'string') {
      return convertVariableToCustomProperty(styleValue);
    }

    if (styleValue.parts) {
      return getCombinedStyleValue(styleValue.parts);
    }

    if (styleValue.type === 'function') {
      const argumentsArray = styleValue.arguments.map(value => resolveVariableValue(value));
      const joinedArgs = argumentsArray.join(', ');

      if (styleValue.name === 'url') {
        return joinedArgs;
      }

      return `${styleValue.name}(${joinedArgs})`;
    }

    return '';
  } // Converts $ variables to custom property references


  function convertVariableToCustomProperty(value) {
    return value && value.indexOf('$') > -1 ? `var(${value.replace('$', '--')})` : value;
  } // Returns a css custom property from name and value


  function getCustomProperty(name, value) {
    return `--${name}: ${convertVariableToCustomProperty(value)}`;
  } // CSS custom properties doesn't work with background-image url. So handling it explicitly


  function getBackgroundImageCSS(data) {
    var _window$appBootstrap$, _window$appBootstrap, _window$appBootstrap$2;

    if (!data) {
      return '';
    } // URL


    const cdn = (_window$appBootstrap$ = (_window$appBootstrap = window.appBootstrap) === null || _window$appBootstrap === void 0 ? void 0 : (_window$appBootstrap$2 = _window$appBootstrap.initData) === null || _window$appBootstrap$2 === void 0 ? void 0 : _window$appBootstrap$2.imageCdnBaseUrl) !== null && _window$appBootstrap$ !== void 0 ? _window$appBootstrap$ : '';
    const relativeUrl = data.find(item => item.name === 'background-image');
    const url = relativeUrl && relativeUrl.value !== 'none' ? `url('${cdn}${relativeUrl.value}')` : 'none'; // Linear Gradient

    const direction = data.find(item => item.name === 'background-gradient-direction') || '';
    const color1 = data.find(item => item.name === 'background-gradient-color1') || '';
    const color2 = data.find(item => item.name === 'background-gradient-color2') || '';
    const linearGradient = `linear-gradient(${direction.value}, ${color1.value}, ${color2.value})`;
    return `${url}, ${linearGradient}`;
  }
});