define("builder/pods/components/form-module/rule-set", ["exports", "builder/pods/components/form-module/form-drop-container", "builder/pods/components/form-module/column-drop-container", "builder/pods/components/form-module/row-drop-container", "builder/pods/components/form-module/fieldset-drop-container"], function (_exports, _formDropContainer, _columnDropContainer, _rowDropContainer, _fieldsetDropContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Kinda bridge maybe, the main goal of this component is to call correct drop-container rule set
   * for example when component dropped into "form", form rule set method dropAllowed, drop, etc. should be called,
   * when component dropped into "row", row rule set method dropAllowed, drop, etc. should be called
   */
  var _default = Ember.Component.extend({
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @property {Object} _module - drag-and-drop-module instance
     */
    module: null,

    /**
     * @property {Object} _rules - set of registered rules(for form, fieldset, row, column, etc)
     * @private
     */
    _rules: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_rules', {
        form: _formDropContainer.default.create({
          dragAndDropModule: this.module
        }),
        fieldset: _fieldsetDropContainer.default.create({
          dragAndDropModule: this.module
        }),
        row: _rowDropContainer.default.create(),
        column: _columnDropContainer.default.create()
      });
    },

    /**
     * Is drop allowed into given container
     *
     * @param {Object} container - drop-container instance, where we wnat to drop form-item instance
     * @param {Object} projectile - form-item instance, that is dropped/dragged
     * @param {Object} target - form-item instance, anchor, used to drop before or after this form-item
     * @param {HTMLElement} eventTarget - immediate event target element (event.target of dragover/drop events)
     * @param {Number} x - drag event x offset (event.offsetX)
     * @param {Number} y - drag event y offset (event.offsetY)
     * @returns {Boolean} - is it allowed to drop into "container" next to "target"
     */
    dropAllowed(container, projectile, target, eventTarget, x, y) {
      const rules = this._rules;
      const rule = rules[container.get('type')];
      const {
        position,
        distance
      } = this.getDropPosition(container, projectile, target, eventTarget, x, y);
      return rule.dropAllowed(container, projectile, target, position, distance);
    },

    /**
     * Detect potencial drop position
     *
     * @param {Object} container - drop-container instance, where we wnat to drop form-item instance
     * @param {Object} projectile - form-item instance, that is dropped/dragged
     * @param {Object} target - form-item instance, anchor, used to drop before or after this form-item
     * @param {HTMLElement} eventTarget - immediate event target element (event.target of dragover/drop events)
     * @param {Number} x - drag event x offset (event.offsetX)
     * @param {Number} y - drag event y offset (event.offsetY)
     * @returns {Object} - object like {position: <top/right/bottom/left>, distance: <number 0-50>}
     */
    getDropPosition(container, projectile, target, eventTarget, x, y) {
      const rules = this._rules;
      const rule = rules[container.get('type')];
      return rule.getDropPosition(container, projectile, target, eventTarget, x, y);
    },

    /**
     * Handle drop
     *
     * @param {Object} container - drop-container instance, where we wnat to drop form-item instance
     * @param {Object} projectile - form-item instance, that is dropped/dragged
     * @param {Object} target - form-item instance, anchor, used to drop before or after this form-item
     * @param {HTMLElement} eventTarget - immediate event target element (event.target of dragover/drop events)
     * @param {Number} x - drag event x offset (event.offsetX)
     * @param {Number} y - drag event y offset (event.offsetY)
     */
    onDrop(container, projectile, target, eventTarget, x, y) {
      const rules = this._rules;
      const rule = rules[container.get('type')];
      const {
        position,
        distance
      } = this.getDropPosition(container, projectile, target, eventTarget, x, y);

      if (rule.dropAllowed(container, projectile, target, position, distance)) {
        rule.drop(container, projectile, target, position);
      }
    },

    /**
     * Will be invoked when user click on remove button of field
     *
     * @param {Object} item - form-item instance to remove
     */
    onRemove(item) {
      const parent = item.get('parent');

      if (parent) {
        const rules = this._rules;
        const rule = rules[parent.get('type')];
        rule.removeChild(parent, item);
      }
    }

  });

  _exports.default = _default;
});