define("builder/services/scroller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * @property {Number} _tick - how often scroll function should be invoked per second
     */
    _tick: 16,

    /**
     * @property {Number} _tickStep - pixels to move scroll per scroll tick
     */
    _tickStep: 20,

    /**
     * @property {Number|null} - id of interval function
     */
    _intervalId: null,

    /**
     * @property {String|null} _direction - current scroll direction
     */
    _direction: null,

    /**
     * Scroll into given direction, bottom - will scroll bottom, all other values will be interpreted as top(except for
     * undefined, since we have default value)
     * @param {Object|Window} scrollObject - object to be scrolled
     * @param {String} direction - scroll direction
     * @param {Function|null} callback - callback to be invoked on each scroll
     */
    scroll(scrollObject, direction = 'bottom', callback = null) {
      let step = this._tickStep; // We are already scrolling in that direction, no need to relaunch

      if (this._direction === direction) {
        return;
      } // Reverse step, if scroll top invoked


      if (direction === 'top') {
        step = -step;
      }

      this._clearInterval();

      this.set('_intervalId', setInterval(() => {
        if (scrollObject === window) {
          scrollObject.scrollTo(0, scrollObject.scrollY + step);
        } else {
          scrollObject.scrollTop += step;
        }

        if (typeof callback === 'function') {
          callback();
        }
      }, this._tick));
      this.set('_direction', direction);
    },

    /**
     * Stop scrolling
     */
    stop() {
      const intervalFn = this._intervalId;

      if (intervalFn) {
        clearInterval(intervalFn);
        this.set('_intervalId', null);
        this.set('_direction', null);
      }
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      this.stop();
    },

    /**
     * Clear interval
     *
     * @private
     */
    _clearInterval() {
      const intervalId = this._intervalId;

      if (intervalId) {
        clearInterval(intervalId);
      }
    }

  });

  _exports.default = _default;
});