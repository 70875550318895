define("builder/pods/components/code-popup/component", ["exports", "codemirror", "codemirror/mode/htmlmixed/htmlmixed", "codemirror/mode/css/css"], function (_exports, _codemirror, _htmlmixed, _css) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CodeEditor = (_dec = Ember._action, _dec2 = Ember._action, (_class = (_temp = class CodeEditor extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.snippetText = '';
      this.mode = 'htmlmixed';
      this.editor = null;

      this.onCodeSave = value => {
        throw new Ember.Error('Method onCodeSave should be provided');
      };

      this.onEditCanceled = () => {
        throw new Ember.Error('Method onEditCanceled should be provided');
      };
    }

    /**
     * @inheritdoc
     * @param {any[]} args
     */
    didRender(...args) {
      this._super(...args);

      if (this.editor) {
        this.editor.setOption('mode', this.mode);
        this.editor.setOption('value', this.snippetText);
        return;
      }

      const editorElement = this.element.querySelector('code'); // eslint-disable-next-line new-cap

      this.editor = (0, _codemirror.default)(editorElement, {
        value: this.snippetText,
        mode: this.mode,
        lineNumbers: true,
        autocorrect: true
      });
    }
    /**
     * Action for save button click
     * @returns {void}
     */


    onSaveClick() {
      this.onCodeSave(this.editor.getValue());
    }
    /**
     * Action for cancel button click
     */


    onCancelClick() {
      this.onEditCanceled();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "onSaveClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onSaveClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancelClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelClick"), _class.prototype)), _class));
  _exports.default = CodeEditor;
});