define("builder/serializers/menu-child", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    attrs: {
      itemId: 'id'
    },

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args);
      /**
       * We need to delete whole link property if no value provided
       * This behavior is by design and very questionable.
       * Validation will fail and whole folder will delete if we have not deleted this property.
       */


      const linkValueIsEmpty = json.link && !json.link.value;

      if (json.link === null || linkValueIsEmpty) {
        delete json.link;
      }

      return json;
    }

  });

  _exports.default = _default;
});