define("builder/mixins/field-visibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FieldVisibility = void 0;

  /**
   * @deprecated
   * Mixins are in deprecation state in ember octane.
   * Use @type {FieldBaseComponent} instead for field components
   */
  const FieldVisibility = Ember.Mixin.create({
    /**
     * @property {Ember.observer} visibleObserver - detect field._visible change
     */
    visibleObserver: Ember.on('init', Ember.observer('field._visible', function () {
      const visible = typeof this.get('field._visible') !== 'boolean' || this.get('field._visible');
      this.set('isVisible', visible);

      if (!this.element) {
        return;
      } // Hide parent element that has class .bld-form_field
      // Actually for module/widgets configs popups


      const parent = this.element.closest('.bld-form_field');

      if (!parent) {
        return;
      }

      parent.style.display = visible ? 'block' : 'none';
    })),

    /**
     * @inheritdoc
     */
    didRender(...args) {
      this._super(...args);

      this.visibleObserver();
    }

  });
  _exports.FieldVisibility = FieldVisibility;
  var _default = FieldVisibility;
  _exports.default = _default;
});