define("builder/utils/geometry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class GeometryUtil
   */
  var _default = Ember.Object.create({
    /**
     * Translates segment on coordinate axis
     *
     * @param {Number} limit - axis limit
     * @param {Number} segmentLength - length of a segment to be translated on axis
     * @param {Number} center - segment desireable center point
     * @returns {{left: Number, right: Number}|false} - if segment couldn't fit into axis, returns false, otherwise
     * left and right axis points
     */
    translateSegmentOnAxis(limit, segmentLength, center) {
      if (limit < segmentLength) {
        return false;
      }

      let left = center - segmentLength / 2;
      let right = center + segmentLength / 2;

      if (left < 0) {
        right += left * -1;
        left = 0;
      }

      if (right > limit) {
        left -= right - limit;
        right = limit;
      }

      return {
        left: isNaN(parseInt(left, 10)) ? 0 : left,
        right: isNaN(parseInt(right, 10)) ? 0 : right
      };
    }

  });

  _exports.default = _default;
});