define("builder/initializers/misc", ["exports", "builder/core/module-config-handlers/configuration", "builder/core/module-config-handlers/form-configuration", "builder/core/module-config-handlers/handler", "builder/core/configuration-builder/configuration-builder", "builder/core/module-dependency-manager", "builder/pods/website/edit/page/edit/widget-settings"], function (_exports, _configuration, _formConfiguration, _handler, _configurationBuilder, _moduleDependencyManager, _widgetSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('module-configuration-handler:configuration', _configuration.default);
    application.register('module-configuration-handler:form-configuration', _formConfiguration.default);
    application.register('module-configuration-handler:handler', _handler.default);
    application.register('module-configuration-builder:builder', _configurationBuilder.default);
    application.register('module:dependency-manager', _moduleDependencyManager.ModuleDependencyManager, {
      singleton: false
    });
    application.register('controller:widget-settings', _widgetSettings.default, {
      singleton: false
    });
    application.inject('controller:website.edit.page.edit.module.settings', 'configurationHandler', 'module-configuration-handler:handler');
    application.inject('controller:website.edit.page.edit.module-gallery.settings', 'configurationHandler', 'module-configuration-handler:handler');
    application.inject('route:website.edit.page.edit.module.settings', 'configurationBuilder', 'module-configuration-builder:builder');
    application.inject('route:website.edit.page.edit.module-gallery.settings', 'configurationBuilder', 'module-configuration-builder:builder');
    application.inject('service:modules-store', 'dependencyManager', 'module:dependency-manager');
  }

  var _default = {
    name: 'misc',
    initialize
  };
  _exports.default = _default;
});