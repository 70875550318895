define("builder/pods/website/edit/page/edit/module/settings/route", ["exports", "builder/routes/application", "builder/core/enumerators/module", "ember-copy", "builder/core/enumerators/container", "builder/models/website-layout", "builder/models/module-style", "builder/core/form-leadrouting-tab-config", "builder/config/environment", "builder/core/enumerators/form-fields"], function (_exports, _application, _module, _emberCopy, _container, _websiteLayout, _moduleStyle, _formLeadroutingTabConfig, _environment, _formFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @property {Ember.Service} modules - modules store service
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * @property {Ember.Service} structureService - Structure service
     */
    structureService: Ember.inject.service('structure'),

    /**
     * @property {Ember.Service} formService - form service
     */
    formService: Ember.inject.service('form'),

    model(params) {
      // NOTE: with modelFor method we are creating strong dependency on parent route model.
      // TODO: move layout filtering model to separate object (service maybe)
      const {
        layout,
        page
      } = this.modelFor('website.edit.page.edit');

      if (!this.canAccessToModuleSettings(page)) {
        return this.transitionTo('website.edit.page.edit');
      }

      return Ember.RSVP.hash({
        // Property params.module_id comes from system-modules
        moduleConfig: this._getModuleConfig(params.module_id),
        websiteSettings: this._getWebsiteSettings(),
        layout
      });
    },

    afterModel(model, transition) {
      const systemModules = this.store.peekAll('system-module');
      let systemModule = null;

      if (model && model.moduleConfig && model.moduleConfig.id) {
        systemModule = systemModules.findBy('ModuleConfigurationId', parseInt(model.moduleConfig.id, 10));
      }

      if (systemModule && !systemModule.IsActive) {
        this.transitionTo('website.edit.page.edit');
        transition.abort();
      }

      const moduleId = model.moduleConfig.id;
      const module = this.modules.findById(moduleId);
      const themeConfig = this.store.peekAll('module-theme').findBy('id', model.moduleConfig.get('ThemeId').toString());
      const {
        configurationBuilder
      } = this;
      const moduleStrings = model.moduleConfig.get('Strings');
      const styleInterface = themeConfig.get('StyleInterface');
      const styleMap = themeConfig.get('StyleMap');
      const {
        formId
      } = model.moduleConfig.Structure;
      let moduleStyleRecord = this.store.peekRecord('module-style', moduleId);
      let moduleStylesData = null;
      let formRecord = Ember.RSVP.resolve(null);

      if (!moduleStyleRecord && module) {
        const {
          hasCSSCustomProperties
        } = themeConfig; // Since style map could be null|{}|[]

        const isStyleMapValid = styleMap && (Ember.isArray(styleMap) ? styleMap.length : true);

        if (hasCSSCustomProperties || isStyleMapValid) {
          const record = (0, _moduleStyle.preparedRecord)(moduleId, themeConfig, this.modelFor('website.edit').styles);
          moduleStyleRecord = this.store.createRecord('module-style', record);
          module.set('moduleStyleModel', moduleStyleRecord);
        }
      }

      const editTextFields = configurationBuilder.buildI18NConfiguration(themeConfig, moduleStrings, model.websiteSettings.Language || _environment.default.APP.DEFAULT_LOCALE);

      if (formId) {
        formRecord = this.formService.getForm(formId, true);
      }

      return formRecord.then(record => {
        const formStructure = this.formService.getTempStructure(formId);
        const {
          ThemeId
        } = model.moduleConfig;
        const isEncryptedField = this.store.peekRecord('module-theme', ThemeId).Structure.findBy('name', 'isEncrypted');
        const ssnIsFound = !!this.formService.findFieldByType(formStructure, _formFields.default.SOCIAL_SECURITY);

        if (isEncryptedField) {
          isEncryptedField.disabled = ssnIsFound;
        }

        if (moduleStyleRecord && styleInterface) {
          moduleStylesData = {
            themeConfig,
            variablesModel: moduleStyleRecord,
            fontsModel: this.modelFor('website.edit').websiteFonts,
            globalStyles: this.store.peekRecord('website-style', this.get('configurationService.website.id')),
            interface: styleInterface,
            map: styleMap
          };
        }

        model.formRecord = record;
        model.editTextFields = editTextFields;
        model.moduleStylesData = moduleStylesData;
        model.openedConfigName = this.store.peekAll('module').findBy('id', model.moduleConfig.get('ModuleId')).get('Label');
        return this.prepareFormConfig(model);
      });
    },

    /**
     * @inheritDoc
     */
    setupController(controller, model) {
      this._super(controller, model);

      const coreModule = this.structureService.findModuleById(model.moduleConfig.id);
      controller.set('activeModule', coreModule);
    },

    /**
     * Prepares form config
     * @param {Object} model - Model
     * @returns {Promise}
     */
    prepareFormConfig(model) {
      const coreModule = this.structureService.findModuleById(model.moduleConfig.id);
      const {
        structureService
      } = this;
      const breakpoint = this.get('configurationService.currentDevice.breakpoint');
      const {
        configurationBuilder
      } = this;
      const themeConfig = this.store.peekAll('module-theme').findBy('id', model.moduleConfig.get('ThemeId').toString());
      const moduleData = model.moduleConfig.get('Structure');
      const configForm = configurationBuilder.buildConfiguration((0, _emberCopy.copy)(themeConfig.get('Structure'), true), moduleData, coreModule, breakpoint);
      let isFormModule;
      let leadsRoutingOtherRecipients = null;
      let leadsRoutingField = null;
      let pagesContainForm = [];

      if (coreModule) {
        isFormModule = structureService.isType(coreModule, _module.default.MODULE_FORM);
        this.send('setActiveModule', coreModule);
      }

      return configForm.then(formConfig => {
        var _model$formRecord;

        // Module has no config, text and styleInterface, config window must be closed
        if (!formConfig.length && !model.editTextFields.length && !themeConfig.get('StyleInterface')) {
          return this.transitionToRoute('website.edit.page.edit');
        }

        if (isFormModule && model.formRecord) {
          pagesContainForm = this.getPagesContainForm(model.formRecord);
          const leadsRoutingFieldConfig = (0, _emberCopy.copy)(_formLeadroutingTabConfig.default, true);
          const recipients = model.formRecord.get('Leadroutes');
          const readOnlyRecipients = [];
          const recipientsForField = [];

          for (let i = 0; i < recipients.length; i++) {
            const recipient = recipients[i];

            if (recipient.Editable) {
              recipientsForField.push({
                'variant-name': 'recipient',
                Id: recipient.Id,
                ToEmail: recipient.ToEmail,
                EmailFormat: recipient.EmailFormat
              });
            } else {
              readOnlyRecipients.push(recipient.ToEmail);
            }
          }

          leadsRoutingFieldConfig.data = recipientsForField;
          leadsRoutingField = leadsRoutingFieldConfig;
          leadsRoutingOtherRecipients = readOnlyRecipients;
        }

        model.formFields = formConfig;
        model.configurationFlatMap = this.configurationService.getFieldsConfigFlatMap(formConfig);
        model.isFormModule = isFormModule;
        model.pagesContainForm = pagesContainForm;
        model.leadsRoutingField = leadsRoutingField;
        model.leadsRoutingOtherRecipients = leadsRoutingOtherRecipients;
        model.leadWebServices = (_model$formRecord = model.formRecord) === null || _model$formRecord === void 0 ? void 0 : _model$formRecord.Webservices;
        return model;
      });
    },

    /**
     * Returns pages where current form exists
     * @param {Object} form - "form" model record
     * @returns {Array}
     */
    getPagesContainForm(form) {
      const pages = [];
      form.get('UsedInPages').forEach(usedInPage => {
        const record = this.store.peekRecord('page', usedInPage.PageId);

        if (usedInPage.Type === 'layout' || usedInPage.Type === 'page' && record && !record.get('isDeleted')) {
          pages.push(this._getFormPageInfo(usedInPage.Type, record));
        }
      });
      return pages;
    },

    /**
     * Unlock layout if footer or header contains currently editable module
     * @returns {void}
     */
    unlockLayoutIfNeeded() {
      const {
        structureService,
        controller
      } = this;
      const {
        activeModule
      } = controller; // Unlock layout if footer or header contains this module

      if (this.modelFor('website.edit.page.edit').layout.IsLayoutLocked === _websiteLayout.default.LAYOUT_IS_LOCKED) {
        const moduleParent = structureService.findClosest(activeModule, _container.default.WIDGET_HEADER) || structureService.findClosest(activeModule, _container.default.WIDGET_FOOTER);

        if (moduleParent) {
          this.send('unlockLayout', moduleParent);
        }
      }
    },

    /**
     * Check access to route
     * @param { PageModel } page - page model
     * @returns { boolean }
     */
    canAccessToModuleSettings(page) {
      if (page.isChild) {
        return false;
      }

      if (page.isGlobal && !this.accessControl.can('manage-global page', page).isGranted) {
        return false;
      }

      return true;
    },

    /**
     * Get form page info (title and page model, if form exists on page)
     *
     * @method getFormPageInfo
     * @param {String} type - type of form existence(page or layout)
     * @param {PageModel|null} page - page model or null(if form is in layout)
     * @returns {Object} - object with keys "title" and "pageModel"
     * @private
     */
    _getFormPageInfo(type, page) {
      const info = {
        title: 'Header or Footer',
        pageModel: null
      };

      if (type === 'page') {
        info.title = page.get('Title');
        info.pageModel = page;
      }

      return info;
    },

    /**
     * Method wraps module config fetch workflow
     * We need to load cached config or get it from backend
     *
     * @param {Number} moduleId - module id for configuration to fetch
     * @returns {Object | Promise } - cached module config or promise for loading config from backend
     * @private
     */
    _getModuleConfig(moduleId) {
      const moduleEditParams = this.paramsFor('website.edit.page.edit.module');
      const id = moduleId || moduleEditParams.module_id;
      return this.store.peekRecord('page-module', id) || this.store.findRecord('page-module', id);
    },

    /**
     * Method wraps website config fetch workflow
     *
     * @returns {Object} - cached module config
     * @private
     */
    _getWebsiteSettings() {
      const websiteEditParams = this.paramsFor('website.edit') || {};
      return this.store.peekRecord('website-setting', websiteEditParams.website_id);
    },

    actions: {
      reloadModule() {
        this.send('reloadActiveModule');
      },

      setChanges(type) {
        this.send('setChangesInPage', type);
        this.unlockLayoutIfNeeded();
      },

      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});