define("builder/helpers/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHeight = getHeight;
  _exports.getWidth = getWidth;
  _exports.updateImageWidgetWidth = updateImageWidgetWidth;
  _exports.observeImageChanges = observeImageChanges;

  function getHeight(length, ratio = 0.88) {
    // eslint-disable-next-line no-restricted-properties
    const height = length / Math.sqrt(Math.pow(ratio, 2) + 1);
    return Math.round(height);
  }

  function getWidth(length, ratio = 0.88) {
    // eslint-disable-next-line no-restricted-properties
    const width = length / Math.sqrt(1 / (Math.pow(ratio, 2) + 1));
    return Math.round(width);
  }

  function updateImageWidgetWidth(widget, breakpoint, width, widgetData) {
    var _widget$styles;

    let w = width;
    const widthEntered = w.toString().endsWith('px') ? w.toString().replace('px', '') : w;

    if (parseInt(widthEntered, 10) > Ember.get(widgetData, 'maxWidth')) {
      w = Ember.get(widgetData, 'maxWidth');
    }

    w = w.toString().endsWith('px') ? w : `${w}px`;
    const styles = (_widget$styles = widget.styles) === null || _widget$styles === void 0 ? void 0 : _widget$styles.toJSON();
    const objIndex = styles[breakpoint].findIndex(item => item.name === 'width');

    if (objIndex > -1) {
      styles[breakpoint][objIndex].value = w;
    }

    widget.styles.set(breakpoint, styles[breakpoint]);
  }

  function observeImageChanges(widgetData, name, value, breakpoint, fields) {
    const widget = widgetData === null || widgetData === void 0 ? void 0 : widgetData.originObject;
    fields.map(field => {
      if (field.name === 'clientWidth') {
        Ember.set(field, 'max', Ember.get(widgetData, 'maxWidth'));
      }

      if (field.name === 'clientHeight') {
        Ember.set(field, 'max', getHeight(Ember.get(widgetData, 'maxWidth')));
      }

      if (name === 'clientHeight' && field.name === 'clientWidth') {
        const w = getWidth(parseInt(Ember.get(widgetData, 'clientHeight').toString().replace('px', ''), 10));
        Ember.set(field, 'data', w);
        Ember.set(widgetData, 'clientWidth', w);
        updateImageWidgetWidth(widget, breakpoint, w, widgetData);
      }

      if (name === 'clientWidth' && field.name === 'clientHeight') {
        const h = getHeight(parseInt(Ember.get(widgetData, 'clientWidth').toString().replace('px', ''), 10));
        Ember.set(field, 'data', h);
        Ember.set(field, 'max', getHeight(Ember.get(widgetData, 'maxWidth')));
        Ember.set(widgetData, 'clientHeight', h);
        updateImageWidgetWidth(widget, breakpoint, getWidth(h), widgetData);
      }

      if (name === 'reset' && field.name === 'clientWidth') {
        Ember.set(field, 'data', Ember.get(widgetData, 'previousWidth'));
        Ember.set(widgetData, 'clientWidth', Ember.get(widgetData, 'previousWidth'));
      }

      if (name === 'reset' && field.name === 'clientHeight') {
        Ember.set(field, 'data', getHeight(Ember.get(widgetData, 'previousWidth')));
        Ember.set(widgetData, 'clientHeight', getHeight(Ember.get(widgetData, 'previousWidth')));
        updateImageWidgetWidth(widget, breakpoint, getSavedWidth(widgetData), widgetData);
      }

      if (field.name === 'reset') {
        Ember.set(field, 'data', false);
      }

      return field;
    });
  }

  function getSavedWidth(widgetData) {
    let rWidth = Ember.get(widgetData, 'previousWidth');
    const rWidthEntered = rWidth.toString().endsWith('px') ? rWidth.toString().replace('px', '') : rWidth;

    if (parseInt(rWidthEntered, 10) > Ember.get(widgetData, 'maxWidth')) {
      rWidth = Ember.get(widgetData, 'maxWidth');
    }

    rWidth = rWidth.toString().endsWith('px') ? rWidth : `${rWidth}px`;
    return rWidth;
  }
});