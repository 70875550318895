define("builder/pods/components/module-gallery/component", ["exports", "builder/core/enumerators/container", "builder/core/enumerators/module", "builder/core/enumerators/widget", "ember-copy", "builder/core/enumerators/module-action", "builder/config/environment", "builder/core/enumerators/gallery"], function (_exports, _container, _module, _widget, _emberCopy, _moduleAction, _environment, _gallery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember Module Gallery component.
   *
   * TODO: Adding and removing of grid nodes, must be performed in controller
   * TODO: I'm pretty sure we can get rid of "notFound"/"expanded"/"showSearch", but no time for now
   */
  var _default = Ember.Component.extend({
    /**
     * class names to be added to component wrapper
     * @type {string[]}
     */
    classNames: ['page_modules_wrapper'],

    /**
     * conditional class names
     * @type {string[]}
     */
    classNameBindings: ['showSearch::__child'],

    /**
     * @type {GalleryDataContainer}
     */
    data: null,

    /**
     * action passed from controller, to be invoked when component added on page and
     * is ready to operate
     * @type {Function}
     */
    moduleAction: null,

    /**
     * type for sorting modules array
     * @type {string}
     */
    modulesSortType: 'alphabetically',

    /**
     * stack for storing gallery modules we travel
     * category -> module -> theme -> sub theme
     * @type {GalleryModule[]}
     */
    stack: null,

    /**
     * show search input
     * @type {boolean}
     */
    showSearch: Ember.computed('stack.[]', 'searchString', function () {
      return !this.stack.length || this.searchString.length;
    }),

    /**
     * flag to show not found text
     * @type {boolean}
     */
    notFound: false,

    /**
     * detects whether search is expanded
     * @type {boolean}
     */
    expanded: false,

    /**
     * @type {StoreService}
     */
    store: Ember.inject.service('store'),

    /**
     * @type {FormService}
     */
    form: Ember.inject.service('form'),

    /**
     * drag and drop structure service, since we must interact with
     * @type {Ember.Service}
     */
    dragAndDropService: Ember.inject.service('drag-and-drop'),

    /**
     * structure service, for handling image moving
     * @type {Ember.Service}
     */
    structureService: Ember.inject.service('structure'),

    /**
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * grid components builder
     * @type {Ember.Service}
     */
    moduleGalleryItemBuilder: Ember.inject.service('module-gallery-item-builder'),

    /**
     * module config service
     * @type {ModuleConfigurationService}
     */
    moduleConfigurationService: Ember.inject.service('module-configuration'),

    /**
     * preview service
     * @type {Ember.Service}
     */
    previewService: Ember.inject.service('preview'),

    /**
     * modules store
     * @type {ModulesStoreService}
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * list of gallery modules to be displayed
     * @type {GalleryModule[]}
     */
    display: null,

    /**
     * search start when query string is equal or greater than this number
     * @type {number}
     * @private
     */
    _searchQueryLength: 3,

    /**
     * value in search box
     * @type {string}
     */
    searchString: '',

    /**
     * modules collection where to search
     * @type {Object[]}
     */
    modulesToSearch: Ember.computed('data', function () {
      var _this$data$findBy, _this$data$findBy$chi, _this$data$findBy$chi2;

      const modulesData = this.data.findBy('title', 'All Modules');
      const forms = this.data.findBy('title', 'Form');
      let modulesToDisplay = (modulesData === null || modulesData === void 0 ? void 0 : modulesData.children) || [];

      if (!(_environment.default === null || _environment.default === void 0 ? void 0 : _environment.default.featureFlags['release-section-widget'])) {
        modulesToDisplay = modulesToDisplay.filter(x => (x === null || x === void 0 ? void 0 : x.type) !== 'widget-section');
      }

      if (!(_environment.default === null || _environment.default === void 0 ? void 0 : _environment.default.featureFlags['release-tabs-widget'])) {
        modulesToDisplay = modulesToDisplay.filter(x => !['widget-tabs', 'widget-tab-pane'].includes(x === null || x === void 0 ? void 0 : x.type));
      } // all snapshots are concentrated inside artificial subcategories
      // which are created at runtime during categories list built process


      const snapshots = ((_this$data$findBy = this.data.findBy('title', _gallery.SNAPSHOT_STUDIO)) === null || _this$data$findBy === void 0 ? void 0 : (_this$data$findBy$chi = _this$data$findBy.children) === null || _this$data$findBy$chi === void 0 ? void 0 : (_this$data$findBy$chi2 = _this$data$findBy$chi.find(item => {
        var _item$data;

        return (item === null || item === void 0 ? void 0 : (_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.Name) === _gallery.SnapshotCategories.ALL_SNAPSHOTS;
      })) === null || _this$data$findBy$chi2 === void 0 ? void 0 : _this$data$findBy$chi2.children) || [];
      return [...modulesToDisplay, ...forms.children, ...snapshots];
    }),

    /**
     * results of performing search
     * @type {Object[]}
     */
    searchResults: Ember.computed('searchString', function () {
      const searchString = this.searchString.trim();
      const regex = new RegExp(searchString, 'i');
      const filteredModulesByTitle = this.modulesToSearch.filter(mdl => mdl.visible && mdl.satisfiesSearchByTitle(regex));
      let remainingData = this.modulesToSearch.filter(x => !filteredModulesByTitle.includes(x));

      if (!remainingData || !remainingData.length) {
        return filteredModulesByTitle;
      }

      const filteredModulesByTags = remainingData.filter(mdl => mdl.visible && mdl.satisfiesSearchByTag(regex));
      const mergeTitleWithTags = [...filteredModulesByTitle, ...filteredModulesByTags];
      remainingData = remainingData.filter(x => !mergeTitleWithTags.includes(x));

      if (!remainingData || !remainingData.length) {
        return mergeTitleWithTags;
      }

      const filteredModulesByDescription = remainingData.filter(mdl => mdl.visible && mdl.satisfiesSearchByDescription(regex));
      return [...mergeTitleWithTags, ...filteredModulesByDescription];
    }),

    /**
     * results of current stack item children
     * @type {Object[]}
     */
    stackResults: Ember.computed('stack', 'stack.[]', function () {
      const {
        stack
      } = this;
      const result = stack.length ? stack[stack.length - 1].get('children') : this.data;
      const isCategoriesList = result.every(item => item.type === 'category');

      if (!isCategoriesList && stack.length) {
        // Do not modify original order
        return this._sortModules(result.slice());
      }

      return result;
    }),

    /**
     * Queue to update form page usage info
     * @type {Object[] | null}
     */
    formConfigsToUpdatePageInfoQueue: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('stack', this.stack || []);
      this.set('display', this.data);
      this.formConfigsToUpdatePageInfoQueue = [];
    },

    /**
     * Generate grid structure item with config
     *
     * @param {string} type - type extracted from original module/widget/form record
     * @param {Object} config - grid structure item config
     * @returns {Object}
     * @private
     */
    createStructureItem(type, config) {
      const item = this.moduleGalleryItemBuilder.createInstance(type, config);

      if (type !== _container.default.WIDGET_CONTAINER) {
        const column = this.moduleGalleryItemBuilder.createInstance(_container.default.WIDGET_COLUMN);
        this.structureService.addChild(column, item);
      }

      return item;
    },

    /**
     * Sorting array with modules
     *
     * @param {Object[]} array - array for sorting
     * @returns {Object[]}
     * @private
     */
    _sortModules(array) {
      switch (this.modulesSortType) {
        case 'alphabetically':
          array.sort((a, b) => {
            const aTitle = a.title.toLowerCase();
            const bTitle = b.title.toLowerCase();
            const greaterReturn = aTitle > bTitle ? 1 : 0;
            return aTitle < bTitle ? -1 : greaterReturn;
          });
      }

      return array;
    },

    /**
     * Get form theme, since forms are not linked to modules, they are linked by hardcoded module name = "Form"
     *
     * @param {string} themeName - name of form theme
     * @returns {DS.Model} - form theme
     */
    getFormTheme(themeName) {
      // TODO: this shouldn't be here, but we have no time for this
      const moduleId = this.store.peekAll('module').findBy('Name', 'Form').get('id');
      return this.store.peekAll('module-theme').filterBy('ModuleId', moduleId).findBy('Name', themeName);
    },

    /**
     * Create form config
     *
     * @param {Object} params - form parameters
     * @param {DS.Model} params.theme - theme
     * @param {string} params.formId - id of form
     * @param {string} params.title - form title
     * @param {string} params.subject - form subject
     * @param {string} params.isEncrypted - form encryption
     * @returns {Object}
     */
    createFormConfig(params) {
      const {
        theme,
        formId,
        title,
        subject,
        isEncrypted,
        formType,
        formSteps
      } = params; // Standard module config

      const moduleConfig = this.moduleConfigurationService.createModuleConfiguration(theme); // Form config differs a bit, it has form id, title and subject

      moduleConfig.Structure = Ember.assign(moduleConfig.Structure, {
        formId,
        title,
        subject,
        isEncrypted,
        formType,
        formSteps
      });
      return moduleConfig;
    },

    /**
     * Gets module config after drop to the page
     * @param {DS.Model<ModuleThemeModel>} themeRecord - module theme record
     * @param {Ember.Object} structureItem - structure item which config to create
     * @returns {Promise}
     */
    getModuleConfig(themeRecord, structureItem) {
      const moduleConfigId = structureItem.originObject.id;
      let promise; // When we drop prefabricated container with module inside

      if (moduleConfigId) {
        promise = this.getPreviewConfigAndPushToStore(moduleConfigId);
      } else {
        const config = this.moduleConfigurationService.createModuleConfiguration(themeRecord);
        promise = this.store.createRecord('page-module', config).save();
      }

      return promise.then(record => {
        this.updateStructureItem(structureItem, record); // Register clone into modules service, since all modules with config, must be registered in modules service
        // in order to open it's config

        this.modules.add(structureItem);
        this.previewService.set('rebuild', true);
        return record;
      });
    },

    /**
     * Gets form module config after drop to the page
     * @param {Ember.Object} structureItem - structure item which config to create
     * @returns {Promise<T>}
     */
    getFormModuleConfig(structureItem) {
      return this.getPreviewConfigAndPushToStore(structureItem.originObject.id).then(config => {
        structureItem.set('config', config); // Register clone into modules service, since all modules with config, must be registered in modules service
        // in order to open it's config

        this.modules.add(structureItem);
        this.previewService.set('rebuild', true);
        this.addFormConfigToUpdatePageInfoQueue(config);
        return config;
      });
    },

    /**
     * Updates structure item with new record
     * @param {Ember.Object} structureItem - Page structure item
     * @param {DS.Model<PageModuleModel>} record - Page module model record
     */
    updateStructureItem(structureItem, record) {
      if (!structureItem.originObject.id) {
        // Well this part is probably should be moved to individual places where it's used
        // Since module config id === module id, we must set new id to structure
        structureItem.get('originObject').set('id', parseInt(record.get('id'), 10));
      }

      structureItem.set('config', record);
    },

    /**
     * Prepare data to be pushed to store
     * @param {Object} data - data to be prepared
     * @returns {Object} newData
     */
    prepareData(data) {
      const newData = {
        id: data.Id,
        type: 'pageModule',
        attributes: (0, _emberCopy.copy)(data)
      };
      delete newData.attributes.Id;
      return newData;
    },

    /**
     * Clones container structure by id
     * @param {number} templateId - Container template id to clone from
     * @returns {Promise}
     */
    cloneContainerStructure(templateId) {
      const currentPageId = this.configurationService.get('page.id');
      const sessionId = this.configurationService.get('sessionId');
      return this.store.cloneContainerTemplateStructure(templateId, currentPageId, sessionId);
    },

    /**
     * Reload form and creates new config
     * @param {DS.Model<FormModel>} record - Form model record
     * @returns {Promise}
     */
    getFormConfig(record) {
      return record.reload().then(formModel => this.createFormConfig({
        theme: this.getFormTheme(formModel.get('Theme')),
        formId: formModel.get('id'),
        title: formModel.get('Title'),
        subject: formModel.get('Subject'),
        isEncrypted: formModel.get('IsEncrypted'),
        formType: formModel.get('FormType'),
        formSteps: formModel.get('formSteps')
      }));
    },

    /**
     * Handle form added on the page
     *
     * @param {DS.Model<FormModel>} record - form record
     * @param {Ember.Object} structureItem - structure item which config to create
     * @returns {Promise}
     */
    handleFormAdd(record, structureItem) {
      return this.getFormConfig(record).then(config => this.store.createRecord('page-module', config).save().then(savedRecord => {
        // Well this part is probably should be moved to individual places where it's used
        // Since module config id === module id, we must set new id to structure
        structureItem.get('originObject').set('id', parseInt(savedRecord.get('id'), 10));
        structureItem.set('config', savedRecord); // Register clone into modules service, since all modules with config, must be registered in modules service
        // in order to open it's config

        this.modules.add(structureItem);
        this.previewService.set('rebuild', true);
        return savedRecord;
      }));
    },

    /**
     * Get widget startup edit config
     *
     * @param {string} type - widget type
     * @returns {Object} - startup widget config
     */
    getWidgetStartupConfig(type) {
      let config = {};

      switch (type) {
        case _widget.WidgetType.WIDGET_IMAGE:
          {
            config = Ember.Object.create({
              src: {
                autoOpen: true
              }
            });
            break;
          }
      }

      return config;
    },

    /**
     * Handles page items
     * @param {Ember.Object} item - page structure item
     * @param {DS.Model<FormModel>|DS.Model<ModuleModel>|DS.Model<ContainerTemplateModel>} model - Module or form model
     * @returns {Promise[]} Array of promises
     */
    handlePageItem(item, model) {
      let promises = [];

      if (this.structureService.isType(item, _module.default.MODULE_CORE)) {
        promises.push(this.handleCoreModule(item));
      } else if (this.structureService.isType(item, _module.default.MODULE_FORM)) {
        // When we drop prefabricated container with form inside
        if (item.originObject.id) {
          promises.push(this.handleContainerForm(item)); // When we drop just new form
        } else if (model) {
          promises.push(this.handleNewFormModule(item, model));
        }
      } else if (this.structureService.isContainerType(item)) {
        item.originObject.children.forEach(child => {
          promises = promises.concat(this.handlePageItem(child, model));
        });
      } else {
        promises.push(Ember.RSVP.resolve(item));
      }

      return promises;
    },

    /**
     * Handle core module item
     * @param {Ember.Object} item - Page structure item
     * @returns {Promise}
     */
    handleCoreModule(item) {
      const moduleId = this.store.peekAll('module').findBy('Name', item.originObject.name).id;
      const themeModel = this.store.peekAll('module-theme').find(theme => theme.ModuleId === moduleId && theme.Name === item.originObject.theme);
      const moduleConfigId = item.originObject.id;
      const promises = [this.getModuleConfig(themeModel, item)]; // Refresh style for existing module

      if (moduleConfigId) {
        promises.push(this.fetchAndPrepareModuleStyle(moduleConfigId).then(moduleStyleModel => {
          item.set('moduleStyleModel', moduleStyleModel);
        }));
      }

      return Ember.RSVP.all(promises);
    },

    /**
     * Fetch and prepare module style
     * @param {string} moduleConfigId - config id
     * @returns {Promise<ModuleStyleModel>}
     */
    fetchAndPrepareModuleStyle(moduleConfigId) {
      return this.store.findRecord('module-style', moduleConfigId).then(moduleStyleModel => {
        const moduleTheme = this.store.peekRecord('module-theme', moduleStyleModel.get('ThemeId'));
        moduleStyleModel.styleMap = moduleTheme.get('StyleMap');
        moduleStyleModel.set('globalVariablesModel', this.store.peekRecord('website-style', this.configurationService.website.id)); // Invalidate generated css

        moduleStyleModel.set('StyleCSS', '');
        return Ember.RSVP.resolve(moduleStyleModel);
      });
    },

    /**
     * Handles form module from container structure
     * @param {Ember.Object} item - Page structure item
     * @returns {Promise}
     */
    handleContainerForm(item) {
      const moduleConfigId = item.originObject.id;
      const promises = [this.getFormModuleConfig(item)];

      if (moduleConfigId) {
        promises.push(this.fetchAndPrepareModuleStyle(moduleConfigId).then(moduleStyleModel => {
          item.set('moduleStyleModel', moduleStyleModel);
        }));
      }

      return Ember.RSVP.all(promises);
    },

    /**
     * Retrieves config from preview table and pushes to store
     * @param {number} id - Config id
     * @returns {Promise}
     */
    getPreviewConfigAndPushToStore(id) {
      return this.store.findModuleConfigPreview(id).then(record => {
        const {
          pageModulepreview
        } = record;
        const data = this.prepareData(pageModulepreview);
        this.store.push({
          data
        });
        return this.store.peekRecord('page-module', data.id);
      });
    },

    /**
     * Handle new added form
     * @param {Ember.Object} item - Page structure item
     * @param {DS.Model<FormModel>} model - Form model
     * @returns {Promise}
     */
    handleNewFormModule(item, model) {
      return this.handleFormAdd(model, item).then(config => {
        this.addFormConfigToUpdatePageInfoQueue(config);
        return config;
      });
    },

    /**
     * Add form config to update queue
     * @param {Object} config - Form config
     */
    addFormConfigToUpdatePageInfoQueue(config) {
      this.formConfigsToUpdatePageInfoQueue.push(config);
    },

    /**
     * Update usage forms in pages
     * @param {Object[]} configs - Form configs
     */
    updateFormsPageInfo(configs) {
      configs.forEach(config => {
        this.moduleAction('updateFormPageInfo', {}, config);
      });
    },

    /**
     * Returns normalize widget structure and updates d&d draggable item
     * @param {string} type - Draggable widget type
     * @param {Object} newStructure - New cloned structure
     * @returns {Ember.Object}
     */
    getNormalizedWidgetContainerInstance(type, newStructure) {
      // Any structure added to a pge must be normalized
      const normalizedStructure = this.store.serializerFor('container-template').normalizeStructure(newStructure); // Create instances from normalized structure

      const structureInstance = this.createStructureItem(type, normalizedStructure);
      return structureInstance;
    },

    /**
     * Clones widget container then handles and drops
     * @param {DS.Model<ContainerTemplateModel>} record - Container template model
     * @param {string} type - Object type
     * @param {number} x - Mouse x coordinate
     * @param {number} y - Mouse y coordinate
     * @returns {Promise}
     */
    addWidgetContainer(record, type, x, y) {
      return this.cloneContainerStructure(record.id).then(newStructure => {
        const widgetContainer = this.getNormalizedWidgetContainerInstance(type, newStructure); // Update drag-and-drop service draggableComponent property because this object will be dropped

        this.dragAndDropService.set('draggableComponent', widgetContainer);
        const promises = this.handlePageItem(widgetContainer, record);
        /**
         * We have to wait to resolve all container children and then drop it
         * because it could be module
         */

        return Ember.RSVP.all(promises, 'Handles container and its children')
        /**
         * Drop container even in case of error because error happened inside container children
         * and we have to render container anyway even with broken module inside
         */
        .finally(() => this.dragAndDropService.mouseUp(x, y));
      });
    },

    /**
     * Drops gallery item then handles it
     * @param {DS.Model<ModuleModel>} record - Module model
     * @param {number} x - Mouse x coordinate
     * @param {number} y - Mouse y coordinate
     * @returns {Promise}
     */
    addGalleryItem(record, x, y) {
      const {
        draggableComponent
      } = this.dragAndDropService;
      const promises = this.handlePageItem(draggableComponent, record);
      return Ember.RSVP.all(promises, 'Handles gallery item after drop').finally(() => this.dragAndDropService.mouseUp(x, y));
    },

    /**
     * Runs actions after gallery item drop and handle
     * @param {DS.Model<ModuleModel>|DS.Model<ContainerTemplateModel>} record - Gallery item model
     * @param {string} type - Gallery item type
     * @param {JQuery} node - Gallery item jQuery object
     * @returns {void}
     */
    handleGalleryItemAfterDrop(record, type, node) {
      const isCoreModule = type === _module.default.MODULE_CORE;
      const isFormModule = type === _module.default.MODULE_FORM;
      const startUpConfig = this.getWidgetStartupConfig(type);
      const {
        draggableComponent
      } = this.dragAndDropService; // Determine the place where drop happened

      const isDropToPage = this.dragAndDropService.isDropToPage() ? 'page' : null;
      const placeToDrop = this.dragAndDropService.isDropToLayout() ? 'layout' : isDropToPage;

      if (isCoreModule) {
        draggableComponent.set('autoOpen', true); // Undo/redo history must be created after module drop and when module get new id in structure

        this.addHistoryCheckpoint(placeToDrop, 'config', record);
      }

      if (!(isCoreModule || isFormModule)) {
        this.addHistoryCheckpoint(placeToDrop, 'page');
      } // When node is really dropped into structure, HTMLElement must be removed immediately


      node.remove(); // Close module gallery

      this.moduleAction('closeModuleGallery'); // activate if module is form

      if (type === _module.default.MODULE_FORM) {
        this.moduleAction(_moduleAction.default.ACTIVATE, draggableComponent, startUpConfig);
      } // Open config when component is ready


      this.moduleAction(_moduleAction.default.EDIT, draggableComponent, startUpConfig);
      this.previewService.set('rebuild', true); // Update form page info usage

      this.updateFormsPageInfo(this.formConfigsToUpdatePageInfoQueue);
      this.formConfigsToUpdatePageInfoQueue.length = 0;
    },

    actions: {
      /**
       * Action to expand search input
       */
      expand() {
        this.set('expanded', true);
      },

      /**
      	 * Search inside gallery
      	 *
      	 * @param {string} searchQuery
      	 * @returns {undefined}
      	 */
      gallerySearch(searchQuery) {
        const searchString = searchQuery.trim();
        this.set('stack', []);
        this.set('notFound', false);

        if (searchString.length >= this._searchQueryLength) {
          const {
            searchResults
          } = this;
          this.set('notFound', !searchResults.length);
          return this.set('display', searchResults);
        }

        return this.set('display', this.stackResults);
      },

      /**
       * Focus out action
       * @param {string} searchQuery
       * @returns {void}
       */
      closeSearch(searchQuery) {
        if (!searchQuery) {
          this.set('expanded', false);
        }
      },

      /**
       * Clean search action
       * @returns {void}
       */
      clearSearch() {
        this.set('searchString', '');
        this.set('stack', []);
        this.set('display', this.stackResults);
        this.set('notFound', false);
      },

      goBack() {
        this.stack.popObject();
        this.set('display', this.stackResults);
      },

      onClick(node) {
        // Restore original behaviour, do not allow user to step into module which has no themes
        if (node.get('children.length') && node.get('children').some(child => child.get('visible'))) {
          this.stack.pushObject(node);
        }

        this.set('display', this.stackResults);
      },

      onDragStart(item, x, y) {
        const model = item.getInsertModel();
        const {
          type
        } = model;
        this.set('dragItem', model);
        const structureItem = this.createStructureItem(type, model.getInsertData());

        if (type === _widget.WidgetType.WIDGET_PARAGRAPH) {
          // Activate editor on startup
          structureItem.editOnStartup = true;
        } // Add draggable node to drag-and-drop service, to emulate structure like drag, that we are dragging one
        // of structure elements


        this.dragAndDropService.mouseDown(structureItem, x, y);
      },

      onMouseMove(x, y) {
        // Since we set draggable element @see onDragStart, now we must let structure drag and drop do it's job
        // on mouse move
        this.dragAndDropService.mouseMove(x, y);
      },

      onMouseUp(component, node, x, y) {
        if (!this.dragAndDropService.canDrop(x, y)) {
          return;
        }

        const itemData = this.dragItem;
        const type = itemData.get('type');
        const record = itemData.get('data');
        let promiseComponentReady;

        if (type === _container.default.WIDGET_CONTAINER) {
          promiseComponentReady = this.addWidgetContainer(record, type, x, y);
        } else {
          promiseComponentReady = this.addGalleryItem(record, x, y);
        }

        promiseComponentReady.then(result => {
          if (result) {
            this.handleGalleryItemAfterDrop(record, type, node);
          }
        });
      },

      onDelete(galleryItem) {
        const itemType = galleryItem.get('type');

        switch (itemType) {
          case _module.default.MODULE_FORM:
            {
              if (!galleryItem.get('data.UsedInPages.length') && confirm(this.get('literals.FORM_TEMPLATE_DELETE_WARNING'))) {
                this.moduleAction(_moduleAction.default.DELETE_FORM_TEMPLATE, galleryItem.get('data'));
              }

              break;
            }

          case _container.default.WIDGET_CONTAINER:
            {
              if (confirm(this.get('literals.CONTAINER_TEMPLATE_DELETE_WARNING'))) {
                this.moduleAction(_moduleAction.default.DELETE_CONTAINER_TEMPLATE, galleryItem.get('data'));
              }

              break;
            }
        }
      },

      onEdit(galleryItem) {
        const itemType = galleryItem.get('type');

        if (itemType === _container.default.WIDGET_CONTAINER) {
          this.moduleAction(_moduleAction.default.EDIT_CONTAINER_TEMPLATE, galleryItem.get('data'));
        }
      },

      /**
       * Action for transition to other route
       * @param {string="page"} type - transition type
       * @param {*[]} rest - other arguments
       * @returns {void}
       */
      onRequestToTransition(type, ...rest) {
        if (typeof this.moduleAction !== 'function') {
          return;
        }

        if (type === 'page') {
          const [pageId] = rest;
          this.moduleAction(_moduleAction.default.TRANSITION_TO_PAGE, pageId);
        }
      }

    }
  });

  _exports.default = _default;
});