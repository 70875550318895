define("builder/pods/components/sm-select/component", ["exports", "builder/core/abstract-classes/variable-component", "builder/pods/components/sm-select/sm-select-options"], function (_exports, _variableComponent, _smSelectOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SMSelectComponent = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Component help build select for styles management UI.
   */
  let SMSelectComponent = (_dec = Ember.computed('cssPropertyName'), _dec2 = Ember.computed('variable.value'), _dec3 = Ember.computed('optionsList'), _dec4 = Ember._action, (_class = (_temp = class SMSelectComponent extends _variableComponent.default {
    constructor(...args) {
      super(...args);
      this.classNames = ['line-box__line', 'line-box__inner'];
      this.isOpened = false;
    }

    /**
     * Component default options
     * @type {SMSelectOption[]}
     */
    get defaultOptions() {
      const options = _smSelectOptions.CSS_PROPERTY_OPTIONS_MAP[this.cssPropertyName];

      if (!options) {
        throw new Ember.Error(`not supported css property ${this.cssPropertyName} for select. ${this.toString()}`);
      }

      return options;
    }
    /**
     * Contains list of options which will be rendered
     * @type {SMSelectOption[]}
     */


    get optionsList() {
      var _this$variable;

      const selectedValue = (_this$variable = this.variable) === null || _this$variable === void 0 ? void 0 : _this$variable.value;
      const options = this.defaultOptions;
      const selectedOption = options.find(option => option.value === selectedValue) || options.find(option => option.default);
      return options.map(option => ({ ...option,
        selected: option === selectedOption
      }));
    }
    /**
     * Current selected options
     * @type {SMSelectOption}
     * @readonly
     */


    get selectedOption() {
      return this.optionsList.find(option => option.selected);
    }
    /**
     * Action select new option.
     *
     * @param {SMSelectOption} option - option which select user
     * @returns {void}
     */


    select(option) {
      if (this.selectedOption.value !== option.value) {
        this.send('update', option.value);
      } // Force close


      this.set('isOpened', false);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "defaultOptions", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "defaultOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "optionsList", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "optionsList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedOption", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectedOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.SMSelectComponent = SMSelectComponent;
  var _default = SMSelectComponent;
  _exports.default = _default;
});