define("builder/pods/website/edit/page/edit/module/settings/controller", ["exports", "builder/controllers/base", "builder/config/environment", "builder/core/literals", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "builder/utils/fields", "builder/core/configuration-builder/fields/field-media", "builder/core/util/define-fields-visibility", "builder/core/grid-models/module-device-visibility-state"], function (_exports, _base, _environment, _literals, _fields, _breakpointModes, _fields2, _fieldMedia, _defineFieldsVisibility, _moduleDeviceVisibilityState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * @property {AjaxService} ajaxService - ember ajax service
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Structure service
     * @type {StructureService}
     */
    structureService: Ember.inject.service('structure'),

    /**
     * Router service
     * @type {import("ember").Ember.Service}
     */
    router: Ember.inject.service('router'),

    /**
     *  modules store service
     * @type {import("ember").Ember.Service}
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * configuration handler(handles module config data set)
     * @type {ModuleDataHandler | null}
     */
    configurationHandler: null,

    /**
     * margin top for popup
     * @type {string}
     */
    popupMarginTop: '140px',

    /**
     * CSS-class for config widget/module popups message
     * @type {string}
     */
    configPopupTitleMessageClass: '',

    /**
     * Message for config widget/module popups
     * @type {string}
     */
    configPopupTitleMessage: '',

    /**
     * link to lead routing module of Ignite2
     * @type {string}
     * @readonly
     */
    leadsRoutingLink: Ember.computed(function () {
      return `${_environment.default.ignite2BaseUrl}leadrouting`;
    }).readOnly(),

    /**
     * Configuration for all module configuration fields
     * @type {object[]}
     */
    formFields: Ember.computed('model.formFields', 'model.formFields.@each.data', function () {
      return this.model.formFields;
    }),

    /**
     * Current page id
     * @type {string}
     * @readonly
     */
    currentPageId: Ember.computed.readOnly('configurationService.page.id'),

    /**
     * Timer for hide save status message
     * @type {import('@ember/runloop/types').EmberRunTimer | null}
     * @private
     */
    _configStatusMessageHideTimer: null,

    /**
     * Timer for show save status message
     * @type {import('@ember/runloop/types').EmberRunTimer | null}
     * @private
     */
    _configStatusMessageShowTimer: null,

    /**
     * Array for promises queue for call auto save config module
     * @type {Promise[]}
     * @private
     */
    _configAutoSavePromisesQueue: [],

    /**
     * Bootstrap service
     * @type {BootstrapService}
     */
    bootstrapService: Ember.inject.service('bootstrap'),

    /**
     * Grid service
     * @type {GridService}
     */
    gridService: Ember.inject.service('grid'),

    /**
     * Gets updates style variables
     * @param {Object }updateModel
     * @param {VariableFragment} updateModel.variable - variable model
     * @param {string} updateModel.variableName - name of variable that was originally referenced to store value
     * @param {string} updateModel.newValue - new value of style variable
     * @param {boolean} updateModel.isNew - help to determine that we should create new variable fragment
     * @param {boolean} updateModel.isDeviceDependent - determine that variable defined in each mode
     * @param {Object} updateModel.variablesModel - Model of style variable
     * @returns {Object[]}
     */
    getUpdatedStyleVariables({
      variable,
      variableName,
      newValue,
      isNew,
      isDeviceDependent,
      variablesModel
    }) {
      var _configService$curren, _variablesModel$Style;

      const moduleStylesData = this.model && this.model.moduleStylesData || null;
      const configService = this.configurationService;
      const breakpoint = configService === null || configService === void 0 ? void 0 : (_configService$curren = configService.currentDevice) === null || _configService$curren === void 0 ? void 0 : _configService$curren.breakpoint;
      const styleOption = variablesModel === null || variablesModel === void 0 ? void 0 : (_variablesModel$Style = variablesModel.StyleOptions) === null || _variablesModel$Style === void 0 ? void 0 : _variablesModel$Style.findBy('variableName', variableName);
      let changedVariables = [];
      let valueToAssign = newValue;
      let varModel = variablesModel;

      if (!isNaN(newValue) && variableName !== 'headings-line-height') {
        valueToAssign = `${newValue}px`;
      }

      if (isNew) {
        const newVar = {
          name: variableName,
          value: valueToAssign
        };

        if (isDeviceDependent) {
          const oldVar = variable.toJSON();
          varModel = moduleStylesData.variablesModel;
          [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
            if (mode === breakpoint) {
              changedVariables.push(varModel[breakpoint].createFragment(newVar));
            } else {
              changedVariables.push(varModel[mode].createFragment(oldVar));
            }
          });
        } else {
          var _moduleStylesData$var, _moduleStylesData$var2;

          changedVariables.push((_moduleStylesData$var = moduleStylesData.variablesModel) === null || _moduleStylesData$var === void 0 ? void 0 : (_moduleStylesData$var2 = _moduleStylesData$var.global) === null || _moduleStylesData$var2 === void 0 ? void 0 : _moduleStylesData$var2.createFragment(newVar));
        }
      } else {
        variable.set('value', valueToAssign);
        changedVariables.push(variable);
      } // Populate variable value to all breakpoints


      if (isDeviceDependent && styleOption && styleOption.locked) {
        changedVariables = changedVariables.concat(this.populateVariableToBreakpoints(variable, varModel));
      }

      return changedVariables;
    },

    /**
     * Transition to page/edit route
     * @returns {void}
     */
    transitionToParent() {
      this.transitionToRoute('website.edit.page.edit');
    },

    /**
     * Setup variable to all breakpoints at once*
     * @param {VariableFragment} variable - variable model
     * @param {StyleModel} variablesModel - style model
     * @param {string[]} [breakpoints=[LARGE, MEDIUM, SMALL, EXTRA_SMALL]] - list of breakpoints
     * @returns {VariableFragment[]} - Changed variables
     */
    populateVariableToBreakpoints(variable, variablesModel, breakpoints = [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL]) {
      const changedVariables = [];
      breakpoints.forEach(mode => {
        const variables = variablesModel[mode];

        if (variables) {
          let variableToChange = variables.findBy('name', variable.name);

          if (variableToChange) {
            variableToChange.set('value', variable.value);
          } else {
            variableToChange = variables.createFragment(variable.toJSON());
          }

          changedVariables.push(variableToChange);
        }
      });
      return changedVariables;
    },

    /**
     * Show save status message in config popup header
     * @param {'in-progress' | 'success' | 'fail'} status - status of message
     * @private
     * @returns {void}
     */
    _showPopupConfigStatusMessage(status = 'in-progress') {
      switch (status) {
        case 'in-progress':
          this.set('configPopupTitleMessage', _literals.default.MODULE_CONFIG_APPLYING_CHANGES);
          this.set('configPopupTitleMessageClass', 'page_module-config_message--success');
          break;

        case 'success':
          this.set('configPopupTitleMessage', _literals.default.MODULE_CONFIG_CHANGES_APPLIED);
          this.set('configPopupTitleMessageClass', 'page_module-config_message--success');
          break;

        case 'fail':
          this.set('configPopupTitleMessage', _literals.default.MODULE_CONFIG_FAILED_APPLY_CHANGES);
          this.set('configPopupTitleMessageClass', 'page_module-config_message--fail');
          break;
      }
    },

    /**
     * Hide save status message in config popup header
     * @param {Number} [delay=0] - delay before hiding message
     * @private
     * @returns {void}
     */
    _hidePopupConfigStatusMessage(delay = 0) {
      Ember.run.cancel(this._configStatusMessageHideTimer);
      this._configStatusMessageHideTimer = Ember.run.later(this, () => {
        this.set('configPopupTitleMessage', '');
        this.set('configPopupTitleMessageClass', '');
      }, delay);
    },

    /**
     * Reset properties for save status message in config popup header
     * @private
     * @returns void
     */
    _resetPopupConfigStatusMessage() {
      this.set('configPopupTitleMessage', '');
      this.set('configPopupTitleMessageClass', '');
      Ember.run.cancel(this._configStatusMessageHideTimer);
      Ember.run.cancel(this._configStatusMessageShowTimer);
    },

    /**
     * Method to be invoked when module config value changes(in module config window)
     * @private
     * @returns {void}
     */
    _configAutoSaveRun() {
      this._showPopupConfigStatusMessage('in-progress');

      const promisesQueue = this._configAutoSavePromisesQueue;

      if (!promisesQueue.length) {
        this._createConfigAutoSavePromises();
      }

      promisesQueue.push(1);
    },

    /**
     * Method is called after the execution of the main promise and causes the next advance in the queue,
     * or calls the result handlers depending on the success or error
     * @param {string} type - type result, success or error
     * @param {Object} data - main promise result data
     * @private
     * @returns {void}
     */
    _configAutoSavePromiseHandler(type, data) {
      const promisesQueue = this._configAutoSavePromisesQueue;
      promisesQueue.shift();

      if (promisesQueue.length) {
        // Set only one promise marker in queue
        promisesQueue.length = 1;

        this._createConfigAutoSavePromises();
      } else {
        switch (type) {
          case 'success':
            this._configAutoSavePromiseEnd(data);

            break;

          case 'error':
            this._configAutoSavePromiseEndError(data);

            break;
        }
      }
    },

    /**
     * Method create and run main promise
     * @private
     * @returns {void}
     */
    _createConfigAutoSavePromises() {
      this.store.peekRecord('page-module', this.model.moduleConfig.id).save().then(this._configAutoSavePromiseHandler.bind(this, 'success'), this._configAutoSavePromiseHandler.bind(this, 'error'));
    },

    /**
     * Method will be call after call all queue promises and returning resolve,
     * reload module and update all module dependence, call auto save builder
     * @private
     * @returns {void}
     */
    _configAutoSavePromiseEnd() {
      const storedModule = this.modules.findById(this.model.moduleConfig.id);
      this.send('reloadModule'); // Config module changed

      this.send('setChanges', 'module');

      if (storedModule) {
        this.modules.updateModulesDependencies(storedModule);
      }

      this._showPopupConfigStatusMessage('success');

      this._hidePopupConfigStatusMessage(3000);
    },

    /**
     * Method will be call after call all queue promises and returning reject,
     * turn off notification about auto save happening, show notification about error auto save and call error handler
     * @param {Object} error - reject error from page module save promise
     * @private
     * @returns {void}
     */
    _configAutoSavePromiseEndError(error) {
      this._showPopupConfigStatusMessage('fail');

      this.errorHandler(error);
    },

    /**
     * Method sets slider images focal points
     * NOTE:
     * 		something magical happens here.
     * 		We are setting focal points through changing values on provided value which is a reference to field values.
     * TODO: should be refactored. Maybe we should move all this stuff to form variant handler method.
     * @param {EmberObject} field - Current field state
     * @param {Object[]} value - array of current variants state
     * @param {string} name - field name in structure
     * @private
     * @returns {void}
     */
    _setSliderImageFocalPoints(field, value, name) {
      var _this$configurationSe, _this$configurationSe2;

      /**
       * NOTE: we need to find active model every time we change value
       * because save operation recreates whole structure and active module component
       * is not in dom after save.
       */
      const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
      const breakpoint = (_this$configurationSe = this.configurationService) === null || _this$configurationSe === void 0 ? void 0 : (_this$configurationSe2 = _this$configurationSe.currentDevice) === null || _this$configurationSe2 === void 0 ? void 0 : _this$configurationSe2.breakpoint;

      const mediaBuilder = _fieldMedia.default.create();

      const variants = {};

      _fields2.default.findFieldsInFormVariants(field, fieldChild => fieldChild.type === _fields.default.MEDIA && fieldChild.validation.hasOwnProperty('image')).forEach(imageVariant => {
        if (!variants.hasOwnProperty(imageVariant.variant.name)) {
          variants[imageVariant.variant.name] = [];
        }

        variants[imageVariant.variant.name].push(imageVariant.field.name);
      });

      value.forEach((variant, index) => {
        if (!variants.hasOwnProperty(variant['variant-name']) || !variant) {
          return;
        }

        Object.keys(variant).forEach(subKey => {
          if (variant[subKey] && variants[variant['variant-name']].includes(subKey)) {
            var _this$model$moduleCon;

            const source = (_this$model$moduleCon = this.model.moduleConfig) === null || _this$model$moduleCon === void 0 ? void 0 : _this$model$moduleCon.Structure[name][index][subKey];
            const data = mediaBuilder.build({
              type: 'image',
              url: Ember.get(source, 'url'),
              naturalWidth: Ember.get(source, 'naturalWidth'),
              naturalHeight: Ember.get(source, 'naturalHeight'),
              scale: Ember.get(source, `focalPoints.${breakpoint}.scale`),
              left: Ember.get(source, `focalPoints.${breakpoint}.left`),
              top: Ember.get(source, `focalPoints.${breakpoint}.top`)
            }, activeModule);
            Ember.setProperties(variant[subKey], data);
          }
        });
      });
    },

    /**
     * Method resets focal points for images (we are using this method for widget-image)
     * TODO: should be refactored. Maybe we should move all this stuff to form variant handler method.
     * @param {EmberObject} field - current field state
     * @param {string} name - structure field name for origin state
     * @private
     * @returns {void}
     */
    _setImageFocalPoints(field, name) {
      var _this$configurationSe3, _this$configurationSe4;

      /**
       * NOTE: we need to find active model every time we change value
       * because save operation recreates whole structure and active module component
       * is not in dom after save.
       */
      const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
      const breakpoint = (_this$configurationSe3 = this.configurationService) === null || _this$configurationSe3 === void 0 ? void 0 : (_this$configurationSe4 = _this$configurationSe3.currentDevice) === null || _this$configurationSe4 === void 0 ? void 0 : _this$configurationSe4.breakpoint;

      const builder = _fieldMedia.default.create();

      const originState = this.model.moduleConfig.Structure[name];
      const data = originState && builder.build({
        type: 'image',
        url: Ember.get(originState, 'url'),
        naturalWidth: Ember.get(originState, 'naturalWidth'),
        naturalHeight: Ember.get(originState, 'naturalHeight'),
        scale: Ember.get(originState, `focalPoints.${breakpoint}.scale`),
        left: Ember.get(originState, `focalPoints.${breakpoint}.left`),
        top: Ember.get(originState, `focalPoints.${breakpoint}.top`)
      }, activeModule);
      Ember.set(field, 'data', data);
    },

    /**
     * Method wraps parent route action run.
     * @private
     * @returns {void}
     */
    _addHistoryCheckpoint() {
      // This is action in page/route.js
      this.send('addHistoryCheckpoint', {
        model: this.model.moduleConfig,
        callback: () => {
          /* @todo it should be refactored because we should not refresh the route
          	to re-render the view. The problem in "buildConfiguration" method in
          	setupController method. It returns promise and it requires node object
          	from structure which makes it super dependent from structure.
          	All data from setupController should be moved to controller w/o any promises
          	then view will be re-rendered on data update.
           */
          const {
            currentRouteName
          } = this.router; // Make sure module config is opened otherwise you will get an error no refresh action

          if (currentRouteName === 'website.edit.page.edit.module-gallery.settings' || currentRouteName === 'website.edit.page.edit.module.settings.index') {
            this.send('refresh');
          }

          this._configAutoSaveRun();
        }
      });
    },

    /**
     * Method adds module to separate update queue
     * if it is a system module
     *
     * @private
     * @returns {void}
     */
    _addSystemModuleToUpdateQueue() {
      const {
        moduleConfig
      } = this.model;
      const systemModules = this.store.peekAll('system-module');
      const systemModule = systemModules.findBy('ModuleConfigurationId', parseInt(moduleConfig.id, 10)); // When user changes system module config w/o any modifications in system module
      // then we have to send PUT request to system module to move its config from preview table to
      // permanent table

      if (systemModule) {
        this.send('addModifiedModuleToQueue', systemModule);
      }
    },

    /**
     * Set field value within module configuration map
     * @param {object} field - field that was changed
     * @returns {void}
     */
    _setMapValue(field) {
      const {
        configurationFlatMap
      } = this.model;
      const path = `${field.path}/${field.name}`;
      const configurationNode = configurationFlatMap.find(n => n.path === path);

      if (!configurationNode) {
        return;
      }

      Ember.set(configurationNode, 'value', field.data);
    },

    _handleDeviceVisibilityChange(value) {
      var _activeModule$originO;

      const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
      /**
       * @type ModuleDeviceVisibilityState
       */

      const visibleOnDevices = _moduleDeviceVisibilityState.default.create((_activeModule$originO = activeModule.originObject) === null || _activeModule$originO === void 0 ? void 0 : _activeModule$originO.visibleOnDevices);

      if (!visibleOnDevices || !value) {
        return;
      }

      if (visibleOnDevices.isEqual(value)) {
        return;
      }

      [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
        Ember.set(activeModule, `originObject.visibleOnDevices.${mode}`, value.includes(mode));
      });
      this.gridService.adjustModuleVisibility(this.model.moduleConfig.id);
    },

    updateFilterOptions(fieldsConfig, type, field, name, value) {
      if (field.useFilter) {
        let filterKey = Object.keys(value)[0];
        const filter = field.children.find(el => el.name === filterKey);

        if (filter.filterParams.length) {
          const queryParams = filter.filterParams.split(',').map(function (item) {
            return item.trim();
          });
          let queryString = '';

          for (let i = 0; i < queryParams.length; i++) {
            const queryParamField = field.children.find(el => el.name === queryParams[i]);
            queryString += `${queryParams[i]}=${queryParamField.data}&`;
          }

          const fieldConfigIndex = fieldsConfig.findIndex(el => el.useFilter === true);
          const filterNameIndex = this.model.formFields[fieldConfigIndex].children.findIndex(el => el.name === filter.filterName);
          this.ajaxService.requestBuilderAPI('filter', `&name=${filter.filterName}&${queryString}`).then(res => {
            Ember.set(this.model.formFields[fieldConfigIndex].children[filterNameIndex], 'choices', res.vehicles || []);
          });
        }
      }
    },

    actions: {
      /**
       * Action that sets value to module translations dictionary
       *
       * @param {TranslationKey} field - translation key that will be updated
       * @param {string} value - value to set to translation key. Passed from edit component (see field.type property)
       *
       * @returns {void}
       */
      setModuleTranslations(field, value) {
        const lng = this.model.websiteSettings.Language || _environment.default.APP.DEFAULT_LOCALE;
        const currentStrings = this.model.moduleConfig.Strings || {};
        const currentKeys = currentStrings[lng] || {};
        const fieldToSet = 'Strings';
        const valueToSet = { ...currentStrings,
          [lng]: { ...currentKeys,
            [field.name]: value
          }
        };
        this.model.moduleConfig.set(fieldToSet, valueToSet);

        this._addSystemModuleToUpdateQueue();

        this._configAutoSaveRun();

        this._addHistoryCheckpoint();
      },

      /**
       * Sets field value for module configuration and module styles
       * TODO this method should be refactored because it gets name twice
       *
       * @param {*} fieldsConfig
       * @param {*} type
       * @param {*} field
       * @param {*} name
       * @param {*} value
       * @param {*} name2
       * @param {*} actionType
       *
       * @returns {void}
       */
      setFieldValue(fieldsConfig, type, field, name, value, name2, actionType) {
        const {
          moduleConfig
        } = this.model;
        const handler = this.configurationHandler;
        this.updateFilterOptions(fieldsConfig, type, field, name, value); // Check for actionType which comes from field-text. We have to handle only of change event

        if (moduleConfig && (!actionType || actionType !== 'input')) {
          var _this$configurationSe5, _this$configurationSe6;

          const breakpoint = (_this$configurationSe5 = this.configurationService) === null || _this$configurationSe5 === void 0 ? void 0 : (_this$configurationSe6 = _this$configurationSe5.currentDevice) === null || _this$configurationSe6 === void 0 ? void 0 : _this$configurationSe6.breakpoint;

          this._addSystemModuleToUpdateQueue();

          handler.handle(type, moduleConfig, field, name, value, breakpoint);

          if (field.type === _fields.default.FORM_VARIANTS) {
            this._setSliderImageFocalPoints(field, value, name);
          } else if (field.type === _fields.default.MEDIA) {
            this._setImageFocalPoints(field, name);
          }

          if (type === 'form-configuration') {
            // Notify that changes has been made but don't need activate save button
            this.send('setChanges', 'form-module');
          } else {
            this._configAutoSaveRun();
          }

          if (name === 'visibleOnDevices') {
            this._handleDeviceVisibilityChange(value);

            this.send('setChanges', 'page');
          }

          (0, _defineFieldsVisibility.defineFieldsVisibility)(fieldsConfig);

          this._setMapValue(field); // Make sure create history checkpoint only on change, not on each input action


          if (!actionType || actionType === 'change') {
            this._addHistoryCheckpoint();
          }
        }
      },

      /**
       * Action update variable value for module and container styles
       * and generate new CSS only for module styles
       *
       * @returns {void}
       */
      updateStyles(...args) {
        const changedVariables = this.getUpdatedStyleVariables(...args);
        this.send('setChanges');
        this.send('createHistoryCheckpoint', changedVariables, false);
      },

      /**
       * Action update lock state for style variables
       *
       * @param {string} mode - current breakpoint
       * @param {string[]} variableNames - variable names whose lock state will be changed
       * @param {StyleModel} variablesModel - style model
       * @param {boolean} locked - lock state
       *
       * @returns {void}
       */
      updateLockState({
        mode,
        variableNames,
        variablesModel,
        locked
      }) {
        const styleOptions = variablesModel.StyleOptions;
        variableNames.forEach(variableName => {
          const option = styleOptions.findBy('variableName', variableName);
          const variable = variablesModel[mode].findBy('name', variableName);

          if (!option) {
            styleOptions.createFragment({
              variableName,
              locked
            });
          } else {
            option.set('locked', locked);
          }

          if (locked && variable) {
            this.populateVariableToBreakpoints(variable, variablesModel);
          }
        });
        this.send('setChanges');
      },

      /**
       * Setup form lead routing recipients
       *
       * @param {Object} field - whole structure for field component
       * @param {string} key - field name
       * @param {Object[]} value - changed array of recipients
       *
       * @returns {void}
       */
      setFormLeadsRouting(field, key, value) {
        const module = this.model.moduleConfig;

        if (module) {
          const {
            formId
          } = module.Structure;
          const form = this.store.peekRecord('form', formId);

          if (form) {
            const recipients = form.Leadroutes;
            const readOnlyRecipients = [];
            const notReadOnlyRecipients = [];

            for (let i = 0, len = recipients.length; i < len; i++) {
              if (!recipients[i].Editable) {
                readOnlyRecipients.push(recipients[i]);
              }
            }

            const uniqueCombinationHashes = {};

            for (let i = 0, len = value.length; i < len; i++) {
              const recipient = value[i];
              const fieldCombinationHash = `${recipient.ToEmail}${recipient.EmailFormat}`.toLowerCase();

              if (Ember.isEmpty(recipient.ToEmail) || uniqueCombinationHashes.hasOwnProperty(fieldCombinationHash)) {
                continue;
              }

              uniqueCombinationHashes[fieldCombinationHash] = true;
              notReadOnlyRecipients.push({
                Id: recipient.Id || null,
                ToEmail: recipient.ToEmail,
                EmailFormat: recipient.EmailFormat,
                Editable: true
              });
            }

            form.set('Leadroutes', readOnlyRecipients.concat(notReadOnlyRecipients));
            this.send('setChanges', 'form-module');
          } else {
            throw new Ember.Error(`Leadroute for form with id='${formId}' not found.`);
          }
        }
      },

      /**
       * Redirect to page
       *
       * @param {Ember.Object} page
       *
       * @returns {void}
       */
      transitionTo(page) {
        if (page && this.hasNoChangesOrDoNotNeedToBeSaved()) {
          // TODO: this must not be here, we need intermediate transition method, to correctly handle transitions
          // like close some windows, open another, etc.
          this.send('deactivateModule');
          this.transitionToRoute('website.edit.page.edit', page.id);
        }
      },

      /**
       * Action after move step
       *
       * @param {Object} options - options for handler
       * @param {number} options.oldIndex - old index moved step
       * @param {number} options.newIndex - new index moved step
       *
       * @returns {void}
       */
      stepSortingHandler({
        oldIndex,
        newIndex
      }) {
        /**
         * NOTE: we need to find active model every time we change value
         * because save operation recreates whole structure and active module component
         * is not in dom after save.
         */
        const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
        activeModule.component.moveStep(oldIndex, newIndex);
      },

      /**
       * Action after select step
       *
       * @param {Object} options - options for handler
       * @param {number} options.index - index selected step
       *
       * @returns {void}
       */
      stepSelectHandler({
        index
      }) {
        /**
         * NOTE: we need to find active model every time we change value
         * because save operation recreates whole structure and active module component
         * is not in dom after save.
         */
        const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
        activeModule.component.selectStep(index);
      },

      /**
       * Action after add new step
       *
       * @param {Object} options - options for handler
       * @param {string} options.label - label added step
       *
       * @returns {void}
       */
      stepAddNewHandler({
        label
      }) {
        /**
         * NOTE: we need to find active model every time we change value
         * because save operation recreates whole structure and active module component
         * is not in dom after save.
         */
        const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
        activeModule.component.addStep(label);
      },

      /**
       * Action after remove step
       *
       * @param {Object} options - options for handler
       * @param {number} options.index - index removed step
       *
       * @returns {void}
       */
      stepRemoveHandler({
        index
      }) {
        /**
         * NOTE: we need to find active model every time we change value
         * because save operation recreates whole structure and active module component
         * is not in dom after save.
         */
        const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
        activeModule.component.removeStep(index);
      },

      /**
       * Action after rename label in step
       *
       * @param {Object} options - options for handler
       * @param {number} options.index - index renamed step
       * @param {number} options.label - new step label
       *
       * @returns {void}
       */
      stepRenameLabelHandler({
        index,
        label
      }) {
        /**
         * NOTE: we need to find active model every time we change value
         * because save operation recreates whole structure and active module component
         * is not in dom after save.
         */
        const activeModule = this.structureService.findModuleById(this.model.moduleConfig.id);
        activeModule.component.renameStep(index, label);
      },

      /**
       *
       * @returns {void}
       */
      cancel() {
        this._resetPopupConfigStatusMessage();

        this.send('invalidateSettingsId');
        this.transitionToParent();
      }

    }
  });

  _exports.default = _default;
});