define("builder/core/styles-management/widget-container/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Part 1:
   *
   * Map for container styles variables.
   * This map is not changed during the application work.
   *
   */
  var _default = [{
    filename: 'container1.css',
    stylesmap: [{
      type: 'style',
      selector: '{moduleId}.js-container-wrapper.container__content_full-width .container',
      properties: [{
        name: 'max-width',
        value: '100%'
      }, {
        name: 'padding',
        value: '0'
      }]
    }, {
      type: 'media',
      conditions: [{
        type: 'expression',
        leftOperand: {
          type: 'operand',
          name: 'min-width',
          value: '0px'
        },
        rightOperand: {
          type: 'operand',
          name: 'max-width',
          value: '767px'
        },
        operation: 'and'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-content-div',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-xs', '$margin-right-xs', '$margin-bottom-xs', '$margin-left-xs']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-xs', '$padding-right-xs', '$padding-bottom-xs', '$padding-left-xs']
          }
        }, {
          name: 'background-size',
          value: '$background-size-xs'
        }, {
          name: 'background-position',
          value: '$background-position-xs'
        }]
      }, {
        type: 'style',
        selector: '{moduleId}.js-container-wrapper.container_full-width',
        properties: [{
          name: 'background-size',
          value: '$background-size-xs'
        }, {
          name: 'background-position',
          value: '$background-position-xs'
        }]
      }]
    }, {
      type: 'media',
      conditions: [{
        type: 'expression',
        leftOperand: {
          type: 'operand',
          name: 'min-width',
          value: '768px'
        },
        rightOperand: {
          type: 'operand',
          name: 'max-width',
          value: '991px'
        },
        operation: 'and'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-content-div',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-sm', '$margin-right-sm', '$margin-bottom-sm', '$margin-left-sm']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-sm', '$padding-right-sm', '$padding-bottom-sm', '$padding-left-sm']
          }
        }, {
          name: 'background-size',
          value: '$background-size-sm'
        }, {
          name: 'background-position',
          value: '$background-position-sm'
        }]
      }, {
        type: 'style',
        selector: '{moduleId}.js-container-wrapper.container_full-width',
        properties: [{
          name: 'background-size',
          value: '$background-size-sm'
        }, {
          name: 'background-position',
          value: '$background-position-sm'
        }]
      }]
    }, {
      type: 'media',
      conditions: [{
        type: 'expression',
        leftOperand: {
          type: 'operand',
          name: 'min-width',
          value: '992px'
        },
        rightOperand: {
          type: 'operand',
          name: 'max-width',
          value: '1199px'
        },
        operation: 'and'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-content-div',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-md', '$margin-right-md', '$margin-bottom-md', '$margin-left-md']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-md', '$padding-right-md', '$padding-bottom-md', '$padding-left-md']
          }
        }, {
          name: 'background-size',
          value: '$background-size-md'
        }, {
          name: 'background-position',
          value: '$background-position-md'
        }]
      }, {
        type: 'style',
        selector: '{moduleId}.js-container-wrapper.container_full-width',
        properties: [{
          name: 'background-size',
          value: '$background-size-md'
        }, {
          name: 'background-position',
          value: '$background-position-md'
        }]
      }]
    }, {
      type: 'media',
      conditions: [{
        type: 'operand',
        name: 'min-width',
        value: '1200px'
      }],
      children: [{
        type: 'style',
        selector: '{moduleId} .js-content-div',
        properties: [{
          name: 'margin',
          value: {
            parts: ['$margin-top-lg', '$margin-right-lg', '$margin-bottom-lg', '$margin-left-lg']
          }
        }, {
          name: 'padding',
          value: {
            parts: ['$padding-top-lg', '$padding-right-lg', '$padding-bottom-lg', '$padding-left-lg']
          }
        }, {
          name: 'background-size',
          value: '$background-size-lg'
        }, {
          name: 'background-position',
          value: '$background-position-lg'
        }]
      }, {
        type: 'style',
        selector: '{moduleId}.js-container-wrapper.container_full-width',
        properties: [{
          name: 'background-size',
          value: '$background-size-lg'
        }, {
          name: 'background-position',
          value: '$background-position-lg'
        }]
      }]
    }]
  }, {
    filename: 'container2.css',
    stylesmap: [{
      type: 'style',
      selector: '{moduleId}.js-container-wrapper.container_full-width',
      properties: [{
        name: 'max-width',
        value: '100%'
      }]
    }, {
      type: 'style',
      selector: '{moduleId}.js-container-wrapper.container_full-width',
      properties: [{
        name: 'background-color',
        value: '$background-color'
      }, {
        name: 'background-image',
        value: [{
          type: 'function',
          name: 'url',
          arguments: ['$background-image']
        }, {
          type: 'function',
          name: 'linear-gradient',
          arguments: ['$background-gradient-direction', '$background-gradient-color1', '$background-gradient-color2']
        }]
      }, {
        name: 'background-repeat',
        value: '$background-repeat'
      }, {
        name: 'background-attachment',
        value: '$background-attachment'
      }]
    }, {
      type: 'style',
      selector: '{moduleId}.js-container-wrapper.container_full-width .js-content-div',
      properties: [{
        name: 'background',
        value: 'none'
      }, {
        name: 'margin',
        value: '0'
      }]
    }, {
      type: 'style',
      selector: '{moduleId} .js-content-div',
      properties: [{
        name: 'background-color',
        value: '$background-color'
      }, {
        name: 'background-image',
        value: [{
          type: 'function',
          name: 'url',
          arguments: ['$background-image']
        }, {
          type: 'function',
          name: 'linear-gradient',
          arguments: ['$background-gradient-direction', '$background-gradient-color1', '$background-gradient-color2']
        }]
      }, {
        name: 'background-repeat',
        value: '$background-repeat'
      }, {
        name: 'background-attachment',
        value: '$background-attachment'
      }]
    }]
  }];
  _exports.default = _default;
});