define("builder/pods/components/styles-builder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property {null|String} css - styles
     */
    css: null,
    variables: null,

    /**
     * @property {String} - attribute for style tag
     */
    type: 'text/css',
    tagName: 'style',
    attributeBindings: ['type'],

    /**
     * NOTICE: Don't delete class "sm-test" it's need for acceptance tests to simple find exactly style tag
     */
    classNames: ['sm-test']
  });

  _exports.default = _default;
});