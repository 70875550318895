define("builder/validators/page-alias", ["exports", "builder/core/abstract-classes/base-ajax-validator", "builder/config/environment"], function (_exports, _baseAjaxValidator, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PageAlias = _baseAjaxValidator.default.extend({
    /**
     * @inheritdoc
     */
    ajaxOptions(value, options) {
      return {
        method: 'PUT',
        url: `${_environment.default.apiURL}/page_aliasvalidator/${options.pageId}`,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          pageAliasValidator: {
            Alias: value
          }
        })
      };
    }

  });

  var _default = PageAlias;
  _exports.default = _default;
});