define("builder/pods/components/form-variants/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "jquery", "ember-copy", "builder/core/enumerators/fields", "uid", "builder/core/visibility-filters"], function (_exports, _fieldValidation, _fieldVisibility, _jquery, _emberCopy, _fields, _uid, _visibilityFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO: remove this
   *
   * @param {Object} config - object to be modified
   * @param {Ember.Object} model - object which value will modify config
   * @param {Boolean} removeConfigData - remove config keys if not found in model
   * @returns {Object} - modified config object
   */
  function extractForm(config = {}, model = Ember.Object.create(), removeConfigData = true) {
    if (Array.isArray(config)) {
      config.forEach(item => {
        const {
          name
        } = item;

        if (name && typeof model.get(name) !== 'undefined') {
          // Workaround for field-container in form-variants, I know it's a bad way (, but we agile
          if (item.type !== _fields.default.FORM_BUTTONS && item.type !== _fields.default.FILTERS && item.hasOwnProperty('children')) {
            item.children.forEach(child => {
              const childName = child.name;
              const childModel = model.get(name);

              if (childName && childModel && typeof childModel[childName] !== 'undefined') {
                Ember.set(child, 'data', childModel[childName]);
              } else if (removeConfigData) {
                delete child.data;
              }
            });
          } else {
            Ember.set(item, 'data', model.get(name));
          }
        } else if (removeConfigData) {
          delete item.data;
        }
      });
    } else if (config) {
      Object.keys(config).forEach(key => {
        let value = model.get(key);

        if (Ember.typeOf(value) !== 'instance') {
          value = Ember.Object.create(value);
        }

        this.extractForm(config[key], value);
      });
    }

    return config;
  }

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form-variants'],

    /**
     * module configuration service
     * @type {ModuleConfigurationService}
     */
    moduleConfigurationService: Ember.inject.service('module-configuration'),

    /**
     * indicate what have one and more variants to select
     * @type {boolean}
     */
    hasSelectedVariants: Ember.computed.gte('field.data.length', 1).readOnly(),

    /**
     * indicate what have only one variant can be selected
     * @type {boolean}
     */
    hasOneVariantToSelect: Ember.computed.equal('field.children.length', 1).readOnly(),

    /**
     * state for show/hide variants selector
     * @type {boolean}
     */
    isShowDropdown: false,

    /**
     * state for show/hide variants selector
     * @type {boolean}
     */
    isVariantsListExpanded: false,

    /**
     * number of selected variants without list expanding
     * @type {number}
     */
    notExpandedVariantsCount: 5,

    /**
     * input handler to be passed to form-variant
     * @type {Function | null}
     */
    inputHandler: null,

    /**
    	* Full array of module fields with their paths
    	* @type {FieldConfigurationNode[]}
    	*/
    configurationFlatMap: [],

    /**
     * visible selected variants
     * @type {object[]}
     */
    visibleVariantsList: Ember.computed('variants', 'isVariantsListExpanded', function () {
      if (!this.isVariantsListExpanded && this.variants.length > this.notExpandedVariantsCount) {
        return this.variants.slice(0, this.notExpandedVariantsCount);
      }

      return this.variants;
    }),

    /**
     * number of selected variants more then {@link form-variants#notExpandedVariantsCount}
     * @type {number}
     */
    restExpandedVariantsCount: Ember.computed('field.data.[]', function () {
      const {
        data
      } = this.field;

      if (data && data.length) {
        return Math.max(0, data.length - this.notExpandedVariantsCount);
      }

      return 0;
    }),
    filteredVariants: Ember.computed('configurationFlatMap.@each.value', function () {
      return (0, _visibilityFilters.filterVisibleVariants)(this.configurationFlatMap, this.field);
    }),

    /**
     * list of variants to select
     * @type {object[]}
     */
    list: Ember.computed('filteredVariants', 'field.data.[]', function () {
      const {
        data
      } = this.field;
      const maxElements = this.get('field.validation.elements.max') || Number.MAX_SAFE_INTEGER;

      if (!data || data && data.length < maxElements) {
        return this.filteredVariants.filter(variant => {
          if (this.isUnique(variant) && this.isVariantAdded(variant)) {
            return false;
          }

          return true;
        }).map(child => ({
          label: child.label,
          value: child.name
        }));
      }

      return [];
    }),

    /**
     * list of selected variants
     * @type {object[]}
     */
    variants: Ember.computed('configurationFlatMap.@each.value', 'field.data.[]', function () {
      const {
        data
      } = this.field;
      const variantsCache = this._variantsCache;

      if (!data) {
        return [];
      }

      return data.map(dataLink => {
        var _dataLink$isOpened, _dataLink$isRemoveEna;

        const existingVariant = variantsCache.findBy('dataLink', dataLink); // Prevent creation new variant if variant has been created before

        if (existingVariant) {
          const {
            variant
          } = existingVariant;
          Ember.set(variant, 'isOpened', false);
          Ember.set(variant, 'isInvalid', !this.isVariantChoiceIncluded(variant));
          return variant;
        }

        let variant = (0, _emberCopy.copy)(this.field.children.findBy('name', dataLink['variant-name']), true); // TODO This variant need for custom label in settings\index\controller.js
        // find WordsGenerator.generate(data);
        // need to think how move it inside _addDynamicLabel method

        if (typeof variant.label === 'function') {
          variant = Ember.Object.extend(variant).create();
          Ember.defineProperty(variant, 'label', Ember.computed('children.@each.data', variant.label));
        } else if (variant.labelFrom) {
          this._addDynamicLabel(variant);
        }

        extractForm(variant.children, Ember.Object.create(dataLink), false);
        variant.original = dataLink;
        variant.localData = dataLink;
        variant.isOpened = (_dataLink$isOpened = dataLink === null || dataLink === void 0 ? void 0 : dataLink.isOpened) !== null && _dataLink$isOpened !== void 0 ? _dataLink$isOpened : false;
        variant.isInvalid = !this.isVariantChoiceIncluded(variant);
        variant.isRemoveEnabled = (_dataLink$isRemoveEna = dataLink === null || dataLink === void 0 ? void 0 : dataLink.isRemoveEnabled) !== null && _dataLink$isRemoveEna !== void 0 ? _dataLink$isRemoveEna : true; // Force switch on "enableValidationImmediately" for all children fields

        const variantChildren = variant.children;

        if (variantChildren && variantChildren.length) {
          for (let j = 0, len = variantChildren.length; j < len; j++) {
            const childVariant = variantChildren[j];

            if (Ember.get(childVariant, 'validation.enableValidationImmediately') === false) {
              childVariant.enableValidationImmediately = !Ember.isPresent(childVariant.data);
            } else {
              childVariant.enableValidationImmediately = true;
            }
          }
        }

        variantsCache.pushObject({
          variant,
          dataLink
        });
        return variant;
      });
    }),

    /**
     * determine ability for sort list items
     * @type {boolean}
     */
    isSortable: Ember.computed('field.sortable', function () {
      if (typeof this.field.sortable === 'undefined') {
        return true;
      }

      return this.field.sortable;
    }),
    variantsActionDecorator: Ember.computed('field.variantsActionDecorator', function () {
      var _this$field$variantsA, _this$field;

      return (_this$field$variantsA = (_this$field = this.field) === null || _this$field === void 0 ? void 0 : _this$field.variantsActionDecorator) !== null && _this$field$variantsA !== void 0 ? _this$field$variantsA : {};
    }),

    /**
     * property for force disable "Show More/Less" button
     * @type {Boolean}
     */
    disableShowMore: false,

    /**
     * cache of variants
     * @type {object[]}
     * @private
     * @readonly
     */
    _variantsCache: Ember.computed(function () {
      return [];
    }).readOnly(),

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations('field.data.[]', 'field.validation', false);
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.applySortable();
      this.attachDropdownClickHandler();
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      this.detachSortable();
      this.detachDropdownClickHandler();
    },

    /**
     * Attach mouse click handler for detect click outside dropdown
     * @returns {void}
     */
    attachDropdownClickHandler() {
      (0, _jquery.default)(document).on(`click.form-variants-${this.elementId}`, e => {
        if (!(0, _jquery.default)(e.target).closest('.js-form-variants__dropdown').length) {
          this.set('isShowDropdown', false);
        }
      });
    },

    /**
     * Detach mouse click handler for detect click outside dropdown
     * @returns {void}
     */
    detachDropdownClickHandler() {
      (0, _jquery.default)(document).off(`click.form-variants-${this.elementId}`);
    },

    /**
     * @inheritdoc
     */
    didUpdate() {
      if (this.isSortable) {
        this.$('.variants-list').sortable('refresh');
      }
    },

    /**
     * Attach sort functionality to variants list
     * @returns {void}
     */
    applySortable() {
      const variants = this;

      if (this.isSortable) {
        variants.$('.variants-list').sortable({
          items: '.bld-form-variant__inner',
          handle: '.bld-form-variant__header',
          distance: 5,
          axis: 'y',
          cursor: 'move',

          start(event, ui) {
            variants.highlightOff();
            let items = (0, _jquery.default)(this).sortable('option', 'items');
            items = variants.$(items);
            ui.item.data('oldIndex', items.index(ui.item));
          },

          stop(event, ui) {
            const sortable = (0, _jquery.default)(this);
            const items = variants.$(sortable.sortable('option', 'items'));
            const oldIndex = ui.item.data('oldIndex');
            const newIndex = items.index(ui.item);

            if (oldIndex !== newIndex) {
              sortable.sortable('cancel');
              variants.sort(oldIndex, newIndex);
              variants.triggerChange();
            }
          }

        });
      }
    },

    /**
     * Destroy sortable functionality
     * @returns {void}
     */
    detachSortable() {
      if (this.isSortable) {
        this.$('> .variants-list').sortable('destroy');
      }
    },

    /**
     * Return unique or not passed variant
     *
     * @param {object} variant - structure for variant
     * @param {undefined|boolean} variant.isUnique - determines the uniqueness for passed variant
     * @returns {boolean}
     */
    isUnique(variant) {
      if (typeof variant.isUnique === 'undefined') {
        return true;
      }

      return variant.isUnique;
    },

    /**
     * Checks is current selection includes variant or not.
     * Method is required to filter unique variants
     * @param {object} variant - variant to check
     * @returns {boolean}
     */
    isVariantAdded(variant) {
      const {
        data
      } = this.field;
      return data && data.some(value => value['variant-name'] === variant.name);
    },

    /**
     * Checks is current filtered variants array includes variant or not.
     * Method is required to mark hidden variants
     * @param {object} variant - variant to check
     * @returns {boolean}
     */
    isVariantChoiceIncluded(variant) {
      return this.filteredVariants.some(filteredVariant => filteredVariant.name === variant.name);
    },

    /**
     * Apply sort for items after drag&drop operation
     *
     * @param {Number} oldIndex - old index
     * @param {Number} newIndex - new index
     *
     * @returns {void}
     */
    sort(oldIndex, newIndex) {
      const {
        data
      } = this.field;
      const item = data.objectAt(oldIndex);
      data.removeAt(oldIndex);
      data.insertAt(newIndex, item);
      this.highlightOff();
    },

    /**
     * Add variant data to field.data array
     *
     * @param {object} variant - structure represented selected variant to add
     * @returns {void}
     */
    addData(variant) {
      if (!this.field.data) {
        this.set('field.data', []);
      }

      const variantData = Ember.assign({
        'variant-name': variant.name,
        createdAt: Date.now(),
        id: (0, _uid.default)()
      }, this.moduleConfigurationService.convertStructureToData(variant.children || []));
      this.field.data.pushObject(variantData);
    },

    /**
     * Remove variant data from field.data array
     *
     * @param {object} variant - structure represented selected variant to remove
     * @returns {void}
     */
    removeData(variant) {
      this.field.data.removeObject(variant.localData);
      this.set('field.immediateUniqueValidationHash', Date.now());
      this.triggerChange();
    },

    /**
     * Trigger change event
     * @param {string|undefined} actionType - Type of the child action (see field-text)
     * @returns {void}
     */
    triggerChange(actionType) {
      if (this.actionHandler && !this.notValid) {
        this.actionHandler(this.field.data, this.field.name, actionType);
      }
    },

    /**
     * Highlight last added variant
     *
     * @returns {void}
     */
    highlightLast() {
      this.set('variants.lastObject.isNew', true);
    },

    /**
     * Highlight off all added variants
     *
     * @returns {void}
     */
    highlightOff() {
      const {
        variants
      } = this;

      for (let i = 0; i < variants.length; i++) {
        Ember.set(variants[i], 'isNew', false);
      }
    },

    /**
     * Scroll container of variants to last variant
     *
     * @returns {void}
     */
    scrollIntoLastVariant() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        const lastElement = this.$('.variants-list .bld-form-variant').last().get(0);

        if (lastElement && typeof lastElement.scrollIntoView === 'function') {
          lastElement.scrollIntoView(false);
        }
      });
    },

    /**
     * Add dynamic label to form variant
     *
     * @param {object} variant - variant in which you need to add
     * @private
     * @returns {void}
     */
    _addDynamicLabel(variant) {
      const originalLabel = variant.label;

      const setFormVariantLabel = function () {
        Ember.set(variant, 'label', this.data || originalLabel);
      };

      const labelFrom = variant.labelFrom.split('.');
      let levelParent = null;
      let level = null;

      for (let i = 0; i < labelFrom.length; i++) {
        levelParent = i === 0 ? variant : level;

        if (!levelParent || !levelParent.children) {
          levelParent = null;
          break;
        }

        level = levelParent.children.findBy('name', labelFrom[i]);
      }

      if (levelParent && level) {
        const newLevel = Ember.Object.extend(level).create();
        newLevel.reopen({
          labelFromHelper: Ember.observer('data', function () {
            setFormVariantLabel.call(this);
          })
        });
        levelParent.children.replace(levelParent.children.indexOf(level), 1, [newLevel]);
        setFormVariantLabel.call(newLevel);
      }
    },

    actions: {
      addVariant(name) {
        if (!name || !name.trim()) {
          return;
        }

        let variant = this.field.children.findBy('name', name);
        this.highlightOff();
        variant = (0, _emberCopy.copy)(variant, true);
        variant.isOpened = false;
        this.addData(variant);
        this.triggerChange();
        this.set('isShowDropdown', false);

        if (this.variants.length > this.notExpandedVariantsCount) {
          this.set('isVariantsListExpanded', true);
        }

        this.highlightLast();
        this.scrollIntoLastVariant();
      },

      toggleVariant(variant) {
        var _this$variantsActionD;

        if ((_this$variantsActionD = this.variantsActionDecorator) === null || _this$variantsActionD === void 0 ? void 0 : _this$variantsActionD.toggleVariant) {
          this.variantsActionDecorator.toggleVariant(variant);
        }

        const {
          variants
        } = this;

        for (let i = 0, len = variants.length; i < len; i++) {
          Ember.set(variants[i], 'isOpened', variant === variants[i] && !variant.isOpened);
        }

        this.highlightOff();
      },

      removeVariant(variant) {
        var _this$variantsActionD2;

        let removeEnabled = true;

        if ((_this$variantsActionD2 = this.variantsActionDecorator) === null || _this$variantsActionD2 === void 0 ? void 0 : _this$variantsActionD2.removeVariant) {
          removeEnabled = this.variantsActionDecorator.removeVariant(variant);
        }

        if (!removeEnabled) {
          return;
        }

        this.removeData(variant);
        this.highlightOff();
      },

      childrenHandler(variant, data, name, actionType) {
        const child = variant.children.findBy('name', name);
        const notFormButtons = child.type !== _fields.default.FORM_BUTTONS;
        const notFilters = child.type !== _fields.default.FILTERS;
        const hasChildren = child.children && child.children.length >= 0; // Workaround for field-container in form-variants, I know it's a bad way (, but we agile

        if (notFormButtons && notFilters && hasChildren) {
          // There are cases when we provide array as initial value for variant property
          if (Array.isArray(variant.localData[name]) && variant.localData[name].length === 0) {
            Ember.set(variant.localData, name, data);
          } else {
            const updateData = variant.localData[name] ? { ...variant.localData[name],
              ...data
            } : data;
            Ember.set(variant.localData, name, updateData);
          }
        } else {
          Ember.set(variant.localData, name, data);
        }

        this.set('field.immediateUniqueValidationHash', Date.now());

        if (!this.notValid) {
          this.triggerChange(actionType);
        }
      },

      addOneVariant() {
        var _this$variantsActionD3;

        const [variant] = this.field.children;

        if ((_this$variantsActionD3 = this.variantsActionDecorator) === null || _this$variantsActionD3 === void 0 ? void 0 : _this$variantsActionD3.addOneVariant) {
          this.variantsActionDecorator.addOneVariant(variant);
        }

        if (this.list.length && variant) {
          this.send('addVariant', variant.name);
        }
      },

      toggleDropdown() {
        this.toggleProperty('isShowDropdown');
      },

      collapseVariantsList() {
        this.set('isVariantsListExpanded', false);
      },

      expandVariantsList() {
        this.set('isVariantsListExpanded', true);
      },

      /**
       * Action called after each variant validation
       *
       * @param {Object} variant - structure represent variant data
       * @param {Boolean} isValid - indicate what variant valid or not
       */
      validationHandler(variant, isValid) {
        const {
          variants
        } = this;
        Ember.set(variant, 'isInvalid', !isValid || !this.isVariantChoiceIncluded(variant));
        this.set('disableShowMore', false); // Definition of disable "Show More/Less" button

        for (let i = variants.length - 1; i >= 0; i--) {
          if (variants[i].isInvalid && i >= this.notExpandedVariantsCount) {
            this.send('expandVariantsList');
            this.set('disableShowMore', true);
            break;
          }
        }
      }

    }
  });

  _exports.default = _default;
});