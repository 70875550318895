define("builder/services/form-validator", ["exports", "builder/config/environment", "builder/core/enumerators/cookie", "builder/core/form/utils", "builder/errors/form-validation-error", "builder/core/enumerators/email-format"], function (_exports, _environment, _cookie, _utils, _formValidationError, _emailFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormValidatorService
   */
  var _default = Ember.Service.extend({
    /**
     * Ajax Service
     * @type {AjaxService}
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Company and website service
     * @type {CompanyAndWebsiteService}
     */
    companyAndWebsiteService: Ember.inject.service('company-and-website'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Url to validator endpoint
     * @type {string}
     * @readonly
     */
    validatorUrl: Ember.computed(function () {
      return `${_environment.default.apiURL}/form_validator`;
    }).readOnly(),

    /**
     * Validate form
     * @param {WebsiteModel} website - website for
     * @param {PageModel} page - page for
     * @param {{inLayout:boolean, model:FormModel}[]} forms - forms on page to validate
     * @returns {Promise<Error|true>}
     */
    async validate(website, page, forms) {
      const successResult = Ember.RSVP.resolve({
        warnings: []
      });

      if (!forms.length) {
        return successResult;
      }

      const request = this.createRequest(website, page, forms);

      try {
        await this.sendRequest(request);
        return successResult;
      } catch (error) {
        if (!(error instanceof _formValidationError.default) || error.errors.length) {
          return Ember.RSVP.reject(error);
        }

        return Ember.RSVP.resolve({
          warnings: error.warnings
        });
      }
    },

    /**
     * Prepare form validation model
     * @param {{inLayout:boolean, model:FormModel}} form - form info
     * @returns {FormValidationModel}
     */
    createFormValidationModel(form) {
      const {
        model,
        inLayout
      } = form;
      const formId = (0, _utils.isTemporaryFormId)(model.id) ? 0 : parseInt(model.id, 10);
      const adfRecipients = model.Leadroutes.filterBy('EmailFormat', _emailFormat.default.ADF_XML).map(recipient => recipient.ToEmail);
      return {
        id: formId,
        type: model.FormType,
        title: model.Title,
        encrypted: model.IsEncrypted === '1',
        structure: JSON.stringify(model.Structure),
        adf_recipients: adfRecipients,
        is_in_layout: inLayout
      };
    },

    /**
     * Create validation request
     * @param {WebsiteModel} website - website for
     * @param {PageModel} page - page for
     * @param {{inLayout:boolean, model:FormModel}[]} forms - forms on page to validate
     * @returns {FormValidationRequest}
     */
    createRequest(website, page, forms) {
      return {
        forms: forms.map(this.createFormValidationModel),
        website_id: parseInt(website.id, 10),
        page: {
          type: page.Type,
          dependency: page.RequiredParam || '',
          variables: page.TypeStructure,
          is_popup: page.isPopup
        }
      };
    },

    /**
     * Prepare and send request to validator
     * @param {FormValidationModel[]} forms - forms
     * @returns {Promise<void>}
     */
    async sendRequest(request) {
      const params = [this.companyAndWebsiteService.getCompanyWebsiteParams(), `${_cookie.default.SESSION_ID}=${this.configurationService.sessionId}`].join('&');
      return this.ajaxService.request(`${this.validatorUrl}?${params}`, {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(request)
      });
    }

  });

  _exports.default = _default;
});