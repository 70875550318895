define("builder/pods/components/form-module/fieldset-drop-container", ["exports", "builder/pods/components/form-module/drop-container", "builder/pods/components/form-module/column-drop-container", "builder/pods/components/form-module/row-drop-container"], function (_exports, _dropContainer, _columnDropContainer, _rowDropContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Rule set of fieldset-drop-container
   */
  var _default = _dropContainer.default.extend({
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('rowContainer', _rowDropContainer.default.create());
      this.set('columnContainer', _columnDropContainer.default.create());
      this.set('allowed', ['field', 'row']);
    },

    /**
     * @inheritdoc
     */
    dropAllowed(fieldset, draggable, anchor, position, distance) {
      if (this.allowed.indexOf(draggable.get('type')) === -1) {
        return false;
      }

      if (draggable === anchor) {
        return false;
      } // When we want to drop above or below of fieldset, since it's droppable too, here is another limit


      if (distance < this.get('tolerance.y') / 2 && (position === 'top' || position === 'bottom')) {
        return false;
      }

      switch (position) {
        case 'top':
          {
            return true;
          }

        case 'right':
          {
            return anchor && draggable.get('type') === anchor.get('type');
          }

        case 'bottom':
          {
            return true;
          }

        case 'left':
          {
            return anchor && draggable.get('type') === anchor.get('type');
          }

        default:
          {
            return false;
          }
      }
    },

    /**
     * @inheritdoc
     */
    getDropPosition(fieldset, draggable, anchor, target, x, y) {
      let position = {
        position: 'bottom',
        distance: 0
      };
      const coordinator = this._coordinator;
      let anchorEl = anchor;

      if (!anchorEl) {
        anchorEl = fieldset;
      }

      if (anchorEl) {
        const wrapper = anchorEl.get('wrapper')[0];
        const vertical = coordinator.getVerticalPosition(wrapper, target, x, y);
        position = vertical; // Only items with the same type are allowed to be dropped left or right to each other
        // TODO: type comparison shouldn't be here, refactor

        if (vertical.distance > this.get('tolerance.y') && anchorEl.get('type') === draggable.get('type')) {
          const horizontal = coordinator.getHorizontalPosition(wrapper, target, x, y);

          if (horizontal.distance <= this.get('tolerance.x')) {
            position = horizontal;
          }
        }
      }

      return position;
    }

  });

  _exports.default = _default;
});