define("builder/pods/components/button-global-visibility/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zr/BjG6h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"page_edit_tools_action\"],[3,\"on\",[\"click\",[23,0,[\"onButtonClick\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isGlobalVisibilityEnabled\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"use\",true],[10,\"xlink:href\",\"#icon-eye-closed\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[1,[23,0,[\"literals\",\"GLOBAL_VISIBILITY_HIDE\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"use\",true],[10,\"xlink:href\",\"#icon-eye\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[1,[23,0,[\"literals\",\"GLOBAL_VISIBILITY_SHOW\"]],false],[9],[0,\"\\t\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/button-global-visibility/template.hbs"
    }
  });

  _exports.default = _default;
});