define("builder/instance-initializers/auth-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    const authManager = applicationInstance.lookup('service:auth-manager');
    const ajax = applicationInstance.lookup('service:ajax');
    authManager.set('ssoClient', appBootstrap.ssoClient);
    authManager.set('ssoResponse', appBootstrap.ssoResponse);
    ajax.set('ssoClient', appBootstrap.ssoClient);
  }

  var _default = {
    name: 'auth-manager',
    initialize
  };
  _exports.default = _default;
});