define("builder/pods/components/dropdown-dropable/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @usage see in "dropdown-container"
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['b2-dropdown-dropable'],

    /**
     * @inheritDoc
     */
    attributeBindings: ['isExpanded:b2-dropdown-opened'],

    /**
     * @property {null|Component} manager - In this property passed "dropdown-container" component
     */
    manager: null,

    /**
     * add delay on hover method in show/hide state
     * @type {Object|null}
     * @private
     */
    delay: null,

    /**
     * @property {boolean} isExpanded - Property to set component in show/hide state
     */
    isExpanded: Ember.computed('manager.isExpanded', function () {
      const {
        isExpanded
      } = this.manager;
      const {
        autoPosition
      } = this.manager;

      if (!this.$()) {
        return isExpanded;
      }

      if (isExpanded) {
        const delayTime = this.manager.delay;
        this.delay = Ember.run.later(this, () => {
          this._toggle(isExpanded);
        }, delayTime);
      } else {
        Ember.run.cancel(this.delay);

        this._toggle(isExpanded);
      }

      if (autoPosition) {
        this._positionDropdown();
      }

      return isExpanded;
    }),

    /**
     * @inheritDoc
     */
    didInsertElement() {
      const {
        manager
      } = this;

      if (manager.get('autoPosition')) {
        window.addEventListener('resize', Ember.run.bind(this, this._positionDropdown));
      }
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      this._super();

      Ember.run.cancel(this.delay);
    },

    /**
     * Method position dropable uses "dropdown-button" component cords
     *
     * @private
     */
    _positionDropdown() {
      const dropable = this.$();
      const button = this.get('manager.button');
      const width = window.innerWidth;

      if (dropable && button) {
        const dropableWidth = dropable.outerWidth();
        const buttonCords = button.position();
        const buttonOffsetCords = button.offset();
        const height = button.outerHeight();

        if (width < buttonOffsetCords.left + dropableWidth) {
          // Left side cords
          dropable.css({
            top: height,
            left: buttonCords.left - Math.abs(button.outerWidth() - dropableWidth)
          });
        } else {
          // Right side cords
          dropable.css({
            top: height,
            left: buttonCords.left
          });
        }
      }
    },

    /**
     * Method change component state in show/hide
     *
     * @param {boolean} isShow
     * @private
     */
    _toggle(isShow) {
      this.$().css('display', isShow ? 'block' : 'none');
    }

  });

  _exports.default = _default;
});