define("builder/pods/components/field-link/component", ["exports", "jquery", "builder/core/convertors", "builder/mixins/field-visibility"], function (_exports, _jquery, _convertors, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component used to set link to internal page or external resource
   */
  var _default = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_link'],

    /**
     * Field data to render
     * @type {{data:Object}}
     */
    field: null,

    /**
     * Show or hide field information component
     * @type {boolean}
     */
    noInfo: false,

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * configuration service
     * @type {PageAnchorsService}
     */
    pageAnchorsService: Ember.inject.service('page-anchors'),

    /**
     * Show advanced options block or not
     * @type {boolean}
     */
    showAdvancedOptions: false,

    /**
     * Is custom select element for switching internal/external type is expanded
     * @type {boolean}
     */
    expanded: false,

    /**
     * Is list of internal pages expanded or not
     * @type {boolean}
     */
    menuIsOpened: false,

    /**
     * Id of selected menu item
     * @type {null | number}
     */
    menuItemId: null,

    /**
     * Message for error tooltip
     * @type {null | string}
     */
    errorMessage: null,

    /**
     * Item type
     * @type {null | string}
     */
    type: null,

    /**
     * Item target
     * @type {null | string}
     */
    target: null,

    /**
     * Error message for internal link
     * @type {string}
     */
    internalErrorMessage: 'You should select page',

    /**
     * Error message for external link
     * @type {string}
     */
    externalErrorMessage: 'This external link is invalid\nCorrect example: http://google.com',

    /**
     * Object with params for external link field configuration
     * @type {null | ConfigurationField}
     */
    externalField: null,

    /**
     * Params field configuration
     * @type {null | ConfigurationField}
     */
    paramsField: null,

    /**
     * Custom attribute field configuration
     * @type {null | ConfigurationField}
     */
    customAttributeField: null,

    /**
     * Anchor field configuration
     * @type {null | ConfigurationField}
     */
    anchorField: Ember.computed('field.data.anchor', 'anchors', function () {
      return {
        label: 'Anchor',
        data: this.get('field.data.anchor'),
        name: 'link-anchor',
        choices: this.anchors.then(anchors => {
          return anchors.map(anchor => ({
            label: anchor,
            value: anchor
          }));
        }).catch(error => {
          console.log('anchors not found');
        })
      };
    }),

    /**
     * Required link url or not
     * @type {boolean}
     */
    required: Ember.computed('field.validation.required', function () {
      return !!this.get('field.validation.required');
    }),

    /**
     * Object with params for internal link field
     * @type {Object}
     */
    internalField: Ember.computed('menuItemId', 'configurationService.mainMenu.Structure.@each.{isDelete,isMissingPage}', 'configurationService.unlinkedMenu.Structure.@each.{isDelete,isMissingPage}', function () {
      const menuItem = this._findMenuItemById(this.menuItemId);

      const result = {
        data: null,
        title: null
      };

      if (menuItem) {
        if (!menuItem.isDelete && !menuItem.isMissingPage) {
          result.title = menuItem.title;
        }

        result.data = menuItem.link.value;
      }

      return result;
    }),

    /**
     * Property set max-height for dropdown internal link menu
     * @type {Number}
     */
    openedMenuMaxHeight: Ember.computed('internal', 'menuIsOpened', function () {
      if (this.menuIsOpened) {
        const $internalContainer = (0, _jquery.default)('.bld-form_link_internal');
        const $dropdown = $internalContainer.find('.bld-form_link_internal_dropdown');
        const height = (0, _jquery.default)(window).height() - $internalContainer.offset().top - $internalContainer.height() - 2;
        $dropdown.css('maxHeight', '');

        if (height < parseFloat($dropdown.css('maxHeight')) && height > parseFloat($dropdown.css('minHeight'))) {
          return `max-height: ${height}px`;
        }
      }

      return null;
    }),

    /**
     * Is available link types are internal and external
     * @type {boolean}
     */
    combo: Ember.computed('field.availableType', function () {
      return !this.field.hasOwnProperty('availableType') || this.field.availableType === 'both';
    }),

    /**
     * Data prepared for field-select component
     * @type {{value: string, label: string, checked: boolean}[]}
     */
    linkOptions: Ember.computed('target', function () {
      return [{
        value: 'self',
        label: 'Current Window',
        checked: this.target === 'self'
      }, {
        value: 'tab',
        label: 'New Tab',
        checked: this.target === 'tab'
      }, {
        value: 'popup',
        label: 'Popup',
        checked: this.target === 'popup'
      }];
    }),

    /**
     * Params as string
     * @type {string}
     */
    params: Ember.computed('field.data.params', function () {
      return (0, _convertors.convertObjectToStringParams)(this.get('field.data.params'));
    }),

    /**
     * Is current link type is internal
     * @type {boolean}
     */
    internal: Ember.computed('type', function () {
      const isInternal = this.type === 'internal'; // TODO Do not set other props inside computed GET(!) property.

      this.set('paramsField.readOnly', !isInternal);
      return isInternal;
    }),

    /**
     * Anchor list
     * @type {string[]}
     * @readonly
     */
    anchors: Ember.computed('internal', 'menuItemId', async function () {
      if (!this.internal) {
        return [];
      }

      const menuItem = this._findMenuItemById(this.menuItemId);

      const pageAlias = menuItem ? menuItem.link.value : '';
      return await this.pageAnchorsService.getAnchorsListByPageAlias(pageAlias);
    }).readOnly(),

    /**
     * Find page menu item by alias
     * @param {string} alias - page alias
     * @returns {MenuChildModel}
     * @private
     */
    _findPageMenuItemByAlias(alias) {
      const {
        mainMenu,
        unlinkedMenu
      } = this.configurationService;
      return mainMenu.findPageByAlias(alias) || unlinkedMenu.findPageByAlias(alias);
    },

    /**
     * Find menu item by id
     * @param {number} menuItemId - item id
     * @returns {MenuChildModel}
     * @private
     */
    _findMenuItemById(menuItemId) {
      const {
        mainMenu,
        unlinkedMenu
      } = this.configurationService;
      return mainMenu.findItemById(menuItemId) || unlinkedMenu.findItemById(menuItemId);
    },

    /**
     * This method set and send new data
     *
     * @private
     * @returns {void}
     */
    _updateState() {
      const value = this.internal ? this.get('internalField.data') : this.get('externalField.data');
      this.set('menuIsOpened', false);

      if (value) {
        this._applyCurrentValue(value);

        return;
      }

      if (!this.required && this.get('field.data.value')) {
        this._applyDefaultValue();
      }
    },

    /**
     * applies default value to link
     * @returns {void}
     */
    _applyDefaultValue() {
      const data = {
        target: 'self',
        type: 'internal',
        value: '',
        menuItemId: null
      };
      this.set('field.data', data);

      if (this.actionHandler) {
        this.actionHandler(data, this.get('field.name'));
      }
    },

    /**
     * Sets value to field link model
     * @param {string} value - value to set to field
     */
    _applyCurrentValue(value) {
      const newFieldLinkDataValue = {
        target: this.target,
        type: this.type,
        menuItemId: this.internal ? this.menuItemId : null,
        value: this.errorMessage ? this.get('field.data.value') : value
      };

      if (this.customAttributeField.data) {
        newFieldLinkDataValue.customAttribute = this.customAttributeField.data;
      }

      if (this.anchorField && this.anchorField.data) {
        newFieldLinkDataValue.anchor = this.anchorField.data;
      }

      const params = (0, _convertors.convertStringParamsToObject)(this.get('paramsField.data'));

      if (this.internal && Object.keys(params).length) {
        newFieldLinkDataValue.params = params;
      }

      this.set('field.data', newFieldLinkDataValue);

      if (this.actionHandler) {
        this.actionHandler(newFieldLinkDataValue, this.field.name);
      }
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      const fieldData = this.get('field.data') || {};
      const {
        value,
        type,
        target,
        customAttribute
      } = fieldData;
      let {
        menuItemId
      } = fieldData;
      this.set('target', target || 'self');
      this.set('type', type || (/internal|both/.test(this.field.availableType) ? 'internal' : 'external'));
      this.set('paramsField', {
        data: this.params,
        label: 'URL Parameters',
        validation: {
          pattern: '/^([a-z[\\]][a-zA-Z0-9[\\]]*=[^&?]+(&[a-z[\\]][a-zA-Z0-9[\\]]*=[^&?]+)*)?$/',
          message: 'This field is invalid\nCorrect example: aS=b&a1S=13'
        },
        readOnly: null
      });
      this.set('externalField', {
        data: !this.internal && value ? value : '',
        enableValidationImmediately: true,
        validation: {
          pattern: `/^(((tel|mailto|sms):\\s*\\S+)|(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,63}\\.[a-z]{2,63}\\S*))${this.required ? '' : '?'}$/`,
          message: this.externalErrorMessage
        }
      });
      this.set('customAttributeField', {
        label: 'Custom Attribute',
        data: customAttribute || '',
        validation: {
          length: {
            max: 255
          }
        }
      }); // Workaround for case that alias is set in theme widget/module but menuItemId not provided.

      if (this.internal && value && !this.menuItemId) {
        const menuItem = this._findPageMenuItemByAlias(value);

        if (menuItem) {
          menuItemId = menuItem.itemId;
        }
      }

      this.set('menuItemId', menuItemId || null);

      if (this.required && this.internal && !this.menuItemId) {
        this.set('errorMessage', this.internalErrorMessage);
      }
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const link = this;
      (0, _jquery.default)('body').on(`click.${this.elementId}`, event => {
        const target = (0, _jquery.default)(event.target);

        const checkTarget = className => this.$() && (!target.closest(`.${className}`).length || !target.closest(`#${this.$().attr('id')}`).length);

        if (this.expanded && checkTarget('bld-form_link_switcher')) {
          link.set('expanded', false);
        }

        if (this.menuIsOpened && checkTarget('bld-form_link_internal')) {
          this.set('menuIsOpened', false);
        }
      });
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      (0, _jquery.default)('body').off(`click.${this.elementId}`);
    },

    actions: {
      /**
       * Toggle advanced options block visibility
       *
       * @param {boolean} [state=null] - show or hide advanced options, if not passed will inverse current value
       * @returns {void}
       */
      toggleShowAdvancedOptions(state = null) {
        this.set('showAdvancedOptions', state === null ? !this.showAdvancedOptions : state);
      },

      /**
       * Toggle internal menu
       * @returns {void}
       */
      showInternalMenu() {
        this.toggleProperty('menuIsOpened');
      },

      /**
       * Toggle dropdown with select internal/external link
       * @returns {void}
       */
      showOptions() {
        this.toggleProperty('expanded');
      },

      /**
       * Update target value of link
       *
       * @param {string} target - new target to set
       * @returns {void}
       */
      changeTarget(target) {
        if (target !== this.target) {
          this.set('target', target);

          this._updateState();
        }
      },

      /**
       * Action invoked when user selects menu item
       *
       * @param {MenuChildModel} item - menu child model
       * @returns {void}
       */
      onSelectMenuItem(item) {
        if (!item.get('isDelete') && item.get('itemId') !== this.menuItemId) {
          this.set('menuItemId', item.get('itemId'));
          this.set('errorMessage', null);
          this.toggleProperty('menuIsOpened');

          this._updateState();
        }
      },

      /**
       * Action set type to internal/external
       *
       * @param {string} type - type item, must be 'internal' or 'external'
       * @returns {void}
       */
      changeType(type) {
        if (this.type !== type) {
          this.set('type', type);
          this.set('showAdvancedOptions', false);
          this.set('expanded', false);
          this.set('errorMessage', null);

          if (this.internal) {
            if (this.required && !this.menuItemId) {
              this.set('errorMessage', this.internalErrorMessage);
            }
          }

          this._updateState();
        }
      },

      /**
       * Action clear data for current item
       *
       * @returns {void}
       */
      clearData() {
        this.set('target', 'self');
        this.set('paramsField.data', '');
        this.set('customAttributeField.data', '');
        this.set('anchorField.data', '');

        if (this.internal) {
          this.set('menuItemId', null);
        } else {
          this.set('externalField.data', '');
        }

        if (this.required) {
          this.set('errorMessage', this.internal ? this.internalErrorMessage : this.externalErrorMessage);
        } else {
          this._updateState();

          this.set('errorMessage', null);
        }
      },

      /**
       * Action invoked when user inputs something in external input
       *
       * @returns {void}
       */
      inputExternal() {
        Ember.run.schedule('actions', this, function () {
          this.set('errorMessage', null);
        });
      },

      /**
       * Action invoked when user end input in external input
       *
       * @param {string} value - input value
       * @param {string} fieldName - field name
       * @param {string} actionType - action type
       *
       * @returns {void}
       */
      actionHandler(value, fieldName, actionType) {
        if (actionType === 'input') {
          this.set('errorMessage', null);

          if (value !== this.get('field.data.value')) {
            this._updateState();
          }
        }
      },

      /**
       * Action invoked when user end input in params field
       *
       * @returns {void}
       */
      actionParamsHandler() {
        if (this.internal && this.params !== this.get('paramsField.data')) {
          this._updateState();
        }
      },

      /**
       * Action invoked when user end input in custom attribute field
       *
       * @returns {void}
       */
      actionCustomAttributeHandler() {
        this._updateState();
      },

      /**
       * Action invoked when user changes data within anchor field
       *
       * @returns {void}
       */
      actionAnchorHandler(value) {
        this.set('anchorField.data', value);

        this._updateState();
      },

      /**
       * Action invoked when user input no valid value in external input
       * @param {string} value - input value
       * @param {string} fieldName - field name
       * @param {string} errorMessage - error message
       *
       * @returns {void}
       */
      clearExternal(value, fieldName, errorMessage) {
        Ember.run.schedule('actions', this, function () {
          this.set('errorMessage', errorMessage);
        });
      }

    }
  });

  _exports.default = _default;
});