define("builder/pods/components/widget-button/component", ["exports", "builder/core/widget", "builder/core/enumerators/module-action"], function (_exports, _widget, _moduleAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widget.default.extend({
    /**
     * @property {String[]} classNames - Component class names
     */
    classNames: ['page_module'],

    /**
     * @property {Object} sizeClassMap - size class lookup table
     * @readonly
     */
    sizeClassMap: Ember.computed(function () {
      return {
        default: '',
        xl: 'btn-xl',
        lg: 'btn-lg',
        sm: 'btn-sm'
      };
    }).readOnly(),

    /**
     * @property {String} alignClass - Css class for button container align
     */
    alignClass: Ember.computed('data.originObject.aligned', function () {
      switch (this.get('data.originObject.aligned')) {
        case 'right':
          return 'text-right';

        case 'center':
          return 'text-center';

        default:
          return 'text-left';
      }
    }),

    /**
     * @property {String} sizeClass - class that corresponds to size set
     */
    sizeClass: Ember.computed('data.originObject.size', function () {
      const size = this.get('data.originObject.size') || 'default';
      return this.get(`sizeClassMap.${size}`);
    }),
    contentLayout: Ember.computed('data.originObject.buttonTextLayout', function () {
      var _this$data, _this$data$originObje;

      // button has horizontal layout by default
      const textLayout = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : _this$data$originObje.buttonTextLayout;

      if (!textLayout) {
        return 'horizontal';
      }

      return textLayout;
    }),

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    },

    /**
     * Double click handler
     *
     * @method
     * @returns {*}
     */
    doubleClick(...args) {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return this._super(...args);
    },

    /**
     * Activate module
     *
     * @method
     * @returns {*}
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    }

  });

  _exports.default = _default;
});