define("builder/core/cleaners/column", ["exports", "builder/core/cleaners/base-cleaner", "builder/core/enumerators/container", "builder/core/enumerators/widget", "builder/core/enumerators/module"], function (_exports, _baseCleaner, _container, _widget, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseCleaner.default.extend({
    /**
     * Cleanup widget-column, if it has the only child, that has the only child
     * widget-column -> children(1 child total) -> children(1 child total).
     *
     * @inheritdoc
     */
    canCleanup(node) {
      var _node$originObject, _node$originObject2, _child$originObject;

      const children = (node === null || node === void 0 ? void 0 : (_node$originObject = node.originObject) === null || _node$originObject === void 0 ? void 0 : _node$originObject.children) || [];

      if (!children.length) {
        return false;
      }

      const child = children[0]; // Current cleanuper specializes only on widget-columns

      if (_container.default.WIDGET_COLUMN !== (node === null || node === void 0 ? void 0 : (_node$originObject2 = node.originObject) === null || _node$originObject2 === void 0 ? void 0 : _node$originObject2.type)) {
        return false;
      }

      const grandChildren = (child === null || child === void 0 ? void 0 : (_child$originObject = child.originObject) === null || _child$originObject === void 0 ? void 0 : _child$originObject.children) || [];
      return children.length === 1 && !this.isDraggable(child) && grandChildren.length === 1;
    },

    /**
     * @returns {boolean}
     */
    isDraggable(gridObject) {
      var _gridObject$originObj, _gridObject$originObj2;

      const type = (_gridObject$originObj = gridObject === null || gridObject === void 0 ? void 0 : (_gridObject$originObj2 = gridObject.originObject) === null || _gridObject$originObj2 === void 0 ? void 0 : _gridObject$originObj2.type) !== null && _gridObject$originObj !== void 0 ? _gridObject$originObj : null;
      return type && [_widget.WidgetType.WIDGET_PARAGRAPH, _widget.WidgetType.WIDGET_IMAGE, _widget.WidgetType.WIDGET_BUTTON, _widget.WidgetType.WIDGET_BUTTONSGROUP, _widget.WidgetType.WIDGET_SPACER, _widget.WidgetType.WIDGET_SECTION, _container.default.WIDGET_TABS, _module.default.MODULE_CORE, _module.default.MODULE_FORM].includes(type);
    },

    /**
     * @inheritdoc
     */
    doCleanup(structure, node) {
      const children = node.get('originObject').get('children');

      do {
        const child = children ? children[0] : null;
        const grandChild = child && child.get('originObject').get('children') ? child.get('originObject').get('children')[0] : null; // Move child up

        if (grandChild) {
          structure.addChild(node, grandChild);
          structure.removeChild(child);
        }
      } while (this.canCleanup(node));
    }

  });

  _exports.default = _default;
});