define("builder/models/website-setting", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WebsiteSettingModel
   */
  var _default = _model.default.extend({
    /**
     * @type {string} DealerName - dealer name
     */
    DealerName: (0, _model.attr)('string'),

    /**
     * @type {string} DealerMainPhone - dealer main phone
     */
    DealerMainPhone: (0, _model.attr)('string'),

    /**
     * @type {string} DealerSalesPhone - dealer sales phone
     */
    DealerSalesPhone: (0, _model.attr)('string'),

    /**
     * @type {string} DealerServicePhone - dealer service phone
     */
    DealerServicePhone: (0, _model.attr)('string'),

    /**
     * @type {string} DealerPartsPhone - dealer parts phone
     */
    DealerPartsPhone: (0, _model.attr)('string'),

    /**
     * @type {string} Favicon - website favicon
     */
    Favicon: (0, _model.attr)('string'),

    /**
     * @type {string} Language - website language
     */
    Language: (0, _model.attr)('string'),

    /**
     * @type {number} CanonicalEnabled - whether canonical url enabled
     */
    CanonicalUrlEnabled: (0, _model.attr)('number'),

    /**
     * @type {Boolean} IsIESupported - whether IE support is enabled
     */
    IsIESupported: (0, _model.attr)('boolean'),

    /* * Form field error message position
     * @type {string}
     */
    FormFieldErrorMessagePosition: (0, _model.attr)('string'),

    /**
     * Form field help message position
     * @type {string}
     */
    FormFieldHelpMessagePosition: (0, _model.attr)('string'),

    /**
     * Page Modal Type
     * @type {String}
     */
    PageModalType: (0, _model.attr)('string'),

    /**
     * Form field label position
     * @type {string}
     */
    FormFieldLabelPosition: (0, _model.attr)('string'),

    /**
     * Show form field label as a placeholder or not
     * @type {string}
     */
    FormFieldShowLabelAsPlaceholder: (0, _model.attr)('string'),

    /**
     * Show form field label as a placeholder or not
     * @type {string}
     */
    CustomCSSString: (0, _model.attr)('string')
  });

  _exports.default = _default;
});