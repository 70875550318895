define("builder/mixins/styles-variables", ["exports", "builder/core/enumerators/widget", "builder/core/enumerators/container", "builder/core/enumerators/module", "builder/core/styles-management/widget-container/variables", "builder/core/styles-management/widget-section/variables", "builder/core/styles-management/core-module/variables", "builder/core/styles-management/form-module/variables", "builder/core/styles-management/widget-button/variables", "builder/core/styles-management/widget-buttonsgroup/variables", "builder/core/styles-management/widget-image/variables", "builder/core/styles-management/widget-paragraph/variables", "builder/core/styles-management/widget-spacer/variables", "builder/core/styles-management/widget-line-divider/variables", "builder/core/styles-management/widget-tabs/variables", "builder/core/styles-management/widget-column/variables", "builder/core/styles-management/widget-row/variables"], function (_exports, _widget, _container, _module, _variables, _variables2, _variables3, _variables4, _variables5, _variables6, _variables7, _variables8, _variables9, _variables10, _variables11, _variables12, _variables13) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @mixin StylesVariablesMixin
   * @public
   */
  var _default = Ember.Mixin.create({
    /**
     * Method return variables for component styles.
     *
     * @param {String} type - component name which styles variables should return
     * @return {undefined|String}
     * @method getVariablesConfig
     * @private
     */
    getVariablesConfig(type) {
      let variables;

      switch (type) {
        case _widget.WidgetType.WIDGET_PARAGRAPH:
          variables = _variables8.default;
          break;

        case _widget.WidgetType.WIDGET_IMAGE:
          variables = _variables7.default;
          break;

        case _widget.WidgetType.WIDGET_BUTTON:
          variables = _variables5.default;
          break;

        case _widget.WidgetType.WIDGET_BUTTONSGROUP:
          variables = _variables6.default;
          break;

        case _widget.WidgetType.WIDGET_SPACER:
          variables = _variables9.default;
          break;

        case _widget.WidgetType.WIDGET_LINE_DIVIDER:
          variables = _variables10.default;
          break;

        case _module.default.MODULE_CORE:
          variables = _variables3.default;
          break;

        case _module.default.MODULE_FORM:
          variables = _variables4.default;
          break;

        case _container.default.WIDGET_CONTAINER:
          variables = _variables.default;
          break;

        case _widget.WidgetType.WIDGET_SECTION:
          variables = _variables2.default;
          break;

        case _container.default.WIDGET_TABS:
          variables = _variables11.WidgetTabsStyleVariables;
          break;

        case _container.default.WIDGET_COLUMN:
          variables = _variables12.default;
          break;

        case _container.default.WIDGET_ROW:
          variables = _variables13.default;
      }

      return variables;
    }

  });

  _exports.default = _default;
});