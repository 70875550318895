define("builder/pods/components/form-fields-control/component", ["exports", "builder/core/enumerators/module-action"], function (_exports, _moduleAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormFieldControl = (_dec = Ember.computed('parentWidth'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class FormFieldControl extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.tagName = '';
      this.parentWidth = 0;
    }

    /**
     * Determine collapse buttons state
     * @type {boolean}
     * @readonly
     */
    get isCollapsed() {
      return this.parentWidth < 450;
    }
    /**
     * Action to open fields editor
     * @returns {void}
     */


    addFieldsAction() {
      this.addFields();
    }
    /**
     * Action editForm - action for open config form popup
     * @returns {void}
     */


    editForm() {
      this.moduleAction(_moduleAction.default.EDIT);
    }
    /**
     * Action exitWithoutSave - action to exit from edit mode without save changes
     * @returns {void}
     */


    exitWithoutSave() {
      this.moduleAction('revertForm');
    }
    /**
     * Action exitWithSave - action to exit from edit mode with save changes
     *
     * @param {MouseEvent} event - event object
     */


    exitWithSave(event) {
      this.moduleAction('formApply', event);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isCollapsed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addFieldsAction", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addFieldsAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editForm", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "editForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exitWithoutSave", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "exitWithoutSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exitWithSave", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "exitWithSave"), _class.prototype)), _class));
  _exports.default = FormFieldControl;
});