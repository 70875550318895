define("builder/core/enumerators/form-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    TEXT: 'field-text',
    TEXTAREA: 'field-textarea',
    DROPDOWN: 'field-dropdown',
    RADIOLIST: 'field-radiolist',
    CHECKBOX: 'field-checkbox',
    CHECKBOX_LIST: 'field-checkboxlist',
    DRIVER_LICENSE_STATE: 'field-driverlicense-state',
    DRIVER_LICENSE: 'field-driverlicense',
    NUMBER: 'field-number',
    FILE: 'field-file',
    EMAIL: 'field-email',
    WEBSITE: 'field-website',
    DATE: 'field-date',
    DATE_OF_BIRTH: 'field-dateofbirth',
    FIELDSET: 'form-fieldset',
    FORM_STEP: 'form-step',
    SCHEDULER_CALENDAR: 'field-schedulercalendar',
    VEHICLE_TYPE: 'field-vehicletype',
    VEHICLE_MAKE: 'field-vehiclemake',
    VEHICLE_MILEAGE: 'field-vehiclemileage',
    VEHICLE_MODEL: 'field-vehiclemodel',
    VEHICLE_PRICE: 'field-vehicleprice',
    VEHICLE_TRIM: 'field-vehicletrim',
    VEHICLE_YEAR: 'field-vehicleyear',
    VEHICLE_SELECTOR: 'field-vehicleselector',
    PHONE: 'field-phone',
    STATE: 'field-state',
    ZIP_CODE: 'field-zipcode',
    SOCIAL_SECURITY: 'field-socialsecurity',
    HIDDEN: 'field-hidden',
    ICON: 'field-icon',
    COMMENTS: 'field-comments',
    LOCATION: 'field-location',
    CITY: 'field-city',
    FIRST_NAME: 'field-firstname',
    LAST_NAME: 'field-lastname',
    MIDDLE_INITIAL: 'field-middleinitial',
    STREET_ADDRESS: 'field-streetaddress',
    ADDRESS_LINE: 'field-addressline2',
    DEPARTMENT: 'field-department',
    NAME_PREFIX: 'field-name-prefix',
    TCPA_CONSENT: 'field-tcpa-consent',
    PREFERRED_CONTACT: 'field-preferred-contact',
    LIVING_SINCE: 'field-living-since',
    RENT_OR_OWN: 'field-rent-or-own',
    VEHICLE_PURCHASE_TYPE: 'field-vehicle-purchase-type',
    VEHICLE_LOAN_AMOUNT: 'field-vehicle-loan-amount',
    VEHICLE_TRADE_IN_MAKE: 'field-vehicle-trade-in-make',
    VEHICLE_TRADE_IN_MODEL: 'field-vehicle-trade-in-model',
    VEHICLE_TRADE_IN_YEAR: 'field-vehicle-trade-in-year',
    VEHICLE_TRADE_IN_MILEAGE: 'field-vehicle-trade-in-mileage',
    VEHICLE_TRADE_IN_TRIM: 'field-vehicle-trade-in-trim',
    VEHICLE_TRANSMISSION: 'field-vehicle-transmission',
    VEHICLE_TRADE_IN: 'field-vehicle-trade-in',
    EMP_CO_COMPANY: 'field-emp-co-company',
    EMP_CO_PREV_COMPANY: 'field-emp-co-prev-company',
    EMP_CO_INCOME: 'field-emp-co-income',
    EMP_CO_OTHER_INCOME_SOURCE: 'field-emp-co-other-income-source',
    EMP_CO_OTHER_INCOME: 'field-emp-co-other-income',
    EMP_CO_OCCUPATION: 'field-emp-co-occupation',
    EMP_CO_SINCE_DATE: 'field-emp-co-since-date',
    CO_ADDRESS_CITY: 'field-co-address-city',
    CO_ADDRESS_STATE: 'field-co-address-state',
    CO_ADDRESS_STREET: 'field-co-address-street',
    CO_ADDRESS_STREET2: 'field-co-address-street2',
    CO_ADDRESS_ZIP: 'field-co-address-zip',
    CO_DATEOFBIRTH: 'field-co-dateofbirth',
    CO_DAY_TIME_PHONE: 'field-co-day-time-phone',
    CO_DRIVERLICENSE_STATE: 'field-co-driverlicense-state',
    CO_DRIVERLICENSE: 'field-co-driverlicense',
    CO_EMAIL: 'field-co-email',
    CO_EVENING_PHONE: 'field-co-evening-phone',
    CO_FIRSTNAME: 'field-co-firstname',
    CO_LASTNAME: 'field-co-lastname',
    CO_LIVING_SINCE: 'field-co-living-since',
    CO_MIDDLENAME: 'field-co-middlename',
    CO_MONTHLY_PAYMENT: 'field-co-monthly-payment',
    CO_RENT_OR_OWN: 'field-co-rent-or-own',
    CO_SOCIAL_SECURITY: 'field-co-social-security',
    EMP_COMPANY_CITY: 'field-emp-company-city',
    EMP_COMPANY_PHONE: 'field-emp-company-phone',
    EMP_COMPANY_STATE: 'field-emp-company-state',
    EMP_COMPANY_STREETADDRESS: 'field-emp-company-streetaddress',
    EMP_COMPANY_ZIPCODE: 'field-emp-company-zipcode',
    EMP_COMPANY: 'field-emp-company',
    EMP_INCOME_MONTHLY: 'field-emp-income-monthly',
    EMP_OCCUPATION: 'field-emp-occupation',
    EMP_OTHER_INCOME_MONTHLY: 'field-emp-other-income-monthly',
    EMP_OTHER_INCOME_SOURCE: 'field-emp-other-income-source',
    EMP_SINCE_DATE: 'field-emp-since-date'
  };
  _exports.default = _default;
});