define("builder/pods/components/sm-select-font/component", ["exports", "builder/pods/components/sm-select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * Component help build select for fonts management UI.
   */
  let SMSelectFontsComponent = (_dec = Ember.computed(), _dec2 = Ember.computed(), _dec3 = Ember.computed('fontsModel', 'variable.value'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SMSelectFontsComponent extends _component.SMSelectComponent {
    constructor(...args) {
      super(...args);
      this.fontsModel = null;
    }

    /**
     * @type {boolean}
     * @readonly
     */
    get emptySearchQuery() {
      return true;
    }
    /**
     * @type {boolean}
     * @readonly
     */


    get fixSearchBox() {
      return true;
    }
    /**
     * @inheritdoc
     */


    get defaultOptions() {
      var _this$variable;

      const fontsList = this.fontsModel.map(font => ({
        name: font.Title,
        value: font.Name,
        style: Ember.String.htmlSafe(`font-family: ${font.Name}`),
        isRemoved: true
      }));
      const selectedFontName = (_this$variable = this.variable) === null || _this$variable === void 0 ? void 0 : _this$variable.value; // Handle removed font

      const hasSelectedFont = fontsList.some(font => font.value === selectedFontName);

      if (selectedFontName && !hasSelectedFont) {
        fontsList.push({
          name: selectedFontName,
          value: selectedFontName,
          style: Ember.String.htmlSafe(''),
          isRemoved: true
        });
      }

      fontsList.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        const greaterReturn = aName > bName ? 1 : 0;
        return aName < bName ? -1 : greaterReturn;
      });
      return fontsList;
    }
    /**
     * Action for searching font
     * @param {string} query
     */


    search(query) {
      const normalizedQuery = query.toLowerCase();
      this.optionsList.forEach(option => {
        const normalizedValue = option.name.toLowerCase().indexOf(normalizedQuery);
        Ember.set(option, 'isRemoved', normalizedValue !== -1);
      });
    }
    /**
     * Action to show full list
     */


    showFullList() {
      this.optionsList.forEach(option => {
        Ember.set(option, 'isRemoved', true);
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "emptySearchQuery", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "emptySearchQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fixSearchBox", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fixSearchBox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultOptions", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "defaultOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showFullList", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showFullList"), _class.prototype)), _class));
  _exports.default = SMSelectFontsComponent;
});