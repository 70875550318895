define("builder/core/abstract-classes/field-base-component", ["exports", "builder/mixins/field-visibility"], function (_exports, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FieldBaseComponent = void 0;

  /**
   * Base class for configuration fields components
   */
  const FieldBaseComponent = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * Original passed in data
     * @type {FieldConfiguration}
     */
    field: null,

    /**
     * Defines rather this field component visible or not
     * @type {boolean}
     */
    isVisible: false,

    /**
     * Generic action handler for field change.
     * Should be passed via component property from parent component
     * @param {unknown} fieldData - new field data
     * @param {string} fieldName - current field name
     * @param {'change' | 'input'} actionType - action that was performed
     */
    actionHandler: (fieldData, fieldName, actionType) => {
      const message = 'action handler should be passed to field component via property';
      throw new Ember.Error(message);
    }
  });
  _exports.FieldBaseComponent = FieldBaseComponent;
});