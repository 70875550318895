define("builder/abilities/tier/misc", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierLinkAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      canUse: {
        entity: 'misc',
        property: 'voicesearch'
      }
    },

    /**
     * @inheritdoc
     */
    canHandle(actionName) {
      const {
        name
      } = this;
      const misc = this.get('checkpoint.permissions.misc') || {};
      return name && misc.hasOwnProperty(name) && this._super(actionName);
    },

    /**
     * @inheritdoc
     */
    handle() {
      const {
        name
      } = this;
      const misc = this.get('checkpoint.permissions.misc');
      return misc[name];
    }

  });

  _exports.default = _default;
});