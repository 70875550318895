define("builder/helpers/some", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns true if array has value
   * @template T
   * @param {[T[], T]} params;
   * @returns {boolean}
   */
  function some([valuesArray, searchValue]) {
    return valuesArray.some(value => value === searchValue);
  }

  var _default = Ember.Helper.helper(some);

  _exports.default = _default;
});