define("builder/core/dom-element-resize-observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *	HTMLElement dimensions observer based on requestAnimationFrame/setTimeout.
   *
   * 	Usage:
   * 		var observer = new DOMElementResizeObserver(<instance of HTMLElement>);
   * 		// For handle element dimensions
   * 		observer.addEventListener('onChange', function(width, height) { ... });
   *
   * 		// Start observation
   * 		observer.start();
  
   * 		// Stop observation
   * 		observer.stop();
  
   * 		// Stop observation and destroy.
   * 		observer.destroy();
   */
  var _default = Ember.Object.extend(Ember.Evented, {
    /**
     * Observable element
     * @type {HTMLElement}
     * @private
     */
    _element: null,

    /**
     * Observer timer
     * @type {number|null}
     * @private
     */
    _timer: null,

    /**
     * Width of element
     * @type {number}
     * @private
     */
    _width: 0,

    /**
     * Height of element
     * @type {number}
     * @private
     */
    _height: 0,

    /**
     * Constructor
     *
     * @method init
     * @param {HTMLElement} element - observable element
     */
    init(element, ...args) {
      this._super(element, ...args);

      if (!element || !(element instanceof window.HTMLElement)) {
        throw new Ember.Error('Parameter "element" is not instance of HTMLElement.');
      }

      this._element = element;
    },

    /**
     * Start observing
     */
    start() {
      if (this.isDestroyed) {
        throw new Ember.Error('Object is destroyed.');
      }

      this._startCheckingSize();
    },

    /**
     * Stop observing
     */
    stop() {
      this._stopCheckingSize();
    },

    /**
     * @inheritdoc
     */
    destroy() {
      this.stop();
      this._element = null;

      this._super();
    },

    /**
     * Stop checking size
     * @private
     */
    _stopCheckingSize() {
      if (!this._timer) {
        return;
      }

      if (window.requestAnimationFrame && window.cancelAnimationFrame) {
        window.cancelAnimationFrame(this._timer);
      } else {
        clearTimeout(this._timer);
      }
    },

    /**
     * Start checking size
     * @private
     */
    _startCheckingSize() {
      const newWidth = this._element.offsetWidth;
      const newHeight = this._element.offsetHeight;

      if (newWidth !== this._width || newHeight !== this._height) {
        this._width = newWidth;
        this._height = newHeight;
        this.trigger('onChange', newWidth, newHeight);
      }

      if (window.requestAnimationFrame) {
        this._timer = window.requestAnimationFrame(this._startCheckingSize.bind(this));
      } else {
        this._timer = setTimeout(this._startCheckingSize.bind(this), 100);
      }
    }

  });

  _exports.default = _default;
});