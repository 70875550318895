define("builder/pods/components/tooltip-external/component", ["exports", "jquery", "builder/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to create a external tooltip in the end of body
   *
   * You can configure the following options:
   *
   * text - text for tooltip, you can use HTML markup, if it's parameter is empty - tooltip not open! (string)
   * closingDelayTime - time delay before closing the tooltip, if it's value set 0, popup closing without delay (it's numeric value, default: 250)
   * horizontalPosition - horizontal position for tooltip (it's enumerated string value: 'left', 'center', 'right', default: 'right')
   * useCorner - should the tooltip contain an corner or not (boolean, default: false)
   * verticalPosition - vertical position for tooltip (it's enumerated string value: 'top', 'bottom', default: 'bottom')
   * viewportIndents - indents in the corners of the viewport in pixels (number, default value: 5)
   * tooltipButtonClass - additional class for calling tooltip button (string)
   * tooltipClass - additional class for tooltip, use it for create tooltip theme (string, default: 'tooltip-external_theme_default')
   * autoWidth - boolean option to set tooltip width auto (default is false)
   *
   * Basic usage:
   *
   * {{#tooltip-external
   *     text=text
   *     horizontalPosition="center"
   *     verticalPosition="top"
   *     useCorner=true
   *     closingDelayTime=300
   *     viewportIndents=10
   *     tooltipButtonClass="tooltip-external-button_theme_new"
   *     autoWidth=true
   *     tooltipClass="tooltip-external_theme_new"}}
   *          <element for tooltip call button>
   * {{/tooltip-external}}
   *
   * when useCorner option is true, to tooltip is added class 'tooltip-external_corner'
   * when tooltip over the tooltip button, to tooltip added class 'tooltip-external_top_position'
   * when tooltip is open, to tooltip button added class 'tooltip-external-button_active'
   */
  var _default = Ember.Component.extend({
    /**
     * tag name
     * @type {string}
     */
    tagName: 'span',

    /**
     * class names
     * @type {string[]}
     */
    classNames: ['tooltip-external-button'],

    /**
     * class name bindings
     * @type {string[]}
     */
    classNameBindings: ['_isTooltipOpen:tooltip-external-button_active', 'tooltipButtonClass'],

    /**
     * text for tooltip
     * @type {string|null}
     */
    text: null,

    /**
     * Extended tooltip text
     * all text transformation (placeholders replace, etc) performed here
     * @type {string}
     * @readonly
     */
    tooltipText: Ember.computed('text', function () {
      if (!this.text) {
        return this.text;
      }

      const igniteUrlPlaceholder = /{{ignite2Domain}}/g;
      return this.text.toString().replace(igniteUrlPlaceholder, _environment.default.ignite2BaseUrl);
    }).readOnly(),

    /**
     * should use tooltip corner or not
     * @type {boolean}
     */
    useCorner: false,

    /**
     * time of closing the tooltip automatically
     * @type {number}
     */
    closingDelayTime: 250,

    /**
     * indents in the corners of the viewport in pixels
     * @type {number}
     */
    viewportIndents: 5,

    /**
     * horizontal position for tooltip
     * @type {'left' | 'center' | 'right'}
     */
    horizontalPosition: 'right',

    /**
     * vertical position for tooltip
     * @type {'top' | 'bottom'}
     */
    verticalPosition: 'bottom',

    /**
     * additional class for tooltip button
     * @type {string|null}
     */
    tooltipButtonClass: null,

    /**
     * additional class for tooltip
     * @type {string}
     */
    tooltipClass: 'tooltip-external_theme_default',

    /**
     * auto width of tooltip
     * @type {boolean}
     */
    autoWidth: false,

    /**
     * is tooltip should be shown on hover or not
     * @property {boolean}
     */
    show: true,

    /**
     * External handler for click on tooltip content
     * @type {Function|null}
     */
    contentClickHandler: null,

    /**
     * property shows that tooltip is open or not
     * @type {boolean}
     * @private
     */
    _isTooltipOpen: false,

    /**
     * timer for close tooltip
     * @type {Object|null}
     * @private
     */
    _timer: null,

    /**
     * open tooltip on click
     * @type {boolean}
     * @private
     */
    openTooltipOnClick: false,

    /**
     * tooltip node for insert on page
     * @type {HTMLElement|null}
     * @private
     */
    _tooltipNode: Ember.computed('text', 'autoWidth', 'useCorner', function () {
      const text = this.tooltipText;

      if (!text) {
        return null;
      }

      const tooltip = document.createElement('div');
      const tooltipBody = document.createElement('div');
      tooltip.className = `tooltip-external ${this.tooltipClass} ${this.useCorner ? 'tooltip-external_corner' : ''}`;

      if (this.autoWidth) {
        tooltip.classList.add('tooltip-external_auto-width');
      }

      tooltipBody.className = 'tooltip-external__body';
      tooltipBody.innerHTML = text;
      tooltip.appendChild(tooltipBody);

      if (this.useCorner) {
        const tooltipCorner = document.createElement('div');
        tooltipCorner.className = 'tooltip-external__corner';
        tooltip.appendChild(tooltipCorner);
      }

      if (this.closingDelayTime) {
        tooltip.addEventListener('mouseenter', () => {
          this._clearTimer();
        });
        tooltip.addEventListener('mouseleave', () => {
          this._closeTooltip();
        });
      }

      if (typeof this.contentClickHandler === 'function') {
        tooltip.addEventListener('click', event => {
          this.contentClickHandler(event);
        });
      }

      return tooltip;
    }),

    /**
     * Click event handler
     * @returns {void}
     */
    click() {
      if (this.openTooltipOnClick && this._tooltipNode && this.show) {
        this._openTooltip();
      }
    },

    /**
     * Mouse Enter event handler
     * @returns {void}
     */
    mouseEnter() {
      if (!this.openTooltipOnClick && this._tooltipNode && this.show) {
        this._openTooltip();
      }
    },

    /**
     * Mouse leave event handler
     * @returns {void}
     */
    mouseLeave() {
      if (this._tooltipNode) {
        const {
          closingDelayTime
        } = this;

        if (closingDelayTime) {
          this.set('_timer', Ember.run.later(this, this._closeTooltip, closingDelayTime));
        } else {
          this._closeTooltip();
        }
      }
    },

    /**
     * @inheritdoc
     */
    willDestroyElement(...args) {
      this._super(...args);

      this._closeTooltip();
    },

    /**
     * Open tooltip and add handler for recalculate tooltip position
     * @private
     * @returns {void}
     */
    _openTooltip() {
      this.set('_isTooltipOpen', true);

      this._clearTimer();

      document.body.appendChild(this._tooltipNode);

      this._setPosition();

      (0, _jquery.default)(window).on('resize.tooltip', () => {
        Ember.run.debounce(this, this._setPosition, 300);
      }).on('scroll.tooltip', () => {
        this._setPosition();
      });
    },

    /**
     * Set tooltip position
     * @private
     * @returns {void}
     */
    _setPosition() {
      const tooltip = this._tooltipNode;
      const offset = this.$()[0].getBoundingClientRect();

      const left = this._getLeftPosition(offset, tooltip.offsetWidth);

      const top = this._getTopPosition(offset, tooltip.offsetHeight);

      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;

      if (top < offset.top) {
        tooltip.classList.add('tooltip-external_top_position');
      } else {
        tooltip.classList.remove('tooltip-external_top_position');
      }

      if (this.useCorner) {
        tooltip.children[1].style.left = `${offset.left - left + offset.width / 2}px`;
      }
    },

    /**
     * Get left tooltip position depending on the horizontalPosition property
     *
     * @param {Object} offset - element position relative to the viewport
     * @param {number} tooltipWidth - tooltip width
     * @returns {number} left tooltip position
     * @private
     */
    _getLeftPosition(offset, tooltipWidth) {
      const documentMaxWidth = document.documentElement.clientWidth;
      const position = this.horizontalPosition;
      const indents = this.viewportIndents;
      let leftResult = 0;

      if (tooltipWidth < documentMaxWidth - indents * 2) {
        switch (position) {
          case 'left':
            leftResult = offset.right - tooltipWidth;
            break;

          case 'center':
            leftResult = (offset.right - offset.left) / 2 + offset.left - tooltipWidth / 2;
            break;

          default:
            leftResult = offset.left;
            break;
        }

        if (leftResult + tooltipWidth > documentMaxWidth - indents) {
          leftResult = documentMaxWidth - indents - tooltipWidth;
        }

        if (leftResult < indents) {
          leftResult = indents;
        }
      }

      return leftResult;
    },

    /**
     * Get top tooltip position depending on the verticalPosition property
     *
     * @param {Object} offset - element position relative to the viewport
     * @param {number} tooltipHeight - tooltip height
     * @returns {number} top tooltip position
     * @private
     */
    _getTopPosition(offset, tooltipHeight) {
      const documentMaxHeight = document.documentElement.clientHeight;
      const isPositionTop = this.verticalPosition === 'top';
      const indents = this.viewportIndents;
      let top = 0;

      if (tooltipHeight < documentMaxHeight - indents * 2) {
        if (isPositionTop) {
          top = offset.top - tooltipHeight;

          if (top < indents) {
            top = offset.bottom;
          }

          if (top + tooltipHeight > documentMaxHeight - indents) {
            top = indents;
          }
        } else {
          top = offset.bottom;

          if (top + tooltipHeight > documentMaxHeight - indents) {
            top = offset.top - tooltipHeight;
          }

          if (top < indents) {
            top = documentMaxHeight - indents - tooltipHeight;
          }
        }
      }

      return top;
    },

    /**
     * Close tooltip and remove handlers for recalculate tooltip position
     *
     * @private
     * @returns {void}
     */
    _closeTooltip() {
      if (this._isTooltipOpen) {
        this._clearTimer();

        this.set('_isTooltipOpen', false);
        document.body.removeChild(this._tooltipNode);
        (0, _jquery.default)(window).off('resize.tooltip').off('scroll.tooltip');
      }
    },

    /**
     * Clear close tooltip timer
     *
     * @private
     * @returns {void}
     */
    _clearTimer() {
      Ember.run.cancel(this._timer);
    }

  });

  _exports.default = _default;
});