define("builder/core/enumerators/system-aliases", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VDP_PAGE_ALIAS = _exports.INVENTORY_PAGE_ALIAS = _exports.HOME_PAGE_ALIAS = void 0;

  /**
   * Enum for system page aliases
   */
  const HOME_PAGE_ALIAS = 'index';
  _exports.HOME_PAGE_ALIAS = HOME_PAGE_ALIAS;
  const INVENTORY_PAGE_ALIAS = 'inventory';
  _exports.INVENTORY_PAGE_ALIAS = INVENTORY_PAGE_ALIAS;
  const VDP_PAGE_ALIAS = 'vehicle-details';
  _exports.VDP_PAGE_ALIAS = VDP_PAGE_ALIAS;
});