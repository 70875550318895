define("builder/widgets/configs/widget-paragraph", ["exports", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "builder/core/field-configurations/page-filter-object", "builder/core/field-configurations/vehicle-filter-object", "builder/widgets/configs/animation.options"], function (_exports, _fields, _breakpointModes, _pageFilterObject, _vehicleFilterObject, _animation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Configuration for widget-spacer
   * This fields will appear at module configuration popup
   */
  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      const configuration = {
        config: [{
          type: _fields.default.CHECKBOX_LIST,
          label: 'Show paragraph module on which devices / breakpoints?',
          description: 'Please select at least 1 device for the module to show on',
          help: 'When Un-checking the box means the widget will be hidden on that device / breakpoint.',
          name: 'visibleOnDevices',
          data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
          choices: [{
            value: _breakpointModes.LARGE,
            label: 'Desktop'
          }, {
            value: _breakpointModes.MEDIUM,
            label: 'Small Monitor'
          }, {
            value: _breakpointModes.SMALL,
            label: 'Tablet'
          }, {
            value: _breakpointModes.EXTRA_SMALL,
            label: 'Mobile'
          }],
          validation: {
            elements: {
              min: 1,
              max: 4
            },
            message: 'You must select at least one device for this module to display on.'
          }
        }, {
          type: _fields.default.FORM_VARIANTS,
          name: 'contextRules',
          label: 'Targeting',
          description: 'This will allow you to show the paragraph module only if certain criteria is met.',
          children: [{
            type: _fields.default.FORM_VARIANT,
            label: 'Rule name',
            labelFrom: 'ruleName',
            isUnique: false,
            name: 'custom',
            children: [{
              type: _fields.default.TEXT,
              name: 'ruleName',
              label: 'Rule name'
            }, {
              type: _fields.default.FILTERS,
              label: 'Edit rule',
              name: 'rule',
              children: [_pageFilterObject.PAGE_FILTER_OBJECT, _vehicleFilterObject.VEHICLE_FILTER_OBJECT]
            }]
          }]
        }, (0, _animation.animationEnabled)('Paragraph'), _animation.animationName, _animation.animationDuration, _animation.animationMirroring, _animation.enableAnimationOnDevice]
      };
      return configuration;
    }

  });

  _exports.default = _default;
});