define("builder/pods/components/input-year/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InputYear extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.format = 'Y';
    }

    /**
     * Callback to be invoked when datepicker updates
     */
    onUpdate() {}
    /**
     * Callback to be invoked when datepicker clear event
     */


    onClear() {}
    /**
     * @inheritdoc
     */


    didInsertElement() {
      this._addDatepicker();
    }
    /**
     * Add datapicker
     *
     * @method _addDatepicker
     * @private
     */


    _addDatepicker() {
      const component = this;
      const input = component.$('input');
      input.Zebra_DatePicker({
        format: this.format,
        default_position: 'below',

        onSelect(formatted, yyyymmdd, jsobject) {
          component.onUpdate(parseInt(formatted, 10));

          if (component.value === null) {
            const datepicker = input.data('Zebra_DatePicker');
            datepicker.clear_date();
          }
        },

        onClear() {
          component.onUpdate(null);
        }

      });
    }

  }

  _exports.default = InputYear;
});