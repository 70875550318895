define("builder/pods/components/field-link-paragraph-popup/component", ["exports", "builder/core/enumerators/fields"], function (_exports, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['field-link-paragraph-popup'],

    /**
     * @property {Object|null} fieldLinkPopupData - structure for field link creation popup
     */
    fieldLinkPopupData: null,

    /**
     * @property {Object|null} fieldData - incoming outside structure for field link
     */
    fieldData: null,

    /**
     * @property {String} title - title for popup
     */
    title: '',

    /**
     * @property {Boolean} isNewLink - state indicated what use create or edit layout popup
     */
    isNewLink: true,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('fieldLinkPopupData', {
        isNewLink: true,
        title: 'Create New Link',
        text: {
          data: '',
          label: 'Display Text'
        },
        fieldLinkData: {
          type: _fields.default.LINK,
          name: 'websiteAddress',
          availableType: 'both',
          label: 'Link To',
          data: {
            value: '',
            target: 'self',
            type: 'external'
          }
        }
      });
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      const {
        fieldData
      } = this;
      const {
        link
      } = fieldData;
      const defaultLink = {
        type: fieldData.type || 'external',
        target: 'tab',
        value: fieldData.value || ''
      };

      switch (fieldData.action) {
        case 'create':
          {
            this.set('title', 'Create New Link');
            this.set('isNewLink', true);
            this.set('fieldLinkPopupData.text.data', fieldData.text || '');
            this.set('fieldLinkPopupData.fieldLinkData.data', link || defaultLink);
            break;
          }

        case 'edit':
          {
            const existLink = fieldData.link || {};
            this.set('title', 'Edit Link');
            this.set('isNewLink', false);
            this.set('fieldLinkPopupData.text.data', existLink.text || fieldData.text || '');
            this.set('fieldLinkPopupData.fieldLinkData.data', link || defaultLink);
            break;
          }

        default:
          throw new Ember.Error(`Invalid action: ${fieldData.action}`);
      }
    },

    /**
     * @inheritDoc
     */
    didInsertElement(...args) {
      this._super(...args);

      document.body.classList.add('bld-no-scroll');
    },

    /**
     * @inheritDoc
     */
    willDestroyElement(...args) {
      document.body.classList.remove('bld-no-scroll');

      this._super(...args);
    },

    actions: {
      /**
       * Action for close field link popup
       */
      onCancelFieldLinkPopup() {
        if (typeof this.handler === 'function') {
          this.handler();
        }
      },

      /**
       * Action for apply changes in field link popup
       */
      onOkFieldLinkPopup() {
        const result = this.fieldData;
        result.text = this.get('fieldLinkPopupData.text.data');
        result.link = this.get('fieldLinkPopupData.fieldLinkData.data');

        if (typeof this.handler === 'function') {
          this.handler(result);
        }
      }

    }
  });

  _exports.default = _default;
});