define("builder/core/drop-rules/widget-column", ["exports", "builder/core/drop-rules/base-rule", "builder/core/enumerators/container", "builder/core/enumerators/direction", "builder/core/enumerators/breakpoint-modes"], function (_exports, _baseRule, _container, _direction, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Rules for widget-column
   */
  var _default = _baseRule.default.extend({
    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_allowedTypes', ['widget-row', 'widget-paragraph', 'widget-image', 'widget-button', 'widget-buttonsgroup', 'widget-spacer', 'core-module', 'form-module', 'widget-column']);
    },

    /**
     * @inheritdoc
     */
    canDrop(container, draggable, direction, anchor, ...args) {
      var _container$component, _container$component$;

      if (!this._super(container, draggable, direction, anchor, ...args)) {
        return false;
      }

      const parent = container.get('parent');
      const grandParent = parent ? parent.get('parent') : null;

      if (!parent || !grandParent) {
        return false;
      } // restrict nested column


      const device = container === null || container === void 0 ? void 0 : (_container$component = container.component) === null || _container$component === void 0 ? void 0 : (_container$component$ = _container$component.device) === null || _container$component$ === void 0 ? void 0 : _container$component$._device;

      if (device && (device.type === 'mobile' || device.type === 'tablet')) {
        return false;
      } // Do not drop on self


      if (anchor && anchor.get('parent') === draggable) {
        return false;
      }

      if (parent.get('originObject').get('type') === _container.default.WIDGET_ROW && grandParent.get('originObject').get('type') === _container.default.WIDGET_COLUMN) {
        return false;
      }

      if (!this._allowedTypes.includes(draggable.originObject.type)) {
        return false;
      } // When row exists inside column, right and left drop is not allowed


      const child = container.get('originObject').get('children')[0];

      if (container.get('originObject').get('children').length > 1 || child && child.get('originObject').get('type') === _container.default.WIDGET_ROW) {
        if (direction === _direction.default.RIGHT || direction === _direction.default.LEFT) {
          return false;
        }
      }

      return parent.get('originObject').get('children').length > 1;
    },

    /**
     * @inheritdoc
     */
    prepare(structure, container, draggable, direction) {
      var _draggable$originObje;

      if (direction === _direction.default.RIGHT || direction === _direction.default.LEFT || (draggable === null || draggable === void 0 ? void 0 : (_draggable$originObje = draggable.originObject) === null || _draggable$originObje === void 0 ? void 0 : _draggable$originObje.type) === _container.default.WIDGET_ROW) {
        return draggable;
      }

      const row = this.gridBuilder.createInstance(_container.default.WIDGET_ROW);
      structure.addChild(row, draggable);
      return row;
    },

    /**
     * @inheritdoc
     */
    drop(structure, container, draggable, direction, anchor) {
      var _this$getRootContaine;

      const currentDeviceType = this.getCurrentBreakpoint(draggable);
      const containerComponent = (_this$getRootContaine = this.getRootContainer(container)) === null || _this$getRootContaine === void 0 ? void 0 : _this$getRootContaine.component;
      const isGridEditorOn = (containerComponent === null || containerComponent === void 0 ? void 0 : containerComponent.isColumnEditorModeOn) || (containerComponent === null || containerComponent === void 0 ? void 0 : containerComponent.isRowEditorModeOn);

      if (currentDeviceType !== _breakpointModes.LARGE && isGridEditorOn) {
        // Handle order change of the child rows
        if (!structure.order.hasOrderedChildren(container, currentDeviceType)) {
          var _container$originObje;

          const containerChildren = container === null || container === void 0 ? void 0 : (_container$originObje = container.originObject) === null || _container$originObje === void 0 ? void 0 : _container$originObje.children;
          structure.order.initChildrenOrder(containerChildren, currentDeviceType);
        }

        structure.order.calculateOrderValue(container, draggable, direction, anchor);
        structure.order.reassignOrderConsecutively(container, null);
      } else {
        this.addDraggableToStructure(structure, container, draggable, direction, anchor);
        this.resetOrderOfSourceContainer(draggable, containerComponent, structure);
      }
    },

    /**
     * Adds a module/widget to the current structure based on drop position
     * @param {object} structure - structure service instance
     * @param {object} container - Container object
     * @param {object} draggable - Draggable element object
     * @param {string} direction - Direction of drop
     * @param {object} anchor - anchor element of drop
     */
    addDraggableToStructure(structure, container, draggable, direction, anchor) {
      structure.startTransaction();

      const draggableContainer = this._getDraggableContainer(draggable);

      const preparedDraggable = this.prepare(structure, container, draggable, direction);
      let insertContainer = container;

      if (container.get('originObject').get('children').length === 1 && container.get('originObject').get('children')[0].get('originObject').get('type') !== _container.default.WIDGET_ROW) {
        this._prepareContainer(structure, container);
      }

      let index = this._getDropIndex(container, anchor, direction, structure);

      if (direction === _direction.default.RIGHT || direction === _direction.default.BOTTOM) {
        index++;
      } // When dragged above self, we must consider that self parent row will be removed,
      // If row has the only child


      if (draggableContainer && draggableContainer.type === _container.default.WIDGET_ROW && anchor === draggableContainer) {
        if (draggableContainer.get('originObject').get('children').length === 1 && direction === _direction.default.BOTTOM) {
          index--;
        }
      } // When drop performed on horizontal axis, we should add draggable into freshly created row


      if (direction === _direction.default.RIGHT || direction === _direction.default.LEFT) {
        [insertContainer] = container.get('originObject').get('children');
      }

      structure.addChild(insertContainer, preparedDraggable, index);
      structure.commitTransaction();
      structure.order.updateChildrenOrderOnAdd(container, preparedDraggable, index);
    },

    /**
     * Prepare container to drop operation
     *
     * @param {Object} structure - structure
     * @param {Object} container - container to prepare
     * @private
     */
    _prepareContainer(structure, container) {
      const {
        gridBuilder
      } = this;
      const child = container.get('originObject').get('children')[0];
      const row = gridBuilder.createInstance(_container.default.WIDGET_ROW);
      const column = gridBuilder.createInstance(_container.default.WIDGET_COLUMN); // Remove child from it's original parent

      structure.removeChild(child); // Pack child into new column

      structure.addChild(column, child); // Pack column into new row

      structure.addChild(row, column); // Update child

      structure.addChild(container, row);
    },

    /**
     * Get widget-container(parent of parent) of draggable element
     *
     * @param {Object} draggable - draggable which container to find
     * @returns {Object|null}
     * @private
     */
    _getDraggableContainer(draggable) {
      const parent = draggable.get('parent');
      return parent ? parent.get('parent') : null;
    },

    /**
     * Get drop index of draggable into container
     *
     * @param {Ember.Object} container - container where draggable will be dropped
     * @param {Ember.Object} anchor - anchor child, next to which draggable will be dropped
     * @param {String} direction - direction of draggable drop {@link builder/app/core/enumerators/direction.js}
     * @param {Object} structure - structure
     * @returns {Number} - index of draggable drop
     * @private
     */
    _getDropIndex(container, anchor, direction, structure) {
      var _container$originObje2, _container$originObje3;

      let index = 0;
      const currentDeviceType = this.getCurrentBreakpoint(anchor);
      const hasOrderedChildren = structure.order.hasOrderedChildren(container, currentDeviceType);
      const containsChildrenInContainer = container === null || container === void 0 ? void 0 : (_container$originObje2 = container.originObject) === null || _container$originObje2 === void 0 ? void 0 : (_container$originObje3 = _container$originObje2.children) === null || _container$originObje3 === void 0 ? void 0 : _container$originObje3.includes(anchor);

      if (anchor && containsChildrenInContainer) {
        if (!hasOrderedChildren) {
          var _container$originObje4, _container$originObje5;

          index = container === null || container === void 0 ? void 0 : (_container$originObje4 = container.originObject) === null || _container$originObje4 === void 0 ? void 0 : (_container$originObje5 = _container$originObje4.children) === null || _container$originObje5 === void 0 ? void 0 : _container$originObje5.indexOf(anchor);
        } else {
          index = structure.order._getOrderOfObject(anchor, currentDeviceType);
        }
      }

      return index;
    }

  });

  _exports.default = _default;
});