define("builder/services/module-remove", ["exports", "builder/core/enumerators/module", "builder/core/enumerators/container", "builder/core/enumerators/widget"], function (_exports, _module, _container, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModuleRemoveService = (_dec = Ember.inject.service('modules-store'), _dec2 = Ember.inject.service('structure'), _dec3 = Ember.inject.service('drag-and-drop'), _dec4 = Ember.inject.service('store'), _dec5 = Ember.inject.service('form'), (_class = (_temp = class ModuleRemoveService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modulesStoreService", _descriptor, this);

      _initializerDefineProperty(this, "structureService", _descriptor2, this);

      _initializerDefineProperty(this, "dragAndDropService", _descriptor3, this);

      _initializerDefineProperty(this, "storeService", _descriptor4, this);

      _initializerDefineProperty(this, "formService", _descriptor5, this);

      this.pageModel = void 0;
    }

    /**
     * @param {any} model
     */
    setPageModel(model) {
      this.pageModel = model;
    }
    /**
     * Removes module from page structure and removes its config
     * @param {Ember.Object} module - Module to be removed
     * @returns {boolean}
     */


    removeModule(module) {
      var _module$originObject, _module$originObject$;

      const blackList = [_container.default.WIDGET_ROW, _container.default.WIDGET_COLUMN];
      const {
        type
      } = module.originObject;

      if (![_container.default.WIDGET_CONTAINER, _widget.WidgetType.WIDGET_SECTION, _container.default.WIDGET_TABS].includes(type)) {
        this._removeModule(module);

        return true;
      }
      /* We should ignore empty sections */


      if (!(module === null || module === void 0 ? void 0 : (_module$originObject = module.originObject) === null || _module$originObject === void 0 ? void 0 : (_module$originObject$ = _module$originObject.children) === null || _module$originObject$ === void 0 ? void 0 : _module$originObject$.length) && type === _widget.WidgetType.WIDGET_SECTION) {
        this._removeModule(module);

        return true;
      }

      const bfArray = this.structureService.buildBFArray(module);

      for (let i = bfArray.length - 1; i >= 0; i--) {
        bfArray[i].forEach(m => {
          if (!blackList.includes(m.originObject.type)) {
            this._removeModule(m);
          }
        });
      }
      /**
       * As BFArray contains only children,
       * and sections do not use cleaners,
       * so we would need to remove it manually
       */


      if (type === _widget.WidgetType.WIDGET_SECTION && type === _container.default.WIDGET_TABS) {
        this._removeModule(module);
      }

      return true;
    }
    /**
     * Single module removal
     * @param {Ember.Object} module - module to remove, pst. it contains originObject
     * @private
     * @returns {void}
     */


    _removeModule(module) {
      const column = module.parent;
      const {
        id
      } = module.originObject;
      let record, moduleStyleRecord;

      if (id) {
        record = this.storeService.peekRecord('page-module', id);

        if (record) {
          moduleStyleRecord = this.storeService.peekRecord('module-style', id);

          switch (module.originObject.type) {
            case _module.default.MODULE_CORE:
              this.modulesStoreService.remove(id);
              break;

            case _module.default.MODULE_FORM:
              this.removeFormUsageByConfigId(id);
              break;
          }
        }
      } // Refresh module before removing


      this.dragAndDropService.refreshModules(module, null, [module]);

      if (column) {
        this.structureService.startTransaction();
        this.structureService.removeChild(column, true);
        this.structureService.commitTransaction();
      }

      if (record) {
        record.deleteRecord();
      }

      if (moduleStyleRecord) {
        moduleStyleRecord.unloadRecord();
      }
    }
    /**
     * Remove form usage by module config id
     * @param {String} configId - form module config id
     * @returns {void}
     */


    removeFormUsageByConfigId(configId) {
      const module = this.storeService.peekRecord('page-module', configId);

      if (module) {
        const containerType = this.getModuleRootContainerType(configId);
        let containerId = '';

        switch (containerType) {
          case 'layout':
            containerId = this.pageModel.layout.id;
            break;

          case 'page':
            containerId = module && module.PageId || this.pageModel.layout.id;
            break;
        }

        this.formService.getFormByConfigId(configId).then(form => {
          if (form) {
            this.updateFormTemplateUsage('delete', containerType, containerId, form.id, configId);
          }
        });
      }
    }
    /**
     * Remove form usage by module config id
     * @param {string} moduleConfigId - module config id
     * @returns {string} container type
     */


    getModuleRootContainerType(moduleConfigId) {
      var _this$structureServic, _this$structureServic2;

      const rootContainers = ((_this$structureServic = this.structureService.pageViewModel) === null || _this$structureServic === void 0 ? void 0 : (_this$structureServic2 = _this$structureServic.originObject) === null || _this$structureServic2 === void 0 ? void 0 : _this$structureServic2.children) || [];
      let result = 'page';

      if (!rootContainers.length) {
        return result;
      }

      for (let i = 0; i < rootContainers.length; i++) {
        const container = rootContainers[i];
        const containerType = container.originObject.type;
        const module = this.structureService.findModuleById(container, moduleConfigId);

        if (module && (containerType === _container.default.WIDGET_HEADER || containerType === _container.default.WIDGET_FOOTER)) {
          result = 'layout';
          break;
        }
      }

      return result;
    }
    /**
     * Update form template usage on page
     * @param {string} actionType="insert","delete" action - action to do
     * @param {string} containerType="page","layout" containerType - container type
     * @param {string | number} containerId - page or layout id
     * @param {string | number} formId - form id
     * @param {string | number} moduleConfigId - module config id
     *
     * @returns {void}
     */


    updateFormTemplateUsage(actionType, containerType, containerId, formId, moduleConfigId) {
      this.formService.getForm(formId).then(form => {
        switch (actionType) {
          case 'insert':
            form.addUsageOnPage(containerType, containerId, moduleConfigId);
            break;

          case 'delete':
            form.removeUsageOnPage(containerType, containerId, moduleConfigId);
            break;
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modulesStoreService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "structureService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dragAndDropService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "storeService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ModuleRemoveService;
});