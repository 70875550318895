define("builder/core/styles-management/widget-section/structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Described components for section styles.
   * Values for each component passed before render.
   */
  var _default = [{
    label: 'Background',
    name: 'composite',
    type: 'background',
    children: [{
      label: 'Color',
      variableName: 'background-color',
      cssPropertyName: 'background-color'
    }, {
      label: 'Gradient',
      name: 'composite',
      type: 'background',
      variableName: 'background',
      children: [{
        label: 'Direction',
        variableName: 'background-gradient-direction',
        cssPropertyName: 'gradient-direction'
      }, {
        label: 'Color 1',
        variableName: 'background-gradient-color1',
        cssPropertyName: 'gradient-color1'
      }, {
        label: 'Color 2',
        variableName: 'background-gradient-color2',
        cssPropertyName: 'gradient-color2'
      }]
    }, {
      label: 'Image',
      variableName: 'background-image',
      cssPropertyName: 'background-image'
    }, {
      label: 'Repeat',
      variableName: 'background-repeat',
      cssPropertyName: 'background-repeat'
    }, {
      label: 'Position',
      variableName: 'background-position',
      cssPropertyName: 'background-position'
    }, {
      label: 'Attachment',
      variableName: 'background-attachment',
      cssPropertyName: 'background-attachment'
    }, {
      label: 'Size',
      variableName: 'background-size',
      cssPropertyName: 'background-size'
    }]
  }, {
    name: 'composite',
    label: 'Border',
    type: 'border',
    children: [{
      variableName: 'border-width',
      cssPropertyName: 'border-width',
      label: 'Width'
    }, {
      variableName: 'border-style',
      cssPropertyName: 'border-style',
      label: 'Style'
    }, {
      variableName: 'border-color',
      cssPropertyName: 'border-color',
      label: 'Color'
    }, {
      variableName: 'border-radius',
      cssPropertyName: 'border-radius',
      label: 'Radius'
    }]
  }, {
    label: 'Padding',
    name: 'composite',
    type: 'rectangle',
    children: [{
      label: 'Top',
      variableName: 'padding-top',
      cssPropertyName: 'padding-top',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Right',
      variableName: 'padding-right',
      cssPropertyName: 'padding-right',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Bottom',
      variableName: 'padding-bottom',
      cssPropertyName: 'padding-bottom',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Left',
      variableName: 'padding-left',
      cssPropertyName: 'padding-left',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }]
  }, {
    label: 'Margin',
    name: 'composite',
    type: 'rectangle',
    children: [{
      label: 'Top',
      variableName: 'margin-top',
      cssPropertyName: 'margin-top',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Right',
      variableName: 'margin-right',
      cssPropertyName: 'margin-right',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Bottom',
      variableName: 'margin-bottom',
      cssPropertyName: 'margin-bottom',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Left',
      variableName: 'margin-left',
      cssPropertyName: 'margin-left',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }]
  }, {
    label: 'Content Position',
    variableName: 'contentPosition',
    cssPropertyName: 'content-position'
  }];
  _exports.default = _default;
});