define("builder/core/data-filters/enabled-third-party-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SOURCE_MODULE_NAME = 'ThirdPartyScripts';
  const SOURCE_MODULE_FIELD_NAME = 'scripts';
  /**
   * Prepare field config with data filter setting
   *
   * @param {Ember.Application} application - application instance
   * @param {Object} destModuleFieldConfig - field config with data filter
   * @returns {Promise}
   */

  function filter(application, destModuleFieldConfig) {
    return _getSourceModuleConfig(application).then(sourceModuleConfig => {
      const sourceData = sourceModuleConfig && sourceModuleConfig.get(`data.Structure.${SOURCE_MODULE_FIELD_NAME}`) || [];

      if (destModuleFieldConfig && destModuleFieldConfig.choices && destModuleFieldConfig.choices.length) {
        destModuleFieldConfig.choices.forEach(choice => {
          let disabled = !sourceData.length;

          if (!disabled) {
            // For form-variants data
            if (typeof sourceData[0] === 'object' && sourceData[0].hasOwnProperty('variant-name')) {
              disabled = !sourceData.findBy('variant-name', choice.value);
            } else {
              disabled = !sourceData.includes(choice.value);
            }
          }

          choice._disabled = disabled;
        });
      }

      return Ember.RSVP.resolve(destModuleFieldConfig);
    });
  }
  /**
   * Get module config
   *
   * @param {Ember.Application} application - application instance
   * @returns {Promise}
   */


  function _getSourceModuleConfig(application) {
    const storeService = application.lookup('service:store');
    const moduleStoreService = application.lookup('service:modules-store');
    const module = moduleStoreService.findByName(SOURCE_MODULE_NAME).objectAt(0); // If module not found then module is switched off

    if (!module) {
      return Ember.RSVP.resolve();
    }

    const insertionId = module.get('originObject.id');
    const pageModule = storeService.peekRecord('page-module', insertionId); // If related system module is on but config do not load yet

    return pageModule ? Ember.RSVP.resolve(pageModule) : storeService.findRecord('page-module', insertionId);
  }

  var _default = filter;
  _exports.default = _default;
});