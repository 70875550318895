define("builder/widgets/configs/widget-container.options", ["exports", "builder/core/enumerators/widget-container-video-background-fields", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "builder/widgets/configs/filter/page-filter-object", "builder/widgets/configs/filter/vehicle-filter-object"], function (_exports, _widgetContainerVideoBackgroundFields, _fields, _breakpointModes, _pageFilterObject, _vehicleFilterObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enableAnimationOnDevice = _exports.animationMirroring = _exports.animationDuration = _exports.animationName = _exports.animationEnabled = _exports.vehicleTargeting = _exports.deviceVisibility = _exports.fullWidthColumns = _exports.videoBackground = _exports.anchor = void 0;
  const anchor = {
    type: _fields.default.TEXT,
    label: 'Anchor',
    name: 'anchor',
    help: 'Adding an anchor will allow for direct targeting of a container for hyperlinks. ' + 'Please note that a single anchor can only be used once per page, including in the header and footer. ' + 'This field will only allow capital and lowercase letters, numbers, dashes (-), underscores(_), colons (:), or periods (.)',
    validation: {
      pattern: '^([A-Za-z]+[\\w\\-\\:\\.]*)?$',
      message: 'Please enter a valid anchor.',
      length: {
        max: 20
      },
      uniqContainerAnchor: {
        debounce: 400
      }
    }
  };
  _exports.anchor = anchor;
  const videoBackground = {
    type: _fields.default.TEXT,
    label: 'Video Background',
    name: _widgetContainerVideoBackgroundFields.default.VIDEO_URL,
    help: `Video will play and loop automatically, without sound. When on mobile, the video will not be shown,
						and instead the container will display whatever else is set in Container Styles for the background
						(image, color, or gradient). The video will also revert to other container background settings on
						larger devices if the video file is too large to load quickly (< 6 sec) for the end-user.`,
    placeholder: 'Paste YouTube URL...',
    validation: {
      pattern: '^(https|http):\\/\\/(youtu\\.be|www\\.youtube\\.com\\/watch).+|^$',
      message: 'Please paste a valid YouTube URL.'
    }
  };
  _exports.videoBackground = videoBackground;
  const fullWidthColumns = {
    type: _fields.default.RADIOLIST,
    label: 'Full-Width Columns',
    name: 'resetColumnsPadding',
    choices: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    data: false,
    description: 'Enable full-width for all columns in the container?',
    help: 'When box is checked, this will disable padding between modules inside the container. ' + '(e.g. If you put two images side by side, there will be no space between them.)'
  };
  _exports.fullWidthColumns = fullWidthColumns;
  const deviceVisibility = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Show container on which devices / breakpoints?',
    description: 'Please select at least 1 device for the module to show on',
    help: 'Un-checking the box means all the modules within the container will be hidden on that device / breakpoint.',
    name: 'visibleOnDevices',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this module to display on.'
    }
  };
  _exports.deviceVisibility = deviceVisibility;
  const vehicleTargeting = {
    type: _fields.default.FORM_VARIANTS,
    name: 'contextRules',
    label: 'Targeting',
    description: 'This will allow you to show this Container only if certain criteria is met.',
    children: [{
      type: _fields.default.FORM_VARIANT,
      label: 'Rule name',
      labelFrom: 'ruleName',
      isUnique: false,
      name: 'custom',
      children: [{
        type: _fields.default.TEXT,
        name: 'ruleName',
        label: 'Rule name'
      }, {
        type: _fields.default.FILTERS,
        label: 'Edit rule',
        name: 'rule',
        children: [_pageFilterObject.PAGE_FILTER_OBJECT, _vehicleFilterObject.VEHICLE_FILTER_OBJECT]
      }]
    }]
  };
  _exports.vehicleTargeting = vehicleTargeting;
  const animationEnabled = {
    type: _fields.default.RADIOLIST,
    label: 'Apply animations to Container',
    name: 'enableAnimation',
    choices: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    data: false
  };
  _exports.animationEnabled = animationEnabled;
  const animationName = {
    type: _fields.default.DROPDOWN,
    label: 'Animation Effect',
    name: 'animationName',
    data: 'fade-down',
    choices: [{
      value: 'fade-down',
      label: 'Enter from Top'
    }, {
      value: 'fade-left',
      label: 'Enter from Right'
    }, {
      value: 'fade-up',
      label: 'Enter from Bottom'
    }, {
      value: 'fade-right',
      label: 'Enter from Left'
    }, {
      value: 'zoom-in',
      label: 'Magnify'
    }],
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }]
  };
  _exports.animationName = animationName;
  const animationDuration = {
    type: _fields.default.NUMBER_WITH_UNITS,
    label: 'Animation Duration',
    name: 'animationDuration',
    description: '',
    data: 250,
    min: 0,
    max: 3000,
    units: [{
      symbol: 'ms',
      label: 'ms'
    }],
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }]
  };
  _exports.animationDuration = animationDuration;
  const animationMirroring = {
    type: _fields.default.RADIOLIST,
    label: 'Reanimate on Scroll Up',
    name: 'animationMirroring',
    choices: [{
      label: 'Yes',
      value: 'true'
    }, {
      label: 'No',
      value: 'false'
    }],
    data: 'true',
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }]
  };
  _exports.animationMirroring = animationMirroring;
  const enableAnimationOnDevice = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Display Animations on Device Types',
    description: '',
    help: '',
    name: 'animationOnDeviceType',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this animation to display on.'
    }
  };
  _exports.enableAnimationOnDevice = enableAnimationOnDevice;
});