define("builder/pods/website/index/route", ["exports", "builder/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Uncomment next lines to remove deprecation messages from console
  // import { registerDeprecationHandler } from '@ember/debug';
  // registerDeprecationHandler((message, options, next) => {
  // 	// next(message, options);
  // });
  var _default = _application.default.extend({
    /**
     * @property {Object} companyAndWebsite - company-and-website service
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * @inheritdoc
     */
    model() {
      return Ember.RSVP.hash({
        websites: this.store.findAll('website'),
        globalPages: this.store.findAll('page-global')
      });
    },

    /**
     * @inheritdoc
     */
    handleAccessDenied() {
      return this.store.findAll('website').then(websites => {
        const websiteId = this.companyAndWebsite.getWebsiteId();
        const website = websiteId ? websites.findBy('id', websiteId) : websites.objectAt(0);
        return website || null;
      }).then(website => {
        if (website) {
          this.transitionTo('website.edit', website.get('id'));
        } else {
          this.transitionTo('access-denied');
        }
      });
    }

  });

  _exports.default = _default;
});