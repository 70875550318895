define("builder/pods/components/widget-menu-item/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: 'li',

    /**
     * @inheritdoc
     */
    classNames: ['pg-aside__menu-item main-nav_item'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['item.active:active'],

    /**
     * @property {Boolean|null} sidebarIsClosed - indicate what left Ignite2 sidebar is closed
     */
    sidebarIsClosed: null,

    /**
     * @property {Object} item - component data
     */
    item: null,

    /**
     * @property {Boolean} hasChildren - Determine the sub-menu.
     * @readonly
     */
    hasChildren: Ember.computed('item.children.[]', function () {
      return !!this.get('item.children.length');
    }).readOnly(),

    /**
     * Component actions.
     * @namespace
     */
    actions: {
      /**
       * Toggle child menu status.
       * @method toggleChildMenu
       */
      toggleChildMenu() {
        if (!this.sidebarIsClosed) {
          if (this.hasChildren) {
            const item = this.$();
            const activeMenuItemClass = 'active';
            item.toggleClass(activeMenuItemClass);
            (0, _jquery.default)('.main-nav').find('> .main-nav_item').not(item).removeClass(activeMenuItemClass);
          } else {
            window.location.href = this.get('item.url');
          }
        }
      }

    }
  });

  _exports.default = _default;
});