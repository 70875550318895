define("builder/core/meta-tags/dom-manipulation/element-search", ["exports", "builder/core/meta-tags/dom-manipulation/tag-constants", "builder/core/meta-tags/dom-manipulation/dom-selection"], function (_exports, _tagConstants, _domSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceSearchWithMetaElement = replaceSearchWithMetaElement;
  _exports.replaceSearchWithCurrentInput = replaceSearchWithCurrentInput;
  _exports.replaceCurlyBracesWithSearch = replaceCurlyBracesWithSearch;
  _exports.createNonBreakingSpace = createNonBreakingSpace;
  _exports.detectOutOfSearchBoundaries = detectOutOfSearchBoundaries;
  _exports.detectSearchContentChange = detectSearchContentChange;
  _exports.getSearchElementOffset = getSearchElementOffset;
  _exports.isKeyPressedOnSearchElement = isKeyPressedOnSearchElement;
  _exports.SPACE_SYMBOL = void 0;
  const SPACE_SYMBOL = '\u00A0';
  /**
   * Replaces search wrapper with metatag element
   * @param {HTMLElement} container
   * @param {HTMLElement} metaTagElement
   * @returns {void}
   */

  _exports.SPACE_SYMBOL = SPACE_SYMBOL;

  function replaceSearchWithMetaElement(container, metaTagElement) {
    const {
      tagName,
      dataAttribute
    } = _tagConstants.MetaSearchTag;
    const wrapper = container.querySelector(`${tagName}[${dataAttribute}]`);

    if (!wrapper) {
      return;
    }

    wrapper.parentElement.replaceChild(metaTagElement, wrapper);
    metaTagElement.after(SPACE_SYMBOL);
    (0, _domSelection.setCaretToNodeStart)(metaTagElement.nextSibling);
  }
  /**
   * Removes wrapper element from current search input
   * Function removes only search tags, so content stays untouched
   * @param {HTMLElement} container
   * @returns {void}
   */


  function replaceSearchWithCurrentInput(container) {
    const {
      tagName,
      dataAttribute
    } = _tagConstants.MetaSearchTag;
    const wrapper = container.querySelector(`${tagName}[${dataAttribute}]`);

    if (!wrapper) {
      return;
    }

    const {
      textContent
    } = wrapper;
    const textNode = document.createTextNode(textContent);
    wrapper.parentNode.replaceChild(textNode, wrapper);
    (0, _domSelection.setCaretToNodeEnd)(textNode);
  }
  /**
   * Wraps curly braces according current cursor position
   * Assuming that curly braces are to the left of current cursor
   * Caller of this function should detect double curly braces input by themselves
   * @returns {void}
   */


  function replaceCurlyBracesWithSearch(addSpace) {
    const bracesRange = (0, _domSelection.createCurlyBracesRange)();
    const wrapper = createSearchElement();
    const nonBreakingSpace = createNonBreakingSpace(); // wrapping curly braces with element

    bracesRange.surroundContents(wrapper); // adding some space after braces. just like at confluence

    wrapper.appendChild(nonBreakingSpace); // adding some anchor spaces so we could move here with right arrow

    if (addSpace) {
      wrapper.before(SPACE_SYMBOL);
      wrapper.after(SPACE_SYMBOL);
    } // setting cursor position to the end of new element


    (0, _domSelection.setCaretToNodeEnd)(wrapper);
  }

  function createNonBreakingSpace() {
    return document.createTextNode(SPACE_SYMBOL);
  }
  /**
   * Detects that carret is out of search input boundaries
   * @param {Function} callback
   * @returns {void}
   */


  function detectOutOfSearchBoundaries(callback) {
    const {
      dataAttribute
    } = _tagConstants.MetaSearchTag;
    const selection = document.getSelection();

    if (!selection || !selection.anchorNode) {
      return;
    }

    const {
      anchorNode: {
        parentElement
      }
    } = selection;

    if (!parentElement.hasAttribute(dataAttribute)) {
      callback();
    }
  }
  /**
   * Detects that keyboard event changed content inside search element
   * @param {KeyboardEvent} event
   * @param {(searchQuery?:string)=>void} callback
   * @returns {void}
   */


  function detectSearchContentChange(event, callback) {
    const {
      target
    } = event;
    const {
      tagName,
      dataAttribute
    } = _tagConstants.MetaSearchTag;
    const searchWrapper = target.querySelector(`${tagName}[${dataAttribute}]`);

    if (searchWrapper) {
      callback(searchWrapper.textContent.replace('{{', ''));
    }
  }
  /**
   * Returns search element offset for positioning dropdown element
   * @param {HTMLElement} container
   * @returns {left: number, top: number} - search element offset
   */


  function getSearchElementOffset(container) {
    const {
      tagName,
      dataAttribute
    } = _tagConstants.MetaSearchTag;
    const searchWrapper = container.querySelector(`${tagName}[${dataAttribute}]`);
    const [rect] = searchWrapper.getClientRects();
    return {
      left: searchWrapper.offsetLeft,
      top: searchWrapper.offsetTop + rect.height
    };
  }
  /**
   * Checks wether last key was pressed within search element or not
   * @returns {boolean}
   */


  function isKeyPressedOnSearchElement() {
    const caretNode = window.getSelection().getRangeAt(0).commonAncestorContainer;
    return caretNode && caretNode.hasAttribute && caretNode.hasAttribute(_tagConstants.MetaSearchTag.dataAttribute);
  }
  /**
   * Creates search element for curly braces replacement
   * @returns {HTMLElement}
   */


  function createSearchElement() {
    const {
      tagName,
      dataAttribute
    } = _tagConstants.MetaSearchTag;
    const wrapper = document.createElement(tagName);
    wrapper.style.backgroundColor = '#CCCCCC';
    wrapper.setAttribute(dataAttribute, true);
    return wrapper;
  }
});