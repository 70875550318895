define("builder/pods/components/field-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/skvtHsc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bld-form_textarea_container\"],[8],[0,\"\\n\\t\"],[1,[28,\"textarea\",null,[[\"name\",\"value\",\"rows\",\"change\"],[[24,[\"field\",\"name\"]],[24,[\"field\",\"data\"]],[24,[\"field\",\"rows\"]],[28,\"if\",[[24,[\"actionHandler\"]],[28,\"action\",[[23,0,[]],\"change\"],null]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});