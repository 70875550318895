define("builder/core/enumerators/container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Enum for grid.
   */
  var _default = Ember.Object.create({
    TOP_CONTAINER: 'top-container',
    WIDGET_HEADER: 'widget-header',
    WIDGET_MAIN: 'widget-main',
    WIDGET_FOOTER: 'widget-footer',
    WIDGET_CONTAINER: 'widget-container',
    WIDGET_ROW: 'widget-row',
    WIDGET_COLUMN: 'widget-column',
    WIDGET_TABS: 'widget-tabs'
  });

  _exports.default = _default;
});