define("builder/pods/components/dropdown-container/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to create a droppable content.
   * This component does not provide any styles so feel free to
   * customize each part of component as you want. You can pass any html or components behind brackets
   * NOTICE: Options does not described in usage you may find in that component
   *
   * Usage:
   * 1) Simple dropdown
   *
  	 		{{#dropdown-container as |manager|}}
  				{{#dropdown-button manager=manager}}Button1{{/dropdown-button}}
  				{{#dropdown-dropable manager=manager}}Dropable Content {{/dropdown-dropable}}
  			{{/dropdown-container}}
  
   	2) Customized dropdown
   			You can put dropdowns one to one as you need it.
  
  		 {{#dropdown-container class="beautiful-dropdown" as |manager|}}
  			 {{#dropdown-button class="beautiful-button" manager=manager}}
  						<div>
  							<span class="icon"></span>Button1
  						</div>
  				{{/dropdown-button}}
  			 	{{#dropdown-dropable class="beautiful-dropdown" manager=manager}}
  						Here you can pass any html or ember component
  				{{/dropdown-dropable}}
  		 {{/dropdown-container}}
  
    	3) Nested dropdown
  			 {{#dropdown-container method='hover' as |manager|}}
  					 {{#dropdown-button manager=manager}}
  							Button
  					 {{/dropdown-button}}
  					 {{#dropdown-dropable manager=manager}}
  						 Content
  
  						 {{#dropdown-container as |manager|}}
  							 {{#dropdown-button manager=manager}}
  							 		Child Button
  							 {{/dropdown-button}}
  							 {{#dropdown-dropable manager=manager}}
  									Child Content
  							 {{/dropdown-dropable}}
  						 {{/dropdown-container}}
  					 {{/dropdown-dropable}}
  			 {{/dropdown-container}}
  
      You can use two type of open/close logic, by default used click logic.
      If you set method='hover', will use logic on mouseEnter/mouseLeave
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['b2-dropdown'],

    /**
     * The property shows which method to use for the opening/closing logic
     *
     * @property {String} method, 'click' - use click logic; 'hover' - use 'hover'
     */
    method: 'click',

    /**
     * Main property to control "dropdown-dropable" component state(show/hide).
     *
     * @property {boolean} isExpanded
     */
    isExpanded: false,

    /**
     * By default "dropdown-dropable" auto positioned near the "dropdown-button" component.
     * You can pass "false" and control dropable content position from css.
     *
     * @property {boolean} autoPosition
     */
    autoPosition: true,

    /**
     * @property {String} closeSelector - css selectr which will be used to find DOM item to apply close dropdown callback.
     */
    closeSelector: 'body',

    /**
     * @property {null|String} activeClass - class which will be apply to DOM node component when dropdown is opened
     */
    activeClass: null,

    /**
     * Property ref to "dropdown-button" DOM element
     *
     * @property {null|Element} button
     */
    button: null,

    /**
     * Callback which will be fire when component state is changed(closed ore opened)
     * This callback accept only one param which contains boolean opened/closed state
     *
     * @property {null|Function} onChange
     */
    onChange: null,

    /**
     * Property contain "dropdown-button" selector.
     * Used when user try to close all opened dropdowns by "document".
     * This property help prevent closing event if user click on "dropdown-button" component.
     *
     * @property {String} _buttonSelector
     * @private
     */
    _buttonSelector: '.b2-dropdown-button',

    /**
     * Property contain callback which will be called when user click on "document"
     *
     * @see init method
     * @property {null|Function} closeCallback
     */
    closeCallback: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      if (this.method === 'click') {
        this.set('closeCallback', Ember.run.bind(this, this.closeDropDown));
      }
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.toggleActiveClass();

      if (this.method === 'click') {
        this.registerCloseListener();
      }
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      if (this.method === 'click') {
        this.removeCloseListener();
      }
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      // Send last onChange hook to notify subscribers nested dropdowns
      this.close();
    },

    /**
     * @param {Element} button - DOM element
     * @method registerButton
     */
    registerButton(button) {
      this.set('button', button);
    },

    /**
     * Method close all dropdowns when user click on "document"
     *
     * @param {Event} event
     * @method closeDropDown
     */
    closeDropDown(event) {
      const target = (0, _jquery.default)(event.target);
      const isTargetButton = target.closest(this._buttonSelector).length;
      const isTargetBelongsToThisDropdown = target.closest(this.$()).length;
      const isTargetNotRemovedBefore = target.parent().length;
      const isTargetButtonAndNotBelongsToThisDropdown = target.closest(this._buttonSelector).get(0) !== this.button.get(0);

      if (this.isExpanded && isTargetNotRemovedBefore && !isTargetBelongsToThisDropdown && (!isTargetButton || isTargetButtonAndNotBelongsToThisDropdown)) {
        this.send('close');
      }
    },

    /**
     * @method registerCloseListener
     */
    registerCloseListener() {
      const closeElement = this.closeSelector && document.querySelector(this.closeSelector);

      if (closeElement) {
        closeElement.addEventListener('click', this.closeCallback);
      }
    },

    /**
     * @method removeCloseListener
     */
    removeCloseListener() {
      const closeElement = this.closeSelector && document.querySelector(this.closeSelector);

      if (closeElement) {
        closeElement.removeEventListener('click', this.closeCallback);
      }
    },

    /**
     * Method remove/add active class on dropdown node
     *
     * @method toggleActiveClass
     */
    toggleActiveClass() {
      const element = this.$();
      const {
        activeClass
      } = this;

      if (activeClass && element && element.length) {
        if (this.isExpanded) {
          element.addClass(activeClass);
        } else {
          element.removeClass(activeClass);
        }
      }
    },

    /**
     * Close dropdown
     */
    close() {
      this.set('isExpanded', false);
      this.toggleActiveClass();

      this._onChange();
    },

    /**
     * Method call passed "onChange" handler
     *
     * @method _onChange
     * @private
     */
    _onChange() {
      if (typeof this.onChange === 'function') {
        this.onChange(this.isExpanded);
      }
    },

    actions: {
      /**
       * Action control dropdown state(show/hide), add/remove active class if it passed and
       * fire onChange hook to notify subscriber
       */
      toggle() {
        this.toggleProperty('isExpanded');
        this.toggleActiveClass();

        this._onChange();
      },

      /**
       * Action to force close dropdown
       */
      close() {
        this.close();
      },

      /**
       * Action to force open dropdown
       */
      open() {
        this.set('isExpanded', true);
        this.toggleActiveClass();

        this._onChange();
      }

    }
  });

  _exports.default = _default;
});