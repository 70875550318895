define("builder/pods/website/edit/page/edit/create-loading/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * @inheritdoc
     */
    renderTemplate(controller, model) {
      this.render('website.edit.page.create_loading', {
        model,
        controller
      });
    }

  });

  _exports.default = _default;
});