define("builder/core/factories/module-gallery/factory", ["exports", "builder/core/factories/module-gallery/category-decorator", "builder/core/factories/module-gallery/module-decorator", "builder/core/factories/module-gallery/form-decorator", "builder/core/factories/module-gallery/widget-decorator", "builder/core/factories/module-gallery/module-theme-decorator", "builder/core/factories/module-gallery/container-template-decorator"], function (_exports, _categoryDecorator, _moduleDecorator, _formDecorator, _widgetDecorator, _moduleThemeDecorator, _containerTemplateDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Produce module gallery item instance
   *
   * @class ModuleGalleryFactory
   */
  var _default = Ember.Object.extend({
    /**
     * Create category gallery module
     *
     * @param {ModuleCategoryModel} category - module category, to be used as gallery module additional data
     * @param {String} iconClass - icon class for display
     * @returns {GalleryModule}
     */
    createCategory(category) {
      return _categoryDecorator.default.create({
        data: category
      });
    },

    /**
     * Create module gallery module
     *
     * @param {ModuleModel} module - module to be used as gallery module additional data
     * @returns {GalleryModule}
     */
    createModule(module) {
      return _moduleDecorator.default.create({
        accessControl: Ember.getOwner(this).lookup('service:can'),
        data: module
      });
    },

    /**
     * Create form gallery module
     *
     * @param {FormModel} formTemplate - form model to be used as gallery module additional data
     * @param {PageModel} currentPageModel - current page model
     * @returns {FormGalleryModuleDecorator}
     */
    createForm(formTemplate, currentPageModel) {
      return _formDecorator.default.create({
        accessControl: Ember.getOwner(this).lookup('service:can'),
        data: formTemplate,
        currentPageModel
      });
    },

    /**
     * Create widget gallery module
     *
     * @param {WidgetModel} widget - widget to be used as gallery module additional data
     * @param {PageModel} currentPageModel - current page model
     * @returns {WidgetGalleryModuleDecorator}
     */
    createWidget(widget, currentPageModel) {
      return _widgetDecorator.default.create({
        data: widget,
        accessControl: Ember.getOwner(this).lookup('service:can'),
        currentPageModel
      });
    },

    /**
     * Create module theme gallery module
     *
     * @param {ModuleThemeModel} moduleTheme - module theme model to be used as gallery module additional data
     * @param {PageModel} currentPageModel - current page model
     * @returns {ModuleThemeGalleryModuleDecorator}
     */
    createModuleTheme(moduleTheme, currentPageModel) {
      return _moduleThemeDecorator.default.create({
        store: Ember.getOwner(this).lookup('service:store'),
        accessControl: Ember.getOwner(this).lookup('service:can'),
        data: moduleTheme,
        currentPageModel
      });
    },

    /**
     * Create container template
     * @param {ContainerTemplateModel} containerTemplate - container template model
     * @param {PageModel} currentPageModel - current page model
     * @returns {ContainerTemplateGalleryDecorator}
     */
    createContainerTemplate(containerTemplate, currentPageModel) {
      return _containerTemplateDecorator.default.create({
        store: Ember.getOwner(this).lookup('service:store'),
        accessControl: Ember.getOwner(this).lookup('service:can'),
        data: containerTemplate,
        currentPageModel
      });
    }

  });

  _exports.default = _default;
});