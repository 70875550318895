define("builder/pods/components/field-daterange/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "builder/helpers/date-format", "builder/helpers/date-parse"], function (_exports, _fieldValidation, _fieldVisibility, _dateFormat, _dateParse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldDateRangeComponent
   */
  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_date-range'],

    /**
     * @property {String|null} dateStart - date start value
     */
    dateStart: Ember.computed('_dateStart', function () {
      return (0, _dateFormat.formatDate)([this._dateStart]);
    }),

    /**
     * @property {String|null} dateEnd - date end value
     */
    dateEnd: Ember.computed('_dateEnd', function () {
      return (0, _dateFormat.formatDate)([this._dateEnd]);
    }),

    /**
     * @property {String|null} minDate - min date to select, used to restrict dateEnd
     */
    minDate: null,

    /**
     * @property {String|null} maxDate - max date to select, used to restrict dateStart
     */
    maxDate: null,

    /**
     * @property {Boolean} datepickerVisible - is datepicker for dateStart visibile
     */
    datepickerVisible: false,

    /**
     * @property {Date|null} _dateStart - date start object or null
     * @private
     */
    _dateStart: null,

    /**
     * @property {Date|null} _dateEnd - date end object or null
     * @private
     */
    _dateEnd: null,

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      const data = this.get('field.data');
      const dates = data && data.length ? data : [null, null];
      this.set('_dateStart', (0, _dateParse.parseDate)([dates[0]]));
      this.set('_dateEnd', (0, _dateParse.parseDate)([dates[1]]));
    },

    /**
     * @inheritDoc
     */
    didInsertElement() {
      if (this.triggerOnRender && this.get('field.data')) {
        this._callActionHandler();
      }
    },

    /**
     * Compare dates on equality
     *
     * @param {Date} date1 - first date to test against 2-nd
     * @param {Date} date2 - second date to test against 1-st
     * @returns {Boolean}
     * @private
     */
    _datesAreEqual(date1, date2) {
      return date1 && date2 ? date1.getTime() === date2.getTime() : date1 === date2;
    },

    /**
     * Call parent action handler
     * @private
     */
    _callActionHandler() {
      const action = this.actionHandler;

      if (action && !this.notValid) {
        action(this.get('field.data'), this.get('field.name'));
      }
    },

    actions: {
      change(dateStart, dateEnd) {
        let data = null;

        if (dateStart || dateEnd) {
          data = [dateStart, dateEnd];
        }

        this.set('field.data', data);

        this._callActionHandler();
      },

      updateDateStart(date) {
        const dateStart = this._dateStart;
        const dateEnd = this._dateEnd;
        this.set('_dateStart', date);
        this.set('minDate', date || null);

        if (date && dateEnd && date.getTime() > dateEnd.getTime()) {
          this.set('_dateEnd', date);
        }

        if (!this._datesAreEqual(dateStart, date)) {
          // Invoke date change handler
          this.send('change', this.dateStart, this.dateEnd);
        }
      },

      updateDateEnd(date) {
        const dateStart = this._dateStart;
        const dateEnd = this._dateEnd;
        this.set('_dateEnd', date);
        this.set('maxDate', date || null);

        if (date && dateStart && date.getTime() < dateStart.getTime()) {
          this.set('_dateStart', date);
        }

        if (!this._datesAreEqual(dateEnd, date)) {
          // Invoke date change handler
          this.send('change', this.dateStart, this.dateEnd);
        }
      },

      openDatepicker() {
        this.set('datepickerVisible', true);
      }

    }
  });

  _exports.default = _default;
});