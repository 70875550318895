define("builder/core/styles-management/composite-properties/background", ["exports", "builder/core/abstract-classes/composite-property"], function (_exports, _compositeProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Class helps build css property "background" according to css rules
   *
   * @class BackgroundCompositeProperty
   *
   * @TODO Probably need to add validation for "gradient-direction" property
   */
  var _default = _compositeProperty.default.extend({
    /**
     * @inheritdoc
     */
    compositeProperties: Ember.computed(function () {
      return {
        'background-color': null,
        'background-image': null,
        'gradient-direction': null,
        'gradient-color1': null,
        'gradient-color2': null
      };
    }),

    /**
     * @property {String} _gradientFunc - css function name
     * @private
     */
    _gradientFunc: 'linear-gradient',

    /**
     * @inheritdoc
     * @returns {String}
     */
    build() {
      const {
        'background-color': color,
        'gradient-direction': direction,
        'gradient-color1': gColor1,
        'gradient-color2': gColor2
      } = this.compositeProperties;
      const result = [];

      if (color) {
        result.pushObject(`${color}`);
      }

      if (direction && gColor1 && gColor1 !== 'inherit' && gColor2 && gColor2 !== 'inherit') {
        result.pushObject(`${this._gradientFunc}(${direction}, ${gColor1}, ${gColor2})`);
      }

      return result.join(' ');
    },

    /**
     * @inheritdoc
     * @returns {{value: (null|String), background: String}}
     */
    buildForCompositeView() {
      const direction = this.getValue('gradient-direction');
      const image = this.getValue('background-image');
      const result = {
        value: null,
        background: null
      };

      if (direction) {
        // By default direction must be have value "to bottom", old values may be equals "none"
        result.value = direction === 'none' ? 'to bottom' : direction;
      } else if (image) {
        const bgImage = image.split('/').reverse()[0];
        result.value = bgImage !== 'none' ? bgImage : null;
      }

      result.background = this.build();
      return result;
    }

  });

  _exports.default = _default;
});