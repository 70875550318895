define("builder/core/page-conditional-type-provider", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    /**
     * @type {object[]} availableTypes - list of available types
     */
    availableTypes: [],

    /**
     * Build config for page type UI interface
     * @param {object} data - source data for initialize config
     * @returns {object}
     */
    buildConfig(data) {
      const fieldConfig = this._getFieldsConfig(this.availableTypes);

      if (typeof data !== 'undefined' && data !== null && !Number.isNaN(data)) {
        fieldConfig.data = data;
      }

      return fieldConfig;
    },

    /**
     * Find page type definition by value
     * @param {string} value - page type value
     * @returns {object}
     */
    findByValue(value) {
      return this.availableTypes.findBy('value', value);
    },

    /**
     * Get field config
     * @param {object[]} availableTypes - available types
     * @return {object}
     * @private
     */
    _getFieldsConfig(availableTypes) {
      return {
        type: 'field-dropdown',
        label: 'Conditional Type',
        name: 'page-conditional-type',
        data: '',
        placeholder: 'None',
        help: 'Select OEM service/special page you want to appear on a page from the list',
        choices: (0, _emberCopy.copy)(availableTypes, true)
      };
    }

  });

  _exports.default = _default;
});