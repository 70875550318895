define("builder/core/grid-models/base", ["exports", "builder/core/copyable", "builder/core/enumerators/breakpoint-modes"], function (_exports, _copyable, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class BaseGridModel
   */
  var _default = _copyable.default.extend({
    /**
     * @property {Object|null}
     */
    visible: null,

    /**
     * @property {String} visibilityString - property used in order to bind observers (items.@each.slots.@each)
     */
    visibilityString: null,

    /**
     * @property {BaseGridModel[]} dependencyModules - list of modules current module depends on
     */
    dependencyModules: null,

    /**
     * @property {observer} deviceVisibilityObserver - observer to update self visible property based on self
     * originObject.visibleOnDevices property
     */
    deviceVisibilityObserver: Ember.observer(`originObject.visibleOnDevices.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}`, function () {
      var _this$originObject$vi, _this$originObject;

      const visibleOnDevices = (_this$originObject$vi = (_this$originObject = this.originObject) === null || _this$originObject === void 0 ? void 0 : _this$originObject.visibleOnDevices) !== null && _this$originObject$vi !== void 0 ? _this$originObject$vi : null;

      if (!visibleOnDevices) {
        return;
      }
      /**
       * Even though it looks that we just copy visibleOnDevices object as this.visible,
       * we actually need to update only device-specific keys for a current GridModelObject visible property ,
       * That's why we are not just coping it.
       * */


      [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
        Ember.set(this.visible, mode, visibleOnDevices[mode]);
      });
    }).on('init'),

    /**
     * @property {Ember.observer} visibleListObserver - used to notify change on visible property change
     * since @each.@each is not allowed
     */
    visibilityStringUpdateObserver: Ember.observer(`visible.{${_breakpointModes.LARGE},${_breakpointModes.MEDIUM},${_breakpointModes.SMALL},${_breakpointModes.EXTRA_SMALL}}`, function () {
      const list = [];

      if (!this.visible) {
        return;
      }

      Object.keys(this.visible).forEach(key => {
        if (this.visible[key]) {
          list.push(key);
        }
      });
      this.set('visibilityString', list.join(','));
    }).on('init'),

    /**
     * Check if at least 1 child is visible in specified mode
     *
     * @param {Ember.Object[]} children - list of children to check
     * @param {String} mode - mode to validate (lg, md, sm, xs)
     * @returns {Boolean}
     */
    hasVisibleChildrenInMode(children, mode) {
      let visible = false;

      if (![_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].includes(mode)) {
        return visible;
      }

      for (let i = 0, len = children.length; i < len; i++) {
        if (Ember.get(children[i], `visible.${mode}`)) {
          visible = true;
          break;
        }
      }

      return visible;
    },

    getChildrenVisibility(children) {
      const state = {};
      [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
        state[mode] = this.hasVisibleChildrenInMode(children, mode);
      });
      return state;
    },

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('visible', _copyable.default.create({
        [_breakpointModes.LARGE]: true,
        [_breakpointModes.MEDIUM]: true,
        [_breakpointModes.SMALL]: true,
        [_breakpointModes.EXTRA_SMALL]: true
      }));
      this.set('dependencyModules', []);
    }

  });

  _exports.default = _default;
});