define("builder/pods/components/misc-bullet-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MiscBulletSelector = (_dec = Ember.computed('bullets.[]'), _dec2 = Ember.computed('bullets.[]', 'label', 'selectedBullet', 'help'), _dec3 = Ember._action, (_class = (_temp = class MiscBulletSelector extends Ember.Component {
    /**
     * @type {number}
     */

    /**
     * @type {string}
     */

    /**
     * @type {string}
     */

    /**
     * @type {string}
     */

    /**
     * @type {string}
     */

    /**
     * Constructor
     */
    constructor(...args) {
      super(...args);
      this.bullets = null;
      this.selectedBullet = '';
      this.placeholder = '';
      this.label = '';
      this.help = '';
      this.bullets = this.bullets || [];
    }
    /**
     * @type {object}
     */


    get choices() {
      return this.bullets.map(x => ({
        value: x.Code,
        label: x.Label
      }));
    }
    /**
     * @type {object}
     */


    get dropdownOptions() {
      return {
        label: this.label,
        data: this.selectedBullet,
        help: this.help,
        choices: this.choices
      };
    }
    /**
     * Passes selected bullet to parent component
     * @param {string} bulletCode - current selected parent code
     * should be provided through component property
     */


    onBulletSelected(bulletCode) {
      throw new Ember.Error('Method implementation should be provided through component property');
    }

    /**
     * Bullet change handler
     * @param {string} bulletCode - selected bullet code
     */
    onBulletSelect(bulletCode) {
      this.onBulletSelected(bulletCode);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "choices", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "choices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropdownOptions", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dropdownOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBulletSelect", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onBulletSelect"), _class.prototype)), _class));
  _exports.default = MiscBulletSelector;
});