define("builder/pods/components/form-field-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form field component, that could be added to a form
   */
  var _default = Ember.Component.extend({
    /**
     * @property {FormFieldTypeModel} model - form field type model
     */
    model: null,

    /**
     * @property {Boolean} enabled - is field enabled or not
     * @readonly
     */
    enabled: Ember.computed.readOnly('model.enabled'),

    /**
     * @property {String} title - field title
     * @readonly
     */
    title: Ember.computed.readOnly('model.Title'),

    /**
     * @property {String} type - field type
     * @readonly
     */
    type: Ember.computed.readOnly('model.Type'),

    /**
     * @property {String} name - field name
     * @readonly
     */
    name: Ember.computed.readOnly('model.Name'),

    /**
     * @property {Boolean} unique - is field unique
     * @readonly
     */
    unique: Ember.computed.readOnly('model.IsUnique'),

    /**
     * @property {String} tooltip - tooltip to be added to field
     * @readonly
     */
    tooltip: Ember.computed.readOnly('model.disabledReason'),

    /**
     * @property {String} fieldClass - field class to be added to field element
     */
    fieldClass: 'form-field-item',

    /**
     * @property {Boolean} eventsAttached - is event listener attached or not
     */
    eventsAttached: false,

    /**
     * @property {String} onDragStart - action to bubble up when field dragstart event dispatched
     */
    onDragStart: null,

    /**
     * @property {String} onDragEnd - action to bubble up when field dragend event dispatched
     */
    onDragEnd: null,

    /**
     * @inheritdoc
     */
    didRender(...args) {
      this._super(...args);

      if (!this.eventsAttached) {
        this.attachEvents();
        this.set('eventsAttached', true);
      }
    },

    /**
     * Attach event listeners
     *
     * @method attachEvents
     */
    attachEvents() {
      const element = this.$().find(`.${this.fieldClass}`);
      element.on('dragstart', event => {
        const {
          onDragStart
        } = this; // IE & Firefox fix

        event.dataTransfer.setData('Text', 'form-field-type');

        if (onDragStart) {
          onDragStart(this);
        }
      });
      element.on('dragend', event => {
        const {
          onDragEnd
        } = this;

        if (onDragEnd) {
          onDragEnd(this, event);
        }
      });
    }

  });

  _exports.default = _default;
});