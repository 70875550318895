define("builder/core/meta-tags/dom-manipulation/element-contenteditable", ["exports", "builder/core/meta-tags/dom-manipulation/tag-constants", "builder/core/meta-tags/dom-manipulation/element-search"], function (_exports, _tagConstants, _elementSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detectDoubleCurlyPress = detectDoubleCurlyPress;
  _exports.clearKeyHistory = clearKeyHistory;
  _exports.addSpacesForMeta = addSpacesForMeta;

  /**
   * Detects double curly press
   * @param {KeyboardEvent} event - current keyboard event
   * @param {()=>void} callback - function to call on double curly press
   * @returns {void}
   */
  function detectDoubleCurlyPress(event, callback) {
    const {
      target,
      key
    } = event;
    const {
      lastkey
    } = target.dataset;

    if (lastkey && lastkey === key && key === '{') {
      target.dataset.lastkey = null;
      callback();
      return;
    }

    target.dataset.lastkey = key;
  }
  /**
   * Clears last key history
   * Useful for attaching to events other than keyboard events
   * @param {Event} event - event with html element as target
   * @returns {void}
   */


  function clearKeyHistory(event) {
    event.target.dataset.lastkey = null;
  }
  /**
   * Adds space before the first meta and after the last one
   * @param {HTMLElement} contentEditable
   * @param {boolean} addOffset
   */


  function addSpacesForMeta(contentEditable, addOffset = false) {
    var _contentEditable$firs, _contentEditable$last;

    const firstTag = (contentEditable === null || contentEditable === void 0 ? void 0 : (_contentEditable$firs = contentEditable.firstChild) === null || _contentEditable$firs === void 0 ? void 0 : _contentEditable$firs.className) === _tagConstants.MetaTag.className ? contentEditable === null || contentEditable === void 0 ? void 0 : contentEditable.firstChild : null;
    const lastTag = (contentEditable === null || contentEditable === void 0 ? void 0 : (_contentEditable$last = contentEditable.lastChild) === null || _contentEditable$last === void 0 ? void 0 : _contentEditable$last.className) === _tagConstants.MetaTag.className ? contentEditable === null || contentEditable === void 0 ? void 0 : contentEditable.lastChild : null;
    const firstSpaceNode = (0, _elementSearch.createNonBreakingSpace)();
    const secondSpaceNode = (0, _elementSearch.createNonBreakingSpace)(); // If space before the first meta was removed then restore it
    // Or if there is any other node than text node then add text node

    if (firstTag && (!firstTag.previousSibling || firstTag.previousSibling.nodeType !== Node.TEXT_NODE)) {
      firstTag.before(firstSpaceNode);
    }

    if (lastTag && !lastTag.nextSibling) {
      lastTag.parentNode.appendChild(secondSpaceNode);
    } else if (lastTag && lastTag.nextSibling.nodeType !== Node.TEXT_NODE) {
      lastTag.after(secondSpaceNode);
    }
    /**
     * We check content and if the first symbol is a space then add class name
     * to the first meta tag which hides the space by moving left
     */


    if (addOffset && firstTag) {
      const {
        previousSibling
      } = firstTag;

      if (previousSibling && (previousSibling.textContent === ' ' || previousSibling.textContent === _elementSearch.SPACE_SYMBOL)) {
        firstTag.classList.add(_tagConstants.MetaTag.offsetClassName);
      } else {
        firstTag.classList.remove(_tagConstants.MetaTag.offsetClassName);
      }
    }
  }
});