define("builder/pods/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Simple component to show progress bar.
   * By default component is hidden.
   *
   * API:
   * 	isVisible - help to control show/hide state of component
   *
   * Example:
   * 	{{progress-bar isVisible=true}}
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['b2-progress-bar'],

    /**
     * @inheritdoc
     */
    isVisible: false
  });

  _exports.default = _default;
});