define("builder/models/module", ["exports", "@ember-data/model", "builder/models/model", "ember-copy"], function (_exports, _model, _model2, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleModel
   */
  var _default = _model2.default.extend(_emberCopy.Copyable, {
    Name: (0, _model.attr)('string'),
    Label: (0, _model.attr)('string'),
    Description: (0, _model.attr)('string'),
    CategoryId: (0, _model.attr)('string'),
    IsActive: (0, _model.attr)('number'),
    Image: (0, _model.attr)('string'),

    /**
     * For now @date 21/11/2017 we don't use copy
     *
     * @returns {null}
     */
    copy() {
      return null;
    }

  });

  _exports.default = _default;
});