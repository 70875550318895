define("builder/core/factories/module-gallery/module-theme-decorator", ["exports", "builder/core/factories/module-gallery/gallery-module", "builder/core/enumerators/module", "builder/core/literals"], function (_exports, _galleryModule, _module, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleThemeGalleryModuleDecorator
   */
  var _default = _galleryModule.default.extend({
    /**
     * @inheritdoc
     */
    componentName: 'gallery-theme',

    /**
     * @inheritdoc
     */
    type: _module.default.MODULE_CORE,

    /**
     * @inheritdoc
     * @readonly
     */
    image: Ember.computed.readOnly('data.Image'),

    /**
     * @inheritdoc
     * @readonly
     */
    title: Ember.computed.readOnly('data.Label'),

    /**
     * @inheritdoc
     * @readonly
     */
    label: Ember.computed.readOnly('parent.data.Label'),

    /**
     * @inheritdoc
     * @readonly
     */
    description: Ember.computed.readOnly('data.Description'),

    /**
     * Store service
     * @type {StoreService|null}
     */
    store: null,

    /**
     * Current page model
     * @type {PageModel|null}
     */
    currentPageModel: null,

    /**
     * Result of check usage ability
     * @type {{isGranted:boolean, message:string}}
     * @readonly
     */
    usageAbility: Ember.computed('accessControl', 'currentPageModel.isGlobal', function () {
      const module = this.store.peekRecord('module', this.data.ModuleId);
      const aclAbility = module ? this.accessControl.can('create module-gallery', null, {
        name: module.Name.toLowerCase()
      }) : {
        isGranted: true
      };
      const globalPageUsageAbility = this.currentPageModel.isGlobal ? {
        isGranted: this.data.isGlobalPageCompatible,
        message: _literals.default.GLOBAL_PAGE_USAGE_RESTRICTION
      } : {
        isGranted: true
      };

      if (!aclAbility.isGranted) {
        return aclAbility;
      }

      return globalPageUsageAbility;
    }).readOnly(),

    /**
     * Is enabled
     * @type {boolean}
     * @readonly
     */
    enabled: Ember.computed.readOnly('usageAbility.isGranted'),

    /**
     * Error message that indicates the reason is not enabled
     * @type {string}
     * @readonly
     */
    errorMessage: Ember.computed('usageAbility.isGranted', function () {
      return this.usageAbility.isGranted ? '' : this.usageAbility.message;
    }).readOnly(),

    /**
     * @inheritdoc
     */
    getInsertData() {
      // Yep, since we dont have relations in our models, this is temp solution, when time will come, come up with better solution
      const module = this.parent && this.parent.data;

      if (!module) {
        throw new Ember.Error(`Looks like relations are not set for theme ${JSON.stringify(this.data)}`);
      }

      return {
        theme: this.data.Name,
        name: module.Name
      };
    },

    /**
     * @inheritdoc
     */
    satisfiesSearch(searchRegex) {
      return searchRegex.test(this.title) || searchRegex.test(this.data.Name) || searchRegex.test(this.data.Tags) || searchRegex.test(this.data.Description);
    },

    /**
     * filter by title
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByTitle(searchRegex) {
      return searchRegex.test(this.title);
    },

    /**
     * filter by tag
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByTag(searchRegex) {
      return searchRegex.test(this.data.Tags);
    },

    /**
     * filter by description
     * @param {string} searchRegex - search string
     * @returns {Object[]}
     */
    satisfiesSearchByDescription(searchRegex) {
      return searchRegex.test(this.data.Description);
    }

  });

  _exports.default = _default;
});