define("builder/core/froala/editor-factory", ["exports", "froala-editor"], function (_exports, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createFroalaEditor = createFroalaEditor;

  function createFroalaEditor(editorElement, onEditorInitialized, froalaSettings) {
    const settings = { ...froalaSettings,
      events: {
        initialized: onEditorInitialized
      }
    };
    return new _froalaEditor.default(editorElement, settings);
  }
});