define("builder/pods/components/tab-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage: see "tabs-manager" component
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['tabs__content'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isActive:tabs__content_opened'],

    /**
     * Property contain name of current tab content
     * which bind button same name
     *
     * @property {String|null} name
     */
    name: null,

    /**
     * Property contain ref on "tabs-manager" component
     *
     * @property {Component|null} manager
     */
    manager: null,

    /**
     * Property contain tab content state(active/inactive)
     *
     * @property {Boolean} isActive
     */
    isActive: Ember.computed('manager.active', function () {
      return this.get('manager.active') === this.name;
    })
  });

  _exports.default = _default;
});