define("builder/core/styles-management/structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-check

  /**
   * Style interface for website styles
   * @type {CssVariable[]}
   */
  const WebsiteStyleInterface = [{
    name: 'group',
    label: 'Colors',
    namespace: 'Colors',
    children: [{
      variableName: 'primary-color',
      cssPropertyName: 'color',
      label: 'Primary Color'
    }, {
      variableName: 'primary-color-hover',
      cssPropertyName: 'color',
      label: 'Primary Color (Hover)'
    }, {
      variableName: 'secondary-color',
      cssPropertyName: 'color',
      label: 'Secondary Color'
    }, {
      variableName: 'secondary-color-hover',
      cssPropertyName: 'color',
      label: 'Secondary Color (Hover)'
    }, {
      variableName: 'line-color',
      cssPropertyName: 'color',
      label: 'Solid/Dashed Line'
    }, {
      variableName: 'solid-box-color',
      cssPropertyName: 'color',
      label: 'Solid Box'
    }, {
      variableName: 'structure-color',
      cssPropertyName: 'color',
      label: 'Structure'
    }]
  }, {
    name: 'group',
    label: 'Text',
    namespace: 'Text',
    children: [{
      variableName: 'default-text-family',
      cssPropertyName: 'font-family',
      label: 'Primary Font Family'
    }, {
      variableName: 'headings-text-family',
      cssPropertyName: 'font-family',
      label: 'Heading Font Family'
    }, {
      variableName: 'icons-font',
      name: 'icons-font',
      label: 'Font Icons'
    }, {
      name: 'group',
      label: 'Default text',
      children: [{
        variableName: 'default-text-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'default-text-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'default-letter-spacing',
        cssPropertyName: 'letter-spacing',
        label: 'Letter Spacing'
      }]
    }, {
      name: 'group',
      label: 'Paragraph',
      children: [{
        variableName: 'paragraph-margin-bottom',
        cssPropertyName: 'margin-bottom',
        label: 'Margin Bottom'
      }]
    }, {
      name: 'group',
      label: 'Text sizes',
      children: [{
        name: 'conjunction',
        children: [{
          label: 'Small',
          children: [{
            variableName: 'small-text-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'small-text-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'small-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }]
        }, {
          label: 'Extra small',
          children: [{
            variableName: 'extra-small-text-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'extra-small-text-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'extra-small-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }]
        }]
      }]
    }, {
      name: 'group',
      label: 'List',
      children: [{
        name: 'conjunction',
        children: [{
          label: 'Ordered',
          children: [{
            name: 'composite',
            label: 'Padding',
            type: 'rectangle',
            children: [{
              variableName: 'ordered-list-padding-top',
              cssPropertyName: 'padding-top',
              label: 'Top'
            }, {
              variableName: 'ordered-list-padding-right',
              cssPropertyName: 'padding-right',
              label: 'Right'
            }, {
              variableName: 'ordered-list-padding-bottom',
              cssPropertyName: 'padding-bottom',
              label: 'Bottom'
            }, {
              variableName: 'ordered-list-padding-left',
              cssPropertyName: 'padding-left',
              label: 'Left'
            }]
          }, {
            variableName: 'ordered-list-bottom-margin',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }, {
          label: 'Unordered',
          children: [{
            name: 'composite',
            label: 'Padding',
            type: 'rectangle',
            children: [{
              variableName: 'unordered-list-padding-top',
              cssPropertyName: 'padding-top',
              label: 'Top'
            }, {
              variableName: 'unordered-list-padding-right',
              cssPropertyName: 'padding-right',
              label: 'Right'
            }, {
              variableName: 'unordered-list-padding-bottom',
              cssPropertyName: 'padding-bottom',
              label: 'Bottom'
            }, {
              variableName: 'unordered-list-padding-left',
              cssPropertyName: 'padding-left',
              label: 'Left'
            }]
          }, {
            variableName: 'unordered-list-bottom-margin',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }]
      }]
    }, {
      name: 'group',
      label: 'Breadcrumbs',
      children: [{
        variableName: 'breadcrumbs-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'breadcrumbs-letter-spacing',
        cssPropertyName: 'letter-spacing',
        label: 'Letter Spacing'
      }, {
        variableName: 'breadcrumbs-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        name: 'composite',
        label: 'Margin',
        type: 'rectangle',
        children: [{
          variableName: 'breadcrumbs-margin-top',
          cssPropertyName: 'margin-top',
          label: 'Top'
        }, {
          variableName: 'breadcrumbs-margin-right',
          cssPropertyName: 'margin-right',
          label: 'Right'
        }, {
          variableName: 'breadcrumbs-margin-bottom',
          cssPropertyName: 'margin-bottom',
          label: 'Bottom'
        }, {
          variableName: 'breadcrumbs-margin-left',
          cssPropertyName: 'margin-left',
          label: 'Left'
        }]
      }]
    }, {
      name: 'group',
      label: 'Link',
      children: [{
        variableName: 'link-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'link-hover-color',
        cssPropertyName: 'color',
        label: 'Hover Color'
      }, {
        variableName: 'link-hover-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Hover Decoration'
      }]
    }]
  }, {
    name: 'group',
    label: 'Heading',
    namespace: 'Headings',
    children: [{
      variableName: 'headings-color',
      cssPropertyName: 'color',
      label: 'Font Color'
    }, {
      variableName: 'headings-line-height',
      cssPropertyName: 'line-height',
      label: 'Line Height'
    }, {
      variableName: 'headings-text-decoration',
      cssPropertyName: 'text-decoration',
      label: 'Text Decoration'
    }, {
      variableName: 'headings-font-style',
      cssPropertyName: 'font-style',
      label: 'Font Style'
    }, {
      variableName: 'headings-font-weight',
      cssPropertyName: 'font-weight',
      label: 'Font Weight'
    }, {
      variableName: 'headings-text-transform',
      cssPropertyName: 'text-transform',
      label: 'Text Transform'
    }, {
      name: 'group',
      label: 'Heading styles',
      children: [{
        name: 'conjunction',
        children: [{
          label: 'H1',
          children: [{
            variableName: 'h1-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'h1-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }, {
            variableName: 'h1-padding-top',
            cssPropertyName: 'padding-top',
            label: 'Margin Top'
          }, {
            variableName: 'h1-margin-bottom',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }, {
          label: 'H2',
          children: [{
            variableName: 'h2-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'h2-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }, {
            variableName: 'h2-padding-top',
            cssPropertyName: 'padding-top',
            label: 'Margin Top'
          }, {
            variableName: 'h2-margin-bottom',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }, {
          label: 'H3',
          children: [{
            variableName: 'h3-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'h3-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }, {
            variableName: 'h3-padding-top',
            cssPropertyName: 'padding-top',
            label: 'Margin Top'
          }, {
            variableName: 'h3-margin-bottom',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }, {
          label: 'H4',
          children: [{
            variableName: 'h4-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'h4-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }, {
            variableName: 'h4-padding-top',
            cssPropertyName: 'padding-top',
            label: 'Margin Top'
          }, {
            variableName: 'h4-margin-bottom',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }, {
          label: 'H5',
          children: [{
            variableName: 'h5-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'h5-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }, {
            variableName: 'h5-padding-top',
            cssPropertyName: 'padding-top',
            label: 'Margin Top'
          }, {
            variableName: 'h5-margin-bottom',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }, {
          label: 'H6',
          children: [{
            variableName: 'h6-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            variableName: 'h6-letter-spacing',
            cssPropertyName: 'letter-spacing',
            label: 'Letter Spacing'
          }, {
            variableName: 'h6-padding-top',
            cssPropertyName: 'padding-top',
            label: 'Margin Top'
          }, {
            variableName: 'h6-margin-bottom',
            cssPropertyName: 'margin-bottom',
            label: 'Margin Bottom'
          }]
        }]
      }]
    }]
  }, {
    name: 'group',
    label: 'Buttons',
    namespace: 'Buttons',
    children: [{
      name: 'group',
      label: 'Button Styles',
      children: [{
        name: 'conjunction',
        children: [{
          label: 'Primary',
          children: [{
            variableName: 'primary-button-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'primary-button-style',
            cssPropertyName: 'font-style',
            label: 'Font Style'
          }, {
            variableName: 'primary-button-font-weight',
            cssPropertyName: 'font-weight',
            label: 'Font Weight'
          }, {
            variableName: 'primary-button-text-decoration',
            cssPropertyName: 'text-decoration',
            label: 'Text Decoration'
          }, {
            variableName: 'primary-button-text-transform',
            cssPropertyName: 'text-transform',
            label: 'Text Transform'
          }, {
            variableName: 'primary-button-hover-color',
            cssPropertyName: 'color',
            label: 'Text Hover Color'
          }, {
            name: 'composite',
            label: 'Border',
            type: 'border',
            resolve: ['primary-button-border-color'],
            children: [{
              variableName: 'primary-button-border-width',
              cssPropertyName: 'border-width',
              label: 'Width'
            }, {
              variableName: 'primary-button-border-style',
              cssPropertyName: 'border-style',
              label: 'Style'
            }, {
              variableName: 'primary-button-border-color',
              cssPropertyName: 'border-color',
              label: 'Color'
            }, {
              variableName: 'primary-button-border-radius',
              cssPropertyName: 'border-radius',
              label: 'Radius'
            }, {
              variableName: 'primary-button-hover-border-color',
              cssPropertyName: 'border-color',
              label: 'Hover Color'
            }]
          }, {
            name: 'composite',
            label: 'Background',
            type: 'background',
            children: [{
              variableName: 'primary-button-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'primary-button-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'primary-button-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'primary-button-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }, {
            name: 'composite',
            label: 'Background Hover',
            type: 'background',
            children: [{
              variableName: 'primary-button-hover-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'primary-button-hover-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'primary-button-hover-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'primary-button-hover-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }]
        }, {
          label: 'Secondary',
          children: [{
            variableName: 'default-button-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'default-button-style',
            cssPropertyName: 'font-style',
            label: 'Font Style'
          }, {
            variableName: 'default-button-font-weight',
            cssPropertyName: 'font-weight',
            label: 'Font Weight'
          }, {
            variableName: 'default-button-text-decoration',
            cssPropertyName: 'text-decoration',
            label: 'Text Decoration'
          }, {
            variableName: 'default-button-text-transform',
            cssPropertyName: 'text-transform',
            label: 'Text Transform'
          }, {
            variableName: 'default-button-hover-color',
            cssPropertyName: 'color',
            label: 'Text Hover Color'
          }, {
            name: 'composite',
            label: 'Border',
            type: 'border',
            resolve: ['default-button-border-color'],
            children: [{
              variableName: 'default-button-border-width',
              cssPropertyName: 'border-width',
              label: 'Width'
            }, {
              variableName: 'default-button-border-style',
              cssPropertyName: 'border-style',
              label: 'Style'
            }, {
              variableName: 'default-button-border-color',
              cssPropertyName: 'border-color',
              label: 'Color'
            }, {
              variableName: 'default-button-border-radius',
              cssPropertyName: 'border-radius',
              label: 'Radius'
            }, {
              variableName: 'default-button-hover-border-color',
              cssPropertyName: 'border-color',
              label: 'Hover Color'
            }]
          }, {
            name: 'composite',
            label: 'Background',
            type: 'background',
            children: [{
              variableName: 'default-button-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'default-button-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'default-button-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'default-button-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }, {
            name: 'composite',
            label: 'Background Hover',
            type: 'background',
            children: [{
              variableName: 'default-button-hover-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'default-button-hover-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'default-button-hover-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'default-button-hover-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }]
        }, {
          label: '3',
          children: [{
            variableName: 'tertiary-button-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'tertiary-button-style',
            cssPropertyName: 'font-style',
            label: 'Font Style'
          }, {
            variableName: 'tertiary-button-font-weight',
            cssPropertyName: 'font-weight',
            label: 'Font Weight'
          }, {
            variableName: 'tertiary-button-text-decoration',
            cssPropertyName: 'text-decoration',
            label: 'Text Decoration'
          }, {
            variableName: 'tertiary-button-text-transform',
            cssPropertyName: 'text-transform',
            label: 'Text Transform'
          }, {
            variableName: 'tertiary-button-hover-color',
            cssPropertyName: 'color',
            label: 'Text Hover Color'
          }, {
            name: 'composite',
            label: 'Border',
            type: 'border',
            resolve: ['tertiary-button-border-color'],
            children: [{
              variableName: 'tertiary-button-border-width',
              cssPropertyName: 'border-width',
              label: 'Width'
            }, {
              variableName: 'tertiary-button-border-style',
              cssPropertyName: 'border-style',
              label: 'Style'
            }, {
              variableName: 'tertiary-button-border-color',
              cssPropertyName: 'border-color',
              label: 'Color'
            }, {
              variableName: 'tertiary-button-border-radius',
              cssPropertyName: 'border-radius',
              label: 'Radius'
            }, {
              variableName: 'tertiary-button-hover-border-color',
              cssPropertyName: 'border-color',
              label: 'Hover Color'
            }]
          }, {
            name: 'composite',
            label: 'Background',
            type: 'background',
            children: [{
              variableName: 'tertiary-button-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'tertiary-button-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'tertiary-button-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'tertiary-button-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }, {
            name: 'composite',
            label: 'Background Hover',
            type: 'background',
            children: [{
              variableName: 'tertiary-button-hover-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'tertiary-button-hover-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'tertiary-button-hover-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'tertiary-button-hover-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }]
        }, {
          label: '4',
          children: [{
            variableName: 'quaternary-button-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'quaternary-button-style',
            cssPropertyName: 'font-style',
            label: 'Font Style'
          }, {
            variableName: 'quaternary-button-font-weight',
            cssPropertyName: 'font-weight',
            label: 'Font Weight'
          }, {
            variableName: 'quaternary-button-text-decoration',
            cssPropertyName: 'text-decoration',
            label: 'Text Decoration'
          }, {
            variableName: 'quaternary-button-text-transform',
            cssPropertyName: 'text-transform',
            label: 'Text Transform'
          }, {
            variableName: 'quaternary-button-hover-color',
            cssPropertyName: 'color',
            label: 'Text Hover Color'
          }, {
            name: 'composite',
            label: 'Border',
            type: 'border',
            resolve: ['quaternary-button-border-color'],
            children: [{
              variableName: 'quaternary-button-border-width',
              cssPropertyName: 'border-width',
              label: 'Width'
            }, {
              variableName: 'quaternary-button-border-style',
              cssPropertyName: 'border-style',
              label: 'Style'
            }, {
              variableName: 'quaternary-button-border-color',
              cssPropertyName: 'border-color',
              label: 'Color'
            }, {
              variableName: 'quaternary-button-border-radius',
              cssPropertyName: 'border-radius',
              label: 'Radius'
            }, {
              variableName: 'quaternary-button-hover-border-color',
              cssPropertyName: 'border-color',
              label: 'Hover Color'
            }]
          }, {
            name: 'composite',
            label: 'Background',
            type: 'background',
            children: [{
              variableName: 'quaternary-button-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'quaternary-button-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'quaternary-button-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'quaternary-button-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }, {
            name: 'composite',
            label: 'Background Hover',
            type: 'background',
            children: [{
              variableName: 'quaternary-button-hover-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'quaternary-button-hover-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'quaternary-button-hover-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'quaternary-button-hover-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }]
        }, {
          label: '5',
          children: [{
            variableName: 'quinary-button-color',
            cssPropertyName: 'color',
            label: 'Font Color'
          }, {
            variableName: 'quinary-button-style',
            cssPropertyName: 'font-style',
            label: 'Font Style'
          }, {
            variableName: 'quinary-button-font-weight',
            cssPropertyName: 'font-weight',
            label: 'Font Weight'
          }, {
            variableName: 'quinary-button-text-decoration',
            cssPropertyName: 'text-decoration',
            label: 'Text Decoration'
          }, {
            variableName: 'quinary-button-text-transform',
            cssPropertyName: 'text-transform',
            label: 'Text Transform'
          }, {
            variableName: 'quinary-button-hover-color',
            cssPropertyName: 'color',
            label: 'Text Hover Color'
          }, {
            name: 'composite',
            label: 'Border',
            type: 'border',
            resolve: ['quinary-button-border-color'],
            children: [{
              variableName: 'quinary-button-border-width',
              cssPropertyName: 'border-width',
              label: 'Width'
            }, {
              variableName: 'quinary-button-border-style',
              cssPropertyName: 'border-style',
              label: 'Style'
            }, {
              variableName: 'quinary-button-border-color',
              cssPropertyName: 'border-color',
              label: 'Color'
            }, {
              variableName: 'quinary-button-border-radius',
              cssPropertyName: 'border-radius',
              label: 'Radius'
            }, {
              variableName: 'quinary-button-hover-border-color',
              cssPropertyName: 'border-color',
              label: 'Hover Color'
            }]
          }, {
            name: 'composite',
            label: 'Background',
            type: 'background',
            children: [{
              variableName: 'quinary-button-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'quinary-button-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'quinary-button-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'quinary-button-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }, {
            name: 'composite',
            label: 'Background Hover',
            type: 'background',
            children: [{
              variableName: 'quinary-button-hover-background-color',
              cssPropertyName: 'background-color',
              label: 'Color'
            }, {
              name: 'composite',
              label: 'Gradient',
              type: 'background',
              children: [{
                variableName: 'quinary-button-hover-background-gradient-direction',
                cssPropertyName: 'gradient-direction',
                label: 'Direction'
              }, {
                variableName: 'quinary-button-hover-background-gradient-color1',
                cssPropertyName: 'gradient-color1',
                label: 'Color 1'
              }, {
                variableName: 'quinary-button-hover-background-gradient-color2',
                cssPropertyName: 'gradient-color2',
                label: 'Color 2'
              }]
            }]
          }]
        }]
      }]
    }, {
      name: 'group',
      label: 'Button sizes',
      children: [{
        name: 'conjunction',
        children: [{
          label: 'Default',
          children: [{
            variableName: 'default-button-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            name: 'composite',
            label: 'Margin',
            type: 'rectangle',
            children: [{
              variableName: 'default-button-margin-top',
              cssPropertyName: 'margin-top',
              label: 'Top'
            }, {
              variableName: 'default-button-margin-right',
              cssPropertyName: 'margin-right',
              label: 'Right'
            }, {
              variableName: 'default-button-margin-bottom',
              cssPropertyName: 'margin-bottom',
              label: 'Bottom'
            }, {
              variableName: 'default-button-margin-left',
              cssPropertyName: 'margin-left',
              label: 'Left'
            }]
          }, {
            name: 'composite',
            label: 'Padding',
            type: 'rectangle',
            children: [{
              variableName: 'default-button-padding-top',
              cssPropertyName: 'padding-top',
              label: 'Top'
            }, {
              variableName: 'default-button-padding-right',
              cssPropertyName: 'padding-right',
              label: 'Right'
            }, {
              variableName: 'default-button-padding-bottom',
              cssPropertyName: 'padding-bottom',
              label: 'Bottom'
            }, {
              variableName: 'default-button-padding-left',
              cssPropertyName: 'padding-left',
              label: 'Left'
            }]
          }]
        }, {
          label: 'Extra Large',
          children: [{
            variableName: 'extra-large-button-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            name: 'composite',
            label: 'Margin',
            type: 'rectangle',
            children: [{
              variableName: 'extra-large-button-margin-top',
              cssPropertyName: 'margin-top',
              label: 'Top'
            }, {
              variableName: 'extra-large-button-margin-right',
              cssPropertyName: 'margin-right',
              label: 'Right'
            }, {
              variableName: 'extra-large-button-margin-bottom',
              cssPropertyName: 'margin-bottom',
              label: 'Bottom'
            }, {
              variableName: 'extra-large-button-margin-left',
              cssPropertyName: 'margin-left',
              label: 'Left'
            }]
          }, {
            name: 'composite',
            label: 'Padding',
            type: 'rectangle',
            children: [{
              variableName: 'extra-large-button-padding-top',
              cssPropertyName: 'padding-top',
              label: 'Top'
            }, {
              variableName: 'extra-large-button-padding-right',
              cssPropertyName: 'padding-right',
              label: 'Right'
            }, {
              variableName: 'extra-large-button-padding-bottom',
              cssPropertyName: 'padding-bottom',
              label: 'Bottom'
            }, {
              variableName: 'extra-large-button-padding-left',
              cssPropertyName: 'padding-left',
              label: 'Left'
            }]
          }]
        }, {
          label: 'Large',
          children: [{
            variableName: 'large-button-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            name: 'composite',
            label: 'Margin',
            type: 'rectangle',
            children: [{
              variableName: 'large-button-margin-top',
              cssPropertyName: 'margin-top',
              label: 'Top'
            }, {
              variableName: 'large-button-margin-right',
              cssPropertyName: 'margin-right',
              label: 'Right'
            }, {
              variableName: 'large-button-margin-bottom',
              cssPropertyName: 'margin-bottom',
              label: 'Bottom'
            }, {
              variableName: 'large-button-margin-left',
              cssPropertyName: 'margin-left',
              label: 'Left'
            }]
          }, {
            name: 'composite',
            label: 'Padding',
            type: 'rectangle',
            children: [{
              variableName: 'large-button-padding-top',
              cssPropertyName: 'padding-top',
              label: 'Top'
            }, {
              variableName: 'large-button-padding-right',
              cssPropertyName: 'padding-right',
              label: 'Right'
            }, {
              variableName: 'large-button-padding-bottom',
              cssPropertyName: 'padding-bottom',
              label: 'Bottom'
            }, {
              variableName: 'large-button-padding-left',
              cssPropertyName: 'padding-left',
              label: 'Left'
            }]
          }]
        }, {
          label: 'Small',
          children: [{
            variableName: 'small-button-font-size',
            cssPropertyName: 'font-size',
            label: 'Font Size'
          }, {
            name: 'composite',
            label: 'Margin',
            type: 'rectangle',
            children: [{
              variableName: 'small-button-margin-top',
              cssPropertyName: 'margin-top',
              label: 'Top'
            }, {
              variableName: 'small-button-margin-right',
              cssPropertyName: 'margin-right',
              label: 'Right'
            }, {
              variableName: 'small-button-margin-bottom',
              cssPropertyName: 'margin-bottom',
              label: 'Bottom'
            }, {
              variableName: 'small-button-margin-left',
              cssPropertyName: 'margin-left',
              label: 'Left'
            }]
          }, {
            name: 'composite',
            label: 'Padding',
            type: 'rectangle',
            children: [{
              variableName: 'small-button-padding-top',
              cssPropertyName: 'padding-top',
              label: 'Top'
            }, {
              variableName: 'small-button-padding-right',
              cssPropertyName: 'padding-right',
              label: 'Right'
            }, {
              variableName: 'small-button-padding-bottom',
              cssPropertyName: 'padding-bottom',
              label: 'Bottom'
            }, {
              variableName: 'small-button-padding-left',
              cssPropertyName: 'padding-left',
              label: 'Left'
            }]
          }]
        }]
      }]
    }]
  }, {
    name: 'group',
    label: 'Forms',
    namespace: 'Forms',
    children: [{
      name: 'group',
      label: 'Input',
      children: [{
        variableName: 'input-font-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'input-font-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'input-font-style',
        cssPropertyName: 'font-style',
        label: 'Font Style'
      }, {
        variableName: 'input-font-weight',
        cssPropertyName: 'font-weight',
        label: 'Font Weight'
      }, {
        variableName: 'input-text-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Text Decoration'
      }, {
        variableName: 'input-text-transform',
        cssPropertyName: 'text-transform',
        label: 'Text Transform'
      }, {
        name: 'composite',
        label: 'Border',
        type: 'border',
        children: [{
          variableName: 'input-border-width',
          cssPropertyName: 'border-width',
          label: 'Width'
        }, {
          variableName: 'input-border-style',
          cssPropertyName: 'border-style',
          label: 'Style'
        }, {
          variableName: 'input-active-border-style',
          cssPropertyName: 'border-style',
          label: 'Active Border Style'
        }, {
          variableName: 'input-border-color',
          cssPropertyName: 'border-color',
          label: 'Color'
        }, {
          variableName: 'input-active-border-color',
          cssPropertyName: 'border-color',
          label: 'Active border color'
        }, {
          variableName: 'input-border-radius',
          cssPropertyName: 'border-radius',
          label: 'Radius'
        }]
      }, {
        variableName: 'input-background-color',
        cssPropertyName: 'background-color',
        label: 'Background'
      }, {
        variableName: 'input-active-background-color',
        cssPropertyName: 'background-color',
        label: 'Active Background Color'
      }, {
        name: 'composite',
        type: 'rectangle',
        label: 'Padding',
        children: [{
          variableName: 'input-padding-top',
          cssPropertyName: 'padding-top',
          label: 'Top'
        }, {
          variableName: 'input-padding-right',
          cssPropertyName: 'padding-right',
          label: 'Right'
        }, {
          variableName: 'input-padding-bottom',
          cssPropertyName: 'padding-bottom',
          label: 'Bottom'
        }, {
          variableName: 'input-padding-left',
          cssPropertyName: 'padding-left',
          label: 'Left'
        }]
      }]
    }, {
      name: 'group',
      label: 'label',
      children: [{
        variableName: 'label-font-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'label-font-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'label-font-style',
        cssPropertyName: 'font-style',
        label: 'Font Style'
      }, {
        variableName: 'label-font-weight',
        cssPropertyName: 'font-weight',
        label: 'Font Weight'
      }, {
        variableName: 'label-text-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Text Decoration'
      }, {
        variableName: 'label-text-transform',
        cssPropertyName: 'text-transform',
        label: 'Text Transform'
      }]
    }, {
      name: 'group',
      label: 'placeholder',
      children: [{
        variableName: 'placeholder-font-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'placeholder-font-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'placeholder-font-style',
        cssPropertyName: 'font-style',
        label: 'Font Style'
      }, {
        variableName: 'placeholder-font-weight',
        cssPropertyName: 'font-weight',
        label: 'Font Weight'
      }, {
        variableName: 'placeholder-text-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Text Decoration'
      }, {
        variableName: 'placeholder-text-transform',
        cssPropertyName: 'text-transform',
        label: 'Text Transform'
      }]
    }, {
      name: 'group',
      label: 'fieldset',
      children: [{
        variableName: 'fieldset-legend-font-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'fieldset-legend-font-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'fieldset-legend-font-style',
        cssPropertyName: 'font-style',
        label: 'Font Style'
      }, {
        variableName: 'fieldset-legend-font-weight',
        cssPropertyName: 'font-weight',
        label: 'Font Weight'
      }, {
        variableName: 'fieldset-legend-text-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Text Decoration'
      }, {
        variableName: 'fieldset-legend-text-transform',
        cssPropertyName: 'text-transform',
        label: 'Text Transform'
      }, {
        name: 'composite',
        type: 'rectangle',
        label: 'Padding',
        children: [{
          variableName: 'fieldset-padding-top',
          cssPropertyName: 'padding-top',
          label: 'Top'
        }, {
          variableName: 'fieldset-padding-right',
          cssPropertyName: 'padding-right',
          label: 'Right'
        }, {
          variableName: 'fieldset-padding-bottom',
          cssPropertyName: 'padding-bottom',
          label: 'Bottom'
        }, {
          variableName: 'fieldset-padding-left',
          cssPropertyName: 'padding-left',
          label: 'Left'
        }]
      }, {
        name: 'composite',
        label: 'Margin',
        type: 'rectangle',
        children: [{
          variableName: 'fieldset-margin-top',
          cssPropertyName: 'margin-top',
          label: 'Top'
        }, {
          variableName: 'fieldset-margin-right',
          cssPropertyName: 'margin-right',
          label: 'Right'
        }, {
          variableName: 'fieldset-margin-bottom',
          cssPropertyName: 'margin-bottom',
          label: 'Bottom'
        }, {
          variableName: 'fieldset-margin-left',
          cssPropertyName: 'margin-left',
          label: 'Left'
        }]
      }, {
        name: 'composite',
        label: 'Border',
        type: 'border',
        children: [{
          variableName: 'fieldset-border-width',
          cssPropertyName: 'border-width',
          label: 'Width'
        }, {
          variableName: 'fieldset-border-style',
          cssPropertyName: 'border-style',
          label: 'Style'
        }, {
          variableName: 'fieldset-border-radius',
          cssPropertyName: 'border-radius',
          label: 'Radius'
        }, {
          variableName: 'fieldset-border-color',
          cssPropertyName: 'border-color',
          label: 'Color'
        }]
      }, {
        variableName: 'fieldset-background-color',
        cssPropertyName: 'background-color',
        label: 'Background Color'
      }]
    }, {
      name: 'group',
      label: 'validation',
      children: [{
        variableName: 'error-color',
        cssPropertyName: 'color',
        label: 'Error Color'
      }, {
        variableName: 'success-color',
        cssPropertyName: 'color',
        label: 'Success Color'
      }]
    }]
  }, {
    name: 'group',
    label: 'Panels',
    namespace: 'Panels',
    children: [{
      variableName: 'fly-out-panel-background-color',
      cssPropertyName: 'background',
      label: 'Layout Background'
    }, {
      name: 'group',
      label: 'Tooltip',
      children: [{
        variableName: 'tooltip-font-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'tooltip-font-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'tooltip-font-style',
        cssPropertyName: 'font-style',
        label: 'Font Style'
      }, {
        variableName: 'tooltip-font-weight',
        cssPropertyName: 'font-weight',
        label: 'Font Weight'
      }, {
        variableName: 'tooltip-text-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Text Decoration'
      }, {
        variableName: 'tooltip-text-transform',
        cssPropertyName: 'text-transform',
        label: 'Text Transform'
      }, {
        name: 'composite',
        label: 'Border',
        type: 'border',
        children: [{
          variableName: 'tooltip-border-width',
          cssPropertyName: 'border-width',
          label: 'Width'
        }, {
          variableName: 'tooltip-border-style',
          cssPropertyName: 'border-style',
          label: 'Style'
        }, {
          variableName: 'tooltip-border-color',
          cssPropertyName: 'border-color',
          label: 'Color'
        }, {
          variableName: 'tooltip-border-radius',
          cssPropertyName: 'border-radius',
          label: 'Radius'
        }]
      }, {
        variableName: 'tooltip-background-color',
        cssPropertyName: 'background-color',
        label: 'Background'
      }]
    }, {
      name: 'group',
      label: 'Popovers',
      children: [{
        variableName: 'popovers-font-color',
        cssPropertyName: 'color',
        label: 'Font Color'
      }, {
        variableName: 'popovers-font-size',
        cssPropertyName: 'font-size',
        label: 'Font Size'
      }, {
        variableName: 'popovers-font-style',
        cssPropertyName: 'font-style',
        label: 'Font Style'
      }, {
        variableName: 'popovers-font-weight',
        cssPropertyName: 'font-weight',
        label: 'Font Weight'
      }, {
        variableName: 'popovers-text-decoration',
        cssPropertyName: 'text-decoration',
        label: 'Text Decoration'
      }, {
        variableName: 'popovers-text-transform',
        cssPropertyName: 'text-transform',
        label: 'Text Transform'
      }, {
        name: 'composite',
        label: 'Border',
        type: 'border',
        children: [{
          variableName: 'popovers-border-width',
          cssPropertyName: 'border-width',
          label: 'Width'
        }, {
          variableName: 'popovers-border-style',
          cssPropertyName: 'border-style',
          label: 'Style'
        }, {
          variableName: 'popovers-border-color',
          cssPropertyName: 'border-color',
          label: 'Color'
        }, {
          variableName: 'popovers-border-radius',
          cssPropertyName: 'border-radius',
          label: 'Radius'
        }]
      }, {
        variableName: 'popovers-background-color',
        cssPropertyName: 'background-color',
        label: 'Background'
      }]
    }]
  }, {
    name: 'group',
    label: 'Overlays',
    namespace: 'Overlays',
    children: [{
      variableName: 'layout-background-color',
      cssPropertyName: 'background-color',
      label: 'Layout Background'
    }, {
      name: 'group',
      label: 'Popup',
      children: [{
        name: 'composite',
        label: 'Padding',
        type: 'rectangle',
        children: [{
          variableName: 'popup-padding-top',
          cssPropertyName: 'padding-top',
          label: 'Top'
        }, {
          variableName: 'popup-padding-right',
          cssPropertyName: 'padding-right',
          label: 'Right'
        }, {
          variableName: 'popup-padding-bottom',
          cssPropertyName: 'padding-bottom',
          label: 'Bottom'
        }, {
          variableName: 'popup-padding-left',
          cssPropertyName: 'padding-left',
          label: 'Left'
        }]
      }, {
        variableName: 'popup-background-color',
        cssPropertyName: 'background-color',
        label: 'Background'
      }]
    }]
  }];
  var _default = WebsiteStyleInterface;
  _exports.default = _default;
});