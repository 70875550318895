define("builder/pods/components/widget-paragraph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bq3CXYva",
    "block": "{\"symbols\":[],\"statements\":[[5,\"module-buttons\",[],[[\"@acl\",\"@title\",\"@moduleAction\",\"@module\",\"@parentWidth\"],[[24,[\"data\",\"acl\"]],[24,[\"data\",\"mainTitle\"]],[22,\"moduleAction\"],[22,\"data\"],[22,\"containerWidth\"]]]],[0,\"\\n\\n\"],[7,\"style\",true],[8],[0,\"\\n\\t\"],[1,[23,0,[\"localCssStylesString\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"page_module_content widget-paragraph\"],[11,\"style\",[22,\"styles\"]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEditorMode\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"js-editor-wrapper\"],[8],[9],[0,\" \\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"showEditOverlayTemplate\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"edit-widget-overlay\",[[12,\"}}\",\"\"]],[[\"@buttonText\",\"@faIconName\",\"@clickCallback\"],[\"Edit Text\",\"fa-pencil-square-o\",[28,\"action\",[[23,0,[]],\"overlayButtonClick\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"js-editor-wrapper\"],[11,\"data-aos\",[24,[\"animationObject\",\"name\"]]],[11,\"data-aos-duration\",[24,[\"animationObject\",\"duration\"]]],[11,\"data-aos-mirror\",[24,[\"animationObject\",\"mirror\"]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"content\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"enableVResize\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"resize-handler\",[],[[\"@horizontal\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-paragraph/template.hbs"
    }
  });

  _exports.default = _default;
});