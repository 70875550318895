define("builder/pods/components/input-zebradatepicker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let InputZebraDatePicker = (_dec = Ember._action, (_class = (_temp = class InputZebraDatePicker extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.tagName = 'input';
      this.attributeBindings = ['type', 'style', 'value'];
      this.format = '';
      this.datepickerVisible = false;
      this.minDate = null;
      this.maxDate = null;
    }

    /**
     * Callback to be invoked when datepicker updates
     */
    onUpdate(formattedDate, dateString, dateObject) {}
    /**
     * Callback to be invoked when datepicker clear event
     */


    onClear() {}

    onClose() {}
    /**
     * @inehritdoc
     */


    didReceiveAttrs() {
      this._toggleDatepicker();
    }
    /**
     * @inheritdoc
     */


    didInsertElement() {
      this._addDatepicker();
    }
    /**
     * Add datapicker
     *
     * @method _addDatepicker
     * @private
     */


    _addDatepicker() {
      const component = this;
      const input = component.$();
      input.Zebra_DatePicker({
        format: component.format,
        default_position: 'below',

        onSelect(formattedDate, dateString, dateObject) {
          component.onUpdate(formattedDate, dateString, dateObject);
          const datepicker = input.data('Zebra_DatePicker');

          if (component.value === null) {
            datepicker.clear_date();
          }
        },

        onClear() {
          component.onClear();
        },

        onClose() {
          const currentElement = this[0];

          if (!currentElement) {
            return;
          }

          const {
            value
          } = currentElement;

          if (!value) {
            component.onClear();
            return;
          }

          component.onUpdate(value, null, new Date(value));
        }

      });
    }
    /**
     * Toggle datepickers
     *
     * @method _toggleDatepicker
     * @private
     */


    _toggleDatepicker() {
      const input = this.$();

      if (!input) {
        return;
      }

      const datepicker = input.data('Zebra_DatePicker');
      const minDate = this.minDate || false;
      const maxDate = this.maxDate || false;

      if (minDate || maxDate) {
        datepicker.update({
          direction: [minDate, maxDate]
        });
      }

      datepicker[this.datepickerVisible === true ? 'show' : 'hide']();
    }

    showDatepicker() {
      this.$().data('Zebra_DatePicker').show();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "showDatepicker", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showDatepicker"), _class.prototype)), _class));
  _exports.default = InputZebraDatePicker;
});