define("builder/core/enumerators/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    FORM_VARIANTS: 'form-variants',
    FORM_VARIANT: 'form-variant',
    FORM_BUTTONS: 'form-buttons',
    LINK: 'field-link',
    TEXT: 'field-text',
    TEXTAREA: 'field-textarea',
    DROPDOWN: 'field-dropdown',
    RADIOLIST: 'field-radiolist',
    MEDIA: 'field-media',
    CONTAINER: 'field-container',
    CHECKBOX: 'field-checkbox',
    CHECKBOX_LIST: 'field-checkboxlist',
    ICON: 'field-icon',
    NUMBER: 'field-number',
    NUMBER_WITH_UNITS: 'field-number-with-units',
    MULTISELECT: 'field-multiselect',
    FILTERS: 'field-filters',
    CONTENTEDITABLE: 'field-contenteditable',
    TAGS: 'field-tags',
    RANGED_INPUT: 'field-rangeinput',
    RESET: 'field-reset'
  };
  _exports.default = _default;
});