define("builder/core/util/extend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Merge two or more objects together and return new merged object.
   * @param {Boolean}  deep     If true, do a deep (or recursive) merge [optional]
   * @param {Object}   objects  The objects to merge together
   * @returns {Object}          Merged values of defaults and options
   */
  function extend(...args) {
    const extended = {};
    let deep = false;
    let i = 0;
    const argumentsLength = args.length;
    const {
      toString
    } = Object.prototype; // Check if a deep merge

    if (toString.call(args[0]) === '[object Boolean]') {
      [deep] = args;
      i++;
    } // Merge the object into the extended object


    function mergeObjects(obj) {
      if (obj) {
        Object.keys(obj).forEach(prop => {
          // If deep merge and property is an object, merge properties
          if (deep && toString.call(obj[prop]) === '[object Object]') {
            extended[prop] = extend(true, extended[prop], obj[prop]);
          } else {
            extended[prop] = obj[prop];
          }
        });
      }
    } // Loop through each object and conduct a merge


    for (; i < argumentsLength; i++) {
      const obj = args[i];
      mergeObjects(obj);
    }

    return extended;
  }

  var _default = extend;
  _exports.default = _default;
});