define("builder/models/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SecuredModel = _model.default.extend({
    /**
     * ember-can service
     * @type {import('ember').Ember.Service}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * List of attributes
     * @type {Set|null}
     * @private
     */
    _attributes: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      const attributes = [];
      this.eachAttribute(name => {
        attributes.push(name);
      });
      this._attributes = new Set(attributes);

      this._super(...args);
    },

    /**
     * Check access before executing default handler
     *
     * @inheritdoc
     */
    get(property, ...args) {
      if (this._attributes.has(property) && !this.canReadProperties()) {
        throw new Ember.Error(`Access denied to view "${this.constructor.modelName}" model`);
      }

      return this._super(property, ...args);
    },

    /**
     * Check access before executing default handler
     *
     * @inheritdoc
     */
    set(property, value) {
      if (this._attributes.has(property) && !this.canUpdateProperties()) {
        throw new Ember.Error(`Access denied to update "${this.constructor.modelName}" model`);
      }

      this._super(property, value);
    },

    /**
     * Check access before executing default handler
     *
     * @inheritdoc
     */
    deleteRecord(...args) {
      if (!this.canDeleteModel()) {
        throw new Ember.Error(`Access denied to delete "${this.constructor.modelName}" model`);
      }

      this._super(...args);
    },

    /**
     * Can read properties
     *
     * @returns {boolean}
     */
    canReadProperties() {
      return this.accessControl.can(`read ${this.constructor.modelName}`).isGranted || this.canUpdateProperties();
    },

    /**
     * Can update properties
     *
     * @returns {boolean}
     */
    canUpdateProperties() {
      return this.accessControl.can(`update ${this.constructor.modelName}`, this).isGranted;
    },

    /**
     * Can remove model
     *
     * @returns {boolean}
     */
    canDeleteModel() {
      return this.accessControl.can(`delete ${this.constructor.modelName}`, this).isGranted;
    }

  });

  var _default = SecuredModel;
  _exports.default = _default;
});