define("builder/pods/components/field-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_switcher'],

    /**
     * @property {Boolean} isActive - switcher on/off state
     */
    isActive: false,
    actions: {
      switch(status) {
        if (typeof this.change === 'function') {
          this.change(status);
        }
      }

    }
  });

  _exports.default = _default;
});