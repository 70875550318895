define("builder/models/container-template-category", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ContainerTemplateCategory
   */
  const ContainerTemplateCategory = _model2.default.extend({
    /**
     * @property {String} Name - category name(title)
     */
    Name: (0, _model.attr)('string'),

    /**
     * @property {String[]} Templates - list of template's ids that belong to this category
     */
    Templates: (0, _model.attr)('raw'),

    /**
     * @param {Number} IsStatic - is current category static(or virtual)
     */
    IsStatic: (0, _model.attr)('number')
  });

  var _default = ContainerTemplateCategory;
  _exports.default = _default;
});