define("builder/core/configuration-builder/fields/field-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldMediaConfigurationBuilder
   */
  var _default = Ember.Object.extend({
    /**
     * Entry point of building configuration for field media
     *
     * @param {Object} data - data to be prepared for field media to consume
     * @param {Ember.Object} node - node object of page structure, the one that contains originObject
     * @param {String} mode - breakpoint mode
     * @returns {*|{type, url, naturalWidth, naturalHeight, zoomLevel, left, top, min}}
     */
    build(data, node, mode) {
      return this._buildImage(data, node, mode);
    },

    /**
     * Field media of type image configuration builder
     *
     * @param {Object} data - data to be prepared for field media to consume
     * @param {Ember.Object} node - node object of page structure, the one that contains originObject
     * @returns {*|{type, url, naturalWidth, naturalHeight, zoomLevel, left, top, min}}
     * @private
     */
    _buildImage(data, node) {
      var _node$parent;

      const naturalWidth = Ember.get(data, 'naturalWidth') || 0;
      const naturalHeight = Ember.get(data, 'naturalHeight') || 0;
      let left = Ember.get(data, 'left');
      let top = Ember.get(data, 'top');
      let min = 1;

      if (naturalWidth && node && ((_node$parent = node.parent) === null || _node$parent === void 0 ? void 0 : _node$parent.component)) {
        const parentWidth = node.parent.component.$().width();
        min = Math.round(Math.min(parentWidth / naturalWidth * 100, 100));
      }

      const zoomLevel = isNaN(parseInt(Ember.get(data, 'scale'), 10)) ? min : Math.min(min / Ember.get(data, 'scale') * 100, 100); // Workaround to not fail nouislider

      if (min >= 100) {
        min = 99;
      }

      left = isNaN(parseInt(left, 10)) ? naturalWidth / 2 : left;
      top = isNaN(parseInt(top, 10)) ? naturalHeight / 2 : top;
      left = left / naturalWidth * 100;
      top = top / naturalHeight * 100;

      if (!isFinite(left) || isNaN(left)) {
        left = 0;
      }

      if (!isFinite(top) || isNaN(top)) {
        top = 0;
      }

      return {
        type: 'image',
        url: Ember.get(data, 'url'),
        naturalWidth,
        naturalHeight,
        zoomLevel,
        left,
        top,
        min
      };
    }

  });

  _exports.default = _default;
});