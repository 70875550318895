define("builder/core/enumerators/cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WEBSITE_ID = _exports.COMPANY_ID = _exports.SESSION_ID = void 0;
  const SESSION_ID = 'sessionId';
  _exports.SESSION_ID = SESSION_ID;
  const COMPANY_ID = 'PSwitchCompanyId';
  _exports.COMPANY_ID = COMPANY_ID;
  const WEBSITE_ID = 'PSwitchWebsiteId';
  _exports.WEBSITE_ID = WEBSITE_ID;
  var _default = {
    SESSION_ID,
    COMPANY_ID,
    WEBSITE_ID
  };
  _exports.default = _default;
});