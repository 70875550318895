define("builder/routes/application", ["exports", "builder/errors/merge-error", "builder/errors/form-validation-error", "builder/core/enumerators/notification", "builder/errors/token-invalid-error", "builder/errors/token-expired-error", "@ember-data/adapter/error"], function (_exports, _mergeError, _formValidationError, _notification, _tokenInvalidError, _tokenExpiredError, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * Main application service which hold some global states
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Sentry reporting service
     * @type {SentryService}
     */
    sentry: Ember.inject.service(),

    /**
     * Router service
     * @type {Ember.Service}
     */
    router: Ember.inject.service('router'),

    /**
     * @inheritdoc
     */
    activate() {
      if (!this.accessControl.can('visit route', this).isGranted) {
        this.handleAccessDenied();
      }
    },

    /**
     * Auth service
     * @type {AuthManagerService}
     */
    authManager: Ember.inject.service(),

    /**
     * Logout url
     * @type {string}
     * @readonly
     */
    logoutUrl: Ember.computed.readOnly('authManager.logoutUrl'),

    /**
     * This method will be invoked is user access to route is disabled
     */
    handleAccessDenied() {
      this.transitionTo('access-denied');
    },

    actions: {
      willTransition(transition) {
        // Make sure we are away from current route in up direction.
        const currentRoute = this.router.currentRouteName.replace('.index', '');

        if (transition.targetName.indexOf(currentRoute) !== -1) {
          return true;
        }

        return false;
      },

      error(error) {
        const controller = this.controllerFor('application');
        window.console.error(error);

        if (error instanceof _mergeError.default) {
          controller.send('notification', {
            type: _notification.default.TYPE.ERROR,
            message: [error.message],
            features: {
              needToReloadPage: true
            },
            timeToDisplay: Number.MAX_SAFE_INTEGER
          });
        } else if (error instanceof _formValidationError.default) {
          controller.send('notification', {
            type: _notification.default.TYPE.ERROR,
            message: error.errors,
            timeToDisplay: Number.MAX_SAFE_INTEGER
          });
        } else if (error instanceof _error.NotFoundError) {
          this.transitionTo('not-found', 'not-found');
        } else if (error instanceof _tokenInvalidError.default || error instanceof _tokenExpiredError.default || error.name === 'ErrorResponse') {
          window.location.href = this.logoutUrl;
        } else {
          this.sentry.report(error); // @todo check if error status is 404 transition to 'not-found' else to 'application-error'

          this.transitionTo('application-error');
        }
      },

      /**
       * Sets website
       * @param {String} websiteId - Website id
       */
      setWebsite(websiteId) {
        this.controllerFor('application').updateWebsite(websiteId);
      }

    }
  });

  _exports.default = _default;
});