define("builder/pods/components/add-new-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property {String} tagName - tag name
     */
    tagName: 'span',

    /**
     * @property {String[]} classNames - class names
     */
    classNames: ['add-new'],

    /**
     * @property {String[]} classNameBindings - class name bindings
     */
    classNameBindings: ['disabled:__disabled'],

    /**
     * @property {Boolean} disabled - Disabled state of the button
     */
    disabled: false,

    /**
     * @inheritDoc
     */
    click() {
      const {
        action
      } = this;

      if (typeof action === 'function') {
        action();
      }
    }

  });

  _exports.default = _default;
});