define("builder/core/styles-management/widget-line-divider/variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Default variable values for widget line divider.
   * This variables values can be changed in UI.
   */
  var _default = {
    lg: [{
      name: 'padding-top',
      value: '0px'
    }, {
      name: 'padding-left',
      value: '0px'
    }, {
      name: 'padding-right',
      value: '0px'
    }, {
      name: 'padding-bottom',
      value: '0px'
    }, {
      name: 'margin-top',
      value: '20px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '20px'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-top-width',
      value: '1px'
    }, {
      name: 'border-top-style',
      value: 'solid'
    }],
    md: [{
      name: 'padding-top',
      value: '0px'
    }, {
      name: 'padding-left',
      value: '0px'
    }, {
      name: 'padding-right',
      value: '0px'
    }, {
      name: 'padding-bottom',
      value: '0px'
    }, {
      name: 'margin-top',
      value: '20px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '20px'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-top-width',
      value: '1px'
    }, {
      name: 'border-top-style',
      value: 'solid'
    }],
    sm: [{
      name: 'padding-top',
      value: '0px'
    }, {
      name: 'padding-left',
      value: '0px'
    }, {
      name: 'padding-right',
      value: '0px'
    }, {
      name: 'padding-bottom',
      value: '0px'
    }, {
      name: 'margin-top',
      value: '20px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '20px'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-top-width',
      value: '1px'
    }, {
      name: 'border-top-style',
      value: 'solid'
    }],
    xs: [{
      name: 'padding-top',
      value: '0px'
    }, {
      name: 'padding-left',
      value: '0px'
    }, {
      name: 'padding-right',
      value: '0px'
    }, {
      name: 'padding-bottom',
      value: '0px'
    }, {
      name: 'margin-top',
      value: '20px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '20px'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-top-width',
      value: '1px'
    }, {
      name: 'border-top-style',
      value: 'solid'
    }]
  };
  _exports.default = _default;
});