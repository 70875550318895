define("builder/pods/components/fonts-uploaded-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubkNGOiP",
    "block": "{\"symbols\":[\"font\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"b2-fonts-uploaded-list_header\"],[3,\"action\",[[23,0,[]],\"collapseExpandList\"]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[5,\"widget-help\",[],[[\"@text\"],[[22,\"help\"]]]],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"b2-fonts-uploaded-list_button-collapse-expand\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[28,\"if\",[[24,[\"isCollapsed\"]],\"fa-caret-up\",\"fa-caret-down\"],null]]]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"b2-fonts-uploaded-list_list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"fonts\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"fonts-uploaded-list-item\",[],[[\"@field\",\"@onRemove\",\"@onChangeVisibility\",\"@onChangeTitle\",\"@onReload\"],[[23,1,[]],[22,\"onRemoveFont\"],[22,\"onChangeFontVisibility\"],[22,\"onChangeFontTitle\"],[22,\"onReloadFont\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/fonts-uploaded-list/template.hbs"
    }
  });

  _exports.default = _default;
});