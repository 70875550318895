define("builder/widgets/configs/widget-tabs", ["exports", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes", "ember-copy", "builder/core/styles-management/widget-tabs/structure"], function (_exports, _fields, _breakpointModes, _emberCopy, _structure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tabConfiguration = {
    type: _fields.default.FORM_VARIANTS,
    name: 'tabs',
    label: 'Tab list',
    validation: {
      elements: {
        min: 1
      }
    },
    children: [{
      type: _fields.default.FORM_VARIANT,
      label: 'Tab Label',
      labelFrom: 'label',
      isUnique: false,
      name: 'custom',
      children: [{
        type: _fields.default.TEXT,
        name: 'label',
        label: 'Tab Label'
      }, {
        name: 'icon',
        type: _fields.default.ICON,
        label: 'Tab icon'
      }, {
        type: _fields.default.RADIOLIST,
        label: 'Display as default tab',
        name: 'isDefault',
        choices: [{
          label: 'Yes',
          value: true
        }, {
          label: 'No',
          value: false
        }],
        data: false,
        description: 'Toggling Yes will display this tab as the default open tab'
      }]
    }]
  };
  const tabPositioning = {
    type: _fields.default.DROPDOWN,
    label: 'Tab Positioning',
    name: 'tabPositioning',
    choices: [{
      value: 'horizontal-top',
      label: 'Horizontal Top'
    }, {
      value: 'horizontal-bottom',
      label: 'Horizontal Bottom'
    }, {
      value: 'vertical-left',
      label: 'Vertical Left'
    }, {
      value: 'vertical-right',
      label: 'Vertical Right'
    }],
    data: 'vertical-left'
  };
  const deviceVisibility = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Show container on which devices / breakpoints?',
    description: 'Please select at least 1 device for the module to show on',
    help: 'Un-checking the box means all the modules within the container will be hidden on that device / breakpoint.',
    name: 'visibleOnDevices',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this module to display on.'
    }
  };

  var _default = Ember.Object.create({
    /**
     * Get widget config
     *
     * @returns {Object}
     */
    getConfig() {
      return {
        styles: (0, _emberCopy.copy)(_structure.WidgetTabsStyleInterface, true),
        config: [tabConfiguration, tabPositioning, deviceVisibility]
      };
    }

  });

  _exports.default = _default;
});