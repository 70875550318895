define("builder/pods/components/dropdown-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * See usage in "dropdown-container" component
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['b2-dropdown-button'],

    /**
     * In this property passed "dropdown-container" component
     *
     * @property {null|Component} manager
     */
    manager: null,

    /**
     * Dropdown button element object
     * @type {HTMLElement|null}
     */
    button: null,

    /**
     * @inheritdoc
     */
    didInsertElement() {
      this.set('button', this.element);
      const $button = this.$();
      this.manager.registerButton($button);

      switch (this.manager.method) {
        case 'click':
          {
            $button.on('click.dropdownButton', () => {
              // Toggle droppable area
              this.manager.send('toggle');
            });
            break;
          }

        case 'hover':
          {
            $button.on('mouseenter.dropdownButton', () => {
              this.manager.send('open');
            });
            $button.on('mouseleave.dropdownButton', () => {
              this.manager.send('close');
            });
            break;
          }
      }
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      const {
        button
      } = this;

      if (!button) {
        return;
      }

      switch (this.manager.method) {
        case 'click':
          {
            button.removeEventListener('click.dropdownButton', () => {
              this.manager.send('toggle');
            });
            break;
          }

        case 'hover':
          {
            button.removeEventListener('mouseenter.dropdownButton', () => {
              this.manager.send('open');
            });
            button.removeEventListener('mouseleave.dropdownButton', () => {
              this.manager.send('close');
            });
            break;
          }
      }
    }

  });

  _exports.default = _default;
});