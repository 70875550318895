define("builder/widgets/data-setters/widget-image", ["exports", "builder/core/data-setters/fields/field-media", "builder/core/enumerators/breakpoint-modes"], function (_exports, _fieldMedia, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetImageDataSetter
   */
  var _default = Ember.Object.create({
    /**
     * Set data to widget(originObject)
     *
     * @param {EmberObject} component - originObject where value should be set
     * @param {String} name - name of property to set
     * @param {*} value - value to set
     * @param {String} mode - mode for which value should be set
     * @returns {EmberObject} - component back
     */
    setData(component, name, value, mode) {
      if (name === 'src') {
        const type = component.get('type');

        const setter = _fieldMedia.default.create();

        setter.setData(component, name, value, mode); // Restore widget image type, since media type here is not required

        component.set('type', type);
      } else if (name === 'link' && value.value === null) {
        delete component.link;
      } else if (name === 'visibleOnDevices') {
        [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(breakpoint => {
          Ember.set(component, `visibleOnDevices.${breakpoint}`, value.includes(breakpoint));
        });
      } else {
        component.set(name, value);
      }

      return component;
    }

  });

  _exports.default = _default;
});