define("builder/errors/global-page-menu-insertion-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class GlobalPageMenuInsertionError
   */
  class GlobalPageMenuInsertionError extends Error {
    constructor(message, details) {
      super(message);
      this.details = details;
    }

  }

  _exports.default = GlobalPageMenuInsertionError;
});