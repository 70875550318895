define("builder/core/expression/operand", ["exports", "builder/core/expression/expression"], function (_exports, _expression) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOperand = isOperand;
  _exports.default = _exports.TYPE_EXPRESSION = _exports.TYPE_LITERAL = _exports.TYPE_OBJECT = _exports.TYPE_ARRAY = void 0;

  /**
   * @const {String} TYPE_ARRAY - Operand array type
   */
  const TYPE_ARRAY = 'array';
  /**
   * @const {String} TYPE_OBJECT - Operand object type
   */

  _exports.TYPE_ARRAY = TYPE_ARRAY;
  const TYPE_OBJECT = 'object';
  /**
   * @const {String} TYPE_LITERAL - Operand literal type
   */

  _exports.TYPE_OBJECT = TYPE_OBJECT;
  const TYPE_LITERAL = 'literal';
  /**
   * @const {String} TYPE_EXPRESSION - Operand expression type
   */

  _exports.TYPE_LITERAL = TYPE_LITERAL;
  const TYPE_EXPRESSION = 'expression';
  /**
   * This class provides API to work with expressions
   * @class Expression
   */

  _exports.TYPE_EXPRESSION = TYPE_EXPRESSION;

  var _default = Ember.Object.extend({
    /**
     * @property {*} _value - Value of operand
     */
    _value: null,

    /**
     * Sets passed value to operand
     * @param {*} value - Value to set
     */
    setValue(value) {
      this.set('_value', value);
    },

    /**
     * Returns operand value
     * @returns {*}
     */
    getValue() {
      return this._value;
    },

    /**
     * Returns object data of the operand
     * @returns {Array|Object}
     */
    toObject() {
      const type = this._getType();

      return this._getObject(type);
    },

    /**
     * Determens whether current operand is array
     * @returns {Boolean}
     */
    isArray() {
      return this._getType() === TYPE_ARRAY;
    },

    /**
     * Returns type of the operand
     * @returns {String}
     * @private
     */
    _getType() {
      const value = this.getValue();
      let type;

      if (value instanceof _expression.default) {
        type = TYPE_EXPRESSION;
      } else if (Array.isArray(value)) {
        type = TYPE_ARRAY;
      } else if ($.isPlainObject(value)) {
        type = TYPE_OBJECT;
      } else if (typeof value === 'number' || typeof value === 'string') {
        type = TYPE_LITERAL;
      }

      return type;
    },

    /**
     * Returns object data of the operand
     * @param {String} type - Operand type
     * @returns {Array|Object}
     * @private
     */
    _getObject(type) {
      const value = this.getValue();
      let object;

      switch (type) {
        case TYPE_ARRAY:
          object = {
            type: TYPE_ARRAY,
            value
          };
          break;

        case TYPE_EXPRESSION:
          object = value.toObject();
          break;

        case TYPE_OBJECT:
          object = {
            type: TYPE_OBJECT,
            value
          };
          break;

        case TYPE_LITERAL:
          object = {
            type: TYPE_LITERAL,
            value
          };
          break;
      }

      return object;
    }

  });
  /**
   * Determines passed object data is operand
   * @param {Object} operand - Operand object data
   * @returns {Boolean}
   */


  _exports.default = _default;

  function isOperand(operand) {
    return [TYPE_ARRAY, TYPE_LITERAL, TYPE_OBJECT].includes(operand.type);
  }
});