define("builder/pods/components/selector/component", ["exports", "ember-copy", "builder/core/enumerators/keyboard"], function (_exports, _emberCopy, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['b2-selector'],

    /**
     * Parent action handler
     * @type {Function|null}
     */
    actionHandler: null,

    /**
     * Selected values
     * @type {Set}
     * @readonly
     */
    selectedValues: Ember.computed('field.data.[]', function () {
      return new Set(this.field.data);
    }).readOnly(),

    /**
     * List of options
     * @type {Object[]}
     * @readonly
     */
    choices: Ember.computed('field.choices', 'selectedValues', 'searchQuery', function () {
      const {
        selectedValues,
        searchQuery
      } = this;
      const normalizedSearchQuery = searchQuery.toLowerCase();
      return this.field.choices.filter(choice => {
        const isSelected = selectedValues.has(choice.value);
        const isQueryMatched = choice.label.toLowerCase().indexOf(normalizedSearchQuery) !== -1;
        return !isSelected && isQueryMatched;
      }).map(choice => (0, _emberCopy.copy)(choice, true));
    }).readOnly(),

    /**
     * Search query
     * @type {string}
     */
    searchQuery: '',

    /**
     * Passes selected value and name to parent action
     * @param {string[]} value - text field value
     */
    sendValuesToParent(values) {
      if (typeof this.actionHandler === 'function') {
        this.actionHandler(values, this.field.name);
      }
    },

    /**
     * @inheritDoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.element.addEventListener('keyup', Ember.run.bind(this, this._onKeyUpHandler));
    },

    /**
     * Element key up handler
     * @param {KeyboardEvent} event - event
     * @private
     */
    _onKeyUpHandler(event) {
      const {
        UP,
        DOWN,
        ENTER
      } = _keyboard.default.KEY;

      if (!this.choices.length) {
        return;
      }

      switch (event.keyCode) {
        case UP:
          {
            this._selectAboveChoice();

            break;
          }

        case DOWN:
          {
            this._selectBelowChoice();

            break;
          }

        case ENTER:
          {
            this._selectActiveChoice();

            break;
          }
      }
    },

    /**
     * Select active choice
     * @private
     * @returns {void}
     */
    _selectActiveChoice() {
      const activeChoice = this.choices.findBy('active', true);

      if (activeChoice) {
        this.send('selectOne', activeChoice);
      }
    },

    /**
     * Select choice above
     * @private
     * @returns {void}
     */
    _selectAboveChoice() {
      const {
        choices
      } = this;
      const activeChoice = choices.findBy('active', true);
      const activeChoiceIndex = choices.indexOf(activeChoice);
      const maxAvailableChoiceIndex = Math.max(0, choices.length - 1);
      let prevIndex = 0;

      if (activeChoiceIndex !== -1) {
        prevIndex = activeChoiceIndex - 1;
      }

      if (prevIndex < 0) {
        prevIndex = maxAvailableChoiceIndex;
      }

      choices.forEach(choice => Ember.set(choice, 'active', false));
      Ember.set(choices[prevIndex], 'active', true);

      this._scrollToActiveOption();
    },

    /**
     * Select choice below
     * @private
     * @returns {void}
     */
    _selectBelowChoice() {
      const {
        choices
      } = this;
      const activeChoice = choices.findBy('active', true);
      const activeChoiceIndex = choices.indexOf(activeChoice);
      const maxAvailableChoiceIndex = Math.max(0, choices.length - 1);
      let nextIndex = 0;

      if (activeChoiceIndex !== -1) {
        nextIndex = activeChoiceIndex + 1;
      }

      if (nextIndex > maxAvailableChoiceIndex) {
        nextIndex = 0;
      }

      choices.forEach(choice => Ember.set(choice, 'active', false));
      Ember.set(choices[nextIndex], 'active', true);

      this._scrollToActiveOption();
    },

    /**
     * Scroll to active element
     * @private
     * @returns {void}
     */
    _scrollToActiveOption() {
      Ember.run.schedule('afterRender', this, () => {
        const containerElement = this.element.querySelector('.list-wrapper');
        const activeOptionElement = this.element.querySelector('.list-wrapper__list-option_active');

        if (!activeOptionElement) {
          return;
        }

        const {
          choices
        } = this;
        const activeChoice = choices.findBy('active', true);
        const activeChoiceIndex = choices.indexOf(activeChoice);

        if (activeChoiceIndex === 0) {
          containerElement.scrollTop = 0;
        } else {
          containerElement.scrollTop = activeOptionElement.offsetTop - containerElement.offsetTop;
        }
      });
    },

    actions: {
      selectOne(option) {
        this.sendValuesToParent([].concat(option.value));
      },

      selectAll(options) {
        const values = options.map(option => option.value);
        this.sendValuesToParent(values);
      },

      search(query) {
        if (this.searchQuery === query) {
          return;
        }

        this.set('searchQuery', query);
      },

      showFullList() {
        this.set('searchQuery', '');
      }

    }
  });

  _exports.default = _default;
});