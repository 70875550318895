define("builder/adapters/application", ["exports", "@ember-data/adapter/rest", "ember-ajax/mixins/ajax-support", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _rest, _ajaxSupport, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_ajaxSupport.default, {
    /**
     * Endpoint of hostname.
     * @type {string}
     */
    host: _environment.default.apiURL,

    /**
     * company-and-website service
     * @type {Object}
     */
    companyAndWebsite: Ember.inject.service(),

    /**
     * page service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @inheritDoc
     */
    buildURL(...args) {
      const url = this._super(...args);

      const params = `${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configurationService.sessionId')}`;
      let delimiter = '?';

      if (url.indexOf(delimiter) !== -1) {
        delimiter = '&';
      }

      return `${url}${delimiter}${params}`;
    },

    /**
     * @inheritdoc
     */
    updateRecord(store, type, snapshot) {
      const options = snapshot.adapterOptions;
      let promise; // Fake request

      if (options && options.fakeRequest) {
        promise = Ember.RSVP.resolve(store.serializerFor(type.modelName).serializeIntoHash({}, type, snapshot));
      } else {
        promise = this._super(store, type, snapshot);
      }

      return promise;
    },

    /**
     * @inheritdoc
     */
    createRecord(store, type, snapshot) {
      const options = snapshot.adapterOptions;
      let promise; // Fake request

      if (options && options.fakeRequest) {
        promise = Ember.RSVP.resolve(store.serializerFor(type.modelName).serializeIntoHash({}, type, snapshot));
      } else {
        promise = this._super(store, type, snapshot);
      }

      return promise;
    }

  });

  _exports.default = _default;
});