define("builder/abilities/tier/website", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierWebsiteAbility
   */
  var _default = _ability.default.extend({
    /**
     * @inheritdoc
     */
    map: {
      canCreate: {
        entity: 'website',
        property: 'create'
      },
      canDelete: {
        entity: 'website',
        property: 'delete'
      }
    }
  });

  _exports.default = _default;
});