define("builder/services/resource-manager", ["exports", "builder/utils/location"], function (_exports, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ResourceManager
   */
  var _default = Ember.Service.extend({
    /**
     * @property {String[]} _loadedCss - list of loaded css files
     */
    _loadedCss: [],

    /**
     * Load css, will not load css if already loaded, defined by filename
     *
     * @param {String} url - url to css
     */
    loadCSS(url) {
      const filename = _location.default.filename(url);

      const loadedCss = this._loadedCss;

      if (!loadedCss.includes(filename)) {
        this._loadCSS(url); // TODO: push only if load succeeded


        loadedCss.push(filename);
      }
    },

    /**
     * Load css from url
     *
     * @param {String} url - url to resource
     * @private
     */
    _loadCSS(url) {
      appBootstrap.loadCSS({
        href: url
      });
    }

  });

  _exports.default = _default;
});