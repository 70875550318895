define("builder/pods/website/edit/page/edit/site-settings/font-item-model", ["exports", "builder/core/enumerators/font-source", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _fontSource, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    /**
     * @property {String} label - font label
     * @readonly
     */
    label: Ember.computed('model.Title', 'source.name', 'source', function () {
      const modelTitle = this.get('model.Title');
      const fileName = this.get('source.name');
      const fontUrl = this.source;

      if (modelTitle) {
        return modelTitle;
      }

      if (fileName) {
        return fileName;
      }

      if (typeof fontUrl === 'string') {
        return fontUrl;
      }

      return '';
    }).readOnly(),

    /**
     * @property {Boolean} isPresentModel - model is present or not
     * @readonly
     */
    isPresentModel: Ember.computed.bool('model').readOnly(),

    /**
     * @property {String} fontFamily - font family
     * @readonly
     */
    fontFamily: Ember.computed.readOnly('model.Name'),

    /**
     * @property {DS.Mode|null} - website-font model
     */
    model: null,

    /**
     * @property {File|String} source - font source
     */
    source: null,

    /**
     * @property {String} error - error message
     */
    error: '',

    /**
     * @property {Ember.Service} ajax - ajax service
     */
    ajax: null,

    /**
     * @property {Ember.Service} store - store service
     */
    store: null,

    /**
     * @property {Ember.Service} configuration - configuration service
     */
    configuration: null,

    /**
     * @property {Ember.Service} companyAndWebsite - company-and-website service
     */
    companyAndWebsite: null,

    /**
     * @property {String} uploadUrl - endpoint to upload files
     */
    uploadUrl: Ember.computed('configuration.sessionId', function () {
      return `${_environment.default.apiURL}/upload?${['module=website_font', `${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`, `${this.companyAndWebsite.getCompanyWebsiteParams()}`].join('&')}`;
    }),

    /**
     * @property {Number} order - item order in the list
     */
    order: 0,

    /**
     * Initializer
     * @param {String|File} source - source of font (link or File instance)
     * @param {DS.Model|Null} model - font model
     * @param {Ember.Application} container - instance of application container
     * @param {Number} [order=0] - font item order
     */
    init({
      source,
      model,
      container,
      order = 0
    }) {
      this._super();

      this.set('ajax', container.lookup('service:ajax'));
      this.set('store', container.lookup('service:store'));
      this.set('configuration', container.lookup('service:configuration'));
      this.set('companyAndWebsite', container.lookup('service:company-and-website'));
      this.set('source', source);
      this.set('model', model);
      this.set('order', order);
    },

    /**
     * Prepare error for display
     * @param {Ember.Error} error - error object
     */
    handleError(error) {
      this.set('error', Ember.isArray(error.errors) ? error.errors.get(0) : error.message);
    },

    /**
     * Upload font and create model
     * @returns {Promise}
     */
    upload() {
      const {
        source
      } = this;
      this.set('error', '');

      if (typeof source === 'string') {
        return this.saveFont({
          Source: _fontSource.default.GOOGLE,
          File: source
        });
      }

      if (source instanceof window.File) {
        return this.uploadFont(source).then(response => this.saveFont({
          Source: _fontSource.default.DEALERSOCKET,
          File: response.id
        }));
      }

      return Ember.RSVP.reject(new Ember.Error('Unsupported font source.'));
    },

    /**
     * Create font model and save it
     * @param {String} Source - source type of font (see FontSource)
     * @param {String} File - contain file id or lint to font
     * @returns {Promise}
     */
    saveFont({
      Source,
      File
    }) {
      const model = this.store.createRecord('website-font', {
        Source,
        File
      });
      return model.save().then(savedModel => {
        this.set('model', savedModel);
        return Ember.RSVP.resolve();
      }).catch(error => {
        this.handleError(error);
        model.unloadRecord();
        return Ember.RSVP.reject(error);
      });
    },

    /**
     * Upload zip-file with font assets
     * @param {File} file - file to upload
     * @returns {Promise}
     */
    uploadFont(file) {
      const {
        ajax
      } = this;
      const {
        uploadUrl
      } = this;
      const formData = new window.FormData();
      formData.append('tmp_file', file);
      return ajax.request(uploadUrl, {
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
      });
    },

    /**
     * Change font visibility
     * @param {String} visibility - visibility of font
     * @returns {Promise}
     */
    changeVisibility(visibility) {
      const {
        model
      } = this;
      model.set('VisibilityType', visibility);
      return model.save();
    },

    /**
     * Change font title
     * @param {String} title - title of font
     * @returns {Promise}
     */
    changeTitle(title) {
      const {
        model
      } = this;
      model.set('Title', title);
      return model.save();
    },

    /**
     * Remove font
     * @returns {Promise}
     */
    remove() {
      const {
        model
      } = this;
      this.set('removeInProgress', true);
      model.deleteRecord();
      return model.save();
    }

  });

  _exports.default = _default;
});