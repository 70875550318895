define("builder/core/styles-management/composite-properties/border", ["exports", "builder/core/abstract-classes/composite-property"], function (_exports, _compositeProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Class helps build css property "border" according to css rules
   *
   * @class BorderCompositeProperty
   */
  var _default = _compositeProperty.default.extend({
    /**
     * @inheritdoc
     */
    compositeProperties: Ember.computed(function () {
      return {
        'border-width': null,
        'border-style': null,
        'border-color': null
      };
    }),

    /**
     * @property {String} unit
     */
    unit: 'px',

    /**
     * @inheritdoc
     */
    setValue(propName, value) {
      if (propName === 'border-width') {
        this._super(propName, `${parseFloat(value)}${this.unit}`);

        return;
      }

      this._super(propName, value);
    },

    /**
     * @inheritdoc
     */
    build() {
      const {
        'border-width': width,
        'border-style': style,
        'border-color': color
      } = this.compositeProperties;
      return `${width} ${style} ${color}`;
    },

    /**
     *
     * @inheritdoc
     */
    buildForCompositeView() {
      let value = this.build();
      const background = this.getValue('border-color');
      value = value.replace(background, '').trim();
      return {
        value,
        background
      };
    }

  });

  _exports.default = _default;
});