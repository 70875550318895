define("builder/abilities/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base ability class, all other abilities must inherit from this one, by default will allow access to any ability
   * requested if it starts with "can"
   */
  var _default = _emberCan.Ability.extend({
    /**
     * 1-st argument will be set to this property, we don't use it though
     * @type {any}
     */
    model: null,

    /**
     * Map of backend ACLs related to current ability
     * @type {object|null}
     */
    map: null,

    /**
     * Checkpoint on which computed properties should be binded to
     * @type {AccessControlCheckpoint}
     */
    checkpoint: null,

    /**
     * Main entry point to check access
     *
     * @param {string} actionName - action to check
     * @returns {boolean} - true if allowed, false otherwise
     */
    isGranted(actionName) {
      if (this.canHandle(actionName)) {
        return this.handle(actionName);
      }

      return true;
    },

    /**
     * Check if current ability can handle requested access check
     *
     * @param {string} actionName - action to check
     * @returns {boolean}
     */
    canHandle(actionName) {
      const {
        map
      } = this;
      return map ? this.map.hasOwnProperty(actionName) : false;
    },

    /**
     * Handle access check
     *
     * @param {string} actionName - action to check
     * @returns {boolean}
     */
    handle(actionName) {
      const permission = this.getPermission(actionName);
      return this.get(`checkpoint.permissions.${permission.entity}.${permission.property}`);
    },

    /**
     * Get mapped permission
     *
     * @param {string} actionName - action name for which mapped permission to get
     * @returns {object}
     */
    getPermission(actionName) {
      return this.get(`map.${actionName}`);
    }

  });

  _exports.default = _default;
});