define("builder/core/access-control/checkpoint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Holder of all permissions, with an ability to expose permissions to be used in application
   *
   * @class AccessControlCheckpoint
   */
  var _default = Ember.Object.extend({
    /**
     * Exposed stored permissions
     * @type {object}
     */
    permissions: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('permissions', {});
    },

    /**
     * Add permission to storage
     *
     * @param {string} entityName - name of entity which permissions to add
     * @param {object} permissions - permissions hash
     */
    addPermission(entityName, permissions) {
      this.set(`permissions.${entityName}`, permissions);
    }

  });

  _exports.default = _default;
});