define("builder/widgets/configs/animation.options", ["exports", "builder/core/enumerators/fields", "builder/core/enumerators/breakpoint-modes"], function (_exports, _fields, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enableAnimationOnDevice = _exports.animationMirroring = _exports.animationDuration = _exports.animationName = _exports.animationEnabled = void 0;

  const animationEnabled = componentName => ({
    type: _fields.default.RADIOLIST,
    label: `Apply animations to ${componentName}`,
    name: 'enableAnimation',
    choices: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    data: false
  });

  _exports.animationEnabled = animationEnabled;
  const animationName = {
    type: _fields.default.DROPDOWN,
    label: 'Animation Effect',
    name: 'animationName',
    data: 'fade-down',
    choices: [{
      value: 'fade-down',
      label: 'Enter from Top'
    }, {
      value: 'fade-left',
      label: 'Enter from Right'
    }, {
      value: 'fade-up',
      label: 'Enter from Bottom'
    }, {
      value: 'fade-right',
      label: 'Enter from Left'
    }, {
      value: 'zoom-in',
      label: 'Magnify'
    }],
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }]
  };
  _exports.animationName = animationName;
  const animationDuration = {
    type: _fields.default.NUMBER_WITH_UNITS,
    label: 'Animation Duration',
    name: 'animationDuration',
    description: '',
    data: 250,
    min: 0,
    max: 3000,
    units: [{
      symbol: 'ms',
      label: 'ms'
    }],
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }]
  };
  _exports.animationDuration = animationDuration;
  const animationMirroring = {
    type: _fields.default.RADIOLIST,
    label: 'Reanimate on Scroll Up',
    name: 'animationMirroring',
    choices: [{
      label: 'Yes',
      value: 'true'
    }, {
      label: 'No',
      value: 'false'
    }],
    data: 'true',
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }]
  };
  _exports.animationMirroring = animationMirroring;
  const enableAnimationOnDevice = {
    type: _fields.default.CHECKBOX_LIST,
    label: 'Display Animations on Device Types',
    description: '',
    help: '',
    name: 'animationOnDeviceType',
    data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
    showOnlyIf: [{
      field: 'enableAnimation',
      notEquals: false
    }],
    choices: [{
      value: _breakpointModes.LARGE,
      label: 'Desktop'
    }, {
      value: _breakpointModes.MEDIUM,
      label: 'Small Monitor'
    }, {
      value: _breakpointModes.SMALL,
      label: 'Tablet'
    }, {
      value: _breakpointModes.EXTRA_SMALL,
      label: 'Mobile'
    }],
    validation: {
      elements: {
        min: 1,
        max: 4
      },
      message: 'You must select at least one device for this animation to display on.'
    }
  };
  _exports.enableAnimationOnDevice = enableAnimationOnDevice;
});