define("builder/pods/components/gallery-form-template/component", ["exports", "builder/pods/components/gallery-item/component", "builder/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritDoc
     */
    classNames: ['__module'],

    /**
     * Form template title
     * @type {string}
     * @readonly
     */
    title: Ember.computed.readOnly('data.title'),

    /**
     * Image src
     * @type {string}
     * @readonly
     */
    image: Ember.computed('data.image', function () {
      return `${_environment.default.rootURL}images/form/${this.get('data.image')}`;
    }).readOnly(),

    /**
     * Style css for display thumbnail of item
     * @type {string}
     * @readonly
     */
    thumbnailCss: Ember.computed('image', function () {
      return Ember.String.htmlSafe(`background-image: url(${this.image})`);
    }).readOnly(),

    /**
     * Indicates the ability of delete template from the gallery
     * @type {boolean}
     * @readonly
     */
    canDelete: Ember.computed.readOnly('data.canDelete'),

    /**
     * Text for tooltip about form usage
     * @type {string}
     */
    aboutFormUsageText: Ember.computed('data.usedInPages', function () {
      const {
        usedInPages
      } = this.data;
      const message = [];

      if (!usedInPages || !usedInPages.length) {
        return '';
      }

      message.push('This form is on the following pages:');
      usedInPages.forEach(info => {
        let link;

        if (!info.pageModel) {
          link = `<span>${info.title}</span>`;
        } else {
          link = `<a href="javascript:void(0)" data-page-id="${info.pageModel.id}">${info.title}</a>`;
        }

        message.push(link);
      });
      return message.join('<br/>');
    }),

    /**
     * Callback for transition request
     * @type {Function|null}
     */
    onRequestToTransition: null,
    actions: {
      deleteTemplate() {
        this.onDelete(this.ref);
      },

      /**
       * Tooltip content click handler
       * @param {MouseEvent} event - mouse event
       */
      tooltipContentClickHandler(event) {
        const {
          dataset
        } = event.target;

        if (typeof this.onRequestToTransition === 'function' && dataset && dataset.hasOwnProperty('pageId')) {
          this.onRequestToTransition('page', dataset.pageId);
        }
      }

    }
  });

  _exports.default = _default;
});