define("builder/pods/components/button-publish-toolbar-right/component", ["exports", "builder/pods/components/button-toolbar-right/component", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _component, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ButtonPublishToolbarRight = _component.default.extend({
    /**
     * @inheritdoc
     */
    tagName: 'div',

    /**
     * @inheritdoc
     */
    classNames: ['publish-button'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['inProgress:__in-progress'],

    /**
     * State indicates what publishing in progress
     * @type {boolean}
     */
    inProgress: false,

    /**
     * Property for store success status
     * @type {boolean}
     */
    isSuccess: false,

    /**
     * Ajax service
     * @type {AjaxService}
     * @link https://github.com/ember-cli/ember-ajax
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Modules store
     * @type {ModulesStoreService}
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * localStorage service
     * @type {LocalStorageService}
     */
    localStorageService: Ember.inject.service('local-storage'),

    /**
     * Sentry reporting service
     * @type {SentryService}
     */
    sentry: Ember.inject.service(),

    /**
     * Key for store publish status in localStorage
     * @type {string}
     * @readonly
     */
    localStoragePublishKey: Ember.computed('app.user.id', function () {
      return `${ButtonPublishToolbarRight.PUBLISH_STATUS_LOCALSTORAGE_KEY}_user${this.get('app.user.id')}`;
    }).readOnly(),

    /**
     * Publish fail status handler
     * @type {Function|null}
     */
    onPublishFail: null,

    /**
     * Publish fail status handler
     * @type {Function|null}
     */
    onPublishSuccess: null,

    /**
     * Update deploy status timer
     * @type {Object|null}
     * @private
     */
    _updateDeployStatusTimer: null,

    /**
     * @inheritdoc
     */
    didInsertElement() {
      if (!this.localStorageService.getItem(this.localStoragePublishKey)) {
        this.updateDeployStatus();
      }
    },

    /**
     * @inheritdoc
     */
    click() {
      if (!this.canWeClick) {
        return;
      }

      const handler = this.handler(); // Probably it's a promise

      if (this._isPromise(handler)) {
        this.set('isLoading', true);
        this.set('inProgress', true);
        this.set('hasError', false);
        this.set('isSuccess', false);
        this.localStorageService.removeItem(this.localStoragePublishKey);
        handler.then(() => {
          this.set('isLoading', false);
          return this.updateDeployStatus();
        }).catch(error => {
          this.applyFailStatus(error);
          this.sentry.report(error);
        });
      }
    },

    /**
     * Apply status SUCCESS_DEPLOY_STATUS for component
     * @returns {void}
     */
    applySuccessStatus() {
      this.set('isLoading', false);
      this.set('inProgress', false);
      this.set('hasError', false);
      this.set('isSuccess', true);

      if (typeof this.onPublishSuccess === 'function') {
        this.onPublishSuccess();
      }

      Ember.run.later(this, () => {
        this.set('isSuccess', false);
        this.localStorageService.setItem(this.localStoragePublishKey, Date.now());
      }, ButtonPublishToolbarRight.SUCCESS_STATUS_SHOW_TIMEOUT);
    },

    /**
     * Apply status FAIL_DEPLOY_STATUS for component
     * @param {Error} error - instance of error
     * @returns {void}
     */
    applyFailStatus(error) {
      this.set('isLoading', false);
      this.set('inProgress', false);
      this.set('hasError', true);
      this.set('isSuccess', false);
      this.localStorageService.setItem(this.localStoragePublishKey, Date.now());

      if (typeof this.onPublishFail === 'function') {
        this.onPublishFail(error);
      }
    },

    /**
     * Apply status PENDING_DEPLOY_STATUS for component
     * @returns {void}
     */
    applyPendingStatus() {
      this.set('inProgress', true);
    },

    /**
     * Apply status UNKNOWN_DEPLOY_STATUS for component
     * @returns {void}
     */
    applyUnknownStatus() {
      if (this.isDestroyed) {
        return;
      }

      this.set('inProgress', false);
    },

    /**
     * Update deploy status
     * @returns {void}
     */
    updateDeployStatus() {
      Ember.run.cancel(this._updateDeployStatusTimer);
      this.getDeployStatus().then(status => {
        switch (status) {
          case ButtonPublishToolbarRight.SUCCESS_DEPLOY_STATUS:
            {
              this.applySuccessStatus();
              break;
            }

          case ButtonPublishToolbarRight.FAILED_DEPLOY_STATUS:
            {
              this.applyFailStatus(); // Update deploy status to enable publish button

              this.updateDeploy(status);
              break;
            }

          case ButtonPublishToolbarRight.PENDING_DEPLOY_STATUS:
            {
              this.applyPendingStatus();
              this._updateDeployStatusTimer = Ember.run.later(this, this.updateDeployStatus, ButtonPublishToolbarRight.UPDATE_DEPLOY_STATUS_TIMEOUT);
              break;
            }

          default:
            this.applyUnknownStatus();
        }
      });
    },

    /**
     * Get deploy status from backend
     * @returns {Promise}
     */
    getDeployStatus() {
      const websiteId = this.configurationService.website.id;
      return this.ajaxService.request(`${_environment.default.apiURL}/website_deploystatus/${websiteId}?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.configurationService.sessionId}`).then(resp => Ember.RSVP.resolve(resp.websiteDeployStatus.Status)).catch(() => Ember.RSVP.resolve());
    },

    /**
     * @inheritdoc
     */
    willDestroyElement() {
      this._super();

      Ember.run.cancel(this._updateDeployStatusTimer);
    }

  });

  ButtonPublishToolbarRight.reopenClass({
    SUCCESS_DEPLOY_STATUS: 'success',
    FAILED_DEPLOY_STATUS: 'failed',
    PENDING_DEPLOY_STATUS: 'pending',
    UPDATE_DEPLOY_STATUS_TIMEOUT: 2000,
    SUCCESS_STATUS_SHOW_TIMEOUT: 2000,
    PUBLISH_STATUS_LOCALSTORAGE_KEY: 'publishStatus'
  });
  var _default = ButtonPublishToolbarRight;
  _exports.default = _default;
});