define("builder/pods/website/edit/page/edit/module/route", ["exports", "builder/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    model() {
      return true;
    }

  });

  _exports.default = _default;
});