define("builder/pods/components/default-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Usage:
   * {{default-button label="btn-name" actionLabel="btn-name action..." isDisabled=true type="button" handled="action here" }}
   *
   * You should always pass handler in "handler" attribute.
   *
   * Button can work with async request and show loading status, in "handler" callback
   * need to return promise.
  
   * Async example:
   * Controller.extends({
   * 	callback() {
   * 		return new Promise((resolve) => (resolve(1)))
   * 	}
   * })
   *
   * {{default-button label="Save" actionLabel="Saving..." handler=(action "callback")}}
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['bttn'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isDisabled:__disabled:bttn-primary'],

    /**
     * @inheritdoc
     */
    attributeBindings: ['type'],

    /**
     * Property contain button state(enable/disable)
     *
     * @property {Boolean} isDisabled
     */
    isDisabled: false,

    /**
     * Property contain type of button.
     * It can be "button", "submit" and etc.
     *
     * @property {String} type
     */
    type: 'button',

    /**
     * @inheritdoc
     */
    tagName: 'button',

    /**
     * @property {null|String} actionLabel - label when action running
     */
    actionLabel: null,

    /**
     * Property contain button label
     *
     * @property {null|String} label
     */
    label: null,

    /**
     * Property protect from multiple click on button
     * if it button handle async request or disabled
     *
     * @property {Boolean} canWeClick
     * @readOnly
     */
    canWeClick: Ember.computed('_isLoading', 'isDisabled', function () {
      return !this._isLoading && !this.isDisabled;
    }).readOnly(),

    /**
     * Property for store async status
     *
     * @private
     * @property {Boolean} _isLoading
     */
    _isLoading: false,

    /**
     * Method detect is Promise passed or not.
     * NOTICE: It's not a bulletproof way.
     *
     * @param {*} promise
     * @return {Boolean}
     * @private
     */
    _isPromise(promise) {
      return promise && typeof promise.then === 'function';
    },

    /**
     * Method which you should pass to component
     */
    handler() {
      throw new Ember.Error('default-button error: not passed handler in "handler" property');
    },

    /**
     * If we can click, we call handler. If handler func return promise, we change
     * label on actionLabel and wait promise resolve for replace label to default
     *
     * @method click
     */
    click() {
      if (this.canWeClick) {
        const fn = this.handler(); // Probably it's a promise

        if (this._isPromise(fn)) {
          const prevLabel = this.label;
          this.set('_isLoading', true);
          this.set('label', this.actionLabel ? this.actionLabel : 'Loading...');
          fn.then(() => {
            this.set('_isLoading', false);
            this.set('label', prevLabel);
          });
        }
      }
    }

  });

  _exports.default = _default;
});