define("builder/pods/components/widget-tabs/component", ["exports", "builder/pods/components/grid-base-component/component", "builder/core/enumerators/module-action"], function (_exports, _component, _moduleAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WidgetTabs = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('tab'), _dec3 = Ember.computed('data.originObject.children'), _dec4 = Ember.computed('data.originObject.tabPositioning'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class WidgetTabs extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "storeService", _descriptor, this);

      this.classNames = ['js-tabs-wrapper'];

      _initializerDefineProperty(this, "tabService", _descriptor2, this);

      this.classNameBindings = ['data.isActive:__active', 'isHovered:js-tabs-wrapper_hovered', 'visibleOnLg::hidden-lg', 'visibleOnMd::hidden-md', 'visibleOnSm::hidden-sm', 'visibleOnXs::hidden-xs', 'isOverlayVisible:overlay-visible'];
      this.isHovered = false;
    }

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      this.tabService.initTabWidget(this.data);
    }
    /**
     * A property for distinguishing and mapping tab-buttons position to the supported o
     * @type {string}
     */


    get tabs() {
      var _this$data, _this$data$originObje;

      return ((_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : _this$data$originObje.children) || [];
    }
    /**
     * A property for distinguishing and mapping tab-buttons position to the supported o
     * @type {string}
     */


    get tabsControlsPosition() {
      var _this$data$originObje2, _this$data2, _this$data2$originObj;

      return (_this$data$originObje2 = (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : _this$data2$originObj.tabPositioning) !== null && _this$data$originObje2 !== void 0 ? _this$data$originObje2 : 'vertical-left';
    }
    /**
     * @inheritdoc
     */


    click() {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return false;
    }
    /**
     * @inheritdoc
     */


    doubleClick() {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return false;
    }
    /**
     * @inheritdoc
     */


    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    }
    /**
     * @inheritdoc
     */


    mouseOver(...args) {
      this.set('isHovered', true);
      return this._super(...args);
    }
    /**
     * @inheritdoc
     */


    mouseOut() {
      this.set('isHovered', false);
    }

    onMouseEnter() {
      this.mouseOver();
    }

    onMouseLeave() {
      this.mouseOver();
    }

    onButtonClick(context) {
      var _context$originObject;

      this.tabService.setActiveTab(this.tabs, context === null || context === void 0 ? void 0 : (_context$originObject = context.originObject) === null || _context$originObject === void 0 ? void 0 : _context$originObject.id);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storeService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tabService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "tabs", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "tabs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabsControlsPosition", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "tabsControlsPosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseEnter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseLeave", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonClick"), _class.prototype)), _class));
  _exports.default = WidgetTabs;
});