define("builder/pods/components/field-reset/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility"], function (_exports, _fieldValidation, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * Element class name.
     * @property
     */
    classNames: ['bld-form_number_with_units'],
    actions: {
      onValueChange(e) {
        let action = null;
        action = this.actionHandler;
        e.preventDefault();
        const newValue = !this.get('field.data');
        this.set('field.data', newValue);

        if (action) {
          action(newValue, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});