define("builder/core/literals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    CURRENT_PAGE_REMOVED: 'This page is removed. Click "Save" or "Publish" to remove page permanently.',
    CURRENT_PAGE_REMOVED_SUCCESS: 'Page is removed. Click "Save" or "Publish" to remove page permanently.',
    CURRENT_PAGE_COPIED_SUCCESS: 'Page successfully copied.',
    CURRENT_PAGE_REMOVE_CONFIRMATION: 'Delete this page?',
    PAGE_REMOVED_SUCCESS: 'Page successfully deleted.',
    MENU_MANAGEMENT_REMOVE_NOT_EMPTY_FOLDER: 'This folder can not be removed because it has pages linked to it. Please remove pages first before removing this folder.',
    MENU_MANAGEMENT_REMOVE_REFERED_PAGE: 'This page cannot be removed, because some folder(s) or link references to it, remove reference(s) to the page and remove page',
    MENU_MANAGEMENT_REMOVE_ITEM_CONFIRMATION: 'Are you sure you want to delete this item?',
    WEBSITE_SYNCHRONIZATION_ERROR: 'During synchronization error occured',
    ALIAS_ALREADY_EXISTS: 'Alias already exist',
    FORM_MODULE_TITLE: 'Form',
    WIDGET_CONTAINER_TITLE: 'Container',
    WIDGET_SECTION_TITLE: 'Section',
    WIDGET_TABS_TITLE: 'Tabs',
    PAGE_TEMPLATE_CREATE_TITLE: 'Create New Template',
    PAGE_TEMPLATE_EDIT_TITLE: 'Template Settings',
    PAGE_TEMPLATE_CREATE_BUTTON_TITLE: 'Create',
    PAGE_TEMPLATE_UPDATE_BUTTON_TITLE: 'Save',
    PAGE_TEMPLATE_FIELD_NAME_LABEL: 'Template Name',
    PAGE_TEMPLATE_FIELD_VISIBILITY_LABEL: 'Visibility',
    PAGE_TEMPLATE_FIELD_CATEGORY_LABEL: 'Select Category',
    PAGE_TEMPLATE_SAVE_ERROR: 'Something went wrong please try again.',
    PAGE_TEMPLATE_THUMBNAIL_UPLOAD_ERROR: 'Error occured while uploading thumbnail',
    PAGE_TEMPLATE_THUMBNAIL_UPLOAD_ERROR_FILE_TOO_BIG: 'Your uploaded file must be 4 MB or less.',
    PAGE_TEMPLATE_REMOVE_CONFIRMATION: 'Are you sure you want permanently delete this template?',
    PAGE_TEMPLATE_REMOVE_SUCCESS: 'Template has been successfully removed',
    PAGE_TEMPLATE_REMOVE_ERROR: 'Error occured during template removing',
    VISIBLE_ONLY_FOR_ME: 'Visible only for me',
    VISIBLE_FOR_EVERYONE_IN_MY_COMPANY: 'Visible for everyone in my company',
    VISIBLE_FOR_DEALERFIRE_EMPLOYEES: 'Visible for DealerFire Employees',
    VISIBLE_GLOBALLY_FOR_ALL_USERS: 'Visible Globally for All Users',
    MODULE_CONFIG_APPLYING_CHANGES: 'Applying changes...',
    MODULE_CONFIG_CHANGES_APPLIED: 'All changes applied',
    MODULE_CONFIG_FAILED_APPLY_CHANGES: 'Failed to apply changes',
    MODULE_STATE_INACTIVE_MESSAGE: 'This module has been deactivated. Please contact Website Support to reactivate the module, or delete the module from this page.',
    MODULE_LOADING_ERROR: 'Sorry an error occurred while loading the content',
    SHOW_MODULE_OUTLINES: 'Show Module Outlines',
    HIDE_MODULE_OUTLINES: 'Hide Module Outlines',
    DELETE_CONFIRMATION_TEXT: 'Are you sure you want to delete this widget/module?',
    DELETE_CONTAINER_CONFIRMATION_TEXT: 'Are you sure you want to delete this container and all of it\'s contents?',
    DELETE_SECTION_CONFIRMATION_TEXT: 'Are you sure you want to delete this section and all of it\'s contents?',
    DELETE_TAB_PANE_CONFIRMATION_TEXT: 'Are you sure you want to delete this tab panel and all it\'s contents?',
    DEVICE_DEPENDENCE_VARIABLE: 'Locking this device-specific style will force all device breakpoints to inherit the same setting.<br>If the style is locked, then the input you set here will be applied across all devices.<br>If you unlock the style, then you may adjust the style setting uniquely/independently across devices.',
    FORM_ERROR_FIELD_ALREADY_EXISTS_IN_FORM: 'A field with this name has already been added to the form. Choose another name or remove that field.',
    FORM_ERROR_FIELD_ALREADY_EXISTS_IN_FORM_HIDDEN_FIELDS: 'This field name is already in use in hidden fields. To enable it rename hidden field.',
    FORM_ERROR_FIELDS_RELATED_WITH_VEHICLE_SELECTOR_ALREADY_EXISTS_IN_FORM: 'Type, Year, Make, Model and Trim are disabled because Vehicle Selector is already added.',
    FORM_ERROR_FIELD_VEHICLE_SELECTOR_ALREADY_EXISTS_IN_FORM: 'Vehicle Selector is disabled because Type, Year, Make, Model or/and Trim are already added.',
    FORM_VARIANT_ERROR: 'One or more of the fields in this section contains invalid inputs.  Please correct these values in order to save changes.',
    FORM_TEMPLATE_DELETE_TOOLTIP: 'You may only delete a form if it is not being used on any page.',
    FORM_TEMPLATE_DELETE_WARNING: 'Are you sure you want to delete this form template? This cannot be undone.',
    FOCAL_POINT_HELP: "Select the image's focal point. By selecting a focal point, our system will better understand which part of the image should remain viewable as the image is scaled and cropped to smaller sizes on mobile devices.",
    ERROR_VALIDATION_REQUEST: 'Validation request was rejected, please contact Website Support',
    IS_NOT_UNIQUE_SEO_RULE: 'This seo rule is already exists. To enable it change link template or filter field.',
    INVENTORY_RENAME_WARNING: 'Warning! All META data related to the inventory will be deleted if you change page URL. Are you sure you want to continue? NOTE: To avoid re-populating Meta variables, you may save this page as a template and create a new page from that template with a new URL.',
    FEATURE_TIER_RESTRICTION: 'This functionality is not available with your current website plan. You may upgrade your plan to unlock this feature, along with much more <a href="https://engine6.me/upgrade" target="_blank" rel="noreferrer noopener">Learn How to Upgrade</a>.',
    FEATURE_OEM_RESTRICTION: 'You don\'t have access to make changes on this page. Please contact Support if you have any questions.',
    ACCESS_CONTROL_AUTHORIZATION_RESTRICTION: 'You don\'t have privileges to use this feature',
    GLOBAL_PAGE_DELETE_RESTRICTION: 'This is a global page and can\'t be deleted. Please disable the global page feature and try again.',
    GLOBAL_PAGE_UPDATE_RESTRICTION: 'You don\'t have access to edit this page.&nbsp;If you have any questions please reach out to <a href="mailto:websitesupport@dealersocket.com">websitesupport@dealersocket.com</a>',
    CHILD_PAGE_UPDATE_RESTRICTION: 'This page can\'t be edited because it belongs to a Global&nbsp;Page. Please visit the <a href="/website/:website/page/:page" target="_blank">Global&nbsp;Page</a> if you want to make changes.',
    SERVER_MERGE_ERROR: 'Merge Error. Reload page and try again.',
    CONTAINER_TEMPLATE_EDIT_TITLE: 'Snapshot Settings',
    CONTAINER_TEMPLATE_CREATE_TITLE: 'Add New Container',
    CONTAINER_TEMPLATE_CREATE_DESCRIPTION: 'All containers that you save will move into the gallery of modules to the <strong>Module&nbsp;Groups</strong> category.',
    CONTAINER_TEMPLATE_CREATE_BUTTON_TITLE: 'Create',
    CONTAINER_TEMPLATE_UPDATE_BUTTON_TITLE: 'Save',
    CONTAINER_TEMPLATE_FIELD_NAME_LABEL: 'Snapshot Name',
    CONTAINER_TEMPLATE_FIELD_VISIBILITY_LABEL: 'Visibility',
    CONTAINER_TEMPLATE_FIELD_CATEGORY_LABEL: 'Select Category',
    CONTAINER_TEMPLATE_SAVE_ERROR: 'Something went wrong please try again.',
    CONTAINER_TEMPLATE_THUMBNAIL_UPLOAD_ERROR: 'Error occured while uploading thumbnail',
    CONTAINER_TEMPLATE_THUMBNAIL_UPLOAD_ERROR_FILE_TOO_BIG: 'Your uploaded file must be 4 MB or less.',
    CONTAINER_TEMPLATE_DELETE_WARNING: 'Are you sure you want to delete this container template? This cannot be undone.',
    GALLERY_ZOOM: 'Zoom Thumbnail',
    GLOBAL_PAGE_USAGE_RESTRICTION: 'This module isn\'t compatible with Global Pages. Please see this <a href="https://dealersocket.atlassian.net/wiki/spaces/EN/pages/946504374/Research+Bulk+management+tech+capabilities" target="_blank" rel="noreferrer noopener">doc</a> for more info.',
    GLOBAL_VISIBILITY_HIDE: 'Hide Hidden Elements',
    GLOBAL_VISIBILITY_SHOW: 'Show Hidden Elements',
    ADD_MODULE_NOTIFICATION_INFO: 'Please check other device types to ensure the layout appears correctly after you are finished editing the page.',
    GRID_EDITOR_ENABLED_INFO: 'You are in Grid Editor mode. The changes you make will only apply to all the screen sizes within this device type.'
  };
  _exports.default = _default;
});