define("builder/pods/components/menumanager-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j0t48qHT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"menu-manager__item \",[28,\"if\",[[24,[\"editMode\"]],\"menu-manager__item_edit\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"menu-manager__icon \",[22,\"icon\"]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"editMode\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[8],[1,[28,\"input\",null,[[\"type\",\"autofocus\",\"value\",\"class\"],[\"text\",true,[24,[\"editTitle\"]],[28,\"concat\",[\"menu-manager__input\",\" \",[28,\"if\",[[24,[\"error\"]],\"menu-manager__input_error\"],null],\" \"],null]]]],false],[9],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"menu-manager__icon menu-manager__icon_enter icon-apply-enter\"],[3,\"action\",[[23,0,[]],\"applyTitle\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"menu-manager__label \",[28,\"if\",[[24,[\"data\",\"isHidden\"]],\"menu-manager__label_hidden\"],null]]]],[3,\"action\",[[23,0,[]],\"titleClick\"]],[3,\"action\",[[23,0,[]],\"editTitle\"],[[\"on\"],[\"doubleClick\"]]],[8],[1,[24,[\"data\",\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[1,[28,\"menumanager-actions\",null,[[\"toggleHide\",\"stateAction\",\"removeItem\",\"openConfig\"],[[28,\"action\",[[23,0,[]],\"toggleHide\"],null],[28,\"action\",[[23,0,[]],\"changeState\"],null],[28,\"action\",[[23,0,[]],\"remove\"],null],[28,\"action\",[[23,0,[]],\"openConfig\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/menumanager-link/template.hbs"
    }
  });

  _exports.default = _default;
});