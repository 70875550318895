define("builder/models/website-font", ["exports", "@ember-data/model", "builder/config/environment", "builder/models/model", "builder/core/enumerators/font-source"], function (_exports, _model, _environment, _model2, _fontSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WebsiteFontModel
   */
  var _default = _model2.default.extend({
    /**
     * @property {String} Name - font name
     */
    Name: (0, _model.attr)('string'),

    /**
     * @property {String} Title - font title
     */
    Title: (0, _model.attr)('string'),

    /**
     * @property {String} Source - font source ('dealersocket' or 'google')
     */
    Source: (0, _model.attr)('string'),

    /**
     * @property {String} VisibilityType - visibility type (e.g. current website, only dealer website or global)
     */
    VisibilityType: (0, _model.attr)('string'),

    /**
     * @property {String} File - file hash if font uploaded via file or link to google font
     */
    File: (0, _model.attr)('string'),

    /**
     * @property {String} Url - generated url where font is located
     * @readonly
     */
    Url: Ember.computed('Source', 'Name', function () {
      switch (this.Source) {
        case _fontSource.default.DEALERSOCKET:
          return window.decodeURIComponent(`${_environment.default.APP.BASE_FONTS_URI}${this.Name}/styles.css`);

        case _fontSource.default.GOOGLE:
          return window.decodeURIComponent(`${_environment.default.APP.GOOGLE_FONT_API_URI}?family=${this.Name}:italic,normal,300,700,800`);

        default:
          return '';
      }
    }).readOnly()
  });

  _exports.default = _default;
});