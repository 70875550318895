define("builder/pods/website/edit/page/edit/widget-settings", ["exports", "builder/pods/website/edit/page/edit/module/settings/controller", "ember-copy", "builder/core/util/define-fields-visibility", "builder/core/enumerators/container", "builder/core/enumerators/widget", "builder/core/configuration-builder/fields/field-media", "builder/core/enumerators/breakpoint-modes", "builder/core/grid-models/module-device-visibility-state", "builder/core/literals", "builder/helpers/helpers"], function (_exports, _controller, _emberCopy, _defineFieldsVisibility, _container, _widget, _fieldMedia, _breakpointModes, _moduleDeviceVisibilityState, _literals, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetSettingsController
   */
  var _default = _controller.default.extend({
    /**
     * Widget service
     * @type {WidgetService}
     */
    widgetService: Ember.inject.service('widget'),

    /**
     * Grid service
     * @type {GridService}
     */
    gridService: Ember.inject.service('grid'),

    /**
     * Bootstrap service
     * @type {BootstrapService}
     */
    bootstrapService: Ember.inject.service('bootstrap'),

    /**
     * @type {TabService}
     */
    tabService: Ember.inject.service('tab'),

    /**
     * @type {AnimationService}
     */
    animationService: Ember.inject.service('animation'),

    /**
     * Widget instance
     * @type {Object}
     */
    _widget: null,

    /**
     * Store widget instance
     * @param {Object} widget - widget instance
     * @returns {void}
     */
    setWidget(widget) {
      this.set('_widget', widget);
    },

    /**
     * Get common config for widget
     * @param {Object} widget - widget instance
     * @param {Object} config - widget config extracted from page structure
     * @returns {Object[]|null}
     */
    getWidgetTabConfigFields(widget, config) {
      if (!widget) {
        return null;
      }

      const type = this.structureService.getType(widget);
      let widgetConfig = this.widgetService.getConfig(type);

      if (!widgetConfig) {
        return null;
      } // Make instance of config structure


      widgetConfig = (0, _emberCopy.copy)(widgetConfig, true);

      if (!Ember.get(widgetConfig, 'config')) {
        Ember.set(widgetConfig, 'config', []);
      }

      const originObject = widget === null || widget === void 0 ? void 0 : widget.originObject;

      if (!originObject) {
        return null;
      }

      if (this.structureService.getType(widget) === _widget.WidgetType.WIDGET_IMAGE) {
        widgetConfig.config = this._processDataFields(this.structureService.getType(widget), widget, Ember.get(widgetConfig, 'config'), widget);
      }

      widgetConfig.config = this._processFields(this.structureService.getType(widget), originObject, Ember.get(widgetConfig, 'config'), widget);

      const composedConfig = this._composeConfig(Ember.get(widgetConfig, 'config'), config);

      (0, _defineFieldsVisibility.defineFieldsVisibility)(composedConfig);
      return composedConfig;
    },

    /**
     * Get styles config for widget
     * @param {Object} widget - widget instance
     * @returns {Object[]|null}
     */
    getWidgetTabStylesFields(widget) {
      var _this$widgetService;

      if (!widget) {
        return null;
      }

      const {
        structureService
      } = this;
      const widgetConfig = (_this$widgetService = this.widgetService) === null || _this$widgetService === void 0 ? void 0 : _this$widgetService.getConfig(structureService.getType(widget));

      if (!widgetConfig) {
        return null;
      }

      const parent = structureService.getParent(widget);
      let styles = Ember.get(widgetConfig, 'styles') || []; // If it's widget-container in header layout, we need to remove Height option from config
      // @todo use structure service to get parent and type

      if (structureService.isType(parent, _container.default.WIDGET_HEADER) && styles.length) {
        const copyStyles = (0, _emberCopy.copy)(styles, true);
        styles = copyStyles.removeObject(copyStyles.findBy('label', 'Height'));
      }

      return styles;
    },

    /**
     * Check existing config for widget
     * @param {Object} widget - widget structure
     * @returns {boolean}
     */
    hasConfig(widget) {
      return !!this.widgetService.getConfig(this.structureService.getType(widget));
    },

    /**
     * Process fields for widget config popup
     * @param {string} type - widget type
     * @param {Ember.Object} originObject - source data for widget
     * @param {Object} widgetConfigFields - widget specific fields from config
     * @param {Ember.Object} widget - widget, owner of fields
     * @returns {Object[]} - prepared fields
     * @private
     */
    _processFields(type, originObject, widgetConfigFields, widget) {
      var _this$configurationSe, _this$configurationSe2;

      const {
        breakpoint
      } = (_this$configurationSe = this.configurationService) === null || _this$configurationSe === void 0 ? void 0 : (_this$configurationSe2 = _this$configurationSe.device) === null || _this$configurationSe2 === void 0 ? void 0 : _this$configurationSe2.getDevice();
      return widgetConfigFields.map(item => {
        if (originObject.hasOwnProperty(item.name)) {
          if (type === _widget.WidgetType.WIDGET_IMAGE && item.name === 'src') {
            this._buildFieldMediaImageConfig(item, originObject, widget, breakpoint);
          } else if (item.name === 'visibleOnDevices') {
            this._buildVisibleOnDevicesConfig(item, type, originObject, widget);
          } else {
            Ember.set(item, 'data', Ember.get(originObject, item.name));
          }
        } else if (item.name === 'tabs') {
          this._buildWidgetTabsConfig(item, originObject); // Buttons group data a little bit more complex than the others

        } else if (_widget.WidgetType.WIDGET_BUTTONSGROUP === type) {
          Ember.set(item, 'data', {
            group: Ember.get(originObject, 'group'),
            buttons: Ember.get(originObject, 'buttons')
          });
        }

        return item;
      });
    },

    /**
     * Process fields for widget config popup
     * @param {string} type - widget type
     * @param {Ember.Object} data - source data for widget
     * @param {Object} widgetConfigFields - widget specific fields from config
     * @param {Ember.Object} widget - widget, owner of fields
     * @returns {Object[]} - prepared fields
     * @private
     */
    _processDataFields(type, data, widgetConfigFields, widget) {
      var _this$configurationSe3, _this$configurationSe4;

      const {
        breakpoint
      } = (_this$configurationSe3 = this.configurationService) === null || _this$configurationSe3 === void 0 ? void 0 : (_this$configurationSe4 = _this$configurationSe3.device) === null || _this$configurationSe4 === void 0 ? void 0 : _this$configurationSe4.getDevice();
      return widgetConfigFields.map(item => {
        if (data.hasOwnProperty(item.name)) {
          if (type === _widget.WidgetType.WIDGET_IMAGE && item.name === 'src') {
            this._buildFieldMediaImageConfig(item, data, widget, breakpoint);
          } else if (item.name === 'visibleOnDevices') {
            this._buildVisibleOnDevicesConfig(item, type, data, widget);
          } else {
            Ember.set(item, 'data', Ember.get(data, item.name));
          }
        } else if (item.name === 'tabs') {
          this._buildWidgetTabsConfig(item, data); // Buttons group data a little bit more complex than the others

        } else if (_widget.WidgetType.WIDGET_BUTTONSGROUP === type) {
          Ember.set(item, 'data', {
            group: Ember.get(data, 'group'),
            buttons: Ember.get(data, 'buttons')
          });
        }

        return item;
      });
    },

    _buildWidgetTabsConfig(item, originObject) {
      const children = (originObject === null || originObject === void 0 ? void 0 : originObject.children) || [];
      const isTheOnlyTab = children.length <= 1;
      const variantsConfig = [];
      Ember.set(item, 'data', variantsConfig);
      /**
       * Tab variant factory-function is being used to map widget-tab-pane data to a data that is
       * consumable by form variants
       *
       * @TODO refactor this approach
       *
       * @param tabPane
       * @param removeIsEnabled
       * @return {*}
       */

      const tabVariantFactory = function (tabPane, removeIsEnabled) {
        var _tabPane$originObject, _tabPane$originObject2;

        const formVariantConfig = (0, _emberCopy.copy)(tabPane.originObject);
        const isDefaultTab = (_tabPane$originObject = (_tabPane$originObject2 = tabPane.originObject) === null || _tabPane$originObject2 === void 0 ? void 0 : _tabPane$originObject2.isDefault) !== null && _tabPane$originObject !== void 0 ? _tabPane$originObject : false;
        formVariantConfig['variant-name'] = 'custom';
        formVariantConfig.createdAt = Date.now();
        formVariantConfig.isOpened = isDefaultTab;
        formVariantConfig.isRemoveEnabled = removeIsEnabled;
        formVariantConfig.isOldDefault = isDefaultTab;
        return formVariantConfig;
      };

      children.forEach(tabPane => {
        variantsConfig.push(tabVariantFactory(tabPane, !isTheOnlyTab));
      });
      const variantsActionDecorator = {
        tabService: this.tabService,

        toggleVariant(variant) {
          this.tabService.setActiveTab(children, variant.localData.id);
        },

        removeVariant(variant) {
          return window.confirm(_literals.default.DELETE_TAB_PANE_CONFIRMATION_TEXT);
        }

      };
      Ember.set(item, 'data', variantsConfig);
      Ember.set(item, 'variantsActionDecorator', variantsActionDecorator);
    },

    updateTabsWidgetConfigAfterChange(fields, widget) {
      var _fields$find;

      const item = (_fields$find = fields.find(itemField => (itemField === null || itemField === void 0 ? void 0 : itemField.name) === 'tabs')) !== null && _fields$find !== void 0 ? _fields$find : null;

      if (!item || !(widget === null || widget === void 0 ? void 0 : widget.originObject)) {
        return;
      }

      this._buildWidgetTabsConfig(item, widget.originObject);
    },

    /**
     *
     * @param {Field | FieldCheckBoxList} field item (for this particular case should be FieldCheckBoxList)
     * @param {Copyable & EmberObject} originObject - grid object
     * @param {EmberObject} widget object
     * @param {string} breakpoint
     *
     * @returns {void}
     * @private
     */
    _buildFieldMediaImageConfig(item, originObject, widget, breakpoint) {
      const builder = _fieldMedia.default.create();

      if (!breakpoint) {
        return;
      }

      Ember.set(item, 'data', builder.build({
        type: 'image',
        url: Ember.get(originObject, item.name),
        naturalWidth: Ember.get(originObject, 'naturalWidth'),
        naturalHeight: Ember.get(originObject, 'naturalHeight'),
        scale: Ember.get(originObject, `focalPoints.${breakpoint}.scale`),
        left: Ember.get(originObject, `focalPoints.${breakpoint}.left`),
        top: Ember.get(originObject, `focalPoints.${breakpoint}.top`)
      }, widget));
    },

    /**
     * Building configuration for visibleOnDevicesField
     * @param {Field | FieldCheckBoxList} field item (for this particular case should be FieldCheckBoxList)
     * @param {String} type of a widget
     * @param {Copyable & EmberObject} originObject - grid object
     * @param {EmberObject} widget object
     * @private
     */
    _buildVisibleOnDevicesConfig(item, type, originObject, widget) {
      const visibleOn = originObject === null || originObject === void 0 ? void 0 : originObject.visibleOnDevices;

      if (!visibleOn) {
        return;
      }

      const visibleOnOptions = _moduleDeviceVisibilityState.default.create(visibleOn).buildConfig();

      if (type === _container.default.WIDGET_CONTAINER) {
        item.choices.forEach(choice => {
          const device = choice.value;

          if (!device || ![_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].includes(device)) {
            return;
          }

          choice._disabled = !widget.hasVisibleChildrenInMode(originObject.children, device);
        });
      }

      Ember.set(item, 'data', visibleOnOptions);
    },

    /**
     * Merge to widget configs, 1-st default config should be passed, second - modifier of default config
     * @param {Object[]} sourceConfig - default widget config
     * @param {Object} optionalConfig - config modifier
     * @returns {Object[]} - modified original config
     * @private
     */
    _composeConfig(sourceConfig, optionalConfig = {}) {
      const fields = sourceConfig;

      if (!fields || !fields.length) {
        return [];
      }

      return fields.map(field => {
        const fieldConfig = optionalConfig.hasOwnProperty(field.name) ? optionalConfig[field.name] : null;

        if (fieldConfig) {
          Object.keys(fieldConfig).forEach(key => {
            field[key] = fieldConfig[key];
          });
        }

        return field;
      });
    },

    /**
     * Removes empty images
     * @param {Object} activeWidget - Active widget object
     * @returns {boolean}
     */
    isEmptyImageWidget(activeWidget) {
      var _activeWidget$originO;

      return activeWidget && this.structureService.isType(activeWidget, _widget.WidgetType.WIDGET_IMAGE) && !((_activeWidget$originO = activeWidget.originObject) === null || _activeWidget$originO === void 0 ? void 0 : _activeWidget$originO.src);
    },

    /**
     * Handles changes in widget config and update widget object
     * @param {*} value - Widget config field value
     * @param {string} name - Widget config field name
     * @param {Object[]} fields - Config fields
     * @returns {void}
     */
    handleWidgetChange(value, name, fields) {
      var _this$_widget, _this$configurationSe5, _this$configurationSe6;

      const widget = (_this$_widget = this._widget) === null || _this$_widget === void 0 ? void 0 : _this$_widget.originObject;

      if (!widget) {
        return;
      }

      const breakpoint = (_this$configurationSe5 = this.configurationService) === null || _this$configurationSe5 === void 0 ? void 0 : (_this$configurationSe6 = _this$configurationSe5.currentDevice) === null || _this$configurationSe6 === void 0 ? void 0 : _this$configurationSe6.breakpoint;

      if (breakpoint) {
        if (this.structureService.getType(widget) === _widget.WidgetType.WIDGET_IMAGE) {
          this.widgetService.setData(widget, name, value, breakpoint, _widget.WidgetType.WIDGET_IMAGE);
          this.removeFieldsFromWidgetOriginObject(name, widget);
          (0, _helpers.observeImageChanges)(this._widget, name, value, breakpoint, fields);
        } else {
          this.widgetService.setData(widget, name, value, breakpoint);
        }
      }

      if (name === 'visibleOnDevices') {
        this.gridService.adjustWidgetVisibility(this._widget);
      }

      if (name === 'tabs' && widget.type === _container.default.WIDGET_TABS) {
        this.tabService.updateTabsState(this._widget, value);
        this.updateTabsWidgetConfigAfterChange(fields, this._widget);
      }

      if (name === 'animationMirroring') {
        setTimeout(() => {
          this.animationService.refreshAOS();
        }, 0);
      }

      if (name === 'enableAnimation') {
        this.animationService.refreshElementsList();
      }

      this._showPopupConfigStatusMessage('success');

      this._hidePopupConfigStatusMessage(3000);

      (0, _defineFieldsVisibility.defineFieldsVisibility)(fields);
    },

    /*
     *	As these fields are not added to json schema
     *  We should remove from structure else will throw validation error
     */
    removeFieldsFromWidgetOriginObject(name, widget) {
      if (name === 'clientHeight') {
        Ember.set(this._widget, 'clientHeight', Ember.get(widget, 'clientHeight'));
        delete widget.clientHeight;
      }

      if (name === 'clientWidth') {
        Ember.set(this._widget, 'clientWidth', Ember.get(widget, 'clientWidth'));
        delete widget.clientWidth;
      }

      if (name === 'reset') {
        Ember.set(this._widget, 'reset', Ember.get(widget, 'reset'));
        delete widget.reset;
      }
    },

    actions: {
      updateLockState(...args) {
        this._super(...args);
      }

    }
  });

  _exports.default = _default;
});