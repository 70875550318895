define("builder/models/system-module", ["exports", "@ember-data/model", "builder/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * System Module model
   */
  var _default = _model2.default.extend({
    /**
     * Id of module config (Page Module model) related to system module
     * @type {number}
     */
    ModuleConfigurationId: (0, _model.attr)('number'),

    /**
     * Flag to indicate whether system module is enabled or disabled
     * @type {number}
     */
    IsActive: (0, _model.attr)('number'),

    /**
     * System module name
     * @type {string}
     */
    Module: (0, _model.attr)('string'),

    /**
     * System module theme name
     * @type {string}
     */
    Theme: (0, _model.attr)('string'),

    /**
     * Loading state of the system module
     * @type {boolean}
     */
    isLoadingNow: false,

    /**
     * Link to module
     * @type {ModuleModel}
     */
    module: null,

    /**
     * Website language
     * @type {string}
     */
    language: null,

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Layout name
     * @type {string}
     */
    layout: Ember.computed.readOnly('configurationService.page.WebsiteLayoutName', function () {
      return this.get('configurationService.page.WebsiteLayoutName');
    }),

    /**
     * Module id
     * @type {number}
     */
    moduleId: Ember.computed('Module', function () {
      return this.store.peekAll('module').findBy('Name', this.Module).id;
    }),

    /**
     * List of themes of the system module
     * @type {ModuleThemeModel[]}
     */
    themes: Ember.computed('moduleId', function () {
      return this.store.peekAll('module-theme').filter(theme => theme.ModuleId === this.moduleId && theme.IsSystem);
    }),

    /**
     * Current selected theme
     * @type {ModuleThemeModel}
     */
    selectedTheme: Ember.computed('Theme', function () {
      return this.themes.find(theme => theme.Name === this.Theme);
    }),

    /**
     * Shows whether module has config or not
     * @type {boolean}
     */
    hasConfig: Ember.computed('selectedTheme', function () {
      const theme = this.selectedTheme;

      if (!theme) {
        return false;
      }

      const strings = theme.get('Strings') ? theme.get(`Strings.${this.language}`) : null;
      return !!(theme.get('Structure.length') || strings && Object.keys(strings).length);
    }),

    /**
     * Flag determines whether system modules is enabled on the current layout
     * @type {boolean}
     */
    isEnabled: Ember.computed('themes.[]', 'layout', function () {
      const themes = this.themes.filter(theme => {
        const layout = theme.Layout || [];
        return layout.includes(this.layout);
      });
      return !!themes.length;
    })
  });

  _exports.default = _default;
});