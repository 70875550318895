define("builder/core/grid-models/widget-container", ["exports", "builder/core/grid-models/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetContainerGridModel
   */
  var _default = _base.default.extend({
    /**
     * @param {Boolean} hasActiveChild - same as isActive, but since property isActive already occupied, property
     * name change required, indicates if there is an active child inside current container
     */
    hasActiveChild: Ember.computed('originObject.children.[]', 'originObject.children.@each.isActive', function () {
      const children = this.get('originObject.children') || [];
      let hasActiveChild = false;

      for (let i = 0, len = children.length; i < len; i++) {
        if (children[i].get('isActive')) {
          hasActiveChild = true;
          break;
        }
      }

      return hasActiveChild;
    }),

    /**
     * @param {Boolean} isActive - reset property from computed to regular, since it's used through the application as
     * a regular property
     */
    isActive: false
  });

  _exports.default = _default;
});