define("builder/pods/components/gallery-widget/component", ["exports", "builder/pods/components/gallery-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * list of classes to be added to ember wrapper
     * @type {string[]}
     */
    classNames: ['__module'],

    /**
     * image src
     * @type {string}
     * @readonly
     */
    image: Ember.computed.readOnly('data.image'),

    /**
     * widget title
     * @type {string}
     * @readonly
     */
    title: Ember.computed.readOnly('data.title')
  });

  _exports.default = _default;
});