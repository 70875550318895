define("builder/pods/components/shell-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ShellButtonComponent
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    tagName: 'button',

    /**
     * @inheritdoc
     */
    type: 'button',

    /**
     * @inheritdoc
     */
    classNames: ['bttn', 'page_edit_tools_button'],

    /**
     * @inheritdoc
     */
    attributeBindings: ['type', 'disabled', 'title'],

    /**
     * @property {Boolean} disabled - is button disabled
     */
    disabled: false,

    /**
     * @property {String} icon - svg icon id
     */
    icon: '',

    /**
     * @property {String} title - button title
     */
    title: 'Shell button',

    /**
     * @property {Boolean} isTitleVisible - display title or not
     */
    isTitleVisible: true,

    /**
     * @property {Function} onClick - callback to be invoked when button clicked
     */
    onClick() {},

    /**
     * @inheritdoc
     */
    click() {
      if (this.onClick && typeof this.onClick === 'function') {
        this.onClick();
      }
    }

  });

  _exports.default = _default;
});