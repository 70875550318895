define("builder/core/styles-management/widget-tabs/structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WidgetTabsStyleInterface = void 0;
  const WidgetTabsStyleInterface = [{
    label: 'Color',
    variableName: 'color',
    cssPropertyName: 'color'
  }, {
    label: 'Background Color',
    variableName: 'background-color',
    cssPropertyName: 'background-color'
  }, {
    variableName: 'font-size',
    cssPropertyName: 'font-size',
    label: 'Font Size'
  }, {
    variableName: 'font-weight',
    cssPropertyName: 'font-weight',
    label: 'Font Weight'
  }, {
    variableName: 'letter-spacing',
    cssPropertyName: 'letter-spacing',
    label: 'Letter Spacing'
  }, {
    variableName: 'text-transform',
    cssPropertyName: 'text-transform',
    label: 'Text Transform'
  }, {
    variableName: 'text-decoration',
    cssPropertyName: 'text-decoration',
    label: 'Text Decoration'
  }, {
    variableName: 'justify-content',
    cssPropertyName: 'justify-content',
    label: 'Justify Content'
  }, {
    name: 'composite',
    label: 'Border',
    type: 'border',
    children: [{
      variableName: 'border-width',
      cssPropertyName: 'border-width',
      label: 'Width'
    }, {
      variableName: 'border-style',
      cssPropertyName: 'border-style',
      label: 'Style'
    }, {
      variableName: 'border-color',
      cssPropertyName: 'border-color',
      label: 'Color'
    }, {
      variableName: 'border-radius',
      cssPropertyName: 'border-radius',
      label: 'Radius'
    }]
  }, {
    label: 'Padding',
    name: 'composite',
    type: 'rectangle',
    children: [{
      label: 'Top',
      variableName: 'padding-top',
      cssPropertyName: 'padding-top',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Right',
      variableName: 'padding-right',
      cssPropertyName: 'padding-right',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Bottom',
      variableName: 'padding-bottom',
      cssPropertyName: 'padding-bottom',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }, {
      label: 'Left',
      variableName: 'padding-left',
      cssPropertyName: 'padding-left',
      options: {
        global: {
          range: {
            px: {
              min: 0,
              max: 150
            }
          },
          rangeInput: {
            px: {
              max: 999
            }
          }
        }
      }
    }]
  }];
  _exports.WidgetTabsStyleInterface = WidgetTabsStyleInterface;
});