define("builder/core/form-field-icons", ["exports", "builder/core/enumerators/form-fields"], function (_exports, _formFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List of icons associated with form fields
   */
  var _default = {
    [_formFields.default.ADDRESS_LINE]: 'fa fa-map-marker',
    [_formFields.default.CHECKBOX_LIST]: 'fa fa-list',
    [_formFields.default.CITY]: 'fa fa-flag-o',
    [_formFields.default.COMMENTS]: 'fa fa-comments-o',
    [_formFields.default.DATE]: 'fa fa-calendar-o',
    [_formFields.default.DATE_OF_BIRTH]: 'fa fa-calendar-o',
    [_formFields.default.EMAIL]: 'fa fa-envelope-o',
    [_formFields.default.FILE]: 'fa fa-upload',
    [_formFields.default.FIRST_NAME]: 'fa fa-square-o',
    [_formFields.default.LAST_NAME]: 'fa fa-square-o',
    [_formFields.default.LOCATION]: 'fa fa-map-marker',
    [_formFields.default.MIDDLE_INITIAL]: 'fa fa-square-o',
    [_formFields.default.NUMBER]: 'fa fa-square-o',
    [_formFields.default.PHONE]: 'fa fa-mobile-phone',
    [_formFields.default.SCHEDULER_CALENDAR]: 'fa fa-calendar-o',
    [_formFields.default.SOCIAL_SECURITY]: 'fa fa-check-square-o',
    [_formFields.default.STATE]: 'fa fa-flag-o',
    [_formFields.default.STREET_ADDRESS]: 'fa fa-map-marker',
    [_formFields.default.VEHICLE_MAKE]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_MILEAGE]: 'fa fa-road',
    [_formFields.default.VEHICLE_MODEL]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_PRICE]: 'fa fa-money',
    [_formFields.default.VEHICLE_TYPE]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRIM]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_YEAR]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_SELECTOR]: 'fa fa-th-list',
    [_formFields.default.WEBSITE]: 'fa fa-credit-card',
    [_formFields.default.ZIP_CODE]: 'fa fa-map-marker',
    [_formFields.default.FIELDSET]: 'fa fa-clone',
    [_formFields.default.TEXT]: 'fa fa-square-o',
    [_formFields.default.DROPDOWN]: 'fa fa-th-list',
    [_formFields.default.TEXTAREA]: 'fa fa-square-o',
    [_formFields.default.RADIOLIST]: 'fa fa-ellipsis-v',
    [_formFields.default.CHECKBOX]: 'fa fa-check-square-o',
    [_formFields.default.DEPARTMENT]: 'fa fa-th-list',
    [_formFields.default.DRIVER_LICENSE_STATE]: 'fa fa-id-card-o',
    [_formFields.default.DRIVER_LICENSE]: 'fa fa-id-card-o',
    [_formFields.default.NAME_PREFIX]: 'fa fa-chevron-up',
    [_formFields.default.TCPA_CONSENT]: 'fa fa-balance-scale',
    [_formFields.default.PREFERRED_CONTACT]: 'fa fa-envelope-o',
    [_formFields.default.LIVING_SINCE]: 'fa fa-calendar-o',
    [_formFields.default.RENT_OR_OWN]: 'fa fa-home',
    [_formFields.default.VEHICLE_PURCHASE_TYPE]: 'fa fa-shopping-cart',
    [_formFields.default.VEHICLE_LOAN_AMOUNT]: 'fa fa-money',
    [_formFields.default.VEHICLE_TRADE_IN_MAKE]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRADE_IN_MODEL]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRADE_IN_YEAR]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRADE_IN_TRIM]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRANSMISSION]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRADE_IN]: 'fa fa-th-list',
    [_formFields.default.VEHICLE_TRADE_IN_MILEAGE]: 'fa fa-road',
    [_formFields.default.EMP_CO_COMPANY]: 'fa fa-building',
    [_formFields.default.EMP_CO_PREV_COMPANY]: 'fa fa-building',
    [_formFields.default.EMP_CO_INCOME]: 'fa fa-money',
    [_formFields.default.EMP_CO_OTHER_INCOME_SOURCE]: 'fa fa-money',
    [_formFields.default.EMP_CO_OTHER_INCOME]: 'fa fa-money',
    [_formFields.default.EMP_CO_OCCUPATION]: 'fa fa-briefcase',
    [_formFields.default.EMP_CO_SINCE_DATE]: 'fa fa-calendar-o',
    [_formFields.default.CO_ADDRESS_CITY]: 'fa fa-flag-o',
    [_formFields.default.CO_ADDRESS_STATE]: 'fa fa-flag-o',
    [_formFields.default.CO_ADDRESS_STREET]: 'fa fa-map-marker',
    [_formFields.default.CO_ADDRESS_STREET2]: 'fa fa-map-marker',
    [_formFields.default.CO_ADDRESS_ZIP]: 'fa fa-map-marker',
    [_formFields.default.CO_DATEOFBIRTH]: 'fa fa-calendar-o',
    [_formFields.default.CO_DAY_TIME_PHONE]: 'fa fa-mobile',
    [_formFields.default.CO_DRIVERLICENSE_STATE]: 'fa fa-id-card-o',
    [_formFields.default.CO_DRIVERLICENSE]: 'fa fa-id-card-o',
    [_formFields.default.CO_EMAIL]: 'fa fa-envelope-o',
    [_formFields.default.CO_EVENING_PHONE]: 'fa fa-phone',
    [_formFields.default.CO_FIRSTNAME]: 'fa fa-square-o',
    [_formFields.default.CO_LASTNAME]: 'fa fa-square-o',
    [_formFields.default.CO_LIVING_SINCE]: 'fa fa-calendar-o',
    [_formFields.default.CO_MIDDLENAME]: 'fa fa-square-o',
    [_formFields.default.CO_MONTHLY_PAYMENT]: 'fa fa-money',
    [_formFields.default.CO_RENT_OR_OWN]: 'fa fa-home',
    [_formFields.default.CO_SOCIAL_SECURITY]: 'fa fa-check-square-o',
    [_formFields.default.EMP_COMPANY_CITY]: 'fa fa-flag-o',
    [_formFields.default.EMP_COMPANY_PHONE]: 'fa fa-phone',
    [_formFields.default.EMP_COMPANY_STATE]: 'fa fa-flag-o',
    [_formFields.default.EMP_COMPANY_STREETADDRESS]: 'fa fa-map-marker',
    [_formFields.default.EMP_COMPANY_ZIPCODE]: 'fa fa-map-marker',
    [_formFields.default.EMP_COMPANY]: 'fa fa-building',
    [_formFields.default.EMP_INCOME_MONTHLY]: 'fa fa-money',
    [_formFields.default.EMP_OCCUPATION]: 'fa fa-briefcase',
    [_formFields.default.EMP_OTHER_INCOME_MONTHLY]: 'fa fa-money',
    [_formFields.default.EMP_OTHER_INCOME_SOURCE]: 'fa fa-money',
    [_formFields.default.EMP_SINCE_DATE]: 'fa fa-calendar-o'
  };
  _exports.default = _default;
});