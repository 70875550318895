define("builder/pods/components/website-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eI8/bTy+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"website-menu_list_item-title\\n\\t\",[28,\"if\",[[24,[\"submenu\"]],\" __submenu\"],null],\"\\n\\t\",[28,\"if\",[[24,[\"showBottomBorder\"]],\" __opened\"],null],\"\\n\\t\",[28,\"if\",[[24,[\"item\",\"isHidden\"]],\" __hidden\"],null],\"\\n\\t\",[28,\"if\",[[28,\"or\",[[24,[\"item\",\"isDelete\"]],[24,[\"item\",\"isMissingPage\"]]],null],\" __delete\"],null]]]],[12,\"title\",[24,[\"item\",\"title\"]]],[3,\"action\",[[23,0,[]],\"openOrSelect\",[24,[\"item\"]]]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"website-menu_list_item-text\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"website-menu_list_item_icon \",[28,\"if\",[[24,[\"icon\"]],[24,[\"icon\"]],\"fa fa-home\"],null]]]],[8],[9],[0,\"\\n\\t\\t\"],[1,[24,[\"item\",\"title\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/website-menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});