define("builder/utils/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class LocationUtil
   */
  var _default = Ember.Object.create({
    /**
     * Get relative url from absolute url
     *
     * @param {String} href - href from which pathname will be extracted
     * @returns {*}
     */
    pathname(href) {
      let pathname = this._getPathname(href); // Since ie returns without leading slash, but leading slash kinda required, not sure if it's correct,
      // but for now we rely on leading slash


      if (pathname && pathname.indexOf('/') !== 0) {
        pathname = `/${pathname}`;
      }

      return pathname;
    },

    /**
     * The simplest version of getting filename from url
     *
     * @param {String} href - path to file, from which file name will be extracted
     * @returns {String} - filename
     */
    filename(href) {
      return href.split('/').pop();
    },

    /**
     * Get pathname from href
     *
     * @param {String} href - href, from which pathname should be extracted
     * @returns {String} - extracted pathname
     * @private
     */
    _getPathname(href) {
      const a = document.createElement('a');
      a.href = href; // Empty(''|null) href will take current path as href, so current pathname will be returned, which is not correct

      return href ? a.pathname : '';
    }

  });

  _exports.default = _default;
});