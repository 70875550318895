define("builder/pods/components/field-date/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "builder/helpers/date-format", "builder/helpers/date-parse"], function (_exports, _fieldValidation, _fieldVisibility, _dateFormat, _dateParse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldDateComponent
   */
  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-form_date'],

    /**
     * @property {Function|null} actionHandler - handler to be invoked when date value changes(datepicker closes date picker)
     */
    actionHandler: null,

    /**
     * @property {Boolean} datepickerVisible - control date picker show/hide state, actually will affect only show state,
     * since datepicker will reset this property to false internally
     */
    datepickerVisible: false,

    /**
     * @property {String} value - date string value
     */
    value: Ember.computed('_value', function () {
      return (0, _dateFormat.formatDate)([this._value]);
    }),

    /**
     * @property {Date|null} _value - actual date value
     * @private
     */
    _value: null,

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      this.set('_value', (0, _dateParse.parseDate)([this.get('field.data')]));
    },

    /**
     * @inheritdoc
     */
    willInsertElement(...args) {
      this._super(...args);

      this.applyValidations();
    },

    /**
     * Compare dates on equality
     *
     * @method _datesAreEqual
     * @param {Date} date1 - first date to test against 2-nd
     * @param {Date} date2 - second date to test against 1-st
     * @returns {Boolean}
     * @private
     */
    _datesAreEqual(date1, date2) {
      return date1 && date2 ? date1.getTime() === date2.getTime() : date1 === date2;
    },

    actions: {
      openDatepicker() {
        this.set('datepickerVisible', true);
      },

      onClose(date) {
        const oldDate = this._value;
        const action = this.actionHandler;
        this.set('_value', date);
        const {
          value
        } = this;
        this.set('field.data', value);

        if (!this.notValid && !this._datesAreEqual(oldDate, date)) {
          action(value, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});