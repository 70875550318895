define("builder/pods/components/menumanager-folder/component", ["exports", "builder/pods/components/menumanager-item/component", "builder/models/menu-child"], function (_exports, _component, _menuChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    settingsTitle: 'Edit Folder Settings',

    /**
     * @property {String[]} classNameBindings - bind class names
     */
    classNameBindings: ['firstLevel:menu-manager__line_folder'],

    /**
     * @property {Boolean} isOpened - flag to indicate whether folder is opened
     */
    isOpened: false,

    /**
     * @property {Boolean} firstLevel - flag to indicate the level of item
     */
    firstLevel: false,

    /**
     * @property {Boolean} addToMenu - flag to indicate need add folder to menu list or unlinked list
     */
    addToMenu: false,

    /**
     * @inheritdoc
     */
    _floatingClass: 'menu-manager__item_floating',

    /**
     * @inheritdoc
     */
    _builderIconClass: 'icon-collapsed-folder',

    /**
     * @inheritdoc
     */
    _builderIconHiddenClass: 'icon-hidden-folder',

    /**
     * @inheritdoc
     */
    icon: Ember.computed('isOpened', 'data.isHidden', function (...args) {
      let icon;

      if (this.isOpened) {
        icon = this.get('data.isHidden') ? `${this._builderIconHiddenClass} ${this._hiddenIconClass}` : 'icon-expanded-folder';
      } else {
        icon = this._super(...args);
      }

      return icon;
    }),

    /**
     * @inheritdoc
     */
    getDraggableElement() {
      return this.$(' > .menu-manager__item');
    },

    /**
     * Method return data to configure form elements in config popup
     *
     * @method getConfig
     * @return {Object}
     */
    getConfig() {
      const link = this.get('data.link');
      const config = {
        type: _menuChild.default.FOLDER,
        storeSegmentRef: this.data,
        settingsTitle: this.settingsTitle,
        addToMenu: this.addToMenu,
        notShowLinkTo: !this.addToMenu,
        title: {
          label: 'Title',
          data: this.get('data.title'),
          validation: {
            required: {
              presence: true,
              ignoreBlank: true
            },
            length: {
              min: 1,
              max: 255
            }
          }
        },
        link: {
          label: 'Link To',
          data: {
            target: 'self',
            type: 'internal',
            value: ''
          },
          availableType: 'both',
          validation: {}
        }
      }; // Folder can not have property "link"
      // If we create this property here model will be dirty

      if (link !== null && typeof link === 'object') {
        const confLink = config.link;
        confLink.data.target = link.get('target');
        confLink.data.type = link.get('type');
        confLink.data.value = link.get('value');
        confLink.data.params = link.get('params');
      }

      return config;
    },

    /**
     * @inheritdoc
     */
    _draggableOn() {
      const me = this;
      const draggable = me.getDraggableElement();
      const element = me.$();

      if (!this.get('data.config.draggable') || this.editMode) {
        return false;
      }

      me.get('dragService').applyDraggable(draggable, {
        scrollObject: document.querySelector('.menu-manager__dropdown'),

        onScroll() {
          me.get('onScroll')();
        },

        onDragStart() {
          element.addClass(me.get('_hiddenClass'));
          me.get('onDragStart')();
        },

        onMouseMove(x, y, viewport) {
          me.get('onMouseMove')(me, x, y, viewport);
        },

        onMouseUp(_, __, x, y) {
          me.get('onMouseUp')(me, x, y);
        },

        getDragElement(node) {
          return me._getDragElement(node);
        },

        onDragEnd() {
          element.removeClass(me.get('_hiddenClass'));
        }

      });
      return true;
    },

    actions: {
      /**
       * Toggles folder
       *
       * @method
       */
      carretClick() {
        this.onCarretClick(this.data);
      },

      /**
       * Expand current folder and bubble action
       *
       * @method
       */
      expandParentFolder() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.set('data.config.expanded', true);

          if (typeof this.expandParentFolder === 'function') {
            this.expandParentFolder();
          }
        });
      }

    }
  });

  _exports.default = _default;
});