define("builder/core/meta-tags/serializer", ["exports", "builder/core/meta-tags/dom-manipulation"], function (_exports, _domManipulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serialize = serialize;

  /**
   * Removes span wrapper of meta tags
   * @param {string} html - HTML code
   * @returns {string} Serialized text with meta tags
   */
  function serialize(html) {
    const div = document.createElement('div');
    const metaSelector = `${_domManipulation.MetaTag.tagName}[${_domManipulation.MetaTag.dataAttribute}]`;
    div.innerHTML = html;
    const meta = div.querySelectorAll(metaSelector);
    meta.forEach(metaTag => unwrap(metaTag));
    return div.innerHTML;
  }
  /**
   * Removes tag parent
   * @param {HTMLElement} element - HTML tag to unwrap
   */


  function unwrap(element) {
    const parent = element.parentNode;
    const metaText = document.createTextNode(element.getAttribute(_domManipulation.MetaTag.dataAttribute));
    parent.insertBefore(metaText, element);
    parent.removeChild(element);
  }
});