define("builder/templates/modules-gallery-dummy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/PNeEIfF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"galleryIsShown\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"page_modules\"],[11,\"style\",[28,\"html-format\",[\"visibility: hidden;\"],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/templates/modules-gallery-dummy.hbs"
    }
  });

  _exports.default = _default;
});