define("builder/pods/components/gallery-category/component", ["exports", "builder/pods/components/gallery-item/component", "builder/core/enumerators/gallery"], function (_exports, _component, _gallery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TITLE_TO_ICON_MAP = {
    All: 'icon-gallery-all',
    Presets: 'icon-gallery-presets',
    Popular: 'icon-gallery-popular',
    Contact: 'icon-gallery-contact',
    Form: 'icon-gallery-form',
    Inventory: 'icon-gallery-inventory',
    Media: 'icon-gallery-media',
    Specials: 'icon-gallery-specials',
    [_gallery.SNAPSHOT_STUDIO]: 'icon-snapshot-studio',
    [_gallery.SnapshotCategories.SHARED_WITH_ME]: 'icon-shared-with-me',
    [_gallery.SnapshotCategories.ABOUT_US]: 'icon-about-us',
    [_gallery.SnapshotCategories.CONTACT_US]: 'icon-contact-us',
    [_gallery.SnapshotCategories.CTA_BLOCK]: 'icon-cta-block',
    [_gallery.SnapshotCategories.CUSTOMER_REVIEWS]: 'icon-customer-reviews',
    Footer: 'icon-footer',
    Header: 'icon-header',
    Hero: 'icon-hero',
    [_gallery.SnapshotCategories.LANDING_PAGE]: 'icon-landing-page',
    [_gallery.SnapshotCategories.MY_SNAPSHOTS]: 'icon-my-snapshots',
    Search: 'icon-search',
    [_gallery.SnapshotCategories.SOCIAL_BOX]: 'icon-social-box',
    SRP: 'icon-srp',
    VDP: 'icon-vdp',
    Basic: 'icon-basic'
  };

  var _default = _component.default.extend({
    /**
     * @inheritDoc
     */
    classNames: ['__category'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['data.enabled::module-gallery__item_disabled'],

    /**
     * @inheritDoc
     */
    draggable: false,

    /**
     * Category item icon class
     * @type {string}
     * @readonly
     */
    iconClass: Ember.computed('data.title', function () {
      if (this.get('data.iconClass')) {
        return this.get('data.iconClass');
      }

      return TITLE_TO_ICON_MAP[this.get('data.title')] || 'icon-gallery-all';
    }).readOnly(),

    /**
     * Category item name
     * @type {string}
     * @readonly
     */
    name: Ember.computed.readOnly('data.title'),

    /**
     * Has items to display
     * @type {boolean}
     */
    hasChildrenToDisplay: Ember.computed.bool('data.hasChildrenToDisplay'),

    /**
     * Extra style for icons
     * @type {sting}
     * @readonly
     */
    extraIconStyles: Ember.computed('iconClass', function () {
      let styles = '';

      switch (this.iconClass) {
        case TITLE_TO_ICON_MAP['Social Box']:
          styles = 'width: 50px';
          break;

        case TITLE_TO_ICON_MAP['About Us']:
          styles = 'width: 45px';
          break;

        case TITLE_TO_ICON_MAP.Search:
          styles = 'width: 34px';
          break;

        case TITLE_TO_ICON_MAP['Landing Page']:
          styles = 'width: 44px';
          break;

        case TITLE_TO_ICON_MAP.Hero:
          styles = 'width: 49px';
          break;
      }

      return styles;
    }).readOnly()
  });

  _exports.default = _default;
});