define("builder/pods/website/index/controller", ["exports", "builder/controllers/base", "builder/core/enumerators/notification"], function (_exports, _base, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * Check for global pages exists
     * @param {WebsiteModel} website - website model
     * @returns {boolean}
     */
    hasGlobalPages(website) {
      return !!this.model.globalPages.findBy('WebsiteId', website.id);
    },

    /**
     * Show success notification
     *
     * @method _showSuccess
     * @param {String} message - message for show in notification
     * @private
     */
    _showSuccess(message) {
      this.showNotification(message, _notification.default.TYPE.SUCCESS);
    },

    /**
     * Show error notification
     *
     * @method _showError
     * @param {String} message - message for show in notification
     * @private
     */
    _showError(message) {
      this.showNotification(message, _notification.default.TYPE.ERROR);
    },

    actions: {
      /**
       * Actions delete website record
       *
       * @param {WebsiteModel} website - website record
       */
      deleteWebsite(website) {
        const domain = website.Domain;
        const hasGlobalPages = this.hasGlobalPages(website);

        if (hasGlobalPages) {
          window.alert('This website has global pages. Please remove them and try to delete again.');
        } else if (window.confirm(`Are you sure you want to delete ${domain}`)) {
          website.destroyRecord().then(() => {
            this._showSuccess(`Website ${domain} was removed.`);
          }, error => {
            this._showError(error.message);
          });
        }
      },

      /**
       * Actions add website to template
       *
       * @param {WebsiteModel} record - website record
       */
      addWebsiteToTemplate(record) {
        const domain = record.get('Domain');
        record.set('IsTemplate', '1');
        record.save().then(() => {
          this._showSuccess(`Website ${domain} was add to templates.`);
        }, error => {
          this._showError(error.message);
        });
      },

      /**
       * Actions remove website from template
       *
       * @param {WebsiteModel} record - website record
       */
      removeWebsiteFromTemplate(record) {
        const domain = record.get('Domain');
        record.set('IsTemplate', '0');
        record.save().then(() => {
          this._showSuccess(`Website ${domain} was removed from templates.`);
        }, error => {
          this._showError(error.message);
        });
      }

    }
  });

  _exports.default = _default;
});