define("builder/core/froala/links/index", ["exports", "builder/core/froala/links/link-add-button", "builder/core/froala/links/link-remove-button", "builder/core/froala/links/link-update-button", "builder/core/froala/links/link-color-button", "builder/core/froala/links/link-hover-color-button", "builder/core/froala/links/link-hover-decoration-dropdown", "builder/core/froala/links/link-color-plugin"], function (_exports, _linkAddButton, _linkRemoveButton, _linkUpdateButton, _linkColorButton, _linkHoverColorButton, _linkHoverDecorationDropdown, _linkColorPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_linkAddButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkAddButton[key];
      }
    });
  });
  Object.keys(_linkRemoveButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkRemoveButton[key];
      }
    });
  });
  Object.keys(_linkUpdateButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkUpdateButton[key];
      }
    });
  });
  Object.keys(_linkColorButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkColorButton[key];
      }
    });
  });
  Object.keys(_linkHoverColorButton).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkHoverColorButton[key];
      }
    });
  });
  Object.keys(_linkHoverDecorationDropdown).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkHoverDecorationDropdown[key];
      }
    });
  });
  Object.keys(_linkColorPlugin).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _linkColorPlugin[key];
      }
    });
  });
});