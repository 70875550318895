define("builder/core/module-config-handlers/form-configuration", ["exports", "builder/core/module-config-handlers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ModuleConfigFormConfigurationHandler
   */
  var _default = _base.default.extend({
    _dataKey: 'Structure',

    /**
     * @inheritdoc
     */
    _handle(structure, field, name, value) {
      Ember.set(structure, name, value);
      return structure;
    },

    _isNormalized() {
      return true;
    }

  });

  _exports.default = _default;
});