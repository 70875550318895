define("builder/pods/components/devices-screens/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component render list of device splitted by categories('small-monitor', 'mobile' and 'tablet').
   * Component provide logic to choose any device from list to simulate 'viewport' used it's dimensions and see how page will be look.
   * All list of devices encapsulated in component.
   *
   * API properties:
   * 	deviceType
   *
   *
   *
   * Example:
   *
   * {{devices-screens deviceType="desktop"}}
   */
  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['devices-screens'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isVisible:devices-screens_visible:devices-screens_hidden'],

    /**
     * @property {ConfigurationService} configurationService
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Field Code service
     * @type {FieldCodeService}
     */
    fieldCodeService: Ember.inject.service('field-code'),

    /**
     * @property {null|String} deviceType - contain name of device category at which component should render list of devices
     * @public
     */
    deviceType: null,

    /**
     * @property {Boolean} isVisible - show/hide list of devices
     * @readonly
     */
    isVisible: true,

    /**
     * property to check if Custom breakpoint popup open
     * @type {Boolean}
     */
    isCustomBreakpointPopupOpen: false,

    /**
     * current Device Name
     * @type {String}
     */
    currentDevice: Ember.computed('configurationService.device._device.name', function () {
      return this.configurationService.device._device.name;
    }),

    /**
     * @property {Array|Object[]|null} devices - property contain list of devices filtered by current selected category
     * @readonly
     */
    devices: null,
    _standardDevices: [{
      name: 'desktop',
      width: 1536,
      height: 860,
      icon: 'desktop',
      selected: false
    }, {
      name: 'small-monitor',
      width: 1199,
      height: 672,
      icon: 'small-monitor',
      selected: false
    }, {
      name: 'tablet',
      width: 768,
      height: 1024,
      icon: 'tablet',
      selected: false
    }, {
      name: 'mobile',
      width: 375,
      height: 812,
      icon: 'mobile',
      selected: false
    }],

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      if (this.devices) {
        return;
      }

      this.devices = [{
        name: 'fullscreen',
        width: this.configurationService.device.getFullscreenWidth(),
        height: 812,
        icon: 'fullscreen',
        selected: false
      }, ...this._standardDevices];
    },

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      // eslint-disable-next-line prefer-rest-params
      this._super(...arguments);

      const firstDevice = this.devices[0];
      this.send('select', firstDevice);
    },

    actions: {
      /**
       * Action which reset previous selected device and choose another by name
       *
       * @param {String} name - name of device which will be marked as selected
       */
      select(device) {
        const {
          devices
        } = this;
        const deviceService = this.get('configurationService.device');
        const selected = devices.findBy('selected', true);

        if (selected) {
          Ember.set(selected, 'selected', false); // remove the selected css class from prev selected device
        }

        const {
          name
        } = device;
        const currentDevice = devices.findBy('name', name);
        const config = {
          name: device.name,
          width: +device.width,
          height: +device.height,
          selected: true
        };
        deviceService.configure(config);
        Ember.set(currentDevice, 'selected', true);
        this.set('isCustomBreakpointPopupOpen', false); // add the css class on selected device

        this.fieldCodeService.codeEditCancel();
      }

    }
  });

  _exports.default = _default;
});