define("builder/core/copyable", ["exports", "builder/core/structure-copy", "ember-copy"], function (_exports, _structureCopy, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const copyObject = {
    copy() {
      return (0, _structureCopy._copy)(this);
    }

  };
  /**
   * @class Copyable
   * NOTE: _copy function returns wrapped Copyable object
   * TODO: Refactor. Remove dependency on this object. Use _copy function instead.
   */

  var _default = Ember.Object.extend(_emberCopy.Copyable, copyObject);

  _exports.default = _default;
});