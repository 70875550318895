define("builder/core/field-configurations/vehicle-filter-object", ["exports", "builder/core/enumerators/fields"], function (_exports, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VEHICLE_FILTER_OBJECT = void 0;
  // This file is generated of VehicleFilterObject.yml from Engine6
  const VEHICLE_TYPE_PROPERTIES = {
    type: 'filter-property',
    label: 'Type',
    name: 'isNew',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.DROPDOWN,
        name: 'any',
        label: 'hidden',
        data: '1',
        choices: [{
          label: 'New',
          value: '1'
        }, {
          label: 'Used',
          value: '0'
        }]
      }]
    }]
  };
  const VEHICLE_YEAR_PROPERTIES = {
    type: 'filter-property',
    label: 'Year',
    name: 'year',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Less than',
      logic: 'less',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Greater than',
      logic: 'greater',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Between',
      logic: 'between',
      children: [{
        type: _fields.default.RANGED_INPUT,
        name: 'any',
        label: 'hidden'
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_MAKE_PROPERTIES = {
    type: 'filter-property',
    label: 'Make',
    name: 'make',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_MODEL_PROPERTIES = {
    type: 'filter-property',
    label: 'Model',
    name: 'model',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_TRIM_PROPERTIES = {
    type: 'filter-property',
    label: 'Trim',
    name: 'trim',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_BODY_STYLE_PROPERTIES = {
    type: 'filter-property',
    label: 'Body Style',
    name: 'bodyStyle',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_DRIVETRAIN_PROPERTIES = {
    type: 'filter-property',
    label: 'Drivetrain',
    name: 'drivetrain',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_STATUS_PROPERTIES = {
    type: 'filter-property',
    label: 'Status',
    name: 'isInTransit',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.DROPDOWN,
        name: 'any',
        label: 'hidden',
        data: '0',
        choices: [{
          label: 'In-Stock',
          value: '0'
        }, {
          label: 'In-Transit',
          value: '1'
        }]
      }]
    }]
  };
  const VEHICLE_DAYS_IN_STOCK_PROPERTIES = {
    type: 'filter-property',
    label: 'Days In Stock',
    name: 'daysInStock',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Less than',
      logic: 'less',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Greater than',
      logic: 'greater',
      children: [{
        type: _fields.default.NUMBER,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Between',
      logic: 'between',
      children: [{
        type: _fields.default.RANGED_INPUT,
        name: 'any',
        label: 'hidden'
      }]
    }]
  };
  const VEHICLE_SPECIAL_PROPERTIES = {
    type: 'filter-property',
    label: 'Special',
    name: 'isSpecial',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.DROPDOWN,
        name: 'any',
        label: 'hidden',
        data: '1',
        choices: [{
          label: 'Yes',
          value: '1'
        }, {
          label: 'No',
          value: '0'
        }]
      }]
    }]
  };
  const VEHICLE_OEM_CERTIFIED_PROPERTIES = {
    type: 'filter-property',
    label: 'Certified',
    name: 'isOEMCertified',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.DROPDOWN,
        name: 'any',
        label: 'hidden',
        data: '1',
        choices: [{
          label: 'Yes',
          value: '1'
        }, {
          label: 'No',
          value: '0'
        }]
      }]
    }]
  };
  const VEHICLE_DEALER_PROPERTIES = {
    type: 'filter-property',
    label: 'Dealer',
    name: 'dealerId',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.DROPDOWN,
        name: 'any',
        label: 'hidden',
        placeholder: 'Please select',
        choices: 'dataSource/Dealers'
      }]
    }]
  };
  const VEHICLE_VIN_PROPERTIES = {
    type: 'filter-property',
    label: 'VIN',
    name: 'vin',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_COMMENT1_PROPERTIES = {
    type: 'filter-property',
    label: 'Comment1',
    name: 'comment1',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_COMMENT2_PROPERTIES = {
    type: 'filter-property',
    label: 'Comment2',
    name: 'comment2',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_COMMENT3_PROPERTIES = {
    type: 'filter-property',
    label: 'Comment3',
    name: 'comment3',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_COMMENT4_PROPERTIES = {
    type: 'filter-property',
    label: 'Comment4',
    name: 'comment4',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_COMMENT5_PROPERTIES = {
    type: 'filter-property',
    label: 'Comment5',
    name: 'comment5',
    children: [{
      type: 'filter-operator',
      label: 'Equal to',
      logic: 'equal',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not equal to',
      logic: 'notEqual',
      children: [{
        type: _fields.default.TEXT,
        name: 'any',
        label: 'hidden',
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'In list',
      logic: 'inList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }, {
      type: 'filter-operator',
      label: 'Not in list',
      logic: 'notInList',
      children: [{
        type: _fields.default.TAGS,
        name: 'any',
        label: 'hidden',
        choices: [],
        allowOther: true,
        validation: {
          required: true
        }
      }]
    }]
  };
  const VEHICLE_FILTER_OBJECT = {
    type: 'filter-object',
    label: 'Vehicle',
    name: 'vehicle',
    children: [VEHICLE_TYPE_PROPERTIES, VEHICLE_YEAR_PROPERTIES, VEHICLE_MAKE_PROPERTIES, VEHICLE_MODEL_PROPERTIES, VEHICLE_TRIM_PROPERTIES, VEHICLE_BODY_STYLE_PROPERTIES, VEHICLE_DRIVETRAIN_PROPERTIES, VEHICLE_STATUS_PROPERTIES, VEHICLE_DAYS_IN_STOCK_PROPERTIES, VEHICLE_SPECIAL_PROPERTIES, VEHICLE_OEM_CERTIFIED_PROPERTIES, VEHICLE_DEALER_PROPERTIES, VEHICLE_VIN_PROPERTIES, VEHICLE_COMMENT1_PROPERTIES, VEHICLE_COMMENT2_PROPERTIES, VEHICLE_COMMENT3_PROPERTIES, VEHICLE_COMMENT4_PROPERTIES, VEHICLE_COMMENT5_PROPERTIES]
  };
  _exports.VEHICLE_FILTER_OBJECT = VEHICLE_FILTER_OBJECT;
});