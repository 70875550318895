define("builder/abilities/tier/link", ["exports", "builder/abilities/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class AccessControlTierLinkAbility
   */
  var _default = _ability.default.extend();

  _exports.default = _default;
});