define("builder/core/grid-models/module-device-visibility-state", ["exports", "builder/core/enumerators/breakpoint-modes", "builder/core/copyable"], function (_exports, _breakpointModes, _copyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ModuleDeviceVisibilityState extends _copyable.default {
    constructor(...args) {
      super(...args);
      this[_breakpointModes.LARGE] = true;
      this[_breakpointModes.MEDIUM] = true;
      this[_breakpointModes.SMALL] = true;
      this[_breakpointModes.EXTRA_SMALL] = true;

      this.isVisible = mode => {
        var _this$mode;

        return (_this$mode = this[mode]) !== null && _this$mode !== void 0 ? _this$mode : false;
      };

      this.update = visibilityState => {
        this._setState(visibilityState);
      };

      this.buildConfig = () => [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].filter(mode => this[mode] === true);

      this.isEqual = visibilityState => [_breakpointModes.EXTRA_SMALL, _breakpointModes.SMALL, _breakpointModes.MEDIUM, _breakpointModes.LARGE].every(mode => visibilityState[mode] === this[mode]);

      this.diff = visibilityState => [_breakpointModes.EXTRA_SMALL, _breakpointModes.SMALL, _breakpointModes.MEDIUM, _breakpointModes.LARGE].filter(mode => visibilityState[mode] !== this[mode]);
    }

    /**
     * @param {Object} rawValue
     * @returns {void}
     */
    init(rawValue) {
      this._setState(rawValue);
    }

    /**
     * Compiles inner properties state and stores it into current state
     * @param Array | Object rawValue
     * @private
     */
    _setState(rawValue) {
      const state = {};

      if (!rawValue) {
        return;
      }

      if (Array.isArray(rawValue)) {
        [_breakpointModes.EXTRA_SMALL, _breakpointModes.SMALL, _breakpointModes.MEDIUM, _breakpointModes.LARGE].forEach(mode => {
          state[mode] = rawValue.includes(mode);
        });
      } else {
        [_breakpointModes.EXTRA_SMALL, _breakpointModes.SMALL, _breakpointModes.MEDIUM, _breakpointModes.LARGE].forEach(mode => {
          var _rawValue$mode;

          return state[mode] = (_rawValue$mode = rawValue[mode]) !== null && _rawValue$mode !== void 0 ? _rawValue$mode : false;
        });
      }

      this.setProperties(state);
    }

  }

  _exports.default = ModuleDeviceVisibilityState;
});