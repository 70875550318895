define("builder/initializers/domain", ["exports", "builder/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // Need to set domain to get
    // access to iframe with our modules
    try {
      document.domain = _environment.default.domain;
    } catch (error) {
      if (Ember.testing) {
        window.console.error(new Ember.Error(error));
      } else {
        throw new Ember.Error(error);
      }
    }
  }

  var _default = {
    name: 'domain',
    after: 'set-cookies',
    initialize
  };
  _exports.default = _default;
});