define("builder/core/factories/module-gallery/form-decorator", ["exports", "builder/core/factories/module-gallery/gallery-module", "builder/core/literals", "builder/core/enumerators/module"], function (_exports, _galleryModule, _literals, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormGalleryModuleDecorator
   */
  var _default = _galleryModule.default.extend({
    /**
     * @inheritdoc
     */
    componentName: 'gallery-form-template',

    /**
     * @inheritdoc
     */
    visible: Ember.computed.not('data.isDeleted'),

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: null,

    /**
     * @inheritdoc
     */
    title: Ember.computed.readOnly('data.Title'),

    /**
     * @inheritdoc
     */
    image: Ember.computed.readOnly('data.Image'),

    /**
     * @inheritdoc
     */
    type: _module.default.MODULE_FORM,

    /**
     * @inheritdoc
     */
    label: _literals.default.FORM_MODULE_TITLE,

    /**
     * Current page model
     * @type {PageModel|null}
     */
    currentPageModel: null,

    /**
     * @inheritdoc
     */
    canDelete: Ember.computed.not('data.UsedInPages.length'),

    /**
     * List of pages contains form
     * @type {{title: string, pageModel: PageModel}[]}
     * @readonly
     */
    usedInPages: Ember.computed('data.UsedInPages.length', function () {
      const formModel = this.data;
      const usedInPages = formModel.UsedInPages;
      return usedInPages.reduce((pages, usedInPage) => {
        const pageModel = formModel.store.peekRecord('page', usedInPage.PageId);

        if (usedInPage.Type === 'layout' || usedInPage.Type === 'page' && pageModel && !pageModel.isDeleted) {
          pages.push(this._getFormPageInfo(usedInPage.Type, pageModel));
        }

        return pages;
      }, []).sortBy('title');
    }).readOnly(),

    /**
     * Result of check usage ability
     * @type {{isGranted:boolean, message:string}}
     * @readonly
     */
    usageAbility: Ember.computed('accessControl', 'currentPageModel.isGlobal', function () {
      const aclAbility = this.accessControl.can('create form');
      const globalPageUsageAbility = this.currentPageModel.isGlobal ? {
        isGranted: false,
        message: _literals.default.GLOBAL_PAGE_USAGE_RESTRICTION
      } : {
        isGranted: true
      };

      if (!aclAbility.isGranted) {
        return aclAbility;
      }

      return globalPageUsageAbility;
    }),

    /**
     * Is enabled
     * @type {boolean}
     * @readonly
     */
    enabled: Ember.computed.readOnly('usageAbility.isGranted'),

    /**
     * Error message that indicates the reason is not enabled
     * @type {string}
     * @readonly
     */
    errorMessage: Ember.computed('usageAbility.isGranted', function () {
      return this.usageAbility.isGranted ? '' : this.usageAbility.message;
    }).readOnly(),

    /**
     * @inheritdoc
     */
    satisfiesSearch(searchRegex) {
      return searchRegex.test(this.data.Title);
    },

    /**
     * @inheritdoc
     */
    getInsertData() {
      return {
        name: _literals.default.FORM_MODULE_TITLE,
        theme: this.data.Theme
      };
    },

    /**
     * Get form page info (title and page model, if form exists on page)
     * @param {string} type - type of form existence(page or layout)
     * @param {PageModel|null} page - page model or null(if form is in layout)
     * @returns {Object} - object with keys "title" and "pageModel"
     * @private
     */
    _getFormPageInfo(type, page) {
      const info = {
        title: 'Header or Footer',
        pageModel: null
      };

      if (type === 'page') {
        info.title = page.Title;
        info.pageModel = page;
      }

      return info;
    }

  });

  _exports.default = _default;
});