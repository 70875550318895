define("builder/pods/website/edit/page/edit/form-variants", ["exports", "builder/pods/website/edit/page/edit/field-hidden-normalizer", "builder/core/enumerators/fields"], function (_exports, _fieldHiddenNormalizer, _fields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form variants structure for hidden fields
   */
  var _default = {
    type: _fields.default.FORM_VARIANTS,
    name: 'hiddenFields',
    label: 'HIDDEN FIELDS',
    description: 'Hidden fields will not be shown on forms but will be included in form lead',
    sortable: false,
    children: [{
      type: _fields.default.FORM_VARIANT,
      label: 'Hidden field',
      labelFrom: 'name',
      name: _fieldHiddenNormalizer.variantName,
      isUnique: false,
      children: [{
        type: _fields.default.TEXT,
        label: 'Name',
        name: 'name',
        validation: {
          required: true,
          pattern: '^[a-z][-a-zA-Z0-9]*$',
          length: {
            min: 1,
            max: 40
          },
          delegate: {
            validate() {}

          }
        }
      }, {
        type: _fields.default.TEXT,
        label: 'Value',
        name: 'value'
      }]
    }],
    validation: {
      elements: {
        min: 0,
        max: 20
      }
    }
  };
  _exports.default = _default;
});