define("builder/pods/components/menumanager-createlink/component", ["exports", "builder/pods/components/menumanager-link/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    settingsTitle: 'Create New Link',

    /**
     * @inheritdoc
     */
    data: Ember.Object.create({
      itemId: +new Date(),
      title: '',
      link: Ember.Object.create({
        target: 'self',
        type: 'internal',
        value: 'index'
      })
    }),

    /**
     * Resets data
     *
     * @method
     */
    clearData() {
      const link = this.data.get('link');
      this.data.set('title', '');
      link.setProperties({
        target: 'self',
        type: 'internal',
        value: 'index'
      });
    },

    actions: {
      /**
       * Action to send config data.
       */
      openConfig() {
        this.clearData();
        this.openMMConfig(this.getConfig(), false);
      }

    }
  });

  _exports.default = _default;
});