define("builder/pods/components/field-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U4Nu26SV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\\n\"],[7,\"input\",true],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-icon/template.hbs"
    }
  });

  _exports.default = _default;
});