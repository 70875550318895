define("builder/pods/website/edit/page/edit/container-template/route", ["exports", "builder/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * @inheritdoc
     */
    model(params) {
      if (!params.hasOwnProperty('container') && !params.slug) {
        this.transitionTo('website.edit.page.edit');
      }

      return params;
    },

    /**
     * @inheritdoc
     */
    afterModel(model) {
      const {
        slug,
        container
      } = model; // Create new Container Template

      if (slug === 'create' && container) {
        model.template = this.store.createRecord('container-template', {
          Title: this.generateUniqTemplateTitle()
        });
        model.visibilities = this.store.getContainerTemplateVisibilities();
        return;
      } // Edit existing Container Template


      if (/^\d+$/.test(slug)) {
        const containerTemplateModel = this.store.peekRecord('container-template', slug);

        if (!containerTemplateModel) {
          this.transitionTo('website.edit.page.edit');
          return;
        }

        model.template = containerTemplateModel;
        model.visibilities = this.store.getContainerTemplateVisibilities();
        return;
      }

      this.transitionTo('website.edit.page.edit');
    },

    /**
     * @inheritdoc
     */
    activate() {
      if (!this.accessControl.can('visit route', this).isGranted) {
        this.handleAccessDenied();
      }
    },

    /**
     * @inheritdoc
     */
    deactivate() {
      const model = this.get('controller.model.template');

      if (model && model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
    },

    /**
     * Generate uniq template title
     * @returns {String}
     */
    generateUniqTemplateTitle() {
      const uid = Math.random().toString().substr(2, 4);
      return `New Snapshot #${uid}`;
    }

  });

  _exports.default = _default;
});