define("builder/pods/components/code-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W0zvJiPj",
    "block": "{\"symbols\":[],\"statements\":[[5,\"widget-popup\",[[12,\"class\",\"widget-popup_theme__code\"]],[[\"@closeHandler\",\"@isFixedCentered\"],[[23,0,[\"onCancelClick\"]],true]],{\"statements\":[[0,\"\\n\\n\\t\"],[7,\"h2\",true],[10,\"class\",\"page_module-config_title\"],[8],[0,\"Edit code snippet\"],[9],[0,\"\\n\\t\\t\\n\\t\"],[7,\"code\",true],[8],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page_module-config__buttons\"],[8],[0,\"\\n\\t\\t\"],[5,\"default-button\",[],[[\"@label\",\"@handler\"],[\"Save\",[23,0,[\"onSaveClick\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/code-popup/template.hbs"
    }
  });

  _exports.default = _default;
});