define("builder/core/froala/metatags/meta-config-mapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapMetatagsConfigToFroalaOptions = mapMetatagsConfigToFroalaOptions;

  /**
   *
   * @param {MetaVariablesColumn} metaVariables
   */
  function mapMetatagsConfigToFroalaOptions(metaVariables) {
    return metaVariables.variables.reduce((previous, current) => {
      const propertyName = `{{${current.path}}}`;
      return { ...previous,
        [propertyName]: current.name
      };
    }, {});
  }
});