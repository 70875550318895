define("builder/pods/components/selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "geQSmADg",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-wrapper\"],[8],[0,\"\\n\\t\"],[5,\"input-search\",[[12,\"class\",\"default-theme\"],[12,\"focused\",true]],[[\"@onKeyPress\",\"@onClear\"],[[28,\"action\",[[23,0,[]],\"search\"],null],[28,\"action\",[[23,0,[]],\"showFullList\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"list-wrapper\"],[8],[0,\"\\n\\t\"],[7,\"ul\",true],[10,\"class\",\"list-wrapper__list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"choices\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",false],[12,\"class\",[29,[\"list-wrapper__list-option \",[28,\"if\",[[23,1,[\"active\"]],\"list-wrapper__list-option_active\"],null]]]],[12,\"title\",[23,1,[\"label\"]]],[3,\"action\",[[23,0,[]],\"selectOne\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"label\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"additional-panel \",[28,\"unless\",[[24,[\"choices\",\"length\"]],\"additional-panel_hidden\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"additional-panel__add-all-button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"selectAll\",[24,[\"choices\"]]]],[8],[0,\"Add All\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"additional-panel__choices-length\"],[8],[0,\"(\"],[1,[24,[\"choices\",\"length\"]],false],[0,\")\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/selector/template.hbs"
    }
  });

  _exports.default = _default;
});