define("builder/pods/components/widget-buttonsgroup/component", ["exports", "builder/core/widget", "builder/core/enumerators/module-action", "builder/core/enumerators/breakpoint-modes"], function (_exports, _widget, _moduleAction, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Widget buttonsgroup
   *
   * @class WidgetButtonsGroup
   */
  const WidgetButtonsGroup = _widget.default.extend();

  WidgetButtonsGroup.reopenClass({
    LAYOUT_VERTICAL: 'vertical',
    LAYOUT_HORIZONTAL: 'horizontal',
    LAYOUT_FULL_WIDTH: 'justified'
  });

  var _default = WidgetButtonsGroup.reopen({
    /**
     * @property {String[]} classNames - Component class names
     */
    classNames: ['page_module'],

    /**
     * @property {ConfigurationService} configurationService - service structure
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {String} generalClass - general class for lg, md, sm modes
     */
    generalClass: 'btn-group',

    /**
     * @property {String} xsClass - special class for xs mode or widget layout is vertical, taking into account
     * that if buttons are not grouped, this class wont be added, but "br" tags will be used instead
     * to align buttons vertically, just as done on E6 side
     */
    xsClass: 'btn-group-vertical',

    /**
     * @property {String} fullWidthHorizontalClass - class to be used for buttons, when buttons have layout full width
     * @readonly
     */
    fullWidthHorizontalClass: Ember.computed('configurationService.currentDevice.breakpoint', 'data.originObject.layout', function () {
      const layout = this.get('data.originObject.layout');
      const breakpoint = this.get('configurationService.currentDevice.breakpoint');
      let clazz = '';

      if (breakpoint !== _breakpointModes.EXTRA_SMALL && layout === WidgetButtonsGroup.LAYOUT_FULL_WIDTH) {
        clazz = 'btn-group-justified';
      }

      return clazz;
    }).readOnly(),

    /**
     * @property {Object} sizeClassMap - size class lookup table, duplicate of widget-button, since probably,
     * that will change, in the future, and buttonsgroup will have it's own class
     * @readonly
     */
    sizeClassMap: Ember.computed(function () {
      return {
        default: '',
        xl: '__xl',
        lg: '__lg',
        sm: '__sm'
      };
    }).readOnly(),

    /**
     * @property {String} groupClass - class to be used for buttons wrapper, when buttons are grouped
     * @readonly
     */
    groupClass: Ember.computed('configurationService.currentDevice.breakpoint', 'data.originObject.{group,layout}', function () {
      const data = this.get('data.originObject');
      let clazz = '';

      if (data.get('group')) {
        clazz = this.get(this.get('configurationService.currentDevice.breakpoint') === _breakpointModes.EXTRA_SMALL || data.get('layout') === WidgetButtonsGroup.LAYOUT_VERTICAL ? 'xsClass' : 'generalClass');
      }

      return clazz;
    }).readOnly(),

    /**
     * @property {Boolean} useBr - use br tag to align button vertically or not,
     * when layout is vertical and buttons are not grouped, vertical positioning will be made
     * by using "br" tags
     * @readonly
     */
    useBr: Ember.computed('data.originObject.{group,layout}', function () {
      const {
        group,
        layout
      } = this.get('data.originObject');
      return !group && layout === WidgetButtonsGroup.LAYOUT_VERTICAL;
    }).readOnly(),

    /**
     * @property {String} alignClass - Css class for buttons container align
     * @readonly
     */
    alignClass: Ember.computed('data.originObject.aligned', function () {
      switch (this.get('data.originObject.aligned')) {
        case 'right':
          return 'text-right';

        case 'center':
          return 'text-center';

        default:
          return 'text-left';
      }
    }).readOnly(),

    /**
     * @property {String} sizeClass - class that corresponds to size set, will be passed to each button
     * @readonly
     */
    sizeClass: Ember.computed('data.originObject.size', function () {
      const size = this.get('data.originObject.size') || 'default';
      return this.get(`sizeClassMap.${size}`);
    }).readOnly(),
    contentLayout: Ember.computed('data.originObject.buttonTextLayout', function () {
      var _this$data, _this$data$originObje;

      // button has horizontal layout by default
      const textLayout = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje = _this$data.originObject) === null || _this$data$originObje === void 0 ? void 0 : _this$data$originObje.buttonTextLayout;

      if (!textLayout) {
        return 'horizontal';
      }

      return textLayout;
    }),

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    },

    /**
     * Double click handler
     *
     * @method
     * @returns {*}
     */
    doubleClick(...args) {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return this._super(...args);
    },

    /**
     * Activate module
     *
     * @method
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);

      this._super(...args);
    }

  });

  _exports.default = _default;
});