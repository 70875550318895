define("builder/pods/components/field-container/component", ["exports", "builder/core/enumerators/fields", "builder/mixins/field-visibility"], function (_exports, _fields, _fieldVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldVisibility.default, {
    /**
     * @inheritdoc
     */
    classNames: ['bld-field-container'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isNested:bld-field-container__nested'],

    /**
     * @property {Boolean} isNested - whether field-container is nested
     */
    isNested: Ember.computed('parent.type', function () {
      return this.get('parent.type') === _fields.default.CONTAINER;
    }),
    actions: {
      actionHandler(value, name) {
        const parentHandler = this.actionHandler;

        if (typeof parentHandler === 'function') {
          parentHandler({
            [name]: value
          }, this.get('field.name'));
        }
      }

    }
  });

  _exports.default = _default;
});