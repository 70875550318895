define("builder/services/website-management-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service for operations with menu within website management context
   */
  class WebsiteManagementMenuService extends Ember.Service {
    /**
     * Saves current website menu
     * @param {MenuSaveCommand} command
     * @returns {Promise<void>}
     */
    async save({
      menu
    }) {
      // Menu MUST be saved or reloaded after page save because of multi user support
      await (menu.hasDirtyAttributes ? menu.save() : menu.reload());
    }

  }

  _exports.default = WebsiteManagementMenuService;
});