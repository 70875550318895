define("builder/pods/components/gallery-item/component", ["exports", "jquery", "builder/core/which-transition-event"], function (_exports, _jquery, _whichTransitionEvent2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base gallery item class
   */
  var _default = Ember.Component.extend({
    /**
     * class names to be added to ember wrapper
     *
     * @type {string[]}
     */
    classNames: ['page_modules_list_item'],

    /**
     * global popups service
     * @type {PopupsService}
     */
    popupsService: Ember.inject.service('popups'),

    /**
     * @inheritdoc
     */
    classNameBindings: ['data.enabled::module-gallery__item_disabled'],

    /**
     * drag service
     * @type {Ember.Service}
     */
    dragService: Ember.inject.service('drag'),

    /**
     * is component draggable or not
     *
     * @type {boolean}
     */
    draggable: true,

    /**
     * reference to original item, see module-gallery, how items are created
     * @type {Object}
     */
    ref: null,

    /**
     * gallery item data/record
     * @type {DS.Model}
     */
    data: null,

    /**
     * On category item click handler
     *
     * @returns {void}
     */
    onClick() {
      throw new Ember.Error('Method onClick must be overridden');
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const me = this; // Make gallery item draggable

      if (this.draggable) {
        // Proxy drag service calls
        this.dragService.applyDraggable(this.$(), {
          onDragStart(x, y) {
            me.onDragStart(me.get('ref'), x, y);
          },

          onMouseMove(...args) {
            me.onMouseMove(...args);
          },

          getDragElement() {
            return me._getDragElement();
          },

          onMouseUp(...args) {
            me.onMouseUp(...args);
          },

          onDragEnd() {// Do nothing, required by drag service
          }

        });
      }
    },

    /**
     * On drag start action handler
     *
     * @param {number} x - event.pageX
     * @param {number} y - event.pageY
     * @returns {void}
     */
    onDragStart() {
      throw new Ember.Error('Mouse move must be overridden');
    },

    /**
     * Mouse move handler
     *
     * @param {number} x - event.pageX
     * @param {number} y - event.pageY
     * @returns {void}
     */
    onMouseMove() {
      throw new Ember.Error('Mouse move must be overridden');
    },

    /**
     * On mouse up handler, not the last action, see onDragEnd
     *
     * @param {Object} component - original object passed to drag service
     * @param {JQuery} node - jQuery node object
     * @returns {void}
     */
    onMouseUp() {
      throw new Ember.Error('Mouse move must be overridden');
    },

    /**
     * Get actual drag element
     *
     * @returns {JQuery} - element to drag
     * @private
     */
    _getDragElement() {
      const element = this.$().clone();

      this._prepareDragElement(element); // Position right at current component's position


      element.css(this._getDragElementCss());
      (0, _jquery.default)('body').append(element);
      element.on((0, _whichTransitionEvent2._whichTransitionEvent)(), function () {
        this.remove();
      });
      return element;
    },

    /**
     * On component click, trigger passed in click handler
     *
     * @returns {void}
     */
    click() {
      const {
        onClick
      } = this;

      if (typeof onClick === 'function') {
        onClick(this.ref);
      }
    },

    /**
     * Get initial styles for drag element, basically all we need is to position drag element at the same position
     * as current component
     *
     * @returns {{position: string, top: (number), left: (number), z-index: number}}
     * @private
     */
    _getDragElementCss() {
      const offset = this.$().offset();
      return {
        position: 'absolute',
        top: offset.top,
        left: offset.left,
        'z-index': 1000,
        opacity: 0.3
      };
    },

    /**
     * Prepare drag element, original drag element is same as component itself
     *
     * @param {JQuery} dragElement - jQuery node object
     * @private
     * @returns {void}
     */
    _prepareDragElement(dragElement) {
      // Remove title
      dragElement.find('.page_modules_list_item-title').remove();
    },

    actions: {
      /**
       * Shows popup with image preview
       *
       * @param {string} imageSrc - image url to show in popup
       * @param {MouseEvent} event - mouse click event
       */
      showImagePopup(imageSrc, event) {
        event.stopPropagation();
        const contentElement = document.createElement('img');
        contentElement.src = imageSrc;
        this.popupsService.showPopup(contentElement);
      }

    }
  });

  _exports.default = _default;
});