define("builder/pods/components/fonts-uploaded-list-item/component", ["exports", "jquery", "builder/core/enumerators/keyboard"], function (_exports, _jquery, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['b2-fonts-uploaded-list-item'],

    /**
     * @inheritDoc
     */
    classNameBindings: ['_titleIsEditing:b2-fonts-uploaded-list-item--active', 'error:b2-fonts-uploaded-list-item--error', 'removeInProgress:b2-fonts-uploaded-list-item--remove-in-progress'],

    /**
     * @property {String} label - font label
     */
    label: Ember.computed('field.label', function () {
      return this.get('field.label');
    }),

    /**
     * @property {Boolean} isModelPresented - font model is present
     * @readonly
     */
    isModelPresented: Ember.computed.bool('field.model').readOnly(),

    /**
     * @property {String} error - error message
     * @readonly
     */
    error: Ember.computed.readOnly('field.error'),

    /**
     * @property {Boolean} removeInProgress - indicate that item prepare to remove
     * @readonly
     */
    removeInProgress: Ember.computed.bool('field.removeInProgress').readOnly(),

    /**
     * @property {String} fontFamily - font family
     * @readonly
     */
    fontFamily: Ember.computed.readOnly('field.model.Name'),

    /**
     * @property {String} fontVisibility - font visibility
     * @readonly
     */
    fontVisibility: Ember.computed.readOnly('field.model.VisibilityType'),

    /**
     * @property {String} fontVisibilityLabel - font visibility label
     * @readonly
     */
    fontVisibilityLabel: Ember.computed('fontVisibility', function () {
      switch (this.fontVisibility) {
        case '0':
          return 'Visible globally for all websites';

        case '1':
          return 'Visible for every website in this dealer company';

        case '2':
          return 'Visible only for this website';

        default:
          return '';
      }
    }).readOnly(),

    /**
     * @property {Object|Null} field - data for component
     * @property {DS.Model} field.model - font model
     * @property {File|String} field.source - font source
     * @property {String} field.error - error message
     */
    field: null,

    /**
     * @property {Function|Null} onRemove - on remove font handler
     */
    onRemove: null,

    /**
     * @property {Function|Null} onChangeVisibility - on change visibility font handler
     */
    onChangeVisibility: null,

    /**
     * @property {Function|Null} onChangeTitle - on change title font handler
     */
    onChangeTitle: null,

    /**
     * @property {Boolean} titleIsEditing - indicate that font title is editing or not
     */
    titleIsEditing: false,

    /**
     * @inheritDoc
     */
    didRender(...args) {
      const select = this.$('select').get(0);

      if (select) {
        select.value = this.fontVisibility;
      }

      this._super(...args);
    },

    /**
     * @inheritDoc
     */
    willDestroyElement(...args) {
      (0, _jquery.default)(document).off('click.font-name-title');

      this._super(...args);
    },

    actions: {
      /**
       * Action for remove font
       */
      remove() {
        if (typeof this.onRemove === 'function') {
          this.onRemove(this.field);
        }
      },

      /**
       * Action for change title
       * @param {Event} event - event object
       */
      changeTitle(event) {
        if (typeof this.onChangeTitle === 'function') {
          this.onChangeTitle(this.field, event.target.value);
        }
      },

      /**
       * Action for change visibility
       * @param {Event} event - event object
       */
      changeVisibility(event) {
        if (typeof this.onChangeVisibility === 'function') {
          this.onChangeVisibility(this.field, event.target.value);
        }
      },

      /**
       * Action that inits edit font title
       * @param {Event} event - event object
       */
      editTitle() {
        this.set('titleIsEditing', true);
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.$('input[name=font-title]').focus();
        });
        (0, _jquery.default)(document).off('click.font-name-title').on('click.font-name-title', event => {
          if (!(0, _jquery.default)(event.target).closest(this.$('input[name=font-title]')).length) {
            this.set('titleIsEditing', false);
          }
        });
      },

      /**
       * Action for complete edit title
       * @param {Object} component - target component
       * @param {Event} event - event object
       */
      editTitleComplete(component, event) {
        if (event.keyCode === _keyboard.default.KEY.ENTER || event.keyCode === _keyboard.default.KEY.ESCAPE) {
          event.stopPropagation();
          this.set('titleIsEditing', false);
        }
      },

      /**
       * Action for try to reload font file or link
       */
      reload() {
        if (typeof this.onReload === 'function') {
          this.onReload(this.field);
        }
      }

    }
  });

  _exports.default = _default;
});