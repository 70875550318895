define("builder/core/froala/metatags/dropdown-dealership", ["exports", "froala-editor", "builder/core/meta-tags/meta-variables-configuration", "builder/core/froala/metatags/meta-config-mapper", "builder/core/froala/metatags/metatags-insert"], function (_exports, _froalaEditor, _metaVariablesConfiguration, _metaConfigMapper, _metatagsInsert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dealershipDropdownCommand = void 0;

  /* eslint-disable new-cap */
  const dealershipDropdownCommand = 'metatags-dealership-dropdown';
  _exports.dealershipDropdownCommand = dealershipDropdownCommand;
  const dealershipIconName = 'dealership-icon';

  _froalaEditor.default.DefineIconTemplate(dealershipIconName, '<i class="fa fa-building"></i>');

  _froalaEditor.default.DefineIcon(dealershipDropdownCommand, {
    NAME: dealershipIconName,
    template: dealershipIconName
  });

  _froalaEditor.default.RegisterCommand(dealershipDropdownCommand, {
    title: 'Dealership meta',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: (0, _metaConfigMapper.mapMetatagsConfigToFroalaOptions)(_metaVariablesConfiguration.dealership),

    callback(cmd, value) {
      (0, _metatagsInsert.insertMetatag)(this, value);
    },

    // Callback on refresh.
    refresh($btn) {},

    // Callback on dropdown show.
    refreshOnShow($btn, $dropdown) {}

  });
});