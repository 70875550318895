define("builder/models/container-template", ["exports", "@ember-data/model", "builder/models/model", "builder/core/enumerators/template-visibility"], function (_exports, _model, _model2, _templateVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ContainerTemplateModel
   */
  const ContainerTemplateModel = _model2.default.extend({
    /**
     * @property {String} Author - id of user who created the template
     */
    Author: (0, _model.attr)('string'),

    /**
     * @property {String} Visibility - template visibility
     */
    Visibility: (0, _model.attr)('string', {
      defaultValue: _templateVisibility.default.VISIBILITY_PRIVATE
    }),

    /**
     * @property {String} Category - category template belongs to
     */
    Category: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * @property {String} Title - template title
     */
    Title: (0, _model.attr)('string'),

    /**
     * @property {String} Preview - url to template thumbnail
     */
    Preview: (0, _model.attr)('string'),

    /**
     * @property {String} Image - system field, used for switching tmp thumbnail to permanent, or delete key word if thumbnail was deleted
     */
    Image: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * @property {String} OriginalImage - url to original image, used for zoom
     */
    OriginalImage: (0, _model.attr)('string', {
      defaultValue: null
    }),

    /**
     * @param {Object} Structure - container structure
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * Global page capability ('1' - compatible, '0' - incompatible)
     * @type {'1' | '0'}
     */
    IsGlobalPageCompatible: (0, _model.attr)('string', {
      defaultValue: '1'
    }),

    /**
     * Global page capability
     * @type {boolean}
     * @readonly
     */
    isGlobalPageCompatible: Ember.computed.equal('IsGlobalPageCompatible', '1').readOnly(),

    /**
     * Update page template visibility, it could affect category
     *
     * @param {String} visibility - visibility
     */
    updateVisibility(visibility) {
      this.set('Visibility', visibility);
      this.invalidateCategory();
    },

    /**
     * Invalidate category
     */
    invalidateCategory() {
      if (this.Visibility !== _templateVisibility.default.VISIBILITY_GLOBAL && this.Category) {
        this.set('Category', null);
      }
    },

    /**
     * Method update thumbnail, since thumbnail update requires property "Image" to be set
     *
     * @param {String|null} imageId - id of uploaded image
     * @param {String|null} thumbnailUrl - path to thumbnail preview
     */
    updateThumbnail(imageId = null, thumbnailUrl = null) {
      this.set('Image', imageId);
      this.set('Preview', thumbnailUrl);
    },

    /**
     * Method to clear thumbnail, since thumbnail removal has specific logic, property "Image" must be set to "delete"
     */
    removeThumbnail() {
      this.set('Preview', '');
      this.set('Image', 'delete');
    }

  });

  var _default = ContainerTemplateModel;
  _exports.default = _default;
});