define("builder/core/expression/operators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OPERATOR_BETWEEN = _exports.OPERATOR_GREATER = _exports.OPERATOR_LESS = _exports.OPERATOR_CONTAINS = _exports.OPERATOR_NOT_EQUAL = _exports.OPERATOR_EQUAL = _exports.OPERATOR_NOT_IN_LIST = _exports.OPERATOR_IN_LIST = _exports.OPERATOR_OR = _exports.OPERATOR_AND = void 0;

  /**
   * @const {String} OPERATOR_AND - Logical operator to check all operands
   */
  const OPERATOR_AND = 'and';
  /**
   * @const {String} OPERATOR_OR - Logical operator to check one of operands
   */

  _exports.OPERATOR_AND = OPERATOR_AND;
  const OPERATOR_OR = 'or';
  /**
   * @const {String} OPERATOR_IN_LIST - Array operator to search in array
   */

  _exports.OPERATOR_OR = OPERATOR_OR;
  const OPERATOR_IN_LIST = 'inList';
  /**
   * @const {String} OPERATOR_NOT_IN_LIST - Array operator to check not in list
   */

  _exports.OPERATOR_IN_LIST = OPERATOR_IN_LIST;
  const OPERATOR_NOT_IN_LIST = 'notInList';
  /**
   * @const {String} OPERATOR_EQUAL - Binary operator to check equality
   */

  _exports.OPERATOR_NOT_IN_LIST = OPERATOR_NOT_IN_LIST;
  const OPERATOR_EQUAL = 'equal';
  /**
   * @const {String} OPERATOR_NOT_EQUAL - Binary operator to check not equality
   */

  _exports.OPERATOR_EQUAL = OPERATOR_EQUAL;
  const OPERATOR_NOT_EQUAL = 'notEqual';
  /**
   * @const {String} OPERATOR_CONTAINS - String operator to check string content
   */

  _exports.OPERATOR_NOT_EQUAL = OPERATOR_NOT_EQUAL;
  const OPERATOR_CONTAINS = 'contains';
  /**
   * @const {String} OPERATOR_LESS - Binary operator to check less than
   */

  _exports.OPERATOR_CONTAINS = OPERATOR_CONTAINS;
  const OPERATOR_LESS = 'less';
  /**
   * @const {String} OPERATOR_GREATER - Binary operator to check greater than
   */

  _exports.OPERATOR_LESS = OPERATOR_LESS;
  const OPERATOR_GREATER = 'greater';
  /**
   * @const {String} OPERATOR_BETWEEN - Binary operator to check between
   */

  _exports.OPERATOR_GREATER = OPERATOR_GREATER;
  const OPERATOR_BETWEEN = 'between';
  _exports.OPERATOR_BETWEEN = OPERATOR_BETWEEN;
});