define("builder/core/froala/links/link-hover-color-plugin", ["exports", "froala-editor", "builder/core/froala/links/link-hover-color-button", "builder/core/froala/links/custom-color-plugin-factory"], function (_exports, _froalaEditor, _linkHoverColorButton, _customColorPluginFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.frCustomHoverColorPopupName = _exports.frCustomHoverColorPlugin = _exports.linkHoverColorChangeEvent = void 0;
  const linkHoverColorChangeEvent = 'link-hover-color-change-event';
  _exports.linkHoverColorChangeEvent = linkHoverColorChangeEvent;
  const frCustomHoverColorPlugin = 'frCustomHoverColorPlugin';
  _exports.frCustomHoverColorPlugin = frCustomHoverColorPlugin;
  const frCustomHoverColorPopupName = `${frCustomHoverColorPlugin}.popup`;
  _exports.frCustomHoverColorPopupName = frCustomHoverColorPopupName;
  _froalaEditor.default.POPUP_TEMPLATES[frCustomHoverColorPopupName] = '[_BUTTONS_][_CUSTOM_LAYER_]';

  _froalaEditor.default.PLUGINS[frCustomHoverColorPlugin] = function frCreateCustomColorPopup(editor) {
    let selectedElement = null; // Selected Element in Froala editor

    let popup = null;
    let linkId = ''; // Selected Link ID

    function showPopup(el) {
      selectedElement = el;
      popup = editor.popups.get(frCustomHoverColorPopupName); // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.

      if (!popup) {
        popup = (0, _customColorPluginFactory.initPopup)(editor, frCustomHoverColorPopupName);
      } // Set the editor toolbar as the popup's container.


      editor.popups.setContainer(frCustomHoverColorPopupName, editor.$tb);
      const linkToolbar = editor.popups.get('link.edit'); // Get the button's object from the link toolbar, in order to place the popup relative to it.

      const b2LinkHoverButton = linkToolbar.find(`.fr-command[data-cmd=${_linkHoverColorButton.linkHoverColorCommand}]`);

      if (!b2LinkHoverButton) {
        return;
      } // Set the popup's position.


      const left = b2LinkHoverButton.offset().left + b2LinkHoverButton.outerWidth() / 2;
      const top = b2LinkHoverButton.offset().top + (editor.opts.toolbarBottom ? 10 : b2LinkHoverButton.outerHeight() - 10);
      editor.popups.show(frCustomHoverColorPopupName, left, top, b2LinkHoverButton.outerHeight());
      initializePopupControls();
      assignActionButtonListeners();
      (0, _customColorPluginFactory.handleSelectionChange)(popup[0], colorUpdateHandler);
    } // Add event listener to popup buttons - back, Ok, delete


    function assignActionButtonListeners() {
      const backButton = popup[0].querySelector(`#${_customColorPluginFactory.backButtonId}`);
      backButton.onclick = hidePopup;
      const setColorButton = popup[0].querySelector(`#${_customColorPluginFactory.setColorButtonId}`);
      setColorButton.onclick = setManualColor;
      const deleteButton = popup[0].querySelector(`#${_customColorPluginFactory.deleteButtonId}`);
      deleteButton.onclick = resetToDefaultStyle;
    } // Initializes the popup controls - textbox and selected color indicator(tick)


    function initializePopupControls() {
      linkId = selectedElement.attributes['data-field-link-id'].value; // Read the local styles set in widget paragraph component for initial popup data

      const {
        paragraphLinks
      } = editor;

      if (paragraphLinks && paragraphLinks[linkId]) {
        var _paragraphLinks$linkI, _paragraphLinks$linkI2, _paragraphLinks$linkI3;

        const color = (_paragraphLinks$linkI = paragraphLinks[linkId]) === null || _paragraphLinks$linkI === void 0 ? void 0 : (_paragraphLinks$linkI2 = _paragraphLinks$linkI.data) === null || _paragraphLinks$linkI2 === void 0 ? void 0 : (_paragraphLinks$linkI3 = _paragraphLinks$linkI2.hoverStyles) === null || _paragraphLinks$linkI3 === void 0 ? void 0 : _paragraphLinks$linkI3.color;

        if (color) {
          (0, _customColorPluginFactory.initializeTextBox)(popup[0], color);
          (0, _customColorPluginFactory.showSelectedColorIndicator)(popup[0], color);
        }
      }
    } // assign handler based on feature type. Eg., text color, hover color..


    function colorUpdateHandler(event) {
      var _event$target;

      if (!selectedElement) {
        return;
      }

      const colorSelected = (event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.innerText) || '';
      const {
        events
      } = editor;
      events.trigger(linkHoverColorChangeEvent, [linkId, 'hoverStyles', colorSelected]);
      hidePopup();
    }

    function hidePopup() {
      editor.popups.hideAll();
    } // Use user provided input from textbox to set the color


    function setManualColor() {
      const color = popup[0].querySelector(`#${_customColorPluginFactory.textBoxId}`).value;
      editor.events.trigger(linkHoverColorChangeEvent, [linkId, 'hoverStyles', color]);
      hidePopup();
    } // Remove the color applied from the popup for a given feature


    function resetToDefaultStyle() {
      const {
        events
      } = editor;
      events.trigger(linkHoverColorChangeEvent, [linkId, 'hoverStyles', '']);
      (0, _customColorPluginFactory.initializeTextBox)(popup[0], '');
      (0, _customColorPluginFactory.removeSelectedColorIndicator)(popup[0]);
      hidePopup();
    }

    return {
      showPopup,
      hidePopup
    };
  };
});