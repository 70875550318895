define("builder/core/cleaners/empty", ["exports", "builder/core/cleaners/base-cleaner", "builder/core/enumerators/container"], function (_exports, _baseCleaner, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseCleaner.default.extend({
    /**
     * @inheritdoc
     */
    canCleanup(node) {
      return this._isWrapper(node.get('originObject').get('type')) && node.get('originObject').get('children').length === 0;
    },

    /**
     * @inheritdoc
     */
    doCleanup(structure, node) {
      structure.removeChild(node);
    },

    /**
     * Check is passed in type is wrapper or not
     *
     * @param {String} type - type to check
     * @returns {Boolean}
     * @private
     */
    _isWrapper(type) {
      return [_container.default.WIDGET_CONTAINER, _container.default.WIDGET_ROW, _container.default.WIDGET_COLUMN].includes(type);
    }

  });

  _exports.default = _default;
});