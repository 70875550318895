define("builder/core/access-control/mapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mapping of backend permissions to frontend
   *
   * backend permissions sample:
   * {
   *     website: {
   *         create: false,
   *         read: true,
   *         update: true,
   *         delete: false
   *     },
   *     page: {
   *         create: false,
   *         read: true,
   *         update: false,
   *         delete: false
   *     }
   * }
   *
   * here these permissions will be mapped to frontend permissions
   *
   * map sample:
   * website: {
   *     sentry: <Website sentry class>,
   *     permissions: {
   *         create: 'CREATE',
   *         read: 'READ'
   *     }
   * }
   *
   * the result(unmapped permissions will be ommited):
   * {
   *     name: 'website',
   *     sentry: <Website sentry class>,
   *     permissions: [
   *         {
   *             name: 'CREATE',
   *             isGranted: false
   *         },
   *         {
   *             name: 'READ',
   *             isGranted: true
   *         }
   *     ]
   * }
   *
   * @class AccessControlMapper
   */
  var _default = Ember.Object.extend({
    /**
     * Map backend permissions to frontend permissions
     *
     * @param {Object} rawBackendPermissions - backend permissions
     * @param {Object} frontendMap - frontend permissions map
     * @returns {Object} - mapped backend permissions
     */
    map(rawBackendPermissions, frontendMap) {
      return this._map(this.normalize(rawBackendPermissions), frontendMap);
    },

    /**
     * Map normalized backend permissions to frontend map
     *
     * @param {Object} backendPermissions - normalized backend permissions
     * @param {Object} frontendMap - frontend map
     * @returns {Object[]} - mapped permissions
     * @private
     *
     * Note: normalized backend permission has the following form:
     * <model/service name>: {
     *     <operation>: <isGranted>
     * }
     *
     * ex.
     * page: {
     *     create: true,
     *     update: false,
     *     move: true
     * },
     * website: {
     *     delete: true,
     *     update: true
     * }
     */
    _map(backendPermissions, frontendMap) {
      const mappedPermissions = [];
      let mappedPermission, frontendMapPiece, backendPermission;

      if (backendPermissions) {
        Object.keys(backendPermissions).forEach(entity => {
          backendPermission = backendPermissions[entity];
          frontendMapPiece = frontendMap.hasOwnProperty(entity) ? frontendMap[entity] : null; // Backend permissions is not mapped on frontend - skip it

          if (!frontendMapPiece) {
            return;
          }

          mappedPermission = {
            name: entity,
            permissions: []
          };

          if (backendPermission) {
            Object.keys(backendPermission).forEach(property => {
              if (!frontendMapPiece.permissions.hasOwnProperty(property)) {
                return;
              }

              mappedPermission.permissions.push({
                name: frontendMapPiece.permissions[property],
                isGranted: backendPermission[property]
              });
            });
          }

          mappedPermissions.push(mappedPermission);
        });
      }

      return mappedPermissions;
    },

    /**
     * Normalize raw backend permissions
     *
     * @param {Object} permissions - raw backend permissions
     * @returns {Object} - object 1-st level keys are model name and 2-nd level keys - object propertiesw
     * @private
     *
     * Sample input:
     * {
     *     page: {
     *         create: true,
     *         update: true,
     *         createTemplate: true,
     *         updateTemplate: true
     *     }
     * }
     *
     * Sample output should be:
     * {
     *     page: {
     *         create: true,
     *         update: true
     *     },
     *     pageTemplate: {
     *         create: true,
     *         update: true
     *     }
     * }
     *
     * expected sections:
     * website,
     * page,
     * form,
     * globalstyles
     */
    normalize(permissions) {
      if (permissions.hasOwnProperty('website')) {
        this.normalizeWebsite(permissions);
      }

      if (permissions.hasOwnProperty('page')) {
        this.normalizePage(permissions);
      }

      if (permissions.hasOwnProperty('globalstyles')) {
        this.normalizeGlobalStyles(permissions);
      }

      if (permissions.hasOwnProperty('modulegallery')) {
        this.normalizeModuleGallery(permissions);
      }

      if (permissions.hasOwnProperty('containertemplate')) {
        this.normalizeContainerTemplates(permissions);
      }

      return permissions;
    },

    /**
     * Normalize "website" key of permissions
     *
     * @param {Object} permissions - permissions which contains website key to be normalized
     * @private
     */
    normalizeWebsite(permissions) {
      if (permissions.website.hasOwnProperty('saveastemplate') || permissions.website.hasOwnProperty('removefromtemplate')) {
        if (permissions.hasOwnProperty('website-template')) {
          throw new Ember.Error('Key "website-template" already exists');
        }

        permissions['website-template'] = {};
      }

      if (permissions.website.hasOwnProperty('saveastemplate')) {
        this.replace(permissions.website, 'saveastemplate', permissions['website-template'], 'saveastemplate');
      }

      if (permissions.website.hasOwnProperty('removefromtemplate')) {
        this.replace(permissions.website, 'removefromtemplate', permissions['website-template'], 'removefromtemplate');
      }

      if (permissions.website.hasOwnProperty('personalisationwizard')) {
        if (permissions.hasOwnProperty('market-segments')) {
          throw new Error('Invalid permissions, "market-segments" should not be present');
        }

        permissions['market-segments'] = {};
        this.replace(permissions.website, 'personalisationwizard', permissions['market-segments'], 'personalisationwizard');
      }
    },

    /**
     * Normalize "containertemplate" key of permissions
     * @param {Object} permissions - permissions which contains page key to be normalized
     */
    normalizeContainerTemplates(permissions) {
      if (permissions.containertemplate.hasOwnProperty('templateadmin')) {
        if (permissions.hasOwnProperty('container-template')) {
          throw new Ember.Error('Key "container-template" already exists');
        }

        permissions['container-template'] = {};
        this.replace(permissions.containertemplate, 'templateadmin', permissions['container-template'], 'templateadmin');
      }
    },

    /**
     * Normalize "page" key of permissions
     *
     * @param {Object} permissions - permissions which contains page key to be normalized
     * @private
     */
    normalizePage(permissions) {
      if (permissions.page.hasOwnProperty('createtemplate')) {
        if (permissions.hasOwnProperty('page-template')) {
          throw new Ember.Error('Key "page-template" already exists');
        }

        permissions['page-template'] = {};
      }

      if (permissions.page.hasOwnProperty('globaltemplates') || permissions.page.hasOwnProperty('mytemplates')) {
        if (permissions.hasOwnProperty('page-template-categories')) {
          throw new Ember.Error('Key "page-template-categories" already exists');
        }

        permissions['page-template-categories'] = {};
      }

      if (permissions.page.hasOwnProperty('createtemplate')) {
        this.replace(permissions.page, 'createtemplate', permissions['page-template'], 'createtemplate');
      }

      if (permissions.page.hasOwnProperty('templateadmin')) {
        this.replace(permissions.page, 'templateadmin', permissions['page-template'], 'templateadmin');
      }

      if (permissions.page.hasOwnProperty('globaltemplates')) {
        this.replace(permissions.page, 'globaltemplates', permissions['page-template-categories'], 'globaltemplates');
      }

      if (permissions.page.hasOwnProperty('mytemplates')) {
        this.replace(permissions.page, 'mytemplates', permissions['page-template-categories'], 'mytemplates');
      }

      if (permissions.page.hasOwnProperty('globalpageadmin')) {
        this.replace(permissions.page, 'globalpageadmin', permissions.page, 'manage-global');
      }
    },

    /**
     * Normalize "globalstyles" key of permissions
     *
     * @param {Object} permissions - permissions that contains globalstyles key to be normalized
     * @private
     */
    normalizeGlobalStyles(permissions) {
      if (permissions.hasOwnProperty('global-style')) {
        throw new Ember.Error('Key "global-style" already exists');
      }

      this.replace(permissions, 'globalstyles', permissions, 'global-style');
    },

    /**
     * Normalize module gallery permissios
     *
     * @param {Object} permissions - permissions to normalize
     */
    normalizeModuleGallery(permissions) {
      if (permissions.hasOwnProperty('module-gallery')) {
        throw new Ember.Error('Key "module-gallery" already exists');
      }

      this.replace(permissions, 'modulegallery', permissions, 'module-gallery'); // Special case, since we can have some named acls, to be applied everywhere it could appear
      // for now only in module config field

      if (permissions['module-gallery'].hasOwnProperty('voicesearch')) {
        if (permissions.hasOwnProperty('misc')) {
          throw new Ember.Error('Key "misc" already exists');
        } // Name "misc" is temporary name, change it when the picture will be more clear


        permissions.misc = {
          voicesearch: permissions['module-gallery'].voicesearch
        };
        delete permissions['module-gallery'].voicesearch;
      }

      if (permissions['module-gallery'].hasOwnProperty('tabsmodule')) {
        permissions['module-gallery'].tabs = permissions['module-gallery'].tabsmodule;
        delete permissions['module-gallery'].tabsmodule;
      }
    },

    /**
     * Replace object key with removal of old key
     *
     * @param {Object} where - object which key to replace and remove
     * @param {String} whereKey - key to be removed
     * @param {Object} replacement - object to which new property will be added
     * @param {String} replacementKey - name of key to be added
     * @private
     */
    replace(where, whereKey, replacement, replacementKey) {
      if (replacement.hasOwnProperty(replacementKey)) {
        throw new Ember.Error(`Key "${replacementKey}" already exists`);
      }

      replacement[replacementKey] = where[whereKey];
      delete where[whereKey];
    }

  });

  _exports.default = _default;
});