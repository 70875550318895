define("builder/mixins/event-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base class component event system support
   * dispatch must be called inside observable component, any parameter passed after dispatch will be passed into
   * event listener. ex. this.dispatch('click', this, event);
   * in event listener - component.attachListener('click', function(component, event) {})
   */
  var _default = Ember.Mixin.create({
    /**
     * @property {Object} events - list of available events
     */
    events: null,

    /**
     * Attach event listener to component
     *
     * @param {String} eventName - name of event to listen
     * @param {Function} handler - event handler
     */
    attachListener(eventName, handler) {
      const {
        events
      } = this;
      const handlers = events[eventName] || [];

      if (!events.hasOwnProperty(eventName)) {
        throw new Ember.Error(`Event ${eventName} is not allowed`);
      }

      if (!handlers.includes(handler)) {
        handlers.pushObject(handler);
      }

      events[eventName] = handlers;
    },

    /**
     * Dispatch event within component. Additional properties (except eventName) will be passed into event handler
     *
     * @param {String} eventName - event name to dispatch.
     * @param {*[]} rest - other arguments
     */
    dispatchEvent(eventName, ...rest) {
      const {
        events
      } = this;
      const handlers = events[eventName] || [];

      for (let i = 0, len = handlers.length; i < len; i++) {
        if (typeof handlers[i] === 'function') {
          handlers[i](...rest);
        }
      }
    },

    /**
     * Remove event listener
     *
     * @param {String} eventName - event from which listener will be remove
     * @param {Function} handler - handler reference
     */
    removeListener(eventName, handler) {
      const {
        events
      } = this;
      const handlers = events[eventName] || [];

      for (let i = 0, len = handlers.length; i < len; i++) {
        if (handlers[i] === handler) {
          handlers.splice(i, 1);
          break;
        }
      }
    }

  });

  _exports.default = _default;
});