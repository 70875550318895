define("builder/core/convertors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayToJSON = arrayToJSON;
  _exports.convertStringParamsToObject = convertStringParamsToObject;
  _exports.convertObjectToStringParams = convertObjectToStringParams;

  /**
   * Convert array of objects into object by creating new object, taking values of object as new object keys and
   * it's value as new object value
   *
   * arrayToJSON([{
   *		key: 'prop1',
   *		value: 'value1'
   *	}, {
   *		key: 'prop2',
   *		value: 'value2',
   *	}, {
   *		key: 'final',
   *		value: 'final value'
   *	}], 'key', 'value') => {
   *	 prop1: 'value1',
   *	 prop2: 'value2',
   *	 final: 'final value'
   * }
   *
   * @param {Object[]} arr - array of objects to convert into array
   * @param {string} key - object value under that key will be used as new object key
   * @param {string} dataKey - object value under that key will be used as a value of new object under passed key
   * @param {Object} data - initial state of new object
   * @returns {Object} - object created by converting array of objects into object
   */
  function arrayToJSON(arr, key, dataKey, data = {}) {
    let element;

    if (!Array.isArray(arr)) {
      return data;
    }

    for (let i = 0, len = arr.length; i < len; i++) {
      element = arr[i];

      if (element[key] && typeof element[dataKey] !== 'undefined') {
        data[element[key]] = element[dataKey];
      }
    }

    return data;
  }
  /**
   * Convert string to object of get params
   *
   * @param {string} string - string to convert to object
   * @returns {Object}
   */


  function convertStringParamsToObject(string) {
    const params = string.split('&');
    const result = {};
    let key, value;

    if (!string || !string.length) {
      return result;
    }

    for (let i = 0, len = params.length; i < len; i++) {
      [key, value] = params[i].split('=');

      if (key.indexOf('[]') !== -1) {
        key = key.replace('[]', '');

        if (!result.hasOwnProperty(key)) {
          result[key] = [];
        }

        result[key].push(value);
      } else {
        result[key] = value;
      }
    }

    return result;
  }
  /**
   * Converts object to get params string
   *
   * @param {Object} params - object to be converted
   * @returns {string}
   */


  function convertObjectToStringParams(params) {
    const paramsArray = [];

    if (!params || typeof params !== 'object') {
      return '';
    }

    Object.keys(params).forEach(key => {
      if (Ember.isArray(params[key])) {
        for (let i = 0; i < params[key].length; i++) {
          paramsArray.push(`${key}[]=${params[key][i]}`);
        }
      } else {
        paramsArray.push(`${key}=${params[key]}`);
      }
    });
    return paramsArray.join('&');
  }
});