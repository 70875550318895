define("builder/initializers/access-control", ["exports", "builder/core/access-control/mapper", "builder/core/access-control/checkpoint", "builder/abilities/tier-control", "builder/abilities/oem-control", "builder/abilities/authorization-control"], function (_exports, _mapper, _checkpoint, _tierControl, _oemControl, _authorizationControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    const mapper = _mapper.default.create();

    const checkpoint = _checkpoint.default.create();

    const permissions = mapper.normalize(window.appBootstrap && window.appBootstrap.initData.user.acl || {});

    if (permissions) {
      Object.keys(permissions).forEach(entityName => {
        checkpoint.addPermission(entityName, permissions[entityName]);
      });
    }

    application.register('access-control:checkpoint', checkpoint, {
      singleton: true,
      instantiate: false
    });
    application.register('access-control:tier-control', _tierControl.default, {
      singleton: true,
      instantiate: true
    });
    application.register('access-control:oem-control', _oemControl.default, {
      singleton: true,
      instantiate: true
    });
    application.register('access-control:authorization-control', _authorizationControl.default, {
      singleton: true,
      instantiate: true
    });
    application.inject('ability', 'checkpoint', 'access-control:checkpoint');
  }

  var _default = {
    name: 'access-control',
    initialize
  };
  _exports.default = _default;
});