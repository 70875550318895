define("builder/pods/components/field-media-video-document/component", ["exports", "builder/pods/components/field-media/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldMediaVideoDocument
   *
   * Note: name left like that, cause probably video and document will have different layout and/or logic
   */
  var _default = _component.default.extend();

  _exports.default = _default;
});