define("builder/core/which-transition-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._whichTransitionEvent = _whichTransitionEvent;

  /**
   * Cross browsers detection "transitionend" event
   *
   * @returns {String}
   * @private
   */
  function _whichTransitionEvent() {
    const el = document.createElement('fakeelement');
    const transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd'
    };
    const property = Object.keys(transitions).find(prop => el.style[prop] !== undefined); // No transition events found
    // TODO: maybe we should throw error here?

    return transitions[property] || '';
  }
});