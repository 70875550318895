define("builder/serializers/page-module", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageModuleSerializer
   */
  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    serializeIntoHash(data, ...args) {
      this._super(data, ...args); // Since all create, update operations must be sent on route /page_modulepreview, data key must be changed too


      data.pageModulepreview = data.pageModule;
      delete data.pageModule;
    },

    /**
     * @inheritdoc
     */
    normalizeCreateRecordResponse(store, primaryModelClass, payload, ...args) {
      // Since we expect pageModule data key but for update and create we use pageModulepreview data key
      // we must restore expected key
      payload.pageModule = payload.pageModulepreview;
      delete payload.pageModulepreview;
      return this._super(store, primaryModelClass, payload, ...args);
    }

  });

  _exports.default = _default;
});