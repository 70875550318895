define("builder/core/cleaners/row", ["exports", "builder/core/cleaners/base-cleaner", "builder/core/enumerators/container"], function (_exports, _baseCleaner, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseCleaner.default.extend({
    /**
     * @inheritdoc
     */
    canCleanup(node) {
      const children = node.get('originObject').get('children');
      const child = children[0];
      const grandChild = child && child.get('originObject').hasOwnProperty('children') ? child.get('originObject').get('children')[0] : null;

      if (node.get('originObject').get('type') !== _container.default.WIDGET_ROW) {
        return false;
      }

      return children.length === 1 && node.get('parent') && node.get('parent').get('originObject').get('type') === _container.default.WIDGET_CONTAINER && grandChild && grandChild.get('originObject').get('type') === _container.default.WIDGET_ROW;
    },

    /**
     * @inheritdoc
     */
    doCleanup(structure, node) {
      const {
        gridBuilder
      } = this; // All rows in last column

      const children = node.get('originObject').get('children')[0].get('originObject').get('children'); // Widget container

      const parentContainer = node.get('parent'); // Row meta container

      const metaContainer = parentContainer.get('parent'); // Insert index for new containers, that will wrap rows

      const insertIndex = metaContainer.get('originObject').get('children').indexOf(parentContainer) + 1;
      let wrapper, child; // Move all child row into their own containers, except for the 1-st child row
      // Since it can reuse existing container

      for (let len = children.length, i = len - 1; i >= 1; i--) {
        wrapper = gridBuilder.createInstance(_container.default.WIDGET_CONTAINER); // Important to keep reference to child element, since this reference will be lost
        // after structure.removeChild

        child = children[i]; // This is important to not trigger other cleaners

        structure.removeChild(children[i]);
        structure.addChild(wrapper, child);
        structure.addChild(metaContainer, wrapper, insertIndex);
      } // Reuse container, leave 1-st child row in the container


      if (children[0]) {
        structure.addChild(parentContainer, children[0]);
        structure.removeChild(node);
      }
    }

  });

  _exports.default = _default;
});