define("builder/pods/website/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hiwhkIGE",
    "block": "{\"symbols\":[],\"statements\":[[5,\"widget-popup\",[[12,\"class\",\"widget-popup_theme_website-create\"]],[[],[]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"website-create\"],[8],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"website-create__title\"],[8],[0,\"\\n\\t\\t\\tStart Building Your New Website\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"website-create__text\"],[8],[0,\"\\n\\t\\t\\tStart a new website by cloning from an existing theme. Or, clone any existing website.\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"website-create__clone-list\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_field\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"field-dropdown\",[],[[\"@field\",\"@actionHandler\"],[[22,\"templatesList\"],[28,\"action\",[[23,0,[]],\"selectTemplate\"],null]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bld-form_field\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"field-dropdown\",[],[[\"@field\",\"@actionHandler\"],[[22,\"websitesList\"],[28,\"action\",[[23,0,[]],\"selectWebsite\"],null]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"website-create__footer\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"default-button\",[],[[\"@label\",\"@actionLabel\",\"@handler\",\"@isDisabled\"],[\"Get Started\",\"Creating...\",[28,\"action\",[[23,0,[]],\"createWebsite\"],null],[28,\"not\",[[24,[\"isEnableCreateButton\"]]],null]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/create/template.hbs"
    }
  });

  _exports.default = _default;
});