define("builder/validators/delegate", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Delegate = _base.default.extend({
    /**
     * @property {StoreService} store - store service
     */
    store: Ember.inject.service('store'),

    /**
     * @inheritdoc
     */
    validate(value, options, component) {
      return options.validate.fn(value, component);
    }

  });

  var _default = Delegate;
  _exports.default = _default;
});