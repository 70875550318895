define("builder/pods/components/form-tree/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tree: Ember.computed('field.choices', function () {
      return this.createTree(this.get('field.choices'), 0, [], 0);
    }),

    createTree(tree, level, array, parent) {
      const selected = this.get('field.hideItem');
      const comp = this;
      let order = 0; // eslint-disable-next-line no-param-reassign

      level++;

      _jquery.default.each(tree, function (index, object) {
        if (selected !== object.id) {
          array.push({
            value: `${parent}-${++order}`,
            label: `${comp.treeLines(level)} |───`
          });
        }

        array.push({
          value: object.id,
          label: `${comp.treeLines(level)} |─ ${object.title}`,
          disabled: 'disabled'
        });

        if (selected !== object.id) {
          if (Array.isArray(object.children) && object.children.length) {
            comp.createTree(object.children, level, array, object.id);
          } else {
            array.push({
              value: `${object.id}-1`,
              label: `${comp.treeLines(level)} | └─`
            });
          }

          if (index === tree.length - 1) {
            array.push({
              value: `${parent}-${++order}`,
              label: `${comp.treeLines(level)} |_____`
            });
          }
        }
      });

      return array;
    },

    treeLines(level) {
      let i = 0;
      const treeLines = [];

      for (; i < level - 1; i++) {
        treeLines.push('|');
      }

      return treeLines.join(' ');
    },

    willInsertElement() {
      const view = this.element;
      const {
        field
      } = this;
      const element = view.querySelector('select');
      const attrs = field.attr;

      if (attrs) {
        Object.keys(attrs).forEach(attr => {
          element.setAttribute(attr, attrs[attr]);
        });
      }

      element.setAttribute('name', field.name);
    },

    selected: Ember.computed('field.data', function () {
      const {
        data
      } = this.field;
      return data && data[0];
    })
  });

  _exports.default = _default;
});