define("builder/pods/components/widget-field-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKyqg4gX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isShowLabel\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"label\",true],[11,\"class\",[29,[\"bld-form_label \",[22,\"labelClass\"],\" \",[28,\"if\",[[24,[\"validation\"]],\"bld-form_label-error\"],null]]]],[11,\"for\",[24,[\"field\",\"attr\",\"id\"]]],[8],[0,\"\\n\\t\\t\"],[1,[24,[\"field\",\"label\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"field\",\"help\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"widget-help\",null,[[\"text\"],[[24,[\"field\",\"help\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"validation\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"validation-tooltip\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"validation-tooltip__inner \",[28,\"if\",[[24,[\"topPosition\"]],\"validation-tooltip__inner--down\"],null]]]],[8],[1,[22,\"validation\"],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"field\",\"description\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-description\",null,[[\"text\"],[[24,[\"field\",\"description\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-field-info/template.hbs"
    }
  });

  _exports.default = _default;
});