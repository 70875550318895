define("builder/models/language", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @typedef {Object} LanguageModel - model for languages dictionary
   * @property {String} Code - two letter language code. See ISO 639-1 standard (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)
   * @property {String} Label - language label to show at dropdown
   */
  var _default = _model.default.extend({
    Code: (0, _model.attr)('string'),
    Label: (0, _model.attr)('string')
  });

  _exports.default = _default;
});