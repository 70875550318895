define("builder/pods/components/widget-container/component", ["exports", "jquery", "builder/pods/components/grid-base-component/component", "builder/core/enumerators/container", "builder/core/styles-management/widget-container/map", "builder/core/enumerators/module-action", "ember-copy"], function (_exports, _jquery, _component, _container, _map, _moduleAction, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_TIME = 700;
  /**
   * Ember component component.
   * @type {Object}
   */

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['js-container-wrapper'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['data.hasActiveChild:js-container-wrapper_hovered', 'isHovered:js-container-wrapper_hovered', 'visibleOnLg::hidden-lg', 'visibleOnMd::hidden-md', 'visibleOnSm::hidden-sm', 'visibleOnXs::hidden-xs', 'isOverlayVisible:overlay-visible', 'data.originObject.resetColumnsPadding:reset-paddings-in-columns', 'containerContentFullWidth:container__content_full-width', 'containerFullWidth:container_full-width', 'isRowEditorModeOn:container-row-editor_enable', 'isColumnEditorModeOn:container-column-editor_enable'],

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @type {CssCustomPropertiesService} CssCustomPropertiesService - configuration styles service
     */
    cssCustomPropertiesService: Ember.inject.service('css-custom-properties'),

    /**
     * Store service
     * @type {import('ember-data').DS.Store}
     */
    storeService: Ember.inject.service('store'),

    /**
     * Structure service
     * @type {StructureService}
     */
    structureService: Ember.inject.service('structure'),

    /**
     * drag and drop structure service, since we must interact with
     * @type {Ember.Service}
     */
    dragAndDropService: Ember.inject.service('drag-and-drop'),

    /**
     * Is mouse over container or not
     * @type {boolean}
     */
    isHovered: false,

    /**
     * Is mouse over container or not
     * @type {boolean}
     */
    isColumnEditorModeOn: false,

    /**
     * Is mouse over container or not
     * @type {boolean}
     */
    isRowEditorModeOn: false,

    /**
     * Youtube vide id for container background
     * @type {string}
     * @readonly
     */
    backgroundVideoId: Ember.computed.alias('data.originObject.videoId').readOnly(),

    /**
     * Animation Data of the container
     * @type {Object} - Animation Data Object
     */
    animationData: Ember.computed('data.originObject.animationName', 'data.originObject.animationDuration', 'data.originObject.animationMirroring', 'data.originObject.enableAnimation', 'data.originObject.animationOnDeviceType.[]', 'configurationService.currentDevice.breakpoint', function () {
      var _this$data$originObje;

      const animationEnabledDevices = ((_this$data$originObje = this.data.originObject) === null || _this$data$originObje === void 0 ? void 0 : _this$data$originObje.animationOnDeviceType) || [];
      const currentDevice = this.configurationService.currentDevice.breakpoint;
      const enabled = this.data.originObject.enableAnimation && animationEnabledDevices.includes(currentDevice);
      const {
        animationName: direction,
        animationDuration: duration,
        animationMirroring: mirror
      } = this.data.originObject;
      return {
        name: enabled ? direction : '',
        duration: duration ? duration.replace('ms', '') : '',
        mirror
      };
    }),

    /**
     * Show or hide video background in container
     * @type {boolean}
     * @readonly
     */
    showVideoBackground: Ember.computed('configurationService.currentDevice.type', 'backgroundVideoId', function () {
      const deviceService = this.configurationService.device;
      return !!((deviceService.isDesktop() || deviceService.isSmallMonitor()) && this.backgroundVideoId);
    }).readOnly(),

    /**
     * Contains fullWidthContent value from container styles
     * @type {string}
     */
    fullWidthContentValue: '',

    /**
     * Contains fullWidth value from container styles
     * @type {string}
     */
    fullWidthValue: '',

    /**
     * Is content vertically centered
     * @type {boolean}
     */
    isContentVerticallyCentered: Ember.computed('data.originObject.styles.global.@each.value', function () {
      var _this$data, _this$data$originObje2, _this$data$originObje3;

      const globalStyles = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : (_this$data$originObje2 = _this$data.originObject) === null || _this$data$originObje2 === void 0 ? void 0 : (_this$data$originObje3 = _this$data$originObje2.styles) === null || _this$data$originObje3 === void 0 ? void 0 : _this$data$originObje3.global;

      if (!globalStyles) {
        return false;
      }

      const contentPosition = globalStyles.findBy('name', 'contentPosition');
      const contentPositionValue = contentPosition && contentPosition.value;
      return contentPositionValue === 'vertically-centered';
    }),

    /**
     * Min-height styles property for container
     * @type {string | null}
     */
    containerMinHeight: Ember.computed('_containerMinHeightValue', function () {
      const value = this._containerMinHeightValue;
      return value ? Ember.String.htmlSafe(`min-height: ${value};`) : Ember.String.htmlSafe(null);
    }),

    /**
     * Is style set to container full width
     * @type {boolean}
     */
    containerFullWidth: Ember.computed('mode', 'data.originObject.styles.{lg,md,sm,xs,global}.@each.value', function () {
      var _this$data2, _this$data2$originObj, _this$data2$originObj2;

      const globalVariables = (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$originObj = _this$data2.originObject) === null || _this$data2$originObj === void 0 ? void 0 : (_this$data2$originObj2 = _this$data2$originObj.styles) === null || _this$data2$originObj2 === void 0 ? void 0 : _this$data2$originObj2.global;

      if (!globalVariables) {
        return false;
      }

      const fullWidth = globalVariables.findBy('name', 'fullWidth');
      return fullWidth && fullWidth.value === 'fit-to-browser';
    }),

    /**
     * Is style set to content full width
     * @type {boolean}
     */
    containerContentFullWidth: Ember.computed('mode', 'data.originObject.styles.{lg,md,sm,xs,global}.@each.value', function () {
      var _this$data3, _this$data3$originObj, _this$data3$originObj2;

      const globalVariables = (_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : (_this$data3$originObj = _this$data3.originObject) === null || _this$data3$originObj === void 0 ? void 0 : (_this$data3$originObj2 = _this$data3$originObj.styles) === null || _this$data3$originObj2 === void 0 ? void 0 : _this$data3$originObj2.global;

      if (!globalVariables) {
        return false;
      }

      const fullWidthContent = globalVariables.findBy('name', 'fullWidthContent');
      return fullWidthContent && fullWidthContent.value === 'fit-to-browser';
    }),

    /**
     * Global styles model
     * @type {WebsiteStyleModel | null }
     */
    globalStylesModel: null,

    /**
     * CSS generator to be used for css generation
     * @type {CSSGenerator}
     */
    cssGenerator: null,

    /**
     * Contains inline css styles
     * @type {string}
     */
    styles: Ember.computed('mode', 'data.originObject.styles.{lg,md,sm,xs,global}.@each.value', 'globalStylesModel.global.@each.value', function () {
      var _this$data4, _this$data4$originObj, _this$data4$originObj2, _this$data5, _this$data5$originObj, _this$data5$originObj2, _this$element;

      const globalVariables = (_this$data4 = this.data) === null || _this$data4 === void 0 ? void 0 : (_this$data4$originObj = _this$data4.originObject) === null || _this$data4$originObj === void 0 ? void 0 : (_this$data4$originObj2 = _this$data4$originObj.styles) === null || _this$data4$originObj2 === void 0 ? void 0 : _this$data4$originObj2.global;
      const fullWidth = globalVariables === null || globalVariables === void 0 ? void 0 : globalVariables.findBy('name', 'fullWidth');
      const fullWidthContent = globalVariables === null || globalVariables === void 0 ? void 0 : globalVariables.findBy('name', 'fullWidthContent');
      const fullHeight = globalVariables === null || globalVariables === void 0 ? void 0 : globalVariables.findBy('name', 'fullHeight');
      const variablesCopy = (_this$data5 = this.data) === null || _this$data5 === void 0 ? void 0 : (_this$data5$originObj = _this$data5.originObject) === null || _this$data5$originObj === void 0 ? void 0 : (_this$data5$originObj2 = _this$data5$originObj.styles) === null || _this$data5$originObj2 === void 0 ? void 0 : _this$data5$originObj2.toJSON();
      const store = this.storeService;
      const fullWidthValue = fullWidth && fullWidth.value;
      const fullHeightValue = fullHeight && fullHeight.value;
      const fullWidthContentValue = fullWidthContent && fullWidthContent.value;

      if (this.fullWidthContentValue !== fullWidthContentValue) {
        this.set('fullWidthContentValue', fullWidthContentValue);
        this.reloadChildModules();
      }

      if (this.fullWidthValue !== fullWidthValue) {
        this.set('fullWidthValue', fullWidthValue);
        this.reloadChildModules();
      }

      this.set('_fullHeightValue', fullHeightValue);
      const styleRecord = store.createRecord('style', variablesCopy);
      const moduleId = `#${(_this$element = this.element) === null || _this$element === void 0 ? void 0 : _this$element.id}`;
      const cssCustomProperties = this.cssCustomPropertiesService.generateStylesFromVariables(styleRecord, null, moduleId);
      const styles = this.cssCustomPropertiesService.generateStylesFromStyleMap(moduleId, _map.default, ''); // Clear not needed record

      store.deleteRecord(styleRecord);
      return `${cssCustomProperties} ${styles}`;
    }),

    /**
     * Prepared acl for container based on data.acl
     * @type {object}
     * @readonly
     */
    preparedAcl: Ember.computed('data.acl', 'data.parent', 'data.parent.originObject.children.[]', function () {
      var _this$data6, _this$data6$parent, _parent$children, _this$data7;

      const parent = (_this$data6 = this.data) === null || _this$data6 === void 0 ? void 0 : (_this$data6$parent = _this$data6.parent) === null || _this$data6$parent === void 0 ? void 0 : _this$data6$parent.originObject;
      const parentChildren = (parent === null || parent === void 0 ? void 0 : parent.children) || [];
      const childrenCount = parent === null || parent === void 0 ? void 0 : (_parent$children = parent.children) === null || _parent$children === void 0 ? void 0 : _parent$children.length;
      const containerIndex = parentChildren.indexOf(this.data);
      let acl = (_this$data7 = this.data) === null || _this$data7 === void 0 ? void 0 : _this$data7.acl;

      if (acl) {
        acl = (0, _emberCopy.copy)(acl, true);
        acl.moveUp = containerIndex > 0;
        acl.moveDown = containerIndex < childrenCount - 1;
      } else {
        acl = {};
      }

      return acl;
    }).readOnly(),

    /**
     * Observer for recalculate height for widget-container
     * @type {import('ember').Ember.observer}
     * @obsolete
     * TODO: Move this observer to didReceiveAttrs method
     */
    containerHeightObserver: Ember.observer('isAllModulesLoaded', '_fullHeightValue', function () {
      this._runContainerHeight();
    }),

    /**
     * Timer indicator of debounce for recalculate container height
     * @type {object | null}
     * @private
     */
    _containerHeightTimer: null,

    /**
     * Contains fullWidth value from container styles
     * @type {string}
     * @private
     */
    _fullHeightValue: '',

    /**
     * Ember header panel height
     * @type {number}
     * @private
     */
    _panelHeight: 0,

    /**
     * Ignite header panel height
     * @type {number}
     * @private
     */
    _ignitePanelHeight: 0,

    /**
     * Value for container min-height styles property
     * @type {string | null}
     * @private
     */
    _containerMinHeightValue: null,

    /**
     * @inheritdoc
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    doubleClick(...args) {
      if (!this.isColumnEditorModeOn && !this.isRowEditorModeOn) {
        this.moduleAction(_moduleAction.default.EDIT, this.data);
      }

      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    init(...args) {
      var _this$data8, _this$data8$originObj, _this$data8$originObj2;

      this._super(...args);

      const globalVariables = (_this$data8 = this.data) === null || _this$data8 === void 0 ? void 0 : (_this$data8$originObj = _this$data8.originObject) === null || _this$data8$originObj === void 0 ? void 0 : (_this$data8$originObj2 = _this$data8$originObj.styles) === null || _this$data8$originObj2 === void 0 ? void 0 : _this$data8$originObj2.global;
      const fullWidthContent = globalVariables === null || globalVariables === void 0 ? void 0 : globalVariables.findBy('name', 'fullWidthContent');
      const fullWidth = globalVariables === null || globalVariables === void 0 ? void 0 : globalVariables.findBy('name', 'fullWidth');
      this.set('fullWidthContent', fullWidthContent === null || fullWidthContent === void 0 ? void 0 : fullWidthContent.value);
      this.set('fullWidth', fullWidth === null || fullWidth === void 0 ? void 0 : fullWidth.value);
      this.set('cssGenerator', Ember.getOwner(this).lookup('css-generator:main'));
      this.configurationService.device.on('onChange', this, this._runContainerHeight);
      (0, _jquery.default)(document).on(`changeStructure.containerHeight${this.elementId}`, Ember.run.bind(this, function () {
        this._runContainerHeight();

        if (!this.isDestroyed) {
          this.set('isHovered', false);
        }
      }));
      this.set('_panelHeight', (0, _jquery.default)('.page_edit_tools').outerHeight());
      this.set('_ignitePanelHeight', (0, _jquery.default)('.header.pg-header').outerHeight());
    },

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    },

    /**
     * @inheritdoc
     */
    willDestroyElement(...args) {
      this._super(...args);

      this.configurationService.device.off('onChange', this, this._runContainerHeight);
      (0, _jquery.default)(document).off(`changeStructure.containerHeight${this.elementId}`);

      this._removeFirstContainerHeightHandler();
    },

    /**
     * @inheritdoc
     */
    mouseOver(...args) {
      this.set('isHovered', true);
      return this._super(...args);
    },

    /**
     * @inheritdoc
     */
    mouseOut() {
      this.set('isHovered', false);
    },

    /**
     * Reload all parent core modules
     * @returns {void}
     */
    reloadChildModules() {
      const modules = [];
      this.structureService.findModules(this.data, 'core', modules);
      modules.forEach((module, index) => {
        module.set('refresh', Date.now() + index);
      });
    },

    /**
     * Method for run with debounce _containerHeight
     * @returns {void}
     * @private
     */
    _runContainerHeight() {
      if (this._containerHeightTimer) {
        Ember.run.cancel(this._containerHeightTimer);
      }

      if (this.element) {
        this.set('_containerHeightTimer', Ember.run.debounce(this, this._containerHeight, DEBOUNCE_TIME));
      }
    },

    /**
     * Method run recalculate height for widget-container
     * @returns {void}
     * @private
     */
    _containerHeight() {
      var _this$data9, _this$data9$parent;

      if (!this.element) {
        return;
      }

      const fullHeightValue = this._fullHeightValue;
      const parent = (_this$data9 = this.data) === null || _this$data9 === void 0 ? void 0 : (_this$data9$parent = _this$data9.parent) === null || _this$data9$parent === void 0 ? void 0 : _this$data9$parent.originObject;
      const isFirstContainerAfterHeader = (parent === null || parent === void 0 ? void 0 : parent.type) === _container.default.WIDGET_MAIN && (parent === null || parent === void 0 ? void 0 : parent.children[0]) === this.data;

      if (fullHeightValue === 'fit-to-browser') {
        if (isFirstContainerAfterHeader) {
          this._addFirstContainerHeightHandler();

          this._addFirstContainerHeight();
        } else {
          this._addContainerHeight();
        }
      } else {
        Ember.run.cancel(this._containerHeightTimer);

        this._removeContainerHeight();

        if (isFirstContainerAfterHeader) {
          this._removeFirstContainerHeightHandler();
        }
      }
    },

    /**
     * Recalculate and set height for not first widget-container
     * @returns {void}
     * @private
     */
    _addContainerHeight() {
      const isDesktop = this.configurationService.device.isDesktop();
      this.set('_containerMinHeightValue', isDesktop ? `calc(100vh - ${this._panelHeight}px)` : `${(0, _jquery.default)('.viewport').outerHeight()}px`);
    },

    /**
     * Add handlers for recalculate height in first widget-container
     * @returns {void}
     * @private
     */
    _addFirstContainerHeightHandler() {
      this._removeFirstContainerHeightHandler();

      (0, _jquery.default)(window).on(`resize.containerHeight${this.elementId}`, Ember.run.bind(this, function () {
        this.set('_containerHeightTimer', Ember.run.debounce(this, this._addFirstContainerHeight, DEBOUNCE_TIME));
      }));
    },

    /**
     * Recalculate and set height for first widget-container
     * @returns {void}
     * @private
     */
    _addFirstContainerHeight() {
      const isDesktop = this.configurationService.device.isDesktop();
      const windowHeight = isDesktop ? (0, _jquery.default)(window).height() : (0, _jquery.default)('.viewport').outerHeight();
      const headerHeight = (0, _jquery.default)('.widget-header').outerHeight();
      let minHeight = windowHeight - headerHeight;

      if (isDesktop) {
        minHeight = minHeight - this._panelHeight - this._ignitePanelHeight;
      }

      this.set('_containerMinHeightValue', minHeight > 0 ? `${minHeight}px` : null);
    },

    /**
     * Method for remove height in widget-controller
     * @returns {void}
     * @private
     */
    _removeContainerHeight() {
      this.set('_containerMinHeightValue', null);
    },

    /**
     * Method for remove handlers for recalculate height in first widget-container
     * @returns {void}
     * @private
     */
    _removeFirstContainerHeightHandler() {
      (0, _jquery.default)(window).off(`resize.containerHeight${this.elementId}`);
    },

    /**
     * Method for adding click event to handle row/column edit mode
     * @private
     */
    addClickEventHandlerForEditorMode(e) {
      const isGridEditModeEnabled = this.isColumnEditorModeOn || this.isRowEditorModeOn;

      if (isGridEditModeEnabled && this.checkIfClickedTargetIsWithinActiveContainer(e)) {
        e.stopPropagation();
        this.set('data.hasActiveChild', false);
      } else {
        this._disableGridEditorMode();
      }

      this._handleContainerChildrenHoverCss();
    },

    /**
     * Method to check if click is happening within
     * active container
     * @private
     */
    checkIfClickedTargetIsWithinActiveContainer(e) {
      var _e$target, _this$data11, _this$data11$componen;

      const targetClassName = e === null || e === void 0 ? void 0 : (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.className;

      if (this._checkIfElementIsPageModuleControlItems(e)) {
        return false;
      }

      if ((targetClassName === null || targetClassName === void 0 ? void 0 : targetClassName.includes) && (targetClassName === null || targetClassName === void 0 ? void 0 : targetClassName.includes('container'))) {
        var _this$data10, _this$data10$componen;

        // return true if the clicked target container is the active container
        return this._getContainerId(e) === ((_this$data10 = this.data) === null || _this$data10 === void 0 ? void 0 : (_this$data10$componen = _this$data10.component) === null || _this$data10$componen === void 0 ? void 0 : _this$data10$componen.elementId);
      } // return true if the clicked target parent container is the active container


      return this._findParentWidgetContainer(e) === ((_this$data11 = this.data) === null || _this$data11 === void 0 ? void 0 : (_this$data11$componen = _this$data11.component) === null || _this$data11$componen === void 0 ? void 0 : _this$data11$componen.elementId);
    },

    /* check if the element is the page module control items
     *
     * @param {Ember.Object} element - structure item which parent to find
     * @returns {boolean}
     * @private
     */
    _checkIfElementIsPageModuleControlItems(element) {
      var _element$target;

      let subject = element ? (_element$target = element.target) === null || _element$target === void 0 ? void 0 : _element$target.parentElement : null;
      let matched = false;

      while (subject && !matched) {
        var _subject, _subject2;

        const type = (_subject = subject) === null || _subject === void 0 ? void 0 : _subject.className;

        if ((type === null || type === void 0 ? void 0 : type.includes) && (type === null || type === void 0 ? void 0 : type.includes('page_module_control'))) {
          matched = true;
        }

        subject = (_subject2 = subject) === null || _subject2 === void 0 ? void 0 : _subject2.parentElement;
      }

      return matched;
    },

    /**
     * Method to disable both row and column edit mode
     * @private
     */
    _disableGridEditorMode() {
      document.querySelector('.page_edit').removeEventListener('click', this.addClickEventHandlerForEditorMode, true);

      if (!this.isDestroyed) {
        this.set('isRowEditorModeOn', false);
        this.set('isColumnEditorModeOn', false);
        this.dragAndDropService.setActiveGridContainer(null, null);
        this.dragAndDropService.setDraggableElements();
      }
    },

    /**
     * Handle column/row drag and drop on selecting the respective mode
     * @returns {void}
     */
    handelDragAndDropInGridEditMode() {
      const mode = this.isRowEditorModeOn && _container.default.WIDGET_ROW || this.isColumnEditorModeOn && _container.default.WIDGET_COLUMN;
      const nodeData = mode ? this.data : null;
      this.dragAndDropService.setActiveGridContainer(mode, nodeData);
      this.dragAndDropService.setDraggableElements();
    },

    /* Find closes widget-container parent of passed in child
     *
     * @param {Ember.Object} child - structure item which parent to find
     * @returns {string}
     * @private
     */
    _findParentWidgetContainer(child) {
      var _child$target, _parent;

      let subject = child ? (_child$target = child.target) === null || _child$target === void 0 ? void 0 : _child$target.parentElement : null;
      let parent = null;

      while (subject && !parent) {
        var _subject3, _subject4;

        const type = (_subject3 = subject) === null || _subject3 === void 0 ? void 0 : _subject3.className;

        if ((type === null || type === void 0 ? void 0 : type.includes) && (type === null || type === void 0 ? void 0 : type.includes('js-container-wrapper'))) {
          parent = subject;
        }

        subject = (_subject4 = subject) === null || _subject4 === void 0 ? void 0 : _subject4.parentElement;
      }

      return (_parent = parent) === null || _parent === void 0 ? void 0 : _parent.id;
    },

    /* Find id of widget-container
     *
     * @param {Ember.Object} container - structure item which parent to find
     * @returns {string}
     * @private
     */
    _getContainerId(container) {
      var _parent2;

      let subject = container ? container.target : null;
      let parent = null;

      while (subject && !parent) {
        var _subject5;

        if (subject.id) {
          parent = subject;
        }

        subject = (_subject5 = subject) === null || _subject5 === void 0 ? void 0 : _subject5.parentElement;
      }

      return (_parent2 = parent) === null || _parent2 === void 0 ? void 0 : _parent2.id;
    },

    /**
     * disable/enable hover css on widget/modules when
     * grid editor mode is enabled/disabled
     * @returns {void}
     */
    _handleContainerChildrenHoverCss() {
      const elements = document.querySelectorAll('.page_module');
      const addClassToElement = this.isRowEditorModeOn || this.isColumnEditorModeOn;
      elements.forEach(function (e) {
        if (addClassToElement) {
          e.classList.add('grid-editor-on');
        } else {
          e.classList.remove('grid-editor-on');
        }
      });
    },

    /**
     * Handle the active Grid Edit mode
     * @returns {void}
     */
    handleGridEditAction() {
      this.handelDragAndDropInGridEditMode();

      this._handleContainerChildrenHoverCss();

      this.addClickEventHandlerForEditorMode = this.addClickEventHandlerForEditorMode.bind(this);
      document.querySelector('.page_edit').addEventListener('click', this.addClickEventHandlerForEditorMode, true);
    },

    actions: {
      buttonAction(type) {
        this.moduleAction(type, this.data);

        switch (type) {
          case _moduleAction.default.ROW_EDITOR:
            this.set('isColumnEditorModeOn', false);
            this.set('isRowEditorModeOn', true);
            this.handleGridEditAction();
            break;

          case _moduleAction.default.COLUMN_EDITOR:
            this.set('isRowEditorModeOn', false);
            this.set('isColumnEditorModeOn', true);
            this.handleGridEditAction();
            break;

          case _moduleAction.default.RESET_ORDER:
            this.structureService.order.resetOrder(false, this.data, this.configurationService.device);
            break;

          case _moduleAction.default.RESET_ALL_DEVICE:
            this.structureService.order.resetOrder(true, this.data, this.configurationService.device);
            break;

          default:
            this._disableGridEditorMode();

        }
      },

      onMouseEnter() {
        this.mouseOver();
      },

      onMouseLeave() {
        this.mouseOut();
      }

    }
  });

  _exports.default = _default;
});