define("builder/pods/website/edit/page/edit/module-gallery/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bYnnSuMg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"moduleMenuActive\",\"base\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"page_modules\"],[8],[0,\"\\n\\t\\t\"],[5,\"module-gallery\",[],[[\"@moduleAction\",\"@addHistoryCheckpoint\",\"@data\",\"@iframeCSS\"],[[28,\"action\",[[23,0,[]],\"parentModuleAction\"],null],[28,\"action\",[[23,0,[]],\"addHistoryCheckpoint\"],null],[22,\"categories\"],[24,[\"pageEditController\",\"iframeCSS\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"moduleMenuActive\",\"system\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"system-modules\",[],[[\"@systemModules\",\"@cogClick\",\"@toggleModule\",\"@changeTheme\"],[[24,[\"model\",\"systemModules\"]],[28,\"action\",[[23,0,[]],\"cogClick\"],null],[28,\"action\",[[23,0,[]],\"toggleModule\"],null],[28,\"action\",[[23,0,[]],\"changeTheme\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/website/edit/page/edit/module-gallery/template.hbs"
    }
  });

  _exports.default = _default;
});