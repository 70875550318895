define("builder/core/froala/toolbar-style-dropdown", ["exports", "froala-editor"], function (_exports, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FROALA_TOOLBAR_STYLE_PREFERENCE = _exports.toolbarStyleEvent = _exports.dropdownToolbarStyleCommand = void 0;

  /* eslint-disable new-cap */
  const dropdownToolbarStyleCommand = 'toolbar-style-dropdown';
  _exports.dropdownToolbarStyleCommand = dropdownToolbarStyleCommand;
  const {
    Cookies
  } = window;
  const svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/><rect fill="none" height="24" width="24"/></g><g><path d="M19,12.87c0-0.47-0.34-0.85-0.8-0.98C16.93,11.54,16,10.38,16,9V4l1,0 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3c0,0.55,0.45,1,1,1l1,0v5c0,1.38-0.93,2.54-2.2,2.89 C5.34,12.02,5,12.4,5,12.87V13c0,0.55,0.45,1,1,1h4.98L11,21c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1l-0.02-7H18c0.55,0,1-0.45,1-1 V12.87z" fill-rule="evenodd"/></g></svg>';
  const iconTemplateName = 'icon_pin';
  const toolbarStyleEvent = 'toolbar-style-event';
  _exports.toolbarStyleEvent = toolbarStyleEvent;
  const FROALA_TOOLBAR_STYLE_PREFERENCE = 'paragraphEditorStyle';
  _exports.FROALA_TOOLBAR_STYLE_PREFERENCE = FROALA_TOOLBAR_STYLE_PREFERENCE;

  _froalaEditor.default.DefineIconTemplate(iconTemplateName, svgIcon);

  _froalaEditor.default.DefineIcon(dropdownToolbarStyleCommand, {
    NAME: 'pin',
    template: iconTemplateName
  });

  _froalaEditor.default.RegisterCommand(dropdownToolbarStyleCommand, {
    title: 'Toolbar Style',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: {
      inline: 'Inline',
      scroll: 'Scroll'
    },

    callback(cmd, val) {
      this.events.trigger(toolbarStyleEvent, [val]);
      Cookies.set(FROALA_TOOLBAR_STYLE_PREFERENCE, val);
    },

    refreshOnShow(btn, dropdown) {
      const pref = Cookies.get(FROALA_TOOLBAR_STYLE_PREFERENCE);

      if (!pref) {
        return;
      }

      const selectedItem = dropdown.find(`[data-param1="${pref}"]`);

      if (!selectedItem) {
        return;
      }

      selectedItem.addClass('fr-active');
    }

  });
});