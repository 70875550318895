define("builder/core/styles-management/widget-section/variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Default variable values for section styles functionality.
   * This variables values can be changed in UI.
   */
  var _default = {
    lg: [{
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'margin-top',
      value: '0px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '0px'
    }, {
      name: 'background-position',
      value: 'left top'
    }, {
      name: 'background-size',
      value: 'auto'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }],
    md: [{
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'margin-top',
      value: '0px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '0px'
    }, {
      name: 'background-position',
      value: 'left top'
    }, {
      name: 'background-size',
      value: 'auto'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }],
    sm: [{
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'margin-top',
      value: '0px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '0px'
    }, {
      name: 'background-position',
      value: 'left top'
    }, {
      name: 'background-size',
      value: 'auto'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }],
    xs: [{
      name: 'padding-top',
      value: '10px'
    }, {
      name: 'padding-left',
      value: '10px'
    }, {
      name: 'padding-right',
      value: '10px'
    }, {
      name: 'padding-bottom',
      value: '10px'
    }, {
      name: 'margin-top',
      value: '0px'
    }, {
      name: 'margin-left',
      value: '0px'
    }, {
      name: 'margin-right',
      value: '0px'
    }, {
      name: 'margin-bottom',
      value: '0px'
    }, {
      name: 'background-position',
      value: 'left top'
    }, {
      name: 'background-size',
      value: 'auto'
    }, {
      name: 'border-color',
      value: ''
    }, {
      name: 'border-width',
      value: '1px'
    }, {
      name: 'border-style',
      value: 'solid'
    }, {
      name: 'border-radius',
      value: '0px'
    }],
    global: [{
      name: 'background-color',
      value: 'inherit'
    }, {
      name: 'background-gradient-direction',
      value: 'to bottom'
    }, {
      name: 'background-gradient-color1',
      value: 'rgba(0, 0, 0, 0)'
    }, {
      name: 'background-gradient-color2',
      value: 'rgba(0, 0, 0, 0)'
    }, {
      name: 'background-attachment',
      value: 'scroll'
    }, {
      name: 'background-image',
      value: 'none'
    }, {
      name: 'background-repeat',
      value: 'repeat'
    }, {
      name: 'contentPosition',
      value: 'fixed-position'
    }]
  };
  _exports.default = _default;
});