define("builder/pods/components/widget-header/component", ["exports", "builder/pods/components/grid-base-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritDoc
     */
    classNames: ['widget-header'],

    /**
     * @inheritDoc
     */
    tagName: 'header',

    /**
     * Main row class
     * @type {string}
     */
    gridMainRowClass: 'js-row',

    /**
     * Property contain label for sticker
     * @type {string}
     * @readOnly
     */
    label: Ember.computed('isLocked', function () {
      return this.isLocked ? 'Header, click to edit' : 'HEADER';
    }).readOnly(),

    /**
     * @inheritDoc
     */
    init(...args) {
      this._super(...args); // Setup default state and encapsulate state for "content-blocker" component.
      // We have only one property to control lock/unlock layout,
      // that why we need "isLocked" property to separate "header" and "footer" unlock individually


      this.set('isLocked', !this.data.get('droppable'));
    },

    /**
     * Unlock layout
     * @return {void}
     * @abstract
     */
    unlockLayout() {
      throw new Ember.Error('widget-header: should pass unlockLayout method');
    },

    actions: {
      /**
       * Action to unlock header
       */
      unlockLayout() {
        this.set('isLocked', false);
        this.unlockLayout(this.data);
      }

    }
  });

  _exports.default = _default;
});