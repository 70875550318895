define("builder/pods/components/widget-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DtiExKJ2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isDraggable\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"widget-popup__handle\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"hasCloseHandler\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-times widget-popup__close\"],[3,\"action\",[[23,0,[]],\"closePopup\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/widget-popup/template.hbs"
    }
  });

  _exports.default = _default;
});