define("builder/pods/components/gallery-module/component", ["exports", "builder/pods/components/gallery-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * classes to be added to ember wrapper
     * @type {string[]}
     */
    classNames: ['__module'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['draggable:__draggable:__clickable'],

    /**
     * number of themes that belongs to a module
     * @type {number}
     * @readonly
     */
    themesCount: Ember.computed('data.children.[]', function () {
      const children = this.get('data.children') || [];
      return children.length;
    }).readOnly(),

    /**
     * @inheritdoc
     */
    draggable: Ember.computed('themesCount', function () {
      return this.themesCount === 1;
    }),

    /**
     * is themes count is greater than 0
     * @type {boolean}
     * @readonly
     */
    hasThemes: Ember.computed.bool('themesCount').readOnly(),

    /**
     * image src
     * @type {string}
     * @readonly
     */
    image: Ember.computed.readOnly('data.image'),

    /**
     * style css for display thumbnail of item
     * @type {string}
     * @readonly
     */
    thumbnailCss: Ember.computed('image', function () {
      return Ember.String.htmlSafe(`background-image: url(${this.image})`);
    }).readOnly(),

    /**
     * theme title
     * @type {string}
     * @readonly
     */
    title: Ember.computed.readOnly('data.title'),

    /**
     * @inheritdoc
     */
    _prepareDragElement(dragElement, ...args) {
      this._super(dragElement, ...args); // Remove themes count


      dragElement.find('.page_modules_list_item-count').remove();
    }

  });

  _exports.default = _default;
});