define("builder/pods/components/field-checkboxlist/component", ["exports", "builder/mixins/field-validation", "builder/mixins/field-visibility", "jquery", "ember-copy"], function (_exports, _fieldValidation, _fieldVisibility, _jquery, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldValidation.default, _fieldVisibility.default, {
    /**
     * @inheritDoc
     */
    classNames: ['bld-form_checkboxlist'],

    /**
     * @property {Object} modifiers - Column class name modifiers
     */
    modifiers: {
      1: '__one',
      2: '__two',
      3: '__three',
      4: '__four'
    },

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      if (typeof this.field.columns === 'undefined') {
        Ember.set(this.field, 'columns', 1);
      }
    },

    /**
     * @inheritdoc
     */
    willInsertElement() {
      this.applyValidations();
    },

    /**
     * @inheritdoc
     */
    didInsertElement() {
      const checkboxes = this.$('.bld-form_checkboxlist_checkbox');
      const columns = this.getColumns();
      columns.forEach(range => this._wrapElements(checkboxes.slice(...range)));

      this._handleCheckbox();
    },

    /**
     * @property {Array} choicesArray - Array of checkboxes objects
     */
    choicesArray: Ember.computed('field.choices', function () {
      const fieldName = this.get('field.name');
      const fieldData = this.get('field.data');
      const choices = this.get('field.choices') || [];
      return choices.map(choice => ({
        id: fieldName + choice.value + Date.now(),
        value: choice.value,
        label: choice.label,
        checked: Array.isArray(fieldData) && fieldData.indexOf(choice.value) > -1,
        disabled: !!choice._disabled
      }));
    }),

    /**
     * Returns columns range
     * @returns {Array[]} - Array of columns range
     */
    getColumns() {
      const columns = this.get('field.columns');
      let end = 0;
      const results = [];
      let checkboxCount = this.choicesArray.length;
      let beginning;

      if (checkboxCount < columns) {
        checkboxCount = columns;
      }

      const inColumn = checkboxCount / columns;
      const max = Math.ceil(inColumn);
      const min = Math.floor(inColumn);

      for (let i = 0; i < columns; i++) {
        beginning = end;
        end += (checkboxCount - max) / (columns - (i + 1)) >= 1 ? max : min;
        checkboxCount -= end;
        results.push([beginning, end]);
      }

      return results;
    },

    /**
     * Notify change handler
     * @param {*} data - new data that has been set
     */
    triggerChange(data) {
      const action = this.actionHandler;
      this.set('field.data', data);

      if (action && !this.notValid) {
        action(data, this.get('field.name'));
      }
    },

    /**
     * Handle click on checkbox
     * @private
     */
    _handleCheckbox() {
      const choice = this;
      choice.$(':checkbox').on('click', function () {
        const checkbox = (0, _jquery.default)(this);
        const value = checkbox.val();
        let data = (0, _emberCopy.copy)(choice.get('field.data'));

        if (!data) {
          data = [];
        }

        if (checkbox.is(':checked')) {
          data.push(value);
        } else {
          const position = data.indexOf(value);
          data.splice(position, 1);
        }

        choice.triggerChange(data);
      });
    },

    /**
     * Wraps passed jquery elements
     * @param {jQuery} elements - Elements need to be wrapped with column element
     * @private
     */
    _wrapElements(elements) {
      elements.wrapAll(`<div class="bld-form_checkboxlist_column ${this.modifiers[this.get('field.columns')]}"></div>`);
    }

  });

  _exports.default = _default;
});