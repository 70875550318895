define("builder/core/copy-menu-management-config", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._copy = _copy;

  function _copy(item) {
    const copy = {};
    const excludedProps = ['component', 'parent'];
    const configClass = Ember.Object.extend(_emberCopy.Copyable, {
      copy() {
        return _copy(this);
      }

    }); // Not a deep copy, since we dont have any nested configs, for now

    if (item) {
      Object.keys(item).forEach(key => {
        if (!excludedProps.includes(key) && !(item[key] instanceof Ember.ComputedProperty)) {
          copy[key] = item[key];
        }
      });
    }

    return configClass.create(copy);
  }
});