define("builder/core/froala/links/link-add-button", ["exports", "froala-editor"], function (_exports, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkAddEvent = _exports.linkAddCommand = void 0;

  /* eslint-disable new-cap */
  const linkAddCommand = 'link-add';
  _exports.linkAddCommand = linkAddCommand;
  const linkAddEvent = 'link-add-event';
  _exports.linkAddEvent = linkAddEvent;

  _froalaEditor.default.DefineIcon(linkAddCommand, {
    NAME: 'link',
    SVG_KEY: 'insertLink'
  });

  _froalaEditor.default.RegisterCommand(linkAddCommand, {
    title: 'Insert Link',
    focus: false,
    undo: false,
    refreshAfterCallback: false,

    callback() {
      const selectedText = this.selection.text();
      this.events.trigger(linkAddEvent, [selectedText]);
    }

  });
});