define("builder/core/form/normalizer-structure", ["exports", "builder/core/enumerators/container", "ember-copy", "builder/core/enumerators/form", "builder/core/enumerators/buttons", "builder/core/form/is-field-removable", "builder/core/enumerators/widget"], function (_exports, _container, _emberCopy, _form, _buttons, _isFieldRemovable, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Creates form field view model from form field structure
   * @param {FormFieldStructure} structure - structure to map
   * @param {string[]} websiteGroups - website groups array, param needs for field rules map
   * @param {string} formType - form specific type, param needs for field rules map
   * @returns {FormFieldViewModel} - structure suitable for view
   */
  function _default(structure, websiteGroups, formType) {
    const data = (0, _emberCopy.copy)(structure);
    const defaultViewModel = getDefaultViewModel(data.type, data.name, websiteGroups, formType);

    if (data.children) {
      delete data.children;
    }

    if (data.hiddenFields) {
      delete data.hiddenFields;
    }

    return { ...defaultViewModel,
      controls: {
        edit: defaultViewModel.editable,
        remove: defaultViewModel.removable
      },
      data
    };
  }
  /**
   * Dictionary, which contains default mappings to {FormFieldViewModel} by type
   * @type {Object.<string, FormFieldViewModel}
   */


  const fieldTypeToViewModelMap = {
    form: {
      wrapperClass: 'form-wrapper',
      type: 'form',
      editable: false,
      removable: false,
      draggable: false,
      droppable: true,
      useMask: false,
      wrap: false
    },
    [_form.MULTISTEP]: {
      wrapperClass: 'form-wrapper',
      type: 'form',
      editable: false,
      removable: false,
      draggable: false,
      droppable: true,
      useMask: false,
      wrap: false
    },
    [_form.STEP]: {
      wrapperClass: 'form-wrapper',
      type: 'form',
      editable: false,
      removable: false,
      draggable: false,
      droppable: true,
      useMask: false,
      wrap: false
    },
    'form-fieldset': {
      wrapperClass: '',
      type: 'fieldset',
      wrapperTagName: 'fieldset',
      editable: true,
      removable: true,
      draggable: true,
      droppable: true,
      useMask: false,
      wrap: true
    },
    [_container.default.WIDGET_ROW]: {
      wrapperClass: '',
      type: 'row',
      editable: false,
      removable: false,
      draggable: false,
      droppable: true,
      useMask: false,
      wrap: false
    },
    [_container.default.WIDGET_COLUMN]: {
      wrapperClass: '',
      type: 'column',
      editable: false,
      removable: false,
      draggable: false,
      droppable: true,
      useMask: false,
      wrap: false
    },
    [_widget.WidgetType.WIDGET_TAB_PANE]: {
      wrapperClass: '',
      type: 'tab-pane',
      editable: false,
      removable: false,
      draggable: false,
      droppable: true,
      useMask: false,
      wrap: false
    },
    [_buttons.FORM_SUBMIT]: {
      wrapperClass: 'btn button-wrapper',
      type: _buttons.FORM_SUBMIT,
      editable: true,
      removable: false,
      draggable: false,
      droppable: false,
      useMask: true,
      wrap: true
    },
    [_buttons.FORM_NEXT]: {
      wrapperClass: 'btn button-wrapper',
      type: _buttons.FORM_NEXT,
      editable: true,
      removable: false,
      draggable: false,
      droppable: false,
      useMask: true,
      wrap: true
    },
    [_buttons.FORM_PREV]: {
      wrapperClass: 'btn button-wrapper',
      type: _buttons.FORM_PREV,
      editable: true,
      removable: false,
      draggable: false,
      droppable: false,
      useMask: true,
      wrap: true
    },
    default: {
      wrapperClass: '',
      type: 'field',
      editable: true,
      removable: true,
      draggable: true,
      droppable: false,
      useMask: true,
      wrap: true
    }
  };
  /**
   * Gets view model by type
   * @param {string} type - config type
   * @param {string} name - form field name
   * @param {string[]} websiteGroups - website groups array, param needs for field rules map
   * @param {string} formType - form specific type, param needs for field rules map
   * @returns {FormFieldViewModel} - found view model
   */

  function getDefaultViewModel(type, name, websiteGroups, formType) {
    const configType = fieldTypeToViewModelMap.hasOwnProperty(type) ? type : 'default';
    return { ...fieldTypeToViewModelMap[configType],
      removable: (0, _isFieldRemovable.isFieldRemovable)(type, name, websiteGroups, formType)
    };
  }
});