define("builder/pods/components/form-module/column-drop-container", ["exports", "builder/pods/components/form-module/drop-container"], function (_exports, _dropContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Rule set for column-drop-container
   */
  var _default = _dropContainer.default.extend({
    /**
     * @property {Boolean} - monkey patch for ember since 2.10 for not really render to DOM Ember.Component-s
     */
    renderer: true,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('allowed', ['field', 'fieldset']);
    },

    /**
     * @inheritdoc
     */
    dropAllowed(column, draggable, anchor) {
      if (this.allowed.indexOf(draggable.get('type')) === -1) {
        return false;
      }

      if (draggable === anchor) {
        return false;
      }

      if (column.get('children').length) {
        return false;
      }

      return true;
    },

    /**
     * @inheritdoc
     */
    getDropPosition() {
      return {
        position: 'bottom',
        distance: 0
      };
    },

    /**
     * @inheritdoc
     */
    drop(column, draggable) {
      column.addChildAt(draggable, 0);
    }

  });

  _exports.default = _default;
});