define("builder/core/enumerators/module-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Common actions used for interact with user via UI
   * For example these used in module-buttons component
   */
  var _default = {
    EDIT: 'edit',
    CONFIGURE: 'configure',
    COPY: 'copy',
    DELETE: 'delete',
    MOVE_UP: 'move-up',
    MOVE_DOWN: 'move-down',
    LOADED: 'loaded',
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
    CREATE_CONTAINER_TEMPLATE: 'create-container-template',
    EDIT_CONTAINER_TEMPLATE: 'edit-container-template',
    DELETE_CONTAINER_TEMPLATE: 'delete-container-template',
    DELETE_FORM_TEMPLATE: 'delete-form-template',
    TRANSITION_TO_PAGE: 'transition-to-page',
    JUMP_TO_CONTAINER: 'jump-to-container',
    JUMP_TO_MODULE: 'jump-to-module',
    JUMP_TO_SECTION: 'jump-to-section',
    JUMP_TO_SECTION_MODULE: 'jump-to-section-module',
    ROW_EDITOR: 'row-editor',
    COLUMN_EDITOR: 'column-editor',
    RESET_ORDER: 'reset-order',
    RESET_ALL_DEVICE: 'reset-all-device'
  };
  _exports.default = _default;
});