define("builder/helpers/sm-component-resolver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSMComponent = getSMComponent;
  _exports.default = void 0;

  /**
   * Return component name according provided property name or css name.
   *
   * @param {object} component
   * @param {string} [Object.name] - used when you need to paint structure component(tabs, sections and etc)
   * @param {string} [Object.cssPropertyName] - used when you need paint component which control variable value
   * @returns {string}
   */
  function getSMComponent([component]) {
    // Mapped components on passed {name} and {cssPropertyName}
    const compMapping = {
      'sm-color': ['color', 'border-color', 'background-color', 'gradient-color1', 'gradient-color2', 'background'],
      'sm-select': ['font-style', 'font-weight', 'text-decoration', 'text-transform', 'border-style', 'gradient-direction', 'background-repeat', 'background-position', 'background-attachment', 'background-size', 'parallax-scrolling', 'container-width', 'container-content-width', 'fullHeight', 'content-position', 'text-align', 'justify-content'],
      'sm-select-font': ['font-family'],
      'sm-select-icon': ['icons-font'],
      'sm-group': ['group'],
      'sm-info-block': ['info-block'],
      'sm-range-slider': ['font-size', 'padding-top', 'padding-right', 'padding-bottom', 'padding-left', 'margin-top', 'margin-right', 'margin-bottom', 'margin-left', 'border-width', 'border-radius', 'line-height', 'min-height', 'height', 'max-height', 'min-width', 'width', 'max-width', 'letter-spacing'],
      'sm-tabs': ['conjunction'],
      'sm-image': ['background-image'],
      'sm-composite': ['composite']
    };
    const type = component.name || component.cssPropertyName;
    const resolvedComponentName = Object.keys(compMapping).find(componentName => compMapping[componentName].includes(type));

    if (resolvedComponentName) {
      return resolvedComponentName;
    }

    throw new Ember.Error(`Component was not found for mapped type -> ${type}`);
  }
  /**
   * Return component name by mapped type.
   */


  var _default = Ember.Helper.extend({
    /**
     * @inheritdoc
     */
    compute(param) {
      return getSMComponent(param);
    }

  });

  _exports.default = _default;
});