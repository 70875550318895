define("builder/mixins/field-validation", ["exports", "builder/core/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ['useErrorClass:bld-form-error'],
    hasErrorClass: true,
    useErrorClass: Ember.computed('hasErrorClass', 'validationsMessage', function () {
      return this.hasErrorClass && this.validationsMessage;
    }),
    validationsMessage: Ember.computed('field.data', 'field.data.value', 'field.data.buttons.[]', 'validations.message', function () {
      return this.get('validations.message');
    }),
    notValid: Ember.computed('field.data', 'validations.isTruelyValid', function () {
      const {
        validations
      } = this;

      if (!validations) {
        return false;
      }

      const notValid = !validations.get('isTruelyValid');
      this.set('field.notValid', notValid);
      return notValid;
    }),

    /**
     * @property {Boolean} disableValidation - allow to control validation process manually.
     */
    disableValidation: false,

    /**
     * @property {Function|null} - function called after complete validation
     */
    validationHandler: null,

    /**
     * @property {Ember.observer} _useErrorClassObserver - observe change property "useErrorClass" for call validationHandler
     * @private
     */
    _useErrorClassObserver: Ember.on('init', Ember.observer('useErrorClass', function () {
      if (typeof this.validationHandler === 'function') {
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.validationHandler(this, !this.useErrorClass);
        });
      }
    })),

    checkValidation() {
      return !!this.get('field.validation') || typeof this.localValidation !== 'undefined';
    },

    // Apply validation mixin
    applyValidations(dataProperty = 'field.data', validationProperty = 'field.validation', hasErrorClass = true) {
      if (this.checkValidation()) {
        new _validation.default(dataProperty, this.get(validationProperty)).apply(this);
      }

      if (hasErrorClass === false) {
        this.set('hasErrorClass', hasErrorClass);
      }
    }

  });

  _exports.default = _default;
});