define("builder/pods/components/field-media-video-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjbalBbd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"field\",\"data\",\"type\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"a\",true],[10,\"class\",\"media-title\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[11,\"href\",[28,\"get-absolute-image-url\",[[24,[\"field\",\"data\",\"url\"]]],null]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"icon\"]]]],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"media-title_label\"],[11,\"title\",[22,\"fileName\"]],[8],[1,[22,\"fileName\"],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"span\",true],[10,\"class\",\"media-controls\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"fa fa-refresh\"],[3,\"action\",[[23,0,[]],\"open\"]],[8],[9],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"media-controls_close\"],[3,\"action\",[[23,0,[]],\"delete\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"span\",false],[12,\"class\",\"add-new\"],[3,\"action\",[[23,0,[]],\"open\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus-circle\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"add-new_title\"],[8],[0,\"Add New\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-media-video-document/template.hbs"
    }
  });

  _exports.default = _default;
});