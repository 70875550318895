define("builder/pods/components/undo-redo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritDoc
     */
    classNames: ['undo-redo'],

    /**
     * @property {Boolean} undoIsDisabled - undo button is disabled
     */
    undoIsDisabled: true,

    /**
     * @property {Boolean} redoIsDisabled - redo button is disabled
     */
    redoIsDisabled: true,

    /**
     * Undo action method
     */
    undoAction() {
      throw new Ember.Error('undoAction method must be overwritten');
    },

    /**
     * Redo action method
     */
    redoAction() {
      throw new Ember.Error('redoAction method must be overwritten');
    },

    actions: {
      undo() {
        if (!this.undoIsDisabled && typeof this.undoAction === 'function') {
          this.undoAction();
        }
      },

      redo() {
        if (!this.redoIsDisabled && typeof this.redoAction === 'function') {
          this.redoAction();
        }
      }

    }
  });

  _exports.default = _default;
});