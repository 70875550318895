define("builder/models/page-module", ["exports", "@ember-data/model", "builder/models/model", "ember-copy"], function (_exports, _model, _model2, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PageModuleModel
   */
  var _default = _model2.default.extend(_emberCopy.Copyable, {
    /**
     * @property {String} PageId - page id
     */
    PageId: (0, _model.attr)('string'),

    /**
     * @property {String} ThemeId - theme id
     */
    ThemeId: (0, _model.attr)('string'),

    /**
     * @property {Object} Structure - data of fields
     */
    Structure: (0, _model.attr)('raw'),

    /**
     * @property {String} ModuleId - module id
     */
    ModuleId: (0, _model.attr)('string'),

    /**
     * @property {Object} Strings - key-value storage for string used in module
     */
    Strings: (0, _model.attr)('raw'),

    /**
     * We don't need copy for now
     *
     * @returns {null}
     */
    copy() {
      return null;
    }

  });

  _exports.default = _default;
});