define("builder/pods/components/form-variant/component", ["exports", "builder/core/enumerators/fields", "builder/core/util/define-fields-visibility"], function (_exports, _fields, _defineFieldsVisibility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @inheritdoc
     */
    classNames: ['bld-form-variant', '__draggable'],

    /**
     * @inheritdoc
     */
    classNameBindings: ['isNew:bld-form-variant_new', 'isEdit:__edit', 'isExpanded:bld-form-variant_expanded:bld-form-variant_collapsed', 'isInvalid:bld-form-variant_invalid'],

    /**
     * Full array of module fields with their paths
     * @type {FieldConfigurationNode[]}
     */
    variantConfigurationFlatMap: [],

    /**
     * Main application service which hold some global states
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * @property {Boolean} isInvalid - Indicate what this item is has fields with invalid data
     * @readonly
     */
    isInvalid: Ember.computed.readOnly('content.isInvalid'),

    /**
     * @property {Boolean} isNew - Indicate what this item is new in parent list
     * @readonly
     */
    isNew: Ember.computed.readOnly('content.isNew'),

    /**
     * @property {Boolean} isExpanded - Indicate what item content collapsed/expanded
     * @readonly
     */
    isExpanded: Ember.computed.or('content.isOpened', 'content.isNew').readOnly(),

    /**
     * @property {Function} inputHandler - input handler to be passed to components inside
     */
    inputHandler: null,

    /**
     * @type {Object} - returns an object with info of schedule status
     */
    scheduleStatus: Ember.computed('content.localData.displayRangeStart', 'content.localData.displayRangeEnd', function () {
      var _this$content;

      const {
        displayRangeStart: start,
        displayRangeEnd: end
      } = ((_this$content = this.content) === null || _this$content === void 0 ? void 0 : _this$content.localData) || {};
      const startValue = start === null || start === void 0 ? void 0 : start.value;
      const endValue = end === null || end === void 0 ? void 0 : end.value;

      if (!startValue || !endValue) {
        return null;
      }

      const startDate = new Date(startValue);
      const endDate = new Date(endValue);
      const startDateString = this.getDateTimeString(startDate);
      const endDateString = this.getDateTimeString(endDate);
      const currentDate = new Date();
      const status = {
        color: 'green',
        tooltipText: `<b>Active till:</b> <br/>${endDateString}`
      };

      if (startDate > currentDate) {
        status.color = 'orange';
        status.tooltipText = `<b>Scheduled for:</b> <br/>${startDateString} <br/><br/> <b>Expires on:</b> <br/>${endDateString}`;
      }

      if (currentDate > endDate) {
        status.color = 'red';
        status.tooltipText = `<b>Expired on:</b> <br/>${endDateString}`;
      }

      return status;
    }),

    /**
     * get formatted date and time string
     * @param {Date} date - Date object
     */
    getDateTimeString(date) {
      return date.toLocaleString('en', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).toUpperCase().replace(',', ' at ');
    },

    /**
     * @property {String} tooltipText - text for tooltip
     * @readonly
     */
    tooltipText: Ember.computed('isInvalid', function () {
      return this.isInvalid ? this.get('literals.FORM_VARIANT_ERROR') : null;
    }).readOnly(),

    /**
     * @property {Boolean} isEnabled - check config enabled/disabled
     * @readonly
     */
    isEnabled: Ember.computed('content.children.@each.data', function () {
      if (!this.content.hasOwnProperty('children')) {
        return true;
      }

      const enabledProps = this.content.children.find(item => item.name === 'enabled');

      if (!enabledProps) {
        return true;
      } // Expected enabledPros.data should be string but getting as '1' | 1 | 'on'. Parsing to integer to catch both cases


      return parseInt(enabledProps.data, 10) === 1 || enabledProps.data === 'on';
    }).readOnly(),

    /**
     * @property {Boolean} isRemoveEnabled - Indicate what this item can be removed
     */
    isRemoveEnabled: Ember.computed('content.isRemoveEnabled', function () {
      var _this$content$isRemov, _this$content2;

      return (_this$content$isRemov = (_this$content2 = this.content) === null || _this$content2 === void 0 ? void 0 : _this$content2.isRemoveEnabled) !== null && _this$content$isRemov !== void 0 ? _this$content$isRemov : true;
    }),

    /**
     * @property {Object|null} _availableValidations - hash contains pais "field name"-"validation result" for all children fields
     * @private
     */
    _availableValidations: null,

    /**
     * @inheritdoc
     */
    didReceiveAttrs() {
      const {
        children
      } = this.content;

      this._addPathToChildren(children);

      if (this.isNew) {
        // Set isOpened to true because it could be false but variant is opened because of isNew
        this.set('content.isOpened', true);
      }

      this._availableValidations = {};

      if (children && children.length) {
        for (let i = 0, len = children.length; i < len; i++) {
          if (children[i].hasOwnProperty('validation')) {
            this._availableValidations[children[i].name] = true;
          }
        }
      }

      (0, _defineFieldsVisibility.defineFieldsVisibility)(children);
      const configFlatMap = this.configurationService.getFieldsConfigFlatMap(children);
      this.set('variantConfigurationFlatMap', configFlatMap);
    },

    /**
     * Adds path property to each field configuration
     * This property is essential for calculating fields visibility and
     * dropdown items visibility
     * @param {FieldConfiguration[]} children - array of fields
     * @param {string} basePath - base path for field configuration
     */
    _addPathToChildren(children, basePath = '') {
      if (!children || !children.length) {
        return;
      }

      children.forEach(field => {
        if (field.type === _fields.default.CONTAINER) {
          const path = `${basePath}/${field.name}`;

          this._addPathToChildren(field.children, path);

          return;
        }

        field.path = basePath;
      });
    },

    /**
     * Sets value on variant fields flat map
     * We need this method to trigger watchers on value change.
     * @param {unknown} fieldValue - field value
     * @param {string} [basePath='/'] - base path for field within configuration
     */
    _setMapValue(fieldValue, basePath = '/') {
      if (fieldValue && typeof fieldValue === 'object') {
        Object.keys(fieldValue).forEach(name => {
          const path = `${basePath}/${name}`;

          this._setMapValue(fieldValue[name], path);
        });
      }

      const configurationNode = this.variantConfigurationFlatMap.find(node => node.path === basePath);

      if (!configurationNode) {
        return;
      }

      Ember.set(configurationNode, 'value', fieldValue);
    },

    actions: {
      remove() {
        if (typeof this.removeVariant === 'function') {
          this.removeVariant(this.content);
        }
      },

      toggle() {
        if (typeof this.toggleVariant === 'function' && this.get('content.children.length')) {
          this.toggleVariant(this.content);
        }
      },

      inputHandler(...rest) {
        if (typeof this.inputHandler === 'function') {
          this.inputHandler(...rest);
        }
      },

      childrenHandler(...rest) {
        const [, fieldValue, fieldName] = rest;
        const basePath = `/${fieldName}`;

        this._setMapValue(fieldValue, basePath);

        if (typeof this.actionHandler === 'function') {
          this.actionHandler(...rest);
          (0, _defineFieldsVisibility.defineFieldsVisibility)(this.content.children);
        }
      },

      validationHandler(target, isValid) {
        const validations = this._availableValidations;
        const keys = Object.keys(validations);
        const fieldName = target.field.name;
        let variantIsValid = true;

        if (validations.hasOwnProperty(fieldName)) {
          validations[fieldName] = isValid;
        }

        for (let i = 0, len = keys.length; i < len; i++) {
          if (!validations[keys[i]]) {
            variantIsValid = false;
            break;
          }
        }

        if (this.isNew && !variantIsValid && !this.isExpanded) {
          this.send('toggle');
        }

        if (typeof this.validationHandler === 'function') {
          this.validationHandler(this.content, variantIsValid);
        }
      }

    }
  });

  _exports.default = _default;
});