define("builder/pods/components/button-publish-toolbar-right/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "54q+7IJ8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"error-sign\"],[8],[0,\"\\n\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\\t\\t\"],[7,\"use\",true],[10,\"xlink:href\",\"#icon-error-sign\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"i\",true],[10,\"class\",\"success-sign\"],[8],[0,\"\\n\\t\"],[7,\"svg\",true],[10,\"class\",\"svg-icon\"],[8],[0,\"\\n\\t\\t\"],[7,\"use\",true],[10,\"xlink:href\",\"#icon-success-sign\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"inProgress\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"progress-bar\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"progress-bar\",null,[[\"isVisible\"],[true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/button-publish-toolbar-right/template.hbs"
    }
  });

  _exports.default = _default;
});