define("builder/services/resizable", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ResizeService
   */
  var _default = Ember.Service.extend({
    /**
     * @property {Boolean} _resizeStarted - property to detect resize start
     */
    _resizeStarted: false,

    /**
     * @property {Number} _resizeStep - default resize step
     */
    _resizeStep: 5,

    /**
     * @property {Number} _resizeMax - max resize value
     */
    _resizeMax: 600,

    /**
     * @property {String} _resizeProperty - default property value to set resize value to
     */
    _resizeProperty: 'margin-bottom',

    /**
     * @property {Ember.Object} _resizeItemToNotify - resize item to be notified when resize started and ended
     * (for now only used by widget-spacer), property "resizeInProgress" will be toggled
     */
    _resizeItemToNotify: null,

    /**
     * Applies resizable for column
     *
     * @method applyResizable
     * @param {Object} instance - Component instance
     * @param {Object} handler - Resize object
     */
    applyResizable(instance, handler) {
      const postfix = handler.get('_postfix');
      const resizableNode = instance === null || instance === void 0 ? void 0 : instance.getResizableNode();

      if (!resizableNode) {
        return;
      } // Just in case, remove previous listener


      resizableNode.off(`mousedown${postfix}`).on(`mousedown${postfix}`, event => {
        event.stopPropagation();
        this.set('_resizeStarted', false);

        this._mouseDown(instance, handler);
      });
    },

    /**
     * Sets resizable property for "resizable" elements in grid
     *
     * @method applyResizableElements
     * @param {Ember.Object} structure - page structure
     * @param {Object} handler - service to handler resize
     */
    applyResizableElements(structure, handler) {
      const children = structure.get('originObject').get('children') || [];

      if (structure.get('resizable')) {
        this.applyResizable(structure.get('component'), handler);
      }

      for (let i = 0, len = children.length; i < len; i++) {
        this.applyResizableElements(children[i], handler);
      }
    },

    /**
     * @inheritdoc
     */
    willDestroy() {
      this._removeListeners();
    },

    /**
     * Mouse down event handler
     *
     * @method mouseDown
     * @param {Object} instance - component instance
     * @param {Object} handler - handler
     * @private
     */
    _mouseDown(instance, handler) {
      const postfix = handler.get('_postfix');
      const data = instance.get('data');
      instance.showResizeHandler();
      (0, _jquery.default)('body').on(`mousemove${postfix}`, Ember.run.bind(this, function (event) {
        event.preventDefault();

        if (!this._resizeStarted) {
          this._onResizeStart(event, data, handler);

          this.set('_resizeStarted', true);
        }

        this._mouseMove(event, data, handler);
      }));
      (0, _jquery.default)(window).on(`mouseup${postfix}`, Ember.run.bind(this, function () {
        this._mouseUp(data, handler, instance);
      }));
    },

    /**
     * Mouse move event handler
     *
     * @method mouseMove
     * @param {jQuery.Event} event - jQuery event object
     * @param {Object} component - component to be handled
     * @param {Object} handler - handler
     */
    _mouseMove(event, component, handler) {
      handler.onResize(component, event.pageX, event.pageY, component.component.get('resizeMin'));
    },

    /**
     * Mouse up event handler
     *
     * @method _mouseUp
     * @param {Object} component - Component object
     * @param {Object} handler - Resize handler
     * @param {Object} instance - Component instance
     * @private
     */
    _mouseUp(component, handler, instance) {
      const resizeItemToNotify = this._resizeItemToNotify;

      this._removeListeners(handler);

      instance.hideResizeHandler();

      if (resizeItemToNotify) {
        resizeItemToNotify.set('resizeInProgress', false);
      }

      handler.onResizeEnd(component); // Trigger event for recalculate widget-container height

      (0, _jquery.default)(document).trigger('changeStructure');
    },

    /**
     * Removes resizable event listeners from body
     *
     * @method _removeListeners
     * @param {Object} [handler] - resize handler
     * @private
     */
    _removeListeners(handler) {
      let postfix = '';

      if (handler) {
        postfix = handler.get('_postfix');
      }

      (0, _jquery.default)('body').off(`mousemove${postfix} mouseleave${postfix} mouseup${postfix}`);
      (0, _jquery.default)(window).off(`mouseup${postfix}`);
    },

    /**
     * Resize start hook
     *
     * @param {jQuery.Event} event - event
     * @param {Ember.Object} component - component to resize
     * @param {Object} handler - callbacks container
     * @private
     */
    _onResizeStart(event, component, handler) {
      const resizeComponent = component.component;
      const children = component.get('originObject.children');
      let resizeItemToNotify = resizeComponent.get('resizeInProgress') !== undefined ? resizeComponent : null; // Notify all children, maybe? Maybe do it more "correct". Ok, will do ASAP

      if (children && children.get('length') === 1 && (children.objectAt(0).component.hasOwnProperty('resizeInProgress') || children.objectAt(0).component.resizeInProgress !== undefined)) {
        resizeItemToNotify = children.objectAt(0).component;
      }

      if (resizeItemToNotify) {
        resizeItemToNotify.set('resizeInProgress', true);
        this.set('_resizeItemToNotify', resizeItemToNotify);
      }

      handler.onResizeStart(component, event.pageX, event.pageY, resizeComponent.get('resizeProperty') || this._resizeProperty, resizeComponent.get('resizeStep') || this._resizeStep, typeof resizeComponent.get('resizeMax') === 'number' ? resizeComponent.get('resizeMax') : this._resizeMax);
    }

  });

  _exports.default = _default;
});