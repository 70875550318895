define("builder/pods/components/widget-spacer/component", ["exports", "builder/core/widget", "builder/core/enumerators/module-action"], function (_exports, _widget, _moduleAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetSpacer
   */
  var _default = _widget.default.extend({
    /**
     * @inheritdoc
     */
    classNames: ['page_module', 'page_module__border-dashed'],

    /**
     * @property {Boolean} resizeInProgress - is component should be notified when resize started
     */
    resizeInProgress: false,

    /**
     * @property {String} resizeProperty - property to be consumed by resizable service, which style to update on rese
     */
    resizeProperty: 'height',

    /**
     * @property {Number} resizeStep - min resize step that is allowed when resizing
     */
    resizeStep: 1,

    /**
     * @property {Number} resizeMin - minimal value allowed to be set as value for {@link WidgetSpacer#resizeProperty}
     */
    resizeMin: 30,

    /**
     * @property {Number} resizeMax - maximum value allowed to be set as value for {@link WidgetSpacer#resizeProperty}
     */
    resizeMax: 0,

    /**
     * @property {Number} displayHeight - height value of current widget
     * @readonly
     */
    displayHeight: Ember.computed('data.originObject.styles.{lg,md,sm,xs}.@each.value', 'mode', function () {
      const height = this.get(`data.originObject.styles.${this.mode}`).findBy('name', 'height');
      return parseInt(Ember.get(height, 'value'), 10);
    }).readOnly(),

    /**
     * @property {Number} displayWidth - width value of parent column element(1-12)
     * @readonly
     */
    displayWidth: Ember.computed('data.parent.originObject.size.{lg,md,sm,xs}', 'mode', function () {
      return this.get(`data.parent.originObject.size.${this.mode}`);
    }).readOnly(),

    /**
     * @inheritdoc
     */
    didInsertElement(...args) {
      this._super(...args);

      this.initializeResizeObserver();
    },

    /**
     * Double click handler
     *
     * @method doubleClick
     * @returns {Boolean}
     */
    doubleClick(...args) {
      this.moduleAction(_moduleAction.default.EDIT, this.data);
      return this._super(...args);
    },

    /**
     * Activate module
     *
     * @method click
     * @returns {Boolean}
     */
    click(...args) {
      this.moduleAction(_moduleAction.default.ACTIVATE, this.data);
      return this._super(...args);
    }

  });

  _exports.default = _default;
});