define("builder/widgets/data-setters/widget-paragraph", ["exports", "builder/core/enumerators/breakpoint-modes"], function (_exports, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetButtonDataSetter
   */
  var _default = Ember.Object.create({
    /**
     * Set data to widget(originObject)
     *
     * @param {EmberObject} component - originObject where value should be set
     * @param {string} name - name of property to set
     * @param {*} value - value to set
     * @returns {EmberObject} - component back
     */
    setData(component, name, value) {
      if (name === 'visibleOnDevices') {
        [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
          Ember.set(component, `visibleOnDevices.${mode}`, value.includes(mode));
        });
      } else {
        component.set(name, value);
      }

      return component;
    }

  });

  _exports.default = _default;
});