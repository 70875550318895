define("builder/pods/components/field-media-active-area-image/component", ["exports", "ember-copy", "builder/pods/components/field-media-active-area/component"], function (_exports, _emberCopy, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FieldMediaActiveAreaImage
   */
  var _default = _component.default.extend({
    /**
     * @property {String} iconClass - icon to be used for "choose different image" button
     */
    iconClass: 'fa fa-file-image-o',

    /**
     * @property {Number} min - minimal allowed value of slider to pick
     * @readonly
     */
    min: Ember.computed('field.data.min', function () {
      return this.get('field.data.min') || 1;
    }).readOnly(),

    /**
     * @property {Number} max - maximal value of slider to pick
     * @readonly
     */
    max: Ember.computed(function () {
      return 100;
    }).readOnly(),

    /**
     * @property {Number} width - natural width of an image
     * @readonly
     */
    width: Ember.computed.readOnly('field.data.naturalWidth'),

    /**
     * @property {Number} height - natural height of an image
     * @readonly
     */
    height: Ember.computed.readOnly('field.data.naturalHeight'),

    /**
     * @property {String} url - relative url to an image
     * @readonly
     */
    url: Ember.computed.readOnly('field.data.url'),

    /**
     * @property {Number} focalX - focal point x coordinate in percents
     * @readonly
     */
    focalX: Ember.computed.readOnly('field.data.left'),

    /**
     * @property {Number} focalY - focal point Y coordinate in percents
     * @readonly
     */
    focalY: Ember.computed.readOnly('field.data.top'),

    /**
     * @property {Number} level - shrink ratio of image in percents, from it's original size
     * @readonly
     */
    level: Ember.computed('field.data.zoomLevel', function () {
      const level = this.get('field.data.zoomLevel');
      return level || this.min;
    }).readOnly(),
    actions: {
      /**
       * Update zoom level
       *
       * @param {Number} level - zoom level to set
       */
      updateZoomLevel(level) {
        const data = (0, _emberCopy.copy)(this.get('field.data'), true);
        Ember.set(data, 'zoomLevel', level);
        this.sendData(data);
      },

      /**
       * Action invoked when marker changes it's position
       *
       * @param {Object} point - coordinate of marker center
       */
      updateFocalPointPosition(point) {
        const data = (0, _emberCopy.copy)(this.get('field.data'), true);

        if (!Ember.get(data, 'naturalHeight') && this.$('img').length) {
          Ember.set(data, 'naturalHeight', this.$('img').get(0).naturalHeight);
        }

        Ember.set(data, 'left', point.x);
        Ember.set(data, 'top', point.y);
        this.sendData(data);
      }

    }
  });

  _exports.default = _default;
});