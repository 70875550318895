define("builder/services/drop-highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * @property {jQuery|null} element - DOM element to highlight drop place
     */
    element: null,

    /**
     * @property {jQuery|null} hlElement - element highlighted
     */
    hlElement: null,

    /**
     * @property {jQuery|null} hlClassName - element class name that was added to highlight element
     */
    hlClassName: null,

    /**
     * @property {String} activeClassName - Predefined default highlight theme
     */
    defaultClassName: 'page-edit__highlight_theme-default',

    /**
     * @property {String} parentClassName - Predefined primary highlight theme
     */
    primaryClassName: 'page-edit__highlight_theme-primary',

    /**
     * @property {String} activeClassName - Active highlight DOM element class name
     */
    activeClassName: 'page-edit__highlight_active',

    /**
     * @property {String} customClassName - store custom class name
     */
    customClassName: null,

    /**
     * Set highlight element
     *
     * @method setHighlightElement
     * @param {jQuery} element - Highlight DOM element
     */
    setHighlightElement(element) {
      this.set('element', element);
    },

    /**
     * Highlights drop place
     *
     * @method highlight
     * @param {Object} styles - Object to decorate highlight element
     * @param {Boolean} primary - Different type of highlight
     * @param {String|null} className - if this parameter passed, this class name will be used as highlight theme class
     */
    highlight(styles, primary = false, className = null) {
      const {
        element
      } = this; // Clear previous height and width

      this.removeHighlight();
      this.set('customClassName', className);
      element.addClass(this.activeClassName).addClass(className || this._getThemeClassName(primary));
      element.css(styles);
    },

    /**
     * Highlight element, add passed in class name to element
     *
     * @param {jQuery} element - element to highlight
     * @param {String} className - class to add to element
     */
    highlightElement(element, className) {
      // Clear previous height and width
      this.removeHighlight();
      element.addClass(className);
      this.set('hlElement', element);
      this.set('hlClassName', className);
    },

    /**
     * Removes highlight
     *
     * @method removeHighlight
     */
    removeHighlight() {
      const {
        hlElement
      } = this; // Remove classes 1-st, since PhantomJS somehow cannot remove style attr before removing classes

      this.element.removeClass([this.activeClassName, this.defaultClassName, this.primaryClassName, this.customClassName].join(' ')).removeAttr('style');

      if (hlElement) {
        hlElement.removeClass(this.hlClassName);
        this.set('hlElement', null);
        this.set('hlClassName', null);
      }
    },

    /**
     * Get theme (primary or default)
     *
     * @param {Boolean} primary - prefer primary theme over default one or not
     * @returns {String}
     * @private
     */
    _getThemeClassName(primary) {
      return primary ? this.primaryClassName : this.defaultClassName;
    },

    /**
     * Highlight parent element, add passed in class name to element
     *
     * @param {object} element - element to highlight
     */
    _handleHighlightParentElement(element, addHighlight = false) {
      var _element$parent, _element$parent$compo, _hlElement$classList;

      const hlElement = document.getElementById(element === null || element === void 0 ? void 0 : (_element$parent = element.parent) === null || _element$parent === void 0 ? void 0 : (_element$parent$compo = _element$parent.component) === null || _element$parent$compo === void 0 ? void 0 : _element$parent$compo.elementId);

      if (hlElement && addHighlight) {
        hlElement.classList.add('highlight-parent-container');
      } else if (hlElement === null || hlElement === void 0 ? void 0 : (_hlElement$classList = hlElement.classList) === null || _hlElement$classList === void 0 ? void 0 : _hlElement$classList.contains('highlight-parent-container')) {
        hlElement.classList.remove('highlight-parent-container');
      }
    }

  });

  _exports.default = _default;
});