define("builder/pods/components/field-filters/component", ["exports", "builder/core/expression/expression", "builder/core/expression/operators", "builder/pods/components/filter-operator/component", "builder/pods/components/filter-property/component"], function (_exports, _expression, _operators, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @property {Object} field - Data of the field
     */
    field: {},

    /**
     * @property {Boolean} isOpened - Flag to open/close dropdown
     */
    isOpened: false,

    /**
     * @inheritDoc
     */
    init(...args) {
      this.set('addedPropertyFilters', []);

      this._super(...args);
    },

    /**
     * @inheritDoc
     */
    didReceiveAttrs() {
      const data = Ember.copy(this.get('field.data'), true);

      if (data) {
        const objects = (0, _expression.getDataObjects)(data);
        this.addPropertyFilters(objects);
      }
    },

    /**
     * Add filters from "data" property to be rendered
     * @param {Array} objects - Array of objects. See Expression#getDataObjects
     */
    addPropertyFilters(objects) {
      objects.forEach(object => {
        const filterObject = Ember.copy(this.get('field.children').findBy('name', object.name), true);

        if (filterObject) {
          object.properties.forEach(property => {
            const {
              operator,
              value,
              name
            } = property;
            const filterProperty = filterObject.children.findBy('name', name);
            const filterOperator = filterProperty.children.findBy('logic', operator); // skip the steps if the property is already exist

            if (filterProperty === null || filterProperty === void 0 ? void 0 : filterProperty.isAdded) {
              return;
            }

            (0, _component.selectOperator)(filterOperator);
            filterOperator.children[0].data = value;
            this.addPropertyFilter(filterObject, filterProperty);
            this.updateExpression(filterProperty, value, operator);
            this.hidePropertyInDropdown(filterObject.name, filterProperty.name);
          });
        }
      });
    },

    /**
     * Returns expression data object
     * @returns {Null|Object}
     */
    getExpressionsData() {
      let expressions = this.getValidExpressions();
      let result = null;
      expressions = this.nestExpressions(expressions);

      if (expressions.length === 1) {
        result = expressions[0].toObject();
      }

      return result;
    },

    /**
     * This method recursively combines each two expressions into new expression until one expression left in array
     * @param {Array} expressions - Array of expressions
     * @returns {Array} Array of nested expressions
     */
    nestExpressions(expressions) {
      if (expressions.length <= 1) {
        return expressions;
      }

      const newExpression = _expression.default.create();

      newExpression.setOperator(_operators.OPERATOR_AND);
      newExpression.setLeftOperand(expressions[0]);
      newExpression.setRightOperand(expressions[1]);
      expressions.splice(0, 2, newExpression);
      return this.nestExpressions(expressions);
    },

    /**
     * Returns array of valid expressions
     * @returns {Array}
     */
    getValidExpressions() {
      const expressions = [];
      this.addedPropertyFilters.forEach(property => {
        if (property.expression.isValid()) {
          expressions.push(property.expression);
        }
      });
      return expressions;
    },

    /**
     * Sets data to the field and send to parent action handler\
     */
    setData() {
      const data = this.getExpressionsData();
      const action = this.actionHandler;
      this.set('field.data', data);

      if (action) {
        action(data, this.get('field.name'));
      }
    },

    /**
     * Updates passed property expression data
     * @param {Object} property - Filter-property object
     * @param {*} value - Right operand value
     * @param {String} operator - Expression operator
     */
    updateExpression(property, value, operator) {
      const propertyObject = (0, _component2.getPropertyObject)(property);
      property.expression.updateLeftOperand(propertyObject);
      property.expression.updateRightOperand(value);
      property.expression.setOperator(operator);
    },

    /**
     * Handles property object and adds to store to be rendered
     * @param {Object} object - Filter object
     * @param {Object} property - Filter property
     */
    addPropertyFilter(object, property) {
      Ember.set(property, 'parent', object);
      Ember.set(property, 'expression', _expression.default.create());
      this.addedPropertyFilters.pushObject(property);
    },

    /**
     * Hides property from "Add new" dropdown
     * @param {String} object - Filter object name
     * @param {String} property - Filter property name
     */
    hidePropertyInDropdown(object, property) {
      const filterObject = this.get('field.children').findBy('name', object);

      if (filterObject) {
        const filterProperty = filterObject.children.findBy('name', property);

        if (filterProperty) {
          filterProperty.isAdded = true;
        }
      }
    },

    /**
     * Shows property from "Add new" dropdown
     * @param {String} object - Filter object name
     * @param {String} property - Filter property name
     */
    showPropertyInDropdown(object, property) {
      const filterObject = this.get('field.children').findBy('name', object);

      if (filterObject) {
        const filterProperty = filterObject.children.findBy('name', property);

        if (filterProperty) {
          delete filterProperty.isAdded;
        }
      }
    },

    /**
     * Adds triggerOnRender property to passed object. This property is passed to filter-operator
     * which is pass it to its component. We need "change" event auto trigger only when user add new filter
     * not on config render
     * @param {Object} property - Property object
     */
    enableAutoTrigger(property) {
      Ember.set(property, 'triggerOnRender', true);
    },

    actions: {
      /**
       * Add property to filter
       * @param {Object} objectCopy - Parent object
       * @param {Object} propertyCopy - Chosen property object
       */
      selectPropertyFilter(object, property) {
        const objectCopy = Ember.copy(object, true);
        const propertyCopy = Ember.copy(property, true);
        this.enableAutoTrigger(propertyCopy);
        this.addPropertyFilter(objectCopy, propertyCopy);
        this.hidePropertyInDropdown(objectCopy.name, propertyCopy.name);
        this.set('isOpened', false);
      },

      /**
       * Removes property from filter
       * @param {Object} property - Property object
       */
      removeProperty(property) {
        this.showPropertyInDropdown(property.parent.name, property.name);
        this.addedPropertyFilters.removeObject(property);
        this.setData();
      },

      /**
       * Updates expression left operand and set data
       * @param {Object} expression - Expression class
       * @param {*} operand - Left operand
       */
      updateLeftOperand(expression, operand) {
        expression.updateLeftOperand(operand);
      },

      /**
       * Updates expression right operand and set data
       * @param {Object} expression - Expression class
       * @param {*} operand - Right operand
       */
      updateRightOperand(expression, operand) {
        expression.updateRightOperand(operand);
        this.setData();
      },

      /**
       * Sets expression operator
       * @param {Object} expression - Expression class
       * @param {String} operator - Operator
       */
      setOperator(expression, operator) {
        expression.setOperator(operator);
        this.setData();
      },

      /**
       * Updates expression operator and set data
       * @param {Object} expression - Expression class
       * @param {String} operator - Operator
       */
      updateOperator(expression, operator) {
        expression.removeRightOperand();
        expression.setOperator(operator);
        this.setData();
      }

    }
  });

  _exports.default = _default;
});