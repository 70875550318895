define("builder/helpers/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  /**
   * Format date object into mm/dd/yy string format
   *
   * @param {*[]} params - 1-st argument is date to format
   * @returns {*}
   */
  function formatDate(params) {
    const date = params[0];
    let dateString = null;

    if (date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      dateString = `${month}/${day}/${year}`;
    }

    return dateString;
  }
  /**
   * @class DateFormatHelper
   */


  var _default = Ember.Helper.extend({
    compute(params) {
      return formatDate(params);
    }

  });

  _exports.default = _default;
});