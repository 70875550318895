define("builder/core/drop-rules/base-rule", ["exports", "builder/core/enumerators/container"], function (_exports, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Drag and drop helper utility, that will restrict drag and drop operations from breaking the structure
   */
  var _default = Ember.Object.extend({
    /**
     * Grid builder service
     * @type {import('ember').Ember.Service}
     */
    gridBuilder: null,

    /**
     * List of allowed types to be dropped
     * @type {string[]}
     * @private
     */
    _allowedTypes: null,

    /**
     * @inheritdoc
     */
    init(...args) {
      this._super(...args);

      this.set('_allowedTypes', []);
    },

    /**
     * If draggable could be dropped into container next to anchor
     *
     * @param {object} container - container where drop will be
     * @param {object} draggable - draggable that is dropped
     * @param {string} direction - drop direction
     * @param {object} anchor - anchor child of container, next to which drop performed(will be)
     * @returns {boolean} - is drop allowed or not
     */
    canDrop(container, draggable) {
      return container && draggable;
    },

    /**
     * Prepare draggable for drop operation, since we have some special rule, which transforms draggable,
     * depending on drop container, direction, anchor, etc.
     *
     * @param {import('ember').Ember.Service} structure - structure service, using which structure should be modified
     * @param {object} container - instance of grid-container
     * @param {object} draggable - draggable component grid-container/widget/module
     * @param {string} direction - drop direction
     * @returns {object} - prepared draggable node for drop operation
     */
    prepare(structure, container, draggable) {
      return draggable;
    },

    /**
     * Perform drop operation
     *
     * @param {import('ember').Ember.Service} structure - structure service, using which structure should be modified
     * @param {object} container - container where draggable dropped
     * @param {object} draggable - element that has been dropped
     * @param {string} direction - drop direction
     * @param {object} [anchor] - anchor node, next to which draggable is dropped
     */
    drop() {
      throw new Ember.Error('Method drop must be overridden');
    },

    /**
    * get the root container holding the element
    * @param {Object} element - element object
    */
    getRootContainer(element) {
      var _element$originObject;

      if (!(element === null || element === void 0 ? void 0 : (_element$originObject = element.originObject) === null || _element$originObject === void 0 ? void 0 : _element$originObject.type) || element.originObject.type === _container.default.WIDGET_CONTAINER) {
        return element;
      }

      return this.getRootContainer(element.parent);
    },

    /**
    * Get the current breakpoint mode
    * @param {Object} element - element object
    */
    getCurrentBreakpoint(element) {
      var _element$component, _device$_device;

      const device = element === null || element === void 0 ? void 0 : (_element$component = element.component) === null || _element$component === void 0 ? void 0 : _element$component.device;
      return device === null || device === void 0 ? void 0 : (_device$_device = device._device) === null || _device$_device === void 0 ? void 0 : _device$_device.breakpoint;
    },

    /**
    * Reset the order of the source container from where the element drag has
    * started
    * @param {Object} draggable - draggable which container to find
    * @param {Object} structure - structure
    */
    resetOrderOfSourceContainer(draggable, targetRootContainer, structure) {
      var _this$getRootContaine;

      const sourceRootContainer = (_this$getRootContaine = this.getRootContainer(draggable === null || draggable === void 0 ? void 0 : draggable.parent)) === null || _this$getRootContaine === void 0 ? void 0 : _this$getRootContaine.component;

      if ((targetRootContainer === null || targetRootContainer === void 0 ? void 0 : targetRootContainer.elementId) !== (sourceRootContainer === null || sourceRootContainer === void 0 ? void 0 : sourceRootContainer.elementId)) {
        structure.order.reassignOrderConsecutively(draggable === null || draggable === void 0 ? void 0 : draggable.parent, draggable);
      }
    }

  });

  _exports.default = _default;
});