define("builder/serializers/menu-child-link", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Menu child link serializer
   *
   * @class MenuChildLinkSerializer
   */
  var _default = _rest.default.extend({
    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args);

      if (!json.params || json.params && typeof json.params === 'object' && !Object.keys(json.params).length) {
        delete json.params;
      }

      return json;
    }

  });

  _exports.default = _default;
});