define("builder/pods/components/menumanager-createfolder/component", ["exports", "builder/pods/components/menumanager-folder/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @inheritdoc
     */
    settingsTitle: 'Create New Folder',

    /**
     * @inheritdoc
     */
    data: Ember.Object.create({
      title: '',
      itemId: +new Date(),
      link: null
    }),

    /**
     * Resets data
     *
     * @method
     */
    clearData() {
      this.data.setProperties({
        title: '',
        link: null
      });
    },

    actions: {
      /**
       * Action to send config data.
       */
      openConfig() {
        this.clearData();
        this.openMMConfig(this.getConfig(), false);
      }

    }
  });

  _exports.default = _default;
});