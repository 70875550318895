define("builder/pods/components/menumanager-page/component", ["exports", "builder/pods/components/menumanager-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @property {Boolean} isCurrentPage - indicate what page is current
     * @readonly
     */
    isCurrentPage: Ember.computed('data.link.value', 'configurationService.page.Alias', function () {
      const isCurrent = this.get('data.link.value') === this.get('configurationService.page.Alias');

      if (isCurrent && typeof this.expandParentFolder === 'function') {
        this.expandParentFolder();
      }

      return isCurrent;
    }).readOnly(),

    /**
     * @property {String} icon - icon class for current item
     * @readonly
     */
    icon: Ember.computed('data.link.value', 'data.isHidden', function (...args) {
      let icon = null;

      if (this.get('data.config.isHomepage')) {
        icon = 'icon-home';
      } else if (this.get('data.config.isPopupPage')) {
        icon = this.get('data.isHidden') ? 'icon-popup-page-hidden' : 'icon-popup-page';
      }

      return icon || this._super(...args);
    }).readOnly(),

    /**
     * Configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Delete page ability
     * @returns {AccessControlResult}
     * @readonly
     */
    deletePageAbility: Ember.computed('accessControl', 'data.config.page.isChild', 'data.config.page.isGlobal', function () {
      const {
        page
      } = this.data.config;
      const deleteAbility = this.accessControl.can('delete page', page);
      const manageGlobalPage = this.accessControl.can('manage-global page', page);
      const deleteGlobalPageAbility = this.accessControl.can('delete-global page', page);
      let {
        message
      } = deleteAbility;

      if (!manageGlobalPage.isGranted) {
        message = this.literals.GLOBAL_PAGE_UPDATE_RESTRICTION;
      } else if (!deleteGlobalPageAbility.isGranted) {
        message = this.literals.GLOBAL_PAGE_DELETE_RESTRICTION;
      }

      return { ...deleteAbility,
        message
      };
    }).readOnly(),

    /**
     * Update page ability
     * @returns {AccessControlResult}
     * @readonly
     */
    updatePageAbility: Ember.computed('accessControl', 'data.config.page.isChild', 'data.config.page.isGlobal', function () {
      const {
        page,
        masterPage
      } = this.data.config;
      const updateAbility = this.accessControl.can('update page', page);
      const updateGlobalAbility = this.accessControl.can('update-global page', page);
      const updateChildAbility = this.accessControl.can('update-child page', page);
      let {
        message
      } = updateAbility;

      if (!updateGlobalAbility.isGranted) {
        message = this.literals.GLOBAL_PAGE_UPDATE_RESTRICTION;
      } else if (!updateChildAbility.isGranted && masterPage) {
        message = this.literals.CHILD_PAGE_UPDATE_RESTRICTION.replace(':website', masterPage.WebsiteId).replace(':page', masterPage.id);
      }

      return { ...updateAbility,
        message
      };
    }).readOnly(),

    /**
     * It is a dummy method to show error when you try to invoke
     * not passed action from parent component or controller.
     *
     * @method openPageSettingsConfigById
     */
    openPageSettingsConfigById() {
      throw new Ember.Error(`${this.toString()}: does not passed "openPageSettingsConfigById" handler`);
    },

    actions: {
      openConfig() {
        this.openPageSettingsConfigById(this.get('data.config.pageId'));
      }

    }
  });

  _exports.default = _default;
});