define("builder/widgets/data-setters/widget-container", ["exports", "builder/core/enumerators/widget-container-video-background-fields", "builder/core/enumerators/breakpoint-modes"], function (_exports, _widgetContainerVideoBackgroundFields, _breakpointModes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WidgetContainerDataSetter
   */
  var _default = Ember.Object.create({
    /**
     * Set data to widget(originObject)
     *
     * @param {EmberObject} component - originObject where value should be set
     * @param {String} name - name of property to set
     * @param {*} value - value to set
     * @returns {EmberObject} - component back
     */
    setData(component, name, value) {
      switch (name) {
        case _widgetContainerVideoBackgroundFields.default.VIDEO_URL:
          {
            Ember.set(component, _widgetContainerVideoBackgroundFields.default.VIDEO_URL, value);
            Ember.set(component, _widgetContainerVideoBackgroundFields.default.VIDEO_ID, this.extractVideoId(value));
            break;
          }

        case _widgetContainerVideoBackgroundFields.default.VIDEO_ID:
          {
            // Do nothing because video id extracted from video url
            break;
          }

        case 'visibleOnDevices':
          {
            [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL].forEach(mode => {
              Ember.set(component, `visibleOnDevices.${mode}`, value.includes(mode));
            });
            break;
          }

        default:
          Ember.set(component, name, value);
      }

      return component;
    },

    /**
     * Extract video id from youtube url
     *
     * @method extractVideoId
     * @param {String} url - youtube url
     * @returns {String}
     */
    extractVideoId(url) {
      let videoId = '';
      let matchResults = null;

      if (/https:\/\/www\.youtube\.com\/watch/.test(url)) {
        // For youtube url like https://www.youtube.com/watch?v=pGbIOC83-So&feature=youtu.be
        matchResults = /[?&]v=([^?&]+)/.exec(url);
      } else if (/https:\/\/youtu\.be/.test(url)) {
        // For from youtube url like https://youtu.be/pGbIOC83-So
        matchResults = /youtu\.be\/([^?&]+)/.exec(url);
      }

      if (matchResults && matchResults.length === 2) {
        videoId = matchResults.pop();
      }

      return videoId;
    }

  });

  _exports.default = _default;
});