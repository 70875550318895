define("builder/serializers/application", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    primaryKey: 'Id',

    normalize(...args) {
      const normalized = this._super(...args);

      if (normalized.data.type === 'form-field-type') {
        normalized.data.attributes.IsUnique = normalized.data.attributes.IsUnique === '1';
      }

      return normalized;
    },

    /**
     * Pass correct payload root key
     *
     * @param {String} payloadKey - original payload key
     * @returns {*}
     */
    modelNameFromPayloadKey(payloadKey) {
      let resolvedKey;

      switch (payloadKey) {
        case 'formFields':
          {
            resolvedKey = 'formFieldType';
            break;
          }

        case 'formFieldCategories':
          {
            resolvedKey = 'formFieldTypeCategory';
            break;
          }

        default:
          {
            resolvedKey = payloadKey;
          }
      }

      return this._super(resolvedKey);
    }

  });

  _exports.default = _default;
});