define("builder/pods/website/create/controller", ["exports", "builder/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Website create controller
   * @class
   */
  var _default = _base.default.extend({
    /**
     * Selected website id
     * @type {null|string}
     */
    selectedWebsite: null,

    /**
     * Selected template id
     * @type {null|String}
     */
    selectedTemplate: null,

    /**
     * Flag to determine whether error happened
     * @type {boolean}
     */
    errorOnCreate: false,

    /**
     * Create button enabled status
     * @type {boolean}
     * @readonly
     */
    isEnableCreateButton: Ember.computed('selectedTemplate', 'selectedWebsite', 'errorOnCreate', function () {
      return !this.errorOnCreate && (this.selectedTemplate || this.selectedWebsite);
    }).readOnly(),

    /**
     * Company-and-website service
     * @type {Ember.Service}
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * List with templates
     * @type {Object}
     */
    templatesList: Ember.computed('model', function () {
      const templates = this.model[0];

      this._normalizeChoicesValues(templates.choices);

      this._sortListByAlphabetically(templates.choices);

      return templates;
    }),

    /**
     * List with websites
     * @type {Object}
     */
    websitesList: Ember.computed('model', function () {
      const websites = this.model[1];

      this._normalizeChoicesValues(websites.choices);

      this._sortListByAlphabetically(websites.choices);

      return websites;
    }),

    /**
     * Reset selected website
     */
    resetSelectWebsite() {
      this.set('selectedWebsite', null);
      this.set('websitesList.data', null);
    },

    /**
     * Reset selected template
     */
    resetSelectTemplate() {
      this.set('selectedTemplate', null);
      this.set('templatesList.data', null);
    },

    /**
     * Normalizes values under "value" key of each choice
     * @param {Object[]} choices - choices which value to normalize
     * @returns {Object[]}
     * @private
     */
    _normalizeChoicesValues(choices) {
      return choices.forEach(choice => {
        choice.value += '';
      });
    },

    /**
     * If list is longer than two, sorting it by alphabetically
     * @param {object[]} list - list with templates or websites
     * @private
     */
    _sortListByAlphabetically(list) {
      if (list && list.length > 1) {
        list.sort((a, b) => {
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();
          const greaterReturn = labelA > labelB ? 1 : 0;
          return labelA < labelB ? -1 : greaterReturn;
        });
      }
    },

    actions: {
      /**
       * Creates new website
       * @returns {Object}
       */
      createWebsite() {
        return this.store.createRecord('website', {
          id: this.companyAndWebsite.getWebsiteId(),
          ClonedFrom: this.selectedTemplate || this.selectedWebsite
        }).save().then(website => {
          this.set('errorOnCreate', false); // @todo some preloader and show notification about success creation.

          this.transitionToRoute('website.edit', website.get('id'));
        }, error => {
          this.set('errorOnCreate', true);
          this.showNotification(error.errors[0], 'error', true);
        });
      },

      /**
       * Select template
       * @param {String} id - id selected template
       */
      selectTemplate(id) {
        if (id) {
          this.set('selectedTemplate', id);
          this.set('errorOnCreate', false);
          this.closeNotification();
          this.resetSelectWebsite();
        } else {
          this.resetSelectTemplate();
        }
      },

      /**
       * Select website
       * @param {String} id - id selected website
       */
      selectWebsite(id) {
        if (id) {
          this.set('selectedWebsite', id);
          this.set('errorOnCreate', false);
          this.closeNotification();
          this.resetSelectTemplate();
        } else {
          this.resetSelectWebsite();
        }
      }

    }
  });

  _exports.default = _default;
});