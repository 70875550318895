define("builder/pods/components/field-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "306tj/Th",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"noInfo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"widget-field-info\",null,[[\"field\",\"validation\"],[[24,[\"field\"]],[24,[\"validationsMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"range-slider\",null,[[\"class\",\"start\",\"min\",\"max\",\"tooltips\",\"connect\",\"format\",\"on-change\"],[\"bld-form_range_container\",[24,[\"sliderOptions\",\"start\"]],[24,[\"sliderOptions\",\"min\"]],[24,[\"sliderOptions\",\"max\"]],[24,[\"sliderOptions\",\"tooltips\"]],[24,[\"sliderOptions\",\"connect\"]],[24,[\"sliderOptions\",\"format\"]],[28,\"action\",[[23,0,[]],\"change\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/field-range/template.hbs"
    }
  });

  _exports.default = _default;
});