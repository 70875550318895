define("builder/services/tab", ["exports", "builder/core/enumerators/widget", "uid", "builder/core/enumerators/module"], function (_exports, _widget, _uid, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TabService = (_dec = Ember.inject.service('grid-builder'), _dec2 = Ember.inject.service('structure'), _dec3 = Ember.inject.service('module-remove'), (_class = (_temp = class TabService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "gridBuilder", _descriptor, this);

      _initializerDefineProperty(this, "structureService", _descriptor2, this);

      _initializerDefineProperty(this, "moduleRemoveService", _descriptor3, this);
    }

    /**
     * Generate default tab configuration on creation of a widget
     *
     * @param {[Object & Copyable]} tabs collection
     * @return {{isDefault: *, children: [], id: string, label: string}}
     * @private
     */
    _getDefaultTabConfig(tabs) {
      return {
        id: (0, _uid.default)(),
        isDefault: tabs.length <= 0,
        label: this._generateTabPaneLabel(tabs),
        children: []
      };
    }
    /**
     * Generating a default tab label based on quantity of tabs
     *
     * @param {[Object & Copyable]} tabs collection
     * @return {string}
     *
     * @private
     */


    _generateTabPaneLabel(tabs) {
      return tabs.length <= 0 ? 'Tab' : `Tab ${tabs.length + 1}`;
    }

    _mapVariantDataToConfig(tabData) {
      var _tabData$isDefault, _tabData$id, _tabData$icon, _tabData$label;

      return {
        isDefault: (_tabData$isDefault = tabData === null || tabData === void 0 ? void 0 : tabData.isDefault) !== null && _tabData$isDefault !== void 0 ? _tabData$isDefault : false,
        id: (_tabData$id = tabData === null || tabData === void 0 ? void 0 : tabData.id) !== null && _tabData$id !== void 0 ? _tabData$id : null,
        icon: (_tabData$icon = tabData === null || tabData === void 0 ? void 0 : tabData.icon) !== null && _tabData$icon !== void 0 ? _tabData$icon : '',
        label: (_tabData$label = tabData === null || tabData === void 0 ? void 0 : tabData.label) !== null && _tabData$label !== void 0 ? _tabData$label : ''
      };
    }
    /**
     * Sets default tab widget pane as active on creating tabs
     * @param {Copyable & Ember.Object} tabsWidgetNode
     */


    initTabWidget(tabsWidgetNode) {
      var _tabsWidgetNode$origi, _defaultTab$originObj;

      const tabs = (tabsWidgetNode === null || tabsWidgetNode === void 0 ? void 0 : (_tabsWidgetNode$origi = tabsWidgetNode.originObject) === null || _tabsWidgetNode$origi === void 0 ? void 0 : _tabsWidgetNode$origi.children) || [];
      const defaultTab = this.getDefaultTab(tabs);
      this.setActiveTab(tabs, defaultTab === null || defaultTab === void 0 ? void 0 : (_defaultTab$originObj = defaultTab.originObject) === null || _defaultTab$originObj === void 0 ? void 0 : _defaultTab$originObj.id);
      tabsWidgetNode.originObject.set('children', [...tabs]);
    }
    /**
     *
     * @param {Copyable & Ember.Object} tabsWidgetNode
     * @param {[Object]} values - array of form-variant Objects that came from settings
     */


    updateTabsState(tabsWidgetNode, values) {
      var _tabsWidgetNode$origi2;

      const tabs = (tabsWidgetNode === null || tabsWidgetNode === void 0 ? void 0 : (_tabsWidgetNode$origi2 = tabsWidgetNode.originObject) === null || _tabsWidgetNode$origi2 === void 0 ? void 0 : _tabsWidgetNode$origi2.children) || [];

      if (!tabs.length && !values.length) {
        return;
      }

      const processedTabsIds = [];
      let newDefaultTabId = null;
      const mappedTabs = values.map(variant => {
        var _variant$icon;

        const {
          id
        } = variant;
        processedTabsIds.push(id);
        const tabPane = this.getTabById(tabs, id);

        if (!tabPane) {
          // When we add a form-variant the properties are empty
          // so we need to extend our naming policy for the tabs
          Ember.set(variant, 'label', this._generateTabPaneLabel(tabs));
          return this.addTabPane(tabsWidgetNode, this._mapVariantDataToConfig(variant));
        }

        tabPane.originObject.set('label', variant.label);
        tabPane.originObject.set('icon', (_variant$icon = variant === null || variant === void 0 ? void 0 : variant.icon) !== null && _variant$icon !== void 0 ? _variant$icon : '');

        if (variant.isDefault === true && !variant.isOldDefault) {
          newDefaultTabId = id;
        }

        return tabPane;
      });

      if (newDefaultTabId) {
        this.setDefault(tabs, newDefaultTabId);
      }

      tabs.forEach(tab => {
        var _tab$originObject;

        if (!processedTabsIds.includes(tab === null || tab === void 0 ? void 0 : (_tab$originObject = tab.originObject) === null || _tab$originObject === void 0 ? void 0 : _tab$originObject.id)) {
          var _tab$originObject2;

          this.removeTabPane(tabsWidgetNode, tab === null || tab === void 0 ? void 0 : (_tab$originObject2 = tab.originObject) === null || _tab$originObject2 === void 0 ? void 0 : _tab$originObject2.id);
        }
      });
      tabsWidgetNode.originObject.set('children', [...mappedTabs]);
    }

    addTabPane(tabsWidgetNode, data) {
      var _tabsWidgetNode$origi3;

      const tabs = (tabsWidgetNode === null || tabsWidgetNode === void 0 ? void 0 : (_tabsWidgetNode$origi3 = tabsWidgetNode.originObject) === null || _tabsWidgetNode$origi3 === void 0 ? void 0 : _tabsWidgetNode$origi3.children) || [];
      const tabPaneConfig = data !== undefined ? data : this._getDefaultTabConfig(tabs);
      const tabPaneWidgetNode = this.gridBuilder.createInstance(_widget.WidgetType.WIDGET_TAB_PANE, tabPaneConfig);
      tabPaneWidgetNode.set('parent', tabsWidgetNode);
      tabs.push(tabPaneWidgetNode);
      tabsWidgetNode.originObject.set('children', [...tabs]);
      this.setActiveTab(tabs, tabPaneConfig.id);
      return tabPaneWidgetNode;
    }

    removeTabPane(tabsWidget, tabId) {
      var _tabsWidget$originObj, _tabs$newDefaultOrAct, _removedTab$originObj;

      const tabs = (tabsWidget === null || tabsWidget === void 0 ? void 0 : (_tabsWidget$originObj = tabsWidget.originObject) === null || _tabsWidget$originObj === void 0 ? void 0 : _tabsWidget$originObj.children) || [];

      if (tabs.length <= 1) {
        // We should not be able to remove the last tab
        return;
      }

      const removedTab = this.getTabById(tabs, tabId);

      if (!removedTab) {
        return;
      }

      const tabPositionIndex = tabs.indexOf(removedTab);
      const newDefaultOrActiveTabIndex = tabPositionIndex >= 0 ? tabPositionIndex + 1 : tabPositionIndex - 1;
      const newDefaultOrActiveTab = (_tabs$newDefaultOrAct = tabs[newDefaultOrActiveTabIndex]) !== null && _tabs$newDefaultOrAct !== void 0 ? _tabs$newDefaultOrAct : null; // Should always be true

      if ((removedTab === null || removedTab === void 0 ? void 0 : removedTab.isActiveTab) && newDefaultOrActiveTab) {
        this.setActiveTab(tabs, newDefaultOrActiveTab);
      }

      if ((removedTab === null || removedTab === void 0 ? void 0 : (_removedTab$originObj = removedTab.originObject) === null || _removedTab$originObj === void 0 ? void 0 : _removedTab$originObj.isDefault) && newDefaultOrActiveTab) {
        this.setDefault(tabs, newDefaultOrActiveTab);
      }

      this.structureService.startTransaction();
      this.structureService.removeChild(removedTab);
      this.structureService.commitTransaction();
      this.moduleRemoveService.removeModule(removedTab);
      tabsWidget.originObject.set('children', [...tabs]);
    }

    setActiveTab(tabs, activeTabId) {
      const newActiveTab = this.getTabById(tabs, activeTabId);

      if (!newActiveTab) {
        return;
      }

      const oldActiveTab = this.getActiveTab(tabs);

      if (oldActiveTab) {
        oldActiveTab.set('isActiveTab', false);
      }

      newActiveTab.set('isActiveTab', true);
      reloadModulesOnActiveTab(newActiveTab);
    }

    setDefault(tabs, defaultTabId) {
      const newDefaultTab = this.getTabById(tabs, defaultTabId);

      if (!newDefaultTab) {
        return;
      }

      const defaultTab = this.getDefaultTab(tabs);

      if (defaultTab) {
        defaultTab.originObject.set('isDefault', false);
      }

      newDefaultTab.originObject.set('isDefault', true);
    }

    getTabById(tabs, tabId) {
      const searchedTab = tabs.filter(tab => {
        var _tab$originObject3;

        return (tab === null || tab === void 0 ? void 0 : (_tab$originObject3 = tab.originObject) === null || _tab$originObject3 === void 0 ? void 0 : _tab$originObject3.id) === tabId;
      });

      if (searchedTab.length > 0) {
        return searchedTab[0];
      }

      return null;
    }

    getActiveTab(tabs) {
      const activeTabs = tabs.filter(tab => (tab === null || tab === void 0 ? void 0 : tab.isActiveTab) === true);

      if (activeTabs.length > 0) {
        return activeTabs[0];
      }

      return null;
    }

    getDefaultTab(tabs) {
      const defaultTabs = tabs.filter(tab => {
        var _tab$originObject4;

        return (tab === null || tab === void 0 ? void 0 : (_tab$originObject4 = tab.originObject) === null || _tab$originObject4 === void 0 ? void 0 : _tab$originObject4.isDefault) === true;
      });

      if (defaultTabs.length > 0) {
        return defaultTabs[0];
      }

      return null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gridBuilder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "structureService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "moduleRemoveService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TabService;

  /**
   * Refesh forms and core modules if needed
   * @param {Ember.Object} widget
   *
   * @returns void
   */
  function reloadModulesOnActiveTab(widget) {
    var _widget$originObject, _originObject$type;

    const originObject = (_widget$originObject = widget === null || widget === void 0 ? void 0 : widget.originObject) !== null && _widget$originObject !== void 0 ? _widget$originObject : null;

    if (!originObject) {
      return;
    }

    const type = (_originObject$type = originObject === null || originObject === void 0 ? void 0 : originObject.type) !== null && _originObject$type !== void 0 ? _originObject$type : null;

    if (!type) {
      return;
    }

    if ([_module.default.MODULE_CORE, _module.default.MODULE_FORM].includes(type)) {
      Ember.set(widget, 'refresh', Date.now());
      return;
    }

    const children = (originObject === null || originObject === void 0 ? void 0 : originObject.children) || [];

    if (!children.length) {
      return;
    }

    children.forEach(function (entry) {
      reloadModulesOnActiveTab(entry);
    });
  }
});