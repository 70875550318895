define("builder/pods/components/metapicker-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yjCrX576",
    "block": "{\"symbols\":[\"category\",\"variable\"],\"statements\":[[4,\"if\",[[24,[\"isVariablesPresent\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"categories\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"dl\",true],[11,\"class\",[28,\"if\",[[23,1,[\"hidden\"]],\"metapicker-popup--hidden-category\"],null]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"dt\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"],[4,\"each\",[[23,1,[\"variables\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"dd\",true],[10,\"tabindex\",\"0\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onVariableClick\",[23,2,[]]],null]],[11,\"class\",[28,\"if\",[[23,2,[\"hidden\"]],\"metapicker-popup--hidden-variable\"],null]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,2,[\"name\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[10,\"class\",\"metapicker-popup__not-found-message\"],[8],[0,\"Did not match any variable\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "builder/pods/components/metapicker-popup/template.hbs"
    }
  });

  _exports.default = _default;
});