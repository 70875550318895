define("builder/pods/components/field-html/component", ["exports", "builder/core/abstract-classes/field-base-component", "sanitize-html"], function (_exports, _fieldBaseComponent, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Component that represents html field in module configuration
   */
  let FieldHTMLComponent = (_dec = Ember.inject.service('field-code'), _dec2 = Ember._action, (_class = (_temp = class FieldHTMLComponent extends _fieldBaseComponent.FieldBaseComponent {
    constructor(...args) {
      super(...args);
      this.classNames = ['field-code'];

      _initializerDefineProperty(this, "fieldCodeService", _descriptor, this);

      this._mode = 'htmlmixed';

      this.onHTMLSnippetChange = changedValue => {
        const sanitizedValue = (0, _sanitizeHtml.default)(changedValue, {
          allowedAttributes: false,
          allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel', 'src', 'href'],
          allowedTags: [..._sanitizeHtml.default.defaults.allowedTags, 'img', 'video', 'audio', 'source', 'track']
        });
        Ember.set(this.field, 'data', sanitizedValue);
        this.actionHandler(sanitizedValue, this.field.name, 'change');
      };
    }

    /**
     * Action for showing code editor popup
     * @returns {void}
     */
    showCodeEditorPopup() {
      var _this$field;

      const snippetText = ((_this$field = this.field) === null || _this$field === void 0 ? void 0 : _this$field.data) || '';

      if (typeof snippetText !== 'string') {
        return;
      }

      const mode = this._mode;
      this.fieldCodeService.openCodePopup(snippetText, mode, this.onHTMLSnippetChange);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fieldCodeService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showCodeEditorPopup", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showCodeEditorPopup"), _class.prototype)), _class));
  _exports.default = FieldHTMLComponent;
});