define("builder/pods/website/edit/page/edit/container-template/controller", ["exports", "builder/controllers/base", "builder/core/enumerators/notification", "builder/core/enumerators/template-category-type"], function (_exports, _base, _notification, _templateCategoryType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * @property {DS.Model<ContainerTemplate>} template - container template model
     * @readonly
     */
    template: Ember.computed.readOnly('model.template'),

    /**
     * @property {Ember.Service} gridBuilder - grid builder service
     */
    gridBuilder: Ember.inject.service(),

    /**
     * @property {Boolean} isSaveAllowed - is template model ready to save
     */
    isSaveAllowed: Ember.computed('model.template.{hasDirtyAttributes,isSaving}', function () {
      const model = this.get('model.template');
      return model && model.get('hasDirtyAttributes') && !model.get('isSaving');
    }),

    /**
     * Save container template model
     * @param {DS.Model<ContainerTemplateModel>} model - model to save
     */
    saveTemplateModel(model) {
      const {
        isNew
      } = model;
      model.save().then(() => this.store.findAll('container-template-category', {
        reload: true
      })).then(() => {
        this.showNotification(`Container template was successfully ${isNew ? 'created' : 'updated'}.`, _notification.default.TYPE.SUCCESS);
        this.transitionToRoute('website.edit.page.edit');
      }).catch(error => {
        const isUnknownServerError = !!Ember.get(error, 'errors.0.status');
        let errors = error.errors || [];

        if (isUnknownServerError) {
          errors = [this.get('literals.CONTAINER_TEMPLATE_SAVE_ERROR')];
        }

        this.showNotification(errors, _notification.default.TYPE.ERROR);
      });
    },

    actions: {
      /**
       * Fetch template categories
       * @returns {Promise}
       */
      fetchCategories() {
        return this.store.findAll('container-template-category', {
          backgroundReload: false
        }).then(categories => categories.filter(category => category.get('IsStatic') === _templateCategoryType.default.STATIC));
      },

      /**
       * Save model
       */
      save() {
        const model = this.get('model.template');

        if (model.isNew) {
          const structure = this.get('model.container').copy(); // Remove anchor because it is unique on page scope

          if (structure.get('originObject.anchor')) {
            delete structure.originObject.anchor;
          }

          model.set('Structure', structure);
        }

        this.saveTemplateModel(model);
      },

      /**
       * Action to close popup
       */
      closePopup() {
        this.transitionToRoute('website.edit.page.edit');
      },

      /**
       * Update template visibility
       * @param {String} visibility - visibility of template
       */
      updateVisibility(visibility) {
        this.get('model.template').updateVisibility(visibility);
      },

      /**
       * Update template thumbnail
       * @param {String} imageId - id of picture received from backend
       * @param {String} thumbnailUrl - path to image received from backend
       */
      updateThumbnail(imageId, thumbnailUrl) {
        this.get('model.template').updateThumbnail(imageId, thumbnailUrl);
      },

      /**
       * Remove template thumbnail
       */
      removeThumbnail() {
        this.get('model.template').removeThumbnail();
      }

    }
  });

  _exports.default = _default;
});