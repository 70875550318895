define("builder/core/configuration-builder/configuration-builder", ["exports", "ember-copy", "builder/core/configuration-builder/fields/field-media", "builder/core/enumerators/fields", "builder/core/util/define-fields-visibility", "builder/core/data-filters", "builder/core/enumerators/breakpoint-modes", "builder/core/grid-models/module-device-visibility-state", "builder/core/configuration-builder/fields/form-variants-configuration-builder"], function (_exports, _emberCopy, _fieldMedia, _fields, _defineFieldsVisibility, _dataFilters, _breakpointModes, _moduleDeviceVisibilityState, _formVariantsConfigurationBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extractor used to provide correct format of fields, that will be used in module configuration window
   * TODO: Here we go, now I think it obvious that config part of application is a mess, I think we must reorganize how we
   * handle configuration, the approach I came up with is the following:
   * 1) We have fields structure(theme structure) which used as a template and populate it with data(that's what we do now),
   * so, the approach is to add layers in order to lower support complexity, the layers are:
   * <Configuration Builder> => <Simple Field or Container Field> => <Field Specific Normalizer>
   * in case of container field, the field it self must process it's children using field specific normalizers.
   * (ex. configurationBuilder.build(template, data<form-variants>) => formVariantsContainer.build(template, data) =>
   * <loop>field.normalize(fieldData)</loop>
   *
   * So, as we can see here field will normalize data, container will assemble normalized data to appropriate field template
   * in case of simple field, configuration builder will assemble data itself.
   *
   * @class ConfigurationBuilder
   */
  var _default = Ember.Object.extend({
    /**
     * access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Build module configuration by using config structure and module data
     *
     * @param {object[]} configStructure - module config structure
     * @param {object} configData - module data
     * @param {EmberObject} node - page structure node, that contains originObject
     * @param {string} breakpoint - breakpoint mode to set data to
     * @returns {Promise<object[]>} - config ready to be used
     */
    buildConfiguration(configStructure, configData, node, breakpoint) {
      const {
        accessControl
      } = this;
      const config = []; // This need for not reflected to theme config

      const configStructureCopy = (0, _emberCopy.copy)(configStructure, true); // we should add a device visibility configuration only for renderable modules

      if (node) {
        this._injectVisibilityConfiguration(configStructureCopy);
      }

      if (node && node.get('acl.routing')) {
        this._addFormTitleValidation(configStructureCopy, configData);
      }

      configStructureCopy.forEach(item => {
        this._buildConfigurationByType(item, configData, node, breakpoint); // 1 level of depth supported for now, since only 1 module uses it,
        // in case it will became a common thing,
        // come up with a solution and it will became a common thing


        if (item.hasOwnProperty('name') && !accessControl.can('use misc', null, {
          name: item.name.toLowerCase()
        }).isGranted) {
          item.disabled = true;
        }

        config.push(item);
      });
      (0, _defineFieldsVisibility.defineFieldsVisibility)(config);
      return this._applyDataFilters(config).then(() => Ember.RSVP.resolve(config));
    },

    /**
     * Build device visibility configuration for core-modules
     *
     * @param {ModuleThemeModel} configStructure - module theme model
     * @private
     * @returns {void}
     */
    _injectVisibilityConfiguration(configStructure) {
      const visibleOnDevices = {
        type: _fields.default.CHECKBOX_LIST,
        label: 'Show module on which devices / breakpoints?',
        description: 'Please select at least 1 device for the module to show on',
        help: 'When Un-checking the box means the module will be hidden on that device / breakpoint.',
        name: 'visibleOnDevices',
        data: [_breakpointModes.LARGE, _breakpointModes.MEDIUM, _breakpointModes.SMALL, _breakpointModes.EXTRA_SMALL],
        choices: [{
          value: _breakpointModes.LARGE,
          label: 'Desktop'
        }, {
          value: _breakpointModes.MEDIUM,
          label: 'Small Monitor'
        }, {
          value: _breakpointModes.SMALL,
          label: 'Tablet'
        }, {
          value: _breakpointModes.EXTRA_SMALL,
          label: 'Mobile'
        }],
        validation: {
          elements: {
            min: 1,
            max: 4
          },
          message: 'You must select at least one device for this module to display on.'
        }
      };
      configStructure.push(visibleOnDevices);
    },

    /**
     * Build module i18n configuration be using config i18n structure and module data
     *
     * @param {ModuleThemeModel} configStructure - module theme model
     * @param {object} configData - module data
     * @param {string} language - language code for which config should be built
     * @returns {object[]} - ready for use config
     */
    buildI18NConfiguration(configStructure, configData, language) {
      const languageKey = `Strings.${language}`;
      const config = configStructure.get(languageKey);
      const data = configData ? Ember.get(configData, language) : null;
      const fields = [];

      if (!config) {
        return fields;
      }

      Object.keys(config).forEach(key => {
        fields.push(this._buildI18NField(key, data && data.hasOwnProperty(key) ? data[key] : config[key]));
      });

      this._sortStringsByAlphabet(fields);

      return fields;
    },

    /**
     * Build module configuration by type
     *
     * @param {object[]} item - module config
     * @param {object} data - module data
     * @param {EmberObject} node - page structure node
     * @param {string} breakpoint - breakpoint mode
     * @param {string} [basePath=""] - base path for configuration map
     * @private
     * @returns {void}
     */
    _buildConfigurationByType(item, data, node, breakpoint, basePath = '') {
      const {
        type
      } = item;

      if (type === _fields.default.CONTAINER) {
        this._buildFieldContainerConfiguration(item, data, node, breakpoint, basePath);
      } else if (type === _fields.default.FORM_VARIANTS) {
        new _formVariantsConfigurationBuilder.default().build(item, data, node, breakpoint, basePath);
      } else if (type === _fields.default.MEDIA && item.validation.hasOwnProperty('image')) {
        this._buildFieldMediaImageConfiguration(item, data, node, breakpoint);
      } else if (item.name === 'visibleOnDevices') {
        this._buildVisibilityConfiguration(item, data, node);
      } else {
        this._buildDefaultConfiguration(item, data, basePath);
      }
    },

    /**
     * Build visibility configuration for a module
     *
     * @param {object[]} item - module config
     * @param {object} data - module data
     * @param {EmberObject} node - page structure node
     * @private
     * @returns {void}
     */
    _buildVisibilityConfiguration(item, data, node) {
      if (!(node === null || node === void 0 ? void 0 : node.originObject)) {
        return;
      }

      const {
        visibleOnDevices
      } = node.originObject;

      if (!visibleOnDevices) {
        return;
      }

      Ember.set(item, 'data', _moduleDeviceVisibilityState.default.create(visibleOnDevices).buildConfig());
    },

    /**
     * Build module configuration for field media image
     *
     * @param {object[]} item - module config
     * @param {object} data - module data
     * @param {EmberObject} node - page structure node
     * @param {string} breakpoint - breakpoint mode
     * @private
     * @returns {void}
     */
    _buildFieldMediaImageConfiguration(item, data, node, breakpoint) {
      const builder = _fieldMedia.default.create();

      const {
        name
      } = item;
      let configData = null;

      if (name && data.hasOwnProperty(name)) {
        const dataName = data[name];
        configData = builder.build({
          type: 'image',
          url: Ember.get(dataName, 'url'),
          naturalWidth: Ember.get(dataName, 'naturalWidth'),
          naturalHeight: Ember.get(dataName, 'naturalHeight'),
          scale: Ember.get(dataName, `focalPoints.${breakpoint}.scale`),
          left: Ember.get(dataName, `focalPoints.${breakpoint}.left`),
          top: Ember.get(dataName, `focalPoints.${breakpoint}.top`)
        }, node);
      }

      Ember.set(item, 'data', configData);
    },

    /**
     * Build module default configuration
     *
     * @param {object[]} item - module config
     * @param {object} data - module data
     * @param {string} path - field base path within configuration map
     * @private
     * @returns {void}
     */
    _buildDefaultConfiguration(item, data, path) {
      const {
        name
      } = item;

      if (data.hasOwnProperty(name)) {
        item.data = (0, _emberCopy.copy)(data[name], true);
      }

      item.path = path;
    },

    /**
     * Sort string keys by alphabet
     *
     * @param {object[]} strings - Array of objects
     * @private
     * @returns {void}
     */
    _sortStringsByAlphabet(strings) {
      strings.sort((field1, field2) => {
        const name1 = field1.name.toUpperCase();
        const name2 = field2.name.toUpperCase();
        const greaterResult = name1 > name2 ? 1 : 0;
        return name1 < name2 ? -1 : greaterResult;
      });
    },

    /**
     * Build module configuration for form variants
     *
     * @param {object[]} item - module config
     * @param {object} data - module data
     * @param {EmberObject} node - page structure node
     * @param {string} breakpoint - breakpoint mode
     * @param {string} [basePath=""] - base path for configuration map
     * @private
     * @returns {void}
     */
    _buildFieldContainerConfiguration(item, data, node, breakpoint, basePath) {
      const children = item.children || [];
      const {
        name
      } = item;
      const path = `${basePath}/${name}`;
      let dataContent = data;

      if (name && data.hasOwnProperty(name)) {
        dataContent = data[name];
      }

      children.forEach(child => {
        if (dataContent.hasOwnProperty(child.name)) {
          this._buildConfigurationByType(child, dataContent, node, breakpoint, path);
        }
      });
    },

    /**
     * Build i18n field
     *
     * @param {string} name - field name
     * @param {any} data - field data
     * @returns {{type: string, name: string, data: any}}
     * @private
     */
    _buildI18NField(name, data) {
      return {
        type: 'field-textarea-content',
        name,
        data
      };
    },

    /**
     * Workaround to add validation to form field(title to be more specific)
     *
     * @param {ModuleThemeModel} configStructure - module theme config
     * @param {PageModuleModel} data - form module config
     * @private
     * @returns {void}
     */
    _addFormTitleValidation(configStructure, data) {
      const title = configStructure.findBy('name', 'title');

      if (title) {
        title.validation = {
          required: {
            presence: true,
            ignoreBlank: true
          },
          formTitle: {
            formId: Ember.get(data, 'formId'),
            debounce: 400,
            pageId: 0
          },
          length: {
            min: 1,
            max: 255
          }
        };
      }
    },

    /**
     * Apply filters to fields with dataFilter settings
     *
     * @param {object} config - merged theme module config with data
     * @private
     * @returns {Promise<object>}
     */
    _applyDataFilters(config) {
      const application = Ember.getOwner(this);
      const promises = [];

      if (config && config.length) {
        config.forEach(fieldConfig => {
          const dataFilter = _dataFilters.default[fieldConfig.dataFilter];

          if (typeof dataFilter === 'function') {
            promises.push(dataFilter(application, fieldConfig));
          }
        });
      }

      return Ember.RSVP.all(promises);
    }

  });

  _exports.default = _default;
});