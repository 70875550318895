define("builder/pods/components/button-global-visibility/component", ["exports", "builder/core/enumerators/breakpoint-modes", "builder/core/literals"], function (_exports, _breakpointModes, _literals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Global visibility toggle button
   */
  let ButtonGlobalVisibilityComponent = (_dec = Ember.inject.service('rendering-global-visibility'), _dec2 = Ember.computed(`globalVisibilityService.globalVisibility.{${_breakpointModes.LARGE},${_breakpointModes.SMALL},${_breakpointModes.MEDIUM},${_breakpointModes.EXTRA_SMALL}}`), _dec3 = Ember._action, (_class = (_temp = class ButtonGlobalVisibilityComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "globalVisibilityService", _descriptor, this);

      this.literals = _literals.default;

      this.onVisibilityToggled = () => {};
    }

    get isGlobalVisibilityEnabled() {
      return this.globalVisibilityService.getCurrentDeviceVisibility();
    }

    onButtonClick() {
      this.globalVisibilityService.toggleGlobalVisibility();
      this.onVisibilityToggled();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "globalVisibilityService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isGlobalVisibilityEnabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isGlobalVisibilityEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonClick"), _class.prototype)), _class));
  _exports.default = ButtonGlobalVisibilityComponent;
});